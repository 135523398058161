import Big from 'big.js';
import {MINUS} from '../../const/minus.const';

const matchFirstHyphen = /\u002D/; //'-' hyphen-minus;

/* Takes as input the output of the number / currency / percentage pipe */
export function postProcessLocalizedNumericValue(
  originalValue: number | Big | null | undefined,
  originalPipeOutput: string
): string {
  if (originalValue !== null && originalValue !== undefined && originalValue < 0) {
    // This assumes that no locale will use hyphens for uses other than signaling that a number is negative
    return originalPipeOutput.replace(matchFirstHyphen, MINUS);
  }
  return originalPipeOutput;
}
