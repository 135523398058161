import {Injectable} from '@angular/core';
import {MatDatepickerIntl} from '@angular/material/datepicker';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class MatDatepickerLabels extends MatDatepickerIntl {
  constructor(private translate: TranslateService) {
    super();
  }

  override calendarLabel = this.translate.instant('ui-elements.mat-datepicker.accessibility.calendar');
  override openCalendarLabel = this.translate.instant('ui-elements.mat-datepicker.accessibility.open-calendar');
  override closeCalendarLabel = this.translate.instant('ui-elements.mat-datepicker.accessibility.close-calendar');
  override prevMonthLabel = this.translate.instant('ui-elements.mat-datepicker.accessibility.prev-month');
  override nextMonthLabel = this.translate.instant('ui-elements.mat-datepicker.accessibility.next-month');
  override prevYearLabel = this.translate.instant('ui-elements.mat-datepicker.accessibility.prev-year');
  override nextYearLabel = this.translate.instant('ui-elements.mat-datepicker.accessibility.next-year');
  override prevMultiYearLabel = this.translate.instant('ui-elements.mat-datepicker.accessibility.prev-multi-year');
  override nextMultiYearLabel = this.translate.instant('ui-elements.mat-datepicker.accessibility.next-multi-year');
  override startDateLabel = this.translate.instant('ui-elements.mat-datepicker.accessibility.start-date');
  override endDateLabel = this.translate.instant('ui-elements.mat-datepicker.accessibility.end-date');
  override switchToMonthViewLabel = this.translate.instant(
    'ui-elements.mat-datepicker.accessibility.switch-to-month-view'
  );
  override switchToMultiYearViewLabel = this.translate.instant(
    'ui-elements.mat-datepicker.accessibility.switch-to-multi-year-view'
  );

  override formatYearRange(startYear: string, endYear: string) {
    return this.translate.instant('ui-elements.mat-datepicker.accessibility.year-range', {startYear, endYear});
  }
}
