<div class="top-spacer"></div>
<div class="bsz-display-flex bsz-justify-center message-error-container">
  <div class="bsz-text-secondary bsz-text-center">
    <mat-icon [color]="'error'" class="bsz-central-message-icon">sick</mat-icon>
    <div class="bsz-subtitle-1 bsz-padding-t4">Gosh - an exception has occurred</div>
    <div class="bsz-body-1">Please reload the page or call our hotline</div>
    <mat-divider class="bsz-margin-y4"></mat-divider>
    <div class="bsz-caption">Alternatively try hitting the server with a hammer or buy a new one</div>
  </div>
</div>
