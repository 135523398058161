import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {AfpRestResponse} from '../afp-rest/index';

import {UserRole} from './user-role.definition';
import {AFPUserRole, UserRoleMapping} from './user-role-mapping.definitions';
import {USER_ROLE_MAPPING} from './user-role-mapping.internal-config';

interface UserRolesPayload {
  userRoleList: {roleIntlId: AFPUserRole}[];
}
type UserRolesRequest = AfpRestResponse<UserRolesPayload>;

@Injectable({
  providedIn: 'root',
})
export class UserRoleService {
  // private static readonly url: string = '/com.basuiz.afs.rest.services/rest/user/accessAndRoles'; // to be used with mock-server & proxy.config.json
  private static readonly url: string = process.env.API_BASE_URL + '/rest/user/accessAndRoles';
  private afpUserRoles: AFPUserRole[] | undefined = undefined;
  private userRoles: UserRole[] | undefined = undefined;

  constructor(
    private httpClient: HttpClient,
    @Optional() @Inject(USER_ROLE_MAPPING) private userRoleMapping: UserRoleMapping
  ) {}

  public async ɵfetchAndCacheRoles(): Promise<void> {
    try {
      const response = await this.httpClient.get<UserRolesRequest>(UserRoleService.url, {}).toPromise();
      if (!response || !response.payload || !Array.isArray(response.payload.userRoleList)) {
        throw Error('Unexpected response form user-roles end-point.');
      }

      this.afpUserRoles = response.payload.userRoleList.map((r) => r.roleIntlId);
      if (!this.afpUserRoles || !this.afpUserRoles.length) {
        console.warn('Tried to fetch user roles from backend but received none.');
      }
      this.userRoles = this.mapUserRoles(this.afpUserRoles);
    } catch (err) {
      console.error(err);
      console.warn(`Could not fetch the user roles from the back-end. Starting the app without roles.`);
      this.afpUserRoles = [];
      this.userRoles = [];
    }
  }

  /** @return: a list of the AFP User roles of the logged user */
  public getAFPUserRoles(): AFPUserRole[] {
    if (!this.afpUserRoles) {
      throw Error(`Tried to read user-roles before they were fetched from backend.`);
    }
    return [...this.afpUserRoles];
  }

  /** @param roles: a list of user-role from the list of roles defined by the portal
   * @return: whether the logged user has at least one of the specified user-roles or not */
  public hasRole(...roles: UserRole[]): boolean {
    return this.getUserRoles().some((grantedRole) => roles.includes(grantedRole));
  }

  /** @return: a list of the portal user-roles of the logged user */
  public getUserRoles(): UserRole[] {
    if (!this.userRoles) {
      throw Error(`Tried to read user roles during app initialization.
                  This may be the result of an NgRX effects class injecting a dependency that depends on the user roles.
                  This may be an indirect dependency via a configuration.
                  Use the injector to \`get\` dependencies in effects classes, instead of injecting them in the constructor.`);
    }
    return [...this.userRoles];
  }

  private mapUserRoles(afpRoles: AFPUserRole[]) {
    if (!this.userRoleMapping) {
      return [];
    } else {
      const portalRoles: UserRole[] = [];
      afpRoles.forEach((afpRole) => {
        if (this.userRoleMapping[afpRole]) {
          portalRoles.push(this.userRoleMapping[afpRole]);
        }
      });
      if (afpRoles.length && !portalRoles.length) {
        console.warn('Could not determine any user role from the mapping provided by the app.');
      }
      return portalRoles;
    }
  }
}
