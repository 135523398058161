import {CommonConfig} from './common.config.definition';

// IMPORTANT: do not modify the signature of this function
export function getDefaultConfig(): CommonConfig {
  return {
    auth: {
      mtan: {
        numericOnlyResponseCode: false,
      },
      token: {
        numericOnlyResponseCode: false,
      },
      cronto: {
        numericOnlyResponseCode: false,
        allowOfflineFallback: true,
        pollingInterval: 5000,
      },
      push: {
        pollingInterval: 5000,
      },
      futurae: {
        numericOnlyResponseCode: false,
        allowOfflineFallback: true,
        pollingInterval: 5000,
      },
      matrix: {
        numericOnlyResponseCode: false,
      },
    },
    exportToFileFileTypes: ['PDF', 'CSV', 'EXCEL'],
    currency: {
      mainIsoCodes: ['CHF', 'USD', 'EUR', 'GBP', 'SEK', 'NOK'],
    },
    documentDownload: {
      useJWT: true,
      openDownloadedFilesInBrowser: false,
    },
    esapiValidation: {
      // the back-end defaults can be found in https://bitbucket.sits.basuiz.net/projects/WBC/repos/rest/browse/com.basuiz.afs.rest.services/external/config/base/security/_esapi/validation.properties
      basuizStrictSafeStringRegExp: /^[\+?!%_,~:@\/\*&;\.#\p{Pd}\(\)\[\]\p{Pc}\p{N}\p{L}\p{Sc}\p{Z}]{0,1000}$/u,
      basuizCollaborationCommentRegExp:
        /^[\+=?!%_,~:@|'\-\/\*&;\.#\n\r\p{Pd}\(\)\[\]\p{Pc}\p{N}\p{L}\p{Sc}\p{Z}]{0,3000}$/u,
    },
  };
}
