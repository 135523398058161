export abstract class NavigationIntent {
  /* This private property prevents accidental assignments of other objects
   to a variable/property declared as a NavigationIntent. For more info, search 'Nominal typing in Typescript' */
  private __nominal: void;

  public isNavigationIntent(): boolean {
    return this instanceof NavigationIntent;
  }
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type NavigationIntentClass = new (...args: any) => NavigationIntent;

export type NavigationIntentFactory = () => NavigationIntent;
