import {WalletSource} from '@basuiz/shared/data-access';
import {TranslationKey} from '@basuiz/web-app-applet-api';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {PageTabMemory} from '../components/portal-page-layout/page-tab-memory.definition';
import {PortalPage} from '../navigation/classes/portal-page';
import {TranslationKeyWithParams} from '../navigation/translation-key-with-params';

export interface PaymentMethodWalletOverviewPagePayload {
  /** Tab to be immediately selected as the page is instantiated */
  tab?: WalletSource;
}

export class PaymentMethodWalletOverviewPage extends PortalPage {
  constructor(public payload: PaymentMethodWalletOverviewPagePayload = {}) {
    super();
  }

  public readonly pageTabMemory: PageTabMemory<WalletSource> = {
    activeTabId: this.payload.tab,
  };

  get parentPortalPage(): PortalPage | null {
    return null; // TODO - Replace with the adequate PortalPage unless the page hangs direct under the 'Home' page.
  }

  get breadcrumbText(): TranslationKey | TranslationKeyWithParams {
    return asTranslationKey('web-app-portal.pages.payment-method-wallet-overview.breadcrumb');
  }

  get breadcrumbTestId(): string {
    return 'web-app-portal.pages.payment-method-wallet-overview.breadcrumb';
  }

  get titleText(): TranslationKey {
    return asTranslationKey('web-app-portal.pages.payment-method-wallet-overview.window-title');
  }

  static readonly routerConfigPath = 'wallet';
  get route(): Array<string> {
    return [`/${PaymentMethodWalletOverviewPage.routerConfigPath}`];
  }
}
