import {UserSettings} from '../models/user-settings.definition';

export type UserSettingsGroup = keyof UserSettings;

export const basuizUserSettingsGroupPrefix = 'com.basuiz.afp.settings.';

const userSettingsGroupMap: Record<UserSettingsGroup, string> = {
  general: `${basuizUserSettingsGroupPrefix}general`,
  reporting: `${basuizUserSettingsGroupPrefix}reporting`,
  contactInformation: `${basuizUserSettingsGroupPrefix}contactinformation`,
};

export function getUserSettingsGroupMap(): Record<UserSettingsGroup, string> {
  return {...userSettingsGroupMap};
}
