import {FocusKeyManager, FocusMonitor, FocusOrigin} from '@angular/cdk/a11y';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import {AsyncSubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {BszFabAnimations} from './bsz-fab-animations';
import {BszFabMenuItem} from './bsz-fab-menu-item';
import {BszFabMenuTrigger} from './bsz-fab-menu-trigger';

const LOG_PREFIX = '[bsz-fab-menu]';

@Component({
  selector: 'bsz-fab-menu',
  templateUrl: 'bsz-fab-menu.html',
  styleUrls: ['bsz-fab-menu.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [BszFabAnimations.fabContainerTransition],
  host: {
    tabindex: '-1',
  },
})
export class BszFabMenu implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {
  @HostBinding('attr.data-fab-menu-id') get menuId() {
    return this.fabMenuUid;
  }

  /**
   * This input takes classes set on the host bsz-fab-menu element and applies them on the
   * template which is displayed in the overlay container.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('class') className = '';

  /** aria-label for the menu panel. */
  @Input('aria-label') ariaLabel: string | null = null;

  /** aria-labelledby for the menu panel. */
  @Input('aria-labelledby') ariaLabelledby: string | null = null;

  /** aria-describedby for the menu panel. */
  @Input('aria-describedby') ariaDescribedby: string | null = null;

  /** The offset position of the FAB on the X axis */
  @Input() offsetX = 0;

  /** The offset position of the FAB on the Y axis */
  @Input() offsetY = 0;

  /**
   * All items inside the menu.
   *
   * @private
   */
  @ContentChildren(BszFabMenuItem) menuItems!: QueryList<BszFabMenuItem>;

  /** @private */
  @ContentChild(BszFabMenuTrigger) fabTriggerDirective?: BszFabMenuTrigger;

  /** The template reference of <ng-template> which will be passed down to the CDK overlay */
  @ViewChild(TemplateRef) private templateRef!: TemplateRef<unknown>;

  /** @private */
  readonly fabMenuUid = `bsz-fab-menu-${BszFabMenu.uniqueId++}`;

  /** @private */
  static uniqueId = 0;

  /** @private */
  isOpen = false;

  /**
   * Used in the transition when the button transforms to the menu.
   *
   * @private
   */
  menuItemsTotalHeight = '0px';

  /**
   * FocusMonitor will update this property and identifies how the trigger
   * was focused.
   *
   * @private
   */
  fabTriggerFocusOrigin: FocusOrigin = null;

  /** The css class to add to the overlay backdrop while the menu is closed. */
  private readonly inactiveBackdropClass = 'bsz-fab-inactive-backdrop';

  private readonly destroy = new AsyncSubject<void>();

  private keyManager!: FocusKeyManager<BszFabMenuItem>;

  private fabTriggerElement!: HTMLButtonElement;

  private overlayRef!: OverlayRef;

  /**
   * Holds the focus state of which the panel was opened so we know when
   * we close the menu whether to focus back on the trigger or not.
   */
  private openedBy: FocusOrigin = null;

  constructor(
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private focusMonitor: FocusMonitor,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.createOverlay();
  }

  ngAfterContentInit(): void {
    if (this.menuItems.length === 0) {
      throw new Error(`${LOG_PREFIX} FAB Menu must contain at least one menu item`);
    }

    this.initKeyManager(this.menuItems);
    this.menuItemsTotalHeight = this.calculateMenuHeight(this.menuItems.length);
  }

  ngAfterViewInit(): void {
    this.overlayRef.attach(new TemplatePortal(this.templateRef, this.viewContainerRef));
    this.fabTriggerElement = this.getTriggerButtonElement();

    this.monitorFocusOnFabTriggerElement();
  }

  ngOnDestroy(): void {
    this.focusMonitor.stopMonitoring(this.fabTriggerElement);
    this.overlayRef.dispose();

    this.destroy.next();
    this.destroy.complete();
  }

  /**
   * opens the menu and based on the focus origin of the trigger
   * the focus will go to the first item of the menu or to the
   * menu panel.
   */
  open(origin?: FocusOrigin): void {
    this.isOpen = true;
    this.openedBy = origin ?? 'program';

    // The first item will be Highlighted only when we navigate via keyboard or programmatically.
    if (this.openedBy === 'mouse' || this.openedBy === 'touch') {
      this.focusMenuPanel();
    } else {
      this.focusFirstItem();
    }
  }

  /**
   * closes the menu and based on the focus origin of the trigger
   * the focus will go back to the trigger or not.
   */
  close(origin: FocusOrigin = 'program'): void {
    this.isOpen = false;

    // set the focus back on the trigger only when we navigate via keyboard or programmatically.
    // @TODO where the focus should go when we opened the menu via mouse and touch?
    if (this.openedBy === 'keyboard' || this.openedBy === 'program' || origin === 'keyboard') {
      this.focusTrigger();
    }

    this.openedBy = null;
    this.keyManager.setActiveItem(-1);
  }

  toggle(): void {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  /**
   * CDK overlay can either have a backdrop or not while it gets created and this
   * cannot be changed at runtime. To overcome this limitation we add a class
   * which moves the backdrop out of the way while the menu is closed, and
   * remove it when it is opened.
   *
   * @private
   */
  toggleBackdrop(): void {
    if (this.isOpen) {
      this.overlayRef.backdropElement?.classList.remove(this.inactiveBackdropClass);
    } else {
      this.overlayRef.backdropElement?.classList.add(this.inactiveBackdropClass);
    }
  }

  /** @private */
  handleKeydown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.close();
    }

    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      this.keyManager.setFocusOrigin('keyboard');
    }

    this.keyManager.onKeydown(event);
  }

  /**
   * We get the HTML element for the button via the overlayRef
   * because we cannot use @ViewChild or @ViewChildren for
   * elements inside the <ng-template>
   */
  private getTriggerButtonElement(): HTMLButtonElement {
    const button = this.overlayRef.overlayElement.querySelector(
      `[aria-controls="${this.fabMenuUid}"]`
    ) as HTMLButtonElement;

    if (!button) {
      throw new Error(`${LOG_PREFIX} Could not find trigger button element`);
    }

    return button;
  }

  private monitorFocusOnFabTriggerElement() {
    this.focusMonitor
      .monitor(this.fabTriggerElement)
      .pipe(takeUntil(this.destroy))
      .subscribe((origin) => (this.fabTriggerFocusOrigin = origin));
  }

  private createOverlay(): void {
    const [bottomOffset, rightOffset] = this.calculateOverlayOffsets();

    const positionStrategy = this.overlay.position().global().bottom(`${bottomOffset}px`).right(`${rightOffset}px`);

    this.overlayRef = this.overlay.create({
      positionStrategy: positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      backdropClass: ['cdk-overlay-dark-backdrop', this.inactiveBackdropClass],
      hasBackdrop: true,
    });

    this.overlayRef.backdropClick().subscribe(() => {
      this.close();
      this.cd.markForCheck();
    });
  }

  private calculateOverlayOffsets() {
    const bottomOffset = parseFloat(`${this.offsetY}`) + 16;

    // add 24px space from the right edge because the overlay sits on top of the scrollbar
    const rightOffset = parseFloat(`${this.offsetX}`) + 24;

    return [bottomOffset, rightOffset];
  }

  /**
   * Init the key manager which will handle the keyboard navigation on the menu items.
   * The instance accepts a collection of items that implement the FocusableOption
   * interface.
   */
  private initKeyManager(menuItems: QueryList<BszFabMenuItem>): void {
    this.keyManager = new FocusKeyManager(menuItems).withWrap();

    // Tab key closes the menu and the focus goes to the next focusable element after the trigger.
    this.keyManager.tabOut.pipe(takeUntil(this.destroy)).subscribe(() => this.close('keyboard'));
  }

  /** We need to know the exact total height of the menu in order to create the animation */
  private calculateMenuHeight(menuItemsNumber: number): string {
    return `${menuItemsNumber * BszFabMenuItem.height}px`;
  }

  private focusTrigger(): void {
    this.fabTriggerElement.focus();
  }

  private focusFirstItem(): void {
    this.keyManager.setFocusOrigin(this.openedBy).setFirstItemActive();
  }

  private focusMenuPanel(): void {
    document.getElementById(this.fabMenuUid)?.focus();
  }
}
