import {Injectable, NgZone} from '@angular/core';
import {Subject} from 'rxjs';

import {Action, ActionWithoutResponse, ActionWithResponse} from './model/action';
import {
  ConfigurationRequest,
  ConfigurationResponse,
  DeserializedNativeAppInterface,
  ErrorResponse,
  getDeserializedNativeAppInterface,
  getNativeAppInterface,
  InstantiateViewRequest,
  IsNativeActionSupportedRequest,
  IsNativeActionSupportedResponse,
  isWindowWithNativeAppInterface,
  MOBILE_BRIDGE_GLOBAL_VARIABLE,
  MobileBridgeInterface,
  NativeActionRequest,
  NativeActionResponse,
  NativeConfiguration,
} from './model/native-app-interface';
import {View} from './model/view';
import {NativeAppPromisifiedRequest} from './native-app-promisified-request';

declare global {
  interface Window {
    [MOBILE_BRIDGE_GLOBAL_VARIABLE]: MobileBridgeInterface;
  }
}

@Injectable()
export class BszMobileBridgeService {
  /**
   * This observable will emit a new value each time the mobile native/hybrid application wants to instantiate
   * a new view in the application.
   */
  get instantiateView() {
    return this._instantiateView.asObservable();
  }
  private readonly _instantiateView = new Subject<InstantiateViewData>();

  private readonly insideNative: boolean;

  private nativeAppInterface: DeserializedNativeAppInterface;

  /** Promisified request class wrapping isNativeActionSupported() and returnIsNativeActionSupported(). */
  private promisifiedIsNativeActionSupported: NativeAppPromisifiedRequest<
    IsNativeActionSupportedRequest,
    IsNativeActionSupportedResponse
  >;

  /** Promisified request class wrapping triggerNativeAction() and returnResultOfNativeAction(). */
  private promisifiedTriggerNativeAction: NativeAppPromisifiedRequest<NativeActionRequest, NativeActionResponse>;

  /** Promisified request class wrapping getConfiguration() and returnConfiguration(). */
  private promisifiedGetConfiguration: NativeAppPromisifiedRequest<ConfigurationRequest, ConfigurationResponse>;

  private appIsReadyCalled = false;

  constructor(private ngZone: NgZone) {
    this.insideNative = isWindowWithNativeAppInterface(window);
  }

  /**
   * Whether the web application is running inside a mobile native/hybrid application.
   * If false, trying to communicate with the mobile native/hybrid application using other methods
   * from this class will fail.
   */
  isInsideNative(): boolean {
    return this.insideNative;
  }

  /**
   * Call this function when the web application is loaded and ready to communicate
   * with the mobile native/hybrid application.
   */
  appIsReady(): void {
    if (this.appIsReadyCalled) {
      throw new Error('appIsReady() can only be called once');
    }
    this.appIsReadyCalled = true;

    if (!this.isInsideNative()) {
      return;
    }
    this.lazyInitNativeAppInterface();

    this.nativeAppInterface.bridgeIsOperational();
  }

  /**
   * Check whether the specified action is supported by the mobile native/hybrid application.
   *
   * @param action Action to check support for.
   *
   * @returns Promise which will resolve to true if the action is supported and to false if not.
   */
  async isActionSupported(action: Action): Promise<boolean> {
    if (!this.isInsideNative()) {
      return false;
    }
    this.lazyInitNativeAppInterface();

    const response = await this.promisifiedIsNativeActionSupported.callAndWaitForResponse({
      nativeActionType: action.name,
      payload: action.payload,
    });

    return response.isSupported;
  }

  /**
   * Retrieve configuration from mobile native/hybrid application
   *
   * @returns Promise which will resolve to the configuration object
   */
  async getConfiguration(): Promise<NativeConfiguration> {
    if (!this.isInsideNative()) {
      throw new Error('Not inside native');
    }
    this.lazyInitNativeAppInterface();
    const response = await this.promisifiedGetConfiguration.callAndWaitForResponse({});
    return response.payload;
  }

  /**
   * Trigger an action to be performed by the mobile native/hybrid application.
   * If the action is not supported, this method will throw an error. If you want to trigger an action
   * which may or may not be supported, call isActionSupported() before calling triggerAction().
   *
   * @param action Action to trigger.
   *
   * @returns A promise which resolves with the payload from the response of the triggered action
   * (for actions that have a response) or with no value (for actions that have no response).
   * For actions with a response the promise will be resolved when the response is received.
   * For actions with no response the promise will be resolved immediately.
   */
  async triggerAction<T>(action: ActionWithResponse<any, T>): Promise<T>;
  async triggerAction(action: ActionWithoutResponse<any>): Promise<void>;
  async triggerAction(action: Action) {
    if (!this.appIsReadyCalled) {
      throw new Error('appIsReady() has to be called before triggerAction(), but it was never called');
    }

    if (!this.isInsideNative()) {
      throw new Error('Not inside native');
    }
    this.lazyInitNativeAppInterface();

    if (action instanceof ActionWithResponse) {
      const response = await this.promisifiedTriggerNativeAction.callAndWaitForResponse({
        nativeActionType: action.name,
        payload: action.payload,
      });

      return response.payload;
    } else {
      return this.promisifiedTriggerNativeAction.call({
        nativeActionType: action.name,
        payload: action.payload,
      });
    }
  }

  /**
   * Load the native app interface lazily to support use cases where the native app interface
   * is not available on the window at the time of the service creation (as is the case with
   * the mobile bridge emulator).
   *
   * We also only create the mobile bridge global interface here, since only at this point
   * we know we can communicate with the native app and only from this point on we will actually
   * need the interface.
   */
  private lazyInitNativeAppInterface() {
    if (!this.nativeAppInterface) {
      // get the native app interface from window and then get the deserialized version, so we get type safety
      const serializedNativeAppInterface = getNativeAppInterface(window);
      this.nativeAppInterface = getDeserializedNativeAppInterface(serializedNativeAppInterface);

      // get the promisified versions of the native app interface functions that return a result
      this.promisifiedIsNativeActionSupported = new NativeAppPromisifiedRequest(
        this.nativeAppInterface.isNativeActionSupported
      );
      this.promisifiedTriggerNativeAction = new NativeAppPromisifiedRequest(
        this.nativeAppInterface.triggerNativeAction
      );
      this.promisifiedGetConfiguration = new NativeAppPromisifiedRequest(this.nativeAppInterface.getConfiguration);

      // create mobile bridge global interface here as well, since only now we'll need it
      this.createMobileBridgeGlobalInterface();
    }
  }

  private createMobileBridgeGlobalInterface() {
    window[MOBILE_BRIDGE_GLOBAL_VARIABLE] = {
      instantiateView: ({view, payload, requestId}: InstantiateViewRequest) => {
        let callbackCalled = false;
        const viewReadyCallback = () => {
          if (callbackCalled) {
            throw new Error(`viewReadyCallback already called (requestId=${requestId})`);
          }
          callbackCalled = true;

          this.nativeAppInterface.instantiatedViewReady({
            requestId: requestId,
            status: 'success',
          });
        };

        this.ngZone.run(() => {
          this._instantiateView.next({
            view: {name: view, payload: payload},
            viewReadyCallback: viewReadyCallback,
          });
        });
      },
      returnIsNativeActionSupported: (response: IsNativeActionSupportedResponse | ErrorResponse) => {
        this.promisifiedIsNativeActionSupported.responseFn(response);
      },
      returnResultOfNativeAction: (response: NativeActionResponse | ErrorResponse) => {
        this.promisifiedTriggerNativeAction.responseFn(response);
      },
      returnConfiguration: (response: ConfigurationResponse | ErrorResponse) => {
        this.promisifiedGetConfiguration.responseFn(response);
      },
    };
  }
}

export interface InstantiateViewData {
  /**
   * The name of the view to instantiate.
   */
  view: View;

  /**
   * This callback should be called when the requested view is ready to be displayed.
   * This should also be called in case the view cannot be loaded - the app should display an error
   * (in a similar way how it would display other errors) and then call this callback anyway.
   */
  viewReadyCallback(): void;
}
