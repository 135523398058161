import {ComponentFixture} from '@angular/core/testing';
import {JestFixtureFacade, JestFixtureFacadeAware, SimpleElement} from '../testing-core/index';

export class BszCentralMessageComponentPageObject implements JestFixtureFacadeAware {
  jestFixtureFacade: JestFixtureFacade;

  constructor(fixture: ComponentFixture<any>) {
    this.jestFixtureFacade = new JestFixtureFacade(fixture);
  }

  get title(): SimpleElement {
    return this.jestFixtureFacade.selectAsSimpleElement('web-app-common-bsz-central-message-title');
  }

  get info(): SimpleElement {
    return this.jestFixtureFacade.selectAsSimpleElement('web-app-common-bsz-central-message-info');
  }

  get additionalInfo(): SimpleElement {
    return this.jestFixtureFacade.selectAsSimpleElement('web-app-common-bsz-central-message-additional-info');
  }

  get icon(): SimpleElement {
    return this.jestFixtureFacade.selectAsSimpleElement('web-app-common-bsz-central-message-icon');
  }
}
