import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppShellOutletEmitterComponent} from './app-shell-outlet-emitter.component';
import {PortalModule} from '@angular/cdk/portal';

@NgModule({
  declarations: [AppShellOutletEmitterComponent],
  exports: [AppShellOutletEmitterComponent],
  imports: [CommonModule, PortalModule],
})
export class AppShellOutletEmitterModule {}
