export interface FacebookApiEnvironment {
  apiBaseUrl: string;
  accessToken: string;
  conversionsApi: {
    dataSourceId: string;
    eventsEndpoint: string;
    sendTestEvent: boolean;
    testEventCode: string;
  };
}

export function getFacebookApiEnvironment(): FacebookApiEnvironment {
  const apiBaseUrl = 'https://graph.facebook.com/v20.0';
  const dataSourceId = process.env['FB_CONVERSIONS_API_DATA_SOURCE_ID'] ?? '';

  return {
    apiBaseUrl: apiBaseUrl,
    accessToken: process.env['FB_API_USER_ACCESS_TOKEN'] ?? '',
    conversionsApi: {
      dataSourceId: dataSourceId,
      eventsEndpoint: dataSourceId ? `${apiBaseUrl}/${dataSourceId}/events` : '',
      sendTestEvent: process.env['FB_CONVERSIONS_API_SEND_TEST_EVENT'] === 'true',
      testEventCode: process.env['FB_CONVERSIONS_API_TEST_EVENT_CODE'] ?? '',
    },
  };
}

export enum FacebookConversionApiEventPrefixId {
  AddPaymentInfo = 'AddPaymentInfo',
  AddToCart = 'AddToCart',
  CompleteRegistration = 'CompleteRegistration',
  InitiateCheckout = 'InitiateCheckout',
  Purchase = 'Purchase',
  Rate = 'Rate',
  SpentCredits = 'SpentCredits',
}
