import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {TestingCoreModule} from '../testing-core/index';
import {BszInfoTextComponent} from './bsz-info-text.component';
import {BszPopoverModule, BszScreenSizeContentSwitcherModule} from '@basuiz/ui-elements';
import {BszI18nModule} from '@basuiz/i18n';
import {MatButtonModule} from '@angular/material/button';
import {BszInfoTextDirective} from './bsz-info-text.directive';

@NgModule({
  imports: [
    BszI18nModule,
    BszScreenSizeContentSwitcherModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    TestingCoreModule,
    BszPopoverModule,
  ],
  exports: [BszInfoTextDirective],
  declarations: [BszInfoTextComponent, BszInfoTextDirective],
})
export class BszInfoTextModule {}
