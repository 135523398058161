import {TranslationKey} from '@basuiz/web-app-applet-api';
import {isTranslationKeyWithParams, TranslationKeyWithParams} from './translation-key-with-params';

export class HistoryNavigationEntry {
  constructor(public navId: number, public translation: TranslationKey | TranslationKeyWithParams) {}

  translationKey: TranslationKey = isTranslationKeyWithParams(this.translation)
    ? this.translation.translationKey
    : this.translation;

  translationParams: Record<string, string | number> = isTranslationKeyWithParams(this.translation)
    ? this.translation.translationParams
    : {};
}
