import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Country} from '../../models/country.definitions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {CountryService} from '../../data-access/country.service';
import {of} from 'rxjs';
import {loadCountries, loadCountriesFailed, loadCountriesSuccess} from './country.actions';

@Injectable()
export class CountryEffects {
  loadCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCountries),
      mergeMap(() =>
        this.countryService.fetch().pipe(
          map((countryList: Country[]) => loadCountriesSuccess({countryList})),
          catchError((error) => of(loadCountriesFailed(error)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private countryService: CountryService) {}
}
