import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {AppletContextRequest} from './applet-context.definitions';
import {isDefined} from '../utils/defined.util';
import {switchMap, take} from 'rxjs/operators';
import {filterEqual} from '../utils/rxjs.util';

type ContextSubjectList<BANKLET_CONTEXT extends Record<string, any>> = ContextSubjectTuple<Required<BANKLET_CONTEXT>>[];
type ContextSubjectTuple<BANKLET_CONTEXT extends Record<string, any>> = [
  keyof BANKLET_CONTEXT,
  BehaviorSubject<BANKLET_CONTEXT[keyof BANKLET_CONTEXT] | undefined>
];

/* Holds the cache for a single context-id */
interface AppletContextStore<BANKLET_CONTEXT extends Record<string, any>> {
  /* Every property in the applet-context is caches in a separate subject */
  subjectList: ContextSubjectList<BANKLET_CONTEXT>;
  /* Read / write operations must wait until the store is initialized */
  initialized: BehaviorSubject<boolean>;
}

/* This class is to be extended by a service of the applet whose only purpose is to cache the applet-context.
 * Services extending this class must be singletons, i.e. all instances of the same applet must consume
 * the same instance of the service extending AppletContextCacheService.
 * Applets should not access this service directly, instead, the applet should have a class (e.g. a service)
 * that extends AppletContextCacheAdapter that mediates between the applet and the context-cache. */
export abstract class AppletContextCacheService<BANKLET_CONTEXT extends Record<string, any>> {
  /* Holds the cache for every context-id */
  private readonly contextCache = new Map<
    AppletContextRequest['appletContextId'],
    AppletContextStore<BANKLET_CONTEXT>
  >();

  /* Returns an observable for a single property of the applet-context,
   * This method can be called at any time, the observable will not emit any values until
   * the applet-context is initialized
   * */
  getAppletContextProperty$<PROP extends keyof BANKLET_CONTEXT>(
    contextProperty: PROP,
    appletContextId: AppletContextRequest['appletContextId']
  ): Observable<BANKLET_CONTEXT[PROP]> {
    const contextStore = this.contextCache.get(appletContextId);
    if (!isDefined(contextStore)) {
      throw new Error(`Attempted to access undefined context with id '${appletContextId}'`);
    }
    return contextStore.initialized.pipe(
      filterEqual(true),
      switchMap(() => {
        const keySubjectTuple: ContextSubjectTuple<BANKLET_CONTEXT> | undefined = contextStore.subjectList.find(
          ([key, _]) => contextProperty === key
        );
        if (!isDefined(keySubjectTuple)) {
          throw new Error(`No subject found for context property ${contextProperty?.toString()}`);
        }
        const [__, propertySubject] = keySubjectTuple;
        return propertySubject.asObservable() as Observable<BANKLET_CONTEXT[PROP]>;
      })
    );
  }

  /* Initializes a context based on a applet-context request.
   * If a applet-context already exists for the requested id and onInit is set to 'restore',
   * the existing applet-context is used.
   *
   * @param initialContext$: Observable whose first emission is used to define the initial value of the applet context
   *   this observable is subscribed either if
   *   - the request has "onInit: 'reset'"
   *   - there is no context cached for the requested context-id
   *
   * @returns: an observable that emits a single time once the applet-context is initialized.
   *   Read / write operations must wait until the applet-context is initialized.
   *   This observable can be used to determine when it is safe to do read / write operations
   * */
  initializeContext(
    {appletContextId, onInit}: AppletContextRequest,
    initialContext$: Observable<Required<BANKLET_CONTEXT>>
  ): Observable<void> {
    const returnSubject = new ReplaySubject<void>(1);

    const contextStore: AppletContextStore<BANKLET_CONTEXT> =
      this.contextCache.get(appletContextId) ?? this.getNonInitializedContextStore();
    if (!this.contextCache.has(appletContextId)) {
      this.contextCache.set(appletContextId, contextStore);
    }

    if (onInit === 'reset') {
      contextStore.initialized.next(false);
    }

    if (!contextStore.initialized.value) {
      initialContext$.pipe(take(1)).subscribe((context) => {
        contextStore.subjectList = Object.entries(context).map(([property, value]) => [
          property,
          new BehaviorSubject(value),
        ]);
        contextStore.initialized.next(true);
        returnSubject.next(undefined);
        returnSubject.complete();
      });
    } else {
      returnSubject.next(undefined);
      returnSubject.complete();
    }

    return returnSubject.asObservable();
  }

  private getNonInitializedContextStore(): AppletContextStore<BANKLET_CONTEXT> {
    return {
      subjectList: [],
      initialized: new BehaviorSubject(false),
    };
  }

  /* Returns the current value of a applet-context represented by the id passed as argument
   * Calling this method before the applet-context has initialized will throw an error
   * */
  getAppletContextSnapshot(appletContextId: AppletContextRequest['appletContextId']): BANKLET_CONTEXT {
    const contextStore = this.contextCache.get(appletContextId);
    if (!isDefined(contextStore)) {
      throw new Error(`Cannot get undefined context snapshot for id '${appletContextId}'`);
    } else if (!contextStore.initialized.value) {
      throw new Error(`Cannot get uninitialized context snapshot with id '${appletContextId}'`);
    } else {
      return contextStore.subjectList.reduce<Partial<BANKLET_CONTEXT>>((acc, [key, subject]) => {
        return {...acc, [key]: subject.value};
      }, {}) as BANKLET_CONTEXT;
    }
  }

  /* Updates part of the value of a applet-context represented by the id passed as argument.
   * @param delta: a partial record of the BANKLET_CONTEXT containing the properties/values to be updated.
   * Subscribers of getAppletContextProperty$ will receive the updated value if the property they subscribed to is
   * part of the delta.
   * Calling this method before the applet-context has initialized will throw an error
   * */
  updateAppletContext(appletContextId: AppletContextRequest['appletContextId'], delta: Partial<BANKLET_CONTEXT>) {
    const contextStore = this.contextCache.get(appletContextId);
    if (!isDefined(contextStore)) {
      throw new Error(`Cannot update undefined context for id '${appletContextId}'`);
    } else if (!contextStore.initialized.value) {
      throw new Error(`Cannot update uninitialized context with id '${appletContextId}'`);
    } else {
      contextStore.subjectList.filter(([key, _]) => key in delta).forEach(([key, subject]) => subject.next(delta[key]));
    }
  }
}
