import {Component} from '@angular/core';

@Component({
  selector: 'bsz-auth-marketing',
  templateUrl: './auth-marketing.component.html',
  styleUrls: ['./auth-marketing.component.scss'],
})
export class AuthMarketingComponent {
  constructor() {}
}
