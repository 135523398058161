import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';
import {AfpRestOperators, AfpRestResponse} from '../afp-rest/index';
import {tap} from 'rxjs/operators';

export interface UserSessionDetails {
  globalSessionId: string;
  loginId: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserSessionService {
  // private static readonly URL = `/com.basuiz.afs.rest.services/rest/systemperformance/ownsession`; // to be used with mock-server & proxy.config.json
  private static readonly URL = process.env.API_BASE_URL + `/rest/systemperformance/ownsession`;

  constructor(private readonly httpClient: HttpClient) {}

  public ɵloadSessionDetails(): Observable<UserSessionDetails> {
    return this.httpClient.get<AfpRestResponse<UserSessionDetails>>(UserSessionService.URL).pipe(
      AfpRestOperators.extractPayload(),
      tap((userSessionDetails) => this.sessionDetailsSubject.next(userSessionDetails))
    );
  }

  public get sessionDetails$(): Observable<UserSessionDetails> {
    return this.sessionDetailsSubject.asObservable();
  }
  private readonly sessionDetailsSubject = new ReplaySubject<UserSessionDetails>(1);
}
