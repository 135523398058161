import {InjectionToken, StaticProvider} from '@angular/core';

export const ENABLE_UI_NOTIFICATION_SNACK_BAR_SERVICE = new InjectionToken<boolean>(
  'bsz.web-app-common.enable-ui-notification-snack-bar-service',
  {
    factory: () => true,
  }
);

export function getDisabledUINotificationSnackBarServiceProvider(): StaticProvider {
  return {
    provide: ENABLE_UI_NOTIFICATION_SNACK_BAR_SERVICE,
    useValue: false,
  };
}
