<mat-divider></mat-divider>

<bsz-progress-bar
  *ngIf="inProgress | async"
  [message]="'web-app-common.auth.verification.push-verification.progress.message' | translate"
></bsz-progress-bar>

<ng-container *bszDesktopContent>
  <div class="bsz-subtitle-1 bsz-padding-t6 bsz-padding-b4">{{
    'web-app-common.auth.verification.push-verification.title' | translate
  }}</div>
</ng-container>
<ng-container *bszMobileContent>
  <div class="bsz-subtitle-2 bsz-padding-t6 bsz-padding-b4">{{
    'web-app-common.auth.verification.push-verification.title' | translate
  }}</div>
</ng-container>

<div class="bsz-body-1 bsz-padding-b4">{{
  'web-app-common.auth.verification.push-verification.description' | translate
}}</div>

<ng-container *ngIf="isPolling | async">
  <div class="bsz-text-center bsz-padding-b6">
    <div class="bsz-body-1">{{
      'web-app-common.auth.verification.push-verification.polling.progress.message' | translate
    }}</div>
    <mat-progress-bar class="bsz-width-50pct bsz-display-inline-block" mode="indeterminate"></mat-progress-bar>
  </div>
</ng-container>

<div class="bsz-text-right bsz-padding-b4">
  <button mat-button color="accent" type="button" (click)="cancel()">{{
    'web-app-common.auth.verification.push-verification.button.cancel' | translate
  }}</button>
</div>
