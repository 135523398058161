import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';

import {webcoat_deprecated} from '../../common/webcoat-deprecated';

export type BszBadgePalette = 'primary' | 'accent' | 'error';
export type BszBadgeContent = string | number | undefined | null;

const MAX_VALUE = 999;
const MAX_LENGTH = 3;
const UNICODE_ELLIPSIS = '\u2026';

const DOT_VARIANT_CLASS = 'bsz-badge-variant--dot';
const HIDDEN_CLASS = 'bsz-badge-hidden';
const FLOAT_CLASS = 'bsz-badge-float';
const INLINE_CLASS = 'bsz-badge-inline';

@Directive({
  selector: '[bszBadge]',
  host: {class: 'bsz-badge-active'},
})
export class BszBadge implements OnInit, OnDestroy {
  @Input()
  get bszBadge(): BszBadgeContent {
    return this.content;
  }

  set bszBadge(content: BszBadgeContent) {
    this.setContent(content);
  }

  @Input()
  get bszBadgeColor(): BszBadgePalette {
    return this.color;
  }

  set bszBadgeColor(value: BszBadgePalette) {
    this.setColor(value);
  }

  @Input()
  set bszBadgeVisible(isVisible: BooleanInput) {
    this.visible = coerceBooleanProperty(isVisible);
    this.updateBadgeVisibility();
  }

  @Input()
  set bszBadgeInline(isInline: BooleanInput) {
    this.inline = coerceBooleanProperty(isInline);
    this.updateBadgeDisplay();
  }

  /** @deprecated [v4.1.0] The input property "bszBadgeLabel" is DEPRECATED. */
  @Input()
  get bszBadgeLabel(): string {
    return this.ariaLabel;
  }

  set bszBadgeLabel(label: string) {
    webcoat_deprecated(`[bsz-badge] The input property "bszBadgeLabel" is DEPRECATED since v4.1.0. Instead use alternative
    ways to provide meaningful meaning about the information conveyed by the badge. See more in the documentation about the
    component`);
    this.ariaLabel = label;
    this.setAriaLabel();
  }

  @Input() set maxValue(value: string | number) {
    this._maxValue = value ? Number(value) : MAX_VALUE;
  }

  @Input() set maxLength(value: string | number) {
    this._maxLength = value ? Number(value) : MAX_LENGTH;
  }

  private _maxValue: number = MAX_VALUE;
  private _maxLength: number = MAX_LENGTH;
  private badgeElement: HTMLElement | undefined;
  private color: BszBadgePalette = 'primary';
  private content: BszBadgeContent = null;
  private ariaLabel = '';
  private visible: boolean | undefined = undefined;
  private visibleByContent = true;
  private inline = true;

  constructor(private _elementRef: ElementRef<HTMLElement>, private _renderer: Renderer2) {}

  ngOnInit() {
    this.createBadge();
  }

  ngOnDestroy() {
    const badgeElement = this.badgeElement;

    // When creating a badge through the Renderer, Angular keeps it in an index.
    // We have to destroy it, otherwise it'll be retained in memory
    if (badgeElement && this._renderer.destroyNode) {
      this._renderer.destroyNode(badgeElement);
    }
  }

  private createBadge(): HTMLSpanElement {
    const badgeElement = this._renderer.createElement('span');

    if (this.ariaLabel) {
      this._renderer.setAttribute(badgeElement, 'aria-label', this.ariaLabel);
    }
    this._renderer.addClass(badgeElement, 'bsz-badge');
    this._renderer.appendChild(this._elementRef.nativeElement, badgeElement);

    this.badgeElement = badgeElement;
    this.setContent(this.content);
    this.setColor(this.color);
    return badgeElement;
  }

  private setContent(content: BszBadgeContent) {
    this.content = content;
    if (!this.badgeElement) {
      return;
    }
    this.updateBadgeText();
    this.updateBadgeVariant();
    this.updateBadgeVisibility();
    this.updateBadgeDisplay();
    this.setAriaLabel();
  }

  private updateBadgeText() {
    if (!this.badgeElement) {
      return;
    }

    this.badgeElement.textContent = this.hasContent() ? this.truncateContent(this.content) : '';
  }

  private updateBadgeVisibility() {
    this.visibleByContent = this.hasContent();
    const isVisible = typeof this.visible === 'boolean' ? this.visible : this.visibleByContent;

    if (isVisible) {
      this.badgeElement?.classList.remove(HIDDEN_CLASS);
    } else {
      this.badgeElement?.classList.add(HIDDEN_CLASS);
    }
  }

  private updateBadgeVariant() {
    if (!this.badgeElement) {
      return;
    }
    if (this.hasContent()) {
      this.badgeElement.classList.remove(DOT_VARIANT_CLASS);
    } else {
      this.badgeElement.classList.add(DOT_VARIANT_CLASS);
    }
  }

  private updateBadgeDisplay() {
    if (!this.badgeElement) {
      return;
    }
    if (this.inline) {
      this.badgeElement.classList.add(INLINE_CLASS);
      this.badgeElement.classList.remove(FLOAT_CLASS);
    } else {
      this.badgeElement.classList.add(FLOAT_CLASS);
      this.badgeElement.classList.remove(INLINE_CLASS);
    }
  }

  /** @deprecated [v4.1.0] remove this when removing the deprecated input property bszBadgeLabel */
  private setAriaLabel() {
    if (!this.badgeElement) {
      return;
    }
    const ariaLabel = `${this.ariaLabel} ${this.content}`;

    if (this.ariaLabel && this.hasContent()) {
      this.badgeElement.setAttribute('aria-label', ariaLabel);
    } else {
      this.badgeElement.removeAttribute('aria-label');
    }
  }

  private setColor(color: BszBadgePalette) {
    if (!color) {
      return;
    }
    if (this.badgeElement && this.badgeElement.classList) {
      this.badgeElement.classList.remove(`bsz-text-on-${this.color}`, `bsz-background-${this.color}`);
      this.badgeElement.classList.add(`bsz-text-on-${color}`, `bsz-background-${color}`);
      this.badgeElement.setAttribute('color', color);
    }
    this.color = color;
  }

  private hasContent() {
    return this.content != null && `${this.content}`.trim().length > 0;
  }

  /**
   * It gets the content and:
   *    - if it is valid number, positive and higher than maxValue, returns maxValue with the "+"
   *    - otherwise, and considering it as string:
   *        - if it has more characters than allowed, returns only the allowed with ellipsis
   *        - otherwise returns it as string
   */
  private truncateContent(content: BszBadgeContent): string {
    const valueAsText = String(content);
    const valueAsNumber = Number(content);
    if (!isNaN(valueAsNumber) && valueAsNumber > 0 && valueAsNumber > this._maxValue) {
      return `${this._maxValue}+`;
    }

    if (valueAsText.length > this._maxLength) {
      let truncatedText = valueAsText.substring(0, this._maxLength);

      // if last character is dot, it is removed
      if (truncatedText[truncatedText.length - 1] === '.') {
        truncatedText = valueAsText.substring(0, this._maxLength - 1);
      }
      return `${truncatedText}${UNICODE_ELLIPSIS}`;
    }

    return valueAsText;
  }
}
