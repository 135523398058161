import * as intents from '../../intents/intents.index';
import {DefaultNavigationIntentHandlerMap} from '../../navigation/default-intent-handlers.config';
import * as pages from '../../pages/pages.index';
import {DefaultIntentHandlerMapBuilder} from '../default-intent-handler-map.builder';

export function authJwtDefaultIntentHandlersFactory(): DefaultNavigationIntentHandlerMap {
  const builder = new DefaultIntentHandlerMapBuilder();
  defineHandlers(builder.addHandler);
  return builder.build();
}

function defineHandlers(addHandler: DefaultIntentHandlerMapBuilder['addHandler']): void {
  addHandler(intents.ShowAuthSigninIntent, () => new pages.AuthSigninPage());
  addHandler(intents.ShowAuthSignupIntent, () => new pages.AuthSignupPage());
  addHandler(intents.ShowAuthResetPasswordRequestIntent, () => new pages.AuthResetPasswordRequestPage());
}
