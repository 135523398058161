import {Type} from '@angular/core';

/**
 * Base class for a View with no payload.
 */
export abstract class ViewWithoutPayload {
  abstract readonly name: string;
}

/**
 * Base class for a View with a defined payload.
 */
export abstract class ViewWithPayload<TPayload> {
  abstract readonly name: string;

  constructor(readonly payload: TPayload) {}
}

/**
 * Union type for both types of views. Useful when we don't care about the specifics of the view.
 */
export type View = ViewWithoutPayload | ViewWithPayload<any>;

/**
 * Type guard for the passed view. Use this to "cast" the view to a specific type. An example:
 * ```
 * import {isView} from '@basuiz/mobile-bridge';
 *
 * import {ShowPaymentOverview, ShowPaymentDetail, ...} from '@basuiz/mobile-bridge-types';
 *
 * this.bszMobileBridgeService.instantiateView.subscribe((view) => {
 *   if (isView(view, ShowPaymentOverview)) {
 *     // show payment overview
 *   } else if (isView(view, ShowPaymentDetail)) {
 *     const paymentId = view.payload.paymentId; // type safe payload thanks to this type guard
 *     // show payment detail with the specified id
 *   } else { ... }
 * });
 * ```
 *
 * @param view The view returned from BszMobileBridgeService.instantiateView observable.
 * @param type The type of the view from the mobile bridge types library.
 */
export function isView<T extends View>(view: View, type: Type<T>): view is T {
  return view.name === new type().name;
}
