import {ActionWithoutResponse} from '@basuiz/mobile-bridge';

/**
 * Opens a document inside of the mobile application on top of the current app
 * content. User has ability to close the displayed document.
 * Supported file types for iOS platform:
 */
export class OpenInternalDocumentAction extends ActionWithoutResponse<OpenInternalDocumentRequest> {
  readonly name = 'openInternalDocument';
}

export interface OpenInternalDocumentRequest {
  /**
   * Absolute URL of the document that is going to be shown to the user. Make
   * sure that the URL has HTTPS scheme (HTTP is considered insecure).
   */
  url: string;

  /**
   * Localised name of the document that is displayed in navigation title.
   */
  documentName?: string;

  /**
   * Content type of the document.
   *
   * @default 'application/pdf'
   */
  fileContentType?: string;

  /**
   * Extension of the document.
   *
   * @default 'pdf'
   */
  fileExtension?: string;

  /**
   * Indicates if the content of the document contains confidential information
   * or not that requires user to be authenticated.
   *
   * @default true
   */
  hasConfidentialContent?: boolean;
}
