import {Inject, Injectable} from '@angular/core';
import {AbstractControl, ValidatorFn} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats} from '@angular/material/core';
import {DateFilterFn, DateRange} from '@angular/material/datepicker';

import {
  BszDateFilterValidationErrors,
  BszDateParseValidationErrors,
  BszDateRangeParseValidationErrors,
  BszMaxDateValidationErrors,
  BszMinDateValidationErrors,
  isDateRange,
} from './bsz-datepicker.definitions';

@Injectable()
export class BszDatepickerValidators<D> {
  constructor(
    private readonly dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private readonly dateFormats: MatDateFormats
  ) {}

  parseValidator(value: string): ValidatorFn {
    const validate = (): BszDateParseValidationErrors | null => {
      if (!value) {
        return null;
      }

      const dateParsed = this.dateAdapter.parse(value, this.dateFormats.parse.dateInput);

      const isValid = dateParsed && this.dateAdapter.isValid(dateParsed);

      return isValid ? null : {bszDatepickerParse: {value}};
    };

    // We don't immediately return a function to prevent error:
    // Metadata collected contains an error that will be reported at runtime: Lambda not supported.
    return validate;
  }

  rangeParseValidator(value: {startDate: string | null; endDate: string | null}): ValidatorFn {
    const validate = (): BszDateRangeParseValidationErrors | null => {
      if (!value || (!value.startDate && !value.endDate)) {
        return null;
      }

      const dateRange = this.parseDateRange(value);
      const startDateIsValid = this.isValidDate(dateRange.start);
      const endDateIsValid = this.isValidDate(dateRange.end);
      const isValidRange = this.dateRangeIsValid(dateRange);

      // Check for a valid range only when both star and end dates are set.
      const startEndDatesAreValidRange = () => {
        if (!startDateIsValid || !endDateIsValid) {
          return true;
        }
        return isValidRange;
      };

      // In general with form validators true means there is an error and null that there is not
      const validationError = {
        bszDatepickerRangeParse: {
          start: startDateIsValid ? null : true,
          end: endDateIsValid ? null : true,
          range: startEndDatesAreValidRange() ? null : true,
          value: Object.values(value).filter(Boolean).join('-'),
        },
      };

      return isValidRange ? null : validationError;
    };

    // We don't immediately return a function to prevent error:
    // Metadata collected contains an error that will be reported at runtime: Lambda not supported.
    return validate;
  }

  partialRangeParseValidator(value: {startDate: string | null; endDate: string | null}): ValidatorFn {
    const validate = (): BszDateRangeParseValidationErrors | null => {
      if (!value || (!value.startDate && !value.endDate)) {
        return null;
      }

      const dateRange = this.parseDateRange(value);
      const startDateIsValid = isNullOrEmptyString(value.startDate) || this.isValidDate(dateRange.start);
      const endDateIsValid = isNullOrEmptyString(value.endDate) || this.isValidDate(dateRange.end);
      const isValidRange = this.dateRangeIsValid(dateRange, true);

      // In general with form validators true means there is an error and null that there is not
      const validationError = {
        bszDatepickerRangeParse: {
          start: startDateIsValid ? null : true,
          end: endDateIsValid ? null : true,
          range: isValidRange ? null : true,
          value: Object.values(value).filter(Boolean).join('-'),
        },
      };

      const isValid = !Object.values(validationError.bszDatepickerRangeParse).some((prop) => prop === true);

      return isValid ? null : validationError;
    };
    // We don't immediately return a function to prevent error:
    // Metadata collected contains an error that will be reported at runtime: Lambda not supported.
    return validate;
  }

  minValidator(minDate: D | null): ValidatorFn {
    const validate = (control: AbstractControl): BszMinDateValidationErrors<D> | null => {
      // If the value is from a range then validate against the start date
      const dateToValidate = isDateRange(control.value) ? control.value.start : control.value;

      const controlValue = this.dateAdapter.getValidDateOrNull(this.dateAdapter.deserialize(dateToValidate));

      if (!minDate || !controlValue) {
        return null;
      }

      const controlValueIsEarlierThanMinDate = this.dateAdapter.compareDate(minDate, controlValue) <= 0;

      const validationError = {
        bszDatepickerMin: {
          min: minDate,
          minFormatted: this.dateAdapter.format(minDate, this.dateFormats.parse.dateInput),
          actual: controlValue,
          actualFormatted: this.dateAdapter.format(controlValue, this.dateFormats.parse.dateInput),
        },
      };

      return controlValueIsEarlierThanMinDate ? null : validationError;
    };
    // We don't immediately return a function to prevent error:
    // Metadata collected contains an error that will be reported at runtime: Lambda not supported.
    return validate;
  }

  maxValidator(maxDate: D | null): ValidatorFn {
    const validate = (control: AbstractControl): BszMaxDateValidationErrors<D> | null => {
      // If the value is from a range then validate against the start date
      const dateToValidate = isDateRange(control.value) ? control.value.end : control.value;
      const controlValue = this.dateAdapter.getValidDateOrNull(this.dateAdapter.deserialize(dateToValidate));

      if (!maxDate || !controlValue) {
        return null;
      }

      const controlValueIsLaterThanMaxDate = this.dateAdapter.compareDate(maxDate, controlValue) >= 0;

      const validationError = {
        bszDatepickerMax: {
          max: maxDate,
          maxFormatted: this.dateAdapter.format(maxDate, this.dateFormats.parse.dateInput),
          actual: controlValue,
          actualFormatted: this.dateAdapter.format(controlValue, this.dateFormats.parse.dateInput),
        },
      };

      return controlValueIsLaterThanMaxDate ? null : validationError;
    };

    // We don't immediately return a function to prevent error:
    // Metadata collected contains an error that will be reported at runtime: Lambda not supported.
    return validate;
  }

  filterValidator(filter: DateFilterFn<D | null> | null): ValidatorFn {
    const validate = (control: AbstractControl): BszDateFilterValidationErrors | null => {
      if (!control.value || !filter) {
        return null;
      }

      let isValidValue = true;
      if (isDateRange(control.value)) {
        const startDate = this.dateAdapter.getValidDateOrNull(this.dateAdapter.deserialize(control.value.start));
        const endDate = this.dateAdapter.getValidDateOrNull(this.dateAdapter.deserialize(control.value.end));

        isValidValue = filter(startDate) && filter(endDate);
      } else {
        const controlValue = this.dateAdapter.getValidDateOrNull(this.dateAdapter.deserialize(control.value));

        isValidValue = filter(controlValue);
      }

      return isValidValue ? null : {bszDatepickerFilter: true};
    };

    // We don't immediately return a function to prevent error:
    // Metadata collected contains an error that will be reported at runtime: Lambda not supported.
    return validate;
  }

  private parseDateRange(value: {startDate: string | null; endDate: string | null}): DateRange<D> {
    const startDateParsed = this.dateAdapter.parse(value.startDate?.trim(), this.dateFormats.parse.dateInput);
    const endDateParsed = this.dateAdapter.parse(value.endDate?.trim(), this.dateFormats.parse.dateInput);

    const startDate = startDateParsed && this.dateAdapter.isValid(startDateParsed) ? startDateParsed : null;
    const endDate = endDateParsed && this.dateAdapter.isValid(endDateParsed) ? endDateParsed : null;

    return new DateRange(startDate, endDate);
  }

  /**
   * Check whether a date range is valid. That means that both start and end dates are not empty
   * and that the start date is not later than the end date.
   *
   * @param {DateRange} dateRange the range object to check.
   * @param {boolean} partial If set to true then check that at least one date is set instead of checking both.
   */
  private dateRangeIsValid(dateRange: DateRange<D>, partial = false): boolean {
    let isValidRange = !!dateRange.start && !!dateRange.end;

    // for partial ranges it's allowed to have only one set.
    if (partial) {
      isValidRange = !!dateRange.start || !!dateRange.end;
    }

    if (dateRange.start && dateRange.end) {
      // start should be earlier than the end
      isValidRange = this.dateAdapter.compareDate(dateRange.start, dateRange.end) <= 0;
    }

    return isValidRange;
  }

  /**
   * Check whether a date object is a valid date.
   */
  private isValidDate(date: D | null): boolean {
    return date !== null && this.dateAdapter.isValid(date);
  }
}

function isNullOrEmptyString(value: unknown): boolean {
  return value === '' || value === null;
}
