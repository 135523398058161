<ng-container *ngIf="notificationActions.length > 0">
  <bsz-context-menu
    bszTestId="{{ bszTestIdPrefix }}.context-menu"
    *ngIf="appletConfig.notificationActions.displayAs === 'contextMenu'; else showActionsAsLinks"
  >
    <button
      bsz-context-menu-item
      *ngFor="let action of notificationActions"
      (click)="action.callback()"
      [disabled]="action.isDisabled$ | async"
      bszTestId="{{ bszTestIdPrefix }}.context-menu-item.{{ action.id }}"
    >
      {{ action.label | translate }}
    </button>
  </bsz-context-menu>

  <ng-template #showActionsAsLinks>
    <ng-container *ngFor="let action of notificationActions">
      <ng-container [ngSwitch]="action.id">
        <ng-container *ngSwitchCase="'viewAll'">
          <div
            class="bsz-body-1 bsz-text-accent bsz-justify-end bsz-cursor-pointer bsz-display-flex bsz-margin-b4"
            (click)="action.callback()"
            bszTestId="{{ bszTestIdPrefix }}.link.viewAll"
          >
            {{ action.label | translate }}
            <mat-icon class="notifications-list-view-all-icon" color="accent">chevron_right</mat-icon>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'markAllAsRead'">
          <div
            *ngIf="(action.isDisabled$ | async) === false"
            class="bsz-body-1 bsz-text-accent bsz-text-right bsz-cursor-pointer bsz-display-flex bsz-margin-b4"
            (click)="action.callback()"
            bszTestId="{{ bszTestIdPrefix }}.link.markAllAsRead"
          >
            {{ action.label | translate }}
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-template>
</ng-container>
