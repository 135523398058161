import {NgModule} from '@angular/core';
import {QuickLinkContainerComponent} from './quick-link-container.component';
import {QuickLinkComponent} from './quick-link/quick-link.component';
import {PortalBasicModule} from '../../util/portal-basic.module';

@NgModule({
  imports: [PortalBasicModule],
  declarations: [QuickLinkContainerComponent, QuickLinkComponent],
  exports: [QuickLinkContainerComponent, QuickLinkComponent],
})
export class QuickLinkModule {}
