import {ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {NotificationMessageStatus, NotificationsFacade} from '@basuiz/web-app-self-service-notifications';
import {BszAttentionItemCounterType, BszNotification} from '@basuiz/web-app-applet-api';
import {combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';
import {WebAppAlertFacade} from '@basuiz/web-app-alert';

@Component({
  selector: 'bsz-alert-list',
  templateUrl: './alert-list.component.html',
  styleUrls: ['./alert-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertListComponent implements OnInit, OnDestroy {
  @Output() notificationClicked: EventEmitter<BszNotification> = new EventEmitter();
  @Output() showAllNotifications: EventEmitter<void> = new EventEmitter();
  @Output() markAllNotificationsAsRead: EventEmitter<void> = new EventEmitter();
  @Output() counterClicked: EventEmitter<BszAttentionItemCounterType> = new EventEmitter();

  loaded$ = combineLatest([
    this.notificationsFacade.notificationsLoaded$,
    this.webAppAlertFacade.attentionItemCountersLoaded$,
  ]).pipe(map((loadedList) => loadedList.every((loaded) => !!loaded)));

  loading$ = combineLatest([
    this.notificationsFacade.notificationsLoading$,
    this.webAppAlertFacade.attentionItemCountersLoading$,
  ]).pipe(map((loadingList) => loadingList.some((loading) => !!loading)));

  error$ = combineLatest([
    this.notificationsFacade.notificationsError$,
    this.webAppAlertFacade.attentionItemCountersError$,
  ]).pipe(map((errorList) => errorList.some((error) => !!error)));

  statusList = NotificationMessageStatus;

  readonly bszTestIdPrefix = 'web-app-alert-list-applet';

  constructor(private notificationsFacade: NotificationsFacade, private webAppAlertFacade: WebAppAlertFacade) {}

  ngOnInit(): void {
    this.webAppAlertFacade.requestStartPolling();
  }

  ngOnDestroy() {
    this.webAppAlertFacade.requestStopPolling();
  }
}
