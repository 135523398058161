import {PortalConfigHybridOverrides, PortalConfigHybridOverridesFactoryWithDeps} from './get-portal-config-for-hybrid';

export function getBaseOverrideFactoryWithDeps(
  factoryWithDeps: PortalConfigHybridOverridesFactoryWithDeps | undefined
): BaseOverrideFactoryWithDeps {
  return {
    baseOverrideFactory: factoryWithDeps?.useFactory,
    baseOverrideDeps: factoryWithDeps?.deps ?? [],
  };
}

export interface BaseOverrideFactoryWithDeps {
  baseOverrideFactory: ((...deps: unknown[]) => PortalConfigHybridOverrides) | undefined;
  baseOverrideDeps: unknown[];
}
