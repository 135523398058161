import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {BszI18nModule} from '@basuiz/i18n';

import {BszContextMenu} from './bsz-context-menu';
import {BszContextMenuItem} from './bsz-context-menu-item';

@NgModule({
  imports: [CommonModule, MatIconModule, MatButtonModule, MatMenuModule, MatRippleModule, BszI18nModule],
  exports: [BszContextMenu, BszContextMenuItem],
  declarations: [BszContextMenu, BszContextMenuItem],
  providers: [],
})
export class BszContextMenuModule {}
