import {ActionWithResponse} from '@basuiz/mobile-bridge';

/**
 * Action provides ability to sign a transaction in native application. It is
 * used by transation signing library under the hood. This means that end hybrid
 * applets do not need ever to care about invoking this action.
 */
export class TransactionSigningAction extends ActionWithResponse<
  TransactionSigningRequest,
  TransactionSigningResponse
> {
  readonly name = 'transactionSigningRequest';
}

export interface TransactionSigningRequest {
  /**
   * Field from TransactionSigningRequestPTO.
   */
  transactionSigningObjectType: string;

  /**
   * Field from TransactionSigningRequestPTO/DTO.
   */
  messageKey?: string;

  /**
   * Field from TransactionSigningRequestPTO/DTO.
   */
  image?: string;

  /**
   * Field from TransactionSigningRequestPTO/DTO.
   */
  redirectURL?: string;

  /**
   * Field from TransactionSigningRequestPTO/DTO.
   */
  operation: string;

  /**
   * Field from TransactionSigningRequestPTO/DTO.
   */
  versionId: number;

  /**
   * Field from TransactionSigningRequestPTO/DTO.
   */
  id: string;

  /**
   * Details of transaction that are visualised in case of external transaction
   * sigining.
   */
  displaySummary?: string;
}

export interface TransactionSigningResponse {
  /**
   * Indicaion if transaction signing request was successful.
   */
  verification: 'SUCCESS' | 'FAILURE' | 'CANCEL';

  /**
   * Indication if verify transaction call returned CANCELLED_BY_USER.
   *
   * @default false
   */
  isPushSessionCancelledByUser?: boolean;
}
