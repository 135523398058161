import {DebugElement} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {DataSource} from '@angular/cdk/collections';

import {BszTable} from '@basuiz/ui-elements';
import {BszTableTreeDataSource} from '@basuiz/ui-elements/lib/bsz-table/bsz-table-tree-data-source';

import {JestFixtureFacade, JestFixtureFacadeAware} from './jest-fixture-facade';
import {BszTable as BszTableInterface} from '../generic/components';

export class JestBszTable implements JestFixtureFacadeAware, BszTableInterface {
  constructor(public testId: string, public jestFixtureFacade: JestFixtureFacade) {}

  get dataSource(): BszTableTreeDataSource<any, any> | MatTableDataSource<any> | DataSource<any> | undefined {
    return this.bszTable?.tableDataSource;
  }

  get displayedColumns(): string[] | undefined {
    return this.bszTable?.columns;
  }

  get sortableColumns(): HTMLTableCellElement[] {
    return Array.from(this.bszTableElement.nativeElement.querySelectorAll('th.mat-sort-header'));
  }

  get bodyRows(): HTMLTableRowElement[] {
    return this.bszTableElement.nativeElement.querySelectorAll('tbody tr');
  }

  get groupHeaderRows(): HTMLTableRowElement[] {
    return this.bszTableElement.nativeElement.querySelectorAll('tbody tr.bsz-group-header-row');
  }

  private get bszTable(): BszTable<any, any> | undefined {
    return this.bszTableElement?.componentInstance as BszTable<any, any>;
  }

  private get bszTableElement(): DebugElement {
    const bszTableElement: DebugElement = this.jestFixtureFacade.debugElement.query((element: DebugElement) => {
      return element.nativeElement.getAttribute('test-id') === this.testId;
    });
    return bszTableElement;
  }
}
