import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UINotificationSnackBarService} from './ui-notification-snack-bar.service';
import {MatSnackBarModule} from '@angular/material/snack-bar';

@NgModule({
  imports: [CommonModule, MatSnackBarModule],
  providers: [UINotificationSnackBarService],
})
export class UINotificationSnackBarModule {}
