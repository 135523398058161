import {EMPTY, merge, Observable, of} from 'rxjs';
import {catchError, filter, map} from 'rxjs/operators';
import {DataState, DataStateError, DataStateLoading} from './data-state.definition';
import {
  DataStateLoadingOptions,
  DataStateNoDataOptions,
  getDataStateAvailable,
  getDataStateError,
  getDataStateLoading,
  getDataStateNoData,
} from './data-state.builders';

type HasData<T> = (data: T | undefined) => boolean;

export interface ToDataStateOptions<T> {
  hasData?: HasData<T>;
  /* Values other than `true` are ignored, i.e the state will not change because a false value was emitted,
   * instead to change the state, e.g. to 'available', new data must be emitted from the source observable. */
  loading$?: Observable<boolean>;
  /* Values other than `true` are ignored, i.e the state will not change because a false value was emitted,
   * instead to change the state, e.g. to 'available', new data must be emitted from the source observable. */
  error$?: Observable<boolean>;
  dataUnavailable?: {
    loading?: DataStateLoadingOptions;
    noData?: DataStateNoDataOptions;
  };
}

export function toDataState<T>(
  options?: ToDataStateOptions<T>
): (dataSource$: Observable<T | Error>) => Observable<DataState<T>> {
  return (dataSource$) => {
    const defaultHasData: HasData<T> = (data) =>
      data !== undefined && data !== null && !(Array.isArray(data) && data.length === 0);
    const hasData: HasData<T> = options?.hasData ?? defaultHasData;

    const loading$: Observable<DataStateLoading> = (options?.loading$ ?? of(true)).pipe(
      filter((value) => !!value),
      map(() => getDataStateLoading(options?.dataUnavailable?.loading))
    );

    const error$: Observable<DataStateError> = (options?.error$ ?? EMPTY).pipe(
      filter((value) => !!value),
      map(() => getDataStateError())
    );

    const handleError = (error: Error) => {
      console.error(error);
      return getDataStateError();
    };

    return merge(
      loading$,
      error$,
      dataSource$.pipe(
        map((data) =>
          data instanceof Error
            ? handleError(data)
            : hasData(data)
            ? getDataStateAvailable(data)
            : getDataStateNoData(options?.dataUnavailable?.noData)
        ),
        catchError((error) => of(handleError(error)))
      )
    );
  };
}
