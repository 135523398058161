import {ConfigurableFocusTrap, ConfigurableFocusTrapFactory} from '@angular/cdk/a11y';
import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {DOCUMENT} from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {BszBannerBase, BszBannerModalMode} from './bsz-banner-base';

const activeBodyClass = 'bsz-global-banner-modal-active';

@Component({
  selector: 'bsz-global-banner',
  styleUrls: ['./bsz-banner.scss'],
  templateUrl: './bsz-banner-base.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.bsz-global-banner-modal]': '_modal',
  },
})
export class BszGlobalBanner extends BszBannerBase implements AfterContentInit, AfterViewInit, OnDestroy {
  @Input()
  set modal(modal: BszBannerModalMode | '') {
    this._modal = modal || 'push';
    this._role = 'dialog';
    this.elementRef.nativeElement.setAttribute('hidden', 'hidden');
  }

  // required for a11y reasons in the element that gets the focus when the banner is displayed
  @Input('aria-label') override ariaLabel: string | null = null;

  @ViewChild('bannerComponentTpl') bannerComponentTpl: TemplateRef<HTMLElement> | null = null;

  private overlayRef: OverlayRef | null = null;
  private focusTrap: ConfigurableFocusTrap | null = null;
  private bodyPaddingTop = '';

  constructor(
    protected override readonly translate: TranslateService,
    protected override readonly cd: ChangeDetectorRef,
    protected override readonly elementRef: ElementRef,
    private readonly overlay: Overlay,
    private readonly viewContainerRef: ViewContainerRef,
    protected focusTrapFactory: ConfigurableFocusTrapFactory,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    super(translate, cd, elementRef);
  }

  ngAfterContentInit() {
    this.title = null;
    this._bannerClass = `bsz-global-banner bsz-background-${this.type}`;
  }

  override ngAfterViewInit() {
    if (this._modal) {
      this.addOverlay();

      // the timeout is required because in some cases, when it is shown, it goes before than the function
      // that sets the multiline styling, so there is a quick blink/movement in the content
      setTimeout(() => {
        if (!this.overlayRef) {
          return;
        }
        this._bannerContainer = this.overlayRef.hostElement;
        this._bannerClass += ` mat-elevation-z4`;
        this._setMultiline();
      });
    }
  }

  ngOnDestroy(): void {
    this.removeOverlay();
  }

  // same as the one in the banner base but adding the modal feature
  override show(): void {
    if (this.isVisible) {
      return;
    }
    this.isVisible = true;
    if (this._modal) {
      this.addOverlay();
    }
    this.cd.markForCheck();
  }

  // same as the one in the banner base but removing the modal feature
  override dismiss(): void {
    if (!this.isVisible) {
      return;
    }
    this.isVisible = false;
    this.closed.emit();
    this.cd.markForCheck();
    this.removeOverlay();
  }

  /** @private */
  override _onResize() {
    this._setMultiline();
    if (this._modal === 'push') {
      this.adaptBodyPaddingToBanner();
    }
  }

  private addOverlay(): void {
    if (!this.bannerComponentTpl) {
      throw new Error('[bsz-global-banner]: Could not load banner template');
    }
    const templatePortal = new TemplatePortal(this.bannerComponentTpl, this.viewContainerRef);

    this.overlayRef = this.overlay.create(this.getOverlayConfig());
    this.overlayRef.attach(templatePortal);

    this.bodyPaddingTop = this.document.body.style.paddingTop;
    this.adaptBodyPaddingToBanner();
    this.addBodyActiveClass();

    const container = this.overlayRef.hostElement.querySelector('.bsz-banner') as HTMLElement;
    container.focus();
    this.focusTrap = this.focusTrapFactory.create(container);
  }

  private removeOverlay(): void {
    this.setBodyPaddingTop(this.bodyPaddingTop);
    this.removeBodyActiveClass();
    this.focusTrap?.destroy();
    this.overlayRef?.dispose();
  }

  private getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay.position().global().top('0px').left('0px');

    return {
      positionStrategy: positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      backdropClass: ['bsz-global-banner-backdrop', 'cdk-overlay-dark-backdrop'],
      hasBackdrop: true,
      width: '100%',
      maxWidth: '100%',
      disposeOnNavigation: false,
    };
  }

  private adaptBodyPaddingToBanner(): void {
    if (this._modal === 'over' || !this.overlayRef) {
      return;
    }
    const container = this.overlayRef.overlayElement.querySelector('.bsz-banner') as HTMLElement;

    this.setBodyPaddingTop(`${container.offsetHeight}px`);
  }

  private setBodyPaddingTop(padding: string): void {
    this.document.body.style.paddingTop = padding;
  }

  private addBodyActiveClass(): void {
    this.document.body.classList.add(activeBodyClass);
  }

  private removeBodyActiveClass(): void {
    this.document.body.classList.remove(activeBodyClass);
  }
}
