import {PortalPage} from '../navigation/classes/portal-page';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslationKeyWithParams} from '../navigation/translation-key-with-params';
import {TranslationKey} from '@basuiz/web-app-applet-api';
import {CollaborationOverviewPage} from './collaboration-overview.page';
import {BszCollaborationIssueType} from '@basuiz/web-app-applet-api';

export interface CollaborationIssueCreationPagePayload {
  collaborationIssueType: BszCollaborationIssueType;
}

export class CollaborationIssueCreationPage extends PortalPage {
  constructor(public payload: CollaborationIssueCreationPagePayload = {collaborationIssueType: 'MESSAGING'}) {
    super();
  }

  get parentPortalPage(): PortalPage | null {
    return new CollaborationOverviewPage();
  }

  get breadcrumbText(): TranslationKey | TranslationKeyWithParams {
    return asTranslationKey('web-app-portal.pages.collaboration-issue-creation.breadcrumb');
  }

  get breadcrumbTestId(): string {
    return 'web-app-portal.pages.collaboration-issue-creation.breadcrumb';
  }

  get titleText(): TranslationKey {
    return asTranslationKey('web-app-portal.pages.collaboration-issue-creation.window-title');
  }

  static readonly routerConfigPath = 'collaboration/create-issue';
  get route(): Array<string> {
    return [`/${CollaborationIssueCreationPage.routerConfigPath}`];
  }
}
