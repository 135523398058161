import {ExpandedFeatureToggle, isDisabled} from '@basuiz/web-app-feature-toggle';
import {PortalPageClass} from '../navigation/classes/portal-page';
import {
  AddressChangePage,
  CollaborationOverviewPage,
  ContactDetailsUpdatePage,
  InvestmentProposalDetailPage,
  InvestmentProposalOverviewPage,
  InvestmentProposalRequestChangesPage,
  NotificationsPage,
  ShipmentEntryPage,
  PaymentCardsPage,
  PaymentMethodWalletOverviewPage,
  PostalAddressUpdatePage,
  ShipmentAddressOverviewPage,
  ShipmentAddressEntryPage,
  ShipmentOverviewPage,
  ManifestOverviewPage,
  ManifestEntryPage,
} from '../pages/pages.index';
import {UserRoleService} from '@basuiz/web-app-applet-sdk';

export function getDefaultDisabledPages(
  featureToggle: ExpandedFeatureToggle,
  userRoleService: UserRoleService
): Array<PortalPageClass> {
  const investmentProposalPages: Array<PortalPageClass> = [
    InvestmentProposalDetailPage,
    InvestmentProposalOverviewPage,
    InvestmentProposalRequestChangesPage,
  ];

  const shipmentPages: Array<PortalPageClass> = [
    ManifestEntryPage,
    ManifestOverviewPage,
    ShipmentEntryPage,
    ShipmentAddressEntryPage,
    ShipmentAddressOverviewPage,
    ShipmentOverviewPage,
  ];
  const paymentMethodPages: Array<PortalPageClass> = [PaymentMethodWalletOverviewPage];

  return [
    ...(isDisabled(featureToggle.investmentProposals) ? investmentProposalPages : []),
    ...(isDisabled(featureToggle.paymentCards) ? [PaymentCardsPage] : []),
    ...(isDisabled(featureToggle.userProfile?.postalAddress) ? [PostalAddressUpdatePage, AddressChangePage] : []),
    ...(isDisabled(featureToggle.userProfile?.contactDetails) ? [ContactDetailsUpdatePage] : []),
    ...(isDisabled(featureToggle.collaboration) ? [CollaborationOverviewPage] : []),
    ...(isDisabled(featureToggle.notifications) ? [NotificationsPage] : []),
    ...(isDisabled(featureToggle.shipment) ? shipmentPages : []),
    ...(isDisabled(featureToggle.paymentMethod) ? paymentMethodPages : []),
  ].filter((p) => p !== undefined);
}
