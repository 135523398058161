import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {BszDesktopContent, BszMobileContent} from './bsz-screen-size-content-switcher';

@NgModule({
  declarations: [BszDesktopContent, BszMobileContent],
  imports: [CommonModule],
  exports: [BszDesktopContent, BszMobileContent],
})
export class BszScreenSizeContentSwitcherModule {}
