import {BszConfigFactoryWithDeps} from '@basuiz/web-app-applet-api';
import {BszFeatureToggle, PartialFeatureToggle, patchFeatureToggle} from '@basuiz/web-app-feature-toggle';
import {WEB_APP_DEV_TOOLS_FEATURE_TOGGLE} from '@basuiz/web-app-dev-tools';

export function getFeatureToggleFactoryWithDeps(): BszConfigFactoryWithDeps<BszFeatureToggle> {
  return {
    useFactory: (defaultFeatureToggle: BszFeatureToggle, devToolsFeatureToggle: BszFeatureToggle | undefined) => {
      /* Takes the feature toggle edited in the dev-tools if available, otherwise the default one */
      if (devToolsFeatureToggle) {
        return devToolsFeatureToggle;
      } else {
        const partial: PartialFeatureToggle = {
          globalFilters: 'disabled',
          investmentProposals: 'disabled',
          paymentCards: 'disabled',
          userProfile: 'disabled',
          notifications: 'disabled',
          collaboration: 'disabled',
          shipment: 'enabled',
          paymentMethod: {
            wallet: 'enabled',
            ecPayphone: 'enabled',
          },
        };
        return patchFeatureToggle(defaultFeatureToggle, partial);
      }
    },
    deps: [WEB_APP_DEV_TOOLS_FEATURE_TOGGLE],
  };
}
