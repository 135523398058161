<h1 mat-dialog-title class="bsz-title-6">{{ data.title | translate }}</h1>
<mat-dialog-content class="bsz-body-1 bsz-margin-b4">{{ data.content | translate }}</mat-dialog-content>
<mat-dialog-actions class="bsz-dialog-actions" align="end">
  <button mat-button color="accent" (click)="closeDialog()" bszTestId="bsz-confirmation-modal-close-dialog-button">
    <ng-container *ngIf="data.cancelAction; else defaultCancelAction">
      {{ data.cancelAction | translate }}</ng-container
    >
    <ng-template #defaultCancelAction> {{ 'web-app-common.confirmation-modal.cancel.button' | translate }}</ng-template>
  </button>
  <button
    mat-flat-button
    [color]="confirmationButtonColor"
    cdkFocusInitial
    (click)="confirmAction()"
    bszTestId="bsz-confirmation-modal-confirm-action-button"
  >
    {{ data.action | translate }}
  </button>
</mat-dialog-actions>
