import {BszFeatureToggle} from './models/features.definition';

export function getAllEnabledFeatureToggle(): BszFeatureToggle {
  return {
    globalFilters: 'enabled',
    investmentProposals: 'enabled',
    paymentCards: {
      debitCards: 'enabled',
    },
    userProfile: {
      contactDetails: 'enabled',
      postalAddress: 'enabled',
    },
    collaboration: 'enabled',
    notifications: 'enabled',
    shipment: 'enabled',
    paymentMethod: {
      wallet: 'enabled',
      ecPayphone: 'enabled',
    },
  };
}
