import {Injectable} from '@angular/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class MatPaginatorLabels extends MatPaginatorIntl {
  constructor(private translate: TranslateService) {
    super();
  }

  override itemsPerPageLabel = this.translate.instant('ui-elements.mat-paginator.accessibility.items-per-page');
  override nextPageLabel = this.translate.instant('ui-elements.mat-paginator.accessibility.next-page');
  override previousPageLabel = this.translate.instant('ui-elements.mat-paginator.accessibility.previous-page');
  override firstPageLabel = this.translate.instant('ui-elements.mat-paginator.accessibility.first-page');
  override lastPageLabel = this.translate.instant('ui-elements.mat-paginator.accessibility.last-page');

  override getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return this.translate.instant('ui-elements.mat-paginator.accessibility.page-range-empty', {length});
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return this.translate.instant('ui-elements.mat-paginator.accessibility.page-range', {
      startIndex: startIndex + 1,
      endIndex: endIndex,
      length: length,
    });
  };
}
