<!-- disable animation until bug of blinking content https://github.com/angular/components/pull/11778 is fixed -->
<div class="bsz-padding-b4" *ngIf="groupedDetailsObject.details?.length as detailsSize" [@.disabled]="true">
  <mat-expansion-panel [expanded]="detailsSize <= pageSize">
    <mat-expansion-panel-header>
      <mat-panel-title class="bsz-subtitle-2">
        {{ groupedDetailsObject.title | translate }}
      </mat-panel-title>
      <mat-panel-description class="bsz-text-right bsz-body-1">
        {{
          'web-app-common.auth.representation.collective.group.counter.desktop' | translate: {count: detailsSize}
        }}
      </mat-panel-description>
    </mat-expansion-panel-header>

    <bsz-table matSort [dataSource]="dataSource" [columns]="groupedDetailsObject.labels">
      <ng-container *ngFor="let label of groupedDetailsObject.labels; let index = index">
        <bsz-table-column [name]="label">
          <th
            bsz-header-cell
            *bszHeaderCellDef
            mat-sort-header="{{ index.toString() }}"
            class="bsz-text-secondary bsz-padding-4"
            >{{ label | translate }}</th
          >
          <td bsz-cell *bszCellDef="let details" class="bsz-body-1 bsz-padding-4">
            {{ details[index] }}
          </td>
        </bsz-table-column>
      </ng-container>
    </bsz-table>

    <ng-container *ngIf="detailsSize > pageSize">
      <mat-paginator [pageSize]="pageSize" [hidePageSize]="true"></mat-paginator>
    </ng-container>
  </mat-expansion-panel>
</div>
