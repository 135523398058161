import {PortalConfig} from './portal.config.definition';

// IMPORTANT: do not modify the signature of this function
export function validatePortalConfig(config: PortalConfig): string | undefined {
  let errors: string | undefined;
  function addError(newError: string) {
    errors = errors ? `${errors}\n${newError}` : newError;
  }

  Object.entries(config.pageLayout.breadcrumbs).forEach(([screenSize, breadcrumb]) => {
    if (breadcrumb.style === 'history' && breadcrumb.maxBreadcrumbs < 2) {
      addError(
        `Breadcrumb for screen size ${screenSize} with style ${breadcrumb.style} has property 'maxBreadcrumbs' equal to ${breadcrumb.maxBreadcrumbs} but the minimum value is 2`
      );
    }
  });

  const {maxPageWidthInPx, mainNavWidthInPx, mainNavModeBufferInPx} = config.portalLayout;
  if (maxPageWidthInPx <= 0 || !Number.isInteger(maxPageWidthInPx)) {
    addError(`Property 'portalLayout.maxPageWidthInPx' must be a positive integer but is '${maxPageWidthInPx}'`);
  }
  if (mainNavWidthInPx <= 0 || !Number.isInteger(mainNavWidthInPx)) {
    addError(`Property 'portalLayout.mainNavWidthInPx' must be a positive integer but is '${mainNavWidthInPx}'`);
  }
  if (mainNavModeBufferInPx <= 0 || !Number.isInteger(mainNavModeBufferInPx)) {
    addError(
      `Property 'portalLayout.mainNavModeBufferInPx' must be a positive integer but is '${mainNavModeBufferInPx}'`
    );
  }

  Object.entries(config.navigation.mainNav.regulatoryLinks).forEach(([regulation, link]) => {
    if (link && !link.match(/^https:\/\//i)) {
      addError(`navigation.mainNav.regulatoryLinks.${regulation} must define an HTTPS URL: ${link}`);
    }
  });

  return errors;
}
