import {InjectionToken} from '@angular/core';

/** A handler application developers can provide and that @basuiz/web-app will call if it detects
 *  that the application is in an unstable state.
 *  @param trigger: an indication of the event that left @basuiz/web-app in an unstable state
 *  */
export type WebAppRebootHandler = (trigger: {
  event:
    | 'userSettingsUpdated'
    | 'globalFilterUpdated'
    | 'localeUpdated'
    | 'portalHistoryBackOnFirstNavigation'
    | 'devToolsUpdate';
}) => void;

/** Injection token providing a handler that @basuiz/web-app calls whenever it knowingly becomes
 *  in an "unstable" state. In such cases a reboot of the Angular instance running web-app is required.
 *  Examples of cases where @basuiz/web-app are when some user settings are changed, when global filters change.
 *  @basuiz/web-app will call this handler when it is not capable of updating its state to be consistent with the
 *  recent changes.
 *
 *  @default: `() => location.reload()` the default handler will reload the browser window in the current location
 *  */
export const WEB_APP_REBOOT_HANDLER: InjectionToken<WebAppRebootHandler> = new InjectionToken<WebAppRebootHandler>(
  'bsz.web-app-common.reboot-handler',
  {
    // eslint-disable-next-line no-restricted-syntax
    factory: () => () => location.reload(),
  }
);
