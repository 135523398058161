import {ChangeDetectionStrategy, Component, forwardRef, OnInit} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import {Observable} from 'rxjs';
import {MatOptionSelectionChange} from '@angular/material/core';
import {WebAppCommonFacade} from '../base-data/web-app-common.facade';
import {BusinessUnit} from '../base-data/models/business-unit.definition';

@Component({
  selector: 'bsz-business-unit-selector',
  templateUrl: './bsz-business-unit-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BszBusinessUnitSelectorComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BszBusinessUnitSelectorComponent),
      multi: true,
    },
  ],
})
export class BszBusinessUnitSelectorComponent implements OnInit, ControlValueAccessor {
  selectedBusinessUnitChanged: any;

  public businessUnitForm: UntypedFormGroup;
  public businessUnitControl: UntypedFormControl;
  public businessUnitList$: Observable<BusinessUnit[]> = this.webAppCommonFacade.businessUnitList$;

  constructor(private webAppCommonFacade: WebAppCommonFacade) {}

  onValidatorChange = () => {};

  ngOnInit(): void {
    this.webAppCommonFacade.loadBusinessUnits();
    this.businessUnitControl = new UntypedFormControl();
    this.businessUnitForm = new UntypedFormGroup({
      businessUnit: this.businessUnitControl,
    });
    this.onValidatorChange();
  }

  onChange(matOptionSelectionChange: MatOptionSelectionChange) {
    this.selectedBusinessUnitChanged({
      id: matOptionSelectionChange.source.value.id,
      name: matOptionSelectionChange.source.value.name,
    });
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.businessUnitForm.invalid ? {businessUnitInvalid: true} : null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  registerOnChange(fn: any): void {
    this.selectedBusinessUnitChanged = fn;
  }

  registerOnTouched(fn: any): void {
    // do nothing
  }

  writeValue(value: any): void {
    if (value === null) {
      this.businessUnitForm.reset();
    } else {
      this.businessUnitControl?.setValue(value);
    }
  }
}
