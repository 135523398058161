import {DataSource} from '@angular/cdk/table';
import {MatTableDataSource} from '@angular/material/table';
import {Observable} from 'rxjs';

import {BszTreeRowDefinition} from '../shared';
import {BszTableTreeDataSource} from './bsz-table-tree-data-source';

/** Re-export these for backwards compatibility. */
export {GroupHeader, isGroupHeader, isTreeRowDefinitionCollection} from '../shared';

/** Union of the types that can be set as the data source for the `BszTable` */
export type BszTableDataSourceInput<T, G = never> =
  | BszTreeRowDefinition<T, G>[]
  | Observable<readonly T[]>
  | DataSource<T>
  | readonly T[];

/** Union types for implementations of DataSource<T> for `BszTable` */
export type BszTableDataSourceType<T, G> = (BszTableTreeDataSource<T, G> & MatTableDataSource<T>) | DataSource<T>;

/**
 * For internal usage, describes the data BszTableRowDefinition
 * with additional metadata.
 */
export interface BszTableTreeRow {
  readonly _ancestorRow?: BszTableTreeRow;
  readonly _nestingLevel: number;
  readonly _isGroupHeader: boolean;
  _children: BszTableTreeRow[]; // this contains all descendants (including nested ones, not just direct children)
  _collapsed: boolean;
  _originalRow: any; // Reference to the original row object.
  _groupId?: string;

  [property: string]: any;
}

/**
 * Alias for tree row definition.
 */
export type BszTableTreeRowDefinition<T, G> = BszTreeRowDefinition<T, G>;

/**
 * Three-state Boolean indicating:
 * - true: all collapsed
 * - null: mixed
 * - false: all expanded
 */
export type CollapsedState = true | null | false;

/** Manual type guard to validate against the BszTableTreeRow interface */
export function isBszTableTreeRow(obj: any): obj is BszTableTreeRow {
  return typeof obj._nestingLevel === 'number' && typeof obj._isGroupHeader === 'boolean';
}

/** Manual type guard to validate against the BszTableTreeRow data set */
export function isBszTableTreeRowData(obj: any): obj is BszTableTreeRow[] {
  return Array.isArray(obj) && obj.length > 0 && isBszTableTreeRow(obj[0]);
}
