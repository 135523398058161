// IMPORTANT: do not modify this file

import {inject, InjectionToken, FactoryProvider} from '@angular/core';
import {BszConfigFactory, BszConfigFactoryWithDeps} from '@basuiz/web-app-applet-api';
import {CollaborationOverviewPageConfig} from './collaboration-overview-page.config.definition';
import {getDefaultConfig} from './collaboration-overview-page.config.default';
import {validateCollaborationOverviewPageConfig} from './collaboration-overview-page.config.validation';

// IMPORTANT: do not modify this file

export const ɵCOLLABORATION_OVERVIEW_PAGE_CONFIG = new InjectionToken<CollaborationOverviewPageConfig>(
  'bsz.web-app.portal.collaboration-overview-page-config',
  {
    factory: () => inject(COLLABORATION_OVERVIEW_PAGE_CONFIG_DEFAULT),
  }
);

// IMPORTANT: do not modify this file

export const COLLABORATION_OVERVIEW_PAGE_CONFIG_DEFAULT = new InjectionToken<CollaborationOverviewPageConfig>(
  'bsz.web-app.portal.collaboration-overview-page-config.default',
  {
    factory: () => {
      const config = getDefaultConfig();
      const error = validateCollaborationOverviewPageConfig(config);
      if (error) {
        console.error('Default CollaborationOverviewPageConfig validation failed:', error);
      }
      return config;
    },
  }
);

// IMPORTANT: do not modify this file

export function collaborationOverviewPageConfigProvider({
  useFactory,
  deps,
}: BszConfigFactoryWithDeps<CollaborationOverviewPageConfig>): FactoryProvider {
  return {
    provide: ɵCOLLABORATION_OVERVIEW_PAGE_CONFIG,
    useFactory: factoryWithValidation(useFactory),
    deps: [COLLABORATION_OVERVIEW_PAGE_CONFIG_DEFAULT, ...(deps || [])],
  };
}

// IMPORTANT: do not modify this file

function factoryWithValidation(
  factory: BszConfigFactory<CollaborationOverviewPageConfig>
): BszConfigFactory<CollaborationOverviewPageConfig> {
  return (defaultConfig: CollaborationOverviewPageConfig, ...deps: unknown[]) => {
    const config = factory(defaultConfig, ...deps);
    const error = validateCollaborationOverviewPageConfig(config);
    if (error) {
      console.error('CollaborationOverviewPageConfig validation failed:', error);
    }
    return config;
  };
}

// IMPORTANT: do not modify this file
