import {Directive, ElementRef, Input, OnChanges} from '@angular/core';
import {TestIdAware} from './generic/components';

@Directive({
  selector: '[bszTestId]',
})
export class TestIdDirective implements OnChanges {
  @Input('bszTestId')
  testIdRepresentation: TestIdAware | string;

  constructor(private el: ElementRef) {}

  ngOnChanges(): void {
    const testId = isTestIdAware(this.testIdRepresentation)
      ? this.testIdRepresentation.testId
      : this.testIdRepresentation;

    TestIdDirective.checkTestId(testId, this.el);
    this.el.nativeElement.setAttribute('test-id', testId);
  }

  private static checkTestId(testId: string, el: ElementRef) {
    if (!testId) {
      console.warn('Test id empty on element.', el);
    }
  }
}

function isTestIdAware(testIdRepresentation: TestIdAware | string): testIdRepresentation is TestIdAware {
  return (testIdRepresentation as TestIdAware).testId !== undefined;
}
