import {Directive, ElementRef, Host, HostListener, OnDestroy, OnInit, Optional} from '@angular/core';
import {MatCheckbox} from '@angular/material/checkbox';
import {ThemePalette} from '@angular/material/core';
import {MatRadioButton} from '@angular/material/radio';
import {AsyncSubject, BehaviorSubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

const LOG_PREFIX = '[bsz-card-select-control]';

@Directive({
  selector: '[bsz-card-select-control]',
  host: {
    class: 'bsz-card-select-control',
  },
})
export class BszCardSelectControl implements OnInit, OnDestroy {
  /** @private */
  _selectionIsActive = false;
  /** @private */
  _selectionChange = new BehaviorSubject(false);
  /** @private */
  _color: ThemePalette = 'primary';

  /** @private */
  readonly _selectControl: MatRadioButton | MatCheckbox | null;

  private readonly destroy = new AsyncSubject<void>();

  @HostListener('click', ['$event']) onClick(event: Event) {
    event.stopPropagation();
  }

  constructor(
    private readonly element: ElementRef,
    @Host() @Optional() matRadioButton: MatRadioButton | null,
    @Host() @Optional() matCheckbox: MatCheckbox | null
  ) {
    if (matRadioButton === null && matCheckbox === null) {
      throw new Error(`${LOG_PREFIX} The directive must be attached to a <mat-radio-button> or a <mat-checkbox>`);
    }

    if (matRadioButton) {
      this.observeChangesOnMatRadioClassList();
    }

    if (matCheckbox) {
      this.observeChangeEvent(matCheckbox);
    }

    this._selectControl = matRadioButton || matCheckbox;
  }

  ngOnInit(): void {
    this._color = this._selectControl?.color || 'primary';
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  private observeChangeEvent(control: MatCheckbox) {
    const isAlreadyChecked = control.checked;
    if (isAlreadyChecked) {
      this.updateState(isAlreadyChecked);
    }

    control.change.pipe(takeUntil(this.destroy)).subscribe(() => this.updateState(control.checked));
  }

  /**
   * Because natively mat-radio does not emit the change event when it gets deselected
   * we try to determine it's selection state based on the existence of .mat-radio-checked
   * class
   */
  private observeChangesOnMatRadioClassList() {
    const el = this.element.nativeElement;

    const isAlreadyChecked = el.classList.contains('mat-radio-checked');
    if (isAlreadyChecked) {
      this.updateState(isAlreadyChecked);
    }

    const classListObserver = new MutationObserver((mutations) =>
      mutations.forEach((mutation) => {
        const target = mutation.target as HTMLElement;

        const isChecked = target.classList.contains('mat-radio-checked');
        if (isChecked !== this._selectionIsActive) {
          this.updateState(isChecked);
        }
      })
    );

    classListObserver.observe(el, {
      attributes: true,
      attributeFilter: ['class'],
    });

    this.destroy.subscribe(() => classListObserver.disconnect());
  }

  private updateState(isSelected: boolean): void {
    this._selectionIsActive = isSelected;
    this._selectionChange.next(isSelected);
  }

  /** @private for internal use only */
  _selectCard() {
    if (this._selectControl && !this._selectControl.disabled) {
      // If it is a radioButton, clicking it does not toggle its status. Once it is checked, it only can be unchecked if
      // other radio is. On the contrary, the checkbox can toggle its selected status clicking it repeatedly
      const selected = this._selectControl instanceof MatRadioButton ? true : !this._selectControl.checked;
      this._selectControl.checked = selected;
      this.updateState(selected);
    }
  }
}
