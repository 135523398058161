import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {filterDefined} from '../utils/defined.util';
import {mapTo, take} from 'rxjs/operators';
import {
  WEB_APP_INITIALIZATION_STRATEGY,
  WebAppInitializationStrategy,
} from './web-app-initialization-strategy.injection';

/** Indicates whether all 'web-app initializers' succeeded or whether some failed.
 * In case the case of a failure the application should restraint itself from using any
 * web-app functionality.
 * */
export type WebAppInitializationResult = 'success' | 'failure';

@Injectable({
  providedIn: 'root',
})
/** Tracks the result of all 'web-app initializers' declared by diverse web-app libraries.
 * Web-app, as a set of libraries, is designed to not prevent the bank's application from loading
 * if the initialization of any web-app library fails,
 * in order for the application to be able to start and provide users other non-web-app related functionality.
 *
 * The 'web-app initializers' are not Angular APP_INITIALIZERS. However, if the initialization strategy is set
 * to 'blocking', the unique APP_INITIALIZER of web-app will wait for all these initializers to complete.
 * */
export class WebAppInitializationService {
  readonly #initializationResultSubject = new BehaviorSubject<WebAppInitializationResult | undefined>(undefined);

  private readonly initializerStatusMap: Record<string, WebAppInitializationResult | 'inProgress'> = {};

  private initializerRegistrationAllowed: boolean = true;

  constructor(
    @Inject(WEB_APP_INITIALIZATION_STRATEGY)
    private strategy: WebAppInitializationStrategy
  ) {}

  /** Returns an observable that emits 'error' as soon any initialization error occurs,
   * or 'success' when web-app completes the initialization process without errors.
   * This observable emits a single value and completes immediately after.
   * */
  getInitializationResult$(): Observable<WebAppInitializationResult> {
    return this.#initializationResultSubject.pipe(filterDefined(), take(1));
  }

  /* Initializers must be registered in the constructors of modules that are designed to be instantiated in the
   * root provider scope of the application,
   * so that they are registered before the APP_INITIALIZER of WebAppModule is triggered.
   *
   * These modules are those who are designed to be imported directly in the applications root module (e.g. AppModule),
   * or modules that are imported by the aforementioned ones.
   *
   * In the 'blocking' initialization strategy, this APP_INITIALIZER will wait until all registered initializers
   * have completed before letting the application start rendering any content */
  ɵregisterInitializer(initializerId: string, initializer: Promise<unknown>): void {
    if (!this.initializerRegistrationAllowed) {
      throw new Error('Not possible to register new initializers once APP_INITIALIZERs are triggered');
    }
    this.initializerStatusMap[initializerId] = 'inProgress';
    initializer.then(
      () => {
        this.updateInitializerStatus(initializerId, 'success');
      },
      (err) => {
        console.error(err);
        this.updateInitializerStatus(initializerId, 'failure');
      }
    );
  }

  /* Makes the web-app root APP_INITIALIZER complete immediately in the 'non-blocking' strategy
   * or to wait for all initializers in the 'blocking' strategy */
  ɵwebAppRootAppInitializerReady$(): Observable<void> {
    this.initializerRegistrationAllowed = false;
    return this.strategy === 'blocking' ? this.getInitializationResult$().pipe(mapTo(undefined)) : of(undefined);
  }

  private updateInitializerStatus(initializerId: string, status: WebAppInitializationResult): void {
    this.initializerStatusMap[initializerId] = status;
    this.checkForInitializationResult();
  }

  private checkForInitializationResult() {
    if (this.#initializationResultSubject.value === undefined && this.hasAnyRegisteredInitializerFailed()) {
      this.#initializationResultSubject.next('failure');
      console.error('Initialization of web-app failed');
    }
    if (!this.initializerRegistrationAllowed && this.hasAllRegisteredInitializersSucceeded()) {
      this.#initializationResultSubject.next('success');
    }
  }

  private hasAllRegisteredInitializersSucceeded(): boolean {
    const results = Object.values(this.initializerStatusMap);
    return results.filter((result) => result === 'success').length === results.length;
  }

  private hasAnyRegisteredInitializerFailed(): boolean {
    return Object.values(this.initializerStatusMap).some((result) => result === 'failure');
  }
}
