<ng-template>
  <div class="bsz-popover-panel mat-elevation-z6" role="tooltip"
       [id]="panelId"
       [attr.data-position]="positionData"
       [attr.data-direction]="directionData"
       [attr.aria-labelledby]="panelId + '-label ' + panelId + '-content'"
       [ngClass]="{
       'bsz-popover-panel--dark': theme === 'dark',
       'bsz-popover-panel--light': theme === 'light'
       }"
       (keydown)="handleKeydown($event)"
       (mouseenter)="handleMouseenter()"
       (mouseleave)="handleMouseleave()"
       [@transformPopover]="'enter'">

    <span id="{{panelId}}-label" class="bsz-popover-panel-label"
              [attr.aria-labelledby]="ariaLabelledby || null">
      {{ariaLabel ? ariaLabel + '.' : ''}}
    </span>

    <span class="bsz-popover-arrow" [ngClass]="arrowClassList"
          [ngStyle]="arrowInlineStyle"></span>

    <div id="{{panelId}}-content" class="bsz-popover-content">
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>
