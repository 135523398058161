import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {BszMicroCard, BszMicroCardActions} from './bsz-micro-card';

@NgModule({
  imports: [CommonModule],
  declarations: [BszMicroCard, BszMicroCardActions],
  exports: [BszMicroCard, BszMicroCardActions],
})
export class BszMicroCardModule {}
