import {Inject, Injectable, Injector, OnDestroy} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {BszUINotification, BszUINotificationRemark, BszUINotificationSeverity} from '@basuiz/web-app-applet-api';
import {UI_NOTIFICATION_STREAM, assertNever} from '@basuiz/web-app-applet-sdk';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subscription} from 'rxjs';
import {
  getManifestCreatedUiNotificationDialogData,
  getManifestDownloadErrorUiNotificationDialogData,
  getShipmentLabelDownloadErrorUiNotificationDialogData,
} from './ui-notification-dialog/domains/shipment/shipment-ui-notification-dialog-data';
import {getUserProfileContactDetailsUiNotificationDialogData} from './ui-notification-dialog/domains/user-profile-contact-details-ui-notification-dialog-data';
import {PortalUINotificationDialogComponent} from './ui-notification-dialog/portal-ui-notification-dialog.component';
import {UINotificationDialogData} from './ui-notification-dialog/portal-ui-notification-dialog.definitions';

@Injectable({
  providedIn: 'root',
})
export class PortalUINotificationService implements OnDestroy {
  private subscriptions = new Subscription();

  constructor(
    @Inject(UI_NOTIFICATION_STREAM) private uiNotificationStream: Observable<BszUINotification>,
    private snackBar: MatSnackBar,
    private readonly matDialog: MatDialog,
    private translateService: TranslateService,
    private injector: Injector
  ) {
    this.subscribeToNotificationStream();
  }

  private subscribeToNotificationStream(): void {
    this.subscriptions.add(
      this.uiNotificationStream.subscribe((notification) => {
        switch (notification.domain) {
          case 'user-profile':
            switch (notification.subDomain) {
              case 'contactDetails':
                this.showNotificationInDialog(
                  getUserProfileContactDetailsUiNotificationDialogData(notification, this.translateService)
                );
                break;
              case 'postalAddress':
              default:
                this.showNotificationInSnackBar(notification);
                break;
            }
            break;
          case 'shipment':
            switch (notification.subDomain) {
              case 'shipmentLabelDownloadError':
                this.showNotificationInDialog(
                  getShipmentLabelDownloadErrorUiNotificationDialogData(notification, this.translateService)
                );
                break;
              case 'manifestCreated':
                this.showNotificationInDialog(
                  getManifestCreatedUiNotificationDialogData(notification, this.translateService)
                );
                break;
              case 'manifestDownloadError':
                this.showNotificationInDialog(
                  getManifestDownloadErrorUiNotificationDialogData(notification, this.translateService)
                );
                break;
              default:
                this.showNotificationInSnackBar(notification);
                break;
            }
            break;
          default:
            this.showNotificationInSnackBar(notification);
        }
      })
    );
  }

  private showNotificationInDialog(data: UINotificationDialogData): void {
    // AFP-44728 todo what if a new notification arrives while a dialog is already open for a previous notification?
    this.matDialog.open(PortalUINotificationDialogComponent, {
      data,
      ...PortalUINotificationDialogComponent.dialogConfig,
    });
  }

  private showNotificationInSnackBar(uiNotification: BszUINotification) {
    const {message, severity} = uiNotification;
    const remarks: BszUINotificationRemark[] = uiNotification.remarks ?? [];
    const snackBarText = remarks.reduce(
      (fullText, remark) => `${fullText.trim().replace(/\.$/, '')}. ${remark.message}`,
      message
    );
    return this.snackBar.open(snackBarText, undefined, this.getSnackBarConfig(severity));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getSnackBarConfig(severity: BszUINotificationSeverity): MatSnackBarConfig {
    const baseSnackBarConfig: MatSnackBarConfig = {
      horizontalPosition: 'end',
      duration: 3000,
    };
    switch (severity) {
      case 'success':
        return {
          ...baseSnackBarConfig,
          panelClass: 'bsz-notification-snackbar-success',
        };
      case 'info':
        return {
          ...baseSnackBarConfig,
          panelClass: 'bsz-notification-snackbar-info',
        };
      case 'warning':
        return {
          ...baseSnackBarConfig,
          panelClass: 'bsz-notification-snackbar-info',
        };
      case 'error':
        return {
          ...baseSnackBarConfig,
          duration: 20000,
          panelClass: 'bsz-notification-snackbar-error',
        };
      default:
        assertNever(severity);
    }
  }
}
