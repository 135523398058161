import {Injectable} from '@angular/core';
import {IProfile} from '@basuiz/shared/data-access';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ProfileFacadeService {
  // TODO p3 change to facade ngrx when needed
  private currentProfile: BehaviorSubject<IProfile | undefined> = new BehaviorSubject<IProfile | undefined>(undefined);

  get currentProfile$(): Observable<IProfile | undefined> {
    return this.currentProfile.asObservable();
  }

  public setCurrentProfile(profile: IProfile | undefined): void {
    this.currentProfile.next(profile);
  }
}
