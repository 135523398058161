<div class="bsz-padding-b4" *ngIf="groupedDetailsObject.details?.length as detailsSize" [@.disabled]="true">
  <mat-expansion-panel [expanded]="detailsSize <= pageSize">
    <mat-expansion-panel-header>
      <mat-panel-title class="bsz-body-1">
        {{ groupedDetailsObject.title | translate }}
      </mat-panel-title>
      <mat-panel-description class="bsz-text-right bsz-body-1">
        {{ 'web-app-common.auth.representation.collective.group.counter.mobile' | translate: {count: detailsSize} }}
      </mat-panel-description>
    </mat-expansion-panel-header>

    <bsz-data-list [dataSource]="dataSource">
      <bsz-micro-card class="collective-representation-card" *bszDataListItemDef="let item">
        <div class="bsz-padding-b2" *ngFor="let label of groupedDetailsObject.labels; let index = index">
          <div class="bsz-caption bsz-text-secondary">{{ label }}</div>
          <div class="bsz-body-1">{{ item[index] }}</div>
        </div>
      </bsz-micro-card>
    </bsz-data-list>

    <ng-container *ngIf="detailsSize > pageSize">
      <mat-paginator [pageSize]="pageSize" [hidePageSize]="true"></mat-paginator>
    </ng-container>
  </mat-expansion-panel>
</div>
