<div *bszDesktopContent>
  <mat-divider class="bsz-padding-b6"></mat-divider>
  <mat-accordion multi [togglePosition]="'before'">
    <ng-container *ngFor="let group of groupedDetails">
      <bsz-collective-representation-panel-desktop
        [groupedDetailsObject]="group"
      ></bsz-collective-representation-panel-desktop>
    </ng-container>
  </mat-accordion>
</div>

<div *bszMobileContent>
  <mat-divider class="bsz-padding-b6"></mat-divider>
  <mat-accordion multi [togglePosition]="'before'">
    <ng-container *ngFor="let group of groupedDetails">
      <bsz-collective-representation-panel-mobile
        [groupedDetailsObject]="group"
      ></bsz-collective-representation-panel-mobile>
    </ng-container>
  </mat-accordion>
</div>
