export {WebAppSdkBusinessObjectModule} from './lib/business-object/web-app-sdk-business-object.module';
export {WebAppSdkCountryService} from './lib/business-object/web-app-sdk-country.service';
export {WebAppSdkUserProfileService} from './lib/user-profile/web-app-sdk-user-profile.service';

/** Locale */
export {
  LocaleSettings,
  getWebAppI18nConfiguration,
  WebAppCommonLocaleConfig,
  WebAppCommonLocaleConfigFactory,
  ActiveLocale,
  ActiveLocaleSettingsService,
} from '@basuiz/web-app-common';

/** Initialization */
export {
  WebAppInitializationService,
  WebAppInitializationResult,
  WebAppInitializationStrategy,
  WEB_APP_INITIALIZATION_STRATEGY,
  WebAppInjectUponInitializationService,
  InjectUponSuccessfulInitializationOptions,
} from '@basuiz/web-app-common';

/** Business object naming */
export {
  DisplayedIn,
  BusinessObjectNameFormatter,
  PaymentMoneyAccountToName,
  PaymentMoneyAccountNameFormatter,
  PaymentMoneyAccountNameFormatterProvider,
  providePaymentMoneyAccountNameFormatter,
} from '@basuiz/web-app-common';

/** Localization - formatting */
export {
  provideCurrencyAmountFormatters,
  CurrencyAmountFormatter,
  CurrencyAmountFormatters,
  CurrencyAmountFormatterOptions,
  currencyAmountDeChCustomFormatter,
  provideCurrencyCompactAmountFormatters,
  CurrencyCompactAmountFormatters,
  CurrencyCompactAmountFormatter,
  provideDefaultDateFormat,
  DefaultMomentDateFormat,
} from '@basuiz/web-app-common';

/** User roles */
export {
  UserRoleService,
  UserRole,
  AFPUserRole,
  UserRoleMapping,
  UserRoleMappingFactoryWithDeps,
} from '@basuiz/web-app-common';

/** Pagination - default page sizes */
export {providePageSizeForDashboard, providePageSizeForForm, providePageSizeForOverview} from '@basuiz/web-app-common';

/** App-shell outlets */
export {
  AppShellOutletCategory,
  AppShellOutletContent,
  AppShellOutletProjectionRequest,
  AppShellOutletBrokerRequestHandler,
  appShellOutletProvider,
  AppShellOutletReceiverModule,
  AppShellOutletReceiverComponent,
} from '@basuiz/web-app-common';

/** Miscellaneous */
export {
  assertNever,
  bszOverlayContainerProvider,
  ExternalLinkService,
  UI_NOTIFICATION_STREAM,
  WebAppRebootHandler,
  WEB_APP_REBOOT_HANDLER,
} from '@basuiz/web-app-common';
// export {GLOBAL_FILTER_STREAM} from '@basuiz/web-app-wealth-global-filter'; // TODO clean
