import {FactoryProvider} from '@angular/core';
import {BszI18nService} from '@basuiz/i18n';
import {
  currencyAmountDeChCustomFormatter,
  provideCurrencyAmountFormatters,
  provideDefaultDateFormat,
} from '@basuiz/web-app-applet-sdk';

export const LOCALIZATION_FORMATTERS: FactoryProvider[] = [
  provideCurrencyAmountFormatters(() => ({'de-CH': currencyAmountDeChCustomFormatter})),
  provideDefaultDateFormat(
    (i18nService) => {
      switch (i18nService.localeId) {
        case 'en-US':
          return 'll';
        case 'de-DE':
          return 'DD/MM/YYYY';
        case 'es-ES':
          return 'DD/MM/YYYY HH:MM';
        default:
          return 'l';
      }
    },
    [BszI18nService]
  ),
];
