import {InjectionToken} from '@angular/core';
import {CurrencyNameFormatter, CurrencyNameFormatterProvider} from '../models/business-object-naming.definitions';

export const provideCurrencyNameFormatter: CurrencyNameFormatterProvider = (factory, deps) => ({
  provide: CURRENCY_NAME_FORMATTER,
  useFactory: factory,
  deps,
});

export const defaultCurrencyNameFormatter: CurrencyNameFormatter = (currency) =>
  `${currency.name} (${currency.isoCode})`;

export const CURRENCY_NAME_FORMATTER = new InjectionToken<CurrencyNameFormatter>(
  'bsz.web-app-common-business-object-naming.currency-name-pipe-formatter',
  {
    factory: () => defaultCurrencyNameFormatter,
  }
);
