import {ActionWithoutResponse} from '@basuiz/mobile-bridge';

/**
 * Notifies the mobile app that a payment form has been successfully submitted
 * from the hybrid payment applet. Any notifications from the request will be
 * displayed to the user.
 */
export class HybridPaymentHasBeenSubmittedAction extends ActionWithoutResponse<HybridPaymentHasBeenSubmittedRequest> {
  readonly name = 'hybridPaymentHasBeenSubmitted';
}

export interface ConfirmationNotification {
  /**
   * Text of the notification that is displayed to the user.
   */
  notificationText: string;

  /**
   * Type of the notification that will change how the notification is
   * visualized to the user.
   */
  notificationType: 'INFO' | 'WARNING' | 'ERROR';
}

export interface HybridPaymentHasBeenSubmittedRequest {
  /**
   * List of notifications that are displayed to the user.
   */
  confirmationNotifications?: ConfirmationNotification[];

  /**
   * Contains single text that is going to be displayed to the user as warning.
   * @deprecated Please use confirmationNotifications field instead.
   */
  confirmationMessage?: string;
}
