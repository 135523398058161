<nav class="bsz-display-flex bsz-align-items-center" *ngIf="showBreadcrumbs$ | async">
  <ng-container *ngIf="startWithHomeSubject | async">
    <bsz-breadcrumb
      [displayText]="'web-app-portal.breadcrumbs.home' | translate"
      (navigate)="navigateToHome()"
    ></bsz-breadcrumb>
    <bsz-breadcrumb-separator></bsz-breadcrumb-separator>
  </ng-container>

  <ng-container *ngFor="let breadcrumb of breadcrumbs$ | async; let last = last">
    <bsz-breadcrumb
      [active]="!last"
      [displayText]="getBreadcrumbText(breadcrumb)"
      (navigate)="breadcrumbClicked(breadcrumb)"
      [bszTestId]="breadcrumb.breadcrumbTestId"
    ></bsz-breadcrumb>
    <bsz-breadcrumb-separator *ngIf="!last"></bsz-breadcrumb-separator>
  </ng-container>
</nav>
