import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AfpRestOperators, AfpRestResponse} from '../../afp-rest/index';
import {HolidayDto} from '../models/holiday-dto.definitions';

@Injectable({providedIn: 'root'})
export class HolidayDataService {
  constructor(private httpClient: HttpClient) {}

  private url: string = '/com.basuiz.afs.rest.services/rest/baseBankData/holidayList';

  public fetch(): Observable<HolidayDto[]> {
    return this.httpClient.get<AfpRestResponse<HolidayDto[]>>(this.url).pipe(AfpRestOperators.extractPayload());
  }
}
