import {NavigationIntent} from '../../navigation/classes/navigation-intent';

export interface ShowManifestOverviewIntentPayload {
  foo?: never;
}

export class ShowManifestOverviewIntent extends NavigationIntent {
  constructor(public payload: ShowManifestOverviewIntentPayload = {}) {
    super();
  }
}
