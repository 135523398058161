import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats} from '@angular/material/core';
import {MAT_FORM_FIELD, MatFormField} from '@angular/material/form-field';
import {AsyncSubject, takeUntil} from 'rxjs';

import {BszDatepicker} from './bsz-datepicker';
import {BszDateRange} from './bsz-datepicker.definitions';
import {BszDatepickerAbstractInput} from './bsz-datepicker-abstract-input';
import {BszDatePickerOverlay} from './bsz-datepicker-overlay';
import {BszDatepickerValidators} from './bsz-datepicker-validators';

const LOG_PREFIX = '[bsz-datepicker-input]';

/** Directive used to connect an input to an BszDatepicker. */
@Directive({
  selector: 'input[bszDatepicker]',
  providers: [
    // Used to allow for registering the built in validators to form controls.
    {provide: NG_VALIDATORS, useExisting: BszDatepickerInput, multi: true},
    // Used to connect the component to the Angular forms API.
    {provide: NG_VALUE_ACCESSOR, useExisting: BszDatepickerInput, multi: true},
  ],
  host: {
    'class': 'bsz-datepicker-input',
    '[attr.aria-haspopup]': '_datepicker ? "dialog" : null',
    '[attr.aria-owns]': '(_datepicker?.opened && _datepicker.uniqueId) || null',
    '[attr.min]': 'min ? _dateAdapter.toIso8601(min) : null',
    '[attr.max]': 'max ? _dateAdapter.toIso8601(max) : null',
    // Used by the test harness to tie this input to its calendar.
    '[attr.data-bsz-calendar]': '_datepicker ? _datepicker.uniqueId : null',
    '[disabled]': 'disabled',
    '(change)': '_onChange($event.target.value)',
    '(blur)': '_onBlur()',
  },
})
export class BszDatepickerInput<D>
  extends BszDatepickerAbstractInput<D>
  implements OnInit, OnDestroy, ControlValueAccessor, Validator
{
  /** The datepicker that this input is associated with. */
  @Input()
  set bszDatepicker(datepicker: BszDatepicker<D>) {
    this._datepicker = datepicker;

    this.subscribeToDatepickerStreams(datepicker);
  }
  _datepicker: BszDatepicker<D> | null = null;

  private readonly destroy = new AsyncSubject<void>();

  private get inputValue() {
    return this.elementRef.nativeElement.value;
  }

  private modelValue: D | BszDateRange<D> | null = null;

  protected override validators: ValidatorFn | null = null;

  constructor(
    override readonly _dateAdapter: DateAdapter<D>,
    private readonly renderer: Renderer2,
    private readonly cd: ChangeDetectorRef,
    protected override readonly elementRef: ElementRef<HTMLInputElement>,
    private readonly datepickerValidators: BszDatepickerValidators<D>,
    @Inject(MAT_DATE_FORMATS) protected override readonly dateFormats: MatDateFormats,
    @Optional() @Inject(MAT_FORM_FIELD) override readonly _formField: MatFormField | null
  ) {
    super(_dateAdapter, elementRef, dateFormats, _formField);
  }

  ngOnInit(): void {
    if (!this._datepicker) {
      throw new Error(
        `${LOG_PREFIX} an input with the bszDatepicker directive cannot be initialized without an associative date-picker`
      );
    }

    this.initWith(this._datepicker);
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  /**
   * This method is called by the forms API to write to the view
   * when programmatic changes from model to view are requested
   *
   * @param value The new value for the element
   */
  writeValue(value: string | D | null): void {
    // Sanity check because formControl.setValue can send any value in.
    if (value !== null && !this.valueIsValid(value)) {
      throw new Error(`${LOG_PREFIX} "${JSON.stringify(value)}" is not a valid value for the datepicker input`);
    }

    const valueParsed = typeof value === 'string' ? this.stringToDateObject(value) : value;

    this.updateView(value, valueParsed);
    this.updateModel(valueParsed);
    this.composeValidators();

    this._datepicker?.setSelectedDate(valueParsed);

    this.cd.markForCheck();
  }

  /**
   * This method is called by the forms API.
   * Function that is called when the control status changes to or from 'DISABLED'.
   */
  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
    if (this._datepicker) {
      this._datepicker.disabled = isDisabled;
    }

    this._disabled = isDisabled;
  }

  /** @private */
  _onChange(value: string) {
    this.writeValue(value);
  }

  /** @private */
  _onBlur(): void {
    this.onTouched();
  }

  private valueIsValid(value: unknown): boolean {
    // String is valid
    if (typeof value === 'string') {
      return true;
    }

    // Date object is valid
    if (this._dateAdapter.getValidDateOrNull(value) !== null) {
      return true;
    }

    return false;
  }

  private subscribeToDatepickerStreams(datepicker: BszDatePickerOverlay<D>) {
    datepicker.onClose.pipe(takeUntil(this.destroy)).subscribe(() => this.cd.markForCheck());

    datepicker.submit.pipe(takeUntil(this.destroy)).subscribe((value) => {
      this.writeValue(value as D);
      this.onTouched();
    });

    datepicker.cancel.pipe(takeUntil(this.destroy)).subscribe(() => {
      // Reset the selected value to the already applied value from the input if set.
      if (this._dateAdapter.isDateInstance(this.modelValue)) {
        this._datepicker?.setSelectedDate(this.modelValue as D);
      } else {
        this._datepicker?.setSelectedDate(null);
      }
    });
  }

  protected override updateView(value: string | D | null, valueParsed: D | null = null) {
    let newDateFormatted = value;
    // If the new value could be parsed to a valid date covert it to string with the appropriate format.
    if (valueParsed && this._dateAdapter.isValid(valueParsed)) {
      newDateFormatted = this.dateObjectToString(valueParsed);
    }

    this.renderer.setProperty(this.elementRef.nativeElement, 'value', newDateFormatted);
  }

  protected override updateModel(value: D | null) {
    let newModelValue = value;
    if (value && !this._dateAdapter.isValid(value)) {
      newModelValue = null;
    }

    this.modelValue = newModelValue;

    this.onChange(newModelValue);
  }

  /** Capture the current state of the component to compose the validators and revalidate it. */
  protected override composeValidators() {
    this.validators = Validators.compose([
      this.datepickerValidators.minValidator(this.min),
      this.datepickerValidators.maxValidator(this.max),
      this.datepickerValidators.filterValidator(this.dateFilter),
      this.datepickerValidators.parseValidator(this.inputValue),
    ]);

    this._validatorOnChange();
  }
}
