import {BszMobileBridgeService} from '@basuiz/mobile-bridge';
import {CreateCollaborationIssueAction} from '@basuiz/mobile-bridge-types';
import {CreateCollaborationInquiryIntent, CustomNavigationIntentHandlerMap, PortalConfig} from '@basuiz/web-app-portal';
import {BszConfigFactory, BszConfigFactoryWithDeps} from '@basuiz/web-app-applet-api';
import {getBaseConfigFactoryWithDeps} from './base-config-factory-with-deps';
import {PortalConfigHybridOverrides, PortalConfigHybridOverridesFactoryWithDeps} from './get-portal-config-for-hybrid';
import {CustomNavigationIntentHandlerOverrideMapBuilder} from './custom-navigation-intent-handler-override-map.builder';
import {getBaseOverrideFactoryWithDeps} from './base-override-factory-with-deps';
import {PortalHybridDebugService} from '../debug/portal-hybrid-debug.service';

export function getPortalHybridConfig(
  portalConfigFactory: BszConfigFactoryWithDeps<PortalConfig> | undefined,
  hybridOverrideFactory: PortalConfigHybridOverridesFactoryWithDeps | undefined
): BszConfigFactoryWithDeps<PortalConfig> {
  const {baseFactory, baseDeps} = getBaseConfigFactoryWithDeps(portalConfigFactory);
  const {baseOverrideFactory, baseOverrideDeps} = getBaseOverrideFactoryWithDeps(hybridOverrideFactory);

  const hybridFactory: BszConfigFactory<PortalConfig> = (
    defaultConfig,
    mobileBridgeService: BszMobileBridgeService,
    portalHybridDebugService: PortalHybridDebugService,
    ...depsInjected
  ) => {
    const [baseDepsInjected, baseOverrideDepsInjected] = splitArray(depsInjected, baseDeps.length);
    const clientConfig = baseFactory(defaultConfig, ...baseDepsInjected);

    if (!mobileBridgeService.isInsideNative()) {
      return clientConfig;
    }

    const overrideConfig = baseOverrideFactory ? baseOverrideFactory(...baseOverrideDepsInjected) : undefined;

    return {
      ...clientConfig,
      ...getHybridOverrides(clientConfig, overrideConfig, mobileBridgeService, portalHybridDebugService),
    };
  };

  return {
    useFactory: hybridFactory,
    deps: [BszMobileBridgeService, PortalHybridDebugService, ...baseDeps, ...baseOverrideDeps],
  };
}

function getHybridOverrides(
  clientConfig: PortalConfig,
  clientHybridOverrides: PortalConfigHybridOverrides | undefined,
  mobileBridgeService: BszMobileBridgeService,
  portalHybridDebugService: PortalHybridDebugService
): Partial<PortalConfig> {
  return {
    portalLayout: {
      ...clientConfig.portalLayout,
      showAppShell: false,
    },
    pageLayout: {
      ...clientConfig.pageLayout,
      breadcrumbs: {
        mobile: {style: 'back-button'},
        tablet: {style: 'back-button'},
        desktop: {style: 'history', maxBreadcrumbs: 3},
      },
      showPageTitle: false,
    },
    navigation: {
      ...clientConfig.navigation,
      customNavigationIntentHandlers: overrideCustomIntentHandlers(
        clientConfig.navigation.customNavigationIntentHandlers,
        clientHybridOverrides?.customNavigationIntentHandlers,
        mobileBridgeService,
        portalHybridDebugService
      ),
    },
  };
}

function overrideCustomIntentHandlers(
  clientHandlers: CustomNavigationIntentHandlerMap,
  clientHybridOverrideHandlers: CustomNavigationIntentHandlerMap | undefined,
  mobileBridgeService: BszMobileBridgeService,
  portalHybridDebugService: PortalHybridDebugService
): CustomNavigationIntentHandlerMap {
  const builder = new CustomNavigationIntentHandlerOverrideMapBuilder(mobileBridgeService, portalHybridDebugService);

  builder.addClientPortalConfigHandlers(clientHandlers);

  overrideCreateCollaborationInquiryIntent(builder);

  builder.addClientHybridOverrideHandlers(clientHybridOverrideHandlers);

  return builder.build();
}

function overrideCreateCollaborationInquiryIntent(builder: CustomNavigationIntentHandlerOverrideMapBuilder): void {
  builder.addPortalHybridLibraryMobileActionOverrideFor(
    CreateCollaborationInquiryIntent,
    ({payload: {collaborationIssueType}}) => {
      let action: CreateCollaborationIssueAction | undefined;

      action = new CreateCollaborationIssueAction({
        defaultIssueType: collaborationIssueType,
        supportedIssueTypes: [collaborationIssueType],
      });

      return action;
    }
  );
}

function splitArray(array: unknown[], count: number): [unknown[], unknown[]] {
  return [array.slice(0, count), array.slice(count)];
}
