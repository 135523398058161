import {Inject, Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AfpAuthNativeDialogInput, AfpAuthNativeDialogResult} from '../models/auth-native-dialog.definitions';
import {AfpAuthObject, AfpAuthOperation, AfpAuthRequest} from '../models/auth.definitions';
import {AfpAuthDialogResult} from '../models/auth-dialog.definitions';
import {AuthVerificationService} from '../data-access/auth-verification.service';
import {isPollingOperation} from '../type-guards/auth-dialog.type-guards';
import {ɵAUTH_NATIVE_CONFIG} from '../native-config/auth.native-config.provider';
import {AuthNativeConfig} from '../native-config/auth.native-config.definition';

@Injectable()
export class AuthNativeDialogService {
  constructor(
    @Inject(ɵAUTH_NATIVE_CONFIG) private authNativeConfig: AuthNativeConfig,
    private verificationService: AuthVerificationService
  ) {}

  public open(authRequest: AfpAuthRequest): Observable<[AfpAuthDialogResult, AfpAuthObject] | 'unhandled'> {
    const [authNativeDialogInput, transactionSigningObject] = this.getDialogData(authRequest);

    return from(this.authNativeConfig.openAuthNativeDialog(authNativeDialogInput)).pipe(
      map((result) =>
        result === 'unhandled' ? result : this.handleResult(result, authRequest.operation, transactionSigningObject)
      )
    );
  }

  private handleResult(
    result: AfpAuthNativeDialogResult,
    operation: AfpAuthOperation,
    transactionSigningObject: AfpAuthObject
  ): [AfpAuthDialogResult, AfpAuthObject] {
    const {verification, isPushSessionCancelledByUser} = result;

    const shouldCancelSigningSession =
      verification === 'CANCEL' && isPollingOperation(operation) && !isPushSessionCancelledByUser;

    if (shouldCancelSigningSession) {
      this.verificationService
        .cancelSigningSession(transactionSigningObject)
        .toPromise()
        .catch(() => {});
    }

    return [verification, transactionSigningObject];
  }

  private getDialogData(authRequest: AfpAuthRequest): [AfpAuthNativeDialogInput, AfpAuthObject] {
    const {id, versionId, transactionSigningObjectType, operation, image, redirectURL, challenge} = authRequest;
    const transactionSigningObject = {id, versionId, transactionSigningObjectType};

    const authNativeDialogInput: AfpAuthNativeDialogInput = {
      id: id.toString(),
      versionId,
      transactionSigningObjectType,
      operation,
      image,
      redirectURL,
      challenge,
    };

    return [authNativeDialogInput, transactionSigningObject];
  }
}
