import {createAction, props} from '@ngrx/store';
import {CurrencyDTO} from '../../data-access/currency-data.service';
import {getErrorState} from '../../../utils/error-state';

export const loadCurrencies = createAction('[WebAppCommon/Currency] Load Currencies');
export const loadCurrenciesSuccess = createAction(
  '[WebAppCommon/Currency] Load Currencies Success',
  props<{currencyList: CurrencyDTO[]}>()
);
export const loadCurrenciesFailed = createAction('[WebAppCommon/Currency] Load Currencies Failed', getErrorState);
