import {Directive, ElementRef, HostBinding, HostListener, Input} from '@angular/core';

/* Adds accessibility characteristics to an <a> element
 * which would be added automatically by the browser if the 'href' attribute could be used.
 * I.e. this directive is necessary because web-app <a> tags most often do not operate with actual URLs.
 * On top, the directive adds default hover and cursor behavior.
 * */
@Directive({
  selector: '[bszAccessibleLink]',
})
export class AccessibleLinkDirective {
  @Input()
  hoverClass: string = '';

  /* Set to false to disable the text-decoration: underline on hover */
  @Input()
  underline: boolean = true;

  @HostBinding('style.cursor')
  readonly cursorCssStyle = 'pointer';

  @HostBinding('class')
  hoverCssClass: string = '';

  @HostBinding('style.text-decoration')
  hoverCssStyle: 'underline' | 'none' = 'none';

  @HostListener('mouseover')
  onMouseOver() {
    this.hoverCssStyle = this.underline ? 'underline' : 'none';
    this.hoverCssClass = this.hoverClass;
  }

  @HostListener('mouseout')
  onMouseOut() {
    this.hoverCssStyle = 'none';
    this.hoverCssClass = '';
  }

  @HostBinding('attr.role')
  readonly role = 'link';

  @HostBinding('attr.tabindex')
  readonly tabindex = 0;

  @HostListener('keyup.enter')
  onKeyUpEnter() {
    this.el.nativeElement.click();
  }

  constructor(private readonly el: ElementRef) {}
}
