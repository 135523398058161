import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatIconModule} from '@angular/material/icon';
import {BszI18nModule} from '@basuiz/i18n';

import {BszSwitchButton} from './bsz-switch-button';
import {BszSwitchButtonOption} from './bsz-switch-button-option';

@NgModule({
  imports: [CommonModule, MatIconModule, MatButtonModule, BszI18nModule, MatButtonToggleModule],
  exports: [BszSwitchButton, BszSwitchButtonOption],
  declarations: [BszSwitchButton, BszSwitchButtonOption],
  providers: [],
})
export class BszSwitchButtonModule {}
