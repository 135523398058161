import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebAppAppletService {
  private revealBoundaryAndNameSubject = new BehaviorSubject<boolean>(false);

  getRevealBoundaryAndName(): Observable<boolean> {
    return this.revealBoundaryAndNameSubject.asObservable();
  }

  toggleRevealBoundaryAndName(value?: 'reveal' | 'hide') {
    if (value) {
      this.revealBoundaryAndNameSubject.next(value === 'reveal');
    } else {
      this.revealBoundaryAndNameSubject.next(!this.revealBoundaryAndNameSubject.getValue());
    }
  }
}
