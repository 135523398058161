import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AfpRestOperators, AfpRestResponse} from '../../afp-rest/index';
import {Currency} from '../models/currency.definitions';

@Injectable({providedIn: 'root'})
export class DefaultCurrencyService {
  constructor(private httpClient: HttpClient) {}

  // portfolioListDefaultReportingCurrency logic:
  // If all portfolios have the same reference currency, return that currency
  // Otherwise, return the Business Unit currency
  private url: string = '/com.basuiz.afs.rest.services/rest/clientData/portfolioListDefaultReportingCurrency';

  public fetch(): Observable<Currency> {
    return this.httpClient.get<AfpRestResponse<Currency>>(this.url, {}).pipe(AfpRestOperators.extractPayload());
  }
}
