<span class="bsz-button-content">
  <ng-content select="[bszButtonIconPrefix]"></ng-content>
  <ng-content></ng-content>
  <ng-content select="[bszButtonIconSuffix]"></ng-content>
</span>
<span *ngIf="loading" class="bsz-button-spinner">
  <mat-spinner diameter="24" strokeWidth="3" mode="indeterminate"></mat-spinner>
</span>
<span
  class="bsz-button-ripple"
  matRipple
  [matRippleDisabled]="_disableRipple"
  [matRippleTrigger]="hostElement"
  [matRippleCentered]="variant === 'icon'"
></span>
<span class="bsz-button-focus-overlay"></span>