import {ChangeDetectionStrategy, Component, HostListener, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AfpAuthDialogInput, AfpAuthDialogResult} from '../../models/auth-dialog.definitions';

@Component({
  selector: 'bsz-auth-dialog',
  templateUrl: './auth-dialog.component.html',
  styleUrls: ['./auth-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthDialogComponent {
  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close('CANCEL');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public authRequestData: AfpAuthDialogInput,
    private dialogRef: MatDialogRef<AuthDialogComponent, AfpAuthDialogResult>
  ) {
    dialogRef.disableClose = true;
  }

  onVerification(verificationResult: AfpAuthDialogResult) {
    this.dialogRef.close(verificationResult);
  }
}
