import {Action, createReducer, on} from '@ngrx/store';
import {loadDefaultCurrencySuccess, loadDefaultCurrencyFailed, loadDefaultCurrency} from './default-currency.actions';
import {Currency} from '../../models/currency.definitions';
import {EntityAdapter, createEntityAdapter, EntityState} from '@ngrx/entity';

export const FEATURE_KEY = 'web-app-common/default-currency';

export interface PartialState {
  readonly [FEATURE_KEY]: State;
}

export interface State extends EntityState<Currency> {
  defaultCurrency: Currency | undefined;
  loading: boolean;
  loaded: boolean;
  error: any;
}

export const adapter: EntityAdapter<Currency> = createEntityAdapter<Currency>({
  selectId: (defaultCurrency: Currency) => defaultCurrency.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  defaultCurrency: undefined,
  loading: false,
  loaded: false,
  error: false,
});

const defaultCurrencyReducer = createReducer(
  initialState,
  on(loadDefaultCurrency, (state) => ({
    ...state,
    loading: true,
  })),
  on(loadDefaultCurrencySuccess, (state, {defaultCurrency}) => ({
    ...state,
    defaultCurrency,
    loaded: true,
    loading: false,
    error: false,
  })),
  on(loadDefaultCurrencyFailed, (state) => ({
    ...state,
    loaded: false,
    loading: false,
    error: true,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return defaultCurrencyReducer(state, action);
}
