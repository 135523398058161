import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatIconModule} from '@angular/material/icon';
import {BszI18nModule} from '@basuiz/i18n';

import {BszTab} from './bsz-tab';
import {BszTabs} from './bsz-tabs';

@NgModule({
  imports: [CommonModule, MatIconModule, MatButtonModule, BszI18nModule, MatButtonToggleModule],
  exports: [BszTabs, BszTab],
  declarations: [BszTabs, BszTab],
  providers: [],
})
export class BszTabsModule {}
