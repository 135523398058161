import {
  // eslint-disable-next-line no-restricted-syntax
  APP_INITIALIZER,
  ApplicationInitStatus,
  Injector,
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import {
  addBackendVersionToBasuizInfo,
  addPackageToBasuizInfo,
  BszMatPaginatorIntl,
  BackendVersionService,
  commonLocaleConfigProvider,
  ENABLE_UI_NOTIFICATION_SNACK_BAR_SERVICE,
  filterEqual,
  getDisabledUINotificationSnackBarServiceProvider,
  UserRoleMappingFactoryWithDeps,
  userRoleMappingProvider,
  UserRoleService,
  UserSessionService,
  UserSettingsService,
  WebAppBaseDataModule,
  WebAppCommonAuthJwtModule,
  WebAppCommonAuthModule,
  WebAppCommonFacade,
  WebAppCommonLocaleConfigFactory,
  WebAppCommonModule,
  WebAppCustomIconService,
  WebAppInitializationService,
  WebAppInjectUponInitializationService,
} from '@basuiz/web-app-common';
import {WebAppHybridModule} from '@basuiz/web-app-hybrid';
import {BszFeatureToggle, featureToggleProviderFactory} from '@basuiz/web-app-feature-toggle';
import {BszConfigFactoryWithDeps} from '@basuiz/web-app-applet-api';
import {firstValueFrom} from 'rxjs';
import {take} from 'rxjs/operators';
import {MatDateFormatsProvider} from './providers/mat-date-formats.provider';

import {PACKAGE_INFO} from '../package-info';
import {UINotificationSnackBarService} from './ui-notification/ui-notification-snack-bar.service';
import {UINotificationSnackBarModule} from './ui-notification/ui-notification-snack-bar.module';
import {HttpInterceptorsProviders} from './providers/http-interceptors.providers';
import {MatFormFieldDefaultOptionsProvider} from './providers/mat-form-field-default-options.provider';
import {MatPaginatorIntl} from '@angular/material/paginator';

export interface WebAppModuleOptions {
  /** Optional configuration for the locales available in web app.
   * This locale configuration is required if you plan to make use of any of the following:
   * - `getWebAppI18nConfiguration` from `@basuiz/web-app-applet-sdk`
   * - the `@basuiz/web-app-self-service-locale-selector-applet` */
  localeConfig?: {useFactory: WebAppCommonLocaleConfigFactory; deps?: unknown[]};

  /** Optional configuration for the user roles mapping. This is a mapping between the AFP user role and the
   * fix collection of user-roles defined by @basuiz/web-app.
   * @default: The web-app initializes without any role assigned to the user. The user will only have access
   * to those features not restricted to specific roles */
  userRolesMapping?: UserRoleMappingFactoryWithDeps;

  /** A tree of features. Each feature can be enabled / disabled separately. The feature toggle is read by the
   * applets and the portal, but only to calculate their default configurations. */
  featureToggle?: BszConfigFactoryWithDeps<BszFeatureToggle>;

  /** Instructs web-app whether to subscribe or not to the UI-notification stream in order to
   * display these notifications via a snack-bar (Angular Material).
   * You can disable these snack-bars and instead provide in your application your own consumer of UI-notifications.
   * To access the stream use the injection token UI_NOTIFICATION_STREAM from @basuiz/web-app-applet-sdk.
   * @default true, every ui-notification is presented to the user in a snack-bar */
  enableSnackBarUINotifications?: boolean;
}

const noProvider: unknown[] = [];

@NgModule({
  imports: [
    WebAppCommonAuthJwtModule,
    WebAppCommonAuthModule,
    WebAppBaseDataModule,
    WebAppCommonModule,
    WebAppHybridModule,
    UINotificationSnackBarModule,
  ],
})
export class WebAppModule {
  static forRoot(config?: WebAppModuleOptions): ModuleWithProviders<WebAppModule> {
    return {
      ngModule: WebAppModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          multi: true,
          useFactory: webAppInitializerFactory,
          deps: [WebAppInitializationService],
        },
        HttpInterceptorsProviders,
        {provide: MatPaginatorIntl, useClass: BszMatPaginatorIntl},
        MatDateFormatsProvider,
        MatFormFieldDefaultOptionsProvider,
        config?.localeConfig
          ? commonLocaleConfigProvider(config.localeConfig.useFactory, config.localeConfig.deps)
          : noProvider,
        config?.userRolesMapping ? userRoleMappingProvider(config.userRolesMapping) : noProvider,
        config?.featureToggle
          ? featureToggleProviderFactory(config.featureToggle.useFactory, config.featureToggle.deps)
          : noProvider,
        config?.enableSnackBarUINotifications === false
          ? getDisabledUINotificationSnackBarServiceProvider()
          : noProvider,
      ],
    };
  }

  constructor(
    applicationInitStatus: ApplicationInitStatus,
    injector: Injector,
    initializationService: WebAppInitializationService,
    injectUponInitializationService: WebAppInjectUponInitializationService,
    userSettingsService: UserSettingsService,
    userRoleService: UserRoleService,
    userSessionService: UserSessionService,
    backendVersionService: BackendVersionService,
    webAppCustomIconService: WebAppCustomIconService
  ) {
    /* Fetch static data that needs to be synchronously available to applets, portal, default configurations ... */
    initializationService.ɵregisterInitializer(
      'user-settings',
      userSettingsService.userSettings$.pipe(take(1)).toPromise()
    );
    initializationService.ɵregisterInitializer('user-roles', userRoleService.ɵfetchAndCacheRoles());
    initializationService.ɵregisterInitializer(
      'user-session',
      firstValueFrom(userSessionService.ɵloadSessionDetails())
    );
    initializationService.ɵregisterInitializer(
      'backend-version',
      firstValueFrom(backendVersionService.backendVersion$)
    );

    applicationInitStatus.donePromise.then(() => {
      initializationService
        .getInitializationResult$()
        .pipe(filterEqual('success'))
        .subscribe(() => {
          /* Requests the base data. These requests need to wait until the locale is defined in order
           * to get the response translated to the users language (E.g. currency names).
           * The locale is set via APP_INITIALIZER by @basuiz/i18n */
          injector.get(WebAppCommonFacade).dispatchLoadOfBaseData();

          /* setup basuiz.info
           */
          addBackendVersionToBasuizInfo({
            name: 'com.basuiz.afs.rest.services',
            version: backendVersionService.backendVersion || 'unknown',
          });
        });
    });

    injectUponInitializationService
      .injectUponSuccessfulInitialization(ENABLE_UI_NOTIFICATION_SNACK_BAR_SERVICE)
      .then((doEnable) => doEnable && injector.get(UINotificationSnackBarService));

    addPackageToBasuizInfo(PACKAGE_INFO);

    webAppCustomIconService.registerIcons();
  }
}

function webAppInitializerFactory(initializationService: WebAppInitializationService): () => Promise<void> {
  return () => firstValueFrom(initializationService.ɵwebAppRootAppInitializerReady$());
}
