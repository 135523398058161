import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClipboardModule} from '@angular/cdk/clipboard';

import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatDividerModule} from '@angular/material/divider';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatBadgeModule} from '@angular/material/badge';
import {MatMenuModule} from '@angular/material/menu';

import {
  BszBadgeModule,
  BszLabelValueModule,
  BszMicroCardModule,
  BszScreenSizeContentSwitcherModule,
  BszSelectAllModule,
  BszSkeletonLoaderModule,
  BszStatusModule,
} from '@basuiz/ui-elements';
import {BszI18nModule} from '@basuiz/i18n';

import {TestingCoreModule} from '../testing-core/index';
import {FormattingModule} from '../formatting/index';
import {WebAppCommonBusinessObjectNamingModule} from '../business-object-naming/index';
import {BszProgressBarModule} from '../progress-indicator/bsz-progress-bar.module';
import {DataStateModule} from '../data-state/data-state.module';
import {ExportToFileModule} from '../export-to-file/export-to-file.module';
import {BszNotificationsModule} from '../afp-rest-notifications/bsz-notifications.module';

import {TranslateModule} from '@ngx-translate/core';
import {AccessibleLinkModule} from '../accessible-link/accessible-link.module';
import {WebAppBannerModule} from '../ui-sdk-proxy/banner/index';
import {MatDialogModule} from '@angular/material/dialog';
import {StylerModule} from '../styler/index';
import {BszCentralMessageModule} from '../central-message/index';

const ANGULAR_MODULES = [CommonModule, ClipboardModule];

const ANGULAR_MATERIAL_MODULES = [
  MatBadgeModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatDialogModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatTooltipModule,
];
const UI_SDK_MODULES = [
  BszBadgeModule,
  BszI18nModule,
  BszLabelValueModule,
  BszMicroCardModule,
  BszNotificationsModule,
  BszScreenSizeContentSwitcherModule,
  BszSelectAllModule,
  BszSkeletonLoaderModule,
  BszStatusModule,
];
const WEB_APP_COMMON_MODULES = [
  AccessibleLinkModule,
  BszCentralMessageModule,
  DataStateModule,
  FormattingModule,
  ExportToFileModule,
  BszProgressBarModule,
  StylerModule,
  TestingCoreModule,
  WebAppBannerModule,
  WebAppCommonBusinessObjectNamingModule,
];

@NgModule({
  exports: [ANGULAR_MODULES, ANGULAR_MATERIAL_MODULES, UI_SDK_MODULES, WEB_APP_COMMON_MODULES, TranslateModule],
})
export class WebAppBasicModule {}
