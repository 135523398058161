import {FocusMonitor} from '@angular/cdk/a11y';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  ANIMATION_MODULE_TYPE,
  ChangeDetectionStrategy,
  Component,
  Directive,
  ElementRef,
  Inject,
  Input,
  Optional,
  ViewEncapsulation,
} from '@angular/core';

import {BszButtonBase} from './bsz-button-base';

type BszButtonVariant = 'filled' | 'tonal' | 'outlined' | 'text' | 'icon';

/** Captures the template for an icon to be placed as a prefix in a button. */
@Directive({
  selector: '[bszButtonIconPrefix]',
  host: {
    class: 'bsz-button-icon-prefix',
  },
})
export class BszButtonIconPrefix {}

/** Captures the template for an icon to be placed as a suffix in a button. */
@Directive({
  selector: '[bszButtonIconSuffix]',
  host: {
    class: 'bsz-button-icon-suffix',
  },
})
export class BszButtonIconSuffix {}

@Component({
  selector:
    // eslint-disable-next-line @angular-eslint/component-selector
    `
    button[bsz-button], input[type=button][bsz-button], input[type=reset][bsz-button], input[type=submit][bsz-button],
    button[bsz-tonal-button], input[type=button][bsz-tonal-button], input[type=reset][bsz-tonal-button], input[type=submit][bsz-tonal-button],
    button[bsz-outlined-button], input[type=button][bsz-outlined-button], input[type=reset][bsz-outlined-button], input[type=submit][bsz-outlined-button],
    button[bsz-text-button], input[type=button][bsz-text-button], input[type=reset][bsz-text-button], input[type=submit][bsz-text-button],
    button[bsz-icon-button], input[type=button][bsz-icon-button], input[type=reset][bsz-icon-button], input[type=submit][bsz-icon-button],
    `,
  templateUrl: 'bsz-button.html',
  styleUrls: ['./bsz-button.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'class': 'bsz-button',
    '[class.bsz-button--filled]': 'variant === "filled"',
    '[class.bsz-button--tonal]': 'variant === "tonal"',
    '[class.bsz-button--outlined]': 'variant === "outlined"',
    '[class.bsz-button--text]': 'variant === "text"',
    '[class.bsz-button--icon]': 'variant === "icon"',
    '[class.bsz-button--loading]': '_loading === true',
    '[class.bsz-button--disabled]': '_disabled || _loading',
    // When it's loading, we use aria-disabled instead of disabled
    // because when the button switches to disabled state it
    // immediately loses the focus.
    // Using aria-disabled allows focus to remain on the button
    // after it is pressed.
    '[attr.aria-disabled]': '_loading || null',
  },
})
export class BszButton extends BszButtonBase {
  @Input()
  set loading(isLoading: BooleanInput) {
    this._loading = coerceBooleanProperty(isLoading);
  }
  get loading() {
    return this._loading;
  }
  protected _loading = false;

  protected readonly variant: BszButtonVariant;

  constructor(
    elementRef: ElementRef<HTMLButtonElement>,
    focusMonitor: FocusMonitor,
    @Optional()
    @Inject(ANIMATION_MODULE_TYPE)
    animationModuleType: 'NoopAnimations' | 'BrowserAnimations' | null
  ) {
    super(elementRef, focusMonitor, animationModuleType);
    this.variant = this.getVariantFromElement(elementRef.nativeElement);
    if (this.variant === 'icon') {
      this.color = undefined;
    }
  }

  private getVariantFromElement(el: HTMLElement): BszButtonVariant {
    const variants: {type: BszButtonVariant; active: boolean}[] = [
      {type: 'filled', active: el.hasAttribute('bsz-button')},
      {type: 'tonal', active: el.hasAttribute('bsz-tonal-button')},
      {type: 'outlined', active: el.hasAttribute('bsz-outlined-button')},
      {type: 'text', active: el.hasAttribute('bsz-text-button')},
      {type: 'icon', active: el.hasAttribute('bsz-icon-button')},
    ];

    const activeVariant = variants.find((variant) => variant.active);

    return activeVariant?.type ?? 'filled';
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'a[bsz-button], a[bsz-tonal-button], a[bsz-outlined-button], a[bsz-text-button], a[bsz-icon-button]',
  templateUrl: 'bsz-button.html',
  styleUrls: ['bsz-button.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'class': 'bsz-anchor-button',
    '[attr.disabled]': '_disabled || null',

    // Notice: we ignore the user defined tabindex when the component is disabled
    // to be consistent with the native buttons (and the bsz-button) where even
    // if they have a user defined tabindex they are not focusable
    '[attr.tabindex]': '_disabled ? -1 : tabIndex',
    '[attr.aria-disabled]': '_disabled ? _disabled.toString() : null',
  },
})
export class BszAnchorButton extends BszButton {
  @Input() tabIndex = 0;
}
