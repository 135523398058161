import {FeatureStatus} from './models/generic.definition';
import {BszFeatureToggle} from './models/features.definition';
import {getAllEnabledFeatureToggle} from './all-enabled-feature-toggle';
import {PartialFeatureToggle} from './models/features-partial.definition';
import {PatchNode, patchRecursive} from './patch-feature-toggle';
import {getExperimentalFeatureToggleTree} from './experimental-feature-toggle-tree';
import {ExperimentalFeatureStatus} from './models/features-experimental.definition';

/** Use to set a feature as enabled in the non-expanded feature toggle */
export function enabled(condition: boolean): FeatureStatus {
  return condition ? 'enabled' : 'disabled';
}

/** Use to set a feature as disbaled in the non-expanded feature toggle */
export function disabled(condition: boolean): FeatureStatus {
  return condition ? 'disabled' : 'enabled';
}

/** Used to check if a feature in enabled in the expanded feature toggle. */
export function isEnabled(feature: Object | FeatureStatus | undefined): boolean {
  return feature instanceof Object || feature === 'enabled';
}

/** Used to check if a feature in disabled in the expanded feature toggle. */
export function isDisabled(feature: Object | FeatureStatus | undefined): boolean {
  return !feature || feature === 'disabled';
}

/** Use to alter a sub-set of features from a given feature toggle.
 * @param base: the original feature toggle
 * @param delta: a partial feature toggle with the features to be overridden
 * @returns: a new feature toggle resulting of patching the base tree with the delta
 * Example:
 * const base: BszFeatureToggle = <some feature toggle>;
 * const delta: PartialFeatureToggle = {
 *   payments: {
 *     scanQRBill: {
 *       payeye: 'disabled'
 *     }
 *   },
 *   trading: 'enabled'
 * };
 * const newToggle = patchFeatureToggle(base, delta);
 * */
export function patchFeatureToggle(base: BszFeatureToggle, delta: PartialFeatureToggle): BszFeatureToggle {
  return patchRecursive(
    base as unknown as PatchNode,
    delta as unknown as PatchNode,
    getAllEnabledFeatureToggle() as unknown as PatchNode
  ) as unknown as BszFeatureToggle;
}

interface ExperimentalFeatureToggleTreeNode {
  [prop: string]: ExperimentalFeatureToggleTreeNode | ExperimentalFeatureStatus;
}

/** Indicates whether a given node of the feature toggle is experimental
 * @param path: a string representing the path in JS notation to a node in the feature toggle tree,
 * e.g. 'payments.scanQRBill.webcam'
 * @return true if the path represents the path to an experimental node, otherwise false
 * Warning! if the path parameter represents an invalid path, the function does not error and instead returns false
 */
export function isExperimentalFeatureToggleTreeNode(path: string): boolean {
  const exp = getExperimentalFeatureToggleTree() as ExperimentalFeatureToggleTreeNode;
  let [firstSegment, ...restSegments] = path.split('.');
  let node: ExperimentalFeatureToggleTreeNode | ExperimentalFeatureStatus | undefined = exp[firstSegment];
  while (node && node !== ExperimentalFeatureStatus && restSegments.length) {
    [firstSegment, ...restSegments] = restSegments;
    node = node[firstSegment];
  }
  return restSegments.length === 0 && node === ExperimentalFeatureStatus;
}
