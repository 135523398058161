<div class="bsz-progress-spinner" [ngClass]="getSizeClass(_size)">
  <div class="bsz-progress-spinner-wrapper" [style.width.px]="_diameter" [style.height.px]="_diameter">
    <mat-progress-spinner
      [color]="color"
      [mode]="mode"
      [value]="value"
      [strokeWidth]="_strokeWidth"
      [diameter]="_diameter"
      [style.min-width.px]="_diameter"
      [attr.aria-label]="ariaLabel"
      [attr.aria-labelledby]="ariaLabelledby"
    ></mat-progress-spinner>

    <span *ngIf="_displayProcessCompletion && !isSmall()" aria-hidden="true"
          class="bsz-progress-spinner-completion">{{value}}%</span>
  </div>

  <div class="bsz-progress-spinner-text">
    <div class="bsz-progress-spinner-label">
      <ng-content select="bsz-progress-spinner-label"></ng-content>
      <span *ngIf="isSmall() && _displayProcessCompletion" class="bsz-progress-spinner-completion"> ({{ value }}%)</span>...
    </div>
    <div *ngIf="!isSmall()" class="bsz-progress-spinner-info">
      <ng-content select="bsz-progress-spinner-info"></ng-content>
    </div>
    <div class="bsz-progress-spinner-action">
      <ng-content select="bsz-progress-spinner-action"></ng-content>
    </div>
  </div>
</div>
