import {from, Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {OptionalWebAppCommonLocaleConfig} from './web-app-common-locale.config';
import {LocaleId} from '@basuiz/web-app-applet-api';

export function callActiveLocaleUpdateHook(
  newLocaleId: LocaleId | undefined,
  maybeLocaleConfig: OptionalWebAppCommonLocaleConfig,
  preferredLanguageUserSettingsUpdated: boolean
): Observable<void> {
  const activeLocaleUpdateHook = maybeLocaleConfig?.activeLocaleUpdateHook;
  if (activeLocaleUpdateHook) {
    return from(activeLocaleUpdateHook(newLocaleId, {preferredLanguageUserSettingsUpdated})).pipe(
      catchError((error) => {
        console.error(`Config provided function 'WebAppCommonLocaleConfig.activeLocaleUpdateHook' failed`);
        console.error(error);
        return of(undefined); // Do not prevent a reboot because an error in the hook
      })
    );
  } else {
    return of(undefined);
  }
}
