import {Relation} from 'typeorm';
import {IBaseUserOwnedEntity, IUser, UserWithoutPassword} from '../..';

// ==============================
// ==============================
// CORE Profile - START
// ==============================

/**
 * ------------------------------------------------------
 * IMPORTANT: CORE Profile
 * ------------------------------------------------------
 *
 * This interface is the one to be used around the app and implement its entity/controller.
 * It contains both the CORE and APP-SPECIFIC data of a user's profile.
 * The 'Profile' entity contains any extra OPTIONAL information of a user which
 * should NOT be in the 'User' entity as they are not required for a user's account.
 * Field that are related for authorization/authentication, signup/signin, or are very sensitive
 * data (such as password or phone) MUST be placed in the 'User'entity.
 * Examples of fields in the Profile entity can be the "bio", "company", "job title", "age", "address", "company tax ID".
 */
export interface IProfile extends IBaseUserOwnedEntity, IAppSpecificProfile {
  ownerUser?: Relation<IUser>;
  bio?: string;
}

export interface UserProfile {
  user: IUser;
  profile: IProfile;
}

export interface UserWithoutPasswordProfile {
  user: Partial<UserWithoutPassword>;
  profile: Partial<IProfile>;
}

// ==============================
// CORE Profile - END
// ==============================
// ==============================

// ==============================
// ==============================
// APP-SPECIFIC Profile - START
// ==============================

export interface IBankAccount {
  personId?: string;
  personName?: string;
  bankName?: string;
  accountNumber?: string;
  accountType?: string;
}

export enum AverageMonthlyShipments {
  // These ranges are calculated for a 20 work days per month
  'unknown' = 'unknown',
  'from1To10' = 'from1To10', // 0.5 daily
  'from11To60' = 'from11To60', // 1-3 daily
  'from61To150' = 'from61To150', // 4-8 daily
  'from151To500' = 'from151To500', // 9-25 daily
  'moreThan500' = 'moreThan500', // >25 daily
}

/**
 * ------------------------------------------------------
 * IMPORTANT: APP-SPECIFIC Profile
 * ------------------------------------------------------
 *
 * This interface is particular or specific only to this
 * backend API app (and cannot be shared to other apps).
 */
export interface IAppSpecificProfile {
  company?: string;
  collectionsBankAccount?: IBankAccount;
  averageMonthlyShipments?: AverageMonthlyShipments;
}

// ==============================
// APP-SPECIFIC Profile - END
// ==============================
// ==============================
