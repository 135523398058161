import {Injectable, Injector} from '@angular/core';
import {map, Observable} from 'rxjs';
import {UserNameService, UserSessionService} from '@basuiz/web-app-common';
import {BszUserProfileContactDetails, BszUserProfilePostalAddress} from '@basuiz/web-app-applet-api';
import {
  getBszUserProfilePostalAddress,
  UserProfileClientAdvisorService,
  UserProfileContactDetailsService,
  UserProfilePostalAddressService,
} from '@basuiz/web-app-user-profile';

/** Service providing information about the profile of the user logged in the web-app application */
@Injectable({
  providedIn: 'root',
})
export class WebAppSdkUserProfileService {
  constructor(private readonly injector: Injector) {}

  /** Provides the name of the currently logged in user */
  getUserName$(): Observable<string> {
    return this.injector.get(UserNameService).userName$;
  }

  /** Provides the contract number, i.e.
   *  the value of the ACP key "E-App contract number" of the document object giving the user access to web-app
   * */
  getContractNumber$(): Observable<string> {
    return this.injector.get(UserSessionService).sessionDetails$.pipe(map(({loginId}) => loginId));
  }

  /** Indicates whether the user has or not a client advisor */
  hasClientAdvisor$(): Observable<boolean> {
    return this.injector.get(UserProfileClientAdvisorService).hasClientAdvisor$();
  }

  /** Provides contact details of the user.
   *  Notice that the availability of this data depends on the availability of the following back-end REST end-points:
   *  https://docs.basuiz.com/daas/r35.19.0/content/applets/self-service/change-contact-ami-ws.htm */
  getContactDetails$(): Observable<BszUserProfileContactDetails> {
    return this.injector.get(UserProfileContactDetailsService).contactDetails$;
  }

  /** Provides the postal address of the user.
   *  @return: an observable emitting the user's postal address, or undefined if no address is stored in the back-end
   *  Notice that the availability of this data depends on the availability of the following back-end REST end-points:
   *  https://docs.basuiz.com/daas/r35.19.0/content/applets/self-service/change-contact-ami-ws.htm */
  getPostalAddress$(): Observable<BszUserProfilePostalAddress | undefined> {
    return this.injector
      .get(UserProfilePostalAddressService)
      .postalAddress$.pipe(map((maybeAddress) => maybeAddress && getBszUserProfilePostalAddress(maybeAddress)));
  }
}
