/* converts the data range returned by an bszDatePickerRange to an object containing to/from ISODates */
import {ISODate} from '../definitions/iso-date.definition';
import {DatepickerRange} from '@basuiz/ui-elements';
import moment from 'moment';

export type ISODateRange =
  | {fromDate: ISODate; toDate: undefined}
  | {fromDate: undefined; toDate: ISODate}
  | {fromDate: ISODate; toDate: ISODate};

/* WARNING: This method keeps only the day component of the date and removes the time component! */
export function convertTimestampDateRangeToISODateRange(timestampRange?: DatepickerRange): ISODateRange | undefined {
  if (timestampRange) {
    if (!timestampRange.fromDate) {
      return {
        fromDate: undefined,
        toDate: moment(timestampRange.toDate).format('YYYY-MM-DD'),
      };
    } else if (!timestampRange.toDate) {
      return {
        fromDate: moment(timestampRange.fromDate).format('YYYY-MM-DD'),
        toDate: undefined,
      };
    }
    return {
      fromDate: moment(timestampRange.fromDate).format('YYYY-MM-DD'),
      toDate: moment(timestampRange.toDate).format('YYYY-MM-DD'),
    };
  }
}
