export interface GroupHeader<T, G> {
  group: G;
  children: T[];
  groupId?: string;
}

export type AnyLevelGroupHeader<T, G> = GroupHeader<AnyLevelGroupHeader<T, G>, G> | GroupHeader<T, G>;

/**
 * Tree data structure that should not allow more than 3 nesting levels.
 *
 * {T} represents the type of the row data
 * {G} represents the type of the group headers
 */
export type BszTreeRowDefinition<T extends Record<string, any>, G extends Record<string, any>> =
  | GroupHeader<T, G>
  | GroupHeader<GroupHeader<T, G>, G>
  | GroupHeader<GroupHeader<GroupHeader<T, G>, G>, G>;

export interface ExpandedGroupStatus {
  groupId: string;
  expandDescendants?: boolean;
  expandAncestors?: boolean;
}

export interface ExpandedHierarchyStatus {
  expandDescendants?: boolean;
  expandAncestors?: boolean;
}

/** Manual type guard to validate against the GroupHeader interface */
export function isGroupHeader<T, G>(obj: any): obj is GroupHeader<T, G> {
  return typeof obj.group !== 'undefined' && Array.isArray(obj.children);
}

/** Manual type guard to validate against an array of items that implement BszTreeRowDefinition interface */
export function isTreeRowDefinitionCollection<T, G>(obj: any): obj is BszTreeRowDefinition<T, G>[] {
  return Array.isArray(obj) && obj.find((element) => isGroupHeader(element)) !== undefined;
}
