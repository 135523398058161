import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {TranslatedText} from '@basuiz/web-app-applet-api';
import {TranslateService} from '@ngx-translate/core';
import {BszDatePipe} from '../formatting/index';
import {FormValidatorBackendErrorsService} from '../validators/form-validator-backend-error.service';

/**
 * Displays common error messages in forms plus backend error messages.
 *
 * Input parameters:
 * control: the form control from where to check the errors
 *
 * In general it's expected to be used within mat-form-fields to display below an error message if it proceeds. Example of use:
 *
 *
 * <mat-form-field
 *   bszInfoTextClass="mdc-layout-grid__cell--span-12"
 *   ngIf="redSlipIbanForPcAccount"
 *   [bszInfoText]="
 *   'web-app-payment-form.component.beneficiary-form.red-slip-iban-account.tooltip-text' | translate
 *   "
 *   bszTestId="{{ bszTestIdPrefix }}.red-slip-iban-account"
 * >
 *   <mat-label>
 *     {{ 'web-app-payment-form.component.beneficiary-form.red-slip-iban-account.label' | translate }}
 *   </mat-label>
 *   <mat-error>
 *      <bsz-form-field-errors [control]="control"></bsz-form-field-errors>
 *   </mat-error>
 * </mat-form-field>
 */
@Component({
  selector: 'bsz-form-field-errors',
  templateUrl: './bsz-form-field-errors.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BszFormFieldErrorsComponent {
  @Input()
  control!: UntypedFormControl;

  @Input()
  requiredErrorText: TranslatedText;

  @Input()
  minlengthErrorText: TranslatedText;

  @Input()
  maxlengthErrorText: TranslatedText;

  @Input()
  matDatepickerParseErrorText: TranslatedText;

  @Input()
  matDatepickerMinErrorText: TranslatedText;

  @Input()
  matDatepickerMaxErrorText: TranslatedText;

  @Input()
  emailErrorText: TranslatedText;

  @Input()
  patternErrorText: TranslatedText;

  private readonly errorPriority: string[] = [
    FormValidatorBackendErrorsService.BACKEND_VALIDATION_ERROR,
    'required',
    'minlength',
    'maxlength',
    'matDatepickerParse',
    'matDatepickerMin',
    'matDatepickerMax',
    'email',
    'pattern',
  ];

  readonly bszTestIdPrefix = 'web-app-common.form-field-errors';

  constructor(private translationService: TranslateService, private readonly bszDatePipe: BszDatePipe) {}

  findFirstPriorityError(): string | undefined {
    let selectedError: string | undefined;
    const firstPriorityErrorFoumd = this.errorPriority.find((priorityError) => this.control.hasError(priorityError));

    if (firstPriorityErrorFoumd) {
      const foundError = this.control.getError(firstPriorityErrorFoumd);
      switch (firstPriorityErrorFoumd) {
        case FormValidatorBackendErrorsService.BACKEND_VALIDATION_ERROR:
          selectedError = foundError.message;
          break;
        case 'required':
          selectedError =
            this.requiredErrorText ?? this.translationService.instant('web-app-common.form-field-errors.required');
          break;
        case 'minlength':
          selectedError =
            this.minlengthErrorText ??
            this.translationService.instant('web-app-common.form-field-errors.min-length', foundError);
          break;
        case 'maxlength':
          selectedError =
            this.maxlengthErrorText ??
            this.translationService.instant('web-app-common.form-field-errors.max-length', foundError);
          break;
        case 'matDatepickerParse':
          selectedError =
            this.matDatepickerParseErrorText ??
            this.translationService.instant('web-app-common.form-field-errors.mat-date-picker-parse');
          break;
        case 'matDatepickerMin':
          selectedError =
            this.matDatepickerMinErrorText ??
            this.translationService.instant('web-app-common.form-field-errors.mat-date-picker-min', {
              min: this.bszDatePipe.transform(foundError.min),
            });
          break;
        case 'matDatepickerMax':
          selectedError =
            this.matDatepickerMaxErrorText ??
            this.translationService.instant('web-app-common.form-field-errors.mat-date-picker-max', {
              max: this.bszDatePipe.transform(foundError.max),
            });
          break;
        case 'email':
          selectedError =
            this.emailErrorText ?? this.translationService.instant('web-app-common.form-field-errors.email');
          break;
        case 'pattern':
          selectedError =
            this.patternErrorText ?? this.translationService.instant('web-app-common.form-field-errors.pattern');
          break;
      }
    }
    return selectedError;
  }
}
