import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {DateAdapter} from '@angular/material/core';
import {DateRange, MatCalendar} from '@angular/material/datepicker';
import {MatSelectionListChange} from '@angular/material/list';
import {MatSelectChange} from '@angular/material/select';

import {transformCalendar} from './bsz-calendar-animations';
import {BszDateRange, BszMixedDate, BszMixedDateCollection, isDateRange} from './bsz-datepicker.definitions';
import {DateRangeSelectionModel} from './date-range-selection-model';

@Component({
  selector: 'bsz-calendar',
  templateUrl: 'bsz-calendar.html',
  styleUrls: ['bsz-calendar.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [transformCalendar],
  // Provide the SelectionModel in Component level so the instance is not shared
  // across different datepicker components in a view.
  providers: [DateRangeSelectionModel],
  host: {
    class: 'bsz-calendar',
  },
})
export class BszCalendar<D> {
  /** To either allow for single dates or range selection */
  @Input() rangePicker = false;

  /** Function for the calendar to use to to filter which dates are selectable. */
  @Input() dateFilter: ((date: D) => boolean) | null = null;

  /** The maximum selectable date. */
  @Input() maxDate: D | null = null;

  /** The minimum selectable date. */
  @Input() minDate: D | null = null;

  /** The date representing the period to start the calendar in. */
  @Input() startAt: D | null = null;

  /**
   * A collection of values for the calendar to use as a list of predefined
   * Range selections. The keys of the collection will be used as labels
   * and the values as the model of the calendar
   */
  @Input() presets: BszMixedDateCollection<D> | null = null;

  /**
   * Whether the calendar UI is in touch mode. In touch mode the calendar
   * is more optimized to fit on smaller screens.
   */
  @Input() touchUi = false;

  /** Emits when the currently selected date changes. */
  @Output() selectedChange = new EventEmitter<BszMixedDate<D> | null>();

  @Output() cancel = new EventEmitter<void>();

  @Output() submit = new EventEmitter<BszMixedDate<D> | null>();

  @ViewChild(MatCalendar, {static: true}) private matCalendar!: MatCalendar<D>;

  /** The selected date or range of dates in the calendar. */
  set selected(date: BszMixedDate<D> | null) {
    this._selected = date;
    if (isDateRange(date)) {
      this.updateSelectionModel(date);
    }
  }
  get selected() {
    return this._selected;
  }
  protected _selected: BszMixedDate<D> | null = null;

  /**
   * Because KeyValue sorts the keys alphabetically, we create this comparatorFn to
   * pass to the keyvalue pipe to preserve the order of the keys in our object.
   *
   * @see https://angular.io/api/common/KeyValuePipe#description
   *
   * @private
   */
  _keyValuePipeOriginalOrder = () => 0;

  /** Whether the invisible close button for screen reader users has the focus. */
  _closeButtonFocused = false;

  /**
   * Used to determine the selected value in the preset list.
   *
   * @private
   */
  _selectedPreset: D | BszDateRange<D> | null = null;

  private previousSelectedPreset: D | BszDateRange<D> | null = null;

  /** @private */
  // option value used to set the "custom" option in the presets, which at the end is the
  // one when user selects directly dated in the calendar
  readonly _presetForCustomValue = {start: null, end: null};

  constructor(
    readonly _elementRef: ElementRef<HTMLElement>,
    private readonly dateAdapter: DateAdapter<D>,
    private readonly dateRangeSelectionModel: DateRangeSelectionModel<D>
  ) {}

  private selectDateOrRange(dateOrRange: D | BszDateRange<D> | null) {
    if (!dateOrRange) {
      this.resetCalendarState();
      this.selectedChange.emit(this.selected);
      return;
    }

    this._selectedPreset = dateOrRange;

    if (this.rangePicker) {
      this.setRange(dateOrRange);
    } else {
      this.setSingleDate(dateOrRange);
    }

    this._setActiveDateOnCalendar(dateOrRange);
    this.selectedChange.emit(dateOrRange);
  }

  private updateSelectionModel(date: BszDateRange<D> | null) {
    this.dateRangeSelectionModel.reset();

    if (date?.start) {
      this.dateRangeSelectionModel.addSelection(date.start);
    }

    if (date?.end) {
      this.dateRangeSelectionModel.addSelection(date.end);
    }
  }

  /** @private */
  _onPresetSelectionChange(event: MatSelectChange | MatSelectionListChange) {
    if (event instanceof MatSelectChange) {
      this.selectDateOrRange(event.value);
    } else {
      this.selectDateOrRange(event.options[0]?.value);
    }

    if (!this.rangePicker) {
      this._onSubmit();
    }
  }

  /** @private */
  _onCalendarDaySelect(event: D) {
    // save the current in case it is not the final one because using cancel action
    this.previousSelectedPreset = this._selectedPreset;
    this._selectedPreset = this._presetForCustomValue;

    if (!this.rangePicker) {
      this.selected = event;
      this.selectedChange.emit(this.selected);
      this._onSubmit();

      return;
    }

    this.selected = this.dateRangeSelectionModel.addSelection(event);

    this.selectedChange.emit(this.selected);
  }

  _onCancel() {
    this._selectedPreset = this.previousSelectedPreset;
    this.cancel.emit();
  }

  _onSubmit() {
    this.submit.emit(this.selected);
  }

  /** @private */
  _setActiveDateOnCalendar(dateOrRange: D | BszDateRange<D>) {
    if (isDateRange(dateOrRange)) {
      if (dateOrRange.end) {
        this.matCalendar.activeDate = dateOrRange.end;
      }
    } else {
      this.matCalendar.activeDate = dateOrRange;
    }
  }

  private setRange(range: D | BszDateRange<D>) {
    const dateRange = isDateRange(range) ? this.objectToDateRangePrototype(range) : new DateRange(range, range);

    this.selected = dateRange;
  }

  private setSingleDate(date: D | BszDateRange<D>) {
    if (isDateRange(date)) {
      this.selected = date.start;
    } else {
      this.selected = date;
    }
  }

  private resetCalendarState() {
    this.selected = null;
    this._selectedPreset = null;
    this.matCalendar.activeDate = this.dateAdapter.today();
  }

  /**
   * Crates a new object whose prototype is "DateRange". We need to do this conversion
   * when we need to assign a custom object to the the mat-calendar because it only
   * accepts an instance of DateRange and simple objects with the same structure
   * or even classes that extend DateRange don't work.
   */
  private objectToDateRangePrototype(range: BszDateRange<D>): BszDateRange<D> {
    const dateRange = Object.create(DateRange.prototype);
    return Object.assign(dateRange, range);
  }

  /** @private */
  _setSelectedPreset(selectedValue: D | BszDateRange<D> | null, userSelectedPreset: D | BszDateRange<D> | null) {
    // set the preset
    this._selectedPreset = userSelectedPreset;

    // if there is no value and userSelectedPreset selected, no preset should be selected
    if (!selectedValue && !userSelectedPreset) {
      this._selectedPreset = null;
      return;
    }

    // if there is value selected but no userSelectedPreset, the custom preset should be selected
    if (selectedValue && !userSelectedPreset) {
      this._selectedPreset = this._presetForCustomValue;
      return;
    }

    // if there is a userSelectedPreset, and it's the same as the custom preset, custom preset is selected
    if (
      isDateRange(userSelectedPreset) &&
      this._presetForCustomValue.start === userSelectedPreset.start &&
      this._presetForCustomValue.start === userSelectedPreset.end
    ) {
      this._selectedPreset = this._presetForCustomValue;
    }
  }

  /** @private */
  _getOpenedState() {
    return this.touchUi === true ? 'dialog-opened' : 'dropdown-opened';
  }
}
