<!-- TODO p3 add language-selector -->
<div class="bsz-text-center bsz-margin-t12">
  <img class="bsz-padding-b6" src="./assets/img/app-logo.png" width="300" />
  <div class="bsz-padding-b10 bsz-title-6">{{ 'web-app-common.auth-jwt.email-verify.title' | translate }}</div>
  <div class="bsz-padding-b10 bsz-title-5">
    <ng-container *ngIf="submitLoadingSubject$ | async">
      <bsz-progress-bar
        [message]="'web-app-common.auth-jwt.email-verify.email-verify-loading' | translate"
        [hasBackdrop]="false"
      >
      </bsz-progress-bar>
    </ng-container>
    <ng-container *ngIf="(submitLoadingSubject$ | async) === false">
      <ng-container *ngIf="emailVerifyStatus === ApiHttpErrorCodes.emailVerifyError">{{
        'web-app-common.auth-jwt.email-verify.email-verify-error.unknown' | translate
      }}</ng-container>
      <ng-container *ngIf="emailVerifyStatus === ApiHttpErrorCodes.emailVerifyAlreadyDone">
        <div>
          {{ 'web-app-common.auth-jwt.email-verify.email-verify-error.already-done' | translate }}
        </div>
        <div class="bsz-margin-t5">
          {{ 'web-app-common.auth-jwt.email-verify.go-to-signin-page' | translate }}
        </div>
        <div class="bsz-display-flex bsz-justify-center bsz-padding-t12">
          <button mat-flat-button color="accent" (click)="goToSignin.emit()">
            <div class="bsz-display-flex bsz-justify-center">
              <span>
                {{ 'web-app-common.auth-jwt.email-verify.sign-in' | translate }}
              </span>
            </div>
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="emailVerifyStatus === 'success'">
        <div>
          {{ 'web-app-common.auth-jwt.email-verify.email-verify-success' | translate }}
        </div>
        <div class="bsz-margin-t5">
          {{ 'web-app-common.auth-jwt.email-verify.go-to-signin-page' | translate }}
        </div>
        <div class="bsz-display-flex bsz-justify-center bsz-padding-t12">
          <button mat-flat-button color="accent" (click)="goToSignin.emit()">
            <div class="bsz-display-flex bsz-justify-center">
              <span>
                {{ 'web-app-common.auth-jwt.email-verify.sign-in' | translate }}
              </span>
            </div>
          </button>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="bsz-display-flex bsz-justify-center bsz-padding-b12">
    <div *ngIf="showHelp" class="bsz-margin-t12 mat-elevation-z4 help-button">
      <button mat-button (click)="openHelp()">
        <img src="./assets/img/customer-service/whatsapp-logo.png" alt="help" />
        <span class="bsz-padding-r3 bsz-text-bold bsz-subtitle-2">
          {{ 'web-app-common.auth-jwt.signin.help' | translate }}
        </span>
      </button>
    </div>
  </div>
</div>
