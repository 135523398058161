import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import {TranslatedText} from '@basuiz/web-app-applet-api';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {MatExpansionPanel} from '@angular/material/expansion';

@Component({
  selector: 'bsz-form-panel',
  templateUrl: './bsz-form-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BszFormPanelComponent {
  @Input() title: TranslatedText = '';

  @Input() actionsTemplate: TemplateRef<unknown> | undefined = undefined;

  private _expanded = false;
  @Input()
  get expanded() {
    return this._expanded;
  }

  set expanded(value: BooleanInput) {
    this._expanded = coerceBooleanProperty(value);
  }

  @Output() expandedChange: EventEmitter<boolean> = new EventEmitter();

  private _collapsible = true;
  @Input()
  get collapsible() {
    return this._collapsible;
  }

  set collapsible(value: BooleanInput) {
    this._collapsible = coerceBooleanProperty(value);
  }

  @ViewChild(MatExpansionPanel) expansionPanel: MatExpansionPanel;

  onToggle() {
    if (!this.collapsible) {
      this.expansionPanel.toggle();
    } else {
      this.expanded = this.expansionPanel.expanded;
      this.expandedChange.emit(this.expanded);
    }
  }

  onActionClick(event: MouseEvent) {
    // this prevents the panel from toggling
    event.stopPropagation();
  }
}
