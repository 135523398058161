import {NgModule} from '@angular/core';
import {BreadcrumbsComponent} from './breadcrumbs.component';
import {HistoryBackComponent} from './history-back/history-back.component';
import {HistoryBreadcrumbsComponent} from './history-trail/history-breadcrumbs.component';
import {HierarchicalBreadcrumbsComponent} from './hierarchical-trail/hierarchical-breadcrumbs.component';
import {PortalBasicModule} from '../../../util/portal-basic.module';
import {BreadcrumbSeparatorComponent} from './breadcrumb-separator.component';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';

@NgModule({
  declarations: [
    BreadcrumbsComponent,
    HierarchicalBreadcrumbsComponent,
    HistoryBackComponent,
    HistoryBreadcrumbsComponent,
    BreadcrumbSeparatorComponent,
    BreadcrumbComponent,
  ],
  imports: [PortalBasicModule],
  exports: [BreadcrumbsComponent],
})
export class BreadcrumbsModule {}
