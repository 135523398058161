<mat-nav-list class="bsz-navigation-list" *ngIf="mainNavElements$ | async as mainNavElements">
  <mat-accordion class="bsz-navigation-accordion" [displayMode]="'flat'">
    <ng-container *ngFor="let navElement of mainNavElements">
      <mat-divider *ngIf="navElement.type === 'divider'"></mat-divider>

      <ng-container *ngIf="navElement.type === 'navItem'">
        <bsz-nav-item
          [navItem]="navElement"
          [active]="navElement.active"
          bszTestId="{{ navElement.label }}"
        ></bsz-nav-item>
      </ng-container>

      <ng-container *ngIf="navElement.type === 'navGroup'">
        <mat-expansion-panel class="mat-elevation-z0" [expanded]="navElement.active">
          <mat-expansion-panel-header class="nav-group" [ngClass]="{active: navElement.active}">
            <mat-panel-title>
              <mat-icon class="nav-icon" [svgIcon]="navElement.svgIcon || undefined">
                {{ navElement.svgIcon ? undefined : navElement.icon || defaultIcon }}
              </mat-icon>
              <span>{{ navElement.label | translate }}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-nav-list>
            <ng-container *ngFor="let navChildItem of navElement.children">
              <bsz-nav-item [navItem]="navChildItem" [active]="navChildItem.active" [childItem]="true"></bsz-nav-item>
            </ng-container>
          </mat-nav-list>
        </mat-expansion-panel>
      </ng-container>
    </ng-container>
  </mat-accordion>

  <bsz-regulatory-links></bsz-regulatory-links>

  <div *ngIf="copyright" class="bsz-text-center bsz-text-secondary">{{ copyright.text | translate }}</div>
</mat-nav-list>
