<div class="bsz-display-flex bsz-justify-center">
  <div class="bsz-text-secondary bsz-text-center">
    <mat-icon *ngIf="icon" class="bsz-central-message-icon" bszTestId="web-app-common-bsz-central-message-icon">
      {{ icon }}
    </mat-icon>
    <mat-icon
      *ngIf="svgIconLabel"
      class="bsz-central-message-icon"
      bszTestId="web-app-common-bsz-central-message-svg-icon-label"
      svgIcon="{{ svgIconLabel }}"
    ></mat-icon>
    <div class="bsz-subtitle-1 bsz-padding-t4" bszTestId="web-app-common-bsz-central-message-title">{{ title }}</div>
    <div class="bsz-body-1" bszTestId="web-app-common-bsz-central-message-info">{{ info }}</div>
    <div *ngIf="additionalInfo">
      <div class="bsz-display-flex bsz-justify-center bsz-margin-t6 bsz-margin-b4">
        <mat-divider class="bsz-central-message-divider"></mat-divider>
      </div>
      <div class="bsz-caption" bszTestId="web-app-common-bsz-central-message-additional-info">{{ additionalInfo }}</div>
    </div>
  </div>
</div>
