import {PortalModule} from '@angular/cdk/portal';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {BszI18nModule} from '@basuiz/i18n';

import {BszNotificationOverlayModule} from '../bsz-notification-overlay';
import {BszSnackbar} from './bsz-snackbar';
import {BszSnackbarService} from './bsz-snackbar.service';

@NgModule({
  imports: [CommonModule, BszI18nModule, MatButtonModule, PortalModule, BszNotificationOverlayModule],
  declarations: [BszSnackbar],
  entryComponents: [BszSnackbar],
  providers: [BszSnackbarService],
})
export class BszSnackbarModule {}
