import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  ViewEncapsulation,
} from '@angular/core';

@Directive({
  selector: 'bsz-micro-card-actions',
})
export class BszMicroCardActions {}

@Component({
  selector: 'bsz-micro-card',
  templateUrl: './bsz-micro-card.html',
  styleUrls: ['./bsz-micro-card.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'bsz-micro-card',
  },
})
export class BszMicroCard {
  @ContentChild(BszMicroCardActions)
  get bszCardItemActions() {
    return this._bszCardItemActions;
  }
  set bszCardItemActions(bszCardItemActions: BszMicroCardActions | null) {
    this._bszCardItemActions = bszCardItemActions;

    // trigger the change detection, so the ngClass for .bsz-micro-card-content-with-actions will be updated
    this.cd.markForCheck();
  }
  private _bszCardItemActions: BszMicroCardActions | null = null;

  constructor(private cd: ChangeDetectorRef) {}
}
