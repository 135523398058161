<!-- TODO p3 add language-selector -->
<div class="mdc-layout-grid">
  <div class="mdc-layout-grid__inner">
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone"
    >
      <ng-container *ngIf="isDesktop$ | async">
        <bsz-auth-marketing></bsz-auth-marketing>
      </ng-container>
    </div>

    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone"
    >
      <div class="bsz-text-center bsz-margin-t12 center-form">
        <div class="bsz-padding-b10 bsz-title-6">{{ 'web-app-common.auth-jwt.signin.title' | translate }}</div>
        <div class="bsz-padding-b4">
          <form [formGroup]="signinForm" autocomplete="off">
            <div class="mdc-layout-grid" class="centered-grid">
              <div class="mdc-layout-grid__inner grid-form">
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                  <mat-form-field class="bsz-width-100pct">
                    <mat-label>
                      {{ 'web-app-common.auth-jwt.signin.form.email' | translate }}
                    </mat-label>
                    <input
                      matInput
                      autocomplete="off"
                      type="email"
                      [formControl]="getFormControl('email')"
                      clearInput
                      autocapitalize="off"
                      inputmode="email"
                      (keyup.enter)="doSignin()"
                    />
                    <mat-error>
                      <bsz-form-field-errors [control]="getFormControl('email')"></bsz-form-field-errors>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                  <mat-form-field class="bsz-width-100pct">
                    <mat-label>
                      {{ 'web-app-common.auth-jwt.signin.form.password' | translate }}
                    </mat-label>
                    <input
                      matInput
                      autocomplete="off"
                      type="password"
                      [formControl]="getFormControl('password')"
                      (keyup.enter)="doSignin()"
                    />
                    <mat-error>
                      <bsz-form-field-errors [control]="getFormControl('password')"></bsz-form-field-errors>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                  <button
                    class="bsz-width-100pct"
                    mat-flat-button
                    color="accent"
                    [disabled]="submitLoadingSubject$ | async"
                    (click)="doSignin()"
                  >
                    <div class="bsz-display-flex bsz-justify-center">
                      <span>
                        {{ 'web-app-common.auth-jwt.signin.form.signin' | translate }}
                      </span>
                      <mat-spinner
                        *ngIf="submitLoadingSubject$ | async"
                        diameter="20"
                        class="bsz-margin-l2 bsz-margin-t2"
                      ></mat-spinner>
                    </div>
                  </button>
                </div>
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 bsz-text-right">
                  <div class="bsz-margin-t4">
                    <a
                      bszAccessibleLink
                      class="bsz-text-accent bsz-cursor-pointer bsz-text-bold"
                      (click)="goToResetPasswordRequest.emit()"
                    >
                      {{ 'web-app-common.auth-jwt.signin.form.forgot-password' | translate }}
                    </a>
                  </div>
                </div>
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                  <div class="bsz-padding-t12 bsz-subtitle-2">
                    <span>{{ 'web-app-common.auth-jwt.signin.form.dont-have-an-account' | translate }}</span>
                    <a
                      bszAccessibleLink
                      class="bsz-margin-l2 bsz-text-accent bsz-cursor-pointer bsz-text-bold"
                      (click)="goToSignup.emit()"
                    >
                      {{ 'web-app-common.auth-jwt.signin.form.signup' | translate }}
                    </a>
                  </div>
                </div>
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                  <div *ngIf="showHelp" class="help-button bsz-margin-t12 mat-elevation-z4 bsz-width-40pct">
                    <button mat-button (click)="openHelp()">
                      <img src="./assets/img/customer-service/whatsapp-logo.png" alt="help" />
                      <span class="bsz-padding-r3 bsz-text-bold bsz-subtitle-2">
                        {{ 'web-app-common.auth-jwt.signin.help' | translate }}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
      <ng-container *ngIf="!(isDesktop$ | async)">
        <bsz-auth-marketing></bsz-auth-marketing>
      </ng-container>
    </div>
  </div>
</div>
