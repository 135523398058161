import moment from 'moment';

export function isEmptyObject(data: any): boolean {
  if (data === undefined || data === null) {
    return true;
  }
  return (
    Object.entries(data).filter(([key, value]) => {
      if ((typeof value === 'object' || typeof value === 'function') && !moment.isMoment(value) && value !== null) {
        return !isEmptyObject(value);
      }
      return value === 0 || !!value;
    }).length === 0
  );
}
