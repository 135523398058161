import {Injectable} from '@angular/core';

import {
  AboveTableContentContainer,
  ColumnDefinitionForPdf,
  ColumnSummaryCell,
  DataAccessDefinition,
  LayoutCell,
  LayoutContainer,
  LayoutElement,
  LayoutElementOfParagraph,
  LayoutElementOfSvg,
  LayoutElementOfTable,
  LayoutRow,
  NumberParagraphValueModel,
  ParagraphValueModel,
  StringParagraphValueModel,
  SvgValueModel,
} from '../definitions/index';

import {LayoutElementType, ParagraphFormat, ParagraphType} from '../enums/index';
import {DataCellModel} from '../definitions/export-to-file-payload.definition';

@Injectable({
  providedIn: 'root',
})
export class ExportToFilePayloadComposerService {
  // PDF RELATED BASIC METHODS FOR CLIENT DATA
  public createContainer(
    styleClasses: string[] = [],
    headerRows: LayoutRow[] = [],
    rows: LayoutRow[] = []
  ): LayoutContainer {
    return {
      styleClass: styleClasses.join(' '),
      headerRows: headerRows,
      rows: rows,
    };
  }

  public createRow(styleClasses: string[] = [], cells: LayoutCell[] = []): LayoutRow {
    return {
      styleClass: styleClasses.join(' '),
      cells: cells,
    };
  }

  public createCell(styleClasses: string[] = [], elements: LayoutElement[] = []): LayoutCell {
    return {
      styleClass: styleClasses.join(' '),
      elements: elements,
    };
  }

  public createParagraph(
    styleClasses: string[] = [],
    value: string,
    valueFormat: ParagraphFormat,
    model?: ParagraphValueModel
  ): LayoutElementOfParagraph {
    return {
      styleClass: styleClasses.join(' '),
      type: LayoutElementType.PARAGRAPH,
      value: value,
      valueFormat: valueFormat,
      model: model,
    };
  }

  public createSvgImage(styleClasses: string[] = [], value: string, model?: SvgValueModel): LayoutElementOfSvg {
    return {
      styleClass: styleClasses.join(' '),
      type: LayoutElementType.SVG_IMAGE,
      value: value,
      model: model,
    };
  }

  public createTable(styleClasses: string[] = [], container: LayoutContainer): LayoutElementOfTable {
    return {
      styleClass: styleClasses.join(' '),
      type: LayoutElementType.TABLE,
      tableValue: container,
    };
  }

  // PDF RELATED COMPLEX METHODS FOR CLIENT DATA
  public createRowWithLabeledSvgChart(styleClasses: string[], title: string, svgData: string): LayoutRow {
    return this.createRow(
      ['layoutRow'],
      [
        this.createCell(
          ['layoutCell'],
          [
            this.createTable(
              ['layoutElement'],
              this.createContainer(
                styleClasses || ['dataTable'],
                [],
                svgData
                  ? [
                      this.createRow(
                        ['labelRow'],
                        [
                          this.createCell(
                            ['labelCell'],
                            [this.createParagraph(['labelElement'], title, ParagraphFormat.STRING_DEFAULT)]
                          ),
                        ]
                      ),
                      this.createRow(
                        ['svgRow'],
                        [this.createCell(['svgCell'], [this.createSvgImage(['svgData'], svgData)])]
                      ),
                    ]
                  : []
              )
            ),
          ]
        ),
      ]
    );
  }

  // PDF RELATED SIMPLE METHODS FOR SERVER DATA
  public createAboveTableContentContainer(
    styleClasses: string[] = [],
    rows: LayoutRow[] = []
  ): AboveTableContentContainer {
    return {
      styleClass: styleClasses.join(' '),
      rows: rows,
    };
  }

  public createDataAccessDefinition(dataPath: string, paragraphFormat: ParagraphFormat): DataAccessDefinition {
    return {
      dataPath: dataPath,
      valueFormat: paragraphFormat,
    };
  }

  public createColumnSummaryCell(styleClasses: string[] = [], dataPath: string): ColumnSummaryCell {
    const styleClass = styleClasses.length > 0 ? styleClasses.join(' ') : undefined;
    return {
      styleClass: styleClass,
      dataPath: dataPath,
    };
  }

  public createColumnDefinition(
    headerNames: string[],
    headerStyleClasses: string[],
    dataStyleClasses: string[],
    dataAccessDefinitions: DataAccessDefinition[],
    bottomCells: ColumnSummaryCell[] = []
  ): ColumnDefinitionForPdf {
    return {
      headerNames: headerNames,
      headerStyleClass: headerStyleClasses.join(' '),
      dataStyleClass: dataStyleClasses.join(' '),
      dataAccessDefinitions: dataAccessDefinitions,
      bottomCells: bottomCells.length > 0 ? bottomCells : undefined,
    };
  }

  public createStringParagraphValueModel(value: string): StringParagraphValueModel {
    return {
      type: ParagraphType.STRING,
      value: value,
    };
  }

  public createNumberParagraphValueModel(
    value: number,
    options: {
      showZeros?: boolean;
      decimalPositions?: number;
    } = {}
  ): NumberParagraphValueModel {
    const defaultOptions = {
      showZeros: true,
      decimalPositions: 2,
    };
    const {showZeros, decimalPositions} = {
      ...defaultOptions,
      ...options,
    };
    return {
      type: ParagraphType.NUMBER,
      value,
      showZeros,
      decimalPositions,
    };
  }

  public createHeaderRow(text: string, textStyles: string[]): LayoutRow {
    return this.createRow(
      ['detailsRow'],
      [
        this.createCell(
          ['detailsCell'],
          [this.createParagraph(['detailsElement', ...textStyles], text, ParagraphFormat.STRING_DEFAULT, undefined)]
        ),
      ]
    );
  }

  public createDataRowFromCells(rowStyleClass: string, dataCellModels: DataCellModel[] = []): LayoutRow {
    return this.createRow(
      [rowStyleClass],
      dataCellModels.map((cellModel, i) =>
        this.createCell(
          [`dataCell n-${i} ${cellModel.styleClass}`.trim()],
          [
            this.createParagraph(
              ['dataElement n-0'],
              cellModel.value,
              ParagraphFormat.STRING_DEFAULT,
              this.createStringParagraphValueModel(cellModel.value)
            ),
          ]
        )
      )
    );
  }
}
