import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  OnDestroy,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {delay, startWith} from 'rxjs/operators';

import {BszScreenSize, ScreenSize} from '../bsz-screen-size-content-switcher/index';

@Directive({
  selector: '[bszPrimaryActionDef]',
})
export class BszPrimaryActionDef {}

@Directive({
  selector: '[bszSecondaryActionDef]',
})
export class BszSecondaryActionDef {}

@Directive({
  selector: '[bszCancelActionDef]',
})
export class BszCancelActionDef {}

@Component({
  selector: 'bsz-action-bar',
  styleUrls: ['./bsz-action-bar.scss'],
  templateUrl: './bsz-action-bar.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'bsz-action-bar',
  },
})
export class BszActionBar implements AfterViewInit, OnDestroy {
  /** @private */
  secondaryActionButtons: TemplateRef<unknown>[] = [];
  /** @private */
  secondaryActionMenuItems: HTMLButtonElement[] = [];

  /** Template definition of the Primary Action Button. */
  @ContentChild(BszPrimaryActionDef, {read: TemplateRef})
  // Using getter/setter here so we can trigger change
  // detection in case the template is dynamic or
  // rendered conditionally (eg. via an *ngIf)
  get primaryActionButtonRef() {
    return this._primaryActionButtonRef;
  }
  set primaryActionButtonRef(primaryActionButton: TemplateRef<void> | null) {
    this._primaryActionButtonRef = primaryActionButton;
    this.cd.markForCheck();
  }
  private _primaryActionButtonRef: TemplateRef<void> | null = null;

  /** Template definitions of the Secondary Action Buttons. */
  @ContentChildren(BszSecondaryActionDef, {read: TemplateRef})
  secondaryActionButtonsRef!: QueryList<TemplateRef<unknown>>;

  /** Template definition of the Cancel Action Button. */
  @ContentChild(BszCancelActionDef, {read: TemplateRef})
  // Using getter/setter here so we can trigger change
  // detection in case the template is dynamic or
  // rendered conditionally (eg. via an *ngIf)
  get cancelActionButtonRef() {
    return this._cancelActionButtonRef;
  }
  set cancelActionButtonRef(cancelActionButton: TemplateRef<void> | null) {
    this._cancelActionButtonRef = cancelActionButton;
    this.cd.markForCheck();
  }
  private _cancelActionButtonRef: TemplateRef<void> | null = null;

  // ng-container used to render secondary action buttons to be used in the menu
  @ViewChild('secondaryActionsViewContainer', {read: ViewContainerRef})
  secondaryActionsViewContainer!: ViewContainerRef;

  private subscription: Subscription | null = null;

  constructor(private screenSizeService: BszScreenSize, private cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    // QueryList.changes is not a Subject, so we have to add a new event at the beginning
    const secondaryActionButtonsRefChange = this.secondaryActionButtonsRef.changes.pipe(startWith(1));

    // re-generate secondary actions when either screen size or secondary action button ref change
    this.subscription = combineLatest([this.screenSizeService.getScreenSize(), secondaryActionButtonsRefChange])
      .pipe(
        delay(0) // to prevent "Expression has changed after it was checked." error
      )
      .subscribe(([size]) => {
        this.generateSecondaryActions(size);
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private generateSecondaryActions(screenSize: ScreenSize) {
    const threshold = this.getThreshold(screenSize);
    this.secondaryActionButtons = this.getSecondaryActionButtons(threshold);
    this.secondaryActionMenuItems = this.getSecondaryActionMenuItems(threshold);
  }

  private getThreshold(screenSize: ScreenSize): number {
    let threshold: number;

    switch (screenSize) {
      case 'desktop': {
        threshold = 3;
        break;
      }
      case 'tablet': {
        threshold = 2;
        break;
      }
      case 'mobile': {
        threshold = 0;
        break;
      }
    }

    // there must never be a single menu item, either no menu or more than 1 item
    if (this.secondaryActionButtonsRef.length - threshold === 1) {
      threshold = threshold > 2 ? threshold - 1 : threshold + 1;
    }

    return threshold;
  }

  private getSecondaryActionButtons(threshold: number): TemplateRef<any>[] {
    return this.secondaryActionButtonsRef
      .toArray()
      .reverse()
      .filter((button, i) => i < threshold)
      .reverse();
  }

  /**
   * We have to use a viewContainer to dynamically create the menu item elements from the
   * secondary action buttons templateRefs, because otherwise interpolation inside the
   * templateRefs does not work.
   * Also for this reason each dynamically created element needs to stay in the viewContainer
   * for as long as it is being used in the action bar's template, so we just hide the
   * viewContainer and only clear it before we generate new secondary action menu items.
   */
  private getSecondaryActionMenuItems(threshold: number): HTMLButtonElement[] {
    this.secondaryActionsViewContainer.clear();

    const buttons = this.secondaryActionButtonsRef
      .toArray()
      .reverse()
      .filter((button, i) => i >= threshold)
      .map((buttonTemplateRef) => {
        const buttonViewRef = this.secondaryActionsViewContainer.createEmbeddedView(buttonTemplateRef);
        return buttonViewRef.rootNodes[0];
      })
      .reverse();

    // this is needed for Angular to re-render the menu items in some cases (e.g. when the menu is open
    // and screen is resized); without this the menu items would be empty...
    this.cd.detectChanges();

    return buttons;
  }
}
