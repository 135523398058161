import {AfterContentChecked, AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild} from '@angular/core';
import * as Highcharts from 'highcharts';
import {Chart, Options} from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import {BehaviorSubject, Observable, ReplaySubject, Subscription} from 'rxjs';
import {map, mapTo, startWith} from 'rxjs/operators';
import {TranslatedText} from '@basuiz/web-app-applet-api';
import {ChartHeaderAction} from './chart/chart-header-action.definition';
import {isEmptyObject} from '../utils/empty-object';

/*
 * Workaround: Out-of-view plot lines make the svg invalid, apply workaround as proposed in
 * https://github.com/highcharts/highcharts/issues/17431
 * TODO remove workaround once the issue is solved in Highcharts
 */
(function (H) {
  H.wrap(H.Axis.prototype, 'getPlotLinePath', function (proceed, options) {
    return proceed.apply(this, [options]) || 'M 0 0';
  });
})(Highcharts);

@Component({
  selector: 'bsz-web-app-chart',
  templateUrl: './web-app-chart.component.html',
  styleUrls: ['./web-app-chart.component.scss'],
})
export class WebAppChartComponent implements AfterViewInit, AfterContentChecked, OnDestroy {
  @ViewChild('chartContainer')
  chartContainer: ElementRef;

  @Input()
  title: TranslatedText;

  @Input()
  tooltipText: TranslatedText;

  @Input()
  set options(value: Options) {
    if (value) {
      this.optionsSubject.next(value);
    }
  }

  @Input()
  headerAction: ChartHeaderAction | undefined = undefined;

  @Input()
  displayInCard: boolean = true;

  private readonly optionsSubject = new ReplaySubject<Options>(1);

  readonly options$ = this.optionsSubject.asObservable();

  readonly loading$ = this.options$.pipe(mapTo(false), startWith(true));

  private readonly chartSubject = new BehaviorSubject<Chart | undefined>(undefined);

  readonly chart$ = this.chartSubject.asObservable();

  private lastDOMRect: DOMRect;

  private subscription: Subscription = new Subscription();

  constructor() {
    HC_exporting(Highcharts);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.chartSubject.next(undefined);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.subscription.add(
        this.options$
          .pipe(
            map((options) => {
              this.chartContainer.nativeElement.style.display = isEmptyObject(options) ? 'none' : 'block';
              return new Chart(this.chartContainer.nativeElement, options);
            })
          )
          .subscribe((chart) => this.chartSubject.next(chart))
      );
    });
  }

  ngAfterContentChecked() {
    setTimeout(() => {
      const currentDOMRect: DOMRect = this.chartContainer.nativeElement.getBoundingClientRect();
      const comparisonKeys: (keyof DOMRect)[] = ['width', 'height'];

      if (
        !this.lastDOMRect ||
        comparisonKeys.some((comparisonKey) => this.lastDOMRect[comparisonKey] !== currentDOMRect[comparisonKey])
      ) {
        this.lastDOMRect = currentDOMRect;
        this.chartSubject.value?.reflow();
      }
    });
  }

  getSVG(options?: Options): Observable<string | undefined> {
    return this.chartSubject.pipe(map((chart) => chart?.getSVG(options)));
  }
}
