import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, FEATURE_KEY, State} from './saved-filter.reducer';
import {SavedFilterKey} from '../models/saved-filter.interface';
import {LoadingState} from '../../definitions/call-state.definition';

export const selectState = createFeatureSelector<State>(FEATURE_KEY);

const adapterSelectors = adapter.getSelectors();

export const selectEntities = createSelector(
  selectState,
  (state: State, props: {savedFilterKey: SavedFilterKey}) =>
    state[props.savedFilterKey] && adapterSelectors.selectAll(state[props.savedFilterKey])
);

export const selectEntitiesDict = createSelector(
  selectState,
  (state: State, props: {savedFilterKey: SavedFilterKey}) =>
    state[props.savedFilterKey] && adapterSelectors.selectEntities(state[props.savedFilterKey])
);

export const selectSelectedSavedFilter = createSelector(
  selectState,
  selectEntitiesDict,
  (state, entities, props: {savedFilterKey: SavedFilterKey}) =>
    entities && state[props.savedFilterKey] && entities[state[props.savedFilterKey].selected]
);

export const selectSelectedSavedFilterName = createSelector(
  selectState,
  (state: State, props: {savedFilterKey: SavedFilterKey}) =>
    state[props.savedFilterKey] && state[props.savedFilterKey].selected
);

export const selectSavedFilterCallState = createSelector(
  selectState,
  (state: State, props: {savedFilterKey: SavedFilterKey}) => state[props.savedFilterKey]?.callState || LoadingState.INIT
);
