import {createFeatureSelector, createSelector} from '@ngrx/store';
import {getErrorMessage, LoadingState} from '@basuiz/web-app-common';
import {adapter, FEATURE_KEY, State} from './notifications.reducer';
import {Dictionary} from '@ngrx/entity';
import {Notification, NotificationMessageStatus} from '../../models/notification.definition';
import moment from 'moment';

const selectFeatureState = createFeatureSelector<State>(FEATURE_KEY);
const adapterSelectors = adapter.getSelectors();

export const selectRawNotifications = createSelector(selectFeatureState, adapterSelectors.selectAll);

export const selectNotifications = createSelector(selectRawNotifications, (notifications: Notification[]) =>
  notifications.map((notification: Notification) => ({
    ...notification,
    dateOfGeneration: moment(notification.dateOfGeneration),
  }))
);

export const selectNotificationDictionary = createSelector(selectFeatureState, adapterSelectors.selectEntities);
export const selectById = createSelector(
  selectNotificationDictionary,
  (dictionary: Dictionary<Notification>, notification: {id: number}) => dictionary[notification.id]
);

export const selectInboxNotifications = createSelector(selectNotifications, (notifications) =>
  notifications.filter((notification) =>
    [NotificationMessageStatus.NEW, NotificationMessageStatus.READ, NotificationMessageStatus.UNREAD].includes(
      notification.status
    )
  )
);

export const selectNewNotifications = createSelector(selectNotifications, (notifications) =>
  notifications.filter((notification) =>
    [NotificationMessageStatus.NEW, NotificationMessageStatus.UNREAD].includes(notification.status)
  )
);

export const selectLoading = createSelector(
  selectFeatureState,
  (state: State) => state.callState === LoadingState.LOADING
);

export const selectLoaded = createSelector(
  selectFeatureState,
  (state: State) => state.callState === LoadingState.LOADED
);

export const selectErrorMessage = createSelector(selectFeatureState, (state) => getErrorMessage(state.callState));
