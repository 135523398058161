import {createFeatureSelector, createSelector} from '@ngrx/store';
import {FEATURE_KEY, State} from './bank-date.reducer';

export const selectState = createFeatureSelector<State>(FEATURE_KEY);

export const selectLoaded = createSelector(selectState, (state: State) => state.loaded);

export const selectLoading = createSelector(selectState, (state: State) => state.loading);

export const selectBankDate = createSelector(selectState, (state: State) => state.bankDate);
