import {createFeatureSelector, createSelector} from '@ngrx/store';
import {FEATURE_KEY, State} from './default-currency.reducer';

export const selectState = createFeatureSelector<State>(FEATURE_KEY);

export const selectLoaded = createSelector(selectState, (state: State) => state.loaded);

export const selectLoading = createSelector(selectState, (state: State) => state.loading);

export const selectDefaultCurrency = createSelector(selectState, (state: State) => state.defaultCurrency);

export const selectCurrencyId = createSelector(selectState, (state: State) => state.ids);

export const selectError = createSelector(selectState, (state: State) => state.error);
