import {
  DataState,
  DataStateAvailable,
  DataStateError,
  DataStateLoading,
  DataStateNoData,
} from './data-state.definition';

export abstract class DataStateContext<DATA_STATE extends DataState<unknown>> {
  protected _dataState: DATA_STATE;

  constructor(dataState: DATA_STATE) {
    this._dataState = dataState;
  }

  set dataState(dataState: DATA_STATE) {
    this._dataState = dataState;
  }
}

export class AvailableContext<T> extends DataStateContext<DataStateAvailable<T>> {
  get data(): T {
    return this._dataState.data;
  }

  get $implicit(): T {
    return this.data;
  }
}

export class LoadingContext extends DataStateContext<DataStateLoading> {
  get reservedHeightPx(): DataStateLoading['reservedHeightPx'] {
    return this._dataState.reservedHeightPx;
  }

  get message(): DataStateLoading['message'] {
    return this._dataState.message;
  }
}

export class NoDataContext extends DataStateContext<DataStateNoData> {
  get message(): DataStateNoData['message'] {
    return this._dataState.message;
  }
  get webAppCustomIcon(): DataStateNoData['webAppCustomIcon'] {
    return this._dataState.webAppCustomIcon;
  }
}

export class ErrorContext extends DataStateContext<DataStateError> {}
