import {NavigationIntent} from '../../navigation/classes/navigation-intent';

export interface ShowAuthSignupIntentPayload {
  foo?: never;
}

export class ShowAuthSignupIntent extends NavigationIntent {
  constructor(public payload: ShowAuthSignupIntentPayload = {}) {
    super();
  }
}
