import {Options, Point, TooltipPositionerPointObject} from 'highcharts';
import {Moment} from 'moment';
import {merge} from 'lodash';
import {createChartBaseOptions} from './chart';
import {BszCurrencyPipe, BszPercentagePipe} from '../../formatting/index';
import {Injectable} from '@angular/core';
import {ChartMomentFormatService, ChartPeriod} from './chart-moment-format.service';
import {getNegativeChartColor, getPrimaryColor, getRawPrimaryChartColor, hexToRGB} from './chart-theme';

@Injectable()
export class TimeSeriesChartBuilder {
  constructor(
    private bszPercentagePipe: BszPercentagePipe,
    private bszCurrencyPipe: BszCurrencyPipe,
    private chartMomentFormatService: ChartMomentFormatService
  ) {}

  build(series: TimeSeriesChartData): Options {
    const bszPercentagePipe = this.bszPercentagePipe;
    const bszCurrencyPipe = this.bszCurrencyPipe;

    const values = series.entities.map((entity) => entity.value);
    const min = Math.min(...values);
    const max = Math.max(...values);

    const xAxisFirstLineFormat = this.chartMomentFormatService.getFirstLineXAxisFormat(series.chartPeriod);
    const xAxisSecondLineFormat = this.chartMomentFormatService.getSecondLineXAxisFormat(series.chartPeriod);
    const tooltipFormat = this.chartMomentFormatService.getTooltipFormat(series.chartPeriod);

    const negativeColor = getNegativeChartColor();

    const options = merge(createChartBaseOptions(), {
      chart: {
        type: series.type,
        marginTop: 50,
      },
      plotOptions: {
        series: {
          color: getRawPrimaryChartColor()
            .split(' ')
            .map((s) => s.trim())
            .filter((s) => s.length > 0)[0],
          negativeColor: series.type === 'column' ? negativeColor : undefined,
          negativeFillColor:
            series.type !== 'column'
              ? (() => {
                  const canvas = document.createElement('canvas');
                  canvas.height = 1;
                  canvas.width = 1;
                  const context = canvas.getContext('2d');
                  if (context) {
                    context.fillStyle = negativeColor;
                    context.fillRect(0, 0, 1, 1);
                    const [r, g, b] = context.getImageData(0, 0, 1, 1).data;
                    return `rgba(${r},${g},${b},0.25)`;
                  }
                })()
              : undefined,
          fillOpacity: 0.25,
          pointWidth: 20,
          marker: {
            enabled: false,
            radius: 6,
            lineWidth: 1,
            lineColor: 'white',
          },
          states: {
            hover: {
              halo: {
                size: 0,
              },
            },
          },
        },
      },
      xAxis: {
        type: 'datetime',
        tickPixelInterval: 120,
        labels: {
          formatter: function () {
            let value: Moment;
            let previousTickValue: Moment | undefined;
            if (this.axis.categories) {
              value = this.value;
              previousTickValue = this.axis.categories[this.pos - 1];
            } else {
              value = series.entities[this.value].moment;
              const previousTickUnix = this.axis.tickPositions[this.axis.tickPositions.indexOf(this.value) - 1];
              if (previousTickUnix !== undefined) {
                previousTickValue = series.entities[previousTickUnix].moment;
              }
            }

            let lowerLabel = formatSecondLine(value);
            if (
              previousTickValue &&
              formatSecondLine(previousTickValue) === lowerLabel &&
              series.xAxisTickLabel?.onlyShowSecondLineIfDistinct !== false
            ) {
              lowerLabel = '';
            }

            return `${value.format(xAxisFirstLineFormat)}<br>${lowerLabel}`;

            function formatSecondLine(m: Moment): string {
              return m.format(xAxisSecondLineFormat);
            }
          },
        },
        plotBands: this.getXPlotBands(series.entities, series.type),
      },
      yAxis: {
        min,
        max,
        labels: {
          formatter: function () {
            if (series.yAxisTickLabel?.currencyIsoCode) {
              const maxAbsAxisValue = Math.max(...[this.axis.min, this.axis.max].map(Math.abs));
              const symbolData = ['B', 'M', 'K']
                .map((symbol, index, symbols) => ({
                  symbol,
                  divisor: Math.pow(10, 3 * (symbols.length - index)),
                }))
                .find(({divisor}) => maxAbsAxisValue / divisor >= 1) || {
                symbol: '',
                divisor: 1,
              };
              return (this.value / symbolData.divisor).toFixed(2) + symbolData.symbol;
            } else {
              return bszPercentagePipe.transform(this.value, '1.0-2');
            }
          },
        },
      },
      series: [
        {
          data: series.entities.map((entity) => ({
            y: entity.value,
            entity,
          })),
        },
      ],
      tooltip: {
        shared: true,
        useHTML: true,
        borderWidth: 0,
        borderRadius: 4,
        padding: 0,
        positioner: function (width: number, height: number, point: TooltipPositionerPointObject) {
          const pointX = point.plotX - width / 2 + this.chart.plotBox.x;
          const minX = this.chart.plotBox.x;
          const maxX = this.chart.plotBox.x + this.chart.plotBox.width - width;
          const x = Math.max(Math.min(pointX, maxX), minX);
          const y = this.chart.spacingBox.y;
          return {x, y};
        },
        formatter: function () {
          const point = this.points[0].point as Point & {entity: TimeSeriesChartData['entities'][0]};

          let value;
          if (series.yAxisTickLabel?.currencyIsoCode) {
            value = bszCurrencyPipe.transform(point.entity.value, series.yAxisTickLabel?.currencyIsoCode);
          } else {
            value = bszPercentagePipe.transform(point.entity.value, '1.0-2');
          }

          return `
            <div class="bsz-surface bsz-padding-2 bsz-text-center mat-elevation-z0">
                <div class="bsz-subtitle-1 bsz-text-bold">${value}</div>
                <div class="bsz-caption bsz-text-secondary">${point.entity.moment.format(tooltipFormat)}</div>
            </div>
          `;
        },
      },
    });
    return options;
  }

  private getXPlotBands(
    entities: TimeSeriesChartEntity[],
    seriesType: TimeSeriesChartData['type']
  ): {color: string; from: number; to: number}[] {
    const entitiesGroupedByYear = entities.reduce(
      (entryMap, entity) => entryMap.set(entity.moment.year(), [...(entryMap.get(entity.moment.year()) || []), entity]),
      new Map()
    );

    const entityYears = [...entitiesGroupedByYear.keys()];

    // Display plot band if entities belong to 2 consecutive years
    if (entityYears.length === 2 && entityYears[0] === entityYears[1] - 1) {
      return [
        {
          color: hexToRGB(getPrimaryColor(), '0.05'),
          // 'column' plot needs to start from -0.25 so that the first bar is fully contained in plotBand
          from: seriesType === 'column' ? -0.25 : 0,
          // end plot should be at the middle of the 2 years, so we need to subtract 0.5
          to: entitiesGroupedByYear.get(entityYears[0]).length - 0.5,
        },
      ];
    }
    return [];
  }
}

export interface TimeSeriesChartData {
  type: 'area' | 'areaspline' | 'column';
  yAxisTickLabel?: {
    currencyIsoCode?: string;
  };
  xAxisTickLabel?: {
    onlyShowSecondLineIfDistinct?: boolean;
  };
  chartPeriod: ChartPeriod;
  entities: TimeSeriesChartEntity[];
}

export interface TimeSeriesChartEntity {
  moment: Moment;
  value: number;
}
