import {Directive, TemplateRef, ViewChild} from '@angular/core';

import {
  BszModalBottomSheetContentDef,
  BszModalBottomSheetFooterDef,
  BszModalBottomSheetTitleDef,
} from './bsz-modal-bottom-sheet-template-directives';

/**
 * When a modal opens via the BszModalBottomSheetService the component which will be the
 * template of the modal and will be passed to the `open()` method MUST implement this
 * abstract class so the service can extract the template definitions.
 */
@Directive()
export abstract class BszModalBottomSheetAwareComponent {
  @ViewChild(BszModalBottomSheetTitleDef, {read: TemplateRef, static: true})
  readonly titleDef: TemplateRef<never>;

  @ViewChild(BszModalBottomSheetContentDef, {read: TemplateRef, static: true})
  readonly contentDef: TemplateRef<never>;

  @ViewChild(BszModalBottomSheetFooterDef, {read: TemplateRef, static: true})
  readonly footerDef: TemplateRef<never>;
}
