import {NgModule} from '@angular/core';
import {WebAppAppletModule} from '@basuiz/web-app-common';

import {WebAppAlertIndicatorAppletComponent} from './web-app-alert-indicator-applet.component';
import {AlertIndicatorComponent} from './alert-indicator/alert-indicator.component';
import {WebAppAlertModule} from '@basuiz/web-app-alert';
import {WebAppSelfServiceNotificationsModule} from '@basuiz/web-app-self-service-notifications';

@NgModule({
  declarations: [WebAppAlertIndicatorAppletComponent, AlertIndicatorComponent],
  imports: [WebAppAlertModule, WebAppSelfServiceNotificationsModule, WebAppAppletModule],
  exports: [WebAppAlertIndicatorAppletComponent], // Notice: only the applet component should be exported!
})
export class WebAppAlertIndicatorAppletModule {}
