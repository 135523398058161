import {Inject, Pipe, PipeTransform} from '@angular/core';
import {Currency} from '../../base-data/models/currency.definitions';
import {CurrencyNameFormatter, CurrencyToName} from '../models/business-object-naming.definitions';
import {CURRENCY_NAME_FORMATTER} from '../formatters/currency-name.formatter';
import {BusinessObjectNamePipe} from './business-object-name.pipe';

@Pipe({name: 'bszCurrencyName'})
export class BszCurrencyNamePipe extends BusinessObjectNamePipe<Currency, CurrencyToName> implements PipeTransform {
  constructor(@Inject(CURRENCY_NAME_FORMATTER) nameFormatter: CurrencyNameFormatter) {
    super(nameFormatter);
  }

  getObjectToName(currency: Currency) {
    return {
      name: currency.name,
      isoCode: currency.isoCode,
    };
  }
}
