// TODO (p3) chage to an Entity and table in database (to add more fields like translations)
export enum DistanceUnits {
  cm = 'cm',
  in = 'in',
  ft = 'ft',
  mm = 'mm',
  m = 'm',
  yd = 'yd',
}

export interface IDimension {
  length: number;
  width: number;
  height: number;
  distanceUnit: DistanceUnits;
}
