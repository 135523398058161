import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApiHttpErrorCodes, IUser, propertyOf} from '@basuiz/shared/data-access';
import {BehaviorSubject, Observable, Subscription, catchError, finalize, firstValueFrom, of, tap} from 'rxjs';
import {ExternalLinkService} from '../../../external-link/external-link.service';
import {AuthJwtService} from '../../services/auth-jwt.service';

@Component({
  selector: 'bsz-auth-email-verify',
  templateUrl: './email-verify.component.html',
  styleUrls: ['./email-verify.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailVerifyComponent implements OnInit, OnDestroy {
  @Output()
  goToSignin: EventEmitter<void> = new EventEmitter<void>();

  public readonly showHelp: boolean = Boolean(process.env['APP_CUSTOMER_SUPPORT_PHONE_WHATSAPP']);
  public emailVerifyStatus: ApiHttpErrorCodes.emailVerifyError | ApiHttpErrorCodes.emailVerifyAlreadyDone | 'success' =
    ApiHttpErrorCodes.emailVerifyError;

  ApiHttpErrorCodes = ApiHttpErrorCodes;

  private subscriptions: Subscription = new Subscription();

  private submitLoadingSubject = new BehaviorSubject<boolean>(false);
  submitLoadingSubject$ = this.submitLoadingSubject.asObservable();

  constructor(
    private activatedRoute: ActivatedRoute,
    private authJwtService: AuthJwtService,
    private externalLinkService: ExternalLinkService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe((params) => {
        const email = params[propertyOf<IUser>('email')];
        const emailVerifyToken = params[propertyOf<IUser>('emailVerifyToken')];

        this.doEmailVerify(email, emailVerifyToken);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async doEmailVerify(email: string, emailVerifyToken: string) {
    this.submitLoadingSubject.next(true);
    const user: Partial<IUser> = {
      email: email ?? '',
      emailVerifyToken: emailVerifyToken ?? '',
    };

    const emailVerifyResult = await firstValueFrom(
      this.authJwtService.emailVerify(user).pipe(
        tap((user) => {
          if (user.isEmailVerified && user.isActive && user.isFirstSetup) {
            this.emailVerifyStatus = 'success';
          }
        }),
        finalize(() => {
          this.submitLoadingSubject.next(false);
        }),
        catchError((error: HttpErrorResponse): Observable<null> => {
          if (error.error.message === ApiHttpErrorCodes.emailVerifyAlreadyDone) {
            this.emailVerifyStatus = ApiHttpErrorCodes.emailVerifyAlreadyDone;
          }
          return of(null);
        })
      )
    );
  }

  openHelp() {
    this.externalLinkService.open(
      `https://api.whatsapp.com/send/?phone=${process.env['APP_CUSTOMER_SUPPORT_PHONE_WHATSAPP']}`
    );
  }
}
