import {IPaymentIntent} from './payment-intent.model';

export function getExtendedAdminPaymentIntent(paymentIntent: IPaymentIntent): IPaymentIntent | null {
  if (!paymentIntent) {
    return null;
  }

  const extendedPaymentIntent = {
    ...paymentIntent,
    _privateNote: paymentIntent.privateNote,
    _extTransactionId: paymentIntent.extTransactionId,
    _extTransactionAdminVouch: paymentIntent.extTransactionAdminVouch,
  } as IPaymentIntent;

  // delete these values, if for any reason were included in the original rate object
  delete extendedPaymentIntent.privateNote;
  // @ts-ignore
  delete extendedPaymentIntent.extTransactionId;
  delete extendedPaymentIntent.extTransactionAdminVouch;

  return extendedPaymentIntent;
}
