<a
  *ngIf="active"
  bszAccessibleLink
  class="bsz-text-primary"
  (click)="navigate.emit()"
  bszTestId="{{ bszTestIdPrefix }}.breadcrumb"
>
  <ng-container *ngIf="appearance === 'regular'">
    {{ displayText }}
  </ng-container>
  <span *ngIf="appearance === 'back-button'" class="bsz-display-flex bsz-align-items-center bsz-text-bold">
    <mat-icon>chevron_left</mat-icon>
    <span class="bsz-margin-l3">{{ displayText }}</span>
  </span>
</a>

<span *ngIf="!active">
  {{ displayText }}
</span>
