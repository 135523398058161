import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {getNumberOfCurrencyDigits} from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  forwardRef,
  Host,
  Inject,
  Input,
  NgZone,
  Optional,
  Renderer2,
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {MatFormField} from '@angular/material/form-field';
import {BszI18nService} from '@basuiz/i18n';
import {
  BSZ_CURRENCY_PIPE_DEFAULT_OPTIONS,
  BszCurrencyPipeConfig,
  formatCurrency,
  formatCurrencyWithTemplate,
} from '@basuiz/ui-elements/pipes';
import {take} from 'rxjs/operators';

import {webcoat_deprecated} from '../../common/webcoat-deprecated';
import {BSZ_AMOUNT_DEFAULT_OPTIONS, BszAmountConfig} from './bsz-amount.definitions';
import {BszAmountBase} from './bsz-amount-base';

const defaultConfig = {
  localeAware: true,
};

@Directive({
  selector: 'input[bszAmount]',
  host: {
    class: 'bsz-amount',
    type: 'text',
    inputmode: 'decimal',
    autocomplete: 'off',
    style: 'vertical-align: baseline',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BszAmount),
      multi: true,
    },
  ],
})
export class BszAmount extends BszAmountBase implements AfterViewInit {
  @Input()
  override set decimalLength(value: string | number | undefined | null) {
    this._localDecimalLength = this.isNumberValue(value) ? Number(value) : undefined;

    this.updateDecimalLength();
    this.updateViewValue();
  }
  private _localDecimalLength: number | undefined;

  @Input()
  set currencyCode(currencyCode: string | undefined | null) {
    this._currencyCode = currencyCode || undefined;

    if (currencyCode) {
      this._currencyCodeDigits = getNumberOfCurrencyDigits(currencyCode);
    }

    this.updateDecimalLength();
    this.updateViewValue();
    this.updateCurrencyPrefix();
    this.updateOutlineGap();
  }
  private _currencyCode: string | undefined;
  private _currencyCodeDigits: number | undefined;

  /** @deprecated The input property "showCurrencyPrefix" is DEPRECATED since v3.8.0. Use "showCurrency" instead. */
  @Input()
  set showCurrencyPrefix(shouldShow: BooleanInput) {
    this.showCurrency = shouldShow;

    webcoat_deprecated(
      '[bsz-amount] The input property "showCurrencyPrefix" is DEPRECATED since v3.8.0. Use "showCurrency" instead.'
    );
  }

  @Input()
  set showCurrency(shouldShow: BooleanInput) {
    this._showCurrency = coerceBooleanProperty(shouldShow);

    this.updateCurrencyPrefix();
    this.updateOutlineGap();
  }
  private _showCurrency = false;

  protected override readonly localeId: string;

  private readonly config: BszAmountConfig;

  constructor(
    elementRef: ElementRef<HTMLInputElement>,
    i18nService: BszI18nService,
    renderer: Renderer2,
    private readonly ngZone: NgZone,
    @Host() @Optional() private readonly matFormField: MatFormField | null,
    @Optional() @Inject(BSZ_AMOUNT_DEFAULT_OPTIONS) private readonly bszAmountConfig: BszAmountConfig | null,
    @Optional() @Inject(BSZ_CURRENCY_PIPE_DEFAULT_OPTIONS) private readonly currencyPipeConfig: BszCurrencyPipeConfig
  ) {
    super(elementRef, i18nService, renderer);
    this.localeId = i18nService.localeId;
    this.config = Object.assign({}, defaultConfig, bszAmountConfig || {});
  }

  ngAfterViewInit(): void {
    // When the component loads initially it can fail to render the currency prefix because the
    // HTML is not built yet. We call the method in this hook to ensure that the currency
    // prefix will be rendered.
    this.updateCurrencyPrefix();
  }

  /**
   * Updates the value of `_decimalLength` property which is inherited from `BszAmountBase`
   * The decimal length used in the component can be either determined from the currency
   * or specified directly.
   */
  private updateDecimalLength() {
    this._decimalLength = this._localDecimalLength ?? this._currencyCodeDigits;
  }

  private updateOutlineGap() {
    if (this.matFormField?.appearance === 'outline') {
      this.ngZone.onStable.pipe(take(1)).subscribe(() => this.matFormField?.updateOutlineGap());
    }
  }

  private updateCurrencyPrefix() {
    const container = this.elementRef.nativeElement.closest('.mat-form-field-infix');
    if (!container) {
      return;
    }

    const containerWrapper = container.parentElement;
    const previousCurrencyPrefix = containerWrapper?.querySelector('.bsz-amount-currency-code');
    if (previousCurrencyPrefix) {
      this.renderer.removeChild(containerWrapper, previousCurrencyPrefix);
    }

    if (!this._currencyCode || !this._showCurrency) {
      return;
    }

    const currencyPrefix = this.renderer.createElement('span');
    this.renderer.addClass(currencyPrefix, 'bsz-amount-currency-code');
    this.renderer.addClass(currencyPrefix, 'bsz-text-secondary');

    // Cannot use util class for spacing because mat-form field cannot calculate the position of the label.
    this.renderer.setStyle(currencyPrefix, 'padding-left', '4px');
    this.renderer.setStyle(currencyPrefix, 'padding-right', '16px');

    const prefixText = this.renderer.createText(this._currencyCode);
    this.renderer.appendChild(currencyPrefix, prefixText);

    if (this.shouldAddCurrencyAsSuffix() && this.config.localeAware) {
      this.renderer.appendChild(containerWrapper, currencyPrefix);
    } else {
      this.renderer.insertBefore(containerWrapper, currencyPrefix, container);
    }
  }

  private shouldAddCurrencyAsSuffix(): boolean {
    if (!this._currencyCode) {
      return false;
    }

    // format an amount to check where the currency is added
    let formattedCurrency = formatCurrency(10, this.localeId, this._currencyCode, this._currencyCode);

    // update the formatted amount with the custom template (if it exists) from the currency pipe config
    if (this.currencyPipeConfig?.template[this.localeId]) {
      formattedCurrency = formatCurrencyWithTemplate(
        formattedCurrency,
        this._currencyCode,
        this.currencyPipeConfig.template[this.localeId]
      );
    }

    const indexOfNumber = formattedCurrency.indexOf('10');
    const indexOfCurrency = formattedCurrency.indexOf(this._currencyCode);

    return indexOfCurrency > indexOfNumber;
  }
}
