export * from './web-app-common-locale.module';

export * from './web-app-common-locale.config';
export * from './definition/locale-settings.definition';
export * from './definition/locale-config.definition';

export * from './supported-locales.service';
export * from './active-locale-settings.service';

export * from './web-app-i18n-configuration';

export * from './custom-locale-source.injection';
export * from './call-active-locale-update-hook';
