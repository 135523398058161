<div>
  <button
    mat-button
    (click)="clipboard.copy(appletCssSelector)"
    style="min-width: unset; padding: unset"
    class="bsz-margin-x1"
  >
    <mat-icon inline class="bsz-text-accent">content_copy</mat-icon>
  </button>
  <span class="bsz-body-1">{{ appletCssSelector }}</span>
</div>
