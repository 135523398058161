// IMPORTANT: do not modify this file

import {FactoryProvider, inject, InjectionToken} from '@angular/core';
import {BszConfigFactory, BszConfigFactoryWithDeps} from '@basuiz/web-app-applet-api';
import {AlertListAppletConfig} from './alert-list-applet.config.definition';
import {getDefaultConfig} from './alert-list-applet.config.default';
import {validateAlertListAppletConfig} from './alert-list-applet.config.validation';

// IMPORTANT: do not modify this file

export const ɵALERT_LIST_BANKLET_CONFIG = new InjectionToken<AlertListAppletConfig>(
  'bsz.web-app.alert-list-applet-config',
  {
    factory: () => inject(ALERT_LIST_BANKLET_CONFIG_DEFAULT),
  }
);

// IMPORTANT: do not modify this file

export const ALERT_LIST_BANKLET_CONFIG_DEFAULT = new InjectionToken<AlertListAppletConfig>(
  'bsz.web-app.alert-list-applet-config.default',
  {
    factory: () => {
      const config = getDefaultConfig();
      const error = validateAlertListAppletConfig(config);
      if (error) {
        console.error('Default AlertListAppletConfig validation failed:', error);
      }
      return config;
    },
  }
);

// IMPORTANT: do not modify this file

export function alertListAppletConfigProvider({
  useFactory,
  deps,
}: BszConfigFactoryWithDeps<AlertListAppletConfig>): FactoryProvider {
  return {
    provide: ɵALERT_LIST_BANKLET_CONFIG,
    useFactory: factoryWithValidation(useFactory),
    deps: [ALERT_LIST_BANKLET_CONFIG_DEFAULT, ...(deps || [])],
  };
}

// IMPORTANT: do not modify this file

function factoryWithValidation(
  factory: BszConfigFactory<AlertListAppletConfig>
): BszConfigFactory<AlertListAppletConfig> {
  return (defaultConfig: AlertListAppletConfig, ...deps: unknown[]) => {
    const config = factory(defaultConfig, ...deps);
    const error = validateAlertListAppletConfig(config);
    if (error) {
      console.error('AlertListAppletConfig validation failed:', error);
    }
    return config;
  };
}

// IMPORTANT: do not modify this file
