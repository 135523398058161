import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {
  FEATURE_KEY as ATTENTION_ITEM_COUNTER_FEATURE_KEY,
  reducer as attentionItemCounterReducers,
} from './+state/attention-item-counter/attention-item-counter.reducer';
import {EffectsModule} from '@ngrx/effects';
import {AttentionItemCounterEffects} from './+state/attention-item-counter/attention-item-counter.effects';
import {WebAppSelfServiceNotificationsModule} from '@basuiz/web-app-self-service-notifications';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(ATTENTION_ITEM_COUNTER_FEATURE_KEY, attentionItemCounterReducers),
    EffectsModule.forFeature([AttentionItemCounterEffects]),
    WebAppSelfServiceNotificationsModule,
  ],
})
export class WebAppAlertModule {}
