import {MonoTypeOperatorFunction} from 'rxjs';
import {filter, map} from 'rxjs/operators';

export function filterEqual<T>(search: T): MonoTypeOperatorFunction<T> {
  return filter((value) => value === search);
}

export function filterArrayByPredicate<T>(predicate: (obj: T) => boolean): MonoTypeOperatorFunction<T[]> {
  return map((list: T[]) => list.filter((item) => predicate(item)));
}
