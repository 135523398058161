import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
/** This services offers information about the state of the portal layout.
 * It also allows changing programmatically multiple aspects to the layout state. */
export class PortalLayoutService {
  /* FULL SCREEN */

  private fullScreenSubject = new BehaviorSubject<boolean>(false);

  /** Emits changes on full screen mode:
   * True: the current portal-page displays uses the full screen (the full viewport), the app-shell is hidden
   * False: the current portal-page appears alongside the app-shell */
  public readonly fullScreen$: Observable<boolean> = this.fullScreenSubject.asObservable();

  /* Internal (not public API): changing the full-screen mode is currently under control of the portal-page-layout */
  public ɵsetFullScreen(state: boolean) {
    this.fullScreenSubject.next(state);
  }

  /* MAIN MENU STATE */

  private readonly mainNavOpenedSubject = new BehaviorSubject<boolean>(false);

  /** Emits changes in the state of the main menu (menu on the left side), i.e. whether the menu is opened or closed. */
  public readonly mainNavOpened$: Observable<boolean> = this.mainNavOpenedSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  /** Toggles the state of the main menu, i.e. between opened and close.
   * If called with a specific state, the menu is set to that state.
   * If no state is specified, the menu switches to the opposite of the current state */
  public toggleMainNav(menuState?: 'opened' | 'closed') {
    if (menuState) {
      this.mainNavOpenedSubject.next(menuState === 'opened');
    } else {
      this.mainNavOpenedSubject.next(!this.mainNavOpenedSubject.getValue());
    }
  }

  /* SCROLL TO TOP */

  private readonly scrollToTopSubject = new Subject<void>();

  /** Emits scroll-to-top requests coming from different parts of the portal. */
  public readonly scrollToTop$: Observable<void> = this.scrollToTopSubject.asObservable();

  /** Requests the portal to scroll to the page top */
  public scrollToTop() {
    this.scrollToTopSubject.next(undefined);
  }

  /* PAGE CONTENT "CENTERED" */
  private readonly pageContentCenteredSubject = new BehaviorSubject<boolean>(false);

  public ɵsetPageContentCentered(state: boolean) {
    this.pageContentCenteredSubject.next(state);
  }

  /** Indicates whether the current portal-page makes use of component PageContentCenteredComponent
   * And therefore some content is narrower that the standard width of portal-pages */
  readonly pageContentCentered$: Observable<boolean> = this.pageContentCenteredSubject.asObservable();
}
