import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TranslatedText} from '@basuiz/web-app-applet-api';

export type StepSummary = [LabelValuePair?, LabelValuePair?, LabelValuePair?];

export interface LabelValuePair {
  label?: TranslatedText;
  value: string;
}

@Component({
  selector: 'bsz-web-app-step-summary',
  templateUrl: './web-app-step-summary.component.html',
  styleUrls: ['./web-app-step-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebAppStepSummaryComponent {
  @Input() summary: StepSummary;
}
