import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {BusinessUnit} from '../../models/business-unit.definition';
import {CallState, LoadingState} from '../../../definitions/call-state.definition';
import {loadBusinessUnitsFailed, loadBusinessUnitsRequired, loadBusinessUnitsSuccess} from './business-unit.actions';

export const FEATURE_KEY = 'web-app-common/business-unit';

export interface PartialState {
  readonly [FEATURE_KEY]: State;
}

export interface State extends EntityState<BusinessUnit> {
  callState: CallState;
}

export const adapter: EntityAdapter<BusinessUnit> = createEntityAdapter<BusinessUnit>({
  selectId: (businessUnit: BusinessUnit) => businessUnit.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  callState: LoadingState.INIT,
});

const businessUnitReducer = createReducer(
  initialState,
  on(loadBusinessUnitsRequired, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(loadBusinessUnitsSuccess, (state, {businessUnitList}) =>
    adapter.setAll(businessUnitList, {
      ...state,
      callState: LoadingState.LOADED,
    })
  ),
  on(loadBusinessUnitsFailed, (state, error) => ({
    ...state,
    callState: error,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return businessUnitReducer(state, action);
}
