import {FocusMonitor} from '@angular/cdk/a11y';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  HostListener,
  Input,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import {AsyncSubject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import {BszCardSelectControl} from './bsz-card-select-control';

@Directive({
  selector: 'bsz-card-content',
  host: {
    class: 'bsz-card-content',
  },
})
export class BszCardContent {}

@Directive({
  selector: 'bsz-card-actions',
  host: {
    'class': 'bsz-card-actions',
    '[class.bsz-card-actions-align-end]': 'align === "end"',
  },
})
export class BszCardActions {
  @Input() align: 'start' | 'end' = 'start';

  @HostListener('click', ['$event']) onClick(event: Event) {
    event.stopPropagation();
  }
}

@Directive({
  selector: '[bsz-card-image]',
  host: {
    class: 'bsz-card-image',
  },
})
export class BszCardImage {}

@Directive({
  selector: '[bsz-card-thumbnail]',
  host: {
    class: 'bsz-card-thumbnail',
  },
})
export class BszCardThumbnail {}

@Directive({
  selector: 'bsz-card-title, [bsz-card-title]',
  host: {
    class: 'bsz-card-title',
  },
})
export class BszCardTitle {}

@Directive({
  selector: 'bsz-card-subtitle, [bsz-card-subtitle]',
  host: {
    class: 'bsz-card-subtitle',
  },
})
export class BszCardSubtitle {}

@Component({
  selector: 'bsz-card-header',
  template: `
    <ng-content select="[bsz-card-thumbnail]"></ng-content>
    <div class="bsz-card-header-titles">
      <ng-content select="bsz-card-title, bsz-card-subtitle, [bsz-card-title], [bsz-card-subtitle],"></ng-content>
    </div>
    <ng-content select="[bsz-card-select-control]"></ng-content>
    <ng-content></ng-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'bsz-card-header',
  },
})
export class BszCardHeader {}

@Component({
  selector: 'bsz-card',
  styleUrls: ['./bsz-card.scss'],
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    'class': 'bsz-card',
    '[class.bsz-card-outlined]': '_outlined',
    '[class.bsz-card-flat]': '_flat',
    '[class.bsz-card-selectable]': '_isSelectable',
    '[class.bsz-card-selected]': '_isSelected',
    '[class.bsz-card-disabled]': 'this.cardSelectControl?._selectControl?.disabled',
    '[class.bsz-card-focused]': '_isFocused',
    '[class.bsz-card-selected-accent]': '_isSelected && _selectedColor === "accent"',
  },
})
export class BszCard implements AfterContentInit, AfterViewInit, OnDestroy {
  @Input()
  set outlined(isOutlined: BooleanInput) {
    this._outlined = coerceBooleanProperty(isOutlined);
  }
  /** @private for internal use only */
  _outlined = false;

  @Input()
  set flat(isFlat: BooleanInput) {
    this._flat = coerceBooleanProperty(isFlat);
  }
  /** @private for internal use only */
  _flat = false;

  protected _isSelected = false;

  protected _isSelectable = false;

  protected _selectedColor: ThemePalette = 'primary';

  protected _isFocused = false;

  private readonly destroy = new AsyncSubject<void>();

  @HostListener('click', ['$event']) onClick(event: Event) {
    if (this.cardSelectControl) {
      this.cardSelectControl._selectCard();
      this.cardSelectControl._selectControl?.focus();
      this.cd.markForCheck();
    }
  }

  @ContentChild(BszCardSelectControl) private readonly cardSelectControl: BszCardSelectControl | undefined;

  constructor(private readonly cd: ChangeDetectorRef, private readonly _focusMonitor: FocusMonitor) {}

  ngAfterContentInit() {
    if (this.cardSelectControl) {
      this._selectedColor = this.cardSelectControl._color;
      this.cardSelectControl._selectionChange.subscribe((selected) => {
        this._isSelected = selected;
        this.cd.markForCheck();
      });
      this._isSelectable = true;
    }
  }

  ngAfterViewInit() {
    if (this.cardSelectControl) {
      this.observeFocusMonitor();
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  /**
   * It observes when the radio or checkbox receives or loses the focus to update
   * the value of "_isfocused". By this way, we can set a class in the HTML to style
   * that state. It only updates the focus state when the focus is done with keyboard.
   */
  private observeFocusMonitor() {
    const input = this.cardSelectControl?._selectControl?._inputElement.nativeElement;
    if (!input) {
      return;
    }
    this._focusMonitor
      .monitor(input)
      .pipe(
        takeUntil(this.destroy),
        // filter when it loses the focus (focus origin is null) or when the focus is made with keyboard
        // The type FocusOrigin includes: 'touch' | 'mouse' | 'keyboard' | 'program' | null
        filter((focusOrigin) => !focusOrigin || focusOrigin === 'keyboard')
      )
      .subscribe((focusOrigin) => {
        this._isFocused = !!focusOrigin;
        this.cd.markForCheck();
      });
  }
}
