import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {ArrayDataSource, DataSource, isDataSource} from '@angular/cdk/collections';
import {CdkColumnDef} from '@angular/cdk/table';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {AsyncSubject, BehaviorSubject, EMPTY, Observable} from 'rxjs';
import {first, switchMap, takeUntil} from 'rxjs/operators';

import {BszFlatSelectionModel, BszSelectable} from '../bsz-selectable/index';
// we get a circular dependency if we import from bsz-table-column-toggle directly
import {BszHeaderToggle} from '../bsz-table-column-toggle/bsz-header-toggle';
import {
  BszTableDataSourceInput,
  BszTableDataSourceType,
  BszTableTreeRow,
  BszTableTreeRowDefinition,
  isTreeRowDefinitionCollection,
} from './bsz-table.definitions';
import {BszTableCollapsible} from './bsz-table-collapsible';
import {BszTableColumn} from './bsz-table-column';
import {BszTableGroupHeaderColumn} from './bsz-table-group-header-column';
import {BszTableTreeDataSource, getOriginalRowFromTableRow, isTreeDataSource} from './bsz-table-tree-data-source';
import {BszTableTreeSelectionModel} from './bsz-table-tree-selection-model';

const LOG_PREFIX = '[bsz-table]';

@Component({
  selector: 'bsz-table',
  templateUrl: './bsz-table.html',
  styleUrls: ['./bsz-table.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'class': 'bsz-table',
    '[class.bsz-tree-table]': 'isTreeTable()',
  },
})
export class BszTable<T, G = unknown> implements OnInit, OnDestroy, AfterContentInit, AfterViewInit {
  /** The table data source */
  @Input()
  set dataSource(tableData: BszTableDataSourceInput<T, G> | null) {
    // Do nothing on null values (it can come from an observable input)
    if (!tableData) {
      return;
    }

    if (!Array.isArray(tableData) && !isDataSource(tableData)) {
      console.error(
        `${LOG_PREFIX} "dataSource" must be an Array or an instance of DataSource from @angular/cdk/collections`
      );
      return;
    }

    this._dataSource.next(tableData);
  }

  get dataSource(): BszTableDataSourceInput<T, G> {
    return this._dataSource.getValue();
  }

  /** Table columns to display */
  @Input()
  set columns(cols: string[]) {
    this._columns.next(cols);
  }

  get columns(): string[] {
    return this._columns.getValue();
  }

  /** Columns to display on group header rows */
  @Input()
  set groupHeaderColumns(cols: string[]) {
    this._groupHeaderColumns.next(cols);
  }

  get groupHeaderColumns(): string[] {
    return this._groupHeaderColumns.getValue();
  }

  /** The caption (or title) of a table */
  @Input() caption: string | null = null;

  /**
   * The content of the summary html attribute
   * Summary is useful and related with accessibility
   */
  @Input() summary: string | null = null;

  /**
   * Object for adding custom classes to the row depending on its data
   *       string | string[] | Set<string> | { [klass: string]: any; }
   */
  @Input() rowClass: (row: {[prop: string]: any}) => {[klass: string]: any} = () => ({});

  @Input()
  set stickyHeader(stickyHeader: BooleanInput) {
    this._stickyHeader = coerceBooleanProperty(stickyHeader);
  }
  get stickyHeader() {
    return this._stickyHeader;
  }
  _stickyHeader = false;

  /** Event emitted when clicking the row (only for data rows, not for groupHeader rows or footers) */
  @Output() rowClick = new EventEmitter<T>();

  @Output() groupHeaderClick = new EventEmitter<BszTableTreeRow>();

  @ContentChildren(BszTableColumn, {descendants: true})
  tableColumns!: QueryList<BszTableColumn<T>>;

  @ContentChildren(BszTableGroupHeaderColumn, {descendants: true})
  tableGroupHeaderColumns!: QueryList<BszTableGroupHeaderColumn<T>>;

  // used by BszTableColumnToggle
  @ContentChildren(BszHeaderToggle, {descendants: true})
  tableHeaderToggleElements!: QueryList<BszHeaderToggle>;

  @ViewChild(MatTable, {static: true})
  table!: MatTable<T>;

  tableDataSource: BszTableDataSourceType<T, G> = new ArrayDataSource([]);

  get columnsToDisplay() {
    return (this.columns || []).filter((column) => this._columnsToDisplay.includes(column));
  }
  get groupHeaderColumnsToDisplay() {
    return this._groupHeaderColumnsToDisplay;
  }

  headerNestingClass = '';
  dataSourceUpdate = new BehaviorSubject<BszTableDataSourceType<T, G>>(new ArrayDataSource([]));
  hasFooter = false;

  /**
   * An async subject that completes when the table is done initializing.
   *
   * Triggered on ngAfterViewInit()
   */
  ready = new AsyncSubject<void>();

  /** Column id for empty cells when groupHeaders require it */
  readonly emptyCellId: string = 'bszEmptyCell';

  private _columns = new BehaviorSubject<string[]>([]);
  private _columnDefs: CdkColumnDef[] = [];
  private _groupHeaderColumns = new BehaviorSubject<string[]>([]);
  private _groupHeaderColumnDefs: CdkColumnDef[] = [];
  private _dataSource = new BehaviorSubject<BszTableDataSourceInput<T, G>>([]);

  private _columnsToDisplay: string[] = [];
  private _groupHeaderColumnsToDisplay: string[] = [];

  /** helper property to keep track of selected items from bszSelected directive */
  private selectedRows: (T | BszTableTreeRow)[] = [];

  private destroy = new AsyncSubject<void>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    @Optional() @Self() public sort: MatSort,
    @Optional() @Self() public bszCollapsible: BszTableCollapsible<T, G>,
    @Optional() @Self() private bszSelectable: BszSelectable<T, G>
  ) {}

  ngOnInit(): void {
    this.initDataSourceSubscriber();
    this.initSorting();
  }

  ngAfterContentInit(): void {
    this.updateColumnDefinitions();
    this.hasFooter = this.footerDefinitionsExist();
  }

  ngAfterViewInit() {
    this.initColumnSubscribers();
    this.initCollapsible();
    this.initSelectable();
    this.table.updateStickyHeaderRowStyles();
    this.table.updateStickyColumnStyles();
    this.ready.next();
    this.ready.complete();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  /** Use in the template to conditionally render the GroupHeaders. */
  isGroupHeader(index: number, item: BszTableTreeRow): boolean {
    return item._isGroupHeader;
  }

  footerDefinitionsExist(): boolean {
    if (!this.tableColumns) {
      return false;
    }
    return this.tableColumns.find((bszTableColumn) => bszTableColumn.footerCellDef !== undefined) !== undefined;
  }

  /**
   * aria-rowindex starts from 1 instead of 0 and 1 is already assigned to the header row,
   * that's why we add +2 on every row.
   */
  getAriaRowIndex(rowIndex: number): number {
    return rowIndex + 2;
  }

  /** Get the aria row index attribute for the footer row */
  getFooterAriaRowIndex(): number {
    return this.table._getRenderedRows(this.table._rowOutlet).length + 2;
  }

  /** Get the right css class for the header in order to be aligned with the rest. */
  setHeaderNestingClass(nestingLevel: number): void {
    if (!nestingLevel) {
      return;
    }

    this.headerNestingClass = `nesting-level-${nestingLevel}`;
  }

  /**
   * Get the css class for the row. It returns the object that ng-class will use.
   * The class values are:
   *   - "nesting-level-X" with value true when it is tree-table and in which X is the level of nesting
   *   - custom classes defined in the input rowClass (when it is used)
   */
  getDynamicRowClass(row: {[prop: string]: any}): {[klass: string]: any} {
    const rowClass: {[klass: string]: any} = this.rowClass(row);

    rowClass[`nesting-level-${row._nestingLevel}`] = row._nestingLevel >= 0;

    if (row._isGroupHeader) {
      rowClass['bsz-group-header-is-collapsed'] = row._collapsed;
      rowClass['bsz-group-header-is-expanded'] = !row._collapsed;
    }

    return rowClass;
  }

  /**
   * Emits the groupHeaderClick event with the row as argument.
   * For internal use (table directives etc..)
   */
  emitGroupHeaderClickEvent(row: BszTableTreeRow, $event: MouseEvent): void {
    // it is applied to the row and also to the button, so this prevents
    // firing it twice when it is executed by clicking with mouse in the button,
    // and also other propagation to other ancestors that could have click events attached
    $event.stopPropagation();

    this.groupHeaderClick.emit(row);
  }

  /** Return true when this table is a tree table (hierarchical) */
  isTreeTable() {
    return isTreeDataSource(this.tableDataSource);
  }

  /**
   * Emits the rowClick event with the original data row.
   */
  emitRowClickEvent(row: T | BszTableTreeRow): void {
    this.rowClick.emit(getOriginalRowFromTableRow(row));
  }

  /**
   * Update the visible columns in the view without affecting state
   * of the defined columns and groupHeaderColumns
   *
   * - Called by BszTableColumnToggle
   */
  updateOnlyColumnsToDisplay(cols: string[], groupHeaderCols: string[]) {
    this.updateColumns(cols, []);
    this.updateGroupHeaderColumns(groupHeaderCols);
  }

  /**
   * Checks if the given row is selected
   *
   * @private Not intended for public use
   */
  isSelected(row: T | BszTableTreeRow): boolean {
    // Using a function encapsulates the usage of `this.selectedRows`
    // We don't want to expose this property as it holds information
    // for internal use only and the selected items should always be
    // retrieved from teh bszCollapsible.selectionChange event.
    if (!this.bszSelectable) {
      return false;
    }
    return this.selectedRows.includes(row);
  }

  /** The component generates the instance of tableDataSource when it is required. */
  private initTableDataSource(dataSource: BszTableDataSourceInput<T, G>): DataSource<T> {
    if (isDataSource(dataSource)) {
      return dataSource;
    }

    if (isTreeRowDefinitionCollection(dataSource)) {
      // @TODO fix Typing. For some reason DataSource<T> seems incompatible with this return.
      // Type 'Operator<any, BszTableTreeRow[]>' is not assignable to type 'Operator<any, readonly T[]>'.
      return this.initWithTreeDataSource(dataSource) as any;
    }

    if (Array.isArray(dataSource)) {
      return this.initWithArray(dataSource);
    }

    // Should not reach this
    throw new Error(`${LOG_PREFIX} Something went wrong during the initialization of the DataSource`);
  }

  private initWithTreeDataSource(dataSource: BszTableTreeRowDefinition<T, G>[]): BszTableTreeDataSource<T, G> {
    const treeDataSource = new BszTableTreeDataSource(dataSource);
    // We need custom sort for a tree table
    if (this.sort) {
      treeDataSource.addSort(this.sort);
    }

    this.setHeaderNestingClass(treeDataSource.getMaxNestingLevel());

    return treeDataSource;
  }

  private initWithArray(dataSource: T[]): MatTableDataSource<T> {
    return new MatTableDataSource(dataSource);
  }

  private detectChanges(): void {
    this.changeDetector.detectChanges();
  }

  private initDataSourceSubscriber(): void {
    this._dataSource.pipe(takeUntil(this.destroy)).subscribe((newSet: BszTableDataSourceInput<T, G>) => {
      this.updateDataSource(newSet);
    });
  }

  private initColumnSubscribers(): void {
    this.tableColumns.changes.pipe(takeUntil(this.destroy)).subscribe(() => this.updateColumnDefinitions());

    this._columns.pipe(takeUntil(this.destroy)).subscribe((cols: string[]) => {
      this.updateColumns(cols, this.groupHeaderColumns);
    });

    this._groupHeaderColumns.pipe(takeUntil(this.destroy)).subscribe((cols: string[]) => {
      this.updateGroupHeaderColumns(cols);
    });
  }

  /** Connect table to bszCollapsible directive if set */
  private initCollapsible(): void {
    if (!this.bszCollapsible) {
      return;
    }

    this.bszCollapsible.connect(this);
  }

  private initSelectable() {
    if (!this.bszSelectable) {
      return;
    }

    // Using collapsible changes the data filter in the data source, which would make the selectable
    // behaviour tricky. That's why we currently won't allow both at the same time.
    if (this.bszCollapsible) {
      throw new Error(`${LOG_PREFIX} bszSelectable cannot be used in conjunction with bszCollapsible`);
    }

    // Transform a data source stream to a data stream, connecting the data source as needed.
    // Note: We don't disconnect the data source here, since the MatTable already disconnects the data source for us.
    const dataChange = this.dataSourceUpdate.pipe(
      switchMap((dataSource) => dataSource.connect({viewChange: EMPTY}))
    ) as Observable<T[] | BszTableTreeRow[]>;

    // Wait for the first data source to connect the BszSelectable, since the type of the selection model
    // (flat or tree) is based on the first data source.
    this.dataSourceUpdate.pipe(first()).subscribe((firstDataSource) => {
      const selectionModel = isTreeDataSource(firstDataSource)
        ? new BszTableTreeSelectionModel()
        : new BszFlatSelectionModel<T>();

      this.bszSelectable.connect(dataChange, selectionModel);

      // Watch item selection and keep the table property up to date.
      // We don't use the selectedChange event because the event
      // has the original row as the payload but in order to be
      // able to compare against the modified rows of the tree
      // table we have to use this extra property.
      this.bszSelectable._selectedItems.pipe(takeUntil(this.destroy)).subscribe((selectedRows) => {
        const selectable = this.bszSelectable.getSelectableItemsData();
        this.selectedRows = selectedRows.filter((item) => selectable.includes(item));
        this.changeDetector.detectChanges();
      });
    });
  }

  /** Attach MatSort to the DataSource along with the sorting function. */
  private initSorting(): void {
    if (!this.sort) {
      return;
    }
    // Don't override if a sort is already defined
    if (this.tableDataSource instanceof MatTableDataSource && !this.tableDataSource.sort) {
      this.tableDataSource.sort = this.sort;
    }
  }

  /**
   * Assign the column definitions (templates) to the MatTable.
   */
  private updateColumnDefinitions() {
    this.clearColumnDefinitions();

    this.tableColumns.forEach((column) => {
      this.table.addColumnDef(column.columnDef);
      this._columnDefs.push(column.columnDef);
    });

    this.tableGroupHeaderColumns.forEach((treeColumn) => {
      this.table.addColumnDef(treeColumn.columnDef);
      this._groupHeaderColumnDefs.push(treeColumn.columnDef);
    });
  }

  /**
   * Remove the column definitions (templates) from the MatTable.
   */
  private clearColumnDefinitions() {
    this._columnDefs.forEach((column) => this.table.removeColumnDef(column));
    this._groupHeaderColumnDefs.forEach((treeColumn) => this.table.removeColumnDef(treeColumn));

    this._columnDefs = [];
    this._groupHeaderColumnDefs = [];
  }

  private updateColumns(cols: string[], groupHeaderCols: string[]): void {
    this._columnsToDisplay = cols;
    this.updateColumnIndices();

    if (groupHeaderCols.length) {
      this.updateGroupHeaderColumns(groupHeaderCols);
    }
  }

  private updateGroupHeaderColumns(cols: string[]): void {
    this.updateGroupHeaderIndicesAndColspan(cols);

    // Add empty cells if required
    const updatedGroupHeaders = this.addEmptyCells(cols);

    this._groupHeaderColumnsToDisplay = updatedGroupHeaders;

    this.detectChanges();
  }

  /**
   * The empty cells are for keeping the programmatic relationship between the cells and their
   * table header (th) and, indirectly, for keeping the alignment by columns
   */
  private addEmptyCells(cols: string[]) {
    // If the first column is empty then replace all empty columns with emptyCellIds
    if (this.isEmptyString(cols[0])) {
      return cols.map((col) => (this.isEmptyString(col) ? this.emptyCellId : col));
    }

    // Find the very next non-empty column after the first
    const firstNonEmptyElementIndex = cols.findIndex((col, index) => index > 0 && !this.isEmptyString(col));

    // No other element, just filter out the empty columns
    if (firstNonEmptyElementIndex === -1) {
      return cols.filter((col) => !this.isEmptyString(col));
    }

    const groupHeadersWithEmptyCells = cols
      // Replace only the empty columns that are positioned in between the the rest of the headers
      .map((value, index) =>
        index >= firstNonEmptyElementIndex && this.isEmptyString(value) ? this.emptyCellId : value
      )
      // Finally remove the empty columns between the first and the next non-empty column (first column will use colspan)
      .filter((col) => !this.isEmptyString(col));

    return groupHeadersWithEmptyCells;
  }

  private updateColumnIndices(): void {
    if (!this.tableColumns) {
      return;
    }

    this.tableColumns.forEach((treeColumn: BszTableColumn<any>) => {
      treeColumn.columnIndex = this.getColumnIndexFromName(treeColumn.name);
    });
  }

  /**
   * Adds the corresponding colspan to the first column and updates the
   * column index value for each column template for the groupHeaders
   */
  private updateGroupHeaderIndicesAndColspan(cols: string[]): void {
    if (!this.tableGroupHeaderColumns) {
      return;
    }

    this.tableGroupHeaderColumns.forEach((treeColumn) => {
      const columnIndex = this.getHeaderColumnIndexFromName(treeColumn.columnDef.name);
      treeColumn.columnIndex = columnIndex;
      // Add colspan only to the first column. This is because the content of the first cell is meant for
      // being group header and usually it is much longer than the column
      treeColumn.colspan = columnIndex === 0 ? this.calculateFirstColumnColspan(cols) : 1;
    });
  }

  /**
   * Returns index of the very next non-empty column. If there is no non-empty
   * column then return the total number of columns
   */
  private calculateFirstColumnColspan(cols: string[]): number {
    const nextNonEmptyColIndex = cols.findIndex((col, index) => !this.isEmptyString(col) && index > 0);

    return nextNonEmptyColIndex > 0 ? nextNonEmptyColIndex : cols.length;
  }

  private isEmptyString(value: string): boolean {
    return value === undefined || value === null || !value.trim().length;
  }

  private getColumnIndexFromName(columnName: string): number {
    return this.columns ? this.columns.indexOf(columnName) : 0;
  }

  private getHeaderColumnIndexFromName(columnName: string): number {
    return this.columns ? this.groupHeaderColumns.indexOf(columnName) : 0;
  }

  private updateDataSource(tableData: BszTableDataSourceInput<T, G>): void {
    this.tableDataSource = this.initTableDataSource(tableData);
    this.dataSourceUpdate.next(this.tableDataSource);
    this.detectChanges();
  }

  /**
   * Triggers the change detection and rows re-render with the current dataSource. Needed when
   * the table's data source is not a DataSource nor an Observable, and there are changes in
   * the data.
   */
  renderRows(): void {
    this.updateDataSource(this.dataSource);

    // Exposing the method from matTable is not enough, so opting for using our own method to update
    // the source. With it, it seems enough, but to be sure that there are no corner cases that
    // wouldn't be covered, and to literally say that we expose the method from matTable
    this.table.renderRows();
  }
}
