import {PortalPage} from '../navigation/classes/portal-page';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslationKeyWithParams} from '../navigation/translation-key-with-params';
import {TranslationKey} from '@basuiz/web-app-applet-api';
import {UserSettingsPage} from './user-settings.page';

export interface CollectionBankAccountUpdatePagePayload {
  foo?: never; //todo define the payload properties
}

export class CollectionBankAccountUpdatePage extends PortalPage {
  constructor(public payload: CollectionBankAccountUpdatePagePayload = {}) {
    super();
  }

  get parentPortalPage(): PortalPage | null {
    return new UserSettingsPage();
  }

  get breadcrumbText(): TranslationKey | TranslationKeyWithParams {
    return asTranslationKey('web-app-portal.pages.collection-bank-account-update.breadcrumb');
  }

  get breadcrumbTestId(): string {
    return 'web-app-portal.pages.collection-bank-account-update.breadcrumb';
  }

  get titleText(): TranslationKey {
    return asTranslationKey('web-app-portal.pages.collection-bank-account-update.window-title');
  }

  static readonly routerConfigPath = 'user-settings/collection-bank-account/update';
  get route(): Array<string> {
    return [`/${CollectionBankAccountUpdatePage.routerConfigPath}`];
  }
}
