import {AfterViewInit, ChangeDetectorRef, Directive, ElementRef, OnDestroy, Renderer2} from '@angular/core';

import {activeItemClass} from './bsz-carousel.definitions';

@Directive({
  selector: 'section[bszCarouselFocusControl]',
})
export class BszCarouselFocusControl implements OnDestroy, AfterViewInit {
  bszCarousel: HTMLElement;
  firstFocusHelper: HTMLElement | null = null;
  lastFocusHelper: HTMLElement | null = null;
  sectionElementUnListen = () => {};
  lastHelperFocusUnListen = () => {};

  private unListeners: (() => void)[] = [];

  constructor(_elementRef: ElementRef<HTMLElement>, private renderer: Renderer2, private cd: ChangeDetectorRef) {
    this.bszCarousel = _elementRef.nativeElement;
  }

  ngAfterViewInit(): void {
    this.updateTabBrowsingBehavior();
  }

  ngOnDestroy() {
    this.unListen();
  }

  /**
   * Clear all the listeners added
   */
  private unListen() {
    this.unListeners.forEach((unListener: () => void) => {
      unListener();
    });
    this.unsetLastFocusHelperListener();
  }

  /**
   * Sets the event listeners to the elements that are involved in the tab order when browsing
   * with keyboard
   */
  updateTabBrowsingBehavior() {
    this.cd.detectChanges();
    this.unListen();
    this.updateFocusHelpersPosition();
    this.setArrowButtonsListeners();
    this.setSectionListeners();
    this.setItemsFrameListeners();
    this.setPageButtonsListeners();
    this.setFocusHelpersListeners();
  }

  /**
   * Sets the listeners in the arrow buttons that help to the keyboard navigation:
   *     - left button (prevButton):
   *            - keydown in the natural order (tab without shift): sends the focus to the first active
   *            item. Since it is in the keydown, it is executed before keypress and this is fired in the
   *            first item container. Because of that, the focus jumps from this container to the following
   *            focusable element in the DOM, which can be inside of it
   *            It also updates the listener in the last focus-helper to follow its behavior when browsing without
   *            shift. That helper only has to execute one action when receiving the focus from one specific order.
   *            Knowing the order (shifted or not) on focus is not possible, so sibling focusable elements update its
   *            listener when they are focused
   *     - right button (nextButton):
   *            - focus: it removes the listener from the second focus helper
   *            - keydown: it sends the focus to the second focus-helper when using tab+shift. Like already explained,
   *            the moment in which this event is fired helps to jump one element with the focus when setting it on it.
   *            In this case it goes in reverse mode, so when the helper takes the focus, it is automatically sent to the
   *            previous focusable element.
   */
  private setArrowButtonsListeners() {
    const prevButton = this.getArrowButtonPrev();

    if (!prevButton) {
      return;
    }

    const prevButtonKeydownListener = this.renderer.listen(prevButton, 'keydown', (event) => {
      if (event.key === 'Tab' && !event.shiftKey) {
        this.setFocusOnFirstActiveItem();
        this.updateLastFocusHelperListener();
      }
    });
    this.unListeners.push(prevButtonKeydownListener);

    const nextButton = this.getArrowButtonNext();
    const nextButtonFocusListener = this.renderer.listen(nextButton, 'focus', (event) => {
      this.unsetLastFocusHelperListener();
    });
    this.unListeners.push(nextButtonFocusListener);

    const nextButtonKeydownListener = this.renderer.listen(nextButton, 'keydown', (event) => {
      if (event.key === 'Tab' && event.shiftKey) {
        this.lastFocusHelper?.focus({
          preventScroll: true,
        });
      }
    });
    this.unListeners.push(nextButtonKeydownListener);
  }

  /**
   * Sets the listeners in the main section element that wraps the component:
   *     - focus: updates the second helper to work with its default listener
   *     - right button (nextButton):
   *            - focus: it adds the listener to itself for keydown. This is done this way to ensure only this element
   *            and not its descendants have that event attached. The listener is removed on blur
   *            - keydown: if it is executed with tab+shift, it goes to the expected following focusable element:
   *                      - left arrow if it is not disabled or
   *                      - first active item. This sends the focus to the following focusable element (already explained)
   *            - blur: removes the listener for the keydown added on focus
   */
  private setSectionListeners() {
    const mainElementFocusListener = this.renderer.listen(this.bszCarousel, 'focus', (event) => {
      this.sectionElementUnListen = this.renderer.listen(this.bszCarousel, 'keydown', (event) => {
        if (event.key === 'Tab' && !event.shiftKey) {
          const prevButton = this.getArrowButtonPrev();
          if (!prevButton) {
            return;
          }
          const prevButtonDisabled = !!prevButton.attributes.getNamedItem('disabled');
          if (prevButtonDisabled) {
            this.setFocusOnFirstActiveItem();
          }
        }
      });
      this.updateLastFocusHelperListener();
    });
    this.unListeners.push(mainElementFocusListener);

    const mainElementBlurListener = this.renderer.listen(this.bszCarousel, 'blur', (event) => {
      this.sectionElementUnListen();
    });
    this.unListeners.push(mainElementBlurListener);
  }

  /**
   * Sets the listeners in the frame in which items are:
   *     - keydown: if the frame detects a keydown with tab key, it means something inside has the focus, so the second
   *     focus-helper needs to behave in a way that, when receives the focus, it means it is normal order and sends it
   *     to the right arrow or page button (when right arrow is disabled).
   *     - click: when it detects a click, it should behave similar to the keydown. Although it could happend that the focus
   *     is not inside, it means the next focusable element in the DOM order should receive it.
   */
  private setItemsFrameListeners() {
    const frameElement = this.getCarouselContent();
    const frameElementKeydownListener = this.renderer.listen(frameElement, 'keydown', (event) => {
      if (event.key === 'Tab') {
        this.updateLastFocusHelperListener();
      }
    });
    this.unListeners.push(frameElementKeydownListener);

    const frameElementClickListener = this.renderer.listen(frameElement, 'click', (event) => {
      if (event.key === 'Tab') {
        this.updateLastFocusHelperListener();
      }
    });
    this.unListeners.push(frameElementClickListener);
  }

  /**
   * Sets the listeners in first pageButton (first in the set of bullet buttons). This is required because the previous
   * focusable element is the right-arrow button, and it can be disabled. Depending on that, the focus from this element
   * should be sent to one element or another:
   *     - focus: remove the listener from the second focus-helper. The focus helper is before this element in the DOM,
   *     so it would be reached in shifted order keyboard navigation. In that situation, that element, when getting the
   *     focus should not do anything
   *     - keydown: when browsing with tab+shift, it should checks if the right-arrow button is disabled:
   *                - if not, it sends the focus to it (this way prevents hidden items from being focusable because they
   *                are between both elements in the DOM)
   *                - if it is, it sends the focus to the second focus-helper. This has already deactivated the focus event,
   *                so it just don't do anything, and the keydown helps (as explained in other events), to jump one element
   *                and arrive to the following (in reverse order) focusable element.
   */
  private setPageButtonsListeners() {
    const firstPageButton = this.getFirstNavigationBulletButton();

    if (!firstPageButton) {
      return;
    }
    const firstPageButtonFocusListener = this.renderer.listen(firstPageButton, 'focus', (event) => {
      this.unsetLastFocusHelperListener();
    });
    this.unListeners.push(firstPageButtonFocusListener);

    const firstPageButtonKeydownListener = this.renderer.listen(firstPageButton, 'keydown', (event) => {
      if (event.key === 'Tab' && event.shiftKey) {
        const nextButton = this.getArrowButtonNext();
        const nextButtonDisabled = !nextButton || !!nextButton.attributes.getNamedItem('disabled');
        if (nextButtonDisabled) {
          this.lastFocusHelper?.focus({
            preventScroll: true,
          });
        }
      }
    });
    this.unListeners.push(firstPageButtonKeydownListener);
  }

  /**
   * Sets the listeners in the focus-helpers.
   * focusable element is the right-arrow button, and it can be disabled. Depending on that, the focus from this element
   * should be sent to one element or another:
   *     - first focus-helper (placed in the DOM before the first active item):
   *                - focus: the combination of all events make this element being focusable only when browsing with shift+tab.
   *                When it has the focus in that situation, it should send it automatically to the previous focusable element
   *                which can be:
   *                      - left-arrow button if it is enabled or
   *                      - main section element.
   *                 By this way, it prevents hidden items to the left from getting the focus.
   *     - second focus-helper (placed in the DOM after the last active item):
   *                - blur: it makes the element recover the focus behavior, which is done in the method updateLastFocusHelperListener
   */
  private setFocusHelpersListeners() {
    const firstFocusHelperFocusListener = this.renderer.listen(this.firstFocusHelper, 'focus', (event) => {
      const prevButton = this.getArrowButtonPrev();
      const prevButtonDisabled = !prevButton || !!prevButton.attributes.getNamedItem('disabled');
      if (!prevButtonDisabled) {
        prevButton.focus();
        return;
      }
      this.bszCarousel.focus();
    });
    this.unListeners.push(firstFocusHelperFocusListener);

    const lastFocusHelperBlurListener = this.renderer.listen(this.lastFocusHelper, 'blur', (event) => {
      this.updateLastFocusHelperListener();
    });
    this.unListeners.push(lastFocusHelperBlurListener);

    this.updateLastFocusHelperListener();
  }

  /**
   * The second focus-helper needs to update its listener on listeners of other elements, so it has this separated
   * method that allows to be called from different places. It adds the events:
   *     - focus: when the focus arrive from elements before it, it should send the focus to the next focusable element:
   *            - if right-button arrow is enabled or
   *            - first page button (bullet button)
   *       By this way, it prevents hidden items from getting the focus.
   *       This listener is removed in some scenarios (other elements do it), so that way, this behavior is not executed
   *       and when the focus arrives with shift key, it doesn't do anything
   */
  private updateLastFocusHelperListener() {
    this.unsetLastFocusHelperListener();
    this.lastHelperFocusUnListen = this.renderer.listen(this.lastFocusHelper, 'focus', (event) => {
      const nextButton = this.getArrowButtonNext();
      const nextButtonDisabled = !nextButton || !!nextButton.attributes.getNamedItem('disabled');
      if (!nextButtonDisabled) {
        nextButton.focus();
        return;
      }
      const firstPageButton = this.getFirstNavigationBulletButton();
      if (firstPageButton) {
        firstPageButton.focus();
      }
    });
  }

  /**
   * Remove the listener from the second focus-helper. It has a separated method not included in "unListeners"
   * because it has to be called in multiple places.
   */
  private unsetLastFocusHelperListener() {
    if (this.lastHelperFocusUnListen) {
      this.lastHelperFocusUnListen();
    }
  }

  /**
   * Updates the position of the focus-helpers in the DOM so they are in an expected order that allows to navigate
   * with the keyboard properly. They are placed one before the first active item and the other after the last one
   */
  updateFocusHelpersPosition() {
    if (!this.bszCarousel || !this.firstFocusHelper || !this.lastFocusHelper) {
      this.setElementsReference();
    }

    const activeItems = this.getActiveItems();
    if (!activeItems.length) {
      return;
    }
    const firstActiveItem = activeItems[0];
    this.renderer.insertBefore(firstActiveItem.parentNode, this.firstFocusHelper, firstActiveItem);

    const lastActiveItem = activeItems[activeItems.length - 1] as HTMLElement;
    this.insertAfter(this.lastFocusHelper!, lastActiveItem);

    this.setFocusHelpersTabIndex();
  }

  /**
   * Sets the reference to the three main elements that are used or referenced in other methods:
   *      - bszCarousel is the reference to the section element with class bsz-carousel and that is root for the rest
   *      of elements ued in other methods and that also has even listeners
   *      - Focus helpers are HTML elements hidden (even for assistive technologies) that, combined with additional
   *      event listeners in other elements, prevent hidden items from taking the focus on keyboard navigation
   */
  private setElementsReference() {
    // Two focusHelpers are needed, one placed before the first item in the active page and one after its last item
    this.firstFocusHelper = this.createFocusHelper();
    this.lastFocusHelper = this.createFocusHelper();
  }

  /**
   * Creates the focus-helper (simple fragment of HTML that is hidden to the accessibility API and is focusable)
   */
  private createFocusHelper(): HTMLElement {
    const focusHelper = this.renderer.createElement('span');
    this.renderer.addClass(focusHelper, 'bsz-carousel-focus-helper');
    this.renderer.setAttribute(focusHelper, 'aria-hidden', 'true');
    this.renderer.setAttribute(focusHelper, 'tabindex', '-1');
    this.renderer.appendChild(this.bszCarousel, focusHelper);

    return focusHelper;
  }

  /**
   * Sets the tabindex of the focusHelpers, so they are not focusable if they are not required
   */
  private setFocusHelpersTabIndex() {
    const carouselNavigation = this.getNavigationElement();
    this.renderer.setAttribute(this.firstFocusHelper, 'tabindex', carouselNavigation ? '0' : '-1');
    this.renderer.setAttribute(this.lastFocusHelper, 'tabindex', carouselNavigation ? '0' : '-1');
  }

  /**
   * Sets the focus in the first active item
   */
  private setFocusOnFirstActiveItem() {
    const activeItems = this.getActiveItems();
    if (!activeItems.length) {
      return;
    }
    const firstActiveItem = activeItems[0] as HTMLElement;
    firstActiveItem.focus({
      preventScroll: true,
    });
  }

  /**
   * To support placing the second focus-helper after the last active item in the DOM
   */
  private insertAfter(newNode: HTMLElement, existingNode: HTMLElement) {
    const parentNode = existingNode.parentNode as HTMLElement;
    const nextSibling = existingNode.nextSibling as HTMLElement;
    if (nextSibling) {
      this.renderer.insertBefore(parentNode, newNode, nextSibling);
    } else {
      this.renderer.appendChild(parentNode, newNode);
    }
  }

  private getActiveItems(): NodeList {
    return this.bszCarousel.querySelectorAll(`.${activeItemClass}`);
  }

  private getArrowButtonPrev(): HTMLElement {
    return this.bszCarousel.querySelector('.bsz-carousel-prev button') as HTMLElement;
  }

  private getArrowButtonNext(): HTMLElement {
    return this.bszCarousel.querySelector('.bsz-carousel-next button') as HTMLElement;
  }

  private getCarouselContent(): HTMLElement {
    return this.bszCarousel.querySelector('.bsz-carousel-content') as HTMLElement;
  }

  private getNavigationElement(): HTMLElement {
    return this.bszCarousel.querySelector('.bsz-carousel-navigation') as HTMLElement;
  }

  private getFirstNavigationBulletButton(): HTMLElement {
    return this.bszCarousel.querySelector('.bsz-carousel-navigation .bsz-carousel-navigation-button') as HTMLElement;
  }
}
