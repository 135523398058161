import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {
  ContextAction,
  ContextActionWithTranslatedLabel,
  ContextActionWithTranslationKey,
  isContextActionWithTranslationKey,
} from './context-action.interface';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'bsz-context-action-menu',
  templateUrl: './context-action-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextActionMenuComponent {
  @Input()
  set actions(actions: ContextAction[]) {
    this.contextActions = actions
      .filter((action) => action.isAvailable ?? true)
      .map((action) => (isContextActionWithTranslationKey(action) ? this.translateContextActionLabel(action) : action));
  }

  @Input() disabled: boolean = false;

  contextActions: ContextActionWithTranslatedLabel[] = [];

  constructor(private translateService: TranslateService) {}

  private translateContextActionLabel({
    translationKey,
    ...contextAction
  }: ContextActionWithTranslationKey): ContextActionWithTranslatedLabel {
    return {
      ...contextAction,
      label: this.translateService.instant(translationKey),
    };
  }
}
