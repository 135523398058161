import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {ValueRangeComponent} from './bsz-value-range.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {TestingCoreModule} from '../testing-core/index';

@NgModule({
  declarations: [ValueRangeComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    TranslateModule,
    ReactiveFormsModule,
    TestingCoreModule,
  ],
  exports: [ValueRangeComponent],
})
export class BszValueRangeModule {}
