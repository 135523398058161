import {
  ECTramacoContratoId,
  ECTramacoLocalidadId,
  EcTramacoShipmentProduct,
  IEcTramacoAddress,
  IEcTramacoCarga,
  IEcTramacoCuerpoRespuesta,
  IEcTramacoLabel,
  IEcTramacoLabelPdf,
  IEcTramacoLabelRegistered,
  IEcTramacoLabelRegisteredTracking,
  IEcTramacoLabelRegisteredTransaccion,
  IEcTramacoLabelReqApi,
  IEcTramacoLabelReqDb,
  IEcTramacoLabelResApi,
  IEcTramacoLabelResDb,
  IEcTramacoQuoteApiRes,
  IEcTramacoQuoteReqApi,
  IEcTramacoQuoteReqDb,
  IEcTramacoQuoteResDb,
} from './ec-tramaco-label.model';
import {EC_TRAMACO_PDF_BASE64_MOCK} from './ec-tramaco-pdf-base64-label.mock';

export const EC_TRAMACO_LABEL_ID_MOCK = '100000000000002';

export const EC_TRAMACO_CUERPO_RESPUESTA_OK: IEcTramacoCuerpoRespuesta = {
  codigo: '1',
  mensaje: 'EXITO',
};

/**
 * From API GET /...consultaTk/consultarLocalidadContrato
 */
export const EC_TRAMACO_LOCALIDAD_CONTRATO: any = {
  cuerpoRespuesta: EC_TRAMACO_CUERPO_RESPUESTA_OK,
  salidaConsultarLocalidadContratoWs: {
    lstCentroDeCosto: [],
    lstContrato: [
      {
        id: ECTramacoContratoId.DEV_MAIN,
        lstProducto: [
          {
            id: 2,
            lstServicio: [
              {
                id: 45,
                metrosLineal: false,
                nombre: 'ENTREGA SABADO',
                porcentaje: 0,
                tipo: 'DOC',
                valor: 0,
              },
            ],
            nombre: 'DOCUMENTOS',
            porKilo: false,
            tipo: 'DOC',
          },
          {
            id: 1,
            lstServicio: [
              {
                id: 30,
                metrosLineal: false,
                nombre: 'ENTREGA SABADO',
                porcentaje: 0,
                tipo: 'LIV',
                valor: 0,
              },
            ],
            nombre: 'CARGA LIVIANA',
            porKilo: true,
            tipo: 'LIV',
          },
          {
            id: 36,
            lstServicio: [
              {
                id: 30,
                metrosLineal: false,
                nombre: 'ENTREGA SABADO',
                porcentaje: 0,
                tipo: 'COU',
                valor: 0,
              },
            ],
            nombre: 'CARGA COURIER',
            porKilo: true,
            tipo: 'COU',
          },
        ],
        numero: '020                 0001208',
      },
      {
        id: 5050,
        lstProducto: [
          {
            id: 2,
            lstServicio: [],
            nombre: 'DOCUMENTOS',
            porKilo: false,
            tipo: 'DOC',
          },
        ],
        numero: 'ADJUNTOS ECOMMERCE',
      },
      {
        id: 5078,
        lstProducto: [
          {
            id: 1,
            lstServicio: [],
            nombre: 'CARGA LIVIANA',
            porKilo: true,
            tipo: 'LIV',
          },
          {
            id: 36,
            lstServicio: [],
            nombre: 'CARGA COURIER',
            porKilo: true,
            tipo: 'COU',
          },
          {
            id: 2,
            lstServicio: [],
            nombre: 'DOCUMENTOS',
            porKilo: false,
            tipo: 'DOC',
          },
        ],
        numero: 'DEVOLUCION ENVIOS',
      },
    ],
    lstLocalidad: [
      {
        activo: true,
        callePrimaria: 'CALLE TOLEDO N14 - 186 ',
        calleSecundaria: 'Y AV. CARLOS MANTILLA ',
        codigoPostal: '.',
        id: ECTramacoLocalidadId.DEV_MAIN,
        idParroquia: 348,
        nombre: 'GENERICA@ASOCIADOS / QUITO ',
        numero: '.',
        referencia: 'BODEGA CALDERON ',
        rutas: [],
      },
    ],
  },
};

export const EC_TRAMACO_CARGA: IEcTramacoCarga = {
  alto: 20,
  ancho: 20,
  largo: 20,
  bultos: 1,
  cajas: 1,
  // cantidadDoc: 0,
  contrato: ECTramacoContratoId.DEV_MAIN,
  // localidad: 0,
  // localidadDestino: 0,
  peso: 2,
  producto: EcTramacoShipmentProduct['CARGA COURIER'],
  // valorAsegurado: '',
  adjuntos: '',
};

export const EC_TRAMACO_ADDRESS_SENDER: IEcTramacoAddress = {
  codigoPostal: 'EC123',
  nombres: 'John',
  codigoParroquia: '290',
  email: 'john@mail.com',
  apellidos: 'Smith',
  callePrimaria: 'Av. Naciones Unidas E42-13',
  telefono: '1234567890',
  calleSecundaria: 'Av. Eloy Alfaro',
  tipoIden: '07',
  referencia: 'Next to gas station Texaco',
  ciRuc: '17000000000001',
  numero: '',
};

export const EC_TRAMACO_ADDRESS_RECIPIENT: IEcTramacoAddress = {
  codigoPostal: 'EC123',
  nombres: 'Valentina',
  codigoParroquia: '290',
  email: 'valentina@mail.com',
  apellidos: 'Enriquez',
  callePrimaria: 'Av. Occidental 123',
  telefono: '6789012345',
  calleSecundaria: 'Pinar Alto',
  tipoIden: '07',
  referencia: 'Corner house',
  ciRuc: '17000000000002',
  numero: '',
};

export const EC_TRAMACO_QUOTE_REQ_API_MOCK: IEcTramacoQuoteReqApi = {
  codParroquiaRemit: '290',
  lstCargaDestino: [
    {
      carga: EC_TRAMACO_CARGA,
      codParroquiaDest: '290',
      id: ECTramacoContratoId.DEV_MAIN,
    },
  ],
};

export const EC_TRAMACO_QUOTE_REQ_DB_MOCK: IEcTramacoQuoteReqDb = {
  codParroquiaRemit: '290',
  carga: EC_TRAMACO_CARGA,
  codParroquiaDest: '290',
  id: ECTramacoContratoId.DEV_MAIN,
};

export const EC_TRAMACO_QUOTE_RES_API_MOCK: IEcTramacoQuoteApiRes = {
  cuerpoRespuesta: EC_TRAMACO_CUERPO_RESPUESTA_OK,
  salidaCalcularPrecioGuiaWs: {
    lstGuias: [
      {
        id: ECTramacoContratoId.DEV_MAIN,
        iva: 0.24,
        ivaCalculado: 0.24,
        ivaSeguro: 0.0,
        lstProducto: [
          {
            idProducto: EcTramacoShipmentProduct['CARGA COURIER'],
            nombreProducto: 'CARGA COURIER',
            porcentajeIva: 12.0,
            valor: 2.0,
            valorIva: 0.24,
            valorIvaPrima: 0.0,
            valorPrima: 0.0,
          },
        ],
        lstServicio: [],
        seguro: 0.0,
        subTotal: 2.0,
        subtotalConIva: 2.0,
        subtotalSinIva: 0.0,
        total: 2.24,
        totalCalculado: 2.24,
      },
    ],
  },
};

export const EC_TRAMACO_QUOTE_RES_DB_MOCK: IEcTramacoQuoteResDb = {
  cuerpoRespuesta: EC_TRAMACO_CUERPO_RESPUESTA_OK,
  id: ECTramacoContratoId.DEV_MAIN,
  iva: 0.24,
  ivaCalculado: 0.24,
  ivaSeguro: 0.0,
  idProducto: EcTramacoShipmentProduct['CARGA COURIER'],
  nombreProducto: 'CARGA COURIER',
  porcentajeIva: 12.0,
  valor: 2.0,
  valorIva: 0.24,
  valorIvaPrima: 0.0,
  valorPrima: 0.0,
  seguro: 0.0,
  subTotal: 2.0,
  subtotalConIva: 2.0,
  subtotalSinIva: 0.0,
  total: 2.24,
  totalCalculado: 2.24,
};

export const EC_TRAMACO_LABEL_REQ_API_MOCK: IEcTramacoLabelReqApi = {
  lstCargaDestino: [
    {
      id: ECTramacoContratoId.DEV_MAIN,
      datoAdicional: {
        motivo: '',
      },
      destinatario: EC_TRAMACO_ADDRESS_RECIPIENT,
      carga: EC_TRAMACO_CARGA,
    },
  ],
  remitente: EC_TRAMACO_ADDRESS_SENDER,
};

export const EC_TRAMACO_LABEL_REQ_DB_MOCK: IEcTramacoLabelReqDb = {
  id: ECTramacoContratoId.DEV_MAIN,
  remitente: EC_TRAMACO_ADDRESS_SENDER,
  destinatario: EC_TRAMACO_ADDRESS_RECIPIENT,
  carga: EC_TRAMACO_CARGA,
};

export const EC_TRAMACO_LABEL_RES_API_MOCK: IEcTramacoLabelResApi = {
  cuerpoRespuesta: EC_TRAMACO_CUERPO_RESPUESTA_OK,
  salidaGenerarGuiaWs: {
    lstGuias: [
      {
        guia: EC_TRAMACO_LABEL_ID_MOCK,
        id: ECTramacoContratoId.DEV_MAIN,
      },
    ],
  },
};

export const EC_TRAMACO_LABEL_RES_DB_MOCK: IEcTramacoLabelResDb = {
  cuerpoRespuesta: EC_TRAMACO_CUERPO_RESPUESTA_OK,
  id: ECTramacoContratoId.DEV_MAIN,
  guia: EC_TRAMACO_LABEL_ID_MOCK,
};

export const EC_TRAMACO_REGISTERED_TRACKING_MOCK: IEcTramacoLabelRegisteredTracking = {
  estado: 'ADMISION',
  ciudad: '',
  descripcion: 'SS - GENERACION DE UNA GUIA DESDE EL WS',
  fechaHora: '09/05/2024 15:03',
  transporte: 'GUAYAQUIL',
  transportista: '',
  usuario: 'GENERICA@ASOCIADOS S.A. GENERICA@ASOCIADOS S.A.',
  celularVpn: '',
};

export const EC_TRAMACO_REGISTERED_TRANSACCION_MOCK: IEcTramacoLabelRegisteredTransaccion = {
  guia: EC_TRAMACO_LABEL_ID_MOCK,
  origen: 'GUAYAQUIL',
  centroCosto: '',
  cliente: 'GENERICA@ASOCIADOS S.A. , GENERICA@ASOCIADOS S.A.',
  contenedor: '',
  contrato: '020                 0001208',
  descripcion: 'PARTIDO SELECCION ECUADOR',
  destino: 'QUITO',
  destinoValido: '',
  entregadoPor: '',
  estado: 'ACTIVO',
  factura: '',
  fecha: '2024-05-09T00:00:00-05:00',
  gestorEntrega: 'GUAYAQUIL',
  localidad: 'GYE - GENERICA@ASOCIADOS / QUITO ',
  observacion: 'B CANT 2',
  paquetes: 1,
  parDestino: 'QUITO',
  parOrigen: 'GUAYAQUIL',
  pesoCliente: 2.0,
  pesoReal: 2.0,
  pesoValidado: 2.0,
  producto: 'CARGA COURIER',
  puntoEmision: 'GENERICA',
  quienRecibe: '',
  valorAsegurado: 0.0,
};

export const EC_TRAMACO_REGISTERED_MOCK: IEcTramacoLabelRegistered = {
  // type: 'respuestaTrackGuiaWs',
  cuerpoRespuesta: EC_TRAMACO_CUERPO_RESPUESTA_OK,
  lstSalidaTrackGuiaWs: [EC_TRAMACO_REGISTERED_TRACKING_MOCK, EC_TRAMACO_REGISTERED_TRACKING_MOCK],
  // lstServicios: [],
  transaccion: EC_TRAMACO_REGISTERED_TRANSACCION_MOCK,
  destinatario: EC_TRAMACO_ADDRESS_RECIPIENT,
  remitente: EC_TRAMACO_ADDRESS_SENDER,
};

export const EC_TRAMACO_PDF_MOCK: IEcTramacoLabelPdf = {
  cuerpoRespuesta: EC_TRAMACO_CUERPO_RESPUESTA_OK,
  pdfBase64: EC_TRAMACO_PDF_BASE64_MOCK,
};

export const EC_TRAMACO_LABEL_MOCK: IEcTramacoLabel = {
  objectOwnerId: 1,
  quoteReq: EC_TRAMACO_QUOTE_REQ_DB_MOCK,
  quoteRes: EC_TRAMACO_QUOTE_RES_DB_MOCK,
  labelReq: EC_TRAMACO_LABEL_REQ_DB_MOCK,
  labelRes: EC_TRAMACO_LABEL_RES_DB_MOCK,
  registered: EC_TRAMACO_REGISTERED_MOCK,
  pdf: EC_TRAMACO_PDF_MOCK,
};
