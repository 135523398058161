import {JestFixtureFacade, JestFixtureFacadeAware} from './jest-fixture-facade';
import {MatButtonToggleGroup as MatButtonToggleGroupInterface} from '../generic/components';
import {MatButtonToggle, MatButtonToggleGroup} from '@angular/material/button-toggle';
import {DebugElement} from '@angular/core';
import {By} from '@angular/platform-browser';

export class JestMatButtonToggleGroup implements JestFixtureFacadeAware, MatButtonToggleGroupInterface {
  constructor(public testId: string, public jestFixtureFacade: JestFixtureFacade) {}

  get buttonValues(): string[] {
    return this.buttons.map((b) => b.value);
  }

  get multiple(): boolean {
    return this.toggleGroup.multiple;
  }

  get value(): string | string[] {
    return this.toggleGroup.value;
  }

  set value(selectedValue: string | string[]) {
    if (Array.isArray(selectedValue)) {
      this.buttons.forEach((buttonToggle: MatButtonToggle) => {
        if (selectedValue.includes(buttonToggle.value)) {
          this.clickButton(buttonToggle);
        }
      });
    } else {
      const selectedButton = this.buttons.find((buttonToggle: MatButtonToggle) => buttonToggle.value === selectedValue);
      if (selectedButton) {
        this.clickButton(selectedButton);
      }
    }
  }

  get nativeHtmlButtons(): Array<HTMLButtonElement> {
    return this.buttons.map((b) => b._buttonElement.nativeElement);
  }

  private clickButton(buttonToggle: MatButtonToggle): void {
    buttonToggle._buttonElement.nativeElement.click();
    this.jestFixtureFacade.detectChanges();
  }

  private get toggleGroupDirectiveDebugElement(): DebugElement {
    return this.jestFixtureFacade.debugElement.query((element) => {
      const matches = element.nativeElement.getAttribute('test-id') === this.testId;
      if (matches && element.nativeElement.tagName !== 'MAT-BUTTON-TOGGLE-GROUP') {
        throw new Error('A test-id for a MatButtonToggleGroup must be placed on the <mat-button-toggle-group> element');
      }
      return matches;
    });
  }

  private get toggleGroup(): MatButtonToggleGroup {
    return this.toggleGroupDirectiveDebugElement.injector.get<MatButtonToggleGroup>(MatButtonToggleGroup);
  }

  private get buttons(): Array<MatButtonToggle> {
    return this.toggleGroupDirectiveDebugElement
      .queryAll(By.directive(MatButtonToggle))
      .map((debugElement) => debugElement.componentInstance);
  }
}
