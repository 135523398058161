import {NgModule} from '@angular/core';
import {BszMobileBridgeModule} from '@basuiz/mobile-bridge';
import {HybridActionService} from '@basuiz/web-app-hybrid';
import {MobileHybridActionService} from './mobile-hybrid-action.service';

@NgModule({
  imports: [BszMobileBridgeModule],
  providers: [{provide: HybridActionService, useClass: MobileHybridActionService}],
})
export class WebAppHybridMobileModule {}
