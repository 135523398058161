import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {OptionalWebAppCommonLocaleConfig, WEB_APP_COMMON_LOCALE_CONFIG} from './web-app-common-locale.config';
import {AfpRestResponse} from '../afp-rest/index';
import {LocaleSettings} from './definition/locale-settings.definition';
import {SupportedLocaleDTO} from './definition/supported-locale-dto.definition';
import {LocaleId} from '@basuiz/web-app-applet-api';

export type SupportedLocale = Pick<LocaleSettings, 'display'>;
export type SupportedLocales = Record<LocaleId, SupportedLocale>;

@Injectable()
export class SupportedLocalesService {
  // private static readonly URL = `/com.basuiz.afs.rest.services/rest/baseBankData/supportedLocalesList`; // to be used with mock-server & proxy.config.json
  private static readonly URL = process.env.API_BASE_URL + `/rest/baseBankData/supportedLocalesList`;

  private supportedLocalesCache: SupportedLocales;

  constructor(
    private httpClient: HttpClient,
    @Inject(WEB_APP_COMMON_LOCALE_CONFIG) private localeConfig: OptionalWebAppCommonLocaleConfig
  ) {
    if (!localeConfig) {
      throw new Error(`Service 'SupportedLocalesService' requires a set of locale settings to be passed via the WebAppModule.forRoot() method.
This service should be instantiated only by consumers who can guarantee that a WebAppCommonLocaleConfig is available.`);
    }
  }

  getSupportedLocales(): Observable<SupportedLocales> {
    if (this.supportedLocalesCache) {
      return of(this.supportedLocalesCache);
    } else {
      return this.httpClient.get<AfpRestResponse<SupportedLocaleDTO[]>>(SupportedLocalesService.URL, {}).pipe(
        map((response) => response.payload),
        map((dtoList) => dtoList.map((dto) => dto.languageTag)),
        map((languageTagList) => this.languageTagListToSupportedLocales(languageTagList)),
        tap((supportedLocales) => {
          this.supportedLocalesCache = supportedLocales;
        })
      );
    }
  }

  private languageTagListToSupportedLocales(languageTagList: string[]): SupportedLocales {
    const result: SupportedLocales = {};

    languageTagList.forEach((languageTag) => {
      const settings = this.localeConfig?.localeSettings[languageTag];
      if (settings) {
        result[languageTag] = {
          display: {...settings.display},
        };
      }
    });

    return result;
  }
}
