import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DevToolsComponent} from './dev-tools.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {DevToolsDialogComponent} from './dev-tools-dialog/dev-tools-dialog.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FormsModule} from '@angular/forms';
import {MatTreeModule} from '@angular/material/tree';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
  declarations: [DevToolsComponent, DevToolsDialogComponent],
  exports: [DevToolsComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatCardModule,
    MatCheckboxModule,
    FormsModule,
    MatTreeModule,
    MatTooltipModule,
    MatMenuModule,
  ],
})
export class ExtraDevToolsModule {}
