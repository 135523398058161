import {BszConfigFactory, BszConfigFactoryWithDeps} from '@basuiz/web-app-applet-api';

export function getBaseConfigFactoryWithDeps<CONFIG>(
  factorywithDeps: BszConfigFactoryWithDeps<CONFIG> | undefined
): BaseConfigFactoryWithDeps<CONFIG> {
  const {useFactory, deps} = factorywithDeps ?? {useFactory: (defaultConfig) => defaultConfig};
  return {
    baseFactory: useFactory,
    baseDeps: deps ?? [],
  };
}

export interface BaseConfigFactoryWithDeps<CONFIG> {
  baseFactory: BszConfigFactory<CONFIG>;
  baseDeps: unknown[];
}
