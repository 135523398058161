import {HttpErrorResponse} from '@angular/common/http';
import {AfpRestNotification} from './models/afp-rest-response.definitions';
import {TranslatedText} from '@basuiz/web-app-applet-api';

export class AfpRestError extends Error {
  private static readonly errorName = 'AfpRestError';

  readonly notifications: AfpRestNotification[] | undefined;

  // Use the static method to create new instances of this class
  private constructor(httpErrorResponse: HttpErrorResponse) {
    super(httpErrorResponse.message);
    this.name = AfpRestError.errorName;
    this.notifications = httpErrorResponse.error?.notifications as AfpRestNotification[] | undefined;
  }

  static create<T>(originalError: T): AfpRestError | T {
    if (originalError instanceof HttpErrorResponse) {
      return new AfpRestError(originalError);
    } else {
      console.warn(`${AfpRestError.errorName}.create should only be used with instances of HttpErrorResponse`);
      return originalError;
    }
  }

  static getFirstNotificationMessage(error: any): TranslatedText | undefined {
    if (error instanceof AfpRestError && !!error.notifications && error.notifications.length > 0) {
      return error.notifications[0].message;
    }
    return undefined;
  }
}
