import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {DefaultCurrencyService} from '../../data-access/default-currency.service';
import {loadDefaultCurrency, loadDefaultCurrencyFailed, loadDefaultCurrencySuccess} from './default-currency.actions';
import {of} from 'rxjs';

@Injectable()
export class DefaultCurrencyEffects {
  loadDefaultCurrency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDefaultCurrency),
      mergeMap(() =>
        this.defaultCurrencyService.fetch().pipe(
          map((defaultCurrency) => loadDefaultCurrencySuccess({defaultCurrency})),
          catchError((err) => {
            console.error(err);
            return of(loadDefaultCurrencyFailed());
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private defaultCurrencyService: DefaultCurrencyService) {}
}
