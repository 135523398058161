import {InjectionToken} from '@angular/core';
import {assertNever} from '../../utils/assert-never';
import {
  PaymentMoneyAccountNameFormatter,
  PaymentMoneyAccountNameFormatterProvider,
} from '../models/business-object-naming.definitions';

export const providePaymentMoneyAccountNameFormatter: PaymentMoneyAccountNameFormatterProvider = (factory, deps) => ({
  provide: PAYMENT_MONEY_ACCOUNT_NAME_FORMATTER,
  useFactory: factory,
  deps,
});

export const defaultPaymentMoneyAccountNameFormatter: PaymentMoneyAccountNameFormatter = (
  moneyAccount,
  displayedIn
) => {
  switch (displayedIn) {
    case 'view':
    case 'filter':
      return moneyAccount.name;
    case 'dropdown':
    case 'selection':
      return `${moneyAccount.name} : ${moneyAccount.balanceInCurrency}`;
    case 'subtitle':
      return `${moneyAccount.name} : ${moneyAccount.iban}`;
    default:
      assertNever(displayedIn);
  }
};

export const PAYMENT_MONEY_ACCOUNT_NAME_FORMATTER = new InjectionToken<PaymentMoneyAccountNameFormatter>(
  'bsz.web-app-common-business-object-naming.payment-money-account-name-pipe-formatter',
  {
    factory: () => defaultPaymentMoneyAccountNameFormatter,
  }
);
