import {A11yModule} from '@angular/cdk/a11y';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import {TranslateModule} from '@ngx-translate/core';

import {BszCalendar} from './bsz-calendar';
import {BszDateRangeInput} from './bsz-date-range-input';
import {BszDateRangePicker} from './bsz-date-range-picker';
import {BszDatepicker} from './bsz-datepicker';
import {BszDatepickerInput} from './bsz-datepicker-input';
import {BszDatePickerOverlay} from './bsz-datepicker-overlay';
import {BszDatePickerToggle} from './bsz-datepicker-toggle';
import {BszDatepickerValidators} from './bsz-datepicker-validators';

const DECLARATIONS_AND_EXPORTS = [
  BszDatepicker,
  BszCalendar,
  BszDatepickerInput,
  BszDateRangeInput,
  BszDatePickerToggle,
  BszDateRangePicker,
];
@NgModule({
  declarations: [BszDatePickerOverlay, ...DECLARATIONS_AND_EXPORTS],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatListModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    A11yModule,
    TranslateModule,
  ],
  exports: DECLARATIONS_AND_EXPORTS,
  providers: [BszDatepickerValidators],
})
export class BszDatepickerModule {}
