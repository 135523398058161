import {CurrencyDTO} from '../../data-access/currency-data.service';
import {Currency} from '../../models/currency.definitions';

export function currencyFactory(currencyDto: CurrencyDTO): Currency {
  const {id, isoCode, name} = currencyDto;
  return {
    ...currencyDto,
    get bszCurrency() {
      return {id, isoCode, name};
    },
  };
}
