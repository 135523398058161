import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AfpRestOperators, AfpRestResponse} from '../../afp-rest/index';
import {Observable} from 'rxjs';
import {FilterToSave, SavedFilter, SavedFilterKey} from '../models/saved-filter.interface';

@Injectable({
  providedIn: 'root',
})
export class SavedFiltersDataService {
  constructor(private httpClient: HttpClient) {}

  web3FilterKeyPrefix = 'WB3'; //use this prefix to exclude the Afp2.4 keys
  url: string = '/com.basuiz.afs.rest.services/rest/search';

  public fetchFilterList(filterKey: SavedFilterKey): Observable<SavedFilter[]> {
    return this.httpClient
      .get<AfpRestResponse<SavedFilter[]>>(`${this.url}/getFilterList/${this.web3FilterKeyPrefix}-${filterKey}`)
      .pipe(AfpRestOperators.extractPayload());
  }
  public saveFilter(saveFilter: FilterToSave): Observable<{}> {
    return this.httpClient
      .post<AfpRestResponse<{}>>(`${this.url}/saveFilter`, {
        ...saveFilter,
        type: `${this.web3FilterKeyPrefix}-${saveFilter.type}`,
      })
      .pipe(AfpRestOperators.extractPayload());
  }

  public deleteFilter(filtersIds: number[]): Observable<{}> {
    return this.httpClient
      .post<AfpRestResponse<{}>>(`${this.url}/deleteFilter`, filtersIds)
      .pipe(AfpRestOperators.extractPayload());
  }
}
