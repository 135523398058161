import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {BszI18nModule} from '@basuiz/i18n';

import {BszSelectable, BszSelectableCheckbox, BszSelectableModule} from '../bsz-selectable/index';
import {BszCell, BszCellDef, BszFooterCell, BszFooterCellDef, BszHeaderCell, BszHeaderCellDef} from './bsz-cell';
import {BszTable} from './bsz-table';
import {BszTableCollapsible} from './bsz-table-collapsible';
import {BszTableColumn} from './bsz-table-column';
import {BszTableGroupHeaderColumn} from './bsz-table-group-header-column';

const EXPORTED_DECLARATIONS = [
  BszTable,
  BszTableColumn,
  BszTableGroupHeaderColumn,
  BszHeaderCell,
  BszHeaderCellDef,
  BszFooterCell,
  BszFooterCellDef,
  BszCell,
  BszCellDef,
  BszTableCollapsible,
];

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatRippleModule,
    MatButtonModule,
    MatMenuModule,
    MatListModule,
    BszI18nModule,
    BszSelectableModule,
  ],
  exports: [
    ...EXPORTED_DECLARATIONS,

    // re-export selectable classes
    BszSelectable,
    BszSelectableCheckbox,
  ],
  declarations: EXPORTED_DECLARATIONS,
})
export class BszTableModule {}
