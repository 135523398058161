import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AccessibleLinkDirective} from './accessible-link.directive';

@NgModule({
  declarations: [AccessibleLinkDirective],
  imports: [CommonModule],
  exports: [AccessibleLinkDirective],
})
export class AccessibleLinkModule {}
