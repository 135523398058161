// IMPORTANT: do not modify this file

import {inject, InjectionToken, FactoryProvider} from '@angular/core';
import {BszConfigFactory, BszConfigFactoryWithDeps} from '@basuiz/web-app-applet-api';
import {AlertConfig} from './alert.config.definition';
import {getDefaultConfig} from './alert.config.default';
import {validateAlertConfig} from './alert.config.validation';
import {WEB_APP_EXPANDED_FEATURE_TOGGLE} from '@basuiz/web-app-feature-toggle';
import {UserRoleService} from '@basuiz/web-app-common';

// IMPORTANT: do not modify this file

export const ɵALERT_CONFIG = new InjectionToken<AlertConfig>('bsz.web-app.alert-config', {
  factory: () => inject(ALERT_CONFIG_DEFAULT),
});

// IMPORTANT: do not modify this file

export const ALERT_CONFIG_DEFAULT = new InjectionToken<AlertConfig>('bsz.web-app.alert-config.default', {
  factory: () => {
    const config = getDefaultConfig(inject(UserRoleService), inject(WEB_APP_EXPANDED_FEATURE_TOGGLE));
    const error = validateAlertConfig(config);
    if (error) {
      console.error('Default AlertConfig validation failed:', error);
    }
    return config;
  },
});

// IMPORTANT: do not modify this file

export function alertConfigProvider({useFactory, deps}: BszConfigFactoryWithDeps<AlertConfig>): FactoryProvider {
  return {
    provide: ɵALERT_CONFIG,
    useFactory: factoryWithValidation(useFactory),
    deps: [ALERT_CONFIG_DEFAULT, ...(deps || [])],
  };
}

// IMPORTANT: do not modify this file

function factoryWithValidation(factory: BszConfigFactory<AlertConfig>): BszConfigFactory<AlertConfig> {
  return (defaultConfig: AlertConfig, ...deps: unknown[]) => {
    const config = factory(defaultConfig, ...deps);
    const error = validateAlertConfig(config);
    if (error) {
      console.error('AlertConfig validation failed:', error);
    }
    return config;
  };
}

// IMPORTANT: do not modify this file
