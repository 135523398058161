import {AnimationEvent} from '@angular/animations';
import {AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, ViewEncapsulation} from '@angular/core';
import {AsyncSubject, forkJoin, timer} from 'rxjs';

import {BszSplashScreenAnimations} from './bsz-splash-screen-animations';
import {BszSplashScreenConfig} from './bsz-splash-screen-config';

@Component({
  templateUrl: 'bsz-splash-screen-container.html',
  styleUrls: ['bsz-splash-screen.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  animations: [
    BszSplashScreenAnimations.bszSplashScreenCloseTransition,
    BszSplashScreenAnimations.bszSplashScreenImageEnterTransition,
  ],
  host: {
    'class': 'bsz-splash-screen-container',
    'tabindex': '-1',
    'aria-modal': 'true',
    '[style.background-color]': 'backgroundColor',
    '[@bszSplashScreenCloseTransition]': 'true',
    '(@bszSplashScreenCloseTransition.done)': '_onAnimationDone($event)',
  },
})
export class BszSplashScreenContainer implements AfterViewInit {
  imgSrc?: string;
  backgroundColor?: string;
  tip?: string;
  info?: string;
  displaySpinner?: boolean;

  /** @private */
  _imgLoadingState: 'loading' | 'enter' = 'loading';

  /** @private */
  readonly _closeTransitionDone = new EventEmitter<void>();

  private readonly imgLoaded = new AsyncSubject<boolean>();

  constructor(config: BszSplashScreenConfig) {
    this.backgroundColor = config.backgroundColor;
    this.imgSrc = config.imgSrc;
    this.tip = config.tip;
    this.info = config.info;
    this.displaySpinner = config.displaySpinner;
  }

  ngAfterViewInit(): void {
    this.showImageAfterDelayOrAfterLoaded();
  }

  /** @private */
  _onAnimationDone(event: AnimationEvent) {
    if (event.toState === 'void') {
      this._closeTransitionDone.emit();
    }
  }

  /**
   * Transition in the image after a small delay, or after it's loaded
   * in case loading takes more time than the delay.
   */
  private showImageAfterDelayOrAfterLoaded() {
    const delayTimer = timer(500);

    // Subscribe to start the timer.
    delayTimer.subscribe();

    // forkJoin will emit when both observables complete.
    forkJoin([delayTimer, this.imgLoaded]).subscribe(() => (this._imgLoadingState = 'enter'));
  }

  /** @private */
  _imageLoaded() {
    this.imgLoaded.next(true);
    this.imgLoaded.complete();
  }
}
