import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {AuthDialogRepresentationField} from '../../../models/auth-dialog.definitions';
import {TranslatedText} from '@basuiz/web-app-applet-api';
import {TranslateService} from '@ngx-translate/core';
import {camelCaseToKebabCase} from '../../../../utils/string-utils';
import {existsTranslation} from '../../../../utils/translation-utils';

@Component({
  selector: 'bsz-fields-representation',
  templateUrl: './fields-representation.component.html',
  styleUrls: ['./fields-representation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldsRepresentationComponent {
  @Input()
  fields: AuthDialogRepresentationField[];

  constructor(private translateService: TranslateService) {}

  getFieldLabel(representationField: AuthDialogRepresentationField): TranslatedText {
    if (existsTranslation(this.translateService, representationField.label)) {
      const translationKey = representationField.label;
      return this.translateService.instant(translationKey);
    } else {
      const fieldNameInKebabCase = camelCaseToKebabCase(representationField.field);
      const translationKey = `web-app-common.auth.representation.property.${fieldNameInKebabCase}`;
      if (existsTranslation(this.translateService, translationKey)) {
        return this.translateService.instant(translationKey);
      } else {
        return representationField.label;
      }
    }
  }
}
