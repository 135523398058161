import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {BankDateDateService} from '../../data-access/bank-date.service';
import {loadBankDate, loadBankDateFailed, loadBankDateSuccess} from './bank-date.actions';
import {of} from 'rxjs';

@Injectable()
export class BankDateEffects {
  loadBankDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBankDate),
      mergeMap(() =>
        this.bankDateDataService.fetch().pipe(
          map((bankDate: string) => loadBankDateSuccess({bankDate})),
          catchError((error) => of(loadBankDateFailed()))
        )
      )
    )
  );

  constructor(private actions$: Actions, private bankDateDataService: BankDateDateService) {}
}
