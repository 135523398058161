import {BszObjectId, LocaleId} from '@basuiz/web-app-applet-api';

/** Common type used by multi-valued user settings */
export interface SelectorValue<T> {
  selector: BszObjectId;
  value: T;
}

export type DefaultTimePeriod =
  | {
      type: 'thisPeriod';
      quantity: 'month' | 'quarter' | 'year';
    }
  | {
      type: 'lastDays';
      quantity: 7 | 14 | 30 | 180;
    };

export function isDefaultTimePeriod(value: unknown): value is DefaultTimePeriod {
  if (!(value instanceof Object)) {
    return false;
  } else {
    const objectValue = value as DefaultTimePeriod;
    return (
      Object.keys(objectValue).length === 2 &&
      ((objectValue.type === 'thisPeriod' && ['month', 'quarter', 'year'].includes(objectValue.quantity)) ||
        (objectValue.type === 'lastDays' && [7, 14, 30, 180].includes(objectValue.quantity)))
    );
  }
}

/** Dictionary contain all Basuiz defined user-settings, grouped by settings-group.
 * This collection represents only the settings defined in the AFP Presentation Server.
 * No support is given for customer-defined user settings */
export interface UserSettings {
  // Source: https://bitbucket.sits.basuiz.net/projects/WBC/repos/server/browse/basuiz.front.server/src/main/java/com/basuiz/afs/server/bsp/client/ws/util/SettingsConstants.java?at=refs/heads/master
  general: GeneralUserSettings;
  reporting: ReportingUserSettings;
  contactInformation: ContactInformationUserSettings;
}

// Source: https://bitbucket.sits.basuiz.net/projects/WBC/repos/server/browse/basuiz.front.server/src/main/java/com/basuiz/afs/server/bsp/bl/settings/definitions/GeneralSettingsGroup.java
export interface GeneralUserSettings {
  /** Indicates if the user has the mobile app enabled */
  mobileAppEnabled: null | boolean;

  /** The user setting for allowing transaction signing on ChannelType MOBILE */
  mobileTransactionSigningEnabled: null | boolean;

  /**  Default reference currency */
  defaultReferenceCurrency: null | BszObjectId;

  /** Users preferred language / locale */
  preferredLanguage: null | LocaleId;
}

// Source: https://bitbucket.sits.basuiz.net/projects/WBC/repos/server/browse/basuiz.front.server/src/main/java/com/basuiz/afs/server/bsp/bl/settings/definitions/ReportingSettingsGroup.java
export interface ReportingUserSettings {
  /** Default time period */
  defaultTimePeriod: null | DefaultTimePeriod;
}

// Source: https://bitbucket.sits.basuiz.net/projects/WBC/repos/server/browse/basuiz.front.server/src/main/java/com/basuiz/afs/server/bsp/bl/settings/definitions/ContactInformationSettingsGroup.java
export interface ContactInformationUserSettings {
  /** Id of mobile phone address to be used by default for mobile communication */
  defaultMobileAddress: null | BszObjectId;

  /** Id of email address to be used by default for email communication */
  defaultEmailAddress: null | BszObjectId;
}

export type UserSettingsSubset = {
  [GROUP in keyof UserSettings]?: Partial<UserSettings[GROUP]>;
};
