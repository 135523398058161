export class PortalDebugService {
  private _navigationTracking: boolean = false;
  get navigationTracking() {
    return this._navigationTracking;
  }
  set navigationTracking(enabled: boolean) {
    this._navigationTracking = enabled;
  }

  private _navigationToDisabledPage: boolean = false;
  get navigationToDisabledPage() {
    return this._navigationToDisabledPage;
  }
  set navigationToDisabledPage(enabled: boolean) {
    this._navigationToDisabledPage = enabled;
  }

  private _navigationIntentHandling: boolean = false;
  get navigationIntentHandling() {
    return this._navigationIntentHandling;
  }
  set navigationIntentHandling(enabled: boolean) {
    this._navigationIntentHandling = enabled;
  }
}
