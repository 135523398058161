import {InjectionToken} from '@angular/core';

export type BszSnackbarHorizontalPosition = 'center' | 'left';

export class BszSnackbarConfig {
  duration?: number;
  horizontalPosition?: BszSnackbarHorizontalPosition;
  politeness?: 'off' | 'polite' | 'assertive';
  announcementMessage?: string;
  dismissable?: boolean;
  pauseOnHover?: boolean;
}

/** Injection token that can be used to specify default configuration */
export const BSZ_SNACK_BAR_DEFAULT_OPTIONS = new InjectionToken<Partial<BszSnackbarConfig>>(
  'bsz_snack_bar_default_options'
);
