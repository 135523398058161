import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {NavigationService} from './navigation.service';
import {PortalConfig} from '../config/portal.config.definition';
import {ɵPORTAL_CONFIG} from '../config/portal.config.provider';
import {PortalPage, PortalPageClass} from './classes/portal-page';
import {Observable} from 'rxjs';
import {PortalDebugService} from '../debug/portal-debug.service';

@Injectable({
  providedIn: 'root',
})
export class DisabledPageGuard implements CanActivate {
  private readonly disabledPortalPages: Array<PortalPageClass>;

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    @Inject(ɵPORTAL_CONFIG) config: PortalConfig,
    private portalDebugService: PortalDebugService
  ) {
    this.disabledPortalPages = config.navigation.disabledPortalPages;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const pageClassFromRouteData = this.getPageClassFromRouteDataOrFail(route);
    const allowNavigation: boolean =
      !pageClassFromRouteData || !this.disabledPortalPages.includes(pageClassFromRouteData);

    if (allowNavigation) {
      return true;
    } else {
      if (this.portalDebugService.navigationToDisabledPage) {
        console.log(
          `Navigation to page ${
            pageClassFromRouteData?.name ?? 'UNKNOWN_PAGE'
          } prevented. The page is disabled in the portal configuration.`
        );
      }
      return this.navigationService.isAppHandlingFirstNavigation ? this.router.parseUrl('') : false;
    }
  }

  private getPageClassFromRouteDataOrFail(route: ActivatedRouteSnapshot): PortalPageClass | undefined {
    const pageClass = route.data?.pageClass;
    if (!pageClass) {
      console.warn(`Missing property 'pageClass' in the data of the route for URL: /${route.url.join('/')}`);
    }
    if (!!pageClass && !(pageClass?.prototype instanceof PortalPage)) {
      const stringRoute: string = `/${route.url.join('/')}`;
      throw new Error(
        `Property 'pageClass' in the data of a route-config must be a CLASS that extend PortalPage. In route: ${stringRoute}`
      );
    }
    return pageClass;
  }
}
