import {BszFactoryProviderGenerator, UnionTypeFromArray} from '@basuiz/web-app-applet-api';

/**
 * A business object naming pipe is used to provide a display name for business object in different contexts in the UI.
 * Each context may require a different representation to e.g. fit in the available space, or provide relevant
 * info in the context.
 *
 * Basuiz have predefined known contexts that business object naming can be applied in.
 */
export const DisplayedIn = ['view', 'dropdown', 'filter', 'subtitle', 'selection'] as const;
export type DisplayedIn = UnionTypeFromArray<typeof DisplayedIn>;

/**
 * A BusinessObjectNameFormatter implements the naming of a business object when displayed in a given context.
 *
 * Basuiz provides default formatters for all business objects supported by the library. These may be overridden by
 * custom formatters to tailor the UI to business needs.
 *
 * Formatter functions should be pure functions; they should be deterministic, always returning the same output for the
 * same input, and they should not trigger any side effects.
 *
 * The business object passed to the formatter provides a limited set properties that are useful for display. Where
 * appropriate the properties are pre-formatted as currency amounts, IBANs etc.
 *
 * @param businessObjectToName a simplified view of the business object that a name is required for.
 * @param displayedIn: the UI context the businessObject will be displayed in.
 * @returns the string name of the businessObject as required when displayedIn the given context.
 */
export type BusinessObjectNameFormatter<T> = (businessObjectToName: T, displayedIn: DisplayedIn) => string;

export type BusinessPartnerNameFormatter = BusinessObjectNameFormatter<BusinessPartnerToName>;
export type CurrencyNameFormatter = BusinessObjectNameFormatter<CurrencyToName>;
export type PaymentMoneyAccountNameFormatter = BusinessObjectNameFormatter<PaymentMoneyAccountToName>;

/**
 * Public API provider methods that allow banks configure the business object naming library
 * by providing a custom BusinessObjectNameFormatter.
 */
export type BusinessPartnerNameFormatterProvider = BszFactoryProviderGenerator<BusinessPartnerNameFormatter>;
export type CurrencyNameFormatterProvider = BszFactoryProviderGenerator<CurrencyNameFormatter>;
export type PaymentMoneyAccountNameFormatterProvider = BszFactoryProviderGenerator<PaymentMoneyAccountNameFormatter>;

export interface BusinessPartnerToName {
  name: string;
  referenceCurrencyIsoCode: string;
  bpNumber: string;
  businessPartnerKey1: string;
  businessPartnerKey2: string;
  businessPartnerKey3: string;
  businessPartnerKey4: string;
  businessPartnerKey5: string;
  businessUnitName?: string;
}

export interface CurrencyToName {
  name: string;
  isoCode: string;
}

export interface PaymentMoneyAccountToName {
  accountNumber: string;
  name: string;
  businessPartner: string;
  iban: string;
  currencyIsoCode: string;
  balance: string;
  balanceInCurrency: string;
  availableAmount: string;
  availableAmountInCurrency: string;
  positionKey1: string;
  positionKey2: string;
  positionKey3: string;
  positionKey4: string;
  positionKey5: string;
  /**
   * Available with ACP version 5.1 and above
   */
  businessUnitName?: string;
}
