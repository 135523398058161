import {ActionWithoutResponse} from '@basuiz/mobile-bridge';

/**
 * Applet action that opens a native form that allows user to create a new
 * account transfer.
 */
export class NewAccountTransferAction extends ActionWithoutResponse<NewAccountTransferRequest> {
  readonly name = 'newAccountTransfer';
}

export interface NewAccountTransferRequest {
  /**
   * The debit money account id to make the transfer from
   */
  debitMoneyAccountId?: number;

  /**
   * The credit money account id to make the transfer to
   */
  creditMoneyAccountId?: number;
}
