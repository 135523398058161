import {createAction, props} from '@ngrx/store';
import {ErrorState} from '../../definitions/call-state.definition';
import {getErrorState} from '../../utils/error-state';
import {FilterToSave, SavedFilter, SavedFilterKey} from '../models/saved-filter.interface';

export const loadSavedFilterList = createAction(
  '[WebAppCommon/FilterFavourites] Load SavedFilter List',
  props<{savedFilterKey: SavedFilterKey}>()
);
export const loadSavedFilterListRequired = createAction(
  '[WebAppCommon/FilterFavourites] Require SavedFilter List',
  props<{savedFilterKey: SavedFilterKey}>()
);
export const loadSavedFilterListSuccess = createAction(
  '[WebAppCommon/FilterFavourites] Load SavedFilter List Success',
  props<{savedFilterList: SavedFilter[]; savedFilterKey: SavedFilterKey}>()
);
export const loadSavedFilterListFailed = createAction(
  '[WebAppCommon/FilterFavourites] Load SavedFilter List Failed',
  props<{savedFilterKey: SavedFilterKey; errorState: ErrorState}>()
);

export const saveFilter = createAction(
  '[WebAppCommon/FilterFavourites] Save Filter',
  props<{filterToSave: FilterToSave}>()
);
export const saveFilterSuccess = createAction(
  '[WebAppCommon/FilterFavourites] Save Filter Success',
  props<{savedFilter: FilterToSave}>()
);
export const saveFilterFailed = createAction('[WebAppCommon/FilterFavourites] Save Filter Failed', getErrorState);

export const deleteSavedFilters = createAction(
  '[WebAppCommon/FilterFavourites] Delete SavedFilter',
  props<{savedFilterKey: SavedFilterKey; savedFilter: SavedFilter}>()
);
export const deleteSavedFilterSuccess = createAction(
  '[WebAppCommon/FilterFavourites] Delete SavedFilters Success',
  props<{names: string[]; savedFilterKey: SavedFilterKey}>()
);
export const deleteSavedFilterFailed = createAction(
  '[WebAppCommon/FilterFavourites] Delete SavedFilters Failed',
  getErrorState
);
export const selectSavedFilter = createAction(
  '[WebAppCommon/FilterFavourites] Select SavedFilter',
  props<{name: string | null; savedFilterKey: SavedFilterKey}>()
);
