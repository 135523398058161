import {BszCountry, BszObjectId, TranslatedText} from '@basuiz/web-app-applet-api';

export interface Country {
  id: BszObjectId;
  /** @deprecated Use `isoCode2` instead */
  isoCode: string; // both isoCode and isoCode2 contain the same data, 'isoCode' is deprecated in AFP REST
  /** ISO 3166 code of the country */
  isoCode2: string;
  name: TranslatedText;
}

export function getBszCountry({id, isoCode2, name}: Country): BszCountry {
  return {id, isoCode: isoCode2, name};
}
