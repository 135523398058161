import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {MAT_DATE_RANGE_SELECTION_STRATEGY} from '@angular/material/datepicker';

import {BszDateRangeSelectionStrategy} from './bsz-date-range-selection-strategy';
import {BszDateRangeCollection} from './bsz-datepicker.definitions';
import {BszDatePickerOverlay} from './bsz-datepicker-overlay';

@Component({
  selector: 'bsz-date-range-picker',
  template: '',
  styleUrls: ['bsz-datepicker.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    // although it would not be a big impact, hiding the host element prevents conflicts or accessibility errors
    // because it can use aria attributes but the element by itself is not defined in the HTML standard
    hidden: 'hidden',
  },
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: BszDateRangeSelectionStrategy,
    },
  ],
})
export class BszDateRangePicker<D> extends BszDatePickerOverlay<D> {
  @Input() override presets: BszDateRangeCollection<D> | null = null;
  override readonly rangePicker = true;
}
