import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {TestingCoreModule} from '../testing-core/index';
import {MatDividerModule} from '@angular/material/divider';
import {BszCentralMessageComponent} from './bsz-central-message.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatDividerModule, TestingCoreModule],
  exports: [BszCentralMessageComponent],
  declarations: [BszCentralMessageComponent],
  providers: [],
})
export class BszCentralMessageModule {}
