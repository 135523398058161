import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BszHighlightTextModule, BszSelectSearchModule} from '@basuiz/ui-elements';
import {BszSelectSearchFilterPipe} from './bsz-select-search-filter.pipe';
import {BszSelectSearchFilterBszSelectSearchDirective} from './bsz-select-search-filter.bsz-select-search.directive';
import {BszSelectSearchFilterMatSelectDirective} from './bsz-select-search-filter.mat-select.directive';
import {BszSelectSearchHighlightFilteredComponent} from './bsz-select-search-highlight-filtered.component';

@NgModule({
  declarations: [
    BszSelectSearchFilterPipe,
    BszSelectSearchFilterBszSelectSearchDirective,
    BszSelectSearchFilterMatSelectDirective,
    BszSelectSearchHighlightFilteredComponent,
  ],
  imports: [CommonModule, BszSelectSearchModule, BszHighlightTextModule],
  providers: [BszSelectSearchFilterBszSelectSearchDirective, BszSelectSearchFilterMatSelectDirective],
  exports: [
    BszSelectSearchModule,
    BszSelectSearchFilterPipe,
    BszSelectSearchFilterBszSelectSearchDirective,
    BszSelectSearchFilterMatSelectDirective,
    BszSelectSearchHighlightFilteredComponent,
  ],
})
export class BszSelectSearchFilterModule {}
