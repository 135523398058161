import {
  BszI18nConfiguration,
  BszI18nInitializationFactory,
  BszI18nService,
  BszTranslationDictionary,
} from '@basuiz/i18n';

import {UserSettingsService} from '../user-settings/index';
import {OptionalWebAppCommonLocaleConfig, WEB_APP_COMMON_LOCALE_CONFIG} from './web-app-common-locale.config';
import {LocaleId} from '@basuiz/web-app-applet-api';
import {WebAppCommonLocaleConfig} from './definition/locale-config.definition';
import {CUSTOM_LOCALE_SOURCE, CustomLocaleSource} from './custom-locale-source.injection';
import {map} from 'rxjs/operators';
import {LoginOnBehalfService} from '../login-on-behalf/login-on-behalf.service';
import {firstValueFrom} from 'rxjs';

function getInitializerFactory(): BszI18nInitializationFactory {
  return (
    i18nService: BszI18nService,
    userSettingsService: UserSettingsService,
    loginOnBehalfService: LoginOnBehalfService,
    localeConfig: OptionalWebAppCommonLocaleConfig,
    customLocaleSource: CustomLocaleSource | undefined
  ) => {
    const initAsync = async () => {
      if (!localeConfig) {
        throw new Error(
          `Method 'getWebAppI18nConfiguration()' requires a set of locale settings to be defined in the options of 'WebAppModule.forRoot()`
        );
      }
      const localeId = await getLocaleId(userSettingsService, loginOnBehalfService, customLocaleSource, localeConfig);
      const translation = await getTranslations(localeId, localeConfig);
      const angularLocale = getAngularLocaleData(localeId, localeConfig);
      return i18nService.initialize({
        localeId,
        angularLocale,
        translation,
      });
    };

    return initAsync;
  };
}

/** Web-app preset configuration for BszI18nModule.
 * Usage: Add the following import to the application root module: BszI18nModule.forRoot(webAppI18nConfiguration).
 * This configuration sets the app active locale to the preferred locale of the user defined in the user settings.
 * In case the user has no preferred locale, it will use the bank's default locale instead.
 * It also applies to BszI18n the settings defined in the config of @basuiz/web-app-common-locale */
export function getWebAppI18nConfiguration(): BszI18nConfiguration {
  return {
    loader: {
      useFactory: getInitializerFactory(),
      deps: [
        BszI18nService,
        UserSettingsService,
        LoginOnBehalfService,
        WEB_APP_COMMON_LOCALE_CONFIG,
        CUSTOM_LOCALE_SOURCE,
      ],
    },
  };
}

async function getLocaleId(
  userSettingsService: UserSettingsService,
  loginOnBehalfService: LoginOnBehalfService,
  customLocaleSource: CustomLocaleSource | undefined,
  config: WebAppCommonLocaleConfig
): Promise<LocaleId> {
  const localeInternalSource: Promise<LocaleId | undefined> =
    typeof customLocaleSource === 'function' // checking for function because in specs the injection token return an empty object
      ? customLocaleSource()
      : getLocaleIdFromUserSettings(userSettingsService, loginOnBehalfService, config);

  const preferredLocaleId = (await localeInternalSource) ?? (await getLocaleIdFromExternalSource(config));

  if (preferredLocaleId) {
    if (!(preferredLocaleId in config.localeSettings)) {
      throw new Error(
        `Locale with id ${preferredLocaleId} not present in the locale settings and not handled by the source method.`
      );
    }
    return preferredLocaleId;
  } else {
    const defaultLocaleId = config.defaultLocaleId;
    if (!defaultLocaleId) {
      throw new Error(`Property 'defaultLocaleId' not provided in the locale settings of WebAppModule.forRoot()`);
    }
    if (!(defaultLocaleId in config.localeSettings)) {
      throw new Error(`No locale setting defined for the default locale id ${defaultLocaleId}.
Check the locale settings passed in WebAppModule.forRoot()`);
    }
    return defaultLocaleId;
  }
}

async function getLocaleIdFromUserSettings(
  userSettingsService: UserSettingsService,
  loginOnBehalfService: LoginOnBehalfService,
  config: WebAppCommonLocaleConfig
): Promise<LocaleId | undefined> {
  let localeId: LocaleId | undefined;
  let isUserLoggedOnBehalf: boolean = false;
  try {
    isUserLoggedOnBehalf = await firstValueFrom(loginOnBehalfService.isUserLoggedOnBehalf$());
  } catch (err) {
    console.error(
      `Failed to determine if user is logged on behalf. Assuming false and reading preferred locale from user-settings.`
    );
  }
  try {
    if (!isUserLoggedOnBehalf) {
      localeId = await firstValueFrom(
        userSettingsService.userSettings$.pipe(map(({general}) => general.preferredLanguage ?? undefined))
      );
    }
  } catch (err) {
    console.error('Failed to fetch locale from user settings in locale configuration');
  }

  if (localeId && !(localeId in config.localeSettings)) {
    console.warn(
      `No locale settings found for locale ${localeId}. Using as fallback: 1. externalLocaleId, 2. defaultLocaleId`
    );
    return undefined;
  }

  return localeId;
}

async function getLocaleIdFromExternalSource({
  externalLocaleId,
  localeSettings,
  defaultLocaleId,
}: WebAppCommonLocaleConfig): Promise<LocaleId | undefined> {
  let localeId: LocaleId | undefined;
  if (externalLocaleId) {
    try {
      localeId = await externalLocaleId;
    } catch (err) {
      console.error(`Failed to fetch locale from 'externalLocaleId' Promise in locale configuration`);
    }
  }

  if (localeId && !(localeId in localeSettings)) {
    console.warn(
      `No locale settings found for locale ${localeId}. Using as fallback 'defaultLocaleId: ${defaultLocaleId}' from the locale setting`
    );
    return undefined;
  }

  return localeId;
}

async function getTranslations(
  localeId: LocaleId,
  config: WebAppCommonLocaleConfig
): Promise<BszTranslationDictionary> {
  let translations: BszTranslationDictionary;
  try {
    translations = await config.translationsLoader(localeId);
  } catch (error) {
    console.error(error);
    console.error(`Could not load translations file for locale '${localeId}'.`);
    translations = {};
  }
  return translations;
}

function getAngularLocaleData(localeId: LocaleId, config: WebAppCommonLocaleConfig): unknown {
  const settings = config.localeSettings[localeId];
  if (!settings) {
    throw new Error(
      `No settings for locale ${localeId} provided in the configuration of @basuiz/web-app-common-locale`
    );
  }
  const angularLocaleData = settings.angularLocaleData;
  if (!angularLocaleData) {
    throw new Error(
      `No data provided in property 'angularLocaleData' for the settings of locale ${localeId} in the configuration of @basuiz/web-app-common-locale`
    );
  }
  return angularLocaleData;
}
