import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {CurrencyDataService, CurrencyDTO} from '../../data-access/currency-data.service';
import {of} from 'rxjs';
import {loadCurrencies, loadCurrenciesFailed, loadCurrenciesSuccess} from './currency.actions';

@Injectable()
export class CurrencyEffects {
  loadCurrencies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCurrencies),
      mergeMap(() =>
        this.currencyDataService.fetch().pipe(
          map((currencyList: CurrencyDTO[]) => loadCurrenciesSuccess({currencyList})),
          catchError((error) => of(loadCurrenciesFailed(error)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private currencyDataService: CurrencyDataService) {}
}
