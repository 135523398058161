import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BszSwipeableCarouselComponent} from './bsz-swipeable-carousel.component';
import {BszSwipeableCarouselSlideComponent} from './bsz-swipeable-carousel-slide.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [BszSwipeableCarouselComponent, BszSwipeableCarouselSlideComponent],
  exports: [BszSwipeableCarouselComponent, BszSwipeableCarouselSlideComponent],
})
export class BszSwipeableCarouselModule {}
