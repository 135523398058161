import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Inject,
  InjectionToken,
  Input,
  NgZone,
  Optional,
  Renderer2,
  SecurityContext,
} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {take} from 'rxjs/operators';

const DEFAULT_CLASS = 'bsz-highlight-text-highlighted--default';

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/API/TrustedTypePolicy
 * @see https://developer.mozilla.org/en-US/docs/Web/API/TrustedTypePolicyFactory/createPolicy
 *
 * @TODO Remove custom typings for the TrystedPolicy when https://github.com/microsoft/TypeScript/issues/30024 is done
 */
interface TrustedTypePolicy {
  /** A callback function in the form of a string that contains code to run when creating a TrustedHTML object. */
  createHTML: (value: string) => string;
  /** A callback function in the form of a string that contains code to run when creating a TrustedScript object. */
  createScript: (value: string) => string;
  /** A callback function in the form of a string that contains code to run when creating a TrustedScriptURL object. */
  createScriptURL: (value: string) => string;
}

declare global {
  interface Window {
    trustedTypes: {
      createPolicy?: (policyName: string, policyOptions: Partial<TrustedTypePolicy>) => TrustedTypePolicy;
    };
  }
}

export interface BszHighlightTextConfig {
  highlightClass: string | string[];
}

/** Injection token that can be used to specify default configuration */
export const BSZ_HIGHLIGHT_TEXT_OPTIONS = new InjectionToken<BszHighlightTextConfig>(
  'bsz_highlight_text_default_options'
);

@Directive({
  selector: '[bsz-highlight-text]',
})
export class BszHighlightText {
  @Input() caseSensitive = false;

  @Input()
  get highlightClass(): string | string[] {
    return this._highlightClass;
  }

  set highlightClass(highlightClass: string | string[]) {
    if (!highlightClass) {
      return;
    }
    const customClass = Array.isArray(highlightClass) ? highlightClass.join(' ') : highlightClass;
    this._highlightClass = customClass.length ? customClass : DEFAULT_CLASS;
  }

  @Input()
  get pattern(): string {
    return this._pattern;
  }

  set pattern(patternToHighlight: string) {
    this._pattern = patternToHighlight;
    this.highlightText();
  }

  @Input()
  get textContent(): string {
    return this._textContent;
  }

  set textContent(textContent: string) {
    this._textContent = textContent;
    this.highlightText();
  }

  private readonly sanitize: (value: string) => string;

  private _textContent = '';
  private _pattern = '';
  private _highlightClass = '';

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly cd: ChangeDetectorRef,
    private readonly ngZone: NgZone,
    private readonly domSanitizer: DomSanitizer,
    @Optional() @Inject(BSZ_HIGHLIGHT_TEXT_OPTIONS) private readonly highlightTextConfig: BszHighlightTextConfig | null
  ) {
    if (highlightTextConfig) {
      this.highlightClass = highlightTextConfig.highlightClass;
    } else {
      this.highlightClass = DEFAULT_CLASS;
    }

    this.sanitize = this.createSanitizerFn();
  }

  /**
   * It searches and replaces the text to highlight in the text, and sets it as content of the host
   */
  private highlightText(): void {
    // ngZone required because under some circumstances it needs to execute the block once other tasks are finished
    // ie: when used in options rendered in a select with search, if the search changes to be less restrictive (like removing
    // one character), the options that were hidden and are shown again don't highlight anything
    this.ngZone.onStable.pipe(take(1)).subscribe(() => {
      const nativeElement = this.elementRef.nativeElement;
      const textContent = this._textContent || '';

      const highlightedText = this.getFormattedText(textContent, this._pattern);
      this.renderer.setProperty(nativeElement, 'innerHTML', this.sanitize(highlightedText));

      // this is required for cases in which it is used inside of complex components that do not render it initially
      // like if they are inside of the mat-option element in a select. Without this, if there are preselected options
      // the select doesn't show them.
      // TODO: find a way to test that "markForCheck" is called.
      this.cd.markForCheck();
    });
  }

  /**
   * Replace the text to highlight with span elements with the highlight class
   */
  private getFormattedText(textContent: string, pattern: string): string {
    if (!pattern) {
      return textContent;
    }

    const regExp = new RegExp(`(${pattern})`, this.caseSensitive ? 'g' : 'gi');

    return textContent.replace(
      regExp,
      `<mark class="bsz-highlight-text-highlighted ${this._highlightClass}">$1</mark>`
    );
  }

  /**
   * We create a sanitizer object that leverages Angular's DomSanitizer which can also work
   * in environments where the Content-Security-Policy "require-trusted-types-for" is set
   *
   * **Notice:** the following approach will not work if "require-trusted-types-for" is used
   * in conjunction with "trusted-types 'none'"
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Security-Policy/require-trusted-types-for
   */
  private createSanitizerFn(): (value: string) => string {
    if (window.trustedTypes?.createPolicy) {
      const sanitizer = window.trustedTypes.createPolicy('bsz-ui-sdk#bsz-highlight-text', {
        createHTML: (htmlString: string) => this.domSanitizer.sanitize(SecurityContext.HTML, htmlString) ?? '',
      });

      return (value: string) => sanitizer.createHTML(value);
    }

    return (value: string) => this.domSanitizer.sanitize(SecurityContext.HTML, value) ?? '';
  }
}
