<div
  class="mdc-layout-grid bsz-filter-favourites"
  *ngIf="{
    savedFilters: savedFilters$ | async,
    selectedFilter: selectedFilter$ | async,
    disableState: disableState$ | async,
    loading: isLoading$ | async,
    hasError: hasErrorState$ | async
  } as data"
>
  <div class="mdc-layout-grid__inner" *ngIf="!data.hasError; else showError">
    <div
      class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-2-phone bsz-subtitle-1"
      test-id="web-app-common.filter-favourites.title"
    >
      {{ 'web-app-common.filter-favourites.title' | translate }}
    </div>

    <div
      class="mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-2-phone favorite-filter-saver bsz-display-desktop-none"
      *bszMobileContent
    >
      <ng-container [ngTemplateOutlet]="filterSaver"></ng-container>
    </div>
    <div
      class="mdc-layout-grid__cell--span-9-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone"
    >
      <mat-chip-list
        *ngIf="data.savedFilters?.length"
        [attr.aria-label]="'web-app-common.filter-favourites.chips-aria' | translate"
        [multiple]="false"
      >
        <mat-chip
          class="saved-filter bsz-caption"
          *ngFor="let savedFilter of data.savedFilters"
          test-id="web-app-common.filter-favourites.chip"
          [selected]="data.selectedFilter?.name === savedFilter.name"
          (click)="onSelect(savedFilter)"
          [removable]="!data.loading"
          (removed)="onRemove(savedFilter)"
        >
          {{ savedFilter.name }}
          <mat-icon
            *ngIf="data.selectedFilter?.name === savedFilter.name"
            [ngClass]="{disabled: data.loading}"
            matChipRemove
            >close</mat-icon
          >
        </mat-chip>
      </mat-chip-list>
      <div
        *ngIf="data.savedFilters?.length === 0"
        test-id="web-app-common.filter-favourites.no-saved-filter"
        class="bsz-caption bsz-text-disabled bsz-padding-t1 bsz-padding-b2"
      >
        {{ 'web-app-common.filter-favourites.no-saved-filter' | translate }}
      </div>
    </div>
    <div
      class="mdc-layout-grid__cell--span-1-desktop favorite-filter-saver bsz-display-mobile-none bsz-display-tablet-none bsz-display-desktop-block"
      *bszDesktopContent
    >
      <ng-container [ngTemplateOutlet]="filterSaver"></ng-container>
    </div>
  </div>

  <ng-template #showError>
    <bsz-wb-banner type="error">
      {{ 'web-app-common.filter-favourites.fetch-filters-error' | translate }}
    </bsz-wb-banner>
  </ng-template>

  <ng-template #filterSaver>
    <span
      [ngClass]="{disabled: data.disableState}"
      test-id="web-app-common.filter-favourites.filter-saver-star"
      (click)="isOverlayOpen = true"
      cdkOverlayOrigin
      #triggerIcon="cdkOverlayOrigin"
    >
      <mat-icon aria-hidden="false" *ngIf="data.selectedFilter" class="full-star">star</mat-icon>
      <mat-icon aria-hidden="false" *ngIf="!data.selectedFilter">star_border</mat-icon>
    </span>

    <ng-template
      [cdkConnectedOverlayPositionStrategy]
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="triggerIcon"
      [cdkConnectedOverlayPositions]="[{originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'top'}]"
      [cdkConnectedOverlayOpen]="isOverlayOpen"
      (overlayOutsideClick)="isOverlayOpen = false"
    >
      <mat-card test-id="web-app-common.filter-favourites.filter-saver-overlay">
        <div (click)="$event.stopPropagation()">
          <div class="bsz-subtitle-1 bsz-text-left" test-id="web-app-common.filter-favourites.filter-saver-title">
            <ng-container *ngIf="data.selectedFilter">
              {{ 'web-app-common.filter-favourites.form-title-update' | translate }}
            </ng-container>
            <ng-container *ngIf="!data.selectedFilter">
              {{ 'web-app-common.filter-favourites.form-title-create' | translate }}
            </ng-container>
          </div>
          <div class="bsz-display-flex">
            <mat-form-field class="bsz-width-65pct" test-id="web-app-common.filter-favourites.filter-saver-filter-name">
              <mat-label> {{ 'web-app-common.filter-favourites.filter-name-label' | translate }}</mat-label>
              <input matInput type="text" [formControl]="filterNameFormControl" />

              <mat-error
                *ngIf="filterNameFormControl.hasError('required') || filterNameFormControl.hasError('pattern')"
                >{{ 'web-app-common.filter-favourites.filter-name.error.required' | translate }}</mat-error
              >

              <mat-error *ngIf="filterNameFormControl.hasError('maxlength')">{{
                'web-app-common.filter-favourites.filter-name.error.max' | translate
              }}</mat-error>

              <mat-error *ngIf="filterNameFormControl.hasError('nameExists')">{{
                'web-app-common.filter-favourites.filter-name.error.name-exists' | translate
              }}</mat-error>
            </mat-form-field>
            <div class="bsz-width-35pct bsz-display-flex bsz-align-items-center bsz-justify-end">
              <button
                class="bsz-width-90pct"
                mat-flat-button
                type="button"
                color="accent"
                test-id="web-app-common.filter-favourites.filter-saver-save-button"
                [disabled]="data.disableState"
                (click)="saveFilter()"
              >
                <span *ngIf="data.selectedFilter">
                  {{ 'web-app-common.filter-favourites.update' | translate }}
                </span>
                <span *ngIf="!data.selectedFilter">
                  {{ 'web-app-common.filter-favourites.create' | translate }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </mat-card>
    </ng-template>
  </ng-template>
</div>
