import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WebAppDevToolsComponent} from './web-app-dev-tools.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {FeatureToggleDevToolComponent} from './feature-toggle/feature-toggle-dev-tool.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTreeModule} from '@angular/material/tree';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {UserRolesDevToolComponent} from './user-roles-dev-tool/user-roles-dev-tool.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
// eslint-disable-next-line no-restricted-imports
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {UserRolesInterceptor} from './user-roles-dev-tool/user-roles.interceptor';
import {FormsModule} from '@angular/forms';
import {AboutInfoDevToolComponent} from './about-info-dev-tool/about-info-dev-tool.component';
import {WebAppBannerModule} from '@basuiz/web-app-common';

@NgModule({
  imports: [
    CommonModule,
    DragDropModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatTreeModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    FormsModule,
    WebAppBannerModule,
  ],
  declarations: [
    WebAppDevToolsComponent,
    FeatureToggleDevToolComponent,
    UserRolesDevToolComponent,
    AboutInfoDevToolComponent,
  ],
  exports: [WebAppDevToolsComponent],
  providers: [{provide: HTTP_INTERCEPTORS, useClass: UserRolesInterceptor, multi: true}],
})
export class WebAppDevToolsModule {}
