import {ChangeDetectionStrategy, Component} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';

@Component({
  selector: 'bsz-quick-link-container',
  templateUrl: './quick-link-container.component.html',
  styleUrls: ['./quick-link-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickLinkContainerComponent {
  private readonly quickLinkCountSubject = new BehaviorSubject<number>(0);
  readonly quickLinkCount$: Observable<number> = this.quickLinkCountSubject.asObservable();

  notifyAddQuickLink() {
    this.quickLinkCountSubject.next(this.quickLinkCountSubject.value + 1);
  }
  notifyRemoveQuickLink() {
    this.quickLinkCountSubject.next(this.quickLinkCountSubject.value - 1);
  }
}
