import {AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, ViewChild} from '@angular/core';
import {
  AuthDialogRepresentationGroupDetails,
  AuthDialogRepresentationGroupRow,
} from '../../../../models/auth-dialog.definitions';
import {ReplaySubject, Subscription} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ɵPAGE_SIZE_FOR_FORM} from '../../../../../page-sizes/page-size-form';

@Component({
  selector: 'bsz-collective-representation-panel-desktop',
  templateUrl: './collective-representation-panel-desktop.component.html',
  styleUrls: ['./collective-representation-panel-desktop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectiveRepresentationPanelDesktopComponent implements AfterViewInit, OnDestroy {
  @Input()
  groupedDetailsObject: AuthDialogRepresentationGroupDetails;

  private readonly tableRowsSubject = new ReplaySubject<AuthDialogRepresentationGroupRow[]>(1);

  dataSource: MatTableDataSource<AuthDialogRepresentationGroupRow>;

  private subscriptions = new Subscription();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  constructor(@Inject(ɵPAGE_SIZE_FOR_FORM) public readonly pageSize: number) {}

  ngAfterViewInit() {
    this.subscriptions.add(
      this.tableRowsSubject.subscribe((tableRows) => {
        this.dataSource = new MatTableDataSource<AuthDialogRepresentationGroupRow>(tableRows);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      })
    );

    this.subscriptions.add(
      this.sort.sortChange.subscribe(() => {
        if (this.paginator) {
          this.paginator.pageIndex = 0;
        }
      })
    );

    this.groupedDetailsObject.details && this.tableRowsSubject.next(this.groupedDetailsObject.details);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
