<div *ngIf="attentionItemCounters$ | async as attentionItemCounters">
  <bsz-data-list [dataSource]="attentionItemCounters">
    <div
      class="bsz-body-1 bsz-cursor-pointer bsz-width-100pct counter-list-counter"
      *bszDataListItemDef="let attentionItemCounter"
      (click)="onCounterClicked(attentionItemCounter)"
      bszTestId="{{ bszTestIdPrefix }}.attentionItemCounter"
    >
      <div class="bsz-display-flex bsz-justify-space-between bsz-align-items-center bsz-padding-x4">
        <div class="bsz-display-flex bsz-padding-y2">
          <div
            *ngIf="config.showAlertCounterBadges"
            class="counter-list-mat-badge bsz-padding-r2"
            matBadgeSize="medium"
            [matBadge]="attentionItemCounter.value < 100 ? attentionItemCounter.value : '99+'"
            bszTestId="{{ bszTestIdPrefix }}.value"
          ></div>
          <div bszTestId="{{ bszTestIdPrefix }}.name">{{ getName(attentionItemCounter) | translate }}</div>
        </div>
        <mat-icon>chevron_right</mat-icon>
      </div>
      <mat-divider></mat-divider>
    </div>
  </bsz-data-list>
</div>
