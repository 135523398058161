import {Injectable, OnDestroy} from '@angular/core';
import {Subject, Subscription} from 'rxjs';

export const UPDATE_TABS_MESSAGE_EVENT_NAME = 'update-tabs-change';

// This service is meant to communicate between components that could require each
// other and cause circular dependencies. It communicates components allowing to
// emit/listen for information between them.
@Injectable({providedIn: 'root'})
export class CommunicationService<T> implements OnDestroy {
  private eventChannels: {[key: string]: Subject<T>} = {};
  private readonly subscriptions: Subscription[] = [];

  sendEvent(eventName: string, data: T) {
    this.registerEvent(eventName);
    this.eventChannels[eventName].next(data);
  }

  subscribeEvent(eventName: string, callback: (data: T) => unknown): Subscription {
    this.registerEvent(eventName);
    const subscribe = this.eventChannels[eventName].subscribe(callback);
    this.subscriptions.push(subscribe);
    return subscribe;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private registerEvent(eventName: string) {
    if (!(eventName in this.eventChannels)) {
      this.eventChannels[eventName] = new Subject<T>();
    }
  }
}
