import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';

/** A breadcrumb */
export interface BszBreadcrumbsItem {
  /** The text shown as breadcrumb */
  text: string;
  /** An optional HTML DOM id associated with the item */
  id?: string;
  /** Additional data associated with the breadcrumb */
  data?: {[prop: string]: any};
}

@Component({
  selector: 'bsz-breadcrumbs',
  templateUrl: './bsz-breadcrumbs.html',
  styleUrls: ['./bsz-breadcrumbs.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'bsz-breadcrumbs',
  },
})
export class BszBreadcrumbs {
  @Input() items: BszBreadcrumbsItem[] = [];
  @Input('aria-label') ariaLabel: string | null = null;
  @Input('aria-labelledby') ariaLabelledby: string | null = null;

  @Output() itemClick: EventEmitter<BszBreadcrumbsItem> = new EventEmitter();

  /**
   * Event emitted when a breadcrumb is clicked
   */
  itemClicked(item: BszBreadcrumbsItem) {
    this.itemClick.emit(item);
  }
}
