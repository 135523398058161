import {TranslationKey} from '@basuiz/web-app-applet-api';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {PortalPage} from '../navigation/classes/portal-page';
import {TranslationKeyWithParams} from '../navigation/translation-key-with-params';
import {InvestmentProposalOverviewPage} from './investment-proposal-overview.page';

export interface InvestmentProposalDetailPagePayload {
  investmentProposalId: number;
}

export class InvestmentProposalDetailPage extends PortalPage {
  constructor(public payload: InvestmentProposalDetailPagePayload) {
    super();
  }

  get parentPortalPage(): PortalPage | null {
    return new InvestmentProposalOverviewPage();
  }

  get breadcrumbText(): TranslationKey | TranslationKeyWithParams {
    return asTranslationKey('web-app-portal.pages.investment-proposal-detail.breadcrumb');
  }

  get breadcrumbTestId(): string {
    return 'web-app-portal.pages.investment-proposal-detail.breadcrumb';
  }

  get titleText(): TranslationKey {
    return asTranslationKey('web-app-portal.pages.investment-proposal-detail.window-title');
  }

  static readonly routerConfigPath = 'investment-proposals/detail';
  get route(): Array<string> {
    return [`/${InvestmentProposalDetailPage.routerConfigPath}`];
  }

  static readonly onInitialNavigationRedirectTo = InvestmentProposalOverviewPage;
}
