import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ContextActionWithTranslatedLabel} from '../context-action.interface';

@Component({
  selector: 'bsz-context-action',
  templateUrl: './context-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextActionComponent {
  @Input()
  contextAction: ContextActionWithTranslatedLabel;
}
