import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// @ts-ignore
import {bszPortalRoutes} from '@basuiz/web-app-portal/routes';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',
  },
  ...bszPortalRoutes,
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
