import {JestFormField} from './jest-form-field';
import {DebugElement, QueryList} from '@angular/core';
import {MatOption} from '@angular/material/core';
import {By} from '@angular/platform-browser';
import {FormFieldMatAutocomplete} from '../generic/components';
import {MatAutocomplete} from '@angular/material/autocomplete';

export class JestFormFieldMatAutocomplete extends JestFormField implements FormFieldMatAutocomplete {
  get matAutocomplete(): MatAutocomplete {
    const matAutocompleteElement: DebugElement = this.jestFixtureFacade.debugElement
      .query((element: DebugElement) => {
        return element.nativeElement.getAttribute('test-id') === this.testId;
      })
      .query(By.directive(MatAutocomplete));

    return <MatAutocomplete>matAutocompleteElement.componentInstance;
  }

  set value(value: string) {
    const options: QueryList<MatOption> = this.matAutocomplete.options;
    const option = options.find((opt) => {
      return opt.value?.toString() === value;
    });

    option?.select();
  }

  get value(): string {
    const options = this.matAutocomplete.options;
    const option = options.find((opt) => opt.selected);
    return option ? option.value : undefined;
  }
}
