import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {map, take} from 'rxjs/operators';
import {AuthJwtService} from './auth-jwt.service';

/**
 * Guard to be used in pages that should be available when
 * the user is not yet autenticathed, and must authenticate to use the rest of the app.
 * Pages like: 'signin', 'signup', 'forgot-password'
 */
@Injectable({
  providedIn: 'root',
})
export class AuthJwtUserNotLoggedInGuardService implements CanActivate {
  constructor(private authJwtService: AuthJwtService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    return this.authJwtService.userJwt$.pipe(
      take(1),
      map((userJwt) => {
        if (userJwt?.user?.objectId) {
          // When user token was found, redirect to main navigation page
          // TODO p4 change to app mainNavigationPage
          return this.router.parseUrl('/dashboard'); // redirect to user home page
          // return false; // don't use this, as this doesn't give any feedback to the user
        } else {
          return true;
        }
      })
    );
  }
}
