import {inject, InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';
import {BszUINotification} from '@basuiz/web-app-applet-api';
import {NotificationService} from './notification.service';

/** Provides the stream of UI-Notifications.
 * This stream will replay all UI-notifications already published before the consumer subscribed to the stream. */
export const UI_NOTIFICATION_STREAM = new InjectionToken<Observable<BszUINotification>>(
  'bsz.web-app-common.ui-notification-stream',
  {
    factory: () => inject(NotificationService).getNotificationStream(),
  }
);
