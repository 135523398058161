<button
  mat-menu-item
  class="user-account-menu--account-and-profile"
  (click)="onShowAccountAndProfile()"
  bszTestId="{{ bszTestIdPrefix }}.item.account-and-profile.button"
>
  <mat-icon>settings</mat-icon>
  <span class="bsz-text-bold">
    {{ 'web-app-portal.app-bar.user-account-menu.item.account-and-profile' | translate }}
  </span>
</button>

<button
  mat-menu-item
  class="user-account-menu--active-locale"
  *ngIf="showLocaleMenuOption"
  (click)="onChangeLocale()"
  bszTestId="{{ bszTestIdPrefix }}.item.active-locale.button"
>
  <mat-icon>language</mat-icon>
  <span class="bsz-text-bold">
    {{ 'web-app-portal.app-bar.user-account-menu.item.active-locale' | translate }}
  </span>
  <span class="bsz-body-1">{{ localeDisplayText }}</span>
</button>

<button
  mat-menu-item
  class="user-account-menu--change-password"
  *ngIf="changePasswordResolver"
  (click)="onChangePassword()"
  bszTestId="{{ bszTestIdPrefix }}.item.change-password.button"
>
  <mat-icon>vpn_key</mat-icon>
  <span class="bsz-text-bold">
    {{ 'web-app-portal.app-bar.user-account-menu.item.change-password' | translate }}
  </span>
</button>

<mat-divider></mat-divider>

<button
  mat-menu-item
  class="user-account-menu--logout"
  (click)="showLogoutConfirmationDialog()"
  bszTestId="{{ bszTestIdPrefix }}.item.logout.button"
>
  <mat-icon>login</mat-icon>
  <span class="bsz-text-bold">
    {{ 'web-app-portal.app-bar.user-account-menu.item.logout' | translate }}
  </span>
</button>

<ng-template #logoutConfirmation>
  <h3 mat-dialog-title class="bsz-title-6">
    {{ 'web-app-portal.logout.confirmation-dialog.title' | translate }}
  </h3>
  <p mat-dialog-content class="mat-typography">
    {{ 'web-app-portal.logout.confirmation-dialog.message' | translate }}
  </p>
  <div mat-dialog-actions align="end">
    <button
      mat-button
      color="accent"
      mat-dialog-close
      bszTestId="{{ bszTestIdPrefix }}.logout.confirmation-dialog.cancel.button"
    >
      {{ 'web-app-portal.logout.confirmation-dialog.cancel-button.label' | translate }}
    </button>
    <button
      mat-flat-button
      color="primary"
      [mat-dialog-close]="true"
      bszTestId="{{ bszTestIdPrefix }}.logout.confirmation-dialog.confirm.button"
    >
      {{ 'web-app-portal.logout.confirmation-dialog.confirm-button.label' | translate }}
    </button>
  </div>
</ng-template>
