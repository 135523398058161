import {PaymentMoneyAccountNameFormatter, providePaymentMoneyAccountNameFormatter} from '@basuiz/web-app-applet-sdk';

const NO_BREAK_SPACE = '\u00A0';

const paymentMoneyAccountNameFormatter: PaymentMoneyAccountNameFormatter = (paymentMoneyAccount, displayedIn) => {
  switch (displayedIn) {
    case 'view':
      return `${paymentMoneyAccount.name} :: ${paymentMoneyAccount.currencyIsoCode} :: ${paymentMoneyAccount.businessPartner}`;
    case 'dropdown':
    case 'filter':
    case 'subtitle':
    case 'selection':
      return `${paymentMoneyAccount.name} :: ${paymentMoneyAccount.availableAmountInCurrency} :: IBAN:${NO_BREAK_SPACE}${paymentMoneyAccount.iban}`;
    default:
      const error: never = displayedIn;
  }
};

export const BUSINESS_OBJECT_NAMING_CONFIG = [
  providePaymentMoneyAccountNameFormatter(() => paymentMoneyAccountNameFormatter),
];
