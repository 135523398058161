import {Injectable} from '@angular/core';
import {fromEvent, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, pairwise, share} from 'rxjs/operators';

export type ScrollObserverDirection = 'up' | 'down';

export interface ScrollObserversConfig {
  scrollElement?: HTMLElement;
  verticalThreshold?: number;
}

export interface ScrollObservers {
  thresholdPassObserver: Observable<boolean>;
  directionChangeObserver: Observable<ScrollObserverDirection>;
  scrollChangeObserver: Observable<number>;
}

const minimumThreshold = 10;

@Injectable({
  providedIn: 'root',
})
export class BszScrollObserver {
  getObservers(config?: ScrollObserversConfig): ScrollObservers {
    const scrollElement = config?.scrollElement || window;
    const verticalThreshold =
      config?.verticalThreshold === undefined || config.verticalThreshold < minimumThreshold
        ? minimumThreshold
        : config.verticalThreshold;

    return {
      thresholdPassObserver: this.thresholdPassObserver(scrollElement, verticalThreshold),
      directionChangeObserver: this.directionChangeObserver(scrollElement),
      scrollChangeObserver: this.scrollObservable(scrollElement),
    };
  }

  private directionChangeObserver(scrollElement: HTMLElement | Window): Observable<ScrollObserverDirection> {
    return this.scrollObservable(scrollElement).pipe(
      pairwise(),
      map(([initialY, finalY]): ScrollObserverDirection => (finalY < initialY ? 'up' : 'down')),
      distinctUntilChanged(),
      share()
    );
  }

  private thresholdPassObserver(
    scrollElement: HTMLElement | Window,
    verticalThreshold = minimumThreshold
  ): Observable<boolean> {
    return this.scrollObservable(scrollElement).pipe(
      map((scrollY) => scrollY > verticalThreshold),
      distinctUntilChanged(),
      share()
    );
  }

  private scrollObservable(scrollElement: HTMLElement | Window): Observable<number> {
    const isHTMLElement = scrollElement instanceof HTMLElement;
    return fromEvent(scrollElement, 'scroll').pipe(
      debounceTime(10),
      map(() => (isHTMLElement ? scrollElement.scrollTop : window.scrollY))
    );
  }
}
