import {Injectable, Injector} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {CommonConfig, ɵCOMMON_CONFIG} from '@basuiz/web-app-common/config';

// Server ESAPI RegExp list:
// https://bitbucket.sits.basuiz.net/projects/WBC/repos/rest/browse/com.basuiz.afs.rest.services/external/config/base/security/_esapi/validation.properties

@Injectable({
  providedIn: 'root',
})
export class BszEsapiValidationService {
  constructor(private injector: Injector) {}

  bszStrictSafeStringValidator = (formControl: UntypedFormControl) => {
    return this.commonConfig.esapiValidation.basuizStrictSafeStringRegExp.test(formControl.value)
      ? null
      : {
          bszStrictSafeString: true,
        };
  };

  bszCollaborationCommentValidator = (formControl: UntypedFormControl) => {
    return this.commonConfig.esapiValidation.basuizCollaborationCommentRegExp.test(formControl.value)
      ? null
      : {
          bszCollaborationComment: true,
        };
  };

  private get commonConfig(): CommonConfig {
    return this.injector.get(ɵCOMMON_CONFIG);
  }
}
