import {NgModule} from '@angular/core';
import {BszCurrencyNamePipe} from './pipes/currency-name.pipe';
import {FormattingModule} from '../formatting/index';

const BUSINESS_OBJECT_NAMING_PIPES = [BszCurrencyNamePipe];

@NgModule({
  imports: [FormattingModule],
  declarations: [...BUSINESS_OBJECT_NAMING_PIPES],
  exports: [...BUSINESS_OBJECT_NAMING_PIPES],
  providers: [...BUSINESS_OBJECT_NAMING_PIPES],
})
export class WebAppCommonBusinessObjectNamingModule {}
