import {NgModule} from '@angular/core';
import {WebAppPortalComponent} from '../../web-app-portal.component';
import {RegulatoryLinksComponent} from '../main-nav/regulatory-links/regulatory-links.component';
import {NavItemComponent} from '../main-nav/nav-item/nav-item.component';
import {MainNavComponent} from '../main-nav/main-nav.component';
import {AppBarComponent} from '../app-bar/app-bar.component';
import {PortalLayoutComponent} from './portal-layout.component';
import {UserAccountMenuComponent} from '../user-account-menu/user-account-menu.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {WebAppSelfServiceLocaleSelectorAppletModule} from '@basuiz/web-app-self-service-locale-selector-applet';
import {MatMenuModule} from '@angular/material/menu';
import {RouterModule} from '@angular/router';
import {ChangeLocaleDialogComponent} from '../user-account-menu/change-locale-dialog/change-locale-dialog.component';
import {AppBarGlobalFilterSelectionComponent} from '../app-bar/app-bar-global-filter-selection/app-bar-global-filter-selection.component';
import {WebAppAlertIndicatorAppletModule} from '@basuiz/web-app-alert-indicator-applet';
import {WebAppAlertListAppletModule} from '@basuiz/web-app-alert-list-applet';
import {PortalInitializationFailureComponent} from './portal-initialization-failure/portal-initialization-failure.component';
// eslint-disable-next-line
import {BszCentralMessageModule} from '@basuiz/web-app-common';
import {PortalBasicModule} from '../../util/portal-basic.module';
import {AppShellOutletReceiverModule} from '@basuiz/web-app-applet-sdk';

@NgModule({
  declarations: [
    MainNavComponent,
    RegulatoryLinksComponent,
    NavItemComponent,
    AppBarComponent,
    AppBarGlobalFilterSelectionComponent,
    WebAppPortalComponent,
    PortalLayoutComponent,
    UserAccountMenuComponent,
    ChangeLocaleDialogComponent,
    PortalInitializationFailureComponent,
  ],
  exports: [WebAppPortalComponent, PortalInitializationFailureComponent],
  imports: [
    RouterModule,
    PortalBasicModule,
    MatExpansionModule,
    MatToolbarModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatMenuModule,
    WebAppSelfServiceLocaleSelectorAppletModule,
    WebAppAlertIndicatorAppletModule,
    WebAppAlertListAppletModule,
    BszCentralMessageModule,
    AppShellOutletReceiverModule,
  ],
})
export class PortalLayoutModule {}
