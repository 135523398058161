import {TranslateCompiler} from '@ngx-translate/core';

import {BszTranslationDictionary} from '../i18n.service';

/**
 * This is a simplified version of the PhraseApp example compiler
 * https://github.com/phrase/ngx-translate-phraseapp/blob/master/src/compiler.ts
 *
 * We don't need to flatten the object because hierarchical translation files are not officially supported.
 */
export class BszI18nPhraseCompiler extends TranslateCompiler {
  constructor() {
    super();
  }

  /**
   * Replace the translation text with the respective escaped key
   *
   * Note: we use the default Phrase escaping (no config needed)
   */
  compile(value: string, lang: string): string {
    return `{{__phrase_${value}__}}`;
  }

  /**
   * Replace the translation text for each translation with the respective escaped key
   *
   * Example
   * from: { 'my_key': 'Yada yada' }
   * to: { 'my_key': '{{__phrase_my_key__}}' }
   */
  compileTranslations(translations: any, lang: string): any {
    const escapedTranslations: BszTranslationDictionary = {};

    Object.keys(translations).forEach((key) => {
      escapedTranslations[key] = this.compile(key, lang);
    });

    return escapedTranslations;
  }
}
