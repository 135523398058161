import {NgModule} from '@angular/core';
import {MatStepperModule} from '@angular/material/stepper';
import {BszI18nModule} from '@basuiz/i18n';

import {BszStepper} from './bsz-stepper';

@NgModule({
  imports: [MatStepperModule, BszI18nModule],
  exports: [BszStepper],
  declarations: [BszStepper],
})
export class BszStepperModule {}
