import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {TranslateModule} from '@ngx-translate/core';
import {BszConfirmationModalComponent} from './bsz-confirmation-modal.component';
import {TestingCoreModule} from '../testing-core/index';

@NgModule({
  declarations: [BszConfirmationModalComponent],
  imports: [CommonModule, MatButtonModule, MatDialogModule, TranslateModule, TestingCoreModule],
  exports: [BszConfirmationModalComponent],
})
export class BszConfirmationModalModule {}
