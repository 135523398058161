import {FactoryProvider, ModuleWithProviders, NgModule} from '@angular/core';
import {BszMobileBridgeModule, BszMobileBridgeService} from '@basuiz/mobile-bridge';
import {BszConfigFactoryWithDeps, LocaleId} from '@basuiz/web-app-applet-api';
import {
  AfpAuthNativeDialogInput,
  AfpAuthNativeDialogResult,
  authNativeConfigProvider,
  CUSTOM_LOCALE_SOURCE,
  CustomLocaleSource,
  WEB_APP_COMMON_LOCALE_CONFIG,
  WebAppCommonLocaleConfig,
} from '@basuiz/web-app-common';
import {PortalHybridConfig} from './config/portal-hybrid.config.definition';
import {portalHybridConfigProvider} from './config/portal-hybrid.config.provider';
import {WebAppPortalHybridComponent} from './web-app-portal-hybrid.component';
import {HybridActionNotHandled} from '@basuiz/web-app-hybrid';
import {WebAppHybridMobileModule} from '@basuiz/web-app-hybrid/mobile';
import {TransactionSigningAction} from '@basuiz/mobile-bridge-types';

/**
 * Options for the web-app-portal-hybrid library
 */
export interface WebAppPortalHybridModuleOptions {
  /**
   * Configuration of portal hybrid
   */
  config: BszConfigFactoryWithDeps<PortalHybridConfig>;
}

@NgModule({
  declarations: [WebAppPortalHybridComponent],
  imports: [BszMobileBridgeModule, WebAppHybridMobileModule],
  exports: [BszMobileBridgeModule, WebAppPortalHybridComponent],
})
export class WebAppPortalHybridModule {
  static forRoot(options?: WebAppPortalHybridModuleOptions): ModuleWithProviders<WebAppPortalHybridModule> {
    return {
      ngModule: WebAppPortalHybridModule,
      providers: [
        options?.config ? portalHybridConfigProvider(options.config) : [],
        hybridAuthConfigProvider(),
        customLocaleSource(),
      ],
    };
  }
}

function hybridAuthConfigProvider() {
  return authNativeConfigProvider({
    useFactory: (defaultConfig, mobileBridgeService: BszMobileBridgeService) => {
      const openAuthNativeDialog = async (
        request: AfpAuthNativeDialogInput
      ): Promise<AfpAuthNativeDialogResult | HybridActionNotHandled> => {
        const action = new TransactionSigningAction(request);
        const isSupported = await mobileBridgeService.isActionSupported(action);
        return isSupported ? mobileBridgeService.triggerAction(action) : 'unhandled';
      };
      return {
        ...defaultConfig,
        openAuthNativeDialog,
      };
    },
    deps: [BszMobileBridgeService],
  });
}

function customLocaleSource(): FactoryProvider {
  function useFactory(
    bszMobileBridgeService: BszMobileBridgeService,
    config: WebAppCommonLocaleConfig
  ): CustomLocaleSource | undefined {
    async function getNativeLocale(): Promise<LocaleId | undefined> {
      let nativeLocaleId: LocaleId | undefined;
      try {
        const nativeConfig = await bszMobileBridgeService.getConfiguration();
        nativeLocaleId = nativeConfig.locale;
      } catch (err) {
        console.error('Failed to retrieve the locale Id from the native app through the mobile bridge');
        console.error(err);
        return undefined;
      }

      if (!nativeLocaleId) {
        return undefined;
      }
      const settingsLocale = config.localeSettings[nativeLocaleId];
      if (settingsLocale) {
        return nativeLocaleId;
      } else {
        /* Hybrid request: in case of locale mismatch, default to another that shares the same language.
         * Note: in the future the mobile apps should guarantee a matching locale for consistency between
         * the native and web content not only in language but also in date and number formatting. */
        const localeSeparator = '-';
        const localeSeparatorIndex = nativeLocaleId.indexOf(localeSeparator);
        if (localeSeparatorIndex > 0) {
          const language: string = nativeLocaleId.substr(0, localeSeparatorIndex);
          if (language.length > 0) {
            const matchingLocaleId = Object.keys(config.localeSettings).find((localeId) =>
              localeId.startsWith(`${language}${localeSeparator}`)
            );
            if (matchingLocaleId) {
              console.warn(`No locale setting found for locale ${nativeLocaleId}, using locale ${matchingLocaleId} as fallback.
This might lead to localization inconsistencies if you are using web-app features mixed with your own features.`);
              return matchingLocaleId;
            }
          }
        }
      }
      console.warn(
        `No viable locale setting found for locale ${nativeLocaleId}, using the default locale ${config.defaultLocaleId} from the locale settings as fallback`
      );
      return undefined;
    }

    return bszMobileBridgeService.isInsideNative() ? getNativeLocale : undefined;
  }

  return {
    provide: CUSTOM_LOCALE_SOURCE,
    useFactory,
    deps: [BszMobileBridgeService, WEB_APP_COMMON_LOCALE_CONFIG],
  };
}
