import {createFeatureSelector, createSelector} from '@ngrx/store';
import {LoadingState} from '../../../definitions/call-state.definition';
import {getErrorMessage} from '../../../utils/error-state';
import {FEATURE_KEY, isCurrencyLoadedState, State} from './currency.reducer';
import {CurrencyDTO} from '../../data-access/currency-data.service';
import {CurrencyDictionary} from '../../models/currency.definitions';
import {currencyFactory} from './currency.factory';

export const selectState = createFeatureSelector<State>(FEATURE_KEY);

export const selectLoaded = createSelector(selectState, (state: State) => state.callState === LoadingState.LOADED);

export const selectLoading = createSelector(selectState, (state: State) => state.callState === LoadingState.LOADING);

export const selectError = createSelector(selectState, (state: State) => getErrorMessage(state.callState));

export const selectDictionary = createSelector(selectState, (state: State) => {
  if (!isCurrencyLoadedState(state)) {
    return undefined;
  } else {
    const dtoList: CurrencyDTO[] = Object.values(state.entities);
    return dtoList.map(currencyFactory).reduce<CurrencyDictionary>(
      (dict, currency) => ({
        ...dict,
        [currency.id]: currency,
      }),
      {}
    );
  }
});
