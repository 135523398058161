import {Action, createReducer, on} from '@ngrx/store';
import {CallState, LoadingState} from '../../../definitions/call-state.definition';
import {loadHoliday, loadHolidayFailed, loadHolidaySuccess} from './holiday.actions';
import {ISODate} from '../../../definitions/iso-date.definition';

export const FEATURE_KEY = 'web-app-common/holiday';

export interface PartialState {
  readonly [FEATURE_KEY]: State;
}

export interface State {
  holidayList: ISODate[] | undefined;
  countryId: number | undefined;
  callState: CallState;
}

export const initialState: State = {
  holidayList: undefined,
  countryId: undefined,
  callState: LoadingState.INIT,
};

const holidayReducer = createReducer(
  initialState,
  on(loadHoliday, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(loadHolidaySuccess, (state, action) => ({
    ...state,
    holidayList: action.holidayDtoList[0].holidayList,
    countryId: action.holidayDtoList[0].countryId,
    callState: LoadingState.LOADED,
  })),
  on(loadHolidayFailed, (state, error) => ({
    ...state,
    callState: error,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return holidayReducer(state, action);
}
