import {ViewWithPayload} from '@basuiz/mobile-bridge';
import {BSZ_WB_VIEW_NAMESPACE} from '../namespace';

/**
 * Show a view for a deep link, typically recieved as a push notification in
 * the native application
 */
export class DeepLinkView extends ViewWithPayload<DeepLinkViewPayload> {
  readonly name = `${BSZ_WB_VIEW_NAMESPACE}-deep-link`;
}

/**
 * The payload is a unknown at build time as the deep links are unique to
 * each customer. Because the data is sent in the notifiction as URL query
 * parameters it will be a simple collection of key/value pairs where the
 * value is either a string or an array of strings.
 *
 * No default handler can be provided for this view in the portal-hybrid.
 *
 * The bank is expected to provide a handler that will trigger a navigation
 * to the appropriate page for the deep link parameters. One of the
 * parameters would typically identify the desired page.
 */
export type DeepLinkViewPayload = Record<string, string | string[]>;
