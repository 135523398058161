import {HttpErrorResponse} from '@angular/common/http';
import {AfpRestNotification} from '../afp-rest/models/afp-rest-response.definitions';
import {CallState, ErrorState} from '../definitions/call-state.definition';

export function getErrorState(errorResponse: HttpErrorResponse): ErrorState {
  return {
    message: errorResponse.message,
    name: errorResponse.name,
    status: errorResponse.status,
    statusText: errorResponse.statusText,
    url: errorResponse.url,
    notifications: errorResponse.error?.notifications as AfpRestNotification[] | undefined,
  };
}

export function getErrorMessage(callState: CallState): string | null {
  if (isErrorState(callState)) {
    return (callState as ErrorState).message;
  }

  return null;
}

export function isErrorState(callState: CallState): callState is ErrorState {
  return (callState as ErrorState).message !== undefined;
}
