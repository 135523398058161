// IMPORTANT: do not modify this file

import {inject, InjectionToken, FactoryProvider} from '@angular/core';
import {BszConfigFactory, BszConfigFactoryWithDeps} from '@basuiz/web-app-applet-api';
import {SelfServiceInvestmentProposalConfig} from './self-service-investment-proposal.config.definition';
import {getDefaultConfig} from './self-service-investment-proposal.config.default';
import {validateSelfServiceInvestmentProposalConfig} from './self-service-investment-proposal.config.validation';

// IMPORTANT: do not modify this file

export const ɵSELF_SERVICE_INVESTMENT_PROPOSAL_CONFIG = new InjectionToken<SelfServiceInvestmentProposalConfig>(
  'bsz.web-app.self-service-investment-proposal-config',
  {
    factory: () => inject(SELF_SERVICE_INVESTMENT_PROPOSAL_CONFIG_DEFAULT),
  }
);

// IMPORTANT: do not modify this file

export const SELF_SERVICE_INVESTMENT_PROPOSAL_CONFIG_DEFAULT = new InjectionToken<SelfServiceInvestmentProposalConfig>(
  'bsz.web-app.self-service-investment-proposal-config.default',
  {
    factory: () => {
      const config = getDefaultConfig();
      const error = validateSelfServiceInvestmentProposalConfig(config);
      if (error) {
        console.error('Default SelfServiceInvestmentProposalConfig validation failed:', error);
      }
      return config;
    },
  }
);

// IMPORTANT: do not modify this file

export function selfServiceInvestmentProposalConfigProvider({
  useFactory,
  deps,
}: BszConfigFactoryWithDeps<SelfServiceInvestmentProposalConfig>): FactoryProvider {
  return {
    provide: ɵSELF_SERVICE_INVESTMENT_PROPOSAL_CONFIG,
    useFactory: factoryWithValidation(useFactory),
    deps: [SELF_SERVICE_INVESTMENT_PROPOSAL_CONFIG_DEFAULT, ...(deps || [])],
  };
}

// IMPORTANT: do not modify this file

function factoryWithValidation(
  factory: BszConfigFactory<SelfServiceInvestmentProposalConfig>
): BszConfigFactory<SelfServiceInvestmentProposalConfig> {
  return (defaultConfig: SelfServiceInvestmentProposalConfig, ...deps: unknown[]) => {
    const config = factory(defaultConfig, ...deps);
    const error = validateSelfServiceInvestmentProposalConfig(config);
    if (error) {
      console.error('SelfServiceInvestmentProposalConfig validation failed:', error);
    }
    return config;
  };
}

// IMPORTANT: do not modify this file
