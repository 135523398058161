import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AfpAuthDialogResult,
  AuthDialogVerificationRequest,
  CrontoOperation,
  CrontoPollingOperation,
} from '../../../models/auth-dialog.definitions';
import {UntypedFormControl, UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {CommonConfig, ɵCOMMON_CONFIG} from '@basuiz/web-app-common/config';
import {catchError, concatMap, expand, map, take, takeUntil} from 'rxjs/operators';
import {NotificationService} from '../../../../ui-notification/index';
import {AuthVerificationService} from '../../../data-access/auth-verification.service';
import {BehaviorSubject, EMPTY, Observable, Subject, Subscription, timer} from 'rxjs';
import {TranslationKey} from '@basuiz/web-app-applet-api';
import {HttpErrorResponse} from '@angular/common/http';
import {AfpRestResponse, isAfpRestResponse} from '../../../../afp-rest/index';
import {AfpAuthOperation, AfpAuthVerify, AfpAuthVerifyResponse} from '../../../models/auth.definitions';
import {assertNever} from '../../../../utils/assert-never';

@Component({
  selector: 'bsz-cronto-verification',
  templateUrl: './cronto-verification.component.html',
  styleUrls: ['./cronto-verification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrontoVerificationComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input()
  verificationRequest: AuthDialogVerificationRequest<CrontoOperation>;

  @Output()
  verificationResult: EventEmitter<AfpAuthDialogResult> = new EventEmitter<AfpAuthDialogResult>();

  @ViewChild('inputField') inputFieldElement: ElementRef;

  form: UntypedFormGroup;
  readonly numericOnlyResponseCode: boolean = this.config.auth.cronto.numericOnlyResponseCode;

  private readonly unsubscribe = new Subject();
  readonly inProgress = new BehaviorSubject<boolean>(false);

  readonly currentOperation = new BehaviorSubject<CrontoOperation | undefined>(undefined);

  readonly isPolling = new BehaviorSubject<boolean>(false);

  private sessionCancellationRequired: boolean = false;

  private tryOfflineSelected: boolean = false;

  private readonly inputFieldChange = new Subscription();

  constructor(
    @Inject(ɵCOMMON_CONFIG) private config: CommonConfig,
    private readonly notificationService: NotificationService,
    private authVerificationService: AuthVerificationService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.form = this.getInitForm();
    this.currentOperation.next(this.verificationRequest.operation);
    if (this.isOnlineCrontoOperation(this.verificationRequest.operation)) {
      this.sessionCancellationRequired = true;
      this.startPolling();
    }
    // Subscribe for first input field change in order to trigger field validation after autofocus
    this.inputFieldChange.add(
      this.form
        .get('verificationToken')
        ?.valueChanges.pipe(take(1))
        .subscribe((value) => {
          this.form.get('verificationToken')?.markAsTouched();
        })
    );
  }

  ngAfterViewChecked() {
    if (this.tryOfflineSelected) {
      this.tryOfflineSelected = false;
      this.inputFieldElement?.nativeElement.focus();
      this.changeDetectorRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
    this.inputFieldChange.unsubscribe();
    this.stopPolling();
    if (this.sessionCancellationRequired) {
      this.authVerificationService
        .cancelSigningSession(this.verificationRequest.transactionSigningObject)
        .toPromise()
        .catch(() => {});
    }
  }

  private getInitForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      verificationToken: new UntypedFormControl(''),
    });
  }

  cancel() {
    this.stopPolling();
    this.verificationResult.emit('CANCEL');
  }

  verify() {
    if (!this.form.valid) {
      return;
    }
    const verificationObject: AfpAuthVerify = {
      ...this.verificationRequest.transactionSigningObject,
      verificationAction: 'VERIFY',
      ...this.form.value,
    };
    this.callVerifyTransactionService(verificationObject)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (result) => this.handleSuccessResponse(result),
        (result: HttpErrorResponse) => this.handleErrorResponse(result)
      );
  }

  private callVerifyTransactionService(
    verificationObject: AfpAuthVerify
  ): Observable<AfpRestResponse<AfpAuthVerifyResponse>> {
    this.inProgress.next(true);
    return this.authVerificationService.verifyTransaction(verificationObject);
  }

  private startPolling() {
    const verificationObject: AfpAuthVerify = {
      ...this.verificationRequest.transactionSigningObject,
      verificationAction: 'PUSH_AUTH_CHECK',
    };
    this.isPolling.next(true);

    const pollData = this.callVerifyTransactionService(verificationObject).pipe(
      map((result) => this.handleSuccessResponse(result)),
      catchError((error: HttpErrorResponse) => this.handleErrorResponse(error))
    );

    pollData
      .pipe(
        expand(() =>
          timer(this.config.auth.cronto.pollingInterval).pipe(
            concatMap(() => (this.isPolling.getValue() ? pollData : EMPTY))
          )
        )
      )
      .subscribe();
  }

  private stopPolling() {
    this.isPolling.next(false);
    this.isPolling.complete();
  }

  private handleSuccessResponse(response: AfpRestResponse<AfpAuthVerifyResponse>): void {
    if (response.payload.transactionSigningVerification === 'RETRY') {
      this.inProgress.next(false);
      this.form.get('verificationToken')?.patchValue('', {emitEvent: false});
      this.form.get('verificationToken')?.setErrors({incorrectCode: true}, {emitEvent: false});
    } else if (response.payload.transactionSigningVerification === 'PENDING') {
      this.inProgress.next(false);
      return;
    } else if (response.payload.transactionSigningVerification === 'CANCELLED_BY_USER') {
      this.sessionCancellationRequired = false;
      this.cancel();
    } else if (response.payload.transactionSigningVerification === 'CORRECT') {
      this.sessionCancellationRequired = false;
      this.verificationResult.emit('SUCCESS');
    } else if (response.payload.transactionSigningVerification === 'WRONG') {
      this.sessionCancellationRequired = false;
      this.verificationResult.emit('FAILURE');
    }
  }

  private handleErrorResponse(response: HttpErrorResponse): Observable<never> {
    this.stopPolling();

    if (this.isUserHasBeenLocked(response)) {
      // TODO userLocked handling (requires BUM-2614)
      console.log('User has been locked and should be logged out');
    }

    this.inProgress.next(false);
    // TODO show general error notification until we have a way of showing notifications from response
    this.notificationService.error(asTranslationKey('web-app-common.auth.verification.general-verification-error'));

    return EMPTY;
  }

  private isUserHasBeenLocked(result: HttpErrorResponse) {
    return (
      isAfpRestResponse(result.error) &&
      result.error.notifications.some((notification) => notification.path === 'userLocked')
    );
  }

  isTryOfflineButtonAvailable(operation: CrontoOperation) {
    return this.isOnlineCrontoOperation(operation) && this.config.auth.cronto.allowOfflineFallback;
  }

  tryOffline() {
    this.stopPolling();
    this.currentOperation.next('CRONTO');
    this.tryOfflineSelected = true;
  }

  getProgressTranslationKey(operation: CrontoOperation): TranslationKey {
    switch (operation) {
      case 'CRONTO':
        return asTranslationKey('web-app-common.auth.verification.cronto-verification.offline.progress.message');
      case 'CRONTO_ONLINE':
        return asTranslationKey('web-app-common.auth.verification.cronto-verification.online.progress.message');
      case 'CRONTO_PUSH':
        return asTranslationKey('web-app-common.auth.verification.cronto-verification.push.progress.message');
      default:
        assertNever(operation);
    }
  }

  getTitleTranslationKey(operation: CrontoOperation) {
    switch (operation) {
      case 'CRONTO':
        return asTranslationKey('web-app-common.auth.verification.cronto-verification.offline.title');
      case 'CRONTO_ONLINE':
        return asTranslationKey('web-app-common.auth.verification.cronto-verification.online.title');
      case 'CRONTO_PUSH':
        return asTranslationKey('web-app-common.auth.verification.cronto-verification.push.title');
      default:
        assertNever(operation);
    }
  }

  getDescriptionTranslationKey(operation: CrontoOperation) {
    switch (operation) {
      case 'CRONTO':
        return asTranslationKey('web-app-common.auth.verification.cronto-verification.offline.description');
      case 'CRONTO_ONLINE':
        return asTranslationKey('web-app-common.auth.verification.cronto-verification.online.description');
      case 'CRONTO_PUSH':
        return asTranslationKey('web-app-common.auth.verification.cronto-verification.push.description');
      default:
        assertNever(operation);
    }
  }

  getInputErrorTranslationKey(): TranslationKey {
    const errors: ValidationErrors | null | undefined = this.form.get('verificationToken')?.errors;
    if (errors?.required) {
      return asTranslationKey('web-app-common.auth.verification.cronto-verification.offline.code.required');
    } else if (errors?.incorrectCode) {
      return asTranslationKey('web-app-common.auth.verification.cronto-verification.offline.code.incorrect');
    } else if (errors?.pattern) {
      return asTranslationKey('web-app-common.auth.verification.cronto-verification.offline.code.pattern-mismatch');
    } else {
      return asTranslationKey('web-app-common.auth.verification.cronto-verification.offline.code.unknown-error');
    }
  }

  getPollingProgressTranslationKey(operation: CrontoPollingOperation) {
    switch (operation) {
      case 'CRONTO_ONLINE':
        return asTranslationKey('web-app-common.auth.verification.cronto-verification.online.polling.progress.message');
      case 'CRONTO_PUSH':
        return asTranslationKey('web-app-common.auth.verification.cronto-verification.push.polling.progress.message');
      default:
        assertNever(operation);
    }
  }

  getTryOfflineTranslationKey(operation: CrontoPollingOperation) {
    switch (operation) {
      case 'CRONTO_ONLINE':
        return asTranslationKey('web-app-common.auth.verification.cronto-verification.online.button.try-offline');
      case 'CRONTO_PUSH':
        return asTranslationKey('web-app-common.auth.verification.cronto-verification.push.button.try-offline');
      default:
        assertNever(operation);
    }
  }

  isOnlineCrontoOperation(operation: AfpAuthOperation): operation is CrontoPollingOperation {
    return ['CRONTO_ONLINE', 'CRONTO_PUSH'].includes(operation);
  }
}
