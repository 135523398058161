import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {BszMobileBridgeService} from '@basuiz/mobile-bridge';
import {NavigationService, PortalDebugService} from '@basuiz/web-app-portal';

@Injectable({
  providedIn: 'root',
})
export class HybridPageGuard implements CanActivate {
  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private portalDebugService: PortalDebugService,
    private mobileBridgeService: BszMobileBridgeService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.mobileBridgeService.isInsideNative()) {
      return true;
    }

    if (this.portalDebugService.navigationToDisabledPage) {
      console.warn(
        `Navigation to route /${route.url.join('/')} prevented. The page is only available in the native-hybrid app.`
      );
    }

    return this.navigationService.isAppHandlingFirstNavigation ? this.router.parseUrl('') : false;
  }
}
