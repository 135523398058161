import {animate, state, style, transition, trigger} from '@angular/animations';

const transitionTiming = '300ms cubic-bezier(0.4, 0, 0.2, 1)';

export class BszModalBottomSheetAnimations {
  private static readonly containerStyle = {
    maximized: {
      transform: 'translateY(0)',
    },
    minimized: {
      transform: 'translateY(calc(100% - {{ minimizedHeight }}))',
    },
  };

  static readonly modalBottomSheetMaximizeMinimizeTransition = trigger('modalBottomSheetMaximizeMinimizeTransition', [
    state('maximized', style(BszModalBottomSheetAnimations.containerStyle.maximized)),
    state('minimized', style(BszModalBottomSheetAnimations.containerStyle.minimized), {
      params: {minimizedHeight: '64px'},
    }),
    transition('void  => maximized', [style({transform: 'translateY(100%)'}), animate(transitionTiming)]),
    transition('* => void', [animate(transitionTiming, style({transform: 'translateY(100%)'}))]),
    transition('maximized => minimized', animate(transitionTiming)),
    transition('minimized => maximized', animate(transitionTiming)),
  ]);
}
