<ng-container *ngIf="pageHelp$ | async as pageHelp">
  <!-- This component adds a <div> #staticContainer (invisible to the user) that remains in the normal document flow
  -- and a <div> fixed-container that is fixed to the bottom of the viewport.
  -- The heights of both divs are kept in sync.
  -- An IntersectionObserver checks whether the static <div> (invisible to the user but not the browser)
  -- is visible (intersects with the viewport), and then makes the fixed-container visible on the bottom of the page
  -- by eliminating progressively the margin-top that pushes the the fixed-container out of the viewport.
  -- Also, the fixed container should use a negative z-index until the help indicator is visible,
  -- so that the empty container does not prevent clicking on some page content visible below the fixed-container.
  --
  -- The motivation for this solution is to avoid having to guarantee a minimum height to all portal-pages
  -- just for this single feature, given that the current assumption is that most customers will not use it.
  -->

  <div #staticContainer [ngStyle]="{height: helpIndicatorContent.offsetHeight + 'px'}">
    <div
      class="fixed-container"
      [ngStyle]="{
        'height': helpIndicatorContent.offsetHeight + 'px',
        'z-index': ((intersection$ | async) || 0) > 0.7 ? 5 : -1
      }"
    >
      <div
        #helpIndicatorContent
        class="bsz-page-help"
        [ngStyle]="{'margin-top': helpIndicatorContent.offsetHeight * (1 - ((intersection$ | async) || 0)) + 'px'}"
      >
        <mat-icon>help</mat-icon>
        <a (click)="showHelpContent(pageHelp)" class="bsz-body-1">
          {{ 'web-app-portal.bsz-portal-page-layout.help.link' | translate }}
        </a>
      </div>
    </div>
  </div>
</ng-container>
