import {InjectionToken} from '@angular/core';

export interface BszNotificationToastConfig<D = unknown> {
  duration?: number;
  politeness?: 'off' | 'polite' | 'assertive';
  announcementMessage?: string;
  data?: D;
}

/** Injection token that can be used to specify default configuration */
export const BSZ_NOTIFICATION_TOAST_DEFAULT_OPTIONS = new InjectionToken<Partial<BszNotificationToastConfig>>(
  'bsz_notification_toast_default_options'
);
