import {TranslationKey} from '@basuiz/web-app-applet-api';
import {TranslationKeyWithParams} from '../translation-key-with-params';

/** Base class for all pages of the web-app-portal */
export abstract class PortalPage {
  /* This private property prevents accidental assignments of other objects
     to a variable/property declared as a PortalPage. For more info, search 'Nominal typing in Typescript' */
  private __nominal: void;

  /** Marks the page to be reset.
   * It is up to the page-component to determine what needs to be done to reset the page.
   * The most common use is to reset the context of any context-aware applet used in the page.
   * An `undefined` value tells @basuiz/web-app-portal to use its own logic to determine when a new page needs
   * to be reset. If a new page should not be reset, modify `needsReset` to false.
   * If the value is `undefined`, the portal will marks for reset every page created by an intent-handler,
   * i.e. on imperative navigations pages will be marked for reset.
   * */
  public needsReset: boolean | undefined = undefined;

  /** Used by the UnsavedDataGuard to determine whether to open or not to show the confirmation dialog when leaving the page.
   * Note: To enable this feature add the guard UnsavedDataGuard to the page's route.
   */
  public hasUnsavedData: boolean = false;

  /** Computes the route for the page, the result must be a valid Angular-router route pointing
  to the specific PortalPageComponent the PortalPage is designed for */
  abstract get route(): Array<string>;

  /**
   * Returns a page corresponding to the logical parent of this page in the application hierarchy.
   * Or 'null' if the current page is located directly under the home page.
   */
  abstract get parentPortalPage(): PortalPage | null;

  /** Text that appears on the breadcrumbs shown on the page header */
  abstract get breadcrumbText(): TranslationKey | TranslationKeyWithParams;

  abstract get breadcrumbTestId(): string;

  /** Text to be shown in the window title (and browser history).
   * The window title is updated on every navigation with the text of the current page.
   * This text is not used directly, but passed to another translation where the bank can add,
   * for example, the bank's name as a prefix */
  abstract get titleText(): TranslationKey;

  /** Computes the breadcrumbs of the corresponding PortalPageComponent instance */
  get breadcrumbs(): PortalPage[] {
    const parentPortalPages: PortalPage[] = this.parentPortalPage ? this.parentPortalPage.breadcrumbs : [];

    return [...parentPortalPages, this];
  }

  ɵresetMemory: {alreadyResetContextIdSet: Set<string>} = {alreadyResetContextIdSet: new Set()};
}

export interface PortalPageClass {
  new (...args: any): PortalPage;

  /** Value to be assigned to the 'path' property of the Angular 'Route' generated by method 'generatePortalRoute' */
  readonly routerConfigPath: string;

  /** Redirects the portal to a different page if the application happens to be loading on this page.
   * Commonly used for pages that require a payload in order to display some useful information
   * (and which is not available when the app just started),
   * these pages can choose to redirect the portal to a another page that requires no payload. */
  readonly onInitialNavigationRedirectTo?: PortalPageClass;
}
