import {JestFixtureFacade, JestFixtureFacadeAware} from './jest-fixture-facade';
import {JestMatError} from './jest-mat-error';
import {JestMatLabel} from './jest-mat-label';
import {FormField} from '../generic/components';

export class JestFormField implements JestFixtureFacadeAware, FormField {
  constructor(public testId: string, public jestFixtureFacade: JestFixtureFacade) {}

  get error(): JestMatError {
    return new JestMatError(this._el.querySelector('mat-error'));
  }

  get label(): JestMatLabel {
    return new JestMatLabel(this._el.querySelector('mat-label'));
  }

  get element(): HTMLElement {
    return <HTMLElement>this._el;
  }

  private get _el(): Element {
    return this.jestFixtureFacade.select(this.testId) ?? this.jestFixtureFacade.selectFromParent(this.testId);
  }
}
