import {CurrencyAmountFormatter} from './currency-amount.formatters';
import {MINUS} from '../const/minus.const';
import {NO_BREAK_SPACE} from '../const/no-break-space.const';

/** A currency amount formatter that produces the format expected by Swiss German speaking users.
 * E.g. 'CHF 12'045.55' or 'CHF -33.00'
 * Notice that this formatter produces non-breaking string, i.e. both the spaces and the minus symbol are non-breaking
 * in order to ensure the amount will not be broken in multiple lines by the browser.
 *
 * This formatter uses Intl.NumberFormat to format the passed amount. Please notice that this formatter is meant
 * for applications running in the browser. Calling this formatter in the server (e.g. a Node application) might
 * produce the wrong output since Node usually ships without most on the locales. If this is essential for you,
 * you might want to add a dependency to https://www.npmjs.com/package/full-icu to your server application
 * to get the missing locale data.
 * */
export const currencyAmountDeChCustomFormatter: CurrencyAmountFormatter = (amount, currencyIsoCode, options) => {
  const {digitsInfo, showIsoCode} = options;
  const isoPrefix: string = showIsoCode ? currencyIsoCode + NO_BREAK_SPACE : '';
  const minusPrefix: string = amount < 0 ? MINUS : '';

  const formattedAmount: string = getIntlFormatter(currencyIsoCode, digitsInfo).format(Math.abs(amount));
  const numericPart = formattedAmount.substr(prefixToRemoveLength);

  return isoPrefix + minusPrefix + numericPart;
};

const deCH = 'de-CH';
const prefixToRemoveLength = 'ISO '.length;
const noDigitsInfo = '#';

type IsoCode = string;
type DigitsInfo = string;

const intlFormatters: Record<IsoCode, Record<DigitsInfo, Intl.NumberFormat>> = {};

function getIntlFormatter(isoCode: IsoCode, digitsInfo?: DigitsInfo): Intl.NumberFormat {
  return digitsInfo ? getIntlFormatterWithDigitsInfo(isoCode, digitsInfo) : getIntlFormatterWithoutDigitsInfo(isoCode);
}

function getFormatterRecordForIsoCode(isoCode: IsoCode): Record<DigitsInfo, Intl.NumberFormat> {
  if (!intlFormatters[isoCode]) {
    intlFormatters[isoCode] = {};
  }
  return intlFormatters[isoCode];
}

function getIntlFormatterWithoutDigitsInfo(isoCode: string): Intl.NumberFormat {
  const formattersOfCurrency = getFormatterRecordForIsoCode(isoCode);
  const formatter = formattersOfCurrency[noDigitsInfo];
  if (!formatter) {
    formattersOfCurrency[noDigitsInfo] = new Intl.NumberFormat(deCH, {
      style: 'currency',
      currency: isoCode,
      currencyDisplay: 'code',
    });
  }
  return formattersOfCurrency[noDigitsInfo];
}

function getIntlFormatterWithDigitsInfo(isoCode: string, digitsInfo: string): Intl.NumberFormat {
  const formattersOfCurrency = getFormatterRecordForIsoCode(isoCode);
  const formatter = formattersOfCurrency[digitsInfo];
  if (!formatter) {
    const [integerPart, fractionPart] = digitsInfo.split('.');
    const [fractionMin, fractionMax] = (fractionPart ?? '').split('-');

    const minimumIntegerDigits = integerPart && Number.isInteger(Number(integerPart)) ? Number(integerPart) : 1;
    const minimumFractionDigits = Number.isInteger(Number(fractionMin)) ? Number(fractionMin) : undefined;
    const maximumFractionDigits = Number.isInteger(Number(fractionMax)) ? Number(fractionMax) : undefined;

    formattersOfCurrency[digitsInfo] = new Intl.NumberFormat(deCH, {
      style: 'currency',
      currency: isoCode,
      currencyDisplay: 'code',
      minimumIntegerDigits,
      minimumFractionDigits,
      maximumFractionDigits,
    });
  }
  return formattersOfCurrency[digitsInfo];
}
