import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BszBannerType} from '@basuiz/ui-elements';
import {TranslatedText} from '@basuiz/web-app-applet-api';

@Component({
  selector: 'bsz-wb-banner',
  template: `<bsz-contextual-banner
    [type]="type"
    [dismissable]="_dismissable || closed?.observed"
    (closed)="closed.emit()"
  >
    <div class="bsz-body-1">
      <ng-content></ng-content>
    </div>

    <bsz-banner-actions *ngIf="actionLabel">
      <bsz-banner-action (click)="actionClick.emit()">{{ actionLabel }}</bsz-banner-action>
    </bsz-banner-actions>
  </bsz-contextual-banner>`,
})
export class WebAppBannerComponent {
  @Input()
  type: BszBannerType;

  _dismissable: boolean = false;
  @Input()
  set dismissable(value: BooleanInput) {
    this._dismissable = coerceBooleanProperty(value);
  }

  @Input()
  actionLabel: TranslatedText;

  @Output()
  readonly closed = new EventEmitter<void>();

  @Output()
  readonly actionClick = new EventEmitter<void>();
}
