import {NgModule} from '@angular/core';
import {ContextActionMenuModule} from './context-action-menu/context-action-menu.module';
import {BszProgressBarModule} from './progress-indicator/bsz-progress-bar.module';
import {BszValueRangeModule} from './value-range/bsz-value-range.module';
import {BszTechnicalErrorModule} from './technical-error/bsz-technical-error.module';

@NgModule({
  exports: [ContextActionMenuModule, BszTechnicalErrorModule, BszValueRangeModule, BszProgressBarModule],
})
/** @deprecated this module is meant to be removed in the medium term. Please:
 *  - replace imports of this module with the individual modules your applet / shared-library needs
 *  - do NOT extend this module with new imports/exports/providers
 *
 *  Check also the 'basic' modules available at libs/web-app-common/src/lib/web-app-basic
 *  it might be that the imports you need are already provided by one of the basic modules. */
export class WebAppCommonModule {}
