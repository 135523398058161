<ng-container *ngIf="singleRepresentation">
  <ng-container *bszDesktopContent>
    <div class="bsz-subtitle-2 bsz-padding-b4">{{
      'web-app-common.auth.representation.single.title' | translate
    }}</div>
    <div class="bsz-subtitle-1">{{ singleRepresentation.title | translate }}</div>
  </ng-container>

  <ng-container *bszMobileContent>
    <div class="bsz-body-1 bsz-padding-b4">{{ 'web-app-common.auth.representation.single.title' | translate }}</div>
    <div class="bsz-subtitle-2">{{ singleRepresentation.title | translate }}</div>
  </ng-container>

  <bsz-fields-representation [fields]="singleRepresentation.details"></bsz-fields-representation>
</ng-container>

<ng-container *ngIf="collectiveRepresentation">
  <ng-container *bszDesktopContent>
    <div class="bsz-subtitle-2 bsz-padding-b4">{{
      'web-app-common.auth.representation.collective.title' | translate
    }}</div>
    <div class="bsz-subtitle-1">{{ collectiveRepresentation.title | translate }}</div>
  </ng-container>

  <ng-container *bszMobileContent>
    <div class="bsz-body-1 bsz-padding-b4">{{
      'web-app-common.auth.representation.collective.title' | translate
    }}</div>
    <div class="bsz-subtitle-2">{{ collectiveRepresentation.title | translate }}</div>
  </ng-container>

  <bsz-fields-representation [fields]="collectiveRepresentation.summary"></bsz-fields-representation>
  <bsz-collective-representation
    [groupedDetails]="collectiveRepresentation.groupedDetails"
  ></bsz-collective-representation>
</ng-container>
