import {Inject, Pipe, PipeTransform} from '@angular/core';
import {isUndefined, throwInvalidPipeArgument} from '../../helpers/formatting-helpers';
import Big from 'big.js';
import {postProcessLocalizedNumericValue} from './post-process-localized-numeric-value';
import {VALID_ISO_CODE_REG_EXP} from '../../const/valid-iso-code-reg-exp';
import {BszI18nService} from '@basuiz/i18n';
import {CurrencyPipe as AngularCurrencyPipe} from '@angular/common';
import {
  CurrencyCompactAmountFormatters,
  ɵCURRENCY_COMPACT_AMOUNT_FORMATTERS,
} from '../../formatters/currency-compact-amount.formatters';

@Pipe({
  name: 'bszCurrencyCompact',
})
export class BszCurrencyCompactPipe implements PipeTransform {
  constructor(
    private i18nService: BszI18nService,
    private angularCurrencyPipe: AngularCurrencyPipe,
    @Inject(ɵCURRENCY_COMPACT_AMOUNT_FORMATTERS) private customCompactFormatters: CurrencyCompactAmountFormatters
  ) {}

  private isIsoCodeParamValid(isoCode: string | null | undefined): isoCode is string {
    const isString = typeof isoCode === 'string';
    const isValidFormat = isString && !!isoCode?.match(VALID_ISO_CODE_REG_EXP);
    return isValidFormat;
  }

  transform(
    input: number | Big | null | undefined,
    isoCode: string | null | undefined,
    showISOCode: boolean = true
  ): string | null {
    // return empty string for nil params
    if (isUndefined(input) || isUndefined(isoCode)) {
      return '';
    }

    // if value is not of an accepted type then fail
    if (typeof input !== 'number' && !(input instanceof Big)) {
      throwInvalidPipeArgument(BszCurrencyCompactPipe, input);
    }

    // if ISO code is not of an accepted type then fail
    if (!this.isIsoCodeParamValid(isoCode)) {
      throwInvalidPipeArgument(BszCurrencyCompactPipe, isoCode);
    }
    return this.formatCompactValue(Number(input), isoCode, showISOCode);
  }

  private formatCompactValue(amount: number, isoCode: string, showISOCode: boolean): string | null {
    const localeId = this.i18nService.localeId;
    const customCompactAmountFormatter = this.customCompactFormatters[localeId];
    const formatted: string | null = customCompactAmountFormatter
      ? customCompactAmountFormatter(amount, isoCode)
      : this.defaultCompactAmountFormatter(amount, isoCode, localeId, showISOCode);
    return formatted ? postProcessLocalizedNumericValue(amount, formatted) : formatted;
  }

  private defaultCompactAmountFormatter(
    amount: number,
    isoCode: string,
    localeId: string,
    showISOCode: boolean
  ): string | null {
    const display: string = showISOCode ? 'code' : '';
    let formatted: string | null;

    try {
      const {value, suffix} = this.getShortScaleValue(amount);
      formatted = this.addSuffix(
        this.angularCurrencyPipe.transform(value, isoCode, display, undefined, localeId),
        suffix,
        showISOCode
      );
    } catch (e) {
      formatted = this.angularCurrencyPipe.transform(Number(amount), isoCode, display, undefined, localeId);
    }
    return formatted;
  }

  private addSuffix(amountWithCurrency: string | null, suffix: string, showISOCode: boolean): string {
    if (!amountWithCurrency) {
      throw new Error('cannot add suffix');
    }

    // Regex: extract numeric value from a string e.g 1.100.1000,100 or -1,100,100.100 or 100 or -4.2 or -4,2 etc...
    const amountMatch = amountWithCurrency.match(/([-+]?\d[\d,.]*)/gm);
    if (!amountMatch) {
      throw new Error('cannot add suffix');
    }

    const amount = amountMatch[0];
    const currency = amountWithCurrency.replace(amount, '').trim();

    if (showISOCode) {
      // For some locales e.g. es-ES it-IT the currency is placed at the end of the string after the amount.
      // the following condition is checking if the last character isn't a numeric value and therefore the currency is at the end
      if (isNaN(+amountWithCurrency[amountWithCurrency.length - 1])) {
        return `${amount}${suffix} ${currency}`;
      }
      return `${currency} ${amount}${suffix}`;
    }
    return `${amount}${suffix}`;
  }

  private getShortScaleValue(input: number | Big): {value: number; suffix: string} {
    const value = Number(input);
    const absoluteValue = Math.abs(value);

    const oneThousand = 1000;
    const oneMillion = 1000 * 1000;
    const oneBillion = 1000 * oneMillion;

    if (absoluteValue >= oneBillion) {
      return {
        value: value / oneBillion,
        suffix: 'B',
      };
    } else if (absoluteValue >= oneMillion) {
      return {
        value: value / oneMillion,
        suffix: 'M',
      };
    } else if (absoluteValue >= oneThousand) {
      return {
        value: value / oneThousand,
        suffix: 'k',
      };
    }

    return {
      value,
      suffix: '',
    };
  }
}
