/**
 * ========================================================
 * DANGER: DO NOT COMMIT THIS FILE, AND add it to gitignore
 * ========================================================
 *
 * Rename the sample file from "./demo-private-users.sample.mock.ts"
 * to "./demo-private-users.mock.ts", and add any private (email) user
 * demo account to this new file, which shouldn't be commited to the git repo.
 */

import {Role} from '../user/roles/role.model';
import {UserWithoutPassword} from '../user/user.model';

/**
 * All users inside demoPrivateUsers will be used in any service
 * that sends an email to this user's email. This is the case even for testing
 * purpose, so be aware that an email dispatch WILL be sent to the emails in this list.
 *
 * WARNING: For the DB Seed files, in order to not commit into the git repo, any private data
 * curently to get the data of some private users we use the order of the array.
 * So before you add or re-order any new private user, check on the DB Seed files to see if the `demoPrivateUsers`
 * list is being used somehwere sensitive.
 */
export const demoPrivateUsers: UserWithoutPassword[] = [
  {
    // 0 - eb
    email: 'ebastidas3@gmail.com',
    isActive: true,
    isFirstSetup: true,
    emailVerifyToken: '00000000-0000-0000-0000-000000000000',
    isEmailVerified: true,
    firstname: 'Erick',
    lastname: 'Bastidas',
    phone: '1234567890',
    roles: [Role.user, Role.superadmin],
  },
  {
    // 1 - sc
    email: 'scordova3@gmail.com',
    isActive: true,
    isFirstSetup: true,
    emailVerifyToken: '00000000-0000-0000-0000-000000000000',
    isEmailVerified: true,
    firstname: 'Santiago',
    lastname: 'Córdova',
    phone: '1234567890',
    roles: [Role.user],
  },
  {
    // 2 - as
    email: 'andresxsg@gmail.com',
    isActive: true,
    isFirstSetup: true,
    emailVerifyToken: '00000000-0000-0000-0000-000000000000',
    isEmailVerified: true,
    firstname: 'Andrés',
    lastname: 'Subía',
    phone: '1234567890',
    roles: [Role.user],
  },
  {
    // 3 - sp
    email: 'sofiapoveda@gmail.com',
    isActive: true,
    isFirstSetup: true,
    emailVerifyToken: '00000000-0000-0000-0000-000000000000',
    isEmailVerified: true,
    firstname: 'Sofia',
    lastname: 'Poveda',
    phone: '1234567890',
    roles: [Role.user],
  },
];
