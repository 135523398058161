import {NavigationIntent} from '../../navigation/classes/navigation-intent';

export interface ModifyUserDataIntentPayload {
  modificationType: 'address' | 'contact-details' | 'web-app-settings' | 'collection-bank-account';
  settingsGroup?: 'general' | 'user-info' /** Specify the settings group to modify */;
}

export class ModifyUserDataIntent extends NavigationIntent {
  constructor(public payload: ModifyUserDataIntentPayload) {
    super();
  }
}
