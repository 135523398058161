import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TranslatedText} from '@basuiz/web-app-applet-api';

@Component({
  selector: 'bsz-overlay-disabled-form',
  templateUrl: './bsz-overlay-disabled-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BszOverlayDisabledFormComponent {
  @Input() set title(text: TranslatedText) {
    this.titleMessage = text?.trim();
  }
  @Input() set message(text: TranslatedText) {
    this.textMessage = text?.trim();
  }

  titleMessage: TranslatedText;
  textMessage: TranslatedText;

  readonly bszTestIdPrefix = 'web-app-common.overlay-disabled-form';
}
