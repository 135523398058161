import {ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {
  callActiveLocaleUpdateHook,
  CUSTOM_LOCALE_SOURCE,
  CustomLocaleSource,
  LoginOnBehalfService,
  OptionalWebAppCommonLocaleConfig,
  SupportedLocale,
  SupportedLocales,
  SupportedLocalesService,
  UserSettingsService,
  WEB_APP_COMMON_LOCALE_CONFIG,
  WEB_APP_REBOOT_HANDLER,
  WebAppRebootHandler,
} from '@basuiz/web-app-common';
import {BszI18nService} from '@basuiz/i18n';
import {BehaviorSubject, from, Observable, of, throwError} from 'rxjs';
import {catchError, map, switchMap, take, tap} from 'rxjs/operators';
import {LocaleId} from '@basuiz/web-app-applet-api';

type SelectorLocale = SupportedLocale & {localeId: LocaleId};

interface ComponentState {
  showError: boolean;
  savingLocale: boolean;
  selectedLocaleId: LocaleId | undefined;
  changeUponSelection: boolean;
  selectorLocaleData: LocaleSelectorData | undefined;
}

interface LocaleSelectorData {
  optionList: SelectorLocale[];
  activeOption: SelectorLocale;
}

@Component({
  selector: 'bsz-self-service-locale-selector',
  templateUrl: './self-service-locale-selector.component.html',
  styleUrls: ['./self-service-locale-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelfServiceLocaleSelectorComponent {
  readonly bszTestIdPrefix =
    'web-app-self-service-self-service-locale-selector-applet.self-service-self-service-locale-selector';
  @Input()
  set changeUponSelection(value: boolean) {
    this.updateComponentState({changeUponSelection: value});
  }

  @Output()
  readonly selectedAndActiveLocalesDiffer: EventEmitter<boolean> = new EventEmitter();

  readonly componentState$ = new BehaviorSubject<ComponentState>({
    savingLocale: false,
    selectedLocaleId: undefined,
    showError: false,
    changeUponSelection: false,
    selectorLocaleData: undefined,
  });

  updateComponentState(changes: Partial<ComponentState>) {
    this.componentState$.next({
      ...this.componentState$.getValue(),
      ...changes,
    });
  }

  get componentState(): ComponentState {
    return this.componentState$.getValue();
  }

  constructor(
    private readonly supportedLocalesService: SupportedLocalesService,
    private readonly bszI18nService: BszI18nService,
    private readonly userSettingsService: UserSettingsService,
    private readonly loginOnBehalfService: LoginOnBehalfService,
    @Inject(WEB_APP_COMMON_LOCALE_CONFIG) private readonly localeConfig: OptionalWebAppCommonLocaleConfig,
    @Inject(CUSTOM_LOCALE_SOURCE) readonly customLocaleSource: CustomLocaleSource | undefined,
    @Inject(WEB_APP_REBOOT_HANDLER) private readonly rebootHandler: WebAppRebootHandler
  ) {
    if (customLocaleSource) {
      console.error(
        `Applet '@basuiz/web-app-self-service-locale-selector-applet' is designed to work only with the web-app default locale source but you have defined a custom source in the provider CUSTOM_LOCALE_SOURCE`
      );
    }
    if (!this.localeConfig) {
      console.error(
        `Applet '@basuiz/web-app-self-service-locale-selector-applet' requires a set of locale settings to be defined via the 'WebAppModule.forRoot()' options`
      );
    }
    this.loginOnBehalfService
      .isUserLoggedOnBehalf$()
      .pipe(take(1))
      .subscribe((isUserLoggedOnBehalf) => {
        if (
          isUserLoggedOnBehalf &&
          !(this.localeConfig?.externalLocaleId && this.localeConfig?.activeLocaleUpdateHook)
        ) {
          console.error(
            `Applet '@basuiz/web-app-self-service-locale-selector-applet' requires the locale settings defined via
the 'WebAppModule.forRoot()' options to have defined properties 'externalLocaleId' and 'activeLocaleUpdateHook'
when the current user is a bank-employee logged on behalf of the bank-client.`
          );
        }
      });

    this.getSelectorLocaleData$()
      .pipe(take(1))
      .subscribe((selectorLocaleData) => {
        this.updateComponentState({selectorLocaleData});
      });
  }

  onLocaleSelected(value: SelectorLocale) {
    this.updateComponentState({
      showError: false,
      selectedLocaleId: value.localeId,
    });
    if (this.componentState.changeUponSelection) {
      this.changeLocale();
    }
    this.selectedAndActiveLocalesDiffer.emit(value.localeId !== this.bszI18nService.localeId);
  }

  changeLocale(): Observable<void> {
    this.updateComponentState({savingLocale: true});
    const changeLocale$: Observable<void> = this.loginOnBehalfService
      .isUserLoggedOnBehalf$()
      .pipe(
        switchMap((isUserLoggedOnBehalf) =>
          (isUserLoggedOnBehalf ? of(undefined) : this.updatePreferredLanguageUserSetting()).pipe(
            switchMap(() => this.callActiveLocaleUpdateHook(!isUserLoggedOnBehalf))
          )
        )
      );
    return changeLocale$.pipe(
      tap(() => this.rebootHandler({event: 'localeUpdated'})),
      catchError((error) => {
        this.updateComponentState({
          savingLocale: false,
          showError: true,
        });
        return throwError(error);
      })
    );
  }

  private updatePreferredLanguageUserSetting(): Observable<void> {
    const preferredLanguage: LocaleId | null = this.componentState.selectedLocaleId ?? null;
    return from(this.userSettingsService.updateUserSettings({general: {preferredLanguage}}));
  }

  private callActiveLocaleUpdateHook(preferredLanguageUserSettingsUpdated: boolean): Observable<void> {
    return callActiveLocaleUpdateHook(
      this.componentState.selectedLocaleId,
      this.localeConfig,
      preferredLanguageUserSettingsUpdated
    );
  }

  private getSelectorLocaleData$(): Observable<LocaleSelectorData> {
    return this.supportedLocalesService.getSupportedLocales().pipe(
      map((supportedLocales) => {
        const optionList = this.supportedLocalesToSortedList(supportedLocales);
        let activeOption = optionList.find((option) => option.localeId === this.bszI18nService.localeId);
        if (!activeOption) {
          console.warn(
            `Currently active locale '${this.bszI18nService.localeId}' is not in the list of locales supplied by the backend.`
          );
          activeOption = {
            display: {
              order: -1,
              text: this.bszI18nService.localeId,
            },
            localeId: this.bszI18nService.localeId,
          };
          optionList.push(activeOption);
        }
        return {
          optionList,
          activeOption,
        };
      })
    );
  }

  private supportedLocalesToSortedList(supportedLocales: SupportedLocales): SelectorLocale[] {
    return Object.entries(supportedLocales)
      .map(([localeId, supportedLocale]) => ({
        localeId,
        display: supportedLocale.display,
      }))
      .sort((a, b) => a.display.order - b.display.order);
  }
}
