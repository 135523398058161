import {isNullOrUndefined} from './defined.util';

export function filterItemsWithValue<T>(original: readonly (T | null | undefined)[]): Array<T> {
  return original.filter((item) => !isNullOrUndefined(item)) as Array<T>;
}

export function arrayIntersection<T>(a: readonly T[], b: readonly T[]): T[] {
  return a.filter((value) => b.includes(value));
}

/* For non-literal values this method only checks reference equality, not the content of the values */
export function equalSets<T>(sourceA: readonly T[], sourceB: readonly T[]): boolean {
  const [a, b] = [[...new Set(sourceA)], [...new Set(sourceB)]]; // remove duplicate from original arrays
  if (a.length !== b.length) {
    return false;
  }
  return arrayIntersection(a, b).length === a.length;
}

export function flattenArray<T>(source: readonly (T | T[])[]): T[] {
  return source.reduce<T[]>((acc, next) => (Array.isArray(next) ? [...acc, ...next] : [...acc, next]), []);
}
