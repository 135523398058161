import {Relation} from 'typeorm';
import {IBaseUserOwnedEntity, IPaymentIntent, IUser} from '../..';

/**
 * In the enum PaymentMethodType, all wallet types must be formatted as "walletXxx", where "Xxx" is the exact key of a wallet.source (in snakeCase), as this format is check among the app to link a 'PaymentMethodWalletTypes' into a 'wallet.source'.
 */
export enum PaymentMethodType {
  /**
   * @param walletTokenized is a payment done using funds of a wallet.source=tokenized of the user
   * * This funds are sourced from the the app-user capital - own wallet.
   */
  walletTokenized = 'walletTokenized',

  /**
   * @param walletAvailable is a payment done using funds of a wallet.source=available of the user
   * * This funds are sourced from the the app-user capital - own wallet.
   */
  walletAvailable = 'walletAvailable',

  /**
   * @param walletGiftcard is a payment done using funds of a wallet.source=available of the user
   * This funds are sourced from the the app-user capital - own wallet.
   */
  walletGiftcard = 'walletGiftcard',

  /**
   * @param refundFunds is a payment done using funds which the company already captured or received the money for the purchase of a product, but later the user/client wants to cancel the purchase and request a refund of the same amount.
   * This funds are sourced from the app internal company's capital.
   */
  refundFunds = 'refundFunds',

  /**
   * @param marketingFunds is a payment done using funds of the company as 'marketing/promotional expenses'.
   * This funds are sourced from the app internal company's capital.
   */
  marketingFunds = 'marketingFunds',

  /**
   * @param collectionFunds is a payment that must be collected from a third-party. This is used when a user request the collection of funds from another person,
   * and the app must execute the collection of this money before it's credited to the user who requested the collection.
   * This funds are sourced from the app internal company's capital, ONLY after they have been collected into the company's balance sheet.
   */
  collectionFunds = 'collectionFunds',

  /**
   * @param cash is a payment done via a cash
   * This funds are sourced from an external company's capital.
   */
  cash = 'cash',

  /**
   * @param bankTransfer is a payment done via a bank transfer
   * This funds are sourced from an external company's capital.
   */
  bankTransfer = 'bankTransfer',

  /**
   * @param ecPayphone is a payment done via a card payment processor (EC) Payphone
   * This funds are sourced from an external company's capital.
   */
  ecPayphone = 'ecPayphone',
}

export const PaymentMethodWalletTypes = [
  PaymentMethodType.walletTokenized,
  PaymentMethodType.walletAvailable,
  PaymentMethodType.walletGiftcard,
];

export type PaymentMethodWalletType =
  | PaymentMethodType.walletTokenized
  | PaymentMethodType.walletAvailable
  | PaymentMethodType.walletGiftcard;

export interface IEcPayphoneOptions {
  charge10URL?: string;
  charge20URL?: string;
  charge50URL?: string;
  charge100URL?: string;
}

/**
 * `PaymentMethodOptions` serves to add extra options/data needed to fulfill a transaction of a PaymentMethod (bank ABC, card XYZ, etc)
 */
export interface IPaymentMethodOptions {
  ecPayphone?: IEcPayphoneOptions;
}

export interface IPaymentMethod extends IBaseUserOwnedEntity {
  ownerUser?: Relation<IUser>;
  type: PaymentMethodType;
  options?: IPaymentMethodOptions;

  /**
   * `userReference` should be used as an unique identifier, so we can easily identify a payment made from a user and link it to its account.
   * This reference should be re-used and highly suggested to be the same for ALL the different payment methods, but if a specific payment method needs to change this text (due to some constrains in that payment method) then we change it only for that payment method.
   * For example, in a bank transfer, the client must insert in the reference input in their bank app this value of `userReference`, so when we receive a payment and see this `userReference` in our own bank account we can credit it to the corresponding user.
   */
  userReference: string;

  paymentIntents?: Relation<IPaymentIntent[]>;
}
