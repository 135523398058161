<ng-container *ngIf="contextActions.length">
  <button
    [disabled]="disabled"
    mat-icon-button
    attr.aria-label="{{ 'web-app-common.context-actions.aria-label' | translate }}"
    [matMenuTriggerFor]="menu"
    bszTestId="web-app-common-menu-trigger"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu" xPosition="before">
    <bsz-context-action
      *ngFor="let action of contextActions"
      [contextAction]="action"
      bszTestId="web-app-common.context-action-menu.action-{{ action.id }}"
    >
    </bsz-context-action>
  </mat-menu>
</ng-container>
