// TODO: use proper locale from centralized place rather than this cont
// TASK: https://jira.tracking.sits.basuiz.net/browse/BUM-479

import {Locale} from '../definitions/index';
import {DateFormat, LocaleId, NumberSeparator} from '../enums/index';

export const DEFAULT_LOCALE: Locale = {
  localeID: <LocaleId.EN_US>'en_US',
  number: {
    decimalSeparator: NumberSeparator.DOT,
    groupSeparator: NumberSeparator.COMMA,
  },
  date: {
    defaultFormat: DateFormat.MEDIUM_DATE,
    formats: {
      shortDate: 'M/d/yyyy',
      mediumDate: 'MMM d, yyyy',
      longDate: 'MMMM d, yyyy',
      fullDate: 'EEEE, MMMM d, yyyy',
      shortDateTime: 'M/d/yyyy h:mm a',
      mediumDateTime: 'MMM d, yyyy h:mm:ss a',
      shortTime: 'h:mm a',
      mediumTime: 'h:mm:ss a',
    },
  },
};
