import {AddressType} from '@basuiz/shared/data-access';
import {NavigationIntent} from '../../navigation/classes/navigation-intent';

export interface ShowShipmentAddressOverviewIntentPayload {
  /** Tab to be immediately selected as the page is instantiated */
  tab?: AddressType;
}

export class ShowShipmentAddressOverviewIntent extends NavigationIntent {
  constructor(public payload: ShowShipmentAddressOverviewIntentPayload = {}) {
    super();
  }
}
