import {Injectable, Injector} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TranslationKey, BszUINotification, BszUnknownUINotification} from '@basuiz/web-app-applet-api';
import {Observable, ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private injector: Injector) {}

  private readonly notificationReplaySubject = new ReplaySubject<BszUINotification>();

  /* Returns the stream of UI-Notifications.
   * This stream will replay all notifications already published before the consumer subscribed to the stream. */
  getNotificationStream(): Observable<BszUINotification> {
    return this.notificationReplaySubject.asObservable();
  }

  /* Publishes an UI-notification meant to be displayed to the user by the UI-notification consumer
   * chosen by the application */
  notify(notification: BszUINotification): void {
    this.notificationReplaySubject.next(notification);
  }

  /**
   * Publishes a legacy notification containing the success message passed in the method argument.
   * @param message The message translation key to show in the snackbar.
   * @deprecated: use method 'publish' and consider extending the UINotification type
   */
  success(message: TranslationKey) {
    this.publishLegacyNotification(message, 'success');
  }

  /**
   * Publishes a legacy notification containing the error message passed in the method argument.
   * @param message The message translation key to show in the snackbar.
   * @deprecated: use method 'publish' and consider extending the UINotification type
   */
  error(message: TranslationKey) {
    this.publishLegacyNotification(message, 'error');
  }

  /* Publish a legacy notification, i.e. a UI-notification with unknown domain */
  private publishLegacyNotification(messageTranslationKey: string, severity: 'success' | 'error') {
    const message = this.injector.get(TranslateService).instant(messageTranslationKey) || '';
    const expected: BszUnknownUINotification = {
      domain: 'unknown',
      message,
      severity,
    };
    this.notificationReplaySubject.next(expected);
  }
}
