import {Provider} from '@angular/core';
import {selfServiceInvestmentProposalConfigProvider} from '@basuiz/web-app-self-service-investment-proposal/config';

export function getInvestmentProposalsConfigProvider(): Provider {
  const restMockerUrlPrefix = '/rest-mocker';
  return selfServiceInvestmentProposalConfigProvider({
    useFactory: (defaultConfig) => ({
      ...defaultConfig,
      proposalsOverviewUrl: restMockerUrlPrefix + defaultConfig.proposalsOverviewUrl,
      singleProposalBaseUrl: restMockerUrlPrefix + defaultConfig.singleProposalBaseUrl,
      afpaasAssetAllocationBaseUrl: restMockerUrlPrefix + defaultConfig.afpaasAssetAllocationBaseUrl,
    }),
  });
}
