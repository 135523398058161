export function propertyOf<T>(name: keyof T) {
  return name;
}

/**
 *
 * const developer: Staff = {
 *   name: 'Tobias',
 *   salary: 100,
 * };
 *
 * // Correct usage
 * const nameType = getProperty(developer, 'name'); // string
 *
 * // Compiler error
 * const salaryType getProperty(developer, ‘pay’); //Cannot find name 'pay'.(2304)
 *
 * @param obj
 * @param key
 * @returns
 */
export function getPropertyType<T, K extends keyof T>(obj: T, key: K): T[K] {
  return obj[key];
}
