import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {BszI18nModule} from '@basuiz/i18n';

import {BszProgressBar, BszProgressBarLabel} from './bsz-progress-bar';

const DECLARATIONS_AND_EXPORTS = [BszProgressBar, BszProgressBarLabel];

@NgModule({
  imports: [CommonModule, MatProgressBarModule, BszI18nModule],
  declarations: [DECLARATIONS_AND_EXPORTS],
  exports: [DECLARATIONS_AND_EXPORTS],
})
export class BszProgressBarModule {}
