import {Directive, ElementRef, Host, Input} from '@angular/core';
import {MatOption} from '@angular/material/core';
import {TranslateService} from '@ngx-translate/core';

import {BszDatepickerRangePeriod, BszDatepickerRangeService} from './bsz-datepicker-range.service';

@Directive({
  selector: 'mat-option[bszDatepickerRange]',
})
export class BszDatepickerRangeBase {
  @Input() set bszDatepickerRange(range: BszDatepickerRangePeriod | string) {
    this.defineOptionDatepickerRange(range as BszDatepickerRangePeriod);
  }

  datepickerRangeService = new BszDatepickerRangeService();

  // we cannot get common parts and have a function to generate the translation key because
  // the extraction tool would not find the key and would return an error
  private readonly translations: Record<BszDatepickerRangePeriod, string> = {
    last7Days: this.translate.instant('ui-elements.bsz-datepicker-range.period.last-7-days'),
    last14Days: this.translate.instant('ui-elements.bsz-datepicker-range.period.last-14-days'),
    last30Days: this.translate.instant('ui-elements.bsz-datepicker-range.period.last-30-days'),
    last180Days: this.translate.instant('ui-elements.bsz-datepicker-range.period.last-180-days'),
    last360Days: this.translate.instant('ui-elements.bsz-datepicker-range.period.last-360-days'),
    last720Days: this.translate.instant('ui-elements.bsz-datepicker-range.period.last-720-days'),
    next7Days: this.translate.instant('ui-elements.bsz-datepicker-range.period.next-7-days'),
    next14Days: this.translate.instant('ui-elements.bsz-datepicker-range.period.next-14-days'),
    next30Days: this.translate.instant('ui-elements.bsz-datepicker-range.period.next-30-days'),
    thisWeek: this.translate.instant('ui-elements.bsz-datepicker-range.period.this-week'),
    thisMonth: this.translate.instant('ui-elements.bsz-datepicker-range.period.this-month'),
    thisQuarter: this.translate.instant('ui-elements.bsz-datepicker-range.period.this-quarter'),
    thisHalfYear: this.translate.instant('ui-elements.bsz-datepicker-range.period.this-half-year'),
    thisYear: this.translate.instant('ui-elements.bsz-datepicker-range.period.this-year'),
    endOfWeek: this.translate.instant('ui-elements.bsz-datepicker-range.period.end-of-week'),
    endOfMonth: this.translate.instant('ui-elements.bsz-datepicker-range.period.end-of-month'),
    endOfQuarter: this.translate.instant('ui-elements.bsz-datepicker-range.period.end-of-quarter'),
    endOfHalfYear: this.translate.instant('ui-elements.bsz-datepicker-range.period.end-of-half-year'),
    endOfYear: this.translate.instant('ui-elements.bsz-datepicker-range.period.end-of-year'),
    nextWeek: this.translate.instant('ui-elements.bsz-datepicker-range.period.next-week'),
    nextMonth: this.translate.instant('ui-elements.bsz-datepicker-range.period.next-month'),
    nextQuarter: this.translate.instant('ui-elements.bsz-datepicker-range.period.next-quarter'),
    nextHalfYear: this.translate.instant('ui-elements.bsz-datepicker-range.period.next-half-year'),
    nextYear: this.translate.instant('ui-elements.bsz-datepicker-range.period.next-year'),
  };

  constructor(
    @Host() protected readonly option: MatOption,
    protected readonly elementRef: ElementRef,
    protected readonly translate: TranslateService
  ) {}

  private defineOptionDatepickerRange(datepickerRange: BszDatepickerRangePeriod) {
    this.option.value = this.datepickerRangeService.getDatepickerRange(datepickerRange);
    this.getOptionLabel(datepickerRange);
  }

  private getOptionLabel(period: BszDatepickerRangePeriod): void {
    const childNodes = this.elementRef.nativeElement.querySelector('.mat-option-text')?.childNodes;
    const viewValue = this.option.viewValue;
    if (!viewValue && childNodes?.length === 0) {
      this.elementRef.nativeElement.textContent = this.getTranslation(period);
    }
  }

  private getTranslation(period: BszDatepickerRangePeriod) {
    return this.translations[period];
  }
}
