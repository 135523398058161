import {InjectionToken, Provider} from '@angular/core';
import {PortalInitializationFailureConfig} from './portal-initialization-failure.config.definition';

export const PORTAL_INITIALIZATION_FAILURE_CONFIG = new InjectionToken<PortalInitializationFailureConfig>(
  'bsz.web-app.portal.initialization-failure-config',
  {
    factory: () => ({
      type: 'default',
      bankLogoFile: undefined,
      fallBackErrorMessage: {
        title: 'Sorry, there was an error and the page could not be loaded',
        info: 'Please reload the page or try again later',
      },
    }),
  }
);

export function getPortalInitializationFailureConfigProvider(config: PortalInitializationFailureConfig): Provider {
  /* On purpose, dependencies are not accepted in order to avoid configurations that might depend on
   * web-app initializations (e.g. user-roles) */
  return {
    provide: PORTAL_INITIALIZATION_FAILURE_CONFIG,
    useValue: config,
  };
}
