<a
  bszAccessibleLink
  [underline]="false"
  mat-list-item
  class="nav-item"
  [ngClass]="{'active': active, 'nav-child-item': childItem}"
  (click)="navigate()"
  bszTestId="{{ navItem.label }}.nav-item"
>
  <mat-icon *ngIf="!childItem" class="nav-icon" [svgIcon]="navItem.svgIcon || undefined">
    {{ navItem.svgIcon ? undefined : navItem.icon || defaultIcon }}
  </mat-icon>
  <span>{{ navItem.label | translate }}</span>
</a>
