import {Injectable, Injector} from '@angular/core';
import {CommonConfig, ɵCOMMON_CONFIG} from '@basuiz/web-app-common/config';
import {select, Store} from '@ngrx/store';
import {isEmpty} from 'lodash';
import {combineLatest, Observable} from 'rxjs';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {Currency, CurrencyDictionary} from './models/currency.definitions';
import {ISODate} from '../definitions/iso-date.definition';
import {UserSettingsService} from '../user-settings/index';
import {loadBankDate} from './+state/bank-date/bank-date.actions';
import * as BankDateSelectors from './+state/bank-date/bank-date.selectors';
import {loadCurrencies} from './+state/currency/currency.actions';
import * as CurrencySelectors from './+state/currency/currency.selectors';
import {loadCountries} from './+state/country/country.actions';
import * as CountrySelectors from './+state/country/country.selectors';
import {Country} from './models/country.definitions';
import {loadDefaultCurrency} from './+state/default-currency/default-currency.actions';
import * as DefaultCurrencySelectors from './+state/default-currency/default-currency.selectors';
import {loadHoliday} from './+state/holidays/holiday.actions';
import * as holidaySelectors from './+state/holidays/holiday.selectors';
import {WebAppCommonStateInterface} from './web-app-common-state.interface';
import {loadBusinessUnits} from './+state/business-unit/business-unit.actions';
import * as businessUnitSelects from './+state/business-unit/business-unit.selectors';
import {BusinessUnit} from './models/business-unit.definition';
import {filterDefined, isDefined} from '../utils/defined.util';
import {Dictionary} from '@ngrx/entity';

@Injectable({
  providedIn: 'root',
})
export class WebAppCommonFacade {
  readonly bankDateLoaded$: Observable<boolean> = this.store.pipe(select(BankDateSelectors.selectLoaded));
  readonly bankDateLoading$: Observable<boolean> = this.store.pipe(select(BankDateSelectors.selectLoading));
  readonly bankDate$: Observable<string> = this.store.pipe(select(BankDateSelectors.selectBankDate), filterDefined());

  readonly defaultCurrencyLoaded$: Observable<boolean> = this.store.pipe(select(DefaultCurrencySelectors.selectLoaded));
  readonly defaultCurrencyLoading$: Observable<boolean> = this.store.pipe(
    select(DefaultCurrencySelectors.selectLoading)
  );
  readonly defaultCurrency$: Observable<Currency> = this.store.pipe(
    select(DefaultCurrencySelectors.selectDefaultCurrency),
    filterDefined()
  );

  readonly holidayLoaded$: Observable<boolean> = this.store.pipe(select(holidaySelectors.selectLoaded));
  readonly holidayLoading$: Observable<boolean> = this.store.pipe(select(holidaySelectors.selectLoading));
  readonly holidayError$: Observable<string | null> = this.store.pipe(select(holidaySelectors.selectError));
  readonly holidayList$: Observable<ISODate[]> = this.store.pipe(
    select(holidaySelectors.selectHolidayList),
    filterDefined(),
    filter((holidays) => !!holidays.length)
  );

  readonly countryLoaded$: Observable<boolean> = this.store.pipe(select(CountrySelectors.selectLoaded));
  readonly countryLoading$: Observable<boolean> = this.store.pipe(select(CountrySelectors.selectLoading));
  readonly countryError$: Observable<boolean> = this.store.pipe(
    select(CountrySelectors.selectError),
    map((error) => !!error)
  );
  readonly countryList$: Observable<Country[]> = this.store.pipe(
    select(CountrySelectors.selectAll),
    filter((countryList) => !!countryList.length)
  );
  readonly countryListSorted$ = this.countryList$.pipe(
    map((country) => country.sort((a, b) => a.name.localeCompare(b.name)))
  );

  readonly businessUnitLoaded$: Observable<boolean> = this.store.pipe(select(businessUnitSelects.selectLoaded));
  readonly businessUnitLoading$: Observable<boolean> = this.store.pipe(select(businessUnitSelects.selectLoading));
  readonly businessUnitError$: Observable<boolean> = this.store.pipe(
    select(businessUnitSelects.selectError),
    map((error) => !!error)
  );
  readonly businessUnitList$: Observable<BusinessUnit[]> = this.store.pipe(
    select(businessUnitSelects.selectAll),
    filterDefined(),
    filter((businessUnits) => !!businessUnits.length)
  );
  readonly businessUnitEntities$: Observable<Dictionary<BusinessUnit>> = this.store.pipe(
    select(businessUnitSelects.selectEntities),
    filter((data) => !isEmpty(data))
  );

  readonly currencyLoaded$: Observable<boolean> = this.store.pipe(select(CurrencySelectors.selectLoaded));
  readonly currencyLoading$: Observable<boolean> = this.store.pipe(select(CurrencySelectors.selectLoading));
  readonly currencyError$: Observable<boolean> = this.store.pipe(
    select(CurrencySelectors.selectError),
    map((error) => !!error)
  );
  readonly currencyList$: Observable<ReadonlyArray<Currency>> = this.store.pipe(
    select(CurrencySelectors.selectDictionary),
    filterDefined(),
    map((currencyDictionary) => Object.values(currencyDictionary))
  );
  readonly currencyDictionary$: Observable<Readonly<CurrencyDictionary>> = this.store.pipe(
    select(CurrencySelectors.selectDictionary),
    filterDefined()
  );

  readonly currencyDictionaryByIsoCode$: Observable<Readonly<Record<string, Currency>>> = this.currencyList$.pipe(
    map((currencyList) =>
      currencyList.reduce((dictionary, currency) => ({...dictionary, [currency.isoCode]: currency}), {})
    )
  );

  /** List of filtered currencies based on main currencies configuration */
  readonly mainCurrencyList$ = this.getMainCurrencyList();

  /* Returns the default currency, this is, the default currency defined in the user's settings; or if the
   * setting is not defined, the business-unit (BU) currency, i.e. the base currency of the bank */
  readonly reportingCurrency$: Observable<Currency> = combineLatest([
    this.store.pipe(select(CurrencySelectors.selectDictionary), filterDefined()),
    this.store.pipe(select(DefaultCurrencySelectors.selectDefaultCurrency), filterDefined()),
  ]).pipe(
    map(([currencyDictionary, defaultCurrency]) => {
      const defaultCurrencyId =
        this.userSettingsService.userSettings.general.defaultReferenceCurrency || defaultCurrency?.id;
      return currencyDictionary[defaultCurrencyId];
    }),
    filterDefined(),
    distinctUntilChanged()
  );

  readonly reportingCurrencyLoaded$: Observable<boolean> = combineLatest([
    this.store.pipe(select(CurrencySelectors.selectLoaded)),
    this.store.pipe(select(DefaultCurrencySelectors.selectLoaded)),
  ]).pipe(map((loadedList) => loadedList.every((loaded) => !!loaded)));

  readonly reportingCurrencyLoading$: Observable<boolean> = combineLatest([
    this.store.pipe(select(CurrencySelectors.selectLoading)),
    this.store.pipe(select(DefaultCurrencySelectors.selectLoading)),
  ]).pipe(map((loadingList) => loadingList.some((loading) => !!loading)));

  readonly reportingCurrencyError$: Observable<boolean> = combineLatest([
    this.store.pipe(select(CurrencySelectors.selectError)).pipe(map((error) => !!error)),
    this.store.pipe(select(DefaultCurrencySelectors.selectError)),
  ]).pipe(map((errorList) => errorList.some((error) => !!error)));

  private getMainCurrencyList(): Observable<ReadonlyArray<Currency>> {
    const {mainIsoCodes} = this.commonConfig.currency;
    if (mainIsoCodes) {
      return this.currencyDictionaryByIsoCode$.pipe(
        map((currencyDictionaryByIsoCode) =>
          mainIsoCodes.map((isoCode): Currency | undefined => currencyDictionaryByIsoCode[isoCode]).filter(isDefined)
        )
      );
    }
    return this.currencyList$;
  }

  constructor(
    private store: Store<WebAppCommonStateInterface>,
    private userSettingsService: UserSettingsService,
    private injector: Injector
  ) {}

  private get commonConfig(): CommonConfig {
    return this.injector.get(ɵCOMMON_CONFIG);
  }

  public dispatchLoadOfBaseData() {
    this.store.dispatch(loadBankDate());
    this.store.dispatch(loadCountries());
    this.store.dispatch(loadCurrencies());
    this.store.dispatch(loadDefaultCurrency()); // todo rename default-currency to bu-currency
    this.store.dispatch(loadHoliday());
  }

  loadBusinessUnits() {
    this.store.dispatch(loadBusinessUnits());
  }
}
