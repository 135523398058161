import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BszI18nModule} from '@basuiz/i18n';

import {BszCurrency} from './bsz-currency';

@NgModule({
  declarations: [BszCurrency],
  imports: [CommonModule, BszI18nModule],
  exports: [BszCurrency],
})
export class BszCurrencyModule {}
