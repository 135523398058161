<div class="bsz-select-search-wrapper">
  <mat-icon class="bsz-select-search-icon">search</mat-icon>
  <input type="text" autocomplete="off" class="bsz-select-search-input"
         [attr.aria-label]="'ui-elements.bsz-select-search.accessibility.type-to-filter-options' | translate "
         [attr.aria-controls]="ariaControls"
         #searchInput
         (keydown)="handleKeydown($event)"
         (keyup)="handleKeyup($event)" />
</div>
<div *ngIf="noResults" class="bsz-empty-results-message">
  {{ 'ui-elements.bsz-select-search.no-results-message' | translate }}
</div>
