import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {Injectable, Injector} from '@angular/core';

import {BszSplashScreenConfig} from './bsz-splash-screen-config';
import {BszSplashScreenContainer} from './bsz-splash-screen-container';

@Injectable()
export class BszSplashScreen {
  /** Reference to the overlay created by the overlay service. */
  private overlayRef: OverlayRef | null = null;

  private readonly overlayConfig: OverlayConfig = {
    positionStrategy: this.overlay.position().global().top('0px').centerHorizontally(),
    scrollStrategy: this.overlay.scrollStrategies.noop(),
    panelClass: 'bsz-splash-screen',
    hasBackdrop: false,
  };

  containerInstance: BszSplashScreenContainer | null = null;

  constructor(private readonly overlay: Overlay, private readonly injector: Injector) {}

  open(config: BszSplashScreenConfig): void {
    this.createOverlay({...new BszSplashScreenConfig(), ...config});
  }

  dismiss(): void {
    this.overlayRef?.detach();
    this.containerInstance = null;
  }

  private createOverlay(config: BszSplashScreenConfig): void {
    if (this.containerInstance) {
      return;
    }

    this.overlayRef = this.overlay.create(this.overlayConfig);

    const injector = Injector.create({
      parent: this.injector,
      providers: [{provide: BszSplashScreenConfig, useValue: config}],
    });

    const overlayTemplatePortal = new ComponentPortal(BszSplashScreenContainer, null, injector);

    const splashScreenContainer = this.overlayRef.attach(overlayTemplatePortal);

    splashScreenContainer.instance._closeTransitionDone.subscribe(() => this.overlayRef?.dispose());

    this.containerInstance = splashScreenContainer.instance;
  }
}
