// IMPORTANT: do not modify this file

import {inject, InjectionToken, FactoryProvider} from '@angular/core';
import {BszConfigFactory, BszConfigFactoryWithDeps} from '@basuiz/web-app-applet-api';
import {CollaborationDetailPageConfig} from './collaboration-detail-page.config.definition';
import {getDefaultConfig} from './collaboration-detail-page.config.default';
import {validateCollaborationDetailPageConfig} from './collaboration-detail-page.config.validation';

// IMPORTANT: do not modify this file

export const ɵCOLLABORATION_DETAIL_PAGE_CONFIG = new InjectionToken<CollaborationDetailPageConfig>(
  'bsz.web-app.portal.collaboration-detail-page-config',
  {
    factory: () => inject(COLLABORATION_DETAIL_PAGE_CONFIG_DEFAULT),
  }
);

// IMPORTANT: do not modify this file

export const COLLABORATION_DETAIL_PAGE_CONFIG_DEFAULT = new InjectionToken<CollaborationDetailPageConfig>(
  'bsz.web-app.portal.collaboration-detail-page-config.default',
  {
    factory: () => {
      const config = getDefaultConfig();
      const error = validateCollaborationDetailPageConfig(config);
      if (error) {
        console.error('Default CollaborationDetailPageConfig validation failed:', error);
      }
      return config;
    },
  }
);

// IMPORTANT: do not modify this file

export function collaborationDetailPageConfigProvider({
  useFactory,
  deps,
}: BszConfigFactoryWithDeps<CollaborationDetailPageConfig>): FactoryProvider {
  return {
    provide: ɵCOLLABORATION_DETAIL_PAGE_CONFIG,
    useFactory: factoryWithValidation(useFactory),
    deps: [COLLABORATION_DETAIL_PAGE_CONFIG_DEFAULT, ...(deps || [])],
  };
}

// IMPORTANT: do not modify this file

function factoryWithValidation(
  factory: BszConfigFactory<CollaborationDetailPageConfig>
): BszConfigFactory<CollaborationDetailPageConfig> {
  return (defaultConfig: CollaborationDetailPageConfig, ...deps: unknown[]) => {
    const config = factory(defaultConfig, ...deps);
    const error = validateCollaborationDetailPageConfig(config);
    if (error) {
      console.error('CollaborationDetailPageConfig validation failed:', error);
    }
    return config;
  };
}

// IMPORTANT: do not modify this file
