/**
 * A utility class to help with manipulation of FileList objects.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/FileList
 */
export class FileListUtils {
  // Using a getter so the instantiation of the DataTransfer does not happen immediately
  // This allows for environments where DataTransfer is not available (Jest) to have
  // the option to mock it before it's invoked.
  static get EmptyFileList() {
    return new DataTransfer().files;
  }

  /**
   * Merges the elements of two or more file lists together so that the values of one are appended to the end of the previous one.
   * If the file lists have files with the same name and extension, then the later value for that file will overwrite the previous one.
   */
  static mergeFileLists(...fileLists: FileList[]): FileList {
    // DataTransfer creates FileList.
    const dataTransfer = new DataTransfer();

    // A collection to keep all the file names attached and check for duplicates.
    const allFileNames: string[] = [];

    // Add the items of each FileList object to the local DataTransfer object
    fileLists.forEach((fileList) => {
      for (let i = 0; i < fileList.length; i++) {
        dataTransfer.items.add(fileList[i]);
        allFileNames.push(fileList[i].name);
      }
    });

    // Remove the duplicates from the beginning (the ones attached in the currentFileList).
    // The new uploaded files will replace the ones that are already attached that have same name.
    allFileNames.forEach((currentFileName, index) => {
      const timesIncluded = allFileNames.filter((fileName) => fileName === currentFileName).length;

      if (timesIncluded > 1) {
        dataTransfer.items.remove(index);
      }
    });

    return dataTransfer.files;
  }

  /**
   * Removed a file by it's name in the given fileList. Returns a new fileList with the file removed
   * or an empty file list in case the given file list is null.
   */
  static deleteFile(fileList: FileList | null, fileToDelete: File): FileList {
    const dataTransfer = new DataTransfer();

    if (!fileList) {
      return dataTransfer.files;
    }

    for (let i = 0; i < fileList.length; i++) {
      dataTransfer.items.add(fileList[i]);
    }

    const indexToDelete = findIndexInFileList(fileList, fileToDelete.name);

    if (indexToDelete >= 0) {
      dataTransfer.items.remove(indexToDelete);
    }

    return dataTransfer.files;
  }

  /** Creates an array representation of the given FileList. */
  static fileListToArray(fileList: FileList | null): File[] {
    const list = [];
    if (fileList) {
      for (let i = 0; i < fileList.length; i++) {
        list.push(fileList[i]);
      }
    }
    return list;
  }
}

/** Finds the position of a File element inside a FileList by it's name. */
function findIndexInFileList(fileList: FileList, fileName: string): number {
  for (let i = 0; i < fileList.length; i++) {
    if (fileList[i].name === fileName) {
      return i;
    }
  }

  return -1;
}
