<div class="bsz-snackbar mat-elevation-z4 bsz-body-2"
     [ngClass]="{'bsz-snackbar--multiline': _isMultiLine}">
      <span class="bsz-snackbar-content">
        <span class="bsz-snackbar-message"><span class="bsz-snackbar-text">{{ message }}</span></span>
        <button *ngIf="action" mat-button color="accent" class="bsz-snackbar-action" (click)="onActionClick()">
          {{ action }}
        </button>
      </span>

  <button
    *ngIf="dismissable"
    mat-icon-button
    (click)="dismiss()"
    class="bsz-snackbar-dismiss"
    [attr.aria-label]="'ui-elements.bsz-snackbar.accessibility.dismiss' | translate"
  >
    <span class="material-icons" aria-hidden="true">close</span>
  </button>
</div>
