<ng-container
  *ngIf="{
    notificationsNew: (notificationsNew$ | async) || [],
    attentionItemCountersWithPositiveValue: (attentionItemCountersWithPositiveValue$ | async) || []
  } as data"
>
  <mat-icon
    bszTestId="web-app-alert-indicator-applet.icon"
    class="alert-indicator-icon"
    [ngClass]="{
      'alert-indicator-with-alerts':
        config.showAlertCounterBadges &&
        (data.notificationsNew?.length || data.attentionItemCountersWithPositiveValue.length)
    }"
    svgIcon="bszNotifications"
  ></mat-icon>
</ng-container>
