import {TranslatedText, TranslationKey} from '@basuiz/web-app-applet-api';
import {TranslateService} from '@ngx-translate/core';

export interface TranslationKeyWithParams {
  translationKey: TranslationKey;
  translationParams: Record<string, string | number>;
}

export function isTranslationKeyWithParams(
  translation: TranslationKey | TranslationKeyWithParams
): translation is TranslationKeyWithParams {
  return !!(translation instanceof Object && translation.translationParams);
}

export function translateKeyOptWithParams(
  toTranslate: TranslationKey | TranslationKeyWithParams,
  translateService: TranslateService
): TranslatedText {
  return isTranslationKeyWithParams(toTranslate)
    ? translateService.instant(toTranslate.translationKey, toTranslate.translationParams)
    : translateService.instant(toTranslate);
}
