/**
 * @returns the unique identifier for this user to be used as `userReference` in a payment menthod.
 *
 */
export function getUserReference(userObjectId: number | undefined): string | null {
  // TODO p2: this value must come from the backend

  if (!userObjectId) {
    return null;
  }

  let userReference = '';

  if (process.env['DB_ENV'] !== 'PROD') {
    // This is needed to add an identifier on CLOUD environments which are not PROD, this is for testing/debugging.
    // NPU = NON-PROD-USER
    userReference += 'NPU' + '-' + process.env['DB_ENV'] + '-';
  }

  userReference += 'E'; // TODO p2 This could be the first 2 letters of the user's residence country. Now it's 'E' which stands for 'Ecuador'
  const userObjectIdLength = userObjectId?.toString().length ?? 0;

  for (let index = userObjectIdLength; index < 6; index++) {
    userReference += '0';
  }

  userReference += userObjectId.toString();

  return userReference;
}
