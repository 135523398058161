import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidatorFn, Validators} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats} from '@angular/material/core';
import {DateRange} from '@angular/material/datepicker';
import {MAT_FORM_FIELD, MatFormField, MatFormFieldControl} from '@angular/material/form-field';
import {ANIMATION_MODULE_TYPE} from '@angular/platform-browser/animations';
import {AsyncSubject, Subject, take, takeUntil} from 'rxjs';

import {BszDateRangePicker} from './bsz-date-range-picker';
import {BszDateRange, BszDateRangeInputPlaceholder, isDateRange} from './bsz-datepicker.definitions';
import {BszDatepickerAbstractInput} from './bsz-datepicker-abstract-input';
import {BszDatePickerOverlay} from './bsz-datepicker-overlay';
import {BszDatepickerValidators} from './bsz-datepicker-validators';

const LOG_PREFIX = '[bsz-date-range-input]';

@Component({
  selector: 'bsz-date-range-input',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['bsz-date-range-input.scss'],
  templateUrl: 'bsz-date-range-input.html',
  host: {
    'role': 'group',
    'class': 'bsz-date-range-input',
    '(focusin)': '_onFocusIn()',
    '(focusout)': '_onFocusOut($event)',
    '[attr.aria-describedby]': '_ariaDescribedBy',
    '[attr.aria-labelledby]': '_formField?.getLabelId()',
    // Used by the test harness to tie this input to its calendar.
    '[attr.data-bsz-calendar]': '_datepicker ? _datepicker.uniqueId : null',
    '[class.bsz-date-range-input-disabled]': 'disabled',
    '[class.bsz-date-range-input-required]': '_required',
    '[class.bsz-date-range-animations-disabled]': '!animationsAreEnabled',
  },
  providers: [
    // Used to allow for registering the built in validators to form controls.
    {provide: NG_VALIDATORS, useExisting: BszDateRangeInput, multi: true},
    // Used to connect the component to the Angular forms API.
    {provide: NG_VALUE_ACCESSOR, useExisting: BszDateRangeInput, multi: true},
    // Used to connect the component to the MatFormField directive.
    {provide: MatFormFieldControl, useExisting: BszDateRangeInput},
  ],
})
export class BszDateRangeInput<D>
  extends BszDatepickerAbstractInput<D>
  implements OnInit, AfterViewInit, OnDestroy, MatFormFieldControl<BszDateRange<D>>
{
  private static nextId = 0;

  /** The element ID for this control. */
  @HostBinding() readonly id = `bsz-date-range-input-${BszDateRangeInput.nextId++}`;

  /** Whether the `MatFormField` label should try to float. */
  @HostBinding('class.bsz-date-range-input-label-floating')
  get shouldLabelFloat() {
    if (this._formField?.floatLabel === 'always') {
      return true;
    }
    return this.focused || !this.empty;
  }

  /** The datepicker that this input is associated with. */
  @Input()
  set bszDatepicker(datepicker: BszDateRangePicker<D>) {
    this._datepicker = datepicker;

    this.subscribeToDatepickerStreams(datepicker);
  }
  _datepicker: BszDateRangePicker<D> | null = null;

  /** Whether the control is required. */
  @Input()
  get required() {
    // `<mat-form-field>` uses this information to add a required
    // indicator to the label
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _required = false;

  /** The placeholder for this control. */
  @Input('placeholder')
  get rangePlaceholder() {
    // We do this workaround because the property "placeholder" is already
    // required by the MatFormField interface and doesn't let us add
    // a different type to it.
    return this._rangePlaceholder;
  }
  set rangePlaceholder(placeholder: BszDateRangeInputPlaceholder) {
    this._rangePlaceholder = placeholder;
    this.stateChanges.next();
  }
  private _rangePlaceholder: BszDateRangeInputPlaceholder = '';

  /** Allows a range to have only a start or an end date. */
  @Input() set allowPartialRange(allowPartial: BooleanInput) {
    this._allowPartialRange = coerceBooleanProperty(allowPartial);
  }
  private _allowPartialRange = false;

  @Input() set formControl(formControl: FormControl) {
    // Set the required property to true when there is a form control
    // with the required validator set within, like:
    // formControl = new FormControl('', [Validators.required]);
    this.required = formControl.hasValidator(Validators.required);
  }

  /** Value of aria-describedby that should be merged with the described-by ids which are set by the form-field. */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy: string | undefined;

  /** The input element for the START date. */
  @ViewChild('startDateInput', {static: true}) startDateInput!: ElementRef<HTMLInputElement>;

  /** The input element for the END date. */
  @ViewChild('endDateInput', {static: true}) endDateInput!: ElementRef<HTMLInputElement>;

  /** The element which mirrors the value of the START date input. */
  @ViewChild('startDateMirrorElement', {static: true}) startDateMirrorElement!: ElementRef<HTMLSpanElement>;

  /** The element which complements the label of the field with additional hint for the inner inputs. */
  @ViewChild('startDateLabelSuffix', {static: true}) startDateLabelSuffixElement!: ElementRef<HTMLSpanElement>;

  /** The element which complements the label of the field with additional hint for the inner inputs. */
  @ViewChild('endDateLabelSuffix', {static: true}) endDateLabelSuffixElement!: ElementRef<HTMLSpanElement>;

  // Required for the MatFormField.
  set value(dateRange: BszDateRange<D> | null) {
    this._value = dateRange;
    this.writeValue(dateRange);
  }
  get value() {
    return this._value;
  }
  private _value: BszDateRange<D> | null = null;

  // Required for the MatFormField.
  get placeholder(): string {
    if (!this._rangePlaceholder) {
      return '';
    }

    if (typeof this._rangePlaceholder === 'string') {
      return this._rangePlaceholder;
    }

    return `${this._rangePlaceholder.startDate} - ${this._rangePlaceholder.endDate}`;
  }

  // Required for the MatFormField.
  get empty(): boolean {
    const dateRangeForm = this._dateRangeForm.value;

    return !dateRangeForm.startDate && !dateRangeForm.endDate;
  }

  // Required for the MatFormField to determine whether the control is in an error state.
  get errorState(): boolean {
    return this.invalid;
  }

  // Required for the MatFormField.
  focused = false;

  /**
   * Value for the `aria-describedby` attribute of the inputs (host element).
   *
   * @private
   */
  _ariaDescribedBy: string | null = null;

  /**
   * Value for the start date input `aria-labelledby` attribute.
   *
   * @private
   */
  _ariaLabelledByStartDate: string | null = null;

  /**
   * Value for the end date input `aria-labelledby` attribute.
   *
   * @private
   */
  _ariaLabelledByEndDate: string | null = null;

  /**
   * An optional name for the control type that can be used to distinguish `mat-form-field` elements
   * based on their control type. The form field will add a class,
   * `mat-form-field-type-{{controlType}}` to its root element.
   */
  readonly controlType = 'bsz-date-range-input';

  // We use NG_VALUE_ACCESSOR to connect to the a formControl or ngModel so we just
  // define the property here to satisfy the MatFormFieldControl interface.
  readonly ngControl = null;

  /** @private */
  readonly _dateRangeForm = new FormGroup({
    startDate: new FormControl(''),
    endDate: new FormControl(''),
  });

  /**
   * Stream that emits whenever the state of the control changes such that the parent `MatFormField`
   * needs to run change detection.
   */
  readonly stateChanges = new Subject<void>();

  protected override validators: ValidatorFn | null = null;

  /** Stream that emits every time the start date input element is resized. */
  private readonly startDateInputResize = new Subject<void>();

  private readonly destroy = new AsyncSubject<void>();

  private animationsAreEnabled = false;

  private modelValue: BszDateRange<D> | null = null;

  private invalid = false;

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly datepickerValidators: BszDatepickerValidators<D>,
    override readonly _dateAdapter: DateAdapter<D>,
    protected override readonly elementRef: ElementRef<HTMLInputElement>,
    @Inject(MAT_DATE_FORMATS) protected override readonly dateFormats: MatDateFormats,
    @Optional() @Inject(MAT_FORM_FIELD) override readonly _formField: MatFormField | null,
    @Inject(ANIMATION_MODULE_TYPE) animationModuleType: 'NoopAnimations' | 'BrowserAnimations'
  ) {
    super(_dateAdapter, elementRef, dateFormats, _formField);
    this.stateChanges.pipe(takeUntil(this.destroy)).subscribe(() => cd.markForCheck());
    this.animationsAreEnabled = animationModuleType !== 'NoopAnimations';
  }

  ngOnInit(): void {
    if (!this._datepicker) {
      throw new Error(`${LOG_PREFIX} input cannot be initialized without an associative bsz-date-range-picker`);
    }

    this.initWith(this._datepicker);
    this.observeInvalidStatus();
    this.autoResizeStartDateInput();

    // Reset the width in order to prevent the first input to be collapsed when the
    // field is initially loaded and has no value or placeholder.
    this.startDateInputResize.pipe(take(1)).subscribe(() => this.resetStartDateInputWidthIfEmpty());
  }

  ngAfterViewInit(): void {
    this.setLabelledByIds();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.stateChanges.complete();
    this.startDateInputResize.complete();
  }

  /**
   * This method is called by the forms API.
   * Registers a callback function to call when the validator inputs change.
   */
  override registerOnValidatorChange(fn: () => void): void {
    this._validatorOnChange = fn;
  }

  /**
   * This method is called by the forms API to write to the view
   * when programmatic changes from model to view are requested
   *
   * @param value The new value for the element
   */
  writeValue(value: BszDateRange<D> | null): void {
    // Sanity check because formControl.setValue can send any value in.
    if (value !== null && !this.valueIsValid(value)) {
      throw new Error(`${LOG_PREFIX} "${JSON.stringify(value)}" is not a valid value for the date range input`);
    }

    this.updateView(value);
    this.updateModel(value);

    this._datepicker?.setSelectedDate(value);

    this.stateChanges.next();
  }

  /**
   * This method is called by the forms API.
   * Function that is called when the control status changes to or from 'DISABLED'.
   */
  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this._dateRangeForm.disable() : this._dateRangeForm.enable();

    if (this._datepicker) {
      this._datepicker.disabled = isDisabled;
    }

    this._disabled = isDisabled;
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]): void {
    this._ariaDescribedBy = ids.length ? ids.join(' ') : null;
  }

  onContainerClick(event: MouseEvent): void {
    if (this.disabled) {
      return;
    }

    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.startDateInput.nativeElement.focus();
    }
  }

  /** @private */
  _onFocusIn() {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  /** @private */
  _onFocusOut(event: FocusEvent) {
    const targetIsInsideElement = this.elementRef.nativeElement.contains(event.relatedTarget as Element);

    if (!targetIsInsideElement) {
      this.onTouched();
      this.resetStartDateInputWidthIfEmpty();
      this.focused = false;
      this.stateChanges.next();
    }
  }

  /**
   * Gets the element that the datepicker popup should be connected to.
   *
   * @return The element to connect the popup to.
   *
   * @private
   */
  override _getConnectedOverlayOrigin(): ElementRef {
    return this._formField ? this._formField.getConnectedOverlayOrigin() : this.elementRef;
  }

  /** @private */
  _onChange() {
    const parseDateRange = this.parseInputValue(this._dateRangeForm.getRawValue());

    /** @todo prevent invalid dates to pass into the view */
    this.writeValue(parseDateRange);
  }

  /** @test this */
  /** @private */
  _onEndDateKeyDown(event: KeyboardEvent) {
    // When a user deletes the text from the endDate and reaches the end then move the focus
    // to the startDateField
    if (event.key.toLowerCase() === 'backspace' && !this.endDateInput.nativeElement.value) {
      this.startDateInput.nativeElement.focus();
    }
  }

  /** @private */
  _getStartDateInputPlaceHolder(): string {
    // Don't show a placeholder when the label is not floating
    if (!this.shouldLabelFloat) {
      return '';
    }

    if (typeof this._rangePlaceholder === 'string') {
      return this._rangePlaceholder;
    }

    return this._rangePlaceholder?.startDate ?? '';
  }

  /** @private */
  _getEndDateInputPlaceHolder(): string {
    if (typeof this._rangePlaceholder === 'string' || !this.shouldLabelFloat) {
      return '';
    }

    return this._rangePlaceholder?.endDate ?? '';
  }

  /** @private */
  _getMirrorValue(): string {
    const startDateValue = this._dateRangeForm.value.startDate as string;

    let startDatePlaceholder = this._rangePlaceholder;
    if (typeof this._rangePlaceholder !== 'string') {
      startDatePlaceholder = this._rangePlaceholder?.startDate ?? '';
    }

    return startDateValue ? startDateValue : (startDatePlaceholder as string);
  }

  private setLabelledByIds() {
    const startDateIds = [];
    const endDateIds = [];

    // If form field has a label it should go first.
    const formFieldLabelId = this._formField?.getLabelId();
    if (formFieldLabelId) {
      startDateIds.push(formFieldLabelId);
      endDateIds.push(formFieldLabelId);
    }

    startDateIds.push(this.startDateLabelSuffixElement.nativeElement.id);
    endDateIds.push(this.endDateLabelSuffixElement.nativeElement.id);

    this._ariaLabelledByStartDate = startDateIds.join(' ');
    this._ariaLabelledByEndDate = endDateIds.join(' ');
    this.cd.detectChanges();
  }

  private valueIsValid(value: unknown): boolean {
    if (value === '') {
      return true;
    }

    if (!isDateRange(value)) {
      return false;
    }

    if (value.start && !this._dateAdapter.isDateInstance(value.start)) {
      return false;
    }

    if (value.end && !this._dateAdapter.isDateInstance(value.end)) {
      return false;
    }

    return true;
  }

  /** Attempt to convert the text from the inputs into date objects */
  private parseInputValue(value: {startDate: string | null; endDate: string | null}): BszDateRange<D> | null {
    const startDate = value.startDate ? this.stringToDateObject(value.startDate) : null;
    const endDate = value.endDate ? this.stringToDateObject(value.endDate) : null;

    if (!startDate && !endDate) {
      return null;
    }

    return new DateRange(startDate, endDate);
  }

  private subscribeToDatepickerStreams(datepicker: BszDatePickerOverlay<D>) {
    datepicker.onOpen.pipe(takeUntil(this.destroy)).subscribe(() => this.cd.markForCheck());
    datepicker.onClose.pipe(takeUntil(this.destroy)).subscribe(() => this.cd.markForCheck());

    datepicker.submit.pipe(takeUntil(this.destroy)).subscribe((value) => {
      this._datepicker?.setSelectedDate(value);
      this.writeValue(value as BszDateRange<D>);
      this.onTouched();
    });

    datepicker.cancel.pipe(takeUntil(this.destroy)).subscribe(() => {
      // Reset the selected value to the already applied value from the input if set.
      if (isDateRange(this.modelValue)) {
        this._datepicker?.setSelectedDate(this.modelValue);
      } else {
        this._datepicker?.setSelectedDate(null);
      }
    });
  }

  protected override updateView(value: BszDateRange<D> | null): void {
    if (value === null) {
      this._dateRangeForm.setValue({
        startDate: null,
        endDate: null,
      });
      return;
    }
    const newStartDate = value?.start ?? null;
    const newEndDate = value?.end ?? null;

    // Get the current values from the inputs
    let startDateFormatted = this._dateRangeForm.value.startDate ?? '';
    let endDateFormatted = this._dateRangeForm.value.endDate ?? '';

    // Attempt to format it to the applicable date format if the new start date is a valid date.
    // note: null is a valid value because it represents a non selected date. This can happen for example
    // When a user selects only one date in the calendar and the submits.
    if (newStartDate === null || this._dateAdapter.isValid(newStartDate)) {
      startDateFormatted = this.dateObjectToString(newStartDate);
    }

    // Attempt to format it to the applicable date format if the new end date is a valid date.
    if (newEndDate === null || this._dateAdapter.isValid(newEndDate)) {
      endDateFormatted = this.dateObjectToString(newEndDate);
    }

    this._dateRangeForm.setValue({
      startDate: startDateFormatted,
      endDate: endDateFormatted,
    });
  }

  protected override updateModel(value: BszDateRange<D> | null) {
    // Make sure that invalid dates will be "null" and not invalid date objects.
    const startDate = value?.start && this._dateAdapter.isValid(value.start) ? value.start : null;
    const endDate = value?.end && this._dateAdapter.isValid(value.end) ? value.end : null;

    const isEmpty = !startDate && !endDate;
    const newValue = isEmpty ? null : {start: startDate, end: endDate};

    this.onChange(newValue);
    this.modelValue = newValue;
    this.composeValidators();
  }

  protected override composeValidators() {
    this.validators = Validators.compose([
      this.datepickerValidators.minValidator(this.min),
      this.datepickerValidators.maxValidator(this.max),
      this.datepickerValidators.filterValidator(this.dateFilter),
      this.getRangeValidator(),
    ]);

    this._validatorOnChange();
  }

  private getRangeValidator(): ValidatorFn {
    if (this._allowPartialRange) {
      return this.datepickerValidators.partialRangeParseValidator(this._dateRangeForm.getRawValue());
    } else {
      return this.datepickerValidators.rangeParseValidator(this._dateRangeForm.getRawValue());
    }
  }

  /**
   * Using this workaround because injecting either NgControl or NgControlStatus results in circular dependency error.
   * We don't use the _formControl to get the status of the form because this doesn't work for the cases where ngModel
   * is used. This workaround works for both.
   */
  private observeInvalidStatus() {
    const updateInvalidProperty = (isInvalid: boolean) => {
      if (this.invalid !== isInvalid) {
        this.invalid = isInvalid;
        this.stateChanges.next();
      }
    };

    const observer = new MutationObserver((mutations) => {
      const target = mutations[0].target as HTMLInputElement;
      const isInvalid = target.classList.contains('ng-invalid') && target.classList.contains('ng-touched');
      updateInvalidProperty(isInvalid);
    });

    observer.observe(this.elementRef.nativeElement, {
      subtree: false,
      childList: false,
      attributes: true,
      attributeFilter: ['class'],
    });

    this.destroy.pipe(take(1)).subscribe(() => observer.disconnect());
  }

  /**
   * We need the input to stick and push against the separator but the problem is
   * that inputs cannot auto resize like other html elements. We work around this
   * by having a hidden span element which mirrors the input value and we track
   * its width while we insert text inside the input field.
   */
  private autoResizeStartDateInput() {
    const getMirrorElementDomRectWidth = () => this.startDateMirrorElement.nativeElement.getBoundingClientRect().width;
    const updateStartDateInputWidth = (width: number) => {
      this.startDateInput.nativeElement.style.width = `${width}px`;
      this.startDateInputResize.next();
    };

    const observer = new MutationObserver(() => updateStartDateInputWidth(getMirrorElementDomRectWidth()));

    observer.observe(this.startDateMirrorElement.nativeElement.parentElement!, {
      subtree: true,
      characterData: true,
      attributes: true,
      attributeFilter: ['class'],
    });

    this.destroy.pipe(take(1)).subscribe(() => observer.disconnect());
  }

  private resetStartDateInputWidthIfEmpty() {
    if (this._dateRangeForm.value.startDate || this._rangePlaceholder) {
      return;
    }

    this.startDateInput.nativeElement.style.width = '';
  }
}
