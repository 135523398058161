// IMPORTANT: do not modify this file

import {inject, InjectionToken, FactoryProvider} from '@angular/core';
import {BszConfigFactory, BszConfigFactoryWithDeps} from '@basuiz/web-app-applet-api';
import {SelfServiceCollaborationClientAdvisorAppletConfig} from './self-service-collaboration-client-advisor-applet.config.definition';
import {getDefaultConfig} from './self-service-collaboration-client-advisor-applet.config.default';
import {validateSelfServiceCollaborationClientAdvisorAppletConfig} from './self-service-collaboration-client-advisor-applet.config.validation';

// IMPORTANT: do not modify this file

export const ɵSELF_SERVICE_COLLABORATION_CLIENT_ADVISOR_BANKLET_CONFIG =
  new InjectionToken<SelfServiceCollaborationClientAdvisorAppletConfig>(
    'bsz.web-app.self-service-collaboration-client-advisor-applet-config',
    {
      factory: () => inject(SELF_SERVICE_COLLABORATION_CLIENT_ADVISOR_BANKLET_CONFIG_DEFAULT),
    }
  );

// IMPORTANT: do not modify this file

export const SELF_SERVICE_COLLABORATION_CLIENT_ADVISOR_BANKLET_CONFIG_DEFAULT =
  new InjectionToken<SelfServiceCollaborationClientAdvisorAppletConfig>(
    'bsz.web-app.self-service-collaboration-client-advisor-applet-config.default',
    {
      factory: () => {
        const config = getDefaultConfig();
        const error = validateSelfServiceCollaborationClientAdvisorAppletConfig(config);
        if (error) {
          console.error('Default SelfServiceCollaborationClientAdvisorAppletConfig validation failed:', error);
        }
        return config;
      },
    }
  );

// IMPORTANT: do not modify this file

export function selfServiceCollaborationClientAdvisorAppletConfigProvider({
  useFactory,
  deps,
}: BszConfigFactoryWithDeps<SelfServiceCollaborationClientAdvisorAppletConfig>): FactoryProvider {
  return {
    provide: ɵSELF_SERVICE_COLLABORATION_CLIENT_ADVISOR_BANKLET_CONFIG,
    useFactory: factoryWithValidation(useFactory),
    deps: [SELF_SERVICE_COLLABORATION_CLIENT_ADVISOR_BANKLET_CONFIG_DEFAULT, ...(deps || [])],
  };
}

// IMPORTANT: do not modify this file

function factoryWithValidation(
  factory: BszConfigFactory<SelfServiceCollaborationClientAdvisorAppletConfig>
): BszConfigFactory<SelfServiceCollaborationClientAdvisorAppletConfig> {
  return (defaultConfig: SelfServiceCollaborationClientAdvisorAppletConfig, ...deps: unknown[]) => {
    const config = factory(defaultConfig, ...deps);
    const error = validateSelfServiceCollaborationClientAdvisorAppletConfig(config);
    if (error) {
      console.error('SelfServiceCollaborationClientAdvisorAppletConfig validation failed:', error);
    }
    return config;
  };
}

// IMPORTANT: do not modify this file
