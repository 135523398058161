import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpResponse} from '@angular/common/http';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {NotificationService} from '../../ui-notification/index';
import {AfpAuthObject, AfpAuthResult} from '../models/auth.definitions';
import {TranslationKey} from '@basuiz/web-app-applet-api';
import {Observable, throwError} from 'rxjs';

type ErrorStatus = 'FAILED' | 'CANCELLED' | 'NOT_PERMITTED';

@Injectable()
export class AuthErrorService {
  constructor(private notificationService: NotificationService) {}

  public handleUnsuccessfulAuth(
    response: HttpResponse<any>,
    status: ErrorStatus,
    transactionSigningObject?: AfpAuthObject
  ): Observable<HttpEvent<HttpErrorResponse>> {
    const authResult: AfpAuthResult = {
      status,
      transactionSigningObject,
    };
    const error = {
      ...response.body,
      payload: {
        ...response.body.payload,
        authResult,
      },
    };

    if (AuthErrorService.NOTIFICATION[status]) {
      this.notificationService.error(AuthErrorService.NOTIFICATION[status]);
    }

    return throwError(
      new HttpErrorResponse({
        error,
        headers: response.headers,
        status: AuthErrorService.HTTP_STATUS[status],
        statusText: AuthErrorService.HTTP_STATUS_TEXT[status],
        url: response.url || undefined,
      })
    );
  }

  private static HTTP_STATUS: Record<ErrorStatus, number> = {
    FAILED: 401,
    CANCELLED: 403,
    NOT_PERMITTED: 409,
  };

  private static HTTP_STATUS_TEXT: Record<ErrorStatus, string> = {
    FAILED: '2FA failed',
    CANCELLED: '2FA cancelled',
    NOT_PERMITTED: '2FA is required but is not currently permitted',
  };

  private static NOTIFICATION: Record<ErrorStatus, TranslationKey> = {
    FAILED: asTranslationKey('web-app-common.auth.auth-status.notification.failed'),
    CANCELLED: asTranslationKey('web-app-common.auth.auth-status.notification.cancelled'),
    NOT_PERMITTED: '',
  };
}
