import {Role} from '../user/roles/role.model';
import {UserWithoutPassword} from '../user/user.model';

const defaultActiveUserOptions = {
  isActive: true,
  isFirstSetup: true,
  emailVerifyToken: '00000000-0000-0000-0000-000000000000',
  isEmailVerified: true,
};

export const demoUser1: Omit<UserWithoutPassword, 'email'> = {
  firstname: 'John',
  lastname: 'Smith',
  phone: '1234567890',
  roles: [Role.user],
  ...defaultActiveUserOptions,
};

export const demoUser2: Omit<UserWithoutPassword, 'email'> = {
  firstname: 'Juan',
  lastname: 'Perez',
  phone: '1234567890',
  roles: [Role.user],
  ...defaultActiveUserOptions,
};

export const demoUser3: Omit<UserWithoutPassword, 'email'> = {
  firstname: 'Denise',
  lastname: 'Cuchara',
  phone: '1234567890',
  roles: [Role.user],
  ...defaultActiveUserOptions,
};

/**
 * All users inside demoPublicUsers will NOT be used in any service
 * that sends an email to this user's email. This is the case even for testing
 * purpose, so be aware that an email dispatch WILL be SKIPPED to the emails in this list.
 */
export const demoPublicUsers: UserWithoutPassword[] = [
  {
    email: 'demo.user.a.1@mail.com', // all demo accounts ending in '.1@mail.com' should have some transactions in the app, this is to simulate an active user
    ...demoUser1,
  },
  {
    email: 'demo.user.a.2@mail.com', // all demo accounts ending in '.2@mail.com' should NOT have any transactions in the app, this is to simulate an empty new user
    ...demoUser2,
  },
  {
    email: 'demo.user.a.3@mail.com', // all demo accounts ending in '.3@mail.com' should have some transactions in the app, this is to simulate an active user
    ...demoUser3,
  },
  {
    email: 'demo.user.b.1@mail.com',
    ...demoUser1,
  },
  {
    email: 'demo.user.b.2@mail.com',
    ...demoUser2,
  },
  {
    email: 'demo.user.b.3@mail.com',
    ...demoUser3,
  },
  {
    email: 'demo.user.c.1@mail.com',
    ...demoUser1,
  },
  {
    email: 'demo.user.c.2@mail.com',
    ...demoUser2,
  },
  {
    email: 'demo.user.c.3@mail.com',
    ...demoUser3,
  },
  {
    email: 'demo.user.d.1@mail.com',
    ...demoUser1,
  },
  {
    email: 'demo.user.d.2@mail.com',
    ...demoUser2,
  },
  {
    email: 'demo.user.d.3@mail.com',
    ...demoUser3,
  },
  {
    email: 'demo.user.e.1@mail.com',
    ...demoUser1,
  },
  {
    email: 'demo.user.e.2@mail.com',
    ...demoUser2,
  },
  {
    email: 'demo.user.e.3@mail.com',
    ...demoUser3,
  },
  {
    email: 'demo.user.f.1@mail.com',
    ...demoUser1,
  },
  {
    email: 'demo.user.f.2@mail.com',
    ...demoUser2,
  },
  {
    email: 'demo.user.f.3@mail.com',
    ...demoUser3,
  },
  {
    email: 'demo.user.g.1@mail.com',
    ...demoUser1,
  },
  {
    email: 'demo.user.g.2@mail.com',
    ...demoUser2,
  },
  {
    email: 'demo.user.g.3@mail.com',
    ...demoUser3,
  },
  {
    email: 'demo.user.h.1@mail.com',
    ...demoUser1,
  },
  {
    email: 'demo.user.h.2@mail.com',
    ...demoUser2,
  },
  {
    email: 'demo.user.h.3@mail.com',
    ...demoUser3,
  },
  {
    email: 'demo.user.i.1@mail.com',
    ...demoUser1,
  },
  {
    email: 'demo.user.i.2@mail.com',
    ...demoUser2,
  },
  {
    email: 'demo.user.i.3@mail.com',
    ...demoUser3,
  },
  {
    email: 'demo.user.j.1@mail.com',
    ...demoUser1,
  },
  {
    email: 'demo.user.j.2@mail.com',
    ...demoUser2,
  },
  {
    email: 'demo.user.j.3@mail.com',
    ...demoUser3,
  },
];
