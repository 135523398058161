import {UserSettingsPageConfig} from './user-settings-page.config.definition';
import {ExternalLinkService, UserRoleService} from '@basuiz/web-app-applet-sdk';
import {ExpandedFeatureToggle, isEnabled} from '@basuiz/web-app-feature-toggle';

// IMPORTANT: do not modify the signature of this function
export function getDefaultConfig(
  externalLinkService: ExternalLinkService,
  userRoleService: UserRoleService,
  featureToggle: ExpandedFeatureToggle
): UserSettingsPageConfig {
  return {
    showUserProfileContactDetails: isEnabled(featureToggle.userProfile?.contactDetails),
    showUserProfilePostalAddress: isEnabled(featureToggle.userProfile?.postalAddress),
    postalAddressDisplayFormatter: ({streetName, streetNumber, postalCode, city, country}) => [
      ...joinPostalAddressTokens(streetName, streetNumber),
      ...joinPostalAddressTokens(postalCode, city),
      ...joinPostalAddressTokens(country?.name),
    ],
    psd2DataSharingConsentLink: undefined,
  };
}

function joinPostalAddressTokens(...tokens: (string | undefined)[]): string[] {
  const tokensWithValue = tokens.filter((token) => !!token);
  return tokensWithValue.length ? [tokensWithValue.join(' ')] : [];
}
