import {FactoryProvider, inject, InjectionToken} from '@angular/core';
import {BszConfigFactoryWithDeps} from '@basuiz/web-app-applet-api';
import {AuthNativeConfig} from './auth.native-config.definition';
import {getDefaultConfig} from './auth.native-config.default';

export const ɵAUTH_NATIVE_CONFIG = new InjectionToken<AuthNativeConfig>('bsz.web-app.auth-native-config', {
  factory: () => inject(AUTH_NATIVE_CONFIG_DEFAULT),
});

export const AUTH_NATIVE_CONFIG_DEFAULT = new InjectionToken<AuthNativeConfig>(
  'bsz.web-app.auth-native-config.default',
  {
    factory: getDefaultConfig,
  }
);

export function authNativeConfigProvider({
  useFactory,
  deps,
}: BszConfigFactoryWithDeps<AuthNativeConfig>): FactoryProvider {
  return {
    provide: ɵAUTH_NATIVE_CONFIG,
    useFactory,
    deps: [AUTH_NATIVE_CONFIG_DEFAULT, ...(deps || [])],
  };
}
