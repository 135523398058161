import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {BszI18nModule} from '@basuiz/i18n';

import {BszHeaderToggle} from './bsz-header-toggle';
import {BszTableColumnToggle} from './bsz-table-column-toggle';

@NgModule({
  declarations: [BszTableColumnToggle, BszHeaderToggle],
  imports: [CommonModule, MatButtonModule, MatMenuModule, MatListModule, BszI18nModule],
  exports: [BszTableColumnToggle, BszHeaderToggle],
})
export class BszTableColumnToggleModule {}
