import {AfpRestResponse, AfpRestResponseRaw} from '../models/afp-rest-response.definitions';

export function isAfpRestResponseRaw(responseBody: any): responseBody is AfpRestResponseRaw {
  return (
    !!responseBody &&
    !!(responseBody as AfpRestResponseRaw)._type &&
    !!(responseBody as AfpRestResponseRaw).dependencies
  );
}

export function isAfpRestResponse(responseBody: unknown): responseBody is AfpRestResponse<any> {
  const responseBodyAsRaw = responseBody as AfpRestResponseRaw;
  return responseBodyAsRaw.payload !== undefined && Array.isArray(responseBodyAsRaw.notifications);
}
