import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {AfpRestResponse} from '../../afp-rest/index';
import {AfpAuthObject, AfpAuthRequest, AfpRestResponseWithAuthRequest} from '../models/auth.definitions';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {AuthDialogService} from './auth-dialog.service';
import {AuthErrorService} from './auth-error.service';
import {AuthGuardService} from './auth-guard.service';
import {assertNever} from '../../utils/assert-never';

@Injectable()
export class AuthService {
  constructor(private injector: Injector) {
    // use the Injector to avoid issues when services make http requests during app initialization
  }

  private get guardService(): AuthGuardService {
    return this.injector.get(AuthGuardService);
  }

  private get dialogService(): AuthDialogService {
    return this.injector.get(AuthDialogService);
  }

  private get errorService(): AuthErrorService {
    return this.injector.get(AuthErrorService);
  }

  public authIfRequired(
    response: HttpResponse<any>,
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.guardService.isAuthRequired(response)) {
      return this.authResponse(response, req, next);
    } else if (this.guardService.isAuthNotPermitted(response)) {
      return this.errorService.handleUnsuccessfulAuth(response, 'NOT_PERMITTED');
    }

    return of(response);
  }

  private authResponse(
    response: HttpResponse<AfpRestResponse<AfpRestResponseWithAuthRequest>> & {
      body: AfpRestResponse<AfpRestResponseWithAuthRequest>;
    },
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const [authRequest, rawResponse] = AuthService.extractAuthRequest(response);

    return this.dialogService.open(authRequest).pipe(
      switchMap(([result, transactionSigningObject]) => {
        switch (result) {
          case 'SUCCESS':
            return this.handleSuccessfulAuth(req, next, transactionSigningObject);
          case 'FAILURE':
            return this.errorService.handleUnsuccessfulAuth(rawResponse, 'FAILED', transactionSigningObject);
          case 'CANCEL':
            return this.errorService.handleUnsuccessfulAuth(rawResponse, 'CANCELLED', transactionSigningObject);
          default:
            assertNever(result);
        }
      })
    );
  }

  private handleSuccessfulAuth(
    req: HttpRequest<any>,
    next: HttpHandler,
    transactionSigningObject: AfpAuthObject
  ): Observable<HttpEvent<any>> {
    const replayRequest = req.clone({body: {...req.body, transactionSigningObject}});

    return next.handle(replayRequest).pipe(
      map((event: HttpEvent<any>) =>
        event instanceof HttpResponse
          ? event.clone({
              body: {
                ...event.body,
                payload: {...event.body.payload, authResult: {status: 'SIGNED'}},
              },
            })
          : event
      )
    );
  }

  private static extractAuthRequest(
    response: HttpResponse<AfpRestResponse<AfpRestResponseWithAuthRequest>> & {
      body: AfpRestResponse<AfpRestResponseWithAuthRequest>;
    }
  ): [AfpAuthRequest, HttpResponse<AfpRestResponse<unknown>>] {
    const {
      payload: {transactionSigningRequest, ...payload},
      ...body
    } = response.body;
    const rawResponse = response.clone({body: {...body, payload}});

    return [transactionSigningRequest, rawResponse];
  }
}
