import {InjectionToken} from '@angular/core';
import {BszFactoryProviderGenerator} from '@basuiz/web-app-applet-api';

export const ɵPAGE_SIZE_FOR_DASHBOARD = new InjectionToken<number>('bsz.web-app-common.page-sizes.dashboard', {
  factory: () => 10,
});

/**
 * Factory provider for a convenient overwrite of the page size for applets displaying a summary list.
 * E.g.: Portfolio list summary, Securities maturing list
 * Can be provided multiple times in the root provider scope and in nested provider scopes if different applets
 * should use different page sizes.
 * */
export const providePageSizeForDashboard: BszFactoryProviderGenerator<number> = (factory, deps) => ({
  provide: ɵPAGE_SIZE_FOR_DASHBOARD,
  useFactory: factory,
  deps,
});
