import {Inject, Injectable, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {BszMobileBridgeService, View} from '@basuiz/mobile-bridge';
import {NavigationIntent, NavigationService} from '@basuiz/web-app-portal';
import {ɵPORTAL_HYBRID_CONFIG} from '../config/portal-hybrid.config.provider';
import {HybridViewHandlerMap, PortalHybridConfig} from '../config/portal-hybrid.config.definition';
import {getDefaultViewHandlers} from '../config/get-default-view-handlers';

@Injectable({
  providedIn: 'root',
})
export class HybridNavigationService implements OnDestroy {
  constructor(
    private mobileBridgeService: BszMobileBridgeService,
    private navigationService: NavigationService,
    @Inject(ɵPORTAL_HYBRID_CONFIG) portalHybridConfig: PortalHybridConfig
  ) {
    this.viewHandlers = {
      ...getDefaultViewHandlers(),
      ...portalHybridConfig.hybridViewHandlers,
    };
  }

  appIsReady(): void {
    if (this.mobileBridgeService.isInsideNative()) {
      this.initializeNavigation();
      this.mobileBridgeService.appIsReady();
    }
  }

  private subscription = new Subscription();
  private viewHandlers: HybridViewHandlerMap;

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private initializeNavigation() {
    this.subscription.add(
      this.mobileBridgeService.instantiateView.subscribe(({view, viewReadyCallback}) => {
        const intent = this.handleView(view);

        if (intent) {
          this.navigationService.navigate(intent, {resetHistoryNavigation: true}).subscribe({
            next: () => viewReadyCallback(),
            error: () => viewReadyCallback(),
          });
        }
      })
    );
  }

  private handleView(view: View): NavigationIntent | undefined {
    const viewHandler = this.viewHandlers[view.name];
    if (!viewHandler) {
      console.error(`Could not map the hybrid-view '${view.name}' to a NavigationIntent`);
      return undefined;
    }

    const intent = viewHandler(view);
    if (!intent) {
      console.error(`The handler for the hybrid-view '${view.name}' did not return a NavigationIntent`);
      return undefined;
    }

    return intent;
  }
}
