import {Moment} from 'moment';

export interface NotificationDto {
  id: number;
  content: string;
  category: NotificationCategory;
  dateOfGeneration: string | Moment;
  status: NotificationMessageStatus;
}

export interface Notification extends NotificationDto {
  selected?: boolean;
}

export interface NotificationCategory {
  id: number;
  name: string;
}

export enum NotificationMessageStatus {
  /** Notification message has never been retrieved so far. */
  NEW = 'NEW',
  /** Notification message has been retrieved in the past but has not been read. */
  UNREAD = 'UNREAD',
  /** Notification message has been retrieved and read in the past. */
  READ = 'READ',
  /** Notification message is being delivered. */
  DELIVERING = 'DELIVERING',
  /** Notification message has been delivered. */
  DELIVERED = 'DELIVERED',
  /** Notification message has been deleted. */
  DELETED = 'DELETED',
  /** There was an error delivering the notification message. */
  ERROR = 'ERROR',
}
