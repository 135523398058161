import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WebAppChartComponent} from './web-app-chart.component';
import {BszTableModule} from '@basuiz/ui-elements';
import {TranslateModule} from '@ngx-translate/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FormattingModule} from '../formatting/index';
import {BszProgressBarModule} from '../progress-indicator/bsz-progress-bar.module';
import {TimeSeriesChartBuilder} from './chart/time-series.chart';
import {PieChartBuilder} from './chart/pie.chart';
import {MatIconModule} from '@angular/material/icon';
import {TimeSeriesStackedChartBuilder} from './chart/time-series-stacked.chart';

@NgModule({
  imports: [
    CommonModule,
    BszTableModule,
    TranslateModule,
    FormattingModule,
    MatIconModule,
    MatTooltipModule,
    BszProgressBarModule,
  ],
  providers: [TimeSeriesChartBuilder, TimeSeriesStackedChartBuilder, PieChartBuilder],
  declarations: [WebAppChartComponent],
  exports: [WebAppChartComponent],
})
export class WebAppChartModule {}
