<button mat-icon-button color="accent" (click)="openDevTools()" matTooltip="Portal debug">
  <mat-icon>my_location</mat-icon>
</button>
<button mat-icon-button color="accent" (click)="toggleTheme()">
  <mat-icon matTooltip="Light theme active, click to switch to dark mode." *ngIf="activeTheme === 'light'"
    >brightness_2</mat-icon
  >
  <mat-icon matTooltip="Dark theme active, click to switch to light mode." *ngIf="activeTheme === 'dark'"
    >wb_sunny</mat-icon
  >
</button>
