import {TranslatedText, TranslationKey} from '@basuiz/web-app-applet-api';

export type ContextAction = ContextActionWithTranslationKey | ContextActionWithTranslatedLabel;

// Original type, to be replaced with ContextActionWithTranslatedLabel
export interface ContextActionWithTranslationKey {
  id: string;
  callback: (event: MouseEvent) => void;
  translationKey: TranslationKey;
  isAvailable?: boolean;
}

// Type consumed by context-action.component
export interface ContextActionWithTranslatedLabel {
  id: string;
  callback: (event: MouseEvent) => void;
  label: TranslatedText;
  isAvailable?: boolean;
}

export function isContextActionWithTranslationKey(value: ContextAction): value is ContextActionWithTranslationKey {
  return !!(value as ContextActionWithTranslationKey).translationKey;
}
export function isContextActionWithTranslatedLabel(value: ContextAction): value is ContextActionWithTranslatedLabel {
  return !!(value as ContextActionWithTranslatedLabel).label;
}
