import {ChangeDetectionStrategy, Component, HostBinding} from '@angular/core';

@Component({
  selector: 'bsz-breadcrumb-separator',
  template: `<mat-icon class="bsz-text-secondary">chevron_right</mat-icon>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbSeparatorComponent {
  @HostBinding('class')
  hostCssClass = 'bsz-display-flex';
}
