import {EcBaseTerritoryItem} from './ec-base-territory.model';

export const EcBaseTerritoryList: EcBaseTerritoryItem[] = [
  {
    territoryBaseId: 'EC|:|AZUAY|:|CAMILO_PONCE_ENRIQUEZ|:|CAMILO_PONCE_ENRIQUEZ',
    territory1Name: 'AZUAY',
    territory2Name: 'CAMILO PONCE ENRIQUEZ',
    territory3Name: 'CAMILO PONCE ENRIQUEZ',
    searchableText: 'Azuay - Camilo Ponce Enriquez - Camilo Ponce Enriquez',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CHORDELEG|:|CHORDELEG',
    territory1Name: 'AZUAY',
    territory2Name: 'CHORDELEG',
    territory3Name: 'CHORDELEG',
    searchableText: 'Azuay - Chordeleg - Chordeleg',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CHORDELEG|:|LA_UNION_(AZ)',
    territory1Name: 'AZUAY',
    territory2Name: 'CHORDELEG',
    territory3Name: 'LA UNION (AZ)',
    searchableText: 'Azuay - Chordeleg - La Union (AZ)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|BAÑOS_(AZ)',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'BAÑOS (AZ)',
    searchableText: 'Azuay - Cuenca - Baños (AZ)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|BELLAVISTA_(AZ)',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'BELLAVISTA (AZ)',
    searchableText: 'Azuay - Cuenca - Bellavista (AZ)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|CAÑARIBAMBA',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'CAÑARIBAMBA',
    searchableText: 'Azuay - Cuenca - Cañaribamba',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|CAPULISPAMBA',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'CAPULISPAMBA',
    searchableText: 'Azuay - Cuenca - Capulispamba',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|CUENCA',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'CUENCA',
    searchableText: 'Azuay - Cuenca - Cuenca',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|CUMBE',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'CUMBE',
    searchableText: 'Azuay - Cuenca - Cumbe',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|EL_BATAN',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'EL BATAN',
    searchableText: 'Azuay - Cuenca - El Batan',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|EL_DESCANSO_(CHAULLA)',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'EL DESCANSO (CHAULLA)',
    searchableText: 'Azuay - Cuenca - El Descanso (Chaulla)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|EL_SAGRARIO_(AZ)',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'EL SAGRARIO (AZ)',
    searchableText: 'Azuay - Cuenca - El Sagrario (AZ)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|EL_VECINO',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'EL VECINO',
    searchableText: 'Azuay - Cuenca - El Vecino',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|GIL_RAMIREZ_DAVALOS',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'GIL RAMIREZ DAVALOS',
    searchableText: 'Azuay - Cuenca - Gil Ramirez Davalos',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|HERMANO_MIGUEL',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'HERMANO MIGUEL',
    searchableText: 'Azuay - Cuenca - Hermano Miguel',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|HUAYNACAPAC',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'HUAYNACAPAC',
    searchableText: 'Azuay - Cuenca - Huaynacapac',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|MACHANGARA',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'MACHANGARA',
    searchableText: 'Azuay - Cuenca - Machangara',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|MONAY',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'MONAY',
    searchableText: 'Azuay - Cuenca - Monay',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|RACAR',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'RACAR',
    searchableText: 'Azuay - Cuenca - Racar',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|RICAURTE_(AZ)',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'RICAURTE (AZ)',
    searchableText: 'Azuay - Cuenca - Ricaurte (AZ)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|SAN_BLAS_(AZ)',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'SAN BLAS (AZ)',
    searchableText: 'Azuay - Cuenca - San Blas (AZ)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|SAN_JOAQUIN',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'SAN JOAQUIN',
    searchableText: 'Azuay - Cuenca - San Joaquin',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|SAN_SEBASTIAN_(AZ)',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'SAN SEBASTIAN (AZ)',
    searchableText: 'Azuay - Cuenca - San Sebastian (AZ)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|SANTA_ANA_(AZ)',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'SANTA ANA (AZ)',
    searchableText: 'Azuay - Cuenca - Santa Ana (AZ)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|SAYAUSI',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'SAYAUSI',
    searchableText: 'Azuay - Cuenca - Sayausi',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|SUCRE_(AZ)',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'SUCRE (AZ)',
    searchableText: 'Azuay - Cuenca - Sucre (AZ)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|TARQUI_(AZ)',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'TARQUI (AZ)',
    searchableText: 'Azuay - Cuenca - Tarqui (AZ)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|TOTORACOCHA',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'TOTORACOCHA',
    searchableText: 'Azuay - Cuenca - Totoracocha',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|TURI',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'TURI',
    searchableText: 'Azuay - Cuenca - Turi',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|VALLE_(AZ)',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'VALLE (AZ)',
    searchableText: 'Azuay - Cuenca - Valle (AZ)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|VICTORIA_DEL_PORTETE_(IRQUIS)',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'VICTORIA DEL PORTETE (IRQUIS)',
    searchableText: 'Azuay - Cuenca - Victoria Del Portete (Irquis)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|CUENCA|:|YANUNCAY',
    territory1Name: 'AZUAY',
    territory2Name: 'CUENCA',
    territory3Name: 'YANUNCAY',
    searchableText: 'Azuay - Cuenca - Yanuncay',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|EL_PAN|:|EL_PAN_(AZ)',
    territory1Name: 'AZUAY',
    territory2Name: 'EL PAN',
    territory3Name: 'EL PAN (AZ)',
    searchableText: 'Azuay - El Pan - El Pan (AZ)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|GIRON|:|GIRON',
    territory1Name: 'AZUAY',
    territory2Name: 'GIRON',
    territory3Name: 'GIRON',
    searchableText: 'Azuay - Giron - Giron',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|GUACHAPALA|:|GUACHAPALA',
    territory1Name: 'AZUAY',
    territory2Name: 'GUACHAPALA',
    territory3Name: 'GUACHAPALA',
    searchableText: 'Azuay - Guachapala - Guachapala',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|GUALACEO|:|GUALACEO',
    territory1Name: 'AZUAY',
    territory2Name: 'GUALACEO',
    territory3Name: 'GUALACEO',
    searchableText: 'Azuay - Gualaceo - Gualaceo',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|NABON|:|NABON',
    territory1Name: 'AZUAY',
    territory2Name: 'NABON',
    territory3Name: 'NABON',
    searchableText: 'Azuay - Nabon - Nabon',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|ONA|:|ONA',
    territory1Name: 'AZUAY',
    territory2Name: 'ONA',
    territory3Name: 'ONA',
    searchableText: 'Azuay - Ona - Ona',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|PAUTE|:|BULAN_(JOSE_VICTOR_IZQUIERDO)',
    territory1Name: 'AZUAY',
    territory2Name: 'PAUTE',
    territory3Name: 'BULAN (JOSE VICTOR IZQUIERDO)',
    searchableText: 'Azuay - Paute - Bulan (Jose Victor Izquierdo)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|PAUTE|:|CHICAN_(GUILLERMO_ORTEGA)',
    territory1Name: 'AZUAY',
    territory2Name: 'PAUTE',
    territory3Name: 'CHICAN (GUILLERMO ORTEGA)',
    searchableText: 'Azuay - Paute - Chican (Guillermo Ortega)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|PAUTE|:|EL_CABO',
    territory1Name: 'AZUAY',
    territory2Name: 'PAUTE',
    territory3Name: 'EL CABO',
    searchableText: 'Azuay - Paute - El Cabo',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|PAUTE|:|PAUTE',
    territory1Name: 'AZUAY',
    territory2Name: 'PAUTE',
    territory3Name: 'PAUTE',
    searchableText: 'Azuay - Paute - Paute',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|PUCARA|:|PUCARA',
    territory1Name: 'AZUAY',
    territory2Name: 'PUCARA',
    territory3Name: 'PUCARA',
    searchableText: 'Azuay - Pucara - Pucara',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|SAN_FERNANDO|:|SAN_FERNANDO',
    territory1Name: 'AZUAY',
    territory2Name: 'SAN FERNANDO',
    territory3Name: 'SAN FERNANDO',
    searchableText: 'Azuay - San Fernando - San Fernando',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|SANTA_ISABEL|:|SANTA_ISABEL_(CHAGUARURCO)',
    territory1Name: 'AZUAY',
    territory2Name: 'SANTA ISABEL',
    territory3Name: 'SANTA ISABEL (CHAGUARURCO)',
    searchableText: 'Azuay - Santa Isabel - Santa Isabel (Chaguarurco)',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|SEVILLA_DE_ORO|:|SEVILLA_DE_ORO',
    territory1Name: 'AZUAY',
    territory2Name: 'SEVILLA DE ORO',
    territory3Name: 'SEVILLA DE ORO',
    searchableText: 'Azuay - Sevilla De Oro - Sevilla De Oro',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|SIG_SIG|:|SAN_BARTOLOME',
    territory1Name: 'AZUAY',
    territory2Name: 'SIG SIG',
    territory3Name: 'SAN BARTOLOME',
    searchableText: 'Azuay - Sig Sig - San Bartolome',
  },
  {
    territoryBaseId: 'EC|:|AZUAY|:|SIG_SIG|:|SIGSIG',
    territory1Name: 'AZUAY',
    territory2Name: 'SIG SIG',
    territory3Name: 'SIGSIG',
    searchableText: 'Azuay - Sig Sig - Sigsig',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|CALUMA|:|CALUMA',
    territory1Name: 'BOLIVAR',
    territory2Name: 'CALUMA',
    territory3Name: 'CALUMA',
    searchableText: 'Bolivar - Caluma - Caluma',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|CHILLANES|:|CHILLANES',
    territory1Name: 'BOLIVAR',
    territory2Name: 'CHILLANES',
    territory3Name: 'CHILLANES',
    searchableText: 'Bolivar - Chillanes - Chillanes',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|CHILLANES|:|PISAGUA_ALTO',
    territory1Name: 'BOLIVAR',
    territory2Name: 'CHILLANES',
    territory3Name: 'PISAGUA ALTO',
    searchableText: 'Bolivar - Chillanes - Pisagua Alto',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|CHILLANES|:|PISAGUA_BAJO',
    territory1Name: 'BOLIVAR',
    territory2Name: 'CHILLANES',
    territory3Name: 'PISAGUA BAJO',
    searchableText: 'Bolivar - Chillanes - Pisagua Bajo',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|CHIMBO|:|ASUNCION_(ASANCOTO)',
    territory1Name: 'BOLIVAR',
    territory2Name: 'CHIMBO',
    territory3Name: 'ASUNCION (ASANCOTO)',
    searchableText: 'Bolivar - Chimbo - Asuncion (Asancoto)',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|CHIMBO|:|CHIMBO',
    territory1Name: 'BOLIVAR',
    territory2Name: 'CHIMBO',
    territory3Name: 'CHIMBO',
    searchableText: 'Bolivar - Chimbo - Chimbo',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|CHIMBO|:|MAGDALENA_(CHAPACOTO)',
    territory1Name: 'BOLIVAR',
    territory2Name: 'CHIMBO',
    territory3Name: 'MAGDALENA (CHAPACOTO)',
    searchableText: 'Bolivar - Chimbo - Magdalena (Chapacoto)',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|CHIMBO|:|SAN_JOSE_DE_CHIMBO',
    territory1Name: 'BOLIVAR',
    territory2Name: 'CHIMBO',
    territory3Name: 'SAN JOSE DE CHIMBO',
    searchableText: 'Bolivar - Chimbo - San Jose De Chimbo',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|ECHEANDIA|:|ECHEANDIA',
    territory1Name: 'BOLIVAR',
    territory2Name: 'ECHEANDIA',
    territory3Name: 'ECHEANDIA',
    searchableText: 'Bolivar - Echeandia - Echeandia',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|GUARANDA|:|1ERO_DE_MAYO',
    territory1Name: 'BOLIVAR',
    territory2Name: 'GUARANDA',
    territory3Name: '1ERO DE MAYO',
    searchableText: 'Bolivar - Guaranda - Primero de Mayo',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|GUARANDA|:|4_ESQUINAS',
    territory1Name: 'BOLIVAR',
    territory2Name: 'GUARANDA',
    territory3Name: '4 ESQUINAS',
    searchableText: 'Bolivar - Guaranda - 4 Esquinas',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|GUARANDA|:|ANGEL_POLIBIO_CHAVES',
    territory1Name: 'BOLIVAR',
    territory2Name: 'GUARANDA',
    territory3Name: 'ANGEL POLIBIO CHAVES',
    searchableText: 'Bolivar - Guaranda - Angel Polibio Chaves',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|GUARANDA|:|GABRIEL_IGNACIO_VEINTIMILLA',
    territory1Name: 'BOLIVAR',
    territory2Name: 'GUARANDA',
    territory3Name: 'GABRIEL IGNACIO VEINTIMILLA',
    searchableText: 'Bolivar - Guaranda - Gabriel Ignacio Veintimilla',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|GUARANDA|:|GUANUJO',
    territory1Name: 'BOLIVAR',
    territory2Name: 'GUARANDA',
    territory3Name: 'GUANUJO',
    searchableText: 'Bolivar - Guaranda - Guanujo',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|GUARANDA|:|GUARANDA',
    territory1Name: 'BOLIVAR',
    territory2Name: 'GUARANDA',
    territory3Name: 'GUARANDA',
    searchableText: 'Bolivar - Guaranda - Guaranda',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|GUARANDA|:|JULIO_E._MORENO_(CATANAHUAN_GRANDE)',
    territory1Name: 'BOLIVAR',
    territory2Name: 'GUARANDA',
    territory3Name: 'JULIO E. MORENO (CATANAHUAN GRANDE)',
    searchableText: 'Bolivar - Guaranda - Julio E. Moreno (Catanahuan Grande)',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|GUARANDA|:|SALINAS_(BO)',
    territory1Name: 'BOLIVAR',
    territory2Name: 'GUARANDA',
    territory3Name: 'SALINAS (BO)',
    searchableText: 'Bolivar - Guaranda - Salinas (BO)',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|GUARANDA|:|SAN_LORENZO',
    territory1Name: 'BOLIVAR',
    territory2Name: 'GUARANDA',
    territory3Name: 'SAN LORENZO',
    searchableText: 'Bolivar - Guaranda - San Lorenzo',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|GUARANDA|:|SAN_SIMON_(YACOTO)',
    territory1Name: 'BOLIVAR',
    territory2Name: 'GUARANDA',
    territory3Name: 'SAN SIMON (YACOTO)',
    searchableText: 'Bolivar - Guaranda - San Simon (Yacoto)',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|GUARANDA|:|SANTA_FE_(BO)',
    territory1Name: 'BOLIVAR',
    territory2Name: 'GUARANDA',
    territory3Name: 'SANTA FE (BO)',
    searchableText: 'Bolivar - Guaranda - Santa Fe (BO)',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|GUARANDA|:|VINCHOA',
    territory1Name: 'BOLIVAR',
    territory2Name: 'GUARANDA',
    territory3Name: 'VINCHOA',
    searchableText: 'Bolivar - Guaranda - Vinchoa',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|LAS_NAVES|:|LAS_NAVES',
    territory1Name: 'BOLIVAR',
    territory2Name: 'LAS NAVES',
    territory3Name: 'LAS NAVES',
    searchableText: 'Bolivar - Las Naves - Las Naves',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|RECINTO_24_DE_MAYO|:|RECINTO_24_DE_MAYO',
    territory1Name: 'BOLIVAR',
    territory2Name: 'RECINTO 24 DE MAYO', // Unknown canton
    territory3Name: 'RECINTO 24 DE MAYO',
    searchableText: 'Bolivar - Recinto 24 de Mayo - Recinto 24 de Mayo',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|RECINTO_EL_PALMAR|:|RECINTO_EL_PALMAR',
    territory1Name: 'BOLIVAR',
    territory2Name: 'RECINTO EL PALMAR', // Unknown canton
    territory3Name: 'RECINTO EL PALMAR',
    searchableText: 'Bolivar - Recinto El Palmar - Recinto El Palmar',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|RECINTO_LA_MARITZA|:|RECINTO_LA_MARITZA',
    territory1Name: 'BOLIVAR',
    territory2Name: 'RECINTO LA MARITZA', // Unknown canton
    territory3Name: 'RECINTO LA MARITZA',
    searchableText: 'Bolivar - Recinto La Maritza - Recinto La Maritza',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|SAN_MIGUEL|:|BALZAPAMBA',
    territory1Name: 'BOLIVAR',
    territory2Name: 'SAN MIGUEL',
    territory3Name: 'BALZAPAMBA',
    searchableText: 'Bolivar - San Miguel - Balzapamba',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|SAN_MIGUEL|:|SAN_MIGUEL_(BO)',
    territory1Name: 'BOLIVAR',
    territory2Name: 'SAN MIGUEL',
    territory3Name: 'SAN MIGUEL (BO)',
    searchableText: 'Bolivar - San Miguel - San Miguel (BO)',
  },
  {
    territoryBaseId: 'EC|:|BOLIVAR|:|SAN_MIGUEL|:|SAN_PABLO_DE_ATENAS',
    territory1Name: 'BOLIVAR',
    territory2Name: 'SAN MIGUEL',
    territory3Name: 'SAN PABLO DE ATENAS',
    searchableText: 'Bolivar - San Miguel - San Pablo de Atenas',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|AZOGUES|:|AURELIO_BAYAS',
    territory1Name: 'CANAR',
    territory2Name: 'AZOGUES',
    territory3Name: 'AURELIO BAYAS',
    searchableText: 'Canar - Azogues - Aurelio Bayas',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|AZOGUES|:|AZOGUES',
    territory1Name: 'CANAR',
    territory2Name: 'AZOGUES',
    territory3Name: 'AZOGUES',
    searchableText: 'Canar - Azogues - Azogues',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|AZOGUES|:|BORRERO',
    territory1Name: 'CANAR',
    territory2Name: 'AZOGUES',
    territory3Name: 'BORRERO',
    searchableText: 'Canar - Azogues - Borrero',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|AZOGUES|:|COJITAMBO',
    territory1Name: 'CANAR',
    territory2Name: 'AZOGUES',
    territory3Name: 'COJITAMBO',
    searchableText: 'Canar - Azogues - Cojitambo',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|AZOGUES|:|GUAPAN',
    territory1Name: 'CANAR',
    territory2Name: 'AZOGUES',
    territory3Name: 'GUAPAN',
    searchableText: 'Canar - Azogues - Guapan',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|AZOGUES|:|JAVIER_LOYOLA_(CHUQUIPATA)',
    territory1Name: 'CANAR',
    territory2Name: 'AZOGUES',
    territory3Name: 'JAVIER LOYOLA (CHUQUIPATA)',
    searchableText: 'Canar - Azogues - Javier Loyola (Chuquipata)',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|AZOGUES|:|LUIS_CORDERO',
    territory1Name: 'CANAR',
    territory2Name: 'AZOGUES',
    territory3Name: 'LUIS CORDERO',
    searchableText: 'Canar - Azogues - Luis Cordero',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|AZOGUES|:|SAN_FRANCISCO_(CÑ)',
    territory1Name: 'CANAR',
    territory2Name: 'AZOGUES',
    territory3Name: 'SAN FRANCISCO (CÑ)',
    searchableText: 'Canar - Azogues - San Francisco (CÑ)',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|BIBLIAN|:|BIBLIAN',
    territory1Name: 'CANAR',
    territory2Name: 'BIBLIAN',
    territory3Name: 'BIBLIAN',
    searchableText: 'Canar - Biblian - Biblian',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|BIBLIAN|:|NAZON_(CAB._EN_PAMPA_DE_DOMINGUEZ)',
    territory1Name: 'CANAR',
    territory2Name: 'BIBLIAN',
    territory3Name: 'NAZON (CAB. EN PAMPA DE DOMINGUEZ)',
    searchableText: 'Canar - Biblian - Nazon (Cab. En Pampa De Dominguez)',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|CANAR|:|CANAR',
    territory1Name: 'CANAR',
    territory2Name: 'CANAR',
    territory3Name: 'CANAR',
    searchableText: 'Canar - Canar - Canar',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|CANAR|:|DUCUR',
    territory1Name: 'CANAR',
    territory2Name: 'CANAR',
    territory3Name: 'DUCUR',
    searchableText: 'Canar - Canar - Ducur',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|CANAR|:|INGAPIRCA',
    territory1Name: 'CANAR',
    territory2Name: 'CANAR',
    territory3Name: 'INGAPIRCA',
    searchableText: 'Canar - Canar - Ingapirca',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|CANAR|:|ZHUD',
    territory1Name: 'CANAR',
    territory2Name: 'CANAR',
    territory3Name: 'ZHUD',
    searchableText: 'Canar - Canar - Zhud',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|DELEG|:|DELEG',
    territory1Name: 'CANAR',
    territory2Name: 'DELEG',
    territory3Name: 'DELEG',
    searchableText: 'Canar - Deleg - Deleg',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|EL_TAMBO|:|EL_TAMBO_(AZ)',
    territory1Name: 'CANAR',
    territory2Name: 'EL TAMBO',
    territory3Name: 'EL TAMBO (AZ)',
    searchableText: 'Canar - El Tambo - El Tambo (AZ)',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|LA_TRONCAL|:|COCHANCAY',
    territory1Name: 'CANAR',
    territory2Name: 'LA TRONCAL',
    territory3Name: 'COCHANCAY',
    searchableText: 'Canar - La Troncal - Cochancay',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|LA_TRONCAL|:|LA_PUNTILLA',
    territory1Name: 'CANAR',
    territory2Name: 'LA TRONCAL',
    territory3Name: 'LA PUNTILLA',
    searchableText: 'Canar - La Troncal - La Puntilla',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|LA_TRONCAL|:|LA_TRONCAL',
    territory1Name: 'CANAR',
    territory2Name: 'LA TRONCAL',
    territory3Name: 'LA TRONCAL',
    searchableText: 'Canar - La Troncal - La Troncal',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|LA_TRONCAL|:|MANUEL_J._CALLE',
    territory1Name: 'CANAR',
    territory2Name: 'LA TRONCAL',
    territory3Name: 'MANUEL J. CALLE',
    searchableText: 'Canar - La Troncal - Manuel J. Calle',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|LA_TRONCAL|:|VOLUNTAD_DE_DIOS',
    territory1Name: 'CANAR',
    territory2Name: 'LA TRONCAL',
    territory3Name: 'VOLUNTAD DE DIOS',
    searchableText: 'Canar - La Troncal - Voluntad de Dios',
  },
  {
    territoryBaseId: 'EC|:|CANAR|:|SUSCAL|:|SUSCAL',
    territory1Name: 'CANAR',
    territory2Name: 'SUSCAL',
    territory3Name: 'SUSCAL',
    searchableText: 'Canar - Suscal - Suscal',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|BOLIVAR|:|BOLIVAR_(CA)',
    territory1Name: 'CARCHI',
    territory2Name: 'BOLIVAR',
    territory3Name: 'BOLIVAR (CA)',
    searchableText: 'Carchi - Bolivar - Bolivar (CA)',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|BOLIVAR|:|CUESACA',
    territory1Name: 'CARCHI',
    territory2Name: 'BOLIVAR',
    territory3Name: 'CUESACA',
    searchableText: 'Carchi - Bolivar - Cuesaca',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|BOLIVAR|:|GARCIA_MORENO',
    territory1Name: 'CARCHI',
    territory2Name: 'BOLIVAR',
    territory3Name: 'GARCIA MORENO',
    searchableText: 'Carchi - Bolivar - Garcia Moreno',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|BOLIVAR|:|LOS_ANDES',
    territory1Name: 'CARCHI',
    territory2Name: 'BOLIVAR',
    territory3Name: 'LOS ANDES',
    searchableText: 'Carchi - Bolivar - Los Andes',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|ESPEJO|:|27_DE_SEPTIEMBRE',
    territory1Name: 'CARCHI',
    territory2Name: 'ESPEJO',
    territory3Name: '27 DE SEPTIEMBRE',
    searchableText: 'Carchi - Espejo - 27 De Septiembre',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|ESPEJO|:|EL_ANGEL',
    territory1Name: 'CARCHI',
    territory2Name: 'ESPEJO',
    territory3Name: 'EL ANGEL',
    searchableText: 'Carchi - Espejo - El Angel',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|ESPEJO|:|LA_LIBERTAD_(CA)',
    territory1Name: 'CARCHI',
    territory2Name: 'ESPEJO',
    territory3Name: 'LA LIBERTAD (CA)',
    searchableText: 'Carchi - Espejo - La Libertad (CA)',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|ESPEJO|:|SAN_ISIDRO',
    territory1Name: 'CARCHI',
    territory2Name: 'ESPEJO',
    territory3Name: 'SAN ISIDRO',
    searchableText: 'Carchi - Espejo - San Isidro',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|MIRA|:|MIRA',
    territory1Name: 'CARCHI',
    territory2Name: 'MIRA',
    territory3Name: 'MIRA',
    searchableText: 'Carchi - Mira - Mira',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|SAN_GABRIEL_(MONTUFAR)|:|CHITAN_DE_NAVARRETE',
    territory1Name: 'CARCHI',
    territory2Name: 'SAN GABRIEL (MONTUFAR)',
    territory3Name: 'CHITAN DE NAVARRETE',
    searchableText: 'Carchi - San Gabriel (Montufar) - Chitan De Navarrete',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|SAN_GABRIEL_(MONTUFAR)|:|CRISTOBAL_COLON_(CA)',
    territory1Name: 'CARCHI',
    territory2Name: 'SAN GABRIEL (MONTUFAR)',
    territory3Name: 'CRISTOBAL COLON (CA)',
    searchableText: 'Carchi - San Gabriel (Montufar) - Cristobal Colon (CA)',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|SAN_GABRIEL_(MONTUFAR)|:|GONZALEZ_SUAREZ_(CA)',
    territory1Name: 'CARCHI',
    territory2Name: 'SAN GABRIEL (MONTUFAR)',
    territory3Name: 'GONZALEZ SUAREZ (CA)',
    searchableText: 'Carchi - San Gabriel (Montufar) - Gonzalez Suarez (CA)',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|SAN_GABRIEL_(MONTUFAR)|:|LA_PAZ',
    territory1Name: 'CARCHI',
    territory2Name: 'SAN GABRIEL (MONTUFAR)',
    territory3Name: 'LA PAZ',
    searchableText: 'Carchi - San Gabriel (Montufar) - La Paz',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|SAN_GABRIEL_(MONTUFAR)|:|PIARTAL',
    territory1Name: 'CARCHI',
    territory2Name: 'SAN GABRIEL (MONTUFAR)',
    territory3Name: 'PIARTAL',
    searchableText: 'Carchi - San Gabriel (Montufar) - Piartal',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|SAN_GABRIEL_(MONTUFAR)|:|SAN_GABRIEL',
    territory1Name: 'CARCHI',
    territory2Name: 'SAN GABRIEL (MONTUFAR)',
    territory3Name: 'SAN GABRIEL',
    searchableText: 'Carchi - San Gabriel (Montufar) - San Gabriel',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|SAN_GABRIEL_(MONTUFAR)|:|SAN_JOSE',
    territory1Name: 'CARCHI',
    territory2Name: 'SAN GABRIEL (MONTUFAR)',
    territory3Name: 'SAN JOSE',
    searchableText: 'Carchi - San Gabriel (Montufar) - San Jose',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|SAN_GABRIEL_(MONTUFAR)|:|SANDIAL',
    territory1Name: 'CARCHI',
    territory2Name: 'SAN GABRIEL (MONTUFAR)',
    territory3Name: 'SANDIAL',
    searchableText: 'Carchi - San Gabriel (Montufar) - Sandial',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|SAN_PEDRO_DE_HUACA|:|HUACA',
    territory1Name: 'CARCHI',
    territory2Name: 'SAN PEDRO DE HUACA',
    territory3Name: 'HUACA',
    searchableText: 'Carchi - San Pedro De Huaca - Huaca',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|SAN_PEDRO_DE_HUACA|:|MARISCAL_SUCRE_(CA)',
    territory1Name: 'CARCHI',
    territory2Name: 'SAN PEDRO DE HUACA',
    territory3Name: 'MARISCAL SUCRE (CA)',
    searchableText: 'Carchi - San Pedro De Huaca - Mariscal Sucre (CA)',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|TULCAN|:|GONZALEZ_SUAREZ_(CA)',
    territory1Name: 'CARCHI',
    territory2Name: 'TULCAN',
    territory3Name: 'GONZALEZ SUAREZ (CA)',
    searchableText: 'Carchi - Tulcan - Gonzalez Suarez (CA)',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|TULCAN|:|JULIO_ANDRADE_(OREJUELA)',
    territory1Name: 'CARCHI',
    territory2Name: 'TULCAN',
    territory3Name: 'JULIO ANDRADE (OREJUELA)',
    searchableText: 'Carchi - Tulcan - Julio Andrade (Orejuela)',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|TULCAN|:|PIOTER',
    territory1Name: 'CARCHI',
    territory2Name: 'TULCAN',
    territory3Name: 'PIOTER',
    searchableText: 'Carchi - Tulcan - Pioter',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|TULCAN|:|SANTA_MARTHA_DE_CUBA',
    territory1Name: 'CARCHI',
    territory2Name: 'TULCAN',
    territory3Name: 'SANTA MARTHA DE CUBA',
    searchableText: 'Carchi - Tulcan - Santa Martha De Cuba',
  },
  {
    territoryBaseId: 'EC|:|CARCHI|:|TULCAN|:|TULCAN',
    territory1Name: 'CARCHI',
    territory2Name: 'TULCAN',
    territory3Name: 'TULCAN',
    searchableText: 'Carchi - Tulcan - Tulcan',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|ALAUSI|:|ALAUSI',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'ALAUSI',
    territory3Name: 'ALAUSI',
    searchableText: 'Chimborazo - Alausi - Alausi',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|CHAMBO|:|CHAMBO',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'CHAMBO',
    territory3Name: 'CHAMBO',
    searchableText: 'Chimborazo - Chambo - Chambo',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|CHUNCHI|:|CHUNCHI',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'CHUNCHI',
    territory3Name: 'CHUNCHI',
    searchableText: 'Chimborazo - Chunchi - Chunchi',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|COLTA|:|CAJABAMBA',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'COLTA',
    territory3Name: 'CAJABAMBA',
    searchableText: 'Chimborazo - Colta - Cajabamba',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|COLTA|:|COLTA',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'COLTA',
    territory3Name: 'COLTA',
    searchableText: 'Chimborazo - Colta - Colta',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|CUMANDA|:|CUMANDA',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'CUMANDA',
    territory3Name: 'CUMANDA',
    searchableText: 'Chimborazo - Cumanda - Cumanda',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|GUAMOTE|:|GUAMOTE',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'GUAMOTE',
    territory3Name: 'GUAMOTE',
    searchableText: 'Chimborazo - Guamote - Guamote',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|GUANO|:|EL_ROSARIO_(GUANO)_(CH)',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'GUANO',
    territory3Name: 'EL ROSARIO (GUANO) (CH)',
    searchableText: 'Chimborazo - Guano - El Rosario (Guano) (CH)',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|GUANO|:|GUANO_(CH)',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'GUANO',
    territory3Name: 'GUANO (CH)',
    searchableText: 'Chimborazo - Guano - Guano (CH)',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|GUANO|:|LA_MATRIZ_(GUANO)_(CH)',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'GUANO',
    territory3Name: 'LA MATRIZ (GUANO) (CH)',
    searchableText: 'Chimborazo - Guano - La Matriz (Guano) (CH)',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|GUANO|:|SAN_ANDRES_(CHI)',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'GUANO',
    territory3Name: 'SAN ANDRES (CHI)',
    searchableText: 'Chimborazo - Guano - San Andres (Chi)',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|PALLATANGA|:|PALLATANGA',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'PALLATANGA',
    territory3Name: 'PALLATANGA',
    searchableText: 'Chimborazo - Pallatanga - Pallatanga',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|PENIPE|:|PENIPE',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'PENIPE',
    territory3Name: 'PENIPE',
    searchableText: 'Chimborazo - Penipe - Penipe',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|RIOBAMBA|:|LICAN',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'RIOBAMBA',
    territory3Name: 'LICAN',
    searchableText: 'Chimborazo - Riobamba - Lican',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|RIOBAMBA|:|LIZARZABURU',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'RIOBAMBA',
    territory3Name: 'LIZARZABURU',
    searchableText: 'Chimborazo - Riobamba - Lizarzaburu',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|RIOBAMBA|:|MALDONADO_(CH)',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'RIOBAMBA',
    territory3Name: 'MALDONADO (CH)',
    searchableText: 'Chimborazo - Riobamba - Maldonado (CH)',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|RIOBAMBA|:|RIOBAMBA',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'RIOBAMBA',
    territory3Name: 'RIOBAMBA',
    searchableText: 'Chimborazo - Riobamba - Riobamba',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|RIOBAMBA|:|SAN_JUAN_CHICO_(CH)',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'RIOBAMBA',
    territory3Name: 'SAN JUAN CHICO (CH)',
    searchableText: 'Chimborazo - Riobamba - San Juan Chico (CH)',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|RIOBAMBA|:|SAN_LUIS_(CH)',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'RIOBAMBA',
    territory3Name: 'SAN LUIS (CH)',
    searchableText: 'Chimborazo - Riobamba - San Luis (CH)',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|RIOBAMBA|:|VELASCO',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'RIOBAMBA',
    territory3Name: 'VELASCO',
    searchableText: 'Chimborazo - Riobamba - Velasco',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|RIOBAMBA|:|VELOZ',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'RIOBAMBA',
    territory3Name: 'VELOZ',
    searchableText: 'Chimborazo - Riobamba - Veloz',
  },
  {
    territoryBaseId: 'EC|:|CHIMBORAZO|:|RIOBAMBA|:|YARUQUIES',
    territory1Name: 'CHIMBORAZO',
    territory2Name: 'RIOBAMBA',
    territory3Name: 'YARUQUIES',
    searchableText: 'Chimborazo - Riobamba - Yaruquies',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LA_MANA|:|LA_MANA_(LR)',
    territory1Name: 'COTOPAXI',
    territory2Name: 'LA MANA',
    territory3Name: 'LA MANA (LR)',
    searchableText: 'Cotopaxi - La Mana - La Mana (LR)',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LA_MANA|:|SAN_MARCOS',
    territory1Name: 'COTOPAXI',
    territory2Name: 'LA MANA',
    territory3Name: 'SAN MARCOS',
    searchableText: 'Cotopaxi - La Mana - San Marcos',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|ALAQUEZ',
    territory1Name: 'COTOPAXI',
    territory2Name: 'LATACUNGA',
    territory3Name: 'ALAQUEZ',
    searchableText: 'Cotopaxi - Latacunga - Alaquez',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|BELISARIO_QUEVEDO',
    territory1Name: 'COTOPAXI',
    territory2Name: 'LATACUNGA',
    territory3Name: 'BELISARIO QUEVEDO',
    searchableText: 'Cotopaxi - Latacunga - Belisario Quevedo',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|ELOY_ALFARO__(SAN_FELIPE)_(CO)',
    territory1Name: 'COTOPAXI',
    territory2Name: 'LATACUNGA',
    territory3Name: 'ELOY ALFARO  (SAN FELIPE) (CO)',
    searchableText: 'Cotopaxi - Latacunga - Eloy Alfaro  (San Felipe) (CO)',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|GUAYTACAMA',
    territory1Name: 'COTOPAXI',
    territory2Name: 'LATACUNGA',
    territory3Name: 'GUAYTACAMA',
    searchableText: 'Cotopaxi - Latacunga - Guaytacama',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|IGNACIO_FLORES_(PARQUE_FLORES)',
    territory1Name: 'COTOPAXI',
    territory2Name: 'LATACUNGA',
    territory3Name: 'IGNACIO FLORES (PARQUE FLORES)',
    searchableText: 'Cotopaxi - Latacunga - Ignacio Flores (Parque Flores)',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|JUAN_MONTALVO_(SAN_SEBASTIAN)_(CO)',
    territory1Name: 'COTOPAXI',
    territory2Name: 'LATACUNGA',
    territory3Name: 'JUAN MONTALVO (SAN SEBASTIAN) (CO)',
    searchableText: 'Cotopaxi - Latacunga - Juan Montalvo (San Sebastian) (CO)',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|LA_MATRIZ_(CO)',
    territory1Name: 'COTOPAXI',
    territory2Name: 'LATACUNGA',
    territory3Name: 'LA MATRIZ (CO)',
    searchableText: 'Cotopaxi - Latacunga - La Matriz (CO)',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|LA_VICTORIA',
    territory1Name: 'COTOPAXI',
    territory2Name: 'LATACUNGA',
    territory3Name: 'LA VICTORIA',
    searchableText: 'Cotopaxi - Latacunga - La Victoria',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|LASSO',
    territory1Name: 'COTOPAXI',
    territory2Name: 'LATACUNGA',
    territory3Name: 'LASSO',
    searchableText: 'Cotopaxi - Latacunga - Lasso',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|LATACUNGA',
    territory1Name: 'COTOPAXI',
    territory2Name: 'LATACUNGA',
    territory3Name: 'LATACUNGA',
    searchableText: 'Cotopaxi - Latacunga - Latacunga',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|MULALAO',
    territory1Name: 'COTOPAXI',
    territory2Name: 'LATACUNGA',
    territory3Name: 'MULALAO',
    searchableText: 'Cotopaxi - Latacunga - Mulalao',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|PATUTAN',
    territory1Name: 'COTOPAXI',
    territory2Name: 'LATACUNGA',
    territory3Name: 'PATUTAN',
    searchableText: 'Cotopaxi - Latacunga - Patutan',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|POALO',
    territory1Name: 'COTOPAXI',
    territory2Name: 'LATACUNGA',
    territory3Name: 'POALO',
    searchableText: 'Cotopaxi - Latacunga - Poalo',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|RUMIPAMBA',
    territory1Name: 'COTOPAXI',
    territory2Name: 'LATACUNGA',
    territory3Name: 'RUMIPAMBA',
    searchableText: 'Cotopaxi - Latacunga - Rumipamba',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|SAN_BUENAVENTURA',
    territory1Name: 'COTOPAXI',
    territory2Name: 'LATACUNGA',
    territory3Name: 'SAN BUENAVENTURA',
    searchableText: 'Cotopaxi - Latacunga - San Buenaventura',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|SAN_JUAN_DE_PASTOCALLE',
    territory1Name: 'COTOPAXI',
    territory2Name: 'LATACUNGA',
    territory3Name: 'SAN JUAN DE PASTOCALLE',
    searchableText: 'Cotopaxi - Latacunga - San Juan De Pastocalle',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|TANICUCHI',
    territory1Name: 'COTOPAXI',
    territory2Name: 'LATACUNGA',
    territory3Name: 'TANICUCHI',
    searchableText: 'Cotopaxi - Latacunga - Tanicuchi',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|LATACUNGA|:|TOACASO',
    territory1Name: 'COTOPAXI',
    territory2Name: 'LATACUNGA',
    territory3Name: 'TOACASO',
    searchableText: 'Cotopaxi - Latacunga - Toacaso',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|PANGUA|:|EL_CORAZON',
    territory1Name: 'COTOPAXI',
    territory2Name: 'PANGUA',
    territory3Name: 'EL CORAZON',
    searchableText: 'Cotopaxi - Pangua - El Corazón',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|PANGUA|:|MORASPUNGO',
    territory1Name: 'COTOPAXI',
    territory2Name: 'PANGUA',
    territory3Name: 'MORASPUNGO',
    searchableText: 'Cotopaxi - Pangua - Moraspungo',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|PANGUA|:|PANGUA',
    territory1Name: 'COTOPAXI',
    territory2Name: 'PANGUA',
    territory3Name: 'PANGUA',
    searchableText: 'Cotopaxi - Pangua - Pangua',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|PUJILI|:|PUJILI',
    territory1Name: 'COTOPAXI',
    territory2Name: 'PUJILI',
    territory3Name: 'PUJILI',
    searchableText: 'Cotopaxi - Pujili - Pujili',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|PUJILI|:|ZUMBAHUA',
    territory1Name: 'COTOPAXI',
    territory2Name: 'PUJILI',
    territory3Name: 'ZUMBAHUA',
    searchableText: 'Cotopaxi - Pujili - Zumbahua',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SALCEDO|:|ANCHILIVI',
    territory1Name: 'COTOPAXI',
    territory2Name: 'SALCEDO',
    territory3Name: 'ANCHILIVI',
    searchableText: 'Cotopaxi - Salcedo - Anchilivi',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SALCEDO|:|ANTONIO_JOSE_HOLGUIN_(SANTA_LUCIA)',
    territory1Name: 'COTOPAXI',
    territory2Name: 'SALCEDO',
    territory3Name: 'ANTONIO JOSE HOLGUIN (SANTA LUCIA)',
    searchableText: 'Cotopaxi - Salcedo - Antonio Jose Holguin (Santa Lucia)',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SALCEDO|:|CHIPUALO',
    territory1Name: 'COTOPAXI',
    territory2Name: 'SALCEDO',
    territory3Name: 'CHIPUALO',
    searchableText: 'Cotopaxi - Salcedo - Chipualo',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SALCEDO|:|CUMCHIBAMBA',
    territory1Name: 'COTOPAXI',
    territory2Name: 'SALCEDO',
    territory3Name: 'CUMCHIBAMBA',
    searchableText: 'Cotopaxi - Salcedo - Cumchibamba',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SALCEDO|:|MULALILLO',
    territory1Name: 'COTOPAXI',
    territory2Name: 'SALCEDO',
    territory3Name: 'MULALILLO',
    searchableText: 'Cotopaxi - Salcedo - Mulalillo',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SALCEDO|:|MULLIQUINDIL_(SANTA_ANA)(CO)',
    territory1Name: 'COTOPAXI',
    territory2Name: 'SALCEDO',
    territory3Name: 'MULLIQUINDIL (SANTA ANA)(CO)',
    searchableText: 'Cotopaxi - Salcedo - Mulliquindil (Santa Ana)(co)',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SALCEDO|:|PANZALEO',
    territory1Name: 'COTOPAXI',
    territory2Name: 'SALCEDO',
    territory3Name: 'PANZALEO',
    searchableText: 'Cotopaxi - Salcedo - Panzaleo',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SALCEDO|:|PATAIN',
    territory1Name: 'COTOPAXI',
    territory2Name: 'SALCEDO',
    territory3Name: 'PATAIN',
    searchableText: 'Cotopaxi - Salcedo - Patain',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SALCEDO|:|SALACHE',
    territory1Name: 'COTOPAXI',
    territory2Name: 'SALCEDO',
    territory3Name: 'SALACHE',
    searchableText: 'Cotopaxi - Salcedo - Salache',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SALCEDO|:|SALCEDO',
    territory1Name: 'COTOPAXI',
    territory2Name: 'SALCEDO',
    territory3Name: 'SALCEDO',
    searchableText: 'Cotopaxi - Salcedo - Salcedo',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SALCEDO|:|SAN_MIGUEL_(CO)',
    territory1Name: 'COTOPAXI',
    territory2Name: 'SALCEDO',
    territory3Name: 'SAN MIGUEL (CO)',
    searchableText: 'Cotopaxi - Salcedo - San Miguel (CO)',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SALCEDO|:|YANAYACU',
    territory1Name: 'COTOPAXI',
    territory2Name: 'SALCEDO',
    territory3Name: 'YANAYACU',
    searchableText: 'Cotopaxi - Salcedo - Yanayacu',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SAQUISILI|:|SAQUISILI',
    territory1Name: 'COTOPAXI',
    territory2Name: 'SAQUISILI',
    territory3Name: 'SAQUISILI',
    searchableText: 'Cotopaxi - Saquisili - Saquisili',
  },
  {
    territoryBaseId: 'EC|:|COTOPAXI|:|SIGCHOS|:|SIGCHOS',
    territory1Name: 'COTOPAXI',
    territory2Name: 'SIGCHOS',
    territory3Name: 'SIGCHOS',
    searchableText: 'Cotopaxi - Sigchos - Sigchos',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|ARENILLAS|:|ARENILLAS',
    territory1Name: 'EL ORO',
    territory2Name: 'ARENILLAS',
    territory3Name: 'ARENILLAS',
    searchableText: 'El Oro - Arenillas - Arenillas',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|ARENILLAS|:|SAN_VICENTE_DEL_JOBO',
    territory1Name: 'EL ORO',
    territory2Name: 'ARENILLAS',
    territory3Name: 'SAN VICENTE DEL JOBO',
    searchableText: 'El Oro - Arenillas - San Vicente Del Jobo',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|ATAHUALPA|:|ATAHUALPA',
    territory1Name: 'EL ORO',
    territory2Name: 'ATAHUALPA',
    territory3Name: 'ATAHUALPA',
    searchableText: 'El Oro - Atahualpa - Atahualpa',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|ATAHUALPA|:|PACCHA',
    territory1Name: 'EL ORO',
    territory2Name: 'ATAHUALPA',
    territory3Name: 'PACCHA',
    searchableText: 'El Oro - Atahualpa - Paccha',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|BALSAS|:|BALSAS',
    territory1Name: 'EL ORO',
    territory2Name: 'BALSAS',
    territory3Name: 'BALSAS',
    searchableText: 'El Oro - Balsas - Balsas',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|BALSAS|:|BELLAMARIA',
    territory1Name: 'EL ORO',
    territory2Name: 'BALSAS',
    territory3Name: 'BELLAMARIA',
    searchableText: 'El Oro - Balsas - Bellamaria',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|CHILLA|:|CHILLA',
    territory1Name: 'EL ORO',
    territory2Name: 'CHILLA',
    territory3Name: 'CHILLA',
    searchableText: 'El Oro - Chilla - Chilla',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|EL_GUABO|:|BAJO_ALTO',
    territory1Name: 'EL ORO',
    territory2Name: 'EL GUABO',
    territory3Name: 'BAJO ALTO',
    searchableText: 'El Oro - El Guabo - Bajo Alto',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|EL_GUABO|:|BARBONES',
    territory1Name: 'EL ORO',
    territory2Name: 'EL GUABO',
    territory3Name: 'BARBONES',
    searchableText: 'El Oro - El Guabo - Barbones',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|EL_GUABO|:|EL_GUABO',
    territory1Name: 'EL ORO',
    territory2Name: 'EL GUABO',
    territory3Name: 'EL GUABO',
    searchableText: 'El Oro - El Guabo - El Guabo',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|EL_GUABO|:|LA_IBERIA',
    territory1Name: 'EL ORO',
    territory2Name: 'EL GUABO',
    territory3Name: 'LA IBERIA',
    searchableText: 'El Oro - El Guabo - La Iberia',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|EL_GUABO|:|RIO_BONITO',
    territory1Name: 'EL ORO',
    territory2Name: 'EL GUABO',
    territory3Name: 'RIO BONITO',
    searchableText: 'El Oro - El Guabo - Rio Bonito',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|HUAQUILLAS|:|ECUADOR',
    territory1Name: 'EL ORO',
    territory2Name: 'HUAQUILLAS',
    territory3Name: 'ECUADOR',
    searchableText: 'El Oro - Huaquillas - Ecuador',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|HUAQUILLAS|:|EL_PARAISO_(EO)',
    territory1Name: 'EL ORO',
    territory2Name: 'HUAQUILLAS',
    territory3Name: 'EL PARAISO (EO)',
    searchableText: 'El Oro - Huaquillas - El Paraiso (EO)',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|HUAQUILLAS|:|HUALTACO',
    territory1Name: 'EL ORO',
    territory2Name: 'HUAQUILLAS',
    territory3Name: 'HUALTACO',
    searchableText: 'El Oro - Huaquillas - Hualtaco',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|HUAQUILLAS|:|HUAQUILLAS',
    territory1Name: 'EL ORO',
    territory2Name: 'HUAQUILLAS',
    territory3Name: 'HUAQUILLAS',
    searchableText: 'El Oro - Huaquillas - Huaquillas',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|HUAQUILLAS|:|MILTON_REYES',
    territory1Name: 'EL ORO',
    territory2Name: 'HUAQUILLAS',
    territory3Name: 'MILTON REYES',
    searchableText: 'El Oro - Huaquillas - Milton Reyes',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|HUAQUILLAS|:|UNION_LOJANA_(EO)',
    territory1Name: 'EL ORO',
    territory2Name: 'HUAQUILLAS',
    territory3Name: 'UNION LOJANA (EO)',
    searchableText: 'El Oro - Huaquillas - Union Lojana (EO)',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|LAS_LAJAS|:|LAS_LAJAS',
    territory1Name: 'EL ORO',
    territory2Name: 'LAS LAJAS',
    territory3Name: 'LAS LAJAS',
    searchableText: 'El Oro - Las Lajas - Las Lajas',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|MACHALA|:|EL_CAMBIO',
    territory1Name: 'EL ORO',
    territory2Name: 'MACHALA',
    territory3Name: 'EL CAMBIO',
    searchableText: 'El Oro - Machala - El Cambio',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|MACHALA|:|EL_PORTON',
    territory1Name: 'EL ORO',
    territory2Name: 'MACHALA',
    territory3Name: 'EL PORTON',
    searchableText: 'El Oro - Machala - El Porton',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|MACHALA|:|EL_RETIRO',
    territory1Name: 'EL ORO',
    territory2Name: 'MACHALA',
    territory3Name: 'EL RETIRO',
    searchableText: 'El Oro - Machala - El Retiro',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|MACHALA|:|JUBONES',
    territory1Name: 'EL ORO',
    territory2Name: 'MACHALA',
    territory3Name: 'JUBONES',
    searchableText: 'El Oro - Machala - Jubones',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|MACHALA|:|LA_PROVIDENCIA',
    territory1Name: 'EL ORO',
    territory2Name: 'MACHALA',
    territory3Name: 'LA PROVIDENCIA',
    searchableText: 'El Oro - Machala - La Providencia',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|MACHALA|:|MACHALA',
    territory1Name: 'EL ORO',
    territory2Name: 'MACHALA',
    territory3Name: 'MACHALA',
    searchableText: 'El Oro - Machala - Machala',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|MACHALA|:|NUEVE_DE_MAYO',
    territory1Name: 'EL ORO',
    territory2Name: 'MACHALA',
    territory3Name: 'NUEVE DE MAYO',
    searchableText: 'El Oro - Machala - Nueve De Mayo',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|MACHALA|:|PUERTO_BOLIVAR',
    territory1Name: 'EL ORO',
    territory2Name: 'MACHALA',
    territory3Name: 'PUERTO BOLIVAR',
    searchableText: 'El Oro - Machala - Puerto Bolivar',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|MARCABELI|:|MARCABELI',
    territory1Name: 'EL ORO',
    territory2Name: 'MARCABELI',
    territory3Name: 'MARCABELI',
    searchableText: 'El Oro - Marcabeli - Marcabeli',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PASAJE|:|BOLIVAR_(EO)',
    territory1Name: 'EL ORO',
    territory2Name: 'PASAJE',
    territory3Name: 'BOLIVAR (EO)',
    searchableText: 'El Oro - Pasaje - Bolivar (EO)',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PASAJE|:|BUENAVISTA_(EO)',
    territory1Name: 'EL ORO',
    territory2Name: 'PASAJE',
    territory3Name: 'BUENAVISTA (EO)',
    searchableText: 'El Oro - Pasaje - Buenavista (EO)',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PASAJE|:|CANA_QUEMADA',
    territory1Name: 'EL ORO',
    territory2Name: 'PASAJE',
    territory3Name: 'CANA QUEMADA',
    searchableText: 'El Oro - Pasaje - Caña Quemada',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PASAJE|:|LA_PEAÑA',
    territory1Name: 'EL ORO',
    territory2Name: 'PASAJE',
    territory3Name: 'LA PEAÑA',
    searchableText: 'El Oro - Pasaje - La Peaña',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PASAJE|:|LA_VICTORIA',
    territory1Name: 'EL ORO',
    territory2Name: 'PASAJE',
    territory3Name: 'LA VICTORIA',
    searchableText: 'El Oro - Pasaje - La Victoria',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PASAJE|:|LOMA_DE_FRANCO',
    territory1Name: 'EL ORO',
    territory2Name: 'PASAJE',
    territory3Name: 'LOMA DE FRANCO',
    searchableText: 'El Oro - Pasaje - Loma De Franco',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PASAJE|:|OCHOA_LEON_(MATRIZ)_(EO)',
    territory1Name: 'EL ORO',
    territory2Name: 'PASAJE',
    territory3Name: 'OCHOA LEON (MATRIZ) (EO)',
    searchableText: 'El Oro - Pasaje - Ochoa Leon (Matriz) (EO)',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PASAJE|:|PASAJE',
    territory1Name: 'EL ORO',
    territory2Name: 'PASAJE',
    territory3Name: 'PASAJE',
    searchableText: 'El Oro - Pasaje - Pasaje',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PASAJE|:|PROGRESO_(EO)',
    territory1Name: 'EL ORO',
    territory2Name: 'PASAJE',
    territory3Name: 'PROGRESO (EO)',
    searchableText: 'El Oro - Pasaje - Progreso (EO)',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PASAJE|:|TRES_CERRITOS',
    territory1Name: 'EL ORO',
    territory2Name: 'PASAJE',
    territory3Name: 'TRES CERRITOS',
    searchableText: 'El Oro - Pasaje - Tres Cerritos',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PINAS|:|EL_PACHE',
    territory1Name: 'EL ORO',
    territory2Name: 'PINAS',
    territory3Name: 'EL PACHE',
    searchableText: 'El Oro - Pinas - El Pache',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PINAS|:|LA_MATRIZ_(EO)',
    territory1Name: 'EL ORO',
    territory2Name: 'PINAS',
    territory3Name: 'LA MATRIZ (EO)',
    searchableText: 'El Oro - Pinas - La Matriz (EO)',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PINAS|:|LA_SUSAYA',
    territory1Name: 'EL ORO',
    territory2Name: 'PINAS',
    territory3Name: 'LA SUSAYA',
    searchableText: 'El Oro - Pinas - La Susaya',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PINAS|:|PIÑAS',
    territory1Name: 'EL ORO',
    territory2Name: 'PINAS',
    territory3Name: 'PIÑAS',
    searchableText: 'El Oro - Pinas - Piñas',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PINAS|:|SARACAY',
    territory1Name: 'EL ORO',
    territory2Name: 'PINAS',
    territory3Name: 'SARACAY',
    searchableText: 'El Oro - Pinas - Saracay',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|PORTOVELO|:|PORTOVELO',
    territory1Name: 'EL ORO',
    territory2Name: 'PORTOVELO',
    territory3Name: 'PORTOVELO',
    searchableText: 'El Oro - Portovelo - Portovelo',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|SANTA_ROSA|:|BELLAVISTA_(EO)',
    territory1Name: 'EL ORO',
    territory2Name: 'SANTA ROSA',
    territory3Name: 'BELLAVISTA (EO)',
    searchableText: 'El Oro - Santa Rosa - Bellavista (EO)',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|SANTA_ROSA|:|JAMBELI',
    territory1Name: 'EL ORO',
    territory2Name: 'SANTA ROSA',
    territory3Name: 'JAMBELI',
    searchableText: 'El Oro - Santa Rosa - Jambeli',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|SANTA_ROSA|:|LA_AVANZADA',
    territory1Name: 'EL ORO',
    territory2Name: 'SANTA ROSA',
    territory3Name: 'LA AVANZADA',
    searchableText: 'El Oro - Santa Rosa - La Avanzada',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|SANTA_ROSA|:|NUEVO_SANTA_ROSA_(EO)',
    territory1Name: 'EL ORO',
    territory2Name: 'SANTA ROSA',
    territory3Name: 'NUEVO SANTA ROSA (EO)',
    searchableText: 'El Oro - Santa Rosa - Nuevo Santa Rosa (EO)',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|SANTA_ROSA|:|PUERTO_JELI',
    territory1Name: 'EL ORO',
    territory2Name: 'SANTA ROSA',
    territory3Name: 'PUERTO JELI',
    searchableText: 'El Oro - Santa Rosa - Puerto Jeli',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|SANTA_ROSA|:|SANTA_ROSA_(EO)',
    territory1Name: 'EL ORO',
    territory2Name: 'SANTA ROSA',
    territory3Name: 'SANTA ROSA (EO)',
    searchableText: 'El Oro - Santa Rosa - Santa Rosa (EO)',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|SHUMIRAL|:|SHUMIRAL',
    territory1Name: 'EL ORO',
    territory2Name: 'SHUMIRAL', // Unknown canton
    territory3Name: 'SHUMIRAL',
    searchableText: 'El Oro - Shumiral - Shumiral',
  },
  {
    territoryBaseId: 'EC|:|EL_ORO|:|ZARUMA|:|ZARUMA',
    territory1Name: 'EL ORO',
    territory2Name: 'ZARUMA',
    territory3Name: 'ZARUMA',
    searchableText: 'El Oro - Zaruma - Zaruma',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ATACAMES|:|ATACAMES',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'ATACAMES',
    territory3Name: 'ATACAMES',
    searchableText: 'Esmeraldas - Atacames - Atacames',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ATACAMES|:|MOMPICHE',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'ATACAMES',
    territory3Name: 'MOMPICHE',
    searchableText: 'Esmeraldas - Atacames - Mompiche',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ATACAMES|:|SAME',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'ATACAMES',
    territory3Name: 'SAME',
    searchableText: 'Esmeraldas - Atacames - Same',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ATACAMES|:|SUA',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'ATACAMES',
    territory3Name: 'SUA',
    searchableText: 'Esmeraldas - Atacames - Sua',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ATACAMES|:|TONCHIGUE',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'ATACAMES',
    territory3Name: 'TONCHIGUE',
    searchableText: 'Esmeraldas - Atacames - Tonchigue',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ATACAMES|:|TONSUPA',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'ATACAMES',
    territory3Name: 'TONSUPA',
    searchableText: 'Esmeraldas - Atacames - Tonsupa',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ELOY_ALFARO|:|BORBON',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'ELOY ALFARO',
    territory3Name: 'BORBON',
    searchableText: 'Esmeraldas - Eloy Alfaro - Borbon',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ELOY_ALFARO|:|LAS_PENAS',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'ELOY ALFARO',
    territory3Name: 'LAS PENAS',
    searchableText: 'Esmeraldas - Eloy Alfaro - Las Peñas',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ESMERALDAS|:|5_DE_AGOSTO',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'ESMERALDAS',
    territory3Name: '5 DE AGOSTO',
    searchableText: 'Esmeraldas - Esmeraldas - 5 De Agosto',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ESMERALDAS|:|BARTOLOME_RUIZ_(CESAR_FRANCO_CARRION)',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'ESMERALDAS',
    territory3Name: 'BARTOLOME RUIZ (CESAR FRANCO CARRION)',
    searchableText: 'Esmeraldas - Esmeraldas - Bartolome Ruiz (Cesar Franco Carrion)',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ESMERALDAS|:|ESMERALDAS',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'ESMERALDAS',
    territory3Name: 'ESMERALDAS',
    searchableText: 'Esmeraldas - Esmeraldas - Esmeraldas',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ESMERALDAS|:|JUDICIALES',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'ESMERALDAS',
    territory3Name: 'JUDICIALES',
    searchableText: 'Esmeraldas - Esmeraldas - Judiciales',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ESMERALDAS|:|LUIS_TELLO__(LAS_PALMAS)',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'ESMERALDAS',
    territory3Name: 'LUIS TELLO  (LAS PALMAS)',
    searchableText: 'Esmeraldas - Esmeraldas - Luis Tello  (Las Palmas)',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ESMERALDAS|:|SAN_MATEO',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'ESMERALDAS',
    territory3Name: 'SAN MATEO',
    searchableText: 'Esmeraldas - Esmeraldas - San Mateo',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ESMERALDAS|:|SIMON_PLATA_TORRES',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'ESMERALDAS',
    territory3Name: 'SIMON PLATA TORRES',
    searchableText: 'Esmeraldas - Esmeraldas - Simon Plata Torres',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ESMERALDAS|:|TACHINA',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'ESMERALDAS',
    territory3Name: 'TACHINA',
    searchableText: 'Esmeraldas - Esmeraldas - Tachina',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|ESMERALDAS|:|VUELTA_LARGA',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'ESMERALDAS',
    territory3Name: 'VUELTA LARGA',
    searchableText: 'Esmeraldas - Esmeraldas - Vuelta Larga',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|MUISNE|:|MUISNE',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'MUISNE',
    territory3Name: 'MUISNE',
    searchableText: 'Esmeraldas - Muisne - Muisne',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|QUININDE|:|LA_INDEPENDENCIA',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'QUININDE',
    territory3Name: 'LA INDEPENDENCIA',
    searchableText: 'Esmeraldas - Quininde - La Independencia',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|QUININDE|:|QUININDE',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'QUININDE',
    territory3Name: 'QUININDE',
    searchableText: 'Esmeraldas - Quininde - Quininde',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|QUININDE|:|ROSA_ZARATE',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'QUININDE',
    territory3Name: 'ROSA ZARATE',
    searchableText: 'Esmeraldas - Quininde - Rosa Zarate',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|QUININDE|:|VICHE',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'QUININDE',
    territory3Name: 'VICHE',
    searchableText: 'Esmeraldas - Quininde - Viche',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|RIO_VERDE|:|LAGARTO',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'RIO VERDE',
    territory3Name: 'LAGARTO',
    searchableText: 'Esmeraldas - Rio Verde - Lagarto',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|RIO_VERDE|:|MONTALVO_(CAB_EN_HORQUETA)',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'RIO VERDE',
    territory3Name: 'MONTALVO (CAB EN HORQUETA)',
    searchableText: 'Esmeraldas - Rio Verde - Montalvo (Cab En Horqueta)',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|RIO_VERDE|:|RIO_VERDE_(ES)',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'RIO VERDE',
    territory3Name: 'RIO VERDE (ES)',
    searchableText: 'Esmeraldas - Rio Verde - Rio Verde (ES)',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|RIO_VERDE|:|ROCAFUERTE',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'RIO VERDE',
    territory3Name: 'ROCAFUERTE',
    searchableText: 'Esmeraldas - Rio Verde - Rocafuerte',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|SAN_LORENZO|:|LA_Y_DE_CALDERON',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'SAN LORENZO',
    territory3Name: 'LA Y DE CALDERON',
    searchableText: 'Esmeraldas - San Lorenzo - La Y De Calderon',
  },
  {
    territoryBaseId: 'EC|:|ESMERALDAS|:|SAN_LORENZO|:|SAN_LORENZO',
    territory1Name: 'ESMERALDAS',
    territory2Name: 'SAN LORENZO',
    territory3Name: 'SAN LORENZO',
    searchableText: 'Esmeraldas - San Lorenzo - San Lorenzo',
  },
  {
    territoryBaseId: 'EC|:|GALAPAGOS|:|ISABELA|:|ISABELA',
    territory1Name: 'GALAPAGOS',
    territory2Name: 'ISABELA',
    territory3Name: 'ISABELA',
    searchableText: 'Galapagos - Isabela - Isabela',
  },
  {
    territoryBaseId: 'EC|:|GALAPAGOS|:|SAN_CRISTOBAL_(PTO._BAQ._MORENO)|:|EL_PROGRESO_(GA)',
    territory1Name: 'GALAPAGOS',
    territory2Name: 'SAN CRISTOBAL (PTO. BAQ. MORENO)',
    territory3Name: 'EL PROGRESO (GA)',
    searchableText: 'Galapagos - San Cristobal (Pto. Baq. Moreno) - El Progreso (GA)',
  },
  {
    territoryBaseId: 'EC|:|GALAPAGOS|:|SAN_CRISTOBAL_(PTO._BAQ._MORENO)|:|PUERTO_BAQUERIZO_MORENO',
    territory1Name: 'GALAPAGOS',
    territory2Name: 'SAN CRISTOBAL (PTO. BAQ. MORENO)',
    territory3Name: 'PUERTO BAQUERIZO MORENO',
    searchableText: 'Galapagos - San Cristobal (Pto. Baq. Moreno) - Puerto Baquerizo Moreno',
  },
  {
    territoryBaseId: 'EC|:|GALAPAGOS|:|SANTA_CRUZ_(PTO._AYORA)|:|PUERTO_AYORA',
    territory1Name: 'GALAPAGOS',
    territory2Name: 'SANTA CRUZ (PTO. AYORA)',
    territory3Name: 'PUERTO AYORA',
    searchableText: 'Galapagos - Santa Cruz (Pto. Ayora) - Puerto Ayora',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|ALFREDO_BAQUERIZO_MORENO|:|ALFREDO_BAQUERIZO_MORENO',
    territory1Name: 'GUAYAS',
    territory2Name: 'ALFREDO BAQUERIZO MORENO',
    territory3Name: 'ALFREDO BAQUERIZO MORENO',
    searchableText: 'Guayas - Alfredo Baquerizo Moreno - Alfredo Baquerizo Moreno',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|BALAO|:|BALAO',
    territory1Name: 'GUAYAS',
    territory2Name: 'BALAO',
    territory3Name: 'BALAO',
    searchableText: 'Guayas - Balao - Balao',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|BALZAR|:|BALZAR',
    territory1Name: 'GUAYAS',
    territory2Name: 'BALZAR',
    territory3Name: 'BALZAR',
    searchableText: 'Guayas - Balzar - Balzar',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|BALZAR|:|LA_MARAVILLA',
    territory1Name: 'GUAYAS',
    territory2Name: 'BALZAR',
    territory3Name: 'LA MARAVILLA',
    searchableText: 'Guayas - Balzar - La Maravilla',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|BUCAY|:|BUCAY',
    territory1Name: 'GUAYAS',
    territory2Name: 'BUCAY',
    territory3Name: 'BUCAY',
    searchableText: 'Guayas - Bucay - Bucay',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|COLIMES|:|COLIMES',
    territory1Name: 'GUAYAS',
    territory2Name: 'COLIMES',
    territory3Name: 'COLIMES',
    searchableText: 'Guayas - Colimes - Colimes',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|CORONEL_MARCELINO_MARIDUENA|:|INGENIO_SAN_CARLOS',
    territory1Name: 'GUAYAS',
    territory2Name: 'CORONEL MARCELINO MARIDUENA',
    territory3Name: 'INGENIO SAN CARLOS',
    searchableText: 'Guayas - Coronel Marcelino Mariduena - Ingenio San Carlos',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|CORONEL_MARCELINO_MARIDUENA|:|MARCELINO_MARIDUEÑAS',
    territory1Name: 'GUAYAS',
    territory2Name: 'CORONEL MARCELINO MARIDUENA',
    territory3Name: 'MARCELINO MARIDUEÑAS',
    searchableText: 'Guayas - Coronel Marcelino Mariduena - Marcelino Maridueñas',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DAULE|:|DAULE',
    territory1Name: 'GUAYAS',
    territory2Name: 'DAULE',
    territory3Name: 'DAULE',
    searchableText: 'Guayas - Daule - Daule',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DAULE|:|LA_AURORA',
    territory1Name: 'GUAYAS',
    territory2Name: 'DAULE',
    territory3Name: 'LA AURORA',
    searchableText: 'Guayas - Daule - La Aurora',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DAULE|:|LAS_ANIMAS',
    territory1Name: 'GUAYAS',
    territory2Name: 'DAULE',
    territory3Name: 'LAS ANIMAS',
    searchableText: 'Guayas - Daule - Las Animas',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DAULE|:|LAUREL',
    territory1Name: 'GUAYAS',
    territory2Name: 'DAULE',
    territory3Name: 'LAUREL',
    searchableText: 'Guayas - Daule - Laurel',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DAULE|:|LIMONAL',
    territory1Name: 'GUAYAS',
    territory2Name: 'DAULE',
    territory3Name: 'LIMONAL',
    searchableText: 'Guayas - Daule - Limonal',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DAULE|:|LOS_TINTOS',
    territory1Name: 'GUAYAS',
    territory2Name: 'DAULE',
    territory3Name: 'LOS TINTOS',
    searchableText: 'Guayas - Daule - Los Tintos',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DAULE|:|MATILDE_ESTHER',
    territory1Name: 'GUAYAS',
    territory2Name: 'DAULE',
    territory3Name: 'MATILDE ESTHER',
    searchableText: 'Guayas - Daule - Matilde Esther',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DAULE|:|PETRILLO',
    territory1Name: 'GUAYAS',
    territory2Name: 'DAULE',
    territory3Name: 'PETRILLO',
    searchableText: 'Guayas - Daule - Petrillo',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DAULE|:|PUENTE_LUCIA',
    territory1Name: 'GUAYAS',
    territory2Name: 'DAULE',
    territory3Name: 'PUENTE LUCIA',
    searchableText: 'Guayas - Daule - Puente Lucia',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DURAN|:|DURAN_(GY)',
    territory1Name: 'GUAYAS',
    territory2Name: 'DURAN',
    territory3Name: 'DURAN (GY)',
    searchableText: 'Guayas - Duran - Duran (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DURAN|:|EL_RECREO_(GY)',
    territory1Name: 'GUAYAS',
    territory2Name: 'DURAN',
    territory3Name: 'EL RECREO (GY)',
    searchableText: 'Guayas - Duran - El Recreo (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|DURAN|:|ELOY_ALFARO',
    territory1Name: 'GUAYAS',
    territory2Name: 'DURAN',
    territory3Name: 'ELOY ALFARO',
    searchableText: 'Guayas - Duran - Eloy Alfaro',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|EL_EMPALME|:|EL_EMPALME',
    territory1Name: 'GUAYAS',
    territory2Name: 'EL EMPALME',
    territory3Name: 'EL EMPALME',
    searchableText: 'Guayas - El Empalme - El Empalme',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|EL_EMPALME|:|EL_ROSARIO',
    territory1Name: 'GUAYAS',
    territory2Name: 'EL EMPALME',
    territory3Name: 'EL ROSARIO',
    searchableText: 'Guayas - El Empalme - El Rosario',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|EL_EMPALME|:|GUAYAS_(PUEBLO_NUEVO)',
    territory1Name: 'GUAYAS',
    territory2Name: 'EL EMPALME',
    territory3Name: 'GUAYAS (PUEBLO NUEVO)',
    searchableText: 'Guayas - El Empalme - Guayas (Pueblo Nuevo)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|EL_TRIUNFO|:|EL_TRIUNFO',
    territory1Name: 'GUAYAS',
    territory2Name: 'EL TRIUNFO',
    territory3Name: 'EL TRIUNFO',
    searchableText: 'Guayas - El Triunfo - El Triunfo',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|EL_TRIUNFO|:|MANUEL_J_CALLE',
    territory1Name: 'GUAYAS',
    territory2Name: 'EL TRIUNFO',
    territory3Name: 'MANUEL J CALLE',
    searchableText: 'Guayas - El Triunfo - Manuel J Calle',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GENERAL_ANTONIO_ELIZALDE|:|GENERAL_ANTONIO_ELIZALDE',
    territory1Name: 'GUAYAS',
    territory2Name: 'GENERAL ANTONIO ELIZALDE',
    territory3Name: 'GENERAL ANTONIO ELIZALDE',
    searchableText: 'Guayas - General Antonio Elizalde - General Antonio Elizalde',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|AYACUCHO_(GY)',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'AYACUCHO (GY)',
    searchableText: 'Guayas - Guayaquil - Ayacucho (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|BOLIVAR_(SAGRARIO)_(GY)',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'BOLIVAR (SAGRARIO) (GY)',
    searchableText: 'Guayas - Guayaquil - Bolivar (sagrario) (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|CARBO_(CONCEPCION)_(GY)',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'CARBO (CONCEPCION) (GY)',
    searchableText: 'Guayas - Guayaquil - Carbo (Concepcion) (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|CERECITA',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'CERECITA',
    searchableText: 'Guayas - Guayaquil - Cerecita',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|CHIVERIA',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'CHIVERIA',
    searchableText: 'Guayas - Guayaquil - Chiveria',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|CHONGON',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'CHONGON',
    searchableText: 'Guayas - Guayaquil - Chongon',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|FEBRES_CORDERO',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'FEBRES CORDERO',
    searchableText: 'Guayas - Guayaquil - Febres Cordero',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|GARCIA_MORENO',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'GARCIA MORENO',
    searchableText: 'Guayas - Guayaquil - Garcia Moreno',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|GUAYAQUIL',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'GUAYAQUIL',
    searchableText: 'Guayas - Guayaquil - Guayaquil',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|LA_TOMA',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'LA TOMA',
    searchableText: 'Guayas - Guayaquil - La Toma',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|LETAMENDI',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'LETAMENDI',
    searchableText: 'Guayas - Guayaquil - Letamendi',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|MORRO',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'MORRO',
    searchableText: 'Guayas - Guayaquil - Morro',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|NUEVE_DE_OCTUBRE',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'NUEVE DE OCTUBRE',
    searchableText: 'Guayas - Guayaquil - Nueve De Octubre',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|OLMEDO_(SAN_ALEJO)_(GY)',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'OLMEDO (SAN ALEJO) (GY)',
    searchableText: 'Guayas - Guayaquil - Olmedo (san Alejo) (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|PASCUALES_(HASTA_KM_16)',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'PASCUALES (HASTA KM 16)',
    searchableText: 'Guayas - Guayaquil - Pascuales (Hasta Km 16)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|POSORJA',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'POSORJA',
    searchableText: 'Guayas - Guayaquil - Posorja',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|PROGRESO',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'PROGRESO',
    searchableText: 'Guayas - Guayaquil - Progreso',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|ROCA_(GY)',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'ROCA (GY)',
    searchableText: 'Guayas - Guayaquil - Roca (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|ROCAFUERTE_(GY)',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'ROCAFUERTE (GY)',
    searchableText: 'Guayas - Guayaquil - Rocafuerte (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|SAN_ISIDRO',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'SAN ISIDRO',
    searchableText: 'Guayas - Guayaquil - San Isidro',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|SAN_LORENZO',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'SAN LORENZO',
    searchableText: 'Guayas - Guayaquil - San Lorenzo',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|SUCRE_(GY)',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'SUCRE (GY)',
    searchableText: 'Guayas - Guayaquil - Sucre (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|TARQUI_(GY)',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'TARQUI (GY)',
    searchableText: 'Guayas - Guayaquil - Tarqui (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|TENGUEL',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'TENGUEL',
    searchableText: 'Guayas - Guayaquil - Tenguel',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|URDANETA_(GY)',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'URDANETA (GY)',
    searchableText: 'Guayas - Guayaquil - Urdaneta (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|XIMENA',
    territory1Name: 'GUAYAS',
    territory2Name: 'GUAYAQUIL',
    territory3Name: 'XIMENA',
    searchableText: 'Guayas - Guayaquil - Ximena',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|ISIDRO_AYORA|:|ISIDRO_AYORA_(GY)',
    territory1Name: 'GUAYAS',
    territory2Name: 'ISIDRO AYORA',
    territory3Name: 'ISIDRO AYORA (GY)',
    searchableText: 'Guayas - Isidro Ayora - Isidro Ayora (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|LOMAS_DE_SARGENTILLO|:|LOMAS_DE_SARGENTILLO',
    territory1Name: 'GUAYAS',
    territory2Name: 'LOMAS DE SARGENTILLO',
    territory3Name: 'LOMAS DE SARGENTILLO',
    searchableText: 'Guayas - Lomas De Sargentillo - Lomas De Sargentillo',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|5_DE_JUNIO',
    territory1Name: 'GUAYAS',
    territory2Name: 'MILAGRO',
    territory3Name: '5 DE JUNIO',
    searchableText: 'Guayas - Milagro - 5 De Junio',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|BOLICHE',
    territory1Name: 'GUAYAS',
    territory2Name: 'MILAGRO',
    territory3Name: 'BOLICHE',
    searchableText: 'Guayas - Milagro - Boliche',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|CAMILO_ANDRADE',
    territory1Name: 'GUAYAS',
    territory2Name: 'MILAGRO',
    territory3Name: 'CAMILO ANDRADE',
    searchableText: 'Guayas - Milagro - Camilo Andrade',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|CHIRIJOS',
    territory1Name: 'GUAYAS',
    territory2Name: 'MILAGRO',
    territory3Name: 'CHIRIJOS',
    searchableText: 'Guayas - Milagro - Chirijos',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|CHOBO',
    territory1Name: 'GUAYAS',
    territory2Name: 'MILAGRO',
    territory3Name: 'CHOBO',
    searchableText: 'Guayas - Milagro - Chobo',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|CORONEL_ENRIQUE_VALDEZ',
    territory1Name: 'GUAYAS',
    territory2Name: 'MILAGRO',
    territory3Name: 'CORONEL ENRIQUE VALDEZ',
    searchableText: 'Guayas - Milagro - Coronel Enrique Valdez',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|ELOY_ALFARO',
    territory1Name: 'GUAYAS',
    territory2Name: 'MILAGRO',
    territory3Name: 'ELOY ALFARO',
    searchableText: 'Guayas - Milagro - Eloy Alfaro',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|ERNESTO_SEMINARIO',
    territory1Name: 'GUAYAS',
    territory2Name: 'MILAGRO',
    territory3Name: 'ERNESTO SEMINARIO',
    searchableText: 'Guayas - Milagro - Ernesto Seminario',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|JOSE_MARIA_VELASCO_IBARRA',
    territory1Name: 'GUAYAS',
    territory2Name: 'MILAGRO',
    territory3Name: 'JOSE MARIA VELASCO IBARRA',
    searchableText: 'Guayas - Milagro - Jose Maria Velasco Ibarra',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|LAS_PINAS',
    territory1Name: 'GUAYAS',
    territory2Name: 'MILAGRO',
    territory3Name: 'LAS PINAS',
    searchableText: 'Guayas - Milagro - Las Pinas',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|MARISCAL_SUCRE_(GY)',
    territory1Name: 'GUAYAS',
    territory2Name: 'MILAGRO',
    territory3Name: 'MARISCAL SUCRE (GY)',
    searchableText: 'Guayas - Milagro - Mariscal Sucre (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|MILAGRO',
    territory1Name: 'GUAYAS',
    territory2Name: 'MILAGRO',
    territory3Name: 'MILAGRO',
    searchableText: 'Guayas - Milagro - Milagro',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|ROBERTO_ASTUDILLO',
    territory1Name: 'GUAYAS',
    territory2Name: 'MILAGRO',
    territory3Name: 'ROBERTO ASTUDILLO',
    searchableText: 'Guayas - Milagro - Roberto Astudillo',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|ROSA_MARIA',
    territory1Name: 'GUAYAS',
    territory2Name: 'MILAGRO',
    territory3Name: 'ROSA MARIA',
    searchableText: 'Guayas - Milagro - Rosa Maria',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|MILAGRO|:|VICENTE_ROCAFUERTE',
    territory1Name: 'GUAYAS',
    territory2Name: 'MILAGRO',
    territory3Name: 'VICENTE ROCAFUERTE',
    searchableText: 'Guayas - Milagro - Vicente Rocafuerte',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|NARANJAL|:|BASE_TAURA',
    territory1Name: 'GUAYAS',
    territory2Name: 'NARANJAL',
    territory3Name: 'BASE TAURA',
    searchableText: 'Guayas - Naranjal - Base Taura',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|NARANJAL|:|CHURUTE',
    territory1Name: 'GUAYAS',
    territory2Name: 'NARANJAL',
    territory3Name: 'CHURUTE',
    searchableText: 'Guayas - Naranjal - Churute',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|NARANJAL|:|JESUS_MARIA',
    territory1Name: 'GUAYAS',
    territory2Name: 'NARANJAL',
    territory3Name: 'JESUS MARIA',
    searchableText: 'Guayas - Naranjal - Jesus Maria',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|NARANJAL|:|NARANJAL',
    territory1Name: 'GUAYAS',
    territory2Name: 'NARANJAL',
    territory3Name: 'NARANJAL',
    searchableText: 'Guayas - Naranjal - Naranjal',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|NARANJAL|:|PUERTO_INCA',
    territory1Name: 'GUAYAS',
    territory2Name: 'NARANJAL',
    territory3Name: 'PUERTO INCA',
    searchableText: 'Guayas - Naranjal - Puerto Inca',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|NARANJAL|:|TAURA',
    territory1Name: 'GUAYAS',
    territory2Name: 'NARANJAL',
    territory3Name: 'TAURA',
    searchableText: 'Guayas - Naranjal - Taura',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|NARANJAL|:|VILLA_NUEVA',
    territory1Name: 'GUAYAS',
    territory2Name: 'NARANJAL',
    territory3Name: 'VILLA NUEVA',
    searchableText: 'Guayas - Naranjal - Villa Nueva',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|NARANJITO|:|NARANJITO',
    territory1Name: 'GUAYAS',
    territory2Name: 'NARANJITO',
    territory3Name: 'NARANJITO',
    searchableText: 'Guayas - Naranjito - Naranjito',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|NOBOL|:|NOBOL',
    territory1Name: 'GUAYAS',
    territory2Name: 'NOBOL',
    territory3Name: 'NOBOL',
    searchableText: 'Guayas - Nobol - Nobol',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PALESTINA|:|COLORADAL',
    territory1Name: 'GUAYAS',
    territory2Name: 'PALESTINA',
    territory3Name: 'COLORADAL',
    searchableText: 'Guayas - Palestina - Coloradal',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PALESTINA|:|PALESTINA',
    territory1Name: 'GUAYAS',
    territory2Name: 'PALESTINA',
    territory3Name: 'PALESTINA',
    searchableText: 'Guayas - Palestina - Palestina',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PEDRO_CARBO|:|PEDRO_CARBO',
    territory1Name: 'GUAYAS',
    territory2Name: 'PEDRO CARBO',
    territory3Name: 'PEDRO CARBO',
    searchableText: 'Guayas - Pedro Carbo - Pedro Carbo',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PEDRO_CARBO|:|SABANILLA',
    territory1Name: 'GUAYAS',
    territory2Name: 'PEDRO CARBO',
    territory3Name: 'SABANILLA',
    searchableText: 'Guayas - Pedro Carbo - Sabanilla',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PLAYAS|:|DATA_DE_PLAYAS',
    territory1Name: 'GUAYAS',
    territory2Name: 'PLAYAS',
    territory3Name: 'DATA DE PLAYAS',
    searchableText: 'Guayas - Playas - Data De Playas',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PLAYAS|:|ENGABAO',
    territory1Name: 'GUAYAS',
    territory2Name: 'PLAYAS',
    territory3Name: 'ENGABAO',
    searchableText: 'Guayas - Playas - Engabao',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PLAYAS|:|PLAYAS',
    territory1Name: 'GUAYAS',
    territory2Name: 'PLAYAS',
    territory3Name: 'PLAYAS',
    searchableText: 'Guayas - Playas - Playas',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PLAYAS|:|PUERTO_DEL_ENGABAO',
    territory1Name: 'GUAYAS',
    territory2Name: 'PLAYAS',
    territory3Name: 'PUERTO DEL ENGABAO',
    searchableText: 'Guayas - Playas - Puerto Del Engabao',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PLAYAS|:|SAN_ANTONIO',
    territory1Name: 'GUAYAS',
    territory2Name: 'PLAYAS',
    territory3Name: 'SAN ANTONIO',
    searchableText: 'Guayas - Playas - San Antonio',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|PTO_BAQUERIZO_MORENO_(JUJAN)|:|JUJAN',
    territory1Name: 'GUAYAS',
    territory2Name: 'PTO BAQUERIZO MORENO (JUJAN)',
    territory3Name: 'JUJAN',
    searchableText: 'Guayas - Pto Baquerizo Moreno (Jujan) - Jujan',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SALITRE_(URBINA_JADO)|:|GENERAL_VERNAZA',
    territory1Name: 'GUAYAS',
    territory2Name: 'SALITRE (URBINA JADO)',
    territory3Name: 'GENERAL VERNAZA',
    searchableText: 'Guayas - Salitre (Urbina Jado) - General Vernaza',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SALITRE_(URBINA_JADO)|:|JUNQUILLAL',
    territory1Name: 'GUAYAS',
    territory2Name: 'SALITRE (URBINA JADO)',
    territory3Name: 'JUNQUILLAL',
    searchableText: 'Guayas - Salitre (Urbina Jado) - Junquillal',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SALITRE_(URBINA_JADO)|:|LA_T_DE_SALITRE',
    territory1Name: 'GUAYAS',
    territory2Name: 'SALITRE (URBINA JADO)',
    territory3Name: 'LA T DE SALITRE',
    searchableText: 'Guayas - Salitre (Urbina Jado) - La T De Salitre',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SALITRE_(URBINA_JADO)|:|LA_VICTORIA',
    territory1Name: 'GUAYAS',
    territory2Name: 'SALITRE (URBINA JADO)',
    territory3Name: 'LA VICTORIA',
    searchableText: 'Guayas - Salitre (Urbina Jado) - La Victoria',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SALITRE_(URBINA_JADO)|:|SALITRE',
    territory1Name: 'GUAYAS',
    territory2Name: 'SALITRE (URBINA JADO)',
    territory3Name: 'SALITRE',
    searchableText: 'Guayas - Salitre (Urbina Jado) - Salitre',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SAMBORONDON|:|BOCA_DE_CANA',
    territory1Name: 'GUAYAS',
    territory2Name: 'SAMBORONDON',
    territory3Name: 'BOCA DE CANA',
    searchableText: 'Guayas - Samborondon - Boca De Caña',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SAMBORONDON|:|LA_PUNTILLA_(EL_DORADO)',
    territory1Name: 'GUAYAS',
    territory2Name: 'SAMBORONDON',
    territory3Name: 'LA PUNTILLA (EL DORADO)',
    searchableText: 'Guayas - Samborondon - La Puntilla (El Dorado)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SAMBORONDON|:|SAMBORONDON',
    territory1Name: 'GUAYAS',
    territory2Name: 'SAMBORONDON',
    territory3Name: 'SAMBORONDON',
    searchableText: 'Guayas - Samborondon - Samborondon',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SAMBORONDON|:|TARIFA',
    territory1Name: 'GUAYAS',
    territory2Name: 'SAMBORONDON',
    territory3Name: 'TARIFA',
    searchableText: 'Guayas - Samborondon - Tarifa',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SAN_JACINTO_DE_YAGUACHI|:|KM_26',
    territory1Name: 'GUAYAS',
    territory2Name: 'KM 26',
    territory3Name: 'KM 26',
    searchableText: 'Guayas - San Jacinto De Yaguachi - Km 26',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SAN_JACINTO_DE_YAGUACHI|:|SAN JACINTO DE YAGUACHI',
    territory1Name: 'GUAYAS',
    territory2Name: 'SAN JACINTO DE YAGUACHI',
    territory3Name: 'SAN JACINTO DE YAGUACHI',
    searchableText: 'Guayas - San Jacinto De Yaguachi - San Jacinto De Yaguachi',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SAN_JACINTO_DE_YAGUACHI|:|TRES_POSTES',
    territory1Name: 'GUAYAS',
    territory2Name: 'SAN JACINTO DE YAGUACHI',
    territory3Name: '3 POSTES',
    searchableText: 'Guayas - San Jacinto De Yaguachi - Tres Postes',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SAN_JACINTO_DE_YAGUACHI|:|VIRGEN_DE_FATIMA_(KM_26)_(GY)',
    territory1Name: 'GUAYAS',
    territory2Name: 'SAN JACINTO DE YAGUACHI',
    territory3Name: 'VIRGEN DE FATIMA (KM 26) (GY)',
    searchableText: 'Guayas - San Jacinto De Yaguachi - Virgen De Fatima (Km 26) (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SAN_JACINTO_DE_YAGUACHI|:|YAGUACHI_(GY)',
    territory1Name: 'GUAYAS',
    territory2Name: 'SAN JACINTO DE YAGUACHI',
    territory3Name: 'YAGUACHI (GY)',
    searchableText: 'Guayas - San Jacinto De Yaguachi - Yaguachi (GY)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SAN_JACINTO_DE_YAGUACHI|:|YAGUACHI_VIEJO_(EL_DESEO)',
    territory1Name: 'GUAYAS',
    territory2Name: 'SAN JACINTO DE YAGUACHI',
    territory3Name: 'YAGUACHI VIEJO (EL DESEO)',
    searchableText: 'Guayas - San Jacinto De Yaguachi - Yaguachi Viejo (El Deseo)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SANTA_LUCIA|:|SANTA_LUCIA',
    territory1Name: 'GUAYAS',
    territory2Name: 'SANTA LUCIA',
    territory3Name: 'SANTA LUCIA',
    searchableText: 'Guayas - Santa Lucia - Santa Lucia',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SIMON_BOLIVAR|:|LORENZO_DE_GARAICOA_(PEDREGAL)',
    territory1Name: 'GUAYAS',
    territory2Name: 'SIMON BOLIVAR',
    territory3Name: 'LORENZO DE GARAICOA (PEDREGAL)',
    searchableText: 'Guayas - Simon Bolivar - Lorenzo De Garaicoa (Pedregal)',
  },
  {
    territoryBaseId: 'EC|:|GUAYAS|:|SIMON_BOLIVAR|:|SIMON_BOLIVAR_(LR)',
    territory1Name: 'GUAYAS',
    territory2Name: 'SIMON BOLIVAR',
    territory3Name: 'SIMON BOLIVAR (LR)',
    searchableText: 'Guayas - Simon Bolivar - Simon Bolivar (LR)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|ANTONIO_ANTE|:|ANDRADE_MARIN',
    territory1Name: 'IMBABURA',
    territory2Name: 'ANTONIO ANTE',
    territory3Name: 'ANDRADE MARIN',
    searchableText: 'Imbabura - Antonio Ante - Andrade Marin',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|ANTONIO_ANTE|:|ATUNTAQUI',
    territory1Name: 'IMBABURA',
    territory2Name: 'ANTONIO ANTE',
    territory3Name: 'ATUNTAQUI',
    searchableText: 'Imbabura - Antonio Ante - Atuntaqui',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|ANTONIO_ANTE|:|CHALTURA_(IM)',
    territory1Name: 'IMBABURA',
    territory2Name: 'ANTONIO ANTE',
    territory3Name: 'CHALTURA (IM)',
    searchableText: 'Imbabura - Antonio Ante - Chaltura (IM)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|ANTONIO_ANTE|:|IMBAYA',
    territory1Name: 'IMBABURA',
    territory2Name: 'ANTONIO ANTE',
    territory3Name: 'IMBAYA',
    searchableText: 'Imbabura - Antonio Ante - Imbaya',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|ANTONIO_ANTE|:|NATABUELA_(IM)',
    territory1Name: 'IMBABURA',
    territory2Name: 'ANTONIO ANTE',
    territory3Name: 'NATABUELA (IM)',
    searchableText: 'Imbabura - Antonio Ante - Natabuela (IM)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|ANTONIO_ANTE|:|SAN_ROQUE',
    territory1Name: 'IMBABURA',
    territory2Name: 'ANTONIO ANTE',
    territory3Name: 'SAN ROQUE',
    searchableText: 'Imbabura - Antonio Ante - San Roque',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|COTACACHI|:|COTACACHI',
    territory1Name: 'IMBABURA',
    territory2Name: 'COTACACHI',
    territory3Name: 'COTACACHI',
    searchableText: 'Imbabura - Cotacachi - Cotacachi',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|COTACACHI|:|QUIROGA',
    territory1Name: 'IMBABURA',
    territory2Name: 'COTACACHI',
    territory3Name: 'QUIROGA',
    searchableText: 'Imbabura - Cotacachi - Quiroga',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|COTACACHI|:|SAGRARIO_(IM)',
    territory1Name: 'IMBABURA',
    territory2Name: 'COTACACHI',
    territory3Name: 'SAGRARIO (IM)',
    searchableText: 'Imbabura - Cotacachi - Sagrario (IM)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|COTACACHI|:|SAN_FRANCISCO',
    territory1Name: 'IMBABURA',
    territory2Name: 'COTACACHI',
    territory3Name: 'SAN FRANCISCO',
    searchableText: 'Imbabura - Cotacachi - San Francisco',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|ADUANA',
    territory1Name: 'IMBABURA',
    territory2Name: 'IBARRA',
    territory3Name: 'ADUANA',
    searchableText: 'Imbabura - Ibarra - Aduana',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|CARANQUI',
    territory1Name: 'IMBABURA',
    territory2Name: 'IBARRA',
    territory3Name: 'CARANQUI',
    searchableText: 'Imbabura - Ibarra - Caranqui',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|CHORLAVI',
    territory1Name: 'IMBABURA',
    territory2Name: 'IBARRA',
    territory3Name: 'CHORLAVI',
    searchableText: 'Imbabura - Ibarra - Chorlavi',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|EL_OLIVO',
    territory1Name: 'IMBABURA',
    territory2Name: 'IBARRA',
    territory3Name: 'EL OLIVO',
    searchableText: 'Imbabura - Ibarra - El Olivo',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|EL_RETORNO',
    territory1Name: 'IMBABURA',
    territory2Name: 'IBARRA',
    territory3Name: 'EL RETORNO',
    searchableText: 'Imbabura - Ibarra - El Retorno',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|GUAYAQUIL_DE_ALPACHACA',
    territory1Name: 'IMBABURA',
    territory2Name: 'IBARRA',
    territory3Name: 'GUAYAQUIL DE ALPACHACA',
    searchableText: 'Imbabura - Ibarra - Guayaquil De Alpachaca',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|LA_DOLOROSA',
    territory1Name: 'IMBABURA',
    territory2Name: 'IBARRA',
    territory3Name: 'LA DOLOROSA',
    searchableText: 'Imbabura - Ibarra - La Dolorosa',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|LA_ESPERANZA_(IM)',
    territory1Name: 'IMBABURA',
    territory2Name: 'IBARRA',
    territory3Name: 'LA ESPERANZA (IM)',
    searchableText: 'Imbabura - Ibarra - La Esperanza (IM)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|PRIORATO',
    territory1Name: 'IMBABURA',
    territory2Name: 'IBARRA',
    territory3Name: 'PRIORATO',
    searchableText: 'Imbabura - Ibarra - Priorato',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|SAGRARIO_(IM)',
    territory1Name: 'IMBABURA',
    territory2Name: 'IBARRA',
    territory3Name: 'SAGRARIO (IM)',
    searchableText: 'Imbabura - Ibarra - Sagrario (IM)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|SAN_ANTONIO_(IM)',
    territory1Name: 'IMBABURA',
    territory2Name: 'IBARRA',
    territory3Name: 'SAN ANTONIO (IM)',
    searchableText: 'Imbabura - Ibarra - San Antonio (IM)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|SAN_FRANCISCO_(IM)',
    territory1Name: 'IMBABURA',
    territory2Name: 'IBARRA',
    territory3Name: 'SAN FRANCISCO (IM)',
    searchableText: 'Imbabura - Ibarra - San Francisco (IM)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|SAN_MIGUEL_DE_IBARRA',
    territory1Name: 'IMBABURA',
    territory2Name: 'IBARRA',
    territory3Name: 'SAN MIGUEL DE IBARRA',
    searchableText: 'Imbabura - Ibarra - San Miguel De Ibarra',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|TABABUELA',
    territory1Name: 'IMBABURA',
    territory2Name: 'IBARRA',
    territory3Name: 'TABABUELA',
    searchableText: 'Imbabura - Ibarra - Tababuela',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|IBARRA|:|YAGUARCOCHA',
    territory1Name: 'IMBABURA',
    territory2Name: 'IBARRA',
    territory3Name: 'YAGUARCOCHA',
    searchableText: 'Imbabura - Ibarra - Yaguarcocha',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|DOCTOR_MIGUEL_EGAS_CABEZAS_(PEGUCHE)',
    territory1Name: 'IMBABURA',
    territory2Name: 'OTAVALO',
    territory3Name: 'DOCTOR MIGUEL EGAS CABEZAS (PEGUCHE)',
    searchableText: 'Imbabura - Otavalo - Doctor Miguel Egas Cabezas (Peguche)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|EUGENIO_ESPEJO',
    territory1Name: 'IMBABURA',
    territory2Name: 'OTAVALO',
    territory3Name: 'EUGENIO ESPEJO',
    searchableText: 'Imbabura - Otavalo - Eugenio Espejo',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|GONZALEZ_SUAREZ_(IM)',
    territory1Name: 'IMBABURA',
    territory2Name: 'OTAVALO',
    territory3Name: 'GONZALEZ SUAREZ (IM)',
    searchableText: 'Imbabura - Otavalo - Gonzalez Suarez (IM)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|ILUMAN',
    territory1Name: 'IMBABURA',
    territory2Name: 'OTAVALO',
    territory3Name: 'ILUMAN',
    searchableText: 'Imbabura - Otavalo - Iluman',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|JORDAN',
    territory1Name: 'IMBABURA',
    territory2Name: 'OTAVALO',
    territory3Name: 'JORDAN',
    searchableText: 'Imbabura - Otavalo - Jordan',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|OTAVALO',
    territory1Name: 'IMBABURA',
    territory2Name: 'OTAVALO',
    territory3Name: 'OTAVALO',
    searchableText: 'Imbabura - Otavalo - Otavalo',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|PEGUCHE',
    territory1Name: 'IMBABURA',
    territory2Name: 'OTAVALO',
    territory3Name: 'PEGUCHE',
    searchableText: 'Imbabura - Otavalo - Peguche',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|PERUGACHI',
    territory1Name: 'IMBABURA',
    territory2Name: 'OTAVALO',
    territory3Name: 'PERUGACHI',
    searchableText: 'Imbabura - Otavalo - Perugachi',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|PINSAQUI',
    territory1Name: 'IMBABURA',
    territory2Name: 'OTAVALO',
    territory3Name: 'PINSAQUI',
    searchableText: 'Imbabura - Otavalo - Pinsaqui',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|PUERTO_LAGO',
    territory1Name: 'IMBABURA',
    territory2Name: 'OTAVALO',
    territory3Name: 'PUERTO LAGO',
    searchableText: 'Imbabura - Otavalo - Puerto Lago',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|SAN_LUIS_(IM)',
    territory1Name: 'IMBABURA',
    territory2Name: 'OTAVALO',
    territory3Name: 'SAN LUIS (IM)',
    searchableText: 'Imbabura - Otavalo - San Luis (IM)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|SAN_PABLO_(IM)',
    territory1Name: 'IMBABURA',
    territory2Name: 'OTAVALO',
    territory3Name: 'SAN PABLO (IM)',
    searchableText: 'Imbabura - Otavalo - San Pablo (IM)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|OTAVALO|:|SAN_RAFAEL_(IM)',
    territory1Name: 'IMBABURA',
    territory2Name: 'OTAVALO',
    territory3Name: 'SAN RAFAEL (IM)',
    searchableText: 'Imbabura - Otavalo - San Rafael (IM)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|PIMAMPIRO|:|PIMAMPIRO',
    territory1Name: 'IMBABURA',
    territory2Name: 'PIMAMPIRO',
    territory3Name: 'PIMAMPIRO',
    searchableText: 'Imbabura - Pimampiro - Pimampiro',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|SAN_MIGUEL_DE_URCUQUI|:|SAN_BLAS_(IM)',
    territory1Name: 'IMBABURA',
    territory2Name: 'SAN MIGUEL DE URCUQUI',
    territory3Name: 'SAN BLAS (IM)',
    searchableText: 'Imbabura - San Miguel De Urcuqui - San Blas (IM)',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|SAN_MIGUEL_DE_URCUQUI|:|URCUQUI',
    territory1Name: 'IMBABURA',
    territory2Name: 'SAN MIGUEL DE URCUQUI',
    territory3Name: 'URCUQUI',
    searchableText: 'Imbabura - San Miguel De Urcuqui - Urcuqui',
  },
  {
    territoryBaseId: 'EC|:|IMBABURA|:|SAN_MIGUEL_DE_URCUQUI|:|YACHAY',
    territory1Name: 'IMBABURA',
    territory2Name: 'SAN MIGUEL DE URCUQUI',
    territory3Name: 'YACHAY',
    searchableText: 'Imbabura - San Miguel De Urcuqui - Yachay',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|CALVAS|:|CARIAMANGA',
    territory1Name: 'LOJA',
    territory2Name: 'CALVAS',
    territory3Name: 'CARIAMANGA',
    searchableText: 'Loja - Calvas - Cariamanga',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|CALVAS|:|EL_LUCERO',
    territory1Name: 'LOJA',
    territory2Name: 'CALVAS',
    territory3Name: 'EL LUCERO',
    searchableText: 'Loja - Calvas - El Lucero',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|CATAMAYO|:|CATAMAYO',
    territory1Name: 'LOJA',
    territory2Name: 'CATAMAYO',
    territory3Name: 'CATAMAYO',
    searchableText: 'Loja - Catamayo - Catamayo',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|CATAMAYO|:|SAN_PEDRO_DE_LA_BENDITA',
    territory1Name: 'LOJA',
    territory2Name: 'CATAMAYO',
    territory3Name: 'SAN PEDRO DE LA BENDITA',
    searchableText: 'Loja - Catamayo - San Pedro De La Bendita',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|CELICA|:|ALGARROBILLO',
    territory1Name: 'LOJA',
    territory2Name: 'CELICA',
    territory3Name: 'ALGARROBILLO',
    searchableText: 'Loja - Celica - Algarrobillo',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|CELICA|:|CELICA',
    territory1Name: 'LOJA',
    territory2Name: 'CELICA',
    territory3Name: 'CELICA',
    searchableText: 'Loja - Celica - Celica',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|CELICA|:|CRUZPAMBA',
    territory1Name: 'LOJA',
    territory2Name: 'CELICA',
    territory3Name: 'CRUZPAMBA',
    searchableText: 'Loja - Celica - Cruzpamba',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|CELICA|:|POZUL',
    territory1Name: 'LOJA',
    territory2Name: 'CELICA',
    territory3Name: 'POZUL',
    searchableText: 'Loja - Celica - Pozul',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|CELICA|:|ROBLONES',
    territory1Name: 'LOJA',
    territory2Name: 'CELICA',
    territory3Name: 'ROBLONES',
    searchableText: 'Loja - Celica - Roblones',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|CELICA|:|SABANILLA',
    territory1Name: 'LOJA',
    territory2Name: 'CELICA',
    territory3Name: 'SABANILLA',
    searchableText: 'Loja - Celica - Sabanilla',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|CHAGUARPAMBA|:|CHAGUARPAMBA',
    territory1Name: 'LOJA',
    territory2Name: 'CHAGUARPAMBA',
    territory3Name: 'CHAGUARPAMBA',
    searchableText: 'Loja - Chaguarpamba - Chaguarpamba',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|ESPINDOLA|:|27_DE_ABRIL_(CAB._EN_LA_NARANJA)',
    territory1Name: 'LOJA',
    territory2Name: 'ESPINDOLA',
    territory3Name: '27 DE ABRIL (CAB. EN LA NARANJA)',
    searchableText: 'Loja - Espindola - 27 De Abril (Cab. En La Naranja)',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|ESPINDOLA|:|AMALUZA',
    territory1Name: 'LOJA',
    territory2Name: 'ESPINDOLA',
    territory3Name: 'AMALUZA',
    searchableText: 'Loja - Espindola - Amaluza',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|GONZANAMA|:|GONZANAMA',
    territory1Name: 'LOJA',
    territory2Name: 'GONZANAMA',
    territory3Name: 'GONZANAMA',
    searchableText: 'Loja - Gonzanama - Gonzanama',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|CARIGAN',
    territory1Name: 'LOJA',
    territory2Name: 'LOJA',
    territory3Name: 'CARIGAN',
    searchableText: 'Loja - Loja - Carigan',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|CIUDAD_VICTORIA',
    territory1Name: 'LOJA',
    territory2Name: 'LOJA',
    territory3Name: 'CIUDAD VICTORIA',
    searchableText: 'Loja - Loja - Ciudad Victoria',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|EL_SAGRARIO_(LO)',
    territory1Name: 'LOJA',
    territory2Name: 'LOJA',
    territory3Name: 'EL SAGRARIO (LO)',
    searchableText: 'Loja - Loja - El Sagrario (LO)',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|LOJA',
    territory1Name: 'LOJA',
    territory2Name: 'LOJA',
    territory3Name: 'LOJA',
    searchableText: 'Loja - Loja - Loja',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|MALACATOS',
    territory1Name: 'LOJA',
    territory2Name: 'LOJA',
    territory3Name: 'MALACATOS',
    searchableText: 'Loja - Loja - Malacatos',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|SAN_LUCAS',
    territory1Name: 'LOJA',
    territory2Name: 'LOJA',
    territory3Name: 'SAN LUCAS',
    searchableText: 'Loja - Loja - San Lucas',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|SAN_PEDRO_DE_VILCABAMBA',
    territory1Name: 'LOJA',
    territory2Name: 'LOJA',
    territory3Name: 'SAN PEDRO DE VILCABAMBA',
    searchableText: 'Loja - Loja - San Pedro De Vilcabamba',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|SAN_SEBASTIAN',
    territory1Name: 'LOJA',
    territory2Name: 'LOJA',
    territory3Name: 'SAN SEBASTIAN',
    searchableText: 'Loja - Loja - San Sebastian',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|SANTIAGO',
    territory1Name: 'LOJA',
    territory2Name: 'LOJA',
    territory3Name: 'SANTIAGO',
    searchableText: 'Loja - Loja - Santiago',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|SUCRE_(LO)',
    territory1Name: 'LOJA',
    territory2Name: 'LOJA',
    territory3Name: 'SUCRE (LO)',
    searchableText: 'Loja - Loja - Sucre (LO)',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|VALLADOLID',
    territory1Name: 'LOJA',
    territory2Name: 'LOJA',
    territory3Name: 'VALLADOLID',
    searchableText: 'Loja - Loja - Valladolid',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|VALLE_(LO)',
    territory1Name: 'LOJA',
    territory2Name: 'LOJA',
    territory3Name: 'VALLE (LO)',
    searchableText: 'Loja - Loja - Valle (LO)',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|LOJA|:|YANGANA_(ARSENIO_CASTILLO)',
    territory1Name: 'LOJA',
    territory2Name: 'LOJA',
    territory3Name: 'YANGANA (ARSENIO CASTILLO)',
    searchableText: 'Loja - Loja - Yangana (Arsenio Castillo)',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|MACARA|:|MACARA',
    territory1Name: 'LOJA',
    territory2Name: 'MACARA',
    territory3Name: 'MACARA',
    searchableText: 'Loja - Macara - Macara',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|OLMEDO|:|OLMEDO',
    territory1Name: 'LOJA',
    territory2Name: 'OLMEDO',
    territory3Name: 'OLMEDO',
    searchableText: 'Loja - Olmedo - Olmedo',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|PALTAS|:|CATACOCHA',
    territory1Name: 'LOJA',
    territory2Name: 'PALTAS',
    territory3Name: 'CATACOCHA',
    searchableText: 'Loja - Paltas - Catacocha',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|PALTAS|:|PALTAS',
    territory1Name: 'LOJA',
    territory2Name: 'PALTAS',
    territory3Name: 'PALTAS',
    searchableText: 'Loja - Paltas - Paltas',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|PALTAS|:|RIO_PLAYAS',
    territory1Name: 'LOJA',
    territory2Name: 'PALTAS',
    territory3Name: 'RIO PLAYAS',
    searchableText: 'Loja - Paltas - Rio Playas',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|PINDAL|:|PINDAL',
    territory1Name: 'LOJA',
    territory2Name: 'PINDAL',
    territory3Name: 'PINDAL',
    searchableText: 'Loja - Pindal - Pindal',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|PUYANGO|:|ALAMOR',
    territory1Name: 'LOJA',
    territory2Name: 'PUYANGO',
    territory3Name: 'ALAMOR',
    searchableText: 'Loja - Puyango - Alamor',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|QUILANGA|:|EL_INGENIO',
    territory1Name: 'LOJA',
    territory2Name: 'QUILANGA',
    territory3Name: 'EL INGENIO',
    searchableText: 'Loja - Quilanga - El Ingenio',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|QUILANGA|:|QUILANGA',
    territory1Name: 'LOJA',
    territory2Name: 'QUILANGA',
    territory3Name: 'QUILANGA',
    searchableText: 'Loja - Quilanga - Quilanga',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|SARAGURO|:|SARAGURO',
    territory1Name: 'LOJA',
    territory2Name: 'SARAGURO',
    territory3Name: 'SARAGURO',
    searchableText: 'Loja - Saraguro - Saraguro',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|SOZORANGA|:|EL_EMPALME',
    territory1Name: 'LOJA',
    territory2Name: 'SOZORANGA',
    territory3Name: 'EL EMPALME',
    searchableText: 'Loja - Sozoranga - El Empalme',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|SOZORANGA|:|SOZORANGA',
    territory1Name: 'LOJA',
    territory2Name: 'SOZORANGA',
    territory3Name: 'SOZORANGA',
    searchableText: 'Loja - Sozoranga - Sozoranga',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|ZAPOTILLO|:|LA_CEIBA',
    territory1Name: 'LOJA',
    territory2Name: 'ZAPOTILLO',
    territory3Name: 'LA CEIBA',
    searchableText: 'Loja - Zapotillo - La Ceiba',
  },
  {
    territoryBaseId: 'EC|:|LOJA|:|ZAPOTILLO|:|ZAPOTILLO',
    territory1Name: 'LOJA',
    territory2Name: 'ZAPOTILLO',
    territory3Name: 'ZAPOTILLO',
    searchableText: 'Loja - Zapotillo - Zapotillo',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABA|:|BABA',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BABA',
    territory3Name: 'BABA',
    searchableText: 'Los Rios - Baba - Baba',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABA|:|GUARE_DE_BABA',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BABA',
    territory3Name: 'GUARE DE BABA',
    searchableText: 'Los Rios - Baba - Guare De Baba',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABA|:|ISLA_DE_BEJUCAL',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BABA',
    territory3Name: 'ISLA DE BEJUCAL',
    searchableText: 'Los Rios - Baba - Isla De Bejucal',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|BABAHOYO',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BABAHOYO',
    territory3Name: 'BABAHOYO',
    searchableText: 'Los Rios - Babahoyo - Babahoyo',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|BARREIRO',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BABAHOYO',
    territory3Name: 'BARREIRO',
    searchableText: 'Los Rios - Babahoyo - Barreiro',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|BRISAS_DEL_RIO',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BABAHOYO',
    territory3Name: 'BRISAS DEL RIO',
    searchableText: 'Los Rios - Babahoyo - Brisas Del Rio',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|CAMILO_PONCE_(LR)',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BABAHOYO',
    territory3Name: 'CAMILO PONCE (LR)',
    searchableText: 'Los Rios - Babahoyo - Camilo Ponce (LR)',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|CDLA._LAS_TOLAS',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BABAHOYO',
    territory3Name: 'CDLA. LAS TOLAS',
    searchableText: 'Los Rios - Babahoyo - Cdla. Las Tolas',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|CLEMENTE_BAQUERIZO',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BABAHOYO',
    territory3Name: 'CLEMENTE BAQUERIZO',
    searchableText: 'Los Rios - Babahoyo - Clemente Baquerizo',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|EL_SALTO',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BABAHOYO',
    territory3Name: 'EL SALTO',
    searchableText: 'Los Rios - Babahoyo - El Salto',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|FEBRES_CORDERO',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BABAHOYO',
    territory3Name: 'FEBRES CORDERO',
    searchableText: 'Los Rios - Babahoyo - Febres Cordero',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|LA_CHORRERA',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BABAHOYO',
    territory3Name: 'LA CHORRERA',
    searchableText: 'Los Rios - Babahoyo - La Chorrera',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|LA_JULIA',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BABAHOYO',
    territory3Name: 'LA JULIA',
    searchableText: 'Los Rios - Babahoyo - La Julia',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|LA_UNION',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BABAHOYO',
    territory3Name: 'LA UNION',
    searchableText: 'Los Rios - Babahoyo - La Union',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|LA_UNION_DE_CLEMENTINA',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BABAHOYO',
    territory3Name: 'LA UNION DE CLEMENTINA',
    searchableText: 'Los Rios - Babahoyo - La Union De Clementina',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|MATA_DE_CACAO',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BABAHOYO',
    territory3Name: 'MATA DE CACAO',
    searchableText: 'Los Rios - Babahoyo - Mata de Cacao',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|PIMOCHA',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BABAHOYO',
    territory3Name: 'PIMOCHA',
    searchableText: 'Los Rios - Babahoyo - Pimocha',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|PUEBLO_NUEVO',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BABAHOYO',
    territory3Name: 'PUEBLO NUEVO',
    searchableText: 'Los Rios - Babahoyo - Pueblo Nuevo',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|SAN_JOSE_DEL_TAMBO',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BABAHOYO',
    territory3Name: 'SAN JOSE DEL TAMBO',
    searchableText: 'Los Rios - Babahoyo - San Jose Del Tambo',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BABAHOYO|:|URB._VALLE_VERDE',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BABAHOYO',
    territory3Name: 'URB. VALLE VERDE',
    searchableText: 'Los Rios - Babahoyo - Urb. Valle Verde',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BUENA_FE|:|LA_CATORCE',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BUENA FE',
    territory3Name: 'LA CATORCE',
    searchableText: 'Los Rios - Buena Fe - La Catorce',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BUENA_FE|:|LOS_ANGELES_(SD)',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BUENA FE',
    territory3Name: 'LOS ANGELES (SD)',
    searchableText: 'Los Rios - Buena Fe - Los Angeles (SD)',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BUENA_FE|:|PALMISA',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BUENA FE',
    territory3Name: 'PALMISA',
    searchableText: 'Los Rios - Buena Fe - Palmisa',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BUENA_FE|:|PATRICIA_PILAR',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BUENA FE',
    territory3Name: 'PATRICIA PILAR',
    searchableText: 'Los Rios - Buena Fe - Patricia Pilar',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BUENA_FE|:|SAN_JACINTO_DE_BUENA_FE_(LR)',
    territory1Name: 'LOS RIOS',
    territory2Name: 'BUENA FE',
    territory3Name: 'SAN JACINTO DE BUENA FE (LR)',
    searchableText: 'Los Rios - Buena Fe - San Jacinto De Buena Fe (LR)',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|BUENA_FE|:|ZULEMA',
    territory1Name: 'LOS RIOS',
    territory2Name: 'ZULEMA',
    territory3Name: 'ZULEMA',
    searchableText: 'Los Rios - Buena Fe - Zulema',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|CARACOL|:|CARACOL',
    territory1Name: 'LOS RIOS',
    territory2Name: 'CARACOL',
    territory3Name: 'CARACOL',
    searchableText: 'Los Rios - Caracol - Caracol',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|EL_EMPALME|:|EL_EMPALME',
    territory1Name: 'LOS RIOS',
    territory2Name: 'EL EMPALME', // Unknown canton
    territory3Name: 'EL EMPALME',
    searchableText: 'Los Rios - El Empalme - El Empalme',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|LAS_NAVES|:|LAS_NAVES',
    territory1Name: 'LOS RIOS',
    territory2Name: 'LAS NAVES', // Unknown canton
    territory3Name: 'LAS NAVES',
    searchableText: 'Los Rios - Las Naves - Las Naves',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|MOCACHE|:|MOCACHE',
    territory1Name: 'LOS RIOS',
    territory2Name: 'MOCACHE',
    territory3Name: 'MOCACHE',
    searchableText: 'Los Rios - Mocache - Mocache',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|MONTALVO|:|LA_ESMERALDA',
    territory1Name: 'LOS RIOS',
    territory2Name: 'MONTALVO',
    territory3Name: 'LA ESMERALDA',
    searchableText: 'Los Rios - Montalvo - La Esmeralda',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|MONTALVO|:|MONTALVO_(LR)',
    territory1Name: 'LOS RIOS',
    territory2Name: 'MONTALVO',
    territory3Name: 'MONTALVO (LR)',
    searchableText: 'Los Rios - Montalvo - Montalvo (LR)',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|PALENQUE|:|PALENQUE',
    territory1Name: 'LOS RIOS',
    territory2Name: 'PALENQUE',
    territory3Name: 'PALENQUE',
    searchableText: 'Los Rios - Palenque - Palenque',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|PUEBLOVIEJO|:|ENTRADA_DE_SAN_JUAN',
    territory1Name: 'LOS RIOS',
    territory2Name: 'PUEBLOVIEJO',
    territory3Name: 'ENTRADA DE SAN JUAN',
    searchableText: 'Los Rios - Puebloviejo - Entrada De San Juan',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|PUEBLOVIEJO|:|PUEBLO_VIEJO',
    territory1Name: 'LOS RIOS',
    territory2Name: 'PUEBLOVIEJO',
    territory3Name: 'PUEBLO VIEJO',
    searchableText: 'Los Rios - Puebloviejo - Pueblo Viejo',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|PUEBLOVIEJO|:|SAN_JUAN_(LR)',
    territory1Name: 'LOS RIOS',
    territory2Name: 'PUEBLOVIEJO',
    territory3Name: 'SAN JUAN (LR)',
    searchableText: 'Los Rios - Puebloviejo - San Juan (LR)',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|24_DE_MAYO_(LR)',
    territory1Name: 'LOS RIOS',
    territory2Name: 'QUEVEDO',
    territory3Name: '24 DE MAYO (LR)',
    searchableText: 'Los Rios - Quevedo - 24 De Mayo (LR)',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|FUMISA',
    territory1Name: 'LOS RIOS',
    territory2Name: 'QUEVEDO',
    territory3Name: 'FUMISA',
    searchableText: 'Los Rios - Quevedo - Fumisa',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|GUAYACAN',
    territory1Name: 'LOS RIOS',
    territory2Name: 'QUEVEDO',
    territory3Name: 'GUAYACAN',
    searchableText: 'Los Rios - Quevedo - Guayacan',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|LA_ESPERANZA_(LR)',
    territory1Name: 'LOS RIOS',
    territory2Name: 'QUEVEDO',
    territory3Name: 'LA ESPERANZA (LR)',
    searchableText: 'Los Rios - Quevedo - La Esperanza (LR)',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|LA_UNION',
    territory1Name: 'LOS RIOS',
    territory2Name: 'QUEVEDO',
    territory3Name: 'LA UNION',
    searchableText: 'Los Rios - Quevedo - La Union',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|NICOLAS_INFANTE_DIAZ',
    territory1Name: 'LOS RIOS',
    territory2Name: 'QUEVEDO',
    territory3Name: 'NICOLAS INFANTE DIAZ',
    searchableText: 'Los Rios - Quevedo - Nicolas Infante Diaz',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|QUEVEDO',
    territory1Name: 'LOS RIOS',
    territory2Name: 'QUEVEDO',
    territory3Name: 'QUEVEDO',
    searchableText: 'Los Rios - Quevedo - Quevedo',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|SAN_CAMILO',
    territory1Name: 'LOS RIOS',
    territory2Name: 'QUEVEDO',
    territory3Name: 'SAN CAMILO',
    searchableText: 'Los Rios - Quevedo - San Camilo',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|SAN_CARLOS_(LR)',
    territory1Name: 'LOS RIOS',
    territory2Name: 'QUEVEDO',
    territory3Name: 'SAN CARLOS (LR)',
    searchableText: 'Los Rios - Quevedo - San Carlos (LR)',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|SAN_CRISTOBAL_(LR)',
    territory1Name: 'LOS RIOS',
    territory2Name: 'QUEVEDO',
    territory3Name: 'SAN CRISTOBAL (LR)',
    searchableText: 'Los Rios - Quevedo - San Cristobal (LR)',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|SIETE_DE_OCTUBRE',
    territory1Name: 'LOS RIOS',
    territory2Name: 'QUEVEDO',
    territory3Name: 'SIETE DE OCTUBRE',
    searchableText: 'Los Rios - Quevedo - Siete De Octubre',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|VENUS_DEL_RIO_QUEVEDO',
    territory1Name: 'LOS RIOS',
    territory2Name: 'QUEVEDO',
    territory3Name: 'VENUS DEL RIO QUEVEDO',
    searchableText: 'Los Rios - Quevedo - Venus Del Rio Quevedo',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUEVEDO|:|VIVA_ALFARO',
    territory1Name: 'LOS RIOS',
    territory2Name: 'QUEVEDO',
    territory3Name: 'VIVA ALFARO',
    searchableText: 'Los Rios - Quevedo - Viva Alfaro',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|QUINSALOMA|:|QUINSALOMA',
    territory1Name: 'LOS RIOS',
    territory2Name: 'QUINSALOMA',
    territory3Name: 'QUINSALOMA',
    searchableText: 'Los Rios - Quinsaloma - Quinsaloma',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|SAN_LUIS_DE_PAMBIL|:|SAN_LUIS_DE_PAMBIL',
    territory1Name: 'LOS RIOS',
    territory2Name: 'SAN LUIS DE PAMBIL', // Unknown canton
    territory3Name: 'SAN LUIS DE PAMBIL',
    searchableText: 'Los Rios - San Luis De Pambil - San Luis De Pambil',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|URDANETA|:|CATARAMA',
    territory1Name: 'LOS RIOS',
    territory2Name: 'URDANETA',
    territory3Name: 'CATARAMA',
    searchableText: 'Los Rios - Urdaneta - Catarama',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|URDANETA|:|RICAURTE_(LR)',
    territory1Name: 'LOS RIOS',
    territory2Name: 'URDANETA',
    territory3Name: 'RICAURTE (LR)',
    searchableText: 'Los Rios - Urdaneta - Ricaurte (LR)',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|URDANETA|:|URDANETA_(LR)',
    territory1Name: 'LOS RIOS',
    territory2Name: 'URDANETA',
    territory3Name: 'URDANETA (LR)',
    searchableText: 'Los Rios - Urdaneta - Urdaneta (LR)',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|VALENCIA|:|LA_UNION_DE_VALENCIA_(LR)',
    territory1Name: 'LOS RIOS',
    territory2Name: 'VALENCIA',
    territory3Name: 'LA UNION DE VALENCIA (LR)',
    searchableText: 'Los Rios - Valencia - La Union De Valencia (LR)',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|VALENCIA|:|NUEVA_UNION',
    territory1Name: 'LOS RIOS',
    territory2Name: 'VALENCIA',
    territory3Name: 'NUEVA UNION',
    searchableText: 'Los Rios - Valencia - Nueva Union',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|VALENCIA|:|VALENCIA_(LR)',
    territory1Name: 'LOS RIOS',
    territory2Name: 'VALENCIA',
    territory3Name: 'VALENCIA (LR)',
    searchableText: 'Los Rios - Valencia - Valencia (LR)',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|VENTANAS|:|10_DE_NOVIEMBRE',
    territory1Name: 'LOS RIOS',
    territory2Name: 'VENTANAS',
    territory3Name: '10 DE NOVIEMBRE',
    searchableText: 'Los Rios - Ventanas - 10 De Noviembre',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|VENTANAS|:|VENTANAS',
    territory1Name: 'LOS RIOS',
    territory2Name: 'VENTANAS',
    territory3Name: 'VENTANAS',
    searchableText: 'Los Rios - Ventanas - Ventanas',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|VENTANAS|:|ZAPOTAL',
    territory1Name: 'LOS RIOS',
    territory2Name: 'VENTANAS',
    territory3Name: 'ZAPOTAL',
    searchableText: 'Los Rios - Ventanas - Zapotal',
  },
  {
    territoryBaseId: 'EC|:|LOS_RIOS|:|VINCES|:|VINCES',
    territory1Name: 'LOS RIOS',
    territory2Name: 'VINCES',
    territory3Name: 'VINCES',
    searchableText: 'Los Rios - Vinces - Vinces',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|24_DE_MAYO|:|BELLAVISTA',
    territory1Name: 'MANABI',
    territory2Name: '24 DE MAYO',
    territory3Name: 'BELLAVISTA',
    searchableText: 'Manabi - 24 De Mayo - Bellavista',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|24_DE_MAYO|:|SUCRE_(MA)',
    territory1Name: 'MANABI',
    territory2Name: '24 DE MAYO',
    territory3Name: 'SUCRE (MA)',
    searchableText: 'Manabi - 24 De Mayo - Sucre (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|BAHIA_DE_CARAQUEZ_(SUCRE)|:|BAHIA_DE_CARAQUEZ',
    territory1Name: 'MANABI',
    territory2Name: 'BAHIA DE CARAQUEZ (SUCRE)',
    territory3Name: 'BAHIA DE CARAQUEZ',
    searchableText: 'Manabi - Bahia De Caraquez (Sucre) - Bahia De Caraquez',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|BAHIA_DE_CARAQUEZ_(SUCRE)|:|CANITAS',
    territory1Name: 'MANABI',
    territory2Name: 'BAHIA DE CARAQUEZ (SUCRE)',
    territory3Name: 'CANITAS',
    searchableText: 'Manabi - Bahia De Caraquez (Sucre) - Cañitas',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|BAHIA_DE_CARAQUEZ_(SUCRE)|:|CHARAPOTO',
    territory1Name: 'MANABI',
    territory2Name: 'BAHIA DE CARAQUEZ (SUCRE)',
    territory3Name: 'CHARAPOTO',
    searchableText: 'Manabi - Bahia De Caraquez (Sucre) - Charapoto',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|BAHIA_DE_CARAQUEZ_(SUCRE)|:|LEONIDAS_PLAZA_GUTIERREZ',
    territory1Name: 'MANABI',
    territory2Name: 'BAHIA DE CARAQUEZ (SUCRE)',
    territory3Name: 'LEONIDAS PLAZA GUTIERREZ',
    searchableText: 'Manabi - Bahia De Caraquez (Sucre) - Leonidas Plaza Gutierrez',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|BAHIA_DE_CARAQUEZ_(SUCRE)|:|SAN_CLEMENTE',
    territory1Name: 'MANABI',
    territory2Name: 'BAHIA DE CARAQUEZ (SUCRE)',
    territory3Name: 'SAN CLEMENTE',
    searchableText: 'Manabi - Bahia De Caraquez (Sucre) - San Clemente',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|BAHIA_DE_CARAQUEZ_(SUCRE)|:|SAN_ISIDRO',
    territory1Name: 'MANABI',
    territory2Name: 'BAHIA DE CARAQUEZ (SUCRE)',
    territory3Name: 'SAN ISIDRO',
    searchableText: 'Manabi - Bahia De Caraquez (Sucre) - San Isidro',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|BAHIA_DE_CARAQUEZ_(SUCRE)|:|SAN_JACINTO',
    territory1Name: 'MANABI',
    territory2Name: 'BAHIA DE CARAQUEZ (SUCRE)',
    territory3Name: 'SAN JACINTO',
    searchableText: 'Manabi - Bahia De Caraquez (Sucre) - San Jacinto',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|CALCETA_(BOLIVAR)|:|CALCETA',
    territory1Name: 'MANABI',
    territory2Name: 'CALCETA (BOLIVAR)',
    territory3Name: 'CALCETA',
    searchableText: 'Manabi - Calceta (Bolivar) - Calceta',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|CHONE|:|CANUTO',
    territory1Name: 'MANABI',
    territory2Name: 'CHONE',
    territory3Name: 'CANUTO',
    searchableText: 'Manabi - Chone - Canuto',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|CHONE|:|CHONE',
    territory1Name: 'MANABI',
    territory2Name: 'CHONE',
    territory3Name: 'CHONE',
    searchableText: 'Manabi - Chone - Chone',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|CHONE|:|PAVON',
    territory1Name: 'MANABI',
    territory2Name: 'CHONE',
    territory3Name: 'PAVON',
    searchableText: 'Manabi - Chone - Pavon',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|CHONE|:|RICAURTE_(MA)',
    territory1Name: 'MANABI',
    territory2Name: 'CHONE',
    territory3Name: 'RICAURTE (MA)',
    searchableText: 'Manabi - Chone - Ricaurte (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|CHONE|:|SAN_ANTONIO_(MA)',
    territory1Name: 'MANABI',
    territory2Name: 'CHONE',
    territory3Name: 'SAN ANTONIO (MA)',
    searchableText: 'Manabi - Chone - San Antonio (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|CHONE|:|SANTA_RITA_(MA)',
    territory1Name: 'MANABI',
    territory2Name: 'CHONE',
    territory3Name: 'SANTA RITA (MA)',
    searchableText: 'Manabi - Chone - Santa Rita (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|CHONE|:|SESME',
    territory1Name: 'MANABI',
    territory2Name: 'CHONE',
    territory3Name: 'SESME',
    searchableText: 'Manabi - Chone - Sesme',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|EL_CARMEN|:|EL_CARMEN',
    territory1Name: 'MANABI',
    territory2Name: 'EL CARMEN',
    territory3Name: 'EL CARMEN',
    searchableText: 'Manabi - El Carmen - El Carmen',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|FLAVIO_ALFARO|:|FLAVIO_ALFARO',
    territory1Name: 'MANABI',
    territory2Name: 'FLAVIO ALFARO',
    territory3Name: 'FLAVIO ALFARO',
    searchableText: 'Manabi - Flavio Alfaro - Flavio Alfaro',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|FLAVIO_ALFARO|:|ZAPALLO',
    territory1Name: 'MANABI',
    territory2Name: 'FLAVIO ALFARO',
    territory3Name: 'ZAPALLO',
    searchableText: 'Manabi - Flavio Alfaro - Zapallo',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JAMA|:|COAQUE',
    territory1Name: 'MANABI',
    territory2Name: 'JAMA',
    territory3Name: 'COAQUE',
    searchableText: 'Manabi - Jama - Coaque',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JAMA|:|DON_JUAN',
    territory1Name: 'MANABI',
    territory2Name: 'JAMA',
    territory3Name: 'DON JUAN',
    searchableText: 'Manabi - Jama - Don Juan',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JAMA|:|EL_MATAL',
    territory1Name: 'MANABI',
    territory2Name: 'JAMA',
    territory3Name: 'EL MATAL',
    searchableText: 'Manabi - Jama - El Matal',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JAMA|:|JAMA',
    territory1Name: 'MANABI',
    territory2Name: 'JAMA',
    territory3Name: 'JAMA',
    searchableText: 'Manabi - Jama - Jama',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JARAMIJO|:|JARAMIJO',
    territory1Name: 'MANABI',
    territory2Name: 'JARAMIJO',
    territory3Name: 'JARAMIJO',
    searchableText: 'Manabi - Jaramijo - Jaramijo',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JIPIJAPA|:|DOCTOR_MIGUEL_MORAN_LUCIO',
    territory1Name: 'MANABI',
    territory2Name: 'JIPIJAPA',
    territory3Name: 'DOCTOR MIGUEL MORAN LUCIO',
    searchableText: 'Manabi - Jipijapa - Doctor Miguel Moran Lucio',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JIPIJAPA|:|JIPIJAPA',
    territory1Name: 'MANABI',
    territory2Name: 'JIPIJAPA',
    territory3Name: 'JIPIJAPA',
    searchableText: 'Manabi - Jipijapa - Jipijapa',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JIPIJAPA|:|LAS_TUNAS',
    territory1Name: 'MANABI',
    territory2Name: 'JIPIJAPA',
    territory3Name: 'LAS TUNAS',
    searchableText: 'Manabi - Jipijapa - Las Tunas',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JIPIJAPA|:|MANUEL_INOCENCIO_PARRALES_Y_GUALE',
    territory1Name: 'MANABI',
    territory2Name: 'JIPIJAPA',
    territory3Name: 'MANUEL INOCENCIO PARRALES Y GUALE',
    searchableText: 'Manabi - Jipijapa - Manuel Inocencio Parrales Y Guale',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JIPIJAPA|:|PUERTO_DE_CAYO',
    territory1Name: 'MANABI',
    territory2Name: 'JIPIJAPA',
    territory3Name: 'PUERTO DE CAYO',
    searchableText: 'Manabi - Jipijapa - Puerto De Cayo',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JIPIJAPA|:|RECINTO_RIO_CHICO',
    territory1Name: 'MANABI',
    territory2Name: 'JIPIJAPA',
    territory3Name: 'RECINTO RIO CHICO',
    searchableText: 'Manabi - Jipijapa - Recinto Rio Chico',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JIPIJAPA|:|SALANGO',
    territory1Name: 'MANABI',
    territory2Name: 'JIPIJAPA',
    territory3Name: 'SALANGO',
    searchableText: 'Manabi - Jipijapa - Salango',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JIPIJAPA|:|SAN_LORENZO_DE_JIPIJAPA_(MA)',
    territory1Name: 'MANABI',
    territory2Name: 'JIPIJAPA',
    territory3Name: 'SAN LORENZO DE JIPIJAPA (MA)',
    searchableText: 'Manabi - Jipijapa - San Lorenzo De Jipijapa (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JIPIJAPA|:|SANCAN',
    territory1Name: 'MANABI',
    territory2Name: 'JIPIJAPA',
    territory3Name: 'SANCAN',
    searchableText: 'Manabi - Jipijapa - Sancan',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|JUNIN|:|JUNIN',
    territory1Name: 'MANABI',
    territory2Name: 'JUNIN',
    territory3Name: 'JUNIN',
    searchableText: 'Manabi - Junin - Junin',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|MANTA|:|ELOY_ALFARO_(MA)',
    territory1Name: 'MANABI',
    territory2Name: 'MANTA',
    territory3Name: 'ELOY ALFARO (MA)',
    searchableText: 'Manabi - Manta - Eloy Alfaro (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|MANTA|:|LOS_ESTEROS',
    territory1Name: 'MANABI',
    territory2Name: 'MANTA',
    territory3Name: 'LOS ESTEROS',
    searchableText: 'Manabi - Manta - Los Esteros',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|MANTA|:|MANTA',
    territory1Name: 'MANABI',
    territory2Name: 'MANTA',
    territory3Name: 'MANTA',
    searchableText: 'Manabi - Manta - Manta',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|MANTA|:|SAN_MATEO_(MA)',
    territory1Name: 'MANABI',
    territory2Name: 'MANTA',
    territory3Name: 'SAN MATEO (MA)',
    searchableText: 'Manabi - Manta - San Mateo (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|MANTA|:|TARQUI_(MA)',
    territory1Name: 'MANABI',
    territory2Name: 'MANTA',
    territory3Name: 'TARQUI (MA)',
    searchableText: 'Manabi - Manta - Tarqui (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|MONTECRISTI|:|EL_COLORADO',
    territory1Name: 'MANABI',
    territory2Name: 'MONTECRISTI',
    territory3Name: 'EL COLORADO',
    searchableText: 'Manabi - Montecristi - El Colorado',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|MONTECRISTI|:|GENERAL_ELOY_ALFARO_(MA)',
    territory1Name: 'MANABI',
    territory2Name: 'MONTECRISTI',
    territory3Name: 'GENERAL ELOY ALFARO (MA)',
    searchableText: 'Manabi - Montecristi - General Eloy Alfaro (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|MONTECRISTI|:|LAS_CAÑAS_(MA)',
    territory1Name: 'MANABI',
    territory2Name: 'MONTECRISTI',
    territory3Name: 'LAS CAÑAS (MA)',
    searchableText: 'Manabi - Montecristi - Las Cañas (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|MONTECRISTI|:|LEONIDAS_PROAÑO',
    territory1Name: 'MANABI',
    territory2Name: 'MONTECRISTI',
    territory3Name: 'LEONIDAS PROAÑO',
    searchableText: 'Manabi - Montecristi - Leonidas Proaño',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|MONTECRISTI|:|LOS_BAJOS',
    territory1Name: 'MANABI',
    territory2Name: 'MONTECRISTI',
    territory3Name: 'LOS BAJOS',
    searchableText: 'Manabi - Montecristi - Los Bajos',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|MONTECRISTI|:|MONTECRISTI',
    territory1Name: 'MANABI',
    territory2Name: 'MONTECRISTI',
    territory3Name: 'MONTECRISTI',
    searchableText: 'Manabi - Montecristi - Montecristi',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|OLMEDO|:|OLMEDO',
    territory1Name: 'MANABI',
    territory2Name: 'OLMEDO',
    territory3Name: 'OLMEDO',
    searchableText: 'Manabi - Olmedo - Olmedo',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PAJAN|:|CASCOL',
    territory1Name: 'MANABI',
    territory2Name: 'PAJAN',
    territory3Name: 'CASCOL',
    searchableText: 'Manabi - Pajan - Cascol',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PAJAN|:|PAJAN',
    territory1Name: 'MANABI',
    territory2Name: 'PAJAN',
    territory3Name: 'PAJAN',
    searchableText: 'Manabi - Pajan - Pajan',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PEDERNALES|:|10_DE_AGOSTO',
    territory1Name: 'MANABI',
    territory2Name: 'PEDERNALES',
    territory3Name: '10 DE AGOSTO',
    searchableText: 'Manabi - Pedernales - 10 De Agosto',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PEDERNALES|:|ATAHUALPA',
    territory1Name: 'MANABI',
    territory2Name: 'PEDERNALES',
    territory3Name: 'ATAHUALPA',
    searchableText: 'Manabi - Pedernales - Atahualpa',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PEDERNALES|:|CHEVE',
    territory1Name: 'MANABI',
    territory2Name: 'PEDERNALES',
    territory3Name: 'CHEVE',
    searchableText: 'Manabi - Pedernales - Cheve',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PEDERNALES|:|COJIMIES',
    territory1Name: 'MANABI',
    territory2Name: 'PEDERNALES',
    territory3Name: 'COJIMIES',
    searchableText: 'Manabi - Pedernales - Cojimies',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PEDERNALES|:|LA_CHORRERA',
    territory1Name: 'MANABI',
    territory2Name: 'PEDERNALES',
    territory3Name: 'LA CHORRERA',
    searchableText: 'Manabi - Pedernales - La Chorrera',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PEDERNALES|:|MACHE',
    territory1Name: 'MANABI',
    territory2Name: 'PEDERNALES',
    territory3Name: 'MACHE',
    searchableText: 'Manabi - Pedernales - Mache',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PEDERNALES|:|PEDERNALES',
    territory1Name: 'MANABI',
    territory2Name: 'PEDERNALES',
    territory3Name: 'PEDERNALES',
    searchableText: 'Manabi - Pedernales - Pedernales',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PICHINCHA|:|PICHINCHA_(MA)',
    territory1Name: 'MANABI',
    territory2Name: 'PICHINCHA',
    territory3Name: 'PICHINCHA (MA)',
    searchableText: 'Manabi - Pichincha - Pichincha (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|12_DE_MARZO',
    territory1Name: 'MANABI',
    territory2Name: 'PORTOVIEJO',
    territory3Name: '12 DE MARZO',
    searchableText: 'Manabi - Portoviejo - 12 De Marzo',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|18_DE_OCTUBRE',
    territory1Name: 'MANABI',
    territory2Name: 'PORTOVIEJO',
    territory3Name: '18 DE OCTUBRE',
    searchableText: 'Manabi - Portoviejo - 18 De Octubre',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|ABDON_CALDERON_(SAN_FRANCISCO)_(MA)',
    territory1Name: 'MANABI',
    territory2Name: 'PORTOVIEJO',
    territory3Name: 'ABDON CALDERON (SAN FRANCISCO) (MA)',
    searchableText: 'Manabi - Portoviejo - Abdon Calderon (San Francisco) (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|ALAJUELA',
    territory1Name: 'MANABI',
    territory2Name: 'PORTOVIEJO',
    territory3Name: 'ALAJUELA',
    searchableText: 'Manabi - Portoviejo - Alajuela',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|ALHAJUELA',
    territory1Name: 'MANABI',
    territory2Name: 'PORTOVIEJO',
    territory3Name: 'ALHAJUELA',
    searchableText: 'Manabi - Portoviejo - Alhajuela',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|ANDRES_DE_VERA_(MA)',
    territory1Name: 'MANABI',
    territory2Name: 'PORTOVIEJO',
    territory3Name: 'ANDRES DE VERA (MA)',
    searchableText: 'Manabi - Portoviejo - Andres De Vera (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|ARENALES',
    territory1Name: 'MANABI',
    territory2Name: 'PORTOVIEJO',
    territory3Name: 'ARENALES',
    searchableText: 'Manabi - Portoviejo - Arenales',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|AYACUCHO',
    territory1Name: 'MANABI',
    territory2Name: 'PORTOVIEJO',
    territory3Name: 'AYACUCHO',
    searchableText: 'Manabi - Portoviejo - Ayacucho',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|CALDERON_(MA)',
    territory1Name: 'MANABI',
    territory2Name: 'PORTOVIEJO',
    territory3Name: 'CALDERON (MA)',
    searchableText: 'Manabi - Portoviejo - Calderon',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|CHIRIJOS',
    territory1Name: 'MANABI',
    territory2Name: 'PORTOVIEJO',
    territory3Name: 'CHIRIJOS',
    searchableText: 'Manabi - Portoviejo - Chirijos',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|COLON_(MA)',
    territory1Name: 'MANABI',
    territory2Name: 'PORTOVIEJO',
    territory3Name: 'COLON (MA)',
    searchableText: 'Manabi - Portoviejo - Colon (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|CRUCITA',
    territory1Name: 'MANABI',
    territory2Name: 'PORTOVIEJO',
    territory3Name: 'CRUCITA',
    searchableText: 'Manabi - Portoviejo - Crucita',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|EL_NARANJO',
    territory1Name: 'MANABI',
    territory2Name: 'PORTOVIEJO',
    territory3Name: 'EL NARANJO',
    searchableText: 'Manabi - Portoviejo - El Naranjo',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|EL_RODEO',
    territory1Name: 'MANABI',
    territory2Name: 'PORTOVIEJO',
    territory3Name: 'EL RODEO',
    searchableText: 'Manabi - Portoviejo - El Rodeo',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|FRANCISCO_PACHECO',
    territory1Name: 'MANABI',
    territory2Name: 'PORTOVIEJO',
    territory3Name: 'FRANCISCO PACHECO',
    searchableText: 'Manabi - Portoviejo - Francisco Pacheco',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|HORONATO_VAZQUES',
    territory1Name: 'MANABI',
    territory2Name: 'PORTOVIEJO',
    territory3Name: 'HORONATO VAZQUES',
    searchableText: 'Manabi - Portoviejo - Horonato Vazques',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|LA_SEQUITA',
    territory1Name: 'MANABI',
    territory2Name: 'PORTOVIEJO',
    territory3Name: 'LA SEQUITA',
    searchableText: 'Manabi - Portoviejo - La Sequita',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|PICOAZA',
    territory1Name: 'MANABI',
    territory2Name: 'PORTOVIEJO',
    territory3Name: 'PICOAZA',
    searchableText: 'Manabi - Portoviejo - Picoaza',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|PLAYA_PRIETA',
    territory1Name: 'MANABI',
    territory2Name: 'PORTOVIEJO',
    territory3Name: 'PLAYA PRIETA',
    searchableText: 'Manabi - Portoviejo - Playa Prieta',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|PORTOVIEJO',
    territory1Name: 'MANABI',
    territory2Name: 'PORTOVIEJO',
    territory3Name: 'PORTOVIEJO',
    searchableText: 'Manabi - Portoviejo - Portoviejo',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|PUEBLO_NUEVO_(MA)',
    territory1Name: 'MANABI',
    territory2Name: 'PORTOVIEJO',
    territory3Name: 'PUEBLO NUEVO (MA)',
    searchableText: 'Manabi - Portoviejo - Pueblo Nuevo (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|RIOCHICO_(RIO_CHICO)',
    territory1Name: 'MANABI',
    territory2Name: 'PORTOVIEJO',
    territory3Name: 'RIOCHICO (RIO CHICO)',
    searchableText: 'Manabi - Portoviejo - Riochico (Rio Chico)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|SAN_IGNACIO',
    territory1Name: 'MANABI',
    territory2Name: 'PORTOVIEJO',
    territory3Name: 'SAN IGNACIO',
    searchableText: 'Manabi - Portoviejo - San Ignacio',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|SAN_PLACIDO',
    territory1Name: 'MANABI',
    territory2Name: 'PORTOVIEJO',
    territory3Name: 'SAN PLACIDO',
    searchableText: 'Manabi - Portoviejo - San Placido',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PORTOVIEJO|:|SIMON_BOLIVAR_(MA)',
    territory1Name: 'MANABI',
    territory2Name: 'PORTOVIEJO',
    territory3Name: 'SIMON BOLIVAR (MA)',
    searchableText: 'Manabi - Portoviejo - Simon Bolivar (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PUERTO_LOPEZ|:|MACHALILLA',
    territory1Name: 'MANABI',
    territory2Name: 'PUERTO LOPEZ',
    territory3Name: 'MACHALILLA',
    searchableText: 'Manabi - Puerto Lopez - Machalilla',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|PUERTO_LOPEZ|:|PUERTO_LOPEZ',
    territory1Name: 'MANABI',
    territory2Name: 'PUERTO LOPEZ',
    territory3Name: 'PUERTO LOPEZ',
    searchableText: 'Manabi - Puerto Lopez - Puerto Lopez',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|ROCAFUERTE|:|MEJIA',
    territory1Name: 'MANABI',
    territory2Name: 'ROCAFUERTE',
    territory3Name: 'MEJIA',
    searchableText: 'Manabi - Rocafuerte - Mejia',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|ROCAFUERTE|:|RESBALON',
    territory1Name: 'MANABI',
    territory2Name: 'ROCAFUERTE',
    territory3Name: 'RESBALON',
    searchableText: 'Manabi - Rocafuerte - Resbalon',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|ROCAFUERTE|:|ROCAFUERTE_(MA)',
    territory1Name: 'MANABI',
    territory2Name: 'ROCAFUERTE',
    territory3Name: 'ROCAFUERTE (MA)',
    searchableText: 'Manabi - Rocafuerte - Rocafuerte (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|ROCAFUERTE|:|SOSOTE',
    territory1Name: 'MANABI',
    territory2Name: 'ROCAFUERTE',
    territory3Name: 'SOSOTE',
    searchableText: 'Manabi - Rocafuerte - Sosote',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|SAN_VICENTE|:|CANOA',
    territory1Name: 'MANABI',
    territory2Name: 'SAN VICENTE',
    territory3Name: 'CANOA',
    searchableText: 'Manabi - San Vicente - Canoa',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|SAN_VICENTE|:|NUEVO_BRICENO',
    territory1Name: 'MANABI',
    territory2Name: 'SAN VICENTE',
    territory3Name: 'NUEVO BRICENO',
    searchableText: 'Manabi - San Vicente - Nuevo Briceño',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|SAN_VICENTE|:|SAN_VICENTE',
    territory1Name: 'MANABI',
    territory2Name: 'SAN VICENTE',
    territory3Name: 'SAN VICENTE',
    searchableText: 'Manabi - San Vicente - San Vicente',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|SANTA_ANA|:|LODANA',
    territory1Name: 'MANABI',
    territory2Name: 'SANTA ANA',
    territory3Name: 'LODANA',
    searchableText: 'Manabi - Santa Ana - Lodana',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|SANTA_ANA|:|SANTA_ANA_(MA)',
    territory1Name: 'MANABI',
    territory2Name: 'SANTA ANA',
    territory3Name: 'SANTA ANA (MA)',
    searchableText: 'Manabi - Santa Ana - Santa Ana (MA)',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|TOSAGUA|:|BACHILLERO',
    territory1Name: 'MANABI',
    territory2Name: 'TOSAGUA',
    territory3Name: 'BACHILLERO',
    searchableText: 'Manabi - Tosagua - Bachillero',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|TOSAGUA|:|LA_ESTANCILLA',
    territory1Name: 'MANABI',
    territory2Name: 'TOSAGUA',
    territory3Name: 'LA ESTANCILLA',
    searchableText: 'Manabi - Tosagua - La Estancilla',
  },
  {
    territoryBaseId: 'EC|:|MANABI|:|TOSAGUA|:|TOSAGUA',
    territory1Name: 'MANABI',
    territory2Name: 'TOSAGUA',
    territory3Name: 'TOSAGUA',
    searchableText: 'Manabi - Tosagua - Tosagua',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|GUALAQUIZA|:|GUALAQUIZA',
    territory1Name: 'MORONA SANTIAGO',
    territory2Name: 'GUALAQUIZA',
    territory3Name: 'GUALAQUIZA',
    searchableText: 'Morona Santiago - Gualaquiza - Gualaquiza',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|HUAMBOYA|:|HUAMBOYA',
    territory1Name: 'MORONA SANTIAGO',
    territory2Name: 'HUAMBOYA',
    territory3Name: 'HUAMBOYA',
    searchableText: 'Morona Santiago - Huamboya - Huamboya',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|LIMON_INDANZA|:|LIMON_INDANZA',
    territory1Name: 'MORONA SANTIAGO',
    territory2Name: 'LIMON INDANZA',
    territory3Name: 'LIMON INDANZA',
    searchableText: 'Morona Santiago - Limon Indanza - Limon Indanza',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|LOGRONO|:|LOGROÑO',
    territory1Name: 'MORONA SANTIAGO',
    territory2Name: 'LOGRONO',
    territory3Name: 'LOGROÑO',
    searchableText: 'Morona Santiago - Logrono - Logroño',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|MACAS_(MORONA)|:|GENERAL_PROAÑO',
    territory1Name: 'MORONA SANTIAGO',
    territory2Name: 'MACAS (MORONA)',
    territory3Name: 'GENERAL PROAÑO',
    searchableText: 'Morona Santiago - Macas (Morona) - General Proaño',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|MACAS_(MORONA)|:|MACAS',
    territory1Name: 'MORONA SANTIAGO',
    territory2Name: 'MACAS (MORONA)',
    territory3Name: 'MACAS',
    searchableText: 'Morona Santiago - Macas (Morona) - Macas',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|MACAS_(MORONA)|:|RIO_BLANCO',
    territory1Name: 'MORONA SANTIAGO',
    territory2Name: 'MACAS (MORONA)',
    territory3Name: 'RIO BLANCO',
    searchableText: 'Morona Santiago - Macas (Morona) - Rio Blanco',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|MACAS_(MORONA)|:|SEVILLA_DON_BOSCO',
    territory1Name: 'MORONA SANTIAGO',
    territory2Name: 'MACAS (MORONA)',
    territory3Name: 'SEVILLA DON BOSCO',
    searchableText: 'Morona Santiago - Macas (Morona) - Sevilla Don Bosco',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|MACAS_(MORONA)|:|SINAI',
    territory1Name: 'MORONA SANTIAGO',
    territory2Name: 'MACAS (MORONA)',
    territory3Name: 'SINAI',
    searchableText: 'Morona Santiago - Macas (Morona) - Sinai',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|PABLO_SEXTO|:|PABLO_SEXTO',
    territory1Name: 'MORONA SANTIAGO',
    territory2Name: 'PABLO SEXTO',
    territory3Name: 'PABLO SEXTO',
    searchableText: 'Morona Santiago - Pablo Sexto - Pablo Sexto',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|PALORA|:|PALORA_(METZERA)',
    territory1Name: 'MORONA SANTIAGO',
    territory2Name: 'PALORA',
    territory3Name: 'PALORA (METZERA)',
    searchableText: 'Morona Santiago - Palora - Palora (Metzera)',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|SAN_JUAN_BOSCO|:|SAN_JUAN_BOSCO',
    territory1Name: 'MORONA SANTIAGO',
    territory2Name: 'SAN JUAN BOSCO',
    territory3Name: 'SAN JUAN BOSCO',
    searchableText: 'Morona Santiago - San Juan Bosco - San Juan Bosco',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|SANTIAGO|:|SANTIAGO_DE_MENDEZ',
    territory1Name: 'MORONA SANTIAGO',
    territory2Name: 'SANTIAGO',
    territory3Name: 'SANTIAGO DE MENDEZ',
    searchableText: 'Morona Santiago - Santiago - Santiago De Mendez',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|SUCUA|:|SANTA_MARIANITA_DE_JESUS_(MS)',
    territory1Name: 'MORONA SANTIAGO',
    territory2Name: 'SUCUA',
    territory3Name: 'SANTA MARIANITA DE JESUS (MS)',
    searchableText: 'Morona Santiago - Sucua - Santa Marianita De Jesus (MS)',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|SUCUA|:|SUCUA',
    territory1Name: 'MORONA SANTIAGO',
    territory2Name: 'SUCUA',
    territory3Name: 'SUCUA',
    searchableText: 'Morona Santiago - Sucua - Sucua',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|TAISHA|:|TAISHA',
    territory1Name: 'MORONA SANTIAGO',
    territory2Name: 'TAISHA',
    territory3Name: 'TAISHA',
    searchableText: 'Morona Santiago - Taisha - Taisha',
  },
  {
    territoryBaseId: 'EC|:|MORONA_SANTIAGO|:|TIWINTZA|:|TIWINTZA',
    territory1Name: 'MORONA SANTIAGO',
    territory2Name: 'TIWINTZA',
    territory3Name: 'TIWINTZA',
    searchableText: 'Morona Santiago - Tiwintza - Tiwintza',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|ARCHIDONA|:|ARCHIDONA',
    territory1Name: 'NAPO',
    territory2Name: 'ARCHIDONA',
    territory3Name: 'ARCHIDONA',
    searchableText: 'Napo - Archidona - Archidona',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|ARCHIDONA|:|COTUNDO',
    territory1Name: 'NAPO',
    territory2Name: 'ARCHIDONA',
    territory3Name: 'COTUNDO',
    searchableText: 'Napo - Archidona - Cotundo',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|ARCHIDONA|:|NUEVA_ESPERANZA',
    territory1Name: 'NAPO',
    territory2Name: 'ARCHIDONA',
    territory3Name: 'NUEVA ESPERANZA',
    searchableText: 'Napo - Archidona - Nueva Esperanza',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|CARLOS_JULIO_AROSEMENA_TOLA|:|CARLOS_JULIO_AROSEMENA_TOLA',
    territory1Name: 'NAPO',
    territory2Name: 'CARLOS JULIO AROSEMENA TOLA',
    territory3Name: 'CARLOS JULIO AROSEMENA TOLA',
    searchableText: 'Napo - Carlos Julio Arosemena Tola - Carlos Julio Arosemena Tola',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|CARLOS_JULIO_AROSEMENA_TOLA|:|TAZAYACU',
    territory1Name: 'NAPO',
    territory2Name: 'CARLOS JULIO AROSEMENA TOLA',
    territory3Name: 'TAZAYACU',
    searchableText: 'Napo - Carlos Julio Arosemena Tola - Tazayacu',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|EL_CHACO|:|EL_CHACO',
    territory1Name: 'NAPO',
    territory2Name: 'EL CHACO',
    territory3Name: 'EL CHACO',
    searchableText: 'Napo - El Chaco - El Chaco',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|EL_TENA|:|EL_TENA',
    territory1Name: 'NAPO',
    territory2Name: 'EL TENA',
    territory3Name: 'EL TENA',
    searchableText: 'Napo - El Tena - El Tena',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|EL_TENA|:|GONZALO_PIZARRO',
    territory1Name: 'NAPO',
    territory2Name: 'EL TENA',
    territory3Name: 'GONZALO PIZARRO',
    searchableText: 'Napo - El Tena - Gonzalo Pizarro',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|EL_TENA|:|PUERTO_NAPO',
    territory1Name: 'NAPO',
    territory2Name: 'EL TENA',
    territory3Name: 'PUERTO NAPO',
    searchableText: 'Napo - El Tena - Puerto Napo',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|EL_TENA|:|SAN_JUAN_DE_MUYUNA',
    territory1Name: 'NAPO',
    territory2Name: 'EL TENA',
    territory3Name: 'SAN JUAN DE MUYUNA',
    searchableText: 'Napo - El Tena - San Juan De Muyuna',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|QUIJOS|:|BAEZA',
    territory1Name: 'NAPO',
    territory2Name: 'QUIJOS',
    territory3Name: 'BAEZA',
    searchableText: 'Napo - Quijos - Baeza',
  },
  {
    territoryBaseId: 'EC|:|NAPO|:|QUIJOS|:|SAN_FRANCISCO_DE_BORJA_(VIRGILIO_DAVILA)',
    territory1Name: 'NAPO',
    territory2Name: 'QUIJOS',
    territory3Name: 'SAN FRANCISCO DE BORJA (VIRGILIO DAVILA)',
    searchableText: 'Napo - Quijos - San Francisco De Borja (Virgilio Davila)',
  },
  {
    territoryBaseId: 'EC|:|ORELLANA|:|AGUARICO|:|AGUARICO',
    territory1Name: 'ORELLANA',
    territory2Name: 'AGUARICO',
    territory3Name: 'AGUARICO',
    searchableText: 'Orellana - Aguarico - Aguarico',
  },
  {
    territoryBaseId: 'EC|:|ORELLANA|:|EL_COCA|:|ALEJANDRO_LABAKA',
    territory1Name: 'ORELLANA',
    territory2Name: 'EL COCA',
    territory3Name: 'ALEJANDRO LABAKA',
    searchableText: 'Orellana - El Coca - Alejandro Labaka',
  },
  {
    territoryBaseId: 'EC|:|ORELLANA|:|EL_COCA|:|EL_COCA',
    territory1Name: 'ORELLANA',
    territory2Name: 'EL COCA',
    territory3Name: 'EL COCA',
    searchableText: 'Orellana - El Coca - El Coca',
  },
  {
    territoryBaseId: 'EC|:|ORELLANA|:|EL_COCA|:|EL_DORADO',
    territory1Name: 'ORELLANA',
    territory2Name: 'EL COCA',
    territory3Name: 'EL DORADO',
    searchableText: 'Orellana - El Coca - El Dorado',
  },
  {
    territoryBaseId: 'EC|:|ORELLANA|:|LA_JOYA_DE_LOS_SACHAS|:|LA_JOYA_DE_LOS_SACHAS',
    territory1Name: 'ORELLANA',
    territory2Name: 'LA JOYA DE LOS SACHAS',
    territory3Name: 'LA JOYA DE LOS SACHAS',
    searchableText: 'Orellana - La Joya De Los Sachas - La Joya De Los Sachas',
  },
  {
    territoryBaseId: 'EC|:|ORELLANA|:|LA_JOYA_DE_LOS_SACHAS|:|SAN_SEBASTIAN_(CAÑON_DE_LOS_MONOS)',
    territory1Name: 'ORELLANA',
    territory2Name: 'LA JOYA DE LOS SACHAS',
    territory3Name: 'SAN SEBASTIAN (CAÑON DE LOS MONOS)',
    searchableText: 'Orellana - La Joya De Los Sachas - San Sebastian (Cañon De Los Monos)',
  },
  {
    territoryBaseId: 'EC|:|ORELLANA|:|LORETO|:|LORETO',
    territory1Name: 'ORELLANA',
    territory2Name: 'LORETO',
    territory3Name: 'LORETO',
    searchableText: 'Orellana - Loreto - Loreto',
  },
  {
    territoryBaseId: 'EC|:|PASTAZA|:|ARAJUNO|:|ARAJUNO',
    territory1Name: 'PASTAZA',
    territory2Name: 'ARAJUNO',
    territory3Name: 'ARAJUNO',
    searchableText: 'Pastaza - Arajuno - Arajuno',
  },
  {
    territoryBaseId: 'EC|:|PASTAZA|:|EL_PUYO|:|EL_PUYO',
    territory1Name: 'PASTAZA',
    territory2Name: 'EL PUYO',
    territory3Name: 'EL PUYO',
    searchableText: 'Pastaza - El Puyo - El Puyo',
  },
  {
    territoryBaseId: 'EC|:|PASTAZA|:|EL_PUYO|:|FATIMA',
    territory1Name: 'PASTAZA',
    territory2Name: 'EL PUYO',
    territory3Name: 'FATIMA',
    searchableText: 'Pastaza - El Puyo - Fatima',
  },
  {
    territoryBaseId: 'EC|:|PASTAZA|:|EL_PUYO|:|TARQUI_(PA)',
    territory1Name: 'PASTAZA',
    territory2Name: 'EL PUYO',
    territory3Name: 'TARQUI (PA)',
    searchableText: 'Pastaza - El Puyo - Tarqui (PA)',
  },
  {
    territoryBaseId: 'EC|:|PASTAZA|:|MERA|:|MERA',
    territory1Name: 'PASTAZA',
    territory2Name: 'MERA',
    territory3Name: 'MERA',
    searchableText: 'Pastaza - Mera - Mera',
  },
  {
    territoryBaseId: 'EC|:|PASTAZA|:|MERA|:|SHELL',
    territory1Name: 'PASTAZA',
    territory2Name: 'MERA',
    territory3Name: 'SHELL',
    searchableText: 'Pastaza - Mera - Shell',
  },
  {
    territoryBaseId: 'EC|:|PASTAZA|:|SANTA_CLARA|:|SANTA_CLARA',
    territory1Name: 'PASTAZA',
    territory2Name: 'SANTA CLARA',
    territory3Name: 'SANTA CLARA',
    searchableText: 'Pastaza - Santa Clara - Santa Clara',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|CAYAMBE|:|ASCAZUBI',
    territory1Name: 'PICHINCHA',
    territory2Name: 'CAYAMBE',
    territory3Name: 'ASCAZUBI',
    searchableText: 'Pichincha - Cayambe - Ascazubi',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|CAYAMBE|:|CAYAMBE',
    territory1Name: 'PICHINCHA',
    territory2Name: 'CAYAMBE',
    territory3Name: 'CAYAMBE',
    searchableText: 'Pichincha - Cayambe - Cayambe',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|CAYAMBE|:|JUAN_MONTALVO_(PI)',
    territory1Name: 'PICHINCHA',
    territory2Name: 'CAYAMBE',
    territory3Name: 'JUAN MONTALVO (PI)',
    searchableText: 'Pichincha - Cayambe - Juan Montalvo (PI)',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|CAYAMBE|:|SAN_JOSE_DE_AYORA_(PI)',
    territory1Name: 'PICHINCHA',
    territory2Name: 'CAYAMBE',
    territory3Name: 'SAN JOSE DE AYORA (PI)',
    searchableText: 'Pichincha - Cayambe - San Jose De Ayora (PI)',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|CAYAMBE|:|SANTA_CLARA',
    territory1Name: 'PICHINCHA',
    territory2Name: 'CAYAMBE',
    territory3Name: 'SANTA CLARA',
    searchableText: 'Pichincha - Cayambe - Santa Clara',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|CAYAMBE|:|SANTA_ROSA_DE_CUZUBAMBA',
    territory1Name: 'PICHINCHA',
    territory2Name: 'CAYAMBE',
    territory3Name: 'SANTA ROSA DE CUZUBAMBA',
    searchableText: 'Pichincha - Cayambe - Santa Rosa De Cuzubamba',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|MEJIA|:|ALOAG',
    territory1Name: 'PICHINCHA',
    territory2Name: 'MEJIA',
    territory3Name: 'ALOAG',
    searchableText: 'Pichincha - Mejia - Aloag',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|MEJIA|:|ALOASI',
    territory1Name: 'PICHINCHA',
    territory2Name: 'MEJIA',
    territory3Name: 'ALOASI',
    searchableText: 'Pichincha - Mejia - Aloasi',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|MEJIA|:|CUTUGLAHUA',
    territory1Name: 'PICHINCHA',
    territory2Name: 'MEJIA',
    territory3Name: 'CUTUGLAHUA',
    searchableText: 'Pichincha - Mejia - Cutuglahua',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|MEJIA|:|MACHACHI',
    territory1Name: 'PICHINCHA',
    territory2Name: 'MEJIA',
    territory3Name: 'MACHACHI',
    searchableText: 'Pichincha - Mejia - Machachi',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|MEJIA|:|MEJIA',
    territory1Name: 'PICHINCHA',
    territory2Name: 'MEJIA',
    territory3Name: 'MEJIA',
    searchableText: 'Pichincha - Mejia - Mejia',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|MEJIA|:|SANTA_ROSA_(PI)',
    territory1Name: 'PICHINCHA',
    territory2Name: 'MEJIA',
    territory3Name: 'SANTA ROSA (PI)',
    searchableText: 'Pichincha - Mejia - Santa Rosa (PI)',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|MEJIA|:|TAMBILLO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'MEJIA',
    territory3Name: 'TAMBILLO',
    searchableText: 'Pichincha - Mejia - Tambillo',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|MEJIA|:|TANDAPI',
    territory1Name: 'PICHINCHA',
    territory2Name: 'MEJIA',
    territory3Name: 'TANDAPI',
    searchableText: 'Pichincha - Mejia - Tandapi',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|MEJIA|:|UYUMBICHO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'MEJIA',
    territory3Name: 'UYUMBICHO',
    searchableText: 'Pichincha - Mejia - Uyumbicho',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|PEDRO_MONCAYO_(TABACUNDO)|:|TABACUNDO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'PEDRO MONCAYO (TABACUNDO)',
    territory3Name: 'TABACUNDO',
    searchableText: 'Pichincha - Pedro Moncayo (Tabacundo) - Tabacundo',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|PEDRO_VICENTE_MALDONADO|:|PEDRO_VICENTE_MALDONADO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'PEDRO VICENTE MALDONADO',
    territory3Name: 'PEDRO VICENTE MALDONADO',
    searchableText: 'Pichincha - Pedro Vicente Maldonado - Pedro Vicente Maldonado',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|PUERTO_QUITO|:|PUERTO_QUITO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'PUERTO QUITO',
    territory3Name: 'PUERTO QUITO',
    searchableText: 'Pichincha - Puerto Quito - Puerto Quito',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|ALANGASI',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'ALANGASI',
    searchableText: 'Pichincha - Quito - Alangasi',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|AMAGUAÑA',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'AMAGUAÑA',
    searchableText: 'Pichincha - Quito - Amaguaña',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|BELISARIO_QUEVEDO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'BELISARIO QUEVEDO',
    searchableText: 'Pichincha - Quito - Belisario Quevedo',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|CALACALI',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'CALACALI',
    searchableText: 'Pichincha - Quito - Calacali',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|CALDERON_(PI)',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'CALDERON (PI)',
    searchableText: 'Pichincha - Quito - Calderon (PI)',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|CARCELEN',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'CARCELEN',
    searchableText: 'Pichincha - Quito - Carcelen',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|CENTRO_HISTORICO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'CENTRO HISTORICO',
    searchableText: 'Pichincha - Quito - Centro Historico',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|CHECA_(CHILPA)',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'CHECA (CHILPA)',
    searchableText: 'Pichincha - Quito - Checa (Chilpa)',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|CHILIBULO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'CHILIBULO',
    searchableText: 'Pichincha - Quito - Chilibulo',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|CHILLOGALLO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'CHILLOGALLO',
    searchableText: 'Pichincha - Quito - Chillogallo',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|CHIMBACALLE',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'CHIMBACALLE',
    searchableText: 'Pichincha - Quito - Chimbacalle',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|COCHAPAMBA',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'COCHAPAMBA',
    searchableText: 'Pichincha - Quito - Cochapamba',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|COMITE_DEL_PUEBLO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'COMITE DEL PUEBLO',
    searchableText: 'Pichincha - Quito - Comite Del Pueblo',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|CONOCOTO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'CONOCOTO',
    searchableText: 'Pichincha - Quito - Conocoto',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|COTOCOLLAO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'COTOCOLLAO',
    searchableText: 'Pichincha - Quito - Cotocollao',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|CUMBAYA',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'CUMBAYA',
    searchableText: 'Pichincha - Quito - Cumbaya',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|CUNUYACU',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'CUNUYACU',
    searchableText: 'Pichincha - Quito - Cunuyacu',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|EL_CONDADO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'EL CONDADO',
    searchableText: 'Pichincha - Quito - El Condado',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|EL_QUINCHE',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'EL QUINCHE',
    searchableText: 'Pichincha - Quito - El Quinche',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|GUAMANI',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'GUAMANI',
    searchableText: 'Pichincha - Quito - Guamani',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|GUANGOPOLO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'GUANGOPOLO',
    searchableText: 'Pichincha - Quito - Guangopolo',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|GUAYLLABAMBA',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'GUAYLLABAMBA',
    searchableText: 'Pichincha - Quito - Guayllabamba',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|ILALO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'ILALO',
    searchableText: 'Pichincha - Quito - Ilalo',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|INAUIO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'INAUIO',
    searchableText: 'Pichincha - Quito - Inauio',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|ITCHIMBIA',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'ITCHIMBIA',
    searchableText: 'Pichincha - Quito - Itchimbia',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|ITULCACHI',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'ITULCACHI',
    searchableText: 'Pichincha - Quito - Itulcachi',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|JIPIJAPA_(PI)',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'JIPIJAPA (PI)',
    searchableText: 'Pichincha - Quito - Jipijapa (PI)',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|KENNEDY',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'KENNEDY',
    searchableText: 'Pichincha - Quito - Kennedy',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|LA_ARGELIA',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'LA ARGELIA',
    searchableText: 'Pichincha - Quito - La Argelia',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|LA_ARMENIA',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'LA ARMENIA',
    searchableText: 'Pichincha - Quito - La Armenia',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|LA_CONCEPCION',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'LA CONCEPCION',
    searchableText: 'Pichincha - Quito - La Concepcion',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|LA_ECUATORIANA',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'LA ECUATORIANA',
    searchableText: 'Pichincha - Quito - La Ecuatoriana',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|LA_FERROVIARIA',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'LA FERROVIARIA',
    searchableText: 'Pichincha - Quito - La Ferroviaria',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|LA_LIBERTAD_(PI)',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'LA LIBERTAD (PI)',
    searchableText: 'Pichincha - Quito - La Libertad (PI)',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|LA_MAGDALENA',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'LA MAGDALENA',
    searchableText: 'Pichincha - Quito - La Magdalena',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|LA_MENA',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'LA MENA',
    searchableText: 'Pichincha - Quito - La Mena',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|LA_MERCED_(PI)',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'LA MERCED (PI)',
    searchableText: 'Pichincha - Quito - La Merced (PI)',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|LLANO_CHICO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'LLANO CHICO',
    searchableText: 'Pichincha - Quito - Llano Chico',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|LLANO_GRANDE',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'LLANO GRANDE',
    searchableText: 'Pichincha - Quito - Llano Grande',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|MARISCAL_SUCRE_(PI)',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'MARISCAL SUCRE (PI)',
    searchableText: 'Pichincha - Quito - Mariscal Sucre (PI)',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|MIRAVALLE',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'MIRAVALLE',
    searchableText: 'Pichincha - Quito - Miravalle',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|MITAD_DEL_MUNDO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'MITAD DEL MUNDO',
    searchableText: 'Pichincha - Quito - Mitad Del Mundo',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|MONJAS',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'MONJAS',
    searchableText: 'Pichincha - Quito - Monjas',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|NANEGAL',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'NANEGAL',
    searchableText: 'Pichincha - Quito - Nanegal',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|NANEGALITO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'NANEGALITO',
    searchableText: 'Pichincha - Quito - Nanegalito',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|NAYON',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'NAYON',
    searchableText: 'Pichincha - Quito - Nayon',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|PIFO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'PIFO',
    searchableText: 'Pichincha - Quito - Pifo',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|PINTAG',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'PINTAG',
    searchableText: 'Pichincha - Quito - Pintag',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|POMASQUI',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'POMASQUI',
    searchableText: 'Pichincha - Quito - Pomasqui',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|PONCEANO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'PONCEANO',
    searchableText: 'Pichincha - Quito - Ponceano',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|PUEMBO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'PUEMBO',
    searchableText: 'Pichincha - Quito - Puembo',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|PUENGASI',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'PUENGASI',
    searchableText: 'Pichincha - Quito - Puengasi',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|PUSUQUI',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'PUSUQUI',
    searchableText: 'Pichincha - Quito - Pusuqui',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|QUITO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'QUITO',
    searchableText: 'Pichincha - Quito - Quito',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|QUITUMBE',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'QUITUMBE',
    searchableText: 'Pichincha - Quito - Quitumbe',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|RUMIPAMBA',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'RUMIPAMBA',
    searchableText: 'Pichincha - Quito - Rumipamba',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|SAN_ANTONIO_(PI)',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'SAN ANTONIO (PI)',
    searchableText: 'Pichincha - Quito - San Antonio (PI)',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|SAN_BARTOLO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'SAN BARTOLO',
    searchableText: 'Pichincha - Quito - San Bartolo',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|SAN_ISIDRO_DEL_INCA',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'SAN ISIDRO DEL INCA',
    searchableText: 'Pichincha - Quito - San Isidro Del Inca',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|SAN_JOSE_DE_MORAN',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'SAN JOSE DE MORAN',
    searchableText: 'Pichincha - Quito - San Jose de Moran',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|SAN_JUAN_(PI)',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'SAN JUAN (PI)',
    searchableText: 'Pichincha - Quito - San Juan (PI)',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|SAN_JUAN_DE_CALDERON',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'SAN JUAN DE CALDERON',
    searchableText: 'Pichincha - Quito - San Juan De Calderon',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|SILLUNCHI',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'SILLUNCHI',
    searchableText: 'Pichincha - Quito - Sillunchi',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|SOLANDA',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'SOLANDA',
    searchableText: 'Pichincha - Quito - Solanda',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|TABABELA',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'TABABELA',
    searchableText: 'Pichincha - Quito - Tababela',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|TUMBACO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'TUMBACO',
    searchableText: 'Pichincha - Quito - Tumbaco',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|TURUBAMBA',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'TURUBAMBA',
    searchableText: 'Pichincha - Quito - Turubamba',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|YARUQUI',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'YARUQUI',
    searchableText: 'Pichincha - Quito - Yaruqui',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|ZAMBIZA',
    territory1Name: 'PICHINCHA',
    territory2Name: 'QUITO',
    territory3Name: 'ZAMBIZA',
    searchableText: 'Pichincha - Quito - Zambiza',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|RUMINAHUI|:|COTOGCHOA',
    territory1Name: 'PICHINCHA',
    territory2Name: 'RUMINAHUI',
    territory3Name: 'COTOGCHOA',
    searchableText: 'Pichincha - Ruminahui - Cotogchoa',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|RUMINAHUI|:|FAJARDO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'RUMINAHUI',
    territory3Name: 'FAJARDO',
    searchableText: 'Pichincha - Ruminahui - Fajardo',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|RUMINAHUI|:|RUMIPAMBA',
    territory1Name: 'PICHINCHA',
    territory2Name: 'RUMINAHUI',
    territory3Name: 'RUMIPAMBA',
    searchableText: 'Pichincha - Ruminahui - Rumipamba',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|RUMINAHUI|:|SAN_PEDRO_DE_TABOADA_(PI)',
    territory1Name: 'PICHINCHA',
    territory2Name: 'RUMINAHUI',
    territory3Name: 'SAN PEDRO DE TABOADA (PI)',
    searchableText: 'Pichincha - Ruminahui - San Pedro De Taboada (PI)',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|RUMINAHUI|:|SAN_RAFAEL_(PI)',
    territory1Name: 'PICHINCHA',
    territory2Name: 'RUMINAHUI',
    territory3Name: 'SAN RAFAEL (PI)',
    searchableText: 'Pichincha - Ruminahui - San Rafael (PI)',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|RUMINAHUI|:|SANGOLQUI',
    territory1Name: 'PICHINCHA',
    territory2Name: 'RUMINAHUI',
    territory3Name: 'SANGOLQUI',
    searchableText: 'Pichincha - Ruminahui - Sangolqui',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|SAN_MIGUEL_DE_LOS_BANCOS|:|MINDO',
    territory1Name: 'PICHINCHA',
    territory2Name: 'SAN MIGUEL DE LOS BANCOS',
    territory3Name: 'MINDO',
    searchableText: 'Pichincha - San Miguel De Los Bancos - Mindo',
  },
  {
    territoryBaseId: 'EC|:|PICHINCHA|:|SAN_MIGUEL_DE_LOS_BANCOS|:|SAN_MIGUEL_DE_LOS_BANCOS',
    territory1Name: 'PICHINCHA',
    territory2Name: 'SAN MIGUEL DE LOS BANCOS',
    territory3Name: 'SAN MIGUEL DE LOS BANCOS',
    searchableText: 'Pichincha - San Miguel De Los Bancos - San Miguel De Los Bancos',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|LA_LIBERTAD|:|LA_LIBERTAD',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'LA LIBERTAD',
    territory3Name: 'LA LIBERTAD',
    searchableText: 'Santa Elena - La Libertad - La Libertad',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|LA_LIBERTAD|:|PUNTA_CENTINELA',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'LA LIBERTAD',
    territory3Name: 'PUNTA CENTINELA',
    searchableText: 'Santa Elena - La Libertad - Punta Centinela',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SALINAS|:|ANCONCITO',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SALINAS',
    territory3Name: 'ANCONCITO',
    searchableText: 'Santa Elena - Salinas - Anconcito',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SALINAS|:|CARLOS_ESPINOZA_LARREA',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SALINAS',
    territory3Name: 'CARLOS ESPINOZA LARREA',
    searchableText: 'Santa Elena - Salinas - Carlos Espinoza Larrea',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SALINAS|:|GENERAL_ALBERTO_ENRIQUEZ_GALLO',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SALINAS',
    territory3Name: 'GENERAL ALBERTO ENRIQUEZ GALLO',
    searchableText: 'Santa Elena - Salinas - General Alberto Enriquez Gallo',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SALINAS|:|JOSE_LUIS_TAMAYO_(MUEY)',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SALINAS',
    territory3Name: 'JOSE LUIS TAMAYO (MUEY)',
    searchableText: 'Santa Elena - Salinas - Jose Luis Tamayo (Muey)',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SALINAS|:|PUNTA_CARNERO',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SALINAS',
    territory3Name: 'PUNTA CARNERO',
    searchableText: 'Santa Elena - Salinas - Punta Carnero',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SALINAS|:|SALINAS_(SE)',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SALINAS',
    territory3Name: 'SALINAS (SE)',
    searchableText: 'Santa Elena - Salinas - Salinas (SE)',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SALINAS|:|SANTA_ROSA_(SE)',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SALINAS',
    territory3Name: 'SANTA ROSA (SE)',
    searchableText: 'Santa Elena - Salinas - Santa Rosa (SE)',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SALINAS|:|VICENTE__ROCAFUERTE_(SE)',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SALINAS',
    territory3Name: 'VICENTE  ROCAFUERTE (SE)',
    searchableText: 'Santa Elena - Salinas - Vicente  Rocafuerte (SE)',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|ANCON_(SE)',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'ANCON (SE)',
    searchableText: 'Santa Elena - Santa Elena - Ancon (SE)',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|ATAHUALPA_(SE)',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'ATAHUALPA (SE)',
    searchableText: 'Santa Elena - Santa Elena - Atahualpa (SE)',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|AYANGUE',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'AYANGUE',
    searchableText: 'Santa Elena - Santa Elena - Ayangue',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|BALLENITA',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'BALLENITA',
    searchableText: 'Santa Elena - Santa Elena - Ballenita',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|BARCELONA',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'BARCELONA',
    searchableText: 'Santa Elena - Santa Elena - Barcelona',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|CADEATE',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'CADEATE',
    searchableText: 'Santa Elena - Santa Elena - Cadeate',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|CAPAES',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'CAPAES',
    searchableText: 'Santa Elena - Santa Elena - Capaes',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|CHANDUY',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'CHANDUY',
    searchableText: 'Santa Elena - Santa Elena - Chanduy',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|COLONCHE_(AYANQUE_/_JAMBELI_/_MONTEVERDE)',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'COLONCHE (AYANQUE / JAMBELI / MONTEVERDE)',
    searchableText: 'Santa Elena - Santa Elena - Colonche (Ayanque / Jambeli / Monteverde)',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|CURIA',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'CURIA',
    searchableText: 'Santa Elena - Santa Elena - Curia',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|EL_TAMBO',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'EL TAMBO',
    searchableText: 'Santa Elena - Santa Elena - El Tambo',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|LA_ENTRADA',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'LA ENTRADA',
    searchableText: 'Santa Elena - Santa Elena - La Entrada',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|LIBERTADOR_BOLIVAR',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'LIBERTADOR BOLIVAR',
    searchableText: 'Santa Elena - Santa Elena - Libertador Bolivar',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|MANGLARALTO',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'MANGLARALTO',
    searchableText: 'Santa Elena - Santa Elena - Manglaralto',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|MONTANITA',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'MONTANITA',
    searchableText: 'Santa Elena - Santa Elena - Montañita',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|OLON',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'OLON',
    searchableText: 'Santa Elena - Santa Elena - Olon',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|OLONCITO',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'OLONCITO',
    searchableText: 'Santa Elena - Santa Elena - Oloncito',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|PALMAR',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'PALMAR',
    searchableText: 'Santa Elena - Santa Elena - Palmar',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|PROSPERIDAD',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'PROSPERIDAD',
    searchableText: 'Santa Elena - Santa Elena - Prosperidad',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|PUNTA_BARANDUA',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'PUNTA BARANDUA',
    searchableText: 'Santa Elena - Santa Elena - Punta Barandua',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|PUNTA_BLANCA',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'PUNTA BLANCA',
    searchableText: 'Santa Elena - Santa Elena - Punta Blanca',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|SAN_JOSE',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'SAN JOSE',
    searchableText: 'Santa Elena - Santa Elena - San Jose',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|SAN_PABLO_(SE)',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'SAN PABLO (SE)',
    searchableText: 'Santa Elena - Santa Elena - San Pablo (SE)',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|SAN_PEDRO',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'SAN PEDRO',
    searchableText: 'Santa Elena - Santa Elena - San Pedro',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|SANTA_ELENA',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'SANTA ELENA',
    searchableText: 'Santa Elena - Santa Elena - Santa Elena',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|SINCHAL',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'SINCHAL',
    searchableText: 'Santa Elena - Santa Elena - Sinchal',
  },
  {
    territoryBaseId: 'EC|:|SANTA_ELENA|:|SANTA_ELENA|:|VALDIVIA',
    territory1Name: 'SANTA ELENA',
    territory2Name: 'SANTA ELENA',
    territory3Name: 'VALDIVIA',
    searchableText: 'Santa Elena - Santa Elena - Valdivia',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|LA_CONCORDIA|:|LA_CONCORDIA',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'LA CONCORDIA',
    territory3Name: 'LA CONCORDIA',
    searchableText: 'Santo Domingo De Los Tsachilas - La Concordia - La Concordia',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|LA_UNION|:|LA_UNION',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'LA UNION',
    territory3Name: 'LA UNION',
    searchableText: 'Santo Domingo De Los Tsachilas - La Union - La Union',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|LAS_MERCEDES|:|LIBERTAD_DEL_TOACHI',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'LAS MERCEDES',
    territory3Name: 'LIBERTAD DEL TOACHI',
    searchableText: 'Santo Domingo De Los Tsachilas - Las Mercedes - Libertad Del Toachi',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|ABRAHAM_CALAZACON',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'SANTO DOMINGO',
    territory3Name: 'ABRAHAM CALAZACON',
    searchableText: 'Santo Domingo De Los Tsachilas - Santo Domingo - Abraham Calazacon',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|ALLURIQUIN',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'SANTO DOMINGO',
    territory3Name: 'ALLURIQUIN',
    searchableText: 'Santo Domingo De Los Tsachilas - Santo Domingo - Alluriquin',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|BOMBOLI',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'SANTO DOMINGO',
    territory3Name: 'BOMBOLI',
    searchableText: 'Santo Domingo De Los Tsachilas - Santo Domingo - Bomboli',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|CHIGUILPE',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'SANTO DOMINGO',
    territory3Name: 'CHIGUILPE',
    searchableText: 'Santo Domingo De Los Tsachilas - Santo Domingo - Chiguilpe',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|EL_CADE',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'SANTO DOMINGO',
    territory3Name: 'EL CADE',
    searchableText: 'Santo Domingo De Los Tsachilas - Santo Domingo - El Cade',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|EL_ESFUERZO',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'SANTO DOMINGO',
    territory3Name: 'EL ESFUERZO',
    searchableText: 'Santo Domingo De Los Tsachilas - Santo Domingo - El Esfuerzo',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|INAEXPO',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'SANTO DOMINGO',
    territory3Name: 'INAEXPO',
    searchableText: 'Santo Domingo De Los Tsachilas - Santo Domingo - Inaexpo',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|KM_14_VIA_QUEVEDO',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'SANTO DOMINGO',
    territory3Name: 'KM 14 QUEVEDO',
    searchableText: 'Santo Domingo De Los Tsachilas - Santo Domingo - Km 14 Via Quevedo',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|KM_24_VIA_QUEVEDO',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'SANTO DOMINGO',
    territory3Name: 'KM 24 QUEVEDO',
    searchableText: 'Santo Domingo De Los Tsachilas - Santo Domingo - Km 24 Via Quevedo',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|KM_38.5_VIA_QUEVEDO',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'SANTO DOMINGO',
    territory3Name: 'KM 38.5 VIA QUEVEDO',
    searchableText: 'Santo Domingo De Los Tsachilas - Santo Domingo - Km 38.5 Via Quevedo',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|KM_41_VIA_QUEVEDO',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'SANTO DOMINGO',
    territory3Name: 'KM 41 VIA QUEVEDO',
    searchableText: 'Santo Domingo De Los Tsachilas - Santo Domingo - Km 41 Via Quevedo',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|LAS_DELICIAS',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'SANTO DOMINGO',
    territory3Name: 'LAS DELICIAS',
    searchableText: 'Santo Domingo De Los Tsachilas - Santo Domingo - Las Delicias',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|LUZ_DE_AMERICA_(KM26)',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'SANTO DOMINGO',
    territory3Name: 'LUZ DE AMERICA (KM26)',
    searchableText: 'Santo Domingo De Los Tsachilas - Santo Domingo - Luz De America (Km26)',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|NUEVO_ISRAEL',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'SANTO DOMINGO',
    territory3Name: 'NUEVO ISRAEL',
    searchableText: 'Santo Domingo De Los Tsachilas - Santo Domingo - Nuevo Israel',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|PUERTO_LIMON_(SD)',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'SANTO DOMINGO',
    territory3Name: 'PUERTO LIMON (SD)',
    searchableText: 'Santo Domingo De Los Tsachilas - Santo Domingo - Puerto Limon (SD)',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|RIO_TOACHI',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'SANTO DOMINGO',
    territory3Name: 'RIO TOACHI',
    searchableText: 'Santo Domingo De Los Tsachilas - Santo Domingo - Rio Toachi',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|RIO_VERDE_(SD)',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'SANTO DOMINGO',
    territory3Name: 'RIO VERDE (SD)',
    searchableText: 'Santo Domingo De Los Tsachilas - Santo Domingo - Rio Verde (SD)',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|SAN_JACINTO_DEL_BUA_(SD)',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'SANTO DOMINGO',
    territory3Name: 'SAN JACINTO DEL BUA (SD)',
    searchableText: 'Santo Domingo De Los Tsachilas - Santo Domingo - San Jacinto Del Bua (SD)',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|SANTO_DOMINGO_DE_LOS_COLORADOS',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'SANTO DOMINGO',
    territory3Name: 'SANTO DOMINGO DE LOS COLORADOS',
    searchableText: 'Santo Domingo De Los Tsachilas - Santo Domingo - Santo Domingo De Los Colorados',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|VALLE_HERMOSO',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'SANTO DOMINGO',
    territory3Name: 'VALLE HERMOSO',
    searchableText: 'Santo Domingo De Los Tsachilas - Santo Domingo - Valle Hermoso',
  },
  {
    territoryBaseId: 'EC|:|SANTO_DOMINGO_DE_LOS_TSACHILAS|:|SANTO_DOMINGO|:|ZARACAY',
    territory1Name: 'SANTO DOMINGO DE LOS TSACHILAS',
    territory2Name: 'SANTO DOMINGO',
    territory3Name: 'ZARACAY',
    searchableText: 'Santo Domingo De Los Tsachilas - Santo Domingo - Zaracay',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|CASCALES|:|CASCALES',
    territory1Name: 'SUCUMBIOS',
    territory2Name: 'CASCALES',
    territory3Name: 'CASCALES',
    searchableText: 'Sucumbios - Cascales - Cascales',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|CASCALES|:|SEVILLA',
    territory1Name: 'SUCUMBIOS',
    territory2Name: 'CASCALES',
    territory3Name: 'SEVILLA',
    searchableText: 'Sucumbios - Cascales - Sevilla',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|CUYABENO|:|CUYABENO',
    territory1Name: 'SUCUMBIOS',
    territory2Name: 'CUYABENO',
    territory3Name: 'CUYABENO',
    searchableText: 'Sucumbios - Cuyabeno - Cuyabeno',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|CUYABENO|:|TARAPOA',
    territory1Name: 'SUCUMBIOS',
    territory2Name: 'CUYABENO',
    territory3Name: 'TARAPOA',
    searchableText: 'Sucumbios - Cuyabeno - Tarapoa',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|LAGO_AGRIO|:|EL_ENO',
    territory1Name: 'SUCUMBIOS',
    territory2Name: 'LAGO AGRIO',
    territory3Name: 'EL ENO',
    searchableText: 'Sucumbios - Lago Agrio - El Eno',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|LAGO_AGRIO|:|NUEVA_LOJA_(LAGO_AGRIO)',
    territory1Name: 'SUCUMBIOS',
    territory2Name: 'LAGO AGRIO',
    territory3Name: 'NUEVA LOJA (LAGO AGRIO)',
    searchableText: 'Sucumbios - Lago Agrio - Nueva Loja (Lago Agrio)',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|LAGO_AGRIO|:|PACAYACU',
    territory1Name: 'SUCUMBIOS',
    territory2Name: 'LAGO AGRIO',
    territory3Name: 'PACAYACU',
    searchableText: 'Sucumbios - Lago Agrio - Pacayacu',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|LAGO_AGRIO|:|SANTA_CECILIA',
    territory1Name: 'SUCUMBIOS',
    territory2Name: 'LAGO AGRIO',
    territory3Name: 'SANTA CECILIA',
    searchableText: 'Sucumbios - Lago Agrio - Santa Cecilia',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|LUMBAQUI_(GONZALO_PIZARRO)|:|GONZALO_PIZARRO',
    territory1Name: 'SUCUMBIOS',
    territory2Name: 'LUMBAQUI (GONZALO PIZARRO)',
    territory3Name: 'GONZALO PIZARRO',
    searchableText: 'Sucumbios - Lumbaqui (Gonzalo Pizarro) - Gonzalo Pizarro',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|LUMBAQUI_(GONZALO_PIZARRO)|:|LUMBAQUI',
    territory1Name: 'SUCUMBIOS',
    territory2Name: 'LUMBAQUI (GONZALO PIZARRO)',
    territory3Name: 'LUMBAQUI',
    searchableText: 'Sucumbios - Lumbaqui (Gonzalo Pizarro) - Lumbaqui',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|PUTUMAYO|:|PUTUMAYO',
    territory1Name: 'SUCUMBIOS',
    territory2Name: 'PUTUMAYO',
    territory3Name: 'PUTUMAYO',
    searchableText: 'Sucumbios - Putumayo - Putumayo',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|SHUSHUFINDI|:|JIVINO_VERDE',
    territory1Name: 'SUCUMBIOS',
    territory2Name: 'SHUSHUFINDI',
    territory3Name: 'JIVINO VERDE',
    searchableText: 'Sucumbios - Shushufindi - Jivino Verde',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|SHUSHUFINDI|:|SAN_PEDRO_DE_LOS_COFANES',
    territory1Name: 'SUCUMBIOS',
    territory2Name: 'SHUSHUFINDI',
    territory3Name: 'SAN PEDRO DE LOS COFANES',
    searchableText: 'Sucumbios - Shushufindi - San Pedro De Los Cofanes',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|SHUSHUFINDI|:|SHUSHUFINDI',
    territory1Name: 'SUCUMBIOS',
    territory2Name: 'SHUSHUFINDI',
    territory3Name: 'SHUSHUFINDI',
    searchableText: 'Sucumbios - Shushufindi - Shushufindi',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|SHUSHUFINDI|:|SIETE_DE_JULIO',
    territory1Name: 'SUCUMBIOS',
    territory2Name: 'SHUSHUFINDI',
    territory3Name: 'SIETE DE JULIO',
    searchableText: 'Sucumbios - Shushufindi - Siete De Julio',
  },
  {
    territoryBaseId: 'EC|:|SUCUMBIOS|:|SUCUMBIOS|:|SUCUMBIOS',
    territory1Name: 'SUCUMBIOS',
    territory2Name: 'SUCUMBIOS',
    territory3Name: 'SUCUMBIOS',
    searchableText: 'Sucumbios - Sucumbios - Sucumbios',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|AMBATILLO',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'AMBATO',
    territory3Name: 'AMBATILLO',
    searchableText: 'Tungurahua - Ambato - Ambatillo',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|AMBATO',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'AMBATO',
    territory3Name: 'AMBATO',
    searchableText: 'Tungurahua - Ambato - Ambato',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|ATAHUALPA_(CHISALATA)_(TU)',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'AMBATO',
    territory3Name: 'ATAHUALPA (CHISALATA) (TU)',
    searchableText: 'Tungurahua - Ambato - Atahualpa (Chisalata) (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|ATOCHA_FICOA',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'AMBATO',
    territory3Name: 'ATOCHA FICOA',
    searchableText: 'Tungurahua - Ambato - Atocha – Ficoa',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|AUGUSTO_N._MARTINEZ_(MUNDUGLEO)',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'AMBATO',
    territory3Name: 'AUGUSTO N. MARTINEZ (MUNDUGLEO)',
    searchableText: 'Tungurahua - Ambato - Augusto N. Martinez (Mundugleo)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|CELIANO_MONGE',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'AMBATO',
    territory3Name: 'CELIANO MONGE',
    searchableText: 'Tungurahua - Ambato - Celiano Monge',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|HUACHI_CHICO_(TU)',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'AMBATO',
    territory3Name: 'HUACHI CHICO (TU)',
    searchableText: 'Tungurahua - Ambato - Huachi Chico (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|HUACHI_GRANDE_(TU)',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'AMBATO',
    territory3Name: 'HUACHI GRANDE (TU)',
    searchableText: 'Tungurahua - Ambato - Huachi Grande (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|HUACHI_LORETO_(TU)',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'AMBATO',
    territory3Name: 'HUACHI LORETO (TU)',
    searchableText: 'Tungurahua - Ambato - Huachi Loreto (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|IZAMBA',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'AMBATO',
    territory3Name: 'IZAMBA',
    searchableText: 'Tungurahua - Ambato - Izamba',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|LA_MERCED_(TU)',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'AMBATO',
    territory3Name: 'LA MERCED (TU)',
    searchableText: 'Tungurahua - Ambato - La Merced (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|LA_PENINSULA_(TU)',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'AMBATO',
    territory3Name: 'LA PENINSULA (TU)',
    searchableText: 'Tungurahua - Ambato - La Peninsula (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|MATRIZ_(TU)',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'AMBATO',
    territory3Name: 'MATRIZ (TU)',
    searchableText: 'Tungurahua - Ambato - Matriz (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|MONTALVO_(TU)',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'AMBATO',
    territory3Name: 'MONTALVO (TU)',
    searchableText: 'Tungurahua - Ambato - Montalvo (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|PICAIGUA',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'AMBATO',
    territory3Name: 'PICAIGUA',
    searchableText: 'Tungurahua - Ambato - Picaigua',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|PISHILATA',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'AMBATO',
    territory3Name: 'PISHILATA',
    searchableText: 'Tungurahua - Ambato - Pishilata',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|QUISAPINCHA',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'AMBATO',
    territory3Name: 'QUISAPINCHA',
    searchableText: 'Tungurahua - Ambato - Quisapincha',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|SAN_BARTOLOME_DE_PINLLO',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'AMBATO',
    territory3Name: 'SAN BARTOLOME DE PINLLO',
    searchableText: 'Tungurahua - Ambato - San Bartolome De Pinllo',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|SAN_FRANCISCO_(TU)',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'AMBATO',
    territory3Name: 'SAN FRANCISCO (TU)',
    searchableText: 'Tungurahua - Ambato - San Francisco (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|SANTA_ROSA_(TU)',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'AMBATO',
    territory3Name: 'SANTA ROSA (TU)',
    searchableText: 'Tungurahua - Ambato - Santa Rosa (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|AMBATO|:|TOTORAS',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'AMBATO',
    territory3Name: 'TOTORAS',
    searchableText: 'Tungurahua - Ambato - Totoras',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|BANOS_DE_AGUA_SANTA|:|BAÑOS_DE_AGUA_SANTA_(TU)',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'BANOS DE AGUA SANTA',
    territory3Name: 'BAÑOS DE AGUA SANTA (TU)',
    searchableText: 'Tungurahua - Banos De Agua Santa - Baños De Agua Santa (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|BANOS_DE_AGUA_SANTA|:|LLIGUA',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'BANOS DE AGUA SANTA',
    territory3Name: 'LLIGUA',
    searchableText: 'Tungurahua - Banos De Agua Santa - Lligua',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|BANOS_DE_AGUA_SANTA|:|RIO_NEGRO_(TU)',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'BANOS DE AGUA SANTA',
    territory3Name: 'RIO NEGRO (TU)',
    searchableText: 'Tungurahua - Banos De Agua Santa - Rio Negro (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|BANOS_DE_AGUA_SANTA|:|RIO_VERDE_(TU)',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'BANOS DE AGUA SANTA',
    territory3Name: 'RIO VERDE (TU)',
    searchableText: 'Tungurahua - Banos De Agua Santa - Rio Verde (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|BANOS_DE_AGUA_SANTA|:|ULBA',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'BANOS DE AGUA SANTA',
    territory3Name: 'ULBA',
    searchableText: 'Tungurahua - Banos De Agua Santa - Ulba',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|CEVALLOS|:|CEVALLOS',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'CEVALLOS',
    territory3Name: 'CEVALLOS',
    searchableText: 'Tungurahua - Cevallos - Cevallos',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|MOCHA|:|MOCHA',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'MOCHA',
    territory3Name: 'MOCHA',
    searchableText: 'Tungurahua - Mocha - Mocha',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|PATATE|:|PATATE',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'PATATE',
    territory3Name: 'PATATE',
    searchableText: 'Tungurahua - Patate - Patate',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|QUERO|:|QUERO',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'QUERO',
    territory3Name: 'QUERO',
    searchableText: 'Tungurahua - Quero - Quero',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SAN_PEDRO_DE_PELILEO|:|BENITEZ_(PACHANLICA)',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'SAN PEDRO DE PELILEO',
    territory3Name: 'BENITEZ (PACHANLICA)',
    searchableText: 'Tungurahua - San Pedro De Pelileo - Benitez (Pachanlica)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SAN_PEDRO_DE_PELILEO|:|BOLIVAR',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'SAN PEDRO DE PELILEO',
    territory3Name: 'BOLIVAR',
    searchableText: 'Tungurahua - San Pedro De Pelileo - Bolivar',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SAN_PEDRO_DE_PELILEO|:|CHIQUICHA_(CAB._EN_CHIQUICHA_GRANDE)',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'SAN PEDRO DE PELILEO',
    territory3Name: 'CHIQUICHA (CAB. EN CHIQUICHA GRANDE)',
    searchableText: 'Tungurahua - San Pedro De Pelileo - Chiquicha (Cab. En Chiquicha Grande)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SAN_PEDRO_DE_PELILEO|:|COTALO',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'SAN PEDRO DE PELILEO',
    territory3Name: 'COTALO',
    searchableText: 'Tungurahua - San Pedro De Pelileo - Cotalo',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SAN_PEDRO_DE_PELILEO|:|EL_ROSARIO_(RUMICHACA)',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'SAN PEDRO DE PELILEO',
    territory3Name: 'EL ROSARIO (RUMICHACA)',
    searchableText: 'Tungurahua - San Pedro De Pelileo - El Rosario (Rumichaca)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SAN_PEDRO_DE_PELILEO|:|GARCIA_MORENO_(CHUMAQUI)',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'SAN PEDRO DE PELILEO',
    territory3Name: 'GARCIA MORENO (CHUMAQUI)',
    searchableText: 'Tungurahua - San Pedro De Pelileo - Garcia Moreno (Chumaqui)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SAN_PEDRO_DE_PELILEO|:|HUAMBALO',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'SAN PEDRO DE PELILEO',
    territory3Name: 'HUAMBALO',
    searchableText: 'Tungurahua - San Pedro De Pelileo - Huambalo',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SAN_PEDRO_DE_PELILEO|:|PELILEO_(TU)',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'SAN PEDRO DE PELILEO',
    territory3Name: 'PELILEO (TU)',
    searchableText: 'Tungurahua - San Pedro De Pelileo - Pelileo (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SAN_PEDRO_DE_PELILEO|:|PELILEO_GRANDE_(TU)',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'SAN PEDRO DE PELILEO',
    territory3Name: 'PELILEO GRANDE (TU)',
    searchableText: 'Tungurahua - San Pedro De Pelileo - Pelileo Grande (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SAN_PEDRO_DE_PELILEO|:|SALASACA',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'SAN PEDRO DE PELILEO',
    territory3Name: 'SALASACA',
    searchableText: 'Tungurahua - San Pedro De Pelileo - Salasaca',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SANTIAGO_DE_PILLARO|:|BAQUERIZO_MORENO',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'SANTIAGO DE PILLARO',
    territory3Name: 'BAQUERIZO MORENO',
    searchableText: 'Tungurahua - Santiago De Pillaro - Baquerizo Moreno',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SANTIAGO_DE_PILLARO|:|CIUDAD_NUEVA',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'SANTIAGO DE PILLARO',
    territory3Name: 'CIUDAD NUEVA',
    searchableText: 'Tungurahua - Santiago De Pillaro - Ciudad Nueva',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SANTIAGO_DE_PILLARO|:|EMILIO_MARIA_TERAN',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'SANTIAGO DE PILLARO',
    territory3Name: 'EMILIO MARIA TERAN',
    searchableText: 'Tungurahua - Santiago De Pillaro - Emilio Maria Teran',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SANTIAGO_DE_PILLARO|:|MARCOS_ESPINEL',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'SANTIAGO DE PILLARO',
    territory3Name: 'MARCOS ESPINEL',
    searchableText: 'Tungurahua - Santiago De Pillaro - Marcos Espinel',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SANTIAGO_DE_PILLARO|:|PILLARO',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'SANTIAGO DE PILLARO',
    territory3Name: 'PILLARO',
    searchableText: 'Tungurahua - Santiago De Pillaro - Pillaro',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SANTIAGO_DE_PILLARO|:|PRESIDENTE_URBINA',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'SANTIAGO DE PILLARO',
    territory3Name: 'PRESIDENTE URBINA',
    searchableText: 'Tungurahua - Santiago De Pillaro - Presidente Urbina',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SANTIAGO_DE_PILLARO|:|SAN_ANDRES_(TU)',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'SANTIAGO DE PILLARO',
    territory3Name: 'SAN ANDRES (TU)',
    searchableText: 'Tungurahua - Santiago De Pillaro - San Andres (TU)',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|SANTIAGO_DE_PILLARO|:|SAN_MIGUELITO',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'SANTIAGO DE PILLARO',
    territory3Name: 'SAN MIGUELITO',
    searchableText: 'Tungurahua - Santiago De Pillaro - San Miguelito',
  },
  {
    territoryBaseId: 'EC|:|TUNGURAHUA|:|TISALEO|:|TISALEO',
    territory1Name: 'TUNGURAHUA',
    territory2Name: 'TISALEO',
    territory3Name: 'TISALEO',
    searchableText: 'Tungurahua - Tisaleo - Tisaleo',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|CENTINELA_DEL_CONDOR|:|ZUMBI',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Name: 'CENTINELA DEL CONDOR',
    territory3Name: 'ZUMBI',
    searchableText: 'Zamora Chinchipe - Centinela Del Condor - Zumbi',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|CHINCHIPE|:|ZUMBA',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Name: 'CHINCHIPE',
    territory3Name: 'ZUMBA',
    searchableText: 'Zamora Chinchipe - Chinchipe - Zumba',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|EL_PANGUI|:|EL_PANGUI',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Name: 'EL PANGUI',
    territory3Name: 'EL PANGUI',
    searchableText: 'Zamora Chinchipe - El Pangui - El Pangui',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|EL_PANGUI|:|PACHICUTZA',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Name: 'EL PANGUI',
    territory3Name: 'PACHICUTZA',
    searchableText: 'Zamora Chinchipe - El Pangui - Pachicutza',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|NANGARITZA|:|NANGARITZA',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Name: 'NANGARITZA',
    territory3Name: 'NANGARITZA',
    searchableText: 'Zamora Chinchipe - Nangaritza - Nangaritza',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|PALANDA|:|PALANDA',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Name: 'PALANDA',
    territory3Name: 'PALANDA',
    searchableText: 'Zamora Chinchipe - Palanda - Palanda',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|PAQUISHA|:|PAQUISHA',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Name: 'PAQUISHA',
    territory3Name: 'PAQUISHA',
    searchableText: 'Zamora Chinchipe - Paquisha - Paquisha',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|YACUAMBI|:|YACUAMBI',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Name: 'YACUAMBI',
    territory3Name: 'YACUAMBI',
    searchableText: 'Zamora Chinchipe - Yacuambi - Yacuambi',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|YANTZAZA|:|LOS_ENCUENTROS',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Name: 'YANTZAZA',
    territory3Name: 'LOS ENCUENTROS',
    searchableText: 'Zamora Chinchipe - Yantzaza - Los Encuentros',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|YANTZAZA|:|YANTZAZA',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Name: 'YANTZAZA',
    territory3Name: 'YANTZAZA',
    searchableText: 'Zamora Chinchipe - Yantzaza - Yantzaza',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|ZAMORA|:|CHAMICO',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Name: 'ZAMORA',
    territory3Name: 'CHAMICO',
    searchableText: 'Zamora Chinchipe - Zamora - Chamico',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|ZAMORA|:|CUMBARATZA',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Name: 'ZAMORA',
    territory3Name: 'CUMBARATZA',
    searchableText: 'Zamora Chinchipe - Zamora - Cumbaratza',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|ZAMORA|:|EL_LIMON',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Name: 'ZAMORA',
    territory3Name: 'EL LIMON',
    searchableText: 'Zamora Chinchipe - Zamora - El Limon',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|ZAMORA|:|GUADALUPE',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Name: 'ZAMORA',
    territory3Name: 'GUADALUPE',
    searchableText: 'Zamora Chinchipe - Zamora - Guadalupe',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|ZAMORA|:|GUAYZIMI',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Name: 'ZAMORA',
    territory3Name: 'GUAYZIMI',
    searchableText: 'Zamora Chinchipe - Zamora - Guayzimi',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|ZAMORA|:|NAMIREZ',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Name: 'ZAMORA',
    territory3Name: 'NAMIREZ',
    searchableText: 'Zamora Chinchipe - Zamora - Namirez',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|ZAMORA|:|PIUNTZA',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Name: 'ZAMORA',
    territory3Name: 'PIUNTZA',
    searchableText: 'Zamora Chinchipe - Zamora - Piuntza',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|ZAMORA|:|TIMBARA',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Name: 'ZAMORA',
    territory3Name: 'TIMBARA',
    searchableText: 'Zamora Chinchipe - Zamora - Timbara',
  },
  {
    territoryBaseId: 'EC|:|ZAMORA_CHINCHIPE|:|ZAMORA|:|ZAMORA',
    territory1Name: 'ZAMORA CHINCHIPE',
    territory2Name: 'ZAMORA',
    territory3Name: 'ZAMORA',
    searchableText: 'Zamora Chinchipe - Zamora - Zamora',
  },
];
