import {UserSettingDTO} from '../models/user-setting-dto.definitions';
import {
  SelectorValue,
  UserSettings,
  GeneralUserSettings,
  ReportingUserSettings,
  ContactInformationUserSettings,
  DefaultTimePeriod,
  isDefaultTimePeriod,
} from '../models/user-settings.definition';
import {BszObjectId} from '@basuiz/web-app-applet-api';
import {getUserSettingsGroupMap, UserSettingsGroup} from '../models/user-settings-group.definition';

export class UserSettingsDeserializer {
  private groupedDTOs: {[group: string]: {[key: string]: UserSettingDTO}};
  private readonly userSettingsGroupMap = getUserSettingsGroupMap();

  constructor(dtoList: UserSettingDTO[]) {
    this.groupDTOs(dtoList);
  }

  public deserialize(): UserSettings {
    const general: GeneralUserSettings = {
      mobileAppEnabled: this.extractBoolean('general', 'mobileAppEnabled'),
      defaultReferenceCurrency: this.extractNumber('general', 'defaultReferenceCurrency'),
      preferredLanguage: this.extractString('general', 'preferredLanguage'),
      mobileTransactionSigningEnabled: this.extractBoolean('general', 'mobileTransactionSigningEnabled'),
    };

    const reporting: ReportingUserSettings = {
      defaultTimePeriod: this.extractDefaultTimePeriod(),
    };

    const contactInformation: ContactInformationUserSettings = {
      defaultEmailAddress: this.extractNumber('contactInformation', 'defaultEmailAddress'),
      defaultMobileAddress: this.extractNumber('contactInformation', 'defaultMobileAddress'),
    };

    return {
      general,
      reporting,
      contactInformation,
    };
  }

  private groupDTOs(dtoList: UserSettingDTO[]) {
    this.groupedDTOs = {};
    dtoList.forEach((dto) => {
      if (!this.groupedDTOs[dto.groupName]) {
        this.groupedDTOs[dto.groupName] = {};
      }
      this.groupedDTOs[dto.groupName][dto.key] = dto;
    });
  }

  private shouldShowWarningForMissingUserSetting(group: UserSettingsGroup, key: string): boolean {
    // TODO clean
    return true;
  }

  private getDTOValue(groupFrontName: UserSettingsGroup, key: string, isMultivalue: boolean = false): unknown {
    const groupDtoName = this.userSettingsGroupMap[groupFrontName];
    const dto = this.groupedDTOs[groupDtoName] ? this.groupedDTOs[groupDtoName][key] : null;
    if (!dto) {
      if (this.shouldShowWarningForMissingUserSetting(groupFrontName, key)) {
        console.warn(`No user setting found in the response for group / key: ${groupDtoName} / ${key}`);
      }
      return null;
    }
    return isMultivalue ? dto.selectorValues : dto.value;
  }

  private extractString(groupName: UserSettingsGroup, key: string): string | null {
    const value = this.getDTOValue(groupName, key);
    if (value === null) return null;
    if (typeof value !== 'string') {
      throw new Error(`Expected a string but received ${typeof value}: ${value}`);
    }
    return value === '' ? null : value;
  }

  private extractBoolean(groupName: UserSettingsGroup, key: string): boolean | null {
    const value = this.getDTOValue(groupName, key);
    if (value === 'true') return true;
    if (value === 'false') return false;
    if (value === null) return null;
    throw new Error(`Expected boolean-like string or null but received ${typeof value}: ${value}`);
  }

  private extractNumber(groupName: UserSettingsGroup, key: string): number | null {
    const value = this.getDTOValue(groupName, key);
    if (value === null) return null;
    if (typeof value !== 'string') {
      throw new Error(`Expected a number-like 'string' but received ${typeof value}: ${value}`);
    }
    if (value === '') return null;
    const valueAsNumber = Number(value);
    if (Number.isNaN(valueAsNumber)) {
      throw new Error(`Expected a number-like string but received ${value}`);
    }
    return valueAsNumber;
  }

  private extractDefaultTimePeriod(): null | DefaultTimePeriod {
    const rawValue = this.getDTOValue('reporting', 'defaultTimePeriod');
    if (rawValue === null || rawValue === '') {
      return null;
    } else if (typeof rawValue !== 'string') {
      throw new Error(`Expected an object-like string but received type ${typeof rawValue}`);
    } else {
      const value = JSON.parse(rawValue);
      if (isDefaultTimePeriod(value)) {
        return value;
      } else {
        throw new Error(`The value ${rawValue} is not compatible with type DefaultTimePeriod `);
      }
    }
  }

  private extractStringSelectorValueList(groupName: UserSettingsGroup, key: string): SelectorValue<string>[] {
    const value = this.getDTOValue(groupName, key, true);
    if (value === null) return [];
    if (!Array.isArray(value)) {
      throw new Error(`Expected array but received ${typeof value}: ${value}`);
    }
    return value.map((sv: unknown) => {
      if (!this.isSelectorValue(sv)) {
        throw new Error(`Expected {selector: string, value: string} like object but got: ${sv}`);
      }
      return {selector: Number(sv.selector), value: sv.value};
    });
  }

  private extractObjectIdSelectorValueList(groupName: UserSettingsGroup, key: string): SelectorValue<BszObjectId>[] {
    const svList = this.extractStringSelectorValueList(groupName, key);
    return svList.map((sv) => ({...sv, value: Number(sv.value)}));
  }

  private isSelectorValue(sv: unknown): sv is SelectorValue<string> {
    return sv instanceof Object && typeof (sv as any).selector === 'string' && typeof (sv as any).value === 'string';
  }
}
