import {PortalModule} from '@angular/cdk/portal';
import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  Host,
  Injectable,
  InjectionToken,
  NgModule,
  Optional,
  Type,
  ViewEncapsulation,
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {BszI18nModule} from '@basuiz/i18n';

import {webcoat_deprecated} from '../../common/webcoat-deprecated';
import {BszNotificationOverlay, BszNotificationOverlayModule} from '../bsz-notification-overlay';
import {
  BszNotificationToast,
  BszNotificationToastAction,
  BszNotificationToastActions,
  BszNotificationToastIcon,
  BszNotificationToastSummary,
  BszNotificationToastTitle,
} from './bsz-notification-toast';
import {BszNotificationToastConfig} from './bsz-notification-toast.definitions';
import {BszNotificationToastService} from './bsz-notification-toast.service';

export const BSZ_NOTIFICATION_DEFAULT_OPTIONS = new InjectionToken<Partial<BszNotificationToastConfig>>(
  'bsz_notification_default_options'
);

@Directive({
  selector: 'bsz-notification-icon',
})
export class BszNotificationIcon extends BszNotificationToastIcon {}

@Directive({
  selector: 'bsz-notification-title',
})
export class BszNotificationTitle extends BszNotificationToastTitle {}

@Directive({
  selector: 'bsz-notification-summary',
})
export class BszNotificationSummary extends BszNotificationToastSummary {}

@Directive({
  selector: 'bsz-notification-actions',
})
export class BszNotificationActions extends BszNotificationToastActions {}

/** @deprecated use bsz-notification-toast instead */
@Component({
  selector: 'bsz-notification',
  templateUrl: './bsz-notification-toast.html',
  styleUrls: ['./bsz-notification-toast.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BszNotification extends BszNotificationToast {
  @ContentChild(BszNotificationIcon) override notificationIcon: BszNotificationIcon;
  constructor(@Optional() notificationOverlayRef: BszNotificationOverlay, elementRef: ElementRef) {
    super(notificationOverlayRef, elementRef);
    webcoat_deprecated(
      '[bsz-notification-toast] "BszNotificationModule" is DEPRECATED since v3.11.0. Use "BszNotificationToastModule" and the updated selectors instead.'
    );
  }
}

@Component({
  selector: 'bsz-notification-action',
  template: ` <button
    mat-button
    class="bsz-text-bold"
    color="primary"
    (click)="dismiss()"
    [disabled]="_disabled"
    [attr.aria-label]="ariaLabel || null"
    [attr.aria-labelledby]="ariaLabelledby || null"
  >
    <ng-content></ng-content>
  </button>`,
})
export class BszNotificationAction extends BszNotificationToastAction {
  constructor(@Host() notification: BszNotification) {
    super(notification);
  }
}

/** @deprecated use BszNotificationToastService instead */
@Injectable({
  providedIn: 'root',
})
export class BszNotificationService extends BszNotificationToastService {
  override open<T>(componentRef: Type<T>, config?: BszNotificationToastConfig): T {
    return super.open(componentRef, config);
  }
}

/** @deprecated use BszNotificationModule instead */
@NgModule({
  imports: [
    CommonModule,
    BszI18nModule,
    MatButtonModule,
    MatRippleModule,
    PortalModule,
    BszNotificationOverlayModule,
    MatIconModule,
  ],
  declarations: [
    BszNotification,
    BszNotificationTitle,
    BszNotificationSummary,
    BszNotificationIcon,
    BszNotificationActions,
    BszNotificationAction,
  ],
  exports: [
    BszNotification,
    BszNotificationTitle,
    BszNotificationSummary,
    BszNotificationIcon,
    BszNotificationActions,
    BszNotificationAction,
  ],
  entryComponents: [BszNotification],
  providers: [BszNotificationService],
})
export class BszNotificationModule {}
