import {Pipe, PipeTransform} from '@angular/core';

import {isUndefined} from '../../helpers/formatting-helpers';
import {isValueWithFormat, TypeOfValue} from './value-with-format.definitions';

import {BszCurrencyPipe} from '../../pipes/common/currency.pipe';
import {BszDatePipe} from '../../pipes/common/date.pipe';
import {BszIbanPipe} from '../../pipes/common/iban.pipe';
import {BszLinkPipe} from '../../pipes/common/link.pipe';
import {BszNumberPipe} from '../../pipes/common/number.pipe';
import {BszPercentagePipe} from '../../pipes/common/percentage.pipe';
import {BszPerformancePipe} from '../../pipes/common/performance.pipe';
import {BszStringPipe} from '../../pipes/common/string.pipe';
import {BszQuantityPipe} from '../common/quantity.pipe';

@Pipe({name: 'bszValueWithFormat'})
export class BszValueWithFormatPipe implements PipeTransform {
  constructor(
    private bszCurrencyPipe: BszCurrencyPipe,
    private bszDatePipe: BszDatePipe,
    private bszIbanPipe: BszIbanPipe,
    private bszLinkPipe: BszLinkPipe,
    private bszNumberPipe: BszNumberPipe,
    private bszPercentagePipe: BszPercentagePipe,
    private bszPerformancePipe: BszPerformancePipe,
    private bszQuantityPipe: BszQuantityPipe,
    private bszStringPipe: BszStringPipe
  ) {}

  private getGracefulResult(value: any): string {
    switch (typeof value) {
      case 'string':
        return value;

      case 'number':
      case 'boolean':
        return '' + value;

      default:
        return '';
    }
  }

  public transform(obj: any): string | null {
    // return empty string for nil param
    if (isUndefined(obj)) {
      return '';
    }

    // type of value is recognized => dispatch to another pipe
    if (!isUndefined(isValueWithFormat(obj))) {
      switch (obj.type) {
        case TypeOfValue.CURRENCY:
          return this.bszCurrencyPipe.transform(<number>obj.value, obj.isoCode, undefined, obj.showISOCode);

        case TypeOfValue.DATE:
          return this.bszDatePipe.transform(<number | string | Date>obj.value, {format: obj.format});

        case TypeOfValue.IBAN:
          return this.bszIbanPipe.transform(<string>obj.value);

        case TypeOfValue.LINK:
          return this.bszLinkPipe.transform(<string>obj.value);

        case TypeOfValue.NUMBER:
          return this.bszNumberPipe.transform(<number>obj.value, obj.digitsInfo);

        case TypeOfValue.PERCENTAGE:
          return this.bszPercentagePipe.transform(<number>obj.value, obj.digitsInfo);

        case TypeOfValue.PERFORMANCE:
          return this.bszPerformancePipe.transform(<number>obj.value, obj.digitsInfo);

        case TypeOfValue.QUANTITY:
          return this.bszQuantityPipe.transform(<number>obj.value);

        case TypeOfValue.STRING:
          return this.bszStringPipe.transform(<string>obj.value);
      }
    }

    // type or value properties are present, but it's not a known type => fail gracefully
    if (typeof obj.type !== 'undefined' || typeof obj.value !== 'undefined') {
      return this.getGracefulResult(obj.value);
    }

    // it's either unrecognized FormattedObject or a not an object at all => fail gracefully
    return this.getGracefulResult(obj);
  }
}
