import {AnimationEvent} from '@angular/animations';
import {BooleanInput, coerceBooleanProperty, coerceNumberProperty} from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import {AsyncSubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {BszScrollObserver, ScrollObserverDirection} from '../bsz-scroll-observer/bsz-scroll-observer';
import {BszPageHeaderAnimations} from './bsz-page-header-content-animation';

export type PageScrollEvent = 'thresholdPass' | 'directionChange';

export interface ChangeEvent {
  event: PageScrollEvent;
  value: boolean | ScrollObserverDirection;
}

@Component({
  selector: 'bsz-page-header-content',
  templateUrl: './bsz-page-header-content.html',
  animations: [BszPageHeaderAnimations.pageHeaderContentTransition],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BszPageHeaderContent {
  @Input() set collapsible(value: BooleanInput) {
    this._collapsible = coerceBooleanProperty(value);
  }

  get collapsible(): BooleanInput {
    return this._collapsible;
  }

  @Input() event: PageScrollEvent | undefined;

  private _collapsible: boolean;
  /** @private */
  _isCollapsed = false;

  constructor(private readonly elementRef: ElementRef, private readonly changeDetector: ChangeDetectorRef) {}

  /** @private */
  _setCollapsedByThresholdPass(isCollapsed: boolean) {
    if (!this._collapsible || this.event === 'directionChange') {
      return;
    }
    this._isCollapsed = isCollapsed;
    this.changeDetector.detectChanges();
  }

  /** @private */
  _setCollapsedByDirectionChange(direction: ScrollObserverDirection) {
    if (!this._collapsible || !this.event || this.event === 'thresholdPass') {
      return;
    }
    this._isCollapsed = direction === 'down';
    this.changeDetector.detectChanges();
  }

  /** @private */
  _onTransitionStart(event: AnimationEvent) {
    const element = this.elementRef.nativeElement;
    if (event.toState === 'expanded') {
      element.removeAttribute('hidden');
    }
  }

  /** @private */
  _onTransitionEnd(event: AnimationEvent) {
    const element = this.elementRef.nativeElement;
    if (event.toState === 'collapsed') {
      element.setAttribute('hidden', '');
    }
  }
}

@Component({
  selector: 'bsz-page-header-toolbar',
  template: `<ng-content></ng-content>`,
  animations: [BszPageHeaderAnimations.pageHeaderShadowTransition],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BszPageHeaderToolbar {}

@Component({
  selector: 'bsz-page-header',
  templateUrl: './bsz-page-header.html',
  styleUrls: ['./bsz-page-header.scss'],
  animations: [BszPageHeaderAnimations.pageHeaderShadowTransition],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BszPageHeader implements AfterViewInit, OnDestroy {
  @ContentChildren(BszPageHeaderContent, {descendants: true})
  pageHeaderCollapsedElements: QueryList<BszPageHeaderContent>;

  @ContentChild(BszPageHeaderToolbar, {static: false})
  pageHeaderToolbar: BszPageHeaderToolbar;

  @Input() scrollElement: HTMLElement;

  @Input() set verticalThreshold(threshold: number | string) {
    this._verticalThreshold = coerceNumberProperty(threshold);
  }

  @Input() set shadowed(shadowed: BooleanInput) {
    this._shadowed = coerceBooleanProperty(shadowed);
  }

  @Input() shadowSwitchEvent: PageScrollEvent | undefined;

  @Output() change = new EventEmitter<ChangeEvent>();

  private _verticalThreshold = 10;
  /** @private */
  _thresholdPassed = false;
  /** @private */
  _direction: ScrollObserverDirection;
  /** @private */
  _shadowed = false;
  /** @private */
  _shadowedToolbar = true;
  /** @private */
  _shadowedHeader = false;
  /** @private */
  _hasToolbar = false;

  private readonly destroy = new AsyncSubject<void>();

  constructor(private readonly scrollObserver: BszScrollObserver, private readonly changeDetector: ChangeDetectorRef) {}

  ngAfterViewInit() {
    const {thresholdPassObserver, directionChangeObserver} = this.scrollObserver.getObservers({
      scrollElement: this.scrollElement,
      verticalThreshold: this._verticalThreshold,
    });

    thresholdPassObserver.pipe(takeUntil(this.destroy)).subscribe((passed) => {
      this.onThresholdPass(passed);
    });

    directionChangeObserver.pipe(takeUntil(this.destroy)).subscribe((direction) => {
      this.onDirectionChange(direction);
    });

    this._hasToolbar = !!this.pageHeaderToolbar;
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  private onThresholdPass(passed: boolean) {
    if (this._thresholdPassed === passed) {
      return;
    }
    this._thresholdPassed = passed;
    this.pageHeaderCollapsedElements.forEach((headerContent: BszPageHeaderContent) => {
      if (!headerContent.event || headerContent.event === 'thresholdPass') {
        headerContent._setCollapsedByThresholdPass(passed);
      }
    });
    this.change.emit({event: 'thresholdPass', value: passed});

    if (!this.shadowSwitchEvent || this.shadowSwitchEvent === 'thresholdPass') {
      this.setPageHeaderShadow(passed);
    }
    this.changeDetector.markForCheck();
  }

  private onDirectionChange(direction: ScrollObserverDirection) {
    if (this._direction === direction) {
      return;
    }
    this._direction = direction;
    this.pageHeaderCollapsedElements.forEach((headerContent: BszPageHeaderContent) => {
      if (headerContent.event === 'directionChange') {
        headerContent._setCollapsedByDirectionChange(direction);
      }
    });
    this.change.emit({event: 'directionChange', value: direction});

    if (this.shadowSwitchEvent === 'directionChange') {
      this.setPageHeaderShadow(direction === 'down');
    }
    this.changeDetector.markForCheck();
  }

  private setPageHeaderShadow(showShadow: boolean) {
    if (!this._shadowed) {
      this._shadowedHeader = false;
      this._shadowedToolbar = false;
      return;
    }
    this._shadowedHeader = showShadow;
    this._shadowedToolbar = !showShadow;
  }
}
