import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {BszI18nModule} from '@basuiz/i18n';

import {BszActionBar, BszCancelActionDef, BszPrimaryActionDef, BszSecondaryActionDef} from './bsz-action-bar';

@NgModule({
  declarations: [BszActionBar, BszPrimaryActionDef, BszSecondaryActionDef, BszCancelActionDef],
  imports: [CommonModule, BszI18nModule, MatButtonModule, MatMenuModule, MatIconModule],
  exports: [BszActionBar, BszPrimaryActionDef, BszSecondaryActionDef, BszCancelActionDef],
})
export class BszActionBarModule {}
