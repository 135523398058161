/*
 * Simplify creating simple mocks for classes, services, etc.
 * Allows to specify only the properties that the tested class consumes from the mocked one,
 * while the function still return an object typed as the parent class.
 * In case the test attempts to read/write a property not defined in the mock,
 * the test will throw a developer friendly error.
 * */
export function getPartialMock<MOCKED extends Record<any, any>>(mockedProperties: Partial<MOCKED>): MOCKED {
  const detectMissingPropExceptions: any[] = ['ngOnDestroy'];
  const proxyHandler: ProxyHandler<MOCKED> = {
    get(mock, prop: keyof MOCKED) {
      if (!(prop in mockedProperties || detectMissingPropExceptions.includes(prop))) {
        throw new Error(`Property '${prop.toString()}' not defined in mock created via getPartialMock`);
      }
      return mock[prop];
    },
    set(mock, prop: keyof MOCKED, value) {
      if (!(prop in mockedProperties)) {
        throw new Error(`Property '${prop.toString()}' not defined in mock created via getPartialMock`);
      }
      mock[prop] = value;
      return true;
    },
  };
  return new Proxy(mockedProperties, proxyHandler) as MOCKED;
}
