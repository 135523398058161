import {PortalPage} from '../navigation/classes/portal-page';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslationKeyWithParams} from '../navigation/translation-key-with-params';
import {TranslationKey} from '@basuiz/web-app-applet-api';

export interface ShipmentOverviewPagePayload {
  foo?: never; //todo define the payload properties
}

export class ShipmentOverviewPage extends PortalPage {
  constructor(public payload: ShipmentOverviewPagePayload = {}) {
    super();
  }

  get parentPortalPage(): PortalPage | null {
    return null; // TODO - Replace with the adequate PortalPage unless the page hangs direct under the 'Home' page.
  }

  get breadcrumbText(): TranslationKey | TranslationKeyWithParams {
    return asTranslationKey('web-app-portal.pages.shipment-overview.breadcrumb');
  }

  get breadcrumbTestId(): string {
    return 'web-app-portal.pages.shipment-overview.breadcrumb';
  }

  get titleText(): TranslationKey {
    return asTranslationKey('web-app-portal.pages.shipment-overview.window-title');
  }

  static readonly routerConfigPath = 'shipments';
  get route(): Array<string> {
    return [`/${ShipmentOverviewPage.routerConfigPath}`];
  }
}
