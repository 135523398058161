export enum ParagraphFormat {
  STRING_DEFAULT = 'STRING_DEFAULT',
  NUMERIC_DEFAULT = 'NUMERIC_DEFAULT',
  NUMERIC_4DIGITS = 'NUMERIC_4DIGITS',
  NUMERIC_3DIGITS = 'NUMERIC_3DIGITS',
  NUMERIC_2DIGITS = 'NUMERIC_2DIGITS',
  NUMERIC_1DIGITS = 'NUMERIC_1DIGITS',
  NUMERIC_0DIGITS = 'NUMERIC_0DIGITS',
  NUMERIC_PERCENT = 'NUMERIC_PERCENT',
  NUMERIC_DYNAMIC_PERCENT = 'NUMERIC_DYNAMIC_PERCENT',
  DATE_DEFAULT = 'DATE_DEFAULT',
  DATE_YEAR = 'DATE_YEAR',
  DATE_MONTH_YEAR = 'DATE_MONTH_YEAR',
  DATE_MEDIUM = 'DATE_MEDIUM',
  DATE_LONG = 'DATE_LONG',
  DATE_FULL = 'DATE_FULL',
  DATE_TIME_SHORT = 'DATE_TIME_SHORT',
  DATE_TIME_MEDIUM = 'DATE_TIME_MEDIUM',
  TIME_SHORT = 'TIME_SHORT',
  TIME_MEDIUM = 'TIME_MEDIUM',
  IBAN_DEFAULT = 'IBAN_DEFAULT',
  REFERENCE_NUMBER_DEFAULT = 'REFERENCE_NUMBER_DEFAULT',
  NUMERIC_BOOKING_CURRENCY = 'NUMERIC_BOOKING_CURRENCY',
}
