import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Country} from '../models/country.definitions';
import {AfpRestOperators, AfpRestResponse} from '../../afp-rest/index';

@Injectable({providedIn: 'root'})
export class CountryService {
  constructor(private httpClient: HttpClient) {}

  private url = '/com.basuiz.afs.rest.services/rest/baseBankData/countryList';

  fetch(): Observable<Country[]> {
    return this.httpClient.get<AfpRestResponse<Country[]>>(this.url).pipe(AfpRestOperators.extractPayload());
  }
}
