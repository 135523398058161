import {Component} from '@angular/core';
import {Meta, MetaDefinition} from '@angular/platform-browser';
import {BszMobileBridgeService} from '@basuiz/mobile-bridge';
import {HybridNavigationService} from './navigation/hybrid-navigation.service';

@Component({
  selector: 'bsz-web-app-portal-hybrid',
  template: '',
})
export class WebAppPortalHybridComponent {
  constructor(
    hybridNavigationService: HybridNavigationService,
    bszMobileBridgeService: BszMobileBridgeService,
    private meta: Meta
  ) {
    hybridNavigationService.appIsReady();

    if (bszMobileBridgeService.isInsideNative()) {
      this.disablePageZoom();
    }
  }

  private disablePageZoom(): void {
    const viewportMetaTag: MetaDefinition = {
      name: 'viewport',
      content: 'initial-scale=1, user-scalable=no, maximum-scale=1',
    };
    this.meta.updateTag(viewportMetaTag, 'name=viewport');
  }
}
