import {Injectable} from '@angular/core';
import {catchError, map, Observable, of, shareReplay, switchMap, take, throwError} from 'rxjs';
import {
  AfpRestOperators,
  AfpRestResponse,
  Country,
  isDefined,
  UserPersonService,
  WebAppCommonFacade,
} from '@basuiz/web-app-common';
import {HttpClient} from '@angular/common/http';
import {UserProfilePostalAddressDto} from './user-profile-postal-address.dto';
import {UserProfilePostalAddress} from '../model/user-profile-postal-address.definition';
import {handleDaaSChangeAddressErrorResponse} from '../utils/handle-daas-change-address-error-response';
import {Moment} from 'moment';
import {TranslatedText} from '@basuiz/web-app-applet-api';

@Injectable({
  providedIn: 'root',
})
export class UserProfilePostalAddressService {
  private readonly uri$: Observable<string> = this.userPersonService.userPerson$.pipe(
    take(1),
    map(
      // (userPerson) => `/com.basuiz.afpaas.master.rest.services/changeaddress/persons/${userPerson.id}/domicileAddress`
      (userPerson) => `/com.basuiz.afpaas.master.rest.services/changeaddress/persons/${123}/domicileAddress`
    ),
    shareReplay(1)
  );

  readonly postalAddress$: Observable<UserProfilePostalAddress | undefined> = this.uri$.pipe(
    switchMap((uri) => this.httpClient.get<AfpRestResponse<UserProfilePostalAddressDto>>(uri)),
    AfpRestOperators.extractPayload(),
    switchMap((dto) =>
      this.isBszUserProfilePostalAddress(dto) ? this.enhancePostalAddressWithCountry(dto) : of(undefined)
    )
  );

  constructor(
    private readonly httpClient: HttpClient,
    private readonly userPersonService: UserPersonService,
    private readonly webAppCommonFacade: WebAppCommonFacade
  ) {}

  updatePostalAddress(
    newPostalAddress: UserProfilePostalAddress,
    {
      validFrom,
      remarks,
    }: {
      validFrom?: Moment;
      remarks?: TranslatedText;
    } = {}
  ): Observable<void> {
    const dto: UserProfilePostalAddressDto = {
      ...newPostalAddress,
      country: newPostalAddress.country?.isoCode2,
      ...(validFrom ? {validFrom: validFrom.format('YYYY-MM-DD')} : undefined),
      ...(remarks ? {remarks} : undefined),
    };

    return this.uri$.pipe(
      switchMap((uri) => this.httpClient.put(uri, dto)),
      map(() => undefined),
      catchError((error) => throwError(() => handleDaaSChangeAddressErrorResponse(error)))
    );
  }

  private enhancePostalAddressWithCountry(dto: UserProfilePostalAddressDto): Observable<UserProfilePostalAddress> {
    if (dto.country) {
      return this.webAppCommonFacade.countryList$.pipe(
        switchMap((countryList) => {
          const country = countryList.find((c) => c.isoCode2 === dto.country);
          return country
            ? of(this.getBszUserProfilePostalAddress(dto, country))
            : throwError(() => `Could not find country with ISO code ${dto.country}`);
        })
      );
    } else {
      return of(this.getBszUserProfilePostalAddress(dto));
    }
  }

  private isBszUserProfilePostalAddress(dto: UserProfilePostalAddressDto): boolean {
    const {country, city, postalCode, streetName, streetNumber} = dto;
    const publicFields = {country, city, postalCode, streetName, streetNumber};
    return Object.values(publicFields).some(isDefined);
  }

  private getBszUserProfilePostalAddress(
    {city, postalCode, streetName, streetNumber}: UserProfilePostalAddressDto,
    country?: Country
  ): UserProfilePostalAddress {
    const postalAddress: UserProfilePostalAddress = {country, city, postalCode, streetName, streetNumber};
    return postalAddress;
  }
}
