import {VERSION} from '@angular/core';

window.basuiz = {
  versions: {
    ui: {},
    backend: {},
    angular: VERSION.full,
  },
  info: function () {
    console.log(JSON.stringify(window.basuiz.versions, null, 2));
  },
};

export function addPackageToBasuizInfo(versionInfo: VersionInfo): void {
  window.basuiz.versions.ui = addVersionToBasuizInfo(window.basuiz.versions.ui, versionInfo);
}

export function addBackendVersionToBasuizInfo(versionInfo: VersionInfo): void {
  window.basuiz.versions.backend = addVersionToBasuizInfo(window.basuiz.versions.backend, versionInfo);
}

function addVersionToBasuizInfo(
  versions: Record<string, string>,
  {name, version}: VersionInfo
): Record<string, string> {
  return Object.entries({...versions, [name]: version})
    .sort()
    .reduce((_versions, [_name, _version]) => ({..._versions, [_name]: _version}), {});
}

declare global {
  interface Window {
    basuiz: {
      versions: {
        ui: Record<string, string>;
        backend: Record<string, string>;
        angular: string;
      };
      info: () => void;
    };
  }
}

export interface VersionInfo {
  name: string;
  version: string;
}
