import {Action} from '@ngrx/store';

export type AppletContextFeatureState<STATE> = Record<string, AppletContexts<STATE>>;

export type AppletContexts<STATE> = Record<string, STATE>;

export interface WithAppletContextId {
  appletContextId: string;
}

export function isActionWithAppletContextId(
  action: Action | WithAppletContextId
): action is Action & WithAppletContextId {
  return !!(action as Action).type && !!(action as WithAppletContextId).appletContextId;
}
