import {BszUnknownUINotification, TranslatedText} from '@basuiz/web-app-applet-api';

/* @deprecated when creating new notifications declare new BszUINotification types instead of using BszUnknownUINotification */
export function getUnknownUiNotification(
  severity: BszUnknownUINotification['severity'],
  message: TranslatedText
): BszUnknownUINotification {
  return {
    domain: 'unknown',
    severity,
    message,
  };
}
