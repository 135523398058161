import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {NotificationsFacade} from '@basuiz/web-app-self-service-notifications';
import {AttentionItemCounter, WebAppAlertFacade} from '@basuiz/web-app-alert';
import {
  AlertIndicatorAppletConfig,
  ɵALERT_INDICATOR_BANKLET_CONFIG,
} from '@basuiz/web-app-alert-indicator-applet/config';

@Component({
  selector: 'bsz-alert-indicator',
  templateUrl: './alert-indicator.component.html',
  styleUrls: ['./alert-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertIndicatorComponent implements OnInit, OnDestroy {
  attentionItemCountersWithPositiveValue$: Observable<AttentionItemCounter[]> =
    this.webAppAlertFacade.attentionItemCountersWithPositiveValue$;
  notificationsNew$ = this.notificationsFacade.notificationsNew$;

  constructor(
    @Inject(ɵALERT_INDICATOR_BANKLET_CONFIG)
    public config: AlertIndicatorAppletConfig,
    private notificationsFacade: NotificationsFacade,
    private webAppAlertFacade: WebAppAlertFacade
  ) {}

  ngOnInit(): void {
    this.webAppAlertFacade.requestStartPolling();
  }

  ngOnDestroy() {
    this.webAppAlertFacade.requestStopPolling();
  }
}
