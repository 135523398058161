import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import {
  AbstractNotificationComponent,
  BSZ_NOTIFICATION_DATA,
  BszNotificationOverlay,
} from '../bsz-notification-overlay';

@Component({
  selector: 'bsz-snackbar',
  templateUrl: './bsz-snackbar.html',
  styleUrls: ['./bsz-snackbar.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BszSnackbar extends AbstractNotificationComponent {
  message: string;
  action: string;
  dismissable: boolean;

  /** @private */
  _isMultiLine = false;

  @Output() actionClick = new EventEmitter();

  constructor(
    @Inject(BSZ_NOTIFICATION_DATA)
    data: {message: string; action: string; dismissable: boolean},
    private readonly notificationOverlayRef: BszNotificationOverlay,
    private readonly elementRef: ElementRef<HTMLInputElement>,
    private readonly cd: ChangeDetectorRef
  ) {
    super();
    this.message = data.message;
    this.action = data.action?.trim();
    this.dismissable = data.dismissable;
  }

  dismiss() {
    this.notificationOverlayRef.dismiss();
  }

  onActionClick() {
    this.notificationOverlayRef.dismiss();
    this.actionClick.emit();
  }

  // when it has action and closing button, if everything does not fit in one line, the styles need some changes,
  // and this function identifies that scenario
  _setMultiline() {
    const content = this.elementRef.nativeElement.querySelector('.bsz-snackbar-content') as HTMLElement;
    if (!this.dismissable && !this.action) {
      return;
    }
    const text = content.querySelector('.bsz-snackbar-message');
    const action = content.querySelector('.bsz-snackbar-action');

    this._isMultiLine = this.dismissable && this.getWidth(content) < this.getWidth(text) + this.getWidth(action);
    this.cd.detectChanges();
  }

  private getWidth(element: Element | null): number {
    if (!element) {
      return 0;
    }
    return element.getBoundingClientRect().width;
  }
}
