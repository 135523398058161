import {ActionWithResponse} from '@basuiz/mobile-bridge';

/**
 * Use of native scanner that is able to scan Swiss QR bills, orange and red
 * payment slips.
 */
export class ScanBillAction extends ActionWithResponse<ScanBillRequest, ScanBillResponse> {
  readonly name = 'scanBill';
}

export interface ScanBillRequest {
  /**
   * Indication of what type of bills the scanner should look for.
   */
  enabledBillType: 'All' | 'SwissQRBill' | 'PaymentSlips';
}

export interface SwissOrangePaymentSlip {
  /**
   * Scanned code line of the orange payment slip.
   */
  codeLine: string;

  /**
   * Account number from the scanned orange payment slip.
   */
  postCheckNumber: string;

  /**
   * Reference number from the scanned orange payment slip.
   */
  referenceNumber: string;

  /**
   * Amount from the orange payment slip. Has to be divided by 100 to get exact
   * amount.
   */
  amount: string;

  /**
   * ISO code of the currency on the scanned orange payment slip.
   */
  currency: 'CHF' | 'EUR';
}

export interface SwissRedPaymentSlip {
  /**
   * The first code line from the scanned red payment slip.
   */
  codeLine1: string;

  /**
   * The second code line from the scanned red payment slip.
   */
  codeLine2: string;
}

export interface ScanBillResponse {
  /**
   * Status success is returned when a bill has been successfully scanned.
   * Status cancelled is returned when user cancels the scanning process.
   */
  resultStatus: 'success' | 'cancelled';

  /**
   * In case that the Swiss QR Bill is scanned the text vaule will be returned
   * here.
   */
  scannedSwissQRBill?: string;

  /**
   * In case that the Swiss Orange Payment slip is scanned the vaule will be
   * returned here.
   */
  scannedOrangeSlip?: SwissOrangePaymentSlip;

  /**
   * In case that the Swiss Red Payment slip is scanned the vaule will be
   * returned here.
   */
  scannedRedSlip?: SwissRedPaymentSlip;
}
