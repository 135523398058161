import {FocusMonitor} from '@angular/cdk/a11y';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ANIMATION_MODULE_TYPE,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Optional,
} from '@angular/core';

type BszButtonThemePalette = 'primary' | 'accent' | 'error';

@Directive({
  host: {
    'class': 'bsz-button-base',
    '[class.bsz-button--primary]': 'color === "primary"',
    '[class.bsz-button--accent]': 'color === "accent"',
    '[class.bsz-button--error]': 'color === "error"',
    '[class.bsz-button--disabled]': '_disabled',
    '[class.bsz-button--animations-disabled]': 'animationModuleType === "NoopAnimations"',
    '[attr.disabled]': '_disabled || null',
  },
})
export abstract class BszButtonBase implements AfterViewInit, OnDestroy {
  /** Sets the color palette for the component. */
  @Input() color: BszButtonThemePalette | undefined = 'primary';

  /** Disables the button. */
  @Input() set disabled(disabled: BooleanInput) {
    this._disabled = coerceBooleanProperty(disabled);
  }
  protected _disabled = false;

  /** Disables the ripple effects. */
  @Input() set disableRipple(disabled: BooleanInput) {
    this._disableRipple = coerceBooleanProperty(disabled);
  }
  protected _disableRipple = false;
  protected readonly hostElement: HTMLButtonElement;

  constructor(
    protected readonly elementRef: ElementRef,
    protected readonly focusMonitor: FocusMonitor,
    @Optional()
    @Inject(ANIMATION_MODULE_TYPE)
    protected readonly animationModuleType: 'NoopAnimations' | 'BrowserAnimations' | null
  ) {
    this.hostElement = elementRef.nativeElement;
  }

  ngAfterViewInit() {
    this.focusMonitor.monitor(this.elementRef, true);
  }

  ngOnDestroy() {
    this.focusMonitor.stopMonitoring(this.elementRef);
  }
}
