import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {PortalDebugService} from '@basuiz/web-app-portal';

class DialogOverviewExampleDialog {}

@Component({
  selector: 'bsz-dev-tools-dialog',
  templateUrl: './dev-tools-dialog.component.html',
  styleUrls: ['./dev-tools-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevToolsDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    public portalDebugService: PortalDebugService
  ) {}

  public setTracingIntentHandling(value: boolean): void {
    this.portalDebugService.navigationIntentHandling = value;
    this.portalDebugService.navigationToDisabledPage = value;
  }
}
