import {ChangeDetectionStrategy, Component, EventEmitter, Output} from '@angular/core';
import {Notification, NotificationMessageStatus, NotificationsFacade} from '@basuiz/web-app-self-service-notifications';
import {BszNotification} from '@basuiz/web-app-applet-api';

@Component({
  selector: 'bsz-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationListComponent {
  @Output() notificationClicked: EventEmitter<BszNotification> = new EventEmitter();
  @Output() showAllNotifications: EventEmitter<void> = new EventEmitter();
  @Output() markAllNotificationsAsRead: EventEmitter<void> = new EventEmitter();

  notificationsNew$ = this.notificationsFacade.notificationsNew$;

  statusList = NotificationMessageStatus;

  readonly bszTestIdPrefix = 'web-app-alert-list-applet.notification-list';

  constructor(private notificationsFacade: NotificationsFacade) {}

  userClickedNotification(notification: Notification) {
    this.notificationsFacade.userMarkedNotificationListAsRead([notification.id]);
    this.notificationClicked.emit({id: notification.id, categoryId: notification.category.id});
  }

  onMarkAllNotificationsAsRead() {
    this.notificationsFacade.userMarkedAllNotificationsAsRead();
    this.markAllNotificationsAsRead.emit();
  }
}
