import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Host,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatSortHeader} from '@angular/material/sort';
import {BehaviorSubject, Subject} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';

import {BszTableTreeRow} from '../bsz-table/bsz-table.definitions';
import {BszSelectable} from './bsz-selectable';
import {SelectionState} from './bsz-selectable.definitions';
import {BszSelectableCheckboxAdapter} from './bsz-selectable-checkbox-adapter';

const LOG_PREFIX = '[bsz-selectable-checkbox]';

/**
 * Checkbox used inside tables/lists for selection. This checkbox can be used for all types of checkboxes:
 * - "Select all" in table/list headers (no 'item' specified)
 * - "Select group" in nested table/list group headers ('item' is a group header)
 * - "Select item" in table/list items ('item' is the table/list item)
 */
@Component({
  selector: 'bsz-selectable-checkbox',
  templateUrl: './bsz-selectable-checkbox.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'bsz-selectable-checkbox',
  },
})
export class BszSelectableCheckbox<T, G> implements OnInit, OnDestroy {
  @Input()
  set item(item: CheckboxItem<T, G>) {
    this._item = item;
    this.itemChange.next(item);
  }
  private itemChange = new BehaviorSubject<CheckboxItem<T, G>>(null);

  @Input('aria-label') ariaLabel = '';
  @Input('aria-labelledby') ariaLabelledby = '';

  @Input() set disabled(disabled: BooleanInput) {
    this._disabled = coerceBooleanProperty(disabled);
    this.updateSelectableItem();
    if (this._disabled) {
      this.checked = false;
      this.cd.markForCheck();
    }
  }
  /** @private */
  _disabled = false;

  checked = false;
  indeterminate: SelectionState = false;

  cssClass = '';

  private selectableAdapter: BszSelectableCheckboxAdapter | null = null;

  private readonly destroy = new Subject<void>();

  private _item: CheckboxItem<T, G> = null;

  constructor(
    private cd: ChangeDetectorRef,
    @Optional() private selectable: BszSelectable<T, G>,
    @Optional() @Host() matSortHeader: MatSortHeader
  ) {
    if (!selectable) {
      throw new Error(`${LOG_PREFIX} BszSelectableCheckbox has to be used in conjunction with BszSelectable`);
    }

    if (matSortHeader !== null) {
      throw new Error(`${LOG_PREFIX} BszSelectableCheckbox cannot be used in conjunction with MatSortHeader`);
    }
  }

  ngOnInit() {
    // Get new adapter from BszSelectable each time the item changes.
    const selectableAdapterChange = this.itemChange.pipe(
      switchMap((item) => this.selectable.getCheckboxAdapter(item)),
      takeUntil(this.destroy)
    );

    // Switch observable to selectionStateChange.
    const adapterSelectionChange = selectableAdapterChange.pipe(
      switchMap((adapter) => adapter.selectionStateChange),
      takeUntil(this.destroy)
    );

    // Switch to the new adapter each time we receive a new one.
    selectableAdapterChange.subscribe((selectableAdapter) => this.switchSelectableAdapter(selectableAdapter));

    // Listen for each adapter's selectionState changes.
    adapterSelectionChange.subscribe((selectionState) => this.onSelectedChange(selectionState));

    if (this._item) {
      this.updateSelectableItem();
    }
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  onChange(change: MatCheckboxChange) {
    this.selectableAdapter?.setSelected(change.checked);
  }

  private switchSelectableAdapter(selectableAdapter: BszSelectableCheckboxAdapter) {
    this.selectableAdapter = selectableAdapter;

    const type = this.selectableAdapter.type;
    this.cssClass = `bsz-selectable-${type}`;

    // We need to poke Angular so it will apply the changes we made to cssClass and ariaLabel above.
    this.cd.detectChanges();
  }

  private onSelectedChange(selected: SelectionState) {
    if (this._disabled) {
      return;
    }
    this.checked = selected === true;
    this.indeterminate = selected === null;

    this.cd.detectChanges();
  }

  private updateSelectableItem() {
    this.selectable.updateSelectableItems(this._item, this._disabled);
  }
}

export type CheckboxItem<T, G> = T | G | BszTableTreeRow | null;
