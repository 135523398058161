import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, filter, flatMap, map, withLatestFrom} from 'rxjs/operators';
import {
  loadBusinessUnits,
  loadBusinessUnitsFailed,
  loadBusinessUnitsRequired,
  loadBusinessUnitsSuccess,
} from './business-unit.actions';
import {BusinessUnitDataService} from '../../data-access/business-unit-data.service';
import {BusinessUnit} from '../../models/business-unit.definition';
import {select, Store} from '@ngrx/store';
import {selectLoaded} from './business-unit.selectors';
import {PartialState} from './business-unit.reducer';

@Injectable()
export class BusinessUnitEffects {
  loadBusinessUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBusinessUnits),
      withLatestFrom(this.store.pipe(select(selectLoaded))),
      filter(([action, loaded]) => !loaded),
      map(([action]) => loadBusinessUnitsRequired())
    )
  );

  loadBusinessUnitsRequired$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBusinessUnitsRequired),
      flatMap(() =>
        this.businessUnitDataService.fetch().pipe(
          map((businessUnitList: BusinessUnit[]) => loadBusinessUnitsSuccess({businessUnitList})),
          catchError((error) => of(loadBusinessUnitsFailed(error)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private businessUnitDataService: BusinessUnitDataService,
    private store: Store<PartialState>
  ) {}
}
