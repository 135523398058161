import {ArrayDataSource, CollectionViewer, DataSource, isDataSource, ListRange} from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import {BehaviorSubject, EMPTY, Observable} from 'rxjs';
import {map, switchAll} from 'rxjs/operators';

import {BszFlatSelectionModel, BszSelectable} from '../bsz-selectable/index';
import {BszDataListSourceInput} from './bsz-data-list.definitions';
import {BszDataListHeaderDef} from './bsz-data-list-header-def';
import {BszDataListItemDef} from './bsz-data-list-item-def';

const LOG_PREFIX = '[bsz-data-list]';

@Component({
  selector: 'bsz-data-list',
  templateUrl: './bsz-data-list.html',
  styleUrls: ['./bsz-data-list.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'bsz-data-list',
  },
})
export class BszDataList<T> implements OnDestroy, OnInit, CollectionViewer {
  @Input()
  set dataSource(dataSource: BszDataListSourceInput<T> | undefined | null) {
    if (dataSource === undefined || dataSource === null) {
      dataSource = [];
    }

    if (!isDataSource(dataSource) && !Array.isArray(dataSource)) {
      throw new Error(
        `${LOG_PREFIX} "dataSource" must be an Array or an instance of DataSource from @angular/cdk/collections`
      );
    }

    this.disconnectLatestDataSource();

    this.dataSource$.next(this.generateDataSource(dataSource));
  }

  /**
   * Custom template to render on top of the list.
   */
  @ContentChild(BszDataListHeaderDef, {static: true, read: TemplateRef})
  headerTemplate!: TemplateRef<unknown>;

  /**
   * Custom template to render for each item.
   */
  @ContentChild(BszDataListItemDef, {static: true, read: TemplateRef})
  itemTemplate!: TemplateRef<unknown>;

  /**
   * This is only to provide the collectionViewer interface for the data source, if it's an actual
   * CDK DataSource. It should return the range of displayed rows, so the DataSource could react to it.
   * We don't currently support this, but we still have to implement the interface in order to be able
   * to connect to a DataSource.
   */
  readonly viewChange: Observable<ListRange> = EMPTY;

  /**
   * A stream of data sources. A new value is emitted each time the input data source changes.
   */
  private readonly dataSource$ = new BehaviorSubject<DataSource<T>>(new ArrayDataSource([]));

  /**
   * A stream of data. A new value is emitted each time new data comes in.
   * This is how we transform the data source stream into the data stream:
   * - map each data source to an observable, so we can handle all data source streams in the same way
   * - switch to the new data source each time a new data source comes in
   */
  readonly data$: Observable<T[]> = this.dataSource$.pipe(
    // connect() can also return Observable<ReadonlyArray<T>>, but we don't care,
    // since it's effectively the same and there are some issues with ReadonlyArray
    // we don't want to work around (see https://github.com/microsoft/TypeScript/issues/17002)
    map((dataSource) => dataSource.connect(this) as Observable<T[]>),
    switchAll()
  );

  constructor(@Optional() @Self() private bszSelectable: BszSelectable<T, never>) {}

  ngOnInit() {
    this.initSelectable();
  }

  ngOnDestroy() {
    this.disconnectLatestDataSource();
  }

  private generateDataSource(dataSource: BszDataListSourceInput<T>): DataSource<T> {
    if (Array.isArray(dataSource)) {
      return new ArrayDataSource(dataSource);
    }

    if (isDataSource(dataSource)) {
      return dataSource;
    }

    // Should not reach this
    throw new Error(`${LOG_PREFIX} something went wrong while initializing the dataSource`);
  }

  private initSelectable() {
    if (!this.bszSelectable) {
      return;
    }

    this.bszSelectable.connect(this.data$, new BszFlatSelectionModel<T>());
  }

  private disconnectLatestDataSource() {
    this.dataSource$.getValue().disconnect(this);
  }
}
