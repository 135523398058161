import {Provider} from '@angular/core';
import {selfServiceCollaborationClientAdvisorAppletConfigProvider} from '@basuiz/web-app-self-service-collaboration-client-advisor-applet/config';

export function getSelfServiceCollaborationClientAdvisorAppletConfigProvider(): Provider {
  return selfServiceCollaborationClientAdvisorAppletConfigProvider({
    useFactory: (defaultConfig) => ({
      ...defaultConfig,
      getClientAdvisorImageURL: (referenceKey: string | undefined) => 'assets/basuiz_logo_twitter.png',
    }),
  });
}
