import {PortalPage} from '../navigation/classes/portal-page';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslationKeyWithParams} from '../navigation/translation-key-with-params';
import {TranslationKey} from '@basuiz/web-app-applet-api';
import {PaymentCardsPageTab} from '../page-config/payment-cards/payment-cards-page.config.definition';
import {PageTabMemory} from '../components/portal-page-layout/page-tab-memory.definition';

export interface PaymentCardsPagePayload {
  /** Tab to be immediately selected as the page is instantiated */
  tab?: PaymentCardsPageTab;

  /** The initial selected payment card ID to be used as the page is instantiated */
  paymentCardId?: number;
}

export class PaymentCardsPage extends PortalPage {
  constructor(public payload: PaymentCardsPagePayload = {}) {
    super();
  }

  public readonly pageTabMemory: PageTabMemory<PaymentCardsPageTab> = {
    activeTabId: this.payload.tab,
  };

  get parentPortalPage(): PortalPage | null {
    return null;
  }

  get breadcrumbText(): TranslationKey | TranslationKeyWithParams {
    return asTranslationKey('web-app-portal.pages.payment-cards-debit.breadcrumb');
  }

  get breadcrumbTestId(): string {
    return 'web-app-portal.pages.payment-cards.breadcrumb';
  }

  get titleText(): TranslationKey {
    return asTranslationKey('web-app-portal.pages.payment-cards-debit.window-title');
  }

  static readonly routerConfigPath = 'cards';
  get route(): Array<string> {
    return [`/${PaymentCardsPage.routerConfigPath}`];
  }
}
