import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';

import {BszDateCollection} from './bsz-datepicker.definitions';
import {BszDatePickerOverlay} from './bsz-datepicker-overlay';

@Component({
  selector: 'bsz-datepicker',
  template: '',
  styleUrls: ['bsz-datepicker.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    // although it would not be a big impact, hiding the host element prevents conflicts or accessibility errors
    // because it can use aria attributes but the element by itself is not defined in the HTML standard
    hidden: 'hidden',
  },
})
export class BszDatepicker<D> extends BszDatePickerOverlay<D> {
  @Input() override presets: BszDateCollection<D> | null = null;
  override readonly rangePicker = false;
}
