// IMPORTANT: do not modify this file

export * from './src/user-profile-postal-address-update-applet.config.definition';
export {
  ɵUSER_PROFILE_POSTAL_ADDRESS_UPDATE_BANKLET_CONFIG,
  userProfilePostalAddressUpdateAppletConfigProvider,
} from './src/user-profile-postal-address-update-applet.config.provider';

export * from './src/postal-address-form-group.abstract';

// IMPORTANT: do not modify this file
