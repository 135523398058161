<button mat-icon-button (menuOpened)="focusFirstItem()"
        attr.aria-label="{{ 'ui-elements.bsz-context-menu.accessibility.label' | translate }}"
        [matMenuTriggerFor]="BszContextMenuMenu">
  <mat-icon>more_vert</mat-icon>
</button>

<mat-menu #BszContextMenuMenu="matMenu">
  <!-- We wrap ng-content with a div so we can listen to keyboard events -->
  <div (keydown)="handleKeydown($event)">
      <ng-content select="button[bsz-context-menu-item]"></ng-content>
  </div>
</mat-menu>
