import {JestFixtureFacade, JestFixtureFacadeAware} from './jest-fixture-facade';

export class JestSelectableListItems implements JestFixtureFacadeAware {
  constructor(public jestFixtureFacade: JestFixtureFacade) {}

  clickOnSelectRow(testId: string, rows: number[]) {
    const checkboxes: NodeListOf<Element> = this.getCheckboxNodeList(testId);
    rows.forEach((row: number) => this.clickOnCheckbox(checkboxes.item(row) as HTMLElement));
  }

  public getHTMLInputElements(testId: string): HTMLInputElement[] {
    const htmlInputElementList: HTMLInputElement[] = [];
    const checkboxes: NodeListOf<Element> = this.getCheckboxNodeList(testId);
    checkboxes.forEach((element: Element) => htmlInputElementList.push(this.getInputElement(element as HTMLElement)));
    return htmlInputElementList;
  }

  private getCheckboxNodeList(testId: string): NodeListOf<Element> {
    return this.jestFixtureFacade.selectAll(testId);
  }

  private getInputElement(matCheckbox: HTMLElement): HTMLInputElement {
    return matCheckbox.querySelector('input') as HTMLInputElement;
  }

  private clickOnCheckbox(checkbox: HTMLElement) {
    const inputElement: HTMLInputElement = this.getInputElement(checkbox);
    inputElement.click();
  }

  getNumberItems(testId: string): number {
    return this.getCheckboxNodeList(testId).length;
  }
}
