import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  forwardRef,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';

import {BszBannerAction, BszBannerBase} from './bsz-banner-base';

@Component({
  selector: 'bsz-contextual-banner',
  styleUrls: ['./bsz-banner.scss'],
  templateUrl: './bsz-banner-base.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BszContextualBanner extends BszBannerBase implements AfterContentInit {
  @ContentChildren(forwardRef(() => BszBannerAction), {descendants: true})
  bannerActions: QueryList<BszBannerAction>;

  ngAfterContentInit() {
    this._bannerClass = 'bsz-contextual-banner';
    this._dismissButtonColor = 'primary';
    this.bannerActions.forEach((action: BszBannerAction) => {
      action._actionColor = 'primary';
    });
  }
}
