<span class="bsz-content-navigation-intersect"></span>
<div class="bsz-content-navigation" [ngClass]="_getClass()">
  <ng-container [ngTemplateOutlet]="leafTpl" *ngIf="_isMobile || _navigationPosition === 'left'">
  </ng-container>
  <div class="bsz-content-navigation-main">
    <ng-content></ng-content>
  </div>
  <ng-container [ngTemplateOutlet]="leafTpl" *ngIf="!_isMobile && _navigationPosition === 'right'">
  </ng-container>
</div>


<ng-template #leafTpl>
  <div class="bsz-content-navigation-nav" *ngIf="!!_sections.length || sectionAside">
    <div class="bsz-content-navigation-nav-content">
      <nav *ngIf="!!_sections.length" class="bsz-content-navigation-nav-list"
           [attr.aria-label]="'ui-elements.bsz-content-navigation.accessibility.navigation-label' | translate">
        <ul>
          <li *ngFor="let anchor of _sections"
              [ngClass]="_itemClass(_activeId === anchor.sectionId)">
            <a *ngIf="!_isRouterLink(anchor.id)"
               [href]="'#' + anchor.sectionId"
               (click)="_onClick($event, anchor.sectionId)"
               [attr.aria-current]="_activeId === anchor.sectionId">
              <span>{{anchor.text}}</span>
            </a>
            <a *ngIf="_isRouterLink(anchor.id) && _routerLinkConf"
               [routerLink]="_routerLinkConf.routerLink" [fragment]="_routerFragment(anchor.id)"
               (click)="_onClick($event, anchor.sectionId)"
               [attr.aria-current]="_activeId === anchor.sectionId">
              <span>{{anchor.text}}</span>
            </a>
          </li>
        </ul>
      </nav>
      <ng-content select="bsz-content-navigation-aside"></ng-content>
    </div>
  </div>
</ng-template>
