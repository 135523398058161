import {Relation} from 'typeorm';
import {IAddress, IBaseUserOwnedEntity, ILineItem, IShipment, IUser} from '../..';
import {Currency} from '../../../utils/models';

export enum OrderStatus {
  /**
   * (default) created: Order created AND column preferredRateId is NULL
   */
  created = 'created',
  unknown = 'unknown',
  awaitPay = 'awaitPay',
  paid = 'paid',
  refunded = 'refunded',
  cancelled = 'cancelled',
  partiallyFulfilled = 'partiallyFulfilled',
  shipped = 'shipped',
}

export enum ShopApp {
  WebApp = 'WebApp', // orders created in the web app
  CSV = 'CSV', // orders imported from a csv file
  PrestaShop = 'PrestaShop', // orders imported from the prestashop API integration
}

export interface IOrder extends IBaseUserOwnedEntity {
  ownerUser?: Relation<IUser>;
  addressSenderObjectId: number;
  addressSender?: Relation<IAddress>;
  addressRecipientObjectId: number;
  addressRecipient?: Relation<IAddress>;
  addressReturnObjectId?: number;
  addressReturn?: Relation<IAddress>;

  status?: OrderStatus;

  /**
   * OneToOne relation to shipment
   */
  shipment?: Relation<IShipment>;

  shopApp?: ShopApp;

  ////////
  // TODO (p2) see goodnotes all costs
  shippingCost?: number;
  subtotal?: number; // should be the sum of all lineItems
  total?: number; // should be the total of all costs (shipping, subtotal, extras)
  currency?: Currency;

  /**
   * ManyToMany relation (Order & Product) via LineItem
   */
  lineItems?: Relation<ILineItem[]>;
}
