import {Inject, Injectable} from '@angular/core';
import {MatDateFormats, MAT_DATE_FORMATS} from '@angular/material/core';
import {MomentDateFormat} from '@basuiz/web-app-applet-api';
import {DefaultMomentDateFormat, ɵDEFAULT_DATE_FORMAT} from '../../formatting/formatters/default-date-format';

export type ChartPeriod = 'DAILY' | 'MONTHLY' | 'QUARTERLY' | 'YEARLY';

interface ChartMomentFormats {
  xAxis: {firstLineFormat: MomentDateFormat; secondLineFormat: MomentDateFormat};
  tooltip: MomentDateFormat;
}

@Injectable({providedIn: 'root'})
export class ChartMomentFormatService {
  private readonly chartMomentFormats: Record<ChartPeriod, ChartMomentFormats> = {
    ['DAILY']: {
      xAxis: {firstLineFormat: 'DD', secondLineFormat: this.matDateFormats.display.monthYearLabel},
      tooltip: this.defaultDateFormat ?? '',
    },
    ['MONTHLY']: {
      xAxis: {firstLineFormat: 'MMM', secondLineFormat: 'YYYY'},
      tooltip: this.matDateFormats.display.monthYearLabel,
    },
    ['QUARTERLY']: {
      xAxis: {firstLineFormat: 'MMM', secondLineFormat: 'YYYY'},
      tooltip: this.matDateFormats.display.monthYearLabel,
    },
    ['YEARLY']: {
      xAxis: {firstLineFormat: 'YYYY', secondLineFormat: ' '},
      tooltip: 'YYYY',
    },
  };
  constructor(
    @Inject(MAT_DATE_FORMATS) private readonly matDateFormats: MatDateFormats,
    @Inject(ɵDEFAULT_DATE_FORMAT) private readonly defaultDateFormat: DefaultMomentDateFormat
  ) {}

  getTooltipFormat(chartPeriod: ChartPeriod): string {
    return (
      (this.chartMomentFormats[chartPeriod] && this.chartMomentFormats[chartPeriod].tooltip) ||
      this.matDateFormats.display.monthYearLabel
    );
  }

  getFirstLineXAxisFormat(chartPeriod: ChartPeriod): string {
    return (
      (this.chartMomentFormats[chartPeriod] && this.chartMomentFormats[chartPeriod].xAxis.firstLineFormat) || 'MMM'
    );
  }

  getSecondLineXAxisFormat(chartPeriod: ChartPeriod): string {
    return (
      (this.chartMomentFormats[chartPeriod] && this.chartMomentFormats[chartPeriod].xAxis.secondLineFormat) || 'YYYY'
    );
  }
}
