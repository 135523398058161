import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IProfile} from '@basuiz/shared/data-access';
import {AfpRestOperators, AfpRestResponse} from '@basuiz/web-app-common';
import {catchError, Observable, of} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ProfileService {
  constructor(private httpClient: HttpClient) {}

  // private readonly baseUrl: string = '/com.basuiz.api/profile'; // to be used with mock-server & proxy.config.json
  private readonly baseUrl: string = `${process.env['API_BASE_URL']}/profile`;

  getOwnSingle(): Observable<IProfile | null> {
    return this.httpClient.get<AfpRestResponse<IProfile>>(`${this.baseUrl}`).pipe(
      AfpRestOperators.extractPayload(),
      catchError((error: HttpErrorResponse): Observable<null> => {
        return of(null);
      })
    );
  }

  updateOne(profile: Partial<IProfile>): Observable<IProfile> {
    return this.httpClient
      .patch<AfpRestResponse<IProfile>>(`${this.baseUrl}`, {...profile})
      .pipe(AfpRestOperators.extractPayload());
  }
}
