<mat-divider></mat-divider>

<bsz-progress-bar
  *ngIf="inProgress | async"
  [message]="'web-app-common.auth.verification.mtan-verification.progress.message' | translate"
></bsz-progress-bar>

<ng-container *bszDesktopContent>
  <div class="bsz-subtitle-1 bsz-padding-t6 bsz-padding-b4">{{
    'web-app-common.auth.verification.mtan-verification.title' | translate
  }}</div>
</ng-container>
<ng-container *bszMobileContent>
  <div class="bsz-subtitle-2 bsz-padding-t6 bsz-padding-b4">{{
    'web-app-common.auth.verification.mtan-verification.title' | translate
  }}</div>
</ng-container>

<div class="bsz-body-1 bsz-padding-b4">{{
  'web-app-common.auth.verification.mtan-verification.description' | translate
}}</div>

<form [formGroup]="form" (ngSubmit)="verify()">
  <mat-form-field class="bsz-padding-b2">
    <mat-label>{{ 'web-app-common.auth.verification.mtan-verification.placeholder' | translate }}</mat-label>
    <div *ngIf="numericOnlyResponseCode; then numericOnlyInput; else defaultInput"></div>
    <ng-template #defaultInput>
      <input matInput formControlName="verificationToken" autocomplete="off" required cdkFocusInitial />
    </ng-template>
    <ng-template #numericOnlyInput>
      <input
        matInput
        inputmode="numeric"
        pattern="[0-9]*"
        formControlName="verificationToken"
        autocomplete="off"
        required
        cdkFocusInitial
      />
    </ng-template>
    <mat-error *ngIf="form.invalid">{{ getInputErrorTranslationKey() | translate }}</mat-error>
  </mat-form-field>
  <div class="bsz-text-right bsz-padding-b4">
    <button mat-button color="accent" type="button" (click)="cancel()">{{
      'web-app-common.auth.verification.mtan-verification.button.cancel' | translate
    }}</button>
    <button mat-flat-button color="accent" [disabled]="!form.valid || (inProgress | async)" type="submit">{{
      'web-app-common.auth.verification.mtan-verification.button.verify' | translate
    }}</button>
  </div>
</form>
