import {Injectable} from '@angular/core';
import {UserSettingsDataService} from './data-access/user-settings-data.service';
import {UserSettings, UserSettingsSubset} from './models/user-settings.definition';
import {cloneDeep} from 'lodash';
import {map, shareReplay} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {UserSettingsVisibility} from './models/user-settings-visibility';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  private userSettingsCache: UserSettings;
  private userSettingsVisibilityCache: UserSettingsVisibility;

  private readonly userSettingsFromDataService$: Observable<
    [UserSettings, UserSettingsVisibility]
  > = this.userSettingsDataService.fetchSettings().pipe(shareReplay(1));

  /** @return the user settings asynchronously.
   * NOTE: This method is intended for consumer of the user settings that need to access the user settings while
   * the application bootstraps. For simplicity, other consumers are encouraged to use the 'get userSettings ()' */
  public readonly userSettings$ = this.userSettingsFromDataService$.pipe(map(([userSettings, _]) => userSettings));

  constructor(private userSettingsDataService: UserSettingsDataService) {
    this.userSettingsFromDataService$.subscribe(
      ([userSettings, userSettingsVisibility]) => {
        this.userSettingsCache = userSettings;
        this.userSettingsVisibilityCache = userSettingsVisibility;
      },
      (error) => {
        throw new Error('Failed to load the user settings.');
      }
    );
  }

  /** @return the user settings synchronously.
   * Fails if the user settings were not fetched yet.
   * NOTE: The user settings are fetched automatically during the application bootstrap when the module
   * WebAppCommonUserSettingsCoreModule is imported by the application. Any consumer that calls this method after
   * the bootstrap will be given the user settings synchronously.
   * */
  public get userSettings(): UserSettings {
    this.assertSettingsLoaded();
    return cloneDeep(this.userSettingsCache);
  }

  /** Update a sub-set of user-settings.
   * @return a promise that resolves when the update was successful or fails when the update failed.
   * IMPORTANT: The caller of this method is expected to reload the application when any subset of settings was
   * successfully updated. Failing to do that could let the application in an inconsistent state.
   * */
  public updateUserSettings(userSettingsSubset: UserSettingsSubset): Promise<void> {
    return this.userSettingsDataService.updateSettings(userSettingsSubset);
  }

  /** Returns a 2-level map (group -> setting) that contains information about the visibility of each user setting,
   * as determined from the back-end in the metadata included in the response of the REST end-point. */
  public getUserSettingsVisibility(): UserSettingsVisibility {
    this.assertSettingsLoaded();
    return cloneDeep(this.userSettingsVisibilityCache);
  }

  private assertSettingsLoaded(): void {
    if (!this.userSettingsCache) {
      throw new Error(
        'Synchronous method cannot be called before the app initialization finish loading the user-settings'
      );
    }
  }
}
