import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {AfpAuthDialogResult, AuthDialogVerificationRequest} from '../../../models/auth-dialog.definitions';

@Component({
  selector: 'bsz-unsupported-verification',
  templateUrl: './unsupported-verification.component.html',
  styleUrls: ['./unsupported-verification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnsupportedVerificationComponent {
  @Input()
  verificationRequest: AuthDialogVerificationRequest;

  @Output()
  verificationResult: EventEmitter<AfpAuthDialogResult> = new EventEmitter<AfpAuthDialogResult>();

  cancel() {
    this.verificationResult.emit('CANCEL');
  }
}
