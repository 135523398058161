import {ChangeDetectionStrategy, Component, HostBinding, Inject, OnDestroy} from '@angular/core';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, map, withLatestFrom} from 'rxjs/operators';
import {PortalLayoutService} from './portal-layout.service';
import {BszScreenSize} from '@basuiz/ui-elements';
import {PortalConfig} from '../../config/portal.config.definition';
import {ɵPORTAL_CONFIG} from '../../config/portal.config.provider';
import {BreakpointObserver} from '@angular/cdk/layout';
import {NavigationService} from '../../navigation/navigation.service';
import {assertNever} from '@basuiz/web-app-applet-sdk';
import {ComponentType} from '@angular/cdk/overlay';
import {MainNavComponent} from '../main-nav/main-nav.component';
import {AppBarComponent} from '../app-bar/app-bar.component';
import {appShellOutletProvider} from '@basuiz/web-app-applet-sdk';
import {AuthJwtService} from '@basuiz/web-app-common';

@Component({
  selector: 'bsz-portal-layout',
  templateUrl: './portal-layout.component.html',
  styleUrls: ['./portal-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [appShellOutletProvider('formActions')],
})
export class PortalLayoutComponent implements OnDestroy {
  public readonly showAppShell = this.portalConfig.portalLayout.showAppShell;

  /* TODO analyze: if changing the order bszScreenSize.getScreenSize() is called
      and the breakpoint observer in `bszScreenSize.getScreenSize()` is called
      the layout starts malfunctioning when switching from mobile to desktop size.
      The left-margin of the mat-sidenav-content takes a very large number pushing
      the content to the very far right of the viewport.
  */
  readonly sideNavWidth$: Observable<string> = this.bszScreenSize
    .getScreenSize()
    .pipe(
      map((screenSize) => (screenSize === 'mobile' ? '85vw' : this.portalConfig.portalLayout.mainNavWidthInPx + 'px'))
    );

  readonly sideNavMode$ = this.calcSideNavMode();
  readonly sideNavInOverMode$: Observable<boolean> = this.sideNavMode$.pipe(map((mode) => mode === 'over'));

  readonly sideNavOpened$ = combineLatest([
    this.portalLayoutService.mainNavOpened$,
    this.portalLayoutService.fullScreen$,
  ]).pipe(map(([mainNavOpened, fullScreen]) => mainNavOpened && !fullScreen));

  public readonly fullScreen$ = this.portalLayoutService.fullScreen$;

  public readonly mainNavComponent: ComponentType<unknown> =
    this.portalConfig.portalLayout.mainNavCustomComponent ?? MainNavComponent;

  public readonly appBarComponent: ComponentType<unknown> =
    this.portalConfig.portalLayout.appBarCustomComponent ?? AppBarComponent;

  readonly footerLeftStyle$: Observable<string> = combineLatest([
    this.fullScreen$,
    this.sideNavWidth$,
    this.sideNavMode$,
    this.sideNavOpened$,
  ]).pipe(
    map(([inFullScreen, sideNavWidth, sideNavMode, sideNavOpened]) => {
      return !inFullScreen && sideNavOpened && sideNavMode === 'side' ? sideNavWidth : '0';
    })
  );

  private readonly subscriptions = new Subscription();

  readonly maxPageWidthInPx = this.portalConfig.portalLayout.maxPageWidthInPx + 'px';
  readonly pageContentCentered$ = this.portalLayoutService.pageContentCentered$;

  @HostBinding('class.mat-typography')
  private readonly typographyCssClass = true;

  @HostBinding('class.bsz-portal')
  private readonly bszPortalCssClass = true;

  readonly userJwt$ = this.authJwtService.userJwt$;

  constructor(
    private portalLayoutService: PortalLayoutService,
    private bszScreenSize: BszScreenSize,
    private breakpointObserver: BreakpointObserver,
    private navigationService: NavigationService,
    @Inject(ɵPORTAL_CONFIG) private portalConfig: PortalConfig,
    private authJwtService: AuthJwtService
  ) {
    this.scrollToTopOnNavigationsToNewPage();
    this.closeMenuOnNewNavigationsWhenMenuInOverMode();
    this.setSideNavOpenStatusOnSideNavModeChanges();
    this.handleScrollToTopRequests();
  }

  toggleSideNav(menuState?: 'opened' | 'closed') {
    this.portalLayoutService.toggleMainNav(menuState);
  }

  private setSideNavOpenStatusOnSideNavModeChanges() {
    const subscription = this.sideNavMode$.subscribe((mode) => {
      switch (mode) {
        case 'over':
          this.portalLayoutService.toggleMainNav('closed');
          break;
        case 'side':
          this.portalLayoutService.toggleMainNav(
            this.portalConfig.portalLayout.mainNavInitiallyOpened ? 'opened' : 'closed'
          );
          break;
        default:
          assertNever(mode);
      }
    });
    this.subscriptions.add(subscription);
  }

  private calcSideNavMode(): Observable<'side' | 'over'> {
    const {maxPageWidthInPx, mainNavWidthInPx, mainNavModeBufferInPx} = this.portalConfig.portalLayout;
    const breakpoint = maxPageWidthInPx + mainNavWidthInPx + mainNavModeBufferInPx;
    const query = `(min-width: ${breakpoint}px)`;
    return this.breakpointObserver.observe(query).pipe(map((state) => (state.matches ? 'side' : 'over')));
  }

  private handleScrollToTopRequests() {
    const subscription = this.portalLayoutService.scrollToTop$.subscribe(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });
    this.subscriptions.add(subscription);
  }

  private scrollToTopOnNavigationsToNewPage() {
    const subscription = this.navigationService.currentPortalPage$
      .pipe(
        filter((page) => !!page),
        map((page) => page.constructor),
        distinctUntilChanged() // do not scroll on navigations within the same page / route
      )
      .subscribe(() => {
        this.portalLayoutService.scrollToTop();
      });
    this.subscriptions.add(subscription);
  }

  private closeMenuOnNewNavigationsWhenMenuInOverMode() {
    const subscription = this.navigationService.currentPortalPage$
      .pipe(withLatestFrom(this.sideNavMode$))
      .subscribe(([_, sideNavMode]) => {
        if (sideNavMode === 'over') {
          this.portalLayoutService.toggleMainNav('closed');
        }
      });
    this.subscriptions.add(subscription);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
