import {getCurrencySymbol} from '@angular/common';
import {ChangeDetectionStrategy, Component, Inject, Input, OnChanges, Optional, ViewEncapsulation} from '@angular/core';
import {BszI18nService} from '@basuiz/i18n';
import {
  BSZ_CURRENCY_PIPE_DEFAULT_CONFIG,
  BSZ_CURRENCY_PIPE_DEFAULT_OPTIONS,
  BszCurrencyDisplay,
  BszCurrencyPipeConfig,
  formatCurrency,
  formatCurrencyWithTemplate,
} from '@basuiz/ui-elements/pipes';

import {UNICODE_MINUS_SIGN} from '../../common/bsz.constants';

const LOG_PREFIX = '[bsz-currency]';
@Component({
  selector: 'bsz-currency',
  styleUrls: ['./bsz-currency.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // We could make this component a directive since no template is required.
  // However, we would have to move the styles to the global stylesheet so
  // we just keep it as a component to keep the styles attached to it.
  template: '',
  host: {
    'class': 'bsz-currency',
    '[innerHTML]': '_htmlValue',
  },
})
export class BszCurrency implements OnChanges {
  /** The value to format. */
  @Input() value: number | string | undefined | null;

  /**
   * The [ISO 4217](https://en.wikipedia.org/wiki/ISO_4217) currency code.
   * ex: 'CHF' for swiss francs or 'EUR' for euro
   */
  @Input() currencyCode = '';

  /**
   * The format for the currency indicator. One of:
   *   - `code`: Show the code (eg: `USD`).
   *   - `symbol`: Show the symbol (eg: `$`).
   *   - `symbol-narrow`: Use the narrow symbol for locales that have two symbols for their currency
   *      (eg: for `CAD` there is `CA$` and `$`, this option will pick the `$`).
   */
  @Input() display: BszCurrencyDisplay;

  /**
   * Decimal representation options, specified by a string
   * in the following format: {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}.
   *   - `minIntegerDigits`: The minimum number of integer digits before the decimal point.
   *   - `minFractionDigits`: The minimum number of digits after the decimal point.
   *   - `maxFractionDigits`: The maximum number of digits after the decimal point.
   * If not provided, the number will be formatted with the proper amount of digits,
   * depending on what the [ISO 4217](https://en.wikipedia.org/wiki/ISO_4217) specifies.
   */
  @Input() digitsInfo: string | undefined;

  /**
   * A locale code for the locale format rules to use.
   * When not supplied, uses the value of `localeId` from the `BszI18nService`
   */
  @Input() locale: string | undefined;

  /** The formatted value without html */
  get valueFormatted(): string {
    // Using a getter to prevent assignment from the outside.
    return this._valueFormatted;
  }
  private _valueFormatted = '';

  /** @private for internal use only. The final html string to render in the template */
  _htmlValue = '';

  private readonly config: BszCurrencyPipeConfig = BSZ_CURRENCY_PIPE_DEFAULT_CONFIG;

  constructor(
    private readonly i18nService: BszI18nService,
    @Optional() @Inject(BSZ_CURRENCY_PIPE_DEFAULT_OPTIONS) defaultConfig?: Partial<BszCurrencyPipeConfig>
  ) {
    this.config = {
      emptyValue: defaultConfig?.emptyValue || this.config.emptyValue,
      display: defaultConfig?.display || this.config.display,
      template: Object.assign({}, this.config.template, defaultConfig?.template || {}),
    };
    this.display = this.config.display;
  }

  ngOnChanges(): void {
    // We wrap the render function into a try/catch block so we can add a prefix
    // to the error and identify it is coming from the component and not from
    // the pipe directly.
    try {
      this.render();
    } catch (error) {
      throw new Error(`${LOG_PREFIX}: ${error}`);
    }
  }

  /** Generate and Render the html representation for the result from BszCurrencyPipe */
  private render(): void {
    const {formattedAmount, formattedAmountCustomTemplate, currencySymbol, absoluteValue} = this.formatCurrency();

    if (formattedAmount === '') {
      this._valueFormatted = formattedAmount;
      this._htmlValue = this.config.emptyValue;
      return;
    }

    // A custom template has priority if it exists.
    const amountToRender = formattedAmountCustomTemplate ?? formattedAmount;

    this._valueFormatted = amountToRender;

    this._htmlValue = amountToRender
      .replace(absoluteValue, `<span class="bsz-currency-value">${absoluteValue}</span>`)
      .replace(currencySymbol, `<span class="bsz-currency-code">${currencySymbol}</span>`)
      .replace(UNICODE_MINUS_SIGN, `<span class="bsz-currency-minus">${UNICODE_MINUS_SIGN}</span>`);
  }

  /**
   * Formats the value of the component and separates it into different properties to
   * allow us to decorate them into the html template.
   */
  private formatCurrency() {
    const localeId = this.locale || this.i18nService.localeId;

    const currencySymbol = this.getCurrencySymbolFromCurrencyCode();

    const formattedAmount = formatCurrency(this.value, localeId, currencySymbol, this.currencyCode, this.digitsInfo);

    const absoluteValue = formattedAmount.replace(currencySymbol, '').replace(UNICODE_MINUS_SIGN, '').trim();

    let formattedAmountCustomTemplate = null;
    if (this.config.template[localeId]) {
      formattedAmountCustomTemplate = formatCurrencyWithTemplate(
        formattedAmount,
        currencySymbol,
        this.config.template[localeId]
      );
    }

    return {formattedAmount, formattedAmountCustomTemplate, absoluteValue, currencySymbol};
  }

  private getCurrencySymbolFromCurrencyCode(): string {
    if (this.display === 'symbol') {
      return getCurrencySymbol(this.currencyCode, 'wide', this.locale);
    }
    if (this.display === 'symbol-narrow') {
      return getCurrencySymbol(this.currencyCode, 'narrow', this.locale);
    }

    return this.currencyCode;
  }
}
