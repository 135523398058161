import {Pipe, PipeTransform} from '@angular/core';
import {BszNumberPipe} from './number.pipe';
import {DEFAULT_QUANTITY_DIGITS_INFO} from '../../const/digits-info.const';

@Pipe({name: 'bszQuantity'})
export class BszQuantityPipe implements PipeTransform {
  constructor(private bszNumberPipe: BszNumberPipe) {}

  public transform(value: number | null | undefined): string {
    return this.bszNumberPipe.transform(value, DEFAULT_QUANTITY_DIGITS_INFO);
  }
}
