import {PaymentCardsPageConfig, PaymentCardsPageTab} from './payment-cards-page.config.definition';
import {ExpandedFeatureToggle, isEnabled} from '@basuiz/web-app-feature-toggle';

// IMPORTANT: do not modify the signature of this function
export function getDefaultConfig(featureToggle: ExpandedFeatureToggle): PaymentCardsPageConfig {
  const sortedTabs: PaymentCardsPageTab[] = ['DEBIT_CARD'];
  const tabs = sortedTabs.filter((tab) => isEnabledInFeatureToggle(tab));
  return {tabs};

  function isEnabledInFeatureToggle(tab: PaymentCardsPageTab): boolean {
    switch (tab) {
      case 'DEBIT_CARD':
        return isEnabled(featureToggle.paymentCards?.debitCards);
      default:
        const never: never = tab;
        return false;
    }
  }
}
