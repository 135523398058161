import {IManifest, IShipment} from '@basuiz/shared/data-access';
import {UnionTypeFromArray} from '../../common';
import {Subset} from '../../common/subset';
import {
  BszAbstractUINotification,
  BszUINotificationGenericAction,
  BszUINotificationWithAction,
  BszUINotificationWithRelatedEntity,
  BszUINotificationWithSubdomain,
} from './../bsz-ui-notifications.util';

/** Subdomains of the shipment domain */
export const BszUINotificationSubDomainShipment = [
  'shipmentLabelDownloadError',
  'manifestCreated',
  'manifestDownloadError',
] as const;
export type BszUINotificationSubDomainShipment = UnionTypeFromArray<typeof BszUINotificationSubDomainShipment>;

export interface AbstractShipmentUINotification
  extends BszAbstractUINotification,
    BszUINotificationWithSubdomain<BszUINotificationSubDomainShipment> {
  domain: 'shipment';
}

/** UI Notifications related when the shipment carrier's label generate or download was not successfully (most likely due to carrier API error)
 * Related Entity contains:
 * order: parent order which was requested a carrier shipment paid
 */
export interface BszShipmentLabelDownloadErrorUINotification
  extends AbstractShipmentUINotification,
    BszUINotificationWithRelatedEntity<IShipment | null>,
    BszUINotificationWithAction<Subset<BszUINotificationGenericAction, 'submit'>> {
  subDomain: 'shipmentLabelDownloadError';
  severity: 'error';
}

/** UI Notifications related when the shipment's manifest was successfully created
 * Related Entity contains:
 * manifest: shipment manifest which was created
 */
export interface BszManifestCreatedUINotification
  extends AbstractShipmentUINotification,
    BszUINotificationWithRelatedEntity<IManifest>,
    BszUINotificationWithAction<Subset<BszUINotificationGenericAction, 'create'>> {
  subDomain: 'manifestCreated';
  severity: 'success';
}

export interface BszManifestDownloadErrorUINotification
  extends AbstractShipmentUINotification,
    BszUINotificationWithRelatedEntity<IManifest | null>,
    BszUINotificationWithAction<Subset<BszUINotificationGenericAction, 'submit'>> {
  subDomain: 'manifestDownloadError';
  severity: 'error';
}

/** UI Notifications related to the shipment */
export type BszShipmentUINotification =
  | BszShipmentLabelDownloadErrorUINotification
  | BszManifestCreatedUINotification
  | BszManifestDownloadErrorUINotification;
