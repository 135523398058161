import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TestIdDirective} from './component/testid.directive';

@NgModule({
  declarations: [TestIdDirective],
  imports: [CommonModule],
  exports: [TestIdDirective],
})
export class TestingCoreModule {}
