import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FilterToSave, SavedFilter, SavedFilterKey} from '../../models/saved-filter.interface';
import {SavedFilterFacade} from '../../+state/saved-filter.facade';
import {Observable, Subscription} from 'rxjs';
import {FilterFavouritesHelperService} from '../../filter-favourites-helper.service';
import {delay, distinctUntilChanged, filter, map, startWith, tap} from 'rxjs/operators';
import {CallState, LoadingState} from '../../../definitions/call-state.definition';
import {isErrorState} from '../../../utils/error-state';

@Component({
  selector: 'bsz-filter-favourites',
  templateUrl: './filter-favourites.component.html',
  styleUrls: ['./filter-favourites.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterFavouritesComponent implements OnInit, OnDestroy {
  @Input() bszFilterForm: UntypedFormGroup;
  @Input() savedFilterKey: SavedFilterKey;

  isOverlayOpen = false;

  filterNameFormControl: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.maxLength(100),
    Validators.pattern(new RegExp('\\S')),
    (formControl: UntypedFormControl) => this.validateNameExist(formControl),
  ]);
  private subscription: Subscription;

  isFormPristine$: Observable<boolean>;
  disableState$: Observable<boolean>;

  savedFilters$: Observable<SavedFilter[]>;
  selectedFilter$: Observable<SavedFilter | undefined>;
  isLoading$: Observable<boolean>;

  savedFilters: SavedFilter[];
  selectedFilter: SavedFilter | undefined;
  hasErrorState$: Observable<boolean>;
  private callState$: Observable<CallState>;

  constructor(private savedFilterFacade: SavedFilterFacade) {}

  ngOnInit(): void {
    this.savedFilterFacade.loadFilters(this.savedFilterKey);
    this.callState$ = this.savedFilterFacade.getSavedFilterCallStateByType$(this.savedFilterKey);
    this.isLoading$ = this.callState$.pipe(map((callState) => callState === LoadingState.LOADING));
    this.hasErrorState$ = this.callState$.pipe(map((callState) => isErrorState(callState)));
    this.savedFilters$ = this.savedFilterFacade.getSavedFiltersByType$(this.savedFilterKey).pipe(
      tap((savedFilters) => {
        this.savedFilters = savedFilters;
      })
    );
    this.selectedFilter$ = this.savedFilterFacade.getSelectedFilterByType$(this.savedFilterKey).pipe(
      tap((selectedFilter) => {
        this.selectedFilter = selectedFilter;
      })
    );
    this.isFormPristine$ = this.getIsFormPristine();
    this.disableState$ = this.getDisableState();
    this.observeFormState();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.savedFilterFacade.deselectFilter(this.savedFilterKey);
  }

  onSelect(savedFilter: SavedFilter) {
    if (savedFilter.name === this.selectedFilter?.name) {
      this.savedFilterFacade.deselectFilter(this.savedFilterKey);
    } else {
      this.savedFilterFacade.selectFilter(savedFilter.name, this.savedFilterKey);
      this.updateForm(savedFilter);
    }
  }

  onRemove(savedFilter: SavedFilter) {
    this.savedFilterFacade.deleteSelectedFilter(this.savedFilterKey, savedFilter);
    this.updateForm(savedFilter);
  }

  saveFilter() {
    this.filterNameFormControl.markAsTouched();
    if (this.bszFilterForm.invalid || this.filterNameFormControl.invalid) {
      this.markFormGroupTouched(this.bszFilterForm);
      return;
    }
    this.savedFilterFacade.saveFilter(this.getFilter());
    this.closeFilterSaverMenus();
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    Object.values(formGroup.controls).forEach((control: UntypedFormGroup) => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  private validateNameExist(formControl: UntypedFormControl) {
    if (this.selectedFilter && this.selectedFilter.name === formControl.value.trim()) {
      return null; //update case
    }

    return this.savedFilters && this.savedFilters.find((savedFilter) => savedFilter.name === formControl.value)
      ? {
          nameExists: {
            valid: false,
          },
        }
      : null;
  }

  private updateForm(savedFilter: SavedFilter) {
    const formValue = FilterFavouritesHelperService.mapSavedFilterPayloadToFormValues(savedFilter.properties);
    this.bszFilterForm.setValue(formValue);
    this.bszFilterForm.markAsDirty();
    this.bszFilterForm.markAsTouched();
  }

  private getFilter(): FilterToSave {
    return {
      id: this.selectedFilter?.id,
      defaultValue: false,
      name: this.filterNameFormControl.value.trim(),
      type: this.savedFilterKey,
      properties: FilterFavouritesHelperService.mapFormValuesToSavedFilterPayload(this.bszFilterForm.getRawValue()),
    };
  }

  private observeFormState() {
    this.subscription = this.isFormPristine$.subscribe((status) => {
      this.savedFilterFacade.deselectFilter(this.savedFilterKey);
      this.filterNameFormControl.reset();
    });

    this.subscription.add(
      this.savedFilterFacade.getSelectedFilterNameByType$(this.savedFilterKey).subscribe((selectedFilterName) => {
        this.filterNameFormControl.patchValue(selectedFilterName || '');
        this.filterNameFormControl.markAsUntouched();
      })
    );
  }

  private getIsFormPristine(): Observable<boolean> {
    return this.bszFilterForm.valueChanges.pipe(
      delay(0), // Wait for propagate dirty state from select filter
      filter(() => this.bszFilterForm.pristine), // Filter only reset filter change
      distinctUntilChanged()
    );
  }

  private getDisableState() {
    return this.bszFilterForm.valueChanges.pipe(
      startWith(this.bszFilterForm.getRawValue()),
      map(() => this.bszFilterForm.pristine),
      distinctUntilChanged(),
      tap((isPristine) => {
        if (isPristine) {
          this.filterNameFormControl.disable();
        } else {
          this.filterNameFormControl.enable();
        }
      })
    );
  }

  private closeFilterSaverMenus() {
    this.isOverlayOpen = false;
  }
}
