import {Injectable} from '@angular/core';
import {UserSessionService} from './user-session.service';
import {take} from 'rxjs/operators';
import {Observable, ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserSessionChangedService {
  private static readonly localStorageUserSessionIdKey: string =
    'bsz.web-app-common.web-app-common-user-session-id';
  private static readonly STORED_SESSION_ID_LENGTH: number = 5;
  private readonly hasSessionChangedSubject = new ReplaySubject<boolean>(1);

  constructor(private userSessionService: UserSessionService) {
    this.userSessionService.sessionDetails$.pipe(take(1)).subscribe((session) => {
      if (!this.compareSessionIds(session.globalSessionId)) {
        this.storeUserSessionId(session.globalSessionId);
        this.hasSessionChangedSubject.next(true);
      } else {
        this.hasSessionChangedSubject.next(false);
      }
    });
  }

  public get hasSessionChanged$(): Observable<boolean> {
    return this.hasSessionChangedSubject.asObservable();
  }

  private storeUserSessionId(sessionId: string): void {
    //only a portion of the user session id is stored due to security reasons
    localStorage.setItem(
      UserSessionChangedService.localStorageUserSessionIdKey,
      sessionId.substr(-UserSessionChangedService.STORED_SESSION_ID_LENGTH)
    );
  }

  private getUserSessionId(): string | null {
    return localStorage.getItem(UserSessionChangedService.localStorageUserSessionIdKey);
  }

  private compareSessionIds(sessionId: string): boolean {
    const userSessionId = this.getUserSessionId();
    return !!userSessionId && sessionId.endsWith(userSessionId);
  }
}
