// IMPORTANT: do not modify this file except for passing new parameters to the default config

import {inject, InjectionToken, FactoryProvider} from '@angular/core';
import {BszConfigFactory, BszConfigFactoryWithDeps} from '@basuiz/web-app-applet-api';
import {UserProfilePostalAddressUpdateAppletConfig} from './user-profile-postal-address-update-applet.config.definition';
import {getDefaultConfig} from './user-profile-postal-address-update-applet.config.default';
import {validateUserProfilePostalAddressUpdateAppletConfig} from './user-profile-postal-address-update-applet.config.validation';

export const ɵUSER_PROFILE_POSTAL_ADDRESS_UPDATE_BANKLET_CONFIG =
  new InjectionToken<UserProfilePostalAddressUpdateAppletConfig>(
    'bsz.web-app.user-profile-postal-address-update-applet-config',
    {
      factory: () => inject(USER_PROFILE_POSTAL_ADDRESS_UPDATE_BANKLET_CONFIG_DEFAULT),
    }
  );

export const USER_PROFILE_POSTAL_ADDRESS_UPDATE_BANKLET_CONFIG_DEFAULT =
  new InjectionToken<UserProfilePostalAddressUpdateAppletConfig>(
    'bsz.web-app.user-profile-postal-address-update-applet-config.default',
    {
      factory: () => {
        const config = getDefaultConfig();
        const error = validateUserProfilePostalAddressUpdateAppletConfig(config);
        if (error) {
          console.error('Default UserProfilePostalAddressUpdateAppletConfig validation failed:', error);
        }
        return config;
      },
    }
  );

/** Returns an Angular provider for the configuration of library @basuiz/web-app-user-profile-postal-address-update-applet.
 *  When the the factory passed as argument of this method is called, it will be passed the default configuration as the first argument.
 *  You can choose your preferred strategy to create a new configuration. For example:
 *  - You can produce a new configuration by making changes to the default configuration.
 *  - Or ignore the default configuration and provide a completely new one that matches your needs.
 *
 *  To have an effect, provider returned by this method must be provided in a provider scope of the Angular application.
 *  In case of applets, this can be any provider scope within the reach of the applet.
 *  Different instances of the same applet can be passed different configurations.
 *  For libraries other than applets, the configuration can be provided only once in the root provider scope.
 *
 *  If not provided, the library @basuiz/web-app-user-profile-postal-address-update-applet will use its default configuration.
 */
export function userProfilePostalAddressUpdateAppletConfigProvider({
  useFactory,
  deps,
}: BszConfigFactoryWithDeps<UserProfilePostalAddressUpdateAppletConfig>): FactoryProvider {
  return {
    provide: ɵUSER_PROFILE_POSTAL_ADDRESS_UPDATE_BANKLET_CONFIG,
    useFactory: factoryWithValidation(useFactory),
    deps: [USER_PROFILE_POSTAL_ADDRESS_UPDATE_BANKLET_CONFIG_DEFAULT, ...(deps || [])],
  };
}

function factoryWithValidation(
  factory: BszConfigFactory<UserProfilePostalAddressUpdateAppletConfig>
): BszConfigFactory<UserProfilePostalAddressUpdateAppletConfig> {
  return (defaultConfig: UserProfilePostalAddressUpdateAppletConfig, ...deps: unknown[]) => {
    const config = factory(defaultConfig, ...deps);
    const error = validateUserProfilePostalAddressUpdateAppletConfig(config);
    if (error) {
      console.error('UserProfilePostalAddressUpdateAppletConfig validation failed:', error);
    }
    return config;
  };
}
