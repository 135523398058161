import {Component, Inject, LOCALE_ID} from '@angular/core';
import {activeLocaleUpdateHook} from './configurations/locales';

@Component({
  selector: 'bsz-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'web-app-jungfrau';
  APP_ENV = process.env.APP_ENV;

  constructor(@Inject(LOCALE_ID) localeId: string) {
    if (localeId === undefined) {
      throw new Error(
        'LOCALE_ID is undefined. Ensure BszI18nService.initialize() is being called as documented and that no other code triggers the instantiation of LOCALE_ID during the application initialization phase'
      );
    } else {
      /* If the URL used to navigate to web-app contains a locale Id that differs from the one that the application
       * eventually uses (e.g. because the app uses the locale Id specified in the user settings).
       * Update the URL with the final locale Id just to avoid the user to become confused.
       * */
      activeLocaleUpdateHook(localeId);
    }
  }
}
