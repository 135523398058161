import {JestFixtureFacade, JestFixtureFacadeAware} from './jest-fixture-facade';
import {MatSlideToggle as MatSlideToggleInterface} from '../generic/components';
import {DebugElement} from '@angular/core';

export class JestMatSlideToggle implements JestFixtureFacadeAware, MatSlideToggleInterface {
  constructor(public testId: string, public jestFixtureFacade: JestFixtureFacade) {}

  get exists(): boolean {
    return !!this.matSlideToggleDirectiveDebugElement;
  }

  get textContent(): string | null | undefined {
    return this.element.querySelector('.mat-slide-toggle-label')?.textContent;
  }

  toggle() {
    this.element.querySelector('input')?.click();
  }

  private get element(): HTMLElement {
    return this.matSlideToggleDirectiveDebugElement.nativeElement;
  }

  private get matSlideToggleDirectiveDebugElement(): DebugElement {
    return this.jestFixtureFacade.debugElement.query((element) => {
      const match = element.nativeElement.getAttribute('test-id') === this.testId;
      if (match && element.nativeElement.tagName !== 'MAT-SLIDE-TOGGLE') {
        throw new Error('A test id for a MatSlideToggle must be placed on the <mat-slide-toggle> element');
      }
      return match;
    });
  }
}
