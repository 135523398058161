import {Pipe, PipeTransform} from '@angular/core';

import {NO_BREAK_SPACE} from '../../const/no-break-space.const';
import {IBAN_REG_EXP_STRING} from '../../const/iban-reg-exp.const';
import {isUndefined, throwInvalidPipeArgument} from '../../helpers/formatting-helpers';

@Pipe({name: 'bszIban'})
export class BszIbanPipe implements PipeTransform {
  ibanRegExp = new RegExp(IBAN_REG_EXP_STRING);

  private removeSpaces(iban: string): string {
    return iban.replace(/\s+/g, '');
  }

  private insertSpaces(iban: string): string {
    return iban.replace(/(.{4})/g, '$1' + NO_BREAK_SPACE).trim();
  }

  public transform(value: string | null | undefined): string {
    // return empty string for nil param
    if (isUndefined(value)) {
      return '';
    }

    // if value is not of an accepted type then fail
    if (typeof value !== 'string') {
      throwInvalidPipeArgument(BszIbanPipe, value);
    }

    // proceed with pipe logic
    const condensedValue = this.removeSpaces(value);
    const isIban = this.ibanRegExp.test(condensedValue);

    if (isIban) {
      return this.insertSpaces(condensedValue);
    } else {
      return value;
    }
  }
}
