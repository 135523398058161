import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TranslatedText} from '@basuiz/web-app-applet-api';
import {PORTAL_INITIALIZATION_FAILURE_CONFIG} from './portal-initialization-failure.config.provider';
import {PortalInitializationFailureConfig} from './portal-initialization-failure.config.definition';
import {SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'bsz-portal-initialization-failure',
  templateUrl: './portal-initialization-failure.component.html',
  styleUrls: ['./portal-initialization-failure.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalInitializationFailureComponent {
  readonly errorMessageTitle: TranslatedText;
  readonly errorMessageInfo: TranslatedText;

  readonly bankLogoUrl: SafeUrl | undefined;

  constructor(
    translateService: TranslateService,
    @Inject(PORTAL_INITIALIZATION_FAILURE_CONFIG) initializationFailureConfig: PortalInitializationFailureConfig
  ) {
    this.bankLogoUrl =
      initializationFailureConfig.type === 'default' ? initializationFailureConfig.bankLogoFile : undefined;

    if (Object.keys(translateService.translations[translateService.currentLang]).length > 0) {
      this.errorMessageTitle = translateService.instant(
        'web-app-portal.application-initialization-failure.error-message.title'
      );
      this.errorMessageInfo = translateService.instant(
        'web-app-portal.application-initialization-failure.error-message.info'
      );
    } else {
      if (initializationFailureConfig.type === 'default') {
        const {title, info} = initializationFailureConfig.fallBackErrorMessage;
        this.errorMessageTitle = title;
        this.errorMessageInfo = info;
      } else {
        this.errorMessageTitle = 'Sorry, there was an error and the page could not be loaded';
        this.errorMessageInfo = 'Please reload the page or try again later';
      }
    }
  }
}
