import {Inject, Optional, Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';

import {InvalidPipeArgumentError} from '../common/invalid-pipe-argument-error';
import {BSZ_DATE_PIPE_DEFAULT_OPTIONS, BszDatePipeConfig} from './date.pipe.config';
import {isDateFormatSupported} from './date.pipe.definitions';

@Pipe({name: 'bszDate'})
export class BszDatePipe implements PipeTransform {
  private readonly config: BszDatePipeConfig;

  constructor(@Optional() @Inject(BSZ_DATE_PIPE_DEFAULT_OPTIONS) defaultConfig?: BszDatePipeConfig) {
    this.config = defaultConfig || new BszDatePipeConfig();
  }

  transform(value: Date | moment.Moment | string | number | null | undefined, format?: string): string {
    if (!format) {
      format = this.config.dateFormat;
    }

    // if it is undefined or null, return empty string
    if (value == null) {
      return '';
    }

    // if it is already Moment, just reformat it
    if (moment.isMoment(value)) {
      return BszDatePipe.getResult(value, format);
    }

    // convert and format numbers, strings and js Date
    if (
      typeof value === 'number' ||
      (typeof value === 'string' && isDateFormatSupported(value)) ||
      value instanceof Date
    ) {
      const momentInstance = moment(value);
      if (momentInstance.isValid()) {
        return BszDatePipe.getResult(momentInstance, format);
      }
    }

    // fail for invalid inputs
    throw new InvalidPipeArgumentError(value, BszDatePipe);
  }

  private static getResult(momentInstance: moment.Moment, momentFormat: string) {
    return momentInstance.format(momentFormat);
  }
}
