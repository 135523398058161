import {FocusMonitor} from '@angular/cdk/a11y';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  AfterContentInit,
  ANIMATION_MODULE_TYPE,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  Optional,
  Self,
  ViewEncapsulation,
} from '@angular/core';
import {MatTooltip} from '@angular/material/tooltip';

import {BszButtonBase} from './bsz-button-base';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[bsz-fab], input[type=button][bsz-fab]',
  styleUrls: ['./bsz-fab.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'class': 'bsz-fab',
    '[class.bsz-fab-extended]': '_extended',
    '[class.mat-elevation-z4]': '!_disabled',
  },
  template: `
    <span class="bsz-button-content" [matTooltip]="_tooltip">
      <ng-content></ng-content>
      <span class="bsz-fab-label">{{ label }}</span>
    </span>
    <span class="bsz-button-focus-overlay"></span>
    <span
      class="bsz-button-ripple"
      matRipple
      [matRippleDisabled]="_disableRipple"
      [matRippleTrigger]="hostElement"
      [matRippleCentered]="!_extended"
    ></span>
  `,
})
export class BszFabButton extends BszButtonBase implements AfterContentInit {
  @Input() label = '';

  @Input() set extended(extended: BooleanInput) {
    this._extended = coerceBooleanProperty(extended);
    this.updateTooltip();
  }

  protected _extended = false;
  protected _tooltip = '';

  constructor(
    protected override readonly elementRef: ElementRef,
    protected override readonly focusMonitor: FocusMonitor,
    @Optional() @Self() protected tooltip: MatTooltip,
    @Optional()
    @Inject(ANIMATION_MODULE_TYPE)
    animationModuleType: 'NoopAnimations' | 'BrowserAnimations' | null
  ) {
    super(elementRef, focusMonitor, animationModuleType);
  }

  ngAfterContentInit() {
    this.updateTooltip();
  }

  /** Update the tooltip, so it is shown when the label is not shown */
  private updateTooltip() {
    // if the button has already a tooltip (added in the template), no other tooltip should be shown
    if (this.tooltip) {
      this._tooltip = '';
      return;
    }

    this._tooltip = this._extended ? '' : this.label;
  }
}
