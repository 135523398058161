import {TestingCoreModule} from '../testing-core/testing-core.module';
import {NgModule} from '@angular/core';
import {MatStepperModule} from '@angular/material/stepper';
import {WebAppStepperComponent} from './web-app-stepper.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {WebAppStepDirective} from './web-app-step.directive';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {BszScreenSizeContentSwitcherModule} from '@basuiz/ui-elements';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {DisabledDirective} from './disabled.directive';
import {WebAppStepSummaryComponent} from './web-app-step-summary/web-app-step-summary.component';
import {AppShellOutletEmitterModule} from '../app-shell-outlet/emitter/index';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatStepperModule,
    MatIconModule,
    MatButtonModule,
    TestingCoreModule,
    BszScreenSizeContentSwitcherModule,
    MatProgressSpinnerModule,
    AppShellOutletEmitterModule,
  ],
  declarations: [WebAppStepperComponent, WebAppStepSummaryComponent, WebAppStepDirective, DisabledDirective],
  exports: [WebAppStepperComponent, WebAppStepSummaryComponent, WebAppStepDirective],
})
export class WebAppStepperModule {}
