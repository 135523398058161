import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AfpRestOperators, AfpRestResponse, parameterizeHttpOptions} from '@basuiz/web-app-common';
import {Observable} from 'rxjs';
import {AttentionItemCounter} from '../models/attention-item-counter.definition';
import {map} from 'rxjs/operators';
import {BszAttentionItemCounterType} from '@basuiz/web-app-applet-api';

@Injectable({
  providedIn: 'root',
})
export class AttentionItemCounterService {
  constructor(private httpClient: HttpClient) {}

  private url: string = '/com.basuiz.afs.rest.services/rest/attentionitemcounters';

  public fetch(counterTypes?: BszAttentionItemCounterType[]): Observable<AttentionItemCounter[]> {
    const options = {
      counterType: counterTypes,
    };
    const params = parameterizeHttpOptions(options);

    return this.httpClient
      .get<AfpRestResponse<{counters: AttentionItemCounter[]}>>(this.url, {params})
      .pipe(
        AfpRestOperators.extractPayload(),
        map((response: {counters: AttentionItemCounter[]}) => response.counters)
      );
  }
}
