// TODO (p3) chage to an Entity and table in database (to add more fields like translations)
export enum MassUnits {
  g = 'g',
  oz = 'oz',
  lb = 'lb',
  kg = 'kg',
}

export interface IWeight {
  weight: number;
  massUnit: MassUnits;
}
