import {ActionWithoutResponse} from '@basuiz/mobile-bridge';

/**
 * A way for external transaction signing applet to return the result of
 * transaction signing request.
 */
export class ExternalTransactionSigningFinishedAction extends ActionWithoutResponse<
  ExternalTransactionSigningFinishedRequest
> {
  readonly name = 'externalTransactionSigningFinished';
}

export interface ExternalTransactionSigningFinishedRequest {
  /**
   * Indication of how the signing process has ended.
   */
  verification: 'SUCCESS' | 'FAILED' | 'CANCELLED';

  /**
   * Id of the transaction signing request that was signed.
   */
  signedObjectId: number;
}
