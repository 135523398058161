<div class="bsz-page-header">
  <div class="bsz-page-header-body">
    <div class="bsz-page-header-toolbar" *ngIf="_hasToolbar">
      <ng-content select="bsz-page-header-toolbar"></ng-content>
      <div class="bsz-page-header-shadow-maker" *ngIf="_shadowed"
           [@pageHeaderShadowTransition]="{value: _shadowedToolbar ? 'shadowed' : ''}"></div>
    </div>
    <div class="bsz-page-header-content">
      <ng-content select="bsz-page-header-content"></ng-content>
    </div>
  </div>
  <div class="bsz-page-header-shadow-maker" *ngIf="_shadowed"
       [@pageHeaderShadowTransition]="{value: _shadowedHeader || (!_hasToolbar && _shadowed) ? 'shadowed' : ''}"></div>
</div>
