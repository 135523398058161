import {userProfilePostalAddressUpdateAppletConfigProvider} from '@basuiz/web-app-user-profile-postal-address-update-applet/config';

export const postalAddressUpdateAppletConfigProvider = userProfilePostalAddressUpdateAppletConfigProvider({
  useFactory: (defaultConfig) => ({
    ...defaultConfig,
    privacyPolicyAcceptance: {
      status: 'required',
      privacyPolicyURL: 'https://www.basuiz.com',
      auditLogMessage: 'User accepted the privacy policy before submitting an update of the postal address',
    },
  }),
});
