import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {NavigationService} from '../../../navigation/navigation.service';
import {ɵPORTAL_CONFIG} from '../../../config/portal.config.provider';
import {PortalConfig} from '../../../config/portal.config.definition';
import {PortalPage, PortalPageClass} from '../../../navigation/classes/portal-page';
import {MatDialog} from '@angular/material/dialog';
import {ComponentType} from '@angular/cdk/portal';

export interface PageHelpData {
  portalPageClass: PortalPageClass;
  helpContentComponent: ComponentType<unknown> | undefined;
}

@Component({
  selector: 'bsz-page-help',
  templateUrl: './page-help.component.html',
  styleUrls: ['./page-help.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHelpComponent implements AfterViewInit {
  constructor(
    private navigationService: NavigationService,
    @Inject(ɵPORTAL_CONFIG) private portalConfig: PortalConfig,
    private matDialog: MatDialog
  ) {}

  public readonly pageHelp$: Observable<PageHelpData> = this.navigationService.currentPortalPage$.pipe(
    map((currentPage) => this.getHelpData(currentPage)),
    filter((helpData): helpData is PageHelpData => !!helpData?.helpContentComponent)
  );

  @ViewChild('staticContainer')
  private staticContainerER: ElementRef;

  private readonly intersectionSubject = new Subject<number>();
  readonly intersection$ = this.intersectionSubject.asObservable();

  ngAfterViewInit() {
    const staticElement = this.staticContainerER?.nativeElement;
    if (staticElement) {
      const observer = new IntersectionObserver(
        (event) => {
          const lastIntersection: IntersectionObserverEntry | undefined = event[event.length - 1];
          if (lastIntersection) {
            this.intersectionSubject.next(lastIntersection.intersectionRatio);
          }
        },
        {
          threshold: [0.0, 0.01, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 0.99, 1.0],
        }
      );
      observer.observe(staticElement);
    }
  }

  private getHelpData(page: PortalPage): PageHelpData | undefined {
    if (page) {
      const portalPageClass: PortalPageClass = page.constructor as PortalPageClass;
      return {
        portalPageClass,
        helpContentComponent: this.portalConfig.pageHelpContent.get(portalPageClass),
      };
    }
    return undefined;
  }

  showHelpContent(helpData: PageHelpData) {
    const {portalPageClass} = helpData;
    if (helpData.helpContentComponent) {
      this.matDialog.open(helpData.helpContentComponent, {
        data: {portalPageClass},
      });
    }
  }
}
