import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TranslatedText} from '@basuiz/web-app-applet-api';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BszScreenSize} from '@basuiz/ui-elements';

@Component({
  selector: 'bsz-info-text',
  templateUrl: './bsz-info-text.component.html',
  styleUrls: ['./bsz-info-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BszInfoTextComponent {
  @Input() set info(text: TranslatedText) {
    this.infoText = text?.trim();
  }

  infoText: TranslatedText;

  readonly isMobile$: Observable<boolean> = this.bszScreenSize
    .getScreenSize()
    .pipe(map((screenSize) => screenSize === 'mobile'));

  //size specified in figma for having a touch area bigger than the icon itself
  readonly TOUCH_AREA_LENGTH: string = '2.62rem';

  readonly bszTestIdPrefix = 'web-app-common.extended-information';

  constructor(private bszScreenSize: BszScreenSize) {}
}
