import {Pipe, PipeTransform} from '@angular/core';

import Big from 'big.js';
import {Moment, LongDateFormatKey} from 'moment';

import {Milliseconds, CalendarDay, DateTime} from './../pipes/common/date.pipe';

@Pipe({name: 'bszCurrency'})
export class BszCurrencyPipeMock implements PipeTransform {
  transform(value: number | Big, isoCode: string): string {
    return `${value}#currency#${isoCode}`;
  }
}

@Pipe({name: 'bszDate'})
export class BszDatePipeMock implements PipeTransform {
  transform(value: Milliseconds | CalendarDay | DateTime | Date | Moment, format?: LongDateFormatKey): string {
    return `${value}#date`;
  }
}

@Pipe({name: 'bszIban'})
export class BszIbanPipeMock implements PipeTransform {
  transform(value: string): string {
    return `${value}#iban`;
  }
}

@Pipe({name: 'bszLink'})
export class BszLinkPipeMock implements PipeTransform {
  transform(value: string): string {
    return `${value}#link`;
  }
}

@Pipe({name: 'bszNumber'})
export class BszNumberPipeMock implements PipeTransform {
  transform(value: number, digitsInfo?: string): string {
    return `${value}#number#${digitsInfo ?? ''}`;
  }
}

@Pipe({name: 'bszPercentage'})
export class BszPercentagePipeMock implements PipeTransform {
  transform(value: number, digitsInfo?: string): string {
    return `${value}#percentage#${digitsInfo ?? ''}`;
  }
}

@Pipe({name: 'bszPerformance'})
export class BszPerformancePipeMock implements PipeTransform {
  transform(value: number, digitsInfo?: string): string {
    return `${value}#performance#${digitsInfo ?? ''}`;
  }
}

@Pipe({name: 'bszQuantity'})
export class BszQuantityPipeMock implements PipeTransform {
  transform(value: number): string {
    return `${value}#quantity`;
  }
}

@Pipe({name: 'bszString'})
export class BszStringPipeMock implements PipeTransform {
  transform(value: string): string {
    return value;
  }
}

@Pipe({name: 'emptyIfZero'})
export class EmptyIfZeroPipeMock implements PipeTransform {
  transform(value: string): string {
    return `${value}#emptyIfZero`;
  }
}

@Pipe({name: 'bszValueWithFormat'})
export class BszValueWithFormatPipeMock implements PipeTransform {
  transform(value: string): string {
    return `${value}#bszValueWithFormat`;
  }
}
