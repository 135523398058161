import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {Component, ContentChild, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatColumnDef} from '@angular/material/table';

import {BszCellDef, BszFooterCellDef, BszHeaderCellDef} from './bsz-cell';

const LOG_PREFIX = '[bsz-table/bsz-table-column]';

@Component({
  selector: 'bsz-table-column',
  providers: [{provide: 'BSZ_TABLE_COLUMN', useExisting: BszTableColumn}],
  template: `
    <ng-container [matColumnDef]="name" [sticky]="_sticky" [stickyEnd]="_stickyEnd">
      <!-- Column Header Cell -->
      <ng-container *matHeaderCellDef="let data">
        <ng-template
          #headerCell
          *ngIf="headerCellDef; else headerCellEmpty"
          [ngTemplateOutlet]="headerCellDef"
        ></ng-template>
        <ng-template #headerCellEmpty>
          <th bsz-header-cell>{{ data }}</th>
        </ng-template>
      </ng-container>
      <!-- Column Header Cell -->
      <!-- Column Cell -->
      <ng-container *matCellDef="let data">
        <!-- Render custom template if set -->
        <ng-template
          *ngIf="cellDef; else rawCell"
          [ngTemplateOutlet]="cellDef"
          [ngTemplateOutletContext]="{$implicit: data}"
        >
        </ng-template>
        <!-- Else just print the raw value -->
        <ng-template #rawCell>
          <td bsz-cell>{{ getPropertyFromDefinition(data) }}</td>
        </ng-template>
      </ng-container>
      <!-- Column Cell -->

      <!-- Column Footer Cell -->
      <ng-container *matFooterCellDef>
        <ng-template *ngIf="footerCellDef; else footerCellEmpty" [ngTemplateOutlet]="footerCellDef"></ng-template>
        <ng-template #footerCellEmpty>
          <td bsz-footer-cell></td>
        </ng-template>
      </ng-container>
      <!-- Column Footer Cell -->
    </ng-container>
  `,
})
export class BszTableColumn<T> implements OnInit {
  /** Unique name for this column that will be passed to MatColumnDef */
  @Input() name = '';

  /** Custom function which will handle how the property will be retrieved of the data source. */
  @Input() dataAccessor: ((data: T, name: string) => string) | null = null;

  @Input()
  set sticky(sticky: BooleanInput) {
    this._sticky = coerceBooleanProperty(sticky);
  }
  get sticky() {
    return this._sticky;
  }
  _sticky = false;

  @Input()
  set stickyEnd(stickyEnd: BooleanInput) {
    this._stickyEnd = coerceBooleanProperty(stickyEnd);
  }
  get stickyEnd() {
    return this._stickyEnd;
  }
  _stickyEnd = false;

  /** Custom template for the table Cell. */
  @ContentChild(BszCellDef, {static: true, read: TemplateRef}) cellDef!: TemplateRef<T>;
  /** Custom template for the table Header Cell. */
  @ContentChild(BszHeaderCellDef, {static: true, read: TemplateRef}) headerCellDef!: TemplateRef<T>;
  /** Custom template for the table Footer Cell. */
  @ContentChild(BszFooterCellDef, {static: true, read: TemplateRef}) footerCellDef!: TemplateRef<T>;

  /** Column definition for the mat-table. */
  @ViewChild(MatColumnDef, {static: true}) columnDef!: MatColumnDef;

  /** Indicates the position of the column in a row */
  columnIndex = 0;

  ngOnInit() {
    if (!this.headerCellDef) {
      console.error(`${LOG_PREFIX} "BszHeaderCellDefinition" is missing for column "${this.name}"`);
    }
  }

  getPropertyFromDefinition(data: T): any {
    if (this.dataAccessor) {
      return this.dataAccessor(data, this.name);
    }

    return (data as any)[this.name];
  }
}
