import {NavigationIntent} from '../../navigation/classes/navigation-intent';

export type EntryShipmentAddressIntentPayload = EntryShipmentAddressFromPartialIntentPayload | {};

export interface EntryShipmentAddressFromPartialIntentPayload {
  /**
   * The shipmentAddress ID to use to start the entry process
   */
  shipmentAddressId: number;
}

export class EntryShipmentAddressIntent extends NavigationIntent {
  constructor(public payload: EntryShipmentAddressIntentPayload = {}) {
    super();
  }
}

export function isEntryShipmentAddressFromPartialIntentPayload(
  payload: EntryShipmentAddressIntentPayload
): payload is EntryShipmentAddressFromPartialIntentPayload {
  return payload && 'shipmentAddressId' in payload;
}
