import {map} from 'rxjs/operators';

import {AnyLevelGroupHeader, BszTreeRowDefinition, isGroupHeader} from '../shared';
import {BszFlatSelectionModel} from './bsz-flat-selection-model';
import {SelectionState, TreeSelectionModel} from './bsz-selectable.definitions';

const LOG_PREFIX = '[bsz-tree-selection-model]';

/**
 * Selection model for generic tree data (tree in this case is BszTreeRowDefinition[]).
 */
export class BszTreeSelectionModel<T, G> implements TreeSelectionModel<T, G> {
  private selectionModel = new BszFlatSelectionModel<T>();

  readonly selectionChange = this.selectionModel.selectionChange.pipe(
    map((selectedChange) => ({
      ...selectedChange,
      selectedGroupMap: this.getSelectedGroupMap(),
    }))
  );

  private readonly groupHeaderMap = new Map<G, AnyLevelGroupHeader<T, G>>();

  private tree: BszTreeRowDefinition<T, G>[] = [];

  setData(tree: BszTreeRowDefinition<T, G>[]) {
    this.tree = tree;

    const {groupHeaders, leaves} = this.splitTree(tree);

    // Save groups separately from leaf items. We don't need to keep the state here, since we're going
    // to recalculate the selection state of all groups anyway.
    this.groupHeaderMap.clear();
    for (const groupHeader of groupHeaders) {
      this.groupHeaderMap.set(groupHeader.group, groupHeader);
    }

    this.selectionModel.setData(leaves);
  }

  setSelectedItems(selectedItems: T[]) {
    return this.selectionModel.setSelectedItems(selectedItems);
  }

  setItemSelected(selected: boolean, ...items: T[]) {
    return this.selectionModel.setItemSelected(selected, ...items);
  }

  setAllSelected(selected: boolean) {
    return this.selectionModel.setAllSelected(selected);
  }

  isItemSelected(item: T) {
    return this.selectionModel.isItemSelected(item);
  }

  getSelectedItems() {
    return this.selectionModel.getSelectedItems();
  }

  setGroupSelected(selected: boolean, group: G) {
    const groupHeader = this.getGroupHeader(group);
    const {leaves} = this.splitTree([groupHeader]);

    this.selectionModel.setItemSelected(selected, ...leaves);
  }

  getGroupRows(): G[] {
    return [...this.groupHeaderMap.keys()];
  }

  private getGroupHeader(group: G) {
    const groupHeader = this.groupHeaderMap.get(group);
    if (groupHeader === undefined) {
      console.error(group);
      throw new Error(`${LOG_PREFIX} Unknown group`);
    }
    return groupHeader;
  }

  private splitTree(tree: AnyLevelGroupHeader<T, G>[] | T[]): {groupHeaders: AnyLevelGroupHeader<T, G>[]; leaves: T[]} {
    const groupHeaders: AnyLevelGroupHeader<T, G>[] = [];
    const leaves: T[] = [];

    for (const item of tree) {
      if (isGroupHeader(item)) {
        const sub = this.splitTree(item.children);
        groupHeaders.push(item, ...sub.groupHeaders);
        leaves.push(...sub.leaves);
      } else {
        leaves.push(item);
      }
    }

    return {groupHeaders, leaves};
  }

  private getSelectedGroupMap(): Map<G, SelectionState> {
    const selectedGroupMap = new Map<G, SelectionState>();

    // Start calculating group selected state at top-level groups.
    for (const groupHeader of this.tree) {
      this.fillGroupSelectedState(selectedGroupMap, groupHeader);
    }

    return selectedGroupMap;
  }

  /** Calculate the selected state of a single group based on the leaf items, save and return the new state. */
  private fillGroupSelectedState(
    selectedGroupMap: Map<G, SelectionState>,
    groupHeader: AnyLevelGroupHeader<T, G>
  ): SelectionState {
    const childSelectionStates: SelectionState[] = [];
    for (const child of groupHeader.children) {
      if (isGroupHeader(child)) {
        childSelectionStates.push(this.fillGroupSelectedState(selectedGroupMap, child));
      } else {
        childSelectionStates.push(this.selectionModel.isItemSelected(child));
      }
    }

    const selectionState = this.getSelectionStateFromChildren(childSelectionStates);

    selectedGroupMap.set(groupHeader.group, selectionState);

    return selectionState;
  }

  private getSelectionStateFromChildren(childSelectionStates: SelectionState[]): SelectionState {
    if (childSelectionStates.every((selectionState) => selectionState === false)) {
      return false;
    }
    if (childSelectionStates.every((selectionState) => selectionState === true)) {
      return true;
    }
    return null;
  }
}
