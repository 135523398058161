import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';

import {BszFabMenu} from './bsz-fab-menu';
import {BszFabMenuItem} from './bsz-fab-menu-item';
import {BszFabMenuTrigger} from './bsz-fab-menu-trigger';

@NgModule({
  imports: [CommonModule, MatButtonModule, OverlayModule],
  exports: [BszFabMenu, BszFabMenuTrigger, BszFabMenuItem],
  declarations: [BszFabMenu, BszFabMenuTrigger, BszFabMenuItem],
})
export class BszFabMenuModule {}
