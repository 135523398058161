import {InjectionToken} from '@angular/core';
import {
  BusinessPartnerNameFormatter,
  BusinessPartnerNameFormatterProvider,
} from '../models/business-object-naming.definitions';

export const provideBusinessPartnerNameFormatter: BusinessPartnerNameFormatterProvider = (factory, deps) => ({
  provide: BUSINESS_PARTNER_NAME_FORMATTER,
  useFactory: factory,
  deps,
});

export const defaultBusinessPartnerNameFormatter: BusinessPartnerNameFormatter = (businessPartner) =>
  `${businessPartner.businessPartnerKey1 || businessPartner.bpNumber}, ${businessPartner.name}, ${
    businessPartner.referenceCurrencyIsoCode
  }`;

export const BUSINESS_PARTNER_NAME_FORMATTER = new InjectionToken<BusinessPartnerNameFormatter>(
  'bsz.web-app-common-business-object-naming.business-partner-name-pipe-formatter',
  {
    factory: () => defaultBusinessPartnerNameFormatter,
  }
);
