import {Options, TooltipPositionerPointObject} from 'highcharts';
import moment, {Moment} from 'moment';
import {merge} from 'lodash';
import {createChartBaseOptions} from './chart';
import {BszCurrencyPipe, BszPercentagePipe} from '../../formatting/index';
import {Injectable} from '@angular/core';
import {ChartPeriod, ChartMomentFormatService} from './chart-moment-format.service';
import {getColorFromPalette, getPrimaryColor, hexToRGB} from './chart-theme';

@Injectable()
export class TimeSeriesStackedChartBuilder {
  constructor(
    private bszPercentagePipe: BszPercentagePipe,
    private bszCurrencyPipe: BszCurrencyPipe,
    private chartMomentFormatService: ChartMomentFormatService
  ) {}

  build(series: TimeSeriesStackedChartData): Options {
    const bszPercentagePipe = this.bszPercentagePipe;
    const bszCurrencyPipe = this.bszCurrencyPipe;

    const xAxisFirstLineFormat = this.chartMomentFormatService.getFirstLineXAxisFormat(series.chartPeriod);
    const xAxisSecondLineFormat = this.chartMomentFormatService.getSecondLineXAxisFormat(series.chartPeriod);
    const tooltipFormat = this.chartMomentFormatService.getTooltipFormat(series.chartPeriod);

    const {minX, maxX} = this.getXRange(series.entities);
    const {minY, maxY} = this.getYRange(series.entities);

    const options = merge(createChartBaseOptions(), {
      chart: {
        type: series.type,
        marginTop: 50,
      },
      plotOptions: {
        series: {
          stacking: 'normal',
          fillOpacity: 0.25,
          pointWidth: 20,
          marker: {
            enabled: false,
            radius: 6,
            lineWidth: 1,
            lineColor: 'white',
          },
          states: {
            hover: {
              halo: {
                size: 0,
              },
            },
          },
        },
      },
      xAxis: {
        type: 'datetime',
        tickPixelInterval: 120,
        min: minX,
        max: maxX,
        labels: {
          formatter: function () {
            let previousTickValue: Moment | undefined;
            const value: Moment = moment(this.value);
            const previousTickUnix = this.axis.tickPositions[this.axis.tickPositions.indexOf(this.value) - 1];
            if (previousTickUnix !== undefined) {
              previousTickValue = moment(previousTickUnix);
            }

            let lowerLabel = formatSecondLine(value);
            if (
              previousTickValue &&
              formatSecondLine(previousTickValue) === lowerLabel &&
              series.xAxisTickLabel?.onlyShowSecondLineIfDistinct !== false
            ) {
              lowerLabel = '';
            }

            return `${value.format(xAxisFirstLineFormat)}<br>${lowerLabel}`;

            function formatSecondLine(m: Moment): string {
              return m.format(xAxisSecondLineFormat);
            }
          },
        },
        plotBands: this.getXPlotBands(minX, maxX),
      },
      yAxis: {
        min: minY,
        max: maxY,
        labels: {
          formatter: function () {
            if (series.yAxisTickLabel?.currencyIsoCode) {
              const maxAbsAxisValue = Math.max(...[this.axis.min, this.axis.max].map(Math.abs));
              const symbolData = ['B', 'M', 'K']
                .map((symbol, index, symbols) => ({
                  symbol,
                  divisor: Math.pow(10, 3 * (symbols.length - index)),
                }))
                .find(({divisor}) => maxAbsAxisValue / divisor >= 1) || {
                symbol: '',
                divisor: 1,
              };
              return (this.value / symbolData.divisor).toFixed(2) + symbolData.symbol;
            } else {
              return bszPercentagePipe.transform(this.value, '1.0-2');
            }
          },
        },
      },

      series: series.entities.map((entity, entityIndex) => ({
        ...entity,
        color: getColorFromPalette(series.palette || 0, entityIndex),
      })),

      tooltip: {
        shared: true,
        useHTML: true,
        borderWidth: 0,
        borderRadius: 4,
        padding: 0,
        positioner: function (width: number, height: number, point: TooltipPositionerPointObject) {
          const pointX = point.plotX - width / 2 + this.chart.plotBox.x;
          const minPlotBoxX = this.chart.plotBox.x;
          const maxPlotBoxX = this.chart.plotBox.x + this.chart.plotBox.width - width;
          const x = Math.max(Math.min(pointX, maxPlotBoxX), minPlotBoxX);
          const y = this.chart.spacingBox.y;
          return {x, y};
        },
        formatter: function () {
          const thisX = this.x;

          const total = series.entities.reduce(
            (totalVal, entity) =>
              totalVal + entity.data.filter((d) => d[0] === thisX).reduce((acc, d) => acc + d[1], 0),
            0
          );

          let value;
          if (series.yAxisTickLabel?.currencyIsoCode) {
            value = bszCurrencyPipe.transform(total, series.yAxisTickLabel?.currencyIsoCode);
          } else {
            value = bszPercentagePipe.transform(total, '1.0-2');
          }

          return `
            <div class="bsz-surface bsz-padding-2 bsz-text-center mat-elevation-z0">
                <div class="bsz-subtitle-1 bsz-text-bold">${value}</div>
                <div class="bsz-caption bsz-text-secondary">${moment(this.x).format(tooltipFormat)}</div>
            </div>
          `;
        },
      },
      legend: {
        enabled: true,
      },
    });
    return options;
  }

  private getYRange(entities: TimeSeriesStackedChartEntity[]): {
    minY: number;
    maxY: number;
  } {
    //sum up all values for a certain date, grouped by positive, negative
    const values = Object.values(
      entities.reduce((acc: Record<number, [number, number]>, entity) => {
        entity.data.forEach((data) => {
          acc[data[0]] = acc[data[0]] ?? [0, 0];
          if (data[1] > 0) {
            acc[data[0]][0] += data[1];
          } else {
            acc[data[0]][1] += data[1];
          }
        });
        return acc;
      }, {})
    ).reduce((acc: number[], val) => [...acc, ...val], []);

    const minY = Math.min(...values);
    const maxY = Math.max(...values);
    return {
      minY,
      maxY,
    };
  }

  private getXRange(entities: TimeSeriesStackedChartEntity[]): {
    minX: number;
    maxX: number;
  } {
    const values = entities.reduce((acc: number[], entity) => [...acc, ...entity.data.map((data) => data[0])], []);

    const min = moment(Math.min(...values));
    const max = moment(Math.max(...values));

    if (moment(min).year() === moment(max).year()) {
      return {
        minX: Date.UTC(min.year(), min.month() - 1, 15), //start 15 days earlier so the tooltip is displayed correctly
        maxX: Date.UTC(max.year(), min.month() + 11), // display 1 full year
      };
    } else {
      return {
        minX: Date.UTC(min.year() - 1, 6),
        maxX: Date.UTC(max.year() + 1, 0),
      };
    }
  }

  private getXPlotBands(min: number, max: number): {color: string; from: number; to: number}[] {
    const minMoment = moment(min);
    const maxMoment = moment(max);
    // Display plot band if entities belong to 2 consecutive years
    if (minMoment.year() === maxMoment.year() - 1) {
      return [
        {
          color: hexToRGB(getPrimaryColor(), '0.05'),
          from: min,
          to: Date.UTC(minMoment.year(), 11, 15),
        },
      ];
    }
    return [];
  }
}

export interface TimeSeriesStackedChartData {
  type: 'column';
  yAxisTickLabel?: {
    currencyIsoCode?: string;
  };
  xAxisTickLabel?: {
    onlyShowSecondLineIfDistinct?: boolean;
  };
  chartPeriod: ChartPeriod;
  entities: TimeSeriesStackedChartEntity[];
  palette?: number;
}

export interface TimeSeriesStackedChartEntity {
  name: string;
  data: [number, number][];
}
