import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {IProfile, IUser, UserProfile, UserPublic} from '@basuiz/shared/data-access';
import {BszScreenSize, BszSnackbarService} from '@basuiz/ui-elements';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable, map} from 'rxjs';
import {FbExtApiService} from '../../../external-api';
import {ExternalLinkService} from '../../../external-link/external-link.service';
import {passwordMinLength} from '../../models/auth.definitions';
import {AuthJwtService} from '../../services/auth-jwt.service';

// ------------------------
// APP-SPECIFIC - START
// Add here the App-Specific Profile imports
// ...
import {AverageMonthlyShipments} from '@basuiz/shared/data-access';
// APP-SPECIFIC - END
// ------------------------

@Component({
  selector: 'bsz-auth-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignupComponent implements OnInit {
  @Output()
  signupSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  goToSignin: EventEmitter<void> = new EventEmitter<void>();

  readonly isDesktop$: Observable<boolean> = this.bszScreenSize
    .getScreenSize()
    .pipe(map((screenSize) => screenSize === 'desktop'));

  signupForm: FormGroup;

  private submitLoadingSubject = new BehaviorSubject<boolean>(false);
  submitLoadingSubject$ = this.submitLoadingSubject.asObservable();

  public readonly showHelp: boolean = Boolean(process.env['APP_CUSTOMER_SUPPORT_PHONE_WHATSAPP']);

  constructor(
    private authJwtService: AuthJwtService,
    private translateService: TranslateService,
    private snackbarService: BszSnackbarService,
    private externalLinkService: ExternalLinkService,
    private bszScreenSize: BszScreenSize,
    private fbExtApiService: FbExtApiService
  ) {
    this.signupForm = new FormGroup({
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(passwordMinLength)]),
      phone: new FormControl('', [Validators.required]),
      ...this.getAppSpecificFormControls(),
    });
  }

  ngOnInit(): void {
    this.resetForm();
  }

  getFormControl(formControlName: any): UntypedFormControl {
    return this.signupForm.get(formControlName) as UntypedFormControl;
  }

  doSignup() {
    this.submitLoadingSubject.next(true);

    if (this.signupForm.valid) {
      const user: Partial<IUser> = {
        firstname: this.signupForm.get('firstname')?.value,
        lastname: this.signupForm.get('lastname')?.value,
        email: this.signupForm.get('email')?.value,
        password: this.signupForm.get('password')?.value,
        phone: this.signupForm.get('phone')?.value,
      };

      const profile: Partial<IProfile> = this.getAppSpecificProfile();

      const userProfile: UserProfile = {
        user: user as IUser,
        profile: profile as IProfile,
      };

      this.authJwtService.signup(userProfile).subscribe((user) => {
        if (user?.objectId) {
          this.snackbarService.open(
            this.translateService.instant('web-app-common.auth-jwt.signup.signup-success'),
            undefined,
            {
              dismissable: false,
              duration: 10000,
            }
          );

          this.resetForm();

          this.sendFbEventCompleteRegistration(user);

          this.signupSubmitted.emit(true);
        }
        this.submitLoadingSubject.next(false);
      });
    } else {
      this.snackbarService.open(
        this.translateService.instant('web-app-common.auth-jwt.signup.signup-invalid-form'),
        undefined,
        {
          dismissable: true,
          duration: 10000,
        }
      );

      this.submitLoadingSubject.next(false);
    }
  }

  resetForm() {
    const controls = Object.keys(this.signupForm.controls);
    for (const control of controls) {
      if (control) {
        this.getFormControl(control).reset('');
        this.getFormControl(control).setErrors(null);
      }
    }
  }

  openHelp() {
    this.externalLinkService.open(
      `https://api.whatsapp.com/send/?phone=${process.env['APP_CUSTOMER_SUPPORT_PHONE_WHATSAPP']}`
    );
  }

  async sendFbEventCompleteRegistration(user: UserPublic) {
    try {
      const event = await this.fbExtApiService.sendFbEventCompleteRegistration(user);
    } catch (error) {}
  }

  // ------------------------
  // APP-SPECIFIC - START
  // * Add here all the APP-SPECIFIC 'Profile' fields to be saved

  averageMonthlyShipmentsOptions = [
    AverageMonthlyShipments.from1To10,
    AverageMonthlyShipments.from11To60,
    AverageMonthlyShipments.from61To150,
    AverageMonthlyShipments.from151To500,
    AverageMonthlyShipments.moreThan500,
    AverageMonthlyShipments.unknown,
  ];

  getAppSpecificFormControls() {
    return {
      company: new FormControl(''),
      averageMonthlyShipments: new FormControl(''),
    };
  }

  getAppSpecificProfile() {
    return {
      company: this.signupForm.get('company')?.value,
      averageMonthlyShipments: this.signupForm.get('averageMonthlyShipments')?.value,
    };
  }

  getAverageMonthlyShipmentsLabel(averageMonthlyShipment: AverageMonthlyShipments) {
    switch (averageMonthlyShipment) {
      case AverageMonthlyShipments.from1To10:
        return this.translateService.instant(
          'web-app-common.auth-jwt.signup.form.average-monthly-shipments.option.from-1-to-10'
        );
      case AverageMonthlyShipments.from11To60:
        return this.translateService.instant(
          'web-app-common.auth-jwt.signup.form.average-monthly-shipments.option.from-11-to-60'
        );
      case AverageMonthlyShipments.from61To150:
        return this.translateService.instant(
          'web-app-common.auth-jwt.signup.form.average-monthly-shipments.option.from-61-to-150'
        );
      case AverageMonthlyShipments.from151To500:
        return this.translateService.instant(
          'web-app-common.auth-jwt.signup.form.average-monthly-shipments.option.from-151-to-500'
        );
      case AverageMonthlyShipments.moreThan500:
        return this.translateService.instant(
          'web-app-common.auth-jwt.signup.form.average-monthly-shipments.option.more-than-500'
        );
      case AverageMonthlyShipments.unknown:
        return this.translateService.instant(
          'web-app-common.auth-jwt.signup.form.average-monthly-shipments.option.unknown'
        );
      default:
        return '-';
    }
  }

  // APP-SPECIFIC - END
  // ------------------------
}
