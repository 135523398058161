<div *ngIf="regulatoryLinks.length > 0" class="regulatory-links bsz-text-secondary">
  <ng-container *ngFor="let item of regulatoryLinks">
    <a *ngIf="item !== 'separator'" (click)="onOpenRegulatoryLink(item.url)">
      {{ item.label | translate }}
    </a>
    <span *ngIf="item === 'separator'">
      {{ 'web-app-portal.main-nav.regulatory-links.separator' | translate }}
    </span>
  </ng-container>
</div>
