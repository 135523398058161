<section class="bsz-carousel"
         [ngClass]="_carouselClass()"
         [attr.aria-label]="ariaLabel" [attr.aria-labelledby]="ariaLabelledby" [attr.aria-describedby]="ariaDescribedby"
         [tabindex]="pagesLength > 1 ? '0' : '-1'"
         bszCarouselFocusControl
         [class.bsz-carousel-selection-mode]="selectionModeActive"
         [class.bsz-carousel-selection-mode-adjusted]="selectionModeActive && pagesLength === 2">
  <div class="bsz-carousel-frame">
    <div class="bsz-carousel-prev" *ngIf="!simplifiedInteraction()">
      <button mat-icon-button class="bsz-carousel-arrow-button"
              (click)="goToPreviousPage()"
              [disabled]="(!activePageRealIndex || arrowDisabledBySize) && !infiniteCarousel"
              [class.bsz-carousel-control-disabled]="(!activePageRealIndex || arrowDisabledBySize) && !infiniteCarousel"
              [attr.aria-label]="'ui-elements.bsz-carousel.accessibility.previous-page' | translate">
        <mat-icon>navigate_before</mat-icon>
      </button>
    </div>

    <div role="list" class="bsz-carousel-content"
         bszCarouselSwipe (swipeStart)="onSwipeStart()" (swipeMove)="onSwipeMove($event)"
         (swipeEnd)="onSwipeEnd($event)"
         [blockSwipe]="simplifiedInteraction()"
         [swipeRightLimit]="!infiniteCarousel ? 0 : undefined"
         [swipeLeftLimit]="!infiniteCarousel ? swipeLeftLimit : undefined"
         [class.bsz-carousel-infinite]="infiniteCarousel">
      <ng-content select="bsz-carousel-item"></ng-content>
    </div>

    <div class="bsz-carousel-next" *ngIf="!simplifiedInteraction()">
      <button mat-icon-button class="bsz-carousel-arrow-button"
              (click)="goToNextPage()"
              [disabled]="(pagesLength <= 1 || activePageRealIndex >= pagesLength - 1 || arrowDisabledBySize) && !infiniteCarousel"
              [class.bsz-carousel-control-disabled]="(pagesLength <= 1 || activePageRealIndex >= pagesLength - 1) && !infiniteCarousel"
              [attr.aria-label]="'ui-elements.bsz-carousel.accessibility.next-page' | translate">
        <mat-icon>navigate_next</mat-icon>
      </button>
    </div>
  </div>

  <nav class="bsz-carousel-navigation" *ngIf="pagesLength > 1"
       [attr.aria-label]="'ui-elements.bsz-carousel.accessibility.carousel-navigation' | translate">
    <button mat-icon-button *ngFor="let pageIndex of pageIndexes"
            (click)="setActivePage(pageIndex + pageIndexLoopAdjustment)"
            class="bsz-carousel-navigation-button"
            [ngClass]="{'bsz-carousel-navigation-button-active': pageIndex === activePageVirtualIndex}"
            [attr.aria-label]="getButtonPageAriaLabel(pageIndex, pageIndex === activePageVirtualIndex)">
      <span class="bsz-carousel-navigation-bullet"></span>
    </button>
  </nav>
</section>
