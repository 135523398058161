import {
  BszFeatureToggle,
  getAllEnabledFeatureToggle,
  PartialFeatureToggle,
  patchFeatureToggle,
} from '@basuiz/web-app-feature-toggle';
import {InjectionToken} from '@angular/core';

export const featureToggleLocalStorageKey = 'bsz.web-app-dev-tools.feature-toggle';

export const devToolsFeatureTogglePersistenceManager = {
  clear,
  update,
  load: loadFeatureToggleFromLocalStorage,
};

function clear(): void {
  localStorage.removeItem(featureToggleLocalStorageKey);
}

function update(featureToggle: BszFeatureToggle): void {
  localStorage.setItem(featureToggleLocalStorageKey, JSON.stringify(featureToggle));
}

function loadFeatureToggleFromLocalStorage(): BszFeatureToggle | undefined {
  const raw: string | null = localStorage.getItem(featureToggleLocalStorageKey);
  if (raw) {
    try {
      /* Feature toggles stored in the local storage might be old.
         A new version of web-app might include changes to the feature toggle definition, e.g new nodes.
         Therefore, restoring a tree stored before later changes might lead to runtime errors.
         E.g. a applet config tries to access a node that does not exists in the stored tree, which leads to an error
         and the application crashes.
         Using the all-enabled tree and patching it with the stored tree makes sure the resulting tree is
         complete.
      */
      const stored = JSON.parse(raw) as PartialFeatureToggle;
      const patched = patchFeatureToggle(getAllEnabledFeatureToggle(), stored);
      update(patched);
      return patched;
    } catch (e) {
      console.error('Could not restore a feature toggle tree set by @basuiz/web-app-dev-tools. Stored value:', raw);
      console.error(e);
    }
  }
  return undefined;
}

/** Provides the feature toggle edited with the dev tools,
 * or undefined if no feature toggle is stored / the feature toggle has been reset */
export const WEB_APP_DEV_TOOLS_FEATURE_TOGGLE = new InjectionToken<BszFeatureToggle | undefined>(
  'bsz.web-app-dev-tools.feature-toggle.injection',
  {
    factory: loadFeatureToggleFromLocalStorage,
  }
);
