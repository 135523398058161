import {CommonModule, DecimalPipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {BszI18nModule} from '@basuiz/i18n';

import {BszFileUpload} from './bsz-file-upload';
import {BszFileUploadSelectedFile} from './bsz-file-upload-selected-file';
import {DragAndDrop} from './drag-and-drop';

@NgModule({
  declarations: [BszFileUpload, BszFileUploadSelectedFile, DragAndDrop],
  imports: [ReactiveFormsModule, CommonModule, BszI18nModule, MatButtonModule, MatRippleModule, MatIconModule],
  exports: [BszFileUpload, BszFileUploadSelectedFile],
  providers: [DecimalPipe],
})
export class BszFileUploadModule {}
