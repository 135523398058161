<div *ngIf="notificationsNew$ | async as notificationsNew" class="bsz-padding-y6">
  <div class="bsz-display-flex bsz-align-items-baseline bsz-justify-space-between">
    <div class="bsz-title-6">{{ 'web-app-alert-list-applet.title' | translate }}</div>
    <bsz-notification-list-actions
      [hasNewNotifications]="notificationsNew.length > 0"
      (showAllNotifications)="showAllNotifications.emit()"
      (markAllNotificationsAsRead)="onMarkAllNotificationsAsRead()"
      bszTestId="{{ bszTestIdPrefix }}.actions"
    ></bsz-notification-list-actions>
  </div>
  <div *ngIf="notificationsNew">
    <bsz-central-message
      class="bsz-margin-t12 bsz-margin-b12 bsz-display-flex bsz-justify-center"
      *ngIf="!notificationsNew.length"
      [title]="'web-app-alert-list-applet.no-messages.title' | translate"
      [info]="'web-app-alert-list-applet.no-messages.description' | translate"
      [svgIconLabel]="'bszNotificationsChecked'"
      bszTestId="{{ bszTestIdPrefix }}.no-notifications"
    ></bsz-central-message>
    <ng-container *ngIf="notificationsNew && notificationsNew.length > 0">
      <bsz-data-list [dataSource]="notificationsNew" class="bsz-margin-t2">
        <bsz-micro-card
          class="bsz-cursor-pointer"
          *bszDataListItemDef="let notification"
          [ngClass]="{
            'notifications-list-new':
              notification.status === statusList.NEW || notification.status === statusList.UNREAD
          }"
          (click)="userClickedNotification(notification)"
          bszTestId="{{ bszTestIdPrefix }}.card"
        >
          <div class="bsz-caption bsz-text-secondary" bszTestId="{{ bszTestIdPrefix }}.date-of-generation">{{
            notification.dateOfGeneration | bszDate: {format: 'lll'}
          }}</div>
          <div class="bsz-caption bsz-text-bold" bszTestId="{{ bszTestIdPrefix }}.category">{{
            notification.category.name
          }}</div>
          <div class="bsz-caption" [innerHTML]="notification.content" bszTestId="{{ bszTestIdPrefix }}.content"></div>
        </bsz-micro-card>
      </bsz-data-list>
    </ng-container>
  </div>
</div>
