import {BszManifestFormAction, TranslationKey} from '@basuiz/web-app-applet-api';
import {ManifestOverviewPage} from '@basuiz/web-app-portal';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {PortalPage} from '../navigation/classes/portal-page';
import {TranslationKeyWithParams} from '../navigation/translation-key-with-params';

export interface ManifestEntryPagePayload {
  /**
   * Determines the action to be done
   */
  manifestFormAction?: BszManifestFormAction;
}

const defaultPayload: ManifestEntryPagePayload = {manifestFormAction: {action: 'create'}};

export class ManifestEntryPage extends PortalPage {
  constructor(public payload: ManifestEntryPagePayload = defaultPayload) {
    super();
  }

  get parentPortalPage(): PortalPage | null {
    return new ManifestOverviewPage();
  }

  get breadcrumbText(): TranslationKey | TranslationKeyWithParams {
    return asTranslationKey('web-app-portal.pages.manifest-entry.breadcrumb');
  }

  get breadcrumbTestId(): string {
    return 'web-app-portal.pages.manifest-entry.breadcrumb';
  }

  get titleText(): TranslationKey {
    return asTranslationKey('web-app-portal.pages.manifest-entry.window-title');
  }

  static readonly routerConfigPath = 'manifests/entry';
  get route(): Array<string> {
    return [`/${ManifestEntryPage.routerConfigPath}`];
  }
}
