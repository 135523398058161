import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {Component, ElementRef, Inject, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {SafeUrl} from '@angular/platform-browser';
import {BszScreenSize, ScreenSize} from '@basuiz/ui-elements';
import {BszAttentionItemCounterType, BszNotification, TranslatedText} from '@basuiz/web-app-applet-api';
import {ExternalLinkService} from '@basuiz/web-app-common';
import {TranslateService} from '@ngx-translate/core';
import {Observable, merge, of} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {PortalConfig} from '../../config/portal.config.definition';
import {ɵPORTAL_CONFIG} from '../../config/portal.config.provider';
import {ShowNotificationRelatedSectionIntent, ShowNotificationsOverviewIntent} from '../../intents/intents.index';
import {navigationIntentFactoryPerCounterType} from '../../navigation/attention-item-counter-navigation-intent';
import {NavigationService} from '../../navigation/navigation.service';
import {PortalLayoutService} from '../portal-layout/portal-layout.service';

@Component({
  selector: 'bsz-app-bar',
  templateUrl: './app-bar.component.html',
  styleUrls: ['./app-bar.component.scss'],
})
export class AppBarComponent {
  public readonly bankLogoUrl: SafeUrl | undefined = this.portalConfig.bankLogoFile;
  public readonly showGlobalFilter: boolean = this.portalConfig.showGlobalFilters;
  public readonly showNotifications: boolean = this.portalConfig.showNotifications;
  public readonly showHelp: boolean = Boolean(process.env['APP_CUSTOMER_SUPPORT_PHONE_WHATSAPP']);
  readonly bszTestIdPrefix = 'web-app-portal.app-bar';

  public readonly mainNavToggleAriaLabel$: Observable<TranslatedText> = merge(
    of(this.translateService.instant('web-app-portal.app-bar.main-nav-toggle.aria-label.toggle-menu')),
    this.portalLayoutService.mainNavOpened$.pipe(
      map((mainNavOpened) =>
        mainNavOpened
          ? this.translateService.instant('web-app-portal.app-bar.main-nav-toggle.aria-label.close-menu')
          : this.translateService.instant('web-app-portal.app-bar.main-nav-toggle.aria-label.open-menu')
      )
    )
  );
  private openedOverlayRef: Record<string, OverlayRef> = {};

  screenSize$: Observable<ScreenSize> = this.bszScreenSize.getScreenSize();

  constructor(
    @Inject(ɵPORTAL_CONFIG) private readonly portalConfig: PortalConfig,
    private navigationService: NavigationService,
    private translateService: TranslateService,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private portalLayoutService: PortalLayoutService,
    private bszScreenSize: BszScreenSize,
    private externalLinkService: ExternalLinkService
  ) {}

  onToggleMainNav() {
    this.portalLayoutService.toggleMainNav();
  }

  onNavigateToHome() {
    this.navigationService.ɵnavigateByPortalPage(this.portalConfig.navigation.homePortalPage);
  }

  userClickedNotification(overlayIdentifier: string, notification: BszNotification) {
    this.disposeOverlay(overlayIdentifier);
    this.navigationService.navigate(
      new ShowNotificationRelatedSectionIntent({notificationCategoryId: notification.categoryId})
    );
  }

  onShowAllNotifications(overlayIdentifier?: string) {
    this.disposeOverlay(overlayIdentifier);
    this.navigationService.navigate(new ShowNotificationsOverviewIntent());
  }

  @ViewChild('appBarRef', {read: ElementRef})
  private appBarRef: ElementRef;

  openSidePanel(overlayIdentifier: string, templateRef: TemplateRef<unknown>) {
    const positionStrategy = this.overlay.position().global().top('0').right('0');

    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy,
      height: '100%',
      maxWidth: '85%',
      panelClass: 'bsz-web-app-portal--side-panel--container',
    });

    overlayRef
      .backdropClick()
      .pipe(take(1))
      .subscribe(() => {
        const entry = Object.entries(this.openedOverlayRef).find(([key, value]) => value === overlayRef);

        if (entry) {
          delete this.openedOverlayRef[entry[0]];
        }
        overlayRef.dispose();
      });

    this.openedOverlayRef[overlayIdentifier] = overlayRef;
    const portal = new TemplatePortal(templateRef, this.viewContainerRef);
    overlayRef.attach(portal);
  }

  onNotificationsIconClicked(screenSize: ScreenSize, templateRef: TemplateRef<unknown>) {
    if (screenSize === 'mobile') {
      this.onShowAllNotifications();
    } else {
      this.openSidePanel('notificationsRecent', templateRef);
    }
  }

  onCounterClicked(overlayIdentifier: string, bszCounterType: BszAttentionItemCounterType) {
    this.navigationService.navigate(navigationIntentFactoryPerCounterType[bszCounterType]());
    this.disposeOverlay(overlayIdentifier);
  }

  private disposeOverlay(overlayIdentifier?: string) {
    if (overlayIdentifier) {
      this.openedOverlayRef[overlayIdentifier].dispose();
      delete this.openedOverlayRef[overlayIdentifier];
    }
  }

  openHelp() {
    this.externalLinkService.open(
      `https://api.whatsapp.com/send/?phone=${process.env['APP_CUSTOMER_SUPPORT_PHONE_WHATSAPP']}`
    );
  }
}
