import {Injectable, Injector} from '@angular/core';
import {BszI18nService} from '@basuiz/i18n';
import {OptionalWebAppCommonLocaleConfig, WEB_APP_COMMON_LOCALE_CONFIG} from './web-app-common-locale.config';
import {LocaleId} from '@basuiz/web-app-applet-api';
import {LocaleSettings} from './definition/locale-settings.definition';

export interface ActiveLocale {
  localeId: LocaleId;
  settings: LocaleSettings | undefined;
}

/** Service returning information from the locale settings passed by the application via WebAppModule.forRoot()
 * for the currently active locale. */
@Injectable({
  providedIn: 'root',
})
export class ActiveLocaleSettingsService {
  constructor(private bszI18nService: BszI18nService, private injector: Injector) {}

  get activeLocaleSettings(): ActiveLocale {
    const {localeId} = this.bszI18nService;
    const localeSettings = this.localeConfig?.localeSettings[localeId];
    if (!localeSettings) {
      console.warn(`No locale settings found in WebAppCommonLocaleConfig for the locale: ${localeId}`);
    }
    return {localeId, settings: localeSettings};
  }

  get localeConfigAvailable(): boolean {
    return !!this.localeConfig;
  }

  private get localeConfig(): OptionalWebAppCommonLocaleConfig {
    return this.injector.get(WEB_APP_COMMON_LOCALE_CONFIG);
  }
}
