import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';

/**
 * Guard to be used in pages that should be available as public pages
 * where all users can access (logged-in users, and not-logged-in users)
 * Pages like: 'public pricing', 'terms and conditions', etc.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthJwtUserPublicGuardService implements CanActivate {
  constructor() {}

  canActivate() {
    return true;
  }
}
