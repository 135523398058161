import {PipeTransform} from '@angular/core';
import {BusinessObjectNameFormatter, DisplayedIn} from '../models/business-object-naming.definitions';

export abstract class BusinessObjectNamePipe<BUSINESS_OBJECT, TO_NAME>
  implements PipeTransform, DisplayedInToStringTransformer<BUSINESS_OBJECT> {
  dropdown: ToStringFunction<BUSINESS_OBJECT>;
  view: ToStringFunction<BUSINESS_OBJECT>;
  subtitle: ToStringFunction<BUSINESS_OBJECT>;
  filter: ToStringFunction<BUSINESS_OBJECT>;
  selection: ToStringFunction<BUSINESS_OBJECT>;

  protected constructor(private nameFormatter: BusinessObjectNameFormatter<TO_NAME>) {
    this.setupDisplayedInToStringFunctions();
  }

  transform(businessObject: BUSINESS_OBJECT, displayedIn: DisplayedIn): string {
    // ensure the pipe is being called correctly
    if (!displayedIn) {
      throw new Error(`[${this.constructor.name}] parameter 'displayedIn' must be passed to the pipe`);
    }

    // handle missing business object gracefully
    if (typeof businessObject !== 'object') {
      return '';
    }

    // build the object to name from the domain object
    const objectToName = this.getObjectToName(businessObject);

    // format the object to name
    return this.nameFormatter(objectToName, displayedIn);
  }

  abstract getObjectToName(businessObject: BUSINESS_OBJECT): TO_NAME;

  private setupDisplayedInToStringFunctions() {
    // WARNING: it is important that arrow functions are used to bind 'this' for these definitions
    DisplayedIn.forEach((displayedIn) => {
      this[displayedIn] = (businessObject: BUSINESS_OBJECT) => this.transform(businessObject, displayedIn);
    });
  }
}

export type ToStringFunction<BUSINESS_OBJECT> = (businessObject: BUSINESS_OBJECT) => string;
export type DisplayedInToStringTransformer<BUSINESS_OBJECT> = Record<DisplayedIn, ToStringFunction<BUSINESS_OBJECT>>;
