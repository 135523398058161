import {ActionWithoutResponse} from '@basuiz/mobile-bridge';

/**
 * Opens native document safe applet and pre-filters all the documents with the
 * protfolio id if provided.
 */
export class FilterDocumentsAction extends ActionWithoutResponse<FilterDocumentsRequest> {
  readonly name = 'filterDocuments';
}

export interface FilterDocumentsRequest {
  /**
   * Show documents that corresponds the portfolio that matches the provided id.
   */
  portfolioId?: number;
}
