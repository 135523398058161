import {Type} from '@angular/core';
import {ActionWithoutResponse, BszMobileBridgeService} from '@basuiz/mobile-bridge';
import {
  CustomNavigationIntentHandler,
  CustomNavigationIntentHandlerMap,
  CustomNavigationIntentHandlerResult,
  NavigationIntent,
} from '@basuiz/web-app-portal';
import {PortalHybridDebugService} from '../debug/portal-hybrid-debug.service';

/**
 * Portal intent handlers can be specified both by Basuiz and by customer config. In portal-hybrid the hierarchy of
 * handlers is complex, with each handler having the ability to handle the intent (return a PortalPage or `ignore`)
 * or to delegate to the next handler in the hierarchy (`execute-default`)
 *
 * Priority in portal hybrid, highest to lowest:
 *
 * 1. customer hybridOverrides.config.customNavigationIntentHandlers
 * 2. Basuiz default portal-hybrid handler overrides in `getPortalConfigForHybrid`
 * 3. customer portal.config.customNavigationIntentHandlers
 * 4. Basuiz default portal handlers (built into the portal, not considered in portal configuration)
 *
 * This builder constructs a handler map that defines the `customNavigationIntentHandlers` passed to the portal config,
 * where each handler may compose the handlers provided for priority levels 1-3. When a new handler is added to the map
 * it automatically wraps and delegates to an existing handler (for `execute-default`) for the same intent if it
 * already exists in the map. Handlers for each level in the priority order are added by calling the equivalent
 * method on the builder. The final call to `build()` ensures the correct priority order is applied.
 *
 * The generated handlers will output debug information when PortalDebugService#navigationIntentHandling is enabled
 *
 */
export class CustomNavigationIntentHandlerOverrideMapBuilder {
  constructor(
    private mobileBridgeService: BszMobileBridgeService,
    private portalHybridDebugService: PortalHybridDebugService
  ) {}

  private customNavigationIntentHandlerMap: CustomNavigationIntentHandlerMap = new Map();
  private customerPortalConfigHandlerMap: CustomNavigationIntentHandlerMap = new Map();
  private customerHybridOverrideHandlerMap: CustomNavigationIntentHandlerMap = new Map();
  private portalHybridLibraryOverrideHandlerMap: CustomNavigationIntentHandlerMap = new Map();

  /**
   * 3. customer portal.config.customNavigationIntentHandlers
   */
  addClientPortalConfigHandlers(
    handlerMap: CustomNavigationIntentHandlerMap
  ): CustomNavigationIntentHandlerOverrideMapBuilder {
    this.customerPortalConfigHandlerMap = handlerMap;
    return this;
  }

  /**
   * 2. Basuiz default portal-hybrid handler overrides in `getPortalConfigForHybrid`
   */
  addPortalHybridLibraryMobileActionOverrideFor<INTENT extends NavigationIntent>(
    intentClass: Type<INTENT>,
    actionFactory: (intent: INTENT) => ActionWithoutResponse<unknown> | undefined
  ): CustomNavigationIntentHandlerOverrideMapBuilder {
    this.portalHybridLibraryOverrideHandlerMap.set(
      intentClass,
      this.createMobileActionHandlerFor(intentClass, actionFactory)
    );
    return this;
  }

  /**
   * 1. customer hybridOverrides.config.customNavigationIntentHandlers
   */
  addClientHybridOverrideHandlers(
    handlerMap: CustomNavigationIntentHandlerMap | undefined
  ): CustomNavigationIntentHandlerOverrideMapBuilder {
    if (handlerMap) {
      this.customerHybridOverrideHandlerMap = handlerMap;
    }
    return this;
  }

  /**
   * Builds the final handlers from all of those provided, composing multiple handlers for the same intent in
   * priority order where necessary.
   */
  build(): CustomNavigationIntentHandlerMap {
    this.addHandlerOverrides(this.customerPortalConfigHandlerMap, 'portal.config.customNavigationIntentHandlers');
    this.addHandlerOverrides(this.portalHybridLibraryOverrideHandlerMap, 'getPortalConfigForHybrid');
    this.addHandlerOverrides(
      this.customerHybridOverrideHandlerMap,
      'hybridOverrides.config.customNavigationIntentHandlers'
    );
    return this.customNavigationIntentHandlerMap;
  }

  /**
   * A convenience method to add overrides for multiple handlers defined in an existing map.
   */
  private addHandlerOverrides(handlerMap: CustomNavigationIntentHandlerMap, sourceDescription: string) {
    handlerMap.forEach((handler, intentClass) => {
      this.addHandlerOverrideFor(intentClass, handler, sourceDescription);
    });
  }

  /**
   * This method is at the core of building the handler hierarchy. It creates a handler that wraps the handler that
   * is passed in. If that handler decides not to handle the intent (returns `execute-default`) the wrapper will
   * call any pre-existing handler instead. Where that pre-existing handler may already be a wrapper created by a
   * previous call. And so on...
   */
  private addHandlerOverrideFor<INTENT extends NavigationIntent>(
    intentClass: Type<INTENT>,
    handler: (intent: INTENT) => CustomNavigationIntentHandlerResult | Promise<CustomNavigationIntentHandlerResult>,
    handlerOrigin: string
  ): CustomNavigationIntentHandlerOverrideMapBuilder {
    const existingHandler = this.customNavigationIntentHandlerMap.get(intentClass);

    const overrideHandler = async (intent: INTENT) => {
      const handlerResult = await handler(intent);

      this.portalHybridDebugService.logCustomIntentHandling(intent, handlerResult, handlerOrigin);

      if (handlerResult !== 'execute-default') {
        return handlerResult;
      }

      return !!existingHandler ? await existingHandler(intent) : 'execute-default';
    };

    this.customNavigationIntentHandlerMap.set(intentClass, overrideHandler);

    return this;
  }

  /**
   * This method simplifies the creation of a custom handler that triggers a native action instead of returning a page
   * to navigate to. The user of the builder only needs to provide an `actionFactory` that returns the appropriate
   * action for the given intent. The builder then constructs the handler that:
   *
   * 1. calls the factory to determine the intent
   * 2. if the factory doesn't return an intent the handler delegates to the next handler (`execute-default`)
   * 3. if the native app doesn't support the action returned by the factory the handler delegates to the next handler
   * 4. if the native app does support the action the handler triggers it and stops delegation (`ignore`)
   */
  private createMobileActionHandlerFor<INTENT extends NavigationIntent>(
    intentClass: Type<INTENT>,
    actionFactory: (intent: INTENT) => ActionWithoutResponse<unknown> | undefined
  ): CustomNavigationIntentHandler {
    const handler = async (intent: INTENT) => {
      const action = actionFactory(intent);
      const isSupported = !!action && (await this.mobileBridgeService.isActionSupported(action));
      const handlerResult = isSupported ? 'ignore' : 'execute-default';

      if (action && isSupported) {
        await this.mobileBridgeService.triggerAction(action);
      }

      this.portalHybridDebugService.logMobileActionIntentHandling(intent, action, isSupported);

      return handlerResult;
    };

    return handler;
  }
}
