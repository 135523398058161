import {HybridViewHandler, HybridViewHandlerMap} from './portal-hybrid.config.definition';
import {View} from '@basuiz/mobile-bridge';
import {Type} from '@angular/core';

export class HybridViewHandlerMapBuilder {
  private hybridViewHandlerMap: HybridViewHandlerMap;

  constructor() {
    this.hybridViewHandlerMap = {};
  }

  addHandlerFor<VIEW extends View>(
    viewClass: Type<VIEW>,
    handler: HybridViewHandler<VIEW>
  ): HybridViewHandlerMapBuilder {
    const viewName = new viewClass().name;
    this.hybridViewHandlerMap[viewName] = handler;
    return this;
  }

  build(): HybridViewHandlerMap {
    return this.hybridViewHandlerMap;
  }
}
