<ng-container [formGroup]="_dateRangeForm">
  <input
    (change)="_onChange()"
    [placeholder]="_getStartDateInputPlaceHolder()"
    [attr.min]="min ? _dateAdapter.toIso8601(min) : null"
    [attr.max]="max ? _dateAdapter.toIso8601(max) : null"
    [attr.aria-labelledby]="_ariaLabelledByStartDate"
    [attr.aria-haspopup]="_datepicker ? 'dialog' : null"
    [attr.aria-owns]="(_datepicker?.opened && _datepicker?.uniqueId) || null"
    #startDateInput
    formControlName="startDate"
    class="bsz-date-range-input-start-date"
    autocomplete="off"
    type="text"
  />
  <span #startDateMirrorElement class="bsz-date-range-input-start-date-mirror" aria-hidden="true">{{_getMirrorValue()}}</span>
  <span #startDateLabelSuffix [id]="id + '-start-date-label-suffix'" hidden>
    {{ 'ui-elements.bsz-datepicker.range-input.start-date.label' | translate }}
  </span>

  <span class="bsz-date-range-input-separator">&ndash;</span>
  <input
    (change)="_onChange()"
    (keydown)="_onEndDateKeyDown($event)"
    [placeholder]="_getEndDateInputPlaceHolder()"
    [attr.min]="min ? _dateAdapter.toIso8601(min) : null"
    [attr.max]="max ? _dateAdapter.toIso8601(max) : null"
    [attr.aria-labelledby]="_ariaLabelledByEndDate"
    [attr.aria-haspopup]="_datepicker ? 'dialog' : null"
    [attr.aria-owns]="(_datepicker?.opened && _datepicker?.uniqueId) || null"
    #endDateInput
    formControlName="endDate"
    class="bsz-date-range-input-end-date"
    autocomplete="off"
    type="text"
  />
  <span #endDateLabelSuffix [id]="id + '-end-date-label-suffix'" hidden>
    {{ 'ui-elements.bsz-datepicker.range-input.end-date.label' | translate }}
  </span>
</ng-container>