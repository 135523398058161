import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MonoTypeOperatorFunction, Observable} from 'rxjs';
import {Currency} from '../models/currency.definitions';
import {AfpRestOperators, AfpRestResponse} from '../../afp-rest/index';
import {map} from 'rxjs/operators';

export interface CurrencyDTO {
  readonly id: number;
  readonly countryId: number;
  readonly isoCode: string;
  readonly name: string;
  readonly tradeUnit: number;
  readonly decimals: number;
  readonly midXrate?: number;
}

@Injectable({providedIn: 'root'})
export class CurrencyDataService {
  constructor(private readonly httpClient: HttpClient) {}

  #url: string = '/com.basuiz.afs.rest.services/rest/baseBankData/currencyList';

  fetch(): Observable<CurrencyDTO[]> {
    return this.httpClient
      .get<AfpRestResponse<Currency[]>>(this.#url)
      .pipe(AfpRestOperators.extractPayload(), this.filterInvalidCurrencies());
  }

  private filterInvalidCurrencies(): MonoTypeOperatorFunction<CurrencyDTO[]> {
    return map((currencies) => currencies.filter((currency) => !!currency.isoCode));
  }
}
