import {NgModule} from '@angular/core';
import {WebAppAppletModule, WebAppCommonModule} from '@basuiz/web-app-common';

import {WebAppAlertListAppletComponent} from './web-app-alert-list-applet.component';
import {AlertListComponent} from './alert-list/alert-list.component';
import {BszContextMenuModule, BszDataListModule} from '@basuiz/ui-elements';
import {WebAppSelfServiceNotificationsModule} from '@basuiz/web-app-self-service-notifications';
import {CounterListComponent} from './components/counter-list/counter-list.component';
import {NotificationListComponent} from './components/notification-list/notification-list.component';
import {WebAppAlertModule} from '@basuiz/web-app-alert';
import {NotificationListActionsComponent} from './components/notification-list-actions/notification-list-actions.component';

@NgModule({
  declarations: [
    WebAppAlertListAppletComponent,
    AlertListComponent,
    CounterListComponent,
    NotificationListComponent,
    NotificationListActionsComponent,
  ],
  imports: [
    WebAppAppletModule,
    BszContextMenuModule,
    BszDataListModule,
    WebAppAlertModule,
    WebAppCommonModule,
    WebAppSelfServiceNotificationsModule,
  ],
  exports: [WebAppAlertListAppletComponent], // Notice: only the applet component should be exported!
})
export class WebAppAlertListAppletModule {}
