import {LiveAnnouncer} from '@angular/cdk/a11y';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  Host,
  Input,
  Optional,
  ViewContainerRef,
} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MatInput} from '@angular/material/input';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {TranslateService} from '@ngx-translate/core';

const LOG_PREFIX = `[bsz-spinner]`;
const SPINNER_DIAMETER = 20;

@Directive({
  selector: 'button[bszSpinner], input[bszSpinner]',
  host: {
    '[class.bsz-spinner-active]': 'isActive',
  },
})
export class BszSpinner {
  @Input()
  set bszSpinner(isActive: BooleanInput) {
    this.setSpinnerStatus(coerceBooleanProperty(isActive));
  }

  private readonly loadingText = this.translate.instant('ui-elements.bsz-spinner.accessibility.loading');

  private spinnerComponentRef: ComponentRef<MatProgressSpinner> | null = null;
  private spinnerComponentRefHtml: HTMLElement | null = null;
  private contentToHide: HTMLElement | null = null;
  private hostElement: MatButton | MatInput;

  isActive = false;

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly liveAnnouncer: LiveAnnouncer,
    private readonly translate: TranslateService,
    @Host() @Optional() private readonly button: MatButton,
    @Host() @Optional() private readonly input: MatInput,
    private readonly _elementRef: ElementRef<HTMLElement>
  ) {
    this.hostElement = this.button || this.input;

    if (!this.hostElement) {
      throw new Error(`${LOG_PREFIX} requires matInput or mat-button (or any of its variants)`);
    }
  }

  private createSpinner() {
    const spinnerComponentRef = this.viewContainerRef.createComponent(MatProgressSpinner, {index: 0});

    spinnerComponentRef.instance.mode = 'indeterminate';
    spinnerComponentRef.instance.diameter = SPINNER_DIAMETER;
    spinnerComponentRef.instance.color = 'primary';
    // angular material adds the role "progressbar" and keeping it would be an accessibility problem
    // We change it to "presentation" because it is used in that way
    spinnerComponentRef.instance._elementRef.nativeElement.setAttribute('role', 'presentation');
    // angular material adds the tabindex "-1", so it is programmatically focusable. For accessibility reasons, that
    // is a problem because it means it could have interaction. Because we use it in a presentational way, we just
    // remove the attribute
    spinnerComponentRef.instance._elementRef.nativeElement.removeAttribute('tabindex');

    this.spinnerComponentRefHtml = (spinnerComponentRef.hostView as EmbeddedViewRef<MatProgressSpinner>)
      .rootNodes[0] as HTMLElement;

    return spinnerComponentRef;
  }

  private setSpinnerStatus(isActive: boolean): void {
    // if it is previously disabled or readonly, do nothing
    if (this.isDisabledOrReadOnly() && !this.isActive) {
      return;
    }

    this.setHostStatus(isActive);
    this.isActive = isActive;
    if (isActive) {
      this.activateSpinner();
    } else {
      this.deactivateSpinner();
    }
  }

  private activateSpinner() {
    if (!this.spinnerComponentRef) {
      this.spinnerComponentRef = this.createSpinner();
    }

    if (this.button) {
      this.activateButtonSpinner();
    } else {
      this.activateInputSpinner();
    }
    this.liveAnnouncer.announce(this.loadingText);
  }

  private deactivateSpinner() {
    this.spinnerComponentRef?.destroy();
    this.spinnerComponentRef = null;

    if (this.button) {
      this.deactivateButtonSpinner();
    } else {
      this.deactivateInputSpinner();
    }
  }

  private activateButtonSpinner() {
    const spinnerNativeElement = this.getSpinnerNativeElement();

    spinnerNativeElement.style.position = 'absolute';
    // vertical position estimated to be centered
    spinnerNativeElement.style.top = `calc(50% - ${SPINNER_DIAMETER / 2}px)`;
    // horizontal position estimated to be centered
    spinnerNativeElement.style.left = `calc(50% - ${SPINNER_DIAMETER / 2}px)`;

    this.contentToHide = this._elementRef.nativeElement.querySelector('.mat-button-wrapper') as HTMLElement;
    this.contentToHide.style.opacity = '0';

    this.spinnerComponentRefHtml && this._elementRef.nativeElement.appendChild(this.spinnerComponentRefHtml);
  }

  private getSpinnerNativeElement(): HTMLElement {
    const spinnerNativeElement = this.spinnerComponentRef?.instance._elementRef.nativeElement as HTMLElement | null;

    if (!spinnerNativeElement) {
      throw new Error(`${LOG_PREFIX} could not find the HTML element of the spinner`);
    }

    return spinnerNativeElement;
  }

  private deactivateButtonSpinner() {
    if (this.contentToHide) {
      this.contentToHide.style.opacity = '';
    }
  }

  private activateInputSpinner() {
    const inputElement = this._elementRef.nativeElement;
    const spinnerNativeElement = this.getSpinnerNativeElement();
    const inputClientRect = inputElement.getBoundingClientRect();
    const inputHeight = inputClientRect.height;
    const inputWidth = inputClientRect.width;
    const inputTop = inputElement.offsetTop;
    const inputLeft = inputElement.offsetLeft;
    spinnerNativeElement.style.position = 'absolute';
    // vertical position estimated to be centered
    const spinnerStyleTop = inputTop + inputHeight / 2 - SPINNER_DIAMETER / 2;
    spinnerNativeElement.style.top = `${spinnerStyleTop}px`;
    // horizontal position estimated to be in the right side
    const spinnerStyleLeft = inputLeft + inputWidth - SPINNER_DIAMETER;
    spinnerNativeElement.style.left = `${spinnerStyleLeft}px`;

    const currentPadding = inputElement.style.paddingRight || '0px';
    // it adds 5px to prevent the text of the field being contacting the spinner
    inputElement.style.paddingRight = `calc(${currentPadding} + ${SPINNER_DIAMETER}px + 5px)`;
    inputElement.style.boxSizing = 'border-box';

    this.spinnerComponentRefHtml &&
      inputElement.parentElement?.insertBefore(this.spinnerComponentRefHtml, inputElement);
  }

  private deactivateInputSpinner() {
    const inputElement = this._elementRef.nativeElement;
    const currentPadding = inputElement.style.paddingRight;
    inputElement.style.paddingRight = `calc(${currentPadding} - ${SPINNER_DIAMETER}px - 5px)`;
    inputElement.style.boxSizing = 'content-box';
  }

  /**
   * Set the status of the host element in terms of user interaction:
   *  - if it is a button, it sets whether it is disabled or not (user cannot interact)
   *  - if it is an input, it sets whether it is readonly or not (user cannot interact but its value is part of the model)
   */
  private setHostStatus(isActive: boolean) {
    if (this.hostElement instanceof MatButton) {
      this.hostElement.disabled = isActive;
    } else {
      this.hostElement.readonly = isActive;
    }
  }

  private isDisabledOrReadOnly(): boolean {
    if (this.hostElement instanceof MatInput) {
      return this.hostElement.readonly || this.hostElement.disabled;
    }
    return this.hostElement.disabled;
  }
}
