import {Directive} from '@angular/core';

/** Structural directive to mark the slot for the title section of a modal bottom sheet */
@Directive({
  selector: '[bszModalBottomSheetTitleDef]',
})
export class BszModalBottomSheetTitleDef {}
/** Structural directive to mark the slot for the content section of a modal bottom sheet */
@Directive({
  selector: '[bszModalBottomSheetContentDef]',
})
export class BszModalBottomSheetContentDef {}
/** Structural directive to mark the slot for the footer section of a modal bottom sheet */
@Directive({
  selector: '[bszModalBottomSheetFooterDef]',
})
export class BszModalBottomSheetFooterDef {}
