import {JestFixtureFacade, JestFixtureFacadeAware} from './jest-fixture-facade';
import {MatButton} from '../generic/components';

export class JestMatButton implements JestFixtureFacadeAware, MatButton {
  constructor(public testId: string, public jestFixtureFacade: JestFixtureFacade) {}

  get exists(): boolean {
    return !!this.element;
  }

  get element(): HTMLButtonElement {
    return <HTMLButtonElement>(
      (this.jestFixtureFacade.select(this.testId) ?? this.jestFixtureFacade.selectFromParent(this.testId))
    );
  }

  get disabled(): boolean {
    return this.element.disabled;
  }

  get textContent(): string | null {
    return this.element.textContent;
  }

  get ariaLabel(): string | null {
    return this.element.getAttribute('aria-label');
  }

  click() {
    this.element.click();
  }
}
