import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ViewChild} from '@angular/core';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {BszMatServerPaginatorIntl} from './bsz-mat-server-paginator-intl';

@Component({
  selector: 'bsz-paginator',
  templateUrl: './bsz-paginator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: BszMatServerPaginatorIntl,
    },
  ],
})
export class BszPaginatorComponent implements OnChanges {
  @Input()
  pageSize: number = 20;

  @Input()
  pageIndex: number = 0;

  @Input()
  numberOfItemsOnCurrentPage: number = 0;

  @Input()
  hasMoreResults: boolean = false;

  @Output()
  page = new EventEmitter<number>();

  length: number = 0;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  ngOnChanges() {
    const numberOfResultsUpToThisPage = this.pageIndex * this.pageSize;
    const numberOfResultsOnThisPage = this.hasMoreResults ? this.pageSize : this.numberOfItemsOnCurrentPage;
    const numberOfResultsAfterThisPage = this.hasMoreResults ? 1 : 0;

    this.length = numberOfResultsUpToThisPage + numberOfResultsOnThisPage + numberOfResultsAfterThisPage;
  }
}
