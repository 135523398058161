import {PageObjectComponentFactory} from '../generic/page-object-component-factory';
import {
  FormFieldMatInput,
  FormFieldMatSelect,
  MatButton,
  MatButtonToggleGroup,
  MatMenu,
  SimpleElement,
  BszTable,
  SimpleElementList,
  MatTabGroup,
  FormFieldMatAutocomplete,
  MatRadioGroup,
  SelectableListItems,
  MatSlideToggle,
} from '../generic/components';
import {JestFixtureFacade, JestFixtureFacadeAware} from './jest-fixture-facade';
import {JestSimpleElement} from './jest-simple-element';
import {JestMatButton} from './jest-mat-button';
import {JestFormFieldMatInput} from './jest-form-field-mat-input';
import {JestFormFieldMatSelect} from './jest-form-field-mat-select';
import {JestMatButtonToggleGroup} from './jest-mat-button-toggle-group';
import {JestMatMenu} from './jest-mat-menu';
import {JestBszTable} from './jest-bsz-table';
import {JestSimpleElementList} from './jest-simple-element-list';
import {JestMatTabGroup} from './jest-mat-tab-group';
import {JestFormFieldMatAutocomplete} from './jest-form-field-mat-autocomplete';
import {JestMatRadioGroup} from './jest-mat-radio-group';
import {JestSelectableListItems} from './jest-selectable-list-items';
import {JestMatSlideToggle} from './jest-mat-slide-toggle';

export class JestPageObjectComponentFactory implements PageObjectComponentFactory, JestFixtureFacadeAware {
  constructor(public jestFixtureFacade: JestFixtureFacade) {}

  simpleElement(testId: string): SimpleElement {
    return new JestSimpleElement(testId, this.jestFixtureFacade);
  }

  simpleElementList(testId: string): SimpleElementList {
    return new JestSimpleElementList(testId, this.jestFixtureFacade);
  }

  matButton(testId: string): MatButton {
    return new JestMatButton(testId, this.jestFixtureFacade);
  }

  matButtonToggleGroup(testId: string): MatButtonToggleGroup {
    return new JestMatButtonToggleGroup(testId, this.jestFixtureFacade);
  }

  matSlideToggle(testId: string): MatSlideToggle {
    return new JestMatSlideToggle(testId, this.jestFixtureFacade);
  }

  matInput(testId: string): FormFieldMatInput {
    return new JestFormFieldMatInput(testId, this.jestFixtureFacade);
  }

  matMenu(testId: string): MatMenu {
    return new JestMatMenu(testId, this.jestFixtureFacade);
  }

  matSelect<T>(testId: string): FormFieldMatSelect<T> {
    return new JestFormFieldMatSelect(testId, this.jestFixtureFacade);
  }

  matAutocomplete(testId: string): FormFieldMatAutocomplete {
    return new JestFormFieldMatAutocomplete(testId, this.jestFixtureFacade);
  }

  matTabGroup(testId: string): MatTabGroup {
    return new JestMatTabGroup(testId, this.jestFixtureFacade);
  }

  matRadioGroup(testId: string): MatRadioGroup {
    return new JestMatRadioGroup(testId, this.jestFixtureFacade);
  }

  selectableListItems(): SelectableListItems {
    return new JestSelectableListItems(this.jestFixtureFacade);
  }

  list<T extends Object>(testId: string, callback: (factory: PageObjectComponentFactory) => T): T {
    //TODO: Look at what to do here AFP-42958
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    return new Proxy(<T>{}, {
      get(target: T, p: PropertyKey, receiver: any): T | number {
        const index: number = parseInt(<string>p, 10) + 1;
        const pathFragment = `[test-id="${testId}"]:nth-child(${index})`;
        const subFacade = that.jestFixtureFacade.getSub(pathFragment);
        return callback(new JestPageObjectComponentFactory(subFacade));
      },
    });
  }

  bszTable(testId: string): BszTable {
    return new JestBszTable(testId, this.jestFixtureFacade);
  }
}
