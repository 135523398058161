// IMPORTANT: do not modify this file

import {FactoryProvider, inject, InjectionToken} from '@angular/core';
import {BszConfigFactory, BszConfigFactoryWithDeps} from '@basuiz/web-app-applet-api';
import {CommonConfig} from './common.config.definition';
import {getDefaultConfig} from './common.config.default';
import {validateCommonConfig} from './common.config.validation';

// IMPORTANT: do not modify this file

export const ɵCOMMON_CONFIG = new InjectionToken<CommonConfig>('bsz.web-app.common-config', {
  factory: () => inject(COMMON_CONFIG_DEFAULT),
});

// IMPORTANT: do not modify this file

export const COMMON_CONFIG_DEFAULT = new InjectionToken<CommonConfig>('bsz.web-app.common-config.default', {
  factory: () => {
    const config = getDefaultConfig();
    const error = validateCommonConfig(config);
    if (error) {
      console.error('Default CommonConfig validation failed:', error);
    }
    return config;
  },
});

// IMPORTANT: do not modify this file

export function commonConfigProvider({useFactory, deps}: BszConfigFactoryWithDeps<CommonConfig>): FactoryProvider {
  return {
    provide: ɵCOMMON_CONFIG,
    useFactory: factoryWithValidation(useFactory),
    deps: [COMMON_CONFIG_DEFAULT, ...(deps || [])],
  };
}

// IMPORTANT: do not modify this file

function factoryWithValidation(factory: BszConfigFactory<CommonConfig>): BszConfigFactory<CommonConfig> {
  return (defaultConfig: CommonConfig, ...deps: unknown[]) => {
    const config = factory(defaultConfig, ...deps);
    const error = validateCommonConfig(config);
    if (error) {
      console.error('CommonConfig validation failed:', error);
    }
    return config;
  };
}

// IMPORTANT: do not modify this file
