import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {PartialState as NotificationsState} from './notifications/notifications.reducer';
import * as notificationsSelectors from './notifications/notifications.selectors';
import {Notification} from '../models/notification.definition';
import {
  loadNotifications,
  deleteNotificationList,
  markNotificationListAsUnread,
  restoreNotificationList,
  markAllNotificationsAsRead,
  markNotificationListAsRead,
} from './notifications/notifications.actions';

@Injectable({
  providedIn: 'root',
})
export class NotificationsFacade {
  readonly notifications$: Observable<Notification[]> = this.store.pipe(
    select(notificationsSelectors.selectNotifications)
  );
  readonly notificationsNew$: Observable<Notification[]> = this.store.pipe(
    select(notificationsSelectors.selectNewNotifications)
  );
  readonly notificationsInbox$: Observable<Notification[]> = this.store.pipe(
    select(notificationsSelectors.selectInboxNotifications)
  );

  readonly notificationsLoading$: Observable<boolean> = this.store.pipe(select(notificationsSelectors.selectLoading));
  readonly notificationsLoaded$: Observable<boolean> = this.store.pipe(select(notificationsSelectors.selectLoaded));
  readonly notificationsError$: Observable<string | null> = this.store.pipe(
    select(notificationsSelectors.selectErrorMessage)
  );

  readonly getNotificationById: (id: number) => Observable<Notification | undefined> = (id: number) =>
    this.store.pipe(select(notificationsSelectors.selectById, {id}));

  public userDeletedNotificationList(notificationIds: number[]) {
    this.store.dispatch(deleteNotificationList({notificationIds}));
  }

  public userMarkedAllNotificationsAsRead() {
    this.store.dispatch(markAllNotificationsAsRead());
  }

  public userMarkedNotificationListAsRead(notificationIds: number[]) {
    this.store.dispatch(markNotificationListAsRead({notificationIds}));
  }

  public userMarkedNotificationListAsUnread(notificationIds: number[]) {
    this.store.dispatch(markNotificationListAsUnread({notificationIds}));
  }

  public userRestoredNotificationList(notificationIds: number[]) {
    this.store.dispatch(restoreNotificationList({notificationIds}));
  }

  public triggerLoadNotifications() {
    this.store.dispatch(loadNotifications());
  }

  constructor(protected readonly store: Store<NotificationsState>) {}
}
