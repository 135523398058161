import {Directive, Input} from '@angular/core';
import {ThemePalette} from '@angular/material/core';

@Directive({
  selector: '[bsz-fab-menu-trigger]',
})
export class BszFabMenuTrigger {
  /** color property which applies to the trigger button. */
  @Input() color: ThemePalette;

  /** aria-label for the trigger. */
  @Input('aria-label') ariaLabel: string | null = null;

  /** aria-labelledby for the trigger. */
  @Input('aria-labelledby') ariaLabelledby: string | null = null;

  /** aria-describedby for the trigger. */
  @Input('aria-describedby') ariaDescribedby: string | null = null;
}
