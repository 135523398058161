export type CarouselMode = 'standard' | 'selection';

export interface BszCarouselActiveData {
  index: number;
  itemsData: any[];
}

export interface PageItems {
  mobile?: number;
  tablet?: number;
  desktop?: number;
}

export type CarouselConfiguration = PageItems;

export const activeItemClass = 'bsz-carousel-item-active';
export const hiddenClass = 'bsz-carousel-item-hidden';
export const dataPageIndexAttrName = 'data-page-index';
export const dataIndexAttrName = 'data-index';
