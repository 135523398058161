import {registerLocaleData} from '@angular/common';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import moment, {Moment} from 'moment';

/**
 * Interface for translations
 * key pairs where the key is the NGX Translation key and the value is its corresponding localized text
 * or
 * compiled translations for ICU MessageFormat
 */
export type BszTranslationDictionary = BszPureTranslationDictionary | BszCompiledTranslationDictionary;
export interface BszPureTranslationDictionary {
  [key: string]: string;
}
export interface BszCompiledTranslationDictionary {
  (params: Record<string, unknown>): string;
  toString(global?: string): string;
}

/**
 * Initializer configuration for Phrase support
 * Usually returned by BszPhraseService.getPhraseInitializer()
 */
export interface BszPhraseInitializer {
  loadEditor: () => void;
  localeId: string;
  translation: BszTranslationDictionary;
}

/**
 * Initializer configuration
 */
export interface BszI18nInitializerConfig {
  /** string in standard locale format, e.g. 'de-CH' */
  localeId: string;
  /** locale configuration object from Angular */
  angularLocale: any;
  /** BszTranslationDictionary containing keys and respective translations for selected locale */
  translation: BszTranslationDictionary;
  /** custom Moment locale specification (see https://momentjs.com/docs/#/customization/) */
  momentCustomization?: moment.LocaleSpecification;
}

/**
 * This service is used by the APP to initialize i18n for the specified locale at bootstrap
 */
@Injectable()
export class BszI18nService {
  private _localeId: string | null = null;

  constructor(private ngxTranslateService: TranslateService) {}

  /**
   * Public getter for current locale id
   *
   * Prefer using this than injecting Angular's LOCALE_ID
   */
  get localeId(): string {
    return this._localeId ?? '';
  }

  /**
   * Public getter for current lang id
   */
  get langId(): string {
    return this._localeId?.split('-')[0] ?? '';
  }

  /**
   * Initialization function specified in the application that wants to set a certain locale.
   *
   * Indirectly called by APP_INITIALIZATION asynchronously.
   *
   * @param config BszI18nInitializerConfig containing localeId etc.
   */
  initialize(config: BszI18nInitializerConfig): void {
    this.doInitialize(config);
  }

  /**
   * Initialization function that also loads phrase editor (internal)
   *
   * @param config BszI18nInitializerConfig
   * @param phraseEditorLoader
   *
   * @private
   */
  _initializeWithPhrase(config: BszI18nInitializerConfig, phraseEditorLoader?: () => void): void {
    this.doInitialize(config);
    if (phraseEditorLoader) {
      phraseEditorLoader();
    }
  }

  private doInitialize(config: BszI18nInitializerConfig): void {
    // sanity checks
    assertDefinedInput(config?.localeId, 'localeId');
    assertDefinedInput(config?.angularLocale, 'angularLocale');
    assertDefinedInput(config?.translation, 'translation');
    // locale can only be defined once and never changed at runtime
    assertLocaleInitialization(this._localeId);

    // store locale id
    this._localeId = config.localeId;

    // set Angular locale
    registerLocaleData(config.angularLocale, config.localeId);

    // set Moment locale
    moment.locale(config.localeId);

    if (config?.momentCustomization) {
      const currentConfig = getConfigFromMoment(moment());
      // see https://momentjs.com/docs/#/customization/
      moment.updateLocale(config.localeId, {...currentConfig, ...config.momentCustomization});
    }

    // set ngx-translate locale and translations
    this.ngxTranslateService.use(config.localeId);
    this.ngxTranslateService.setTranslation(config.localeId, config.translation, false);
  }
}

/**
 * moment's updateLocale should update the properties specified, while others will remain the same.
 * However this is not happening and only the specified properties are updated while the rest
 * fallback to the english defaults.
 *
 * We use this function to recover the translations of a localized moment object before we call
 * updateLocale to update the same locale.
 *
 * @see https://momentjs.com/docs/#/customization/
 */
function getConfigFromMoment(momentObject: Moment): moment.LocaleSpecification {
  return {
    calendar: (momentObject.localeData() as any)._calendar,
    relativeTime: (momentObject.localeData() as any)._relativeTime,
    months: momentObject.localeData().months(),
    monthsShort: momentObject.localeData().monthsShort(),
    weekdays: momentObject.localeData().weekdays(),
    weekdaysShort: momentObject.localeData().weekdaysShort(),
    weekdaysMin: momentObject.localeData().weekdaysMin(),
  };
}

/** Helper for sanity checks to make sure parameter is not falsy */
function assertDefinedInput(parameter: unknown, name: string) {
  if (!parameter) {
    throw Error(`[BszI18nService] The input parameter '${name}' is missing or invalid.`);
  }
}

/** Helper to make sure locale has not been already initialized */
function assertLocaleInitialization(localeId: string | null) {
  if (localeId) {
    throw Error(`[BszI18nService] The locale has been already set to '${localeId}'.`);
  }
}
