<mat-divider></mat-divider>

<bsz-progress-bar
  *ngIf="inProgress | async"
  [message]="'web-app-common.auth.verification.matrix-verification.progress.message' | translate"
></bsz-progress-bar>

<ng-container *bszDesktopContent>
  <div class="bsz-subtitle-1 bsz-padding-t6 bsz-padding-b4">{{
    'web-app-common.auth.verification.matrix-verification.title' | translate
  }}</div>
</ng-container>
<ng-container *bszMobileContent>
  <div class="bsz-subtitle-2 bsz-padding-t6 bsz-padding-b4">{{
    'web-app-common.auth.verification.matrix-verification.title' | translate
  }}</div>
</ng-container>

<div class="bsz-body-1 bsz-padding-b4">{{
  'web-app-common.auth.verification.matrix-verification.description' | translate
}}</div>

<form [formGroup]="form" (ngSubmit)="verify()">
  <div *ngIf="coordinates | async as coords" class="mdc-layout-grid__inner bsz-padding-t3 bsz-padding-b4">
    <ng-container
      [formArrayName]="verificationTokenControlId"
      *ngFor="let coord of coords; let i = index; let numberOfFields = count"
    >
      <div
        class="mdc-layout-grid__cell--span-12-phone bsz-padding-t1"
        [class.mdc-layout-grid__cell--span-6]="numberOfFields > 1"
        [class.mdc-layout-grid__cell--span-12]="numberOfFields === 1"
      >
        <div class="bsz-body-1 bsz-padding-b1">{{
          'web-app-common.auth.verification.matrix-verification.coordinate.label' | translate: {coordinate: coord}
        }}</div>
        <mat-form-field>
          <mat-label>{{
            'web-app-common.auth.verification.matrix-verification.coordinate.placeholder' | translate
          }}</mat-label>
          <div *ngIf="numericOnlyResponseCode; then numericOnlyInput; else defaultInput"></div>

          <ng-template #defaultInput>
            <input matInput [formControlName]="i" autocomplete="off" required cdkFocusInitial />
          </ng-template>

          <ng-template #numericOnlyInput>
            <input
              matInput
              inputmode="numeric"
              pattern="[0-9]*"
              [formControlName]="i"
              autocomplete="off"
              required
              cdkFocusInitial
            />
          </ng-template>

          <mat-error *ngIf="getVerificationTokenControl(i).invalid">{{
            getInputErrorTranslationKey(i) | translate
          }}</mat-error>
        </mat-form-field>
      </div>
    </ng-container>
  </div>

  <div class="bsz-text-right bsz-padding-b4">
    <button mat-button color="accent" type="button" (click)="cancel()">{{
      'web-app-common.auth.verification.matrix-verification.button.cancel' | translate
    }}</button>
    <button
      mat-flat-button
      color="accent"
      [disabled]="!form.valid || (inProgress | async) || (coordinates | async) === null"
      type="submit"
      >{{ 'web-app-common.auth.verification.matrix-verification.button.verify' | translate }}</button
    >
  </div>
</form>
