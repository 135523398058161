<header
  #header
  class="bsz-page-header"
  [ngClass]="{'full-screen': fullScreen, 'bsz-display-none': hideHeader || (containsHeaderItems$ | async) === false}"
>
  <div
    class="mdc-layout-grid"
    [ngClass]="{'remove-bottom-padding': _showTabHeaders()}"
    [ngStyle]="{'max-width': fullScreen ? '' : _maxPageWidthInPx}"
  >
    <div class="bsz-page-header-area-above-title" [ngClass]="{'bsz-display-none': fullScreen}">
      <bsz-breadcrumbs bszTestId="{{ bszTestIdPrefix }}.breadcrumbs"></bsz-breadcrumbs>
    </div>

    <div class="bsz-page-header-title-row" [ngClass]="{'bsz-margin-t3': !fullScreen}">
      <div>
        <h2
          *ngIf="_showTitle"
          bszTestId="{{ bszTestIdPrefix }}.header-title"
          class="bsz-margin-b0"
          [ngClass]="{
            'bsz-title-5': !fullScreen,
            'bsz-title-6': fullScreen
          }"
        >
          <ng-content select="[bszPortalPageHeaderTitle]"></ng-content>
        </h2>
        <div *ngIf="_showSubtitle" class="bsz-subtitle-2" [ngClass]="{'bsz-display-none': fullScreen}">
          <ng-content select="[bszPortalPageHeaderSubtitle]"></ng-content>
        </div>
      </div>
      <span class="bsz-margin-l2 bsz-display-flex" [ngClass]="{'bsz-display-none': fullScreen}">
        <ng-content select="[bszPortalPageHeaderActions]"></ng-content>
        <bsz-app-shell-outlet-receiver [category]="'primaryActions'"></bsz-app-shell-outlet-receiver>
      </span>
      <button
        mat-icon-button
        [ngClass]="{'bsz-display-none': !fullScreen}"
        (click)="onFullScreenCloseClick()"
        [attr.aria-label]="
          fullScreenCloseButtonAriaLabel ||
          ('web-app-portal.bsz-portal-page-layout.full-screen.header.close-button.aria-label' | translate)
        "
      >
        <mat-icon>clear</mat-icon>
      </button>
    </div>

    <div class="bsz-page-header-area-below-title">
      <ng-content select="[bszPortalPageHeaderExtras]"></ng-content>
    </div>

    <mat-tab-group
      class="bsz-page-header-tab-group"
      bszTestId="web-app-portal.page-layout.page-header-tab-group"
      *ngIf="_showTabHeaders()"
      [selectedIndex]="_selectedTabIndex"
      (selectedTabChange)="_setSelectedTabAndUpdateTabMemory($event.index)"
      [animationDuration]="'0ms'"
      [bszTestId]="bszTestIdPrefix + '.mat-tab-group'"
    >
      <ng-container *ngFor="let pageTab of _pageTabs">
        <mat-tab>
          <ng-template mat-tab-label>
            <span bszTestId="{{ bszTestIdPrefix }}.tab-headers.{{ pageTab.tabId }}">{{ pageTab.tabLabel }}</span>
          </ng-template>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </div>
</header>

<section
  class="bsz-page-content mdc-layout-grid"
  [ngStyle]="{
    'margin-top': (fullScreen ? header.offsetHeight : 0) + 'px',
    'max-width': fullScreen ? '' : _maxPageWidthInPx
  }"
>
  <div class="mdc-layout-grid__inner">
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone"
    >
      <mat-tab-group
        class="bsz-page-content-tab-group hide-mat-tab-header"
        *ngIf="_pageTabs?.length"
        [selectedIndex]="_selectedTabIndex"
        [animationDuration]="'0ms'"
      >
        <mat-tab *ngFor="let pageTab of _pageTabs; let index = index">
          <ng-container *ngTemplateOutlet="index === _selectedTabIndex ? pageTab.templateRef : null"></ng-container>
        </mat-tab>
      </mat-tab-group>

      <ng-content select="[bszPortalPageContent]"></ng-content>
    </div>
  </div>
</section>

<bsz-page-help></bsz-page-help>
