import {JestFixtureFacade, JestFixtureFacadeAware} from './jest-fixture-facade';
import {MatRadioButton, MatRadioGroup} from '@angular/material/radio';
import {DebugElement, QueryList} from '@angular/core';

export class JestMatRadioGroup implements JestFixtureFacadeAware {
  constructor(public testId: string, public jestFixtureFacade: JestFixtureFacade) {}

  get matRadioGroup(): MatRadioGroup {
    const matRadioGroupElement: DebugElement = this.jestFixtureFacade.debugElement.query((element: DebugElement) => {
      return element.nativeElement.getAttribute('test-id') === this.testId;
    });
    return matRadioGroupElement.injector.get<MatRadioGroup>(MatRadioGroup);
  }

  set value(value: string) {
    const radioButtons: QueryList<MatRadioButton> = this.matRadioGroup._radios;
    radioButtons.forEach((radioButton) => {
      if (radioButton.value?.toString() === value) {
        radioButton.checked = true;
        this.matRadioGroup._emitChangeEvent();
      }
    });
  }

  get value(): string {
    return this.matRadioGroup.selected?.value;
  }
}
