<ng-container *ngIf="verificationRequest?.operation as operation">
  <ng-container *ngIf="isAuthOperationSupported(operation); else unsupported">
    <bsz-mtan-verification
      *ngIf="guards.isMtanRequest(verificationRequest)"
      [verificationRequest]="verificationRequest"
      (verificationResult)="onResult($event)"
    ></bsz-mtan-verification>
    <bsz-token-verification
      *ngIf="guards.isTokenRequest(verificationRequest)"
      [verificationRequest]="verificationRequest"
      (verificationResult)="onResult($event)"
    ></bsz-token-verification>
    <bsz-cronto-verification
      *ngIf="guards.isCrontoRequest(verificationRequest)"
      [verificationRequest]="verificationRequest"
      (verificationResult)="onResult($event)"
    ></bsz-cronto-verification>
    <bsz-push-verification
      *ngIf="guards.isPushRequest(verificationRequest)"
      [verificationRequest]="verificationRequest"
      (verificationResult)="onResult($event)"
    ></bsz-push-verification>
    <bsz-futurae-verification
      *ngIf="guards.isFuturaeRequest(verificationRequest)"
      [verificationRequest]="verificationRequest"
      (verificationResult)="onResult($event)"
    ></bsz-futurae-verification>
    <bsz-matrix-verification
      *ngIf="guards.isMatrixRequest(verificationRequest)"
      [verificationRequest]="verificationRequest"
      (verificationResult)="onResult($event)"
    ></bsz-matrix-verification>
  </ng-container>

  <ng-template #unsupported>
    <bsz-unsupported-verification
      [verificationRequest]="verificationRequest"
      (verificationResult)="onResult($event)"
    ></bsz-unsupported-verification>
  </ng-template>
</ng-container>
