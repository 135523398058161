import {Country, DistanceUnits, MassUnits} from '../../../utils/models';
import {IQuotation} from './quotation.model';

export const QUOTATION_MOCK: Partial<IQuotation> = {
  senderCountry: Country.EC,
  senderTerritoryBaseId: 'EC|:|PICHINCHA|:|QUITO|:|QUITO',
  recipientCountry: Country.EC,
  recipientTerritoryBaseId: 'EC|:|GUAYAS|:|GUAYAQUIL|:|GUAYAQUIL',
  distanceUnit: DistanceUnits.cm,
  length: 2,
  height: 2,
  width: 2,
  massUnit: MassUnits.kg,
  weight: 2,
  goodsInsured: 100,
  goodsCollection: 100,
};
