import {A11yModule} from '@angular/cdk/a11y';
import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {BszPopover} from './bsz-popover';
import {BszPopoverTrigger} from './bsz-popover-trigger';

@NgModule({
  imports: [OverlayModule, CommonModule, A11yModule],
  exports: [BszPopover, BszPopoverTrigger],
  declarations: [BszPopover, BszPopoverTrigger],
})
export class BszPopoverModule {}
