import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {BszI18nModule} from '@basuiz/i18n';

import {BszSelectable, BszSelectableCheckbox, BszSelectableModule} from '../bsz-selectable/index';
import {BszTreeDataList} from './bsz-tree-data-list';
import {BszTreeDataListGroupHeaderDef} from './bsz-tree-data-list-group-header-def';
import {BszTreeDataListHeaderDef} from './bsz-tree-data-list-header-def';
import {BszTreeDataListItemDef} from './bsz-tree-data-list-item-def';
import {BszTreeDataListNode} from './bsz-tree-data-list-node';

const EXPORTED_DECLARATIONS = [
  BszTreeDataList,
  BszTreeDataListGroupHeaderDef,
  BszTreeDataListItemDef,
  BszTreeDataListNode,
  BszTreeDataListHeaderDef,
];

@NgModule({
  imports: [CommonModule, MatButtonModule, BszI18nModule, BszSelectableModule],
  exports: [
    ...EXPORTED_DECLARATIONS,

    // re-export selectable classes
    BszSelectable,
    BszSelectableCheckbox,
  ],
  declarations: EXPORTED_DECLARATIONS,
})
export class BszTreeDataListModule {}
