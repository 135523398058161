import {UserSettingDTO} from '../models/user-setting-dto.definitions';
import {getUserSettingsGroupMap} from '../models/user-settings-group.definition';
import {SelectorValue, UserSettingsSubset} from '../models/user-settings.definition';
import {isDefined} from '../../utils/defined.util';

export type UserSettingUpdateDTO = Pick<
  UserSettingDTO,
  'key' | 'groupName' | 'value' | 'isMultiValued' | 'selectorValues'
>;

interface SerializedSelectorValue {
  selector: string;
  value: string;
}

export class UserSettingsSerializer {
  private readonly userSettingsGroupMap = getUserSettingsGroupMap();

  constructor(private userSettingsSubset: UserSettingsSubset) {}

  public serialize(): UserSettingUpdateDTO[] {
    return [
      ...this.serializeGeneralSettings(),
      ...this.serializeReportingSettings(),
      ...this.serializeContactInformationSettings(),
    ];
  }

  private serializeGeneralSettings(): UserSettingUpdateDTO[] {
    const result: UserSettingUpdateDTO[] = [];
    const groupName = this.userSettingsGroupMap.general;
    const group = this.userSettingsSubset.general;

    if (group) {
      const mobileAppEnabled = 'mobileAppEnabled';
      const mobileAppEnabledValue = group.mobileAppEnabled;
      if (isDefined(mobileAppEnabledValue)) {
        result.push(this.singleValueDto(groupName, mobileAppEnabled, this.serializeBoolean(mobileAppEnabledValue)));
      }

      const mobileTransactionSigningEnabled = 'mobileTransactionSigningEnabled';
      const mobileTransactionSigningEnabledValue = group.mobileTransactionSigningEnabled;
      if (isDefined(mobileTransactionSigningEnabledValue)) {
        result.push(
          this.singleValueDto(
            groupName,
            mobileTransactionSigningEnabled,
            this.serializeBoolean(mobileTransactionSigningEnabledValue)
          )
        );
      }

      const defaultReferenceCurrency = 'defaultReferenceCurrency';
      const defaultReferenceCurrencyValue = group[defaultReferenceCurrency];
      if (isDefined(defaultReferenceCurrencyValue)) {
        result.push(
          this.singleValueDto(groupName, defaultReferenceCurrency, this.serializeNumber(defaultReferenceCurrencyValue))
        );
      }

      const preferredLanguage = 'preferredLanguage';
      const preferredLanguageValue = group.preferredLanguage;
      if (isDefined(preferredLanguageValue)) {
        result.push(this.singleValueDto(groupName, preferredLanguage, this.serializeString(preferredLanguageValue)));
      }
    }

    return result;
  }

  private serializeReportingSettings(): UserSettingUpdateDTO[] {
    const result: UserSettingUpdateDTO[] = [];
    const groupName = this.userSettingsGroupMap.reporting;
    const group = this.userSettingsSubset.reporting;

    if (group) {
      const defaultTimePeriod = 'defaultTimePeriod';
      const defaultTimePeriodValue = group[defaultTimePeriod];
      if (isDefined(defaultTimePeriodValue)) {
        result.push(
          this.singleValueDto(groupName, defaultTimePeriod, this.serializeDefaultTimePeriod(defaultTimePeriodValue))
        );
      }
    }

    return result;
  }

  private serializeContactInformationSettings(): UserSettingUpdateDTO[] {
    const result: UserSettingUpdateDTO[] = [];
    const groupName = this.userSettingsGroupMap.contactInformation;
    const group = this.userSettingsSubset.contactInformation;

    if (group) {
      const defaultMobileAddress = 'defaultMobileAddress';
      const defaultMobileAddressValue = group[defaultMobileAddress];
      if (isDefined(defaultMobileAddressValue)) {
        result.push(
          this.singleValueDto(groupName, defaultMobileAddress, this.serializeNumber(defaultMobileAddressValue))
        );
      }

      const defaultEmailAddress = 'defaultEmailAddress';
      const defaultEmailAddressValue = group[defaultEmailAddress];
      if (isDefined(defaultEmailAddressValue)) {
        result.push(
          this.singleValueDto(groupName, defaultEmailAddress, this.serializeNumber(defaultEmailAddressValue))
        );
      }
    }

    return result;
  }

  // HELPERS

  private serializeBoolean(value: boolean | null | undefined): string {
    if (typeof value === 'boolean') {
      return value.toString();
    }
    return '';
  }

  private serializeNumber(value: number | null | undefined): string {
    if (typeof value === 'number') {
      return value.toString();
    }
    return '';
  }

  private serializeString(value: string | null | undefined): string {
    if (typeof value === 'string') {
      return value;
    }
    return '';
  }

  private serializeDefaultTimePeriod(value: Object | null | undefined): string {
    if (value instanceof Object) {
      return JSON.stringify(value).replace(/\"/g, '&quot;');
    }
    return '';
  }

  private serializeSelectorValueListOfString(
    selectorValueList: SelectorValue<string>[] | undefined
  ): SerializedSelectorValue[] {
    return selectorValueList
      ? selectorValueList.map((sv) => {
          return {selector: sv.selector.toString(), value: sv.value};
        })
      : [];
  }

  private serializeSelectorValueListOfNumber(
    selectorValueList: SelectorValue<number>[] | undefined
  ): SerializedSelectorValue[] {
    return this.serializeSelectorValueListOfString(
      selectorValueList?.map((sv) => ({...sv, value: sv.value ? sv.value.toString() : ''}))
    );
  }

  private singleValueDto(groupName: string, key: string, value: string | null): UserSettingUpdateDTO {
    return {
      groupName,
      key,
      isMultiValued: false,
      value,
    };
  }

  private multiValueDto(
    groupName: string,
    key: string,
    selectorValues: SerializedSelectorValue[]
  ): UserSettingUpdateDTO {
    return {
      groupName,
      key,
      isMultiValued: true,
      selectorValues,
    };
  }
}
