import {JestFormField} from './jest-form-field';
import {DebugElement, QueryList} from '@angular/core';
import {MatOption} from '@angular/material/core';
import {MatSelect} from '@angular/material/select';
import {By} from '@angular/platform-browser';
import {FormFieldMatSelect} from '../generic/components';

export class JestFormFieldMatSelect<T = string> extends JestFormField implements FormFieldMatSelect<T> {
  get matSelect(): MatSelect {
    const matSelectElement: DebugElement = this.jestFixtureFacade.debugElement
      .query((element: DebugElement) => {
        return element.nativeElement.getAttribute('test-id') === this.testId;
      })
      .query(By.directive(MatSelect));

    return <MatSelect>matSelectElement.componentInstance;
  }

  set value(value: T) {
    const options: QueryList<MatOption> = this.matSelect.options;
    const option = options.find((opt) => {
      return opt.value?.toString() === value;
    });

    option?.select();
  }

  get value(): T {
    const options = this.matSelect.options;
    const option = options.find((opt) => opt.selected);
    return option ? option.value : undefined;
  }

  get values(): T[] {
    const selected = this.matSelect.selected;
    if (selected instanceof Array) {
      return selected.map((opt) => opt.value);
    } else {
      return (selected && [selected.value]) || [];
    }
  }
}
