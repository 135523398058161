<ng-template #loading let-reservedHeightPx="reservedHeightPx" let-message="message">
  <div class="bsz-position-relative" [ngStyle]="{'min-height': reservedHeightPx + 'px'}">
    <bsz-progress-bar [message]="message"></bsz-progress-bar>
  </div>
</ng-template>

<ng-template #noData let-message="message" let-webAppCustomIcon="webAppCustomIcon">
  <bsz-central-message
    [title]="message ? message.title : ('web-app-common.data-state.no-data.message.title' | translate)"
    [info]="message?.info"
    [additionalInfo]="
      message ? message.additionalInfo : ('web-app-common.data-state.no-data.message.additional-info' | translate)
    "
    [icon]="webAppCustomIcon ? undefined : 'error_outline'"
    [svgIconLabel]="webAppCustomIcon"
    class="bsz-display-block bsz-padding-t4"
    bszTestId="web-app-common.data-state.no-data.message"
  ></bsz-central-message>
</ng-template>

<ng-template #error>
  <bsz-technical-error></bsz-technical-error>
</ng-template>
