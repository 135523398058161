import {FeatureStatus} from './models/generic.definition';

export type PatchNode = {[key in string]: {} | FeatureStatus};

type NodePatcher = (patchedNode: PatchNode, [feature, setting]: [string, PatchNode | FeatureStatus]) => PatchNode;

export function patchRecursive(baseNode: PatchNode, deltaNode: PatchNode, allEnabledNode: PatchNode): PatchNode {
  const nodePatcher: NodePatcher = (patchedNode, [feature, setting]) => {
    const [baseFeature, deltaFeature, allEnabledFeature, allDisabledFeature] = [
      baseNode[feature],
      deltaNode[feature] as PatchNode,
      allEnabledNode[feature] as PatchNode,
      () => getDirectDescendantDisabled(allEnabledNode[feature] as PatchNode),
    ];
    const patchedSetting = isFeatureStatus(setting)
      ? setting
      : patchRecursive(
          baseFeature === 'enabled'
            ? allEnabledFeature
            : baseFeature === 'disabled'
            ? allDisabledFeature()
            : baseFeature,
          deltaFeature,
          allEnabledFeature
        );
    return {
      ...patchedNode,
      [feature]: patchedSetting,
    };
  };

  return Object.entries(deltaNode)
    .filter(([key, _]) => key in allEnabledNode)
    .reduce(nodePatcher, {...baseNode});
}

function isFeatureStatus(value: unknown): value is FeatureStatus {
  return value === 'enabled' || value === 'disabled';
}

/* Disabled inner-nodes should not be expanded more than one level since expanding a node means enabling it,
 * if a child of the patched node would be fully expanded fully,
 * it would mean that multiple features (every inner-node) are enabled,
 * even if the leaf-nodes are disabled */
function getDirectDescendantDisabled(allEnabledNode: PatchNode): PatchNode {
  const result = {...allEnabledNode};
  Object.keys(result).forEach((key) => {
    result[key] = 'disabled';
  });
  return result;
}
