import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {TranslatedText} from '@basuiz/web-app-applet-api';
import {combineLatest, Subscription} from 'rxjs';
import {QuickLinkContainerComponent} from '../quick-link-container.component';
import {BszScreenSize} from '@basuiz/ui-elements';

@Component({
  selector: 'bsz-quick-link',
  templateUrl: './quick-link.component.html',
  styleUrls: ['./quick-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickLinkComponent implements OnInit, OnDestroy {
  readonly bszTestIdPrefix = 'web-app-portal.quick-link';

  @Input() icon?: string;
  @Input() svgIconLabel?: string;
  @Input() label: TranslatedText;
  @Output() clickedQuickLink: EventEmitter<void> = new EventEmitter();

  private cellSpan: 'wide' | 'narrow' = 'wide';

  @HostBinding('class')
  private get spanClass() {
    return this.cellSpan === 'wide' ? 'mdc-layout-grid__cell--span-4' : 'mdc-layout-grid__cell--span-3';
  }

  private subscriptions = new Subscription();

  constructor(private quickLinkContainerComponent: QuickLinkContainerComponent, private bszScreenSize: BszScreenSize) {
    this.quickLinkContainerComponent.notifyAddQuickLink();
  }

  ngOnInit() {
    this.subscriptions.add(
      combineLatest([this.quickLinkContainerComponent.quickLinkCount$, this.bszScreenSize.getScreenSize()]).subscribe(
        ([quickLinkCount, screenSize]) => {
          if (screenSize === 'desktop') {
            if (quickLinkCount < 3) {
              this.cellSpan = 'wide';
            } else if (quickLinkCount % 4 === 0) {
              this.cellSpan = 'narrow';
            } else if (quickLinkCount % 3 === 0) {
              this.cellSpan = 'wide';
            } else if (quickLinkCount % 4 === 1) {
              this.cellSpan = 'wide';
            } else {
              this.cellSpan = 'narrow';
            }
          } else {
            this.cellSpan = 'wide';
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.quickLinkContainerComponent.notifyRemoveQuickLink();
    this.subscriptions.unsubscribe();
  }
}
