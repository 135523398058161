import {NavigationIntent} from '../../navigation/classes/navigation-intent';
import {PaymentCardsPageTab} from '../../page-config/index';

export interface ShowPaymentCardsIntentPayload {
  /**
   * The tab of payment cards to display
   */
  tab?: PaymentCardsPageTab;
}

export class ShowPaymentCardsIntent extends NavigationIntent {
  constructor(public payload: ShowPaymentCardsIntentPayload = {}) {
    super();
  }
}
