import {Injectable} from '@angular/core';
import {MatStepperIntl} from '@angular/material/stepper';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class MatStepperLabels extends MatStepperIntl {
  constructor(private translate: TranslateService) {
    super();
  }

  override optionalLabel = this.translate.instant('ui-elements.mat-stepper.optional');

  override completedLabel = this.translate.instant('ui-elements.mat-stepper.completed');

  override editableLabel = this.translate.instant('ui-elements.mat-stepper.editable');
}
