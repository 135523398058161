import {Relation} from 'typeorm';
import {IBaseUserOwnedEntity, ILineItem, IUser} from '../..';
import {LabelledEnum} from '../../../utils/common';
import {Currency} from '../../../utils/models/currency.model';
import {MassUnits} from '../../../utils/models/weight.model';

export enum ProductCategory {
  agriculturalSupply = 'agriculturalSupply',
  audioAndVideoAccessory = 'audioAndVideoAccessory',
  audioArticle = 'audioArticle',
  babyProduct = 'babyProduct',
  beverage = 'beverage',
  book = 'book',
  cameraAccessory = 'cameraAccessory',
  camera = 'camera',
  cellularPhoneAccessory = 'cellularPhoneAccessory',
  cellularPhone = 'cellularPhone',
  clothes = 'clothes',
  clothingAccessory = 'clothingAccessory',
  computerAccessory = 'computerAccessory',
  computer = 'computer',
  decoration = 'decoration',
  documents = 'documents',
  dron = 'dron',
  electromenor = 'electromenor',
  food = 'food',
  householdAppliance = 'householdAppliance',
  jewelryAndWatch = 'jewelryAndWatch',
  medicalEquipment = 'medicalEquipment',
  medicalSupply = 'medicalSupply',
  musicalInstrument = 'musicalInstrument',
  party = 'party',
  personalCareProduct = 'personalCareProduct',
  petAccessory = 'petAccessory',
  petFood = 'petFood',
  printer = 'printer',
  shoeAndFootwear = 'shoeAndFootwear',
  spareparts = 'spareparts',
  sportingArticle = 'sportingArticle',
  stationery = 'stationery',
  television = 'television',
  toy = 'toy',
  vehicleAccessory = 'vehicleAccessory',
  videoGameConsoleAccessory = 'videoGameConsoleAccessory',
  videoGameConsole = 'videoGameConsole',
  videoArticle = 'videoArticle',
  videoGame = 'videoGame',
}

// TODO p4 add English translation and support
/**
 * (ES) Spanish translated
 */
export const EsLabelledParcelContent: LabelledEnum<ProductCategory>[] = [
  {
    id: ProductCategory.agriculturalSupply,
    label: 'Insumos para agricultura',
  },
  {
    id: ProductCategory.audioAndVideoAccessory,
    label: 'Accesorios de audio y video',
  },
  {
    id: ProductCategory.audioArticle,
    label: 'Artículos de audio',
  },
  {
    id: ProductCategory.babyProduct,
    label: 'Productos para bebés',
  },
  {
    id: ProductCategory.beverage,
    label: 'Bebidas',
  },
  {
    id: ProductCategory.book,
    label: 'Libros',
  },
  {
    id: ProductCategory.cameraAccessory,
    label: 'Accesorios para cámaras',
  },
  {
    id: ProductCategory.camera,
    label: 'Cámaras',
  },
  {
    id: ProductCategory.cellularPhoneAccessory,
    label: 'Accesorios para celulares',
  },
  {
    id: ProductCategory.cellularPhone,
    label: 'Celulares',
  },
  {
    id: ProductCategory.clothes,
    label: 'Ropa',
  },
  {
    id: ProductCategory.clothingAccessory,
    label: 'Accesorios de vestir',
  },
  {
    id: ProductCategory.computerAccessory,
    label: 'Accesorios para computadoras',
  },
  {
    id: ProductCategory.computer,
    label: 'Computadoras',
  },
  {
    id: ProductCategory.decoration,
    label: 'Decoración',
  },
  {
    id: ProductCategory.documents,
    label: 'Documentos',
  },
  {
    id: ProductCategory.dron,
    label: 'Drones',
  },
  {
    id: ProductCategory.electromenor,
    label: 'Electromenores',
  },
  {
    id: ProductCategory.food,
    label: 'Alimentos',
  },
  {
    id: ProductCategory.householdAppliance,
    label: 'Electromésticos',
  },
  {
    id: ProductCategory.jewelryAndWatch,
    label: 'Joyas y relojes',
  },
  {
    id: ProductCategory.medicalEquipment,
    label: 'Equipamiento médico',
  },
  {
    id: ProductCategory.medicalSupply,
    label: 'Insumos médicos',
  },
  {
    id: ProductCategory.musicalInstrument,
    label: 'Instrumentos musicales',
  },
  {
    id: ProductCategory.party,
    label: 'Artículos de fiesta',
  },
  {
    id: ProductCategory.personalCareProduct,
    label: 'Productos de cuidado personal',
  },
  {
    id: ProductCategory.petAccessory,
    label: 'Accesorios para mascotas',
  },
  {
    id: ProductCategory.petFood,
    label: 'Alimento para mascotas',
  },
  {
    id: ProductCategory.printer,
    label: 'Impresoras',
  },
  {
    id: ProductCategory.shoeAndFootwear,
    label: 'Zapatos y calzado',
  },
  {
    id: ProductCategory.spareparts,
    label: 'Repuestos y piezas',
  },
  {
    id: ProductCategory.sportingArticle,
    label: 'Artículos deportivos',
  },
  {
    id: ProductCategory.stationery,
    label: 'Papelería',
  },
  {
    id: ProductCategory.television,
    label: 'Televisiones',
  },
  {
    id: ProductCategory.toy,
    label: 'Juguetes',
  },
  {
    id: ProductCategory.vehicleAccessory,
    label: 'Accesorios para vehiculos',
  },
  {
    id: ProductCategory.videoGameConsoleAccessory,
    label: 'Accesorios para consolas de videojuegos',
  },
  {
    id: ProductCategory.videoGameConsole,
    label: 'Consolas de videojuegos',
  },
  {
    id: ProductCategory.videoArticle,
    label: 'Artículos de video',
  },
  {
    id: ProductCategory.videoGame,
    label: 'Videojuegos',
  },
];

export interface IProduct extends IBaseUserOwnedEntity {
  ownerUser?: Relation<IUser>;
  shopAppObjectExternalId?: string;
  name: string;
  sku?: string;
  weight?: number;
  massUnit?: MassUnits;
  price: number;
  currency: Currency;
  category?: ProductCategory;

  /**
   * ManyToMany relation (Order & Product) via LineItem
   */
  lineItems?: Relation<ILineItem[]>;
}
