import {NavigationIntent} from '../../navigation/classes/navigation-intent';

export interface ShowInvestmentProposalDetailIntentPayload {
  investmentProposalId: number;
}

export class ShowInvestmentProposalDetailIntent extends NavigationIntent {
  constructor(public payload: ShowInvestmentProposalDetailIntentPayload) {
    super();
  }
}
