import {Component, EventEmitter, Output} from '@angular/core';
import {BszAttentionItemCounterType, BszNotification} from '@basuiz/web-app-applet-api';

@Component({
  selector: 'bsz-web-app-alert-list-applet',
  template: `<bsz-alert-list
    bszWebAppApplet
    bszTestId="testIds.web-app-alert-list-applet"
    (notificationClicked)="notificationClicked.emit($event)"
    (showAllNotifications)="showAllNotifications.emit()"
    (markAllNotificationsAsRead)="markAllNotificationsAsRead.emit()"
    (counterClicked)="counterClicked.emit($event)"
  ></bsz-alert-list>`,
})
export class WebAppAlertListAppletComponent {
  /**
   * Outputs a `BszNotification` which contains the notification id and the category id of the notification being
   * clicked. This helps to retrieve the notification from the store or route the user to the specific area in the
   * app related to the notification category.
   */
  @Output() notificationClicked: EventEmitter<BszNotification> = new EventEmitter();

  /**
   * Outputs an event when the user clicks on 'View all'
   */
  @Output() showAllNotifications: EventEmitter<void> = new EventEmitter();

  /**
   * Outputs an event when the user clicks on 'Mark all as read'
   */
  @Output() markAllNotificationsAsRead: EventEmitter<void> = new EventEmitter();

  /**
   * Outputs an 'BszAttentionItemCounterType' which represents the type of counter on which the user has clicked
   */
  @Output() counterClicked: EventEmitter<BszAttentionItemCounterType> = new EventEmitter();

  // Please align WebAppAlertListAppletTestingComponent with any changes in the API of this component
  // DO NOT REMOVE THIS COMMENT!
  //
  // - ENCAPSULATION:
  // This component serves as an interface between the applet's features and the customer application.
  // Therefore it should not contain other public elements apart from those that belong to the applet API,
  // which typically consist of @Input and @Output bindings.
  //
  // Otherwise customer could access to public properties that are intended to be used only by the applet's template,
  // leading unintended breaking changes when refactoring a applet or to customers opening strange issues when they
  // access to parts of the applet they are not supposed to.
  //
  // All the logic of the applet, and the associated templates, should be located in internal components of the
  // applet library, which are not to be exported by the applet module.
  //
  // - API DOCUMENTATION:
  // All properties that conform the applet API should be well-typed and documented.
  // Customers will be able to inspect from their IDE the declaration file generated when packaging the library.
  // Therefore it is important to type all the API elements and to add a description of their purpose.
  // Documentation should be in the form of TS-Docs. e.g. /** This is an API-element description */
}
