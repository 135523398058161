import {AnimationEvent} from '@angular/animations';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {Platform} from '@angular/cdk/platform';
import {CdkPortalOutlet, TemplatePortal} from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import {take} from 'rxjs/operators';

import {
  AriaLivePoliteness,
  BSZ_NOTIFICATION_OVERLAY_DEFAULT_OPTIONS,
  BszNotificationOverlayConfig,
  BszNotificationOverlayHorizontalPosition,
  BszNotificationOverlayVerticalPosition,
} from './bsz-notification-overlay.definitions';
import {BszNotificationOverlayAnimations} from './bsz-notification-overlay-animation';

@Directive()
export abstract class AbstractNotificationComponent {
  @Output() afterDismissed = new EventEmitter<unknown>();

  @Output() afterOpened = new EventEmitter();

  abstract dismiss(): void;
}

@Component({
  selector: 'bsz-notification-overlay',
  templateUrl: './bsz-notification-overlay.html',
  styleUrls: ['bsz-notification-overlay.scss'],
  animations: [BszNotificationOverlayAnimations.notificationOverlayTransition],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[style.z-index]': '_zIndex',
    '[style.order]': '_flexOrder',
  },
})
export class BszNotificationOverlay implements OnDestroy {
  @Input() set fixed(isFixed: boolean) {
    this._fixed = isFixed;
    this._flexOrder = isFixed ? 0 : -1;
  }

  get fixed(): boolean {
    return this._fixed;
  }

  private duration: number | undefined;
  private timeoutId: number;
  private activationTime: number;
  private active = false;
  private readonly pauseOnHover: undefined | boolean;
  private readonly announceDelay: number = 150;
  private announceTimeoutId: number;

  type: string;

  /** @private */
  _isOpen = false;

  /** @private */
  _isDismissed = false;

  /** @private */
  _templatePortal: TemplatePortal;

  /** @private */
  _live: AriaLivePoliteness; //  aria-live value for the live region

  private readonly announcementMessage: string | undefined;

  // Role of the live region. This is only for Firefox as there is a known issue where Firefox +
  // JAWS does not read out aria-live message.
  /** @private */
  _role?: 'status' | 'alert';

  /** @private */
  _horizontalPosition: BszNotificationOverlayHorizontalPosition | undefined;

  /** @private */
  _verticalPosition: BszNotificationOverlayVerticalPosition | undefined;

  /** @private */
  _zIndex: number | 'auto' = 'auto';

  /** @private */
  _fixed = false;

  /** @private */
  _flexOrder = 0;

  /** The portal outlet inside this container into which the content will be loaded. */
  @ViewChild(CdkPortalOutlet, {static: true}) _portalOutlet: CdkPortalOutlet;

  /**
   * Gets an observable that is notified when the notification-overlay is finished closing.
   */
  @Output() afterDismissed = new EventEmitter<unknown>();

  @Output() afterOpened = new EventEmitter();

  constructor(
    @Inject(BSZ_NOTIFICATION_OVERLAY_DEFAULT_OPTIONS)
    private readonly notificationOverlayConfig: BszNotificationOverlayConfig,
    private readonly cd: ChangeDetectorRef,
    private readonly elementRef: ElementRef,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly liveAnnouncer: LiveAnnouncer,
    private readonly ngZone: NgZone,
    private readonly platform: Platform
  ) {
    const politeness = notificationOverlayConfig.politeness ? notificationOverlayConfig.politeness : 'polite';
    this.setAriaLiveRegion(politeness);

    this.announcementMessage = notificationOverlayConfig.announcementMessage;
    this.duration = notificationOverlayConfig.duration;
    this.type = notificationOverlayConfig.type;
    this._horizontalPosition = notificationOverlayConfig.horizontalPosition;
    this._verticalPosition = notificationOverlayConfig.verticalPosition;
    this.pauseOnHover = notificationOverlayConfig.pauseOnHover;
  }

  ngOnDestroy() {
    clearTimeout(this.timeoutId);
  }

  /**
   * Dismisses the notification-overlay
   */
  dismiss() {
    this._isOpen = false;
    this._isDismissed = true;
    this.cd.markForCheck();
  }

  /** @private */
  _setTemplatePortal(templatePortalContent: TemplateRef<unknown>, context?: unknown) {
    if (this._isDismissed) {
      return;
    }
    this._portalOutlet.attach(new TemplatePortal(templatePortalContent, this.viewContainerRef, {$implicit: context}));
  }

  activate() {
    if (this._isDismissed) {
      return;
    }
    this._isOpen = true;
    this.active = true;
    this.cd.markForCheck();
    // with this we make sure the subscription to _afterOpened is already done before emitting it
    this.ngZone.onStable.pipe(take(1)).subscribe(() => {
      this.afterOpened.emit();
    });

    this.setTimer();
  }

  /**
   * Pauses the timer so it won't be dismissed automatically
   */
  pause() {
    if (this.duration) {
      clearTimeout(this.timeoutId);
      // updates the duration to the remaining time
      this.duration -= Date.now() - this.activationTime;
    }
  }

  /**
   * Resumes timer to be automatically dismissed
   */
  resume() {
    this.setTimer();
  }

  private setTimer() {
    if (!this.duration) {
      return;
    }
    this.activationTime = Date.now();
    this.timeoutId = window.setTimeout(() => {
      this.dismiss();
    }, this.duration);
  }

  getConfiguration() {
    return this.notificationOverlayConfig;
  }

  /** @private */
  _onTransitionStart(event: AnimationEvent) {
    if (!this.active) {
      return;
    }
    if (event.toState.startsWith('open-')) {
      this.screenReaderAnnounce();
    }
  }

  /** @private */
  _onTransitionEnd(event: AnimationEvent) {
    if (!this.active || event.toState !== 'dismissed') {
      return;
    }
    this.elementRef.nativeElement.parentElement?.removeChild(this.elementRef.nativeElement);
    this.afterDismissed.emit();
    clearTimeout(this.announceTimeoutId);

    this.ngOnDestroy();
  }

  /** @private */
  _getHeightInPixels(): string {
    const height = this.elementRef.nativeElement?.getBoundingClientRect().height ?? 0;

    // the negative value is used to have the reference that the animation will use. By this way, the "dismissed" state
    // in the transition, being that value, makes the component occupy the position of the previous component in the stack

    return `-${height}px`;
  }

  /** @private */
  _getState(): string {
    return this._isOpen ? `open-${this._verticalPosition}` : 'dismissed';
  }

  private setAriaLiveRegion(politeness: AriaLivePoliteness) {
    // Use aria-live rather than a live role like 'alert' or 'status'
    // because NVDA and JAWS have show inconsistent behavior with live roles.
    if (politeness === 'assertive' && !this.announcementMessage) {
      this._live = 'assertive';
    } else if (politeness === 'off') {
      this._live = 'off';
    } else {
      this._live = 'polite';
    }

    // Only set role for Firefox. Set role based on aria-live because setting role="alert" implies
    // aria-live="assertive" which may cause issues if aria-live is set to "polite" above.
    if (this.platform.FIREFOX) {
      if (this._live === 'polite') {
        this._role = 'status';
      }
      if (this._live === 'assertive') {
        this._role = 'alert';
      }
    }
  }

  /**
   * It takes the content and moves it to the container that has the aria-live attribute. This is the native way
   * to make screen readers announce (read) the content. When something changes inside a live region, it is read
   * based in the attributes that it has (role and politeness from aria-live). The only requirement is that the
   * aria live region needs to be already present in the accessibility tree, so for that reason the template has
   * it, and then it moves the content inside.
   */
  private screenReaderAnnounce() {
    if (this.announceTimeoutId) {
      return;
    }

    this.ngZone.runOutsideAngular(() => {
      this.announceTimeoutId = window.setTimeout(() => {
        const nativeElement = this.elementRef.nativeElement;

        const tempElement = nativeElement.querySelector('[aria-hidden]');
        tempElement.removeAttribute('aria-hidden');

        const liveElement = nativeElement.querySelector('[aria-live]');
        liveElement.appendChild(tempElement);

        // finally, after the live region is read, if there is announcementMessage, it is
        // also announced with the same politeness value
        if (this.announcementMessage) {
          this.liveAnnouncer.announce(this.announcementMessage, this._live);
        }
      }, this.announceDelay);
    });
  }
}
