export {WebAppCommonBusinessObjectNamingModule} from './business-object-naming.module';

export * from './models/business-object-naming.definitions';

export {
  provideBusinessPartnerNameFormatter,
  defaultBusinessPartnerNameFormatter,
  BUSINESS_PARTNER_NAME_FORMATTER,
} from './formatters/business-partner-name.formatter';
export {provideCurrencyNameFormatter, defaultCurrencyNameFormatter} from './formatters/currency-name.formatter';
export {
  providePaymentMoneyAccountNameFormatter,
  defaultPaymentMoneyAccountNameFormatter,
  PAYMENT_MONEY_ACCOUNT_NAME_FORMATTER,
} from './formatters/payment-money-account-name.formatter';

export {BszCurrencyNamePipe} from './pipes/currency-name.pipe';
export {BusinessObjectNamePipe} from './pipes/business-object-name.pipe';
