import {PortalPage} from '../navigation/classes/portal-page';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslationKeyWithParams} from '../navigation/translation-key-with-params';
import {TranslationKey} from '@basuiz/web-app-applet-api';

export class UserSettingsPage extends PortalPage {
  get parentPortalPage(): PortalPage | null {
    return null;
  }

  get breadcrumbText(): TranslationKey | TranslationKeyWithParams {
    return asTranslationKey('web-app-portal.pages.user-settings.breadcrumb');
  }

  get breadcrumbTestId(): string {
    return 'web-app-portal.pages.user-settings.breadcrumb';
  }

  get titleText(): TranslationKey {
    return asTranslationKey('web-app-portal.pages.user-settings.window-title');
  }

  static readonly routerConfigPath = 'user-settings';
  get route(): Array<string> {
    return [`/${UserSettingsPage.routerConfigPath}`];
  }
}
