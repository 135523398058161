import {
  AuthDialogCollectiveRepresentation,
  AuthDialogRepresentation,
  AuthDialogSingleRepresentation,
  AuthDialogVerificationRequest,
  CrontoOperation,
  FuturaeOperation,
  MatrixOperation,
  MtanOperation,
  PollingOperation,
  PushOperation,
  TokenOperation,
} from '../models/auth-dialog.definitions';
import {AfpAuthOperation} from '../models/auth.definitions';

export function isAuthDialogSingleRepresentation(
  representation: AuthDialogRepresentation
): representation is AuthDialogSingleRepresentation {
  return Array.isArray((representation as AuthDialogSingleRepresentation)?.details);
}

export function isAuthDialogCollectiveRepresentation(
  representation: AuthDialogRepresentation
): representation is AuthDialogCollectiveRepresentation {
  return Array.isArray((representation as AuthDialogCollectiveRepresentation)?.summary);
}

export function isCrontoRequest(
  verificationRequest: AuthDialogVerificationRequest
): verificationRequest is AuthDialogVerificationRequest<CrontoOperation> {
  const CRONTO_OPERATION: Record<CrontoOperation, true> = {
    CRONTO: true,
    CRONTO_ONLINE: true,
    CRONTO_PUSH: true,
  };

  return verificationRequest.operation in CRONTO_OPERATION;
}

export function isFuturaeRequest(
  verificationRequest: AuthDialogVerificationRequest
): verificationRequest is AuthDialogVerificationRequest<FuturaeOperation> {
  const FUTURAE_OPERATION: Record<FuturaeOperation, true> = {
    FUTURAE: true,
    FUTURAE_PUSH: true,
  };

  return verificationRequest.operation in FUTURAE_OPERATION;
}

export function isMatrixRequest(
  verificationRequest: AuthDialogVerificationRequest
): verificationRequest is AuthDialogVerificationRequest<MatrixOperation> {
  const MATRIX_OPERATION: Record<MatrixOperation, true> = {
    MATRIX: true,
  };

  return verificationRequest.operation in MATRIX_OPERATION;
}

export function isMtanRequest(
  verificationRequest: AuthDialogVerificationRequest
): verificationRequest is AuthDialogVerificationRequest<MtanOperation> {
  const MTAN_OPERATION: Record<MtanOperation, true> = {
    MTAN: true,
  };

  return verificationRequest.operation in MTAN_OPERATION;
}

export function isTokenRequest(
  verificationRequest: AuthDialogVerificationRequest
): verificationRequest is AuthDialogVerificationRequest<TokenOperation> {
  const TOKEN_OPERATION: Record<TokenOperation, true> = {
    TOKEN: true,
  };

  return verificationRequest.operation in TOKEN_OPERATION;
}

export function isPushRequest(
  verificationRequest: AuthDialogVerificationRequest
): verificationRequest is AuthDialogVerificationRequest<PushOperation> {
  const PUSH_OPERATION: Record<PushOperation, true> = {
    PUSH: true,
  };

  return verificationRequest.operation in PUSH_OPERATION;
}

export function isPollingOperation(operation: AfpAuthOperation): operation is PollingOperation {
  const POLLING_OPERATION: Record<PollingOperation, true> = {
    CRONTO_ONLINE: true,
    CRONTO_PUSH: true,
    FUTURAE_PUSH: true,
    PUSH: true,
  };

  return operation in POLLING_OPERATION;
}
