import {NavigationIntent} from '../../navigation/classes/navigation-intent';

export type EntryManifestIntentPayload = EntryManifestFromPartialIntentPayload | {};

export interface EntryManifestFromPartialIntentPayload {
  /**
   * The manifest ID to use to start the entry process
   */
  manifestId: number;
}

export class EntryManifestIntent extends NavigationIntent {
  constructor(public payload: EntryManifestIntentPayload = {}) {
    super();
  }
}

export function isEntryManifestFromPartialIntentPayload(
  payload: EntryManifestIntentPayload
): payload is EntryManifestFromPartialIntentPayload {
  return payload && 'manifestId' in payload;
}
