import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TestingCoreModule} from '../testing-core/index';
import {MatPaginatorModule} from '@angular/material/paginator';
import {BszPaginatorComponent} from './bsz-paginator.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, MatPaginatorModule, TranslateModule, TestingCoreModule],
  exports: [BszPaginatorComponent],
  declarations: [BszPaginatorComponent],
})
export class BszPaginatorModule {}
