import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AfpRestOperators, AfpRestResponse, isDefined, isNotNullOrUndefined} from '@basuiz/web-app-common';
import {map, shareReplay} from 'rxjs/operators';
import {ClientAdvisorDetails} from '../model/client-advisor-details.definition';

@Injectable({
  providedIn: 'root',
})
export class UserProfileClientAdvisorService {
  private readonly URL = '/com.basuiz.afs.rest.services/rest/clientAdvisor/advisorDetails';

  private readonly maybeClientAdvisor$: Observable<ClientAdvisorDetails | undefined> = this.http
    .get<AfpRestResponse<ClientAdvisorDetails | null>>(this.URL)
    .pipe(
      AfpRestOperators.extractPayload(),
      map((clientAdvisorDetailsList) =>
        isNotNullOrUndefined(clientAdvisorDetailsList) && Object.keys(clientAdvisorDetailsList).length > 0
          ? clientAdvisorDetailsList
          : undefined
      ),
      shareReplay(1)
    );

  constructor(private readonly http: HttpClient) {}

  hasClientAdvisor$(): Observable<boolean> {
    return this.maybeClientAdvisor$.pipe(map((maybeClientAdvisor) => isDefined(maybeClientAdvisor)));
  }

  clientAdvisorDetails$(): Observable<ClientAdvisorDetails | undefined> {
    return this.maybeClientAdvisor$;
  }
}
