import {Injectable} from '@angular/core';
import {assertNever} from '../../utils/assert-never';

import {DEFAULT_LOCALE, FILE_EXTENSION} from '../const/index';
import {
  AboveTableContentContainer,
  AdditionalPageHeaderData,
  ExportToFileConfig,
  ExportToFileFromClientDataPayload,
  ExportToFileFromServerDataConfig,
  ExportToFileFromServerDataPayload,
  ExportToFilePayload,
  isExportToFileFromClientDataConfig,
  isExportToFileFromServerDataConfig,
  LocaleConfiguration,
} from '../definitions/index';
import {FileType, LocaleId, PageOrientation} from '../enums/index';

@Injectable({
  providedIn: 'root',
})
export class ExportToFileConfigService {
  private generateTimeStamp(): string {
    return new Date().toISOString().replace(/[-:T]/g, '').split('.')[0];
  }

  private generateFileName(config: ExportToFileConfig): string {
    let fileName = config.fileName + '.' + FILE_EXTENSION[config.fileType];
    if (config.prefixFileNameWithTimestamp) {
      fileName = this.generateTimeStamp() + '_' + fileName;
    }
    return fileName;
  }

  private generateHeaderData(config: ExportToFileConfig): AdditionalPageHeaderData {
    return config.additionalPageHeaderData || {};
  }

  private generateLocaleConfiguration(): LocaleConfiguration {
    return {
      fallbackLocale: LocaleId.EN_US, // TODO: use proper locale from centralized place
      locales: [DEFAULT_LOCALE], // TODO: is this supposed to be like this?
    };
  }

  private generatePageDefinition(config: ExportToFileConfig): PageOrientation {
    return config.pageOrientation || PageOrientation.LANDSCAPE;
  }

  private generateExportTopSection(config: ExportToFileFromServerDataConfig): AboveTableContentContainer {
    return config.aboveTableContent || {styleClass: '', rows: []};
  }

  /**
   * Backend is sensitive about extra keys and we need add them only for PDF.
   */
  public createExportToFilePayload(config: ExportToFileConfig): ExportToFilePayload {
    const isPdf = config.fileType === FileType.PDF;

    let payload = {} as ExportToFilePayload;
    payload.outputFilename = this.generateFileName(config);
    payload.localeConfiguration = this.generateLocaleConfiguration();

    if (isPdf) {
      payload.headerData = this.generateHeaderData(config);
      payload.pageDefinition = this.generatePageDefinition(config);
    }

    if (isExportToFileFromClientDataConfig(config)) {
      payload = payload as ExportToFileFromClientDataPayload;
      payload.exportData = config.content;
      return payload;
    } else if (isExportToFileFromServerDataConfig(config)) {
      payload = payload as ExportToFileFromServerDataPayload;
      payload.cells = config.tableColumnDefinitions;
      if (isPdf) {
        payload.exportTopSection = this.generateExportTopSection(config);
        payload.tableStyleClass = config.tableStyleClass;
      }
      return payload;
    } else {
      assertNever(config);
    }
  }
}
