<h1 mat-dialog-title class="bsz-title-6">{{ 'web-app-portal.unsaved-data-guard.dialog.title' | translate }}</h1>
<mat-dialog-content class="bsz-body-1 bsz-margin-b4">{{
  'web-app-portal.unsaved-data-guard.dialog.content' | translate
}}</mat-dialog-content>
<mat-dialog-actions class="bsz-dialog-actions" align="end">
  <button mat-button color="accent" (click)="closeDialog()">
    {{ 'web-app-portal.unsaved-data-guard.dialog.cancel.button' | translate }}
  </button>
  <button mat-flat-button color="primary" (click)="confirmAction()" cdkFocusInitial>
    {{ 'web-app-portal.unsaved-data-guard.dialog.action.button' | translate }}
  </button>
</mat-dialog-actions>
