import {NgModule} from '@angular/core';
import {WebAppAppletDirective} from './web-app-applet.directive';
import {WebAppBasicModule} from '../web-app-basic/web-app-basic.module';
import {WebAppAppletNameComponent} from './web-app-applet-name/web-app-applet-name.component';

@NgModule({
  exports: [WebAppAppletDirective, WebAppBasicModule],
  declarations: [WebAppAppletDirective, WebAppAppletNameComponent],
  imports: [WebAppBasicModule],
})
export class WebAppAppletModule {}
