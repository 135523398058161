import {LiveAnnouncer} from '@angular/cdk/a11y';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AsyncSubject, merge} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import {BszScreenSize, ScreenSize} from '../bsz-screen-size-content-switcher/index';
import {
  activeItemClass,
  BszCarouselActiveData,
  CarouselConfiguration,
  CarouselMode,
  dataIndexAttrName,
  hiddenClass,
} from './bsz-carousel.definitions';
import {BszCarouselFocusControl} from './bsz-carousel-focus-control';
import {BszCarouselInfiniteLoop} from './bsz-carousel-infinite.loop';
import {BszCarouselItem, CarouselItemStatus} from './bsz-carousel-item';

const defaultConfiguration = {
  desktop: 1,
  tablet: 1,
  mobile: 1,
};

@Component({
  selector: 'bsz-carousel',
  styleUrls: ['./bsz-carousel.scss'],
  templateUrl: './bsz-carousel.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BszCarousel implements OnDestroy, AfterContentInit {
  @ContentChildren(BszCarouselItem) items!: QueryList<BszCarouselItem>;

  @ViewChild(BszCarouselFocusControl, {static: true}) focusControl!: BszCarouselFocusControl;

  @Input() configuration: CarouselConfiguration = {};

  @Input('aria-label') ariaLabel: string | null = null;
  @Input('aria-labelledby') ariaLabelledby: string | null = null;
  @Input('aria-describedby') ariaDescribedby: string | null = null;

  @Input()
  set activeIndex(index: string | number | undefined | null) {
    if (!this.isNumberValue(index)) {
      return;
    }
    this._activeIndex = Number(index);
    if (this.carouselContent) {
      this.setActivePage(this._activeIndex);
    }
  }

  get activeIndex(): number {
    return this._activeIndex;
  }

  @Output() pageChange = new EventEmitter<BszCarouselActiveData>();

  @Input()
  set mode(mode: CarouselMode) {
    this.setCarouselMode(mode);
  }

  selectionModeActive = false;
  pageIndexes: number[] = [];
  screenClass = '';
  pagesLength = 0;
  activePageRealIndex = 0;
  activePageVirtualIndex = 0;
  arrowDisabledBySize = false;
  screenSize: ScreenSize = 'desktop';
  swipeLeftLimit = 0;
  infiniteCarousel = false;
  pageIndexLoopAdjustment = 0;
  private itemsPerPage = 1;
  private readonly destroy = new AsyncSubject<void>();
  private carouselContent!: HTMLElement;
  private previousActivePage = 0;
  private _mode: CarouselMode = 'standard';
  private infiniteLoop = new BszCarouselInfiniteLoop();
  private _activeIndex = 0;
  /**
   * Array that stores all the un-listener functions for disposing the handlers that
   * are registered with the Renderer2.listen method
   */
  private unListeners: (() => void)[] = [];
  private swipeActive = false;
  private isAnimationActive = true;

  constructor(
    private _elementRef: ElementRef<HTMLElement>,
    private screenSizeService: BszScreenSize,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private liveAnnouncer: LiveAnnouncer,
    private renderer: Renderer2
  ) {}

  ngAfterContentInit(): void {
    this.carouselContent = this.getCarouselContent();
    this.adjustToScreenSize();
    this.initSubscribers();
    this.initFrameKeyboardEvents();
    this.setInitialActivePage();
  }

  private setInitialActivePage() {
    setTimeout(() => {
      // index that comes from the item with selected attribute
      const selectedIndex = this.items.toArray().findIndex((item: BszCarouselItem) => item.selected);
      this.activePageRealIndex = this.selectionModeActive && selectedIndex > -1 ? selectedIndex : this._activeIndex;
      this.isAnimationActive = false;
      this.setActivePage(this.activePageRealIndex);
      this.isAnimationActive = true;
    });
  }

  ngOnDestroy() {
    this.unListen();
    this.destroy.next();
    this.destroy.complete();
  }

  /**
   * Subscriptions to adapt (if required) items per page, active page, screenClass, ...:
   *      - changes in the screen size
   *      - changes in the items (new items added, removed)
   */
  private initSubscribers(): void {
    this.screenSizeService
      .getScreenSize()
      .pipe(takeUntil(this.destroy))
      .subscribe((size: ScreenSize) => {
        this.adjustToScreenSize(size);
        this.setActivePage(this.activePageRealIndex);
      });

    this.items.changes.pipe(takeUntil(this.destroy)).subscribe((_) => {
      setTimeout(() => {
        this.removeClonedItems();
        this.adjustToScreenSize(this.screenSize);
        this.setActivePage(0);
      });
    });

    merge(...this.items.map((item) => item.selectedChange))
      .pipe(
        takeUntil(this.destroy),
        filter((itemChange) => itemChange.selected)
      )
      .subscribe((itemChange) => this.setActivePage(itemChange.itemIndex));
  }

  /**
   * Adapts everything (items per page, items width, active page, screenClass, ...) to the new screen size
   *
   * @param screen:ScreenSize = 'mobile' | 'desktop' | 'tablet'
   */
  private adjustToScreenSize(screen: ScreenSize = 'desktop') {
    this.screenSize = screen;
    this.screenClass = `bsz-carousel-${screen}`;
    this.arrowDisabledBySize = screen !== 'desktop';
    this.infiniteCarousel = this.selectionModeActive && this.items.length > 2;
    this.adjustItemsPerPage();
    this.cd.detectChanges();
  }

  /**
   * Sets the width of the items so each page shows the correct amount.
   * The 100% of the page is divided between the items that it contains
   */
  private adjustItemsPerPage() {
    this.itemsPerPage = this.getItemsLengthPerPage();
    this.pagesLength = Math.ceil(this.items.length / this.itemsPerPage);
    const itemWidth = 1 / this.itemsPerPage;

    this.items.forEach((item: BszCarouselItem, index: number) => {
      item.setItemWidth(`${itemWidth * 100}%`);
      item.setPageIndex(Math.floor(index / this.itemsPerPage));
    });

    const items = this.getItems();
    items.forEach((item, index) => {
      this.renderer.setAttribute(item, dataIndexAttrName, `${Math.floor(index / this.itemsPerPage)}`);
      const itemComponent = this.items.get(index);
      if (itemComponent) {
        itemComponent.itemIndex = index;
      }
    });

    this.pageIndexes = [...Array(this.pagesLength).keys()];
    this.focusControl.updateTabBrowsingBehavior();

    this.removeClonedItems();
    if (this.infiniteCarousel) {
      this.infiniteLoop.setClonedItems(this.carouselContent);
      this.setActivePage(this.activePageRealIndex);
    }

    // when it is selection mode and has two pages, by design it has different scroll position
    if (this.selectionModeActive && this.pagesLength === 2 && this.screenSize === 'desktop') {
      this.resetScroll();
    }
    this.unListen();
    this.activatePageByClickIfNeeded();
    this.cd.markForCheck();
  }

  private removeClonedItems() {
    this.infiniteLoop.removeClonedItems();
    this.previousActivePage = 0;
    this.activePageRealIndex = 0;
    this.pageIndexLoopAdjustment = 0;
    this.resetScroll();
  }

  /**
   * Gets the number of items per page based on the current screen size
   */
  private getItemsLengthPerPage(): number {
    // Selection mode has always one item per page by design
    if (this.selectionModeActive) {
      return 1;
    }
    return Object.assign({}, defaultConfiguration, this.configuration)[this.screenSize];
  }

  /**
   * Set the page that is visible and active
   *
   * @param pageIndex: number = index of the page to set
   */
  setActivePage(pageIndex: number): void {
    if (!this.items) {
      return;
    }
    // if it is out of range, set it to the closest extreme (first or last item)
    if (!this.infiniteCarousel && (pageIndex < 0 || pageIndex >= this.pagesLength)) {
      pageIndex = !pageIndex ? 0 : this.pagesLength - 1;
    }

    this.activePageRealIndex = pageIndex;
    this.setActivePageVirtualIndex(this.activePageRealIndex);
    this.pageIndexLoopAdjustment = this.pagesLength * Math.floor(this.activePageRealIndex / this.pagesLength);

    this.scrollToActivePage();
    this.updateItemsAttributes(this.activePageRealIndex);
    const hasPageChanged =
      isNaN(this.previousActivePage) || (pageIndex - this.previousActivePage) % this.pagesLength === 0;
    if (!hasPageChanged) {
      this.announceActivePage();
      this.pageChange.emit({index: this.activePageVirtualIndex, itemsData: this.getItemsData()});
    }
    this.previousActivePage = pageIndex;
    this.focusControl.updateFocusHelpersPosition();
    this.cd.detectChanges();
  }

  /**
   * If it is in selection mode on desktop, and there are only two pages/items, by design the carousel shows
   * both pages and there is no sliding to activate them. In that case, the activation can be done clicking the
   * page/item that is not active (since this is not accessible because the items are not focusable, the page
   * navigation is kept and can be used for the same goal)
   */
  private activatePageByClickIfNeeded() {
    if (this.selectionModeActive && this.pagesLength > 1 && this.screenSize !== 'mobile') {
      this.activatePageByItemClick();
    }
  }

  goToPreviousPage(): void {
    const previousPageIndex = this.activePageRealIndex - 1;
    this.setActivePage(previousPageIndex);
  }

  goToNextPage(): void {
    const nextPageIndex = this.activePageRealIndex + 1;
    this.setActivePage(nextPageIndex);
  }

  /**
   * Sets the attribute aria-label for the page buttons (bullets at the bottom of the carousel)
   */
  getButtonPageAriaLabel(buttonIndex: number, isActive: boolean): string {
    const ariaLabel = this.translate.instant('ui-elements.bsz-carousel.accessibility.button-index', {buttonIndex});
    const ariaLabelActivePage = this.translate.instant('ui-elements.bsz-carousel.accessibility.active-page');

    if (isActive) {
      return `${ariaLabel}, ${ariaLabelActivePage}`;
    }

    return ariaLabel;
  }

  /**
   * Moves the whole content of the carousel frame to the position in which the active page is active except
   * when the swipe is not active
   */
  private scrollToActivePage(): void {
    if (this.selectionModeActive && this.pagesLength === 2 && this.screenSize === 'desktop') {
      return;
    }
    const scrolledItems = this.activePageRealIndex - this.previousActivePage;
    if (this.infiniteCarousel && scrolledItems !== 0) {
      this.infiniteLoop.adjustInfiniteCarouselItems(scrolledItems);
    }
    this.scrollToPage(this.activePageRealIndex);
  }

  /**
   * Moves the content of the carousel to the position of the page
   */
  private scrollToPage(pageIndex: number): void {
    this.renderer.setStyle(this.carouselContent, 'transform', `translateX(${-pageIndex * 100}%)`);
  }

  /**
   * Resets the scroll
   */
  private resetScroll(left = 0, translateX = 0): void {
    this.renderer.setStyle(this.carouselContent, 'left', `${left}%`);
    this.renderer.setStyle(this.carouselContent, 'transform', `translateX(${translateX}%)`);
  }

  /**
   * Updates the attributes that the active and non-active items should have (class, aria,...)
   */
  private updateItemsAttributes(activePage: number) {
    const items = this.getItems();
    items.forEach((item) => {
      const dataIndex = Number(item.getAttribute(dataIndexAttrName));
      if (dataIndex !== activePage) {
        this.renderer.setAttribute(item, 'aria-hidden', 'true');
        this.renderer.addClass(item, hiddenClass);
        this.renderer.removeClass(item, activeItemClass);
      } else {
        this.renderer.removeAttribute(item, 'aria-hidden');
        this.renderer.addClass(item, activeItemClass);
        this.renderer.removeClass(item, hiddenClass);
      }
    });
  }

  /**
   * Uses "liveAnnouncer" service to communicate to the accessibility API the change in the active page. By
   * this way, assistive technologies announce the change to the users (for instance, screen readers users)
   */
  private announceActivePage() {
    this.liveAnnouncer.announce(
      this.translate.instant('ui-elements.bsz-carousel.accessibility.aria-live.index-of-page', {
        pageIndex: this.activePageVirtualIndex,
        pagesLength: this.pagesLength,
      })
    );
  }

  /**
   * Callback executed when swipe starts
   */
  onSwipeStart() {
    this.swipeLeftLimit = -this.getPageWidth() * (this.pagesLength - 1);
  }

  /**
   * Callback executed while swiping when selection mode is active. By this way, the items set their
   * attributes as active/inactive and the user experience is improved while swiping (he/she actually
   * can see the item that will be active when releasing). This is not required in the default mode
   * because there are not changes in the styles between active items or not, so there is no need of
   * affecting the performance by executing it.
   */
  onSwipeMove(swipeDistance: number) {
    const virtuallyActivePage = this.getVirtuallyActivePage(swipeDistance);
    // this makes the page/bullet buttons adapt its styles to the page that would be
    // active when the user finishes the swipe movement
    this.setActivePageVirtualIndex(virtuallyActivePage);
    this.swipeActive = true;
    if (!this.selectionModeActive) {
      return;
    }
    this.updateItemsAttributes(virtuallyActivePage);
  }

  /**
   * Callback executed when swipe ends for setting the active page
   */
  onSwipeEnd(swipeDistance: number) {
    this.setActivePage(this.getVirtuallyActivePage(swipeDistance));

    // It needs to wait before. Otherwise, the selection of the page by clicking
    // is also fired, and the page to select could not be the correct one (for instance,
    // when swiping, the highlighted page could be other than the one in which the click
    // happened, so if both events are executed, that one would be the final page and the
    // output would be fired twice
    setTimeout(() => {
      this.swipeActive = false;
    });
  }

  /**
   * Returns whether the carousel controls are required or not, which usually corresponds with situations
   * in which there is no need to change page or swipe is not active or required:
   *      - when there is only one page
   *      - in desktop when mode selection is active and there are two pages
   */
  simplifiedInteraction(): boolean {
    return (
      this.pagesLength === 1 || (this.selectionModeActive && this.pagesLength === 2 && this.screenSize === 'desktop')
    );
  }

  /**
   * Returns what would be the active page based on distance and direction of the displacement of the carousel.
   * There is a threshold that decides if the active page changes
   */
  getVirtuallyActivePage(swipeDistance: number) {
    // minimum distance (as proportion of the width of the page/slide) that is
    // required to fire the output onSwipe
    const swipeThreshold = 0.3;
    const proportionMoved = swipeDistance / this.getPageWidth();
    const integerPart = Math.floor(proportionMoved);
    const decimalPart = proportionMoved - integerPart;

    let pagesToMove = integerPart;
    if (swipeDistance > 0) {
      // if it moves to the right, the threshold is the defined
      pagesToMove += decimalPart >= swipeThreshold ? 1 : 0;
    } else {
      // if it moves to the left, the threshold should be the opposite proportion
      pagesToMove += decimalPart <= 1 - swipeThreshold ? 0 : 1;
    }

    return this.activePageRealIndex - pagesToMove;
  }

  /**
   * The real index of the active page is based on the loops that the user has moved and the items. This means
   * that the index can be any number, positive or negative. The activePageVirtualIndex is the index of the active
   * page in the set of pages. It is the index for the user, and its value goes from 0 to the length of pages minus
   * one. This value helps identifying things like the item data that should be sent in the emit function or to
   * define styles.
   */
  private setActivePageVirtualIndex(realIndex: number) {
    const pagesDifference = realIndex % this.pagesLength;
    // this makes the page/bullet buttons adapt its styles to the page that would be
    // active when the user finishes the swipe movement
    this.activePageVirtualIndex = pagesDifference >= 0 ? Math.abs(pagesDifference) : this.pagesLength + pagesDifference;
  }

  /**
   * Sets the mode in which the carousel should behave. So far 'standard' or 'selection'.
   * Selection mode impacts in other places so it declares the variable selectionModeActive to
   * be used when it is required.
   */
  private setCarouselMode(mode: CarouselMode) {
    this._mode = mode;
    this.selectionModeActive = mode === 'selection';
  }

  /**
   * Returns the attached data to the active (are in the current page) items that have it defined
   */
  private getItemsData(): unknown[] {
    return this.items
      .filter((item) => item.pageIndexAttr === this.activePageVirtualIndex && item.itemData !== null)
      .map((item) => item.itemData);
  }

  /**
   * On click in the content of the carousel (where all the items are in the DOM), if the target is one item that
   * is not active, it sets it as active.
   */
  private activatePageByItemClick() {
    const contentClickUnListener = this.renderer.listen(this.carouselContent, 'click', (event: KeyboardEvent) => {
      if (this.swipeActive) {
        return;
      }
      const target = event.target as HTMLElement;
      const item = target.closest('bsz-carousel-item');
      if (item) {
        this.setActivePage(Number(item.getAttribute(dataIndexAttrName)));
        this.cd.detectChanges();
      }
    });
    this.unListeners.push(contentClickUnListener);
  }

  /**
   * Clear all the listeners added
   */
  private unListen() {
    this.unListeners.forEach((unListener: () => void) => {
      unListener();
    });
  }

  /**
   * Get the width of the page
   */
  private getPageWidth(): number {
    const pageWidth = window.getComputedStyle(this.carouselContent).getPropertyValue('width');

    return parseInt(pageWidth.replace(/(?!-)[^0-9.]/g, ''), 10);
  }

  /**
   * When focusing the main element, left and right arrows move the carousel
   */
  private initFrameKeyboardEvents() {
    const frame = this.getCarousel();
    let keyboardUnListener: () => void;

    const focusListener = this.renderer.listen(frame, 'focus', (event) => {
      keyboardUnListener = this.renderer.listen(frame, 'keydown', (event) => {
        switch (event.key) {
          case 'ArrowRight':
            this.goToNextPage();
            break;
          case 'ArrowLeft':
            this.goToPreviousPage();
            break;
        }
      });
      this.unListeners.push(keyboardUnListener);
    });

    const blurListener = this.renderer.listen(frame, 'blur', (event) => {
      keyboardUnListener();
    });

    this.unListeners.push(focusListener);
    this.unListeners.push(blurListener);
  }

  /**
   * Returns the section element with class bsz-carousel, which is the direct child of the component
   */
  private getCarousel(): HTMLElement {
    return this._elementRef.nativeElement.querySelector('.bsz-carousel') as HTMLElement;
  }

  private getCarouselContent(): HTMLElement {
    return this._elementRef.nativeElement.querySelector('.bsz-carousel-content') as HTMLElement;
  }

  private getItems() {
    return this.carouselContent.querySelectorAll('.bsz-carousel-item');
  }

  protected isNumberValue(value: any): boolean {
    // parseFloat(value) handles most of the cases we're interested in (it treats null, empty string,
    // and other non-number values as NaN, where Number just uses 0) but it considers the string
    // '123hello' to be a valid number. Therefore we also check if Number(value) is NaN.
    return !isNaN(parseFloat(value)) && !isNaN(Number(value));
  }

  /** @private */
  _carouselClass(): string {
    let carouselClass = this.screenClass;
    if (!this.isAnimationActive) {
      carouselClass += ' bsz-carousel-no-animation';
    }
    return carouselClass;
  }
}
