import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {WebAppCustomIcon} from '../custom-icon/index';

@Component({
  selector: 'bsz-central-message',
  templateUrl: './bsz-central-message.component.html',
  styleUrls: ['./bsz-central-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BszCentralMessageComponent {
  @Input() title: string;
  @Input() info: string;
  @Input() additionalInfo?: string;
  @Input() icon?: string;
  @Input() svgIconLabel?: WebAppCustomIcon;
}
