<div class="main-container bsz-display-flex bsz-flex-column" [ngClass]="{'bsz-surface': displayInCard}">
  <div class="top-row">
    <div class="chart-title bsz-display-flex bsz-subtitle-2">
      <div>{{ title }}</div>
      <mat-icon *ngIf="tooltipText" class="bsz-text-primary bsz-margin-l2" [matTooltip]="tooltipText" [inline]="true">
        help_outline
      </mat-icon>
    </div>
    <span class="row-filler"></span>
    <a *ngIf="headerAction" class="bsz-subtitle-2 bsz-text-accent bsz-cursor-pointer" (click)="headerAction.callback()">
      {{ headerAction.label }}
    </a>
  </div>
  <div class="miscellaneous-container">
    <ng-content select="[bszChartMiscellaneous]"></ng-content>
  </div>

  <div class="bsz-display-flex bsz-flex-wrap bsz-align-items-center chart-legend-container">
    <div class="chart-container" #chartContainer>
      <div *ngIf="loading$ | async" class="progress-bar-container">
        <bsz-progress-bar></bsz-progress-bar>
      </div>
    </div>
    <div class="side-legend-container">
      <ng-content select="[bszChartSideLegend]"></ng-content>
    </div>
  </div>

  <ng-content select="[bszChartBottomLegend]"></ng-content>
</div>
