import {Relation} from 'typeorm';
import {IBaseUserOwnedEntity, IUser, IWalletTransaction} from '../..';
import {Country, Currency} from '../../../utils/models';

export enum WalletSource {
  /**
   * @member tokenized has funds where its source comes from an external source (bank transfer, credit card topup, transfer from a 'collection/charges' wallet).
   * With a wallet of this source, a user is ONLY ALLOWED to credit (topups) and is NOT ALLOWED to debit (payouts).
   * Use this wallet source to add funds which MUST NOT be withdrawn or NOT refundable from the user balance, for example 'real-world money' is converted to app-money called 'tokens', and MUST to be used inside the app only, and can't be converted back to cash.
   * Examples: credit card topups to be used as money-tokens in the app.
   * ---
   * Use this a the MAIN / PRINCIPAL / DEFAULT  wallet for the user to spend / buy things within the app.
   */
  tokenized = 'tokenized',

  /**
   * @member available has funds where its source comes typically from collected/captured payments done from a third-party to a user of the app. This means this funds must given to the user as 'real-world' money.
   * With a wallet of this source, a user is ALLOWED to credit (topups) AND debit (payouts, transfers, pay application fees).
   * Use this wallet source to add all the order's payment collected on behalf of a user.
   * ---
   * Use this as the ONLY wallet to do Payouts.
   */
  available = 'available',

  /**
   * @member giftcard has funds where its source comes from an internal app process, and DOES NOT come from 'real-world money', it's ONLY app-tokens similar to a gift-card.
   * With a wallet of this source, a user is NOT ALLOWED to credit (topups) nor is NOT ALLOWED to debit (payouts). ONLY an admin-finance-user is allowed to credit (topups) a wallet of a regular non-admin app user.
   * Use this wallet source to add funds which MUST be used inside the app only, and can't be converted back to cash. This wallet's funds MUST NOT be withdrawn or NOT refundable from the user balance,
   * The funds on this wallet could be deleted by an admin-user or by an automatic app-process under certain conditions, for example a coupon was given to a user to be used only for X days only and after it expires this amount must be deleted from this wallet source.
   * Examples: gift cards, bonus, promotional topup, tokens given to users as compensation due to operational/internal/customerSupport/app faults or errors.
   */
  giftcard = 'giftcard',
}

// Reference inspiration: https://docs.stripe.com/api/balance
export interface IWallet extends IBaseUserOwnedEntity {
  ownerUser?: Relation<IUser>;

  /**
   * When `isActive` is false, it should be EXCLUDED in the app/api
   */
  isActive: boolean;

  balance: number;

  source: WalletSource;

  currency: Currency;

  country: Country;

  /**
   * List of all WalletTransaction
   */
  walletTransactions?: Relation<IWalletTransaction[]>;
}
