import {A11yModule} from '@angular/cdk/a11y';
import {OverlayModule} from '@angular/cdk/overlay';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {BszI18nModule} from '@basuiz/i18n';

import {BszModalBottomSheet} from './bsz-modal-bottom-sheet';
import {BszModalBottomSheetService} from './bsz-modal-bottom-sheet.service';
import {
  BszModalBottomSheetContentDef,
  BszModalBottomSheetFooterDef,
  BszModalBottomSheetTitleDef,
} from './bsz-modal-bottom-sheet-template-directives';

@NgModule({
  imports: [CommonModule, MatButtonModule, OverlayModule, ScrollingModule, A11yModule, BszI18nModule],
  exports: [
    BszModalBottomSheet,
    BszModalBottomSheetTitleDef,
    BszModalBottomSheetContentDef,
    BszModalBottomSheetFooterDef,
  ],
  declarations: [
    BszModalBottomSheet,
    BszModalBottomSheetTitleDef,
    BszModalBottomSheetContentDef,
    BszModalBottomSheetFooterDef,
  ],
  providers: [BszModalBottomSheetService],
})
export class BszModalBottomSheetModule {}
