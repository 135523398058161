<ng-container *ngIf="showAppShell; else contentWithoutAppShell">
  <header class="bsz-portal--app-bar" #headerAppBar [ngClass]="{'bsz-display-none': fullScreen$ | async}">
    <ng-container *ngIf="userJwt$ | async">
      <ng-container *ngComponentOutlet="appBarComponent"></ng-container>
    </ng-container>
  </header>
  <mat-sidenav-container
    [ngClass]="{'side-nav--over-mode': sideNavInOverMode$ | async}"
    [ngStyle]="{'margin-top': headerAppBar.offsetHeight + 'px'}"
    (backdropClick)="toggleSideNav('closed')"
  >
    <mat-sidenav
      autoFocus="false"
      position="start"
      [opened]="(sideNavOpened$ | async) && !!(userJwt$ | async)"
      [mode]="(sideNavMode$ | async) || 'side'"
      fixedInViewport="true"
      [ngClass]="{'bsz-display-none': fullScreen$ | async}"
      [ngStyle]="{
        'width': (sideNavWidth$ | async) || '',
        'margin-top': ((sideNavInOverMode$ | async) ? 0 : headerAppBar.offsetHeight) + 'px'
      }"
      role="navigation"
      disableClose
    >
      <ng-container *ngComponentOutlet="mainNavComponent"></ng-container>
    </mat-sidenav>

    <mat-sidenav-content role="main">
      <div class="bsz-portal--content-boundary">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <footer>
    <div
      #footerPortalOutletContainer
      class="footer-fixed-container bsz-padding-2"
      [ngClass]="{'bsz-display-none': (receiver.isRendering$ | async) === false}"
      [ngStyle]="{left: footerLeftStyle$ | async}"
    >
      <div class="mdc-layout-grid__inner footer-page-alignment" [ngStyle]="{'max-width': maxPageWidthInPx}">
        <div class="mdc-layout-grid__cell" [ngClass]="(pageContentCentered$ | async) ? 'narrow-footer' : 'wide-footer'">
          <bsz-app-shell-outlet-receiver #receiver [category]="'formActions'"></bsz-app-shell-outlet-receiver>
        </div>
      </div>
    </div>
    <div class="portal-footer-placeholder" [ngStyle]="{height: footerPortalOutletContainer.offsetHeight + 'px'}"></div>
  </footer>
</ng-container>

<ng-template #contentWithoutAppShell>
  <div class="bsz-portal--content-boundary" role="main">
    <router-outlet></router-outlet>
  </div>
</ng-template>
