<!-- TODO p3 add language-selector -->
<div class="bsz-text-center bsz-margin-t12">
  <img class="bsz-padding-b6" src="./assets/img/app-logo.png" width="300" />
  <div class="bsz-padding-b10 bsz-title-6">{{
    'web-app-common.auth-jwt.reset-password-validate.title' | translate
  }}</div>

  <div class="bsz-padding-b10 bsz-title-5">
    <ng-container *ngIf="resetPasswordPreValidateLoadingSubjectLoadingSubject$ | async">
      <bsz-progress-bar
        [message]="'web-app-common.auth-jwt.reset-password-validate.loading' | translate"
        [hasBackdrop]="false"
      >
      </bsz-progress-bar>
    </ng-container>
    <ng-container *ngIf="(resetPasswordPreValidateLoadingSubjectLoadingSubject$ | async) === false">
      <ng-container *ngIf="resetPasswordPreValidateStatus === 'error'">
        <div>
          {{ 'web-app-common.auth-jwt.reset-password-validate.unknown-error' | translate }}
        </div>
        <div>
          <button
            class="center-form bsz-width-100pct bsz-margin-t12"
            mat-flat-button
            color="accent"
            (click)="goToResetPasswordRequest.emit()"
          >
            {{ 'web-app-common.auth-jwt.reset-password-validate.go-reset-password-request' | translate }}
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="resetPasswordPreValidateStatus === ApiHttpErrorCodes.resetPasswordValidateTokenExpired">
        <div>
          {{ 'web-app-common.auth-jwt.reset-password-validate.token-expired' | translate }}
        </div>
        <div>
          <button
            class="center-form bsz-width-100pct bsz-margin-t12"
            mat-flat-button
            color="accent"
            (click)="goToResetPasswordRequest.emit()"
          >
            {{ 'web-app-common.auth-jwt.reset-password-validate.go-reset-password-request' | translate }}
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="resetPasswordPreValidateStatus === 'success'">
        <ng-container *ngIf="resetPasswordValidateStatus === 'pristine'">
          <form [formGroup]="resetPasswordValidateForm" autocomplete="off">
            <div class="center-form">
              <mat-form-field class="bsz-width-100pct">
                <mat-label>
                  {{ 'web-app-common.auth-jwt.reset-password-validate.form.email' | translate }}
                </mat-label>
                <input
                  disabled
                  matInput
                  autocomplete="off"
                  type="email"
                  clearInput
                  autocapitalize="off"
                  inputmode="email"
                  [value]="this.queryParamEmail"
                />
              </mat-form-field>

              <mat-form-field class="bsz-width-100pct">
                <mat-label>
                  {{ 'web-app-common.auth-jwt.reset-password-validate.form.new-password' | translate }}
                </mat-label>
                <input
                  matInput
                  autocomplete="off"
                  type="password"
                  [formControl]="getFormControl('newPassword')"
                  (keyup.enter)="doResetPasswordValidate()"
                />
                <mat-error>
                  <bsz-form-field-errors [control]="getFormControl('newPassword')"></bsz-form-field-errors>
                </mat-error>
              </mat-form-field>

              <button
                class="bsz-width-100pct"
                mat-flat-button
                color="accent"
                [disabled]="resetPasswordValidateLoadingSubjectLoadingSubject$ | async"
                (click)="doResetPasswordValidate()"
              >
                <div class="bsz-display-flex bsz-justify-center">
                  <span>
                    {{ 'web-app-common.auth-jwt.reset-password-validate.form.button.reset-password' | translate }}
                  </span>
                  <mat-spinner
                    *ngIf="resetPasswordValidateLoadingSubjectLoadingSubject$ | async"
                    diameter="20"
                    class="bsz-margin-l2 bsz-margin-t2"
                  ></mat-spinner>
                </div>
              </button>
            </div>
          </form>
        </ng-container>
        <ng-container *ngIf="resetPasswordValidateStatus === 'error'">
          <div>
            {{ 'web-app-common.auth-jwt.reset-password-validate.unknown-error' | translate }}
          </div>
          <div>
            <button
              class="center-form bsz-width-100pct bsz-margin-t12"
              mat-flat-button
              color="accent"
              (click)="goToResetPasswordRequest.emit()"
            >
              {{ 'web-app-common.auth-jwt.reset-password-validate.go-reset-password-request' | translate }}
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="resetPasswordValidateStatus === ApiHttpErrorCodes.resetPasswordValidateTokenExpired">
          <div>
            {{ 'web-app-common.auth-jwt.reset-password-validate.token-expired' | translate }}
          </div>
          <div>
            <button
              class="center-form bsz-width-100pct bsz-margin-t12"
              mat-flat-button
              color="accent"
              (click)="goToResetPasswordRequest.emit()"
            >
              {{ 'web-app-common.auth-jwt.reset-password-validate.go-reset-password-request' | translate }}
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="resetPasswordValidateStatus === 'success'">
          <div>
            {{ 'web-app-common.auth-jwt.reset-password-validate.form.response.success' | translate }}
          </div>
          <div>
            <button
              class="center-form bsz-width-100pct bsz-margin-t12"
              mat-flat-button
              color="accent"
              (click)="goToSignin.emit()"
            >
              {{ 'web-app-common.auth-jwt.reset-password-validate.sign-in' | translate }}
            </button>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <div class="bsz-display-flex bsz-flex-column bsz-margin-t12">
    <a
      bszAccessibleLink
      *ngIf="resetPasswordValidateStatus !== 'success'"
      class="bsz-text-accent bsz-cursor-pointer bsz-text-bold"
      (click)="goToSignin.emit()"
    >
      {{ 'web-app-common.auth-jwt.reset-password-validate.sign-in' | translate }}
    </a>

    <div *ngIf="showHelp" class="center-form bsz-margin-t12 mat-elevation-z4 help-button">
      <button mat-button (click)="openHelp()">
        <img src="./assets/img/customer-service/whatsapp-logo.png" alt="help" />
        <span class="bsz-padding-r3 bsz-text-bold bsz-subtitle-2">
          {{ 'web-app-common.auth-jwt.reset-password-validate.help' | translate }}
        </span>
      </button>
    </div>
  </div>
</div>
