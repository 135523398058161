import {demoUser1, demoUser2} from '../../../core/data-access/mock-data/demo-public-users.mock';
import {shipmentIdLabelToken, shipmentObjectCreatedLabelToken} from '../../../shipment/data-access/shipment.model';
import {
  IServientregaLabelGenerate,
  IServientregaLabelPdf,
  IServientregaLabelRegistered,
  IServientregaLabelRequest,
  IServientregaLabelResponse,
  ServientregaLabelPdfResponseMessage,
  ServientregaLabelRequestProducto,
  ServientregaLabelResponseResponseMessage,
} from './servientrega-label-generate.model';
import {SERVIENTREGA_PDF_LABEL_GENERATED_PDF_BASE64_MOCK} from './servientrega-pdf-label-generated.mock';

export const SERVIENTREGA_LABEL_REQUEST_MOCK: IServientregaLabelRequest = {
  id_tipo_logistica: 1, // TODO p2 check value. Servientrega shipping type.
  detalle_envio_1: process.env['APP_NAME'] ?? '',
  detalle_envio_2: `${shipmentIdLabelToken}: 1234567890`,
  detalle_envio_3: `${shipmentObjectCreatedLabelToken}: 2023-01-31 23:59`,
  // ------------------------------------
  // city recipient and sender
  id_ciudad_origen: '2',
  id_ciudad_destino: '1',
  // ------------------------------------
  // ------------------------------------
  // recipient address - start
  id_destinatario_ne_cl: '001_destinatario',
  razon_social_desti_ne: 'Acme Destinatario Inc.',
  nombre_destinatario_ne: demoUser2.firstname,
  apellido_destinatar_ne: demoUser2.lastname,
  direccion1_destinat_ne: 'Av. Siempre Viva 456',
  sector_destinat_ne: '',
  telefono1_destinat_ne: '5551234567890',
  telefono2_destinat_ne: '',
  codigo_postal_dest_ne: '',
  // recipient address - end
  // ------------------------------------
  // ------------------------------------
  // sender address - start
  id_remitente_cl: '001_remitente',
  razon_social_remite: 'Acme Remitente Inc.',
  nombre_remitente: demoUser1.firstname,
  apellido_remite: demoUser1.lastname,
  direccion1_remite: 'Av. Siempre Viva 123',
  sector_remite: '',
  telefono1_remite: '5550987654321',
  telefono2_remite: '',
  codigo_postal_remi: '',
  // sender address - end
  // ------------------------------------
  id_producto: ServientregaLabelRequestProducto['MERCANCIA PREMIER'],
  contenido: 'LAPTOP',
  numero_piezas: 1,
  valor_mercancia: 10,
  valor_asegurado: 9,
  largo: 30,
  ancho: 30,
  alto: 30,
  peso_fisico: 3,
  login_creacion: 'EC_SERVIENTREGA_API_BASE_CONTRACT_ID',
  password: 'EC_SERVIENTREGA_API_BASE_CONTRACT_PASSWORD',
};

export const SERVIENTREGA_LABEL_RESPONSE_MOCK: IServientregaLabelResponse = {
  id: 1234567890,
  msj: ServientregaLabelResponseResponseMessage.labelGeneratedCorrectly,
};

export const SERVIENTREGA_LABEL_REGISTERED_MOCK: IServientregaLabelRegistered = {
  numero_guia: 1234567890,
  tipo_logistica: 'ENVIO NACIONAL',
  tipo_guia: 'NACIONAL',
  cliente: process.env['APP_NAME'] ?? '',
  sucursal: 'SUCURSAL_MATRIZ',
  dependencia: 'DEPENDENCIA',
  detalle1: SERVIENTREGA_LABEL_REQUEST_MOCK.detalle_envio_1,
  detalle2: SERVIENTREGA_LABEL_REQUEST_MOCK.detalle_envio_2,
  detalle3: SERVIENTREGA_LABEL_REQUEST_MOCK.detalle_envio_3,
  destino: 'GUAYAQUIL',
  origen: 'QUITO',
  remitente: `${SERVIENTREGA_LABEL_REQUEST_MOCK.nombre_remitente} ${SERVIENTREGA_LABEL_REQUEST_MOCK.apellido_remite}`,
  razon_social_remitente: SERVIENTREGA_LABEL_REQUEST_MOCK.razon_social_remite,
  direccion_remitente: SERVIENTREGA_LABEL_REQUEST_MOCK.direccion1_remite,
  sector_remitente: SERVIENTREGA_LABEL_REQUEST_MOCK.sector_remite,
  telefono1_remitente: SERVIENTREGA_LABEL_REQUEST_MOCK.telefono1_remite,
  telefono2_remitente: SERVIENTREGA_LABEL_REQUEST_MOCK.telefono2_remite,
  destinatario: `${SERVIENTREGA_LABEL_REQUEST_MOCK.nombre_destinatario_ne} ${SERVIENTREGA_LABEL_REQUEST_MOCK.apellido_destinatar_ne}`,
  razon_social_destinatario: SERVIENTREGA_LABEL_REQUEST_MOCK.razon_social_desti_ne,
  direccion_destinatario: SERVIENTREGA_LABEL_REQUEST_MOCK.direccion1_destinat_ne,
  sector_destinatario: SERVIENTREGA_LABEL_REQUEST_MOCK.sector_destinat_ne,
  producto: 'MERCANCIA PREMIER', // should be a key of ServientregaLabelRequestProducto,
  contenido: SERVIENTREGA_LABEL_REQUEST_MOCK.contenido,
  cantidad: SERVIENTREGA_LABEL_REQUEST_MOCK.numero_piezas,
  valor_mercancia: SERVIENTREGA_LABEL_REQUEST_MOCK.valor_mercancia,
  valor_asegurado: SERVIENTREGA_LABEL_REQUEST_MOCK.valor_asegurado,
  largo: SERVIENTREGA_LABEL_REQUEST_MOCK.largo,
  ancho: SERVIENTREGA_LABEL_REQUEST_MOCK.ancho,
  alto: SERVIENTREGA_LABEL_REQUEST_MOCK.alto,
  peso_volumetrico: 4.5,
  peso_fisico: SERVIENTREGA_LABEL_REQUEST_MOCK.peso_fisico,
  fecha: '2023-01-01T23:59:59.000',
  estado: 'INGRESADA',
  registrado_por: 'APP_ADMIN',
};

export const SERVIENTREGA_LABEL_PDF_MOCK: IServientregaLabelPdf = {
  guia: 1,
  mensaje: ServientregaLabelPdfResponseMessage.pdfGeneratedCorrectly,
  archivoEncriptado: SERVIENTREGA_PDF_LABEL_GENERATED_PDF_BASE64_MOCK,
};

export const SERVIENTREGA_LABEL_GENERATE_MOCK: IServientregaLabelGenerate = {
  objectOwnerId: 1,
  request: SERVIENTREGA_LABEL_REQUEST_MOCK,
  response: SERVIENTREGA_LABEL_RESPONSE_MOCK,
  registered: SERVIENTREGA_LABEL_REGISTERED_MOCK,
  pdf: SERVIENTREGA_LABEL_PDF_MOCK,
};
