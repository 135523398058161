import {getNowDateTimeInUTC, textSeparator} from '../../utils/common';
import {FacebookConversionApiEventPrefixId, getFacebookApiEnvironment} from './facebook-api.const';
import {FbApiUserData} from './facebook-api.model';

export function getEventFormData(
  eventName: any,
  eventId: any,
  userData: FbApiUserData,
  customData?: any
): FormData | null {
  if (process.env['FB_CONVERSIONS_API_ENABLED'] !== 'true') {
    return null;
  }

  if (!userData.em) {
    // must contain 'em' email info, otherwise FB api error
    console.error('ERROR: FB Conversion API does not contain email field to identify user');
    return null;
  }

  const dataJson: any = [
    {
      action_source: 'website',
      event_time: getNowDateTimeInUTC().toUnixInteger(),
      event_name: eventName,
      event_id: eventId,
      user_data: userData,
    },
  ];

  if (customData) {
    dataJson[0].custom_data = customData;
  }

  const form = new FormData();
  form.append('data', JSON.stringify(dataJson));
  form.append('access_token', getFacebookApiEnvironment().accessToken);
  if (getFacebookApiEnvironment().conversionsApi.sendTestEvent) {
    form.append('test_event_code', getFacebookApiEnvironment().conversionsApi.testEventCode);
  }

  return form;
}

export function getFormDataAddPaymentInfo(UID: number, userData: any, customData: any): FormData | null {
  const eventId = FacebookConversionApiEventPrefixId.AddPaymentInfo + textSeparator + 'UID' + textSeparator + UID;
  return getEventFormData(FacebookConversionApiEventPrefixId.AddPaymentInfo, eventId, userData, customData);
}

export function getFormDataAddToCart(genericOrderObjectId: number, userData: any, customData: any): FormData | null {
  const eventId =
    FacebookConversionApiEventPrefixId.AddToCart + textSeparator + 'ORID' + textSeparator + genericOrderObjectId;
  return getEventFormData(FacebookConversionApiEventPrefixId.AddToCart, eventId, userData, customData);
}

export function getFormDataCompleteRegistration(UID: number, userData: any, customData: any): FormData | null {
  const eventId = FacebookConversionApiEventPrefixId.CompleteRegistration + textSeparator + 'UID' + textSeparator + UID;
  return getEventFormData(FacebookConversionApiEventPrefixId.CompleteRegistration, eventId, userData, customData);
}

export function getFormDataInitiateCheckout(
  genericOrderObjectId: number,
  userData: any,
  customData: any
): FormData | null {
  const eventId =
    FacebookConversionApiEventPrefixId.InitiateCheckout + textSeparator + 'ORID' + textSeparator + genericOrderObjectId;
  return getEventFormData(FacebookConversionApiEventPrefixId.InitiateCheckout, eventId, userData, customData);
}

export function getFormDataPurchase(genericOrderObjectId: number, userData: any, customData: any): FormData | null {
  const eventId =
    FacebookConversionApiEventPrefixId.Purchase + textSeparator + 'ORID' + textSeparator + genericOrderObjectId;
  return getEventFormData(FacebookConversionApiEventPrefixId.Purchase, eventId, userData, customData);
}

export function getFormDataRate(genericOrderObjectId: number, userData: any, customData: any): FormData | null {
  const eventId =
    FacebookConversionApiEventPrefixId.Rate + textSeparator + 'ORID' + textSeparator + genericOrderObjectId;
  return getEventFormData(FacebookConversionApiEventPrefixId.Rate, eventId, userData, customData);
}

export function getFormDataSpentCredits(genericOrderObjectId: number, userData: any, customData: any): FormData | null {
  const eventId =
    FacebookConversionApiEventPrefixId.SpentCredits + textSeparator + 'ORID' + textSeparator + genericOrderObjectId;
  return getEventFormData(FacebookConversionApiEventPrefixId.SpentCredits, eventId, userData, customData);
}
