import {Pipe, PipeTransform} from '@angular/core';
import {combineLatest, isObservable, Observable, of} from 'rxjs';
import {filter, map, startWith, tap} from 'rxjs/operators';
import {Nullable} from '../utils/nullable.type-util';
import {BszSelectSearchFilterMatSelectDirective} from './bsz-select-search-filter.mat-select.directive';

interface OptionWithFilterValue<T> {
  option: T;
  filterValue: string;
}

@Pipe({
  name: 'bszSelectSearchFilter',
})
export class BszSelectSearchFilterPipe implements PipeTransform {
  constructor(private selectSearchFilterMatSelectDirective: BszSelectSearchFilterMatSelectDirective) {}

  transform<T>(
    optionsOrOptions$: Nullable<ReadonlyArray<T>> | Observable<Nullable<ReadonlyArray<T>>>,
    filterValueProvider?: keyof T | ((value: T) => string),
    sortOptions: 'sortAscending' | 'unsorted' = 'unsorted'
  ): Observable<(OptionWithFilterValue<T> & {searchText: string})[]> {
    const options$: Observable<Nullable<ReadonlyArray<T>>> = isObservable(optionsOrOptions$)
      ? optionsOrOptions$
      : of(optionsOrOptions$);

    const filterValueTransformer: (value: T) => string = this.getFilterValueTransformerFor(filterValueProvider);

    const compareFn = (a: OptionWithFilterValue<T>, b: OptionWithFilterValue<T>) => {
      if (a.filterValue < b.filterValue) return -1;
      if (a.filterValue > b.filterValue) return 1;
      return 0;
    };

    const optionsToFilter$: Observable<OptionWithFilterValue<T>[]> = options$.pipe(
      filter((options): options is T[] => Array.isArray(options)),
      tap((options) => this.selectSearchFilterMatSelectDirective.optionsCount.next(options.length)),
      map((options) => options.map((option) => ({option, filterValue: filterValueTransformer(option)}))),
      map((options) => (sortOptions === 'sortAscending' ? options.sort(compareFn) : options))
    );

    const searchText$: Observable<string> = this.selectSearchFilterMatSelectDirective.searchChange.pipe(
      startWith(''),
      map((searchText: string) => searchText.toLowerCase())
    );

    return combineLatest([optionsToFilter$, searchText$]).pipe(
      map(([optionsToFilter, searchText]) =>
        optionsToFilter
          .filter(({filterValue}) => filterValue.toLowerCase().includes(searchText))
          .map((filteredOption) => ({
            ...filteredOption,
            searchText,
          }))
      )
    );
  }

  protected getFilterValueTransformerFor<T>(
    filterValueProvider: keyof T | ((value: T) => string) | undefined
  ): (value: T) => string {
    switch (typeof filterValueProvider) {
      case 'function':
        return filterValueProvider;
      case 'string':
        return (value: T) => {
          const filteredProperty = value[filterValueProvider];

          if (typeof filteredProperty === 'string') {
            return filteredProperty;
          }

          return String(value);
        };
      default:
        return (value: T) => String(value);
    }
  }
}
