<mat-divider></mat-divider>

<ng-container *ngIf="currentOperation | async as operation">
  <bsz-progress-bar
    *ngIf="inProgress | async"
    [message]="getProgressTranslationKey(operation) | translate"
  ></bsz-progress-bar>

  <ng-container *bszDesktopContent>
    <div class="mdc-layout-grid__inner bsz-padding-t6">
      <ng-container *ngIf="operation === 'FUTURAE_PUSH'">
        <div class="mdc-layout-grid__cell--span-12">
          <ng-container
            [ngTemplateOutlet]="titleAndDescriptionDesktop"
            [ngTemplateOutletContext]="{operation: operation}"
          ></ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="operation === 'FUTURAE'">
        <div class="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet">
          <ng-container
            [ngTemplateOutlet]="titleAndDescriptionDesktop"
            [ngTemplateOutletContext]="{operation: operation}"
          ></ng-container>
          <ng-container [ngTemplateOutlet]="offlineForm"></ng-container>
        </div>
        <div class="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet">
          <ng-container [ngTemplateOutlet]="futuraeQr"></ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="isPolling | async">
        <div class="mdc-layout-grid__cell--span-12">
          <ng-container [ngTemplateOutlet]="pollingProgressBar"></ng-container>
        </div>
      </ng-container>

      <div class="mdc-layout-grid__cell--span-12">
        <ng-container
          [ngTemplateOutlet]="actionButtons"
          [ngTemplateOutletContext]="{operation: operation}"
        ></ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container *bszMobileContent>
    <div class="bsz-padding-t6">
      <ng-container
        [ngTemplateOutlet]="titleAndDescriptionMobile"
        [ngTemplateOutletContext]="{operation: operation}"
      ></ng-container>
      <ng-container *ngIf="operation === 'FUTURAE'">
        <ng-container [ngTemplateOutlet]="futuraeQr"></ng-container>
        <ng-container [ngTemplateOutlet]="offlineForm"></ng-container>
      </ng-container>
      <ng-container *ngIf="isPolling | async">
        <ng-container [ngTemplateOutlet]="pollingProgressBar"></ng-container>
      </ng-container>
      <ng-container
        [ngTemplateOutlet]="actionButtons"
        [ngTemplateOutletContext]="{operation: operation}"
      ></ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #titleAndDescriptionDesktop let-operation="operation">
  <div class="bsz-subtitle-1 bsz-padding-b4">{{ getTitleTranslationKey(operation) | translate }}</div>
  <div class="bsz-body-1 bsz-padding-b4">{{ getDescriptionTranslationKey(operation) | translate }}</div>
</ng-template>

<ng-template #titleAndDescriptionMobile let-operation="operation">
  <div class="bsz-subtitle-2 bsz-padding-b4">{{ getTitleTranslationKey(operation) | translate }}</div>
  <div class="bsz-body-1 bsz-padding-b4">{{ getDescriptionTranslationKey(operation) | translate }}</div>
</ng-template>

<ng-template #offlineForm>
  <form [formGroup]="form" (ngSubmit)="verify()">
    <mat-form-field class="bsz-padding-b2">
      <mat-label>{{
        'web-app-common.auth.verification.futurae-verification.offline.placeholder' | translate
      }}</mat-label>
      <div *ngIf="numericOnlyResponseCode; then numericOnlyInput; else defaultInput"></div>
      <ng-template #defaultInput>
        <input matInput formControlName="verificationToken" autocomplete="off" required #inputField cdkFocusInitial />
      </ng-template>
      <ng-template #numericOnlyInput>
        <input
          matInput
          inputmode="numeric"
          pattern="[0-9]*"
          formControlName="verificationToken"
          autocomplete="off"
          required
          #inputField
          cdkFocusInitial
        />
      </ng-template>
      <mat-error *ngIf="form.invalid">{{ getInputErrorTranslationKey() | translate }}</mat-error>
    </mat-form-field>
  </form>
</ng-template>

<ng-template #futuraeQr>
  <div *ngIf="futuraeQrCode">
    <img
      class="futurae-qr-code"
      alt="{{ 'web-app-common.auth.verification.futurae-verification.qr-code.alternative-text' }}"
      src="data:image/png;base64,{{ futuraeQrCode }}"
    />
  </div>
</ng-template>

<ng-template #pollingProgressBar>
  <div class="bsz-text-center bsz-padding-b6">
    <div class="bsz-body-1">{{
      'web-app-common.auth.verification.futurae-verification.push.polling.progress.message' | translate
    }}</div>
    <mat-progress-bar class="bsz-width-50pct bsz-display-inline-block" mode="indeterminate"></mat-progress-bar>
  </div>
</ng-template>

<ng-template #actionButtons let-operation="operation">
  <div class="bsz-text-right bsz-padding-b4">
    <button mat-button color="accent" type="button" (click)="cancel()">{{
      'web-app-common.auth.verification.futurae-verification.button.cancel' | translate
    }}</button>
    <button
      mat-stroked-button
      color="accent"
      type="button"
      *ngIf="isTryOfflineButtonAvailable(operation)"
      (click)="tryOffline()"
      >{{ 'web-app-common.auth.verification.futurae-verification.push.button.try-offline' | translate }}</button
    >
    <button
      mat-flat-button
      color="accent"
      *ngIf="operation === 'FUTURAE'"
      [disabled]="!form.valid || (inProgress | async)"
      type="submit"
      (click)="verify()"
      >{{ 'web-app-common.auth.verification.futurae-verification.offline.button.verify' | translate }}</button
    >
  </div>
</ng-template>
