import {IUser} from './user.model';

export type UserPublicJwt = Required<
  Pick<IUser, 'objectId' | 'email' | 'firstname' | 'lastname' | 'phone' | 'roles' | 'emailHashed' | 'phoneHashed'>
>;

export type UserJwt = {
  user: UserPublicJwt;
};

export type UserJwtAccessTokenResponse = {
  accessToken: string;
};

export const SwaggerApiTagAuth = 'auth';

/**
 * `apiEndpoint` refers to the API endpoint in the backend
 * `appRoute` refers to the webpage's URL route in the frontend
 */
export const AuthRoutes = {
  signin: {
    apiEndpoint: 'auth/signin',
    appRoute: 'auth/signin',
  },
  signup: {
    apiEndpoint: 'auth/signup',
    appRoute: 'auth/signup',
  },
  emailVerify: {
    apiEndpoint: 'auth/emailVerify',
    appRoute: 'auth/email-verify',
  },
  resetPasswordRequest: {
    apiEndpoint: 'auth/resetPasswordRequest',
    appRoute: 'auth/reset-password',
  },
  resetPasswordPreValidate: {
    apiEndpoint: 'auth/resetPasswordPreValidate',
    appRoute: '', // not needed
  },
  resetPasswordValidate: {
    apiEndpoint: 'auth/resetPasswordValidate',
    appRoute: 'auth/reset-password-confirmation',
  },
};
