import {Directive, Input, NgZone} from '@angular/core';
import {take} from 'rxjs/operators';
import {MatStep, MatStepper} from '@angular/material/stepper';

@Directive({
  selector: 'mat-step[bszDisabled]',
})
export class DisabledDirective {
  @Input()
  set bszDisabled(value: boolean) {
    if (value) {
      this.matStep.completed = true;
      this.matStep.optional = true;
      this.matStep.editable = false;
      this.matStep.hasError = false;

      this.ngZone.onStable.pipe(take(1)).subscribe(() => {
        const index = this.elementRef.steps.toArray().indexOf(this.matStep);
        this.elementRef._stepHeader.toArray()[index]._elementRef.nativeElement.classList.add('bsz-step-disabled');
      });
    }
  }

  constructor(public matStep: MatStep, private elementRef: MatStepper, private ngZone: NgZone) {}
}
