import {Directive, EventEmitter, HostBinding, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[dragAndDrop]',
})
export class DragAndDrop {
  @Output() fileDrop: EventEmitter<FileList> = new EventEmitter();

  @HostBinding('class.bsz-file-upload-fileover') fileOver = false;

  @HostListener('dragover', ['$event']) onDragOver(event: Event) {
    // We don't call preventDefault() here since we want the browser to handle the dragover event,
    // showing the correct mouse pointer indication to the user in case he tries to drop multiple files
    // to a non-multiple file upload (it will also prevent the actual drop event).
    event.stopPropagation();

    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    const fileList = event.dataTransfer?.files;
    if (fileList && fileList.length > 0) {
      this.fileDrop.emit(fileList);
    }
    this.fileOver = false;
  }
}
