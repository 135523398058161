import {CursorValue, Options, Point, PointClickEventObject, TooltipPositionerPointObject} from 'highcharts';
import {merge} from 'lodash';
import {Injectable} from '@angular/core';
import {TranslatedText} from '@basuiz/web-app-applet-api';
import {createChartBaseOptions} from './chart';
import {getColorFromCustomCssColorVariable, getColorFromPalette} from './chart-theme';

const CHART_PADDING = 16;
const PIE_CHART_DEFAULT_OPTIONS: BuildOptions = {
  chartWithNegativeValuesDisplayedAs: 'horizontalBarChart',
  chartPointsDisplayedAs: 'percentage',
  onChartPointClick: (point: Point) => {},
  pieOuterSizePx: 130,
  pieInnerSizePx: 84,
  sortEntities: {direction: 'descending'},
};

@Injectable()
export class PieChartBuilder {
  build(originalPieChartData: PieChartData, partialOptions?: Partial<BuildOptions>): Options {
    const options: BuildOptions = {
      ...PIE_CHART_DEFAULT_OPTIONS,
      ...partialOptions,
    };

    const pieChartData: PieChartData = {
      ...originalPieChartData,
      entities: PieChartBuilder.getSortedNodes(originalPieChartData.entities, options.sortEntities),
    };

    const seriesCursor: CursorValue | undefined = partialOptions?.onChartPointClick ? 'pointer' : undefined;

    const hasNegativeValues = pieChartData.entities.some((s) => s.value < 0);
    if (hasNegativeValues) {
      return merge(createChartBaseOptions(), this.getColumnChartOptions(pieChartData, options, seriesCursor));
    } else {
      return merge(createChartBaseOptions(), this.getPieChartOptions(pieChartData, options, seriesCursor));
    }
  }

  private static getSortedNodes(
    entities: PieChartDataEntity[],
    sortEntities: 'keep-original-order' | {direction: 'descending' | 'ascending'}
  ): PieChartDataEntity[] {
    if (sortEntities === 'keep-original-order') return entities;

    return sortEntities.direction === 'descending'
      ? PieChartBuilder.sortEntitiesByValueDesc(entities)
      : PieChartBuilder.sortEntitiesByValueAsc(entities);
  }

  private getPieChartOptions(series: PieChartData, options: BuildOptions, seriesCursor?: CursorValue): Options {
    const pieChartSize = options.pieOuterSizePx + 2 * CHART_PADDING;
    return {
      chart: {
        height: pieChartSize,
        width: pieChartSize,
      },
      plotOptions: {
        series: {
          cursor: seriesCursor,
          point: {
            events: {
              click: (pointObject: PointClickEventObject) => options.onChartPointClick(pointObject.point),
            },
          },
        },
        pie: {
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      tooltip: {
        shared: true,
        useHTML: true,
        borderWidth: 0,
        borderRadius: 4,
        padding: 0,
        formatter: function () {
          const point = this.point as Point & {entity: PieChartData['entities'][0]};

          return `
            <div class="bsz-surface bsz-padding-2 bsz-text-center mat-elevation-z0">
                <div class="bsz-subtitle-1 bsz-text-bold">${point.entity.tooltipValue}</div>
                <div class="bsz-caption bsz-text-secondary">${point.entity.name}</div>
            </div>
          `;
        },
      },
      series: [
        {
          type: 'pie',
          data: series.entities.map((entity, entityIndex) => ({
            name: entity.name,
            y: entity.value,
            color:
              getColorFromCustomCssColorVariable(entity.customColorCssVariable) ??
              getColorFromPalette(series.palette || 0, entityIndex),
            entity,
          })),
          size: options.pieOuterSizePx,
          innerSize: options.pieInnerSizePx,
        },
      ],
    };
  }

  private getColumnChartOptions(series: PieChartData, options: BuildOptions, seriesCursor?: CursorValue) {
    const values = series.entities.map((entity) => entity.value);
    const min = Math.min(...values);
    const max = Math.max(...values);
    const displayValueOnBarChartPoints = options.chartPointsDisplayedAs === 'value';

    return {
      chart: {
        height: options.pieOuterSizePx + 2 * CHART_PADDING,
        width: options.pieOuterSizePx + 2 * CHART_PADDING,
        marginTop: 30,
        marginBottom: 30,
      },
      plotOptions: {
        column: {
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
        series: {
          cursor: seriesCursor,
          point: {
            events: {
              click: (pointObject: PointClickEventObject) => options.onChartPointClick(pointObject.point),
            },
          },
        },
      },
      tooltip: {
        shared: true,
        useHTML: true,
        borderWidth: 0,
        borderRadius: 4,
        padding: 0,
        formatter: function () {
          if (this.points) {
            const point = this.points[0].point as Point & {entity: PieChartData['entities'][0]};

            return `
            <div class="bsz-surface bsz-padding-2 bsz-text-center mat-elevation-z0">
                <div class="bsz-subtitle-1 bsz-text-bold">
                  ${displayValueOnBarChartPoints ? point.entity.value.toFixed(2) : point.entity.tooltipValue}
                </div>
                <div class="bsz-caption bsz-text-secondary">${point.entity.name}</div>
            </div>
          `;
          }
        },
        positioner: function (width: number, height: number, point: TooltipPositionerPointObject) {
          const pointX = point.plotX - width / 2 + this.chart.plotBox.x;
          const minX = this.chart.plotBox.x;
          const maxX = this.chart.plotBox.x + this.chart.plotBox.width - width;
          const x = Math.max(Math.min(pointX, maxX), minX);
          const y = this.chart.spacingBox.y;
          return {x, y};
        },
      },
      xAxis: {
        visible: false,
        opposite: options.chartWithNegativeValuesDisplayedAs === 'horizontalBarChart',
      },
      yAxis: {
        min,
        max,
      },
      series: [
        {
          type: options.chartWithNegativeValuesDisplayedAs === 'horizontalBarChart' ? 'bar' : 'column',
          data: series.entities.map((entity, entityIndex) => ({
            name: entity.name,
            y: entity.value,
            color:
              getColorFromCustomCssColorVariable(entity?.customColorCssVariable) ??
              getColorFromPalette(series.palette || 0, entityIndex),
            entity,
          })),
        },
      ],
    };
  }

  private static sortEntitiesByValueDesc(entities: PieChartDataEntity[]): PieChartDataEntity[] {
    return [...entities].sort((a, b) => (a.value < b.value ? 1 : -1));
  }

  private static sortEntitiesByValueAsc(entities: PieChartDataEntity[]): PieChartDataEntity[] {
    return [...entities].sort((a, b) => (a.value > b.value ? 1 : -1));
  }
}

export interface PieChartData {
  palette?: number;
  entities: PieChartDataEntity[];
}

export interface PieChartDataEntity {
  name: TranslatedText;
  value: number;
  tooltipValue: TranslatedText;
  customColorCssVariable?: string;
}

export interface BuildOptions {
  chartWithNegativeValuesDisplayedAs: 'verticalBarChart' | 'horizontalBarChart';
  chartPointsDisplayedAs: 'value' | 'percentage';
  pieOuterSizePx: number;
  pieInnerSizePx: number;
  sortEntities: 'keep-original-order' | {direction: 'descending' | 'ascending'};
  onChartPointClick: (point: Point) => void;
}
