<ng-container *cdkPortal>
  <div style="{{ (revealInfoBox$ | async) ? 'border: 2px green solid' : '' }}">
    <p *ngIf="revealInfoBox$ | async" style="{{ (revealInfoBox$ | async) ? 'border-bottom: 1px green solid' : '' }}">
      {{ infoBoxMessage$ | async }}
    </p>
    <ng-content></ng-content>
  </div>
</ng-container>

<div
  *ngIf="(isProjecting$ | async) && (revealInfoBox$ | async)"
  style="{{ (revealInfoBox$ | async) ? 'border: 2px green solid' : '' }}"
>
  <p>Source of content projected through '{{ category }}' app-shell outlet</p>
</div>

<ng-container *ngIf="isNotProjecting$ | async">
  <ng-container *cdkPortalOutlet="cdkPortal"></ng-container>
</ng-container>
