// IMPORTANT: do not modify this file

import {FactoryProvider, inject, InjectionToken} from '@angular/core';
import {BszConfigFactory, BszConfigFactoryWithDeps} from '@basuiz/web-app-applet-api';
import {AlertIndicatorAppletConfig} from './alert-indicator-applet.config.definition';
import {getDefaultConfig} from './alert-indicator-applet.config.default';
import {validateAlertIndicatorAppletConfig} from './alert-indicator-applet.config.validation';

// IMPORTANT: do not modify this file

export const ɵALERT_INDICATOR_BANKLET_CONFIG = new InjectionToken<AlertIndicatorAppletConfig>(
  'bsz.web-app.alert-indicator-applet-config',
  {
    factory: () => inject(ALERT_INDICATOR_BANKLET_CONFIG_DEFAULT),
  }
);

// IMPORTANT: do not modify this file

export const ALERT_INDICATOR_BANKLET_CONFIG_DEFAULT = new InjectionToken<AlertIndicatorAppletConfig>(
  'bsz.web-app.alert-indicator-applet-config.default',
  {
    factory: () => {
      const config = getDefaultConfig();
      const error = validateAlertIndicatorAppletConfig(config);
      if (error) {
        console.error('Default AlertIndicatorAppletConfig validation failed:', error);
      }
      return config;
    },
  }
);

// IMPORTANT: do not modify this file

export function alertIndicatorAppletConfigProvider({
  useFactory,
  deps,
}: BszConfigFactoryWithDeps<AlertIndicatorAppletConfig>): FactoryProvider {
  return {
    provide: ɵALERT_INDICATOR_BANKLET_CONFIG,
    useFactory: factoryWithValidation(useFactory),
    deps: [ALERT_INDICATOR_BANKLET_CONFIG_DEFAULT, ...(deps || [])],
  };
}

// IMPORTANT: do not modify this file

function factoryWithValidation(
  factory: BszConfigFactory<AlertIndicatorAppletConfig>
): BszConfigFactory<AlertIndicatorAppletConfig> {
  return (defaultConfig: AlertIndicatorAppletConfig, ...deps: unknown[]) => {
    const config = factory(defaultConfig, ...deps);
    const error = validateAlertIndicatorAppletConfig(config);
    if (error) {
      console.error('AlertIndicatorAppletConfig validation failed:', error);
    }
    return config;
  };
}

// IMPORTANT: do not modify this file
