import {BszConfigFactoryWithDeps} from '@basuiz/web-app-applet-api';
import {PortalConfig} from '@basuiz/web-app-portal';

export const portalConfigProvider: BszConfigFactoryWithDeps<PortalConfig> = {
  useFactory: (defaultConfig) => ({
    ...defaultConfig,
    bankLogoFile: 'assets/img/app-logo.png',
    sessionManagement: {
      ...defaultConfig.sessionManagement,
      sessionExpiredCheckPingUrl: 'session/ping',
      sessionExpiredCheckIntervalInSeconds: 120,
      logOutResolver: () => {
        window.location.href = '/auth/signin';
      },
      changePasswordResolver: undefined, // TODO p2 remove undefined to show link in menu
    },
    pageLayout: {
      ...defaultConfig.pageLayout,
      breadcrumbs: {
        ...defaultConfig.pageLayout.breadcrumbs,
        tablet: {style: 'history', maxBreadcrumbs: 3},
      },
    },
    portalLayout: {
      ...defaultConfig.portalLayout,
      maxPageWidthInPx: 1428,
      mainNavWidthInPx: 265,
    },
  }),
};
