import {BszCountry, BszUserProfilePostalAddress} from '@basuiz/web-app-applet-api';
import {Observable} from 'rxjs';
import {AsyncValidator, ControlValueAccessor, ValidationErrors} from '@angular/forms';

export type UserProfilePostalAddressFormValue = Pick<
  BszUserProfilePostalAddress,
  'streetName' | 'streetNumber' | 'postalCode' | 'city'
>;

/** Abstract class to extend in components providing a postal address form.
 *  The applet will instantiate dynamically components passed via configuration. The applet will talk to the
 *  dynamically created component through the interface declared in this class.
 *
 *  Custom form components should limit it contents to the fields where the user should enter the new address
 *
 *  Notice that the applet already provides action buttons, a country control and a privacy policy checkbox,
 *  therefore should not be part of custom components extending this class.
 *
 *  This class is based on standard Angular forms API: ControlValueAccessor, AsyncValidator from '@angular/forms'.
 *  The applet interacts with this child components extending this class as if it were a standard FormControl.
 *  This allows you can use Angular forms or any other technique of your choice.
 *
 *  Suggested implementation:
 *  - Define an Angular FormGroup inside the child component
 *  - Add form controls to your form group with the desired validations
 *  - Subscribe to your FormGroup's value status and notify the applet calling the `onChange` and `onTouched` methods
 *
 *    formGroupValueUpdate$ = this.myFormGroup.statusChanges.pipe(
 *     tap((value) => {
 *       this.onChange(value);
 *       this.onTouched();
 *     })
 *   );
 *
 *  - Implement `setDisableState` by updating the state of your FormGroup
 *
 *    setDisabledState(isDisabled: boolean): void {
 *      if (isDisabled) {
 *        this.myFormGroup.disable();
 *      } else {
 *        this.myFormGroup.enable();
 *      }
 *    }
 *
 *  - Implement the `validate` method returning the valid status of the FormGroup
 *
 *    validate(): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
 *      return Promise.resolve(this.myFormGroup.valid ? null : {invalidAddress: true});
 *    }
 *
 *  */
export abstract class UserProfilePostalAddressFormControl implements ControlValueAccessor, AsyncValidator {
  /** Country selected in the applet.
   *  Whilst the country is chosen by the user outside this component, it might be useful information to
   *  adapt the form layout or validators.
   *  The value will be set by the applet when it instantiates this component. */
  country!: BszCountry;

  /** Method to be called by the child class when the value of the form changes
   *  to notify the applet of the new value */
  onChange: (newAddressValue: Partial<UserProfilePostalAddressFormValue>) => void = () => {};

  /** Method to be called by the child class when any control of the form is touched
   * to notify the applet that the user interacted with the form */
  onTouched: () => void = () => {};

  /** Method from ControlValueAccessor. To be called exclusively by the applet. */
  registerOnChange(onChange: (newValue: UserProfilePostalAddressFormValue) => void): void {
    this.onChange = onChange;
  }

  /** Method from ControlValueAccessor. To be called exclusively by the applet. */
  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  /** Method from ControlValueAccessor.
   *  Will be called by the applet as needed.
   *  E.g. disable when the user clicks on submit to block further modifications on the form */
  abstract setDisabledState(isDisabled: boolean): void;

  /** Method from ControlValueAccessor.
   *  Called by the applet to set the initial value that the component should prefill in the form. */
  abstract writeValue(newAddressValue: UserProfilePostalAddressFormValue): void;

  /** Method from AsyncValidator.
   *  Required for the applet to be able to enable / disable the submit button accordingly to the state of the form */
  abstract validate(): Promise<ValidationErrors | null> | Observable<ValidationErrors | null>;
}
