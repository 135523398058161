<mat-card>
  <mat-card-content>
    <h2 class="bsz-title-6">Tracing</h2>
    <h3 class="bsz-body-2">Intent handlers</h3>
    <section>
      <mat-slide-toggle
        [checked]="this.portalDebugService.navigationIntentHandling"
        (change)="setTracingIntentHandling($event.checked)"
      >
      </mat-slide-toggle>
      <p *ngIf="this.portalDebugService.navigationIntentHandling">
        Intent handlers will be displayed in the console.
      </p>
    </section>
    <h3 class="bsz-body-2">Navigation tracker</h3>
    <section>
      <mat-slide-toggle
        [checked]="this.portalDebugService.navigationTracking"
        (change)="this.portalDebugService.navigationTracking = $event.checked"
      >
      </mat-slide-toggle>
      <p *ngIf="this.portalDebugService.navigationTracking"> Navigation events will be displayed in the console. </p>
    </section>
  </mat-card-content>
</mat-card>
