import {ViewWithPayload} from '@basuiz/mobile-bridge';

/**
 * Show a view with the details of the specified payment.
 */
export class PaymentDetailView extends ViewWithPayload<PaymentDetailViewPayload> {
  readonly name = 'payment-detail-view';
}

export interface PaymentDetailViewPayload {
  /**
   * The id of the payment to show.
   */
  paymendId: number;
}
