import {NgModule} from '@angular/core';
import {ContextActionMenuComponent} from './context-action-menu.component';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {TranslateModule} from '@ngx-translate/core';
import {TestingCoreModule} from '../testing-core/index';
import {ContextActionComponent} from './context-action/context-action.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatButtonModule, MatMenuModule, TranslateModule, TestingCoreModule],
  exports: [ContextActionMenuComponent],
  declarations: [ContextActionMenuComponent, ContextActionComponent],
})
export class ContextActionMenuModule {}
