import {Pipe, PipeTransform} from '@angular/core';
import {formatNumber} from '@angular/common';

import {DEFAULT_NUMBER_DIGITS_INFO} from '../../const/digits-info.const';
import {isUndefined, throwInvalidPipeArgument} from '../../helpers/formatting-helpers';
import {BszI18nService} from '@basuiz/i18n';
import {postProcessLocalizedNumericValue} from './post-process-localized-numeric-value';

@Pipe({name: 'bszNumber'})
export class BszNumberPipe implements PipeTransform {
  constructor(private i18nService: BszI18nService) {}

  public transform(value: number | null | undefined, digitsInfo?: string): string {
    // return empty string for nil param
    if (isUndefined(value)) {
      return '';
    }

    // if value is not of an accepted type then fail
    if (typeof value !== 'number') {
      throwInvalidPipeArgument(BszNumberPipe, value);
    }

    // calculate result
    const locale = this.i18nService.localeId;
    const formatted = formatNumber(value, locale, digitsInfo ? digitsInfo : DEFAULT_NUMBER_DIGITS_INFO);
    return postProcessLocalizedNumericValue(value, formatted);
  }
}
