import {animate, state, style, transition, trigger} from '@angular/animations';

const transitionTiming = '300ms cubic-bezier(0.4, 0, 0.2, 1)';

export class BszNotificationOverlayAnimations {
  static readonly notificationOverlayTransition = trigger('notificationOverlayTransition', [
    state('open-top', style({opacity: 1, marginTop: '0'})),
    state('open-bottom', style({opacity: 1, marginBottom: '0'})),
    // to make it disappear we use the opacity, and to help other notification overlays to move smoothly, we make it
    // move to the top the same distance as its height
    state('dismissed', style({opacity: 0, marginTop: '{{height}}'}), {params: {height: 'auto'}}),
    transition('*  => open-top', [style({opacity: 0, marginTop: '{{height}}'}), animate(transitionTiming)]),
    transition('*  => open-bottom', [style({opacity: 0, marginBottom: '{{height}}'}), animate(transitionTiming)]),
    transition('* => dismissed', animate(transitionTiming)),
    transition('* => dismissed', animate(transitionTiming)),
  ]);
}
