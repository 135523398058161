import {Component, ChangeDetectionStrategy, Inject} from '@angular/core';
import {TranslationKey} from '@basuiz/web-app-applet-api';
import {ExternalLinkService} from '@basuiz/web-app-applet-sdk';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {PortalConfig} from '../../../config/portal.config.definition';
import {ɵPORTAL_CONFIG} from '../../../config/portal.config.provider';

export interface RegulatoryLink {
  label: TranslationKey;
  url: string;
}

@Component({
  selector: 'bsz-regulatory-links',
  templateUrl: './regulatory-links.component.html',
  styleUrls: ['./regulatory-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegulatoryLinksComponent {
  public readonly regulatoryLinks: (RegulatoryLink | 'separator')[] = this.getRegulatoryLinks();

  constructor(
    @Inject(ɵPORTAL_CONFIG) private portalConfig: PortalConfig,
    private externalLinkService: ExternalLinkService
  ) {}

  private getRegulatoryLinks(): (RegulatoryLink | 'separator')[] {
    const {privacyLinkUrl, termsLinkUrl, cookiesLinkUrl} = this.portalConfig.navigation.mainNav.regulatoryLinks;

    const privacy: [RegulatoryLink, 'separator'] | [] = privacyLinkUrl
      ? [
          {
            label: asTranslationKey('web-app-portal.main-nav.regulatory-links.privacy.label'),
            url: privacyLinkUrl,
          },
          'separator',
        ]
      : [];

    const terms: [RegulatoryLink, 'separator'] | [] = termsLinkUrl
      ? [
          {
            label: asTranslationKey('web-app-portal.main-nav.regulatory-links.terms.label'),
            url: termsLinkUrl,
          },
          'separator',
        ]
      : [];

    const cookies: [RegulatoryLink, 'separator'] | [] = cookiesLinkUrl
      ? [
          {
            label: asTranslationKey('web-app-portal.main-nav.regulatory-links.cookies.label'),
            url: cookiesLinkUrl,
          },
          'separator',
        ]
      : [];

    const links = [...privacy, ...terms, ...cookies];
    links.pop(); // remove the last separator
    return links;
  }

  onOpenRegulatoryLink(url: string) {
    this.externalLinkService.open(url);
  }
}
