import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';

import {BszTreeRowDefinition, ExpandedGroupStatus, GroupHeader, isGroupHeader} from '../shared';
import {CollapsedState} from './bsz-tree-data-list.definitions';

let nextUniqueId = 0;

@Component({
  // We use an attribute selector to have nice direct ul > li > ul > li structure without intermediate elements.
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'li[bsz-tree-data-list-node]',
  templateUrl: './bsz-tree-data-list-node.html',
  host: {
    '[class]': '"nested-level-" + level',
  },
})
export class BszTreeDataListNode<T, G> implements OnInit, OnChanges {
  @Input()
  set data(item: BszTreeRowDefinition<T, G> | T) {
    const isHeader = isGroupHeader(item);
    this.isGroupHeader = isHeader;
    this.isItem = !isHeader;
    this._data = item;

    if (isGroupHeader(item)) {
      this._groupId = item.groupId;
    }
  }

  get data() {
    return this._data;
  }

  private _data: BszTreeRowDefinition<T, G> | T = {} as T;
  private _groupId: string | undefined;

  @Input()
  level = 0;

  @Input()
  groupHeaderTemplate: TemplateRef<G> | null = null;

  @Input()
  itemTemplate: TemplateRef<T> | null = null;

  /** Node Children collapsed state */
  @Input()
  componentCollapsed: CollapsedState = true;

  @Input()
  hasUserInteraction = false;

  @Input() expandedGroupIds: (string | ExpandedGroupStatus)[] | null | undefined;

  /**
   * function that returns true if the item should be expanded, using its
   * data as parameter
   */
  @Input()
  expandFn: ((data: T | G) => boolean) | null;

  @Output() collapsedChange = new EventEmitter<{
    groupId: string | undefined;
    collapsed: boolean;
  }>();

  @HostBinding('class.bsz-tree-data-list-group')
  isGroupHeader = false;

  @HostBinding('class.bsz-tree-data-list-item')
  isItem = true;

  /** Current Node collapsed state */
  collapsed = true;

  /** Use unique id for header content */
  bszTreeDataListGroupHeader = `bsz-tree-data-list-group-header-${nextUniqueId++}`;

  /** Use unique id for each filter's form */
  bszTreeDataListNodeId = `bsz-tree-data-list-node-${nextUniqueId++}`;

  ngOnInit(): void {
    if (!this.expandFn) {
      return;
    }
    if (this.expandedGroupIds) {
      this.collapsed = !this.isCurrentGroupActive(this.expandFn, this._data);
      return;
    }
    if (!this.hasUserInteraction) {
      this.collapsed = !this.isCurrentOrDescendantActive(this.expandFn, this._data);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.expandedGroups?.previousValue && this._groupId && this.expandFn) {
      this.collapsed = !this.isCurrentGroupActive(this.expandFn, this._data);
    }
    if (changes.componentCollapsed?.currentValue !== undefined) {
      this.setCollapseState(changes.componentCollapsed.currentValue);
    }
  }

  private setCollapseState(isCollapsed: CollapsedState): void {
    // in the first execution, if both properties collapsed and expandFn are used and
    // collapsed is false, it should execute the expandFn instead of overwrite it
    if (!isCollapsed) {
      this.expandFn = () => !isCollapsed;
    }

    this.collapsed = this.isCurrentOrDescendantActive(() => coerceBooleanProperty(isCollapsed), this._data);
  }

  isGroupHeaderTypeGuard(item: unknown): item is GroupHeader<T, G> {
    return isGroupHeader(item);
  }

  toggleCollapsed(groupId: string | undefined) {
    this.collapsed = !(this.expandedGroupIds && groupId) ? !this.collapsed : this.isCollapsed(groupId);
    this.hasUserInteraction = true;
    this.collapsedChangeCallback({
      groupId: this._groupId,
      collapsed: this.collapsed,
    });
  }

  collapsedChangeCallback(event: {groupId: string | undefined; collapsed: boolean}): void {
    this.collapsedChange.emit(event);
  }

  private isCurrentOrDescendantActive(
    conditionFn: (data: T | G) => boolean,
    data: BszTreeRowDefinition<T, G> | T
  ): boolean {
    if (!isGroupHeader(data)) {
      return conditionFn(data);
    }

    if (conditionFn(data.group)) {
      return true;
    }

    return data.children.some((childrenData: BszTreeRowDefinition<T, G> | T) =>
      this.isCurrentOrDescendantActive(conditionFn, childrenData)
    );
  }

  private isCurrentGroupActive(conditionFn: (data: T | G) => boolean, data: BszTreeRowDefinition<T, G> | T): boolean {
    if (isGroupHeader(data)) {
      return conditionFn(data.group);
    }
    return conditionFn(data);
  }

  isCollapsed(groupId: string | undefined): boolean {
    if (this.expandedGroupIds && groupId) {
      return !this.getGroupsIds(this.expandedGroupIds).includes(groupId);
    }
    return this.collapsed;
  }

  private getGroupsIds(expandedGroups: (string | ExpandedGroupStatus)[] | null | undefined): string[] {
    if (!expandedGroups) {
      return [];
    }
    const currentlyExpanded: string[] = [];

    expandedGroups.forEach((data: string | ExpandedGroupStatus, index) => {
      if (typeof data == 'string') {
        currentlyExpanded.push(data);
      } else {
        currentlyExpanded.push(data.groupId);
      }
    });

    return currentlyExpanded;
  }
}
