import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {UINotificationDialogAction, UINotificationDialogData} from './portal-ui-notification-dialog.definitions';
import {NavigationService} from '../../navigation/navigation.service';
import {NoopScrollStrategy} from '@angular/cdk/overlay';
import {take} from 'rxjs/operators';

@Component({
  selector: 'bsz-ui-notification-dialog',
  templateUrl: './portal-ui-notification-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalUINotificationDialogComponent {
  readonly bszTestIdPrefix = 'web-app-portal.portal-ui-notification-dialog';

  static dialogConfig: MatDialogConfig = {
    panelClass: 'bsz-wb-no-padding-dialog',
    width: '380px',
    scrollStrategy: new NoopScrollStrategy(),
  } as const;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: UINotificationDialogData,
    private matDialogRef: MatDialogRef<unknown>,
    private readonly navigationService: NavigationService
  ) {
    this.matDialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((v) => {
        this.dialogData.actions.forEach((action) => action.resolve.onDestroy());
      });
  }

  resolveAction(action: UINotificationDialogAction) {
    action.resolve
      .getIntent$()
      .pipe(take(1))
      .subscribe({
        next: (intent) => {
          this.navigationService.navigate(intent);
          this.closeDialog();
        },
        error: () => {
          action.failed = true;
        },
      });
  }

  closeDialog() {
    this.matDialogRef.close();
  }
}
