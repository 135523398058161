import {Directive, Host, Input, OnInit, TemplateRef} from '@angular/core';
import {TranslatedText} from '@basuiz/web-app-applet-api';
import {BszPortalPageHeaderItem} from './bsz-portal-page-header-item';

@Directive({
  selector: '[bszPortalPageTab]',
  providers: [{provide: BszPortalPageHeaderItem, useExisting: BszPortalPageTabDirective}],
})
export class BszPortalPageTabDirective implements OnInit {
  /** Used when selecting the tab programmatically and for generating the test-id of the tab-header */
  @Input()
  tabId: string;

  /** Text displayed in the tab-header */
  @Input()
  tabLabel: TranslatedText;

  constructor(@Host() public templateRef: TemplateRef<any>) {}

  ngOnInit(): void {
    if (!this.tabId) {
      throw new Error(`Missing mandatory input 'tabId' of directive 'bszPageTab'`);
    }
    if (!this.tabLabel) {
      throw new Error(`Missing mandatory input 'tabLabel' of directive 'bszPageTab'`);
    }
  }
}
