import {AuthRoutes} from '@basuiz/shared/data-access';
import {TranslationKey} from '@basuiz/web-app-applet-api';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {PortalPage} from '../navigation/classes/portal-page';
import {TranslationKeyWithParams} from '../navigation/translation-key-with-params';

export class AuthEmailVerifyPage extends PortalPage {
  get parentPortalPage(): PortalPage | null {
    return null; // TODO - Replace with the adequate PortalPage unless the page hangs direct under the 'Home' page.
  }

  get breadcrumbText(): TranslationKey | TranslationKeyWithParams {
    return asTranslationKey('web-app-portal.pages.auth-email-verify.breadcrumb');
  }

  get breadcrumbTestId(): string {
    return 'web-app-portal.pages.auth-email-verify.breadcrumb';
  }

  get titleText(): TranslationKey {
    return asTranslationKey('web-app-portal.pages.auth-email-verify.window-title');
  }

  static readonly routerConfigPath = AuthRoutes.emailVerify.appRoute;
  get route(): Array<string> {
    return [`/${AuthEmailVerifyPage.routerConfigPath}`];
  }
}
