import {Injector} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  BszCustomContextAction,
  BszCustomContextActionAppletContext,
  BszCustomContextActionId,
} from '@basuiz/web-app-applet-api';
import {ContextActionWithTranslatedLabel} from '../context-action.interface';
import {isDefined} from '../../utils/defined.util';
import {WebAppAppletDirective} from '../../web-app-applet/web-app-applet.directive';

/*
 * Components supporting custom context actions should extend this class, this class provides the
 * logic to generate the context-action model that can be consumed by the common context-action-menu component.
 *
 * It is essential to have the have the closest WebAppAppletDirective injected.
 * Avoid extending this class in SERVICES, since a same service instance might be reused by different
 * applets or instances of a same applet and lead to situations where the wrong instance of WebAppAppletDirective
 * is used.
 */
export abstract class BszCustomizableContextActionComponent<
  INTERNAL,
  CUSTOM extends BszCustomContextAction<ENTITY_NAME, ENTITY>,
  ENTITY_NAME extends string,
  ENTITY
> {
  protected constructor(protected translateService: TranslateService, protected injector: Injector) {}

  protected getCustomizedContextActions(): ContextActionWithTranslatedLabel[] {
    return this.getConfiguredContextActions()
      .map((action) =>
        this.isCustomContextAction(action)
          ? this.resolveCustomContextAction(action)
          : this.isInternalContextAction(action)
          ? this.getInternalContextAction(action)
          : undefined
      )
      .filter((action): action is ContextActionWithTranslatedLabel => isDefined(action));
  }

  protected abstract getConfiguredContextActions(): (INTERNAL | CUSTOM)[];

  protected abstract getInternalContextAction(action: INTERNAL): ContextActionWithTranslatedLabel;

  protected abstract getCustomContextActionEntity(): ENTITY;

  protected isCustomContextAction(action: INTERNAL | CUSTOM): action is CUSTOM {
    const guardId: BszCustomContextActionId<'guard'> = 'custom-id.context-action.guard.id';
    const guardIdPrefix = guardId.replace('.guard.id', '');
    return (action as CUSTOM).id?.startsWith(guardIdPrefix) ?? false;
  }

  /* This shouldn't be necessary, but the upgrade from TS 4.2.4 to TS 4.3.5 caused compilation issues with a small
     number of cases of type narrowing. Where a union of two types, narrowed to a single type by a guard, isn't narrowed
     as expected on both sides of an if-else or ternary operator

     Believed to be related to changes made in TS to facilitate this:
     https://devblogs.microsoft.com/typescript/announcing-typescript-4-3/#contextual-narrowing

     Could not reproduce:
     https://www.typescriptlang.org/play?ts=4.3.5#code/IYIwzgLgTsDGEAJYBthjAgYge2wHgEEAaBAIQD4EBvAWACgFGEBzAUwgGVsBbVj6AJYA7ZmAAUASgBcCSFGHMA2gF1q9JhoRR2AVyhCEEABYCwAOjYQCYAkIAmpcRLPdgABzFjgAeSikJCAC85OqaYcamZqYEXr7+CAD8oWEphibmlvzyIphQPDE+fhLJqZoyERnsWQq5PKSxRSUaxQxMAL70JaBycIiW1rYOTjJiBAgAPmQSKgDcna2MbnkQrPCsdgjRDaQyY5P+MoWkmxhjtAua2hB6BtA6rHMXHXQlS9graxuZgjl53AW7aSyH7MNQXDRXG4IADkBGhjw0z1ey1WKy+VRBtW49RAMgOwOyoPOqUh+hhpHhJWebSAA

     TODO: AFP-44558: look to remove this with the upgrade to Nx/Ng 13 and TS 4.4.x
   */
  private isInternalContextAction(action: INTERNAL | CUSTOM): action is INTERNAL {
    return !this.isCustomContextAction(action);
  }

  private resolveCustomContextAction(customContextAction: CUSTOM): ContextActionWithTranslatedLabel {
    const {id, labelResolver, availableResolver, clickCallback} = customContextAction;
    const {translateService} = this;
    const entity = this.getCustomContextActionEntity();
    const appletContext = this.getAppletContext();

    return {
      id,
      callback: () => clickCallback(entity, appletContext),
      get label() {
        return labelResolver(translateService, entity, appletContext);
      },
      get isAvailable() {
        return isDefined(availableResolver) ? availableResolver(entity, appletContext) : true;
      },
    };
  }

  private getAppletContext(): BszCustomContextActionAppletContext {
    const {injector} = this; // Important: the `this` in `get appletCssSelector()` does not point to this class
    return {
      get appletCssSelector() {
        return injector.get(WebAppAppletDirective).getAppletComponentSelector();
      },
      get appletContextId() {
        return injector.get(WebAppAppletDirective).appletContext?.appletContextId;
      },
    };
  }
}
