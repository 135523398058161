import {NavigationIntent} from '../../navigation/classes/navigation-intent';

export interface ShowShipmentOverviewIntentPayload {
  foo?: never;
}

export class ShowShipmentOverviewIntent extends NavigationIntent {
  constructor(public payload: ShowShipmentOverviewIntentPayload = {}) {
    super();
  }
}
