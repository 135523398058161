import {BszFeatureToggle} from './models/features.definition';
import {FeatureStatus} from './models/generic.definition';
import {ExperimentalFeatureStatus, ExperimentalFeatureToggleTree} from './models/features-experimental.definition';
import {getExperimentalFeatureToggleTree} from './experimental-feature-toggle-tree';

type OriginalNode = {[key in string]: OriginalNode | FeatureStatus};
type ExperimentalNode = {[key in string]: ExperimentalFeatureStatus | ExperimentalNode};

export function warnOnEnabledExperimentalFeatureToggle(toggle: BszFeatureToggle): void {
  const experimentalTree: ExperimentalFeatureToggleTree = getExperimentalFeatureToggleTree();
  let hasExperimentalFeatureEnabled: boolean = false;

  checkRecursive(experimentalTree as unknown as ExperimentalNode, toggle as unknown as OriginalNode, '');

  if (hasExperimentalFeatureEnabled) {
    console.warn(`Experimental features might make use of experimental applets that are not ready for use in production.
Or define new user journeys that are still incomplete since some applets might not be available yet.`);
  }

  function checkRecursive(experimentalNode: ExperimentalNode, toggleNode: OriginalNode | FeatureStatus, path: string) {
    Object.entries(experimentalNode).forEach(([featureKey, maybeExperimentalFeature]) => {
      const toggleFeature = toggleNode instanceof Object ? toggleNode[featureKey] : toggleNode;
      if (maybeExperimentalFeature === ExperimentalFeatureStatus && toggleFeature !== 'disabled') {
        hasExperimentalFeatureEnabled = true;
        console.warn(`Feature '${path}${featureKey}' from @basuiz/web-app-feature-toggle is experimental`);
      } else if (maybeExperimentalFeature instanceof Object) {
        checkRecursive(maybeExperimentalFeature, toggleFeature, `${path}${featureKey}.`);
      }
    });
  }
}
