import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AfpRestResponse} from '../../afp-rest/index';
import {AfpAuthObject, AfpAuthRequest, AfpAuthVerify, AfpAuthVerifyResponse} from '../models/auth.definitions';
import {Observable} from 'rxjs';

@Injectable()
export class AuthVerificationService {
  private URLS = {
    GENERIC_TRANSACTION: {
      VERIFY: '/com.basuiz.afs.rest.services/rest/generictransactionsigning/verifyTransaction',
      CANCEL_SESSION: '/com.basuiz.afs.rest.services/rest/generictransactionsigning/cancelSession',
      STORE_FOR_SIGNING: '/com.basuiz.afs.rest.services/rest/generictransactionsigning/storeTransactionForSigning',
      RESEND_TOKEN: '/com.basuiz.afs.rest.services/rest/generictransactionsigning/resendToken',
    },
  };

  constructor(private http: HttpClient) {}

  verifyTransaction(verificationObject: AfpAuthVerify): Observable<AfpRestResponse<AfpAuthVerifyResponse>> {
    return this.http.post<AfpRestResponse<AfpAuthVerifyResponse>>(
      this.URLS.GENERIC_TRANSACTION.VERIFY,
      verificationObject
    );
  }

  cancelSigningSession(authObject: AfpAuthObject): Observable<AfpRestResponse<void>> {
    return this.http.post<AfpRestResponse<void>>(this.URLS.GENERIC_TRANSACTION.CANCEL_SESSION, authObject);
  }

  storeTransactionForSigning(authObject: AfpAuthObject): Observable<AfpRestResponse<void>> {
    return this.http.post<AfpRestResponse<void>>(this.URLS.GENERIC_TRANSACTION.STORE_FOR_SIGNING, authObject);
  }

  resendSigningToken(authObject: AfpAuthObject): Observable<AfpRestResponse<AfpAuthRequest>> {
    return this.http.post<AfpRestResponse<AfpAuthRequest>>(this.URLS.GENERIC_TRANSACTION.RESEND_TOKEN, authObject);
  }
}
