import {Injectable} from '@angular/core';
import {AuthVerificationService} from '../data-access/auth-verification.service';
import {isAfpRestResponseWithAuthResult} from '../type-guards/auth.type-guards';
import {AfpRestResponseWithAuthResult} from '../models/auth.definitions';
import {AfpRestResponse, isAfpRestResponse} from '../../afp-rest/index';

@Injectable()
export class AuthResultService {
  constructor(private verificationService: AuthVerificationService) {}

  public isResponseWithAuthResult(
    responseBody: unknown
  ): responseBody is AfpRestResponse<AfpRestResponseWithAuthResult> {
    return isAfpRestResponse(responseBody) && isAfpRestResponseWithAuthResult(responseBody.payload);
  }

  public isResponseWithAuthNotNeeded(responseBody: unknown): boolean {
    return !this.isResponseWithAuthResult(responseBody);
  }

  public isResponseWithAuthSuccessful(
    responseBody: unknown
  ): responseBody is AfpRestResponse<AfpRestResponseWithAuthResult> {
    return this.isResponseWithAuthResult(responseBody) && responseBody.payload.authResult.status === 'SIGNED';
  }

  public isResponseWithAuthCancelled(
    responseBody: unknown
  ): responseBody is AfpRestResponse<AfpRestResponseWithAuthResult> {
    return this.isResponseWithAuthResult(responseBody) && responseBody.payload.authResult.status === 'CANCELLED';
  }

  public isResponseWithAuthFailed(
    responseBody: unknown
  ): responseBody is AfpRestResponse<AfpRestResponseWithAuthResult> {
    return this.isResponseWithAuthResult(responseBody) && responseBody.payload.authResult.status === 'FAILED';
  }

  public isResponseWithAuthNotPermitted(
    responseBody: unknown
  ): responseBody is AfpRestResponse<AfpRestResponseWithAuthResult> {
    return this.isResponseWithAuthResult(responseBody) && responseBody.payload.authResult.status === 'NOT_PERMITTED';
  }

  public storeTransactionWhenAuthCancelled(response: AfpRestResponse<unknown>) {
    if (this.isResponseWithAuthCancelled(response) && response.payload.authResult.transactionSigningObject) {
      return this.verificationService.storeTransactionForSigning(response.payload.authResult.transactionSigningObject);
    }
  }
}
