/**
 * A service that provides functionality that depends on the <code>WebAppAppletDirective</code>
 * cannot be a singleton; it must be provided by the applet where the directive is available.
 *
 * This constant provides a marker to denote (and lint for) a service with this requirement
 * when it is declared <code>@Injectable</code>. The value of <code>null</code> informs Angular that
 * it is not responsible for providing the service and that it should be in the
 * <code>providers</code> array of a component
 *
 * <pre>
 * @Injectable({
 *   providedIn: TO_BE_PROVIDED_BY_BANKLET_INSTANCE
 * })
 * </pre>
 *
 * The applet entry-component does not make the directive available. The service can be provided
 * by any inner component, typically the first component wrapped by the entry component.
 */
export const TO_BE_PROVIDED_BY_BANKLET_INSTANCE: null = null;
