import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {saveAs} from 'file-saver';

import {MIME_TYPE} from '../const/index';
import {
  ExportToFileFromClientDataConfig,
  ExportToFileFromServerDataConfig,
  ExportToFilePayload,
} from '../definitions/index';
import {ExportToFileConfigService} from './export-to-file-config.service';

@Injectable({
  providedIn: 'root',
})
export class ExportToFileService {
  constructor(private http: HttpClient, private exportToFileConfigService: ExportToFileConfigService) {}

  private saveFile(response: ArrayBuffer, type: string, filename: string): void {
    const file = new Blob([response], {type: type});
    saveAs(file, filename);
  }

  private generateHeaders(mimeType: string, contentType: string): HttpHeaders {
    const headers = new HttpHeaders().set('Accept', mimeType).set('Content-type', contentType);
    return headers;
  }

  public fromClientData(config: ExportToFileFromClientDataConfig): Observable<ArrayBuffer> {
    const mimeType = MIME_TYPE[config.fileType];
    const url = '/com.basuiz.afs.rest.services/rest/export/fromClientData';
    const payload = this.exportToFileConfigService.createExportToFilePayload(config);

    const options = {
      headers: this.generateHeaders(mimeType, 'application/octet-stream;charset=UTF-8'),
      responseType: 'arraybuffer' as 'arraybuffer',
    };

    return this.http
      .post(url, this.replaceMinusWithHyphen(payload), options)
      .pipe(tap((response) => this.saveFile(response, mimeType, payload.outputFilename)));
  }

  public fromServerData(config: ExportToFileFromServerDataConfig): Observable<ArrayBuffer> {
    const mimeType = MIME_TYPE[config.fileType];
    const url = config.endpoint.url;
    const payload = this.exportToFileConfigService.createExportToFilePayload(config);
    const options = {
      headers: this.generateHeaders(mimeType, 'application/json;charset=UTF-8'),
      params: config.endpoint.params,
      responseType: 'arraybuffer' as 'arraybuffer',
    };

    return this.http
      .post(url, payload, options)
      .pipe(tap((response) => this.saveFile(response, MIME_TYPE[config.fileType], payload.outputFilename)));
  }

  /* In the browser, minus character is used for negative numbers, however the export end-point does not handle
   * the minus character properly, PDFs just ignore them, converting a negative amount into positive.
   * CSV and Excel do not handle the minus character correctly */
  private replaceMinusWithHyphen(payload: ExportToFilePayload): ExportToFilePayload {
    const matchAllMinusRegexp = /\u2212/g; // minus character (not a hyphen) HTML Entity (Named) &minus;
    const hyphen = '\u002D'; //'-' hyphen-minus;
    const payloadAsString = JSON.stringify(payload);
    return JSON.parse(payloadAsString.replace(matchAllMinusRegexp, hyphen));
  }
}
