/**
 * Transforms input percentage value from 100-base to 1-base
 * @param percentValue input in 100-base format
 * @return input argument divided by 100 or unchanged input argument if it is not a number
 */
export function transformPercentageToUnitRatio(percentValue: number): number;
export function transformPercentageToUnitRatio(percentValue: undefined): undefined;
export function transformPercentageToUnitRatio(percentValue: number | undefined): number | undefined;
export function transformPercentageToUnitRatio(percentValue: any): number | undefined {
  if (percentValue === undefined) {
    return undefined;
  } else {
    if (!Number.isFinite(percentValue) || percentValue === 0) {
      return percentValue;
    }
    return (percentValue as number) / 100;
  }
}
