import {Injectable} from '@angular/core';
import {getBszCountry, WebAppCommonFacade} from '@basuiz/web-app-common';
import {Observable} from 'rxjs';
import {BszCountry} from '@basuiz/web-app-applet-api';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WebAppSdkCountryService {
  constructor(private readonly webAppCommonFacade: WebAppCommonFacade) {}

  /** Emits a list of countries retrieved from the AFP back-end */
  getCountryList$(): Observable<BszCountry[]> {
    return this.webAppCommonFacade.countryList$.pipe(map((list) => list.map(getBszCountry)));
  }
}
