import {HttpRequest} from '@angular/common/http';
import {AfpRestResponse} from '../../afp-rest/index';
import {
  AfpAuthOperation,
  AfpAuthOperationSupported,
  AfpRestRequestWithAuth,
  AfpRestResponseWithAuthRequest,
  AfpRestResponseWithAuthResult,
} from '../models/auth.definitions';

export function isAfpRestRequestWithAuth(req: HttpRequest<unknown>): req is HttpRequest<AfpRestRequestWithAuth> {
  return (
    typeof (req.body as AfpRestRequestWithAuth)?.transactionSigningObject?.transactionSigningObjectType === 'string'
  );
}

export function isAfpRestResponseWithAuthRequest(
  response: AfpRestResponse<unknown>
): response is AfpRestResponse<AfpRestResponseWithAuthRequest> {
  return (
    typeof (response.payload as AfpRestResponseWithAuthRequest)?.transactionSigningRequest
      ?.transactionSigningObjectType === 'string'
  );
}

export function isAfpRestResponseWithAuthResult(payload: unknown): payload is AfpRestResponseWithAuthResult {
  return (payload as AfpRestResponseWithAuthResult)?.authResult instanceof Object;
}

export function isAfpAuthOperationSupported(operation: AfpAuthOperation): operation is AfpAuthOperationSupported {
  const OPERATION_SUPPORTED_MAP: Record<AfpAuthOperationSupported, true> = {
    CRONTO: true,
    CRONTO_ONLINE: true,
    CRONTO_PUSH: true,
    FUTURAE: true,
    FUTURAE_PUSH: true,
    MATRIX: true,
    MTAN: true,
    PUSH: true,
    TOKEN: true,
  };

  return operation in OPERATION_SUPPORTED_MAP;
}
