import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BszI18nModule} from '@basuiz/i18n';

// We have to import the pipes like this (instead of using the barrel index files - e.g. instead of using
// "import {BszNumberPipe} from './number'") because of this issue: https://github.com/ng-packagr/ng-packagr/issues/482
// Even "import {BszNumberPipe} from './number/index'" (with the "index" part added, which has to be used
// in other places because of another issue in ngPackagr) doesn't work here...
import {BszCurrencyPipe} from './currency/currency.pipe';
import {BszDatePipe} from './date/date.pipe';
import {BszIbanPipe} from './iban/iban.pipe';
import {BszNumberPipe} from './number/number.pipe';

const PIPE_DEFINITIONS = [BszNumberPipe, BszIbanPipe, BszDatePipe, BszCurrencyPipe];

@NgModule({
  imports: [CommonModule, BszI18nModule],
  exports: PIPE_DEFINITIONS,
  declarations: PIPE_DEFINITIONS,
})
export class BszPipesModule {}
