import {AfterViewInit, Directive, ElementRef, Inject, Input} from '@angular/core';

/**
 * A simple directive to be used in the column's <th>, that will mark it to be available for toggling its visibility.
 * columnProperty and columnText will be used to generate the menu that will handle the action
 *
 * TODO: find a better way to do this - it is confusing to have a directive in the <th> instead of the column itself
 */
@Directive({
  selector: '[bsz-toggle-header]',
  host: {
    class: 'bsz-toggle-header',
  },
})
export class BszHeaderToggle implements AfterViewInit {
  /** The text of value that will appear in the toggle menu. */
  @Input('bsz-toggle-header') text: string | null = null;

  /**
   * Property of the context of a BszTableColumn where header sits within.
   * This will default to the column's name.
   */
  columnProperty!: string;

  /**
   * The inner text of the table header that has been marked for toggle.
   * This will default to the column's header nativeElement innerText.
   */
  columnText!: string;

  constructor(
    private elementRef: ElementRef,

    // Using any, since otherwise we would get "ReferenceError: Cannot access 'BszTableColumn' before initialization".
    // We have a circular dependency between BszCell and BszTableColumn, so the error is most probably caused by that.
    @Inject('BSZ_TABLE_COLUMN') private bszTableColumn: any
  ) {}

  // we need to wait for the view to be initialized, otherwise the column text might be empty
  // (e.g. when we are using ngx-translate pipe to translate the headers)
  ngAfterViewInit(): void {
    this.columnText = this.text || this.elementRef.nativeElement.textContent;
    this.columnProperty = this.bszTableColumn.name;
  }
}
