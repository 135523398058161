import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {
  AuthDialogCollectiveRepresentation,
  AuthDialogRepresentation,
  AuthDialogSingleRepresentation,
} from '../../models/auth-dialog.definitions';
import {
  isAuthDialogCollectiveRepresentation,
  isAuthDialogSingleRepresentation,
} from '../../type-guards/auth-dialog.type-guards';

@Component({
  selector: 'bsz-auth-representation',
  templateUrl: './auth-representation.component.html',
  styleUrls: ['./auth-representation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthRepresentationComponent {
  @Input()
  // Representation title can be a translation key translated on the webapp, or a translated text coming from the server
  // As translate pipe first checks for the translation and, if doesn't exist, it uses the key, the fallback is already achieved.
  representation: AuthDialogRepresentation;

  get singleRepresentation(): AuthDialogSingleRepresentation | null {
    return isAuthDialogSingleRepresentation(this.representation) ? this.representation : null;
  }

  get collectiveRepresentation(): AuthDialogCollectiveRepresentation | null {
    return isAuthDialogCollectiveRepresentation(this.representation) ? this.representation : null;
  }
}
