<mat-toolbar *ngIf="bankLogoUrl">
  <div class="logo" [attr.aria-label]="'web-app-portal.app-bar.bank-logo.link.aria-label' | translate">
    <img [src]="bankLogoUrl" />
  </div>
</mat-toolbar>
<div class="top-spacer"></div>
<div class="error-message-container">
  <bsz-central-message
    [icon]="'error'"
    [title]="errorMessageTitle"
    [info]="errorMessageInfo"
    bszTestId="web-app-portal.application-initialization-failure.error-message"
  ></bsz-central-message>
</div>
