import {NavigationIntent} from '../../navigation/classes/navigation-intent';

export interface ShowPriceCalculatorIntentPayload {
  foo?: never;
}

export class ShowPriceCalculatorIntent extends NavigationIntent {
  constructor(public payload: ShowPriceCalculatorIntentPayload = {}) {
    super();
  }
}
