import {PortalPage} from '../navigation/classes/portal-page';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslationKeyWithParams} from '../navigation/translation-key-with-params';
import {TranslationKey} from '@basuiz/web-app-applet-api';
import {PageTabMemory} from '../components/portal-page-layout/page-tab-memory.definition';

export type NotificationsPageTab = 'inbox' | 'subscription';

export interface NotificationsPagePayload {
  view?: NotificationsPageTab;
}

export class NotificationsPage extends PortalPage {
  constructor(public payload: NotificationsPagePayload = {view: 'inbox'}) {
    super();
  }

  public readonly pageTabMemory: PageTabMemory<NotificationsPageTab> = {
    activeTabId: this.payload.view,
  };

  get parentPortalPage(): PortalPage | null {
    return null;
  }

  get breadcrumbText(): TranslationKey | TranslationKeyWithParams {
    return asTranslationKey('web-app-portal.pages.notifications.breadcrumb');
  }

  get breadcrumbTestId(): string {
    return 'web-app-portal.pages.notifications.breadcrumb';
  }

  get titleText(): TranslationKey {
    return asTranslationKey('web-app-portal.pages.notifications.window-title');
  }

  static readonly routerConfigPath = 'notifications';
  get route(): Array<string> {
    return [`/${NotificationsPage.routerConfigPath}`];
  }
}
