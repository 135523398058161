import {Type} from '@angular/core';
import {CustomNavigationIntentHandlerMap, CustomNavigationIntentHandlerResult} from './portal.config.definition';
import {NavigationIntent} from '../navigation/classes/navigation-intent';

export class CustomNavigationIntentHandlerMapBuilder {
  private customNavigationIntentHandlerMap: CustomNavigationIntentHandlerMap;

  constructor() {
    this.customNavigationIntentHandlerMap = new Map();
  }

  addHandlerFor<INTENT extends NavigationIntent>(
    intentClass: Type<INTENT>,
    handler: (intent: INTENT) => CustomNavigationIntentHandlerResult | Promise<CustomNavigationIntentHandlerResult>
  ): CustomNavigationIntentHandlerMapBuilder {
    this.customNavigationIntentHandlerMap.set(intentClass, handler);
    return this;
  }

  build(): CustomNavigationIntentHandlerMap {
    return this.customNavigationIntentHandlerMap;
  }
}
