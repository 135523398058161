<div class="bsz-action-bar-cancel bsz-margin-l2" *ngIf="cancelActionButtonRef">
  <ng-template [ngTemplateOutlet]="cancelActionButtonRef"></ng-template>
</div>

<div class="bsz-margin-l2" *ngIf="secondaryActionMenuItems && secondaryActionMenuItems.length">
  <button
    mat-stroked-button
    [matMenuTriggerFor]="menu"
    [attr.aria-label]="'ui-elements.bsz-action-bar.accessibility.secondary-actions-menu' | translate"
  >
    <mat-icon>more_horiz</mat-icon>
  </button>
  <mat-menu class="bsz-action-bar-secondary" #menu="matMenu">
    <button
      mat-menu-item
      *ngFor="let menuItem of secondaryActionMenuItems"
      (click)="menuItem.click()"
      [disabled]="menuItem.disabled"
    >
      {{ menuItem.textContent }}
    </button>
  </mat-menu>
</div>

<div class="bsz-action-bar-secondary bsz-margin-l2" *ngFor="let secondaryActionTemplate of secondaryActionButtons">
  <ng-template [ngTemplateOutlet]="secondaryActionTemplate"></ng-template>
</div>

<div class="bsz-action-bar-primary bsz-margin-l2" *ngIf="primaryActionButtonRef">
  <ng-template [ngTemplateOutlet]="primaryActionButtonRef"></ng-template>
</div>

<div style="display: none;">
  <ng-container #secondaryActionsViewContainer></ng-container>
</div>
