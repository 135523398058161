import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {TranslateModule} from '@ngx-translate/core';
import {TestingCoreModule} from '../testing-core/index';
import {ExportToFileButtonComponent} from './components/export-to-file-button/export-to-file-button.component';

@NgModule({
  declarations: [ExportToFileButtonComponent],
  imports: [CommonModule, TestingCoreModule, TranslateModule, MatButtonModule, MatIconModule, MatMenuModule],
  exports: [ExportToFileButtonComponent],
})
export class ExportToFileModule {}
