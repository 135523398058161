import {Directive, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {combineLatest, isObservable, Observable, of, Subject} from 'rxjs';
import {filter, map, startWith} from 'rxjs/operators';

type Nullable<T> = T | null | undefined;

interface OptionWithFilterValue<T> {
  option: T;
  filterValue: string;
}

interface SearchChangePayload {
  searchText: string;
  optionViewValues?: string[];
}

@Directive({
  selector: 'mat-select',
})
export class BszSelectSearchMatSelectDirective implements OnDestroy {
  bszSelectSearchChange = new Subject<SearchChangePayload>();

  ngOnDestroy(): void {
    this.bszSelectSearchChange.complete();
  }
}

@Pipe({
  name: 'bszSelectSearch',
})
export class BszSelectSearchPipe implements PipeTransform {
  constructor(private readonly selectSearchFilterMatSelectDirective: BszSelectSearchMatSelectDirective) {}

  transform<T>(options: Nullable<T[]> | Observable<Nullable<T[]>>, filterValueProvider?: keyof T): Observable<T[]> {
    const options$: Observable<Nullable<T[]>> = isObservable(options) ? options : of(options);

    const optionsToFilter$: Observable<OptionWithFilterValue<T>[]> = options$.pipe(
      filter((options): options is T[] => Array.isArray(options)),
      map((options) =>
        options.map((option) => ({
          option: option,
          filterValue: this.getValueToFilter(option, filterValueProvider),
        }))
      )
    );

    const searchChangePayload$: Observable<SearchChangePayload> =
      this.selectSearchFilterMatSelectDirective.bszSelectSearchChange.pipe(
        startWith({searchText: '', optionViewValues: undefined}),
        map((searchText: SearchChangePayload) => searchText)
      );

    return combineLatest([optionsToFilter$, searchChangePayload$]).pipe(
      map(([optionsToFilter, searchChangePayload]) => {
        // If the payload already has the viewValues and no filterValueProvider was used,
        // the filterValue is replaced with the viewValue
        if (searchChangePayload.optionViewValues && !filterValueProvider) {
          optionsToFilter.forEach((option, index) => {
            option.filterValue = searchChangePayload.optionViewValues?.[index] ?? '';
          });
        }

        return optionsToFilter
          .filter(({filterValue}) => filterValue?.toLowerCase().includes(searchChangePayload.searchText.toLowerCase()))
          .map((filteredOption) => filteredOption.option);
      })
    );
  }

  protected getValueToFilter<T>(option: T, filterValueProvider: keyof T | undefined): string {
    if (filterValueProvider) {
      return String(option[filterValueProvider]);
    }
    return String(option);
  }
}
