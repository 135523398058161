import {ConfigurableFocusTrap, ConfigurableFocusTrapFactory} from '@angular/cdk/a11y';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  ConnectedPosition,
  FlexibleConnectedPositionStrategy,
  GlobalPositionStrategy,
  Overlay,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {ComponentRef, Directive, EventEmitter, Injector, Input, NgZone, OnDestroy, Output} from '@angular/core';
import {DatepickerDropdownPositionX, DatepickerDropdownPositionY, DateRange} from '@angular/material/datepicker';
import {AsyncSubject, BehaviorSubject, filter, map, merge, Subject, take, takeUntil} from 'rxjs';

import {BszScreenSize} from '../bsz-screen-size-content-switcher';
import {BszCalendar} from './bsz-calendar';
import {BszDateRange, BszMixedDate, BszMixedDateCollection, isDateRange} from './bsz-datepicker.definitions';
import {BszDatepickerAbstractInput} from './bsz-datepicker-abstract-input';

@Directive({
  selector: 'bsz-datepicker-panel',
})
export class BszDatePickerOverlay<D> implements OnDestroy {
  private static nextId = 0;

  /** @private */
  readonly uniqueId = `bsz-datepicker-${BszDatePickerOverlay.nextId++}`;

  /**
   * A collection of values for the calendar to use as a list of predefined
   * Range selections. The keys of the collection will be used as labels
   * and the values as the model of the calendar
   */
  @Input() presets: BszMixedDateCollection<D> | null = null;

  /** The date representing the period to start the calendar in. */
  @Input() startAt: D | null = null;

  /** Preferred position of the datepicker in the X axis. */
  @Input()
  xPosition: DatepickerDropdownPositionX = 'start';

  /** Preferred position of the datepicker in the Y axis. */
  @Input()
  yPosition: DatepickerDropdownPositionY = 'below';

  /** Whether the datepicker pop-up should be disabled. */
  @Input()
  get disabled(): boolean {
    if (this._disabled === undefined && this.datepickerInput) {
      return this.datepickerInput.disabled;
    }

    return !!this._disabled;
  }
  set disabled(isDisabled: BooleanInput) {
    this._disabled = coerceBooleanProperty(isDisabled);
    this._disabledStateChange.next(this._disabled);
  }
  /** @private */
  _disabled: boolean | undefined;

  @Output() submit = new EventEmitter<BszMixedDate<D> | null>();

  @Output() cancel = new EventEmitter<void>();

  readonly onClose = new Subject<void>();

  readonly onOpen = new Subject<void>();

  readonly selected = new BehaviorSubject<BszMixedDate<D> | null>(null);

  readonly _disabledStateChange = new Subject<boolean>();

  /** Function for the calendar to use to to filter which dates are selectable. */
  dateFilter: ((date: D) => boolean) | null = null;

  /** The maximum selectable date. */
  maxDate: D | null = null;

  /** The minimum selectable date. */
  minDate: D | null = null;

  /** To either allow for single dates or range selection. */
  rangePicker = false;

  /** Whether the datepicker is opened or not. */
  opened = false;

  private readonly destroy = new AsyncSubject<void>();

  private readonly isMobile$ = this.screenSizeService
    .getScreenSize()
    .pipe(map((screenSize) => screenSize === 'mobile'));

  private isMobile = false;

  /** Reference to the overlay created by the overlay service. */
  private overlayRef: OverlayRef | null = null;

  /** Reference to the component instance rendered in the overlay. */
  private calendarComponentRef: ComponentRef<BszCalendar<D>> | null = null;

  /** The directive on the input element associated with the datepicker */
  private datepickerInput: BszDatepickerAbstractInput<D> | null = null;

  private focusTrap: ConfigurableFocusTrap | null = null;

  /** The element which had the focus before the datepicker was opened. */
  private focusedElementBeforeOpen: HTMLElement | null = null;

  /** Property to store the range selection when a user has picked a range from the range list. */
  private userSelectedPreset: D | BszDateRange<D> | null = null;

  constructor(
    protected readonly overlay: Overlay,
    protected readonly screenSizeService: BszScreenSize,
    protected readonly focusTrapFactory: ConfigurableFocusTrapFactory,
    protected readonly injector: Injector,
    protected readonly ngZone: NgZone
  ) {
    this.isMobile$.pipe(takeUntil(this.destroy)).subscribe((isMobile) => (this.isMobile = isMobile));
  }

  ngOnDestroy(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
    this.destroy.next();
    this.destroy.complete();
  }

  open(): void {
    if (this.opened) {
      return;
    }

    this.focusedElementBeforeOpen = document.activeElement as HTMLElement;
    this.opened = true;
    this.onOpen.next();
    this.openOverlay();
  }

  close(): void {
    //Store the selected range to restore the state of the calendar when we open it again.
    this.userSelectedPreset = this.calendarComponentRef?.instance._selectedPreset ?? null;

    this.overlayRef?.detach();
    this.calendarComponentRef = null;

    this.restoreFocus();

    this.onClose.next();
    this.opened = false;
  }

  /** @private */
  _registerInput(input: BszDatepickerAbstractInput<D>): void {
    if (this.datepickerInput) {
      throw Error('BszDatepicker can only be associated with a single input.');
    }
    this.datepickerInput = input;
  }

  setSelectedDate(dateOrRange: D | BszDateRange<D> | null): void {
    const newSelected = isDateRange(dateOrRange) ? new DateRange(dateOrRange.start, dateOrRange.end) : dateOrRange;
    this.userSelectedPreset = null;
    this.selected.next(newSelected);
  }

  private openOverlay(): void {
    this.overlayRef = this.overlay.create(this.getOverlayConfig());

    this.calendarComponentRef = this.attachCalendarToOverlay(this.overlayRef);

    this.setAnimationTransformOrigin(this.overlayRef);

    this.initOverlaySubscribers(this.overlayRef);

    this.setupCalendar(this.calendarComponentRef.instance);

    this.trapFocus(this.calendarComponentRef.instance._elementRef.nativeElement);
  }

  /**
   * Because we don't know in advance if it opens to the top or to the bottom,
   * we need to get it from the overlay once it has it defined and update it and
   *  after the zone is stable
   */
  private setAnimationTransformOrigin(overlayRef: OverlayRef) {
    this.ngZone.onStable.pipe(take(1)).subscribe(() => {
      const calendarWrapper = overlayRef.overlayElement.querySelector<HTMLElement>('.bsz-calendar-content-wrapper');

      if (calendarWrapper) {
        calendarWrapper.style.transformOrigin = overlayRef.overlayElement.style.transformOrigin;
      }
    });
  }

  private setupCalendar(calendar: BszCalendar<D>): void {
    this.forwardInputs(calendar);

    calendar._elementRef.nativeElement.id = this.uniqueId;

    const selectedValue = this.selected.getValue();

    // Assign the selected value and range selection if applicable
    calendar.selected = selectedValue;
    calendar._setSelectedPreset(selectedValue, this.userSelectedPreset);

    // If there is a selected date move the calendar view to the selected period
    if (selectedValue && !this.startAt) {
      const startDate = isDateRange(selectedValue) ? selectedValue.end : selectedValue;
      calendar.startAt = startDate;
    }

    calendar.selectedChange.subscribe((value) => this.selected.next(value));

    calendar.submit.pipe(takeUntil(this.onClose)).subscribe((value) => {
      this.submit.emit(value);
      this.close();
    });

    calendar.cancel.pipe(takeUntil(this.onClose)).subscribe(() => this.cancelAndClose());
  }

  private attachCalendarToOverlay(overlayRef: OverlayRef): ComponentRef<BszCalendar<D>> {
    const calendarComponentPortal = new ComponentPortal<BszCalendar<D>>(BszCalendar, null, this.injector);

    return overlayRef.attach(calendarComponentPortal);
  }

  private forwardInputs(calendarInstance: BszCalendar<D>): void {
    calendarInstance.presets = this.presets;
    calendarInstance.dateFilter = this.dateFilter;
    calendarInstance.maxDate = this.maxDate;
    calendarInstance.minDate = this.minDate;
    calendarInstance.startAt = this.startAt;
    calendarInstance.rangePicker = this.rangePicker;

    calendarInstance.touchUi = this.isMobile;
  }

  private restoreFocus(): void {
    this.focusedElementBeforeOpen?.focus();
    this.focusedElementBeforeOpen = null;

    this.focusTrap?.destroy();
    this.focusTrap = null;
  }

  private trapFocus(element: HTMLElement) {
    this.focusTrap = this.focusTrapFactory.create(element);
    this.focusTrap.focusFirstTabbableElementWhenReady();
  }

  private getOverlayConfig(): OverlayConfig {
    const panelClasses = {
      'bsz-datepicker-panel': true,
      'bsz-datepicker-panel-with-ranges': !!this.presets,
      'bsz-datepicker-dialog': this.isMobile,
      'bsz-datepicker-panel-connected': !this.isMobile,
    };

    const backdropClasses = {
      'cdk-overlay-dark-backdrop': this.isMobile,
      [`${this.uniqueId}-backdrop`]: true,
    };

    return {
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      positionStrategy: this.isMobile ? this.getDialogStrategy() : this.getConnectedStrategy(),
      backdropClass: boolObjectToArray(backdropClasses),
      panelClass: boolObjectToArray(panelClasses),
    };
  }

  private getDialogStrategy(): GlobalPositionStrategy {
    return this.overlay.position().global().centerHorizontally().centerVertically();
  }

  private getConnectedStrategy(): FlexibleConnectedPositionStrategy {
    if (!this.datepickerInput) {
      throw new Error('BszDatepicker is not associated to an input.');
    }

    return this.overlay
      .position()
      .flexibleConnectedTo(this.datepickerInput._getConnectedOverlayOrigin())
      .withPositions(this.getConnectedPositions())
      .withTransformOriginOn('.bsz-datepicker-panel')
      .withFlexibleDimensions(false)
      .withViewportMargin(8)
      .withLockedPosition();
  }

  /** Gets the positions of the overlay in dropdown mode based on the current configuration. */
  private getConnectedPositions(): ConnectedPosition[] {
    const primaryX = this.xPosition === 'end' ? 'end' : 'start';
    const secondaryX = primaryX === 'start' ? 'end' : 'start';
    const primaryY = this.yPosition === 'above' ? 'bottom' : 'top';
    const secondaryY = primaryY === 'top' ? 'bottom' : 'top';

    return [
      {
        originX: primaryX,
        originY: secondaryY,
        overlayX: primaryX,
        overlayY: primaryY,
      },
      {
        originX: primaryX,
        originY: primaryY,
        overlayX: primaryX,
        overlayY: secondaryY,
      },
      {
        originX: secondaryX,
        originY: secondaryY,
        overlayX: secondaryX,
        overlayY: primaryY,
      },
      {
        originX: secondaryX,
        originY: primaryY,
        overlayX: secondaryX,
        overlayY: secondaryY,
      },
    ];
  }

  private initOverlaySubscribers(overlayRef: OverlayRef): void {
    merge(overlayRef.backdropClick(), overlayRef.keydownEvents().pipe(filter((event) => event.key === 'Escape')))
      .pipe(takeUntil(this.onClose))
      .subscribe(() => this.cancelAndClose());
  }

  private cancelAndClose() {
    this.cancel.emit();
    this.close();
  }
}

/**
 * Takes an object and creates a new array of strings
 * where its values is the truthy entries of the object
 */
function boolObjectToArray(obj: Record<string, boolean>) {
  return Object.entries(obj)
    .filter(([_, value]) => value)
    .map(([key, _]) => key);
}
