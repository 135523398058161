import {Injectable, Injector} from '@angular/core';
import {NotificationMessageStatus} from '../../models/notification.definition';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, concatMap, map, switchMap, take} from 'rxjs/operators';
import {NotificationsService} from '../../data-access/notifications.service';
import {
  deleteNotificationList,
  deleteNotificationListError,
  deleteNotificationListSuccess,
  loadNotificationListError,
  loadNotificationListSuccess,
  loadNotifications,
  loadNotificationsRequired,
  markAllNotificationsAsRead,
  markNotificationListAsRead,
  markNotificationListAsReadError,
  markNotificationListAsReadSuccess,
  markNotificationListAsUnread,
  markNotificationListAsUnreadError,
  markNotificationListAsUnreadSuccess,
  restoreNotificationList,
  restoreNotificationListError,
  restoreNotificationListSuccess,
} from './notifications.actions';
import {Store} from '@ngrx/store';
import {PartialState as NotificationsState} from './notifications.reducer';
import {selectNewNotifications} from './notifications.selectors';
import {NotificationService} from '@basuiz/web-app-common';

@Injectable()
export class NotificationsEffects {
  deleteNotificationList$ = createEffect(() =>
    this.action$.pipe(
      ofType(deleteNotificationList),
      concatMap((action) =>
        this.notificationsService
          .updateStatus({idList: action.notificationIds, targetStatus: NotificationMessageStatus.DELETED})
          .pipe(
            map(() =>
              deleteNotificationListSuccess({
                notificationList: action.notificationIds.map((notificationId) => ({
                  id: notificationId,
                  changes: {status: NotificationMessageStatus.DELETED},
                })),
              })
            ),
            catchError((error) => of(deleteNotificationListError(error)))
          )
      )
    )
  );

  loadNotifications$ = createEffect(() =>
    this.action$.pipe(
      ofType(loadNotifications),
      // AFP-44121 Load fresh notification always
      // This action is fired by alert applet in the pooling for new items
      // or, on the load of Notification applet, where we also need to have fresh data
      map((action) => loadNotificationsRequired())
    )
  );

  loadNotificationsRequired$ = createEffect(() =>
    this.action$.pipe(
      ofType(loadNotificationsRequired),
      concatMap(() =>
        this.notificationsService.getNotificationList().pipe(
          map((notifications) => {
            return loadNotificationListSuccess({notificationList: notifications});
          }),
          catchError((error) => of(loadNotificationListError(error)))
        )
      )
    )
  );

  markAllNotificationsAsRead$ = createEffect(() =>
    this.action$.pipe(
      ofType(markAllNotificationsAsRead),
      concatMap(() =>
        this.store.select(selectNewNotifications).pipe(
          take(1),
          map((notifications) => markNotificationListAsRead({notificationIds: notifications.map((n) => n.id)}))
        )
      )
    )
  );

  markNotificationListAsRead$ = createEffect(() =>
    this.action$.pipe(
      ofType(markNotificationListAsRead),
      concatMap((action) =>
        this.notificationsService
          .updateStatus({idList: action.notificationIds, targetStatus: NotificationMessageStatus.READ})
          .pipe(
            map(() =>
              markNotificationListAsReadSuccess({
                notificationList: action.notificationIds.map((notificationId) => ({
                  id: notificationId,
                  changes: {status: NotificationMessageStatus.READ},
                })),
              })
            ),
            catchError((error) => of(markNotificationListAsReadError(error)))
          )
      )
    )
  );

  markNotificationListAsUnread$ = createEffect(() =>
    this.action$.pipe(
      ofType(markNotificationListAsUnread),
      concatMap((action) =>
        this.notificationsService
          .updateStatus({idList: action.notificationIds, targetStatus: NotificationMessageStatus.UNREAD})
          .pipe(
            map(() =>
              markNotificationListAsUnreadSuccess({
                notificationList: action.notificationIds.map((notificationId) => ({
                  id: notificationId,
                  changes: {status: NotificationMessageStatus.UNREAD},
                })),
              })
            ),
            catchError((error) => of(markNotificationListAsUnreadError(error)))
          )
      )
    )
  );

  restoreNotificationList$ = createEffect(() =>
    this.action$.pipe(
      ofType(restoreNotificationList),
      concatMap((action) =>
        this.notificationsService
          .updateStatus({idList: action.notificationIds, targetStatus: NotificationMessageStatus.READ})
          .pipe(
            map(() =>
              restoreNotificationListSuccess({
                notificationList: action.notificationIds.map((notificationId) => ({
                  id: notificationId,
                  changes: {status: NotificationMessageStatus.READ},
                })),
              })
            ),
            catchError((error) => of(restoreNotificationListError(error)))
          )
      )
    )
  );

  /** Solves issue between NGRX effects and Angular APP_INITIALIZER
   * https://github.com/ngrx/platform/issues/931
   * The notification service uses the translate service that depends on the APP_INITIALIZER.
   * By using the injector to get access to the NotificationService and, therefore, TranslateService,
   * the effect class avoids forcing the premature initialization of the translate TranslateService.
   * */
  private get notificationService(): NotificationService {
    return this.injector.get(NotificationService);
  }

  constructor(
    private action$: Actions,
    private notificationsService: NotificationsService,
    private store: Store<NotificationsState>,
    private injector: Injector
  ) {}
}
