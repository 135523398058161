import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal, Portal} from '@angular/cdk/portal';
import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Inject,
  Injectable,
  Injector,
  Optional,
  StaticProvider,
  TemplateRef,
  Type,
  ViewRef,
} from '@angular/core';
import {take} from 'rxjs/operators';

import {BszNotificationOverlay} from './bsz-notification-overlay';
import {
  BSZ_NOTIFICATION_DATA,
  BSZ_NOTIFICATION_OVERLAY_DEFAULT_OPTIONS,
  BszNotificationOverlayConfig,
  BszNotificationOverlayOffset,
  BszNotificationOverlayOffsetConfig,
  BszNotificationOverlayPosition,
  BszNotificationOverlayVerticalPosition,
} from './bsz-notification-overlay.definitions';

const defaultNotificationOverlayConfiguration: BszNotificationOverlayConfig = {
  verticalPosition: 'top',
  horizontalPosition: 'right',
  politeness: 'polite',
  duration: 0, // unlimited
  type: 'default',
  maxStackLength: 0, // unlimited
  fixed: false,
  pauseOnHover: true,
};

const defaultOffsetTop = 0;
const defaultOffsetBottom = 0;

@Component({
  template: '',
})
export class NotificationOverlayRoot {}

@Injectable({
  providedIn: 'root',
})
export class BszNotificationOverlayService {
  private readonly notificationsCollection: ComponentRef<BszNotificationOverlay>[] = [];
  private readonly overlayRefs: {
    [key: string]: OverlayRef;
  } = {};

  private readonly baseConfig: BszNotificationOverlayConfig;

  /** @private */
  _portal: Portal<unknown>;

  private resizeOffsetObservers: {
    top?: ResizeObserver;
    bottom?: ResizeObserver;
  } = {};

  private offsetTop: BszNotificationOverlayOffset | undefined;
  private offsetBottom: BszNotificationOverlayOffset | undefined;

  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly overlay: Overlay,
    private readonly injector: Injector,
    private readonly appRef: ApplicationRef,
    @Optional() @Inject(BSZ_NOTIFICATION_OVERLAY_DEFAULT_OPTIONS) defaultConfig?: BszNotificationOverlayOffsetConfig
  ) {
    this.baseConfig = Object.assign({}, defaultNotificationOverlayConfiguration);
    this.offsetTop = defaultConfig?.offsetTop ?? defaultOffsetTop;
    this.offsetBottom = defaultConfig?.offsetBottom ?? defaultOffsetBottom;
  }

  /**
   * Creates a notification-overlay with a custom component for the content
   * @param component Component to be instantiated
   * @param config Configuration for the notification-overlay
   */
  openFromComponent<T>(
    component: Type<T>,
    config: BszNotificationOverlayConfig
  ): {
    notificationOverlay: BszNotificationOverlay;
    component: T;
  } {
    const configuration = Object.assign({}, this.baseConfig, config);
    const notificationOverlay = this.createNotificationOverlay(configuration);
    const componentPortal = this.createComponentOutletOfType(component, [
      {
        provide: BSZ_NOTIFICATION_DATA,
        useValue: configuration.data,
      },
      {
        provide: BszNotificationOverlay,
        useValue: notificationOverlay.instance,
      },
    ]);

    const componentRef = notificationOverlay.instance._portalOutlet.attach(componentPortal);

    return {notificationOverlay: notificationOverlay.instance, component: componentRef.instance};
  }

  /**
   * Creates a notification-overlay with from a template
   * @param templateRef
   * @param config Configuration for the notification-overlay
   */
  openFromTemplate<T>(templateRef: TemplateRef<unknown>, config: BszNotificationOverlayConfig): BszNotificationOverlay {
    const configuration = Object.assign({}, this.baseConfig, config);
    const notificationOverlay = this.createNotificationOverlay(configuration);
    const context = {
      data: configuration?.data,
      notificationOverlay: notificationOverlay.instance,
    };
    notificationOverlay.instance._setTemplatePortal(templateRef, context);

    return notificationOverlay.instance;
  }

  /**
   * Shows the notification-overlay and activates its timer for the duration
   */
  private showNotificationOverlay(notificationOverlayComponent: ComponentRef<BszNotificationOverlay>) {
    const configuration = notificationOverlayComponent.instance.getConfiguration();
    const overlayPositionRefKey = this.getOverlayPositionRefKey(configuration);
    const {stack} = this.getCollectionByPosition(overlayPositionRefKey);

    if (!this.canAddToStack(stack, configuration) || !notificationOverlayComponent) {
      return;
    }

    this.appendToOverlay(notificationOverlayComponent);
    notificationOverlayComponent.instance.activate();

    this.updateNotificationOverlayZIndex();
  }

  private updateNotificationOverlayZIndex() {
    let counter = 0;

    this.notificationsCollection
      .filter((notification) => !notification.instance.fixed)
      .forEach((notification) => {
        notification.instance._zIndex = counter;
        counter++;
      });

    // fixed appear at the beginning of the stack, so they update the z-index first because
    // they have to have higher z-index
    this.notificationsCollection
      .filter((notification) => notification.instance.fixed)
      .forEach((notification) => {
        notification.instance._zIndex = counter;
        counter++;
      });
  }

  /**
   * When the notifications are dismissed, it goes to the queue and show more from there
   */
  private setAfterDismissed(notificationOverlayComponent: ComponentRef<BszNotificationOverlay>) {
    const configuration = notificationOverlayComponent.instance.getConfiguration();

    notificationOverlayComponent.instance.afterDismissed.pipe(take(1)).subscribe(() => {
      this.showFromQueue(configuration);
    });
  }

  private showFromQueue(configuration: BszNotificationOverlayConfig) {
    const overlayPositionRefKey = this.getOverlayPositionRefKey(configuration);
    const {queue} = this.getCollectionByPosition(overlayPositionRefKey);
    const queueLength = queue.length;

    if (!queueLength) {
      return;
    }

    queue.forEach((notification: ComponentRef<BszNotificationOverlay>) => {
      this.showNotificationOverlay(notification);
    });
  }

  /**
   * Returns the lists of components that are in both, the stack and the queue, for a specific position
   */
  private getCollectionByPosition(overlayPositionRefKey: string): {
    stack: ComponentRef<BszNotificationOverlay>[];
    queue: ComponentRef<BszNotificationOverlay>[];
  } {
    const stack: ComponentRef<BszNotificationOverlay>[] = [];
    const queue: ComponentRef<BszNotificationOverlay>[] = [];
    this.notificationsCollection.forEach((notification: ComponentRef<BszNotificationOverlay>) => {
      const configuration = notification.instance.getConfiguration();
      if (overlayPositionRefKey !== this.getOverlayPositionRefKey(configuration)) {
        return;
      }
      if (notification.instance._isOpen) {
        stack.push(notification);
      } else {
        queue.push(notification);
      }
    });

    return {stack, queue};
  }

  private appendToOverlay(notificationOverlayComponent: ComponentRef<BszNotificationOverlay>) {
    const config = notificationOverlayComponent.instance.getConfiguration();
    const {verticalPosition, horizontalPosition, maximized} = config;
    const overlayRefKey = this.getOverlayPositionRefKey(config);
    let overlayRef = this.overlayRefs[overlayRefKey];

    if (!overlayRef) {
      overlayRef = this.createOverlay({verticalPosition, horizontalPosition, maximized});
      this.overlayRefs[overlayRefKey] = overlayRef;
    }

    this.updateVerticalOffset(overlayRef, verticalPosition);

    const notificationOverlay = (notificationOverlayComponent.hostView as EmbeddedViewRef<ViewRef>).rootNodes[0];
    if (verticalPosition === 'top') {
      overlayRef.overlayElement.prepend(notificationOverlay);
    } else {
      overlayRef.overlayElement.appendChild(notificationOverlay);
    }
  }

  /**
   * Dismisses all notification-overlays open. The optional parameter condition is used to
   * specify additional conditions that the ones to dismiss would have
   */
  dismissAll(filter: (notificationOverlay: BszNotificationOverlay) => boolean = () => true) {
    this.notificationsCollection
      .filter(({instance}: ComponentRef<BszNotificationOverlay>) => instance._isOpen && filter(instance))
      .forEach((notification) => {
        notification.instance.dismiss();
      });
    this.updateResizeObservers();
    this.updateNotificationOverlayZIndex();
  }

  /**
   * Dismisses all notification-overlays open of a specific type
   */
  dismissAllOfType(type: string) {
    const filter = (notificationOverlay: BszNotificationOverlay) => notificationOverlay.type === type;

    this.dismissAll(filter);
  }

  /**
   * Gets an array with the reference to all the notification-overlays open
   */
  getAll(): ComponentRef<BszNotificationOverlay>[] {
    return this.notificationsCollection;
  }

  /**
   * Gets an array with the reference to all the notification-overlays open
   */
  getAllOfType(type: string): ComponentRef<BszNotificationOverlay>[] {
    return this.notificationsCollection.filter(
      (notification: ComponentRef<BszNotificationOverlay>) => notification.instance.type === type
    );
  }

  private createNotificationOverlay(
    notificationOverlayConfig: BszNotificationOverlayConfig
  ): ComponentRef<BszNotificationOverlay> {
    const configuration = Object.assign({}, this.baseConfig, notificationOverlayConfig);
    const notificationOverlayComponent = this.createComponent(BszNotificationOverlay, [
      {
        provide: BSZ_NOTIFICATION_OVERLAY_DEFAULT_OPTIONS,
        useValue: configuration,
      },
    ]);

    notificationOverlayComponent.instance.afterDismissed.pipe(take(1)).subscribe(() => {
      const index = this.notificationsCollection.findIndex((ref) => ref === notificationOverlayComponent);
      this.notificationsCollection.splice(index, 1);
      notificationOverlayComponent.destroy();
      this.updateResizeObservers();
      this.updateNotificationOverlayZIndex();
    });

    this.notificationsCollection.push(notificationOverlayComponent);

    notificationOverlayComponent.instance.fixed = !!configuration.fixed;

    this.showNotificationOverlay(notificationOverlayComponent);

    this.setAfterDismissed(notificationOverlayComponent);

    return notificationOverlayComponent;
  }

  private createComponent<T>(component: Type<T>, providers: StaticProvider[]): ComponentRef<T> {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    const componentRef = componentFactory.create(
      Injector.create({
        parent: this.injector,
        providers: providers,
      })
    );
    this.appRef.attachView(componentRef.hostView);

    return componentRef;
  }

  private createComponentOutletOfType<T>(component: Type<T>, providers: StaticProvider[]): ComponentPortal<T> {
    return new ComponentPortal(
      component,
      null,
      Injector.create({
        parent: this.injector,
        providers: providers,
      })
    );
  }

  private createOverlay(position: BszNotificationOverlayPosition): OverlayRef {
    const overlayRef = this.overlay.create(this.getOverlayConfig(position));
    const overlayRoot = new ComponentPortal(NotificationOverlayRoot);
    overlayRef.attach(overlayRoot);

    overlayRef.overlayElement.addEventListener('mouseenter', this.mouseenterHandler(position));
    overlayRef.overlayElement.addEventListener('mouseleave', this.mouseleaveHandler(position));

    return overlayRef;
  }

  private readonly mouseenterHandler = (position: BszNotificationOverlayPosition) => {
    return () => {
      this.pauseAll(position);
    };
  };

  private readonly mouseleaveHandler = (position: BszNotificationOverlayPosition) => {
    return () => {
      this.resumeAll(position);
    };
  };

  private pauseAll(position: BszNotificationOverlayPosition) {
    this.getSiblingNotificationOverlays(position).forEach((notification) => notification.instance.pause());
  }

  private resumeAll(position: BszNotificationOverlayPosition) {
    this.getSiblingNotificationOverlays(position).forEach((notification) => notification.instance.resume());
  }

  private getSiblingNotificationOverlays(position: BszNotificationOverlayPosition) {
    return this.notificationsCollection.filter((notification) => {
      const {horizontalPosition, verticalPosition, pauseOnHover} = notification.instance.getConfiguration();

      // It should return only the ones that are in the stack, so _isOpen must be true
      const isOpen = notification.instance._isOpen;
      return (
        isOpen &&
        pauseOnHover &&
        horizontalPosition === position.horizontalPosition &&
        verticalPosition === position.verticalPosition
      );
    });
  }

  private getOverlayConfig(position: BszNotificationOverlayPosition): OverlayConfig {
    const positionStrategy = this.overlay.position().global();
    const panelClass = ['bsz-notification-overlay-panel'];

    if (position.maximized) {
      positionStrategy.centerHorizontally();
      panelClass.push('bsz-notification-overlay-panel-maximized');
    } else if (position.horizontalPosition === 'left') {
      positionStrategy.left('0');
    } else if (position.horizontalPosition === 'right') {
      positionStrategy.right('0');
    } else {
      positionStrategy.centerHorizontally();
    }
    panelClass.push('bsz-notification-overlay-panel-' + position.horizontalPosition);

    if (position.verticalPosition === 'top') {
      positionStrategy.top('0');
    } else {
      positionStrategy.bottom('0');
    }
    panelClass.push('bsz-notification-overlay-panel-' + position.verticalPosition);

    return {
      positionStrategy: positionStrategy,
      panelClass: panelClass,
      hasBackdrop: false,
    };
  }

  private updateVerticalOffset(overlayRef: OverlayRef, position: BszNotificationOverlayVerticalPosition | undefined) {
    let offset = position === 'top' ? this.offsetTop : this.offsetBottom;
    if (!offset || !position) {
      return;
    }

    switch (typeof offset) {
      case 'number':
        if (offset < 0) {
          offset = 0;
        }
        this.updateOverlayOffset(overlayRef, position, `${offset}px`);
        break;

      case 'string':
        this.updateOverlayOffset(overlayRef, position, offset);
        break;

      default:
        const {elementId, reference} = offset;
        const element = document.getElementById(elementId);
        if (!element) {
          break;
        }
        this.updateVerticalOffsetBasedOnElement(overlayRef, element, reference, position);
        this.setResizeObserver(overlayRef, element, reference, position);
        break;
    }
  }

  private updateVerticalOffsetBasedOnElement(
    overlayRef: OverlayRef,
    element: HTMLElement,
    clientRectParam: BszNotificationOverlayVerticalPosition,
    overlayPosition: BszNotificationOverlayVerticalPosition
  ) {
    let positionValue = element.getBoundingClientRect()[clientRectParam];
    if (overlayPosition === 'bottom') {
      positionValue = window.innerHeight - positionValue;
    }
    if (positionValue < 0) {
      positionValue = 0;
    }
    this.updateOverlayOffset(overlayRef, overlayPosition, `${positionValue}px`);
  }

  private setResizeObserver(
    overlayRef: OverlayRef,
    elementToObserve: HTMLElement,
    clientRectParam: BszNotificationOverlayVerticalPosition,
    overlayPosition: BszNotificationOverlayVerticalPosition
  ) {
    if (this.resizeOffsetObservers[overlayPosition]) {
      return;
    }
    this.resizeOffsetObservers[overlayPosition] = new ResizeObserver(() => {
      this.updateVerticalOffsetBasedOnElement(overlayRef, elementToObserve, clientRectParam, overlayPosition);
    });

    this.resizeOffsetObservers[overlayPosition]?.observe(elementToObserve);
  }

  /**
   * Sets the top or bottom position based in the offset defined. It uses these properties because
   * the margin is controlled by the animation and the padding would make impossible to interact
   * with elements that are in the app bellow the area covered by the padding
   */
  private updateOverlayOffset(
    overlayRef: OverlayRef,
    position: BszNotificationOverlayVerticalPosition,
    positionValue: string
  ) {
    overlayRef.overlayElement.style[position] = positionValue;
  }

  private detachOverlays() {
    for (const key in this.overlayRefs) {
      this.overlayRefs[key].detach();
      delete this.overlayRefs[key];
    }
  }

  private disconnectResizeObservers() {
    this.resizeOffsetObservers.top?.disconnect();
    this.resizeOffsetObservers.bottom?.disconnect();
    this.resizeOffsetObservers = {};
  }

  private updateResizeObservers() {
    if (!this.notificationsCollection.length) {
      this.disconnectResizeObservers();
      this.detachOverlays();
    }
  }

  /**
   * Check whether it is possible to add it to the stack by checking the stack size
   * and its restrictions
   */
  private canAddToStack(
    stack: ComponentRef<BszNotificationOverlay>[],
    nextOverlayConfig: BszNotificationOverlayConfig
  ): boolean {
    const stackOfSameType = stack.filter(
      (notification: ComponentRef<BszNotificationOverlay>) => notification.instance.type === nextOverlayConfig.type
    );

    const maxLengthReached =
      nextOverlayConfig.maxStackLength && stackOfSameType.length >= nextOverlayConfig.maxStackLength;

    return !maxLengthReached && this.canIncreaseStackHeight(stack, nextOverlayConfig);
  }

  /**
   * Check whether it is possible to add it to the stack by checking the stack height and the available space.
   * Since the notification overlay is still not added, its height is based in an estimation (average obtained from
   * the stack height and the amount of notification overlays already shown)
   */
  private canIncreaseStackHeight(
    stack: ComponentRef<BszNotificationOverlay>[],
    nextOverlayConfig: BszNotificationOverlayConfig
  ): boolean {
    // if the stack is empty, allows adding
    if (!stack.length) {
      return true;
    }

    const overlayPositionRefKey = this.getOverlayPositionRefKey(nextOverlayConfig);
    const {verticalPosition} = nextOverlayConfig;
    const overlayRef = this.overlayRefs[overlayPositionRefKey];
    const overlayBoundingClientRect = overlayRef?.overlayElement.getBoundingClientRect();

    if (overlayRef && overlayBoundingClientRect) {
      const {top, bottom, height} = overlayBoundingClientRect;

      // estimate the average height that the notification overlays have
      const averageHeight = height / stack.length;

      // max height limited to the window height leaving space for at least two notifications at the bottom.
      // It will happen that usually the real height is a bit bigger (all is based in estimations), so the
      // final space not occupied by the stack will be of a bit less than those two additional notifications
      const estimatedAvailableHeight = window.innerHeight - 2 * averageHeight;

      // it uses the top/bottom (instead height) because they include the offsets
      const currentHeight = verticalPosition === 'top' ? bottom : window.innerHeight - top;

      return estimatedAvailableHeight > currentHeight;
    }

    return true;
  }

  private getOverlayPositionRefKey(overlayConfig: BszNotificationOverlayConfig) {
    const {verticalPosition, horizontalPosition, maximized} = overlayConfig;
    let overlayRefKey = `${verticalPosition}${horizontalPosition}`;
    if (maximized) {
      overlayRefKey += 'maximized';
    }

    return overlayRefKey;
  }
}
