import {InjectionToken} from '@angular/core';

/** Any source than can provide a locale-id as alternative to the AFP user settings.
 * If the source returns undefined, the default locale-id from the locale settings will be used */
export type CustomLocaleSource = () => Promise<string | undefined>;

/** Provider for a custom locale source. This source is read-only,
 * i.e. web-app can read from it what is the locale to be used,
 * but web-app cannot update the locale id in the source.
 * Therefore, you cannot use the user settings or locale selector applets to update the user's locale */
export const CUSTOM_LOCALE_SOURCE: InjectionToken<CustomLocaleSource | undefined> = new InjectionToken(
  'bsz.web-app.common.locale.custom-locale-source',
  {
    /* Undefined, i.e. no method is provided to retrieve a custom source, means the default web-app source will be used */
    factory: () => undefined,
  }
);
