import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {BszI18nModule} from '@basuiz/i18n';

import {BszPercentage} from './bsz-percentage';

@NgModule({
  declarations: [BszPercentage],
  imports: [CommonModule, BszI18nModule, ReactiveFormsModule],
  exports: [BszPercentage],
})
export class BszPercentageModule {}
