import {ChangeDetectionStrategy, Component, EventEmitter, Inject, Output} from '@angular/core';
import {BszAttentionItemCounterType, TranslatedText} from '@basuiz/web-app-applet-api';
import {Observable} from 'rxjs';
import {AttentionItemCounter, WebAppAlertFacade} from '@basuiz/web-app-alert';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslateService} from '@ngx-translate/core';
import {AlertListAppletConfig, ɵALERT_LIST_BANKLET_CONFIG} from '@basuiz/web-app-alert-list-applet/config';

@Component({
  selector: 'bsz-counter-list',
  templateUrl: './counter-list.component.html',
  styleUrls: ['./counter-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CounterListComponent {
  @Output() counterClicked: EventEmitter<BszAttentionItemCounterType> = new EventEmitter();

  attentionItemCounters$: Observable<AttentionItemCounter[]> =
    this.webAppAlertFacade.attentionItemCountersWithPositiveValue$;

  readonly bszTestIdPrefix = 'web-app-alert-list-applet.counter-list';

  readonly attentionItemCounterName: Record<BszAttentionItemCounterType, TranslatedText> = {
    COLLABORATION_ISSUES_UNREAD: asTranslationKey(
      'web-app-alert-list-applet.counter-type.collaboration.unread-issues.with-counter'
    ),
    NOTIFICATIONS_UNREAD: asTranslationKey('web-app-alert-list-applet.counter-type.notifications.unread.with-counter'),
  };

  constructor(
    @Inject(ɵALERT_LIST_BANKLET_CONFIG) public config: AlertListAppletConfig,
    private webAppAlertFacade: WebAppAlertFacade,
    private translateService: TranslateService
  ) {}

  getName(attentionItemCounter: AttentionItemCounter): string {
    return this.translateService.instant(this.attentionItemCounterName[attentionItemCounter.counterType], {
      counterValue: attentionItemCounter.value,
    });
  }

  onCounterClicked(attentionItemCounter: AttentionItemCounter) {
    this.counterClicked.emit(attentionItemCounter.counterType);
  }
}
