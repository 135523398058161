import {Component, TemplateRef, ViewChild} from '@angular/core';
import {ErrorContext, LoadingContext, NoDataContext} from '../data-state.context';

// noinspection AngularMissingOrInvalidDeclarationInModule -> declared in module in spec
@Component({templateUrl: './data-state-unavailable.component.html'})
export class DataStateUnavailableComponent {
  @ViewChild('loading') loadingTemplateRef: TemplateRef<LoadingContext>;
  @ViewChild('noData') noDataTemplateRef: TemplateRef<NoDataContext>;
  @ViewChild('error') errorTemplateRef: TemplateRef<ErrorContext>;
}
