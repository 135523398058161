import {AfterViewInit, Directive, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {AsyncSubject, BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {first, map, takeUntil, tap} from 'rxjs/operators';

import {BszTableTreeRow, isBszTableTreeRow, isBszTableTreeRowData} from '../bsz-table/bsz-table.definitions';
import {getOriginalRowFromTableRow} from '../bsz-table/bsz-table-tree-data-source';
import {BszTreeRowDefinition} from '../shared';
import {SelectionModel, TreeSelectionModel} from './bsz-selectable.definitions';
import {CheckboxItem} from './bsz-selectable-checkbox';
import {
  AllCheckboxAdapter,
  BszSelectableCheckboxAdapter,
  GroupCheckboxAdapter,
  RowCheckboxAdapter,
} from './bsz-selectable-checkbox-adapter';
import {BszTreeSelectionModel} from './bsz-tree-selection-model';

const LOG_PREFIX = '[bsz-selectable]';

@Directive({
  selector: '[bszSelectable]',
})
export class BszSelectable<T, G> implements AfterViewInit, OnDestroy {
  /** Changing selected items. */
  @Input()
  set selected(selectedItems: T[] | null) {
    this.selectedItemsUpdate.next(selectedItems || []);
  }

  private readonly selectedItemsUpdate = new BehaviorSubject<T[]>([]);

  /** Event emitted when the selected items change. */
  @Output()
  selectedChange = new EventEmitter<T[]>();

  /** @private Not for direct use. to track the selected items use selectedChange event instead */
  readonly _selectedItems = new BehaviorSubject<(T | BszTableTreeRow)[]>([]);

  /** This observable fires a value and finishes after the connect() method is called. */
  private readonly connected = new AsyncSubject<SelectableSelectionModel<T>>();

  private readonly destroy = new AsyncSubject<void>();

  /** To track the selectable status (the selection could be disabled for some rows) */
  private readonly selectableItems = new Map<CheckboxItem<T, G>, boolean>();

  private isTreeTable = false;
  private readonly afterViewInit = new AsyncSubject<void>();

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  ngAfterViewInit() {
    this.afterViewInit.next();
    this.afterViewInit.complete();
  }
  /**
   * Connect the BszSelectable.
   */
  connect(dataChange: Observable<SelectableData<T, G>>, selectionModel: SelectableSelectionModel<T>) {
    // This is required because the connection and events should happen once the view is ready, so if it is
    // selectable, bsz-selectable is already available so the selected items are available. Otherwise, it could
    // happen that the event is emitted empty (like without items selected) although there are pre-selected ones
    this.afterViewInit.subscribe(() => {
      this.updateSelectionModelOnDataChange(selectionModel, dataChange);
      this.updateSelectionModelOnSelectedItemsUpdate(selectionModel, dataChange);
      this.emitSelectedChangeOnSelectionModelSelectionChange(selectionModel);

      this.connected.next(selectionModel);
      this.connected.complete();
    });
  }

  /**
   * Get the checkbox adapter for the specified item.
   *
   * Note: This returns an observable, since we have to wait until the bszSelectable is connected to know what
   * type of checkbox adapter to return.
   *
   * @param item The item of a checkbox we want to connect with.
   */
  getCheckboxAdapter(item: CheckboxItem<T, G>): Observable<BszSelectableCheckboxAdapter> {
    return this.connected.pipe(
      map((selectionModel) => {
        if (!item) {
          return new AllCheckboxAdapter(selectionModel);
        } else if (this.isGroup(selectionModel, item)) {
          return new GroupCheckboxAdapter(selectionModel as TreeSelectionModel<any, G | BszTableTreeRow>, item);
        } else {
          return new RowCheckboxAdapter(selectionModel, item);
        }
      })
    );
  }

  /** When new data set comes, we need to update the selection model's data. */
  private updateSelectionModelOnDataChange(
    selectionModel: SelectableSelectionModel<T>,
    dataChange: Observable<SelectableData<T, G>>
  ) {
    dataChange.pipe(takeUntil(this.destroy)).subscribe((data) => {
      this.isTreeTable = isBszTableTreeRowData(data);
      selectionModel.setData(data);
    });
  }

  /** When the selected items are updated (from the @Input), we need to let the selection model know. */
  private updateSelectionModelOnSelectedItemsUpdate(
    selectionModel: SelectableSelectionModel<T>,
    dataChange: Observable<SelectableData<T, G>>
  ) {
    combineLatest([
      this.selectedItemsUpdate,
      // Wait for the first data source before setting the selected items, since without data there's nothing to select.
      dataChange.pipe(first()),
    ])
      .pipe(
        takeUntil(this.destroy),
        // The array of selected items specified the original items (T), but the BszTableTreeSelectionModel
        // uses BszTreeTableRow, so we have to get the table row based on the original row.
        map(([selectedItems, data]) => selectedItems.map((item) => this.getTableRowFromOriginalRow(item, data)))
      )
      .subscribe((selectedItems) => selectionModel.setSelectedItems(selectedItems));
  }

  /** When the selection state of the selection model changes, we need to emit a new selectedChange @Output event. */
  private emitSelectedChangeOnSelectionModelSelectionChange(selectionModel: SelectableSelectionModel<T>) {
    selectionModel.selectionChange
      .pipe(
        takeUntil(this.destroy),
        tap(({selectedItems}) => this._selectedItems.next(selectedItems)),
        map(({selectedItems}) => selectedItems.map((item) => getOriginalRowFromTableRow(item))),
        map((selectedItems) => this.getSelectedItemsData(selectedItems))
      )
      // Connect the selection change from selectionModel to the "selectedChange" output. Looks like we have to
      // subscribe just to emit instead of passing the observable directly: https://github.com/angular/angular/issues/23435
      .subscribe((selectedItems) => {
        this.selectedChange.emit(selectedItems);
      });
  }

  /** Check whether the passed item is a group. */
  private isGroup(selectionModel: SelectableSelectionModel<T>, obj: T | G | BszTableTreeRow): boolean {
    if (isBszTableTreeRow(obj)) {
      return obj._isGroupHeader;
    }

    if (selectionModel instanceof BszTreeSelectionModel) {
      return selectionModel.getGroupRows().includes(obj);
    }

    return false;
  }

  /** Get the table row defined by the specified original data row. */
  private getTableRowFromOriginalRow(originalRow: T, data: SelectableData<T, G>): T | BszTableTreeRow {
    if (isBszTableTreeRowData(data)) {
      const tableRow = data.find((row) => row._originalRow === originalRow);
      if (!tableRow) {
        console.error(originalRow);
        throw new Error(`${LOG_PREFIX} Unknown row`);
      }
      return tableRow;
    } else {
      return originalRow;
    }
  }

  private getSelectedItemsData(selectedItems: T[]) {
    // The itemMap that selection models have include all rows in the table, and assumes all rows are selectable
    // and none of them have the checkbox disabled. When selection changes (specially when using select-all),
    // the parameter selectedItems could include them. To prevent it, filtering them to emit only the ones that
    // are selectable (row with checkbox not disabled) is required.
    const selectable = this.getSelectableItemsData().map((item) => {
      if (this.isTreeTable) {
        // @ts-ignore
        return item._originalRow;
      }
      return item;
    });

    return selectedItems.filter((item: CheckboxItem<T, G>) => selectable.includes(item));
  }

  updateSelectableItems(checkboxItem: CheckboxItem<T, G>, disabled: boolean) {
    if (this.selectableItems.has(checkboxItem)) {
      this.selectableItems.delete(checkboxItem);
    }
    this.selectableItems.set(checkboxItem, disabled);
  }

  getSelectableItemsData(): CheckboxItem<T, G>[] {
    return [...this.selectableItems.entries()].filter(([item, disabled]) => !disabled).map(([item]) => item);
  }
}

type SelectableData<T, G> = T[] | BszTableTreeRow[] | BszTreeRowDefinition<T, G>[];
type SelectableSelectionModel<T> = SelectionModel<T | BszTableTreeRow>;
