import {ErrorResponse, Request, RequestIdentifier, SuccessResponse} from './model/native-app-interface';

/**
 * This is a class that takes care of mapping requests to / responses from the native app.
 *
 * It provides promise-based interface and hides the requestId (and status) of the communication.
 */
export class NativeAppPromisifiedRequest<T extends Request, U extends SuccessResponse> {
  private waitingPromises = new Map<RequestIdentifier, Deferred<U, ErrorResponse>>();
  private requestCount = 0;

  /**
   * Constructor. Pass the request function to wrap.
   */
  constructor(private requestFn: (request: T) => void) {}

  /**
   * Call the request function one-way (do not expect a response).
   * The returned promise is immediately resolved.
   *
   * @param request Request to pass to the function.
   */
  async call(request: PromisifiedRequest<T>): Promise<void> {
    return this.doCall(request, false);
  }

  /**
   * Call the request function two-way (wait for a response).
   * The returned promise will be resolved to the response of the corresponding response function.
   *
   * @param request Request to pass to the function.
   */
  async callAndWaitForResponse(request: PromisifiedRequest<T>): Promise<PromisifiedSuccessResponse<U>> {
    return this.doCall(request, true);
  }

  /**
   * This is the response function that should be called each time a response is received.
   */
  get responseFn() {
    return (response: U | ErrorResponse) => {
      const requestId = response.requestId;
      const promise = this.waitingPromises.get(requestId);
      if (!promise) {
        throw new Error(`Received response for an unknown request id ${response.requestId}`);
      }

      this.waitingPromises.delete(requestId);

      if (response.status === 'success') {
        promise.resolve(response);
      } else {
        promise.reject(response);
      }
    };
  }

  private async doCall(request: PromisifiedRequest<T>, waitForResponse: false): Promise<void>;
  private async doCall(request: PromisifiedRequest<T>, waitForResponse: true): Promise<PromisifiedSuccessResponse<U>>;
  private async doCall(
    request: PromisifiedRequest<T>,
    waitForResponse: boolean
  ): Promise<PromisifiedSuccessResponse<U> | void> {
    const requestId = this.getNewRequestId();

    // call the request function
    this.requestFn({
      requestId,
      ...request,
    } as T); // see https://github.com/microsoft/TypeScript/issues/35858 for why we need the type assertion

    if (!waitForResponse) {
      return;
    }

    return new Promise((resolve, reject) => {
      this.waitingPromises.set(requestId, {
        resolve: (response) => {
          resolve(this.cleanSuccessResponse(response));
        },
        reject: (response) => {
          reject(new Error(response.error));
        },
      });
    });
  }

  private cleanSuccessResponse(response: U): PromisifiedSuccessResponse<U> {
    const {requestId, status, ...cleanedResponse} = response;

    return cleanedResponse;
  }

  private getNewRequestId(): RequestIdentifier {
    return ++this.requestCount;
  }
}

type PromisifiedRequest<T extends Request> = Omit<T, 'requestId'>;
type PromisifiedSuccessResponse<T extends SuccessResponse> = Omit<T, 'requestId' | 'status'>;

interface Deferred<T, U> {
  resolve: (response: T) => void;
  reject: (error: U) => void;
}
