import {DataStateAvailable, DataStateError, DataStateLoading, DataStateNoData} from './data-state.definition';

export function getDataStateAvailable<T>(data: T): DataStateAvailable<T> {
  return {
    state: 'available',
    data,
  };
}

export type DataStateLoadingOptions = Omit<DataStateLoading, 'state'>;

export function getDataStateLoading(options?: DataStateLoadingOptions): DataStateLoading {
  const DEFAULT_RESERVED_HEIGHT_PX = 20;
  const reservedHeightPx = options?.reservedHeightPx ?? DEFAULT_RESERVED_HEIGHT_PX;
  const message = options?.message;

  return {
    state: 'loading',
    reservedHeightPx,
    message,
  };
}

export type DataStateNoDataOptions = Omit<DataStateNoData, 'state'>;

export function getDataStateNoData(options?: DataStateNoDataOptions): DataStateNoData {
  return {
    state: 'no-data',
    message: options?.message,
    webAppCustomIcon: options?.webAppCustomIcon,
  };
}

export function getDataStateError(): DataStateError {
  return {
    state: 'error',
  };
}
