import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {BszSwitchButtonOption} from './bsz-switch-button-option';

const LOG_PREFIX = '[bsz-switch-button]';

@Component({
  selector: 'bsz-switch-button',
  templateUrl: './bsz-switch-button.html',
  styleUrls: ['./bsz-switch-button.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BszSwitchButton<T = unknown> implements AfterContentInit {
  @Input()
  set disabled(disabled: BooleanInput) {
    this._disabled = coerceBooleanProperty(disabled);
  }

  get disabled(): BooleanInput {
    return this._disabled;
  }

  @Input()
  set value(value: T) {
    this.setValue(value);
  }

  get value(): T {
    return this._value;
  }

  @Output() change = new EventEmitter<T>();

  // set two way binding to the "value" input
  @Output() valueChange = new EventEmitter<T>();

  @ContentChildren(forwardRef(() => BszSwitchButtonOption)) options: QueryList<BszSwitchButtonOption<T>>;

  /** @private */
  isValidComponent = true;
  /** @private */
  ariaLabel = '';
  /** @private */
  _value: T;
  private activeOptionIndex: number;
  private readonly optionValues: Array<T> = [];
  private _disabled = false;
  private _inputValue: T;

  constructor(private readonly translate: TranslateService) {}

  ngAfterContentInit(): void {
    const {isValid, errorMessage} = this.validateComponent();
    if (!isValid) {
      this.isValidComponent = false;
      throw new Error(`${LOG_PREFIX} ${errorMessage}`);
    }
    this.initialiseValues();
    this.updateOptionsStatus();
    this.updateAriaLabel();
  }

  /** @private */
  switchOption() {
    this.setActiveOption(this.activeOptionIndex === 0 ? 1 : 0);
  }

  private setValue(value: T) {
    this._inputValue = value;
    const optionIndex = this.optionValues.indexOf(value);
    if (optionIndex === -1 || value === this._value) {
      return;
    }

    this.setActiveOption(optionIndex);
  }

  private setActiveOption(optionIndex: number) {
    this.activeOptionIndex = optionIndex;
    this._value = this.optionValues[optionIndex];
    this.change.emit(this._value);
    this.valueChange.emit(this._value);
    this.updateOptionsStatus();
    this.updateAriaLabel();
  }

  private validateComponent(): {isValid: boolean; errorMessage?: string} {
    if (this.options.length < 2) {
      return {
        isValid: false,
        errorMessage: `BszSwitchButton requires two "bsz-switch-button-option" elements`,
      };
    }
    if (this.options.length > 2) {
      return {
        isValid: false,
        errorMessage: `BszSwitchButton does not allow more than two "bsz-switch-button-option" elements`,
      };
    }
    const optionsLabel = this.getLabels();
    if (optionsLabel.some((label) => label === undefined)) {
      return {
        isValid: false,
        errorMessage: `BszSwitchButtonOption requires the use of the input property "text" or "aria-label"`,
      };
    }
    if (this.options.first.value === this.options.last.value) {
      return {
        isValid: false,
        errorMessage: `BszSwitchButton requires two different option values`,
      };
    }
    return {isValid: true};
  }

  private initialiseValues(): void {
    let activeOptionIndex = 0;

    this.options.forEach((option, index) => {
      const optionValue = option.value;
      this.optionValues.push(optionValue);
      if (option.selected) {
        activeOptionIndex = index;
      }
    });

    if (this._inputValue !== undefined && this.optionValues.indexOf(this._inputValue) !== -1) {
      this.setValue(this._inputValue);
      return;
    }

    this.setValue(this.optionValues[activeOptionIndex]);
  }

  private updateOptionsStatus(): void {
    if (!this.options) {
      return;
    }
    this.options.forEach((option) => {
      option.selected = option.value === this._value;
    });
  }

  private updateAriaLabel(): void {
    const labels = this.getLabels();
    const [firstOptionLabel, secondOptionLabel] = labels;
    const selectedOptionLabel = this.activeOptionIndex === 0 ? firstOptionLabel : secondOptionLabel;
    this.ariaLabel = this.translate.instant('ui-elements.bsz-switch-button.accessibility.aria-label', {
      firstOptionLabel,
      secondOptionLabel,
      selectedOptionLabel,
    });
  }

  private getLabels(): (string | undefined)[] {
    return this.options.map((option) => option.getLabel());
  }
}
