<mat-form-field bszTestId="web-app-common.bsz-country.mat-form-field" class="bsz-width-100pct">
  <mat-label>{{ 'web-app-common.country.edit.country' | translate }}</mat-label>
  <mat-select
    [(value)]="selectedCountry"
    (selectionChange)="selectionChange($event)"
    [required]="required"
    [formControl]="control"
    bszTestId="web-app-common.bsz-country.mat-select"
  >
    <bsz-select-search (searchChange)="onSearchCountry($event)"></bsz-select-search>
    <mat-divider></mat-divider>
    <mat-option
      *ngFor="let country of filteredCountryList$ | async"
      [value]="country"
      bszTestId="web-app-common.bsz-country.mat-option"
    >
      {{ country.name }}
    </mat-option>
  </mat-select>
  <mat-error>{{ 'web-app-common.country.edit.required' | translate }}</mat-error>
</mat-form-field>
