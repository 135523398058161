import {Directive, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mat-select',
})
export class BszSelectSearchFilterMatSelectDirective implements OnDestroy {
  searchChange = new Subject<string>();
  optionsCount = new Subject<number>();

  ngOnDestroy(): void {
    this.searchChange.complete();
    this.optionsCount.complete();
  }
}
