import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {BszI18nModule} from '@basuiz/i18n';

import {BszCarousel} from './bsz-carousel';
import {BszCarouselFocusControl} from './bsz-carousel-focus-control';
import {BszCarouselItem} from './bsz-carousel-item';
import {BszCarouselSwipe} from './bsz-carousel-swipe';

@NgModule({
  declarations: [BszCarousel, BszCarouselItem, BszCarouselSwipe, BszCarouselFocusControl],
  imports: [CommonModule, BszI18nModule, MatIconModule, MatButtonModule],
  exports: [BszCarousel, BszCarouselItem],
})
export class BszCarouselModule {}
