import {BszObjectId} from '@basuiz/web-app-applet-api';
import {NavigationIntent} from '../../navigation/classes/navigation-intent';

export type CreateNotificationsSubscriptionIntentPayload = PainFileSubscription;

export interface PainFileSubscription {
  painFileId: BszObjectId;
}

export class CreateNotificationsSubscriptionIntent extends NavigationIntent {
  constructor(public payload?: CreateNotificationsSubscriptionIntentPayload) {
    super();
  }
}
