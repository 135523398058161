import {isObject} from 'lodash';

export function removeNullOrEmptyFields<T>(obj: T) {
  if (isObject(obj)) {
    Object.keys(obj).forEach((k) => {
      if (!obj[k as keyof T] && obj[k as keyof T] !== undefined) {
        delete obj[k as keyof T];
      }
    });
  }
}

export function changeToNullAllEmptyFields<T>(obj: T) {
  if (isObject(obj)) {
    Object.keys(obj).forEach((k: string) => {
      if (!obj[k as keyof T] && obj[k as keyof T] !== undefined) {
        obj[k as keyof T] = null as any;
      }
    });
  }
}
