import {APP_INITIALIZER, forwardRef, Inject, LOCALE_ID, ModuleWithProviders, NgModule} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {TranslateModule} from '@ngx-translate/core';

import {BszI18nService} from './i18n.service';
import {BszI18nLocaleStorageService} from './i18n-locale-storage.service';
import {BszI18nPhraseEditorModule} from './phrase-editor/phrase-editor.module';

const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'll',
  },
  display: {
    dateInput: 'll',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

/**
 * Factory to get the current locale id dynamically (set in the BszI18nService.initialize())
 * for the LOCALE_ID token
 */
export function getLocaleId(i18nService: BszI18nService): string {
  return i18nService.localeId;
}

/**
 * Type of factory used as loader to initialize the library
 */
export type BszI18nInitializationFactory = (...args: any[]) => () => Promise<void>;

/**
 * Interface defining the configuration options of the module forRoot
 */
export interface BszI18nConfiguration {
  loader: {
    useFactory: BszI18nInitializationFactory;
    deps?: any[];
  };
  usePhraseEditor?: boolean;
}

@NgModule({
  imports: [TranslateModule],
  exports: [TranslateModule],
})
export class BszI18nModule {
  static forRoot(config: BszI18nConfiguration): ModuleWithProviders<BszI18nModule> {
    // Return a separate module, which imports the configured ngx-translate module (using forRoot).
    // The BszI18nRootModule returned here is to be used only in the root app, while the BszI18nModule
    // module can be imported in other modules / libraries.
    const moduleToExport = config.usePhraseEditor ? BszI18nPhraseEditorModule : BszI18nRootModule;

    return {
      providers: [
        /**
         * The Basuiz i18n service, initializing Ngx Translate and moment for the app
         */
        BszI18nService,
        /**
         * The Basuiz i18n Locale service, providing standard way for storing and retrieving locale
         */
        BszI18nLocaleStorageService,
        /**
         * Angular LOCALE_ID, used by the following core pipes: DatePipe, CurrencyPipe, DecimalPipe, PercentPipe
         */
        {provide: LOCALE_ID, useClass: DynamicLocaleId, deps: [BszI18nService]},
        /**
         * Angular Material configuration
         */
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        /**
         * Angular Material configuration
         */
        {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS},
        /**
         * Initialize our i18n service during app bootstrap using the provided loader factory
         */
        {
          provide: APP_INITIALIZER,
          useFactory: config.loader.useFactory,
          multi: true,
          deps: config.loader.deps,
        },
      ],
      ngModule: moduleToExport,
    };
  }
}

/**
 * Default module
 */
@NgModule({
  imports: [TranslateModule.forRoot({})],
  exports: [TranslateModule],
})
export class BszI18nRootModule {
  // we use forwardRef to wait for LOCALE_ID to be created, then we check if the type is what we are providing,
  // if it is something else it means it was created outside of the library
  constructor(@Inject(forwardRef(() => LOCALE_ID)) localeId: any) {
    if (!(localeId instanceof DynamicLocaleId)) {
      console.warn(
        '[BszI18nModule] The application is providing a custom "LOCALE_ID" instead of relying on BszI18nService. ' +
          'This might break Angular localization features (e.g. native pipes) or create inconsistencies with Basuiz localization features (e.g. ui-elements pipes).'
      );
    }
  }
}

/**
 * Normally, LOCALE_ID provider can only be string, and once defined it can never change. This means that if it is
 * injected anywhere before our library sets it correctly, it will be set to 'undefined' (this can happen when
 * HTTP interceptors are used, or in general when it is injected in a piece of code that runs before APP_INITIALIZER).
 *
 * By providing it as a class, it will automatically get converted to string and fire the toString() method every time
 * that it is injected, so its value can change dynamically.
 *
 * See: https://github.com/angular/angular/issues/15039
 */
export class DynamicLocaleId extends String {
  constructor(protected service: BszI18nService) {
    super('');
  }

  override toString() {
    return this.service.localeId;
  }
}
