
<div class="bsz-file-upload-header">
  <!-- Field Label -->
  <label [for]="uniqueId" [ngClass]="{'mat-error': formSubmittedOrTouchedAndHasErrors()}" class="bsz-file-upload-label">
    <ng-content select="mat-label"></ng-content>
    <span *ngIf="_required">*</span>
  </label>
  <!-- Field Label -->

  <!-- Reset button -->
  <button
    [hidden]="!multiple || !fileList?.length"
    (click)="$event.preventDefault(); reset()"
    [color]="formSubmittedOrTouchedAndHasErrors() ? 'warn' : 'primary'"
    [attr.aria-label]="'ui-elements.bsz-file-upload.accessibility.clear-all-button.aria-label' | translate:{count: fileList?.length ?? 0}"
    class="bsz-file-upload-reset-button"
    mat-button>
    <mat-icon>close</mat-icon>
    {{ 'ui-elements.bsz-file-upload.clear-all-button' | translate }}
  </button>
  <!-- Reset button -->
</div>


<!-- Hint text -->
<div class="bsz-file-upload-hint bsz-caption">
  <ng-content select="mat-hint"></ng-content>
</div>

<!-- Drag and Drop Zone -->
<div
[class.bsz-file-upload-drop-zone-is-hidden]="!_multiple && fileList?.length! > 0"
[class.bsz-file-upload-is-focused]="focused"
[class.bsz-file-upload-is-disabled]="_disabled"
[matRippleDisabled]="_disableRipple"
class="bsz-file-upload-drop-zone"
matRipple
dragAndDrop>

  <input
  (focus)="onFocus()"
  (blur)="onBlur()"
  [id]="uniqueId"
  [attr.accept]="_accept"
  [multiple]="_multiple"
  [required]="_required"
  [disabled]="_disabled"
  type="file"
  class="bsz-file-upload-input"
  #fileInputElement>

  <span class="bsz-file-upload-drop-zone-text">{{ 'ui-elements.bsz-file-upload.drop-zone-text' | translate:{multiple: multiple} }}</span>
  <span class="bsz-file-upload-drop-zone-fileover-text">{{ 'ui-elements.bsz-file-upload.drop-zone-fileover-text' | translate }}</span>
</div>
<!-- Drag and Drop Zone -->

<!-- Selected file for single file upload -->
<bsz-file-upload-selected-file
  *ngIf="!_multiple && fileList && fileList.length! > 0"
  (delete)="_deleteFileFromFileList($event)"
  [file]="fileList[0]"
  [errors]="ngControl?.errors">
</bsz-file-upload-selected-file>
<!-- Selected file for single file upload -->

<!-- Error text -->
<div class="bsz-file-upload-error-message">
  <div *ngIf="formSubmittedOrTouchedAndHasErrors()" [@transitionMessages]="'enter'">
    <ng-content select="mat-error"></ng-content>
  </div>
</div>
<!-- Error text -->

<!-- Selected files for multiple file upload -->
<ul *ngIf="_multiple" [attr.aria-label]="'ui-elements.bsz-file-upload.accessibility.files-selected' | translate" class="bsz-file-upload-file-list">
  <li class="bsz-file-upload-file-list-item" *ngFor="let file of getFileListAsArray()">
    <bsz-file-upload-selected-file [file]="file" [errors]="ngControl?.errors" (delete)="_deleteFileFromFileList($event)"></bsz-file-upload-selected-file>
  </li>
</ul>
<!-- Selected files for multiple file upload -->
