import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AfpAuthDialogResult,
  AuthDialogVerificationRequest,
  PushOperation,
} from '../../../models/auth-dialog.definitions';
import {BehaviorSubject, EMPTY, interval, Observable} from 'rxjs';
import {AfpAuthVerify, AfpAuthVerifyResponse} from '../../../models/auth.definitions';
import {CommonConfig, ɵCOMMON_CONFIG} from '@basuiz/web-app-common/config';
import {NotificationService} from '../../../../ui-notification/index';
import {AuthVerificationService} from '../../../data-access/auth-verification.service';
import {catchError, exhaustMap, take, takeWhile} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {AfpRestResponse, isAfpRestResponse} from '../../../../afp-rest/index';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'bsz-push-verification',
  templateUrl: './push-verification.component.html',
  styleUrls: ['./push-verification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PushVerificationComponent implements OnInit, OnDestroy {
  @Input()
  verificationRequest: AuthDialogVerificationRequest<PushOperation>;

  @Output()
  verificationResult: EventEmitter<AfpAuthDialogResult> = new EventEmitter<AfpAuthDialogResult>();

  readonly inProgress = new BehaviorSubject<boolean>(false);

  readonly isPolling = new BehaviorSubject<boolean>(false);

  private sessionCancellationRequired: boolean = false;

  constructor(
    @Inject(ɵCOMMON_CONFIG) private config: CommonConfig,
    private readonly notificationService: NotificationService,
    private authVerificationService: AuthVerificationService
  ) {}

  ngOnInit(): void {
    this.sessionCancellationRequired = true;
    this.startPolling();
  }

  ngOnDestroy(): void {
    this.stopPolling();
    if (this.sessionCancellationRequired) {
      this.authVerificationService
        .cancelSigningSession(this.verificationRequest.transactionSigningObject)
        .pipe(take(1))
        .toPromise()
        .catch(() => {});
    }
  }

  cancel() {
    this.stopPolling();
    this.verificationResult.emit('CANCEL');
  }

  private startPolling() {
    const verificationObject: AfpAuthVerify = {
      ...this.verificationRequest.transactionSigningObject,
      verificationAction: 'PUSH_AUTH_CHECK',
    };
    this.isPolling.next(true);
    interval(this.config.auth.push.pollingInterval)
      .pipe(
        takeWhile(() => this.isPolling.getValue()),
        exhaustMap(() =>
          this.callVerifyTransactionService(verificationObject).pipe(
            catchError((error: HttpErrorResponse) => {
              this.handleErrorResponse(error);
              return EMPTY;
            })
          )
        )
      )
      .subscribe((result) => this.handleSuccessResponse(result));
  }

  private callVerifyTransactionService(
    verificationObject: AfpAuthVerify
  ): Observable<AfpRestResponse<AfpAuthVerifyResponse>> {
    this.inProgress.next(true);
    return this.authVerificationService.verifyTransaction(verificationObject);
  }

  private stopPolling() {
    this.isPolling.next(false);
    this.isPolling.complete();
  }

  private handleSuccessResponse(response: AfpRestResponse<AfpAuthVerifyResponse>): void {
    if (response.payload.transactionSigningVerification === 'PENDING') {
      this.inProgress.next(false);
      return;
    } else if (response.payload.transactionSigningVerification === 'CANCELLED_BY_USER') {
      this.sessionCancellationRequired = false;
      this.cancel();
    } else if (response.payload.transactionSigningVerification === 'CORRECT') {
      this.sessionCancellationRequired = false;
      this.verificationResult.emit('SUCCESS');
    } else if (response.payload.transactionSigningVerification === 'WRONG') {
      this.sessionCancellationRequired = false;
      this.verificationResult.emit('FAILURE');
    }
  }

  private handleErrorResponse(response: HttpErrorResponse): void {
    if (this.isUserHasBeenLocked(response)) {
      // TODO userLocked handling (requires BUM-2614)
      this.stopPolling();
      console.log('User has been locked and should be logged out');
    }

    this.inProgress.next(false);
    // TODO show general error notification until we have a way of showing notifications from response
    this.notificationService.error(asTranslationKey('web-app-common.auth.verification.general-verification-error'));
  }

  private isUserHasBeenLocked(result: HttpErrorResponse) {
    return (
      isAfpRestResponse(result.error) &&
      result.error.notifications.some((notification) => notification.path === 'userLocked')
    );
  }
}
