import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {TranslateModule} from '@ngx-translate/core';
import {UnsavedDataGuardDialogComponent} from './unsaved-data-guard-dialog.component';

@NgModule({
  declarations: [UnsavedDataGuardDialogComponent],
  imports: [CommonModule, MatButtonModule, MatDialogModule, TranslateModule],
  exports: [UnsavedDataGuardDialogComponent],
})
export class UnsavedDataGuardDialogModule {}
