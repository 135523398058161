<mat-progress-bar [color]="color" [mode]="mode" [value]="value" [attr.aria-label]="ariaLabel" [attr.aria-labelledby]="ariaLabelledby"></mat-progress-bar>

<div class="bsz-progress-bar-text">
  <span class="bsz-progress-bar-label">
    <ng-content select="bsz-progress-bar-label"></ng-content>
    <span *ngIf="_progressBarCustomLabel" aria-hidden="true">...</span>
  </span>

  <span *ngIf="_displayProcessCompletion" class="bsz-progress-completion-text">
      {{ value }}% {{'ui-elements.bsz-progress-bar.process-completion-label.completed' | translate}}
    </span>
</div>
