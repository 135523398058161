import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import {ExpandedGroupStatus, getExpandedGroupsInfo} from '../shared';
import {
  BszTableTreeRow,
  BszTableTreeRowDefinition,
  CollapsedState,
  isBszTableTreeRow,
  isGroupHeader,
} from './bsz-table.definitions';
import {bszTableTreeSort} from './bsz-table-tree-sort';

const LOG_PREFIX = '[bsz-table/tree-data-source]';

/**
 * This class adds additional functionality on top of MatTableDataSource in order
 * to satisfy the requirements of rendering a tree data structure to the mat-table
 * with expandable rows.
 */
export class BszTableTreeDataSource<T, G = unknown> extends MatTableDataSource<BszTableTreeRow> {
  protected static readonly MAX_NESTING_LEVEL = 10;

  constructor(initialData: BszTableTreeRowDefinition<T, G>[] = []) {
    super(BszTableTreeDataSource.flatData(initialData));

    // Add custom sort relevant to the hierarchical data.
    // We need to sort the data and leave the group headers in place.
    this.sortData = bszTableTreeSort;
  }

  override filterPredicate = (row: BszTableTreeRow) => {
    const ancestors = this.getAllAncestors(row);
    return ancestors.every((ancestor) => !ancestor._collapsed);
  };

  getMaxNestingLevel(): number {
    if (this.data.length === 0) {
      return 0;
    }

    return Math.max(...this.data.map((item) => item._nestingLevel));
  }

  addSort(sort: MatSort) {
    this.sort = sort;
  }

  toggleGroup(row: BszTableTreeRow) {
    if (row._collapsed) {
      this.expand(row);
    } else {
      this.collapse(row);
    }
  }

  expand(row: BszTableTreeRow): void {
    if (row._isGroupHeader) {
      row._collapsed = false;

      this.triggerFilter();
    }
  }

  collapse(row: BszTableTreeRow) {
    if (row._isGroupHeader) {
      row._collapsed = true;

      this.triggerFilter();
    }
  }

  expandAll() {
    for (const row of this.data) {
      if (row._isGroupHeader) {
        row._collapsed = false;
      }
    }

    this.triggerFilter();
  }

  collapseAll() {
    for (const row of this.data) {
      if (row._isGroupHeader) {
        row._collapsed = true;
      }
    }

    this.triggerFilter();
  }

  expandRows(filterCallback: (row: BszTableTreeRow) => boolean) {
    for (const row of this.data.filter(filterCallback)) {
      if (row._isGroupHeader) {
        row._collapsed = false;

        // expand all nested descendants groups
        row._children
          .filter((descendant: BszTableTreeRow) => descendant._isGroupHeader)
          .forEach((descendant: BszTableTreeRow) => (descendant._collapsed = false));
      }

      // expand all ancestor groups
      for (const ancestor of this.getAllAncestors(row)) {
        ancestor._collapsed = false;
      }
    }

    this.triggerFilter();
  }

  /** @private */
  _expandGroups(expandedGroups: (string | ExpandedGroupStatus)[]) {
    const {expandedGroupsId, groupsHierarchyStatus} = getExpandedGroupsInfo(expandedGroups);
    const filterCallback = (row: BszTableTreeRow) => row._groupId && expandedGroupsId.includes(row._groupId);

    for (const row of this.data.filter(filterCallback)) {
      const groupId = row._groupId;

      if (row._isGroupHeader) {
        row._collapsed = false;

        // @ts-ignore
        if (groupId && groupsHierarchyStatus[groupId].expandDescendants) {
          // expand all nested descendants groups
          row._children
            .filter((descendant: BszTableTreeRow) => descendant._isGroupHeader)
            .forEach((descendant: BszTableTreeRow) => (descendant._collapsed = false));
        }
      }

      if (groupId && groupsHierarchyStatus[groupId].expandAncestors) {
        // expand all ancestor groups
        for (const ancestor of this.getAllAncestors(row)) {
          ancestor._collapsed = false;
        }
      }
    }

    this.triggerFilter();
  }

  /**
   * Get the overall collapsed state of the data source.
   */
  getAllCollapsedState(): CollapsedState {
    // every top level header collapsed -> the table is fully collapsed
    const topLevelHeaders = this.data.filter((row) => row._nestingLevel === 0);
    const allCollapsed = topLevelHeaders.every((row) => row._collapsed);

    // all headers expanded -> the table is fully expanded
    const allHeaders = this.data.filter((row) => row._isGroupHeader);
    const allExpanded = allHeaders.every((row) => !row._collapsed);

    if (allCollapsed) {
      return true;
    }
    if (allExpanded) {
      return false;
    }
    return null;
  }

  private triggerFilter() {
    // Normally the 'filter' field should hold some name of the filter and changing the value will trigger
    // filtering, passing the value of the 'filter' field to the 'filterPredicate' function
    // (this is defined by the MatTableDataSource).
    // Since we always filter based on the same predicate, we don't care about the value of the 'filter'
    // field, we only want to trigger the filtering by using the setter with any value.
    this.filter = 'whatever';
  }

  private getAllAncestors(row: BszTableTreeRow): BszTableTreeRow[] {
    if (row._ancestorRow) {
      return [row._ancestorRow, ...this.getAllAncestors(row._ancestorRow)];
    } else {
      return [];
    }
  }

  /**
   * Convert the hierarchical data structure to a flat one and pass the
   * relevant meta data to the data for identifying nesting level,
   * ancestor rows and children.
   */
  static flatData<T, G>(
    dataRows: BszTableTreeRowDefinition<T, G>[] | T[],
    ancestorRow?: BszTableTreeRow,
    nestingLevel = 0
  ) {
    if (nestingLevel > this.MAX_NESTING_LEVEL) {
      throw new Error(`${LOG_PREFIX} Nesting levels should not be more than ${this.MAX_NESTING_LEVEL}`);
    }

    const rows: BszTableTreeRow[] = [];

    for (const row of dataRows) {
      const rowToAdd = this.transform(row, ancestorRow, nestingLevel);

      rows.push(rowToAdd);
      if (isGroupHeader(row)) {
        const children = this.flatData(row.children, rowToAdd, nestingLevel + 1);

        rows.push(...children);

        rowToAdd._children = children;
      }
    }

    return rows;
  }

  /**
   * Create a new structure from a given BszTableTreeRowDefinition
   * that includes meta data information regarding nesting,
   * children etc...
   */
  private static transform<T, G>(
    row: BszTableTreeRowDefinition<T, G> | T,
    ancestorRow: BszTableTreeRow | undefined,
    nestingLevel: number
  ): BszTableTreeRow {
    const isHeader = isGroupHeader(row);
    const data = isHeader ? row.group : row;
    const groupId = isHeader && row.groupId ? row.groupId : undefined;

    return {
      _ancestorRow: ancestorRow,
      _nestingLevel: nestingLevel,
      _isGroupHeader: isHeader,
      _collapsed: false,
      _children: [],
      _groupId: groupId,
      _originalRow: data,
      ...data,
    };
  }
}

export function isTreeDataSource<T, G>(obj: any): obj is BszTableTreeDataSource<T, G> {
  return obj instanceof BszTableTreeDataSource;
}

/** Get the original row object from either flat or nested table row. */
export function getOriginalRowFromTableRow<T>(row: T | BszTableTreeRow): T {
  return isBszTableTreeRow(row) ? row._originalRow : row;
}
