import {NavigationIntent} from '../../navigation/classes/navigation-intent';

export interface ShowInvestmentProposalRequestChangesIntentPayload {
  investmentProposalId: number;
}

export class ShowInvestmentProposalRequestChangesIntent extends NavigationIntent {
  constructor(public payload: ShowInvestmentProposalRequestChangesIntentPayload) {
    super();
  }
}
