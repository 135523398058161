import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {AfpAuthDialogResult, AuthDialogVerificationRequest} from '../../models/auth-dialog.definitions';
import {isAfpAuthOperationSupported} from '../../type-guards/auth.type-guards';
import {
  isCrontoRequest,
  isFuturaeRequest,
  isMatrixRequest,
  isMtanRequest,
  isPushRequest,
  isTokenRequest,
} from '../../type-guards/auth-dialog.type-guards';

@Component({
  selector: 'bsz-auth-verification',
  templateUrl: './auth-verification.component.html',
  styleUrls: ['./auth-verification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthVerificationComponent {
  @Input()
  verificationRequest: AuthDialogVerificationRequest;

  @Output()
  verificationResult: EventEmitter<AfpAuthDialogResult> = new EventEmitter<AfpAuthDialogResult>();

  readonly isAuthOperationSupported = isAfpAuthOperationSupported;

  readonly guards = {
    isCrontoRequest,
    isFuturaeRequest,
    isMatrixRequest,
    isMtanRequest,
    isTokenRequest,
    isPushRequest,
  };

  onResult(result: AfpAuthDialogResult) {
    this.verificationResult.emit(result);
  }
}
