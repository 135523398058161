import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {BszI18nModule} from '@basuiz/i18n';

import {BszDatepickerRangeBase} from './bsz-datepicker-range.directive';
import {BszDatepickerRangeService} from './bsz-datepicker-range.service';

@NgModule({
  imports: [CommonModule, BszI18nModule, MatButtonModule, MatSelectModule],
  exports: [BszDatepickerRangeBase],
  declarations: [BszDatepickerRangeBase],
  providers: [BszDatepickerRangeService],
})
export class BszDatepickerRangeModule {}
