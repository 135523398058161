import {UserRoleService} from '@basuiz/web-app-common';
import {disabled, enabled} from './helpers';
import {BszFeatureToggle} from './models/features.definition';

export function getDefaultFeatureToggle(userRoleService: UserRoleService): BszFeatureToggle {
  return {
    // ** Examples with roles: **
    // investmentProposals: enabled(userRoleService.hasRole('CORPORATE')),
    // investmentProposals: disabled(userRoleService.hasRole('CORPORATE', 'RETAIL')),
    investmentProposals: 'enabled',
    globalFilters: 'enabled',
    paymentCards: 'enabled', // This is an opt-in feature that requires the bank to have its own payment cards provider (Bank internal cards, SIX, Viseca...)
    userProfile: 'enabled', // Before enabling, bank must first implement an AMI Web service in their ACP instance
    notifications: 'enabled',
    collaboration: 'enabled',
    shipment: 'enabled',
    paymentMethod: {
      wallet: 'enabled',
      ecPayphone: 'enabled',
    },
  };
}
