import {CdkCellDef, CdkColumnDef, CdkFooterCellDef, CdkHeaderCellDef} from '@angular/cdk/table';
import {Directive, ElementRef, Inject, Optional} from '@angular/core';
import {MatSortHeader} from '@angular/material/sort';

/** Base class for the cells. */
export class BaseBszCell {
  constructor(private elementRef: ElementRef, columnDef: CdkColumnDef) {
    const columnClassName = `mat-column-${columnDef.cssClassFriendlyName}`;
    this.elementRef.nativeElement.classList.add(columnClassName);
  }
}

/** Table cell template container that adds the right classes and role. */
@Directive({
  selector: 'td[bsz-cell], bsz-cell',
  host: {
    class: 'bsz-cell mat-cell cdk-cell',
    role: 'gridcell',
  },
})
export class BszCell extends BaseBszCell {
  constructor(
    elementRef: ElementRef,
    @Inject('BSZ_TABLE_COLUMN') @Optional() bszTableColumn: any,
    @Inject('BSZ_TABLE_GROUP_HEADER_COLUMN') @Optional() bszGroupHeaderColumn: any
  ) {
    super(elementRef, bszTableColumn?.columnDef || bszGroupHeaderColumn?.columnDef);
  }
}

/** Header cell template container that adds the right classes and role, and sets up the MatSortHeader*/
@Directive({
  selector: 'bsz-header-cell, th[bsz-header-cell]',
  host: {
    class: 'bsz-header-cell mat-header-cell cdk-header-cell',
    role: 'columnheader',
  },
})
export class BszHeaderCell extends BaseBszCell {
  constructor(
    elementRef: ElementRef,

    // Using any, since otherwise we would get "ReferenceError: Cannot access 'BszTableColumn' before initialization".
    // We have a circular dependency between BszCell and BszTableColumn, so the error is most probably caused by that.
    // TODO: Remove the circular dependency
    @Inject('BSZ_TABLE_COLUMN') bszTableColumn: any,
    @Optional() sort: MatSortHeader
  ) {
    super(elementRef, bszTableColumn.columnDef);
    if (sort) {
      // MatSort uses the columnDefinition name as an id
      sort._columnDef = bszTableColumn.columnDef;
    }
  }
}

/** Footer cell template container that adds the right classes and role. */
@Directive({
  selector: 'bsz-footer-cell, td[bsz-footer-cell]',
  host: {
    class: 'bsz-footer-cell mat-footer-cell cdk-footer-cell',
    role: 'gridcell',
  },
})
export class BszFooterCell extends BaseBszCell {
  constructor(elementRef: ElementRef, @Inject('BSZ_TABLE_COLUMN') bszTableColumn: any) {
    super(elementRef, bszTableColumn.columnDef);
  }
}

/**
 * Cell definition for the bsz table.
 * Captures the template of a column's data row cell as well as cell-specific properties.
 */
@Directive({
  selector: '[bszCellDef]',
  providers: [{provide: CdkCellDef, useExisting: BszCellDef}],
})
export class BszCellDef extends CdkCellDef {}

/**
 * Header cell definition for the bsz table.
 * Captures the template of a column's header cell and as well as cell-specific properties.
 */
@Directive({
  selector: '[bszHeaderCellDef]',
  providers: [{provide: CdkHeaderCellDef, useExisting: BszHeaderCellDef}],
})
export class BszHeaderCellDef extends CdkHeaderCellDef {}

/**
 * Footer cell definition for the bsz table.
 * Captures the template of a column's footer cell and as well as cell-specific properties.
 */
@Directive({
  selector: '[bszFooterCellDef]',
  providers: [{provide: CdkFooterCellDef, useExisting: BszFooterCellDef}],
})
export class BszFooterCellDef extends CdkHeaderCellDef {}
