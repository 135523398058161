import {LiveAnnouncer} from '@angular/cdk/a11y';
import {coerceNumberProperty} from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Host,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {MatOption} from '@angular/material/core';
import {MatSelect} from '@angular/material/select';
import {TranslateService} from '@ngx-translate/core';
import {AsyncSubject, fromEvent} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';

import {BszSelectSearchMatSelectDirective} from './bsz-select-search.pipe';

@Component({
  selector: 'bsz-select-search',
  templateUrl: './bsz-select-search.html',
  styleUrls: ['./bsz-select-search.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BszSelectSearch implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  get debounceInterval(): number {
    return this._debounceInterval;
  }

  set debounceInterval(value: number) {
    this._debounceInterval = coerceNumberProperty(value);
  }

  @Output() searchChange = new EventEmitter<string>();

  @ViewChild('searchInput') searchField!: ElementRef;

  private readonly destroy = new AsyncSubject<void>();
  private _debounceInterval = 150;
  private optionViewValues: string[] = [];
  ariaControls = '';
  noResults = false;

  constructor(
    @Host() private matSelect: MatSelect,
    private liveAnnouncer: LiveAnnouncer,
    private translate: TranslateService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly matSelectDirective: BszSelectSearchMatSelectDirective
  ) {}

  ngOnInit() {
    this.matSelect.openedChange.pipe(takeUntil(this.destroy)).subscribe((isOpen) => {
      if (isOpen) {
        this.ariaControls = this.matSelect.panel.nativeElement.id;
        this.focusSearchField();

        // setting the view values, so the optional pipe can search in the text that is shown in the option
        this.optionViewValues = this.matSelect.options
          .filter((option: MatOption) => !option._getHostElement().getAttributeNames().includes('searchignore'))
          .map((option: MatOption) => option.viewValue);
      } else {
        this.searchField.nativeElement.value = '';
        this.resetActiveItemStyles();
        this.emitChanges('');
      }
    });
  }

  ngAfterViewInit(): void {
    this.matSelect.typeaheadDebounceInterval = 0;

    // timeout because this.matSelect.options might be undefined at this point in time if ngIf is used (see UISDK-707)
    setTimeout(() => {
      this.matSelect.options.changes.pipe(takeUntil(this.destroy)).subscribe((options) => {
        this.noResults = !this.hasOptions(options);
        this.updateActiveItem(0);
        this.cdRef.detectChanges();
      });
    });

    fromEvent<InputEvent>(this.searchField.nativeElement, 'input')
      .pipe(debounceTime(this._debounceInterval), takeUntil(this.destroy))
      .subscribe((event) => {
        this.handleInput(event);
      });
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  handleInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let inputValue = input.value;
    if (inputValue.trim() === '') {
      inputValue = '';
      input.value = '';
    }
    this.emitChanges(inputValue);
  }

  handleKeydown(event: KeyboardEvent) {
    const eventKey = event.key;

    // the search feature that matSelect has must be deactivated;
    // not doing it doesn't affect to the behavior of the component
    // but it updates the scroll position and the active option;
    // space must be also stopped because it fires the option selection
    const isCharacterKey = eventKey.length === 1;
    if (isCharacterKey) {
      event.stopPropagation();
    }

    // enter-key selects the option in matSelect but if multiple selection,
    // the focus is lost, so the search field needs to recover it
    if (eventKey === 'Enter' && this.matSelect.multiple) {
      this.focusSearchField();
    }

    // escape-key closes the select field, so the focus must be recovered
    if (eventKey === 'Escape') {
      this.matSelect.focus();
    }

    this.adjustScroll();
  }

  handleKeyup(event: KeyboardEvent) {
    const eventKey = event.key;
    // keys that move between the list of options in matSelect
    const matSelectNavigationKeys = ['ArrowUp', 'ArrowDown', 'Home', 'End'];
    if (matSelectNavigationKeys.indexOf(eventKey) === -1) {
      return;
    }
    this.announceActiveOptionChange();
  }

  /**
   * This method is meant to inform to the users of assistive technologies when the active option (highlighted) changes
   * so they know which one is active in case they want to select it. When matSelect has the search feature,
   * the focus is in the input field and there is no live notification.
   */
  private announceActiveOptionChange() {
    const ariaActiveDescendantId = this.matSelect._getAriaActiveDescendant();
    const options = this.matSelect.options.toArray();
    const optionIndex = options.findIndex((item) => item.id === ariaActiveDescendantId);
    if (optionIndex === -1) {
      return;
    }
    const activeDescendant = options[optionIndex];
    const optionsLength = options.length;
    this.liveAnnouncer.announce(
      `${activeDescendant.viewValue} ${this.getAriaLiveIndexOf(optionIndex + 1, optionsLength)}`
    );
  }

  private getAriaLiveIndexOf(optionIndex: number, optionsLength: number) {
    return this.translate.instant('ui-elements.bsz-select-search.accessibility.aria-live.index-of-options', {
      optionIndex,
      optionsLength,
    });
  }

  private focusSearchField() {
    // There are cases in which, if there is no timeout, the execution is too fast and happens before
    // other matSelect behavior happens. For instance, when using "Enter" key for selecting options, without
    // the timeout, the execution is fired before the focus is sent by matSelect to the highlighted option
    setTimeout(() => {
      this.searchField.nativeElement.focus();
    });
  }

  /**
   * The component is not working as option for the select, so it occupies space
   * that matSelect doesn't recognize and when changing the active option with the
   * arrow keys, the scroll doesn't behave as expected.
   */
  private adjustScroll(): void {
    setTimeout(() => {
      const panel = this.matSelect.panel;
      if (!this.matSelect.options.length || !panel) {
        return;
      }
      const panelElement = panel.nativeElement;
      const panelHeight = panelElement.offsetHeight;
      const currentScrollTop = panelElement.scrollTop;
      const panelBottom = currentScrollTop + panelHeight;

      const optionHeight = this.getOptionHeight();
      const activeOption = this.matSelect._keyManager.activeItem;
      const activeOptionTop = activeOption?._getHostElement().offsetTop || 0;
      const activeOptionBottom = activeOptionTop + optionHeight;

      const scrollDifference = activeOptionBottom - panelBottom;
      // if part of the option is below the bottom of the panel,
      // update the scroll to prevent it
      if (scrollDifference) {
        panelElement.scrollTop += scrollDifference;
      }
    });
  }

  private getOptionHeight(): number {
    const options = this.matSelect.options;

    return options.length ? options.first._getHostElement().getBoundingClientRect().height : 0;
  }

  /**
   * Check if there are no options without considering the one for "select all"
   */
  private hasOptions(options: QueryList<MatOption>): boolean {
    return options.filter((option) => !this.isSelectAllOption(option)).length >= 1;
  }

  /**
   * Set the active item to the first option without any other effect and
   * after filtering. By this way, the keyManager keeps handling the focus and
   * keyboard behavior.
   * Param: Index of the item to be set as active
   */
  private updateActiveItem(index: number) {
    setTimeout(() => {
      this.resetActiveItemStyles();
      // it uses updateActiveItem because this method changes the active item without any other effect;
      // other methods, like setFirstItemActive, fire the selection;
      // the null impact in the selection is tested in the E2E tests
      this.matSelect._keyManager.updateActiveItem(index);

      // Although the item is active, for any reason matSelect doesn't style it as active
      this.matSelect.options.first?.setActiveStyles();
    });
  }

  private resetActiveItemStyles() {
    this.matSelect._keyManager.activeItem?.setInactiveStyles();
  }

  private isSelectAllOption(option: MatOption): boolean {
    // Check if the option has the directive 'bsz-select-all'
    return option._getHostElement().getAttribute('bsz-select-all') !== null;
  }

  private emitChanges(searchText: string) {
    this.searchChange.emit(searchText);
    this.matSelectDirective.bszSelectSearchChange.next({
      searchText: searchText,
      optionViewValues: this.optionViewValues,
    });
  }
}
