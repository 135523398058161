import {ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
  ApiHttpErrorCodes,
  IUser,
  UserResetPasswordPreValidatedReq,
  UserResetPasswordValidatedReq,
  isRegexUUID,
  isRegexEmail,
  propertyOf,
} from '@basuiz/shared/data-access';
import {BehaviorSubject, Observable, Subscription, catchError, finalize, firstValueFrom, of, tap, delay} from 'rxjs';
import {ExternalLinkService} from '../../../external-link/external-link.service';
import {AuthJwtService} from '../../services/auth-jwt.service';
import {HttpErrorResponse} from '@angular/common/http';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {passwordMinLength} from '../../models/auth.definitions';

@Component({
  selector: 'bsz-auth-reset-password-validate',
  templateUrl: './reset-password-validate.component.html',
  styleUrls: ['./reset-password-validate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordValidateComponent implements OnInit, OnDestroy {
  @Output()
  goToSignin: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  goToResetPasswordRequest: EventEmitter<void> = new EventEmitter<void>();

  public readonly showHelp: boolean = Boolean(process.env['APP_CUSTOMER_SUPPORT_PHONE_WHATSAPP']);
  public resetPasswordPreValidateStatus: ApiHttpErrorCodes.resetPasswordValidateTokenExpired | 'error' | 'success' =
    'error';
  public resetPasswordValidateStatus:
    | ApiHttpErrorCodes.resetPasswordValidateTokenExpired
    | 'pristine'
    | 'error'
    | 'success' = 'pristine';

  private subscriptions: Subscription = new Subscription();

  private resetPasswordPreValidateLoadingSubject = new BehaviorSubject<boolean>(false);
  resetPasswordPreValidateLoadingSubjectLoadingSubject$ = this.resetPasswordPreValidateLoadingSubject.asObservable();

  private resetPasswordValidateLoadingSubject = new BehaviorSubject<boolean>(false);
  resetPasswordValidateLoadingSubjectLoadingSubject$ = this.resetPasswordValidateLoadingSubject.asObservable();

  ApiHttpErrorCodes = ApiHttpErrorCodes;

  queryParamEmail: string;
  queryParamResetPasswordToken: string;

  resetPasswordValidateForm: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authJwtService: AuthJwtService,
    private externalLinkService: ExternalLinkService
  ) {
    this.resetPasswordValidateForm = new FormGroup({
      newPassword: new FormControl('', [Validators.required, Validators.minLength(passwordMinLength)]),
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe(async (params) => {
        this.queryParamEmail = params[propertyOf<IUser>('email')];
        this.queryParamResetPasswordToken = params[propertyOf<IUser>('resetPasswordToken')];

        if (
          this.queryParamEmail &&
          this.queryParamResetPasswordToken &&
          isRegexEmail(this.queryParamEmail) &&
          isRegexUUID(this.queryParamResetPasswordToken)
        ) {
          await this.doResetPasswordPreValidate();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getFormControl(formControlName: any): FormControl {
    return this.resetPasswordValidateForm.get(formControlName) as FormControl;
  }

  async doResetPasswordPreValidate() {
    this.resetPasswordPreValidateLoadingSubject.next(true);

    const user: UserResetPasswordPreValidatedReq = {
      email: this.queryParamEmail ?? null,
      resetPasswordToken: this.queryParamResetPasswordToken ?? null,
    };

    await firstValueFrom(
      this.authJwtService.resetPasswordPreValidate(user).pipe(
        tap((user) => {
          if (user.email) {
            this.resetPasswordPreValidateStatus = 'success';
          }
        }),
        finalize(() => {
          this.resetPasswordPreValidateLoadingSubject.next(false);
        }),
        catchError((error: HttpErrorResponse): Observable<null> => {
          if (error.error.message === ApiHttpErrorCodes.resetPasswordValidateTokenExpired) {
            this.resetPasswordPreValidateStatus = ApiHttpErrorCodes.resetPasswordValidateTokenExpired;
          } else {
            this.resetPasswordPreValidateStatus = 'error';
          }
          return of(null);
        })
      )
    );
  }

  async doResetPasswordValidate() {
    if (!this.resetPasswordValidateForm.valid) {
      return;
    }

    this.resetPasswordValidateLoadingSubject.next(true);

    const newPassword = this.resetPasswordValidateForm.get('newPassword')?.value;

    const user: UserResetPasswordValidatedReq = {
      email: this.queryParamEmail ?? null,
      resetPasswordToken: this.queryParamResetPasswordToken ?? null,
      password: newPassword ?? null,
    };

    await firstValueFrom(
      this.authJwtService.resetPasswordValidate(user).pipe(
        tap((user) => {
          if (user.email) {
            this.resetPasswordValidateStatus = 'success';
          }
        }),
        finalize(() => {
          this.resetPasswordValidateLoadingSubject.next(false);
        }),
        catchError((error: HttpErrorResponse): Observable<null> => {
          if (error.error.message === ApiHttpErrorCodes.resetPasswordValidateTokenExpired) {
            this.resetPasswordValidateStatus = ApiHttpErrorCodes.resetPasswordValidateTokenExpired;
          } else {
            this.resetPasswordValidateStatus = 'error';
          }
          return of(null);
        })
      )
    );
  }

  openHelp() {
    this.externalLinkService.open(
      `https://api.whatsapp.com/send/?phone=${process.env['APP_CUSTOMER_SUPPORT_PHONE_WHATSAPP']}`
    );
  }
}
