import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AfpRestResponse} from '../afp-rest/models/afp-rest-response.definitions';
import {map, shareReplay} from 'rxjs/operators';

interface LoginOnBehalfStatusOutputPTO {
  isLoggedInOnBehalf: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LoginOnBehalfService {
  constructor(private readonly httpClient: HttpClient) {}

  // private readonly url = '/com.basuiz.afs.rest.services/rest/user/loginOnBehalfStatus'; // to be used with mock-server & proxy.config.json
  private readonly url = process.env.API_BASE_URL + '/rest/user/loginOnBehalfStatus';

  private readonly _isUserLoggedOnBehalf$: Observable<boolean> = this.httpClient
    .get<AfpRestResponse<LoginOnBehalfStatusOutputPTO>>(`${this.url}`)
    .pipe(
      map(({payload}) => payload.isLoggedInOnBehalf),
      shareReplay(1)
    );

  isUserLoggedOnBehalf$(): Observable<boolean> {
    return this._isUserLoggedOnBehalf$;
  }
}
