import {ActionWithoutResponse} from '@basuiz/mobile-bridge';

/**
 * Opens the crypto hybrid module to display the details of a given crypto position.
 */
export class OpenCryptoPositionDetailsAction extends ActionWithoutResponse<OpenCryptoPositionDetailsRequest> {
  readonly name = 'openCryptoPosition';
}

export interface OpenCryptoPositionDetailsRequest {
  /**
   * The ID of the crypto position to display.
   */
  positionId: number;
}
