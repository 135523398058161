import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';

const LOG_PREFIX = '[bsz-skeleton-loader]';

@Component({
  selector: 'bsz-skeleton-loader',
  styleUrls: ['./bsz-skeleton-loader.scss'],
  templateUrl: './bsz-skeleton-loader.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'bsz-skeleton-loader',
  },
})
export class BszSkeletonLoader {
  @Input() variant: BszSkeletonLoaderVariant = 'body-1';

  @Input() loaderClass = '';

  @Input() width = '';

  @Input() height = '';

  @Input() set size(size: string) {
    if (this.width || this.height) {
      console.error(`${LOG_PREFIX} "size" property cannot be used in conjunction with "width" or "height"`);
      return;
    }

    this.width = size;
    this.height = size;
  }

  @Input() set count(num: number | string) {
    // We need to create a dummy array because
    // *ngFor can iterate over collections only
    const length = parseInt(num as string, 10);
    this._count = new Array(length).fill(0);
  }
  _count = new Array(1).fill(0);
}

export type BszSkeletonLoaderVariant =
  | 'title-1'
  | 'title-2'
  | 'title-3'
  | 'title-4'
  | 'title-5'
  | 'title-6'
  | 'subtitle-1'
  | 'subtitle-2'
  | 'body-1'
  | 'body-2'
  | 'caption'
  | 'rect'
  | 'circle'
  | 'button';
