import {Component, ContentChildren, ElementRef, Input, QueryList, Renderer2, ViewChild} from '@angular/core';
import {animate, AnimationBuilder, style} from '@angular/animations';
import {BszSwipeableCarouselSlideComponent} from './bsz-swipeable-carousel-slide.component';

@Component({
  selector: 'bsz-swipeable-carousel',
  templateUrl: './bsz-swipeable-carousel.component.html',
  styleUrls: ['./bsz-swipeable-carousel.component.scss'],
})
export class BszSwipeableCarouselComponent {
  @Input()
  slideSpacing: number = 0;

  @Input()
  slidePreviewSize: number = 0;

  @ContentChildren(BszSwipeableCarouselSlideComponent)
  slides: QueryList<BszSwipeableCarouselSlideComponent>;

  @ViewChild('carouselContainer', {static: true})
  private carouselContainer: ElementRef<HTMLDivElement>;

  @ViewChild('carouselSlidesContainer', {static: true})
  private carouselSlidesContainer: ElementRef<HTMLElement>;

  activeItemIndex: number = 0;

  constructor(private renderer2: Renderer2, private animationBuilder: AnimationBuilder) {}

  onSwipeLeft() {
    if (this.activeItemIndex + 1 < this.slides.length) {
      this.activeItemIndex++;
      this.updateTransformation();
    }
  }

  onSwipeRight() {
    if (this.activeItemIndex - 1 >= 0) {
      this.activeItemIndex--;
      this.updateTransformation();
    }
  }

  private updateTransformation(): void {
    const transform = `translateX(${
      -this.activeItemIndex *
      (this.carouselContainer.nativeElement.clientWidth - 2 * this.slidePreviewSize + this.slideSpacing)
    }px)`;
    const animation = this.animationBuilder
      .build(animate('250ms ease-in-out', style({transform})))
      .create(this.carouselSlidesContainer.nativeElement);
    animation.onDone(() => {
      this.renderer2.setStyle(this.carouselSlidesContainer.nativeElement, 'transform', transform);
      animation.destroy();
    });
    animation.play();
  }

  onSlideIndicatorClick(index: number) {
    this.activeItemIndex = index;
    this.updateTransformation();
  }
}
