import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
// eslint-disable-next-line
import {
  AccessibleLinkModule,
  FormattingModule,
  TestingCoreModule,
  WebAppBannerModule,
  // eslint-disable-next-line bsz-wbui/monorepo-import
} from '@basuiz/web-app-common';
import {BszScreenSizeContentSwitcherModule, BszSkeletonLoaderModule} from '@basuiz/ui-elements';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {BszI18nModule} from '@basuiz/i18n';

@NgModule({
  exports: [
    CommonModule,
    FormattingModule,
    TestingCoreModule,
    AccessibleLinkModule,
    TranslateModule,
    BszI18nModule,
    BszScreenSizeContentSwitcherModule,
    BszSkeletonLoaderModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatDividerModule,
    WebAppBannerModule,
  ],
})
export class PortalBasicModule {}
