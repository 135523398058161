import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DEFAULT_NAVIGATION_INTENT_HANDLERS} from './default-intent-handlers.config';
import {getDefaultPortalIntentHandlers} from '../intent-handlers/default-intent-handler';
import {ExpandedFeatureToggle, WEB_APP_EXPANDED_FEATURE_TOGGLE} from '@basuiz/web-app-feature-toggle';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: DEFAULT_NAVIGATION_INTENT_HANDLERS,
      useFactory: (featureToggle: ExpandedFeatureToggle) => getDefaultPortalIntentHandlers(featureToggle),
      deps: [WEB_APP_EXPANDED_FEATURE_TOGGLE],
    },
  ],
})
export class NavigationModule {}
