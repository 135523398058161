import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {
  BszCard,
  BszCardActions,
  BszCardContent,
  BszCardHeader,
  BszCardImage,
  BszCardSubtitle,
  BszCardThumbnail,
  BszCardTitle,
} from './bsz-card';
import {BszCardSelectControl} from './bsz-card-select-control';

const DECLARATIONS_AND_EXPORTS = [
  BszCard,
  BszCardActions,
  BszCardImage,
  BszCardTitle,
  BszCardSubtitle,
  BszCardThumbnail,
  BszCardHeader,
  BszCardContent,
  BszCardSelectControl,
];

@NgModule({
  imports: [CommonModule],
  declarations: DECLARATIONS_AND_EXPORTS,
  exports: DECLARATIONS_AND_EXPORTS,
})
export class BszCardModule {}
