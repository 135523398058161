import {InjectionToken, Provider} from '@angular/core';
import {UserRoleMapping, UserRoleMappingFactoryWithDeps} from './user-role-mapping.definitions';

export const USER_ROLE_MAPPING = new InjectionToken<UserRoleMapping>('bsz.web-app.user-role-mapping');

/* Creates a provider for the portal user-roles mapping. */
export function userRoleMappingProvider(factoryWithDeps: UserRoleMappingFactoryWithDeps): Provider {
  const {useFactory, deps} = factoryWithDeps;
  return {provide: USER_ROLE_MAPPING, useFactory, deps: [...(deps || [])]};
}
