import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[bsz-button-toggle-style]',
  styleUrls: ['bsz-button-toggle-style.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<ng-content></ng-content>`,
  host: {class: 'bsz-button-toggle-group'},
})
export class BszButtonToggleStyle {} // we use a component here only for the styleUrls, in order to keep the styles coupled together.
