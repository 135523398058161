import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PortalModule} from '@angular/cdk/portal';
import {AppShellOutletReceiverComponent} from './app-shell-outlet-receiver.component';

@NgModule({
  declarations: [AppShellOutletReceiverComponent],
  exports: [AppShellOutletReceiverComponent],
  imports: [CommonModule, PortalModule],
})
export class AppShellOutletReceiverModule {}
