import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';

import {CommunicationService, UPDATE_TABS_MESSAGE_EVENT_NAME} from './bsz-tabs-message.service';

@Component({
  selector: 'bsz-tab',
  template: '<div #tabpanel><ng-content *ngIf="isActive"></ng-content></div>',
  styleUrls: ['./bsz-tabs.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'class': 'bsz-tabs-tabpanel',
    'role': 'tabpanel',
    'tabindex': '0',
    '[attr.id]': 'uniqueId',
    '[attr.aria-labelledby]': 'labelledBy',
  },
})
export class BszTab<T = unknown> {
  @Input() set textLabel(label: string) {
    this._textLabel = label;
    // send the event so the main element trigger the change detection
    this.communicationService.sendEvent(UPDATE_TABS_MESSAGE_EVENT_NAME, '');
  }

  get textLabel(): string {
    return this._textLabel;
  }

  @Input() set icon(icon: string) {
    this._icon = icon;
    // send the event so the main element trigger the change detection
    this.communicationService.sendEvent(UPDATE_TABS_MESSAGE_EVENT_NAME, '');
  }

  get icon(): string {
    return this._icon;
  }

  @Input('aria-label') set ariaLabel(label: string) {
    this._ariaLabel = label;
    // send the event so the main element trigger the change detection
    this.communicationService.sendEvent(UPDATE_TABS_MESSAGE_EVENT_NAME, '');
  }

  get ariaLabel(): string {
    return this._ariaLabel;
  }

  @Input() set disabled(disabled: BooleanInput) {
    this.updateDisabled(coerceBooleanProperty(disabled));
  }

  get disabled(): BooleanInput {
    return this._disabled;
  }

  private _disabled = false;
  private _textLabel: string;
  private _icon: string;
  private _ariaLabel: string;
  private static nextId = 0;
  isActive = false;

  /** @private */
  uniqueIndex = BszTab.nextId++;
  /** @private */
  uniqueId = `bsz-tabs-tabpanel-${this.uniqueIndex}`;
  /** @private */
  labelledBy = `bsz-tabs-tab-${this.uniqueIndex}`;

  constructor(
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly renderer: Renderer2,
    private readonly cd: ChangeDetectorRef,
    private readonly communicationService: CommunicationService<string>
  ) {}

  isActiveTab(tabId: string) {
    if (tabId === this.uniqueId) {
      if (this._disabled) {
        return;
      }
      this.isActive = true;
      this.renderer.removeAttribute(this._elementRef.nativeElement, 'hidden');
      this.renderer.addClass(this._elementRef.nativeElement, 'bsz-tabs-tabpanel-active');
    } else {
      this.isActive = false;
      this.renderer.setAttribute(this._elementRef.nativeElement, 'hidden', '');
      this.renderer.removeClass(this._elementRef.nativeElement, 'bsz-tabs-tabpanel-active');
    }
    this.cd.detectChanges();
  }

  private updateDisabled(disabled: boolean) {
    this._disabled = disabled;

    // send the event so the main element trigger the change detection, update the list of disabled tabs and
    // set the disabled status for the tab buttons
    this.communicationService.sendEvent(UPDATE_TABS_MESSAGE_EVENT_NAME, '');
  }
}
