import {Action, createReducer, on} from '@ngrx/store';
import {loadBankDateSuccess, loadBankDateFailed, loadBankDate} from './bank-date.actions';

export const FEATURE_KEY = 'web-app-common/bank-date';

export interface PartialState {
  readonly [FEATURE_KEY]: State;
}

export interface State {
  bankDate: string | undefined;
  loading: boolean;
  loaded: boolean;
  error: any;
}

export const initialState: State = {
  bankDate: undefined,
  loading: false,
  loaded: false,
  error: false,
};

const bankDateReducer = createReducer(
  initialState,
  on(loadBankDate, (state) => ({
    ...state,
    loading: true,
  })),
  on(loadBankDateSuccess, (state, {bankDate}) => ({
    ...state,
    bankDate,
    loaded: true,
    loading: false,
    error: false,
  })),
  on(loadBankDateFailed, (state) => ({
    ...state,
    loaded: false,
    loading: false,
    error: true,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return bankDateReducer(state, action);
}
