import {Injectable} from '@angular/core';
import moment, {Moment, MomentInput} from 'moment';

import StartOf = moment.unitOfTime.StartOf;

export interface DatepickerRange {
  fromDate: number;
  toDate: number;
}

export type DatepickerRangePeriod = 'year' | 'half-year' | 'quarter' | 'month' | 'week';

export type BszDatepickerRangePeriod =
  | 'last7Days'
  | 'last14Days'
  | 'last30Days'
  | 'last180Days'
  | 'last360Days'
  | 'last720Days'
  | 'next7Days'
  | 'next14Days'
  | 'next30Days'
  | 'thisWeek'
  | 'thisMonth'
  | 'thisQuarter'
  | 'thisHalfYear'
  | 'thisYear'
  | 'endOfWeek'
  | 'endOfMonth'
  | 'endOfQuarter'
  | 'endOfHalfYear'
  | 'endOfYear'
  | 'nextWeek'
  | 'nextMonth'
  | 'nextQuarter'
  | 'nextHalfYear'
  | 'nextYear';

@Injectable()
export class BszDatepickerRangeService {
  private readonly dateRanges: Record<BszDatepickerRangePeriod, DatepickerRange> = {
    last7Days: this.getLastDays(7),
    last14Days: this.getLastDays(14),
    last30Days: this.getLastDays(30),
    last180Days: this.getLastDays(180),
    last360Days: this.getLastDays(360),
    last720Days: this.getLastDays(720),
    next7Days: this.getNextDays(7),
    next14Days: this.getNextDays(14),
    next30Days: this.getNextDays(30),
    thisWeek: this.getThisPeriod('week'),
    thisMonth: this.getThisPeriod('month'),
    thisQuarter: this.getThisPeriod('quarter'),
    thisHalfYear: this.getThisPeriod('half-year'),
    thisYear: this.getThisPeriod('year'),
    endOfWeek: this.getEndPeriod('week'),
    endOfMonth: this.getEndPeriod('month'),
    endOfQuarter: this.getEndPeriod('quarter'),
    endOfHalfYear: this.getEndPeriod('half-year'),
    endOfYear: this.getEndPeriod('year'),
    nextWeek: this.getNextPeriod('week'),
    nextMonth: this.getNextPeriod('month'),
    nextQuarter: this.getNextPeriod('quarter'),
    nextHalfYear: this.getNextPeriod('half-year'),
    nextYear: this.getNextPeriod('year'),
  };

  private currentDate: Moment = moment();

  private getLastDays(quantity: number): DatepickerRange {
    return {
      fromDate: moment(this.currentDate).subtract(quantity, 'days').startOf('day').valueOf(),
      toDate: moment(this.currentDate).endOf('day').valueOf(),
    };
  }

  private getNextDays(quantity: number): DatepickerRange {
    return {
      fromDate: moment(this.currentDate).startOf('day').valueOf(),
      toDate: moment(this.currentDate).add(quantity, 'days').endOf('day').valueOf(),
    };
  }

  private getPeriod(timePeriod: DatepickerRangePeriod, referenceDate?: MomentInput): DatepickerRange {
    referenceDate = referenceDate || moment(this.currentDate);

    return timePeriod === 'half-year'
      ? this.getHalfYear(referenceDate)
      : this.getStandardPeriod(timePeriod, referenceDate);
  }

  private getStandardPeriod(timePeriod: StartOf, referenceDate: MomentInput): DatepickerRange {
    return {
      fromDate: moment(referenceDate).startOf(timePeriod).valueOf(),
      toDate: moment(referenceDate).endOf(timePeriod).valueOf(),
    };
  }

  private getHalfYear(referenceDate: MomentInput): DatepickerRange {
    const monthBoundaries = moment(referenceDate).month() < 6 ? [0, 5] : [6, 11];

    return {
      fromDate: moment(referenceDate).month(monthBoundaries[0]).startOf('month').valueOf(),
      toDate: moment(referenceDate).month(monthBoundaries[1]).endOf('month').valueOf(),
    };
  }

  private getThisPeriod(timePeriod: DatepickerRangePeriod): DatepickerRange {
    return this.getPeriod(timePeriod);
  }

  private getEndPeriod(timePeriod: DatepickerRangePeriod): DatepickerRange {
    const thisPeriod = this.getPeriod(timePeriod);

    return {
      fromDate: moment(this.currentDate).startOf('day').valueOf(),
      toDate: thisPeriod.toDate,
    };
  }

  private getNextPeriod(timePeriod: DatepickerRangePeriod): DatepickerRange {
    const thisPeriod = this.getPeriod(timePeriod);
    const firstDayOfNextPeriod = moment(thisPeriod.toDate).add(1, 'day');

    return this.getPeriod(timePeriod, firstDayOfNextPeriod);
  }

  /**
   * Returns the date-range object based on the defined range.
   */
  getDatepickerRange(range: BszDatepickerRangePeriod): DatepickerRange | undefined {
    return this.dateRanges[range];
  }
}
