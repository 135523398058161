import {DefaultNavigationIntentHandlerMap} from '../../navigation/default-intent-handlers.config';
import {DefaultIntentHandlerMapBuilder} from '../default-intent-handler-map.builder';
import * as intents from '../../intents/intents.index';
import * as pages from '../../pages/pages.index';
import {assertNever} from '@basuiz/web-app-applet-sdk';

export function userDefaultIntentHandlersFactory(
  userProfilePostalAddressEnabled: boolean
): DefaultNavigationIntentHandlerMap {
  const builder = new DefaultIntentHandlerMapBuilder();
  defineHandlers(builder.addHandler, userProfilePostalAddressEnabled);
  return builder.build();
}

function defineHandlers(
  addHandler: DefaultIntentHandlerMapBuilder['addHandler'],
  userProfilePostalAddressEnabled: boolean
): void {
  addHandler(intents.ModifyUserDataIntent, ({payload}) => {
    switch (payload.modificationType) {
      case 'address':
        return userProfilePostalAddressEnabled ? new pages.PostalAddressUpdatePage() : new pages.AddressChangePage();
      case 'web-app-settings':
        const settingsGroup = payload.settingsGroup;
        if (settingsGroup) {
          switch (settingsGroup) {
            case 'general':
              return new pages.UserSettingsGeneralPage();
            case 'user-info':
              return new pages.UserSettingsPage();
            default:
              assertNever(settingsGroup);
          }
        } else {
          return new pages.UserSettingsPage();
        }
        break;
      case 'contact-details':
        return new pages.ContactDetailsUpdatePage();
      case 'collection-bank-account':
        return new pages.CollectionBankAccountUpdatePage();
      default:
        assertNever(payload.modificationType);
    }
  });
}
