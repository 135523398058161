import {DefaultNavigationIntentHandlerMap} from '../../navigation/default-intent-handlers.config';
import {DefaultIntentHandlerMapBuilder} from '../default-intent-handler-map.builder';
import * as intents from '../../intents/intents.index';
import * as pages from '../../pages/pages.index';

export function collaborationDefaultIntentHandlersFactory(): DefaultNavigationIntentHandlerMap {
  const builder = new DefaultIntentHandlerMapBuilder();
  defineHandlers(builder.addHandler);
  return builder.build();
}

function defineHandlers(addHandler: DefaultIntentHandlerMapBuilder['addHandler']): void {
  addHandler(intents.ShowCollaborationOverviewIntent, () => new pages.CollaborationOverviewPage());
  addHandler(
    intents.ShowCollaborationDetailIntent,
    (intent) =>
      new pages.CollaborationDetailPage({
        collaborationIssueId: intent.payload.collaborationIssueId,
        collaborationIssueSubject: intent.payload.collaborationIssueSubject,
      })
  );
  addHandler(
    intents.CreateCollaborationInquiryIntent,
    (intent) =>
      new pages.CollaborationIssueCreationPage({collaborationIssueType: intent.payload.collaborationIssueType})
  );
}
