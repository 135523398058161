import {createAction, props} from '@ngrx/store';
import {getErrorState} from '../../../utils/error-state';
import {HolidayDto} from '../../models/holiday-dto.definitions';

export const loadHoliday = createAction('[WebAppCommon/Holiday] Load Holiday');
export const loadHolidaySuccess = createAction(
  '[WebAppCommon/Holiday] Load Holiday Success',
  props<{holidayDtoList: HolidayDto[]}>()
);
export const loadHolidayFailed = createAction('[WebAppCommon/Holiday] Load Holiday Failed', getErrorState);
