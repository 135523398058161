import {ActionWithoutResponse} from '@basuiz/mobile-bridge';

/**
 * Applet action that opens a native form that allows user to create a new
 * trading standing order.
 */
export class CreateSingleOrderAction extends ActionWithoutResponse<CreateSingleOrderRequest> {
  readonly name = 'createSingleOrder';
}

export interface CreateSingleOrderRequest {
  /**
   * Indication if the order should buy or sell the asset.
   * In case of 'sell' positionId or isin needs to be set.
   */
  orderType: 'sell' | 'buy';

  /**
   * Id of position which instrument is going to be bought or sold.
   */
  positionId?: number;

  /**
   * ISIN of the instrument that is going to be bought or sold.
   */
  isin?: string;
}
