import {BszUserProfileContactDetailsUINotification} from '@basuiz/web-app-applet-api';
import {UINotificationDialogAction, UINotificationDialogData} from '../portal-ui-notification-dialog.definitions';
import {TranslateService} from '@ngx-translate/core';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {ModifyUserDataIntent, ShowDashboardIntent} from '../../../intents/intents.index';
import {UINotificationDialogActionResolver} from '../portal-ui-notification-dialog-action-resolver';

export function getUserProfileContactDetailsUiNotificationDialogData(
  notification: BszUserProfileContactDetailsUINotification,
  translateService: TranslateService
): UINotificationDialogData {
  const {severity, message, remarks} = notification;
  return {
    severity,
    message,
    remarks,
    actions: getActions(notification, translateService),
  };
}

function getActions(
  notification: BszUserProfileContactDetailsUINotification,
  translateService: TranslateService
): UINotificationDialogAction[] {
  return [
    {
      label: translateService.instant(
        asTranslationKey(
          // 'web-app-portal.ui-notification-dialog.actions.link.label.user-profile.contact-details'
          'Show User Setting'
        )
      ),
      icon: 'list',
      resolve: new UINotificationDialogActionResolver(() => {
        // NOTE: the notification can contain some metadata under 'notification.relatedEntity', to be used to navigate into certain context
        console.log('1 - notification.relatedEntity:', notification.relatedEntity);
        return new ModifyUserDataIntent({modificationType: 'web-app-settings'});
      }),
      failed: true,
    },
    {
      label: translateService.instant(
        asTranslationKey(
          // 'web-app-portal.ui-notification-dialog.actions.link.label.user-profile.contact-details'
          'Show Home'
        )
      ),
      icon: 'description',
      resolve: new UINotificationDialogActionResolver(() => {
        // NOTE: the notification can contain some metadata under 'notification.relatedEntity', to be used to navigate into certain context
        console.log('2 - notification.relatedEntity:', notification.relatedEntity);
        return new ShowDashboardIntent();
      }),
    },
  ];
}
