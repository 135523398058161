import {Type} from '@angular/core';
import {View} from '@basuiz/mobile-bridge';
import {
  BSZ_WB_VIEW_NAMESPACE,
  CollaborationView,
  DashboardView,
  DebitCardListView,
  InvestmentProposalListView,
  NotificationsView,
  UserSettingsView,
} from '@basuiz/web-app-portal-hybrid-views';
import {
  ModifyUserDataIntent,
  ShowCollaborationOverviewIntent,
  ShowDashboardIntent,
  ShowInvestmentProposalsIntent,
  ShowNotificationsOverviewIntent,
  ShowPaymentCardsIntent,
} from '@basuiz/web-app-portal';
import {HybridViewHandler, HybridViewHandlerMap} from '../config/portal-hybrid.config.definition';

export function getDefaultViewHandlers(): HybridViewHandlerMap {
  const handlers: HybridViewHandlerMap = {};

  addHandlerFor(CollaborationView, () => new ShowCollaborationOverviewIntent());
  addHandlerFor(DashboardView, () => new ShowDashboardIntent());
  addHandlerFor(InvestmentProposalListView, () => new ShowInvestmentProposalsIntent());
  addHandlerFor(NotificationsView, () => new ShowNotificationsOverviewIntent());
  addHandlerFor(DebitCardListView, () => new ShowPaymentCardsIntent({tab: 'DEBIT_CARD'}));
  addHandlerFor(UserSettingsView, () => new ModifyUserDataIntent({modificationType: 'web-app-settings'}));

  return handlers;

  function addHandlerFor<VIEW extends View>(viewClass: Type<VIEW>, handler: HybridViewHandler<VIEW>): void {
    const viewName = new viewClass().name;
    if (!viewName.startsWith(BSZ_WB_VIEW_NAMESPACE)) {
      console.error(
        `Hybrid views handled by @basuiz/web-app-portal-hybrid should begin with the prefix '${BSZ_WB_VIEW_NAMESPACE}'. View '${viewName}' does not.`
      );
    }
    handlers[viewName] = handler;
  }
}
