export type Theme = 'light' | 'dark';

export class ThemeStyleLoader {
  private static readonly cacheKey = 'bsz.web-app-jungfrau.dev-tools.active-theme';
  private static readonly themes = ['light', 'dark'];
  private static readonly storage = window.localStorage;

  static toggleTheme(): Theme {
    const newTheme: Theme = this.getThemeFromStorage() === 'light' ? 'dark' : 'light';
    this.saveThemeInStorage(newTheme);
    this.loadTheme();
    return newTheme;
  }

  static loadTheme(): void {
    const theme: Theme = this.getThemeFromStorage();
    const styleSheet = `${theme}.css`;
    this.generateOrUpdateLinkElement(styleSheet);
  }

  private static generateOrUpdateLinkElement(styleSheetPath: string): void {
    const themeLink = document.getElementById('client-theme') as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = styleSheetPath;
    } else {
      const style = document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = styleSheetPath;

      document.head.appendChild(style);
    }
  }

  static saveThemeInStorage(theme: Theme): void {
    this.storage.setItem(this.cacheKey, theme);
  }

  static getThemeFromStorage(): Theme {
    const fetched: string = this.storage.getItem(this.cacheKey) as Theme | undefined;
    if (!fetched) {
      this.saveThemeInStorage('light');
      return 'light';
    }
    this.assertTheme(fetched);
    return fetched;
  }

  static assertTheme(value: string | undefined): asserts value is Theme | undefined {
    if (value) {
      if (!this.themes.includes(value)) {
        throw new Error(`Value fetched from the local storage is not a valid theme: ${value}`);
      }
    }
  }
}
