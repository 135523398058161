import {PortalModule} from '@angular/cdk/portal';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {BszI18nModule} from '@basuiz/i18n';

import {BszNotificationOverlay} from './bsz-notification-overlay';
import {NotificationOverlayRoot} from './bsz-notification-overlay.service';

@NgModule({
  imports: [CommonModule, BszI18nModule, MatButtonModule, PortalModule],
  declarations: [BszNotificationOverlay, NotificationOverlayRoot],
  entryComponents: [BszNotificationOverlay, NotificationOverlayRoot],
  exports: [BszNotificationOverlay],
})
export class BszNotificationOverlayModule {}
