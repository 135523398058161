import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  NgZone,
  Optional,
  Output,
  Self,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {BszSelectable, BszTreeSelectionModel} from '../bsz-selectable/index';
import {BszTreeRowDefinition, ExpandedGroupStatus, isGroupHeader, isTreeRowDefinitionCollection} from '../shared';
import {BszTreeRow, CollapsedState} from './bsz-tree-data-list.definitions';
import {BszTreeDataListGroupHeaderDef} from './bsz-tree-data-list-group-header-def';
import {BszTreeDataListHeaderDef} from './bsz-tree-data-list-header-def';
import {BszTreeDataListItemDef} from './bsz-tree-data-list-item-def';

const LOG_PREFIX = '[bsz-tree-data-list]';

@Component({
  selector: 'bsz-tree-data-list',
  templateUrl: './bsz-tree-data-list.html',
  styleUrls: ['./bsz-tree-data-list.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'bsz-tree-data-list',
  },
})
export class BszTreeDataList<T, G> implements AfterViewInit {
  @Input()
  set dataSource(dataSource: BszTreeRowDefinition<T, G>[]) {
    if (!isTreeRowDefinitionCollection(dataSource)) {
      throw new Error(`${LOG_PREFIX} Provided data source did not match BszTreeRowDefinition[]`);
    }

    this.dataSourceChange.next(dataSource);
    this.groupHeadersMetaData = this.getGroupHeadersMetaData(dataSource);
  }

  dataSourceChange = new BehaviorSubject<BszTreeRowDefinition<T, G>[]>([]);

  private groupHeadersMetaData: BszTreeRow[] = [];

  /**
   * function that returns true if the item should be expanded, using its
   * data as parameter
   */
  @Input()
  expandFn: ((data: T | G) => boolean) | null = null;

  @Input()
  get collapsed(): CollapsedState {
    return this.componentCollapsed;
  }

  set collapsed(componentCollapsed: CollapsedState) {
    this.componentCollapsed = componentCollapsed;
  }

  componentCollapsed: CollapsedState = true;

  @Output()
  collapsedChange = new EventEmitter();

  /**
   * Array with the information of the groups that should be expanded.
   * Any other group will be collapsed
   */
  @Input() set expandedGroups(expanded: (string | ExpandedGroupStatus)[] | null | undefined) {
    if (!expanded) {
      return;
    }

    this._expandedGroups = this.mapToExpandedGroupsStatus(expanded);
    this.isUserInteraction = false;
  }

  _expandedGroups: (string | ExpandedGroupStatus)[] | undefined;
  _expandedGroupsStatus: ExpandedGroupStatus[] | undefined;

  // set two-way binding to the "expandedGroups" input
  @Output() expandedGroupsChange = new EventEmitter<(string | ExpandedGroupStatus)[]>();

  /**
   * Custom template to render on top of the list.
   */
  @ContentChild(BszTreeDataListHeaderDef, {static: true, read: TemplateRef})
  headerTemplate: TemplateRef<unknown> | null = null;

  /**
   * Custom item template to render.
   */
  @ContentChild(BszTreeDataListGroupHeaderDef, {static: true, read: TemplateRef})
  groupHeaderTemplate: TemplateRef<G> | null = null;

  /**
   * Custom group header template to render.
   */
  @ContentChild(BszTreeDataListItemDef, {static: true, read: TemplateRef})
  itemTemplate: TemplateRef<T> | null = null;

  private isUserInteraction = false;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    @Optional() @Self() private readonly selectable: BszSelectable<T, G>
  ) {}

  ngAfterViewInit() {
    if (this.selectable) {
      this.selectable.connect(this.dataSourceChange, new BszTreeSelectionModel<T, G>());
    }
  }

  collapsedTreeChangeCallback(groupStatus: {groupId: string | undefined; collapsed: boolean}) {
    this.isUserInteraction = true;
    this.updateExpandedGroups(groupStatus);
    this.expandedGroupsChange.emit(this._expandedGroups);
    this.changeDetector.detectChanges();
    this.collapsedChange.emit();
  }

  /**
   * It adds/removes one group to the array of expanded:
   *    * If the group is already expanded, it will be removed -> so it will be collapsed
   *    * If it is collapsed, it will be added -> so it will be expanded
   */
  private updateExpandedGroups(groupStatus: {groupId: string | undefined; collapsed: boolean}) {
    const {groupId, collapsed} = groupStatus;
    if (!this._expandedGroups || !groupId) {
      return;
    }
    // Step 1: find the index of the group that will be modified
    const index = this._expandedGroups.findIndex((group: string | ExpandedGroupStatus) => {
      if (typeof group === 'string') {
        return group === groupId;
      }
      return group.groupId === groupId;
    });

    // Step 2: if it is currently expanded (and it is in the array), it will be removed (so it will collapse)
    if (!collapsed && index > -1) {
      this._expandedGroups.splice(index, 1);
    }
    // Step 3: if it is currently (and it is not in the array), it should be added (so it will expand)
    if (collapsed && index === -1) {
      this._expandedGroups.push({
        groupId: groupId,
        expandAncestors: false,
        expandDescendants: false,
      });
    }
  }

  /**
   * Generates an array with all groupHeaders (similar to what the table does but without including the data rows).
   * This array is meant to facilitate the work with the groups, their hierarchical relationship, collapsed status...
   */
  private getGroupHeadersMetaData(dataRows: BszTreeRowDefinition<T, G>[] | T[], ancestorRow?: BszTreeRow) {
    const rows: BszTreeRow[] = [];

    for (const row of dataRows) {
      const rowMetaData = this.getRowMetadata(row, ancestorRow);

      rows.push(rowMetaData);
      if (isGroupHeader(row)) {
        const children = this.getGroupHeadersMetaData(row.children, rowMetaData);
        // only take the groupHeaders
        const descendantGroupHeaders = children.filter((treeRow) => treeRow._isGroupHeader);
        rows.push(...descendantGroupHeaders);

        rowMetaData._children = descendantGroupHeaders;
      }
    }

    return rows;
  }

  /**
   * Create a simplified structure from a given BszTreeRowDefinition
   * that includes metadata regarding children, ancestors,
   * collapsed state, etc.
   */
  private getRowMetadata(row: BszTreeRowDefinition<T, G> | T, ancestorRow: BszTreeRow | undefined): BszTreeRow {
    const isHeader = isGroupHeader(row);
    const data = isHeader ? row.group : row;
    const groupId = isHeader && row.groupId ? row.groupId : undefined;

    return {
      _groupId: groupId,
      _ancestorRow: ancestorRow,
      _isGroupHeader: isHeader,
      _collapsed: true,
      _children: [],
      _originalRow: data,
    };
  }

  private mapToExpandedGroupsStatus(
    expanded: (string | ExpandedGroupStatus)[] | null | undefined
  ): (string | ExpandedGroupStatus)[] {
    if (!expanded) {
      return [];
    }
    const currentlyExpanded: ExpandedGroupStatus[] = [];

    expanded.forEach((data: string | ExpandedGroupStatus, index) => {
      if (typeof data == 'string') {
        currentlyExpanded.push({
          groupId: data,
          expandDescendants: false,
          expandAncestors: false,
        });
        const row = this.groupHeadersMetaData.find((treeRow: BszTreeRow) => treeRow._groupId === data);
        // consider the ancestors only when the expanded groups are defined/updated programmatically,
        // because when the user interacts (with the click to toggle the groups), the ancestors are not
        // meant to be expanded automatically
        if (row && !this.isUserInteraction) {
          this.getAncestors(row).forEach((ancestor) => {
            // if the ancestor is already included in the list of expanded groups, do nothing...
            if (currentlyExpanded.find(({groupId}) => groupId === ancestor.groupId)) {
              return;
            }
            // ...otherwise add it to the list
            currentlyExpanded.push(ancestor);
          });
        }
      } else {
        const {groupId, expandDescendants, expandAncestors} = data;
        currentlyExpanded.push(data);

        const relativesToExpand = this.getGroupRelatives(groupId, {expandDescendants, expandAncestors});
        currentlyExpanded.push(...relativesToExpand);
      }
    });

    // remove duplicates (there could be when different children have common ancestors)
    return [...new Set(currentlyExpanded)];
  }

  private getGroupRelatives(
    groupId: string,
    expandRelativesOptions: {expandDescendants: boolean | undefined; expandAncestors: boolean | undefined}
  ): ExpandedGroupStatus[] {
    const row = this.groupHeadersMetaData.find((treeRow: BszTreeRow) => treeRow._groupId === groupId);
    if (!row) {
      return [];
    }
    const {expandDescendants, expandAncestors} = expandRelativesOptions;
    const relatives: ExpandedGroupStatus[] = [];

    // by default, when not used, it expands the ancestors
    if (typeof expandAncestors === 'undefined' || expandAncestors) {
      relatives.push(...this.getAncestors(row));
    }
    if (expandDescendants) {
      relatives.push(...this.getDescendantsId(row));
    }
    return relatives;
  }

  private getAncestors(treeRow: BszTreeRow): ExpandedGroupStatus[] {
    const ancestors: ExpandedGroupStatus[] = [];
    const ancestorRow = treeRow._ancestorRow;
    if (ancestorRow) {
      const ancestorId = ancestorRow._groupId;
      if (ancestorId) {
        ancestors.push({
          groupId: ancestorId,
          expandDescendants: false,
          expandAncestors: false,
        });
      }
      ancestors.push(...this.getAncestors(ancestorRow));
    }

    return ancestors;
  }

  private getDescendantsId(treeRow: BszTreeRow): ExpandedGroupStatus[] {
    const descendants: ExpandedGroupStatus[] = [];
    const children = treeRow._children;
    children.forEach((child: BszTreeRow) => {
      const childrenId = child._groupId;
      if (childrenId) {
        descendants.push({
          groupId: childrenId,
          expandDescendants: false,
          expandAncestors: false,
        });
      }
      descendants.push(...this.getDescendantsId(child));
    });

    return descendants;
  }

  getExpandedIds(): string[] {
    return this.groupHeadersMetaData
      .filter((treeRow: BszTreeRow) => !treeRow._collapsed)
      .map((treeRow: BszTreeRow) => treeRow._groupId || '');
  }
}
