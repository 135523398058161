import {ModuleWithProviders, NgModule} from '@angular/core';
import {PortalBasicModule} from './util/portal-basic.module';
import {RouterModule} from '@angular/router';
import {BszConfigFactoryWithDeps} from '@basuiz/web-app-applet-api';
import {PortalLayoutModule} from './components/portal-layout/portal-layout.module';
import {PortalConfig} from './config/portal.config.definition';
import {portalConfigProvider} from './config/portal.config.provider';
import {PortalDebugService} from './debug/portal-debug.service';
import {NavigationModule} from './navigation/navigation.module';
import {getPageConfigProviders, PortalPageConfigFactoriesWithDeps} from './page-config/all-page-config-providers';
import {WebAppPortalComponent} from './web-app-portal.component';
import {DashboardIdService} from './navigation/dashboard-id.service';
import {PortalInitializationFailureConfig} from './components/portal-layout/portal-initialization-failure/portal-initialization-failure.config.definition';
import {getPortalInitializationFailureConfigProvider} from './components/portal-layout/portal-initialization-failure/portal-initialization-failure.config.provider';
import {WebAppInjectUponInitializationService} from '@basuiz/web-app-applet-sdk';
// import {SessionExpiresRedirectService} from './session-expires-redirect/session-expires-redirect.service';
import {NavigationService} from './navigation/navigation.service';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries,bsz-wbui/monorepo-import
import {getDisabledUINotificationSnackBarServiceProvider} from '@basuiz/web-app-common';
import {PortalUINotificationService} from './ui-notification/portal-ui-notification.service';
import {PortalUiNotificationModule} from './ui-notification/portal-ui-notification.module';
import {JwtModule} from '@auth0/angular-jwt';
import {jwtTokenKey} from '@basuiz/web-app-common';

/** Options for the web-app-portal */
export interface WebAppPortalModuleOptions {
  /** Optional. Configuration of the portal. */
  config?: BszConfigFactoryWithDeps<PortalConfig>;
  /** Optional configurations for each of the pages of the portal. */
  pageConfig?: PortalPageConfigFactoriesWithDeps;
  /** Optional configuration used to determine the view to use when web-app initialization fails */
  initializationFailure?: PortalInitializationFailureConfig;
}

const noProvider: [] = [];

@NgModule({
  imports: [
    PortalBasicModule,
    RouterModule,
    NavigationModule,
    PortalLayoutModule,
    PortalUiNotificationModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return localStorage.getItem(jwtTokenKey);
        },
        allowedDomains: ['TODO_BACKEND_API_URL'], // TODO p1: check if added to .env and/or change to real Backend Localhost and Cloud host (appenginge.google...)
        // throwNoTokenError: true,
      },
    }),
  ],
  exports: [WebAppPortalComponent],
  providers: [PortalDebugService, DashboardIdService],
})
export class WebAppPortalModule {
  static forRoot(options?: WebAppPortalModuleOptions): ModuleWithProviders<WebAppPortalModule> {
    return {
      ngModule: WebAppPortalModule,
      providers: [
        options?.config ? portalConfigProvider(options.config) : noProvider,
        ...(options?.pageConfig ? getPageConfigProviders(options.pageConfig) : noProvider),
        options?.initializationFailure
          ? getPortalInitializationFailureConfigProvider(options.initializationFailure)
          : noProvider,
        getDisabledUINotificationSnackBarServiceProvider(),
      ],
    };
  }

  constructor(private readonly injectUponInitializationService: WebAppInjectUponInitializationService) {
    /* Important! The following services depend on portal-configuration and cannot
     * be instantiated before the web-app is initialized successfully
     * */
    // this.injectUponInitializationService
    //   .injectUponSuccessfulInitialization(SessionExpiresRedirectService)
    //   .then((service) => service.redirectPageAfterSessionExpires());
    this.injectUponInitializationService.injectUponSuccessfulInitialization(NavigationService);
    this.injectUponInitializationService.injectUponSuccessfulInitialization(DashboardIdService);
    this.injectUponInitializationService.injectUponSuccessfulInitialization(PortalUINotificationService);
  }
}
