import {ComponentFixture} from '@angular/core/testing';
import {JestFixtureFacade, JestFixtureFacadeAware, MatMenuItem} from '../testing-core/index';

export class ContextActionMenuComponentPageObject implements JestFixtureFacadeAware {
  jestFixtureFacade: JestFixtureFacade;

  constructor(fixture: ComponentFixture<any>) {
    this.jestFixtureFacade = new JestFixtureFacade(fixture);
  }

  getCtxMenuItem(testId: string): MatMenuItem {
    return this.getMatMenuItem(testId);
  }

  openCtxMenu(row: number) {
    const menuTriggers: NodeListOf<Element> = this.jestFixtureFacade.selectAll('web-app-common-menu-trigger');
    const menuTrigger: HTMLElement = menuTriggers.item(row) as HTMLElement;

    menuTrigger.click();
  }

  private getMatMenuItem(testId: string): MatMenuItem {
    const menuItem: HTMLElement = this.jestFixtureFacade.selectFromParent(testId) as HTMLElement;

    return {
      label: menuItem.textContent || '',
      click: () => menuItem.click(),
    };
  }
}
