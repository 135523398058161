import {Inject, Injectable, Injector, Optional, Type} from '@angular/core';
import {first} from 'rxjs/operators';

import {BszNotificationOverlayConfig, BszNotificationOverlayService} from '../bsz-notification-overlay';
import {BszScreenSize} from '../bsz-screen-size-content-switcher';
import {BSZ_NOTIFICATION_TOAST_DEFAULT_OPTIONS, BszNotificationToastConfig} from './bsz-notification-toast.definitions';

const DEFAULT_CORE_CONFIG: BszNotificationOverlayConfig = {
  type: 'notification',
  politeness: 'polite',
  duration: 6000,
  maxStackLength: 0, // unlimited
  pauseOnHover: true,
  verticalPosition: 'top',
};

@Injectable({
  providedIn: 'root',
})
export class BszNotificationToastService {
  private readonly config: BszNotificationOverlayConfig;

  constructor(
    private readonly notificationOverlayService: BszNotificationOverlayService,
    private readonly screenSizeService: BszScreenSize,
    private readonly injector: Injector,
    @Optional() @Inject(BSZ_NOTIFICATION_TOAST_DEFAULT_OPTIONS) defaultConfig?: BszNotificationToastConfig
  ) {
    this.config = Object.assign({}, DEFAULT_CORE_CONFIG, defaultConfig);
  }

  /**
   * Opens the notification
   * @param componentRef Component to be instantiated as notification
   * @param config Configuration for the notification
   */
  open<T>(componentRef: Type<T>, config?: BszNotificationToastConfig): T {
    const configuration: BszNotificationOverlayConfig = Object.assign({}, this.config, config || {});

    this.screenSizeService
      .getScreenSize()
      .pipe(first())
      .subscribe((size) => {
        const isMobile = size === 'mobile';
        configuration.horizontalPosition = isMobile ? 'center' : 'right';
        configuration.maximized = isMobile;
      });

    return this.notificationOverlayService.openFromComponent(componentRef, configuration).component;
  }
}
