import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatRippleModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';

import {BszAnchorButton, BszButton, BszButtonIconPrefix, BszButtonIconSuffix} from './bsz-button';
import {BszFabButton} from './bsz-fab';

@NgModule({
  declarations: [BszButton, BszAnchorButton, BszButtonIconPrefix, BszButtonIconSuffix, BszFabButton],
  imports: [CommonModule, MatRippleModule, MatIconModule, MatTooltipModule, MatProgressSpinnerModule],
  exports: [BszButton, BszAnchorButton, BszButtonIconPrefix, BszButtonIconSuffix, BszFabButton],
})
export class BszButtonModule {}
