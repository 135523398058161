import {Pipe, PipeTransform} from '@angular/core';

import {DEFAULT_PERFORMANCE_DIGITS_INFO} from '../../const/digits-info.const';
import {postProcessLocalizedNumericValue} from './post-process-localized-numeric-value';
import {BszPercentagePipe} from './percentage.pipe';

@Pipe({name: 'bszPerformance'})
export class BszPerformancePipe implements PipeTransform {
  constructor(private percentagePipe: BszPercentagePipe) {}

  public transform(value: number | null | undefined, digitsInfo?: string): string {
    const formatted = this.percentagePipe.transform(value, digitsInfo ?? DEFAULT_PERFORMANCE_DIGITS_INFO);
    const prefix = value && value > 0 ? '+' : '';
    return prefix + formatted;
  }
}
