import {UserSettingsPageConfig} from './user-settings-page.config.definition';

// IMPORTANT: do not modify the signature of this function
export function validateUserSettingsPageConfig(config: UserSettingsPageConfig): string | undefined {
  /*
   * TODO: implement config validation
   *  - In case of validation failure return a string containing an explanation of what went wrong
   *  - In case of successful validation return undefined
   *  - Remove this comment after implementing the validation
   */
  return;
}
