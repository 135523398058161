import {NavigationIntent} from '../../navigation/classes/navigation-intent';
import {BszObjectId} from '@basuiz/web-app-applet-api';

export interface EditNotificationsSubscriptionIntentPayload {
  subscriptionEditId: BszObjectId;
}

export class EditNotificationsSubscriptionIntent extends NavigationIntent {
  constructor(public payload: EditNotificationsSubscriptionIntentPayload) {
    super();
  }
}
