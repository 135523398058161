import {createFeatureSelector, createSelector} from '@ngrx/store';
import {FEATURE_KEY, State} from './holiday.reducer';
import {getErrorMessage} from '../../../utils/error-state';
import {LoadingState} from '../../../definitions/call-state.definition';

export const selectState = createFeatureSelector<State>(FEATURE_KEY);

export const selectLoaded = createSelector(selectState, (state: State) => state.callState === LoadingState.LOADED);

export const selectLoading = createSelector(selectState, (state: State) => state.callState === LoadingState.LOADING);

export const selectError = createSelector(selectState, (state: State) => getErrorMessage(state.callState));

export const selectHolidayList = createSelector(selectState, (state) => state.holidayList);
