import {Nullable} from './nullable.type-util';
import {equalSets} from './array.util';
import {isDefined, isNotNullOrUndefined} from './defined.util';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function compareBy<T extends {[prop: string]: any}, KEY extends keyof T>(
  a: Nullable<T>,
  b: Nullable<T>,
  key: KEY
): boolean {
  return !!a && !!b && a[key] === b[key];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function compareById<T extends {id: any; [prop: string]: any}>(a: Nullable<T>, b: Nullable<T>): boolean {
  return compareBy(a, b, 'id');
}

// Shallow check if 2 objects are equals
export function compareByAllKeys(a: Readonly<Record<string, unknown>>, b: Readonly<Record<string, unknown>>) {
  if (!a || !b) {
    return false;
  }

  const keysA = Object.entries(a)
    .filter(([key, value]) => isDefined(value))
    .map(([key, value]) => key);
  const keysB = Object.entries(b)
    .filter(([key, value]) => isDefined(value))
    .map(([key, value]) => key);

  if (!equalSets(keysA, keysB)) {
    return false;
  }

  for (const key of keysA) {
    if (a[key] !== b[key]) {
      return false;
    }
  }
  return true;
}

/**
 * Returns a new obj that contains only defined properties from the source
 */
export function filterDefinedProperties<T extends Record<any, any>>(obj: T): Partial<T> {
  return Object.fromEntries(Object.entries(obj).filter(([key, value]) => isNotNullOrUndefined(value))) as Partial<T>;
}
