import {
  AfterViewChecked,
  Directive,
  ElementRef,
  EventEmitter,
  Host,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Self,
} from '@angular/core';
import {MatOption} from '@angular/material/core';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {AsyncSubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Directive({
  selector: 'mat-option[bsz-select-all]',
})
export class BszSelectAll implements OnInit, AfterViewChecked, OnDestroy {
  @Output() selectionChange = new EventEmitter();
  destroy = new AsyncSubject<void>();
  selectionStatus = false;

  @HostListener('onSelectionChange') toggleSelection(): void {
    if (this.option.selected) {
      this.option.deselect();
      return;
    }
    this.setSelectValue();
  }

  constructor(
    readonly elementRef: ElementRef<HTMLElement>,
    @Self() readonly option: MatOption,
    @Host() readonly select: MatSelect
  ) {}

  ngOnInit(): void {
    if (!this.select.multiple) {
      this.option.disabled = true;
      return;
    }
    this.select.selectionChange.pipe(takeUntil(this.destroy)).subscribe(() => {
      this.setCheckboxStatus();
    });
  }

  ngAfterViewChecked() {
    if (this.select.options) {
      // in case there are options initially selected
      this.setCheckboxStatus();

      // in case there are no options initially
      this.updateVisibilityStatus();

      this.select.options.changes.pipe(takeUntil(this.destroy)).subscribe((change) => {
        this.updateVisibilityStatus();
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  isAllSelected(): boolean {
    return this.getActiveOptions(this.select.options).every((option) => option.selected);
  }

  isPartiallySelected(): boolean {
    return !this.isAllSelected() && this.getActiveOptions(this.select.options).some((option) => option.selected);
  }

  getActiveOptions(options: QueryList<MatOption>): any[] {
    return options.filter((option) => !this.isSelectAllOption(option) && !option.disabled);
  }

  getActiveValues(options: QueryList<MatOption>): any[] {
    return this.getActiveOptions(options).map((option) => option.value);
  }

  // This set the status of the checkbox so for the users works normally but internally
  // it only simulates the three states that it can have
  setCheckboxStatus(): void {
    const optionElement = this.elementRef.nativeElement;
    // @ts-ignore
    const optionClassList = optionElement.querySelector('mat-pseudo-checkbox').classList;

    const checkedClass = 'mat-pseudo-checkbox-checked';
    const indeterminateClass = 'mat-pseudo-checkbox-indeterminate';

    if (this.isAllSelected()) {
      optionClassList.add(checkedClass);
      optionClassList.remove(indeterminateClass);
      this.setSelectionStatus(true);
      return;
    }

    if (this.isPartiallySelected()) {
      optionClassList.add(indeterminateClass);
      optionClassList.remove(checkedClass);
      this.setSelectionStatus(false);
      return;
    }

    optionClassList.remove(indeterminateClass);
    optionClassList.remove(checkedClass);
    this.setSelectionStatus(false);
  }

  setSelectionStatus(isSelected: boolean) {
    if (this.selectionStatus !== isSelected) {
      this.selectionStatus = isSelected;
      this.selectionChange.emit(isSelected);
      this.elementRef.nativeElement.setAttribute('aria-selected', String(isSelected));
    }
  }

  setSelectValue() {
    const selectValue = !this.isAllSelected() ? this.getActiveValues(this.select.options) : [];

    this.select.writeValue(selectValue);
    this.select._onChange(selectValue);

    this.emitSelectEvents();
  }

  emitSelectEvents() {
    const valueToEmit = (this.select.selected as MatOption[]).map((option) => option.value);
    this.select.selectionChange.emit(new MatSelectChange(this.select, valueToEmit));
    this.select.valueChange.emit(valueToEmit);
  }

  private updateVisibilityStatus() {
    const isVisible = this.select.options.length > 1;
    this.elementRef.nativeElement.style.display = isVisible ? '' : 'none';
    this.elementRef.nativeElement.setAttribute('aria-hidden', `${!isVisible}`);
    this.elementRef.nativeElement.setAttribute('role', isVisible ? 'option' : 'none');
  }

  isSelectAllOption(option: MatOption): boolean {
    return option._getHostElement().getAttribute('bsz-select-all') !== null;
  }
}
