import {HttpErrorResponse} from '@angular/common/http';
import {TranslatedText} from '@basuiz/web-app-applet-api';
import {AfpRestError, AfpRestNotification} from '@basuiz/web-app-common';

export function handleDaaSChangeAddressErrorResponse(maybeHttpErrorResponse: unknown): unknown {
  if (maybeHttpErrorResponse instanceof HttpErrorResponse) {
    /*
     * The Java REST end-point populates a 'payload' property with the contents of the error response from the
     * AMI-WS call.
     * https://bitbucket.sits.basuiz.net/projects/DAAS-MASTER/repos/rest-wb/browse/rest-service/src/main/java/com/basuiz/afpaas/master/rest/service/changeaddress/ChangeAddressController.java#68
     * The AMI-WS let the ACP developer to specify as response, in case of error, an object with a 'details'
     * property containing a text aimed to the end-user.
     * See type 'bszErrorsProblemDetails' in https://docs.basuiz.com/daas/r35.19.0/content/resources/other/change-contact.raml.zip
     *
     * In order to profit from the AfpRestError class, the logic below translates the received HttpErrorResponse to
     * a synthetic one using a standard AFP REST notification.
     * */
    const userIntendedErrorMessage: TranslatedText | undefined = maybeHttpErrorResponse.error?.payload.details;
    if (userIntendedErrorMessage) {
      /* Handle the case the end-point would be refactored to use the standard 'notifications' property */
      const existingNotifications: AfpRestNotification[] = Array.isArray(maybeHttpErrorResponse.error.notifications)
        ? maybeHttpErrorResponse.error.notifications
        : [];
      const newNotification: AfpRestNotification = {
        severity: 'ERROR',
        message: userIntendedErrorMessage,
        code: NaN,
      };
      const notifications = [newNotification, ...existingNotifications];
      const {url, headers, status, statusText} = maybeHttpErrorResponse;
      const afpRestLikeErrorResponse = new HttpErrorResponse({
        url: url ?? undefined,
        headers,
        status,
        statusText,
        error: {notifications},
      });
      return AfpRestError.create(afpRestLikeErrorResponse);
    }
  }
  return maybeHttpErrorResponse;
}
