import {UnionTypeFromArray} from '../../../../../utils/common';

// TODO p1: check and change to something like: https://docs.retool.com/org-users/concepts/permission-groups
export enum Role {
  // ============================
  // App User roles - START
  user = 'user',
  // App User roles - END
  // ============================
  // ============================
  // Admin roles - START
  superadmin = 'superadmin',
  adminViewer = 'adminViewer',
  adminEditor = 'adminEditor',
  adminFinance = 'adminFinance',
  // Admin roles - END
  // ============================
}

export const AdminRoles = [Role.superadmin, Role.adminFinance, Role.adminEditor, Role.adminViewer] as const;
export type AdminRoles = UnionTypeFromArray<typeof AdminRoles>;
