import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {BszSelectable, BszSelectableCheckbox, BszSelectableModule} from '../bsz-selectable/index';
import {BszDataList} from './bsz-data-list';
import {BszDataListHeaderDef} from './bsz-data-list-header-def';
import {BszDataListItemDef} from './bsz-data-list-item-def';

const EXPORTED_DECLARATIONS = [BszDataList, BszDataListItemDef, BszDataListHeaderDef];

@NgModule({
  declarations: EXPORTED_DECLARATIONS,
  imports: [CommonModule, BszSelectableModule],
  exports: [
    ...EXPORTED_DECLARATIONS,
    // re-export selectable classes
    BszSelectable,
    BszSelectableCheckbox,
  ],
})
export class BszDataListModule {}
