import {NgModule} from '@angular/core';
import {BszBusinessUnitSelectorComponent} from './bsz-business-unit-selector.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {TestingCoreModule} from '../testing-core/index';

@NgModule({
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslateModule,
    ReactiveFormsModule,
    CommonModule,
    TestingCoreModule,
  ],
  declarations: [BszBusinessUnitSelectorComponent],
  exports: [BszBusinessUnitSelectorComponent],
})
export class BszBusinessUnitSelectorModule {}
