import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {delay, Observable, of} from 'rxjs';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries,bsz-wbui/monorepo-import
import {AfpRestResponse} from '@basuiz/web-app-common';

@Injectable()
export class UserProfileMocksInterceptor implements HttpInterceptor {
  private contactDetailsCache: {} = {
    phoneNumber: '+41 44 123 45 67',
    mobileNumber: '+41 79 123 45 67',
    emailAddress: 'john.smith@basuiz.com',
    faxNumber: '+41 99 123 45 67',
  };
  private postalAddressCache: {} = {
    country: 'CH',
    city: 'Zurich',
    postalCode: '8001',
    streetName: 'Bahnhofstrasse',
    streetNumber: '123',
  };

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const parsedUrl = req.url.replace(/^\//, '');

    if (parsedUrl.startsWith('com.basuiz.afpaas.master.rest.services/changeaddress/persons/')) {
      if (req.method === 'GET') {
        if (parsedUrl.endsWith('/contactDetails')) {
          return this.getSuccessfulAfpHttpResponse(req, this.contactDetailsCache);
        }
        if (parsedUrl.endsWith('/domicileAddress')) {
          return this.getSuccessfulAfpHttpResponse(req, this.postalAddressCache);
        }
      }
      if (req.method === 'PUT') {
        if (parsedUrl.endsWith('/contactDetails')) {
          return this.getUpdateContactDetailsMockResponse(req);
        }
        if (parsedUrl.endsWith('/domicileAddress')) {
          return this.getUpdatePostalAddressMockResponse(req);
        }
      }
    }

    return next.handle(req);
  }

  private getUpdateContactDetailsMockResponse(req: HttpRequest<unknown>): Observable<HttpResponse<unknown>> {
    if (req.body instanceof Object) {
      this.contactDetailsCache = req.body;
    }
    return this.getSuccessfulAfpHttpResponse(req, this.contactDetailsCache);
  }

  private getUpdatePostalAddressMockResponse(req: HttpRequest<unknown>): Observable<HttpResponse<unknown>> {
    if (req.body instanceof Object) {
      this.postalAddressCache = req.body;
    }
    return this.getSuccessfulAfpHttpResponse(req, this.postalAddressCache);
  }

  private getSuccessfulAfpHttpResponse(
    {url, method}: HttpRequest<unknown>,
    payload: unknown
  ): Observable<HttpResponse<AfpRestResponse<unknown>>> {
    console.warn(`REST REQUEST ${method}:${url} mocked via HTTP interceptor. Payload mock:`, payload);
    return of(
      new HttpResponse<AfpRestResponse<unknown>>({
        status: 200,
        url,
        body: {
          payload,
          notifications: [],
        },
      })
    ).pipe(delay(200));
  }
}
