import {DefaultNavigationIntentHandlerMap} from '../../navigation/default-intent-handlers.config';
import {DefaultIntentHandlerMapBuilder} from '../default-intent-handler-map.builder';
import * as intents from '../../intents/intents.index';
import * as pages from '../../pages/pages.index';

export function investmentProposalsDefaultIntentHandlersFactory(): DefaultNavigationIntentHandlerMap {
  const builder = new DefaultIntentHandlerMapBuilder();
  defineHandlers(builder.addHandler);
  return builder.build();
}

function defineHandlers(addHandler: DefaultIntentHandlerMapBuilder['addHandler']): void {
  addHandler(
    intents.ShowInvestmentProposalDetailIntent,
    (intent) => new pages.InvestmentProposalDetailPage({investmentProposalId: intent.payload.investmentProposalId})
  );

  addHandler(intents.ShowInvestmentProposalsIntent, () => new pages.InvestmentProposalOverviewPage());

  addHandler(
    intents.ShowInvestmentProposalRequestChangesIntent,
    (intent) =>
      new pages.InvestmentProposalRequestChangesPage({investmentProposalId: intent.payload.investmentProposalId})
  );
}
