/**
 * Generic type for specifying configuration that can optionally be specialised for different devices.
 *
 * Typically applied to individual configuration properties rather than a whole applet configuration.
 */
export type BszConfigOrByDevice<CONFIG> = CONFIG | BszConfigByDevice<CONFIG>;

/**
 * Generic type for specifying configuration that is specialised for different devices.
 *
 * The device is currently determined by the viewport size using the breakpoints specified in the UI-SDK theme.
 * Currently supporting `mobile` and `desktop`. Everything larger than `mobile` is treated as `desktop`.
 *
 * Typically applied to individual configuration properties rather than a whole applet configuration.
 */
export interface BszConfigByDevice<CONFIG> {
  /**
   * The configuration to apply for the mobile view
   */
  mobile: CONFIG;

  /**
   * The configuration to apply for the desktop view
   */
  desktop: CONFIG;
}

/**
 * Type guard to identify if an `BszConfigOrByDevice` is an `BszConfigByDevice`.
 *
 * @param configOrByDevice
 */
export function isBszConfigByDevice<CONFIG>(
  configOrByDevice: BszConfigOrByDevice<CONFIG>
): configOrByDevice is BszConfigByDevice<CONFIG> {
  return (
    (configOrByDevice as BszConfigByDevice<CONFIG>).mobile !== undefined &&
    (configOrByDevice as BszConfigByDevice<CONFIG>).desktop !== undefined
  );
}

/**
 * Utility function to convert a single config into the `BszConfigByDevice` structure for
 * convenient use by the consuming code.
 *
 * @param config
 */
export function asBszConfigByDevice<CONFIG>(config: CONFIG): BszConfigByDevice<CONFIG> {
  return {
    mobile: config,
    desktop: config,
  };
}
