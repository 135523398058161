import {ExpandedFeatureToggle} from '@basuiz/web-app-feature-toggle';
import {ShowDashboardIntent} from '../intents/misc/show-dashboard.intent';
import {DefaultNavigationIntentHandlerMap} from '../navigation/default-intent-handlers.config';
import {DashboardPage} from '../pages/dashboard.page';
import {authJwtDefaultIntentHandlersFactory} from './domains/auth-jwt-default-intent-handlers';
import {collaborationDefaultIntentHandlersFactory} from './domains/collaboration-default-intent-handlers';
import {investmentProposalsDefaultIntentHandlersFactory} from './domains/investment-proposals-default-intent-handlers';
import {notificationsDefaultIntentHandlersFactory} from './domains/notifications-default-intent-handlers';
import {paymentCardsDefaultIntentHandlersFactory} from './domains/payment-cards-default-intent-handlers';
import {paymentMethodDefaultIntentHandlersFactory} from './domains/payment-method-default-intent-handlers';
import {shipmentDefaultIntentHandlersFactory} from './domains/shipment-default-intent-handlers';
import {userDefaultIntentHandlersFactory} from './domains/user-default-intent-handlers';

// Avoid passing the feature toggle nodes to domain specific handler factories as much as possible.
// The feature toggle is meant to be used only to steer configuration, the default intent handlers are not configuration.
export function getDefaultPortalIntentHandlers(
  featureToggle: ExpandedFeatureToggle
): DefaultNavigationIntentHandlerMap {
  return new Map([
    [ShowDashboardIntent, () => new DashboardPage()],
    ...authJwtDefaultIntentHandlersFactory(),
    ...collaborationDefaultIntentHandlersFactory(),
    ...investmentProposalsDefaultIntentHandlersFactory(),
    ...notificationsDefaultIntentHandlersFactory(),
    ...paymentCardsDefaultIntentHandlersFactory(),
    ...paymentMethodDefaultIntentHandlersFactory(),
    ...userDefaultIntentHandlersFactory(!!featureToggle.userProfile?.postalAddress),
    ...shipmentDefaultIntentHandlersFactory(),
  ]);
}
