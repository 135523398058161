import {FocusKeyManager} from '@angular/cdk/a11y';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  QueryList,
  ViewChild,
} from '@angular/core';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';

import {BszContextMenuItem} from './bsz-context-menu-item';

@Component({
  selector: 'bsz-context-menu',
  templateUrl: './bsz-context-menu.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BszContextMenu implements AfterContentInit {
  @ContentChildren(BszContextMenuItem) menuItems!: QueryList<BszContextMenuItem>;

  @ViewChild(MatMenu, {static: true}) menu!: MatMenu;
  @ViewChild(MatMenuTrigger, {static: true}) menuTrigger!: MatMenuTrigger;

  private keyManager!: FocusKeyManager<BszContextMenuItem>;

  ngAfterContentInit(): void {
    this.initKeyManager();
  }

  /**
   * Because we wrap the MatMenu and expose our own template api
   * we loose the support for keyboard navigation and we need to
   * set it up.
   *
   * MatMenu expects a collection of MatMenuItem elements in order
   * to initialize all the keyboard interaction and because we use
   * our own element (that extends the MatMenuItem nonetheless)
   * MatMenu won't recognize the elements and FocusKeyManager will
   * be initialized with an empty collection.
   */
  initKeyManager() {
    this.keyManager = new FocusKeyManager(this.menuItems).withWrap().withTypeAhead();
  }

  /**
   * Focus on the first item if we open the menu via keyboard.
   * We take the origin of the event from the MatMenuTrigger.
   *
   * The first item will be Highlighted only when we navigate
   * via keyboard.
   */
  focusFirstItem() {
    this.keyManager.setFocusOrigin(this.menuTrigger._openedBy || null).setFirstItemActive();
  }

  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      this.keyManager.setFocusOrigin('keyboard');
    }

    this.keyManager.onKeydown(event);
  }
}
