import {Injectable, Injector} from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {TokenizeService} from '../tokenize/tokenize.service';
import {CommonConfig, ɵCOMMON_CONFIG} from '@basuiz/web-app-common/config';
import {DownloadedFile} from '../definitions/document-file.definitions';
import {saveAs} from 'file-saver';
import {HttpClient} from '@angular/common/http';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {parameterizeHttpOptions} from '../utils/parameterize-http-options';
import {NotificationService} from '../ui-notification/index';
import {ExternalLinkService} from '../external-link/external-link.service';
import {isDefined} from '../utils/defined.util';
import {HybridActionHandled, HybridActionNotHandled, HybridActionService} from '@basuiz/web-app-hybrid';
import {assertNever} from '../utils/assert-never';

@Injectable()
export class DocumentDownloadService {
  constructor(
    private injector: Injector,
    private tokenizeService: TokenizeService,
    private httpClient: HttpClient,
    private externalLinkService: ExternalLinkService,
    private hybridActionService: HybridActionService
  ) {}

  private get commonConfig(): CommonConfig {
    return this.injector.get(ɵCOMMON_CONFIG);
  }

  private get notificationService(): NotificationService {
    return this.injector.get(NotificationService);
  }

  /**
   * This method downloads and saves a document from document safe endpoint
   * @param options list of optional parameters that can be:
   * - documentIds the list of documents to be downloaded
   * - grouped whether the id is coming from a grouping action.
   * - tradingDocumentId the list of uuids that identify a trading document
   * At least either documentIds or tradingDocumentId must be provided
   */
  downloadDocuments(options: {
    documentId?: number[];
    grouped?: boolean;
    tradingDocumentId?: string[];
  }): Observable<DownloadedFile | undefined> {
    return from(this.downloadDocumentsNative(options)).pipe(
      switchMap((nativeResult) => {
        switch (nativeResult) {
          case 'unhandled':
            return this.downloadDocumentsWeb(options);
          case 'triggered':
            return of(undefined);
          case 'unsupported':
            return of(undefined);
          case 'failed':
            return of(undefined);
          default:
            assertNever(nativeResult);
        }
      })
    );
  }

  private async downloadDocumentsNative({
    documentId,
    grouped,
    tradingDocumentId,
  }: {
    documentId?: number[];
    grouped?: boolean;
    tradingDocumentId?: string[];
  }): Promise<HybridActionNotHandled | HybridActionHandled | 'unsupported' | 'failed'> {
    if (!this.hybridActionService.isInsideNative()) {
      return 'unhandled';
    }

    if (grouped || isDefined(tradingDocumentId)) {
      return 'failed';
    }

    if (!isDefined(documentId)) {
      // this should never happen, this would mean none of the 3 options were passed, a better model would fix this
      return 'failed';
    }

    // const nativeResult = await this.hybridActionService.viewDocumentSafeDocument({documentId: documentId[0]}); // TODO clean

    // return nativeResult === 'unhandled' ? 'failed' : nativeResult;
    return 'failed';
  }

  private downloadDocumentsWeb(options: {
    documentId?: number[];
    grouped?: boolean;
    tradingDocumentId?: string[];
  }): Observable<DownloadedFile> {
    const token$: Observable<string | undefined> = this.commonConfig.documentDownload.useJWT
      ? this.tokenizeService.getToken('/documentsafe/eDocData')
      : of(undefined);

    return token$.pipe(
      switchMap((token) => this.fetch({token, ...options})),
      tap((downloadedFile: DownloadedFile) => this.handleDocument(downloadedFile))
    );
  }

  /**
   * Handles a received document in order to open or download it in the user file system
   *
   * @param document the document that has been received from the backend
   */
  private handleDocument(document: DownloadedFile) {
    if (this.canOpenFileInBrowser()) {
      const url = URL.createObjectURL(document.file);
      this.externalLinkService.open(url);
    } else {
      try {
        saveAs(document.file, document.name);
      } catch (e) {
        throw new Error("You're using an outdated browser that doesn't support the download: " + e);
      }
    }
  }

  /**
   * Checks whether the document can be open in a the browser depending on the configuration set up in the common
   * settings and whether the browser is chrome or firefox
   * @return a boolean, when true, the document can be directly open in the browser. When false, it should be downloaded.
   */
  private canOpenFileInBrowser(): boolean {
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    //http://www.javascripter.net/faq/browsern.htm
    const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;

    return this.commonConfig.documentDownload.openDownloadedFilesInBrowser && (isFirefox || isChrome);
  }

  /**
   * Retrieves a list of documents
   * @param paramValues list of optional parameters that can be:
   * - documentIds the list of documents to be downloaded
   * - grouped whether the id is coming from a grouping action. When true => metadataId, when false => documentContractId
   * - tradingDocumentId the list of uuids that identify a trading document
   * - token a token needed when using jwt solution
   * At least either documentIds or tradingDocumentId must be provided
   */
  private fetch(paramValues: {
    documentId?: number[];
    grouped?: boolean;
    tradingDocumentId?: string[];
    token?: string;
  }): Observable<DownloadedFile> {
    return this.httpClient
      .get('/com.basuiz.afs.rest.services/rest/documentsafe/eDocData', {
        params: parameterizeHttpOptions(paramValues),
        observe: 'response',
        responseType: 'arraybuffer',
      })
      .pipe(
        map((res) => ({
          name:
            /filename[^;=\n]*=\s?"((['"]).*?\2|[^;\n]*)"/.exec(res.headers.get('Content-Disposition') ?? '')?.[1] ??
            'attachment.pdf',
          file: new Blob([res.body as ArrayBuffer], {type: res.headers.get('Content-Type') ?? 'application/pdf'}),
        })),
        catchError((error) => {
          this.notificationService.error(asTranslationKey('web-app-common.notification-downloading-error'));
          return throwError(error);
        })
      );
  }
}
