import {Subset} from './subset';
import {TranslationKey} from './translation-key';
import {UnionTypeFromArray} from './union-type-from-array';

/**
 * Generic types applets use to allow configuring different table column-layout features:
 * - BszTableColumns: used by applets supporting a single column-layout for all display-formats,
 *     or different column-layouts for different display-formats
 * - BszTableMultiValueColumn: used by applets supporting multi-value cells.
 * - BszTableColumnWithCustomHeader: allow configuring custom column headers (titles), specially useful
 *     combined with multi-value cells.
 *
 * Only certain applets support these features, and usually a applet will only support a subset of these.
 * Please consult applet configuration documentation to identify the features supported.
 */

/** Allow configuring a table's column-layout differently per display-format.
 * Applets using this pattern expect either a generic list of columns to use in all display-formats,
 * or separate lists linked to a specific display-format.
 * When a list for a given display-format is not specified, the applet uses the 'desktop' display-format as fallback,
 * this includes cases when the screen size falls inside the threshold of the 'tablet' breakpoint.
 * Notice that different applets might support different subsets of display-formats,
 * and the list of display-formats a given applet supports can grow over time.
 * */
export type BszTableColumns<COLUMN, OPTIONAL_FORMAT extends BszTableColumnsDisplayOptionalFormat = 'mobile'> =
  /** table columns for all display formats */
  | COLUMN[]
  | ({
      desktop: COLUMN[];
    } & {
      [prop in OPTIONAL_FORMAT]?: COLUMN[];
    });

/** Allow configuring the column-layout of a table in an export.
 *  Similar to BszTableColumns, however, this type is used by applets that do not render a table on the browser,
 *  therefore, the collection of formats is limited to export related ones.
 *
 *  When the value is an array of columns, it will be used for all formats.
 *
 *  When the value is an object whose keys identify display formats, the applet will use the array assigned to the
 *  display format the user has chosen.
 *  Since all display-format properties are optional, if a value is not present for that display format, the applet
 *  will fallback to an internal default column-layout.
 *  */
export type BszTableColumnsExportOnly<
  COLUMN,
  OPTIONAL_FORMAT extends BszTableColumnsExportDisplayFormat = BszTableColumnsExportDisplayFormat
> = COLUMN[] | Partial<Record<OPTIONAL_FORMAT, COLUMN[]>>;

/** List of all possible display formats related to a table's column-layout */
export const BszTableColumnsDisplayFormat = [
  'desktop', // Table rendered in the browser on a desktop screen-size
  'mobile', // Table rendered in the browser on a mobile screen-size
  'pdf', // Table rendered in an .pdf file exported by the applet
  'csv', // Table rendered in an .csv file exported by the applet
  'excel', // Table rendered in an Excel file exported by the applet
] as const;
export type BszTableColumnsDisplayFormat = UnionTypeFromArray<typeof BszTableColumnsDisplayFormat>;

export type BszTableColumnsDisplayOptionalFormat = Exclude<BszTableColumnsDisplayFormat, 'desktop'>;

export type BszTableColumnsExportDisplayFormat = Subset<BszTableColumnsDisplayFormat, 'pdf' | 'excel' | 'csv'>;

/** Configures a column whose cells might contain a single or multiple values */
export type BszTableMultiValueColumn<FIELD> = FIELD | FIELD[];

/** Configures a column overwriting the header translation key */
export interface BszTableColumnWithCustomHeader<COLUMN> {
  translationKey: TranslationKey;
  column: COLUMN;
}

/** Configures a column that may have a custom translation key for the header */
export type BszTableColumnWithCustomisableHeader<COLUMN> = COLUMN | BszTableColumnWithCustomHeader<COLUMN>;

export function isTableColumnWithCustomHeader<COLUMN>(
  column: BszTableColumnWithCustomisableHeader<COLUMN>
): column is BszTableColumnWithCustomHeader<COLUMN> {
  return (column as BszTableColumnWithCustomHeader<COLUMN>).translationKey !== undefined;
}
