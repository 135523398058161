import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import * as fromNotifications from './+state/notifications/notifications.reducer';
import {EffectsModule} from '@ngrx/effects';
import {NotificationsEffects} from './+state/notifications/notifications.effects';
import {NotificationsService} from './data-access/notifications.service';

@NgModule({
  imports: [
    StoreModule.forFeature(fromNotifications.FEATURE_KEY, fromNotifications.reducer),
    EffectsModule.forFeature([NotificationsEffects]),
  ],
  providers: [NotificationsService],
})
export class WebAppSelfServiceNotificationsModule {}
