import {JestFixtureFacade, JestFixtureFacadeAware} from './jest-fixture-facade';
import {SimpleElement} from '../generic/components';

export class JestSimpleElement implements JestFixtureFacadeAware, SimpleElement {
  constructor(public testId: string, public jestFixtureFacade: JestFixtureFacade) {}

  get element(): Element {
    return this.jestFixtureFacade.select(this.testId);
  }

  get textContent(): string | null {
    return this.element.textContent;
  }
}
