import {TerritoryBaseId} from '../../../address/data-access/address.model';
import {CollectiveCarrierToken} from '../../../collective-carrier/data-access/collective-carrier.model';
import {COMMON_MAX_GOODS_COLLECTION} from '../../../shipment/data-access/shipment.model';
import {
  SERVIENTREGA_MAX_GOODS_COLLECTED,
  SERVIENTREGA_MAX_GOODS_INSURED,
} from '../ec-servientrega/servientrega-label-generate.model';
import {EcServientregaTerritoryList} from '../ec-servientrega/territory/ec-servientrega-territory.list';
import {EC_TRAMACO_MAX_GOODS_COLLECTED, EC_TRAMACO_MAX_GOODS_INSURED} from '../ec-tramaco/ec-tramaco-label.model';
import {EcTramacoTerritoryList} from '../ec-tramaco/territory/ec-tramaco-territory.list';
import {EcBaseTerritoryList} from './ec-base-territory.list';
import {EcAllTerritoryItemType, EcBaseTerritoryItem} from './ec-base-territory.model';

export function findTerritoryBaseItem(territoryBaseIdToSearch: string): EcBaseTerritoryItem | null {
  const baseTerritory: EcBaseTerritoryItem | undefined = EcBaseTerritoryList.find(
    ({territoryBaseId}) => territoryBaseId === territoryBaseIdToSearch
  );

  if (!baseTerritory || !baseTerritory?.territoryBaseId) {
    return null;
  }

  return baseTerritory;
}

export function findTerritoryCarrierItem(
  collectiveCarrierToken: CollectiveCarrierToken,
  territoryBaseId: TerritoryBaseId
): EcAllTerritoryItemType | null {
  let carrierTerritoryList = [];

  switch (collectiveCarrierToken) {
    case CollectiveCarrierToken.servientrega:
      carrierTerritoryList = EcServientregaTerritoryList;
      break;
    case CollectiveCarrierToken.ec_tramaco:
      carrierTerritoryList = EcTramacoTerritoryList;
      break;
    // // NOTE (NEW_CARRIER): add here a new carrier territory list to be used for the search
    // case CollectiveCarrierToken.<NEW_CARRIER>:
    //   carrierTerritoryList = Ec<NEW_CARRIER>TerritoryList;
    //   break;
    default:
      return null;
  }

  if (!carrierTerritoryList.length) {
    return null;
  }

  const addressTerritoryItem: EcAllTerritoryItemType | undefined = carrierTerritoryList.find(
    (carrierTerritoryItem) => carrierTerritoryItem.territoryBaseId === territoryBaseId
  );

  if (!addressTerritoryItem || !addressTerritoryItem?.territoryBaseId) {
    return null;
  }

  return addressTerritoryItem;
}

export function getDefaultCarrierMaxGoodsInsured(): number {
  // TODO p2, a higher value of insurance from one courier could be allowed in the FE. This function limits the selection of the lowest possible value for goodsInsured, when a courier could allow a higher value, and we should allow to insert in the FE this higher value
  return Math.min(
    SERVIENTREGA_MAX_GOODS_INSURED,
    EC_TRAMACO_MAX_GOODS_INSURED
    // <NEW_CARRIER>_MAX_GOODS_INSURED,
  );
}

export function getDefaultCarrierMaxGoodsCollection(): number {
  // TODO p1, a higher collection from one courier could be allowed in the FE. This function limits the selection of the lowest possible value for goodsCollection, when a courier could allow a higher value, and we should allow to insert in the FE this higher value
  return Math.min(
    COMMON_MAX_GOODS_COLLECTION,
    SERVIENTREGA_MAX_GOODS_COLLECTED,
    EC_TRAMACO_MAX_GOODS_COLLECTED
    // <NEW_CARRIER>_MAX_GOODS_COLLECTED,
  );
}
