import {PortalPage, PortalPageClass} from './portal-page';
import {NavigationService, PortalPageWithNotifier} from '../navigation.service';
import {AppLoadsPage} from './app-loads-page';
import {Directive, OnDestroy, Type} from '@angular/core';
import {NavigationIntent} from './navigation-intent';
import {concat, identity, Observable, of, Subscription} from 'rxjs';
import {delay, distinctUntilChanged, filter, map, switchMap, takeWhile, tap} from 'rxjs/operators';
import {BszAppletContextRequest} from '@basuiz/web-app-applet-api';
import {NavigationOptions} from '../navigation-options';

class UseMethodOnDestroy {} // do not export this class!

@Directive()
export abstract class PortalPageComponent<PAGE extends PortalPage> implements OnDestroy {
  /* This private property prevents accidental assignments of other objects
   * to a variable/property declared as a PortalPageComponent. For more info, search 'Nominal typing in Typescript'
   * */
  private __nominal: void;

  private __handleFirstNavigationSubscription: Subscription;
  private __triggerPageReadyNotifierSubscription: Subscription;

  /** Emits the current PortalPage of the navigation service. Notice that the page instance can change while the
   * page component remains on the screen, e.g. a navigation can result in the same route but a different payload,
   * in this case the page component will not be re-created, therefore it needs to be able to react
   * to a new page instance being passed by the navigation service with different data
   * */
  public readonly page$: Observable<PAGE> = this.navigationService.currentPortalPage$.pipe(
    filter((page) => page instanceof this.portalPageClass),
    map((ppi) => ppi as PAGE)
  );

  /** Emits a applet-context based on the value returned by a call to getAppletContextRequest$ without arguments.
   *  In more advance scenarios, e.g. in pages with multiple tabs using the same applet,
   *  you might need to call getAppletContextRequest$ directly in your child of PortalPageComponent class.
   *  Check method getAppletContextRequest$ for more details.
   * */
  public readonly appletContextRequest$: Observable<BszAppletContextRequest | undefined> =
    this.getAppletContextRequest$();

  protected getAppletContextIdSuffix(page: PAGE): string {
    return '';
  }

  /** Returns a stream of applet-context requests on every emission of PortalPageComponent.portalPage$.
   *  The emitted applet-context requests will have the 'onInit' property depend on the property 'needsReset'
   *  of the current PortalPage instance.
   *
   *  The applet-context Id in the applet-context request will include a prefix that uniquely identifies the
   *  PortalPage class.
   *  The method can be passed a 'tabId' argument to specify a special context for specific tabs.
   *  Useful if multiple tabs contain applets with contexts.
   *  Each must subscribe to its own applet-context request stream to ensure that applet-context resets & restores
   *  are applied correctly in all tabs.
   *  Finally, the applet-context Id can be assigned a suffix based on the data stored in the PortalPage instance
   *  by overriding method 'getAppletContextIdSuffix'.
   *  E.g. if the page includes a portfolio selector, add as suffix the id of the portfolio currently on display
   *  to be able to store different contexts for different entities and therefore to be able to restore them.
   *
   *  For the specific case of pages with entity selectors, the expected behavior is to preserve the context
   *  of previous entities when the navigation triggered by selecting a new entity and a new page instance is created
   *  (even though the PortalPageComponent instance is not re-created).
   *  In those cases the PortalPageComponent instance should
   *  set to FALSE the navigation option 'shouldResetAppletContextInSamePageNavigation'
   *
   *  Child classes of PortalPageComponent that require a different logic for PortalPage.needsReset
   *  (e.g. because there more to reset than the applet-context) should override this method.
   *  */
  protected getAppletContextRequest$({tabId}: {tabId?: string} = {}): Observable<BszAppletContextRequest | undefined> {
    let firstEmission = true;
    const appletContextIdPrefix = this.asPortalPageClass().routerConfigPath + '#' + (tabId ?? '') + '#';

    return this.page$.pipe(
      distinctUntilChanged(),
      switchMap((page) => {
        const appletContextId: string = appletContextIdPrefix + this.getAppletContextIdSuffix(page);
        const wasAlreadyReset = page.ɵresetMemory.alreadyResetContextIdSet.has(appletContextId);
        const onInit: BszAppletContextRequest['onInit'] = page.needsReset && !wasAlreadyReset ? 'reset' : 'restore';
        const appletContextRequest: BszAppletContextRequest = {appletContextId, onInit};
        return concat(
          // an undefined followed by the context request 10ms later will kill the applet instance inside an *ngIf and create it again
          of(undefined),
          of(appletContextRequest).pipe(
            firstEmission ? identity : delay(10),
            tap(() => {
              firstEmission = false;
              if (onInit === 'reset') {
                // important! record alreadyResetContextId only after the delay, otherwise applet might not have reset and page will never request reset again
                page.ɵresetMemory.alreadyResetContextIdSet.add(appletContextId);
              }
            })
          )
        );
      })
    );
  }

  protected constructor(private portalPageClass: Type<PAGE>, private navigationService: NavigationService) {
    this.watchForFirstNavigation();
    this.watchForPageReadyNotifiers();
  }

  private watchForFirstNavigation() {
    /* this operation must be done independently on whether the child component subscribes to pages$ or not */
    this.__handleFirstNavigationSubscription = this.navigationService.currentPortalPage$
      .pipe(takeWhile((page) => !(page instanceof this.portalPageClass)))
      .subscribe((page) => {
        if (page instanceof AppLoadsPage) {
          this.handleFirstNavigation();
        }
      });
  }

  /* Navigations triggered via NavigationService.navigate() pass a 'notifier' to be triggered when the page
   * instantiated due to the navigation is ready */
  private watchForPageReadyNotifiers() {
    /* this operation must be done independently on whether the child component subscribes to pages$ or not */
    this.__triggerPageReadyNotifierSubscription = this.navigationService.ɵcurrentPortalPageWithNotifier$
      .pipe(
        filter(
          (pageWithNotifier): pageWithNotifier is PortalPageWithNotifier =>
            pageWithNotifier?.page instanceof this.portalPageClass
        )
      )
      .subscribe((pageWithNotifier) => {
        setTimeout(() => {
          //delay the emission to give the subscriber time to setup the subscription for pageReadyNotifier
          const {pageReadyNotifier} = pageWithNotifier;
          if (pageReadyNotifier && !pageReadyNotifier.isStopped) {
            pageReadyNotifier.next(undefined);
            pageReadyNotifier.complete();
          }
        });
      });
  }

  /** Do not override this method in child classes, instead, override method 'onDestroy' */
  ngOnDestroy(): UseMethodOnDestroy {
    this.__handleFirstNavigationSubscription.unsubscribe();
    this.__triggerPageReadyNotifierSubscription.unsubscribe();

    this.onDestroy(); // child's own logic to execute when the page component is destroyed

    return new UseMethodOnDestroy(); // Dissuades child classes of overriding this method by showing an error
  }

  /** Method called by PortalPageComponent's own 'ngOnDestroy' method.
   * Child classes cannot implement their own 'ngOnDestroy' method, implement this method instead.
   * */
  protected onDestroy(): void {
    // do nothing
  }

  private asPortalPageClass(): PortalPageClass {
    return this.portalPageClass as unknown as PortalPageClass;
  }

  /* When the app loads on a given routes (e.g. the user enters an URL directly on the browser, the navigation service
   * cannot provide an instance for the PortalPage class under that route. Instead it provides the page an AppLoadsPage
   * to give the page an opportunity to auto-generate its own page instance.
   * This is not possible for pages that require a payload, these pages should use the InitialNavigationRedirect guard
   * to indicate the portal what route/page should be used instead when the app is loading in this route.
   * */
  private handleFirstNavigation(): void {
    const ppiClass = this.portalPageClass;
    let ppi: PAGE;
    try {
      ppi = new ppiClass();
    } catch (e) {
      console.error('Could not create a page without parameters');
      throw e;
    }
    this.navigationService.ɵreplaceAppLoadsPage(ppi);
  }

  protected navigate(intent: NavigationIntent, options?: NavigationOptions) {
    this.navigationService.navigate(intent, ...(options ? [options] : []));
  }

  protected navigateToPreviousPageWithFallback(fallbackIntent: NavigationIntent) {
    this.navigationService.navigateToPreviousPageWithFallback(fallbackIntent);
  }
}
