import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

/**
 * Service that retrieves the list of document categories
 */
@Injectable({providedIn: 'root'})
export class TokenizeService {
  constructor(private http: HttpClient) {}

  url = '/com.basuiz.afs.rest.services/rest/technical/tokenize';

  /**
   * Retrieves the token that allows to consume JWT services
   * @param path the identifier of the type of service for which the jwt token is needed
   */
  getToken(path: string): Observable<string> {
    const params = {path};
    return this.http.get(this.url, {params, responseType: 'text'});
  }
}
