import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {BszI18nModule} from '@basuiz/i18n';

import {BszAmount} from './bsz-amount';

@NgModule({
  declarations: [BszAmount],
  imports: [CommonModule, BszI18nModule, ReactiveFormsModule],
  exports: [BszAmount],
})
export class BszAmountModule {}
