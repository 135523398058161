<div class="bsz-text-center bsz-margin-x2 bsz-padding-3">
  <h3 mat-dialog-title class="bsz-title-5">About</h3>

  <div mat-dialog-content [style]="{overflow: 'unset'}">
    <div class="bsz-body-1 bsz-text-bold">{{ libName }}</div>
    <div class="bsz-body-2">{{ uiVersion }}</div>
    <div class="bsz-body-1 bsz-text-bold">{{ backendName }}</div>
    <div class="bsz-body-2">{{ backendVersion }}</div>
    <div class="bsz-body-1 bsz-text-bold bsz-margin-t2">Angular</div>
    <div class="bsz-body-2">{{ angularVersion }}</div>
  </div>

  <div mat-dialog-actions class="bsz-display-mobile-none bsz-margin-t3">
    <button
      mat-raised-button
      style="width: 100%"
      (click)="logBasuizInfo()"
      matTooltip="Run basuiz.info() in the console"
      >basuiz.info()</button
    >
  </div>
</div>
