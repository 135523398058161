import {inject, InjectionToken} from '@angular/core';
import {ExpandedFeatureToggle} from './models/features-expanded.definition';
import {expandFeatureToggle} from './expand-feature-toggle';
import {getDefaultFeatureToggle} from './feature-toggle.default';
import {UserRoleService} from '@basuiz/web-app-common';

export const WEB_APP_EXPANDED_FEATURE_TOGGLE = new InjectionToken<ExpandedFeatureToggle>(
  'bsz.web-app.expanded-feature-toggle',
  {
    factory: () => expandFeatureToggle(getDefaultFeatureToggle(inject(UserRoleService))),
  }
);
