import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {FeatureToggleDevToolComponent} from './feature-toggle/feature-toggle-dev-tool.component';
import {UserRolesDevToolComponent} from './user-roles-dev-tool/user-roles-dev-tool.component';
import {AboutInfoDevToolComponent} from './about-info-dev-tool/about-info-dev-tool.component';
import {afpRolesLocalStoreKey} from './user-roles-dev-tool/user-roles.interceptor';
import {featureToggleLocalStorageKey} from './feature-toggle/feature-toggle.persistence';
import {WebAppAppletService, WebAppInitializationResult, WebAppInitializationService} from '@basuiz/web-app-common';
import {Observable} from 'rxjs';

@Component({
  selector: 'bsz-web-app-dev-tools',
  templateUrl: './web-app-dev-tools.component.html',
  styleUrls: ['./web-app-dev-tools.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebAppDevToolsComponent {
  public userRolesToolActive: boolean = !!localStorage.getItem(afpRolesLocalStoreKey);
  public featureToggleToolActive: boolean = !!localStorage.getItem(featureToggleLocalStorageKey);
  public version: string = window?.basuiz?.versions?.ui['@basuiz/web-app'];

  public maximized: boolean = this.userRolesToolActive || this.featureToggleToolActive;

  readonly initializationResult$: Observable<WebAppInitializationResult> =
    this.webAppInitializationService.getInitializationResult$();
  readonly getRevealBoundaryAndName$: Observable<boolean> = this.webAppAppletService.getRevealBoundaryAndName();

  constructor(
    public dialog: MatDialog,
    private webAppAppletService: WebAppAppletService,
    private webAppInitializationService: WebAppInitializationService
  ) {}

  openFeatureToggle() {
    this.dialog.open(FeatureToggleDevToolComponent, {maxWidth: 'initial'});
  }

  openUserRoles() {
    this.dialog.open(UserRolesDevToolComponent, {maxWidth: 'initial'});
  }

  openAboutInfo() {
    this.dialog.open(AboutInfoDevToolComponent, {maxWidth: 'initial'});
  }

  onToggleRevealApplets() {
    this.webAppAppletService.toggleRevealBoundaryAndName();
  }
}
