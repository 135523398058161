import {InjectionToken} from '@angular/core';
import {BszFactoryProviderGenerator} from '@basuiz/web-app-applet-api';

/**
 * Default date format applets will use when the applet does not define a specific format itself.
 * When undefined, applets will use the format that happens to be the default in MomentJs.
 * E.g provided date format 'l' will return '9/4/1986';
 * 'https://momentjs.com/docs/#/displaying/format/' => supported date formats.
 */
export type DefaultMomentDateFormat = string | undefined;

export const ɵDEFAULT_DATE_FORMAT = new InjectionToken<DefaultMomentDateFormat>(
  'bsz.web-app-common.formatting.default-date-format',
  {
    factory: () => 'll',
  }
);

/**
 * Factory provider for convinient override of the default date format style. Default date format will be
 * applied throughout the applets.
 */
export const provideDefaultDateFormat: BszFactoryProviderGenerator<DefaultMomentDateFormat> = (useFactory, deps) => ({
  provide: ɵDEFAULT_DATE_FORMAT,
  useFactory,
  deps,
});
