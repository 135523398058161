import {FactoryProvider, InjectionToken} from '@angular/core';
import {AppShellOutletBroker} from './app-shell-outlet-broker';
import {AppShellOutletCategory} from './app-shell-outlet-category.definition';
import {AppShellOutletBrokerRequestHandler} from './app-shell-outlet-broker-request-handler.definition';
import {appShellOutletBrokerRequestHandlerDefault} from './app-shell-outlet-broker-request-handler.default';

type Tokens = Record<AppShellOutletCategory, InjectionToken<AppShellOutletBroker<AppShellOutletCategory>>>;

const tokens = AppShellOutletCategory.reduce<Tokens>((_tokens, category) => {
  return {
    ..._tokens,
    // eslint-disable-next-line no-restricted-syntax
    [category]: new InjectionToken<AppShellOutletBroker<AppShellOutletCategory>>(
      `bsz.web-app-common.app-shell-outlet.${category}`,
      {
        /* An undefined request handler means that the app-shell outlet is not available.
         *  Receivers will get a warning when instantiated. All requests sent by emitters will be denied */
        factory: () => new AppShellOutletBroker(category, undefined),
      }
    ),
  };
}, {} as Tokens);

/** Returns the injection token to use for a determined app-shell outlet category */
export function getTokenForCategory<CATEGORY extends AppShellOutletCategory>(
  category: CATEGORY
): InjectionToken<AppShellOutletBroker<CATEGORY>> {
  return tokens[category];
}

/** Returns a provider af broker for an app-shell outlet.
 *  By adding this provider in an Angular provider scope an app-shell outlet is created.
 *
 *  Multiple app-shell outlets for the same category (e.g. 'generalActions') can coexists
 *  by defining multiple providers in different provider-scopes.
 *  Example: the application developer might create an actions outlet on the Angular root provider scope,
 *  a second outlet in the provider scope generated by an Angular lazy loaded module and a third one in provider scope
 *  generated using the `providers` property of an Angular component's decorator.
 *
 *  In case multiple outlets for a same category are available, Applets will utilize the outlet defined in the
 *  nearest provider scope. This way application developers can define different regions on their app-shell where
 *  to render projected content for different applet instances.
 *
 *  Example: two different applets that can project to a 'generalActions' outlet are instantiated simultaneously
 *  next to each other on a page. The developer wants that both applets can project their content to the app-shell
 *  instead of forcing one of them to render the content inside the boundaries of the applet element.
 *  To achieve this result, the application developer can divide the page in two wrapper components,
 *  each of these wrapper components:
 *  - define an outlet in it's `providers` property of the `@Component` decorator
 *  - add the container where the projected content should be rendered in the component's template
 *  - instantiate one of the applets in the component's template
 *
 *  Application developers can pass custom request handler in the options parameter.
 *  If no handler is specified, @basuiz/web-app will use a default one.
 *  Specifying a custom handler can be useful if you need special logic to handle competing requests from different
 *  applets / emitters. For example, if you want to give priority to new requests when the outlet is already in use.
 * */
export function appShellOutletProvider<CATEGORY extends AppShellOutletCategory>(
  category: CATEGORY,
  options: {
    brokerRequestHandler?: AppShellOutletBrokerRequestHandler;
  } = {}
): FactoryProvider {
  const {brokerRequestHandler} = options;
  return {
    provide: getTokenForCategory(category),
    useFactory: () =>
      new AppShellOutletBroker<CATEGORY>(category, brokerRequestHandler ?? appShellOutletBrokerRequestHandlerDefault),
  };
}
