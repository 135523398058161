<h3 mat-dialog-title class="bsz-body-2">Feature toggle</h3>

<div mat-dialog-content>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <!-- Leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
      <button mat-icon-button disabled></button>
      <mat-checkbox
        class="checklist-leaf-node"
        [checked]="featureSelection.isSelected(node)"
        (change)="toggleNode(node)"
      >
        {{ node.displayName }}
      </mat-checkbox>
      <ng-container *ngIf="node.isExperimental">
        <mat-icon
          matTooltip="{{ experimentalTooltipMessage }}"
          matTooltipClass="bsz-body-1"
          color="warn"
          class="bsz-margin-l2"
          >construction</mat-icon
        >
      </ng-container>
    </mat-tree-node>

    <!-- Inner nodes -->
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
      <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </button>
      <mat-checkbox [checked]="featureSelection.isSelected(node)" (change)="toggleNode(node)">
        {{ node.displayName }}
      </mat-checkbox>
      <ng-container *ngIf="node.isExperimental">
        <mat-icon
          matTooltip="{{ experimentalTooltipMessage }}"
          matTooltipClass="bsz-body-1"
          color="warn"
          class="bsz-margin-l2"
          >construction</mat-icon
        >
      </ng-container>
      <button
        mat-icon-button
        color="accent"
        [attr.aria-label]="'Activate including children:' + node.filename"
        (click)="activateIncludingChildren(node)"
      >
        <mat-icon matTooltip="{{ enableEntireSubTreeTooltipMessage }}" matTooltipClass="bsz-body-1"
          >all_inclusive</mat-icon
        >
      </button>
    </mat-tree-node>
  </mat-tree>
</div>

<p *ngIf="errorMessage" class="bsz-text-error">{{ errorMessage }}</p>

<div mat-dialog-actions>
  <button mat-button color="warn" (click)="resetToDefault()">Reset and reload</button>
  <button mat-flat-button color="primary" [disabled]="!!errorMessage" (click)="applyAndReload()"
    >Apply and reload</button
  >
</div>
