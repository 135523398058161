import {Component, Input} from '@angular/core';
import {AfpRestNotification, AfpRestNotificationSeverity} from '../afp-rest/index';
import {BszBannerType} from '@basuiz/ui-elements';

@Component({
  selector: 'bsz-notifications',
  templateUrl: './bsz-notifications.component.html',
})
export class BszNotificationsComponent {
  @Input() notifications: AfpRestNotification[];

  BANNER_TYPE_FOR_SEVERITY: Record<AfpRestNotificationSeverity, BszBannerType> = {
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error',
  };

  close(notificationToClose: AfpRestNotification) {
    this.notifications = this.notifications.filter((notification) => notification !== notificationToClose);
  }
}
