import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BszI18nModule} from '@basuiz/i18n';
import {BszOverlayDisabledFormComponent} from './bsz-overlay-disabled-form.component';
import {BszOverlayDisabledFormDirective} from './bsz-overlay-disabled-form.directive';
import {WebAppBannerModule} from '../ui-sdk-proxy/banner/index';

@NgModule({
  imports: [BszI18nModule, WebAppBannerModule, CommonModule],
  exports: [BszOverlayDisabledFormDirective],
  declarations: [BszOverlayDisabledFormComponent, BszOverlayDisabledFormDirective],
})
export class BszOverlayDisabledFormModule {}
