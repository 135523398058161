import {createAction, props} from '@ngrx/store';
import {AttentionItemCounter} from '../../models/attention-item-counter.definition';
import {getErrorState} from '@basuiz/web-app-common';
import {BszAttentionItemCounterType} from '@basuiz/web-app-applet-api';

export const loadAttentionItemCountersSuccess = createAction(
  '[WebAppAlert/AttentionItemCounter] Load Attention Item Counters Success',
  props<{attentionItemCounters: AttentionItemCounter[]}>()
);
export const loadAttentionItemCountersFailed = createAction(
  '[WebAppAlert/AttentionItemCounter] Load Attention Item Counters Failed',
  getErrorState
);

export const loadAttentionItemCounterAction = createAction(
  '[WebAppAlert/AttentionItemCounter] Load Attention Item Counters',
  props<{counterTypes?: BszAttentionItemCounterType[]}>()
);
