import {SelfServiceCollaborationClientAdvisorAppletConfig} from './self-service-collaboration-client-advisor-applet.config.definition';

// IMPORTANT: do not modify the signature of this function
export function validateSelfServiceCollaborationClientAdvisorAppletConfig(
  config: SelfServiceCollaborationClientAdvisorAppletConfig
): string | undefined {
  /*
   * TODO: implement config validation
   *  - In case of validation failure return a string containing an explanation of what went wrong
   *  - In case of successful validation return undefined
   *  - Remove this comment after implementing the validation
   */
  return;
}
