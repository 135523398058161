import {NgModule} from '@angular/core';
import {WebAppAppletModule} from '@basuiz/web-app-common';

import {WebAppSelfServiceLocaleSelectorAppletComponent} from './web-app-self-service-locale-selector-applet.component';
import {SelfServiceLocaleSelectorComponent} from './self-service-locale-selector/self-service-locale-selector.component';
import {WebAppCommonLocaleModule} from '@basuiz/web-app-common';

@NgModule({
  declarations: [WebAppSelfServiceLocaleSelectorAppletComponent, SelfServiceLocaleSelectorComponent],
  imports: [WebAppAppletModule, WebAppCommonLocaleModule],
  exports: [WebAppSelfServiceLocaleSelectorAppletComponent],
})
export class WebAppSelfServiceLocaleSelectorAppletModule {}
