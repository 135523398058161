import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {AsyncSubject, merge, Subscription, takeUntil} from 'rxjs';

import {BszDatePickerOverlay} from './bsz-datepicker-overlay';

@Component({
  selector: 'bsz-datepicker-toggle',
  template: `
    <button
      (click)="open($event)"
      [disabled]="disabled"
      [color]="datepicker?.opened ? 'primary' : null"
      [attr.aria-haspopup]="datepicker ? 'dialog' : null"
      mat-icon-button
      type="button"
      class="bsz-datepicker-toggle-button"
      [attr.aria-label]="'ui-elements.bsz-datepicker.open-calendar' | translate"
    >
      <span aria-hidden="true" class="bsz-display-block material-icons">calendar_today</span>
    </button>
  `,
  styleUrls: ['bsz-datepicker-toggle.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    'class': 'bsz-datepicker-toggle',
    '[class.bsz-datepicker-toggle-active]': 'datepicker?.opened',
    // Used by the test harness to tie this toggle to its datepicker.
    '[attr.data-bsz-calendar]': 'datepicker ? datepicker.uniqueId : null',
  },
})
export class BszDatePickerToggle<D> implements OnDestroy, OnChanges {
  /** The Datepicker instance that the button will toggle. */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('for') datepicker: BszDatePickerOverlay<D> | null = null;

  /** Whether the toggle button is disabled. */
  @Input()
  get disabled(): boolean {
    if (this._disabled === undefined && this.datepicker) {
      return this.datepicker.disabled;
    }

    return !!this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this.cd.markForCheck();
  }
  private _disabled: boolean | undefined;

  private readonly destroy = new AsyncSubject<void>();

  private datepickerSubscriptions = new Subscription();

  constructor(private readonly cd: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('datepicker')) {
      this.observeDatepickerState();
    }
  }

  open(event?: Event) {
    event?.stopPropagation();
    this.datepicker?.open();
    this.cd.markForCheck();
  }

  private observeDatepickerState() {
    this.datepickerSubscriptions.unsubscribe();

    if (!this.datepicker) {
      return;
    }

    this.datepickerSubscriptions = merge(this.datepicker._disabledStateChange, this.datepicker.onClose)
      .pipe(takeUntil(this.destroy))
      .subscribe(() => this.cd.markForCheck());
  }
}
