import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {FEATURE_KEY as BANK_DATE_FEATURE_KEY, reducer as bankDateReducer} from './+state/bank-date/bank-date.reducer';
import {FEATURE_KEY as COUNTRY_FEATURE_KEY, reducer as countryReducer} from './+state/country/country.reducer';
import {FEATURE_KEY as CURRENCY_FEATURE_KEY, reducer as currencyReducer} from './+state/currency/currency.reducer';
import {
  FEATURE_KEY as DEFAULT_CURRENCY_FEATURE_KEY,
  reducer as defaultCurrencyReducer,
} from './+state/default-currency/default-currency.reducer';
import {FEATURE_KEY as HOLIDAY_FEATURE_KEY, reducer as holidayReducer} from './+state/holidays/holiday.reducer';
import {
  FEATURE_KEY as BUSINESS_UNIT_FEATURE_KEY,
  reducer as businessUnitReducer,
} from './+state/business-unit/business-unit.reducer';
import {EffectsModule} from '@ngrx/effects';
import {BankDateEffects} from './+state/bank-date/bank-date.effects';
import {CountryEffects} from './+state/country/country.effects';
import {CurrencyEffects} from './+state/currency/currency.effects';
import {DefaultCurrencyEffects} from './+state/default-currency/default-currency.effects';
import {HolidayEffects} from './+state/holidays/holiday-effects.service';
import {BusinessUnitEffects} from './+state/business-unit/business-unit.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(BANK_DATE_FEATURE_KEY, bankDateReducer),
    StoreModule.forFeature(COUNTRY_FEATURE_KEY, countryReducer),
    StoreModule.forFeature(CURRENCY_FEATURE_KEY, currencyReducer),
    StoreModule.forFeature(DEFAULT_CURRENCY_FEATURE_KEY, defaultCurrencyReducer),
    StoreModule.forFeature(HOLIDAY_FEATURE_KEY, holidayReducer),
    StoreModule.forFeature(BUSINESS_UNIT_FEATURE_KEY, businessUnitReducer),
    EffectsModule.forFeature([
      BankDateEffects,
      CountryEffects,
      CurrencyEffects,
      DefaultCurrencyEffects,
      HolidayEffects,
      BusinessUnitEffects,
    ]),
  ],
})
/* Must be imported only by WebAppModule, so that
 * the base-data feature-stores are instantiated before the web-app initialization phase,
 * since the initialization will dispatch the actions that load the base-data into the stores */
export class WebAppBaseDataModule {}
