<table class="bsz-table-inner" mat-table [dataSource]="tableDataSource" [attr.summary]="summary">
  <caption class="bsz-table-caption" *ngIf="caption">
    <span class="bsz-table-caption-content">{{caption}}</span>
  </caption>

  <!-- Row for Table Headers -->
  <tr
    class="bsz-header-row"
    mat-header-row
    aria-rowindex="1"
    *matHeaderRowDef="columnsToDisplay; sticky: _stickyHeader"
    [ngClass]="headerNestingClass"
  ></tr>

  <!-- Normal rows for Table -->
  <tr
    class="bsz-row"
    mat-row
    *matRowDef="let row; let rowIndex = index; columns: columnsToDisplay"
    (click)="emitRowClickEvent(row)"
    [attr.aria-rowindex]="getAriaRowIndex(rowIndex)"
    [class.bsz-row-selected]="isSelected(row)"
    [ngClass]="getDynamicRowClass(row)"
  ></tr>

  <!-- Row for Table Group Headers -->
  <ng-container *ngIf="isTreeTable()">
    <tr
      class="bsz-group-header-row bsz-row"
      mat-row
      *matRowDef="let row; let rowIndex = index; columns: groupHeaderColumnsToDisplay; when: isGroupHeader"
      (click)="emitGroupHeaderClickEvent(row, $event)"
      [attr.aria-rowindex]="getAriaRowIndex(rowIndex)"
      [ngClass]="getDynamicRowClass(row)"
    ></tr>
  </ng-container>

  <!-- Template for empty cells when groupHeaders require it -->
  <ng-container [matColumnDef]="emptyCellId">
    <td mat-cell *matCellDef></td>
  </ng-container>

  <!-- Footer row for Table -->
  <tr
    *matFooterRowDef="hasFooter ? columnsToDisplay : []"
    [attr.aria-rowindex]="getFooterAriaRowIndex()"
    [hidden]="!hasFooter"
    class="bsz-footer-row"
    mat-footer-row
  ></tr>

</table>
