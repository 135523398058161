import {ChangeDetectionStrategy, Component, Inject, OnDestroy, TemplateRef, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {NavigationService} from '../../navigation/navigation.service';
import {ModifyUserDataIntent} from '../../intents/intents.index';
import {ɵPORTAL_CONFIG} from '../../config/portal.config.provider';
import {PortalConfig} from '../../config/portal.config.definition';
import {ActiveLocaleSettingsService} from '@basuiz/web-app-applet-sdk';
import {TranslatedText} from '@basuiz/web-app-applet-api';
import {ChangeLocaleDialogComponent} from './change-locale-dialog/change-locale-dialog.component';
// TODO: Only needed for nx-enforce-module-boundaries Rule AFP-42958
// eslint-disable-next-line
import {AuthJwtService, CustomLocaleSource, CUSTOM_LOCALE_SOURCE} from '@basuiz/web-app-common';

@Component({
  selector: 'bsz-user-account-menu',
  templateUrl: './user-account-menu.component.html',
  styleUrls: ['./user-account-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAccountMenuComponent implements OnDestroy {
  readonly localeDisplayText: TranslatedText | undefined;
  readonly showLocaleMenuOption: boolean =
    this.activeLocaleSettingsService.localeConfigAvailable && !this.customLocaleSource;

  readonly changePasswordResolver = this.portalConfig.sessionManagement.changePasswordResolver;

  readonly bszTestIdPrefix = 'web-app-portal.app-bar.user-account-menu';

  constructor(
    private matDialog: MatDialog,
    private navigationService: NavigationService,
    private activeLocaleSettingsService: ActiveLocaleSettingsService,
    @Inject(ɵPORTAL_CONFIG) private portalConfig: PortalConfig,
    @Inject(CUSTOM_LOCALE_SOURCE) private customLocaleSource: CustomLocaleSource | undefined,
    private authJwtService: AuthJwtService
  ) {
    this.localeDisplayText = this.activeLocaleSettingsService.activeLocaleSettings.settings?.display.text;
  }

  private readonly subscriptions = new Subscription();

  @ViewChild('logoutConfirmation') logoutConfirmationDialogTR: TemplateRef<unknown>;
  showLogoutConfirmationDialog() {
    const dialogRef = this.matDialog.open(this.logoutConfirmationDialogTR);
    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((logoutConfirmed) => {
        if (logoutConfirmed) {
          // this.portalConfig.sessionManagement.logOutResolver();
          this.authJwtService.signout();
        }
      })
    );
  }

  onChangePassword(): void {
    if (this.changePasswordResolver) {
      this.changePasswordResolver();
    }
  }

  onShowAccountAndProfile() {
    this.navigationService.navigate(new ModifyUserDataIntent({modificationType: 'web-app-settings'}));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onChangeLocale() {
    this.matDialog.open(ChangeLocaleDialogComponent, {
      maxWidth: '340px',
    });
  }
}
