import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BszObjectId, TranslatedText} from '@basuiz/web-app-applet-api';
import {Observable, of, switchMap, throwError} from 'rxjs';
import {AfpRestOperators, AfpRestResponse} from '../afp-rest/index';
import {shareReplay} from 'rxjs/operators';

export interface UserPerson {
  id: BszObjectId;
  name: TranslatedText;
}

@Injectable({
  providedIn: 'root',
})
export class UserPersonService {
  // private static URL = `/com.basuiz.afs.rest.services/rest/user/person`; // to be used with mock-server & proxy.config.json
  private static URL = process.env.API_BASE_URL + '/rest/user/person';

  readonly userPerson$: Observable<UserPerson> = this.httpClient
    .get<AfpRestResponse<UserPerson[]>>(UserPersonService.URL)
    .pipe(
      AfpRestOperators.extractPayload(),
      switchMap((personList) => {
        if (personList.length === 0) {
          return throwError(() => 'User person list contains no entities');
        } else if (personList.length > 1) {
          return throwError(() => 'Unsupported: User person list contains multiple entities');
        } else {
          return of(personList[0]);
        }
      }),
      shareReplay(1)
    );

  constructor(private httpClient: HttpClient) {}
}
