<button
  bszTestId="{{ bszTestIdPrefix }}.quick-link-button"
  mat-raised-button
  class="bsz-quick-link-button bsz-padding-4"
  (click)="clickedQuickLink.emit()"
>
  <div class="bsz-quick-link-button-content">
    <mat-icon *ngIf="icon" color="primary" class="quick-link-icon" [innerText]="icon"></mat-icon>
    <mat-icon *ngIf="svgIconLabel" color="primary" class="quick-link-icon" svgIcon="{{ svgIconLabel }}"></mat-icon>
    <span class="quick-link-label bsz-body-1 bsz-text-bold">{{ label }}</span>
    <mat-icon color="primary" class="chevron_right">chevron_right</mat-icon>
  </div>
</button>
