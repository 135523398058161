import {NgModule} from '@angular/core';
import {TestingCoreModule} from '../testing-core/index';
import {BszTechnicalErrorComponent} from './bsz-technical-error.component';
import {BszCentralMessageModule} from '../central-message/bsz-central-message.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [BszCentralMessageModule, TestingCoreModule, TranslateModule],
  exports: [BszTechnicalErrorComponent],
  declarations: [BszTechnicalErrorComponent],
  providers: [],
})
export class BszTechnicalErrorModule {}
