import {ChangeDetectionStrategy, Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {AppShellOutletContent, AppShellOutletCategory, getTokenForCategory} from '../index';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {isDefined} from '../../utils/defined.util';
import {map} from 'rxjs/operators';
import {AppShellOutletBroker} from '../app-shell-outlet-broker';
import {AppShellOutletReceiver} from '../app-shell-outlet-receiver.definition';

@Component({
  selector: 'bsz-app-shell-outlet-receiver',
  template: `<ng-template [cdkPortalOutlet]="__projectedContent$ | async"></ng-template>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppShellOutletReceiverComponent implements OnInit, OnDestroy {
  /** Indicates the category of content the receiver accepts */
  @Input()
  category: AppShellOutletCategory;

  private readonly subscriptions = new Subscription();
  private readonly projectedContentSubject = new ReplaySubject<AppShellOutletContent | undefined>(1);

  /** INTERNAL / Private API */
  readonly __projectedContent$: Observable<AppShellOutletContent | undefined> =
    this.projectedContentSubject.asObservable();

  /** Emits whether the receiver is rendering any content projected via the app-shell outlet or not */
  readonly isRendering$: Observable<boolean> = this.__projectedContent$.pipe(map(isDefined));

  constructor(private readonly injector: Injector) {}

  /** Angular ngOnInit. INTERNAL / Private API */
  ngOnInit(): void {
    this.subscriptions.add(
      this.receiver.register().subscribe((maybeContent) => {
        this.projectedContentSubject.next(maybeContent);
      })
    );
  }

  private get receiver(): AppShellOutletReceiver {
    const broker: AppShellOutletBroker<any> = this.injector.get(getTokenForCategory(this.category));
    return broker.asReceiver;
  }

  /** Angular ngOnDestroy. INTERNAL / Private API */
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.receiver.deregister({skipDetach: true});
  }
}
