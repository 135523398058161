import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  forwardRef,
  InjectionToken,
  Input,
  Output,
} from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import {TranslateService} from '@ngx-translate/core';

export type BszBannerModalMode = 'push' | 'over';

const icons = {
  success: 'check_circle_outline',
  info: 'info',
  warning: 'warning_amber',
  error: 'error_outline',
};

@Directive({
  selector: 'bsz-banner-title',
  host: {
    class: 'bsz-banner-title bsz-text-bold',
  },
})
export class BszBannerTitle {}

@Directive()
export class BszBannerBase implements AfterViewInit {
  @Input()
  type: BszBannerType = 'info'; // defaults to info

  @Input()
  set dismissable(value: BooleanInput) {
    this._dismissable = coerceBooleanProperty(value);
  }

  get dismissable() {
    return this._dismissable;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('class') className = '';

  @Output()
  closed = new EventEmitter<void>();

  @ContentChild(BszBannerTitle)
  title: BszBannerTitle | null = null;

  @ContentChild(forwardRef(() => BszBannerActions))
  actions: BszBannerActions | null = null;

  isVisible = true;

  private _dismissable = false;

  /** @private */
  _isMultiLine = false;

  /** @private */
  _dismissButtonColor: 'primary' | undefined;

  /** @private */
  _bannerClass = '';

  /** @private */
  _bannerContainer: HTMLElement;

  /** @private */
  _modal: BszBannerModalMode | undefined = undefined;

  /** @private */
  _role: 'alert' | 'dialog' = 'alert';

  /** @private */
  ariaLabel: string | null = null;

  constructor(
    protected readonly translate: TranslateService,
    protected readonly cd: ChangeDetectorRef,
    protected readonly elementRef: ElementRef
  ) {
    this._bannerContainer = this.elementRef.nativeElement;
  }

  ngAfterViewInit() {
    this._setMultiline();
  }

  show(): void {
    if (this.isVisible) {
      return;
    }
    this.isVisible = true;
    this.cd.markForCheck();
  }

  dismiss(): void {
    if (!this.isVisible) {
      return;
    }
    this.isVisible = false;
    this.closed.emit();
    this.cd.markForCheck();
  }

  /** @private */
  _onResize() {
    this._setMultiline();
  }

  /** @private */
  _setMultiline() {
    const content = this._bannerContainer?.querySelector('.bsz-banner-content') as HTMLElement;
    if (!this.actions || !content) {
      return;
    }
    const text = content.querySelector('.bsz-banner-text') as HTMLElement;
    const actions = content.querySelectorAll('.bsz-banner-action');
    let actionsWidth = 0;
    actions.forEach((action) => {
      actionsWidth += this.getWidth(action);
    });

    // when it has closing button and the actions do not fit, they move down. By design, when that happens, they should
    // be aligned to the right corner with the closing button. So the conditions to set the style for that scenario are:
    // * it is dismissable (it has the closing button) and
    // * the actions are displaced:
    //      - if there is title or
    //      - if the sum of the width of text and actions is wider than the available space
    this._isMultiLine =
      this._dismissable && (!!this.title || this.getWidth(content) < this.getWidth(text) + actionsWidth);
    this.cd.detectChanges();
  }

  private getWidth(element: Element | null): number {
    if (!element) {
      return 0;
    }
    return element.getBoundingClientRect().width;
  }

  /** @private */
  _getIcon(bannerType: BszBannerType) {
    return icons[bannerType];
  }

  /** @private */
  _getIconAriaLabel(bannerType: BszBannerType): string {
    switch (bannerType) {
      case 'success':
        return this.translate.instant('ui-elements.bsz-banner.accessibility.success');
      case 'warning':
        return this.translate.instant('ui-elements.bsz-banner.accessibility.warning');
      case 'error':
        return this.translate.instant('ui-elements.bsz-banner.accessibility.error');
      case 'info':
      default:
        return this.translate.instant('ui-elements.bsz-banner.accessibility.info');
    }
  }
}

@Component({
  selector: 'bsz-banner-action',
  host: {
    class: 'bsz-banner-action',
  },
  template: ` <button
    mat-button
    class="bsz-text-bold"
    [color]="_actionColor"
    [disabled]="_disabled"
    [attr.aria-label]="ariaLabel"
    [attr.aria-labelledby]="ariaLabelledby"
  >
    <ng-content></ng-content>
  </button>`,
})
export class BszBannerAction {
  @Input('aria-label') ariaLabel: string | null = null;
  @Input('aria-labelledby') ariaLabelledby: string | null = null;

  @Input() set disabled(disabled: BooleanInput) {
    this._disabled = coerceBooleanProperty(disabled);
  }

  /** @private */
  _disabled = false;

  /** @private */
  _actionColor: ThemePalette;
}

export const BSZ_BANNER_DEFAULT_IS_OPEN = new InjectionToken<boolean>('bsz_banner_default_is_open');

@Component({
  selector: 'bsz-banner-actions',
  host: {
    class: 'bsz-banner-actions',
  },
  template: ` <ng-content select="bsz-banner-action"></ng-content> `,
})
export class BszBannerActions {}

export type BszBannerType = 'info' | 'warning' | 'error' | 'success';
