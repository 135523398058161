import {
  BszManifestCreatedUINotification,
  BszManifestDownloadErrorUINotification,
  BszShipmentLabelDownloadErrorUINotification,
} from '@basuiz/web-app-applet-api';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslateService} from '@ngx-translate/core';
import {ShowManifestOverviewIntent, ShowShipmentOverviewIntent} from '../../../../intents/intents.index';
import {UINotificationDialogActionResolver} from '../../portal-ui-notification-dialog-action-resolver';
import {UINotificationDialogData} from '../../portal-ui-notification-dialog.definitions';

export function getShipmentLabelDownloadErrorUiNotificationDialogData(
  notification: BszShipmentLabelDownloadErrorUINotification,
  translateService: TranslateService
): UINotificationDialogData {
  const {severity, message, remarks} = notification;
  return {
    severity,
    message,
    remarks,
    actions: [],
  };
}

export function getManifestCreatedUiNotificationDialogData(
  notification: BszManifestCreatedUINotification,
  translateService: TranslateService
): UINotificationDialogData {
  const {severity, message, remarks} = notification;
  return {
    severity,
    message,
    remarks,
    actions: [
      {
        label: translateService.instant(
          asTranslationKey(
            'web-app-portal.ui-notification-dialog.shipment.manifest-created.actions.link.label.download-label'
          )
        ),
        icon: 'products_avq',
        resolve: new UINotificationDialogActionResolver(() => {
          return new ShowShipmentOverviewIntent();
        }),
      },
      {
        label: translateService.instant(
          asTranslationKey(
            'web-app-portal.ui-notification-dialog.shipment.manifest-created.actions.link.label.download-manifest'
          )
        ),
        icon: 'local_shipping',
        resolve: new UINotificationDialogActionResolver(() => {
          return new ShowManifestOverviewIntent();
        }),
      },
    ],
  };
}

export function getManifestDownloadErrorUiNotificationDialogData(
  notification: BszManifestDownloadErrorUINotification,
  translateService: TranslateService
): UINotificationDialogData {
  const {severity, message, remarks} = notification;
  return {
    severity,
    message,
    remarks,
    actions: [],
  };
}
