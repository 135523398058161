import {JestFormField} from './jest-form-field';
import {FormFieldMatInput} from '../generic/components';

export class JestFormFieldMatInput extends JestFormField implements FormFieldMatInput {
  get input(): HTMLInputElement | null {
    return this.element.querySelector('input');
  }
  get value(): string | undefined {
    return this.input?.value;
  }

  set value(value: string | undefined) {
    if (this.input && value) {
      this.input.value = value;
    }
    this.updateAngularFormField();
  }

  private updateAngularFormField() {
    this.input?.dispatchEvent(new Event('input'));
  }

  focusAndBlur() {
    this.input?.focus();
    this.jestFixtureFacade.detectChanges();

    this.input?.blur();
    this.jestFixtureFacade.detectChanges();
  }
}
