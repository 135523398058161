import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {combineLatest, Observable, ReplaySubject} from 'rxjs';
import {NavigationService} from '../../../../navigation/navigation.service';
import {filter, map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {TranslatedText} from '@basuiz/web-app-applet-api';
import {translateKeyOptWithParams} from '../../../../navigation/translation-key-with-params';
import {HistoryNavigationEntry} from '../../../../navigation/history-navigation-entry';

@Component({
  selector: 'bsz-history-breadcrumbs',
  templateUrl: './history-breadcrumbs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryBreadcrumbsComponent {
  @Input()
  set maxBreadcrumbs(value: number) {
    this.maxBreadcrumbsSubject.next(value);
  }

  private maxBreadcrumbsSubject = new ReplaySubject<number>(1);

  readonly breadcrumbs$: Observable<TranslatedText[]> = combineLatest([
    this.navigationService.ɵhistoryNavigationEntries$,
    this.maxBreadcrumbsSubject,
  ]).pipe(
    filter(([entries, maxBreadcrumbs]) => entries.length > 1),
    map(([entries, maxBreadcrumbs]) => entries.slice(Math.max(entries.length - maxBreadcrumbs, 0))),
    map((entries) =>
      entries.map((entry: HistoryNavigationEntry) =>
        translateKeyOptWithParams(entry.translation, this.translateService)
      )
    )
  );

  constructor(private navigationService: NavigationService, private translateService: TranslateService) {}

  breadcrumbClicked(breadcrumbs: TranslatedText[], clickedIndex: number) {
    /*The last breadcrumb (non-clickable) is the current page,
     * go as many steps back as steps separate the clicked breadcrumb from the last one */
    const steps: number = breadcrumbs.length - clickedIndex - 1;
    history.go(-steps);
  }
}
