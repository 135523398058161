import {Provider} from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {
  AfpRestIsNativeAppHttpInterceptor,
  AfpRestRequestLangHttpInterceptor,
  AfpRestResponsePayloadHttpInterceptor,
  AuthHttpInterceptor,
  AuthJwtHttpInterceptor,
} from '@basuiz/web-app-common';
// import {GlobalFilterInterceptor} from '@basuiz/web-app-wealth-global-filter'; // TODO clean

// interceptors in required order; pass resolved payload up the chain to auth, lang is always added to replayed auth.
const HttpInterceptors = [
  AuthJwtHttpInterceptor,
  AuthHttpInterceptor,
  AfpRestRequestLangHttpInterceptor,
  // GlobalFilterInterceptor, // TODO clean
  AfpRestIsNativeAppHttpInterceptor,
  AfpRestResponsePayloadHttpInterceptor,
];

export const HttpInterceptorsProviders: Array<Provider> = HttpInterceptors.map((interceptorClass) => ({
  provide: HTTP_INTERCEPTORS,
  useClass: interceptorClass,
  multi: true,
}));
