import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, shareReplay, take, tap} from 'rxjs/operators';
import {AfpRestOperators, AfpRestResponse} from '../afp-rest/index';

@Injectable({
  providedIn: 'root',
})
export class BackendVersionService {
  // private URL = `/com.basuiz.afs.rest.services/rest/serverinfo/version`; // to be used with mock-server & proxy.config.json
  private URL = process.env.API_BASE_URL + `/rest/serverinfo/version`;
  private backendVersionCache: string | null | undefined = undefined;

  constructor(private httpClient: HttpClient) {}

  private loadBackendVersion(): Observable<string | null> {
    return this.httpClient.get<AfpRestResponse<{versions: {'com.basuiz.afs.rest.services': string}}>>(this.URL).pipe(
      AfpRestOperators.extractPayload(),
      map((res) => res.versions['com.basuiz.afs.rest.services']),

      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return of(null);
        }
        return throwError(error.message);
      }),
      tap((version) => (this.backendVersionCache = version))
    );
  }

  readonly backendVersion$: Observable<string | null> = this.loadBackendVersion().pipe(take(1), shareReplay(1));

  /**
   * Synchronous value of backendVersion. Callable after the app initialization
   * null means the version service is not present => no new features supported
   */
  get backendVersion(): string | null {
    if (this.backendVersionCache === undefined) {
      throw new Error(
        'Synchronous method cannot be called before the app initialization finishes loading the backend version'
      );
    }
    return this.backendVersionCache;
  }
}
