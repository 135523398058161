import {
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import {TranslatedText} from '@basuiz/web-app-applet-api';
import {BszInfoTextComponent} from './bsz-info-text.component';

/**
 * Conditionally include an icon at the horizontal-end of the component where the directive is added. When the user hovers
 * on the icon in the case of desktop devices or clicks on it in the case of mobile devices, a tooltip will be displayed
 * showing the information received as parameter.
 * If the value from this parameter after removing the spaces at the beginning or end is empty, no icon will be shown.
 * To add the icon, the directive creates a new div that wraps the host-element plus the info-text icon.
 *
 * Input parameters:
 * bszInfoText: the translated text to display as a tooltip
 * bszInfoTextClass: the class to apply to the new div created where the element and the icon are added
 *
 * In general it's expected to be used for titles and mat-form-fields within a form. Example of use:
 *
 *
 * ```
 * <h5
 *   [bszInfoText]="'web-app-payment-form.component.beneficiary-form.account.tooltip-text' | translate"
 *   bszInfoTextClass="mdc-layout-grid__cell--span-12"
 *   class="bsz-subtitle-2"
 * >
 *   {{ 'web-app-payment-form.component.beneficiary-form.account.header' | translate }}
 * </h5>
 * ```
 * <mat-form-field
 *   bszInfoTextClass="mdc-layout-grid__cell--span-12"
 *   ngIf="redSlipIbanForPcAccount"
 *   [bszInfoText]="
 *   'web-app-payment-form.component.beneficiary-form.red-slip-iban-account.tooltip-text' | translate
 *   "
 *   bszTestId="{{ bszTestIdPrefix }}.red-slip-iban-account"
 * >
 *   <mat-label>
 *     {{ 'web-app-payment-form.component.beneficiary-form.red-slip-iban-account.label' | translate }}
 *   </mat-label>
 * </mat-form-field>
 */
@Directive({selector: '[bszInfoText]'})
export class BszInfoTextDirective implements OnChanges, OnDestroy {
  @Input() bszInfoText: TranslatedText;

  @Input() bszInfoTextClass: string;

  private infoTextComponent: ComponentRef<BszInfoTextComponent> | undefined;
  private wrapperDiv: HTMLDivElement | undefined;

  constructor(private el: ElementRef, private renderer: Renderer2, private viewContainerRef: ViewContainerRef) {}

  ngOnDestroy() {
    this.removeComponent(true);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.bszInfoText) {
      const isInfoTextProvided: boolean = this.bszInfoText?.trim().length > 0;

      if (isInfoTextProvided) {
        this.createOrUpdateComponent();
      } else {
        this.removeComponent();
      }
    }

    if (changes.bszInfoTextClass) {
      if (this.wrapperDiv) {
        this.addInfoTextClass(this.wrapperDiv);
      } else {
        this.addInfoTextClass(this.el.nativeElement);
      }
    }
  }

  private createOrUpdateComponent() {
    if (this.infoTextComponent) {
      this.infoTextComponent.instance.info = this.bszInfoText;
    } else {
      this.createComponent(this.bszInfoText);
    }
  }

  private createComponent(infoText: TranslatedText) {
    this.viewContainerRef.clear();

    this.infoTextComponent = this.viewContainerRef.createComponent(BszInfoTextComponent);
    this.infoTextComponent.instance.info = infoText;

    //in order to be able to add the info-text component, a parent div is created which contains the element
    //where the directive has been added and the info-text component. As a consequence, we change the parent to the
    // element having the directive and we add to this previous parent the recently created div
    const div: HTMLDivElement = this.renderer.createElement('div');
    const parent = this.renderer.parentNode(this.el.nativeElement);
    this.renderer.insertBefore(parent, div, this.el.nativeElement);

    div.appendChild(this.infoTextComponent.location.nativeElement);
    this.renderer.insertBefore(div, this.el.nativeElement, this.infoTextComponent.location.nativeElement, true);

    div.classList.add('bsz-display-flex');

    if (this.el.nativeElement.tagName === 'MAT-FORM-FIELD') {
      this.el.nativeElement.style.width = `calc(100% - ${this.infoTextComponent.instance.TOUCH_AREA_LENGTH})`;
      this.infoTextComponent.location.nativeElement.classList.add('bsz-margin-t2');
    } else {
      div.classList.add('bsz-align-items-center');
    }
    this.addInfoTextClass(div);
    this.wrapperDiv = div;
  }

  private removeComponent(shouldDestroy: boolean = false) {
    if (this.wrapperDiv) {
      const parent = this.renderer.parentNode(this.wrapperDiv);

      if (!shouldDestroy) {
        this.addInfoTextClass(this.el.nativeElement);
        this.renderer.insertBefore(parent, this.el.nativeElement, this.wrapperDiv, true);
      }

      this.renderer.removeChild(parent, this.wrapperDiv);
      this.infoTextComponent = undefined;
      this.wrapperDiv = undefined;
    } else {
      this.addInfoTextClass(this.el.nativeElement);
    }
  }

  private addInfoTextClass(element: HTMLElement) {
    if (this.bszInfoTextClass) {
      this.bszInfoTextClass.split(' ').map((className) => {
        if (!element.classList.value.includes(className)) {
          element.classList.add(className);
        }
      });
    }
  }
}
