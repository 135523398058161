import {NgModule} from '@angular/core';
import {BszPortalPageLayoutComponent} from './bsz-portal-page-layout.component';
import {MatTabsModule} from '@angular/material/tabs';
import {BszPortalPageTabDirective} from './bsz-portal-page-tab.directive';
import {BreadcrumbsModule} from './breadcrumbs/breadcrumbs.module';
import {PageContentCenteredComponent} from './page-content-centered/page-content-centered.component';
import {PageHelpComponent} from './page-help/page-help.component';
import {PortalBasicModule} from '../../util/portal-basic.module';
import {
  PortalPageHeaderActionsDirective,
  PortalPageHeaderExtrasDirective,
  PortalPageHeaderSubtitleDirective,
  PortalPageHeaderTitleDirective,
} from './bsz-portal-page-header-item';
import {AppShellOutletReceiverModule} from '@basuiz/web-app-applet-sdk';

const HEADER_ITEMS = [
  PortalPageHeaderTitleDirective,
  PortalPageHeaderSubtitleDirective,
  PortalPageHeaderActionsDirective,
  PortalPageHeaderExtrasDirective,
  BszPortalPageTabDirective,
];

const EXPORTED_DECLARATIONS = [BszPortalPageLayoutComponent, ...HEADER_ITEMS, PageContentCenteredComponent];

@NgModule({
  declarations: [EXPORTED_DECLARATIONS, PageHelpComponent],
  exports: [EXPORTED_DECLARATIONS, PortalBasicModule],
  imports: [PortalBasicModule, MatTabsModule, BreadcrumbsModule, AppShellOutletReceiverModule],
})
export class BszPortalPageLayoutModule {}
