/**
 * Defines the utility classes to use to style a MatChip in the
 * [colours defined by UX](https://www.figma.com/file/5biyyyNQmeOBuMbtUtuA7g/Web-3.0%3A-Components?node-id=2550%3A5119ko)
 */
export enum MatChipClass {
  DEFAULT = '',
  NEUTRAL = 'bsz-background-secondary bsz-text-on-secondary',
  PRIMARY = 'bsz-background-primary bsz-text-on-primary',
  INFO = 'bsz-background-accent bsz-text-on-accent',
  WARN = 'bsz-background-error bsz-text-on-error',
  SUCCESS = 'bsz-background-success bsz-text-on-success',
}
