import {Action, createReducer, on} from '@ngrx/store';
import {
  loadAttentionItemCounterAction,
  loadAttentionItemCountersFailed,
  loadAttentionItemCountersSuccess,
} from './attention-item-counter.actions';
import {AttentionItemCounter} from '../../models/attention-item-counter.definition';
import {CallState, LoadingState} from '@basuiz/web-app-common';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

export const FEATURE_KEY = 'web-app-alert/attention-item-counter';

export interface PartialState {
  readonly [FEATURE_KEY]: State;
}

export interface State extends EntityState<AttentionItemCounter> {
  callState: CallState;
}

export const adapter: EntityAdapter<AttentionItemCounter> = createEntityAdapter<AttentionItemCounter>({
  selectId: (attentionItemCounter: AttentionItemCounter) => attentionItemCounter.counterType,
});

export const initialState: State = adapter.getInitialState({
  callState: LoadingState.INIT,
});

const attentionItemCounterReducer = createReducer(
  initialState,
  on(loadAttentionItemCounterAction, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(loadAttentionItemCountersSuccess, (state, action) =>
    adapter.upsertMany(action.attentionItemCounters, {
      ...state,
      callState: LoadingState.LOADED,
    })
  ),
  on(loadAttentionItemCountersFailed, (state, error) => ({
    ...state,
    callState: error,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return attentionItemCounterReducer(state, action);
}
