import {BszFeatureToggle} from './features.definition';
import {FeatureStatus} from './generic.definition';

export type ExperimentalFeatureToggleTree = RecursivePartialExperimentalFeature<BszFeatureToggle>;

export const ExperimentalFeatureStatus = 'experimental';
export type ExperimentalFeatureStatus = typeof ExperimentalFeatureStatus;

/* Defines a partial feature toggle where leaf nodes are flagged as experimental */
/*
  const x: ExperimentalFeatureToggle = {
  payments: {
    accountTransfers: 'experimental',
    scanQRBill: {
      payeye: 'experimental'
    }
  },
  wealth: {
    balanceForecast: 'experimental'
  }
};*/

export type RecursivePartialExperimentalFeature<PARENT_FEATURE> = {
  [CHILD_FEATURE in keyof PARENT_FEATURE]?: Exclude<PARENT_FEATURE[CHILD_FEATURE], FeatureStatus> extends never
    ? ExperimentalFeatureStatus
    :
        | ExperimentalFeatureStatus
        | Exclude<RecursivePartialExperimentalFeature<PARENT_FEATURE[CHILD_FEATURE]>, FeatureStatus>;
};
