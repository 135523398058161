import {NavigationIntent} from '../../navigation/classes/navigation-intent';

export interface ShowCollaborationDetailsIntentPayload {
  collaborationIssueId: number;
  collaborationIssueSubject: string;
}

export class ShowCollaborationDetailIntent extends NavigationIntent {
  constructor(public payload: ShowCollaborationDetailsIntentPayload) {
    super();
  }
}
