import {Component, ChangeDetectionStrategy, Input} from '@angular/core';
import {TranslatedText} from '@basuiz/web-app-applet-api';

@Component({
  selector: 'bsz-technical-error',
  templateUrl: './bsz-technical-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BszTechnicalErrorComponent {
  @Input()
  additionalInfo: TranslatedText = '';
}
