import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TranslatedText} from '@basuiz/web-app-applet-api';
import {AfpRestOperators, AfpRestResponse} from '../afp-rest/index';
import {shareReplay} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserNameService {
  private static readonly URL = `/com.basuiz.afs.rest.services/rest/user/name`;

  constructor(private readonly httpClient: HttpClient) {}

  public userName$: Observable<TranslatedText> = this.httpClient
    .get<AfpRestResponse<TranslatedText>>(UserNameService.URL)
    .pipe(AfpRestOperators.extractPayload(), shareReplay(1));
}
