import {Action, MemoizedSelector, Store} from '@ngrx/store';
import {Actions} from '@ngrx/effects';
import {BehaviorSubject, EMPTY, Observable, OperatorFunction, Subject} from 'rxjs';
import {finalize, map, mergeMap, switchMap, take} from 'rxjs/operators';
import {AppletContextFeatureState, isActionWithAppletContextId} from './applet-context-feature-store.definitions';
import {AppletContextFeatureStoreAdapter} from './applet-context-feature-store.adapter';

export abstract class AppletContextFeatureStoreEffects<STATE> {
  getActionInAllAppletContexts$<ACTION extends Action>(action: ACTION): Observable<ACTION> {
    if (!this.store || !this.appletContextAdapter) {
      throw new Error(
        `[AppletContextFeatureStoreEffects] cannot getActionInAllAppletContexts$ for '${action.type}' without passing the` +
          `Store and appletContextStoreAdapter to AppletContextFeatureStoreEffects when calling super() in the constructor.`
      );
    }

    return this.store.select(this.appletContextAdapter.selectFeatureState).pipe(
      take(1),
      switchMap((featureState) => Object.keys(featureState).map((appletContextId) => ({...action, appletContextId})))
    );
  }

  withActionsInAppletContext<DISPATCHED extends Action, OF_TYPE extends Action, TO_DISPATCH extends Action>(
    filterActions: OperatorFunction<DISPATCHED, OF_TYPE>,
    inAppletContext: (
      actions$: Observable<OF_TYPE>,
      select: <RESULT>(selector: MemoizedSelector<STATE, RESULT>) => Observable<RESULT>
    ) => Observable<TO_DISPATCH>
  ): Observable<TO_DISPATCH & {appletContextId: string}> {
    const actionsInAppletContexts: Record<string, Subject<OF_TYPE>> = {};

    return this.actions$.pipe(
      filterActions,
      mergeMap((filteredAction) => {
        const appletContextId = this.getAppletContextIdFromAction(filteredAction);
        const actionsInAppletContext = actionsInAppletContexts[appletContextId];

        if (!actionsInAppletContext) {
          const actionsInNewAppletContext = new BehaviorSubject<OF_TYPE>(filteredAction);
          actionsInAppletContexts[appletContextId] = actionsInNewAppletContext;

          return inAppletContext(
            actionsInNewAppletContext.asObservable(),
            this.getSelectForAction(filteredAction)
          ).pipe(
            map((toDispatch) => ({
              ...toDispatch,
              appletContextId,
            }))
          );
        }

        actionsInAppletContext.next(filteredAction);
        return EMPTY;
      }),
      finalize(() => {
        Object.values(actionsInAppletContexts).forEach((actionsInAppletContext) => actionsInAppletContext.complete());
      })
    );
  }

  constructor(
    protected actions$: Actions,
    private store?: Store<AppletContextFeatureState<STATE>>,
    private appletContextAdapter?: AppletContextFeatureStoreAdapter<STATE>
  ) {}

  private getSelectForAction(action: Action) {
    const {store, appletContextAdapter, getAppletContextIdFromAction} = this;

    return function select<RESULT>(selector: MemoizedSelector<STATE, RESULT>): Observable<RESULT> {
      if (!store || !appletContextAdapter) {
        throw new Error(
          `[AppletContextFeatureStoreEffects] cannot selectFromContext without passing the Store and appletContextStoreAdapter ` +
            `to AppletContextFeatureStoreEffects when calling super() in the constructor.`
        );
      }
      return store.select(appletContextAdapter.getSelectorInContext(selector, getAppletContextIdFromAction(action)));
    };
  }

  private getAppletContextIdFromAction(action: Action): string {
    if (!isActionWithAppletContextId(action)) {
      throw new Error(
        `[AppletContextFeatureStoreEffects] cannot get appletContextId from action '${action.type}', ensure it is dispatched by a AppletContextFeatureStoreService`
      );
    }

    return action.appletContextId;
  }
}
