import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {DOCUMENT} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {first, takeUntil} from 'rxjs/operators';

interface ExtendedFormControl extends FormControl {
  nativeElement: HTMLElement;
}

interface ExtendedValueAccessor extends ControlValueAccessor {
  _elementRef: ElementRef;
}

let nextUniqueId = 0;

@Component({
  selector: 'bsz-filter',
  styleUrls: ['./bsz-filter.scss'],
  templateUrl: './bsz-filter.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'bsz-filter',
  },
})
export class BszFilter implements OnInit, OnChanges, OnDestroy {
  /** The form group binding the model to the view */
  @Input() form: FormGroup;

  /** The value to reset the filter to when Reset all is pressed */
  @Input() resetValue: any;

  @Input() set disabled(disabled: BooleanInput) {
    this._disabled = coerceBooleanProperty(disabled);
  }

  get disabled(): BooleanInput {
    return this._disabled;
  }

  /** @private */
  _disabled = false;

  /** An event emitted after the filter is applied by submitting the form */
  @Output() filter = new EventEmitter(); // TODO: how to add type of form values? EventEmitter<???>()

  /** The filter form element */
  @ViewChild('filterFormDirective') filterFormDirective: FormGroupDirective;

  /** Whether to show the active indicator */
  isActive = false;

  /** Keep track of screen size */
  isMobile = false;

  id = `bsz-filter-${nextUniqueId++}`;

  /** Use unique id for each filter's form */
  formId = `${this.id}-form`;

  private resetFilterValue = {};
  private latestFilterValue = {};
  private destroy = new Subject<void>();
  private document: Document;
  private isOpen = false;

  constructor(
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    @Inject(DOCUMENT) document: any
  ) {
    // workaround to add nativeElement to form controls so that we can get the HTML elements
    this.addNativeElementToFormControls();
    // Angular compiler (without Ivy) does not like the Document type in the constructor function signature
    // see https://github.com/angular/angular/issues/20351
    this.document = document;
  }

  ngOnInit(): void {
    this.observeLayoutChanges();
    this.subscribeFormValueChanges();
  }

  /** When any Input changes, we re-initialise the filter */
  ngOnChanges(changes: SimpleChanges): void {
    this.initFilter();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  /** Manually set the filter status to active/inactive (show/hide active indicator) */
  setActive(active = true) {
    this.isActive = active;
    this.cd.markForCheck();
  }

  openFilter(filterPanel: TemplateRef<any>) {
    const dialogConfiguration: MatDialogConfig = {
      id: this.id,
      panelClass: ['bsz-filter-panel'],
      maxWidth: '100%',
      maxHeight: '100%',
    };

    if (this.isMobile) {
      dialogConfiguration.width = '100%';
      dialogConfiguration.height = '100%';
    }

    const dialogRef = this.dialog.open(filterPanel, dialogConfiguration);

    dialogRef
      .afterOpened()
      .pipe(first())
      .subscribe(() => this.formFieldOutlinePatch());

    this.initDialogSubscribers(this.dialog.getDialogById(this.id));
  }

  /**
   * This fixes the issue of misplaced labels on form fields with a prefix and the
   * outline appearance inside mat-dialog and animations enabled.
   *
   * The elements cannot be selected via @ViewChildren because of the ng-template
   * and also the form is projected through ng-content, thus making  @ViewChildren
   * to return `undefined`
   *
   * We manually reposition the label using the HTML elements directly and trying
   * to replicate the formula from: https://github.com/angular/components/blob/master/src/material/form-field/form-field.ts#L597
   *
   * @TODO remove this as soon as angular material releases a fix
   *
   * @see https://github.com/angular/components/issues/15027
   */
  private formFieldOutlinePatch() {
    const dialogElement = this.document.getElementById(this.id);
    if (!dialogElement) {
      return;
    }

    const outlineGapPadding = 5;
    const formFields = dialogElement.querySelectorAll('.mat-form-field.mat-form-field-appearance-outline');

    formFields.forEach((formFieldElement: HTMLElement) => {
      const prefixElement = formFieldElement.querySelector('.mat-form-field-prefix');

      if (!prefixElement) {
        return;
      }

      const formFieldPadding = window
        .getComputedStyle(formFieldElement.querySelector('.mat-form-field-flex') as HTMLElement)
        .getPropertyValue('padding-left');
      const prefixElementWidth = prefixElement.getBoundingClientRect().width;
      const outlineStartElements = formFieldElement.querySelectorAll('.mat-form-field-outline-start');

      const outLineStartWidth = prefixElementWidth + parseFloat(formFieldPadding) - outlineGapPadding;
      outlineStartElements.forEach((el: HTMLElement) => (el.style.width = `${outLineStartWidth}px`));
    });
  }

  /** Setup dialog's subscriptions */
  private initDialogSubscribers(dialog: MatDialogRef<any> | undefined): void {
    dialog?.backdropClick().subscribe(() => {
      this.resetFilterForm(this.latestFilterValue);
    });

    dialog?.keydownEvents().subscribe((event) => {
      if (event.code === 'Escape') {
        this.resetFilterForm(this.latestFilterValue);
      }
    });

    dialog?.afterOpened().subscribe(() => {
      this.isOpen = true;
    });

    dialog?.afterClosed().subscribe(() => {
      this.isOpen = false;
    });
  }

  /** Apply context to the aria label attribute attached to the trigger element of the filter */
  getTriggerAriaLabel(): void {
    if (this.isActive) {
      return this.translate.instant('ui-elements.bsz-filter.accessibility.criteria-applied');
    }
    return this.translate.instant('ui-elements.bsz-filter.accessibility.no-criteria-applied');
  }

  /** Submit the filter programmatically */
  submit(): void {
    this.onSubmit();
  }

  /** Form submitted */
  onSubmit(): void {
    // Workaround because of issue on FormGroup.reset()
    // where it does not reset the form validity status
    // https://github.com/angular/components/issues/9347
    if (!this.form.valid) {
      // Mark all form fields as touched to display error messages if form is invalid
      this.form.markAllAsTouched();
      return;
    }

    this.latestFilterValue = {...this.form.value};
    this.setActive(Object.keys(this.getActiveCriteria()).length > 0);
    this.closeFilterPanel();

    const normalizedValues = this.getFormValueNormalized();

    // emit normalized form value for clients
    this.filter.emit(normalizedValues);
  }

  /** Reset all button pressed */
  onReset(): void {
    this.resetFilterForm(this.resetFilterValue);
  }

  /** Cancel button pressed */
  onCancel(): void {
    this.resetFilterForm(this.latestFilterValue);
    this.closeFilterPanel();
  }

  closeFilterPanel(): void {
    this.dialog.getDialogById(this.id)?.close();
  }

  /** Initialize filter */
  private initFilter(): void {
    this.resetFilterValue = this.resetValue ? {...this.resetValue} : {...this.form.value};
    this.latestFilterValue = {...this.form.value};
  }

  /**
   * Returns a copy from form.value with consistent empty values that are casted to null
   * to keep consistency between undefined, null, or empty string.
   */
  private getFormValueNormalized(): any {
    const normalizedValues: {[key: string]: any} = {};

    for (const property in this.form.value) {
      if (this.form.value.hasOwnProperty(property)) {
        normalizedValues[property] = this.normalizeEmptyValueToNull(this.form.value[property]);
      }
    }

    return normalizedValues;
  }

  /**
   * Accepts a value of any type and returns null when it is any type
   * of the defined falsy ones
   *
   * @param value the value from the FormGroup
   */
  private normalizeEmptyValueToNull<T extends any>(value: T): T | null {
    return this.isEmptyValue(value) ? null : value;
  }

  private isEmptyValue(value: any): value is undefined | null | '' {
    return [undefined, null, ''].includes(value);
  }

  /**
   * Reset form to specified value - this will NOT submit the form
   *
   * @param newValue value to set the form to
   */
  private resetFilterForm(newValue?: any): void {
    this.filterFormDirective.resetForm(newValue);
  }

  /** Observe layout changes to set the isMobile flag */
  private observeLayoutChanges(): void {
    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.destroy))
      .subscribe((state: BreakpointState) => {
        this.isMobile = state.matches;
        this.cd.markForCheck();
        this.updateDialogLayout();
      });
  }

  /**
   * Update dialog layout
   */
  private updateDialogLayout(): void {
    if (this.isMobile) {
      this.dialog.getDialogById(this.id)?.updateSize('100%', '100%');
      return;
    }
    this.dialog.getDialogById(this.id)?.updateSize('initial', 'auto');
  }

  /**
   * Calling this method enhances the FormControlDirective by adding the
   * `nativeElement` attribute on the OnChanges lifecycle of the directive.
   *
   * NativeElement is used by getActiveCriteria() to identify the type of element (see checkbox exception)
   *
   * TODO: find a better way to get a reference to the native element of form controls
   */
  private addNativeElementToFormControls() {
    // from: https://stackoverflow.com/questions/39642547/is-it-possible-to-get-native-element-for-formcontrol

    // when used with FormControlDirective
    const originFormControlNgOnChanges = FormControlDirective.prototype.ngOnChanges;
    FormControlDirective.prototype.ngOnChanges = function () {
      if (this.valueAccessor && (this.valueAccessor as ExtendedValueAccessor)._elementRef) {
        (this.control as ExtendedFormControl).nativeElement = (
          this.valueAccessor as ExtendedValueAccessor
        )._elementRef.nativeElement;
      }
      return originFormControlNgOnChanges.apply(this, arguments);
    };

    // when used with FormControlName
    const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
    FormControlName.prototype.ngOnChanges = function () {
      const result = originFormControlNameNgOnChanges.apply(this, arguments);
      if (this.valueAccessor && (this.valueAccessor as ExtendedValueAccessor)._elementRef) {
        (this.control as ExtendedFormControl).nativeElement = (
          this.valueAccessor as ExtendedValueAccessor
        )._elementRef.nativeElement;
      }
      return result;
    };
  }

  /**
   * Returns a new key-pair object of active criteria
   *
   * Rules:
   * - mat-checkbox: only active when TRUE (false is ignored, see UISDK-188)
   * - others: only active when Truthy or false
   */
  private getActiveCriteria(): any {
    const criteria: {[key: string]: any} = {};

    if (this.form.valid) {
      Object.keys(this.form.controls).forEach((key) => {
        const control = this.form.controls[key] as any;
        if (this.isActiveControlValue(control)) {
          criteria[key] = control.value;
        }
      });
    }
    return criteria;
  }

  private isActiveControlValue(control: ExtendedFormControl): boolean {
    const nativeElement = control.nativeElement;
    const value = control.value;

    // MAT-CHECKBOX has special consideration (UISDK-188): when it is not selected (value = false), it is ignored
    // This means that when it is not selected, it is not considered in the list of active criteria
    const isCheckbox = nativeElement && nativeElement.tagName === 'MAT-CHECKBOX';
    if (isCheckbox) {
      return value;
    }

    return this.isActiveValue(value);
  }

  private isActiveValue(value: any): boolean {
    const isBoolean = typeof value === 'boolean';
    const isNumber = typeof value === 'number';
    if (isBoolean || isNumber) {
      return true;
    }

    const isArray = value && Array.isArray(value);
    // if it is an array, it is only valid if it has items
    if (isArray) {
      return !!value.length;
    }

    const isObject = value && typeof value === 'object' && !isArray;
    if (isObject) {
      const objectValues = Object.values(value);
      return objectValues.length > 0 && objectValues.some((objectValue) => this.isActiveValue(objectValue));
    }

    return value;
  }

  /**
   * From the user perspective, if the fields change while the dialog is open, they should not be kept
   * unless the filter is applied. It means that if he/she cancels and closes the dialog, when opening
   * it again, the values shown should be the ones that were used to filter last time. This applies to
   * the "reset all" action because it changes the values but they are not applied until "filter" action
   * is executed.
   * If the changes happen while the dialog is closed, it means it was done programmatically, so they
   * are intended to be shown to the user as already used filter values.
   */
  private subscribeFormValueChanges(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy)).subscribe(() => {
      if (!this.isOpen) {
        this.latestFilterValue = {...this.form.value};
      }
    });
  }
}
