import {ActionWithoutResponse} from '@basuiz/mobile-bridge';

/**
 * Applet action that opens a native form that allows user to create a new
 * collaboration issue.
 */
export class CreateCollaborationIssueAction extends ActionWithoutResponse<CreateCollaborationIssueRequest> {
  readonly name = 'createCollaborationIssue';
}

type CollaborationActiveActions = 'CALL_REQUEST' | 'APPOINTMENT' | 'MESSAGING';

/**
 * This interface requests the mobile native to show a native form that creates a collaboration issue.
 * This will optionally include subject/message and which buttons will be active by default and the ones that are enabled.
 */
export interface CreateCollaborationIssueRequest {
  /**
   * Text that will be predefined in title of the native form.
   */
  subject?: string;

  /**
   * Text that will be predefined in the first comment of the native form.
   */
  comment?: string;

  /**
   * List of active actions, the native form will not disable these buttons.
   */
  supportedIssueTypes?: CollaborationActiveActions[];

  /**
   * Issue type that is going to be preselected in the native form.
   */
  defaultIssueType?: CollaborationActiveActions;
}
