import {AfterViewInit, ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {ValueRange} from './value-range.interface';
import {isEqual} from 'lodash';
import {TranslatedText} from '@basuiz/web-app-applet-api';

@Component({
  selector: 'bsz-value-range',
  templateUrl: './bsz-value-range.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ValueRangeComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ValueRangeComponent),
      multi: true,
    },
  ],
})
export class ValueRangeComponent implements AfterViewInit, OnDestroy, ControlValueAccessor, Validator {
  /**
   *  A prefix to display when the value is input Example: 'CHF' for amounts including a chf currency.
   */
  @Input() prefix: TranslatedText = '';

  /**
   * Label to be shown for the range control in the form.
   */
  @Input() controlLabel: TranslatedText = '';

  /**
   * A text to be shown inside the From and To input fields as label. Example: percentage, amount.
   */
  @Input() valueLabel: TranslatedText = '';

  /**
   * The minimum possible value in the range
   */
  @Input() min: number | undefined;

  displayFromPrefix: boolean = false;
  displayToPrefix: boolean = false;

  valueRangeForm = new UntypedFormGroup({
    from: new UntypedFormControl(null),
    to: new UntypedFormControl(null),
  });

  onChange: (valueRange: ValueRange) => void = (valueRange) => {
    this.valueRangeForm.setValue(valueRange);
  };
  onTouch = () => {};
  onValidatorChange = () => {};

  private valueChangeSubscription: Subscription;

  ngAfterViewInit(): void {
    this.valueChangeSubscription = this.valueRangeForm.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((valueRange) => {
        this.onChange(valueRange);
        this.onValidatorChange();
      });
  }

  ngOnDestroy(): void {
    this.valueChangeSubscription?.unsubscribe();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.valueRangeForm.disable() : this.valueRangeForm.enable();
  }

  writeValue(newValue: ValueRange | null): void {
    if (newValue === null) {
      this.valueRangeForm.reset();
    } else {
      if (newValue && !isEqual(newValue, this.valueRangeForm.value)) {
        this.valueRangeForm.patchValue(newValue);
      }
    }

    this.displayFromPrefix = this.shouldDisplayThePrefix('from');
    this.displayToPrefix = this.shouldDisplayThePrefix('to');
  }

  validate(control: AbstractControl): ValidationErrors | null {
    let fromErrors: ValidationErrors | null = null;
    let toErrors: ValidationErrors | null = null;
    const fromControl = this.getFormControl('from');
    const toControl = this.getFormControl('to');

    if (this.min !== undefined && control.value.from !== null && control.value.from < this.min) {
      fromErrors = {min: {min: this.min, actual: control.value.from}};
      fromControl.markAsTouched();
    } else if (control.value.from !== null && control.value.to !== null && control.value.from > control.value.to) {
      toErrors = {valueRangeInvalid: true};
      toControl.markAsTouched();
    }

    //we attach the error to the `toControl`, so we display it within this control
    //like this, we dont use additional space
    fromControl.setErrors(fromErrors);
    toControl.setErrors(toErrors);
    return fromErrors || toErrors;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  shouldDisplayThePrefix(fieldPath: string): boolean {
    return this.prefix && this.getFormControl(fieldPath).value ? true : false;
  }

  getFormControl(formControlName: string): UntypedFormControl {
    // TODO: only necessary because formControlName can not be used with the bsz-filter
    return this.valueRangeForm.get(formControlName) as UntypedFormControl;
  }

  getMinErrorData(): string | undefined {
    const fromControl = this.getFormControl('from');
    return fromControl?.errors ? fromControl.errors['min'] : undefined;
  }
}
