import {Component, Inject} from '@angular/core';
import {BszI18nService} from '@basuiz/i18n';
import {WebAppInitializationResult, WebAppInitializationService} from '@basuiz/web-app-applet-sdk';
import {ComponentType} from '@angular/cdk/portal';
import {PORTAL_INITIALIZATION_FAILURE_CONFIG} from './components/portal-layout/portal-initialization-failure/portal-initialization-failure.config.provider';
import {PortalInitializationFailureConfig} from './components/portal-layout/portal-initialization-failure/portal-initialization-failure.config.definition';
import {PortalInitializationFailureComponent} from './components/portal-layout/portal-initialization-failure/portal-initialization-failure.component';
import {Observable} from 'rxjs';

@Component({
  selector: 'bsz-web-app-portal',
  template: `
    <ng-container *ngIf="initializationResult$ | async as initializationResult; else initializing">
      <bsz-portal-layout *ngIf="initializationResult === 'success'; else initializationFailure"></bsz-portal-layout>
      <ng-template #initializationFailure>
        <ng-container *ngComponentOutlet="initializationFailureComponent"></ng-container>
      </ng-template>
    </ng-container>
    <ng-template #initializing>
      <div>Loading ...</div>
    </ng-template>
  `,
  // side-panel rules require leaking to style cdkOverlay
  // TODO: Only needed for bsz-styling Rule AFP-42958
  // eslint-disable-next-line
  styleUrls: ['./web-app-portal.component.scss'],
})
export class WebAppPortalComponent {
  readonly initializationResult$: Observable<WebAppInitializationResult> =
    this.webAppInitializationService.getInitializationResult$();
  public readonly initializationFailureComponent: ComponentType<unknown>;

  constructor(
    private readonly bszI18nService: BszI18nService,
    @Inject(PORTAL_INITIALIZATION_FAILURE_CONFIG)
    readonly initializationFailureConfig: PortalInitializationFailureConfig,
    private readonly webAppInitializationService: WebAppInitializationService
  ) {
    this.initializationFailureComponent =
      initializationFailureConfig.type === 'custom'
        ? initializationFailureConfig.component
        : PortalInitializationFailureComponent;

    this.updateLanguageInIndexHtml();
  }

  private updateLanguageInIndexHtml(): void {
    const documentElement = document.documentElement;
    const langAttribute = document.createAttribute('lang');
    langAttribute.value = this.bszI18nService.localeId;
    documentElement.attributes.setNamedItem(langAttribute);
  }
}
