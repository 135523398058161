import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Directive,
  Input,
  OnInit,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import {ProgressSpinnerMode} from '@angular/material/progress-spinner';

export type BszProgressSpinnerSize = 'small' | 'medium' | 'large';

/**
 * This is used as a selector in the API.
 */
@Directive({
  selector: 'bsz-progress-spinner-label',
  host: {
    class: 'bsz-progress-spinner-custom-label',
  },
})
export class BszProgressSpinnerLabel {}

/**
 * This is used as a selector in the API.
 */
@Directive({
  selector: 'bsz-progress-spinner-info',
})
export class BszProgressSpinnerInfo {}

@Component({
  selector: 'bsz-progress-spinner-action',
  template: ` <button
    mat-button
    class="bsz-text-bold"
    color="primary"
    [disabled]="_disabled"
    [attr.aria-label]="ariaLabel || null"
    [attr.aria-labelledby]="ariaLabelledby || null"
  >
    <ng-content></ng-content>
  </button>`,
})
export class BszProgressSpinnerAction {
  @Input('aria-label') ariaLabel: string;
  @Input('aria-labelledby') ariaLabelledby: string;

  @Input() set disabled(disabled: BooleanInput) {
    this._disabled = coerceBooleanProperty(disabled);
  }

  /** @private */
  _disabled = false;
}

@Component({
  selector: 'bsz-progress-spinner',
  templateUrl: './bsz-progress-spinner.html',
  styleUrls: ['./bsz-progress-spinner.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BszProgressSpinner implements OnInit, AfterContentInit {
  @ContentChildren(BszProgressSpinnerAction) actions: QueryList<BszProgressSpinnerAction>;

  @Input() color: ThemePalette = 'primary';

  @Input() mode: ProgressSpinnerMode = 'indeterminate';

  @Input() value = 0;

  @Input('aria-label') ariaLabel: string | null = null;

  @Input('aria-labelledby') ariaLabelledby: string | null = null;

  @Input() set displayProcessCompletion(display: BooleanInput) {
    if (display && this.mode !== 'determinate') {
      console.warn(`${this.logPrefix} cannot show process completion with mode: "${this.mode}"`);
      this._displayProcessCompletion = false;
      return;
    }

    this._displayProcessCompletion = coerceBooleanProperty(display);
  }

  /** @private for internal use only */
  _displayProcessCompletion = false;

  @Input() set size(newSize: BszProgressSpinnerSize) {
    this._size = newSize;
    this._diameter = this.getSpinnerDiameter(newSize);
    this._strokeWidth = this.getStrokeWidth(newSize);
  }

  get size(): BszProgressSpinnerSize {
    return this._size;
  }

  protected readonly logPrefix = '[bsz-progress-spinner]';

  /** @private for internal use only */
  _diameter = 32;

  /** @private for internal use only */
  _size: BszProgressSpinnerSize = 'medium';

  /** @private for internal use only */
  _strokeWidth = 4;

  ngOnInit() {
    this._diameter = this.getSpinnerDiameter(this._size);
  }

  ngAfterContentInit() {
    if (this.actions.length > 1) {
      console.warn(`${this.logPrefix} cannot have more than one action. Only one is displayed`);
    }
  }

  private getSpinnerDiameter(size: BszProgressSpinnerSize): number {
    if (size === 'small') {
      return 24;
    }

    if (this._displayProcessCompletion && size === 'medium') {
      return 64;
    }

    if (this._displayProcessCompletion && size === 'large') {
      return 80;
    }

    if (size === 'medium') {
      return 48;
    }

    if (size === 'large') {
      return 64;
    }

    return 48;
  }

  private getStrokeWidth(size: BszProgressSpinnerSize): number {
    if (size === 'small') {
      return 3;
    }

    return 4;
  }

  /** @private for internal use only */
  isSmall(): boolean {
    return this._size === 'small';
  }

  /** @private for internal use only */
  getSizeClass(size: BszProgressSpinnerSize): string {
    const sizes = ['small', 'medium', 'large'];
    if (sizes.includes(size)) {
      return 'bsz-progress-spinner-' + size;
    }
    return '';
  }
}
