import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, map, tap} from 'rxjs/operators';
import {PortalLayoutService} from '../portal-layout/portal-layout.service';

@Component({
  selector: 'bsz-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollToTopComponent {
  constructor(private changeDetectorRef: ChangeDetectorRef, private portalLayoutService: PortalLayoutService) {}

  private readonly verticalScrollOffsetSubject = new BehaviorSubject<number>(window.pageYOffset);

  @HostListener('window:scroll', ['$event'])
  emitScrollEvent() {
    this.verticalScrollOffsetSubject.next(window.pageYOffset);
  }

  public readonly showScrollToTop$: Observable<boolean> = this.verticalScrollOffsetSubject.pipe(
    map((scrollOffset) => scrollOffset > 0),
    distinctUntilChanged(),
    tap(() => setTimeout(() => this.changeDetectorRef.detectChanges())) // have to force change detection since the scroll event is running outside of NgZone
  );

  scrollToTop() {
    this.portalLayoutService.scrollToTop();
  }
}
