import {createAction, props} from '@ngrx/store';
import {Country} from '../../models/country.definitions';
import {getErrorState} from '../../../utils/error-state';

export const loadCountries = createAction('[WebAppCommon/Country] Load Countries');
export const loadCountriesSuccess = createAction(
  '[WebAppCommon/Country] Load Countries Success',
  props<{countryList: Country[]}>()
);
export const loadCountriesFailed = createAction('[WebAppCommon/Country] Load Countries Failed', getErrorState);
