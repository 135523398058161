import {ActionWithoutResponse} from '@basuiz/mobile-bridge';

/**
 * Applet action that opens a native form that allows user to create a new
 * payment with predefined debit money account.
 */
export class NewPaymentAction extends ActionWithoutResponse<NewPaymentRequest> {
  readonly name = 'newPayment';
}

export interface NewPaymentRequest {
  /**
   * The debit money account id to make the payment from
   */
  debitMoneyAccountId?: number;
}
