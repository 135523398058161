import {ChangeDetectionStrategy, Component, VERSION} from '@angular/core';

@Component({
  selector: 'bsz-about-info-dev-tool',
  templateUrl: './about-info-dev-tool.component.html',
  styleUrls: ['./about-info-dev-tool.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutInfoDevToolComponent {
  public libName = '@basuiz/web-app';
  public uiVersion: string = window.basuiz.versions.ui[this.libName];

  public backendName = 'com.basuiz.afs.rest.services';
  public backendVersion = window.basuiz.versions.backend[this.backendName];

  public angularVersion = VERSION.full;

  logBasuizInfo() {
    if (typeof window?.basuiz?.info === 'function') {
      console.log('basuiz.info()');
      window.basuiz.info();
    } else {
      console.error('basuiz.info() not available on the window global object :/');
    }
  }
}
