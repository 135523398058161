import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {
  AFPUserRole,
  USER_ROLE_MAPPING,
  UserRole,
  UserRoleMapping,
  WEB_APP_REBOOT_HANDLER,
  WebAppRebootHandler,
} from '@basuiz/web-app-common';
import {afpRolesLocalStoreKey} from './user-roles.interceptor';

@Component({
  selector: 'bsz-user-roles-dev-tool',
  templateUrl: './user-roles-dev-tool.component.html',
  styleUrls: ['./user-roles-dev-tool.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRolesDevToolComponent {
  constructor(
    @Inject(USER_ROLE_MAPPING) private userRoleMapping: UserRoleMapping,
    @Inject(WEB_APP_REBOOT_HANDLER) private readonly rebootHandler: WebAppRebootHandler
  ) {}

  retail: boolean = this.checkRoleInLocalStore('RETAIL');
  corporate: boolean = this.checkRoleInLocalStore('CORPORATE');

  private checkRoleInLocalStore(userRole: UserRole): boolean {
    const afpRolesInLocaleStorage = localStorage.getItem(afpRolesLocalStoreKey);
    if (!afpRolesInLocaleStorage) {
      return false;
    }
    const afpUserRolesFromMapping = this.getAfpRoles(userRole);
    return (
      afpRolesInLocaleStorage
        .split(',')
        .filter((storageRole) => afpUserRolesFromMapping.some((mappingRole) => mappingRole === storageRole)).length > 0
    );
  }

  private _useInterceptor: boolean = localStorage.getItem(afpRolesLocalStoreKey) !== null;
  get useInterceptor(): boolean {
    return this._useInterceptor;
  }

  setUseInterceptor(value: boolean) {
    this._useInterceptor = value;
  }

  onReload() {
    if (this._useInterceptor) {
      localStorage.setItem(afpRolesLocalStoreKey, this.getRolesForLocalStore());
    } else {
      localStorage.removeItem(afpRolesLocalStoreKey);
    }
    this.rebootHandler({event: 'devToolsUpdate'});
  }

  private getRolesForLocalStore(): string {
    const roles: AFPUserRole[] = [
      ...(this.retail ? this.getAfpRoles('RETAIL') : []),
      ...(this.corporate ? this.getAfpRoles('CORPORATE') : []),
    ];
    return roles.join(',');
  }

  private getAfpRoles(userRole: UserRole): AFPUserRole[] {
    return Object.entries(this.userRoleMapping)
      .filter(([afprole, role]) => role === userRole)
      .map(([afpRole]) => afpRole);
  }
}
