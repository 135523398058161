import {bszWebAppThemeScopeCssClass} from '@basuiz/web-app-applet-api';

const palettesCache: string[][] = [];
const customColorCssVariableCache: Record<string, string> = {};

export function getColorFromPalette(palette: number, colorIndex: number) {
  const inCache: boolean = palettesCache[palette] && palettesCache[palette][colorIndex] !== undefined;
  if (!inCache) {
    const color: string = getPropertyValueFromDocumentOrWebAppThemeScopedElement(
      `--bsz-web-app-chart-palettes-${palette}`
    )
      .split(' ')
      .map((s) => s.trim())
      .filter((s) => s.length > 0)[colorIndex];

    palettesCache[palette] = palettesCache[palette] ?? [];
    palettesCache[palette][colorIndex] = color;
  }
  return palettesCache[palette][colorIndex];
}

export function getColorFromCustomCssColorVariable(customColorVariable?: string): string | undefined {
  if (customColorVariable) {
    const inCache: boolean = customColorCssVariableCache[customColorVariable] !== undefined;

    if (!inCache) {
      const dedicatedColorFromStyles: string = getPropertyValueFromDocumentOrWebAppThemeScopedElement(
        customColorVariable || ''
      );

      if (dedicatedColorFromStyles) {
        customColorCssVariableCache[customColorVariable] = dedicatedColorFromStyles;
      } else {
        // if no dedicated color found although configured for this chart series
        // then log an error and return null
        console.error(
          `A custom color for
          ${customColorVariable}
          was configured but not found in the root-styles. Please check the configuration or the css root variables.`
        );
        return undefined;
      }
    }
    return customColorCssVariableCache[customColorVariable];
  }

  return undefined;
}

let primaryColorCache: string;
export function getPrimaryColor(): string {
  if (!primaryColorCache) {
    primaryColorCache = getRawPrimaryChartColor().trim();
  }
  return primaryColorCache;
}

export function getRawPrimaryChartColor(): string {
  return getPropertyValueFromDocumentOrWebAppThemeScopedElement('--bsz-web-app-chart-primary');
}

export function getNegativeChartColor(): string {
  return getPropertyValueFromDocumentOrWebAppThemeScopedElement('--bsz-web-app-chart-negative').trim();
}

function getPropertyValueFromDocumentOrWebAppThemeScopedElement(property: string): string {
  let propertyValue = getComputedStyle(document.documentElement).getPropertyValue(property);
  if (!propertyValue) {
    const elementList = document.getElementsByClassName(bszWebAppThemeScopeCssClass);
    let iterator = 0;
    while (!propertyValue && iterator < elementList?.length) {
      propertyValue = getComputedStyle(elementList[0]).getPropertyValue(property);
      iterator = iterator + 1;
    }
  }
  return propertyValue;
}

export function hexToRGB(hex: string, alpha: string) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgb(${r}, ${g}, ${b})`;
  }
}

export function getChartFontFamilyFromTheme(): string {
  const fontFamily = getPropertyValueFromDocumentOrWebAppThemeScopedElement(
    '--bsz-web-app-typography-font-family'
  ).trim();
  return fontFamily;
}
