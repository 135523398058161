import {Type} from '@angular/core';
import {instance, mock} from 'ts-mockito';

/** @deprecated this function uses ts-mockito that is aimed to be removed from WBUI
 *  avoid consuming this service and replace it with Jest equivalent features */
export const mockService = <T>(
  toMock: Type<T>,
  setupMock?: (m: T) => void
): {
  provide: Type<T>;
  useFactory: () => T;
} => {
  const m = mock(toMock);
  if (setupMock) {
    setupMock(m);
  }
  return {provide: toMock, useFactory: () => instance(m)};
};
