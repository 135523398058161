import {InjectionToken} from '@angular/core';

/** Injection token that can be used to specify default pipe options. */
export const BSZ_NUMBER_PIPE_DEFAULT_OPTIONS = new InjectionToken<BszNumberPipeConfig>(
  'bsz_number_pipe_default_options'
);

export class BszNumberPipeConfig {
  constructor(public digitsInfo = '1.0-0', public emptyValue = '') {}
}
