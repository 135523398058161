import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {deleteSavedFilters, loadSavedFilterList, saveFilter, selectSavedFilter} from './saved-filter.actions';
import {FilterToSave, SavedFilter, SavedFilterKey} from '../models/saved-filter.interface';
import {Observable} from 'rxjs';
import {
  selectEntities,
  selectSavedFilterCallState,
  selectSelectedSavedFilter,
  selectSelectedSavedFilterName,
} from './saved-filter.selectors';
import {CallState} from '../../definitions/call-state.definition';

@Injectable({providedIn: 'root'})
export class SavedFilterFacade {
  constructor(private store: Store<{}>) {}

  /* Data streams */

  getSavedFiltersByType$(savedFilterKey: SavedFilterKey): Observable<SavedFilter[]> {
    return this.store.pipe(select(selectEntities, {savedFilterKey}));
  }

  getSelectedFilterByType$(savedFilterKey: SavedFilterKey): Observable<SavedFilter | undefined> {
    return this.store.pipe(select(selectSelectedSavedFilter, {savedFilterKey}));
  }

  getSelectedFilterNameByType$(savedFilterKey: SavedFilterKey): Observable<string> {
    return this.store.pipe(select(selectSelectedSavedFilterName, {savedFilterKey}));
  }

  getSavedFilterCallStateByType$(savedFilterKey: SavedFilterKey): Observable<CallState> {
    return this.store.pipe(select(selectSavedFilterCallState, {savedFilterKey}));
  }

  /* Dispatchers */
  loadFilters(savedFilterKey: SavedFilterKey) {
    this.store.dispatch(loadSavedFilterList({savedFilterKey}));
  }

  selectFilter(name: string, savedFilterKey: SavedFilterKey) {
    this.store.dispatch(selectSavedFilter({name, savedFilterKey}));
  }

  deselectFilter(savedFilterKey: SavedFilterKey) {
    this.store.dispatch(selectSavedFilter({name: null, savedFilterKey}));
  }

  deleteSelectedFilter(savedFilterKey: SavedFilterKey, savedFilter: SavedFilter) {
    this.store.dispatch(deleteSavedFilters({savedFilterKey, savedFilter}));
  }

  saveFilter(filterToSave: FilterToSave) {
    this.store.dispatch(saveFilter({filterToSave}));
  }
}
