import {ActionWithoutResponse} from '@basuiz/mobile-bridge';

/**
 * Simply opens URL that should point to SIX eBill portal page. The aleart that
 * is displayed to the user is tailored for eBill case.
 */
export class OpenSIXeBillPortalAction extends ActionWithoutResponse<OpenSIXeBillPortalRequest> {
  readonly name = 'openSIXeBillPortal';
}

export interface OpenSIXeBillPortalRequest {
  /**
   * URL that points to SIX eBill page that is going to be opened in external
   * browser. As any other URL it needs to has HTTPS scheme (HTTP is treated as
   * insecure).
   */
  url: string;
}
