import {AppShellOutletCategory} from './app-shell-outlet-category.definition';
import {AppShellOutletEmitter} from './app-shell-outlet-emitter.definition';
import {AppShellOutletReceiver} from './app-shell-outlet-receiver.definition';
import {BehaviorSubject, ReplaySubject} from 'rxjs';
import {AppShellOutletProjectionRequest} from './app-shell-outlet-projection-request.definition';
import {map} from 'rxjs/operators';
import {AppShellOutletBrokerRequestHandler} from './app-shell-outlet-broker-request-handler.definition';
import {isDefined} from '../utils/defined.util';

interface ActiveProjection {
  request: AppShellOutletProjectionRequest;
  revoke: () => void;
}

/** Class that mediates the projections requests made by any number of competing emitters
 * and the single receiver connected to a specific app-shell outlet.
 * I.e. the broker can determine how to handler new requests when the outlet is already in use by either
 * denying the new request, or by accepting it and thus revoking the request of the emitter currently using the outlet
 * Finer granularity can be achieved based on the parameters sent by the emitter in a projection requests.
 * */
export class AppShellOutletBroker<CATEGORY extends AppShellOutletCategory> {
  private isReceiverRegistered: boolean = false;
  private readonly activeProjectionSubject = new BehaviorSubject<ActiveProjection | undefined>(undefined);

  constructor(
    private readonly category: CATEGORY,
    private readonly requestHandler: AppShellOutletBrokerRequestHandler | undefined
  ) {}

  /** The emitter allows to send requests to project content through the app-shell outlet */
  get asEmitter(): AppShellOutletEmitter {
    return {
      requestProjection: (request) => {
        if (!isDefined(this.requestHandler)) {
          return {result: 'denied'};
        }
        const doAccept: boolean =
          this.isReceiverRegistered && this.requestHandler(request, this.activeProjectionSubject.value?.request);
        if (doAccept) {
          const oldActiveProjection = this.activeProjectionSubject.value;
          if (oldActiveProjection) {
            oldActiveProjection.revoke();
          }

          const requestRevokeSubject = new ReplaySubject<void>(1);

          const newActiveProjection: ActiveProjection = {
            request,
            revoke: () => {
              requestRevokeSubject.next(undefined);
              requestRevokeSubject.complete();
            },
          };

          setTimeout(() => {
            // a timeout is sometimes necessary to avoid destabilizing an ongoing change detection cycle
            this.activeProjectionSubject.next(newActiveProjection);
          });
          return {
            result: 'accepted',
            projectionRevoked$: requestRevokeSubject.asObservable(),
            releaseOutlet: () => {
              if (this.activeProjectionSubject.value === newActiveProjection) {
                newActiveProjection.request.content.detach();
                setTimeout(() => this.activeProjectionSubject.next(undefined));
              }
            },
          };
        } else {
          return {result: 'denied'};
        }
      },
    };
  }

  /** The receiver emits the contents to be rendered that the broker considers opportune */
  get asReceiver(): AppShellOutletReceiver {
    if (!isDefined(this.requestHandler)) {
      console.warn(`An app-shell outlet receiver is instantiated for category '${this.category}', however,
no app-shell outlet is provided for this category in the current provider scope by using 'appShellOutletBrokerProvider'`);
    }
    return {
      register: () => {
        if (this.isReceiverRegistered) {
          console.warn(`AppShellOutletBroker<${this.category}>: A receiver is already registered`);
        }
        this.isReceiverRegistered = true;
        return this.activeProjectionSubject.pipe(map((activeProjection) => activeProjection?.request.content));
      },
      deregister: (options) => {
        if (!this.isReceiverRegistered) {
          console.warn(`AppShellOutletBroker<${this.category}>: Deregistering a receiver which was not registered`);
        }
        this.isReceiverRegistered = false;
        const activeProjection = this.activeProjectionSubject.value;
        if (activeProjection) {
          activeProjection.revoke();
          this.activeProjectionSubject.next(undefined);
          if (options && !options?.skipDetach) {
            activeProjection.request.content.detach();
          }
        }
      },
    };
  }
}
