import {createEntityAdapter, Dictionary, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {CallState, LoadingState} from '../../../definitions/call-state.definition';
import {Country} from '../../models/country.definitions';
import {loadCountries, loadCountriesFailed, loadCountriesSuccess} from './country.actions';

export const FEATURE_KEY = 'web-app-common/country';

export interface PartialState {
  readonly [FEATURE_KEY]: State;
}

export interface CountryState extends EntityState<Country> {
  callState: CallState;
}

export interface CountryLoadedState extends EntityState<Country> {
  callState: LoadingState.LOADED;
  entities: Dictionary<Country>;
}

export type State = CountryLoadedState | CountryState;

export function isCountryLoadedState(state: State): state is CountryLoadedState {
  return state.callState === LoadingState.LOADED;
}

export const adapter: EntityAdapter<Country> = createEntityAdapter<Country>({
  selectId: (country: Country) => country.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  callState: LoadingState.INIT,
});

const countryReducer = createReducer(
  initialState,
  on(loadCountries, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(loadCountriesSuccess, (state, action) =>
    adapter.setAll(action.countryList, {
      ...state,
      callState: LoadingState.LOADED,
    })
  ),
  on(loadCountriesFailed, (state, error) => ({
    ...state,
    callState: error,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return countryReducer(state, action);
}
