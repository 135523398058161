import {NavigationIntent} from '../../navigation/classes/navigation-intent';
import {PaymentCardsPageTab} from '../../page-config/index';

export interface ShowPaymentCardsDetailsIntentPayload {
  view: PaymentCardsPageTab;
  paymentCardId: number;
}

export class ShowPaymentCardsDetailsIntent extends NavigationIntent {
  constructor(public payload: ShowPaymentCardsDetailsIntentPayload) {
    super();
  }
}
