import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  BszCurrencyPipeMock,
  BszDatePipeMock,
  BszIbanPipeMock,
  BszNumberPipeMock,
  BszPercentagePipeMock,
  BszPerformancePipeMock,
  BszStringPipeMock,
  BszLinkPipeMock,
  EmptyIfZeroPipeMock,
  BszValueWithFormatPipeMock,
  BszQuantityPipeMock,
} from './mocks/pipes.mocks';
import {BszCurrencyPipe} from './pipes/common/currency.pipe';
import {BszDatePipe} from './pipes/common/date.pipe';
import {BszIbanPipe} from './pipes/common/iban.pipe';
import {BszNumberPipe} from './pipes/common/number.pipe';
import {BszLinkPipe} from './pipes/common/link.pipe';
import {BszPercentagePipe} from './pipes/common/percentage.pipe';
import {BszPerformancePipe} from './pipes/common/performance.pipe';
import {BszStringPipe} from './pipes/common/string.pipe';
import {EmptyIfZeroPipe} from './pipes/empty-if-zero/empty-if-zero.pipe';
import {BszValueWithFormatPipe} from './pipes/value-with-format/value-with-format.pipe';
import {BszQuantityPipe} from './pipes/common/quantity.pipe';

const bszPipesMocks = [
  BszCurrencyPipeMock,
  BszDatePipeMock,
  BszIbanPipeMock,
  BszLinkPipeMock,
  BszNumberPipeMock,
  BszPercentagePipeMock,
  BszPerformancePipeMock,
  BszQuantityPipeMock,
  BszStringPipeMock,
  EmptyIfZeroPipeMock,
  BszValueWithFormatPipeMock,
];

@NgModule({
  declarations: [...bszPipesMocks],
  imports: [CommonModule],
  exports: [...bszPipesMocks],
  providers: [
    {provide: BszCurrencyPipe, useClass: BszCurrencyPipeMock},
    {provide: BszDatePipe, useClass: BszDatePipeMock},
    {provide: BszIbanPipe, useClass: BszIbanPipeMock},
    {provide: BszLinkPipe, useClass: BszLinkPipeMock},
    {provide: BszNumberPipe, useClass: BszNumberPipeMock},
    {provide: BszPercentagePipe, useClass: BszPercentagePipeMock},
    {provide: BszPerformancePipe, useClass: BszPerformancePipeMock},
    {provide: BszQuantityPipe, useClass: BszQuantityPipeMock},
    {provide: BszStringPipe, useClass: BszStringPipeMock},
    {provide: EmptyIfZeroPipe, useClass: EmptyIfZeroPipeMock},
    {provide: BszValueWithFormatPipe, useClass: BszValueWithFormatPipeMock},
  ],
})
export class FormattingTestingModule {}
