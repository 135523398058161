<div
  *ngIf="{
    loaded: loaded$ | async,
    loading: loading$ | async,
    error: error$ | async
  } as data"
  class="bsz-padding-x2 bsz-padding-y3"
>
  <div *ngIf="data.loading" class="bsz-position-relative bsz-padding-x4 bsz-padding-b12 bsz-padding-t10">
    <bsz-progress-bar bszTestId="{{ bszTestIdPrefix }}.progress-bar"></bsz-progress-bar>
  </div>
  <div *ngIf="data.error" class="bsz-padding-x4 bsz-padding-b12 bsz-padding-t10">
    <bsz-technical-error bszTestId="{{ bszTestIdPrefix }}.technical-error"></bsz-technical-error>
  </div>
  <div *ngIf="data.loaded">
    <div class="bsz-title-6 bsz-margin-b3">{{ 'web-app-alert-list-applet.alert-list.title' | translate }}</div>
    <bsz-counter-list
      (counterClicked)="counterClicked.emit($event)"
      bszTestId="{{ bszTestIdPrefix }}.bsz-counter-list"
    ></bsz-counter-list>
    <bsz-notification-list
      (notificationClicked)="notificationClicked.emit($event)"
      (showAllNotifications)="showAllNotifications.emit()"
      (markAllNotificationsAsRead)="markAllNotificationsAsRead.emit()"
      bszTestId="{{ bszTestIdPrefix }}.bsz-notification-list"
    ></bsz-notification-list>
  </div>
</div>
