import {AlertCard, DashboardPageConfig} from './dashboard-page.config.definition';
import {assertNever, UserRoleService} from '@basuiz/web-app-applet-sdk';
import {ExpandedFeatureToggle, isEnabled} from '@basuiz/web-app-feature-toggle';
import {selectDashboardFromUserRoles} from '../../navigation/select-dashboard-from-user-roles';

// IMPORTANT: do not modify the signature of this function
export function getDefaultConfig(
  userRoleService: UserRoleService,
  featureToggle: ExpandedFeatureToggle
): DashboardPageConfig {
  const selectedDashboard = selectDashboardFromUserRoles(userRoleService);
  switch (selectedDashboard) {
    case 'RETAIL':
      return {
        userRole: 'RETAIL',
        showUserGreeting: false,
        showQuickLinks: {
          debitCards: isEnabled(featureToggle.paymentCards?.debitCards),
          debitCards2: isEnabled(featureToggle.paymentCards?.debitCards),
        },
      };
    case 'CORPORATE':
      const alertCards: AlertCard[] = [];
      alertCards.push({
        cardId: 'collaboration',
        counters: ['COLLABORATION_ISSUES_UNREAD'],
      });
      alertCards.push({
        cardId: 'collaboration2',
        counters: ['COLLABORATION_ISSUES_UNREAD'],
      });

      return {
        userRole: 'CORPORATE',
        showQuickLinks: {
          debitCards: isEnabled(featureToggle.paymentCards?.debitCards),
          debitCards2: isEnabled(featureToggle.paymentCards?.debitCards),
          debitCards3: isEnabled(featureToggle.paymentCards?.debitCards),
        },
        alertCards: alertCards,
      };
    default:
      assertNever(selectedDashboard);
  }
}
