import {Inject, Injectable, Injector, OnDestroy} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {NavigationStart, ResolveEnd, Router} from '@angular/router';
import {assertNever, WEB_APP_REBOOT_HANDLER, WebAppRebootHandler} from '@basuiz/web-app-applet-sdk';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, from, Observable, of, Subject, Subscription} from 'rxjs';
import {catchError, filter, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {CustomNavigationIntentHandlerResult, PortalConfig} from '../config/portal.config.definition';
import {ɵPORTAL_CONFIG} from '../config/portal.config.provider';
import {PortalDebugService} from '../debug/portal-debug.service';
import {AppLoadsPage} from './classes/app-loads-page';
import {NavigationIntent, NavigationIntentClass} from './classes/navigation-intent';
import {PortalPage} from './classes/portal-page';
import {DEFAULT_NAVIGATION_INTENT_HANDLERS, DefaultNavigationIntentHandlerMap} from './default-intent-handlers.config';
import {HistoryNavigationEntry} from './history-navigation-entry';
import {AllNavigationOptions, NavigationOptions} from './navigation-options';

type RouterNavigationId = number;

interface PortalNavigation {
  routerNavigationId: RouterNavigationId;
  portalPage: PortalPage;
  navigatedUrl: string;
  historyNavigationEntries: HistoryNavigationEntry[];
}

type PortalNavigations = Array<PortalNavigation>;

export interface PortalPageWithNotifier {
  page: PortalPage;
  pageReadyNotifier?: Subject<void> | undefined;
}

type NavigationRequest = NavigationRequestByPage | NavigationRequestByIntent | NavigationRequestByBrowserHistory;

interface NavigationRequestByPage {
  type: 'page';
  page: PortalPage;
  navigationOptions: AllNavigationOptions;
}

interface NavigationRequestByIntent {
  type: 'intent';
  intent: NavigationIntent;
  pageReadyNotifier?: Subject<void>;
  navigationOptions: AllNavigationOptions;
}

interface NavigationRequestByBrowserHistory {
  type: 'browser-history';
}

interface HandledNavigationRequest {
  pageWithNotifier: PortalPageWithNotifier;
  navigationOptions: AllNavigationOptions;
}

@Injectable({
  providedIn: 'root',
})
export class NavigationService implements OnDestroy {
  private readonly navigations: PortalNavigations = [];

  private get lastNavigation(): PortalNavigation {
    return this.navigations[this.navigations.length - 1];
  }

  private readonly subscriptions = new Subscription();

  private nextPortalPageWithNotifier: PortalPageWithNotifier = {page: new AppLoadsPage()};

  private readonly currentPortalPageWithNotifierSubject: BehaviorSubject<PortalPageWithNotifier | undefined> =
    new BehaviorSubject(undefined);
  public readonly ɵcurrentPortalPageWithNotifier$ = this.currentPortalPageWithNotifierSubject.asObservable();
  public readonly currentPortalPage$: Observable<PortalPage> = this.ɵcurrentPortalPageWithNotifier$.pipe(
    filter((pageWithNotifier): pageWithNotifier is PortalPageWithNotifier => !!pageWithNotifier),
    map((pageWithNotifier) => pageWithNotifier?.page)
  );

  private resetHistoryNavigation: boolean = false;
  private historyNavigationEntriesSubject: Subject<HistoryNavigationEntry[]> = new BehaviorSubject([]);

  constructor(
    private router: Router,
    private injector: Injector,
    @Inject(DEFAULT_NAVIGATION_INTENT_HANDLERS) private defaultIntentHandlerMap: DefaultNavigationIntentHandlerMap,
    private portalDebugService: PortalDebugService,
    private titleService: Title,
    private translateService: TranslateService,
    @Inject(WEB_APP_REBOOT_HANDLER) private readonly rebootHandler: WebAppRebootHandler
  ) {
    this.trackRouterNavigationEvents();
    this.trackNavigationRequest();
  }

  private get portalConfig(): PortalConfig {
    return this.injector.get(ɵPORTAL_CONFIG);
  }

  private getAllNavigationOptions(overrides?: NavigationOptions): AllNavigationOptions {
    const defaultOptions: AllNavigationOptions = {
      resetHistoryNavigation: false,
      shouldResetAppletContextInSamePageNavigation: true,
    };
    return {...defaultOptions, ...overrides};
  }

  // PUBLIC API

  /** Triggers a navigation within the portal. I.e. to navigate to a portal page.
   * The navigation is driven by an intent, whose resolution will define the page to be navigated to.
   * @return: Observable that emits when the new page is initialized, it completes immediately after emitting or when a new navigation is requested */
  public navigate(intent: NavigationIntent, options?: NavigationOptions): Observable<void> {
    NavigationService.assertIsIntent(intent);

    const pageReadyNotifier = new Subject<void>();
    const navigationOptions = this.getAllNavigationOptions(options);
    this.navigationRequestSubject.next({type: 'intent', intent, pageReadyNotifier, navigationOptions});
    return pageReadyNotifier.asObservable();
  }

  /** Triggers a navigation within the portal to the previous page.
   * I.e. the page the user saw last before the current page.
   * This navigation imperative, i.e. is NOT using the browser-history. If after this navigation the user
   * hists the browser back-button, they will see the page that called this method.
   *
   * A fallback intent is required when calling this method.
   * In case there is no previous page where to navigate (i.e. method is called on the first navigation),
   * or if the last navigation had flag 'resetHistoryNavigation' set to true,
   * this intent will be used to calculate a new destination page.
   * */
  public navigateToPreviousPageWithFallback(fallbackIntent: NavigationIntent, options?: NavigationOptions) {
    const currentNavigation = this.navigations[this.navigations.length - 1];
    const isHistoryResetDoneOnCurrent = currentNavigation.historyNavigationEntries.length <= 1;

    const existsPreviousPage = this.navigations.length > 1;

    if (existsPreviousPage && !isHistoryResetDoneOnCurrent) {
      const previousPage: PortalPage = this.navigations[this.navigations.length - 2].portalPage;
      this.ɵnavigateByPortalPage(previousPage);
    } else {
      this.navigate(fallbackIntent, options);
    }
  }

  /**
   * Portal internal method. Do not use!
   * Functionality or existence can change without notice.
   */
  public ɵnavigateByPortalPage(page: PortalPage, options?: NavigationOptions): void {
    const navigationOptions = this.getAllNavigationOptions(options);
    this.navigationRequestSubject.next({type: 'page', page, navigationOptions});
  }

  public get isAppHandlingFirstNavigation(): boolean {
    // noinspection UnnecessaryLocalVariableJS
    const noNavigationsRecordedYet = this.navigations.length === 0;
    return noNavigationsRecordedYet;
  }

  public ɵreplaceAppLoadsPage(page: PortalPage): void {
    if (this.navigations.length === 1 && this.navigations[0].portalPage instanceof AppLoadsPage) {
      const firstNavigation: PortalNavigation = this.navigations[0];
      firstNavigation.portalPage = page;
      firstNavigation.historyNavigationEntries = [
        new HistoryNavigationEntry(firstNavigation.routerNavigationId, page.breadcrumbText),
      ];
      this.communicateNextPageFromNavigation({page: page, pageReadyNotifier: undefined});
    } else {
      throw new Error('Method can only be called right after the first navigation and before any other navigation');
    }
  }

  public get ɵhistoryNavigationEntries$(): Observable<HistoryNavigationEntry[]> {
    return this.historyNavigationEntriesSubject.asObservable();
  }

  // NAVIGATION REQUEST TRACKING

  private readonly navigationRequestSubject = new Subject<NavigationRequest>();
  private trackNavigationRequest() {
    const handledNavigationRequest$: Observable<HandledNavigationRequest> = this.navigationRequestSubject.pipe(
      tap((request) => {
        this.completePendingNotifier(request.type === 'intent' ? request.pageReadyNotifier : undefined);
      }),
      switchMap((request) => {
        /* Cancel a pending intent handler when a new navigation request is dispatched */
        if (request.type === 'intent') {
          return this.getHandledRequestFromIntent(request);
        } else if (request.type === 'page') {
          const {page, navigationOptions} = request;
          const pageWithNotifier: PortalPageWithNotifier = {page};
          return of({pageWithNotifier, navigationOptions});
        } else if (request.type === 'browser-history') {
          return of(null);
        } else {
          assertNever(request);
        }
      }),
      /* Some requests should not trigger the angular router,
       * e.g. request from navigation history or intents whose handler returned 'ignore' */
      filter((handledRequest): handledRequest is HandledNavigationRequest => !!handledRequest)
    );
    this.subscriptions.add(
      handledNavigationRequest$.subscribe((handledRequest) => {
        const {pageWithNotifier, navigationOptions} = handledRequest;
        this.navigateWithAngularRouter(pageWithNotifier, navigationOptions);
      })
    );
  }

  private navigateWithAngularRouter(pageWithNotifier: PortalPageWithNotifier, navigationOptions: AllNavigationOptions) {
    this.nextPortalPageWithNotifier = pageWithNotifier;
    this.resetHistoryNavigation = !!navigationOptions.resetHistoryNavigation;

    const {page: newPage} = pageWithNotifier;

    const currentPage = this.currentPortalPageWithNotifierSubject.getValue()?.page;

    /* Always call router.navigate, even when the active and new routes are the same,
     * it will cancel ongoing navigations, for example, in the scenario A (active) -> B (pending) -> A (new)
     * if router.navigate would not be called it could happen that the new navigation to A completes and
     * a bit later, when B is finally resolved after lazy loading, it replaces A on the screen */
    this.router.navigate(newPage.route).catch((err) => {
      console.error(`Failed to navigate based on the following portalPage`, newPage);
      throw err;
    });

    /* If the new route is the same to the active one, the router will not trigger its lifecycle events.
     * As consequence, when this happens, the service has to update and communicate the portal navigation */

    if (newPage.constructor === currentPage?.constructor) {
      this.replacePortalPageInCurrentNavigation(newPage);
    }
  }

  private replacePortalPageInCurrentNavigation(newPage: PortalPage): void {
    const currentNavigation = this.navigations.pop(); // remove the current navigation temporarily
    if (currentNavigation) {
      currentNavigation.portalPage = newPage;

      const lastHistoryNavigationEntry = currentNavigation.historyNavigationEntries.pop();
      if (lastHistoryNavigationEntry) {
        currentNavigation.historyNavigationEntries.push(
          new HistoryNavigationEntry(lastHistoryNavigationEntry.navId, newPage.breadcrumbText)
        );
      }
      this.registerPortalNavigation(currentNavigation); // re-register the current navigation after applying changes
    }
  }

  private lastPageReadyNotifier: Subject<void> | undefined;
  private completePendingNotifier(newPageReadyNotifier: Subject<void> | undefined) {
    /* If, when a new navigation is requested, the new page resulting from the last navigation is not ready yet,
     * and therefore it did not notify its readiness to the requester. The navigation service must complete the
     * pending notifier to indicate that the previous request is now cancelled (replaced by the new one) */
    if (this.lastPageReadyNotifier && !this.lastPageReadyNotifier.isStopped) {
      this.lastPageReadyNotifier.complete();
    }
    this.lastPageReadyNotifier = newPageReadyNotifier;
  }

  // ROUTER NAVIGATION EVENT TRACKING

  private trackRouterNavigationEvents() {
    this.subscriptions.add(
      this.resolveEndWithLatestNavigationStart$.subscribe(
        ([resolveEnd, navigationStart]: [ResolveEnd, NavigationStart]) => {
          NavigationService.assertEventsCorrespondToSameNavigationId(resolveEnd, navigationStart);

          const navigation: PortalNavigation = this.determinePortalNavigationFromRouterEvent(navigationStart);

          this.registerPortalNavigation(navigation);
          this.logNavigationTracking(navigationStart);
        }
      )
    );
  }

  private get resolveEndWithLatestNavigationStart$(): Observable<[ResolveEnd, NavigationStart]> {
    function eventIsResolveEnd(event: unknown): event is ResolveEnd {
      return event instanceof ResolveEnd;
    }

    const resolveEnd$: Observable<ResolveEnd> = this.router.events.pipe(filter(eventIsResolveEnd));

    function eventIsNavigationStart(event: unknown): event is NavigationStart {
      return event instanceof NavigationStart;
    }

    const navigationStart$: Observable<NavigationStart> = this.router.events.pipe(filter(eventIsNavigationStart));

    return resolveEnd$.pipe(withLatestFrom(navigationStart$));
  }

  private static assertEventsCorrespondToSameNavigationId(
    resolveEnd: ResolveEnd,
    navigationStart: NavigationStart
  ): void {
    if (resolveEnd.id !== navigationStart.id) {
      throw new Error(`Unexpected mismatch between the navigation id of ResolveEnd and NavigationStart router events.`);
    }
  }

  private registerPortalNavigation(navigation: PortalNavigation) {
    this.navigations.push(navigation);
    this.historyNavigationEntriesSubject.next(navigation.historyNavigationEntries);

    const nextPageReadyNotifier: Subject<void> | undefined =
      navigation.portalPage === this.nextPortalPageWithNotifier.page
        ? this.nextPortalPageWithNotifier.pageReadyNotifier
        : undefined;

    const nextPage: PortalPageWithNotifier = {
      page: navigation.portalPage,
      pageReadyNotifier: nextPageReadyNotifier,
    };

    this.communicateNextPageFromNavigation(nextPage);
  }

  private communicateNextPageFromNavigation(nextPage: PortalPageWithNotifier) {
    this.updateAppTitle(nextPage.page);
    this.currentPortalPageWithNotifierSubject.next(nextPage);
  }

  private updateAppTitle(portalPage: PortalPage) {
    if (!(portalPage instanceof AppLoadsPage)) {
      const appName = process.env['APP_NAME'] ?? null;
      const pageTitle = this.translateService.instant(portalPage.titleText);
      const newTitle = this.translateService.instant('web-app-portal.app-title', {
        page: `${pageTitle} | ${appName ? appName : 'App'}`,
      });
      this.titleService.setTitle(newTitle);
    }
  }

  private determinePortalNavigationFromRouterEvent(navigationStart: NavigationStart): PortalNavigation {
    if (this.popstateBelongsToPreviousAppInstance(navigationStart)) {
      /* The page is reloaded in the new URL the browser history points to, starting a new app-instance,
       * and thus avoiding the portal to consume navigations that are not available anymore in memory.
       * This behavior will occur on any subsequent navigation using the browser history, and will stop as
       * soon the user start triggering imperative navigations */
      this.rebootHandler({event: 'portalHistoryBackOnFirstNavigation'});
    }

    switch (navigationStart.navigationTrigger) {
      case 'imperative':
        return this.getNavigationFromImperative(navigationStart);
      case 'popstate':
        this.navigationRequestSubject.next({type: 'browser-history'}); // Notify a navigation-request to cancel a pending intent-handler
        return this.getNavigationFromPopState(navigationStart);
      case 'hashchange':
        throw new Error(`No logic defined for 'hashchange'`);
      case undefined:
        throw new Error(`Received undefined value for navigationTrigger`);
      default:
        assertNever(navigationStart.navigationTrigger);
    }
  }

  private popstateBelongsToPreviousAppInstance({id, navigationTrigger}: NavigationStart) {
    /*
     * Angular uses HTML5 history.pushState to prevent that every navigation leads to a page load from the server (i.e.
     * to prevent that the web-app restarts on every navigation)
     * https://angular.io/guide/router#locationstrategy-and-browser-url-styles
     * https://developer.mozilla.org/en-US/docs/Web/API/History_API#Adding_and_modifying_history_entries
     *
     * But if the user leaves the app (e.g. enters a URL or history navigation to a different site) and then
     * comes back using browser history a new app-instance will start, however, the portal-navigation records of the
     * previous app-instance were erased from the memory when the app was killed and are not available any longer.
     * I.e. the portal cannot retrieve page-instances and therefore lacks the data required by the target page.
     *
     * This can happen both while navigation back or forward in the history:
     * Forward navigation:
     *   1) type 'foo.com'
     *   2) type 'web-app.com'
     *   3) history back (app is killed when browser opens 'foo.com')
     *   4) history forward (new instance of web-app)
     *
     * Back navigation:
     *   1) type 'web-app.com'
     *   2) type 'foo.com' (app is killed)
     *   3) history back (new instance of web-app)
     *
     * When the user exits the app and comes back using the browser history, a new app-instance starts,
     * the navigation-1 always has 'navigationTrigger = imperative' so it is not possible to detect that the new
     * app-instance is created after a history back / forward.
     * The application will initialize correctly following the standard approach
     * (if the portal-page related to the URL would require a payload, a redirect to a parent page will occur).
     *
     * On navigation-2, if the 'navigationTrigger' equals to 'popstate', the app knows that the history-navigation
     * actually relates to a previous instance of the app, therefore the navigation record will not be available in
     * the memory. And there is no graceful way to solve this issue.
     *
     * E.g.
     * 1) User enters URL 'web-app.com', app starts and (commonly) redirect to 'web-app.com/dashboard'
     * 2) User within the app navigates to 'positions' within the app-instance
     * 3) User enters URL 'foo.com', the app-instance is killed and memory is erased
     * 4) User clicks back-button, browser goes to 'web-app.com/positions' navigation: {id: 1, trigger: imperative}
     * 5) User clicks back-button, browser foes to 'web-app.com/dashboard' navigation: {id: 2, trigger: popstate}
     *
     * {id: 2, trigger: popstate} cannot happen within a normal run of the app
     *
     * Examples
     * 1) User enters URL 'foo.com'
     * 2) User enters URL 'web-app.com' and redirects to 'web-app.com/dashboard' {id: 1, trigger: imperative}
     * 3) User clicks back-button and browser goes back to 'foo.com' killing the web-app app
     *
     * 1) User enters URL 'web-app.com' and redirects to 'web-app.com/dashboard' {id: 1, trigger: imperative}
     * 2) User navigates within the app to 'positions' {id: 2, trigger: imperative}
     * 3) User clicks on back-button {id: 3, trigger: popstate, id-to-restore: 2}
     */
    return navigationTrigger === 'popstate' && id === 2;
  }

  private getNavigationFromImperative(navigationStart: NavigationStart): PortalNavigation {
    const page: PortalPage = this.nextPortalPageWithNotifier.page;
    return {
      routerNavigationId: navigationStart.id,
      portalPage: page,
      navigatedUrl: navigationStart.url,
      historyNavigationEntries: this.getHistoryNavigationForImperative(navigationStart, page),
    };
  }

  private getNavigationFromPopState(navigationStart: NavigationStart): PortalNavigation {
    return {
      ...this.getRestoredNavigationOrFail(navigationStart),
      routerNavigationId: navigationStart.id,
    };
  }

  private getRestoredNavigationOrFail(navigationStart: NavigationStart): PortalNavigation {
    const restoredNavigationId = navigationStart.restoredState?.navigationId;
    const restoredNavigation = this.navigations.find(
      (navigation) => navigation.routerNavigationId === restoredNavigationId
    );
    if (!restoredNavigation) {
      throw new Error('Could not find a PortalNavigation instance for restored navigation id ' + restoredNavigationId);
    }
    return restoredNavigation;
  }

  private getHistoryNavigationForImperative(
    navigationStart: NavigationStart,
    page: PortalPage
  ): HistoryNavigationEntry[] {
    let previousHistoryNavigationEntries: HistoryNavigationEntry[] = [];
    if (!(page instanceof AppLoadsPage)) {
      if (this.lastNavigation) {
        previousHistoryNavigationEntries = this.resetHistoryNavigation
          ? []
          : this.lastNavigation.historyNavigationEntries;
      }
    }

    return [...previousHistoryNavigationEntries, new HistoryNavigationEntry(navigationStart.id, page.breadcrumbText)];
  }

  // INTENT HANDLING

  private getHandledRequestFromIntent(request: NavigationRequestByIntent): Observable<HandledNavigationRequest | null> {
    const {intent, pageReadyNotifier, navigationOptions} = request;
    return this.getValidPortalPageFromIntentOrFail(intent).pipe(
      map((handlerResult) => {
        if (handlerResult === 'ignore') {
          return null;
        } else if (handlerResult instanceof PortalPage) {
          const page = handlerResult;
          this.handlePageReset(page, navigationOptions);
          const pageWithNotifier: PortalPageWithNotifier = {page, pageReadyNotifier};
          return {pageWithNotifier, navigationOptions};
        } else {
          assertNever(handlerResult);
        }
      }),
      catchError((err) => {
        console.error(`Failed to navigate with intent ${intent.constructor.name}, error: ${err.message}`);
        return of(null);
      })
    );
  }

  private handlePageReset(page: PortalPage, navigationOptions: AllNavigationOptions): void {
    if (page.needsReset === undefined) {
      page.needsReset = true;
    }
    /* When the navigation does not require a reset, copy over the reset memory to avoid resetting
     * already visited contexts. Typically used by pages with entity selectors. */
    const currentPage = this.currentPortalPageWithNotifierSubject.getValue()?.page;
    const isSamePageNavigation = page.constructor === currentPage?.constructor;
    if (isSamePageNavigation && !navigationOptions.shouldResetAppletContextInSamePageNavigation && currentPage) {
      page.ɵresetMemory = currentPage.ɵresetMemory;
    }
  }

  private getValidPortalPageFromIntentOrFail(intent: NavigationIntent): Observable<PortalPage | 'ignore'> {
    return this.getPageFromIntent(intent).pipe(
      map((handlerResult) => {
        if (handlerResult === 'ignore') {
          return handlerResult;
        }
        if (!(handlerResult instanceof PortalPage)) {
          throw new Error('Intent handlers must return an instance of ' + PortalPage.name);
        }
        if (handlerResult instanceof AppLoadsPage) {
          throw new Error('Intent handlers are not allowed to use ' + AppLoadsPage.name);
        }
        return handlerResult;
      })
    );
  }

  private getPageFromIntent(intent: NavigationIntent): Observable<PortalPage | 'ignore'> {
    return this.getIntentCustomResult(intent).pipe(
      map((customResult) => {
        const result = customResult === 'execute-default' ? this.getIntentDefaultResult(intent) : customResult;
        this.logIntentHandling(intent, result, customResult === 'execute-default' ? 'DEFAULT' : 'CUSTOM');
        return result;
      })
    );
  }

  private getIntentCustomResult(intent: NavigationIntent): Observable<CustomNavigationIntentHandlerResult> {
    const customIntentHandler = this.portalConfig.navigation.customNavigationIntentHandlers.get(
      intent.constructor as NavigationIntentClass
    );
    if (!customIntentHandler) {
      return of('execute-default');
    } else {
      const handlerResult = customIntentHandler(intent);
      return isPromise(handlerResult) ? from(handlerResult) : of(handlerResult);
    }
  }

  private getIntentDefaultResult(intent: NavigationIntent): PortalPage {
    const defaultHandler = this.defaultIntentHandlerMap.get(intent.constructor as NavigationIntentClass);
    if (!defaultHandler) {
      throw new Error(`Missing portal default handler for intent class ${intent.constructor.name}`);
    }
    return defaultHandler(intent);
  }

  private static assertIsIntent(intent: NavigationIntent): intent is NavigationIntent {
    if (!intent.isNavigationIntent()) {
      console.error(intent);
      throw new Error(`Object build with constructor '${intent.constructor.name}' is not an intent`);
    }
    return true;
  }

  // DEBUG

  private logIntentHandling(
    intent: NavigationIntent,
    result: PortalPage | 'ignore',
    handlerOrigin: 'CUSTOM' | 'DEFAULT'
  ) {
    if (this.portalDebugService.navigationIntentHandling) {
      console.log('-->>' + '-'.repeat(56));
      console.log('Navigation from intent');
      console.log(`Intent: ${intent.constructor.name}`);
      console.log((intent as unknown as {payload: unknown}).payload || 'Intent without payload');
      console.log(`Handled with ${handlerOrigin} handler`);
      if (result instanceof PortalPage) {
        console.log(`Resulting PortalPage: ${result.constructor.name}`);
        console.log((result as unknown as {payload: unknown}).payload || 'Page instance without payload');
      } else if (result === 'ignore') {
        console.log(`Instruction given by the handler to ignore the intent.`);
      } else {
        assertNever(result);
      }
      console.log('--<<' + '-'.repeat(56));
    }
  }

  private logNavigationTracking(navStartEvent: NavigationStart): void {
    if (this.portalDebugService.navigationTracking) {
      console.log('>> ' + navStartEvent.constructor.name);
      console.log('url: ' + navStartEvent.url);
      console.log('id: ' + navStartEvent.id);
      console.log('navigationTrigger: ' + navStartEvent.navigationTrigger);
      console.log('restoredState.id: ' + (navStartEvent.restoredState ? navStartEvent.restoredState.navigationId : ''));

      console.log('Navigation history:');
      console.log(this.navigations);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}

function isPromise<T>(underTest: unknown): underTest is Promise<T> {
  return typeof (underTest as any)?.then === 'function';
}
