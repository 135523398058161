<div *ngIf="infoText?.length && {isMobile: isMobile$ | async} as data">
  <button
    class="bsz-padding-l2 bsz-display-flex"
    [style]="{width: TOUCH_AREA_LENGTH, height: TOUCH_AREA_LENGTH}"
    mat-icon-button
    [attr.aria-label]="
      data.isMobile
        ? ('web-app-common.info-text.icon.aria-label.mobile' | translate)
        : ('web-app-common.info-text.icon.aria-label.desktop' | translate)
    "
    [bszPopoverTriggerFor]="popover"
    [triggerEvent]="data.isMobile ? 'click' : 'hover'"
    [direction]="data.isMobile ? 'vertical' : 'horizontal'"
    bszTestId="{{ bszTestIdPrefix }}.info"
  >
    <mat-icon [class]="data.isMobile ? 'info-text-icon-mobile' : 'info-text-icon-desktop'">info_outline</mat-icon>
  </button>
  <bsz-popover #popover aria-label="{{ 'web-app-common.info-text.tooltip.aria-label' | translate }}">
    <div class="bsz-caption bsz-padding-x1 info-text-tooltip" bszTestId="{{ bszTestIdPrefix }}.tooltip">{{
      infoText
    }}</div>
  </bsz-popover>
</div>
