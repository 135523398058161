import {SelfServiceInvestmentProposalConfig} from './self-service-investment-proposal.config.definition';

// IMPORTANT: do not modify the signature of this function
export function getDefaultConfig(): SelfServiceInvestmentProposalConfig {
  return {
    proposalsOverviewUrl: '/AWS/investment-proposition-review/proposals-overview',
    singleProposalBaseUrl: '/AWS/investment-proposition-review/proposal',
    afpaasAssetAllocationBaseUrl: '/com.basuiz.afpaas.master.rest.services/assetallocation',
    afpaasInvestmentProposalBaseUrl: '/com.basuiz.afpaas.master.rest.services/investmentproposal',
  };
}
