import {
  HybridActionHandled,
  HybridActionNotHandled,
  HybridCollaborationAttachment,
  HybridDocumentAtUrl,
  HybridScanQrBillResult,
} from './hybrid-action.definitions';
import {HybridActionService} from './hybrid-action.service';

export class WebHybridActionService extends HybridActionService {
  isInsideNative(): boolean {
    return false;
  }

  async openExternalLink(httpsUrl: string): Promise<HybridActionHandled | HybridActionNotHandled> {
    if (!httpsUrl.match(/^https:\/\//i)) {
      console.error(`Security: The URL must use protocol HTTPS: ${httpsUrl}`);
      return 'unhandled';
    }

    this.openLinkInNewWindow(httpsUrl);
    return 'triggered';
  }

  async scanQrBill(): Promise<HybridScanQrBillResult | HybridActionNotHandled> {
    return 'unhandled';
  }

  async viewCollaborationAttachment(
    attachment: HybridCollaborationAttachment
  ): Promise<HybridActionHandled | HybridActionNotHandled> {
    return 'unhandled';
  }

  async viewDocumentAtUrl(documentAtUrl: HybridDocumentAtUrl): Promise<HybridActionHandled | HybridActionNotHandled> {
    return 'unhandled';
  }

  private openLinkInNewWindow(url: string): void {
    if (!url.match(/^https:\/\//i)) {
      console.warn(`After the HyperlinkService is removed only HTTPS URLs will be supported: ${url}`);
    }

    // TODO: Only needed for bsz-window-open Rule AFP-42958
    // eslint-disable-next-line
    window.open(url, '_blank', 'noopener,noreferrer');
  }
}
