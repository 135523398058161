import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DevToolsDialogComponent} from './dev-tools-dialog/dev-tools-dialog.component';
import {Theme, ThemeStyleLoader} from './theme-style-loader';

@Component({
  selector: 'bsz-portal-dev-tools',
  templateUrl: './dev-tools.component.html',
  styleUrls: ['./dev-tools.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevToolsComponent {
  constructor(public dialog: MatDialog) {}

  openDevTools() {
    const dialogRef = this.dialog.open(DevToolsDialogComponent, {
      width: '400px',
    });
  }

  activeTheme: Theme = ThemeStyleLoader.getThemeFromStorage();
  toggleTheme() {
    this.activeTheme = ThemeStyleLoader.toggleTheme();
  }
}
