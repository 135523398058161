import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {BszLabelValue} from './bsz-label-value';

@NgModule({
  declarations: [BszLabelValue],
  imports: [CommonModule],
  exports: [BszLabelValue],
})
export class BszLabelValueModule {}
