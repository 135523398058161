import {JestFixtureFacade, JestFixtureFacadeAware, JestMatButton, JestMatMenu} from '../../../testing-core/index';
import {ComponentFixture} from '@angular/core/testing';

export class ExportToFileButtonPageObject implements JestFixtureFacadeAware {
  jestFixtureFacade: JestFixtureFacade;

  constructor(fixture: ComponentFixture<any>) {
    this.jestFixtureFacade = new JestFixtureFacade(fixture);
  }

  get singleFileButton(): JestMatButton {
    return new JestMatButton('web-app-common.export-to-file.single-file-button', this.jestFixtureFacade);
  }

  get multiFileButton(): JestMatButton {
    return new JestMatButton('web-app-common.export-to-file.multi-file-button', this.jestFixtureFacade);
  }

  get menu(): JestMatMenu {
    return new JestMatMenu('web-app-common.export-to-file.menu', this.jestFixtureFacade);
  }
}
