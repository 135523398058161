import {CustomNavigationIntentHandlerMap, WebAppPortalModuleOptions} from '@basuiz/web-app-portal';
import {getPortalHybridConfig} from './portal.hybrid-config';

/*
 * This interface mirrors the shape of `WebAppPortalModuleOptions`. The `config` property is for overrides to
 * the `PortalConfig`. Leaving open the possibility to add a `pageConfig` option for hybrid overrides to pages configs.
 */
export interface WebAppPortalModuleHybridOverrides {
  config: PortalConfigHybridOverridesFactoryWithDeps;
}

export interface PortalConfigHybridOverridesFactoryWithDeps {
  useFactory: (...deps: unknown[]) => PortalConfigHybridOverrides;
  deps?: unknown[];
}

export interface PortalConfigHybridOverrides {
  /**
   * Intent handlers that will only be applied when the app is running inside the hybrid-native app. Handlers can be
   * provided in multiple places with the following order of precedence:
   *
   * 1. customer provided in hybridOverrides.config.customNavigationIntentHandlers
   * 2. Basuiz default portal-hybrid handler overrides in `getPortalConfigForHybrid`
   * 3. customer provided portal.config.customNavigationIntentHandlers
   *
   * If more than one handler is provided for the same intent they will be called in priority order until the first one
   * handles the intent by returning a `PortalPage` or `ignore`.
   */
  customNavigationIntentHandlers: CustomNavigationIntentHandlerMap;
}

export function getPortalConfigForHybrid(
  options: {
    /**
     * Customer own configuration for the portal.
     * When the web-app is running inside a native-app this config is subject to manipulation from the hybrid lib
     */
    portal?: WebAppPortalModuleOptions;

    /**
     * Customer own hybrid overrides.
     * Used to manipulate the portal configuration when the web-app runs inside a native-mobile app.
     * These overrides have priority over the default overrides made by the portal-hybrid library
     */
    hybridOverrides?: WebAppPortalModuleHybridOverrides;
  } = {}
): WebAppPortalModuleOptions {
  return {
    ...options.portal,
    config: getPortalHybridConfig(options.portal?.config, options.hybridOverrides?.config),
  };
}
