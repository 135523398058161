<div class="bsz-display-flex bsz-justify-center bsz-padding-y4 bsz-title-2 bsz-background-disabled">
  <mat-icon inline="true" class="bsz-text-success">check_circle_outline</mat-icon>
</div>

<div class="bsz-padding-y6 bsz-padding-x2">
  <div class="bsz-padding-x4">
    <h3 mat-dialog-title class="bsz-title-6">
      {{ dialogData.message }}
    </h3>

    <ng-container *ngFor="let remark of dialogData.remarks || []">
      <bsz-wb-banner
        *ngIf="remark.severity === 'warning' || remark.severity === 'error'; else paragraph"
        [type]="remark.severity"
      >
        <span class="bsz-body-1">{{ remark.message }}</span>
      </bsz-wb-banner>
      <ng-template #paragraph>
        <p class="bsz-body-1">{{ remark.message }}</p>
      </ng-template>
    </ng-container>
  </div>

  <mat-nav-list *ngIf="dialogData.actions.length > 0">
    <ng-container *ngFor="let action of dialogData.actions">
      <mat-list-item (click)="resolveAction(action)" [disabled]="action.failed">
        <a bszAccessibleLink [underline]="false">
          <div class="bsz-text-primary bsz-body-1 bsz-display-flex bsz-align-items-center">
            <ng-container *ngIf="action.failed; else actionIcon">
              <mat-icon class="bsz-margin-r2 bsz-text-error">error_outline</mat-icon>
            </ng-container>
            <ng-template #actionIcon>
              <mat-icon class="bsz-margin-r2">{{ action.icon }}</mat-icon>
            </ng-template>
            <div class="bsz-display-flex bsz-flex-column">
              <span>{{ action.label }}</span>
              <span class="bsz-caption bsz-text-secondary" *ngIf="action.failed">{{
                'web-app-portal.ui-notification-dialog.actions.error-hint' | translate
              }}</span>
            </div>
          </div>
        </a>
      </mat-list-item>
    </ng-container>
  </mat-nav-list>

  <div class="bsz-display-flex bsz-justify-end bsz-margin-t7 bsz-margin-r2">
    <button mat-dialog-close mat-flat-button color="accent" bszTestId="{{ bszTestIdPrefix }}.close-dialog.button">
      {{ 'web-app-portal.ui-notification-dialog.close-dialog.button.label' | translate }}
    </button>
  </div>
</div>
