<button
  mat-icon-button
  [attr.aria-label]="'ui-elements.bsz-table.accessibility.show-hide-columns' | translate"
  [matMenuTriggerFor]="menu"
>
  <i class="material-icons">visibility</i>
</button>
<mat-menu #menu="matMenu">
  <mat-selection-list
    [attr.dense]="dense"
    (selectionChange)="toggleColumnsVisibility($event)"
    [attr.aria-label]="'ui-elements.bsz-table.accessibility.show-hide-columns' | translate"
  >
    <mat-list-option
      selected
      checkboxPosition="before"
      *ngFor="let column of toggleableColumns"
      (click)="$event.stopPropagation()"
      [disabled]="disabled(column)"
      [value]="column.property"
    >
      {{ column.text }}
    </mat-list-option>
  </mat-selection-list>
</mat-menu>
