<span class="bsz-subtitle-2 bsz-padding-b2"> {{ controlLabel }}</span>
<div>
  <mat-form-field class="bsz-padding-r3 bsz-display-inline-block bsz-width-50pct">
    <mat-label>{{ 'web-app-common.value-range.from.label' | translate: {valueLabel: valueLabel} }}</mat-label>
    <span matPrefix *ngIf="displayFromPrefix" class="bsz-caption bsz-text-disabled bsz-padding-x2">{{ prefix }}</span>
    <input
      type="number"
      (focusin)="displayFromPrefix = !!prefix"
      (focusout)="displayFromPrefix = shouldDisplayThePrefix('from')"
      [attr.aria-label]="'web-app-common.value-range.from.aria-label' | translate"
      matInput
      [min]="min"
      [formControl]="getFormControl('from')"
      (blur)="onTouch()"
      bszTestId="web-app-common.value-range.from"
    />
  </mat-form-field>
  <mat-form-field class="bsz-display-inline-block bsz-width-50pct">
    <mat-label>{{ 'web-app-common.value-range.to.label' | translate: {valueLabel: valueLabel} }}</mat-label>
    <span matPrefix *ngIf="displayToPrefix" class="bsz-caption bsz-text-disabled bsz-padding-x2">{{ prefix }}</span>
    <input
      type="number"
      (focusin)="displayToPrefix = !!prefix"
      (focusout)="displayToPrefix = shouldDisplayThePrefix('to')"
      [attr.aria-label]="'web-app-common.value-range.to.aria-label' | translate"
      matInput
      [min]="min"
      [formControl]="getFormControl('to')"
      (blur)="onTouch()"
      bszTestId="web-app-common.value-range.to"
    />
    <mat-error *ngIf="getFormControl('to').hasError('valueRangeInvalid')">{{
      'web-app-common.value-range.errors.invalid-range-error' | translate
    }}</mat-error>
  </mat-form-field>
</div>
