import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'bsz-unsaved-data-guard-dialog',
  templateUrl: './unsaved-data-guard-dialog.component.html',
  styleUrls: ['./unsaved-data-guard-dialog.component.scss'],
})
export class UnsavedDataGuardDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<UnsavedDataGuardDialogComponent>) {}

  ngOnInit() {
    this.dialogRef.disableClose = true;
  }

  confirmAction() {
    this.dialogRef.close(true);
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
}
