import {ExpandedFeatureToggle, isEnabled} from '@basuiz/web-app-feature-toggle';
import {DashboardPage} from '../pages/pages.index';
import {getDefaultMainNavConfig} from './main-nav.config.default';
import {getDefaultDisabledPages} from './portal.config.default.disabled-pages';
import {PortalConfig} from './portal.config.definition';
import {UserRoleService} from '@basuiz/web-app-applet-sdk';
import {selectDashboardFromUserRoles} from '../navigation/select-dashboard-from-user-roles';

// IMPORTANT: do not modify the signature of this function
export function getDefaultConfig(featureToggle: ExpandedFeatureToggle, userRoleService: UserRoleService): PortalConfig {
  return {
    bankLogoFile: undefined,
    sessionManagement: {
      logOutResolver: () => {
        window.location.href = '/auth/logout';
      },
      changePasswordResolver: () => {
        window.location.href = '/auth/password-change';
      },
      sessionExpiredCheckIntervalInSeconds: 20,
      sessionExpiredCheckPingUrl: 'session/ping',
    },
    navigation: {
      homePortalPage: new DashboardPage(),
      disabledPortalPages: getDefaultDisabledPages(featureToggle, userRoleService),
      mainNav: getDefaultMainNavConfig(featureToggle),
      customNavigationIntentHandlers: new Map(),
      dashboardIdResolver: () => selectDashboardFromUserRoles(userRoleService),
    },
    portalLayout: {
      showAppShell: true,
      maxPageWidthInPx: 1128,
      mainNavWidthInPx: 300,
      mainNavModeBufferInPx: 24,
      mainNavInitiallyOpened: true,
      mainNavCustomComponent: undefined,
      appBarCustomComponent: undefined,
    },
    showGlobalFilters: isEnabled(featureToggle.globalFilters),
    showNotifications: isEnabled(featureToggle.notifications),
    pageLayout: {
      breadcrumbs: {
        mobile: {style: 'back-button'},
        tablet: {style: 'hierarchy', startWithHome: true},
        desktop: {style: 'hierarchy', startWithHome: true},
      },
      showPageTitle: true,
      showPageSubtitle: true,
    },
    pageHelpContent: new Map(),
  };
}
