import {MatSort} from '@angular/material/sort';

import {BszTableTreeRow} from './bsz-table.definitions';

const SORT_DIRECTION_ASC = 'asc';
const SORT_DIRECTION_RESET = '';

/**
 * Gets a sorted copy of the data array based on the state of the MatSort.
 */
export function bszTableTreeSort(data: BszTableTreeRow[], sort: MatSort): BszTableTreeRow[] {
  const active = sort.active;
  const direction = sort.direction;
  if (!active || direction === SORT_DIRECTION_RESET) {
    return data;
  }

  return sortDataSet(data, active, direction);
}

/**
 * Iterate through the dataset and have the GroupHeaders as a "checkpoint" to isolate groups
 * and sort only those groups.
 *
 * Every time we sort, GroupHeaders should remain in place and only the "leaves" of the
 * dataset should be sorted at a time.
 */
function sortDataSet(data: BszTableTreeRow[], active: string, direction: string): BszTableTreeRow[] {
  /** The final sorted array to return */
  const sortedArray = [];
  /** Here we store the items that are not GroupHeaders */
  let tmpData: BszTableTreeRow[] = [];
  data.forEach((item: BszTableTreeRow) => {
    if (item._isGroupHeader) {
      // when groupHeader, sort and push items to sortedArray.
      if (tmpData.length > 0) {
        sortedArray.push(...tmpData.sort((a, b) => sortCompareFunction(a, b, active, direction)));
      }
      // then push the groupHeaders and continue.
      sortedArray.push(item);
      // clean tmp
      tmpData = [];
    } else {
      // while not a group header, push item to the tmpData.
      tmpData.push(item);
    }
  });

  // push any remaining items.
  if (tmpData.length > 0) {
    sortedArray.push(...tmpData.sort((a, b) => sortCompareFunction(a, b, active, direction)));
  }

  return sortedArray;
}

function sortCompareFunction(
  firstEl: BszTableTreeRow,
  secondEl: BszTableTreeRow,
  active: string,
  direction: string
): number {
  const valueA = firstEl[active];
  const valueB = secondEl[active];

  // If both valueA and valueB exist (truthy), then compare the two. Otherwise, check if
  // one value exists while the other doesn't. In this case, existing value should come first.
  // This avoids inconsistent results when comparing values to undefined/null.
  // If neither value exists, return 0 (equal).
  let comparatorResult: 0 | 1 | -1;
  if (valueA != null && valueB != null) {
    // Check if one value is greater than the other; if equal, comparatorResult should remain 0.
    if (valueA > valueB) {
      comparatorResult = 1;
    } else if (valueA < valueB) {
      comparatorResult = -1;
    } else {
      comparatorResult = 0;
    }
  } else if (valueA != null) {
    comparatorResult = 1;
  } else {
    // valueB is null
    comparatorResult = -1;
  }

  return comparatorResult * (direction === SORT_DIRECTION_ASC ? 1 : -1);
}
