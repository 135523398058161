import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {isAfpRestResponseRaw} from '../type-guards/afp-rest-response.type-guards';
import {AfpRestResponse, AfpRestResponseRaw} from '../models/afp-rest-response.definitions';
import {get, isArray, isPlainObject, map as _map, omit, reduce} from 'lodash';

const AFP_URLS_WITH_PAYLOAD_DEPENDENCIES_TO_RESOLVE: Array<string> = ['/com.basuiz.afs.rest.services/rest'];

/*
 * A response has the shape:
 *
 * { dependencies: [...],
 *   payload: {...},
 *   notifications: [...]
 * }
 *
 * Where the payload has been normalized by extracting common dependencies from the data.
 *
 * The interceptor uses the DependencyResolverService to denormalize the payload, replacing all of the
 * references to dependencies with the actual dependency so that the client code does not have to manage
 * the more complex normalized data structure.
 */
@Injectable()
export class AfpRestResponsePayloadHttpInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.shouldIntercept(req)) {
      return next.handle(req).pipe(
        map((event) => this.resolveResponse(event)),
        catchError((errorResponse: HttpErrorResponse) => throwError(() => this.resolveErrorResponse(errorResponse)))
      );
    }

    return next.handle(req);
  }

  private shouldIntercept(req: HttpRequest<any>): boolean {
    return this.isAfpRestUrl(req.url);
  }

  private isAfpRestUrl(url: string): boolean {
    return AFP_URLS_WITH_PAYLOAD_DEPENDENCIES_TO_RESOLVE.some((afpUrl) => url.includes(afpUrl));
  }

  private resolveResponse(event: HttpEvent<any>): HttpEvent<any> {
    if (event instanceof HttpResponse && isAfpRestResponseRaw(event.body)) {
      return event.clone({
        body: this.resolvePayloadDependencies(event.body),
      });
    }

    return event;
  }

  private resolveErrorResponse(errorResponse: HttpErrorResponse): HttpErrorResponse {
    const {error, headers, status, statusText} = errorResponse;

    if (isAfpRestResponseRaw(error)) {
      return new HttpErrorResponse({
        error: this.resolvePayloadDependencies(error),
        headers,
        status,
        statusText,
        url: errorResponse.url ?? undefined,
      });
    }

    return errorResponse;
  }

  private resolvePayloadDependencies(rawResponse: AfpRestResponseRaw): AfpRestResponse<any> {
    const {payload, dependencies, ...response} = rawResponse;

    return {
      ...response,
      payload: this.denormalizePayload(payload, dependencies),
    };
  }

  private denormalizePayload(payload: any, dependencies: any): any {
    if (isArray(payload)) {
      return _map(payload, (payloadElem) => this.denormalizePayload(payloadElem, dependencies));
    }
    if (isPlainObject(payload)) {
      const denormalizedObject = reduce(
        payload,
        (result: any, propertyValue: any, propertyName: string) => {
          const propertyIsReference = isPlainObject(propertyValue) && get(propertyValue, '_shape') === 'reference';
          result[propertyName] = propertyIsReference
            ? this.denormalizeReference(propertyValue, dependencies)
            : this.denormalizePayload(propertyValue, dependencies);
          return result;
        },
        {}
      );
      return omit(denormalizedObject, ['_type', '_shape', 'jsonDependencyId']);
    }
    return payload;
  }

  private denormalizeReference(serializedObject: any, dependencies: any) {
    const type = get(serializedObject, '_type');
    const dependencyId = get(serializedObject, '_dependencyId');
    const objectsOfType = dependencies[type];
    const referencedObject = objectsOfType && isPlainObject(objectsOfType) && objectsOfType[dependencyId];
    if (!referencedObject) {
      throw new Error(
        'Object of type: ' +
          type +
          ' with dependencyId: ' +
          dependencyId +
          ' not found in: ' +
          JSON.stringify(dependencies) +
          '.'
      );
    }
    return this.denormalizePayload(referencedObject, dependencies);
  }
}
