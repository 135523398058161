<section class="bsz-splash-screen-focus-trap-container" [cdkTrapFocus]="true" [cdkTrapFocusAutoCapture]="true">
  <div class="bsz-splash-screen-logo-section">
    <div class="bsz-splash-screen-image-container">
      <img
        [src]="imgSrc"
        [@bszSplashScreenImageEnterTransition]="_imgLoadingState"
        (load)="_imageLoaded()"
        class="bsz-splash-screen-image"
        alt="logo"
      />
    </div>
  
    <mat-progress-spinner aria-label="spinner" *ngIf="displaySpinner" [diameter]="24" [strokeWidth]="3" mode="indeterminate"></mat-progress-spinner>
  
    <div class="bsz-splash-screen-info bsz-splash-screen-info-small-screen">{{ info }}</div>
  </div>
  
  <div class="bsz-splash-screen-text">
    <div class="bsz-splash-screen-tip"><mat-icon class="bsz-text-warning">lightbulb</mat-icon> {{ tip }}</div>
    <div class="bsz-splash-screen-info bsz-splash-screen-info-large-screen">{{ info }}</div>
  </div>
</section>