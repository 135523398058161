import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {BszI18nModule} from '@basuiz/i18n';

import {BszFilter} from './bsz-filter';

@NgModule({
  declarations: [BszFilter],
  imports: [ReactiveFormsModule, CommonModule, BszI18nModule, MatButtonModule, MatDialogModule],
  exports: [BszFilter],
})
export class BszFilterModule {}
