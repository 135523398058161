import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {BszFormFieldErrorsModule} from '../form-field-errors';
import {WebAppBasicModule} from '../web-app-basic';
import {AuthMarketingComponent} from './components/auth-marketing/auth-marketing.component';
import {EmailVerifyComponent} from './components/email-verify/email-verify.component';
import {ResetPasswordRequestComponent} from './components/reset-password-request/reset-password-request.component';
import {ResetPasswordValidateComponent} from './components/reset-password-validate/reset-password-validate.component';
import {SigninComponent} from './components/signin/signin.component';
import {SignupComponent} from './components/signup/signup.component';

@NgModule({
  imports: [WebAppBasicModule, ReactiveFormsModule, BszFormFieldErrorsModule],
  declarations: [
    AuthMarketingComponent,
    EmailVerifyComponent,
    ResetPasswordRequestComponent,
    ResetPasswordValidateComponent,
    SigninComponent,
    SignupComponent,
  ],
  providers: [],
  exports: [
    EmailVerifyComponent,
    ResetPasswordRequestComponent,
    ResetPasswordValidateComponent,
    SigninComponent,
    SignupComponent,
  ],
})
export class WebAppCommonAuthJwtModule {}
