import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {BszNotificationsComponent} from './bsz-notifications.component';
import {WebAppBannerModule} from '../ui-sdk-proxy/banner/index';
import {TranslateModule} from '@ngx-translate/core';
import {TestingCoreModule} from '../testing-core/index';

@NgModule({
  declarations: [BszNotificationsComponent],
  imports: [WebAppBannerModule, CommonModule, TranslateModule, TestingCoreModule],
  exports: [BszNotificationsComponent],
})
export class BszNotificationsModule {}
