<button
  mat-button
  color="primary"
  class="bsz-filter-trigger"
  aria-haspopup="dialog"
  [disabled]="_disabled"
  [attr.aria-label]="getTriggerAriaLabel()"
  (click)="openFilter(filterPanel)">
  <span [ngClass]="{'bsz-filter-criteria-applied': isActive}" class="material-icons">
    filter_list
  </span>
  <span *ngIf="!isMobile">{{ 'ui-elements.bsz-filter.toggle-panel-button' | translate }}</span>
</button>

<ng-template #filterPanel>
  <mat-dialog-content>
  <div class="bsz-filter-content" [ngClass]="{'bsz-filter-mobile': isMobile}">
    <div class="bsz-filter-container" [attr.id]="id + '-panel'">
      <div class="mat-headline avg-filter-title">
        {{ 'ui-elements.bsz-filter.toggle-panel-button' | translate }}
        <button *ngIf="isMobile" mat-flat-button type="button" (click)="onReset()">
          {{ 'ui-elements.bsz-filter.reset-all' | translate }}
        </button>
      </div>

      <form [attr.id]="formId" [formGroup]="form" (ngSubmit)="onSubmit()" #filterFormDirective="ngForm"
      class="bsz-filter-form">
        <!-- Filter custom template - This content is the form provided by devs -->
        <ng-content></ng-content>
        <!-- Filter custom template -->
      </form>
      <div class="bsz-filter-action-buttons">
        <div *ngIf="!isMobile">
          <button
            class="bsz-filter-action bsz-filter-action-reset"
            mat-flat-button
            (click)="onReset()"
          >
            {{ 'ui-elements.bsz-filter.reset-all' | translate }}
          </button>
        </div>
        <div class="bsz-filter-primary-actions">
          <button
            (click)="onCancel()"
            class="bsz-filter-action bsz-filter-action-cancel"
            color="accent"
            mat-stroked-button
          >
            {{ 'ui-elements.bsz-filter.cancel' | translate }}
          </button>
          <button
            [attr.form]="formId"
            class="bsz-filter-action bsz-filter-action-submit"
            color="accent"
            type="submit"
            mat-flat-button
          >
            {{ 'ui-elements.bsz-filter.apply' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  </mat-dialog-content>
</ng-template>
