import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateFakeLoader,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';

export const TRANSLATED_INDICATOR = 'translated$';

class MissingTranslationTestHandler extends MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): any {
    let translatedKey = TRANSLATED_INDICATOR + params.key;

    if (params.interpolateParams) {
      translatedKey += Object.entries(params.interpolateParams).reduce((reducedParams, entry) => {
        return `${reducedParams}$${entry[0]}=${entry[1]}`;
      }, '');
    }

    return translatedKey;
  }
}

/** @deprecated use BszI18nTestingModule from '@basuiz/i18n/testing' */
export function translationTestConfigurationModule() {
  return TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useClass: TranslateFakeLoader,
    },
    missingTranslationHandler: {provide: MissingTranslationHandler, useClass: MissingTranslationTestHandler},
  });
}
