import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatDialogModule} from '@angular/material/dialog';
import {AuthService} from './services/auth.service';
import {AuthDialogComponent} from './components/auth-dialog/auth-dialog.component';
import {BszI18nModule} from '@basuiz/i18n';
import {MatButtonModule} from '@angular/material/button';
import {AuthRepresentationComponent} from './components/auth-representation/auth-representation.component';
import {AuthVerificationComponent} from './components/auth-verification/auth-verification.component';
import {MtanVerificationComponent} from './components/auth-verification/mtan-verification/mtan-verification.component';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ReactiveFormsModule} from '@angular/forms';
import {MatDividerModule} from '@angular/material/divider';
import {AuthVerificationService} from './data-access/auth-verification.service';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {AuthRepresentationService} from './services/auth-representation.service';
import {FormattingModule} from '../formatting/index';
import {BszProgressBarModule} from '../progress-indicator/bsz-progress-bar.module';
import {CrontoVerificationComponent} from './components/auth-verification/cronto-verification/cronto-verification.component';
import {
  BszDataListModule,
  BszMicroCardModule,
  BszScreenSizeContentSwitcherModule,
  BszTableModule,
} from '@basuiz/ui-elements';
import {AuthResultService} from './services/auth-result.service';
import {PushVerificationComponent} from './components/auth-verification/push-verification/push-verification.component';
import {FieldsRepresentationComponent} from './components/auth-representation/fields-representation/fields-representation.component';
import {FuturaeVerificationComponent} from './components/auth-verification/futurae-verification/futurae-verification.component';
import {MatrixVerificationComponent} from './components/auth-verification/matrix-verification/matrix-verification.component';
import {TokenVerificationComponent} from './components/auth-verification/token-verification/token-verification.component';
import {CollectiveRepresentationComponent} from './components/auth-representation/collective-representation/collective-representation.component';
import {MatSortModule} from '@angular/material/sort';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatPaginatorModule} from '@angular/material/paginator';
import {CollectiveRepresentationPanelDesktopComponent} from './components/auth-representation/collective-representation/collective-representation-panel-desktop/collective-representation-panel-desktop.component';
import {CollectiveRepresentationPanelMobileComponent} from './components/auth-representation/collective-representation/collective-representation-panel-mobile/collective-representation-panel-mobile.component';
import {AuthDialogService} from './services/auth-dialog.service';
import {AuthNativeDialogService} from './services/auth-native-dialog.service';
import {AuthGuardService} from './services/auth-guard.service';
import {AuthErrorService} from './services/auth-error.service';
import {UnsupportedVerificationComponent} from './components/auth-verification/unsupported-verification/unsupported-verification.component';
import {WebAppBannerModule} from '../ui-sdk-proxy/banner/index';

@NgModule({
  imports: [
    CommonModule,
    BszI18nModule,
    FormattingModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatProgressBarModule,
    BszProgressBarModule,
    BszScreenSizeContentSwitcherModule,
    BszTableModule,
    MatSortModule,
    MatExpansionModule,
    MatPaginatorModule,
    BszDataListModule,
    BszMicroCardModule,
    WebAppBannerModule,
  ],
  declarations: [
    AuthDialogComponent,
    AuthRepresentationComponent,
    FieldsRepresentationComponent,
    AuthVerificationComponent,
    MtanVerificationComponent,
    CrontoVerificationComponent,
    PushVerificationComponent,
    FuturaeVerificationComponent,
    MatrixVerificationComponent,
    TokenVerificationComponent,
    CollectiveRepresentationComponent,
    CollectiveRepresentationPanelDesktopComponent,
    CollectiveRepresentationPanelMobileComponent,
    UnsupportedVerificationComponent,
  ],
  providers: [
    AuthService,
    AuthDialogService,
    AuthNativeDialogService,
    AuthErrorService,
    AuthGuardService,
    AuthRepresentationService,
    AuthVerificationService,
    AuthResultService,
  ],
})
export class WebAppCommonAuthModule {}
