import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {
  ENVIRONMENT_INITIALIZER,
  FactoryProvider,
  InjectionToken,
  PLATFORM_ID,
  ValueProvider,
  // makeEnvironmentProviders, // TODO p4: enable when upgrade to angular >=v15
} from '@angular/core';

function extAppScript(projectId: string): string {
  return `(function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "${projectId}");`;
}

const BSZ_MS_CLARITY_CONFIG_TOKEN = new InjectionToken<BszMsClarityConfiguration>('bsz-ms-clarity.config');

export type BszMsClarityConfiguration = Readonly<{
  /**
   * Whether or not to inject this script on application initialization.
   */
  enabled: boolean;
  /**
   * The Clarity Project ID.
   */
  projectId: string;
}>;

/**
 * Builds configured providers for injecting External Application scripts during
 * application initialization.
 * @param config The runtime configuration for the External Application providers.
 * @returns the External Application providers.
 */
export function msClarityProvider(config: BszMsClarityConfiguration): (ValueProvider | FactoryProvider)[] {
  // return makeEnvironmentProviders([
  return [
    {provide: BSZ_MS_CLARITY_CONFIG_TOKEN, useValue: config},
    {
      provide: ENVIRONMENT_INITIALIZER,
      useFactory: (platformId: Object, d: Document, {enabled, projectId}: BszMsClarityConfiguration) => {
        return () => {
          if (isPlatformBrowser(platformId) && enabled) {
            // script
            const s = d.createElement('script');
            s.type = 'text/javascript';
            s.innerHTML = extAppScript(projectId);
            d.head.appendChild(s);
          }
        };
      },
      deps: [PLATFORM_ID, DOCUMENT, BSZ_MS_CLARITY_CONFIG_TOKEN],
      multi: true,
    },
  ];
}
