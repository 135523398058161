// IMPORTANT: do not modify this file

import {FactoryProvider, inject, InjectionToken} from '@angular/core';
import {BszConfigFactory, BszConfigFactoryWithDeps} from '@basuiz/web-app-applet-api';
import {PortalHybridConfig} from './portal-hybrid.config.definition';
import {getDefaultConfig} from './portal-hybrid.config.default';
import {validatePortalHybridConfig} from './portal-hybrid.config.validation';

// IMPORTANT: do not modify this file

export const ɵPORTAL_HYBRID_CONFIG = new InjectionToken<PortalHybridConfig>('bsz.web-app.portal-hybrid-config', {
  factory: () => inject(PORTAL_HYBRID_CONFIG_DEFAULT),
});

// IMPORTANT: do not modify this file

export const PORTAL_HYBRID_CONFIG_DEFAULT = new InjectionToken<PortalHybridConfig>(
  'bsz.web-app.portal-hybrid-config.default',
  {
    factory: () => {
      const config = getDefaultConfig();
      const error = validatePortalHybridConfig(config);
      if (error) {
        console.error('Default PortalHybridConfig validation failed:', error);
      }
      return config;
    },
  }
);

// IMPORTANT: do not modify this file

export function portalHybridConfigProvider({
  useFactory,
  deps,
}: BszConfigFactoryWithDeps<PortalHybridConfig>): FactoryProvider {
  return {
    provide: ɵPORTAL_HYBRID_CONFIG,
    useFactory: factoryWithValidation(useFactory),
    deps: [PORTAL_HYBRID_CONFIG_DEFAULT, ...(deps || [])],
  };
}

// IMPORTANT: do not modify this file

function factoryWithValidation(factory: BszConfigFactory<PortalHybridConfig>): BszConfigFactory<PortalHybridConfig> {
  return (defaultConfig: PortalHybridConfig, ...deps: unknown[]) => {
    const config = factory(defaultConfig, ...deps);
    const error = validatePortalHybridConfig(config);
    if (error) {
      console.error('PortalHybridConfig validation failed:', error);
    }
    return config;
  };
}

// IMPORTANT: do not modify this file
