import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {BszScreenSize} from '@basuiz/ui-elements';
import {Observable} from 'rxjs';
import {BreadcrumbStyle, BreadcrumbStyleId, PortalConfig} from '../../../config/portal.config.definition';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {ɵPORTAL_CONFIG} from '../../../config/portal.config.provider';
import {BreadcrumbsService} from './breadcrumbs.service';

@Component({
  selector: 'bsz-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BreadcrumbsService],
})
export class BreadcrumbsComponent {
  readonly bszTestIdPrefix = 'web-app-portal.bsz-breadcrumbs';

  private readonly breadcrumbsStyle$: Observable<BreadcrumbStyle> = this.bszScreenSize
    .getScreenSize()
    .pipe(map((screenSize) => this.portalConfig.pageLayout.breadcrumbs[screenSize]));

  readonly breadcrumbsVisible$: Observable<boolean> = this.breadcrumbsService.breadcrumbCount$.pipe(
    map((count) => count > 0),
    distinctUntilChanged()
  );

  public readonly breadcrumbsStyleId$: Observable<BreadcrumbStyleId> = this.breadcrumbsStyle$.pipe(
    map((breadcrumbsStyle) => breadcrumbsStyle.style)
  );

  public readonly historyMaxBreadcrumbs$: Observable<number> = this.breadcrumbsStyle$.pipe(
    map((breadcrumbsStyle) => (breadcrumbsStyle.style === 'history' ? breadcrumbsStyle.maxBreadcrumbs : 0))
  );

  public readonly startWithHome$: Observable<boolean> = this.breadcrumbsStyle$.pipe(
    map((breadcrumbsStyle) => (breadcrumbsStyle.style === 'hierarchy' ? breadcrumbsStyle.startWithHome : false))
  );

  constructor(
    private readonly bszScreenSize: BszScreenSize,
    @Inject(ɵPORTAL_CONFIG) private readonly portalConfig: PortalConfig,
    private readonly breadcrumbsService: BreadcrumbsService
  ) {}
}
