import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatPseudoCheckboxModule, MatRippleModule} from '@angular/material/core';

import {BszSelectAll} from './bsz-select-all';

@NgModule({
  declarations: [BszSelectAll],
  imports: [CommonModule, MatRippleModule, MatPseudoCheckboxModule],
  exports: [BszSelectAll],
})
export class BszSelectAllModule {}
