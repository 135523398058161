import {InjectionToken} from '@angular/core';

export interface BszNotificationData {
  message: string;
  action: string;
  config?: BszNotificationOverlayConfig;
}

export type BszNotificationOverlayVerticalPosition = 'top' | 'bottom';
export type BszNotificationOverlayHorizontalPosition = 'center' | 'left' | 'right';
export type AriaLivePoliteness = 'off' | 'polite' | 'assertive';

export interface BszNotificationOverlayPosition {
  verticalPosition?: BszNotificationOverlayVerticalPosition;
  horizontalPosition?: BszNotificationOverlayHorizontalPosition;
  maximized?: boolean;
}

// distance in px, CSS value, element to observe
export type BszNotificationOverlayOffset =
  | number
  | string
  | {
      elementId: string;
      reference: 'top' | 'bottom';
    };

export interface BszNotificationOverlayConfig<D = unknown> {
  duration?: number;
  data?: D;
  privateData?: D;
  verticalPosition?: BszNotificationOverlayVerticalPosition;
  horizontalPosition?: BszNotificationOverlayHorizontalPosition;
  politeness?: AriaLivePoliteness;
  announcementMessage?: string;
  maxStackLength?: number;
  type: string;
  fixed?: boolean;
  pauseOnHover?: boolean;
  maximized?: boolean;
}

export interface BszNotificationOverlayOffsetConfig {
  offsetTop?: BszNotificationOverlayOffset;
  offsetBottom?: BszNotificationOverlayOffset;
}

export const BSZ_NOTIFICATION_DATA = new InjectionToken<unknown>('BszNotificationData');
export const BSZ_NOTIFICATION_OVERLAY_DEFAULT_OPTIONS = new InjectionToken<BszNotificationOverlayOffsetConfig>(
  'BszNotificationOverlayOffsetConfig'
);
