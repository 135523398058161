<mat-checkbox
  [checked]="checked"
  [indeterminate]="indeterminate"
  (change)="onChange($event)"
  (click)="$event.stopPropagation()"
  [ngClass]="cssClass"
  [aria-label]="ariaLabel"
  [aria-labelledby]="ariaLabelledby"
  [disabled]="_disabled">
  <ng-content></ng-content>
</mat-checkbox>
