import {Country, getBszCountry} from '@basuiz/web-app-common';
import {BszUserProfilePostalAddress} from '@basuiz/web-app-applet-api';

export interface UserProfilePostalAddress {
  country?: Country;
  city?: string;
  postalCode?: string;
  streetName?: string;
  streetNumber?: string;
}

export function getBszUserProfilePostalAddress(postalAddress: UserProfilePostalAddress): BszUserProfilePostalAddress {
  const bszUserProfilePostalAddress: BszUserProfilePostalAddress = {
    ...postalAddress,
    ...(postalAddress?.country ? {country: getBszCountry(postalAddress.country)} : undefined),
  };
  return bszUserProfilePostalAddress;
}
