import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BszMatPaginatorIntl} from './bsz-mat-paginator-intl';

const TO_BE_PROVIDED_BY_COMPONENT_INSTANCE: null = null;

@Injectable({
  providedIn: TO_BE_PROVIDED_BY_COMPONENT_INSTANCE,
})
export class BszMatServerPaginatorIntl extends BszMatPaginatorIntl {
  constructor(private translationService: TranslateService) {
    super(translationService);
    super.displayTotalItems = false;
  }
}
