import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {BszI18nModule} from '@basuiz/i18n';

import {
  BszProgressSpinner,
  BszProgressSpinnerAction,
  BszProgressSpinnerInfo,
  BszProgressSpinnerLabel,
} from './bsz-progress-spinner';

const DECLARATIONS_AND_EXPORTS = [
  BszProgressSpinner,
  BszProgressSpinnerLabel,
  BszProgressSpinnerInfo,
  BszProgressSpinnerAction,
];

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule, BszI18nModule, MatButtonModule],
  declarations: DECLARATIONS_AND_EXPORTS,
  exports: DECLARATIONS_AND_EXPORTS,
})
export class BszProgressSpinnerModule {}
