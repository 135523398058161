import {
  HybridActionHandled,
  HybridActionNotHandled,
  HybridCollaborationAttachment,
  HybridDocumentAtUrl,
  HybridScanQrBillResult,
} from './hybrid-action.definitions';

/**
 * HybridActionService defines the actions that may be performed by a native (iOS or Android) app when web app is
 * running in hybrid mode, i.e. embedded inside the native app.
 *
 * Applets (and portal pages) use this service to perform a hybrid action. If the app is not running inside a
 * native app, or the native app reports that a particular action is not supported by the device, then the methods
 * will typically return `HybridActionNotHandled`.
 *
 * Users of the service should handle the `HybridActionNotHandled` case gracefully, performing the action themselves,
 * or informing the user that the action is not possible.
 *
 * The default implementation provided by the WebAppHybridModule returns `HybridActionNotHandled` for most actions
 * because it is not hybrid aware. Notable exceptions are methods that open URLs, which use `window.open` (securely).
 * This allows applets to rely on the service in apps that don't need hybrid capabilities without including the
 * dependencies needed to communicate with a native app.
 *
 * The WebAppHybridNativeModule, imported from @basuiz/web-app-hybrid/native, provides an implementation of the
 * HybridActionService that uses the @basuiz/mobile-bridge to communicate with a native app and perform hybrid actions
 * in the native app where the native app supports them. This implementation is used by @basuiz/web-app-portal-hybrid
 * to facilitate communication. You may also use it in your own portal/app to have the applets that use the service
 * communicate with the native app.
 *
 * You may also provide your own implementation of the service so that applets triggering hybrid actions can communicate
 * with your own mobile app. E.g.
 *
 * `{provide: HybridActionService, useClass: MyNativeActionService}`
 *
 * Provide your implementation after importing `WebAppModule.forRoot`, or `WebAppPortalModule.forRoot` to ensure
 * it overrides the default.
 */
export abstract class HybridActionService {
  /**
   * isInsideNative
   * Whether the web application is running inside a mobile native/hybrid application. If false then other methods that
   * may trigger actions in the native/hybrid application will return HybridActionNotHandled
   *
   * @return true if the application is running embedded in a mobile native/hybrid application
   */
  abstract isInsideNative(): boolean;

  /**
   * openExternalLink
   *
   * Opens a URL outside the web app app. In the web app this should use `window.open` (securely), in the native app
   * this will use the appropriate viewer to display the link without replacing the hybrid app in the viewer.
   *
   * Applets or applications can use the `ExternalLinkService` for a more natural API than using the HybridActionService
   * directly for this purpose; it is simply a facade for this method.
   *
   * @param url to open outside the app; only HTTPS will be accepted
   * @returns a `Promise` indicating whether or not the action was handled
   */
  abstract openExternalLink(url: string): Promise<HybridActionHandled | HybridActionNotHandled>;

  /**
   * scanQrBill
   *
   * Request the native application to perform a scan for a QR-Bill payment.
   *
   * @returns a `Promise` with the scan result if it was successful, otherwise an indication whether the
   * action was handled by the native app but cancelled by the user or that the action is not handled.
   */
  abstract scanQrBill(): Promise<HybridScanQrBillResult | HybridActionNotHandled>;

  /**
   * viewCollaborationAttachment
   *
   * Where the web application would normally download an attachment on a collaboration issue the native app
   * would show the attachment in a native viewer.
   *
   * @param attachment
   * @returns a `Promise` indicating whether or not the action was handled .
   */
  abstract viewCollaborationAttachment(
    attachment: HybridCollaborationAttachment
  ): Promise<HybridActionHandled | HybridActionNotHandled>;

  /**
   * viewDocumentAtUrl
   *
   * Where the web application would normally download a document that has been fetched from a backend url the
   * native app would show the document in a native viewer.
   *
   * @param documentAtUrl
   * @returns a `Promise` indicating whether or not the action was handled .
   */
  abstract viewDocumentAtUrl(documentAtUrl: HybridDocumentAtUrl): Promise<HybridActionHandled | HybridActionNotHandled>;
}
