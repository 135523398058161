import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class BreadcrumbsService {
  private readonly breadcrumbCountSubject = new BehaviorSubject<number>(0);
  readonly breadcrumbCount$: Observable<number> = this.breadcrumbCountSubject.asObservable();

  public addBreadcrumb() {
    this.breadcrumbCountSubject.next(this.breadcrumbCountSubject.value + 1);
  }

  public removeBreadcrumb() {
    this.breadcrumbCountSubject.next(this.breadcrumbCountSubject.value - 1);
  }
}
