import {UnionTypeFromArray} from '@basuiz/web-app-applet-api';

export const AppShellOutletCategory = ['formActions', 'primaryActions'] as const;

/** Category of the content to be projected through the outlet into the app-shell.
 * Application developers wishing to use app-shell outlets can define where in their app-shell's layout
 * to instantiate a receiver for a specific category of content.
 * For example, for an app-shell outlet projecting form actions, the app-shell might instantiate
 * the receiver in a footer fixed to the bottom of the viewport.
 *
 * - 'formActions': controls that allow to submit / cancel the form and,
 *     for forms consisting of multiple steps, navigate back and forth the steps
 *
 * - 'primaryActions': primary actions of a applet. What actions are primary depends on the type of applet.
 *     - overview applets: actions that do not relate to a specific set of entities  (e.g. buy / sell)
 *     - details applets: actions in the context of the entity the applet is displaying
 * */
export type AppShellOutletCategory = UnionTypeFromArray<typeof AppShellOutletCategory>;
