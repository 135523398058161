import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BszCountryComponent} from './bsz-country.component';
import {BszSelectSearchModule} from '@basuiz/ui-elements';
import {MatDividerModule} from '@angular/material/divider';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';

@NgModule({
  declarations: [BszCountryComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatDividerModule,
    MatFormFieldModule,
    MatSelectModule,
    BszSelectSearchModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [BszCountryComponent],
})
export class BszCountryModule {}
