<div #carouselContainer class="carousel" [ngStyle]="{margin: '0 ' + -slidePreviewSize + 'px'}">
  <div
    #carouselSlidesContainer
    class="carousel-slides-container"
    [ngStyle]="{'padding-right': slidePreviewSize + 'px', 'padding-left': slidePreviewSize + 'px'}"
  >
    <div
      *ngFor="let slide of slides"
      class="carousel-slide"
      [ngStyle]="{'margin-right': slideSpacing + 'px'}"
      (swipeleft)="onSwipeLeft()"
      (swiperight)="onSwipeRight()"
    >
      <ng-container [ngTemplateOutlet]="slide.templateRef"></ng-container>
    </div>
  </div>
</div>

<div *ngIf="slides.length > 1" class="bsz-display-flex bsz-flex-row bsz-justify-center bsz-margin-t2">
  <a
    *ngFor="let slide of slides; let index = index"
    class="indicator"
    [ngClass]="{active: index === activeItemIndex}"
    [attr.aria-label]="
      'web-app-common.carousel.slide-indicators.aria.slide-number'
        | translate
          : {
              slideNumber: index + 1,
              carouselSlideCount: slides.length
            }
    "
    (click)="onSlideIndicatorClick(index)"
  >
    <ng-container *ngTemplateOutlet="indicatorCircle"></ng-container>
  </a>
</div>

<ng-template #indicatorCircle>
  <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="50" />
  </svg>
</ng-template>
