<div class="dev-tools-drag-boundary" *ngIf="initializationResult$ | async">
  <div class="positioner">
    <div class="dev-tools" cdkDragBoundary=".dev-tools-drag-boundary" cdkDrag>
      <div class="controls-container">
        <button mat-icon-button color="accent" (click)="maximized = !maximized">
          <mat-icon class="rotate">{{ maximized ? 'unfold_less' : 'unfold_more' }}</mat-icon>
        </button>
        <ng-container *ngIf="maximized">
          <button
            mat-icon-button
            [color]="userRolesToolActive ? 'warn' : 'accent'"
            (click)="openUserRoles()"
            matTooltip="User roles"
          >
            <mat-icon>groups</mat-icon>
          </button>
          <button
            mat-icon-button
            [color]="featureToggleToolActive ? 'warn' : 'accent'"
            (click)="openFeatureToggle()"
            matTooltip="Feature toggle"
          >
            <mat-icon>extension</mat-icon>
          </button>
          <button *ngIf="version" mat-icon-button color="accent" (click)="openAboutInfo()" matTooltip="About">
            <mat-icon>info</mat-icon>
          </button>
          <button
            mat-icon-button
            [color]="(getRevealBoundaryAndName$ | async) ? 'warn' : 'accent'"
            (click)="onToggleRevealApplets()"
            matTooltip="Reveal applets"
          >
            <mat-icon>visibility</mat-icon>
          </button>
          <ng-content></ng-content>
        </ng-container>
        <button mat-icon-button class="handle" cdkDragHandle>
          <mat-icon>open_with</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
