import {DefaultNavigationIntentHandlerMap} from '../../navigation/default-intent-handlers.config';
import {DefaultIntentHandlerMapBuilder} from '../default-intent-handler-map.builder';
import * as intents from '../../intents/intents.index';
import * as pages from '../../pages/pages.index';
import {assertNever} from '@basuiz/web-app-applet-sdk';

export function paymentCardsDefaultIntentHandlersFactory(): DefaultNavigationIntentHandlerMap {
  const builder = new DefaultIntentHandlerMapBuilder();
  defineHandlers(builder.addHandler);
  return builder.build();
}

function defineHandlers(addHandler: DefaultIntentHandlerMapBuilder['addHandler']): void {
  addHandler(intents.ShowPaymentCardsIntent, (intent) => new pages.PaymentCardsPage({}));
  addHandler(intents.ShowPaymentCardsDetailsIntent, (intent) => {
    const pagePayload: pages.PaymentCardsPagePayload = {
      tab: intent.payload.view,
      paymentCardId: intent.payload.paymentCardId,
    };
    if (intent.payload.view) {
      switch (intent.payload.view) {
        case 'DEBIT_CARD':
          pagePayload.tab = 'DEBIT_CARD';
          break;
        default:
          assertNever(intent.payload.view);
      }
    }
    return new pages.PaymentCardsPage(pagePayload);
  });
}
