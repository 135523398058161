import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TranslatedText} from '@basuiz/web-app-applet-api';

/** @deprecated use bszProgressIndicator directive instead */
@Component({
  selector: 'bsz-progress-bar',
  templateUrl: './bsz-progress-bar.component.html',
  styleUrls: ['./bsz-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BszProgressBarComponent {
  /** Optional parameter to add custom message. If not provided component is showing default message */
  @Input() message?: TranslatedText | undefined;
  /** Optional parameter to add backdrop that swallows all click events.
   * Backdrop will be extended to the closest parent that has css position relative attribute
   * If not provided component is using backdrop by default */
  @Input() hasBackdrop? = true;

  readonly defaultMessage = this.translateService.instant('web-app-common.progress-bar.data-is-fetching');

  constructor(private translateService: TranslateService) {}
}
