import {Relation} from 'typeorm';
import {IBaseCrudEntity, ICollectiveCarrier, ICollectiveTariff, IIndividualServiceLevel} from '../..';
import {Country} from '../../../utils/models';

/**
 * `CollectiveServiceLevelToken` must be unique.
 *
 * Format of the CollectiveServiceLevelToken:
 *
 * `countryShortCode_carrierName_carrierShippingServiceLevelName`
 *
 * ---
 * Example 1: For the carrier "Fedex", located in the United States, for its shipping service called "Express", token would be:
 *
 * `us_fedex_express`
 *
 * Example 2: For the carrier "Shipper Turtle", located in the Ecuador, for its shipping service called "Mercancia Premier Express", token would be:
 *
 * `ec_shipperTurtle_mercanciaPremierExpress`
 */
export enum CollectiveServiceLevelToken {
  carrier123Ground = 'carrier123Ground', // NOTE: This is a TEST carrier. Don't delete it. Don't use it in production (see env variable 'INCLUDE_TEST_CARRIER_QUOTES').
  carrier123Express = 'carrier123Express', // NOTE: This is a TEST carrier. Don't delete it. Don't use it in production (see env variable 'INCLUDE_TEST_CARRIER_QUOTES').
  carrier456Express = 'carrier456Express', // NOTE: This is a TEST carrier. Don't delete it. Don't use it in production (see env variable 'INCLUDE_TEST_CARRIER_QUOTES').
  carrier456International = 'carrier456International', // NOTE: This is a TEST carrier. Don't delete it. Don't use it in production (see env variable 'INCLUDE_TEST_CARRIER_QUOTES').
  carrier789Local = 'carrier789Local', // NOTE: This is a TEST carrier. Don't delete it. Don't use it in production (see env variable 'INCLUDE_TEST_CARRIER_QUOTES').
  ec_servientrega_local = 'ec_servientrega_local',
  ec_servientrega_cantonal = 'ec_servientrega_cantonal',
  ec_servientrega_provincial = 'ec_servientrega_provincial',
  ec_servientrega_regional = 'ec_servientrega_regional',
  ec_servientrega_especial = 'ec_servientrega_especial',
  ec_servientrega_galapagos = 'ec_servientrega_galapagos',
  /**
   * @member ec_tramaco_all — service level for unknown routes in ec_tramaco. This happens when a route could not be identified based on its label price (queried to its API endpoint 'quote')
   */
  ec_tramaco_all = 'ec_tramaco_all',
  ec_tramaco_local = 'ec_tramaco_local',
  ec_tramaco_nacional_principal = 'ec_tramaco_nacional_principal',
  ec_tramaco_nacional_secundaria = 'ec_tramaco_nacional_secundaria',
  ec_tramaco_nacional_especial = 'ec_tramaco_nacional_especial',
  ec_tramaco_canton_diferenciado = 'ec_tramaco_canton_diferenciado',
  ec_tramaco_galapagos = 'ec_tramaco_galapagos',
}

export interface ICollectiveServiceLevel extends IBaseCrudEntity {
  token: CollectiveServiceLevelToken;
  country: Country;
  name: string;

  /**
   * When `isActive` is false, it should be EXCLUDED in the app/api
   */
  isActive: boolean;

  /**
   * Estimated transit time (duration) in days of the Parcel at the given servicelevel.
   * Please note that this is not binding, but only an average value as given by the provider.
   * The App is not able to guarantee any transit times.
   */
  estimateDays: number;

  /**
   * Further clarification of the transit times. Often, this includes notes that the transit time
   * as given in "days" is only an average, not a guaranteed time.
   */
  durationTerms?: string;

  // Relations

  collectiveCarrierObjectId: number;
  collectiveCarrier?: Relation<ICollectiveCarrier>;

  individualServiceLevels?: Relation<IIndividualServiceLevel[]>;

  /** OneToOne relation of an `CollectiveServiceLevel` to most current version of tariffs in `currentCollectiveTariffObjectId` for this specific `CollectiveServiceLevel.token`. */
  currentCollectiveTariffObjectId?: number;
  currentCollectiveTariff?: Relation<ICollectiveTariff>;

  collectiveTariffs?: Relation<ICollectiveTariff[]>;
}
