// IMPORTANT: do not modify this file

import {FactoryProvider, inject, InjectionToken} from '@angular/core';
import {BszConfigFactory, BszConfigFactoryWithDeps} from '@basuiz/web-app-applet-api';
import {UserSettingsPageConfig} from './user-settings-page.config.definition';
import {getDefaultConfig} from './user-settings-page.config.default';
import {validateUserSettingsPageConfig} from './user-settings-page.config.validation';
import {ExternalLinkService, UserRoleService} from '@basuiz/web-app-applet-sdk';
import {WEB_APP_EXPANDED_FEATURE_TOGGLE} from '@basuiz/web-app-feature-toggle';

// IMPORTANT: do not modify this file

export const ɵUSER_SETTINGS_PAGE_CONFIG = new InjectionToken<UserSettingsPageConfig>(
  'bsz.web-app.portal.user-settings-page-config',
  {
    factory: () => inject(USER_SETTINGS_PAGE_CONFIG_DEFAULT),
  }
);

// IMPORTANT: do not modify this file

export const USER_SETTINGS_PAGE_CONFIG_DEFAULT = new InjectionToken<UserSettingsPageConfig>(
  'bsz.web-app.portal.user-settings-page-config.default',
  {
    factory: () => {
      const config = getDefaultConfig(
        inject(ExternalLinkService),
        inject(UserRoleService),
        inject(WEB_APP_EXPANDED_FEATURE_TOGGLE)
      );
      const error = validateUserSettingsPageConfig(config);
      if (error) {
        console.error('Default UserSettingsPageConfig validation failed:', error);
      }
      return config;
    },
  }
);

// IMPORTANT: do not modify this file

export function userSettingsPageConfigProvider({
  useFactory,
  deps,
}: BszConfigFactoryWithDeps<UserSettingsPageConfig>): FactoryProvider {
  return {
    provide: ɵUSER_SETTINGS_PAGE_CONFIG,
    useFactory: factoryWithValidation(useFactory),
    deps: [USER_SETTINGS_PAGE_CONFIG_DEFAULT, ...(deps || [])],
  };
}

// IMPORTANT: do not modify this file

function factoryWithValidation(
  factory: BszConfigFactory<UserSettingsPageConfig>
): BszConfigFactory<UserSettingsPageConfig> {
  return (defaultConfig: UserSettingsPageConfig, ...deps: unknown[]) => {
    const config = factory(defaultConfig, ...deps);
    const error = validateUserSettingsPageConfig(config);
    if (error) {
      console.error('UserSettingsPageConfig validation failed:', error);
    }
    return config;
  };
}

// IMPORTANT: do not modify this file
