import {Inject, Optional, Pipe, PipeTransform} from '@angular/core';

import {InvalidPipeArgumentError} from '../common/invalid-pipe-argument-error';
import {isValue} from '../common/number-utils';
import {NO_BREAK_SPACE} from '../common/pipes.constants';
import {BSZ_IBAN_PIPE_DEFAULT_OPTIONS, BszIbanPipeConfig} from './iban.pipe.config';

// It is an IBAN if it starts with two capital letters [A-Z] and then two numbers [0-9].
// The fifth character in the string can be a number or an uppercase letter.
// IBANs are between 15 and 34 characters in length.
export const IBAN_REGEX = '^[A-Z]{2}[0-9]{2}[A-Z0-9]{11,30}$';

@Pipe({name: 'bszIban'})
export class BszIbanPipe implements PipeTransform {
  private readonly config: BszIbanPipeConfig;
  private ibanRegExp = new RegExp(IBAN_REGEX);

  constructor(@Optional() @Inject(BSZ_IBAN_PIPE_DEFAULT_OPTIONS) defaultConfig?: BszIbanPipeConfig) {
    this.config = defaultConfig || new BszIbanPipeConfig();
  }

  transform(value: string | null | undefined): string {
    if (!isValue(value)) {
      return this.config.emptyValue;
    }

    try {
      const condensedValue = this.removeSpaces(value);
      const isIban = this.ibanRegExp.test(condensedValue);
      if (isIban) {
        return this.insertSpaces(condensedValue).trim();
      } else {
        return value;
      }
    } catch (error) {
      throw new InvalidPipeArgumentError(value, BszIbanPipe);
    }
  }

  // for IBAN, insert a space after every 4th character
  private insertSpaces(iban: string): string {
    return iban.replace(/(.{4})/g, '$1' + NO_BREAK_SPACE).trim();
  }

  // remove all existing spaces
  private removeSpaces(input: string): string {
    return input.replace(/\s+/g, '');
  }
}
