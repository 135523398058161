import {animate, AnimationQueryMetadata, group, query, state, style, transition, trigger} from '@angular/animations';

export class AnimationHelper {
  static fadeIn(selector: string, duration: string, delay = '0ms'): AnimationQueryMetadata {
    return query(selector, [
      style({opacity: 0}),
      animate(`${duration} ${delay} cubic-bezier(0.4, 0, 0.2, 1)`, style({opacity: 1})),
    ]);
  }

  static fadeOut(selector: string, duration: string, delay = '0ms'): AnimationQueryMetadata {
    return query(selector, [
      style({opacity: 1}),
      animate(`${duration} ${delay} cubic-bezier(0.4, 0, 0.2, 1)`, style({opacity: 0})),
    ]);
  }
}

export class BszFabAnimations {
  private static readonly containerStyle = {
    open: {
      'width': '280px',
      'height': '{{ containerOpenedHeight }}', // This will be calculated based on how many items are in the menu
      'border-radius': '4px',
    },
    close: {
      'width': '56px',
      'height': '56px',
      'border-radius': '56px',
    },
  };

  static readonly fabContainerTransition = trigger('fabContainerTransition', [
    state('close', style(BszFabAnimations.containerStyle.close)),
    state('open', style(BszFabAnimations.containerStyle.open), {params: {containerOpenedHeight: '0px'}}),
    transition(
      'close => open',
      group([
        // fade out the trigger half way
        AnimationHelper.fadeOut('.bsz-fab-menu-trigger', '150ms'),
        AnimationHelper.fadeIn('.bsz-fab-menu-panel', '300ms'),
        animate('300ms cubic-bezier(0.4, 0, 0.2, 1)'),
      ])
    ),
    transition(
      'open => close',
      group([
        // fade in the trigger half way
        AnimationHelper.fadeIn('.bsz-fab-menu-trigger', '150ms', '150ms'),
        AnimationHelper.fadeOut('.bsz-fab-menu-panel', '300ms'),
        animate('300ms cubic-bezier(0.4, 0, 0.2, 1)'),
      ])
    ),
  ]);
}
