<div *ngIf="steps$ | async as steps" class="stepper-wrapper">
  <div class="mobile-step-indicator bsz-display-none bsz-display-mobile-block">
    <div class="bsz-caption">
      {{
        'web-app-common.web-app-stepper.mobile.step-indicator'
          | translate
            : {
                currentStep: stepper.selectedIndex + 1,
                totalSteps: steps.length
              }
      }}
    </div>
    <div class="bsz-subtitle-1 bsz-margin-desktop-y6 bsz-margin-mobile-y4">
      {{ getLabel(stepper.selectedIndex) | async }}
    </div>
    <div class="bsz-margin-mobile-y4" *ngIf="(hasStepsWithSummary$ | async) || (showStepSummaries$ | async)">
      <div *ngFor="let step of steps; let index = index">
        <ng-container *ngIf="index < stepper.selectedIndex">
          <ng-container *ngIf="step.summary as summaryTemplate">
            <ng-container *ngTemplateOutlet="summaryTemplate"></ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <mat-horizontal-stepper
    [linear]="true"
    [ngClass]="{
      'stepper-with-step-summaries': hasStepsWithSummary$ | async,
      'hide-steps': (bszScreenSize$ | async) === 'mobile'
    }"
    #stepper
    bszTestId="web-app-common.web-app-stepper.horizonal-stepper"
    (selectionChange)="onSelectionChange($event)"
  >
    <ng-template matStepperIcon="edit">
      <mat-icon>done</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="error">
      <mat-icon>error</mat-icon>
    </ng-template>

    <mat-step
      [stepControl]="step.stepControl"
      [bszDisabled]="step.disabled"
      *ngFor="let step of steps; let index = index"
    >
      <ng-template matStepLabel>
        <ng-container
          *ngIf="{
            visible: (showStepSummaries$ | async) && step.summary && index < stepper.selectedIndex
          } as summary"
        >
          <div class="bsz-display-flex">
            <span class="bsz-body-1 step-label">{{ step.label }}</span>
            <span *ngIf="summary.visible" class="stepper-horizontal-line-extension"></span>
          </div>
          <ng-container *ngIf="summary.visible">
            <ng-container *ngTemplateOutlet="step.summary"></ng-container>
          </ng-container>
        </ng-container>
      </ng-template>

      <ng-container
        *ngTemplateOutlet="step.templateRef; context: {$implicit: index === stepper.selectedIndex}"
      ></ng-container>
    </mat-step>
  </mat-horizontal-stepper>

  <bsz-app-shell-outlet-emitter [category]="'formActions'" #emitter>
    <ng-container *ngIf="selectedStep$ | async as step">
      <div
        class="stepper-navigation mdc-layout-grid__inner"
        [ngClass]="{'bsz-padding-y6': emitter.isNotProjecting$ | async}"
      >
        <ng-container *ngIf="cancelClick.observers.length > 0 && stepper.selectedIndex === 0">
          <div *bszMobileContent class="mdc-layout-grid__cell--span-2">
            <button
              type="button"
              class="bsz-width-100pct"
              mat-stroked-button
              mat-button
              color="accent"
              (click)="cancelClick.emit()"
            >
              {{ 'web-app-common.web-app-stepper.cancel-button' | translate }}
            </button>
          </div>
        </ng-container>
        <div *bszDesktopContent class="mdc-layout-grid__cell--span-2 bsz-display-flex bsz-align-items-center">
          <button
            type="button"
            mat-button
            *ngIf="cancelClick.observers.length > 0"
            color="accent"
            (click)="cancelClick.emit()"
          >
            {{ 'web-app-common.web-app-stepper.cancel-button' | translate }}
          </button>
        </div>
        <div
          *bszDesktopContent
          class="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-2-tablet"
        ></div>
        <div *ngIf="showGoToPreviousStepButton$ | async" class="mdc-layout-grid__cell--span-2">
          <button
            type="button"
            class="bsz-width-100pct"
            mat-stroked-button
            color="accent"
            [disabled]="step.isValidatingSubject.value"
            (click)="onBackClick(steps)"
          >
            {{ 'web-app-common.web-app-stepper.previous-button' | translate }}
          </button>
        </div>
        <ng-container *ngIf="(showGoToPreviousStepButton$ | async) === false">
          <div
            *bszDesktopContent
            class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-2-tablet"
          ></div>
        </ng-container>
        <div class="mdc-layout-grid__cell--span-2">
          <button
            class="bsz-width-100pct"
            bszTestId="web-app-common.web-app-stepper.next-button-{{ selectedStepIndex$ | async }}"
            mat-flat-button
            color="accent"
            [disabled]="(step.stepControl && !step.stepControl.valid) || step.isValidatingSubject.value"
            (click)="onNextClick(steps)"
          >
            <ng-container *ngIf="step.isValidatingSubject | async; else nextButtonText">
              <mat-icon>
                <mat-spinner diameter="20"></mat-spinner>
              </mat-icon>
            </ng-container>
            <ng-template #nextButtonText>
              {{ step.nextButtonText }}
            </ng-template>
          </button>
        </div>
      </div>
    </ng-container>
  </bsz-app-shell-outlet-emitter>
</div>
