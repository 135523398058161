import {
  ExportToFileConfig,
  ExportToFileFromClientDataConfig,
  ExportToFileFromServerDataConfig,
} from './export-to-file-config.definition';

export function isExportToFileFromClientDataConfig(c: ExportToFileConfig): c is ExportToFileFromClientDataConfig {
  return (c as ExportToFileFromClientDataConfig).content !== undefined;
}

export function isExportToFileFromServerDataConfig(c: ExportToFileConfig): c is ExportToFileFromServerDataConfig {
  return (c as ExportToFileFromServerDataConfig).endpoint !== undefined;
}
