import {dataIndexAttrName, dataPageIndexAttrName} from './bsz-carousel.definitions';

export class BszCarouselInfiniteLoop {
  private carouselContent: HTMLElement | null = null;
  private currentTranslationX = 0;

  /**
   * Makes a clone of all items and places them before the original ones:
   *     - makes a clone in reverse order to the items because the place in which they will be
   *     - each clone is inserted as first item
   *     - sets the data-origin attribute to "component" so it is possible to differentiate them
   *     from the original ones in the DOM
   *     - once they are added, it moves the carousel to the left the distance required to have
   *     the first original item in the same place in which it was before creating the clones
   */
  setClonedItems(carouselContent: HTMLElement) {
    this.carouselContent = carouselContent;
    const items = this.getOriginalItems();
    let previousItem = items[0];
    Array.from(items)
      .reverse()
      .forEach((item, index) => {
        const clonedItem = item.cloneNode(true) as HTMLElement;
        clonedItem.setAttribute('data-origin', 'component');
        carouselContent.insertBefore(clonedItem, previousItem);
        this.setItemIndexAttr(clonedItem, Math.floor(-1 - index));
        previousItem = clonedItem;
      });
    this.currentTranslationX = -items.length * 100;
    this.setLeftPosition(this.currentTranslationX);
  }

  removeClonedItems() {
    const carouselContent = this.carouselContent;
    if (!carouselContent) {
      return;
    }
    this.getClonedItems().forEach((item) => {
      carouselContent.removeChild(item);
    });

    // the original items could be in wrong order because the carousel was already moved by the user
    Array.from(this.getOriginalItems())
      .sort((a, b) =>
        Number(a.getAttribute(dataPageIndexAttrName)) > Number(b.getAttribute(dataPageIndexAttrName)) ? 1 : -1
      )
      .forEach((item, index) => {
        carouselContent.appendChild(item);
        item.setAttribute(dataIndexAttrName, `${index}`);
      });
  }

  /**
   * When the carousel scrolls, depending on the amount of items scrolled by
   * the user to one direction or another, it changes the position of items
   * from one side or the other
   */
  adjustInfiniteCarouselItems(scrolledItems: number) {
    const totalItems = Math.abs(scrolledItems);

    for (let i = 0; i < totalItems; i++) {
      // if it is positive, it is moving to the left
      if (scrolledItems > 0) {
        this.moveToLastPosition();
      } else {
        this.moveToFirstPosition();
      }
    }
  }

  /**
   * Gets last item and moves it to the first position
   */
  private moveToFirstPosition() {
    const carouselContent = this.carouselContent;
    const items = this.getItems();
    const firstItem = items[0] as HTMLElement;
    const lastItem = items[items.length - 1] as HTMLElement;
    const firstItemIndex = Number(firstItem.getAttribute(dataIndexAttrName));
    carouselContent?.insertBefore(lastItem, firstItem);
    this.setItemIndexAttr(lastItem, firstItemIndex - 1);
    this.currentTranslationX -= 100;
    this.setLeftPosition(this.currentTranslationX);
  }

  /**
   * Gets first item and moves it to the last position
   */
  private moveToLastPosition() {
    const carouselContent = this.carouselContent;
    const items = this.getItems();
    const firstItem = items[0] as HTMLElement;
    const lastItem = items[items.length - 1] as HTMLElement;
    const lastItemIndex = Number(lastItem.getAttribute(dataIndexAttrName));
    carouselContent?.appendChild(firstItem);
    this.setItemIndexAttr(firstItem, lastItemIndex + 1);
    this.currentTranslationX += 100;
    this.setLeftPosition(this.currentTranslationX);
  }

  private setLeftPosition(leftPosition: number) {
    if (this.carouselContent) {
      this.carouselContent.style.left = `${leftPosition}%`;
    }
  }

  private setItemIndexAttr(item: HTMLElement, pageIndex: number) {
    item.setAttribute(dataIndexAttrName, `${pageIndex}`);
  }

  private getItems() {
    return this.carouselContent?.querySelectorAll<HTMLElement>('.bsz-carousel-item') ?? [];
  }

  private getOriginalItems() {
    return this.carouselContent?.querySelectorAll<HTMLElement>('.bsz-carousel-item:not([data-origin=component])') ?? [];
  }

  private getClonedItems() {
    return this.carouselContent?.querySelectorAll<HTMLElement>('.bsz-carousel-item[data-origin=component]') ?? [];
  }
}
