import {Injectable} from '@angular/core';
import {BszMobileBridgeService} from '@basuiz/mobile-bridge';
import {
  OpenExternalLinkAction,
  OpenInternalDocumentAction,
  ScanBillAction,
  ScanBillResponse,
  ViewCollaborationAttachmentWithIDAction,
  ViewDocumentWithIdAction,
} from '@basuiz/mobile-bridge-types';
import {
  HybridActionHandled,
  HybridActionNotHandled,
  HybridCollaborationAttachment,
  HybridDocumentAtUrl,
  HybridScanQrBillResult,
  WebHybridActionService,
} from '@basuiz/web-app-hybrid';

@Injectable()
export class MobileHybridActionService extends WebHybridActionService {
  constructor(private mobileBridgeService: BszMobileBridgeService) {
    super();
  }

  isInsideNative() {
    return this.mobileBridgeService.isInsideNative();
  }

  async openExternalLink(url: string): Promise<HybridActionHandled | HybridActionNotHandled> {
    if (!this.mobileBridgeService.isInsideNative()) {
      return super.openExternalLink(url);
    }

    if (!url.match(/^https:\/\//i)) {
      console.error(`Native app will not open an insecure link: ${url}`);
      return 'unhandled';
    }

    const action = new OpenExternalLinkAction({url: url});

    const isSupported = await this.mobileBridgeService.isActionSupported(action);
    if (!isSupported) {
      console.warn(`Native app does not support opening ${url}`);
      return 'unhandled';
    }

    return this.mobileBridgeService.triggerAction(action).then(() => 'triggered');
  }

  async scanQrBill(): Promise<HybridScanQrBillResult | HybridActionNotHandled> {
    const action = new ScanBillAction({enabledBillType: 'SwissQRBill'});
    const isSupported = await this.mobileBridgeService.isActionSupported(action);
    return isSupported
      ? this.mobileBridgeService
          .triggerAction(action)
          .then(({scannedSwissQRBill}: ScanBillResponse) => scannedSwissQRBill ?? 'cancelled')
      : 'unhandled';
  }

  async viewCollaborationAttachment(
    attachment: HybridCollaborationAttachment
  ): Promise<HybridActionHandled | HybridActionNotHandled> {
    const action = new ViewCollaborationAttachmentWithIDAction({
      attachmentID: attachment.documentKey,
      documentName: attachment.name,
    });
    const isSupported = await this.mobileBridgeService.isActionSupported(action);
    return isSupported ? this.mobileBridgeService.triggerAction(action).then(() => 'triggered') : 'unhandled';
  }

  async viewDocumentAtUrl(documentAtUrl: HybridDocumentAtUrl): Promise<HybridActionHandled | HybridActionNotHandled> {
    const action = new OpenInternalDocumentAction(documentAtUrl);
    const isSupported = await this.mobileBridgeService.isActionSupported(action);
    return isSupported ? this.mobileBridgeService.triggerAction(action).then(() => 'triggered') : 'unhandled';
  }
}
