import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {BszHighlightText} from './bsz-highlight-text';

@NgModule({
  declarations: [BszHighlightText],
  imports: [CommonModule],
  exports: [BszHighlightText],
})
export class BszHighlightTextModule {}
