<ng-container *ngIf="componentState$ | async as cs">
  <mat-form-field class="bsz-width-100pct">
    <mat-label *ngIf="cs.selectorLocaleData && !cs.savingLocale">
      {{ 'web-app-self-service-locale-selector-applet.selector.label' | translate }}
    </mat-label>
    <mat-label *ngIf="!cs.selectorLocaleData" class="loading-message">
      <mat-spinner diameter="20" class="bsz-margin-r2"></mat-spinner>
      <span>{{ 'web-app-self-service-locale-selector-applet.selector.loading-message' | translate }}</span>
    </mat-label>
    <mat-label *ngIf="cs.savingLocale" class="loading-message">
      <mat-spinner diameter="20" class="bsz-margin-r2"></mat-spinner>
      <span>{{ 'web-app-self-service-locale-selector-applet.selector.updating-message' | translate }}</span>
    </mat-label>

    <mat-select
      [value]="cs.selectorLocaleData?.activeOption"
      [disabled]="cs.savingLocale"
      class="bsz-body-1"
      (selectionChange)="onLocaleSelected($event.value)"
      bszTestId="{{ bszTestIdPrefix }}.selected-language"
    >
      <mat-option *ngFor="let locale of cs.selectorLocaleData?.optionList" [value]="locale" [lang]="locale.localeId">
        {{ locale.display.text }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="cs.showError" class="bsz-text-error bsz-body-1 bsz-text-bold">
    {{ 'web-app-self-service-locale-selector-applet.error-while-changing-locale' | translate }}
  </div>
</ng-container>
