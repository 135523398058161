import {Injectable} from '@angular/core';
import {DateAdapter} from '@angular/material/core';
import {DateRange} from '@angular/material/datepicker';

@Injectable()
export class DateRangeSelectionModel<D> {
  private firstSelectedDate: D | null = null;
  private secondSelectedDate: D | null = null;

  selectedRange = new DateRange<D | null>(null, null);

  constructor(private readonly dateAdapter: DateAdapter<D>) {}

  addSelection(date: D): DateRange<D | null> {
    if (!this.firstSelectedDate) {
      this.firstSelectedDate = date;
      return (this.selectedRange = new DateRange(this.firstSelectedDate, null));
    }

    if (this.firstSelectedDate && !this.secondSelectedDate) {
      this.secondSelectedDate = date;
    }

    // When both values are set, update range and reset
    if (this.firstSelectedDate && this.secondSelectedDate) {
      this.selectedRange = this.createRangeSelection(this.firstSelectedDate, this.secondSelectedDate);
      this.reset();
    }

    return this.selectedRange;
  }

  reset(): void {
    this.firstSelectedDate = null;
    this.secondSelectedDate = null;
  }

  hasSelected(): boolean {
    return this.selectedRange.start !== null && this.selectedRange.end !== null;
  }

  isSelecting(): boolean {
    return this.firstSelectedDate !== null && this.secondSelectedDate === null;
  }

  private createRangeSelection(firstDate: D, secondDate: D): DateRange<D> {
    return this.dateAdapter.compareDate(firstDate, secondDate) < 0
      ? new DateRange(firstDate, secondDate)
      : new DateRange(secondDate, firstDate);
  }
}
