import {coerceNumberProperty} from '@angular/cdk/coercion';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import {AsyncSubject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {webcoat_deprecated} from '../../common/webcoat-deprecated';
import {BszTab} from './bsz-tab';
import {CommunicationService, UPDATE_TABS_MESSAGE_EVENT_NAME} from './bsz-tabs-message.service';

/** @deprecated This component is deprecated and will be removed in v5. Use "Angular Material tabs" instead.*/
@Component({
  selector: 'bsz-tabs',
  templateUrl: './bsz-tabs.html',
  styleUrls: ['./bsz-tabs.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BszTabs implements AfterContentInit, OnDestroy {
  @Input() get selectedIndex(): number {
    return this._selectedIndex;
  }

  set selectedIndex(tabIndex: number) {
    this._selectedIndex = coerceNumberProperty(tabIndex);
    this.setActiveTab(this._selectedIndex);
  }

  @Input('aria-label') ariaLabel: string;
  @Input('aria-labelledby') ariaLabelledby: string;

  /** Output to enable support for two-way binding on `[(selectedIndex)]` */
  @Output() readonly selectedIndexChange: EventEmitter<number> = new EventEmitter<number>();

  @ContentChildren(BszTab) bszTabs: QueryList<BszTab>;

  private readonly destroy = new AsyncSubject<void>();
  private indexSelected = 0;
  private disabledTabs: number[] = []; // indexes of the tabs that are disabled
  _selectedIndex = 0;
  private nameChangeEvent: Subscription;

  constructor(
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly cd: ChangeDetectorRef,
    private readonly communicationService: CommunicationService<string>
  ) {
    webcoat_deprecated(
      '[bsz-tabs] The component "bsz-tabs" is DEPRECATED since v3.11.0, use "Angular Material tabs" instead.'
    );
  }

  ngAfterContentInit(): void {
    this.initSubscribers();
    this.updateTabsStatus();
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
    this.nameChangeEvent.unsubscribe();
  }

  private initSubscribers(): void {
    this.bszTabs.changes.pipe(takeUntil(this.destroy)).subscribe((_) => {
      this.updateTabsStatus();
    });
    this.nameChangeEvent = this.communicationService.subscribeEvent(UPDATE_TABS_MESSAGE_EVENT_NAME, () => {
      this.updateDisabledTabs();
      this.cd.detectChanges();
    });
  }

  private updateTabsStatus() {
    this.updateDisabledTabs();
    this.setActiveTab(this._selectedIndex);
  }

  updateDisabledTabs() {
    this.disabledTabs = [];
    if (!this.bszTabs) {
      return;
    }
    this.bszTabs.forEach((tab: BszTab, index: number) => {
      if (tab.disabled) {
        this.disabledTabs.push(index);
      }
    });
  }

  isTabDisabled(tabIndex: number): boolean {
    return this.disabledTabs.indexOf(tabIndex) !== -1;
  }

  setActiveTab(tabIndex: number) {
    if (!this.bszTabs || this.bszTabs.length === 0) {
      return;
    }
    // if the index is higher than the tabs length, the active is the last one
    if (tabIndex >= this.bszTabs.length) {
      tabIndex = this.bszTabs.length - 1;
    }

    // if the index is negative, the active is the first one
    if (tabIndex < 0) {
      tabIndex = 0;
    }

    // if the tab is disabled, it search for the following not disabled tab
    if (this.isTabDisabled(tabIndex)) {
      tabIndex = this.findSiblingActiveTabIndex(tabIndex, 1);
    }

    const activeTabId = this.bszTabs.toArray()[tabIndex].uniqueId;
    this.bszTabs.forEach((tab: BszTab) => {
      tab.isActiveTab(activeTabId);
    });

    this._selectedIndex = tabIndex;
    this.selectedIndexChange.emit(tabIndex);
  }

  updateIndexSelected(index: number) {
    this.indexSelected = index;
  }

  handleKeydown(event: KeyboardEvent) {
    const eventKey = event.key;
    const navigationKeys = ['ArrowLeft', 'ArrowRight'];
    if (navigationKeys.indexOf(eventKey) === -1) {
      return;
    }
    this.setFocusOnSibling(eventKey === 'ArrowLeft' ? -1 : 1);
  }

  private setFocusOnSibling(direction: number) {
    const buttonIndexToFocus = this.findSiblingActiveTabIndex(this.indexSelected, direction);
    const buttonToFocus = this._elementRef.nativeElement.querySelector(
      `.bsz-tabs-tab:nth-child(${buttonIndexToFocus + 1})`
    ) as HTMLButtonElement;
    buttonToFocus.focus();
  }

  private findSiblingActiveTabIndex(currentIndex: number, direction: number): number {
    let index = currentIndex + direction;
    if (this.isTabDisabled(index)) {
      index = this.findSiblingActiveTabIndex(index, direction);
    }
    index = this.adaptIndexToTabsRange(index);
    if (this.isTabDisabled(index)) {
      index = this.findSiblingActiveTabIndex(index, direction);
    }
    return index;
  }

  private adaptIndexToTabsRange(index: number): number {
    if (index < 0) {
      // if the index is negative, returns the index of the last tab
      return this.bszTabs.length - 1;
    } else if (index >= this.bszTabs.length) {
      // if the index is higher than the available, returns 0 (index of the first one)
      return 0;
    } else {
      return index;
    }
  }
}
