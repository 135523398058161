import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
// eslint-disable-next-line no-restricted-syntax
import {BszBannerModule} from '@basuiz/ui-elements';
import {WebAppBannerComponent} from './web-app-banner.component';

@NgModule({
  declarations: [WebAppBannerComponent],
  imports: [CommonModule, BszBannerModule],
  exports: [WebAppBannerComponent],
})
export class WebAppBannerModule {}
