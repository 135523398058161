// eslint-disable-next-line no-restricted-imports
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {WebAppModule} from '@basuiz/web-app';
import {WebAppPortalModule} from '@basuiz/web-app-portal';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';

import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {userRoleMappingFactory} from './configurations/user-role-mapping';
import {portalConfigProvider} from './configurations/portal/web-app-portal-config';
import {ExtraDevToolsModule} from './dev-tools/extra-dev-tools.module';
import {localeConfig} from './configurations/locales';
import {AppAppletConfigModule} from './configurations/applet/app-applet-config.module';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {getFeatureToggleFactoryWithDeps} from './configurations/feature-toggle';
import {pageConfigs} from './configurations/portal/page-config/page-configs';
import {getPortalConfigForHybrid, WebAppPortalHybridModule} from '@basuiz/web-app-portal-hybrid';
import {WebAppDevToolsModule} from '@basuiz/web-app-dev-tools';
import {CustomInitializationFailureModule} from './custom-inititalization-failure/custom-initialization-failure.module';
import {bszOverlayContainerProvider, WEB_APP_INITIALIZATION_STRATEGY} from '@basuiz/web-app-applet-sdk';
import {portalInitializationFailureConfig} from './configurations/portal/initialization-failure-config';
import {UserProfileMocksInterceptor} from './user-profile-mocks.interceptor';

import {facebookPixelProvider, msClarityProvider} from '@basuiz/web-app-common';

export const ANGULAR_MODULES = [BrowserModule, BrowserAnimationsModule, HttpClientModule, HammerModule];

export const NGRX_MODULES = [
  EffectsModule.forRoot([]),
  StoreModule.forRoot(
    {},
    {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
      },
    }
  ),
  !environment.production ? [StoreDevtoolsModule.instrument({name: 'Web app jungfrau'})] : [],
];

@NgModule({
  imports: [
    ANGULAR_MODULES,
    NGRX_MODULES,
    ...environment.i18nModules,
    WebAppModule.forRoot({
      localeConfig,
      userRolesMapping: userRoleMappingFactory,
      featureToggle: getFeatureToggleFactoryWithDeps(),
    }),
    WebAppPortalModule.forRoot(
      getPortalConfigForHybrid({
        portal: {
          config: portalConfigProvider,
          pageConfig: pageConfigs,
          initializationFailure: portalInitializationFailureConfig,
        },
      })
    ),
    WebAppPortalHybridModule.forRoot(),
    AppRoutingModule,
    AppAppletConfigModule,
    ExtraDevToolsModule,
    WebAppDevToolsModule,
    CustomInitializationFailureModule,
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    {provide: WEB_APP_INITIALIZATION_STRATEGY, useValue: 'non-blocking'},
    bszOverlayContainerProvider(),
    {provide: HTTP_INTERCEPTORS, useClass: UserProfileMocksInterceptor, multi: true},
    msClarityProvider({
      enabled: process.env['MS_CLARITY_TRACKING_ENABLED'] === 'true',
      projectId: process.env['MS_CLARITY_PROJECT_ID'] ?? '',
    }),
    facebookPixelProvider({
      enabled: process.env['FB_CONVERSIONS_API_ENABLED'] === 'true',
      pixelId: process.env['FB_CONVERSIONS_API_DATA_SOURCE_ID'] ?? '',
    }),
  ],
})
export class AppModule {}
