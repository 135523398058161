import {Component, Inject} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {
  MainNavElement,
  MainNavElements,
  MainNavGroup,
  MainNavItem,
  PortalConfig,
} from '../../config/portal.config.definition';
import {ɵPORTAL_CONFIG} from '../../config/portal.config.provider';
import {assertNever} from '@basuiz/web-app-applet-sdk';
import {MainNavElementsInternal, MainNavItemInternal} from './model/main-nav.internal-model';

@Component({
  selector: 'bsz-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
})
export class MainNavComponent {
  public copyright = this.portalConfig.navigation.mainNav.copyright;

  private readonly mainNavElements: MainNavElementsInternal = this.convertToInternal(
    this.portalConfig.navigation.mainNav.mainNavElements
  );

  private readonly currentUrl$: Observable<string> = this.router.events.pipe(
    filter((event: any) => event instanceof NavigationEnd),
    map((event) => event.url),
    distinctUntilChanged()
  );

  public readonly mainNavElements$: Observable<MainNavElementsInternal> = this.currentUrl$.pipe(
    map((currentUrl) => {
      return this.calculateActiveElements(this.mainNavElements, currentUrl);
    })
  );

  public readonly defaultIcon = this.portalConfig.navigation.mainNav.defaultIcon;

  private readonly rootPathRedirectTo: string | undefined = this.getRootPathRedirectTo();

  constructor(@Inject(ɵPORTAL_CONFIG) private portalConfig: PortalConfig, private router: Router) {}

  private calculateActiveElements(
    mainNavElements: MainNavElementsInternal,
    currentUrl: string
  ): MainNavElementsInternal {
    return mainNavElements.map((element) => {
      switch (element.type) {
        case 'navItem':
          return {
            ...element,
            active: this.isNavItemActive(element, currentUrl),
          };
        case 'divider':
          return {
            ...element,
          };
        case 'navGroup':
          const children = element.children.map((child) => ({
            ...child,
            active: currentUrl.startsWith(child.baseRoute),
          }));
          return {
            ...element,
            active: children.some((child) => child.active),
            children,
          };
        default:
          assertNever(element);
      }
    });
  }

  private convertToInternal(mainNavElements: MainNavElements): MainNavElementsInternal {
    const mainNavElementsInternal: MainNavElementsInternal = [];
    mainNavElements.forEach((element) => {
      if (this.isMainNavItem(element)) {
        mainNavElementsInternal.push({
          ...element,
          type: 'navItem',
          active: false,
        });
      } else if (this.isDivider(element)) {
        mainNavElementsInternal.push({
          type: 'divider',
        });
      } else if (this.isMainNavGroup(element)) {
        if (element.children.length > 0) {
          const mainNavChildrenInternal: MainNavItemInternal[] = [];
          element.children.forEach((childElement) =>
            mainNavChildrenInternal.push({
              ...childElement,
              type: 'navItem',
              active: false,
            })
          );
          mainNavElementsInternal.push({
            ...element,
            type: 'navGroup',
            active: false,
            children: mainNavChildrenInternal,
          });
        }
      } else {
        assertNever(element);
      }
    });
    return mainNavElementsInternal;
  }

  isDivider(navItem: MainNavElement): navItem is 'divider' {
    return navItem === 'divider';
  }

  isMainNavItem(navItem: MainNavElement): navItem is MainNavItem {
    return !!(navItem as MainNavItem)?.baseRoute;
  }

  isMainNavGroup(navItem: MainNavElement): navItem is MainNavGroup {
    return !!(navItem as MainNavGroup)?.children;
  }

  private isNavItemActive(navItem: MainNavItemInternal, currentUrl: string): boolean {
    return currentUrl.startsWith(navItem.baseRoute) || !!this.rootPathRedirectTo?.startsWith(navItem.baseRoute);
  }

  private getRootPathRedirectTo(): string | undefined {
    const redirectTo = this.router.config.find((route) => route.path === '')?.redirectTo;
    return redirectTo ? `/${redirectTo}` : undefined;
  }
}
