import {NgModule} from '@angular/core';
import {BszPaginatorModule} from '../paginator/bsz-paginator.module';
import {WebAppBasicModule} from './web-app-basic.module';
import {
  BszButtonToggleStyleModule,
  BszDataListModule,
  BszDatepickerRangeModule,
  BszFilterModule,
  BszTableModule,
} from '@basuiz/ui-elements';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ReactiveFormsModule} from '@angular/forms';

const ANGULAR_MODULES = [ReactiveFormsModule];
const ANGULAR_MATERIAL_MODULES = [MatPaginatorModule, MatSortModule];
const UI_SDK_MODULES = [
  BszButtonToggleStyleModule,
  BszDataListModule,
  BszDatepickerRangeModule,
  BszFilterModule,
  BszTableModule,
];
const WEB_APP_COMMON_MODULES = [BszPaginatorModule];

@NgModule({
  exports: [WebAppBasicModule, ANGULAR_MODULES, ANGULAR_MATERIAL_MODULES, UI_SDK_MODULES, WEB_APP_COMMON_MODULES],
})
export class OverviewAppletBasicModule {}
