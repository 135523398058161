import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AfpAuthDialogResult,
  AuthDialogVerificationRequest,
  MatrixOperation,
} from '../../../models/auth-dialog.definitions';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {CommonConfig, ɵCOMMON_CONFIG} from '@basuiz/web-app-common/config';
import {NotificationService} from '../../../../ui-notification/index';
import {AuthVerificationService} from '../../../data-access/auth-verification.service';
import {take, takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {isAfpRestResponse} from '../../../../afp-rest/index';
import {TranslationKey} from '@basuiz/web-app-applet-api';

@Component({
  selector: 'bsz-matrix-verification',
  templateUrl: './matrix-verification.component.html',
  styleUrls: ['./matrix-verification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatrixVerificationComponent implements OnInit, OnDestroy {
  @Input()
  verificationRequest: AuthDialogVerificationRequest<MatrixOperation>;

  @Output()
  verificationResult: EventEmitter<AfpAuthDialogResult> = new EventEmitter<AfpAuthDialogResult>();

  readonly coordinates = new BehaviorSubject<string[] | undefined>(undefined);

  private coordinatesNumber: number;

  form: UntypedFormGroup = new UntypedFormGroup({});

  readonly verificationTokenControlId = 'verificationToken';

  readonly numericOnlyResponseCode: boolean = this.config.auth.matrix.numericOnlyResponseCode;

  private readonly unsubscribe = new Subject();
  readonly inProgress = new BehaviorSubject<boolean>(false);

  private readonly inputFieldChange = new Subscription();

  constructor(
    @Inject(ɵCOMMON_CONFIG) private config: CommonConfig,
    private readonly notificationService: NotificationService,
    private authVerificationService: AuthVerificationService
  ) {}

  ngOnInit(): void {
    if (!this.verificationRequest.challenge?.length) {
      console.error('No matrix coordinates provided');
      return;
    }
    const coords = this.verificationRequest.challenge.split(' ');
    this.form = this.getInitForm(coords);
    this.coordinatesNumber = coords.length;
    this.coordinates.next(coords);
    // Subscribe for first input field change in order to trigger field validation after autofocus
    this.inputFieldChange.add(
      this.form
        .get([this.verificationTokenControlId, '0'])
        ?.valueChanges.pipe(take(1))
        .subscribe((value) => {
          this.form.get([this.verificationTokenControlId, '0'])?.markAsTouched();
        })
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
    this.inputFieldChange.unsubscribe();
  }

  private getInitForm(coordinates: string[]): UntypedFormGroup {
    const form = new UntypedFormGroup({});
    const coordFormArray = new UntypedFormArray([]);
    for (let i = 0; i < coordinates.length; i++) {
      coordFormArray.push(new UntypedFormControl('', Validators.required));
    }
    form.addControl(this.verificationTokenControlId, coordFormArray);
    return form;
  }

  cancel() {
    this.verificationResult.emit('CANCEL');
  }

  verify() {
    if (!this.form.valid || !this.coordinatesNumber) {
      return;
    }
    this.inProgress.next(true);
    this.authVerificationService
      .verifyTransaction({
        ...this.verificationRequest.transactionSigningObject,
        verificationAction: 'VERIFY',
        verificationToken: (<Array<string>>this.form.get(this.verificationTokenControlId)?.value).join(' '),
      })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (result) => {
          if (result.payload.transactionSigningVerification === 'RETRY') {
            this.inProgress.next(false);
            this.form.reset();
            for (let i = 0; i < this.coordinatesNumber; i++) {
              this.form.get([this.verificationTokenControlId, i])?.setErrors({incorrectCode: true}, {emitEvent: false});
            }
          } else if (result.payload.transactionSigningVerification === 'CORRECT') {
            this.verificationResult.emit('SUCCESS');
          } else if (result.payload.transactionSigningVerification === 'WRONG') {
            this.verificationResult.emit('FAILURE');
          }
        },
        (result: HttpErrorResponse) => {
          if (this.isUserHasBeenLocked(result)) {
            // TODO userLocked handling (requires BUM-2614)
            console.log('User has been locked and should be logged out');
          }

          this.inProgress.next(false);
          // TODO show general error notification until we have a way of showing notifications from response
          this.notificationService.error(
            asTranslationKey('web-app-common.auth.verification.general-verification-error')
          );
        }
      );
  }

  private isUserHasBeenLocked(result: HttpErrorResponse) {
    return (
      isAfpRestResponse(result.error) &&
      result.error.notifications.some((notification) => notification.path === 'userLocked')
    );
  }

  getVerificationTokenControl(index: number): UntypedFormControl {
    return this.form.get([this.verificationTokenControlId, index]) as UntypedFormControl;
  }

  getInputErrorTranslationKey(index: number): TranslationKey {
    const errors: ValidationErrors | null | undefined = this.form.get([this.verificationTokenControlId, index])?.errors;
    if (errors?.required) {
      return asTranslationKey('web-app-common.auth.verification.matrix-verification.code.required');
    } else if (errors?.incorrectCode) {
      return asTranslationKey('web-app-common.auth.verification.matrix-verification.code.incorrect');
    } else if (errors?.pattern) {
      return asTranslationKey('web-app-common.auth.verification.matrix-verification.code.pattern-mismatch');
    } else {
      return asTranslationKey('web-app-common.auth.verification.matrix-verification.code.unknown-error');
    }
  }
}
