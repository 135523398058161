import {Component, Inject, Input} from '@angular/core';
import {combineLatest, Observable, ReplaySubject} from 'rxjs';
import {NavigationService} from '../../../../navigation/navigation.service';
import {PortalPage} from '../../../../navigation/classes/portal-page';
import {map} from 'rxjs/operators';
import {ɵPORTAL_CONFIG} from '../../../../config/portal.config.provider';
import {translateKeyOptWithParams} from '../../../../navigation/translation-key-with-params';
import {TranslateService} from '@ngx-translate/core';
import {TranslatedText} from '@basuiz/web-app-applet-api';
import {PortalConfig} from '../../../../config/portal.config.definition';
import {AppLoadsPage} from '../../../../navigation/classes/app-loads-page';

@Component({
  selector: 'bsz-hierarchical-breadcrumbs',
  templateUrl: './hierarchical-breadcrumbs.component.html',
})
export class HierarchicalBreadcrumbsComponent {
  public breadcrumbs$: Observable<PortalPage[]>;
  public showBreadcrumbs$: Observable<boolean>;
  private homePage: PortalPage;

  @Input()
  set startWithHome(value: boolean) {
    this.startWithHomeSubject.next(value);
  }

  readonly startWithHomeSubject = new ReplaySubject<boolean>(1);

  constructor(
    @Inject(ɵPORTAL_CONFIG) portalConfig: PortalConfig,
    private navigationService: NavigationService,
    private translateService: TranslateService
  ) {
    this.homePage = portalConfig.navigation.homePortalPage;

    this.breadcrumbs$ = combineLatest([this.navigationService.currentPortalPage$, this.startWithHomeSubject]).pipe(
      map(([portalPage, startWithHome]) => {
        const isParentHome = !portalPage?.parentPortalPage;
        const showBreadcrumbs: boolean =
          !!portalPage && !(portalPage instanceof AppLoadsPage) && (!isParentHome || startWithHome);
        return showBreadcrumbs ? portalPage.breadcrumbs : [];
      })
    );

    this.showBreadcrumbs$ = this.breadcrumbs$.pipe(map((breadcrumbs) => breadcrumbs.length > 0));
  }

  breadcrumbClicked(portalPage: PortalPage): void {
    this.navigateWithoutPageReset(portalPage);
  }

  navigateToHome(): void {
    this.navigateWithoutPageReset(this.homePage);
  }

  private navigateWithoutPageReset(page: PortalPage) {
    page.needsReset = false;
    this.navigationService.ɵnavigateByPortalPage(page);
  }

  getBreadcrumbText(portalPage: PortalPage): TranslatedText {
    return translateKeyOptWithParams(portalPage.breadcrumbText, this.translateService);
  }
}
