import {ActionWithoutResponse} from '@basuiz/mobile-bridge';

/**
 * Informs the native application that attention conter needs to be updated.
 */
export class UpdateAttentionItemCounterAction extends ActionWithoutResponse<UpdateAttentionItemCounterRequest> {
  readonly name = 'updateAttentionItemCounter';
}

export interface UpdateAttentionItemCounterRequest {
  /**
   * Indication which attention counter needs to be updated.
   */
  attentionItemCounterType: string;
}
