/**
 * RULES ABOUT DATES:
 * - All dates/times from must be stored in the database in UTC timezone
 * - All TypeORM Entity's classes, that have @Columns() decorators which are dates must be of type 'timestamptz' ==> @Column({type: 'timestamptz'})
 * - All TypeORM Entity's classes, the class's fields which are dates must add the validation decorator 'IsDateUTC' ==> @IsDateUTC()
 * - All strings that are to be saved in the database (via the API endpoints) must be validated so that it includes a timezone ==> "2023-12-30" is not valid, but "2023-12-30T20:09:09.521Z" is valid
 * - All dates sent from the API to the frontend must be shown in UTC timezone.
 * - When appropiate, all dates shown in the frontend app must be converted from UTC to the user browser/app timezone
 * - "LuxonJS" is Preferred JS library to manipulate dates both in backend and frontend app. The usage of "MomentJS" is disencouraged as it not supports timezones.
 */

import {DateTime} from 'luxon';
import {Moment} from 'moment';
import {UnionTypeFromArray} from './union-type-from-array';
const moment = require('moment');

/**
 *   Valid date: 2023-07-28T14:35:55.552Z
 *
 *   Valid date: 2023-07-28T14:35:55.5Z
 *
 *   Valid date: 2023-07-28T14:35:55Z
 *
 * Invalid date: 2023-07-28T14:35:55 (non-defined UTC/zulu timezone)
 *
 * Invalid date: 2023-07-28T14:35:55+00:00 (UTC timezone must be only marked with a 'Z' at the end)
 *
 * Invalid date: 2023-07-28T14:99:55.552Z (invalid time-minutes part)
 *
 * Invalid date: 2023-17-28T14:99:55.552Z (invalid date-month part)
 */
export const regexDateTimeUTC =
  /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])T([0-1]\d|2[0-3]):([0-5]\d):([0-5]\d)(\.(\d{1,3}))*Z$/;

// TODO p4 these format constants are not localized to user's preferences. These are used in the frontend app, so this should consider the user locale and timezone.
export const DATE_TIME_FORMAT_LONG = 'dd/MM/yyyy, HH:mm:ss'; // DEFAULT (LuxonJS)
export const DATE_TIME_FORMAT_LONG_MOMENT = DATE_TIME_FORMAT_LONG.replace('dd', 'DD'); // value for MomentJS format, which is equivalent from the LuxonJS format on variable 'DATE_TIME_FORMAT_LONG'
export const DATE_TIME_FORMAT_SHORT = 'dd/MM/yyyy, HH:mm'; // (LuxonJS)
export const DATE_TIME_FORMAT_SHORT_MOMENT = DATE_TIME_FORMAT_SHORT.replace('dd', 'DD'); // (MomentJS)
export const DATE_FORMAT_SHORT = 'dd/MM/yyyy'; // (LuxonJS)
export const DATE_FORMAT_SHORT_MOMENT = DATE_FORMAT_SHORT.replace('dd', 'DD'); // (MomentJS)
export const DATE_FORMAT_SHORT_FOR_FILENAME = 'yyyy-MM-dd'; // (LuxonJS)
export const DATE_FORMAT_SHORT_MOMENT_FOR_FILENAME = DATE_FORMAT_SHORT_FOR_FILENAME.replace('dd', 'DD'); // (MomentJS)

export const weekday = [1, 2, 3, 4, 5, 6, 7] as const; // constant used in Luxon 'DateTime.weekday' as the day of the week: 1 is Monday, 2 is Tuesday, ..., and 7 is Sunday.
export type weekday = UnionTypeFromArray<typeof weekday>;
export const hourday = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23] as const; // constant used in Luxon 'DateTime.hour' as the hour of the day: 0 is for 00h00 (o 0am - midnight), 1 is for 01h00 (or 1am), ..., 12 is for 12h00 (or 12pm), ..., and 23 is for 23h00 (or 11pm).
export type hourday = UnionTypeFromArray<typeof hourday>;
export type AmPm = 'AM' | 'PM';

export function isDateUTCPattern(str: string): boolean {
  const regex = new RegExp(regexDateTimeUTC);
  if (!regex.test(str)) {
    return false;
  }
  const d = new Date(str);
  return d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === str; // valid date
}

/**
 * This function is used to parse a UTC date found in the backend, and transform it into a corresponding date
 * using the user's specific locale and timezone of the main frontend app. For example this output date can be
 * used to format the correct date that is sent in an email (from the backend directly, i.e. no frontend interaction)
 *
 * @param date - the input date object, it must be a date object in ISO format and UTC timezone
 * @param locale - the locale that the output date should be parsed to, ex.: 'es-ES', 'en-US'.
 * @param tz - the timezone (relative to UTC) that the output date should be parsed to, ex.: '-5', '+6'.
 * @param format - (MUST be in a LuxonJS format only) the format that the output date should be parsed to, ex.: 'dd/MM/yyyy, HH:mm:ss'.
 * @returns string with the formatted date
 */

export function parseDateToLocaleTimezone(
  date: Date | string,
  format: string = DATE_TIME_FORMAT_LONG,
  locale?: Intl.LocalesArgument,
  tz?: string
): string {
  const DEFAULT_APP_LOCALE: Intl.LocalesArgument = process.env.DEFAULT_APP_LOCALE ?? ''; // TODO p2 this locale should be set based on the user's current language selection
  const DEFAULT_APP_TIMEZONE = `UTC${process.env.DEFAULT_APP_TIMEZONE ?? ''}`; // TODO p2 this timezone should be set based on the user's current timezone selection
  locale = locale ?? DEFAULT_APP_LOCALE;
  tz = tz ?? DEFAULT_APP_TIMEZONE;

  if (typeof date === 'string') {
    // NOTE: this is needed when the input date is a string, in cases where we store the type 'date' in the postgresql as data_type, ex. "2023-01-31" is returned as a string from the DB / endpoint, and not as a JS Date
    date = new Date(date);
  }

  const dateIso = date.toISOString();
  if (!isDateUTCPattern(dateIso)) {
    throw new Error('Date parse currently do not support any timezone different to UTC as an input date object');
  }

  const dt = DateTime.fromISO(dateIso).setZone('UTC').setLocale(locale.toString());

  if (!dt.isValid) {
    throw new Error('Invalid date to be parsed');
  }
  if (dt.zoneName !== 'UTC') {
    throw new Error('Parsed date object was not transformed into UTC timezone');
  }
  if (!dt.setZone(tz).isValid) {
    throw new Error('Invalid date timezone to be parsed');
  }

  const parsedDt: string = dt.setZone(tz).setLocale(locale.toString()).toFormat(format);

  return parsedDt;
}

/**
 * @deprecated this is needed in the frontend as it uses Moment. TODO p4 change this to use Luxon DateTime instead of Moment.
 * @returns a JS Date which its original timeb (based on its timezone) was strip off, and added a UTZ midgnight time to return it back
 */
export function transformDateToZoneUTC(d: Date | Moment): Date {
  const utcDate = new Date(`${moment(d).format('yy-MM-DD')}T00:00:00.000Z`);
  return utcDate;
}

/**
 * @returns the current now datetime at the zone of the default app timezone.
 * This is usually used in the front-end APP.
 */
export function getNowDateTimeInDefaultAppTimeZone(): DateTime {
  const tz = `UTC${process.env.DEFAULT_APP_TIMEZONE ?? ''}`;
  const dt = DateTime.now().setZone(tz);
  return dt;
}

/**
 * @returns the current now datetime at UTC timezone.
 * This is usually used in the back-end API.
 */
export function getNowDateTimeInUTC(): DateTime {
  const dt = DateTime.utc();
  return dt;
}

/**
 *
 * @param date - the input date object, it must be a date object in ISO format and UTC timezone
 * @param format - (MUST be in a LuxonJS format only) the format that the output date should be parsed to, ex.: 'dd/MM/yyyy, HH:mm:ss'.
 * @returns a formatted date as a string
 */
export function formatDateInUTC(date: Date | string, format: string): string {
  if (typeof date === 'string') {
    // NOTE: this is needed when the input date is a string, in cases where we store the type 'date' in the postgresql as data_type, ex. "2023-01-31" is returned as a string from the DB / endpoint, and not as a JS Date
    date = new Date(date);
  }

  const dateIso = date.toISOString();

  if (!isDateUTCPattern(dateIso)) {
    throw new Error('Date parse currently do not support any timezone different to UTC as an input date object');
  }

  const tz = `UTC`;
  const dt = DateTime.fromISO(dateIso).setZone(tz).toFormat(format);

  return dt;
}
