<!-- TODO p3 add language-selector -->
<div class="bsz-text-center bsz-margin-t12">
  <img class="bsz-padding-b6" src="./assets/img/app-logo.png" width="300" />
  <div class="bsz-padding-b10 bsz-title-6">{{
    'web-app-common.auth-jwt.reset-password-request.title' | translate
  }}</div>
  <div class="bsz-padding-b10 bsz-title-5">
    <ng-container *ngIf="resetPasswordRequestStatus === 'pristine'">
      <form [formGroup]="resetPasswordRequestForm" autocomplete="off">
        <div class="center-form">
          <mat-form-field class="bsz-width-100pct">
            <mat-label>
              {{ 'web-app-common.auth-jwt.reset-password-request.form.email' | translate }}
            </mat-label>
            <input
              matInput
              autocomplete="off"
              type="email"
              [formControl]="getFormControl('email')"
              clearInput
              autocapitalize="off"
              inputmode="email"
              (keyup.enter)="doResetPasswordValidate()"
            />
            <mat-error>
              <bsz-form-field-errors [control]="getFormControl('email')"></bsz-form-field-errors>
            </mat-error>
          </mat-form-field>
          <button
            class="bsz-width-100pct"
            mat-flat-button
            color="accent"
            [disabled]="submitLoadingSubject$ | async"
            (click)="doResetPasswordValidate()"
          >
            <div class="bsz-display-flex bsz-justify-center">
              <span>
                {{ 'web-app-common.auth-jwt.reset-password-request.form.button.reset-password' | translate }}
              </span>
              <mat-spinner
                *ngIf="submitLoadingSubject$ | async"
                diameter="20"
                class="bsz-margin-l2 bsz-margin-t2"
              ></mat-spinner>
            </div>
          </button>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="resetPasswordRequestStatus === 'error'">
      {{ 'web-app-common.auth-jwt.reset-password-request.form.response.error' | translate }}
    </ng-container>
    <ng-container *ngIf="resetPasswordRequestStatus === ApiHttpErrorCodes.resetPasswordRequestUserNotFound">
      {{ 'web-app-common.auth-jwt.reset-password-request.form.response.check-email' | translate }}
    </ng-container>
    <ng-container
      *ngIf="resetPasswordRequestStatus === ApiHttpErrorCodes.resetPasswordRequestTimeDelayToCreateNewToken"
    >
      {{ 'web-app-common.auth-jwt.reset-password-request.form.response.wait-to-request-new-token' | translate }}
    </ng-container>
    <ng-container *ngIf="resetPasswordRequestStatus === 'success'">
      {{ 'web-app-common.auth-jwt.reset-password-request.form.response.check-email' | translate }}
    </ng-container>
  </div>
  <div class="bsz-display-flex bsz-flex-column bsz-margin-t12">
    <a bszAccessibleLink class="bsz-text-accent bsz-cursor-pointer bsz-text-bold" (click)="goToSignin.emit()">
      {{ 'web-app-common.auth-jwt.reset-password-request.sign-in' | translate }}
    </a>

    <div *ngIf="showHelp" class="center-form bsz-margin-t12 mat-elevation-z4 help-button">
      <button mat-button (click)="openHelp()">
        <img src="./assets/img/customer-service/whatsapp-logo.png" alt="help" />
        <span class="bsz-padding-r3 bsz-text-bold bsz-subtitle-2">
          {{ 'web-app-common.auth-jwt.reset-password-request.help' | translate }}
        </span>
      </button>
    </div>
  </div>
</div>
