<div class="bsz-surface bsz-padding-0">
  <mat-expansion-panel
    class="mat-elevation-z0 bsz-margin-b6"
    [ngClass]="{'bsz-padding-t4': !collapsible && !title}"
    [expanded]="expanded || !collapsible"
    [hideToggle]="!collapsible"
  >
    <mat-expansion-panel-header
      [ngClass]="{'bsz-cursor-default': !collapsible}"
      *ngIf="collapsible || title || actionsTemplate"
      (click)="onToggle()"
    >
      <mat-panel-title>
        {{ title }}
      </mat-panel-title>
      <mat-panel-description *ngIf="actionsTemplate" class="bsz-justify-end" (click)="onActionClick($event)">
        <ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-content></ng-content>
  </mat-expansion-panel>
</div>
