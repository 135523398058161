import {UserWithoutPassword} from '../user/user.model';

/**
 *
 * @returns an array of the emails from a list of Users
 */
export function getUsersEmails(users: UserWithoutPassword[]): string[] {
  const usersEmail: string[] = [];
  users.map(({email}) => {
    // TODO p4 do this more efficient, as this is computed everytime this function is called, and the returned values, might be always the same
    usersEmail.push(email);
  });
  return usersEmail;
}
