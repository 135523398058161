import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatPseudoCheckboxModule, MatRippleModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {BszI18nModule} from '@basuiz/i18n';

import {BszSelectSearch} from './bsz-select-search';
import {BszSelectSearchMatSelectDirective, BszSelectSearchPipe} from './bsz-select-search.pipe';

@NgModule({
  declarations: [BszSelectSearch, BszSelectSearchPipe, BszSelectSearchMatSelectDirective],
  imports: [CommonModule, MatRippleModule, MatPseudoCheckboxModule, MatIconModule, BszI18nModule],
  exports: [BszSelectSearch, BszSelectSearchPipe, BszSelectSearchMatSelectDirective],
  providers: [BszSelectSearch],
})
export class BszSelectSearchModule {}
