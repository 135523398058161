import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {TestingCoreModule} from '../testing-core/index';
import {BszFormPanelComponent} from './bsz-form-panel.component';
import {MatExpansionModule} from '@angular/material/expansion';

@NgModule({
  declarations: [BszFormPanelComponent],
  imports: [CommonModule, MatExpansionModule, TranslateModule, TestingCoreModule],
  exports: [BszFormPanelComponent, MatExpansionModule],
})
export class BszFormPanelModule {}
