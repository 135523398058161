import {A11yModule} from '@angular/cdk/a11y';
import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import {BszSplashScreen} from './bsz-splash-screen';
import {BszSplashScreenContainer} from './bsz-splash-screen-container';

@NgModule({
  imports: [CommonModule, OverlayModule, A11yModule, MatIconModule, MatProgressSpinnerModule],
  declarations: [BszSplashScreenContainer],
  exports: [BszSplashScreenContainer],
  providers: [BszSplashScreen],
})
export class BszSplashScreenModule {}
