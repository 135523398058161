/* Exception to eslint rule intentionally needed since this is the only file
 * which is allowed to import MAT_FORM_FIELD_DEFAULT_OPTIONS */
/* eslint-disable no-restricted-syntax */

import {ValueProvider} from '@angular/core';
import {MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';

const FormFieldDefaultOptions: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

export const MatFormFieldDefaultOptionsProvider: ValueProvider = {
  provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
  useValue: FormFieldDefaultOptions,
};
