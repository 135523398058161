export class JestMatError {
  constructor(private element: HTMLElement | null) {}

  get exists() {
    return !!this.element;
  }

  get textContent() {
    return this.element?.textContent;
  }
}
