import {HttpParams} from '@angular/common/http';
import {reduce} from 'lodash';

interface Options {
  [key: string]: string | number | boolean | string[] | number[] | undefined | null;
}

export const parameterizeHttpOptions = (options: Options): HttpParams =>
  reduce(
    options,
    (accumulator, value, key) =>
      value !== undefined && value !== null
        ? value instanceof Array
          ? //in case of an array, each value is appended using the same key
            reduce(
              value,
              (accumulatorArray, arrayValue, index) =>
                arrayValue !== undefined ? accumulatorArray.append(key, arrayValue.toString()) : accumulatorArray,
              accumulator
            )
          : accumulator.set(key, value.toString())
        : accumulator, //no value => no modification of accumulator
    new HttpParams()
  );
