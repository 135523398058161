import {
  createAction,
  createFeatureSelector,
  createReducer,
  createSelector,
  MemoizedSelector,
  on,
  props,
  ReducerTypes,
  Store,
} from '@ngrx/store';
import {ActionCreator, ActionReducer} from '@ngrx/store/src/models';
import {AppletContextRequest} from './applet-context.definitions';
import {
  AppletContextFeatureState,
  AppletContexts,
  isActionWithAppletContextId,
} from './applet-context-feature-store.definitions';

export interface AppletContextFeatureStoreAdapter<STATE> {
  initAppletContext: (
    appletContextRequest: AppletContextRequest,
    store: Store<AppletContextFeatureState<STATE>>
  ) => void;
  featureKey: string;
  reducer: ActionReducer<AppletContexts<STATE>>;
  selectFeatureState: MemoizedSelector<AppletContextFeatureState<STATE>, AppletContexts<STATE>>;
  selectContextState: (appletContext: STATE) => STATE;
  getSelectorInContext: <RESULT>(
    selector: MemoizedSelector<STATE, RESULT>,
    contextId: string
  ) => MemoizedSelector<AppletContextFeatureState<STATE>, RESULT>;
}

export function createAppletContextFeatureStoreAdapter<STATE, ACTIONS extends Record<string, ActionCreator>>(
  featureKey: string,
  actions: ACTIONS,
  contextReducer: ActionReducer<STATE>
): AppletContextFeatureStoreAdapter<STATE> {
  const initAppletContextAction = createAction(
    `[AppletContextStoreAdapter] init feature ${featureKey}]`,
    props<AppletContextRequest>()
  );

  const initAppletContext = (
    appletContextRequest: AppletContextRequest,
    store: Store<AppletContextFeatureState<STATE>>
  ) => {
    store.dispatch(initAppletContextAction(appletContextRequest));
  };

  const reducer = createReducer<AppletContexts<STATE>>(
    {},
    on(initAppletContextAction, (state: AppletContexts<STATE>, {appletContextId, onInit, ...action}) =>
      onInit === 'reset' || !(appletContextId in state)
        ? {
            ...state,
            [appletContextId]: contextReducer(undefined, action),
          }
        : state
    ),
    ...Object.values(actions).map(
      (actionCreator) =>
        on(actionCreator, (state: AppletContexts<STATE>, action) => {
          if (!isActionWithAppletContextId(action)) {
            throw new Error(
              `[AppletContextFeatureStoreAdapter] reducer for ${action.type} expects 'appletContextId' to be provided, dispatch with a AppletContextFeatureStoreService`
            );
          }

          const {appletContextId, ...appletAction} = action;

          return {
            ...state,
            [appletContextId]: contextReducer(state[appletContextId], appletAction),
          };
        }) as ReducerTypes<AppletContexts<STATE>, ActionCreator[]>
    )
  );

  const selectFeatureState = createFeatureSelector<AppletContexts<STATE>>(featureKey);

  const selectContextState = (appletContext: STATE) => appletContext;

  const contextSelectors: Record<
    string,
    MemoizedSelector<AppletContextFeatureState<STATE>, AppletContexts<STATE>[string]>
  > = {};

  const getContextSelector = (
    contextId: string
  ): MemoizedSelector<AppletContextFeatureState<STATE>, AppletContexts<STATE>[string]> => {
    if (!contextSelectors[contextId]) {
      contextSelectors[contextId] = createSelector(selectFeatureState, (featureState) => featureState[contextId]);
    }
    return contextSelectors[contextId];
  };

  const getSelectorInContext = <RESULT>(
    selector: MemoizedSelector<STATE, RESULT>,
    contextId: string
  ): MemoizedSelector<AppletContextFeatureState<STATE>, RESULT> =>
    createSelector(getContextSelector(contextId), selector);

  return {
    initAppletContext,
    featureKey,
    reducer,
    selectFeatureState,
    selectContextState,
    getSelectorInContext,
  };
}
