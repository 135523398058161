import {NavigationIntent} from '../../navigation/classes/navigation-intent';

export interface ShowAuthSigninIntentPayload {
  foo?: never;
}

export class ShowAuthSigninIntent extends NavigationIntent {
  constructor(public payload: ShowAuthSigninIntentPayload = {}) {
    super();
  }
}
