import {CALENDAR_DAY_REG_EXP} from '../../const/calendar-day.const';
import {DATE_TIME_REG_EXP} from '../../const/date-time.const';

export enum TypeOfValue {
  CURRENCY = 'CURRENCY',
  DATE = 'DATE',
  IBAN = 'IBAN',
  LINK = 'LINK',
  NUMBER = 'NUMBER',
  PERCENTAGE = 'PERCENTAGE',
  PERFORMANCE = 'PERFORMANCE',
  QUANTITY = 'QUANTITY',
  STRING = 'STRING',
}

export type CalendarDay = string;
export type DateTime = string;

export function isCalendarDay(dateString: string | null | undefined): dateString is CalendarDay {
  const isString = typeof dateString === 'string';
  const doesMatch = isString && !!dateString ? CALENDAR_DAY_REG_EXP.test(dateString) : false;
  return doesMatch;
}

export function isDateTime(dateTimeString: string | null | undefined): dateTimeString is DateTime {
  const isString = typeof dateTimeString === 'string';
  const doesMatch = isString && !!dateTimeString ? DATE_TIME_REG_EXP.test(dateTimeString) : false;
  return doesMatch;
}

export interface CurrencyValueWithFormat {
  type: TypeOfValue.CURRENCY;
  value: number | undefined;
  isoCode: string;
  showISOCode?: boolean;
}

export interface DateValueWithFormat {
  type: TypeOfValue.DATE;
  value: number | string | Date | undefined;
  format?: string;
}

export interface IbanValueWithFormat {
  type: TypeOfValue.IBAN;
  value: string;
}

export interface LinkValueWithFormat {
  type: TypeOfValue.LINK;
  value: string;
}

export interface NumberValueWithFormat {
  type: TypeOfValue.NUMBER;
  value: number | undefined;
  digitsInfo?: string;
}

export interface PercentageValueWithFormat {
  type: TypeOfValue.PERCENTAGE;
  value: number | undefined;
  digitsInfo?: string;
}

export interface PerformanceValueWithFormat {
  type: TypeOfValue.PERFORMANCE;
  value: number | undefined;
  digitsInfo?: string;
}

export interface QuantityValueWithFormat {
  type: TypeOfValue.QUANTITY;
  value: number | undefined;
}

export interface StringValueWithFormat {
  type: TypeOfValue.STRING;
  value: string | undefined;
}

export type ValueWithFormat =
  | CurrencyValueWithFormat
  | DateValueWithFormat
  | IbanValueWithFormat
  | LinkValueWithFormat
  | NumberValueWithFormat
  | PercentageValueWithFormat
  | PerformanceValueWithFormat
  | QuantityValueWithFormat
  | StringValueWithFormat
  | undefined;

export type NumericValueWithFormat = NumberValueWithFormat | PercentageValueWithFormat | PerformanceValueWithFormat;

export function isValueWithFormat(obj: any): obj is ValueWithFormat {
  const isAnObject = typeof obj === 'object';
  const isOfPredefinedType = typeof obj.type === 'string' && obj.type in TypeOfValue;
  const isValueDefined = typeof obj.value !== 'undefined';
  return isAnObject && isOfPredefinedType && isValueDefined;
}
