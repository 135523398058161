<div class="bsz-notification-toast bsz-body-1" matRipple [matRippleDisabled]="!this._hasNotificationClick" [matRippleTrigger]="rippleTrigger">
  <div class="bsz-notification-toast-body"
       [ngClass]="{'bsz-notification-toast--with-icon': _hasIcon}">
    <div class="bsz-notification-toast-content" #rippleTrigger
         (click)="_onNotificationClick()"
         (keydown.enter)="_onNotificationClick()"
         (keydown.space)="_onNotificationClick()">

      <div>
        <ng-content *ngIf="_hasIcon" select="bsz-notification-toast-icon, bsz-notification-icon"></ng-content>
      </div>

      <div class="bsz-notification-toast-text">
        <ng-content select="bsz-notification-toast-title, bsz-notification-title"></ng-content>
        <ng-content select="bsz-notification-toast-summary, bsz-notification-summary"></ng-content>
      </div>
    </div>

    <ng-content select="bsz-notification-toast-actions, bsz-notification-actions"></ng-content>
  </div>

  <button
    mat-icon-button
    (click)="dismiss()"
    class="bsz-notification-toast-dismiss bsz-text-secondary"
    [attr.aria-label]="'ui-elements.bsz-notification.accessibility.dismiss' | translate"
  >
    <span class="material-icons" aria-hidden="true">close</span>
  </button>
</div>
