import {Injectable} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {AfpRestNotification} from '../afp-rest/models/afp-rest-response.definitions';
import {TO_BE_PROVIDED_BY_BANKLET_INSTANCE} from '../web-app-applet/to-be-provided-by-applet-instance.const';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {isEmptyObject} from '../utils/empty-object';

@Injectable({
  providedIn: TO_BE_PROVIDED_BY_BANKLET_INSTANCE,
})
export class FormValidatorBackendErrorsService {
  static readonly BACKEND_VALIDATION_ERROR = 'backendValidation';

  private errorNotificationsSubject: ReplaySubject<AfpRestNotification[] | undefined> = new ReplaySubject<
    AfpRestNotification[] | undefined
  >(1);

  setErrorNotifications(notifications: AfpRestNotification[] | undefined) {
    this.errorNotificationsSubject.next(notifications);
  }

  getErrorNotifications(): Observable<AfpRestNotification[] | undefined> {
    return this.errorNotificationsSubject.asObservable();
  }

  updateFormControlAccordingToBackendValidationErrors(control: UntypedFormControl, path: string): Subscription {
    return this.errorNotificationsSubject.subscribe((errors) => {
      if (errors?.length) {
        const foundError = errors?.find((error) => error.path === path);
        if (foundError) {
          control.setErrors({
            ...(control.errors ?? {}),
            [FormValidatorBackendErrorsService.BACKEND_VALIDATION_ERROR]: foundError,
          });
        } else {
          this.removeBackendValidationError(control);
        }
      } else {
        this.removeBackendValidationError(control);
      }
    });
  }

  private removeBackendValidationError(control: UntypedFormControl) {
    const {backendValidation, ...otherErrors} = control.errors ?? {};
    control.setErrors(isEmptyObject(otherErrors) ? null : otherErrors);
  }
}
