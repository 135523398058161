import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'bsz-web-app-applet-name',
  templateUrl: './web-app-applet-name.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebAppAppletNameComponent {
  @Input()
  appletCssSelector: string;

  @HostBinding('class')
  readonly hostCssClass = 'bsz-web-app-applet--name';

  constructor(public readonly clipboard: Clipboard) {}
}
