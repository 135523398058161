<mat-divider></mat-divider>

<div class="bsz-padding-t6 bsz-padding-b4">
  <bsz-wb-banner type="error">{{
    'web-app-common.auth.verification.unsupported-verification.message'
      | translate: {operation: verificationRequest?.operation}
  }}</bsz-wb-banner>
</div>

<div class="bsz-text-right bsz-padding-b4">
  <button mat-button color="accent" type="button" (click)="cancel()">{{
    'web-app-common.auth.verification.unsupported-verification.button.cancel' | translate
  }}</button>
</div>
