interface PageObject {
  testId: string;
}

export function createPageObject<T extends object>(testId: string, properties: T = <T>{}): PageObject & T {
  const pageObject = Object.assign(
    {
      testId,
    },
    properties
  );
  propagateHierarchyToTestIds(pageObject);
  return pageObject;
}

function propagateHierarchyToTestIds<T>(baseComponent: PageObject, idPrefix: string = ''): void {
  baseComponent.testId = idPrefix + baseComponent.testId;

  for (const value of Object.values(baseComponent)) {
    if (value instanceof Object && value['testId']) {
      propagateHierarchyToTestIds(value, baseComponent['testId'] + '.');
    }
  }
}
