import {animate, state, style, transition, trigger} from '@angular/animations';

const defaultCubicBezierTiming = '300ms cubic-bezier(0.4, 0, 0.2, 1)';
const imageCubicBezierTiming = '1500ms cubic-bezier(.17, .84, .44, 1)';

export class BszSplashScreenAnimations {
  static readonly bszSplashScreenCloseTransition = trigger('bszSplashScreenCloseTransition', [
    transition('* => void', [animate(defaultCubicBezierTiming), style({opacity: '0'})]),
  ]);

  static readonly bszSplashScreenImageEnterTransition = trigger('bszSplashScreenImageEnterTransition', [
    state('loading', style({opacity: '0', transform: 'translateY(-80px)'})),
    transition('loading => enter', [
      animate(imageCubicBezierTiming),
      style({opacity: '1', transform: 'translateY(0)'}),
    ]),
  ]);
}
