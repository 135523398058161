import {createAction, props} from '@ngrx/store';
import {getErrorState} from '../../../utils/error-state';
import {BusinessUnit} from '../../models/business-unit.definition';

export const loadBusinessUnits = createAction('[WebAppCommon/Business units] Load Business units');
export const loadBusinessUnitsRequired = createAction('[WebAppCommon/Business units] Business units required');
export const loadBusinessUnitsSuccess = createAction(
  '[WebAppCommon/Business units] Load Business units Success',
  props<{businessUnitList: BusinessUnit[]}>()
);
export const loadBusinessUnitsFailed = createAction(
  '[WebAppCommon/Business units] Load Business units Failed',
  getErrorState
);
