import {ComponentFixture} from '@angular/core/testing';
import {JestFixtureFacade, JestFixtureFacadeAware} from '../jest/jest-fixture-facade';

export class MatSelectTestingHelper implements JestFixtureFacadeAware {
  jestFixtureFacade: JestFixtureFacade;

  constructor(fixture: ComponentFixture<any>) {
    this.jestFixtureFacade = new JestFixtureFacade(fixture);
  }

  public getMatSelectHTMLElement(testId: string): HTMLElement {
    return this.jestFixtureFacade.select(testId) as HTMLElement;
  }

  public getSelectedValue(matSelect: HTMLElement): string | null {
    const matSelectValue: HTMLElement = matSelect.querySelector('span') as HTMLElement;

    return matSelectValue.textContent;
  }

  public clickMatOptions(matSelect: HTMLElement, optionValues: string[]) {
    //to open the mat select
    this.toggleMatSelect(matSelect);

    optionValues.forEach((option) => {
      const matOption: HTMLElement = this.jestFixtureFacade.debugElement.nativeElement.parentNode.querySelector(
        `mat-option[test-id="${option}"]`
      ) as HTMLElement;
      matOption.click();
    });

    //to close the mat select
    this.toggleMatSelect(matSelect);
  }

  public toggleMatSelect(matSelect: HTMLElement) {
    matSelect.click();
    this.jestFixtureFacade.detectChanges();
  }
}
