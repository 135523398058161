import {LocaleSettings, WebAppCommonLocaleConfig} from '@basuiz/web-app-applet-sdk';
import {LocaleId} from '@basuiz/web-app-applet-api';

import localeEnGB from '@angular/common/locales/en-GB';
import localeEs from '@angular/common/locales/es';
import {WebAppModuleOptions} from '@basuiz/web-app';

export const localeConfig: WebAppModuleOptions['localeConfig'] = {
  useFactory: localeConfigFactory,
};

const defaultLocaleId = 'es-ES';

export function localeConfigFactory(): WebAppCommonLocaleConfig {
  const localeSettings: Record<LocaleId, LocaleSettings> = {
    'en-GB': {
      display: {
        text: 'English',
        order: 1,
      },
      angularLocaleData: localeEnGB,
    },
    'es-ES': {
      display: {
        text: 'Español',
        order: 2,
      },
      angularLocaleData: localeEs,
    },
  };

  const externalLocaleId = new Promise<LocaleId | undefined>((resolve) => {
    const langQueryParameter = new URLSearchParams(window.location.search).get('lang');
    if (langQueryParameter) {
      const localeId = Object.keys(localeSettings).find((key) => key.startsWith(langQueryParameter));
      resolve(localeId);
    } else {
      resolve(undefined);
    }
  });

  return {
    externalLocaleId,
    defaultLocaleId,
    translationsLoader: async (localeId) => import(`../../assets/i18n/${localeId}.json`),
    localeSettings,
    activeLocaleUpdateHook,
  };
}

export async function activeLocaleUpdateHook(newLocaleId: LocaleId | undefined): Promise<void> {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set('lang', newLocaleId ?? defaultLocaleId);
  const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
  history.pushState(null, '', newRelativePathQuery);
}
