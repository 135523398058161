import {NgModule} from '@angular/core';
import {LOCALIZATION_FORMATTERS} from './localization-formatters';
import {BUSINESS_OBJECT_NAMING_CONFIG} from './business-object-naming-config';
import {getInvestmentProposalsConfigProvider} from './investment-proposal-config';
import {getSelfServiceCollaborationClientAdvisorAppletConfigProvider} from './self-service-collaboration-client-advisor-applet.config';
import {postalAddressUpdateAppletConfigProvider} from './user-profile.play-config';

@NgModule({
  providers: [
    ...LOCALIZATION_FORMATTERS,
    ...BUSINESS_OBJECT_NAMING_CONFIG,
    getInvestmentProposalsConfigProvider(),
    getSelfServiceCollaborationClientAdvisorAppletConfigProvider(),
    postalAddressUpdateAppletConfigProvider,
  ],
})
export class AppAppletConfigModule {}
