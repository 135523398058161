import {Pipe, PipeTransform} from '@angular/core';

import {isUndefined, throwInvalidPipeArgument} from '../../helpers/formatting-helpers';

@Pipe({name: 'emptyIfZero'})
export class EmptyIfZeroPipe implements PipeTransform {
  public transform(value: string | null | undefined): string {
    // return empty string for nil param
    if (isUndefined(value)) {
      return '';
    }

    // if value is not of an accepted type then fail
    if (typeof value !== 'string') {
      throwInvalidPipeArgument(EmptyIfZeroPipe, value);
    }

    // proceed with pipe logic
    const strippedValue = value.replace(/[^\d,.-]/g, '').replace(',', '');

    // no special chars nor digits - looks like this is a string
    if (strippedValue === '') {
      return value;
    }

    const numericValue = Number(strippedValue);
    return numericValue === 0 ? '' : value;
  }
}
