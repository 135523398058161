import {UserProfilePostalAddressUpdateAppletConfig} from './user-profile-postal-address-update-applet.config.definition';

export function getDefaultConfig(): UserProfilePostalAddressUpdateAppletConfig {
  return {
    customFormComponent: () => undefined,
    countryAllowlist: undefined,
    privacyPolicyAcceptance: {status: 'disabled'},
    validFrom: {status: 'disabled'},
    userRemarks: {status: 'disabled'},
  };
}
