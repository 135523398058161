import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {BszButtonToggleStyle} from './bsz-button-toggle-style';

@NgModule({
  imports: [CommonModule],
  exports: [BszButtonToggleStyle],
  declarations: [BszButtonToggleStyle],
  providers: [],
})
export class BszButtonToggleStyleModule {}
