import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatRadioModule} from '@angular/material/radio';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import {BszAmountModule} from '@basuiz/ui-elements';

import {AppShellOutletEmitterModule} from '../app-shell-outlet/emitter/index';
import {BszInfoTextModule} from '../info-text/bsz-info-text.module';
import {BszSelectSearchFilterModule} from '../select-search-filter/index';
import {WebAppStepperModule} from '../stepper/web-app-stepper.module';

import {WebAppBasicModule} from './web-app-basic.module';

const ANGULAR_MODULES = [ReactiveFormsModule];

const ANGULAR_MATERIAL_MODULES = [MatAutocompleteModule, MatRadioModule, MatSlideToggleModule, MatDatepickerModule];

const UI_SDK_MODULES = [BszAmountModule];

const WEB_APP_COMMON_MODULES = [
  WebAppStepperModule,
  BszInfoTextModule,
  AppShellOutletEmitterModule,
  BszSelectSearchFilterModule,
];

@NgModule({
  exports: [WebAppBasicModule, ANGULAR_MODULES, ANGULAR_MATERIAL_MODULES, UI_SDK_MODULES, WEB_APP_COMMON_MODULES],
})
export class FormAppletBasicModule {}
