<ng-container *ngIf="componentState$ | async as cs">
  <div mat-dialog-title>
    {{ 'web-app-portal.app-bar.user-account-menu.change-locale.dialog.title' | translate }}
  </div>

  <mat-dialog-content>
    <div class="bsz-body-1 bsz-margin-b8">
      {{ 'web-app-portal.app-bar.user-account-menu.change-locale.dialog.description' | translate }}
    </div>
    <bsz-web-app-self-service-locale-selector-applet
      [changeUponSelection]="false"
      (selectedAndActiveLocalesDiffer)="this.updateComponentState({localesDiffer: $event})"
    >
    </bsz-web-app-self-service-locale-selector-applet>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      mat-button
      color="accent"
      mat-dialog-close
      [disabled]="cs.changing"
      bszTestId="{{ bszTestIdPrefix }}.change-locale-dialog.cancel.button"
    >
      {{ 'web-app-portal.app-bar.user-account-menu.change-locale.dialog.actions.cancel.label' | translate }}
    </button>

    <button
      mat-flat-button
      color="accent"
      (click)="onChangeLanguage()"
      [disabled]="!cs.localesDiffer || cs.changing"
      bszTestId="{{ bszTestIdPrefix }}.change-locale-dialog.change.button"
    >
      {{ 'web-app-portal.app-bar.user-account-menu.change-locale.dialog.actions.change.label' | translate }}
    </button>
  </mat-dialog-actions>
</ng-container>
