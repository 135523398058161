import {Injectable} from '@angular/core';
import {MatSortHeaderIntl} from '@angular/material/sort';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class MatSortHeaderLabels extends MatSortHeaderIntl {
  constructor(private translate: TranslateService) {
    super();
  }

  sortButtonLabel = (id: string) =>
    this.translate.instant('ui-elements.mat-sort-header.accessibility.sort-button', {id});
}
