import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {BszScreenSize, BszSnackbarService} from '@basuiz/ui-elements';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable, map} from 'rxjs';
import {ExternalLinkService} from '../../../external-link/external-link.service';
import {passwordMinLength} from '../../models/auth.definitions';
import {AuthJwtService} from '../../services/auth-jwt.service';

@Component({
  selector: 'bsz-auth-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SigninComponent implements OnInit {
  @Output()
  signinSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  goToSignup: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  goToResetPasswordRequest: EventEmitter<void> = new EventEmitter<void>();

  readonly isDesktop$: Observable<boolean> = this.bszScreenSize
    .getScreenSize()
    .pipe(map((screenSize) => screenSize === 'desktop'));

  signinForm: FormGroup;

  private submitLoadingSubject = new BehaviorSubject<boolean>(false);
  submitLoadingSubject$ = this.submitLoadingSubject.asObservable();

  public readonly showHelp: boolean = Boolean(process.env['APP_CUSTOMER_SUPPORT_PHONE_WHATSAPP']);

  constructor(
    private authJwtService: AuthJwtService,
    private translateService: TranslateService,
    private snackbarService: BszSnackbarService,
    private externalLinkService: ExternalLinkService,
    private bszScreenSize: BszScreenSize
  ) {
    this.signinForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(passwordMinLength)]),
    });
  }

  ngOnInit(): void {
    this.resetForm();
  }

  getFormControl(formControlName: any): UntypedFormControl {
    return this.signinForm.get(formControlName) as UntypedFormControl;
  }

  doSignin() {
    this.submitLoadingSubject.next(true);

    if (this.signinForm.valid) {
      const email = this.signinForm.get('email')?.value;
      const password = this.signinForm.get('password')?.value;

      this.authJwtService.signin(email, password).subscribe((userJwt) => {
        if (userJwt?.user?.objectId) {
          this.resetForm();
          this.signinSubmitted.emit(true);
        }
        this.submitLoadingSubject.next(false);
      });
    } else {
      this.snackbarService.open(
        this.translateService.instant('web-app-common.auth-jwt.signin.signin-invalid-form'),
        undefined,
        {
          dismissable: true,
          duration: 10000,
        }
      );

      this.submitLoadingSubject.next(false);
    }
  }

  resetForm() {
    const controls = Object.keys(this.signinForm.controls);
    for (const control of controls) {
      if (control) {
        this.getFormControl(control).reset('');
        this.getFormControl(control).setErrors(null);
      }
    }
  }

  openHelp() {
    this.externalLinkService.open(
      `https://api.whatsapp.com/send/?phone=${process.env['APP_CUSTOMER_SUPPORT_PHONE_WHATSAPP']}`
    );
  }
}
