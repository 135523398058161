<div mat-dialog-title>
  {{ 'web-app-common.auth.dialog.title' | translate }}
</div>

<mat-dialog-content>
  <bsz-auth-representation [representation]="authRequestData.representation"></bsz-auth-representation>
</mat-dialog-content>

<mat-dialog-actions>
  <bsz-auth-verification
    [verificationRequest]="authRequestData.verificationRequest"
    (verificationResult)="onVerification($event)"
  ></bsz-auth-verification>
</mat-dialog-actions>
