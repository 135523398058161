import {Type, ɵstringify as stringify} from '@angular/core';

// True for undefined or null
/* @deprecated use isNullOrUndefined which is type safe */
export function isUndefined(value: any): boolean {
  // true for null and undefined, following lodash implementation
  return value == null;
}

// Used to check if value after rounding is equal to zero
export function isZeroAlike(value: string): boolean {
  // no need to remove thousand separators or +/-
  const strippedValue = value.replace('%', '');
  return Number(strippedValue) === 0;
}

// Mimics native Angular pipes that fail on invalid argument type
export function throwInvalidPipeArgument(type: Type<any>, value: any): never {
  throw Error(`InvalidPipeArgument: '${value}' for pipe '${stringify(type)}'`);
}
