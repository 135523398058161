import {
  ComponentRef,
  Directive,
  EmbeddedViewRef,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {ComponentType} from '@angular/cdk/portal';
import {assertNever} from '../utils/assert-never';
import {DataState} from './data-state.definition';
import {AvailableContext, DataStateContext, ErrorContext, LoadingContext, NoDataContext} from './data-state.context';
import {DataStateUnavailableComponent} from './data-state-unavailable/data-state-unavailable.component';

abstract class DataStateUnavailableTemplateProvider {
  abstract loadingTemplateRef: TemplateRef<LoadingContext>;
  abstract noDataTemplateRef: TemplateRef<NoDataContext>;
  abstract errorTemplateRef: TemplateRef<ErrorContext>;
}

// noinspection AngularMissingOrInvalidDeclarationInModule -> declared in module in spec
/**
 * Conditionally include a template when the `DataState` is `DataStateAvailable`,
 * giving the template access to the available `data`. The `DataState` is
 * typically provided by an observable created using the `toDataState()` operator
 *
 * ```
 * <ng-container *bszDataState="dataState$ | async; let myData=data">
 *   <my-component [data]="myData"></my-component>
 * </ng-container>
 * ```
 *
 * When the `DataState` is `DataStateUnavailable` then a standard template is
 * rendered instead. These standard templates can be overridden by passing a
 * TemplateRef to the directive.
 *
 * | `DataState`        | Standard              | Override  |
 * | ------------------ | --------------------- | --------- |
 * | `DataStateLoading` | `bsz-progress-bar`    | `loading` |
 * | `DataStateNoData`  | `bsz-central-message` | `noData`  |
 * | `DataStateError`   | `bsz-technical-error` | `error`   |
 *
 * ```
 *
 * <ng-container *bszDataState="dataState$ | async; let data; error: onError">
 *   <my-component [data]="data"></my-component>
 * </ng-container>
 * <ng-template #onError>
 *   <div>Custom Error</div>
 * <ng-template>
 * ```
 *
 */
@Directive({selector: '[bszDataState]'})
export class DataStateDirective<T> implements OnChanges {
  /* eslint-disable @angular-eslint/no-input-rename */
  @Input('bszDataState') dataState: DataState<T>;
  @Input('bszDataStateLoading') loadingTemplateRef: TemplateRef<LoadingContext> | undefined;
  @Input('bszDataStateNoData') noDataTemplateRef: TemplateRef<NoDataContext> | undefined;
  @Input('bszDataStateError') errorTemplateRef: TemplateRef<ErrorContext> | undefined;

  // DO NOT use a setter for `bszDataState`; it results in a render of a default template before any template input is resolved
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataState.currentValue) {
      this.createOrUpdateEmbeddedView();
    }
  }

  protected get templateProviderComponentType(): ComponentType<DataStateUnavailableTemplateProvider> {
    return DataStateUnavailableComponent;
  }

  constructor(
    private availableTemplateRef: TemplateRef<AvailableContext<T>>,
    private viewContainerRef: ViewContainerRef
  ) {
    this.createDataStateUnavailableComponent();
  }

  private savedState: DataState<T>['state'];
  private context: DataStateContext<DataState<T>>;
  private templateRef: TemplateRef<unknown>;
  private viewRef: EmbeddedViewRef<unknown>;
  private dataStateUnavailableComponentRef: ComponentRef<DataStateUnavailableTemplateProvider>;

  private createDataStateUnavailableComponent() {
    this.dataStateUnavailableComponentRef = this.viewContainerRef.createComponent(this.templateProviderComponentType);
    this.dataStateUnavailableComponentRef.changeDetectorRef.detectChanges(); // resolve @ViewChild template refs
  }

  private createOrUpdateEmbeddedView() {
    if (this.dataState.state !== this.savedState) {
      this.createEmbeddedView();
    } else {
      this.updateEmbeddedView();
    }
  }

  private createEmbeddedView() {
    switch (this.dataState.state) {
      case 'available':
        this.context = new AvailableContext(this.dataState);
        this.templateRef = this.availableTemplateRef;
        break;
      case 'loading':
        this.context = new LoadingContext(this.dataState);
        this.templateRef = this.loadingTemplateRef ?? this.dataStateUnavailableComponentRef.instance.loadingTemplateRef;
        break;
      case 'no-data':
        this.context = new NoDataContext(this.dataState);
        this.templateRef = this.noDataTemplateRef ?? this.dataStateUnavailableComponentRef.instance.noDataTemplateRef;
        break;
      case 'error':
        this.context = new ErrorContext(this.dataState);
        this.templateRef = this.errorTemplateRef ?? this.dataStateUnavailableComponentRef.instance.errorTemplateRef;
        break;
      default:
        assertNever(this.dataState);
    }

    this.viewContainerRef.clear();
    this.viewRef = this.viewContainerRef.createEmbeddedView(this.templateRef, this.context);

    this.savedState = this.dataState.state;
  }

  private updateEmbeddedView() {
    this.context.dataState = this.dataState;
    this.viewRef.detectChanges();
  }
}
