import {ChangeDetectionStrategy, Component, ElementRef, Input, ViewEncapsulation} from '@angular/core';

import {webcoat_deprecated} from '../../common/webcoat-deprecated';

export type BszStatusPalette = 'default' | 'primary' | 'accent' | 'success' | 'error' | 'info' | 'warning';

/** @deprecated [v3.12.0] The BszLozengePalette is deprecated, use BszStatusPalette instead */
export type BszLozengePalette = BszStatusPalette;

@Component({
  selector: 'bsz-status, bsz-emphasized-status, bsz-lozenge',
  styleUrls: ['bsz-status.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'class': 'bsz-status',
    '[class.bsz-emphasized-status]': "variant === 'emphasized'",
    '[class.bsz-status-default]': "!color || color === 'default'",
    '[class.bsz-status-primary]': "color === 'primary'",
    '[class.bsz-status-accent]': "color === 'accent'",
    '[class.bsz-status-success]': "color === 'success'",
    '[class.bsz-status-error]': "color === 'error'",
    '[class.bsz-status-info]': "color === 'info'",
    '[class.bsz-status-warning]': "color === 'warning'",
  },
  template: `<ng-content></ng-content>`,
})
export class BszStatus {
  @Input() color: BszStatusPalette = 'default';

  protected readonly variant: 'default' | 'emphasized' = 'default';

  constructor(private readonly elementRef: ElementRef) {
    const tagName = elementRef.nativeElement.tagName.toLowerCase();
    if (tagName === 'bsz-lozenge') {
      webcoat_deprecated(
        '[bsz-status] The selector "bsz-lozenge" is DEPRECATED since v3.12.0. Use "bsz-status" instead.'
      );
    }
    this.variant = tagName === 'bsz-emphasized-status' ? 'emphasized' : 'default';
  }
}
