import {Injectable} from '@angular/core';
import {BszCurrencyPipe, BszDatePipe} from '../../formatting/index';
import {
  AuthRepresentation,
  AuthRepresentationField,
  AuthRepresentationFieldValue,
  AuthRepresentationSummaryGroup,
  StructuredBeneficiaryAddress,
} from '../models/auth.definitions';
import {
  AuthDialogCollectiveRepresentation,
  AuthDialogRepresentation,
  AuthDialogRepresentationField,
  AuthDialogRepresentationGroupDetails,
  AuthDialogRepresentationGroupRow,
  AuthDialogSingleRepresentation,
} from '../models/auth-dialog.definitions';
import {TranslationKey} from '@basuiz/web-app-applet-api';

@Injectable()
export class AuthRepresentationService {
  constructor(private currencyPipe: BszCurrencyPipe, private datePipe: BszDatePipe) {}

  getDialogRepresentation(authRepresentation: AuthRepresentation): AuthDialogRepresentation {
    return authRepresentation.summaryGroups.length === 0
      ? this.getDialogSingleRepresentation(authRepresentation)
      : this.getDialogCollectiveRepresentation(authRepresentation);
  }

  private getDialogSingleRepresentation(authRepresentation: AuthRepresentation): AuthDialogSingleRepresentation {
    return {
      title: authRepresentation.summaryTitle,
      details: this.getDialogRepresentationFields(authRepresentation.signingFields),
    };
  }

  private getDialogCollectiveRepresentation(
    authRepresentation: AuthRepresentation
  ): AuthDialogCollectiveRepresentation {
    return {
      title: authRepresentation.summaryTitle,
      summary: this.getDialogRepresentationFields(authRepresentation.signingFields),
      groupedDetails: this.getDialogRepresentationGroupDetails(authRepresentation.summaryGroups),
    };
  }

  private getDialogRepresentationFields(signingFields: AuthRepresentationField[]): AuthDialogRepresentationField[] {
    return signingFields.map((field) => this.getDialogRepresentationField(field));
  }

  private getDialogRepresentationField(representationField: AuthRepresentationField): AuthDialogRepresentationField {
    const {field, label} = representationField;
    const value = this.formatRepresentationField(representationField);

    return {
      field,
      label,
      value,
    };
  }

  private formatRepresentationField(representationField: AuthRepresentationField): string | null {
    const {field} = representationField;
    return this.formatRepresentationFieldValue(representationField, field);
  }

  private formatRepresentationFieldValue(
    fieldValue: AuthRepresentationFieldValue,
    fieldOrLabel: string | TranslationKey
  ): string | null {
    try {
      switch (fieldValue.type) {
        case 'AmountInCurrency':
          return !!fieldValue.format
            ? this.currencyPipe.transform(Number(fieldValue.value), fieldValue.format)
            : fieldValue.value;
        case 'DateWithFormat':
          return this.datePipe.transform(fieldValue.value, {format: fieldValue.format});
        case 'StructuredBeneficiaryAddress':
          return AuthRepresentationService.getStructuredBeneficiaryAddressRepresentation(fieldValue.value);
        default:
          return fieldValue.value;
      }
    } catch (error) {
      console.warn(`Cannot format value for '${fieldOrLabel}', using raw value: ${error}`);
      return null;
    }
  }

  private getDialogRepresentationGroupDetails(
    summaryGroups: AuthRepresentationSummaryGroup[]
  ): AuthDialogRepresentationGroupDetails[] {
    return summaryGroups.map((group) => this.getDialogRepresentationGroupDetailsObject(group));
  }

  private getDialogRepresentationGroupDetailsObject(
    group: AuthRepresentationSummaryGroup
  ): AuthDialogRepresentationGroupDetails {
    const {title, labels, rows} = group;
    const details: AuthDialogRepresentationGroupRow[] | undefined =
      rows?.length > 0
        ? rows.map((row) =>
            row.values.reduce((result: AuthDialogRepresentationGroupRow, value, index) => {
              result[index] = this.formatRepresentationFieldValue(value, labels[index]);
              return result;
            }, {})
          )
        : undefined;
    return {title, labels, details};
  }

  // Replicates the representation from payment-details-helper.service#getUnstructuredOrStructuredAddress
  private static getStructuredBeneficiaryAddressRepresentation(value: StructuredBeneficiaryAddress): string | null {
    return [
      value.street ? value.street + ' ' + value.streetNumber : undefined,
      value.zip ? value.zip + ' ' + value.city : undefined,
      value.countryName ? value.countryName : undefined,
    ]
      .filter((addressLine): addressLine is string => !!addressLine)
      .join(', ');
  }
}
