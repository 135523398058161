<form [formGroup]="businessUnitForm">
  <mat-form-field class="bsz-width-100pct" bszTestId="web-app-common.business-unit">
    <mat-label>{{ 'web-app-common.business-unit.label' | translate }}</mat-label>
    <mat-select
      [attr.aria-label]="'web-app-common.business-unit.aria-label' | translate"
      [formControl]="businessUnitControl"
      bszTestId="web-app-common.business-unit.mat-select"
    >
      <mat-option
        *ngFor="let businessUnit of businessUnitList$ | async"
        [value]="businessUnit"
        (onSelectionChange)="onChange($event)"
      >
        {{ businessUnit.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
