import {
  formatCurrency as ngFormatCurrency,
  getCurrencySymbol,
  getLocaleNumberSymbol,
  NumberSymbol,
} from '@angular/common';
import {Inject, Optional, Pipe, PipeTransform} from '@angular/core';
import {BszI18nService} from '@basuiz/i18n';

import {InvalidPipeArgumentError} from '../common/invalid-pipe-argument-error';
import {isValue, strToNumber} from '../common/number-utils';
import {UNICODE_MINUS_SIGN} from '../common/pipes.constants';
import {
  BSZ_CURRENCY_PIPE_DEFAULT_CONFIG,
  BSZ_CURRENCY_PIPE_DEFAULT_OPTIONS,
  BszCurrencyPipeConfig,
} from './currency.pipe.config';
import {InvalidTemplateError} from './invalid-template-error';

export type BszCurrencyDisplay = 'code' | 'symbol' | 'symbol-narrow';

export enum BszCurrencyTemplateToken {
  CURRENCY_SYMBOL = '[CURRENCY_SYMBOL]',
  MINUS_SYMBOL = '[MINUS_SYMBOL]',
  AMOUNT = '[AMOUNT]',
}

@Pipe({name: 'bszCurrency'})
export class BszCurrencyPipe implements PipeTransform {
  private readonly config: BszCurrencyPipeConfig;

  constructor(
    private readonly i18nService: BszI18nService,
    @Optional() @Inject(BSZ_CURRENCY_PIPE_DEFAULT_OPTIONS) defaultConfig?: BszCurrencyPipeConfig
  ) {
    const emptyValue = defaultConfig?.emptyValue || BSZ_CURRENCY_PIPE_DEFAULT_CONFIG.emptyValue;
    const display = defaultConfig?.display || BSZ_CURRENCY_PIPE_DEFAULT_CONFIG.display;
    const template = Object.assign(BSZ_CURRENCY_PIPE_DEFAULT_CONFIG.template, defaultConfig?.template || {});
    this.config = {emptyValue, display, template};
  }

  /**
   * @param value The value to format.
   *
   * @param currencyCode The [ISO 4217](https://en.wikipedia.org/wiki/ISO_4217) currency code.
   * ex: 'CHF' for swiss francs or 'EUR' for euro
   *
   * @param display The format for the currency indicator. One of:
   *   - `code`: Show the code (eg: `USD`).
   *   - `symbol`: Show the symbol (eg: `$`).
   *   - `symbol-narrow`: Use the narrow symbol for locales that have two symbols for their currency
   *      (eg: for `CAD` there is `CA$` and `$`, this option will pick the `$`).
   *
   * @param digitsInfo Decimal representation options, specified by a string
   * in the following format: {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}.
   *   - `minIntegerDigits`: The minimum number of integer digits before the decimal point.
   *   - `minFractionDigits`: The minimum number of digits after the decimal point.
   *   - `maxFractionDigits`: The maximum number of digits after the decimal point.
   * If not provided, the number will be formatted with the proper amount of digits,
   * depending on what the [ISO 4217](https://en.wikipedia.org/wiki/ISO_4217) specifies.
   *
   * @param locale A locale code for the locale format rules to use.
   * When not supplied, uses the value of `localeId` from the `BszI18nService`
   */
  transform(
    value: number | string | null | undefined,
    currencyCode: string,
    display?: BszCurrencyDisplay,
    digitsInfo?: string,
    locale?: string
  ): string {
    const localeId = locale || this.i18nService.localeId;
    const currencyDisplay = display ?? this.config.display;

    let currency = currencyCode;
    if (currencyDisplay === 'symbol' || currencyDisplay === 'symbol-narrow') {
      const symbolFormat = currencyDisplay === 'symbol' ? 'wide' : 'narrow';
      currency = getCurrencySymbol(currency, symbolFormat, locale);
    }

    try {
      const formattedCurrency = formatCurrency(value, localeId, currency, currencyCode, digitsInfo);

      if (formattedCurrency === '') {
        return this.config.emptyValue;
      }

      if (this.config.template[localeId]) {
        return formatCurrencyWithTemplate(formattedCurrency, currency, this.config.template[localeId]);
      }

      return formattedCurrency;
    } catch (error) {
      if (error instanceof InvalidTemplateError) {
        throw error;
      }
      throw new InvalidPipeArgumentError(value, BszCurrencyPipe);
    }
  }
}

// We export this function separately to allow for usage in different
// places without the need to provide/inject BszCurrencyPipe
export function formatCurrency(
  value: number | string | null | undefined,
  locale: string,
  currency: string,
  currencyCode?: string,
  digitsInfo?: string
): string {
  if (!isValue(value)) {
    return '';
  }
  const val = strToNumber(value as any);
  const formattedCurrency = ngFormatCurrency(val, locale, currency, currencyCode, digitsInfo);
  const localeMinusSing = getLocaleNumberSymbol(locale, NumberSymbol.MinusSign);

  return formattedCurrency.replace(localeMinusSing, UNICODE_MINUS_SIGN);
}

/**
 * Returns a formatted string based on a custom template with predefined tokens.
 * The individual parts of a formatted currency are separated and then placed
 * to their relevant positions of the custom template string.
 */
export function formatCurrencyWithTemplate(formattedAmount: string, currencySymbol: string, template: string): string {
  const absoluteValue = formattedAmount.replace(currencySymbol, '').replace(UNICODE_MINUS_SIGN, '').trim();
  const isNegative = formattedAmount.includes(UNICODE_MINUS_SIGN);

  validateCurrencyTemplate(template);

  const customFormattedAmount = template
    .replace(BszCurrencyTemplateToken.AMOUNT, absoluteValue)
    .replace(BszCurrencyTemplateToken.CURRENCY_SYMBOL, currencySymbol)
    .replace(BszCurrencyTemplateToken.MINUS_SYMBOL, isNegative ? UNICODE_MINUS_SIGN : '');

  return customFormattedAmount;
}

function validateCurrencyTemplate(template: string): never | true {
  if (!template.includes(BszCurrencyTemplateToken.AMOUNT)) {
    throw new InvalidTemplateError(`${BszCurrencyTemplateToken.AMOUNT} is missing from the template: "${template}"`);
  }

  if (!template.includes(BszCurrencyTemplateToken.CURRENCY_SYMBOL)) {
    throw new InvalidTemplateError(
      `${BszCurrencyTemplateToken.CURRENCY_SYMBOL} is missing from the template: "${template}"`
    );
  }

  if (!template.includes(BszCurrencyTemplateToken.MINUS_SYMBOL)) {
    throw new InvalidTemplateError(
      `${BszCurrencyTemplateToken.MINUS_SYMBOL} is missing from the template: "${template}"`
    );
  }

  return true;
}
