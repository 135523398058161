<ng-template>
  <div
   class="bsz-modal-bottom-sheet mat-elevation-z4"
   role="dialog"
   aria-modal="true"
   [ngClass]="_panelClass"
   [cdkTrapFocus]="this.hasBackdrop || this.isMaximized"
   [id]="uniqueId"
   [style.max-width]="maxWidth"
   [cdkTrapFocusAutoCapture]="true"
   [attr.aria-label]="ariaLabel || null"
   [attr.aria-labelledby]="ariaLabelledby || null"
   [attr.aria-describedby]="ariaDescribedby || null"
   (@modalBottomSheetMaximizeMinimizeTransition.start)="onTransitionStart($event)"
   (@modalBottomSheetMaximizeMinimizeTransition.done)="onTransitionEnd($event)"
   [@modalBottomSheetMaximizeMinimizeTransition]="{
     value: isMaximized ? 'maximized' : 'minimized',
     params: {minimizedHeight: minimizedHeight}
    }"
   >

    <div class="bsz-modal-bottom-sheet-header">
      <button
        class="bsz-modal-bottom-sheet-bar-trigger"
        [attr.tabindex]="minimizeMaximizeTriggerIsFocusable"
        (click)="toggleMaximize()"
        [attr.data-is-maximized]="isMaximized"
        [attr.aria-label]="isMaximized ? ('ui-elements.bsz-modal-bottom-sheet.accessibility.minimize' | translate) : ('ui-elements.bsz-modal-bottom-sheet.accessibility.maximize' | translate)">
      </button>
      <div *ngIf="title" class="bsz-modal-bottom-sheet-header-title">
        <ng-template [ngTemplateOutlet]="title"></ng-template>
      </div>
    </div>

    <div class="bsz-modal-bottom-sheet-content-container" [style.height]="contentContainerHeight" [style.display]="hideContentFromA11YTech ? 'none' : ''">
      <div cdkScrollable class="bsz-modal-bottom-sheet-content">
        <ng-template *ngIf="content" [ngTemplateOutlet]="content"></ng-template>
      </div>

      <div *ngIf="footer" class="bsz-modal-bottom-sheet-footer">
        <ng-template [ngTemplateOutlet]="footer"></ng-template>
      </div>
    </div>

  </div>

</ng-template>
