import {Component} from '@angular/core';

@Component({
  selector: 'bsz-web-app-alert-indicator-applet',
  template: `<bsz-alert-indicator
    bszWebAppApplet
    bszTestId="testIds.web-app-alert-indicator-applet"
  ></bsz-alert-indicator>`,
})
export class WebAppAlertIndicatorAppletComponent {
  // Please align WebAppAlertIndicatorAppletModuleAppletTestingComponent with any changes in the API of this component
  // DO NOT REMOVE THIS COMMENT!
  //
  // - ENCAPSULATION:
  // This component serves as an interface between the applet's features and the customer application.
  // Therefore it should not contain other public elements apart from those that belong to the applet API,
  // which typically consist of @Input and @Output bindings.
  //
  // Otherwise customer could access to public properties that are intended to be used only by the applet's template,
  // leading unintended breaking changes when refactoring a applet or to customers opening strange issues when they
  // access to parts of the applet they are not supposed to.
  //
  // All the logic of the applet, and the associated templates, should be located in internal components of the
  // applet library, which are not to be exported by the applet module.
  //
  // - API DOCUMENTATION:
  // All properties that conform the applet API should be well-typed and documented.
  // Customers will be able to inspect from their IDE the declaration file generated when packaging the library.
  // Therefore it is important to type all the API elements and to add a description of their purpose.
  // Documentation should be in the form of TS-Docs. e.g. /** This is an API-element description */
}
