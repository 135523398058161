import {ValidationErrors} from '@angular/forms';

export interface BszDateRange<D> {
  start: D | null;
  end: D | null;
}

export type BszDateRangeInputPlaceholder = null | string | {startDate: string; endDate: string};

/** Collection type for periods */
export type BszDateCollection<D> = Record<string, D | null>;
export type BszDateRangeCollection<D> = Record<string, BszDateRange<D> | null>;
export type BszMixedDateCollection<D> = Record<string, D | BszDateRange<D> | null>;

/** Represents either single or a range of dates */
export type BszMixedDate<D> = D | BszDateRange<D>;

export interface BszMinDateValidationErrors<D> extends ValidationErrors {
  bszDatepickerMin: {
    min: D;
    minFormatted: string;
    actual: D;
    actualFormatted: string;
  };
}

export interface BszMaxDateValidationErrors<D> extends ValidationErrors {
  bszDatepickerMax: {
    max: D;
    maxFormatted: string;
    actual: D;
    actualFormatted: string;
  };
}

export interface BszDateFilterValidationErrors extends ValidationErrors {
  bszDatepickerFilter: boolean;
}

export interface BszDateParseValidationErrors extends ValidationErrors {
  bszDatepickerParse: {
    value: string;
  };
}

export interface BszDateRangeParseValidationErrors extends ValidationErrors {
  bszDatepickerRangeParse: {
    /** Whether the start date is invalid */
    start: boolean | null;
    /** Whether the end date is invalid */
    end: boolean | null;
    /**
     * Whether range is invalid
     *
     * An invalid range is considered when both start and end dates are
     * valid but the start date is later than the end date.
     */
    range: boolean | null;
    /** The value validated as a string */
    value: string;
  };
}

export function isDateRange(o: unknown): o is BszDateRange<unknown> {
  if (o && typeof o === 'object') {
    return o.hasOwnProperty('start') && o.hasOwnProperty('end');
  }
  return false;
}
