// IMPORTANT: do not modify this file

import {inject, InjectionToken, FactoryProvider} from '@angular/core';
import {BszConfigFactory, BszConfigFactoryWithDeps} from '@basuiz/web-app-applet-api';
import {DashboardPageConfig} from './dashboard-page.config.definition';
import {getDefaultConfig} from './dashboard-page.config.default';
import {validateDashboardPageConfig} from './dashboard-page.config.validation';
import {UserRoleService} from '@basuiz/web-app-applet-sdk';
import {WEB_APP_EXPANDED_FEATURE_TOGGLE} from '@basuiz/web-app-feature-toggle';

// IMPORTANT: do not modify this file

export const ɵDASHBOARD_PAGE_CONFIG = new InjectionToken<DashboardPageConfig>(
  'bsz.web-app.portal.dashboard-page-config',
  {
    factory: () => inject(DASHBOARD_PAGE_CONFIG_DEFAULT),
  }
);

// IMPORTANT: do not modify this file

export const DASHBOARD_PAGE_CONFIG_DEFAULT = new InjectionToken<DashboardPageConfig>(
  'bsz.web-app.portal.dashboard-page-config.default',
  {
    factory: () => {
      const config = getDefaultConfig(inject(UserRoleService), inject(WEB_APP_EXPANDED_FEATURE_TOGGLE));
      const error = validateDashboardPageConfig(config);
      if (error) {
        console.error('Default DashboardPageConfig validation failed:', error);
      }
      return config;
    },
  }
);

// IMPORTANT: do not modify this file

export function dashboardPageConfigProvider({
  useFactory,
  deps,
}: BszConfigFactoryWithDeps<DashboardPageConfig>): FactoryProvider {
  return {
    provide: ɵDASHBOARD_PAGE_CONFIG,
    useFactory: factoryWithValidation(useFactory),
    deps: [DASHBOARD_PAGE_CONFIG_DEFAULT, ...(deps || [])],
  };
}

// IMPORTANT: do not modify this file

function factoryWithValidation(factory: BszConfigFactory<DashboardPageConfig>): BszConfigFactory<DashboardPageConfig> {
  return (defaultConfig: DashboardPageConfig, ...deps: unknown[]) => {
    const config = factory(defaultConfig, ...deps);
    const error = validateDashboardPageConfig(config);
    if (error) {
      console.error('DashboardPageConfig validation failed:', error);
    }
    return config;
  };
}

// IMPORTANT: do not modify this file
