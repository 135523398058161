import {InjectionToken} from '@angular/core';
import {BszFactoryProviderGenerator, LocaleId} from '@basuiz/web-app-applet-api';

/** A collection of currency amount formatters.
 * If no custom formatter is provided for a given locale, the default formatting of web-app will be applied */
export type CurrencyAmountFormatters = Record<LocaleId, CurrencyAmountFormatter>;

/** A formatter for currency amounts. E.g. (100, 'CHF') => 'CHF 100'.
 * If available, web-app libraries will invoke the provided method instead of the default logic.
 *
 * @param amount: the amount to be formatted, e.g. 100
 * @param currencyIsoCode: ISO code of the currency, e.g. 'CHF'
 * @param options: additional parameters
 * */
export type CurrencyAmountFormatter = (
  amount: number,
  currencyIsoCode: string,
  options: CurrencyAmountFormatterOptions
) => string;

/** Additional parameters passed to the CurrencyFormatter */
export interface CurrencyAmountFormatterOptions {
  /** String following the same 'digits info' pattern from Angular */
  digitsInfo?: string;
  /** Allows the caller of the formatter to specify whether it expects the ISO code,
   * or an alternative identifier of the currency the custom formatter might decide to use,
   * to be present or not in the formatted string or not.
   * Some very specific views designed to display only the numeric part of the amount will set this flag to true. */
  showIsoCode: boolean;
}

export const ɵCURRENCY_AMOUNT_FORMATTERS = new InjectionToken<CurrencyAmountFormatters>(
  'bsz.web-app-common.formatting.currency-amount-formatters',
  {
    factory: () => ({}),
  }
);

export const provideCurrencyAmountFormatters: BszFactoryProviderGenerator<CurrencyAmountFormatters> = (
  useFactory,
  deps
) => ({
  provide: ɵCURRENCY_AMOUNT_FORMATTERS,
  useFactory,
  deps,
});
