import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {BszFlatSelectionModel, SelectionState, TreeSelectionChange, TreeSelectionModel} from '../bsz-selectable/index';
import {BszTableTreeRow} from './bsz-table.definitions';

const LOG_PREFIX = '[bsz-table/tree-selection-model]';

/**
 * Selection model for the nested version of BszTable.
 *
 * Note: While BszTableSelectionModel uses the original data items as items, the BszTreeTableSelectionModel
 * uses BszTableTreeRow, because that's what the nested table internally uses as items.
 */
export class BszTableTreeSelectionModel implements TreeSelectionModel<BszTableTreeRow, BszTableTreeRow> {
  private selectionModel = new BszFlatSelectionModel<BszTableTreeRow>();

  readonly selectionChange: Observable<BszTableTreeSelectionChange> = this.selectionModel.selectionChange.pipe(
    map((selectedChange) => ({
      ...selectedChange,
      selectedGroupMap: this.getSelectedGroupMap(),
    }))
  );

  private topLevelGroups: BszTableTreeRow[] = [];

  setData(data: BszTableTreeRow[]) {
    this.topLevelGroups = data.filter((item) => !item._nestingLevel);

    const leaves = data.filter((row) => !row._isGroupHeader);

    // Get the array of currently selected original rows, so we can keep the selection for the new data,
    // if it would contain the same original rows.
    const previousSelectedOriginalRows = this.getSelectedItems().map((row) => row._originalRow);

    // Store only the leaves (the group selection state will only be calculated for selection state change event).
    this.selectionModel.setData(leaves);

    // Set new selected rows.
    const selectedRows = leaves.filter((row) => previousSelectedOriginalRows.includes(row._originalRow));
    this.selectionModel.setSelectedItems(selectedRows);
  }

  setSelectedItems(selectedItems: BszTableTreeRow[]) {
    selectedItems.forEach((item) => this.checkIfItem(item));
    return this.selectionModel.setSelectedItems(selectedItems);
  }

  setItemSelected(selected: boolean, ...items: BszTableTreeRow[]) {
    items.forEach((item) => this.checkIfItem(item));
    return this.selectionModel.setItemSelected(selected, ...items);
  }

  setAllSelected(selected: boolean) {
    return this.selectionModel.setAllSelected(selected);
  }

  isItemSelected(item: BszTableTreeRow) {
    this.checkIfItem(item);
    return this.selectionModel.isItemSelected(item);
  }

  getSelectedItems() {
    return this.selectionModel.getSelectedItems();
  }

  setGroupSelected(selected: boolean, group: BszTableTreeRow) {
    this.checkIfGroup(group);
    const leaves = this.findLeafItems(group);

    this.selectionModel.setItemSelected(selected, ...leaves);
  }

  private getSelectedGroupMap(): Map<BszTableTreeRow, SelectionState> {
    const selectedGroupMap = new Map<BszTableTreeRow, SelectionState>();

    // Start calculating group selected state at top-level groups.
    for (const item of this.topLevelGroups) {
      this.fillGroupSelectedState(selectedGroupMap, item);
    }

    return selectedGroupMap;
  }

  /** Calculate the selected state of a single group based on the leaf items, save and return the new state. */
  private fillGroupSelectedState(
    selectedGroupMap: Map<BszTableTreeRow, SelectionState>,
    group: BszTableTreeRow
  ): SelectionState {
    const childSelectionStates: SelectionState[] = [];
    for (const child of group._children) {
      if (child._isGroupHeader) {
        childSelectionStates.push(this.fillGroupSelectedState(selectedGroupMap, child));
      } else {
        childSelectionStates.push(this.selectionModel.isItemSelected(child));
      }
    }

    const selectionState = this.getSelectionStateFromChildren(childSelectionStates);
    selectedGroupMap.set(group, selectionState);

    return selectionState;
  }

  private getSelectionStateFromChildren(childSelectionStates: SelectionState[]): SelectionState {
    if (childSelectionStates.every((selectionState) => selectionState === false)) {
      return false;
    }
    if (childSelectionStates.every((selectionState) => selectionState === true)) {
      return true;
    }
    return null;
  }

  private checkIfItem(item: BszTableTreeRow) {
    if (item._isGroupHeader) {
      throw new Error(`${LOG_PREFIX} This function is only for leaf items.`);
    }
  }

  private checkIfGroup(group: BszTableTreeRow) {
    if (!group._isGroupHeader) {
      throw new Error(`${LOG_PREFIX} This function is only for group items.`);
    }
  }

  /** Find all leaf items (items that are not group headers) for the specified group. */
  private findLeafItems(tableTreeItem: BszTableTreeRow): BszTableTreeRow[] {
    const children = [];
    for (const child of tableTreeItem._children) {
      if (child._isGroupHeader) {
        children.push(...this.findLeafItems(child));
      } else {
        children.push(child);
      }
    }
    return children;
  }
}

export type BszTableTreeSelectionChange = TreeSelectionChange<BszTableTreeRow, BszTableTreeRow>;
