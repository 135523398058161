import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, FEATURE_KEY, State} from './business-unit.reducer';
import {LoadingState} from '../../../definitions/call-state.definition';
import {getErrorMessage} from '../../../utils/error-state';

export const selectState = createFeatureSelector<State>(FEATURE_KEY);

const adapterSelectors = adapter.getSelectors();

export const selectLoaded = createSelector(selectState, (state: State) => state.callState === LoadingState.LOADED);

export const selectLoading = createSelector(selectState, (state: State) => state.callState === LoadingState.LOADING);

export const selectError = createSelector(selectState, (state: State) => getErrorMessage(state.callState));

export const selectAll = createSelector(selectState, adapterSelectors.selectAll);

export const selectEntities = createSelector(selectState, adapterSelectors.selectEntities);
