import {map, mapTo, switchMap, take} from 'rxjs/operators';
import {WebAppAppletDirective} from '../web-app-applet/web-app-applet.directive';
import {AppletContextCacheService} from './applet-context-cache.service';
import {AppletContextRequest} from './applet-context.definitions';
import {BehaviorSubject, Observable} from 'rxjs';
import {filterDefined, isDefined} from '../utils/defined.util';

/* This class is to be extended by applet services or components
 * that inject a service extending AppletContextCacheService.
 * The service/component extending this class should NOT be a singleton,
 * i.e. an instance of this service/component should created per applet-instance.
 *
 * This class takes care of retrieving the appropriate applet-context based on the request obtained
 * from the WebAppAppletDirective.
 * */
export abstract class AppletContextCacheBridge<BANKLET_CONTEXT extends Record<string, any>> {
  private appletContextIdSubject = new BehaviorSubject<AppletContextRequest['appletContextId'] | undefined>(undefined);

  private isAppletContextCacheBridgeInitialized: boolean = false;

  protected constructor(
    protected readonly appletDirective: WebAppAppletDirective,
    protected readonly appletContextCacheService: AppletContextCacheService<BANKLET_CONTEXT>
  ) {}

  /* Initializes the applet-context cache bridge by retrieving a applet-context request from the applet-directive
   * and using it to retrieve the appropriate applet-context from the cache service.
   * @returns observable that emits a single time when the bridge has finished initializing.
   */
  protected initializeAppletContextCacheBridge(): Observable<void> {
    if (this.isAppletContextCacheBridgeInitialized) {
      throw new Error('AppletContextCacheBridge should be initialized only once');
    }
    this.isAppletContextCacheBridgeInitialized = true;

    this.appletDirective.appletContext$
      .pipe(
        take(1), // ignore changes to the applet-request input, a applet instance should not change between different contexts in its lifespan
        switchMap((appletContextRequest) =>
          this.appletContextCacheService
            .initializeContext(appletContextRequest, this.getInitialContext$())
            .pipe(mapTo(appletContextRequest.appletContextId))
        ),
        take(1) // make sure to terminate this one-time subscription
      )
      .subscribe((appletContextId) => this.appletContextIdSubject.next(appletContextId));
    return this.appletContextIdSubject.pipe(filterDefined(), mapTo(undefined), take(1));
  }

  /* Returns an observable that indicates whether the applet-context has been already initialized or not.
   * The bridge will not emit any values before the applet-context is initialized. Moreover, attempts to
   * retrieve an snapshot or update the applet-context before the applet context is initialized will throw an error
   */
  protected getAppletContextInitialized$(): Observable<boolean> {
    return this.appletContextIdSubject.pipe(map(isDefined));
  }

  /* Returns an observable for a single property of the applet-context
   * Applets should not keep their own working copy of the applet-context, all access to information stored in the
   * context must be via this observable or, in punctual cases, via method getAppletContextSnapshot.
   * Updates to the applet-context must be done by calling method updateAppletContext that will trigger a new
   * emission of this observable with the updated property.
   */
  protected getAppletContextProperty$<PROP extends keyof BANKLET_CONTEXT>(
    contextProperty: PROP
  ): Observable<BANKLET_CONTEXT[PROP]> {
    return this.appletContextIdSubject.pipe(
      filterDefined(),
      switchMap((appletContextId) =>
        this.appletContextCacheService.getAppletContextProperty$(contextProperty, appletContextId)
      )
    );
  }

  /* Returns an snapshot with the current value of the applet-context, i.e. a one-time read access.
   * This method should be used in punctual use-cases, the default access to the applet-context should
   * be done via method getAppletContextProperty$.
   * */
  protected getAppletContextSnapshot(): BANKLET_CONTEXT {
    const appletContextId = this.appletContextIdSubject.value;
    if (!isDefined(appletContextId)) {
      throw new Error(`Cannot get applet-context snapshot before receiving a applet-context request`);
    }
    return this.appletContextCacheService.getAppletContextSnapshot(appletContextId);
  }

  /* Method called when a new applet-context needs to be created.
   * @return an observable from whose only the first value emitted is used.
   * This value might be a default applet-context or,
   * if the applet has inputs that are required to calculate the applet-context,
   * a context generated taking into account the initial value of these inputs */
  protected abstract getInitialContext$(): Observable<Required<BANKLET_CONTEXT>>;

  /* Updates the applet-context by overriding the cached context with a delta passed as argument.
   * Every time the applet-context is updated calling this method, the observable returned by getAppletContext$
   * will emit a new value equivalent to the result of updating the context.
   * Note: this method cannot be called before the applet-context request has been resolved and therefore
   * a applet-context is available.
   * */
  protected updateAppletContext(delta: Partial<BANKLET_CONTEXT>): void {
    const appletContextId = this.appletContextIdSubject.value;
    if (!isDefined(appletContextId)) {
      throw new Error(`Cannot update applet-context before receiving a applet-context request`);
    }
    this.appletContextCacheService.updateAppletContext(appletContextId, delta);
  }
}
