import {Injectable, OnDestroy} from '@angular/core';
import {WebAppCommonFacade} from '../base-data/web-app-common.facade';
import moment, {Moment} from 'moment';
import {Subscription} from 'rxjs';
import {AbstractControl, ValidatorFn} from '@angular/forms';
import {DateFilterFn} from '@angular/material/datepicker';
import {ISODate} from '../definitions/iso-date.definition';

@Injectable({
  providedIn: 'root',
})
export class WorkDayValidatorService implements OnDestroy {
  public workDayDatePickerFilter: DateFilterFn<Moment | null> = this._workDayMatDatePickerFilter.bind(this);
  public workDayValidatorFn: ValidatorFn = this._holidayValidatorFn.bind(this);

  private holidayMap: Record<ISODate, true> = {};
  private subscription: Subscription;

  constructor(private webAppCommonFacade: WebAppCommonFacade) {
    this.subscription = this._setHolidaysMap();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private _holidayValidatorFn(control: AbstractControl) {
    if (control?.value && moment(control.value).isValid()) {
      const isHoliday: boolean = !this.workDayDatePickerFilter(moment(control.value).startOf('day'));
      return isHoliday ? {holiday: true} : null;
    }
    return null;
  }

  private _workDayMatDatePickerFilter(value: Moment | null): boolean {
    if (!value) {
      return true;
    }
    if (this._isWorkDay(value)) {
      return this._matDatePickerFilterHolidays(value);
    }
    return false;
  }

  private _matDatePickerFilterHolidays(value: Moment | null): boolean {
    if (!value) {
      return true;
    }
    const valueDate = moment(value).format('YYYY-MM-DD');
    return !this.holidayMap[valueDate];
  }

  private _isWorkDay(date: Moment): boolean {
    return date && date.day() >= 1 && date.day() <= 5;
  }

  private _setHolidaysMap(): Subscription {
    return this.webAppCommonFacade.holidayList$.subscribe((holidays) => {
      this.holidayMap = holidays.reduce((holidayMap, holiday) => {
        holidayMap[holiday as ISODate] = true;
        return holidayMap;
      }, {} as Record<ISODate, true>);
    });
  }
}
