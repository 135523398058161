import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {ChangeDetectorRef, Directive, EventEmitter, HostListener, Input, OnDestroy, Output} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

import {ExpandedGroupStatus} from '../shared';
import {BszTable} from './bsz-table';
import {BszTableDataSourceType, BszTableTreeRow, CollapsedState} from './bsz-table.definitions';
import {BszTableTreeDataSource, isTreeDataSource} from './bsz-table-tree-data-source';

const LOG_PREFIX = '[bsz-table-collapsible]';

@Directive({
  selector: 'bsz-table[bszCollapsible]',
  host: {
    class: 'bsz-table-collapsible',
  },
})
export class BszTableCollapsible<T, G> implements OnDestroy {
  /**
   * The collapsed state of the table, can be one of either:
   * - true - all collapsed
   * - false - all expanded
   * - null - partially collapsed
   *
   * default: false all expanded
   */
  @Input()
  get collapsed() {
    return this._collapsed;
  }
  set collapsed(isCollapsed: CollapsedState) {
    isCollapsed = isCollapsed !== null ? coerceBooleanProperty(isCollapsed) : null;

    // ignore if the value didn't change (our internal state is already up-to-date)
    if (isCollapsed === this._collapsed) {
      return;
    }

    // value of collapsed cannot be set directly to null, since that doesn't make sense
    // (e.g. what should we do with the table if it should change to "partially collapsed"?)
    if (isCollapsed === null) {
      throw new Error(`${LOG_PREFIX} collapsed cannot be changed to null directly`);
    }

    this.setLatestInputCollapsed(isCollapsed);
  }

  /**
   * filterPredicate to be used in the dataSource for identifying
   * the rows that should switch to expanded when they are collapsed
   */
  @Input() expandFn: ((row: T | G | BszTableTreeRow) => boolean) | null = null;

  /**
   * Array with the id of the groups (groupId) that should be expanded.
   * Anny other group will be collapsed
   */
  @Input() set expandedGroups(expanded: (string | ExpandedGroupStatus)[] | null | undefined) {
    if (!expanded) {
      return;
    }
    this._expandedGroups = expanded;
    this.setExpandedGroups();
  }

  // set two-way binding to the "expandedGroups" input
  @Output() expandedGroupsChange = new EventEmitter<(string | ExpandedGroupStatus)[]>();

  /**
   * Event emitted when the collapsed property changes.
   *
   * This is async EventEmitter to prevent ExpressionChangedAfterItHasBeenCheckedError error
   * in cases where two-way binding of "collapsed" is used. For example, when a new data source is set,
   * the value of "collapsed" will be updated to the latest value specified from the outside,
   * which may differ from the current value, which without async EventEmitter could cause the error above.
   */
  @Output() collapsedChange = new EventEmitter<CollapsedState>(true);

  @HostListener('groupHeaderClick', ['$event']) onGroupHeaderClick(row: BszTableTreeRow) {
    this.toggleGroup(row);
  }

  /**
   * The latest value of "collapsed" as received from the outside of the directive.
   * This is to be used when new data source is received. We have to keep a separate copy of the value
   * because the internal value of _collapsed changes over time (e.g. when the user expands/collapses
   * some group headers).
   */
  private latestInputCollapsed = false;

  /** The current value of "collapsed". */
  private _collapsed: CollapsedState = false;

  private destroy = new Subject<void>();

  private hostTableDataSource: BszTableTreeDataSource<T, G> | null = null;

  private _expandedGroups: (string | ExpandedGroupStatus)[] | null = null;

  constructor(private readonly changeDetector: ChangeDetectorRef) {}

  connect(bszTable: BszTable<T, G>): void {
    bszTable.dataSourceUpdate
      .pipe(
        takeUntil(this.destroy),
        map((dataSource) => this.checkDataSource(dataSource))
      )
      .subscribe({
        next: (newDataSource) => this.updateDataSource(newDataSource),
        error: (error: Error) => console.error(error.message),
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  /**
   * Update value of the MatTableDataSource.filter property that will then be used to the
   * custom function MatTableDataSource.filterPredicate in order to do the filtering
   * and add/removed columns that are expanded/collapsed
   */
  toggleGroup(row: BszTableTreeRow): void {
    if (!this.hostTableDataSource) {
      return;
    }

    this.hostTableDataSource.toggleGroup(row);

    // toggling a group may change the collapsed state (e.g. when fully expanded, collapsing one group header
    // would change the collapsed state to partially collapsed), so we'll update the collapsed state
    this.setCollapsed(this.hostTableDataSource.getAllCollapsedState());

    this.expandedGroupsChange.emit(this.getExpandedGroups());
  }

  private setLatestInputCollapsed(isCollapsed: boolean) {
    this.latestInputCollapsed = isCollapsed;

    if (!this.hostTableDataSource) {
      return;
    }

    if (this._expandedGroups) {
      this.setExpandedGroups();
      return;
    }

    if (isCollapsed) {
      this.collapseTable(isCollapsed);
      return;
    }

    this.hostTableDataSource.expandAll();
    this.setCollapsed(isCollapsed);
  }

  private setCollapsed(isCollapsed: CollapsedState) {
    this._collapsed = isCollapsed;

    this.collapsedChange.emit(isCollapsed);
  }

  private checkDataSource(dataSource: BszTableDataSourceType<T, G>) {
    // Allow empty data sets.
    if (dataSource === null || (dataSource instanceof MatTableDataSource && dataSource.data.length === 0)) {
      return null;
    }

    if (isTreeDataSource(dataSource)) {
      return dataSource;
    }

    throw new Error(`${LOG_PREFIX} bszCollapsible directive can be used only with TreeDataSource`);
  }

  /** Keep the dataSource connected on every update */
  private updateDataSource(dataSource: BszTableTreeDataSource<T, G> | null): void {
    this.hostTableDataSource = dataSource;

    // re-apply the last collapsed value we got from the outside
    this.setLatestInputCollapsed(this.latestInputCollapsed);
  }

  private setExpandedGroups() {
    const hostTableDataSource = this.hostTableDataSource;
    if (!hostTableDataSource) {
      return;
    }
    hostTableDataSource.collapseAll();
    if (this._expandedGroups) {
      hostTableDataSource._expandGroups(this._expandedGroups);
    }
    this.setCollapsed(this.latestInputCollapsed);
    setTimeout(() => {
      this.changeDetector.markForCheck();
    });
  }

  private collapseTable(isCollapsed: boolean) {
    const hostTableDataSource = this.hostTableDataSource;
    if (!hostTableDataSource) {
      return;
    }
    hostTableDataSource.collapseAll();

    if (this.expandFn) {
      hostTableDataSource.expandRows(this.expandFn);
    }
    this.setCollapsed(isCollapsed);
  }

  private getExpandedGroups(): (string | ExpandedGroupStatus)[] {
    if (!this.hostTableDataSource) {
      return [];
    }

    const expandedGroups: (string | ExpandedGroupStatus)[] = [];
    this.hostTableDataSource.data.forEach((row: BszTableTreeRow) => {
      if (row._groupId && !row._collapsed) {
        expandedGroups.push({
          groupId: row._groupId,
          expandDescendants: false,
          expandAncestors: false,
        });
      }
    });

    return expandedGroups;
  }
}
