import {Component, TemplateRef, ViewChild} from '@angular/core';

@Component({
  selector: 'bsz-swipeable-carousel-slide',
  template: '<ng-template><ng-content></ng-content></ng-template>',
})
export class BszSwipeableCarouselSlideComponent {
  @ViewChild(TemplateRef, {static: true})
  templateRef: TemplateRef<any>;
}
