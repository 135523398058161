import {AfterViewInit, ChangeDetectionStrategy, Component, Injector, Input, OnDestroy, ViewChild} from '@angular/core';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppShellOutletCategory, getTokenForCategory} from '../index';
import {CdkPortal} from '@angular/cdk/portal';
import {WebAppAppletService} from '../../web-app-applet/index';
import {AppShellOutletBroker} from '../app-shell-outlet-broker';
import {AppShellOutletEmitter} from '../app-shell-outlet-emitter.definition';

@Component({
  selector: 'bsz-app-shell-outlet-emitter',
  templateUrl: './app-shell-outlet-emitter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppShellOutletEmitterComponent<CATEGORY extends AppShellOutletCategory>
  implements AfterViewInit, OnDestroy
{
  @Input()
  category: AppShellOutletCategory;

  @ViewChild(CdkPortal)
  cdkPortal: CdkPortal;

  private readonly isProjectingSubject = new ReplaySubject<boolean>(1);

  /* Use in the ngContent to change UI based on the content being projected or not */
  readonly isProjecting$: Observable<boolean> = this.isProjectingSubject.asObservable();
  /* Use in the ngContent to change UI based on the content being projected or not */
  readonly isNotProjecting$: Observable<boolean> = this.isProjecting$.pipe(map((v) => !v));

  private outletReleaseHandler: (() => void) | undefined = undefined;
  private readonly subscriptions = new Subscription();

  readonly revealInfoBox$: Observable<boolean> = this.webAppAppletService.getRevealBoundaryAndName();
  readonly infoBoxMessage$: Observable<string> = this.isProjecting$.pipe(
    map((isProjecting) =>
      // Text for developers available via dev-tools, does not need translation
      isProjecting
        ? `Content projected through '${this.category}' app-shell outlet`
        : `Content available for projection via '${this.category}' app-shell outlet`
    )
  );

  constructor(private readonly injector: Injector, private readonly webAppAppletService: WebAppAppletService) {}

  ngAfterViewInit(): void {
    if (!this.category) {
      throw new Error(`Input 'category' must be specified when instantiating AppShellOutletEmitterComponent`);
      return;
    }
    const brokerResponse = this.emitter.requestProjection({
      content: this.cdkPortal,
    });
    if (brokerResponse.result === 'denied') {
      this.notifyProjectingStatus(false);
    } else {
      this.notifyProjectingStatus(true);
      this.outletReleaseHandler = brokerResponse.releaseOutlet;
      this.subscriptions.add(
        brokerResponse.projectionRevoked$.subscribe(() => {
          this.notifyProjectingStatus(false);
        })
      );
    }
  }

  private notifyProjectingStatus(isProjecting: boolean): void {
    setTimeout(() => {
      this.isProjectingSubject.next(isProjecting);
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    if (this.outletReleaseHandler) {
      this.outletReleaseHandler();
    }
  }

  private get emitter(): AppShellOutletEmitter {
    const broker: AppShellOutletBroker<any> = this.injector.get(getTokenForCategory(this.category));
    return broker.asEmitter;
  }
}
