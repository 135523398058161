import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {BszProgressBarComponent} from './bsz-progress-bar.component';
import {ProgressIndicatorDirective} from './progress-indicator.directive';

@NgModule({
  declarations: [BszProgressBarComponent, ProgressIndicatorDirective],
  imports: [CommonModule, MatProgressBarModule, TranslateModule],
  exports: [BszProgressBarComponent, ProgressIndicatorDirective],
})
export class BszProgressBarModule {}
