import {BszConfigFactoryWithDeps} from '@basuiz/web-app-applet-api';
import {UserSettingsPageConfig} from '@basuiz/web-app-portal';

export const userSettingsPageConfigFactory: BszConfigFactoryWithDeps<UserSettingsPageConfig> = {
  useFactory: function (defaultConfig: UserSettingsPageConfig): UserSettingsPageConfig {
    return {
      ...defaultConfig,
      psd2DataSharingConsentLink: undefined,
    };
  },
};
