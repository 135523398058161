import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AfpRestOperators, AfpRestResponse} from '../../afp-rest/index';
import {BusinessUnit} from '../models/business-unit.definition';

@Injectable({providedIn: 'root'})
export class BusinessUnitDataService {
  constructor(private httpClient: HttpClient) {}

  private url: string = '/com.basuiz.afs.rest.services/rest/baseBankData/businessUnitList';

  public fetch(): Observable<BusinessUnit[]> {
    return this.httpClient.get<AfpRestResponse<BusinessUnit[]>>(this.url).pipe(AfpRestOperators.extractPayload());
  }
}
