import {DebugElement} from '@angular/core';
import {MatMenu, MatMenuItem} from '@angular/material/menu';

import {JestFixtureFacade, JestFixtureFacadeAware} from './jest-fixture-facade';

export class JestMatMenu implements JestFixtureFacadeAware {
  constructor(public testId: string, public jestFixtureFacade: JestFixtureFacade) {}

  get exists(): boolean {
    return !!this.matMenuDirectiveDebugElement;
  }

  get labels(): string[] {
    return this.items.map((i) => i.getLabel());
  }

  private get items(): MatMenuItem[] {
    return this.matMenu._allItems.toArray();
  }

  private get matMenu(): MatMenu {
    return this.matMenuDirectiveDebugElement.injector.get<MatMenu>(MatMenu);
  }

  private get matMenuDirectiveDebugElement(): DebugElement {
    return this.jestFixtureFacade.debugElement.query((element) => {
      const matches = element.nativeElement.getAttribute('test-id') === this.testId;
      if (matches && element.nativeElement.tagName !== 'MAT-MENU') {
        throw new Error('A test id for a MatMenu must be placed on the <mat-menu> element');
      }
      return matches;
    });
  }
}
