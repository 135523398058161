import {Injectable} from '@angular/core';
import {HybridActionService} from '@basuiz/web-app-hybrid';

/**
 * Service that opens external links safely.
 */
@Injectable({
  providedIn: 'root',
})
export class ExternalLinkService {
  constructor(private hybridActionService: HybridActionService) {}

  /**
   * Open the specified link in a new browser window or tab, dependent on browser configuration.
   *
   * Hybrid aware; if the web app is embedded in a native mobile app then the native app will be asked to open the
   * external link. Refer to the hybrid documentation for more information about the HybridActionService that provides
   * this functionality.
   *
   * Only URLs specifying the HTTPS scheme will be opened. The service will not navigate the user to an insecure
   * destination.
   *
   * @param httpsUrl to open outside the app, must be secure (HTTPS)
   * @returns a `Promise` indicating whether or not the link was opened.
   */
  async open(httpsUrl: string): Promise<boolean> {
    return this.hybridActionService.openExternalLink(httpsUrl).then((result) => result === 'triggered');
  }
}
