/* IMPORTANT! This file is updated by the 'portal-page-config' schematic.
 * When doing structural changes in this file the schematic need to be updated accordingly.
 * */
export {PortalPageConfigFactoriesWithDeps, PortalPageConfigs} from './all-page-config-providers';
export * from './dashboard/dashboard-page.config.definition';
export {ɵDASHBOARD_PAGE_CONFIG} from './dashboard/dashboard-page.config.provider';
export * from './collaboration-overview/collaboration-overview-page.config.definition';
export {ɵCOLLABORATION_OVERVIEW_PAGE_CONFIG} from './collaboration-overview/collaboration-overview-page.config.provider';

export * from './user-settings/user-settings-page.config.definition';
export {ɵUSER_SETTINGS_PAGE_CONFIG} from './user-settings/user-settings-page.config.provider';

export * from './collaboration-detail/collaboration-detail-page.config.definition';
export {ɵCOLLABORATION_DETAIL_PAGE_CONFIG} from './collaboration-detail/collaboration-detail-page.config.provider';

export * from './payment-cards/payment-cards-page.config.definition';
export {ɵPAYMENT_CARDS_PAGE_CONFIG} from './payment-cards/payment-cards-page.config.provider';
