import {ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {
  AlertListAppletConfig,
  NotificationActionOption,
  ɵALERT_LIST_BANKLET_CONFIG,
} from '@basuiz/web-app-alert-list-applet/config';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {TranslationKey} from '@basuiz/web-app-applet-api';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';

export interface NotificationAction {
  id: NotificationActionOption;
  label: TranslationKey;
  callback: () => void;
  isDisabled$: Observable<boolean>;
}

@Component({
  selector: 'bsz-notification-list-actions',
  templateUrl: './notification-list-actions.component.html',
  styleUrls: ['./notification-list-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationListActionsComponent implements OnInit {
  @Input()
  set hasNewNotifications(hasNewNotifications: boolean) {
    this.hasNewNotifications$.next(hasNewNotifications);
  }

  readonly hasNewNotifications$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Output()
  showAllNotifications: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  markAllNotificationsAsRead: EventEmitter<void> = new EventEmitter<void>();

  readonly bszTestIdPrefix = 'web-app-alert-list-applet.notification-list-actions';

  notificationActions: NotificationAction[];

  constructor(@Inject(ɵALERT_LIST_BANKLET_CONFIG) public appletConfig: AlertListAppletConfig) {}

  ngOnInit(): void {
    const actionsMap: Record<NotificationActionOption, NotificationAction> = {
      viewAll: {
        id: 'viewAll',
        label: asTranslationKey('web-app-alert-list-applet.view-all'),
        callback: () => {
          this.showAllNotifications.emit();
        },
        isDisabled$: of(false),
      },
      markAllAsRead: {
        id: 'markAllAsRead',
        label: asTranslationKey('web-app-alert-list-applet.mark-all-as-read'),
        callback: () => {
          this.markAllNotificationsAsRead.emit();
        },
        isDisabled$: this.hasNewNotifications$.pipe(map((hasNewNotifications) => !hasNewNotifications)),
      },
    };
    this.notificationActions = this.appletConfig.notificationActions.options.map((option) => actionsMap[option]);
  }
}
