import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, filter, mergeMap, map, tap, take} from 'rxjs/operators';
import {of} from 'rxjs';
import {
  deleteSavedFilterFailed,
  deleteSavedFilters,
  deleteSavedFilterSuccess,
  loadSavedFilterList,
  loadSavedFilterListFailed,
  loadSavedFilterListRequired,
  loadSavedFilterListSuccess,
  saveFilter,
  saveFilterFailed,
  saveFilterSuccess,
} from './saved-filter.actions';
import {SavedFiltersDataService} from '../data-access/saved-filters-data.service';
import {SavedFilter} from '../models/saved-filter.interface';
import {getErrorState} from '../../utils/error-state';
import {LoadingState} from '../../definitions/call-state.definition';
import {SavedFilterFacade} from './saved-filter.facade';
import {NotificationService} from '../../ui-notification/notification.service';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';

@Injectable()
export class SavedFilterEffects {
  loadSavedFilterList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSavedFilterList),
      mergeMap((action) =>
        this.savedFilterFacade.getSavedFilterCallStateByType$(action.savedFilterKey).pipe(
          take(1),
          filter((callState) => callState !== LoadingState.LOADING && callState !== LoadingState.LOADED),
          map(() => loadSavedFilterListRequired({savedFilterKey: action.savedFilterKey}))
        )
      )
    )
  );

  loadSavedFilterListRequired$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSavedFilterListRequired),
      mergeMap((action) =>
        this.savedFilterDataService.fetchFilterList(action.savedFilterKey).pipe(
          map((savedFilterList: SavedFilter[]) =>
            loadSavedFilterListSuccess({
              savedFilterList,
              savedFilterKey: action.savedFilterKey,
            })
          ),
          catchError((error) =>
            of(
              loadSavedFilterListFailed({
                savedFilterKey: action.savedFilterKey,
                errorState: getErrorState(error),
              })
            )
          )
        )
      )
    )
  );

  saveSavedFilterList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveFilter),
      mergeMap((action) =>
        this.savedFilterDataService.saveFilter(action.filterToSave).pipe(
          map(() => saveFilterSuccess({savedFilter: action.filterToSave})),
          catchError((error) => of(saveFilterFailed(error)))
        )
      )
    )
  );

  savedFilterSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveFilterSuccess),
      tap(() => {
        this.notificationService.success(
          asTranslationKey('web-app-common.filter-favourites.notification.saved-success')
        );
      }),
      map((action) => loadSavedFilterListRequired({savedFilterKey: action.savedFilter.type}))
    )
  );

  deleteFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSavedFilters),
      mergeMap((action) =>
        this.savedFilterDataService.deleteFilter([action.savedFilter.id]).pipe(
          tap(() => {
            this.notificationService.success(
              asTranslationKey('web-app-common.filter-favourites.notification.delete-success')
            );
          }),
          map(() =>
            deleteSavedFilterSuccess({
              names: [action.savedFilter.name],
              savedFilterKey: action.savedFilterKey,
            })
          ),
          catchError((error) => of(deleteSavedFilterFailed(error)))
        )
      )
    )
  );

  onSaveError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveFilterFailed),
        tap(() => {
          this.notificationService.error(asTranslationKey('web-app-common.filter-favourites.notification.save-error'));
        })
      ),
    {dispatch: false}
  );

  onDeleteError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteSavedFilterFailed),
        tap(() => {
          this.notificationService.error(
            asTranslationKey('web-app-common.filter-favourites.notification.delete-error')
          );
        })
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private savedFilterDataService: SavedFiltersDataService,
    private notificationService: NotificationService,
    private savedFilterFacade: SavedFilterFacade
  ) {}
}
