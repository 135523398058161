import {ActionWithoutResponse} from '@basuiz/mobile-bridge';

/**
 * Opens a link in external browser (outside of the mobile app).
 */
export class OpenExternalLinkAction extends ActionWithoutResponse<OpenExternalLinkRequest> {
  readonly name = 'openExternalLink';
}

export interface OpenExternalLinkRequest {
  /**
   * Absolute URL that is going to be opened by an external browser. Make sure
   * that the URL has HTTPS scheme (HTTP is considered insecure).
   */
  url: string;
}
