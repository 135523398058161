import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Data, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {NavigationService} from './navigation.service';

@Injectable({
  providedIn: 'root',
})
export class InitialNavigationRedirect implements CanActivate {
  constructor(private router: Router, private navigationService: NavigationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): true | UrlTree {
    if (this.navigationService.isAppHandlingFirstNavigation === true) {
      const routeData: Data = InitialNavigationRedirect.getRouteDataOrFail(route);
      const newRoute: string = InitialNavigationRedirect.getNewRouteOrFail(routeData);
      return this.router.parseUrl(newRoute);
    } else {
      return true;
    }
  }

  private static getRouteDataOrFail(route: ActivatedRouteSnapshot): Data {
    if (!route.data) {
      throw new Error(`Guard ${InitialNavigationRedirect.name} can only be used in routes with data.`);
    }
    return route.data;
  }

  private static getNewRouteOrFail(routeData: Data): string {
    if (!routeData.onInitialNavigationRedirectTo) {
      throw new Error(
        `Route using guard InitialNavigationRedirect must have property 'onInitialNavigationRedirectTo' in the data object.`
      );
    } else {
      if (typeof routeData.onInitialNavigationRedirectTo !== 'string') {
        throw new Error(`Route data property onInitialNavigationRedirectTo must be of type string`);
      } else {
        return routeData.onInitialNavigationRedirectTo;
      }
    }
  }
}
