import {NavigationIntent} from '../../navigation/classes/navigation-intent';
import {isObservable, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export class UINotificationDialogActionResolver {
  private subject: ReplaySubject<NavigationIntent> = new ReplaySubject(1);
  private unsubscribe: Subject<void> = new Subject();

  constructor(intentSource: (() => NavigationIntent) | Observable<NavigationIntent>) {
    (isObservable(intentSource) ? intentSource : of(intentSource())).pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (navigationIntent) => this.subject.next(navigationIntent),
      error: (error) => this.subject.error(error),
      complete: () => {
        this.subject.complete();
      },
    });
  }

  getIntent$(): Observable<NavigationIntent> {
    return this.subject.asObservable();
  }

  onDestroy(): void {
    this.subject.complete();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
