import {BooleanInput} from '@angular/cdk/coercion';
import {InjectionToken} from '@angular/core';

export interface BszModalBottomSheetGlobalConfig {
  hasBackdrop?: boolean;
  offsetTop?: number;
  maxWidth?: string;
  disableEscape?: BooleanInput;
  panelClass?: string | string[];
}

export interface BszModalBottomSheetConfig extends BszModalBottomSheetGlobalConfig {
  ariaLabel?: string;
  ariaLabelledby?: string;
  ariaDescribedby?: string;
  data?: any;
}

/**
 * Configuration for opening a modal with the BszModalBottomSheetService.
 */
export class BszModalBottomSheetDefaultConfig {
  hasBackdrop = true;
  offsetTop = 64;
  maxWidth = '1440px';
  ariaLabel = '';
  ariaLabelledby = '';
  ariaDescribedby = '';
  disableEscape: BooleanInput = false;
  panelClass = '';
}

/** Injection token that can be used to specify default configuration */
export const BSZ_MODAL_BOTTOM_SHEET_DEFAULT_OPTIONS = new InjectionToken<BszModalBottomSheetGlobalConfig>(
  'bsz_modal_bottom_sheet_default_options'
);
