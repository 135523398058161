/*
 * IMPORTANT! When adding a new view add a handler in the getViewHandlers function in the portal-hybrid lib
 */
export * from './lib/namespace';
export * from './lib/views/collaboration.view';
export * from './lib/views/dashboard.view';
export * from './lib/views/debit-card-list.view';
export * from './lib/views/deep-link.view';
export * from './lib/views/investment-proposal-list.view';
export * from './lib/views/notifications.view';
export * from './lib/views/user-settings.view';
