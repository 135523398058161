import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {NavigationService} from '../../../../navigation/navigation.service';
import {HistoryNavigationEntry} from '../../../../navigation/history-navigation-entry';

@Component({
  selector: 'bsz-history-back',
  templateUrl: './history-back.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryBackComponent {
  readonly historyNavigationEntry$: Observable<HistoryNavigationEntry> =
    this.navigationService.ɵhistoryNavigationEntries$.pipe(
      map((historyNavigationEntries: HistoryNavigationEntry[]) => {
        const indexOfPreviousHistoryNavigationEntry = historyNavigationEntries.length - 2;
        return historyNavigationEntries[indexOfPreviousHistoryNavigationEntry];
      })
    );

  constructor(private navigationService: NavigationService) {}

  navigateBack(): void {
    history.go(-1);
  }
}
