import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ScrollToTopComponent} from './scroll-to-top.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule],
  declarations: [ScrollToTopComponent],
  exports: [ScrollToTopComponent],
})
/* The reason for having this component in a separate module instead of the BszPortalPageLayoutModule
 * is that the latter is imported in every page.spec.ts.
 * Since the ScrollToTopModule injects the MatSidenavContent that is out of the page-layout, it would
 * require a sophisticated setup just to make the test run. While most likely the test of the page will have
 * little to do with the scroll-to-top functionality.
 * Instead, by separating the component in a different module, each page opting to use this component can
 * easily mock it.
 * */
export class ScrollToTopModule {}
