import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe, DatePipe} from '@angular/common';

import {BszCurrencyPipe} from './pipes/common/currency.pipe';
import {BszDatePipe} from './pipes/common/date.pipe';
import {BszIbanPipe} from './pipes/common/iban.pipe';
import {BszLinkPipe} from './pipes/common/link.pipe';
import {BszNumberPipe} from './pipes/common/number.pipe';
import {BszPercentagePipe} from './pipes/common/percentage.pipe';
import {BszPerformancePipe} from './pipes/common/performance.pipe';
import {BszStringPipe} from './pipes/common/string.pipe';

import {EmptyIfZeroPipe} from './pipes/empty-if-zero/empty-if-zero.pipe';
import {BszValueWithFormatPipe} from './pipes/value-with-format/value-with-format.pipe';
import {BszQuantityPipe} from './pipes/common/quantity.pipe';
import {BszCurrencyCompactPipe} from './pipes/common/currency-compact.pipe';

const angularPipes = [CurrencyPipe, DatePipe];

const bszPipes = [
  BszCurrencyPipe,
  BszCurrencyCompactPipe,
  BszDatePipe,
  BszIbanPipe,
  BszLinkPipe,
  BszNumberPipe,
  BszPercentagePipe,
  BszPerformancePipe,
  BszStringPipe,
  BszQuantityPipe,

  EmptyIfZeroPipe,
  BszValueWithFormatPipe,
];

@NgModule({
  declarations: [...bszPipes],
  imports: [CommonModule],
  exports: [...bszPipes],
  providers: [...bszPipes, ...angularPipes],
})
export class FormattingModule {}
