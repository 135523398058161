import {NavigationIntent} from '../../navigation/classes/navigation-intent';

export interface ShowAuthResetPasswordRequestIntentPayload {
  foo?: never;
}

export class ShowAuthResetPasswordRequestIntent extends NavigationIntent {
  constructor(public payload: ShowAuthResetPasswordRequestIntentPayload = {}) {
    super();
  }
}
