import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import {WebAppStepDirective} from './web-app-step.directive';
import {combineLatest, Observable, ReplaySubject} from 'rxjs';
import {map, shareReplay, startWith, switchMap, take} from 'rxjs/operators';
import {MatStepper} from '@angular/material/stepper';
import {STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent} from '@angular/cdk/stepper';
import {findIndex, findLastIndex} from 'lodash';
import {filterDefined} from '../utils/defined.util';
import {BszScreenSize} from '@basuiz/ui-elements';

@Component({
  selector: 'bsz-web-app-stepper',
  templateUrl: './web-app-stepper.component.html',
  styleUrls: ['./web-app-stepper.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {showError: true},
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebAppStepperComponent implements AfterViewInit {
  /**
   * Emitted when the user pressed next on the last step and the validation completed successfully.
   * The payload is the validation result.
   */
  @Output()
  completed: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Emitted when the user presses cancel.
   */
  @Output()
  cancelClick: EventEmitter<void> = new EventEmitter<void>();

  @ContentChildren(WebAppStepDirective)
  set _steps(value: QueryList<WebAppStepDirective>) {
    this.stepsSubject.next(value);
  }

  @ViewChild(MatStepper)
  private matStepper: MatStepper;

  private readonly stepsSubject = new ReplaySubject<QueryList<WebAppStepDirective>>(1);

  readonly steps$: Observable<WebAppStepDirective[]> = this.stepsSubject.pipe(
    switchMap((queryList) =>
      queryList.changes.pipe(
        startWith(queryList),
        map((list) => list.toArray())
      )
    ),
    shareReplay(1)
  );

  private readonly firstEnabledStepIndex$: Observable<number> = this.steps$.pipe(
    map((steps) => findIndex(steps, (step) => !step.disabled))
  );

  private readonly selectedStepIndexSubject = new ReplaySubject<number>(1);
  public readonly selectedStepIndex$: Observable<number> = this.selectedStepIndexSubject.asObservable();
  public readonly selectedStep$: Observable<WebAppStepDirective> = combineLatest([
    this.steps$,
    this.selectedStepIndex$,
  ]).pipe(
    map(([steps, stepIndex]) => steps[stepIndex]),
    filterDefined()
  );
  public readonly selectedStepId$: Observable<string> = this.selectedStep$.pipe(map((step) => step.stepId ?? ''));

  readonly showGoToPreviousStepButton$: Observable<boolean> = combineLatest([
    this.selectedStepIndex$,
    this.firstEnabledStepIndex$,
  ]).pipe(map(([selectedIndex, firstEnabledStepIndex]) => selectedIndex > firstEnabledStepIndex));

  readonly hasStepsWithSummary$: Observable<boolean> = this.steps$.pipe(
    map((steps) => steps.some((step) => !!step.summary))
  );
  readonly showStepSummaries$: Observable<boolean> = combineLatest([this.steps$, this.selectedStepIndex$]).pipe(
    map(([steps, selectedIndex]) => {
      return !steps[selectedIndex]?.confirmationStep;
    })
  );

  readonly bszScreenSize$ = this.bszScreenSize.getScreenSize();

  constructor(private readonly bszScreenSize: BszScreenSize) {}

  ngAfterViewInit() {
    this.firstEnabledStepIndex$.pipe(take(1)).subscribe((firstEnabledStepIndex) => {
      this.matStepper.selectedIndex = firstEnabledStepIndex;
    });
    this.selectedStepIndexSubject.next(this.matStepper.selectedIndex);
  }

  onSelectionChange($event: StepperSelectionEvent) {
    this.selectedStepIndexSubject.next($event.selectedIndex);
  }

  getLabel(index: number): Observable<string> {
    return this.steps$.pipe(map((steps) => steps[index].label));
  }

  onNextClick(steps: WebAppStepDirective[]) {
    steps[this.matStepper.selectedIndex]
      .validationFactory()
      .pipe(take(1))
      .subscribe((validationResult) => {
        // Mark steps as dirty so that potential step errors get updated
        this.matStepper.steps.setDirty();
        if (validationResult) {
          const nextIndex = findIndex(
            steps,
            (step) => !step.disabled && steps.indexOf(step) > this.matStepper.selectedIndex
          );
          if (nextIndex >= 0) {
            this.matStepper.selectedIndex = nextIndex;
          } else {
            this.completed.emit(validationResult);
          }
        }
      });
  }

  goToStep(stepIndex: number): void {
    this.matStepper.selectedIndex = stepIndex;
  }

  goToStepById<STEP_ID extends string>(stepId: STEP_ID): void {
    this.steps$.pipe(take(1)).subscribe((steps) => {
      const newIndex: number = steps.findIndex((step) => step.stepId === stepId);
      if (newIndex < 0) {
        throw new Error('Cannot go to unknown step with id ' + stepId);
      }
      this.matStepper.selectedIndex = newIndex;
    });
  }

  onBackClick(steps: WebAppStepDirective[]) {
    const currentIndex: number = this.matStepper.selectedIndex;
    const firstErroneousStepIndex = this.matStepper.steps
      .toArray()
      .slice(0, currentIndex)
      .findIndex((step) => step.hasError);

    if (firstErroneousStepIndex !== -1) {
      // Navigate to the first step that has an error
      this.matStepper.selectedIndex = firstErroneousStepIndex;
      return;
    }

    const nextIndex = findLastIndex(steps, (step) => !step.disabled && steps.indexOf(step) < currentIndex);
    if (nextIndex >= 0) {
      this.matStepper.selectedIndex = nextIndex;
    }
  }
}
