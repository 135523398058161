import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AfpRestNotification, AfpRestResponse} from '../../afp-rest/index';
import {UserSettingDTO} from '../models/user-setting-dto.definitions';
import {UserSettings, UserSettingsSubset} from '../models/user-settings.definition';
import {UserSettingsDeserializer} from './user-settings-deserializer';
import {UserSettingsSerializer} from './user-settings-serializer';
import {NotificationService} from '../../ui-notification/notification.service';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {UserSettingsVisibility} from '../models/user-settings-visibility';
import {
  basuizUserSettingsGroupPrefix,
  getUserSettingsGroupMap,
  UserSettingsGroup,
} from '../models/user-settings-group.definition';

@Injectable({providedIn: 'root'})
export class UserSettingsDataService {
  constructor(private httpClient: HttpClient, private notificationService: NotificationService) {}

  // private url: string = '/com.basuiz.afs.rest.services/rest/userSettings/settings'; // to be used with mock-server & proxy.config.json
  private url: string = process.env.API_BASE_URL + '/rest/userSettings/settings';

  public fetchSettings(): Observable<[UserSettings, UserSettingsVisibility]> {
    return this.httpClient
      .get<AfpRestResponse<{settingList: UserSettingDTO[]}>>(this.url, {params: {includeMetadata: 'true'}})
      .pipe(
        map(({payload}) => payload.settingList),
        map((settingDTOs) => {
          return [this.deserializeSettings(settingDTOs), this.extractSettingsVisibilityMap(settingDTOs)];
        })
      );
  }

  public async updateSettings(userSettingsSubset: UserSettingsSubset): Promise<void> {
    const body = {
      settingList: new UserSettingsSerializer(userSettingsSubset).serialize(),
    };

    return (await this.httpClient
      .post(`${this.url}`, body, {})
      .pipe(
        catchError((result: HttpErrorResponse) => {
          const notifications: AfpRestNotification[] = result.error?.notifications || [];
          const errorNotifications = notifications.filter((notification) => notification.severity === 'ERROR');

          if (errorNotifications.length > 0) {
            errorNotifications.forEach((errorNotification) => {
              this.notificationService.error(errorNotification.message);
            });
          } else {
            this.notificationService.error(asTranslationKey('web-app-common.user-settings.update-settings-error'));
          }

          throw result;
        })
      )
      .toPromise()) as Promise<void>;
  }

  private deserializeSettings(dtoList: UserSettingDTO[]): UserSettings {
    return new UserSettingsDeserializer(dtoList).deserialize();
  }

  private extractSettingsVisibilityMap(dtoList: UserSettingDTO[]): UserSettingsVisibility {
    const groupNameMap = this.swapKeyValue(getUserSettingsGroupMap());

    return dtoList.reduce((acc, dto) => {
      const {groupName, key, metadata} = dto;
      const groupFrontName: UserSettingsGroup | undefined = groupNameMap[groupName];

      if (!groupFrontName) {
        if (groupName.startsWith(basuizUserSettingsGroupPrefix)) {
          console.warn(`Unknown Bsz user settings group: ${groupName}`);
        }
        // Ignore user settings groups that are unknown to the front application
        return acc;
      }

      const group: Record<string, boolean> = acc[groupFrontName] ?? {};
      group[key] = metadata.visible;
      return {...acc, [groupFrontName]: group};
    }, {} as Record<string, Record<string, boolean>>) as UserSettingsVisibility;
  }

  private swapKeyValue<KEY extends string, VALUE extends string>(source: Record<KEY, VALUE>): Record<VALUE, KEY> {
    return Object.entries(source).reduce((acc, [key, value]: [KEY, VALUE]) => {
      return {...acc, [value]: key};
    }, {}) as Record<VALUE, KEY>;
  }
}
