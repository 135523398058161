<ng-container *ngIf="exportFileTypes$ | async as fileTypes">
  <ng-container *ngIf="fileTypes.length === 1">
    <button
      mat-button
      color="primary"
      [disabled]="isDisabled"
      (click)="download(fileTypes[0])"
      attr.aria-label="{{ 'web-app-common.export-to-file.button.download' | translate }}"
      bszTestId="web-app-common.export-to-file.single-file-button"
    >
      <mat-icon svgIcon="bszDownload"></mat-icon>
      <span aria-hidden="true" class="bsz-display-none bsz-display-tablet-inline">
        {{ 'web-app-common.export-to-file.button.download' | translate }}
      </span>
    </button>
  </ng-container>

  <ng-container *ngIf="fileTypes.length > 1">
    <button
      mat-button
      color="primary"
      [disabled]="isDisabled"
      [matMenuTriggerFor]="downloadMenu"
      attr.aria-label="{{ 'web-app-common.export-to-file.button.download' | translate }}"
      bszTestId="web-app-common.export-to-file.multi-file-button"
    >
      <mat-icon svgIcon="bszDownload"></mat-icon>
      <span aria-hidden="true" class="bsz-display-none bsz-display-tablet-inline">
        {{ 'web-app-common.export-to-file.button.download' | translate }}
      </span>
    </button>

    <mat-menu #downloadMenu="matMenu" bszTestId="web-app-common.export-to-file.menu">
      <ng-container *ngFor="let fileType of fileTypes">
        <button
          *ngIf="fileType === FILE_TYPE.PDF"
          mat-menu-item
          (click)="download(FILE_TYPE.PDF)"
          bszTestId="web-app-common.export-to-file.pdf-export-item"
          >{{ 'web-app-common.export-to-file.button.download-as-pdf' | translate }}</button
        >

        <button
          *ngIf="fileType === FILE_TYPE.CSV"
          mat-menu-item
          (click)="download(FILE_TYPE.CSV)"
          bszTestId="web-app-common.export-to-file.csv-export-item"
          >{{ 'web-app-common.export-to-file.button.download-as-csv' | translate }}</button
        >

        <button
          *ngIf="fileType === FILE_TYPE.EXCEL"
          mat-menu-item
          (click)="download(FILE_TYPE.EXCEL)"
          bszTestId="web-app-common.export-to-file.excel-export-item"
          >{{ 'web-app-common.export-to-file.button.download-as-xls' | translate }}</button
        >
      </ng-container>
    </mat-menu>
  </ng-container>
</ng-container>
