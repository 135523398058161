import * as intents from '../../intents/intents.index';
import {DefaultNavigationIntentHandlerMap} from '../../navigation/default-intent-handlers.config';
import * as pages from '../../pages/pages.index';
import {DefaultIntentHandlerMapBuilder} from '../default-intent-handler-map.builder';

export function paymentMethodDefaultIntentHandlersFactory(): DefaultNavigationIntentHandlerMap {
  const builder = new DefaultIntentHandlerMapBuilder();
  defineHandlers(builder.addHandler);
  return builder.build();
}

function defineHandlers(addHandler: DefaultIntentHandlerMapBuilder['addHandler']): void {
  addHandler(intents.ShowPaymentMethodWalletOverviewIntent, () => new pages.PaymentMethodWalletOverviewPage());
}
