import {DecimalPipe} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {ValidationErrors} from '@angular/forms';

@Component({
  selector: 'bsz-file-upload-selected-file',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="bsz-file-upload-selected-file">
      <div [ngClass]="{'mat-error': hasErrors}" class="bsz-file-upload-selected-file-icon">
        <mat-icon *ngIf="!hasErrors">insert_drive_file</mat-icon>
        <mat-icon
          [attr.aria-label]="
            'ui-elements.bsz-file-upload.bsz-file-upload-selected-file.accessibility.error-icon-alt' | translate
          "
          *ngIf="hasErrors"
          >error_outline</mat-icon
        >
      </div>
      <div [ngClass]="{'mat-error': hasErrors}" class="bsz-file-upload-selected-file-info">
        <div class="bsz-file-upload-selected-file-name">
          {{ file.name }}
        </div>
        <div *ngIf="!hasErrors" class="bsz-file-upload-selected-file-size bsz-caption">
          {{ bytesToStringFormat(file.size) }}
        </div>
        <div *ngIf="hasErrors" class="bsz-file-upload-selected-files-violations">
          <div *ngFor="let violation of violations" class="bsz-caption bsz-text-bold">
            {{ violation.message }}
          </div>
        </div>
      </div>
      <button
        mat-icon-button
        [attr.aria-label]="
          'ui-elements.bsz-file-upload.accessibility.selected-file.delete-button.aria-label' | translate
        "
        (click)="delete.emit(file)"
        class="bsz-file-upload-selected-file-delete-button"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  `,
})
export class BszFileUploadSelectedFile implements OnInit {
  @Input() file!: File;
  @Input() errors: ValidationErrors | null | undefined;

  @Output() delete = new EventEmitter<File>();

  get hasErrors() {
    return this.violations.length > 0;
  }

  violations: ValidationErrors[] = [];

  constructor(private decimalPipe: DecimalPipe) {}

  ngOnInit(): void {
    this.violations = this.extractViolations(this.file);
  }

  bytesToStringFormat(size: number): string {
    // from: https://stackoverflow.com/a/20732091
    const i = Math.floor(Math.log(size) / Math.log(1024));

    const bytesTransformed = this.decimalPipe.transform(size / Math.pow(1024, i), '1.0-2');
    const suffix = ['bytes', 'KB', 'MB', 'GB', 'TB'][i] || '';

    return bytesTransformed !== null ? `${bytesTransformed} ${suffix}` : '0 bytes';
  }

  extractViolations(file: File) {
    const ngControlErrors = this.errors || {};
    const violations = [];

    for (const error in ngControlErrors) {
      if (ngControlErrors.hasOwnProperty(error)) {
        const totalViolations = ngControlErrors[error];
        const violationForFile = totalViolations.find((err: any) => err?.file === file);
        if (violationForFile) {
          violations.push(violationForFile);
        }
      }
    }

    return violations;
  }
}
