import {Directive, ElementRef, forwardRef, Renderer2} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {BszI18nService} from '@basuiz/i18n';

import {BszAmountBase} from '../bsz-amount/bsz-amount-base';

@Directive({
  selector: 'input[bszPercentage]',
  host: {
    class: 'bsz-percentage',
    type: 'text',
    inputmode: 'decimal',
    autocomplete: 'off',
    style: 'vertical-align: baseline',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BszPercentage),
      multi: true,
    },
  ],
})
export class BszPercentage extends BszAmountBase {
  constructor(elementRef: ElementRef<HTMLInputElement>, i18nService: BszI18nService, renderer: Renderer2) {
    super(elementRef, i18nService, renderer);
  }

  protected override modelToViewValue(modelValue: number | null, isFocused: boolean): string {
    const viewValue = super.modelToViewValue(modelValue, isFocused);

    return viewValue ? `${viewValue}%` : '';
  }

  protected override viewToModelValue(viewValue: string, isFocused: boolean): number | null | false {
    return super.viewToModelValue(viewValue.replace('%', ''), isFocused);
  }

  protected override isValidKey(event: KeyboardEvent): boolean {
    // Allow entering %. We check the format in the autoFormatViewValue().
    if (event.key === '%') {
      return true;
    }

    return super.isValidKey(event);
  }

  protected override getDecimalPositionsLength(value: string): number {
    // Remove the % character before considering the decimal position length so it won't affect
    // the calculations.
    return super.getDecimalPositionsLength(value.replace('%', ''));
  }

  protected override autoFormatViewValue(value: string): string {
    // Ignore everything user enters after a percent character, thus effectively
    // preventing him to enter anything after a percent character.
    // We could do this in the isValidKey(), but it's non trivial to handle selections
    // (when user selects some text and then replaces it with a percent character).
    value = value.replace(/^(.+?%).?$/, '$1');

    return super.autoFormatViewValue(value);
  }

  /**
   * The model value for the outside world is the number value of the percentage (e.g. 0.75),
   * while the internal model value is the percentage itself (e.g. 75), since the number input
   * works over the percentage.
   * This means we need to transform the value when coming from the outside (0.75 -> 75).
   */
  override writeValue(value: any) {
    super.writeValue(this.transformExternalToInternalModelValue(value));
  }

  /**
   * A counterpart to writeValue(), we need to transform the internal model value to the external
   * model value (75 -> 0.75) when the outer world gets notified of a new model value. This is done
   * via the onChange callback (coming from ControlValueAccessor), so we'll just wrap the onChange
   * callback in our custom function doing the transformation.
   */
  override registerOnChange(fn: (value: any) => void) {
    super.registerOnChange((value) => {
      fn(this.transformInternalToExternalModelValue(value));
    });
  }

  private transformInternalToExternalModelValue(value: number | null): number | null {
    return value !== null ? value / 100 : null;
  }

  private transformExternalToInternalModelValue(value: number | string | null | undefined): number | null {
    return this.isNumberValue(value) ? Number(value) * 100 : null;
  }
}
