import {Injectable} from '@angular/core';

export const BSZ_I18N_LOCAL_STORAGE_KEY = 'bsz-i18n-locale';
export const BSZ_I18N_DEFAULT_LOCALE = 'en-GB';

export interface BszI18nFetchLocaleParams {
  supportedLocales: string[];
}

export interface BszI18nStoreLocaleParams {
  localeId: string;
}

@Injectable()
export class BszI18nLocaleStorageService {
  /**
   * Get stored locale id
   *
   * If BszI18nGetLocaleIdParams.supportedLocales are provided, the function will only return one of those.
   * For instance, if there is "ch-DE" in storage, but the supportedLocales is ["en-GB"], the storage will be ignored.
   *
   * Function is async to future-proof the API, on architectural request.
   */
  async fetchLocale(params?: BszI18nFetchLocaleParams): Promise<string> {
    const fromStorage = isLocaleStorageAvailable() ? localStorage.getItem(BSZ_I18N_LOCAL_STORAGE_KEY) : '';
    const fromBrowser = navigator.language;
    const suggestedLocale = fromStorage || fromBrowser || BSZ_I18N_DEFAULT_LOCALE;

    // return one of the supported locales
    if (params?.supportedLocales.length) {
      if (params.supportedLocales.includes(suggestedLocale)) {
        return suggestedLocale;
      } else if (params.supportedLocales.includes(BSZ_I18N_DEFAULT_LOCALE)) {
        return BSZ_I18N_DEFAULT_LOCALE;
      } else {
        return params.supportedLocales[0];
      }
    }

    // simply return the suggested locale
    return suggestedLocale;
  }

  /**
   * Store provided locale id as string
   *
   * Function is async to future-proof the API, on architectural request.
   */
  async storeLocale(params: BszI18nStoreLocaleParams): Promise<void> {
    if (isLocaleStorageAvailable()) {
      localStorage.setItem(BSZ_I18N_LOCAL_STORAGE_KEY, params.localeId);
    } else {
      console.error('[BszI18nLocaleStorageService] Cannot persist locale id because local storage is not available.');
    }
  }
}

function isLocaleStorageAvailable() {
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}
