<div class="bsz-tree-data-list-header" *ngIf="headerTemplate">
  <ng-template [ngTemplateOutlet]="headerTemplate"></ng-template>
</div>

<ul class="bsz-tree-data-list-collection">
  <li *ngFor="let child of dataSourceChange | async"
      bsz-tree-data-list-node
      [data]="child"
      [level]="1"
      [groupHeaderTemplate]="groupHeaderTemplate"
      [itemTemplate]="itemTemplate"
      [expandFn]="expandFn"
      [componentCollapsed]="componentCollapsed"
      (collapsedChange)="collapsedTreeChangeCallback($event)"
      [expandedGroupIds]="_expandedGroups">
  </li>
</ul>
