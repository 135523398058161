import {Inject, Pipe, PipeTransform} from '@angular/core';

import moment, {Moment} from 'moment';
import {DefaultMomentDateFormat, ɵDEFAULT_DATE_FORMAT} from '../../formatters/default-date-format';

import {isUndefined, throwInvalidPipeArgument} from '../../helpers/formatting-helpers';
import {isCalendarDay, isDateTime} from '../value-with-format/value-with-format.definitions';
import {TranslateService} from '@ngx-translate/core';
import {TranslationKey} from '@basuiz/web-app-applet-api';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {filterDefinedProperties} from '../../../utils/object.util';

export type Milliseconds = number;

// There are only two date formats supported, represented by CALENDAR_DAY_REG_EXP and DATE_TIME_REG_EXP
// If need to introduce new one make sure it has regExp, type guard and could be parsed by moment
export type CalendarDay = string;

/* An ISO 8601 date compatible with the `moment()` creator.
 * If no time-offset is defined in the string then moment will assume it is in the local time zone of the browser,
 * and it will not apply any time-zone manipulations (will keep the date / time as it states in the string).
 * Note: Date-time strings obtained from AFP back-end typically lack the time-offset indication, which leads to
 * showing any date and / or time according to the server time-zone (which is unknown to the UI) instead
 * of converting these to the user's time zone. This is the desired behavior for the date / time
 * data fetched obtained from the AFP back-end. */
export type DateTime = string;

export interface BszDateOptions {
  format?: DefaultMomentDateFormat;
  relative?: boolean;
}

type RelativeDate = 'today' | 'tomorrow' | 'yesterday';

@Pipe({name: 'bszDate'})
export class BszDatePipe implements PipeTransform {
  private readonly relativeDateTranslation: Record<RelativeDate, TranslationKey> = {
    today: asTranslationKey('web-app-common.formatters.date.today'),
    tomorrow: asTranslationKey('web-app-common.formatters.date.tomorrow'),
    yesterday: asTranslationKey('web-app-common.formatters.date.yesterday'),
  };

  private readonly defaultDateOptions: BszDateOptions;

  constructor(
    private readonly translateService: TranslateService,
    @Inject(ɵDEFAULT_DATE_FORMAT) private readonly defaultDateFormat?: DefaultMomentDateFormat
  ) {
    this.defaultDateOptions = {
      format: this.defaultDateFormat,
      relative: false,
    };
  }

  private getResult(momentInstance: Moment, options: BszDateOptions) {
    if (options.relative && BszDatePipe.isKnownRelativeDate(momentInstance)) {
      return momentInstance.calendar(null, {
        sameDay: `[${this.translateService.instant(this.relativeDateTranslation.today, {
          time: momentInstance.format('LT'),
        })}]`,
        nextDay: `[${this.translateService.instant(this.relativeDateTranslation.tomorrow, {
          time: momentInstance.format('LT'),
        })}]`,
        lastDay: `[${this.translateService.instant(this.relativeDateTranslation.yesterday, {
          time: momentInstance.format('LT'),
        })}]`,
      });
    }

    return momentInstance.format(options.format);
  }

  public transform(
    value: Milliseconds | CalendarDay | DateTime | Date | Moment | null | undefined,
    options?: BszDateOptions
  ): string {
    const definedOptions: BszDateOptions = options ? filterDefinedProperties(options) : {};
    const config: BszDateOptions = {...this.defaultDateOptions, ...definedOptions};

    // return empty string for nil param
    if (isUndefined(value)) {
      return '';
    }

    // check if this is a Moment
    if (moment.isMoment(value)) {
      return this.getResult(value, config);
    }

    // if a string then needs to comply to our formats, otherwise fail
    if (typeof value === 'string' && !isCalendarDay(value) && !isDateTime(value)) {
      throwInvalidPipeArgument(BszDatePipe, value);
    }

    // proceed with pipe logic
    if (typeof value === 'number' || typeof value === 'string' || value instanceof Date) {
      const momentInstance = moment(value);
      if (momentInstance.isValid()) {
        return this.getResult(momentInstance, config);
      }
    }

    // fail for invalid inputs
    throwInvalidPipeArgument(BszDatePipe, value);
  }

  private static isKnownRelativeDate(momentInstance: Moment): boolean {
    const today = moment().startOf('day'),
      diff = momentInstance.diff(today, 'days', true);

    return BszDatePipe.isYesterday(diff) || BszDatePipe.isToday(diff) || BszDatePipe.isTomorrow(diff);
  }

  private static isYesterday(diff: number): boolean {
    return diff >= -1 && diff < 0;
  }

  private static isToday(diff: number): boolean {
    return diff >= 0 && diff < 1;
  }

  private static isTomorrow(diff: number): boolean {
    return diff >= 1 && diff < 2;
  }
}
