import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'bsz-switch-button-option',
  templateUrl: './bsz-switch-button-option.html',
  styleUrls: ['./bsz-switch-button.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BszSwitchButtonOption<T = unknown> {
  @Input() value: T;
  @Input() icon: string;
  @Input() text: string;
  @Input('aria-label') ariaLabel: string;

  @Input()
  set selected(selected: BooleanInput) {
    this._selected = coerceBooleanProperty(selected);
    this.cd.markForCheck();
  }

  get selected(): BooleanInput {
    return this._selected;
  }

  private _selected = false;

  constructor(private readonly cd: ChangeDetectorRef) {}

  /** @private */
  getLabel(): string | undefined {
    return this.text || this.ariaLabel;
  }
}
