import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {WebAppSelfServiceLocaleSelectorAppletComponent} from '@basuiz/web-app-self-service-locale-selector-applet';
import {take} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';

interface ComponentState {
  localesDiffer: boolean;
  changing: boolean;
}

@Component({
  selector: 'bsz-change-locale-dialog',
  templateUrl: './change-locale-dialog.component.html',
  styleUrls: ['./change-locale-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLocaleDialogComponent {
  readonly bszTestIdPrefix = 'web-app-portal.app-bar.user-account-menu.change-locale-dialog';
  readonly componentState$ = new BehaviorSubject<ComponentState>({
    localesDiffer: false,
    changing: false,
  });

  @ViewChild(WebAppSelfServiceLocaleSelectorAppletComponent)
  private applet: WebAppSelfServiceLocaleSelectorAppletComponent;

  onChangeLanguage() {
    this.updateComponentState({changing: true});
    this.applet
      .changeLocale()
      .pipe(take(1))
      .subscribe(
        () => {
          this.updateComponentState({changing: false});
        },
        () => {
          this.updateComponentState({changing: false});
        }
      );
  }

  updateComponentState(changes: Partial<ComponentState>) {
    this.componentState$.next({
      ...this.componentState$.getValue(),
      ...changes,
    });
  }
}
