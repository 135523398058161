import {Injectable} from '@angular/core';
import {DateAdapter} from '@angular/material/core';
import {DateRange, MatDateRangeSelectionStrategy} from '@angular/material/datepicker';

/**
 * Provides the date range behavior for range previews and selection in the calendar.
 * Currently we make use of this service only for the range preview.
 */
@Injectable()
export class BszDateRangeSelectionStrategy<D> implements MatDateRangeSelectionStrategy<D> {
  constructor(private readonly dateAdapter: DateAdapter<D>) {}

  /** We don't make use of this method in our implementation since we won't provide custom behaviors for the time being. */
  selectionFinished(): never {
    throw new Error('Method not implemented.');
  }

  createPreview(activeDate: D | null, currentRange: DateRange<D>) {
    if (currentRange.start && !currentRange.end && activeDate) {
      return this.createRangeSelection(currentRange.start, activeDate);
    }

    return new DateRange<D>(null, null);
  }

  private createRangeSelection(firstDate: D, secondDate: D): DateRange<D> {
    return this.dateAdapter.compareDate(firstDate, secondDate) < 0
      ? new DateRange(firstDate, secondDate)
      : new DateRange(secondDate, firstDate);
  }
}
