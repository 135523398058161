import {Injectable} from '@angular/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class BszMatPaginatorIntl extends MatPaginatorIntl {
  constructor(private translateService: TranslateService) {
    super();
  }

  displayTotalItems: boolean = true;

  itemsPerPageLabel = this.translateService.instant(asTranslationKey('web-app-common.paginator.items-per-page.label'));
  nextPageLabel = this.translateService.instant(asTranslationKey('web-app-common.paginator.next-page.label'));
  previousPageLabel = this.translateService.instant(asTranslationKey('web-app-common.paginator.previous-page.label'));
  firstPageLabel = this.translateService.instant(asTranslationKey('web-app-common.paginator.first-page.label'));
  lastPageLabel = this.translateService.instant(asTranslationKey('web-app-common.paginator.last-page.label'));

  getRangeLabel = function (page: number, pageSize: number, length: number) {
    if (length === 0 || pageSize === 0) {
      return this.translateService.instant(asTranslationKey('web-app-common.paginator.range.zero-of.label'), {
        datasourceLength: length,
      });
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    if (this.displayTotalItems) {
      return this.translateService.instant(asTranslationKey('web-app-common.paginator.range.from-to-of.label'), {
        from: startIndex + 1,
        to: endIndex,
        length,
      });
    }

    return this.translateService.instant(asTranslationKey('web-app-common.paginator.range.from-to.label'), {
      from: startIndex + 1,
      to: endIndex,
    });
  };
}
