import {
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import {TranslatedText} from '@basuiz/web-app-applet-api';
import {BszOverlayDisabledFormComponent} from './bsz-overlay-disabled-form.component';

/**
 * Create a panel over the element it is attached to, preventing user interaction with whatever there is below it.
 * The panel has a style similar to modals, with a gray background, and show a banner with a custom title and a
 * custom message received in input.
 *
 * Input parameters:
 * bszMessageTitle: the translated text to display as a title, in bold format
 * bszMessageText: the translated info text to display to the user
 *
 * Example of use:
 *
 * ```
    <bsz-web-app-stepper
      bszOverlayDisableForm
      [bszOverlayDisplayCondition]="paymentSubmitted$ | async"
      [bszMessageTitle]="'web-app-payment-account-transfer-applet.account-transfer-form.confirmation-step.banner.title' | translate"
      [bszMessageText]="'web-app-payment-account-transfer-applet.account-transfer-form.confirmation-step.banner.text' | translate"
    ></bsz-web-app-stepper>
 * ```
 */
@Directive({selector: '[bszOverlayDisableForm]'})
export class BszOverlayDisabledFormDirective implements OnChanges, OnDestroy {
  @Input() bszOverlayMessageTitle: TranslatedText;
  @Input() bszOverlayMessageText: TranslatedText;
  @Input() bszOverlayDisplayCondition = false;

  private overlayDisabledFormComponent: ComponentRef<BszOverlayDisabledFormComponent> | undefined;
  private wrapperDiv: HTMLDivElement | undefined;

  constructor(private el: ElementRef, private renderer: Renderer2, private viewContainerRef: ViewContainerRef) {}

  ngOnDestroy() {
    this.removeComponent();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.bszOverlayDisplayCondition || changes.bszMessageTitle || changes.bszMessageText) {
      const isMessageTitleProvided = this.bszOverlayMessageTitle?.length > 0;
      const isMessageTextProvided = this.bszOverlayMessageText?.length > 0;

      if (this.bszOverlayDisplayCondition && (isMessageTitleProvided || isMessageTextProvided)) {
        this.createOrUpdateComponent();
      } else {
        this.removeComponent();
      }
    }
  }

  private createOrUpdateComponent() {
    if (this.overlayDisabledFormComponent) {
      this.overlayDisabledFormComponent.instance.title = this.bszOverlayMessageTitle;
      this.overlayDisabledFormComponent.instance.message = this.bszOverlayMessageText;
    } else {
      this.createComponent(this.bszOverlayMessageTitle, this.bszOverlayMessageText);
    }
  }

  private createComponent(messageTitle: TranslatedText, messageText: TranslatedText) {
    this.viewContainerRef.clear();

    this.overlayDisabledFormComponent = this.viewContainerRef.createComponent(BszOverlayDisabledFormComponent);
    this.overlayDisabledFormComponent.instance.title = messageTitle;
    this.overlayDisabledFormComponent.instance.message = messageText;

    const component = this.overlayDisabledFormComponent.location.nativeElement as HTMLElement;
    const divAroundComponent: HTMLDivElement = this.createDivAroundOverlayComponent(component);
    this.generateWrapperDivWithComponent(divAroundComponent);
  }

  private generateWrapperDivWithComponent(divAroundComponent: HTMLElement) {
    // get elements to work on
    const element = this.el.nativeElement as HTMLElement;
    const parent = this.renderer.parentNode(element) as HTMLElement;

    // create a wrapper div element to contain the previous div with the overlay component and the element this directive is attached to
    // this div will have the bsz-position-relative class applied
    const div: HTMLDivElement = this.renderer.createElement('div');
    this.renderer.insertBefore(parent, div, element);
    div.appendChild(divAroundComponent);
    div.appendChild(element);
    div.classList.add('bsz-position-relative');

    this.wrapperDiv = div;
  }

  private createDivAroundOverlayComponent(component: HTMLElement) {
    const div: HTMLDivElement = this.renderer.createElement('div');
    div.appendChild(component);
    div.classList.add(
      'bsz-position-absolute',
      'bsz-display-flex',
      'bsz-align-items-center',
      'bsz-justify-center',
      'bsz-padding-l12',
      'bsz-padding-r12'
    );
    div.setAttribute('style', 'z-index: 100; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.32);');

    return div;
  }

  private removeComponent() {
    if (this.wrapperDiv) {
      const parent = this.renderer.parentNode(this.wrapperDiv);
      const element = this.el.nativeElement as HTMLElement;
      this.renderer.insertBefore(parent, element, this.wrapperDiv);
      this.renderer.removeChild(parent, this.wrapperDiv);
      this.overlayDisabledFormComponent = undefined;
      this.wrapperDiv = undefined;
    }
  }
}
