import {PortalPage} from '../navigation/classes/portal-page';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslationKeyWithParams} from '../navigation/translation-key-with-params';
import {TranslationKey} from '@basuiz/web-app-applet-api';
import {CollaborationOverviewPage} from './collaboration-overview.page';

export interface CollaborationDetailPagePayload {
  collaborationIssueId: number;
  collaborationIssueSubject: string;
}

export class CollaborationDetailPage extends PortalPage {
  constructor(public payload: CollaborationDetailPagePayload) {
    super();
  }

  get parentPortalPage(): PortalPage | null {
    return new CollaborationOverviewPage();
  }

  get breadcrumbText(): TranslationKey | TranslationKeyWithParams {
    return asTranslationKey('web-app-portal.pages.collaboration-detail.breadcrumb');
  }

  get breadcrumbTestId(): string {
    return 'web-app-portal.pages.collaboration-detail.breadcrumb';
  }

  get titleText(): TranslationKey {
    return asTranslationKey('web-app-portal.pages.collaboration-detail.window-title');
  }

  static readonly routerConfigPath = 'collaboration/detail';
  get route(): Array<string> {
    return [`/${CollaborationDetailPage.routerConfigPath}`];
  }

  static readonly onInitialNavigationRedirectTo = CollaborationOverviewPage;
}
