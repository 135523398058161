import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {BszPageHeader, BszPageHeaderContent, BszPageHeaderToolbar} from './bsz-page-header';

@NgModule({
  imports: [CommonModule],
  declarations: [BszPageHeader, BszPageHeaderContent, BszPageHeaderToolbar],
  exports: [BszPageHeader, BszPageHeaderContent, BszPageHeaderToolbar],
  entryComponents: [BszPageHeader],
  providers: [],
})
export class BszPageHeaderModule {}
