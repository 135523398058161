/* IMPORTANT! This file is updated by the 'portal-page-config' schematic.
 * When doing structural changes in this file the schematic need to be updated accordingly.
 * */

import {Provider} from '@angular/core';
import {BszConfigFactoryWithDeps} from '@basuiz/web-app-applet-api';
import {DashboardPageConfig} from './dashboard/dashboard-page.config.definition';
import {dashboardPageConfigProvider} from './dashboard/dashboard-page.config.provider';
import {CollaborationOverviewPageConfig} from './collaboration-overview/collaboration-overview-page.config.definition';
import {collaborationOverviewPageConfigProvider} from './collaboration-overview/collaboration-overview-page.config.provider';
import {UserSettingsPageConfig} from './user-settings/user-settings-page.config.definition';
import {userSettingsPageConfigProvider} from './user-settings/user-settings-page.config.provider';
import {CollaborationDetailPageConfig} from './collaboration-detail/collaboration-detail-page.config.definition';
import {collaborationDetailPageConfigProvider} from './collaboration-detail/collaboration-detail-page.config.provider';
import {paymentCardsPageConfigProvider} from './payment-cards/payment-cards-page.config.provider';
import {PaymentCardsPageConfig} from './payment-cards/payment-cards-page.config.definition';

export interface PortalPageConfigs {
  dashboard: DashboardPageConfig;
  collaborationOverview: CollaborationOverviewPageConfig;
  userSettings: UserSettingsPageConfig;
  collaborationDetail: CollaborationDetailPageConfig;
  paymentCards: PaymentCardsPageConfig;
}

export type PortalPageConfigFactoriesWithDeps = {
  [key in keyof PortalPageConfigs]?: BszConfigFactoryWithDeps<PortalPageConfigs[key]>;
};

export function getPageConfigProviders(factoriesWithDeps: PortalPageConfigFactoriesWithDeps): Provider[] {
  return [
    ...(factoriesWithDeps.dashboard ? [dashboardPageConfigProvider(factoriesWithDeps.dashboard)] : []),
    ...(factoriesWithDeps.collaborationOverview
      ? [collaborationOverviewPageConfigProvider(factoriesWithDeps.collaborationOverview)]
      : []),
    ...(factoriesWithDeps.userSettings ? [userSettingsPageConfigProvider(factoriesWithDeps.userSettings)] : []),
    ...(factoriesWithDeps.collaborationDetail
      ? [collaborationDetailPageConfigProvider(factoriesWithDeps.collaborationDetail)]
      : []),
    ...(factoriesWithDeps.paymentCards ? [paymentCardsPageConfigProvider(factoriesWithDeps.paymentCards)] : []),
  ];
}
