import {DefaultNavigationIntentHandlerMap} from '../navigation/default-intent-handlers.config';
import {NavigationIntent} from '../navigation/classes/navigation-intent';
import {PortalPage} from '../navigation/classes/portal-page';
import {Type} from '@angular/core';

export class DefaultIntentHandlerMapBuilder {
  private readonly defaultIntentHandlers: DefaultNavigationIntentHandlerMap = new Map();
  private isBuilt = false;

  constructor() {
    // allows the addHandler function to be passed around by value with the correct binding to `this`
    this.addHandler = this.addHandler.bind(this);
  }

  public addHandler<T extends NavigationIntent>(intent: Type<T>, handler: (intent: T) => PortalPage): void {
    if (this.isBuilt) {
      throw new Error(`method 'addHandler' invoked after method 'build'`);
    }
    this.defaultIntentHandlers.set(intent, handler);
  }

  public build(): DefaultNavigationIntentHandlerMap {
    return this.defaultIntentHandlers;
    this.isBuilt = true;
  }
}
