import {FocusableOption} from '@angular/cdk/a11y';
import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: 'button[bsz-fab-menu-item]',
  host: {
    'class': 'bsz-fab-menu-item',
    'role': 'menuitem',
    'tabindex': '-1',
    '[style.height.px]': `${BszFabMenuItem.height}`,
  },
})
export class BszFabMenuItem implements FocusableOption {
  /**
   * This property will be used to calculate the total height of all items
   * so the value can be passed down to the fabContainerTransition.
   *
   * @private
   */
  static readonly height = 48;

  constructor(private elementRef: ElementRef) {}

  focus() {
    this.elementRef.nativeElement.focus();
  }
}
