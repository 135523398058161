import {
  ComponentRef,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import {BszProgressBarComponent} from './bsz-progress-bar.component';
import {TranslateService} from '@ngx-translate/core';
import {TranslatedText} from '@basuiz/web-app-applet-api';

@Directive({
  selector: '[bszProgressIndicator]',
})
export class ProgressIndicatorDirective implements OnDestroy {
  private progressBarComponentRef: ComponentRef<BszProgressBarComponent> | undefined;

  @Input()
  set bszProgressIndicator(showProgressBar: boolean) {
    showProgressBar ? this.addProgressBar() : this.removeProgressBar();
  }

  @Input()
  inProgressMessage: TranslatedText = this.translateService.instant('web-app-common.progress-bar.data-is-fetching');

  @HostBinding('style.position')
  readonly position = 'relative';

  constructor(
    private translateService: TranslateService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef
  ) {}

  private addProgressBar() {
    if (!this.progressBarComponentRef) {
      this.progressBarComponentRef = this.viewContainerRef.createComponent(BszProgressBarComponent);
      this.progressBarComponentRef.instance.message = this.inProgressMessage;
      this.progressBarComponentRef.changeDetectorRef.detectChanges();
      this.renderer.appendChild(this.elementRef.nativeElement, this.progressBarComponentRef.location.nativeElement);
    }
  }

  private removeProgressBar() {
    if (this.progressBarComponentRef) {
      this.renderer.removeChild(this.elementRef.nativeElement, this.progressBarComponentRef.location.nativeElement);
      this.progressBarComponentRef.destroy();
      this.progressBarComponentRef = undefined;
    }
  }

  ngOnDestroy() {
    this.removeProgressBar();
  }
}
