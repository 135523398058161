import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {intersectionWith, isMatch, size} from 'lodash';
import {AfpRestNotification, AfpRestResponse, isAfpRestResponse} from '../../afp-rest/index';
import {AfpRestResponseWithAuthRequest} from '../models/auth.definitions';
import {isAfpRestResponseWithAuthRequest} from '../type-guards/auth.type-guards';

@Injectable()
export class AuthGuardService {
  public isAuthRequired(
    response: HttpResponse<any>
  ): response is HttpResponse<AfpRestResponse<AfpRestResponseWithAuthRequest>> & {
    body: AfpRestResponse<AfpRestResponseWithAuthRequest>;
  } {
    return isAfpRestResponseWithAuthRequest(response.body);
  }

  public isAuthNotPermitted(response: HttpResponse<any>): response is HttpResponse<AfpRestResponse<any>> {
    return (
      isAfpRestResponse(response.body) &&
      !!size(intersectionWith(response.body.notifications || [], AuthGuardService.NOT_PERMITTED_NOTIFICATIONS, isMatch))
    );
  }

  private static NOT_PERMITTED_NOTIFICATIONS: Partial<AfpRestNotification>[] = [
    {
      code: 14000,
      path: 'transactionSigningMobileChannelDisabled',
    },
  ];
}
