import {propertyOf} from '@basuiz/shared/data-access';
import {NavigationIntent} from '../../navigation/classes/navigation-intent';

export type CreateShipmentIntentPayload = CreateShipmentFromPartialIntentPayload | {};

export interface CreateShipmentFromPartialIntentPayload {
  /**
   * The order ID to use to start the create order process
   */
  shipmentId: number;
}

export class CreateShipmentIntent extends NavigationIntent {
  constructor(public payload: CreateShipmentIntentPayload = {}) {
    super();
  }
}

export function isCreateShipmentFromPartialIntentPayload(
  payload: CreateShipmentIntentPayload
): payload is CreateShipmentFromPartialIntentPayload {
  return payload && propertyOf<CreateShipmentFromPartialIntentPayload>('shipmentId') in payload;
}
