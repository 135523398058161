import {UserRoleService} from '@basuiz/web-app-applet-sdk';
import {DefaultDashboardId} from '../config/portal.config.definition';
import {UnionTypeFromArray} from '@basuiz/web-app-applet-api';

/* Technically nothing prevents an user from having multiple roles.
 Therefore, for a given set of roles, choose the more "advanced" dashboard the user can access to */
const dashboardPriority = ['CORPORATE', 'RETAIL'] as const;
type DashboardPriorityItem = UnionTypeFromArray<typeof dashboardPriority>;

const ensureValidUserRoles: Readonly<DefaultDashboardId[]> = dashboardPriority;

export function selectDashboardFromUserRoles(userRoleService: UserRoleService): DashboardPriorityItem {
  return dashboardPriority.find((role) => userRoleService.hasRole(role)) ?? 'RETAIL';
}
