import {Injectable, Injector} from '@angular/core';
import {Dictionary} from '@ngrx/entity';
import {select, Store} from '@ngrx/store';
import {isEmpty} from 'lodash';
import {BehaviorSubject, interval, Observable, Subscription, timer} from 'rxjs';
import {filter, map, share, takeWhile, tap} from 'rxjs/operators';
import {AttentionItemCounter} from '../models/attention-item-counter.definition';
import * as attentionItemCounterSelectors from './attention-item-counter/attention-item-counter.selectors';
import {loadAttentionItemCounterAction} from './attention-item-counter/attention-item-counter.actions';
import {PartialState} from './attention-item-counter/attention-item-counter.reducer';
import {AlertConfig, ɵALERT_CONFIG} from '@basuiz/web-app-alert/config';
import {NotificationsFacade} from '@basuiz/web-app-self-service-notifications';

@Injectable({
  providedIn: 'root',
})
export class WebAppAlertFacade {
  private static attentionItemCounterPollingRequestors: number = 0;
  private pollingSubscription: Subscription = new Subscription();

  readonly attentionItemCountersLoaded$: Observable<boolean> = this.store.pipe(
    select(attentionItemCounterSelectors.selectLoaded)
  );
  readonly attentionItemCountersLoading$: Observable<boolean> = this.store.pipe(
    select(attentionItemCounterSelectors.selectLoading)
  );
  readonly attentionItemCountersError$: Observable<boolean> = this.store.pipe(
    select(attentionItemCounterSelectors.selectErrorMessage),
    map((error) => !!error)
  );

  readonly attentionItemCountersDictionary$: Observable<Dictionary<AttentionItemCounter>> = this.store.pipe(
    select(attentionItemCounterSelectors.selectEntities),
    filter((data) => !isEmpty(data))
  );

  readonly attentionItemCounters$: Observable<AttentionItemCounter[]> = this.store.pipe(
    select(attentionItemCounterSelectors.selectAll),
    filter((data) => !isEmpty(data))
  );

  readonly attentionItemCountersWithPositiveValue$: Observable<AttentionItemCounter[]> =
    this.attentionItemCounters$.pipe(
      map((attentionItemCounters) => {
        const positiveCounters: AttentionItemCounter[] = attentionItemCounters.filter(
          (attentionItemCounter) => attentionItemCounter.value > 0
        );
        return this.config.counterTypes
          .map((counterType) => positiveCounters.find((positiveCounter) => positiveCounter.counterType === counterType))
          .filter((counter): counter is AttentionItemCounter => !!counter);
      })
    );

  loadAttentionItemCounter() {
    this.store.dispatch(loadAttentionItemCounterAction({counterTypes: this.config.counterTypes}));
  }

  requestStartPolling() {
    if (!WebAppAlertFacade.attentionItemCounterPollingRequestors) {
      this.pollingSubscription = timer(0, this.config.pollingInterval).subscribe(() => {
        this.triggerLoadAlert();
      });
    }

    WebAppAlertFacade.attentionItemCounterPollingRequestors++;
  }

  requestStopPolling() {
    if (WebAppAlertFacade.attentionItemCounterPollingRequestors > 0) {
      WebAppAlertFacade.attentionItemCounterPollingRequestors--;

      if (WebAppAlertFacade.attentionItemCounterPollingRequestors === 0) {
        this.pollingSubscription.unsubscribe();
      }
    }
  }

  private triggerLoadAlert() {
    this.store.dispatch(loadAttentionItemCounterAction({counterTypes: this.config.counterTypes}));
    this.notificationsFacade.triggerLoadNotifications();
  }

  private get config(): AlertConfig {
    return this.injector.get(ɵALERT_CONFIG);
  }

  private get notificationsFacade(): NotificationsFacade {
    return this.injector.get(NotificationsFacade);
  }

  constructor(private store: Store<PartialState>, private injector: Injector) {}
}
