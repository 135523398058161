import {Directive, HostBinding, Input} from '@angular/core';

export interface DisplayFlexDirectiveOptions {
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'start'
    | 'end'
    | 'left'
    | 'right'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  alignItems?:
    | 'stretch'
    | 'flex-start'
    | 'start'
    | 'self-start'
    | 'flex-end'
    | 'end'
    | 'self-end'
    | 'center'
    | 'baseline';
}

@Directive({
  selector: '[bszDisplayFlex]',
})
export class DisplayFlexDirective {
  @Input()
  set bszDisplayFlex(options: DisplayFlexDirectiveOptions | '') {
    if (options instanceof Object) {
      const {direction, justifyContent, alignItems} = options;
      this.flexDirection = direction;
      this.justifyContent = justifyContent;
      this.alignItems = alignItems;
    }
  }

  @HostBinding('style.display')
  readonly display = 'flex';

  @HostBinding('style.flex-direction')
  flexDirection: DisplayFlexDirectiveOptions['direction'] = undefined;

  @HostBinding('style.justify-content')
  justifyContent: DisplayFlexDirectiveOptions['justifyContent'] = undefined;

  @HostBinding('style.align-items')
  alignItems: DisplayFlexDirectiveOptions['alignItems'] = undefined;
}
