import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {
  BszFeatureToggle,
  ExpandedFeatureToggle,
  getAllEnabledFeatureToggle,
  isExperimentalFeatureToggleTreeNode,
  WEB_APP_EXPANDED_FEATURE_TOGGLE,
} from '@basuiz/web-app-feature-toggle';
import {SelectionModel} from '@angular/cdk/collections';
import {devToolsFeatureTogglePersistenceManager} from './feature-toggle.persistence';
import {validateFeatureToggle} from '@basuiz/web-app-feature-toggle';
import {WEB_APP_REBOOT_HANDLER, WebAppRebootHandler} from '@basuiz/web-app-common';

/* Structure to use in the material tree */
interface TreeNode {
  name: string;
  displayName: string;
  signature: string;
  isExperimental: boolean;
  children?: TreeNode[];
}

/* Flat node with expandable and level information */
interface FlatNode {
  expandable: boolean;
  name: string;
  displayName: string;
  signature: string;
  isExperimental: boolean;
  level: number;
}

function flattenNode(node: TreeNode, level: number): FlatNode {
  const {name, displayName, signature, isExperimental} = node;
  return {
    expandable: !!node.children && node.children.length > 0,
    name,
    displayName,
    signature,
    isExperimental,
    level,
  };
}

@Component({
  selector: 'bsz-feature-toggle-dev-tool',
  templateUrl: './feature-toggle-dev-tool.component.html',
  styleUrls: ['./feature-toggle-dev-tool.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureToggleDevToolComponent {
  treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  hasChild = (_: number, node: FlatNode) => node.expandable;

  private treeFlattener = new MatTreeFlattener(
    flattenNode,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource<TreeNode, FlatNode>(this.treeControl, this.treeFlattener);
  featureSelection = new SelectionModel<FlatNode>(true);
  private trees: TreeNode[] = this.featureToggleToTreeNodes(getAllEnabledFeatureToggle());

  errorMessage: string | undefined = undefined;

  readonly enableEntireSubTreeTooltipMessage: string = `Click to enable every sub-feature
below the current node`;

  readonly experimentalTooltipMessage: string = `This feature is experimental.
It might depend on applets that are not finished yet and therefore, are not ready for use in production.
Or activate a user journey for which some applets do not exist yet`;

  constructor(
    @Inject(WEB_APP_EXPANDED_FEATURE_TOGGLE) featureToggle: ExpandedFeatureToggle,
    @Inject(WEB_APP_REBOOT_HANDLER) private rebootHandler: WebAppRebootHandler
  ) {
    this.dataSource.data = this.trees;
    this.initializeSelection(featureToggle);
  }

  resetToDefault() {
    devToolsFeatureTogglePersistenceManager.clear();
    this.rebootHandler({event: 'devToolsUpdate'});
  }

  applyAndReload() {
    devToolsFeatureTogglePersistenceManager.update(this.treeNodesToFeatureToggle(this.trees));
    this.rebootHandler({event: 'devToolsUpdate'});
  }

  private featureToggleToTreeNodes(feature: {}, parentSignature: string | null = null): TreeNode[] {
    return Object.entries(feature).map(([key, value]) => {
      const signature: string = parentSignature ? `${parentSignature}.${key}` : key;
      return {
        name: key,
        displayName: camelCaseToSentenceCase(key),
        signature,
        isExperimental: isExperimentalFeatureToggleTreeNode(signature),
        children: !!value && typeof value === 'object' ? this.featureToggleToTreeNodes(value, signature) : [],
      };
    });
  }

  private treeNodesToFeatureToggle(trees: TreeNode[]): BszFeatureToggle {
    const featureNode: {[key: string]: {} | 'disabled' | 'enabled'} = {};
    trees.forEach((treeNode) => {
      const flatNode = this.treeControl.dataNodes.find((n) => n.signature === treeNode.signature);
      if (!this.featureSelection.isSelected(flatNode)) {
        featureNode[treeNode.name] = 'disabled';
      } else if (!!treeNode.children && treeNode.children.length > 0) {
        featureNode[treeNode.name] = this.treeNodesToFeatureToggle(treeNode.children);
      } else {
        featureNode[treeNode.name] = 'enabled';
      }
    });
    return featureNode as unknown as BszFeatureToggle;
  }

  private initializeSelection(feature: {}, parentSignature: string | null = null): void {
    Object.entries(feature).forEach(([key, value]) => {
      const signature: string = parentSignature ? `${parentSignature}.${key}` : key;
      if (value === 'enabled') {
        this.selectNodeFromSignature(signature);
      }
      if (value instanceof Object) {
        this.selectNodeFromSignature(signature);
        this.initializeSelection(value, signature);
      }
    });
  }

  private selectNodeFromSignature(signature: string): void {
    const flatNode = this.treeControl.dataNodes.find((n) => n.signature === signature);
    if (flatNode) {
      this.featureSelection.select(flatNode);
    }
  }

  toggleNode(node: FlatNode): void {
    if (this.featureSelection.isSelected(node)) {
      const descendants = this.treeControl.getDescendants(node);
      this.featureSelection.deselect(node, ...descendants);
    } else {
      this.featureSelection.select(node);
      this.selectAscendants(node);
    }
    this.checkForErrorsInFeatureToggle();
  }

  activateIncludingChildren(node: FlatNode): void {
    const descendants = this.treeControl.getDescendants(node);
    this.featureSelection.select(node, ...descendants);
    this.selectAscendants(node);
    this.checkForErrorsInFeatureToggle();
  }

  private selectAscendants(node: FlatNode): void {
    let parent: FlatNode | null = this.getParentNode(node);
    while (parent) {
      this.featureSelection.select(parent);
      parent = this.getParentNode(parent);
    }
  }

  private getParentNode(node: FlatNode): FlatNode | null {
    if (node.level < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (currentNode.level < node.level) {
        return currentNode;
      }
    }
    return null;
  }

  checkForErrorsInFeatureToggle(): void {
    const featureToggle = this.treeNodesToFeatureToggle(this.trees);
    const errors = validateFeatureToggle(featureToggle);
    this.errorMessage = errors || undefined;
  }
}

function camelCaseToSentenceCase(source: string): string {
  let result = source.replace(/([A-Z])/g, ' $1');
  result = result.toLowerCase();
  return result.charAt(0).toUpperCase() + result.slice(1);
}
