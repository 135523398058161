import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {ChangeDetectionStrategy, Component, ContentChild, Directive, Input, ViewEncapsulation} from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import {ProgressBarMode} from '@angular/material/progress-bar';

export type BszProgressBarSize = 'small' | 'medium' | 'large';

const LOG_PREFIX = '[bsz-progress-bar]';

/**
 * This is used as a selector in the API.
 */
@Directive({
  selector: 'bsz-progress-bar-label',
  host: {
    class: 'bsz-progress-bar-custom-label',
  },
})
export class BszProgressBarLabel {}

@Component({
  selector: 'bsz-progress-bar',
  templateUrl: './bsz-progress-bar.html',
  styleUrls: ['./bsz-progress-bar.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    'class': 'bsz-progress-bar',
    '[class.bsz-progress-bar-small]': 'size === "small"',
    '[class.bsz-progress-bar-medium]': 'size === "medium"',
    '[class.bsz-progress-bar-large]': 'size === "large"',
  },
})
export class BszProgressBar {
  @Input() color: ThemePalette = 'primary';

  @Input() mode: Extract<ProgressBarMode, 'indeterminate' | 'determinate'> = 'determinate';

  @Input() size: BszProgressBarSize = 'small';

  @Input() value = 0;

  @Input('aria-label') ariaLabel: string | null = null;

  @Input('aria-labelledby') ariaLabelledby: string | null = null;

  @Input() set displayProcessCompletion(display: BooleanInput) {
    if (display && this.mode !== 'determinate') {
      console.warn(`${LOG_PREFIX} cannot show process completion with mode: "${this.mode}"`);
      this._displayProcessCompletion = false;
      return;
    }

    this._displayProcessCompletion = coerceBooleanProperty(display);
  }
  /** @private for internal use only */
  _displayProcessCompletion: boolean;

  /** @private for internal use only */
  @ContentChild(BszProgressBarLabel) _progressBarCustomLabel: BszProgressBarLabel;
}
