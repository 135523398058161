import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {Injectable} from '@angular/core';
import {icons} from './web-app-custom-icon';

@Injectable({
  providedIn: 'root',
})
export class WebAppCustomIconService {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

  public registerIcons(): void {
    for (const [key, value] of Object.entries(icons)) {
      this.matIconRegistry.addSvgIconLiteral(key, this.domSanitizer.bypassSecurityTrustHtml(value));
    }
  }
}
