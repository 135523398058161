<ng-container *ngIf="breadcrumbsStyleId$ | async as breadcrumbsStyle">
  <bsz-hierarchical-breadcrumbs
    bszTestId="{{ bszTestIdPrefix }}.hierarchical-breadcrumbs"
    *ngIf="breadcrumbsStyle === 'hierarchy'"
    [startWithHome]="(startWithHome$ | async) || false"
  ></bsz-hierarchical-breadcrumbs>
  <bsz-history-breadcrumbs
    *ngIf="breadcrumbsStyle === 'history'"
    [maxBreadcrumbs]="(historyMaxBreadcrumbs$ | async) || 2"
  >
  </bsz-history-breadcrumbs>
  <bsz-history-back *ngIf="breadcrumbsStyle === 'back-button'"></bsz-history-back>
</ng-container>
