import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {formatNumber, getLocaleNumberSymbol, NumberSymbol} from '@angular/common';
import {Directive, ElementRef, HostListener, Input, Renderer2} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';
import {BszI18nService} from '@basuiz/i18n';

import {UNICODE_MINUS_SIGN} from '../../common/bsz.constants';

/**
 * Base class with the common functionality for a number input directive.
 */
@Directive()
export abstract class BszAmountBase implements ControlValueAccessor {
  @Input()
  set decimalLength(value: string | number | undefined | null) {
    this._decimalLength = this.isNumberValue(value) ? Number(value) : undefined;

    this.updateViewValue();
  }
  protected _decimalLength: number | undefined;

  @Input()
  set allowNegative(value: BooleanInput) {
    this._allowNegative = coerceBooleanProperty(value);

    this.updateViewValue();
  }
  protected _allowNegative = false;

  @Input()
  set roundValue(value: BooleanInput) {
    this._roundValue = coerceBooleanProperty(value);
  }
  protected _roundValue = false;

  @HostListener('blur') onBlur() {
    this.focused = false;
    this.updateViewValue();
    this.onTouched(); // -> once it is touched, the validation for required is executed
  }

  @HostListener('focus') onFocus() {
    this.focused = true;
    this.updateViewValue();
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    this.handleFieldKeydown(event);
  }

  @HostListener('paste', ['$event']) onPaste(event: any) {
    // do not allow to paste
    event.preventDefault();
  }

  @HostListener('drop', ['$event']) onDrop(event: any) {
    // do not allow to drop content
    event.preventDefault();
  }

  @HostListener('input', ['$event.target.value'])
  onInput(viewValue: string) {
    // Auto-format the value in the input while the user still types. This is to be used e.g. when the user
    // writes a dot, comma or the current locale-specific decimal separator -> the separator shown
    // will always be the locale-specific one.
    viewValue = this.autoFormatViewValue(viewValue);

    const modelValue = this.viewToModelValue(viewValue, this.focused);

    // When the parsed model value is invalid, we go back to the previous value.
    if (modelValue === false) {
      this.setInputValue(this.latestInputValue);
      return;
    }

    this.setInputValue(viewValue);
    this.setModelValue(modelValue);
  }

  protected modelValue: number | null = null;

  /** This stores the latest value of the input element, so we can roll back the value if the new one is invalid. */
  protected latestInputValue = '';

  protected readonly localeId: string;
  protected readonly localizedDecimalSeparator: string;
  protected readonly localizedGroupSeparator: string;
  protected readonly validInputDecimalSeparators: string[];

  /** Whether the input is focused (= user is editing it) or not. */
  protected focused = false;

  /** View -> model callback called when value changes. */
  protected onChange: (value: any) => void = () => {};

  /** View -> model callback called when autocomplete has been touched. */
  protected onTouched = () => {};

  protected constructor(
    protected elementRef: ElementRef<HTMLInputElement>,
    protected i18nService: BszI18nService,
    protected renderer: Renderer2
  ) {
    this.localeId = i18nService.localeId;
    this.localizedDecimalSeparator = getLocaleNumberSymbol(this.localeId, NumberSymbol.Decimal);
    this.localizedGroupSeparator = getLocaleNumberSymbol(this.localeId, NumberSymbol.Group);

    const separators = new Set(['.', ',', this.localizedDecimalSeparator]); // prevent duplicates
    this.validInputDecimalSeparators = [...separators];
  }

  // Implemented as part of ControlValueAccessor.
  registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }

  // Implemented as part of ControlValueAccessor.
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  // Implemented as part of ControlValueAccessor.
  writeValue(value: any) {
    this.modelValue = this.isNumberValue(value) ? Number(value) : null;
    this.updateViewValue();
  }

  protected setModelValue(value: number | null) {
    this.modelValue = value;
    this.onChange(this.modelValue);
  }

  protected setInputValue(viewValue: string) {
    this.elementRef.nativeElement.value = viewValue;
    this.latestInputValue = viewValue;
  }

  /**
   * Update the value displayed in the input based on the model value and other parameters (e.g. decimal length).
   */
  protected updateViewValue() {
    this.setInputValue(this.modelToViewValue(this.modelValue, this.focused));

    // There are some cases where the view value should modify the model value. For example when the model value
    // is passed from the outside and it has more decimals than we are showing, the view and model values will get
    // out of sync (since the model value will have more decimals than the view value). In these cases the model
    // value we get back from the view value differs from the model value stored in the component, so we'll update
    // the model value to get them back in sync.
    // (The same should happen when we don't allow negative values, but the model value was set to negative value.)
    let modelValue = this.viewToModelValue(this.elementRef.nativeElement.value, this.focused);

    // If the parsed model value is invalid, this means the original model value was invalid, and the only
    // sensible thing to do now is to set the model value to null and view value to empty.
    if (modelValue === false || modelValue === null) {
      modelValue = null;
      this.setInputValue('');
      this.setModelValue(null);
    }

    if (modelValue && modelValue !== this.modelValue) {
      this.setModelValue(modelValue);

      // This scenario means the value of the input changes its value by itself (without a user input),
      // so in order to make the input behave correctly, we need to simulate user input by calling onTouched.
      this.onTouched();
    }
  }

  /**
   * Transform model value (internal value stored in the component) to the view value (shown in the input).
   */
  protected modelToViewValue(value: number | null, isFocused: boolean): string {
    if (value === null) {
      return '';
    }

    // When negative values are not allowed and yet we do have a negative value, we go to an empty value.
    if (!this._allowNegative && value < 0) {
      return '';
    }

    // When roundValue is active, it can format the value directly by using
    // formatNumber function with digitsInfo parameter based on decimals length
    if (this._roundValue) {
      const formattedValue = this.formatValue(value, isFocused);
      // when it is focused, the minus sign is different from whe it is not
      return this.updateMinusSign(formattedValue, isFocused);
    }

    const signPrefix = value >= 0 ? '' : this.getMinusSign(isFocused);

    // Format integer and decimal part fo the number separately to avoid issues with rounding
    const integer = Math.trunc(Math.abs(value));
    const formattedInteger = this.formatInteger(integer, isFocused);

    const formattedDecimals = this.formatDecimals(value);

    return `${signPrefix}${formattedInteger}${formattedDecimals}`;
  }

  /**
   * Transform view value (shown in the input) to model value (internal value stored in the component).
   * This will return number or null for a valid value (number or an empty value, respectively) or false
   * in case where the value is invalid (too many digits).
   */
  protected viewToModelValue(value: string, isFocused: boolean): number | null | false {
    if (!isFocused) {
      // remove group separators
      const groupSeparatorRegExp = new RegExp(`(\\${this.localizedGroupSeparator})`, 'gm');
      value = value.replace(groupSeparatorRegExp, '');
    }

    // change decimal separator from locale-specific to standard javascript one
    value = value.replace(this.localizedDecimalSeparator, '.');

    // change to valid minus sign
    value = this.updateMinusSign(value, true);

    // Values that have too many digits would cause JavaScript to apply some rounding, so the model value would
    // end up being different than the value entered (e.g. entering "9999999999999999" will end up as 10000000000000000,
    // very large values will end up in scientific notation, etc.). To prevent this, we won't accept inputs
    // with too many digits.
    if (this.isOverMaxLength(value)) {
      return false;
    }

    if (!this.isNumberValue(value)) {
      return null;
    }

    return Number(value);
  }

  /**
   * Do some auto-formatting of the view value. This is to re-format user input even while the user
   * is still typing.
   */
  protected autoFormatViewValue(value: string) {
    const decimalSeparator = this.localizedDecimalSeparator;

    // Normalize decimal separator to the locale-specific one.
    const decimalSeparatorRegExpPattern = this.validInputDecimalSeparators
      .map((separator) => `\\${separator}`)
      .join('|');
    const decimalSeparatorRegExp = new RegExp(decimalSeparatorRegExpPattern);
    value = value.replace(decimalSeparatorRegExp, decimalSeparator);

    if (!this._allowNegative) {
      value = value.replace('-', '');
    }

    // If we have only a decimal separator, add a zero before it.
    if (value === decimalSeparator) {
      return `0${decimalSeparator}`;
    }
    // If we have only the minus sign and the decimal separator, add a zero between
    if (value === `-${decimalSeparator}`) {
      return `-0${decimalSeparator}`;
    }
    return value;
  }

  /**
   * Format the number with its decimals rounded based on whether we are in focused state or not.
   */
  protected formatValue(value: number, isFocused: boolean): string {
    if (isFocused) {
      return `${value}`;
    } else {
      const digitsInfo = `1.${this._decimalLength}-${this._decimalLength}`;
      return formatNumber(value, this.localeId, digitsInfo);
    }
  }

  /**
   * Format the integer part of the number based on whether we are in focused state or not.
   */
  protected formatInteger(value: number, isFocused: boolean): string {
    if (isFocused) {
      return `${value}`;
    } else {
      const digitsInfo = '1.0-0';
      return formatNumber(value, this.localeId, digitsInfo);
    }
  }

  /**
   *  Get the decimals part considering its length and the _decimalLength.
   *  If decimals are allowed and it is defined, when the value does not reach the length,
   *  it adds as many zeros as required
   */
  protected formatDecimals(value: number): string {
    if (this._decimalLength === 0) {
      return '';
    }

    let decimals = `${value}`.split('.')[1] || '';

    if (this._decimalLength !== undefined) {
      if (this._decimalLength - decimals.length > 0) {
        const zerosToAdd = this._decimalLength - decimals.length;
        [...Array(zerosToAdd)].forEach(() => {
          decimals += '0';
        });
      }
    }

    return decimals ? this.localizedDecimalSeparator + decimals.substr(0, this._decimalLength) : '';
  }

  protected handleFieldKeydown(event: KeyboardEvent) {
    if (!this.isValidKey(event) || this.isDecimalMaxLengthReached(event) || this.isNotAllowedSeparatorLocation(event)) {
      event.preventDefault();
      return;
    }
  }

  /**
   * Check if it is a valid key, so:
   *    it is number or...
   *    it is not a character or...
   *    it is decimal separator, decimals are allowed and it doesn't have decimals or...
   *    it is combined with ctrl key (for instance, for copy, etc.)
   */
  protected isValidKey(event: KeyboardEvent): boolean {
    const eventKey = event.key;
    // if it is a functional key, it is not a character and its length is longer than 1
    if (eventKey.length !== 1) {
      return true;
    }
    // if it is control key, or a number or the valid sign for minus character
    if (event.ctrlKey || this.isNumberValue(eventKey) || this.isValidMinusSign(event)) {
      return true;
    }

    // if it is a valid separator key, check if decimals are allowed and it is in a valid position
    if (this.validInputDecimalSeparators.includes(eventKey)) {
      const isDecimalAllowed = this._decimalLength === undefined || this._decimalLength > 0;
      if (isDecimalAllowed) {
        const input = this.elementRef.nativeElement;
        const inputValue = input.value;
        const {separator, position} = this.getDecimalSeparatorInfo(inputValue, this.validInputDecimalSeparators);
        const hasDecimals = separator !== undefined;
        const {selectionStart, selectionEnd} = this.getSelectionInfo(input);
        const isDecimalSeparatorSelected = selectionStart <= position && position < selectionEnd;

        // it is valid if there are no decimals in the value or if there are and the separator key is selected (if it is
        // selected, when typing it will be removed, so it is like if there are no decimals)
        return !hasDecimals || isDecimalSeparatorSelected;
      }
    }

    return false;
  }

  /**
   * Check if it is the minus sign and if it is allowed:
   *    * if allowNegative is set to true
   *    * if it is in the first position
   *    * if the value does not have it already
   */
  protected isValidMinusSign(event: KeyboardEvent): boolean {
    const input = this.elementRef.nativeElement;
    const inputValue = input.value;
    const isMinusSign = event.key === '-';

    if (isMinusSign && !this._allowNegative) {
      return false;
    }

    const {selectionStart, selectionEnd} = this.getSelectionInfo(input);
    const isCorrectPosition = this.getCursorPosition(input) === 0 || selectionStart === 0;
    const isMinusSignSelected = inputValue.indexOf('-') === -1 || (selectionStart <= 0 && selectionEnd > 0);

    return isMinusSign && isCorrectPosition && isMinusSignSelected;
  }

  /**
   * Check if a decimal is going to be added and if the number of decimals already added
   * would not allow it. Returns true when trying to add a decimal and no more decimals are
   * allowed
   */
  protected isDecimalMaxLengthReached(event: KeyboardEvent): boolean {
    const eventKey = event.key;
    const isNumberKey = this.isNumberValue(eventKey);
    const input = this.elementRef.nativeElement;
    const inputValue = input.value;
    const {separator, position} = this.getDecimalSeparatorInfo(inputValue, this.validInputDecimalSeparators);

    if (separator === undefined) {
      return false;
    }

    const cursorPosition = this.getCursorPosition(input);
    const isCursorAfterDecimalSeparator = cursorPosition === -1 ? false : position < cursorPosition;
    const {selectionStart, selectionEnd} = this.getSelectionInfo(input);
    const isSelectingDecimals = selectionStart !== selectionEnd && position < selectionEnd;
    const isAddingDecimal = isNumberKey && isCursorAfterDecimalSeparator && !isSelectingDecimals;
    const isMaxDecimalReached =
      this._decimalLength !== undefined && this.getDecimalPositionsLength(inputValue) >= this._decimalLength;

    return isAddingDecimal && isMaxDecimalReached;
  }

  /**
   * Check whether the decimal separator is going to be added in a position that would
   * mean the result has more decimals than allowed
   */
  protected isNotAllowedSeparatorLocation(event: KeyboardEvent): boolean {
    const eventKey = event.key;
    const isDecimalSeparatorKey = this.validInputDecimalSeparators.indexOf(eventKey) > -1;
    const input = this.elementRef.nativeElement;
    const inputValue = input.value;
    const cursorPosition = this.getCursorPosition(input);
    const isDecimalLengthDefined = this._decimalLength !== undefined;
    const isLocationForMoreDecimalsThanAllowed = inputValue.length - cursorPosition > (this._decimalLength || 0);

    return isDecimalLengthDefined && isDecimalSeparatorKey && isLocationForMoreDecimalsThanAllowed;
  }

  protected getDecimalSeparatorInfo(
    value: string,
    decimalSeparator: string | string[]
  ): {separator: string; position: number} {
    const validSeparators = typeof decimalSeparator === 'string' ? [decimalSeparator] : decimalSeparator;
    // check whether there is already a separator
    const separator = validSeparators.filter((sep) => value.indexOf(sep) > -1)[0];
    // if there is separator, there are decimals
    const position = value.indexOf(separator);

    return {separator, position};
  }

  protected getDecimalPositionsLength(value: string): number {
    const position = this.getDecimalSeparatorInfo(value, this.localizedDecimalSeparator).position;
    if (position === -1) {
      return 0;
    }

    return value.length - position - 1;
  }

  protected getSelectionInfo(inputField: HTMLInputElement): {selectionStart: number; selectionEnd: number} {
    const selectionStart = inputField.selectionStart as number;
    const selectionEnd = inputField.selectionEnd as number;
    return {selectionStart, selectionEnd};
  }

  protected getCursorPosition(inputField: HTMLInputElement): number {
    return inputField.selectionEnd as number;
  }

  protected isNumberValue(value: any): boolean {
    // parseFloat(value) handles most of the cases we're interested in (it treats null, empty string,
    // and other non-number values as NaN, where Number just uses 0) but it considers the string
    // '123hello' to be a valid number. Therefore we also check if Number(value) is NaN.
    return !isNaN(parseFloat(value)) && !isNaN(Number(value));
  }

  protected isOverMaxLength(viewValue: string) {
    // Max safe number of digits before the floating point precision errors start appearing. Based on
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/MAX_SAFE_INTEGER
    const maxTotalLength = 15;

    // Remove minus sign, since we don't care about it here.
    viewValue = viewValue.replace('-', '');

    // it splits with period ('.') because the value that comes already
    // has its decimal separator replaced with it
    const parts = viewValue.split('.');
    const integerLength = parts[0].length;
    const decimalLength = parts[1] ? parts[1].length : 0;

    if (this._decimalLength !== undefined) {
      // If we have set amount of decimals, we can specify and check max lengths of both integer and decimal parts.
      // We will only allow an amount of digits for the integer part such that the total amount of digits
      // will be less than the max threshold.
      const maxDecimalLength = this._decimalLength;
      const maxIntegerLength = maxTotalLength - maxDecimalLength;

      return integerLength > maxIntegerLength || decimalLength > maxDecimalLength;
    } else {
      // If we don't have the maximum amount of decimal specified, we don't know how many decimals the number will
      // have. This means the only way to limit this is to check the total amount of digits, regardless of where
      // the decimal separator is specified.
      const totalLength = integerLength + decimalLength;

      return totalLength > maxTotalLength;
    }
  }

  /**
   * Returns the minus sign to use when it will be part of a
   * valid number (model value) or the formatted string (view value)
   */
  private getMinusSign(asNumber: boolean): string {
    return asNumber ? '-' : UNICODE_MINUS_SIGN;
  }

  /**
   * Returns the value with the updated minus sign depending on if it will be
   * used as number (model value) or the formatted string (view value)
   */
  private updateMinusSign(value: string, asNumber: boolean): string {
    return value.replace(this.getMinusSign(!asNumber), this.getMinusSign(asNumber));
  }
}
