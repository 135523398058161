import {Injectable} from '@angular/core';
import {BszUserProfileContactDetails} from '@basuiz/web-app-applet-api';
import {HttpClient} from '@angular/common/http';
import {catchError, combineLatest, map, Observable, shareReplay, switchMap, take, throwError} from 'rxjs';
import {AfpRestOperators, AfpRestResponse, UserPersonService} from '@basuiz/web-app-common';
import {handleDaaSChangeAddressErrorResponse} from '../utils/handle-daas-change-address-error-response';

// https://bitbucket.sits.basuiz.net/projects/DAAS-MASTER/repos/rest-wb/browse/rest-service/src/main/java/com/basuiz/afpaas/master/rest/service/changeaddress/ContactDetails.java
interface UserProfileContactDetailsDto {
  readonly mobileNumber?: string;
  readonly phoneNumber?: string;
  readonly emailAddress?: string;
  readonly faxNumber?: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserProfileContactDetailsService {
  private readonly uri$: Observable<string> = this.userPersonService.userPerson$.pipe(
    take(1),
    map(
      // (userPerson) => `/com.basuiz.afpaas.master.rest.services/changeaddress/persons/${userPerson.id}/contactDetails`
      (userPerson) => `/com.basuiz.afpaas.master.rest.services/changeaddress/persons/${123}/contactDetails`
    ),
    shareReplay(1)
  );

  private readonly dto$: Observable<UserProfileContactDetailsDto> = this.uri$.pipe(
    switchMap((uri) => this.httpClient.get<AfpRestResponse<UserProfileContactDetailsDto>>(uri)),
    AfpRestOperators.extractPayload()
  );

  readonly contactDetails$: Observable<BszUserProfileContactDetails> = this.dto$.pipe(
    map(({phoneNumber, mobileNumber, emailAddress, faxNumber}) => {
      const result: BszUserProfileContactDetails = {phoneNumber, mobileNumber, emailAddress, faxNumber};
      return result;
    })
  );

  constructor(private readonly httpClient: HttpClient, private readonly userPersonService: UserPersonService) {}

  updateContactDetails(newContactDetails: BszUserProfileContactDetails): Observable<void> {
    function setFieldsToErase(details: BszUserProfileContactDetails) {
      return Object.entries(details).reduce(
        (parsedDetails, [key, value]) => ({...parsedDetails, [key]: value === undefined ? null : value}),
        {}
      );
    }

    return combineLatest([this.uri$, this.dto$]).pipe(
      switchMap(([uri, currentContactDetails]) => {
        const body: UserProfileContactDetailsDto = {
          ...currentContactDetails,
          ...setFieldsToErase(newContactDetails),
        };
        return this.httpClient.put(uri, body);
      }),
      map(() => undefined),
      catchError((error) => throwError(() => handleDaaSChangeAddressErrorResponse(error)))
    );
  }
}
