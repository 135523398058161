import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {
  ApiHttpErrorCodes,
  AuthRoutes,
  IUser,
  UserJwt,
  UserJwtAccessTokenResponse,
  UserProfile,
  UserPublic,
  UserResetPasswordPreValidatedReq,
  UserResetPasswordSuccessRes,
  UserVerified,
  propertyOf,
} from '@basuiz/shared/data-access';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AfpRestOperators, AfpRestResponse} from '../../afp-rest';
import {WEB_APP_REBOOT_HANDLER, WebAppRebootHandler} from '../../reboot';
import {NotificationService} from '../../ui-notification';
import {jwtTokenKey} from '../models/auth.definitions';

@Injectable({
  providedIn: 'root',
})
export class AuthJwtService {
  // private readonly baseUrl: string = '/com.basuiz.api'; // to be used with mock-server & proxy.config.json
  private readonly baseUrl: string = `${process.env.API_BASE_URL}`;

  private userJwt: BehaviorSubject<UserJwt | null> = new BehaviorSubject(null);

  constructor(
    @Inject(WEB_APP_REBOOT_HANDLER) private rebootHandler: WebAppRebootHandler,
    private http: HttpClient,
    private helper: JwtHelperService,
    private translateService: TranslateService,
    private notificationService: NotificationService
  ) {
    this.checkToken();
  }

  get userJwt$(): Observable<UserJwt | null> {
    return this.userJwt.asObservable();
  }

  public setAuthUser(user: UserJwt | null): void {
    this.userJwt.next(user);
  }

  signin(email: string, password: string): Observable<UserJwt> {
    return this.http
      .post<AfpRestResponse<UserJwtAccessTokenResponse>>(`${this.baseUrl}/${AuthRoutes.signin.apiEndpoint}`, {
        email,
        password,
      })
      .pipe(
        AfpRestOperators.extractPayload(),
        map((userSignedInResponse) => {
          if (!userSignedInResponse.accessToken) {
            this.notificationService.error(this.translateService.instant('web-app-common.auth-jwt.api-not-available'));
            return {} as UserJwt;
          }

          let userTemp: UserJwt = this.helper.decodeToken(userSignedInResponse.accessToken);

          if (!userTemp?.user?.objectId) {
            this.notificationService.error(this.translateService.instant('web-app-common.auth-jwt.api-not-available'));
            return {} as UserJwt;
          }

          localStorage.setItem(jwtTokenKey, userSignedInResponse.accessToken);
          this.userJwt.next(userTemp);
          return userTemp;
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.error.message === ApiHttpErrorCodes.userIsNotActive) {
            this.notificationService.error(
              this.translateService.instant('web-app-common.auth-jwt.signin.user-is-not-active')
            );
          } else if (error.error.message === ApiHttpErrorCodes.emailPasswordIncorrect) {
            this.notificationService.error(
              this.translateService.instant('web-app-common.auth-jwt.signin.form.email-or-password-incorrect')
            );
          } else {
            // TODO p4- later: used when backend is down, find a better way to handle this error
            this.notificationService.error(this.translateService.instant('web-app-common.auth-jwt.api-not-available'));
          }
          return of({} as UserJwt);
        })
      );
  }

  signup(user: Partial<IUser> | UserProfile): Observable<UserPublic> {
    return this.http
      .post<AfpRestResponse<UserPublic>>(`${this.baseUrl}/${AuthRoutes.signup.apiEndpoint}`, {...user})
      .pipe(
        AfpRestOperators.extractPayload(),
        catchError((error: HttpErrorResponse) => {
          if (error.error.message === ApiHttpErrorCodes.emailDuplicate) {
            this.notificationService.error(
              this.translateService.instant('web-app-common.auth-jwt.signup.email-is-duplicate')
            );
          } else if (error.error.error === 'Bad Request') {
            this.notificationService.error(
              this.translateService.instant('web-app-common.auth-jwt.signup.user-info-submitted-has-errors')
            );
          } else {
            // TODO p4- later: used when backend is down, find a better way to handle this error
            this.notificationService.error(this.translateService.instant('web-app-common.auth-jwt.api-not-available'));
          }
          return of({} as IUser);
        })
      );
  }

  signout() {
    localStorage.removeItem(jwtTokenKey);
    this.userJwt.next(null);
    this.rebootHandler({event: 'userSettingsUpdated'}); // Reboot is fired here
  }

  // Loaded current user from storage
  checkToken() {
    let userTemp = null;
    const jwtToken = localStorage.getItem(jwtTokenKey);

    if (jwtToken) {
      userTemp = this.helper.decodeToken(jwtToken);
      const isTokenExpired = this.helper.isTokenExpired(jwtToken);

      if (isTokenExpired) {
        // Token is expired
        return this.signout();
      }

      this.userJwt.next(userTemp);
    } else {
      // No token found in storage
      this.userJwt.next(null);
    }
  }

  emailVerify(user: Partial<IUser>): Observable<UserVerified> {
    return this.http
      .get<AfpRestResponse<UserVerified>>(
        `${this.baseUrl}/${AuthRoutes.emailVerify.apiEndpoint}?${propertyOf<IUser>('email')}=${
          user.email
        }&${propertyOf<IUser>('emailVerifyToken')}=${user.emailVerifyToken}`
      )
      .pipe(AfpRestOperators.extractPayload());
  }

  resetPasswordRequest(email: string): Observable<UserResetPasswordSuccessRes> {
    return this.http
      .get<AfpRestResponse<UserResetPasswordSuccessRes>>(
        `${this.baseUrl}/${AuthRoutes.resetPasswordRequest.apiEndpoint}?${propertyOf<IUser>('email')}=${email}`
      )
      .pipe(AfpRestOperators.extractPayload());
  }

  resetPasswordPreValidate(user: UserResetPasswordPreValidatedReq): Observable<UserResetPasswordSuccessRes> {
    return this.http
      .post<AfpRestResponse<UserResetPasswordSuccessRes>>(
        `${this.baseUrl}/${AuthRoutes.resetPasswordPreValidate.apiEndpoint}`,
        user
      )
      .pipe(AfpRestOperators.extractPayload());
  }

  resetPasswordValidate(user: Partial<IUser>): Observable<UserResetPasswordSuccessRes> {
    return this.http
      .post<AfpRestResponse<UserResetPasswordSuccessRes>>(
        `${this.baseUrl}/${AuthRoutes.resetPasswordValidate.apiEndpoint}`,
        user
      )
      .pipe(AfpRestOperators.extractPayload());
  }
}
