import {NavigationIntent} from '../../navigation/classes/navigation-intent';

export interface ShowPaymentMethodWalletOverviewIntentPayload {
  foo?: never;
}

export class ShowPaymentMethodWalletOverviewIntent extends NavigationIntent {
  constructor(public payload: ShowPaymentMethodWalletOverviewIntentPayload = {}) {
    super();
  }
}
