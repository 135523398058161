import {NgModule} from '@angular/core';
import {MatChipsModule} from '@angular/material/chips';
import {EffectsModule} from '@ngrx/effects';
import {SavedFilterEffects} from './+state/saved-filter.effects';
import {StoreModule} from '@ngrx/store';
import {FEATURE_KEY, reducer} from './+state/saved-filter.reducer';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FilterFavouritesComponent} from './components/filter-favourites/filter-favourites.component';
import {WebAppCommonFilterFavouritesComponent} from './web-app-common-filter-favourites.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {MatCardModule} from '@angular/material/card';
import {WebAppBasicModule} from '../web-app-basic/index';

@NgModule({
  imports: [
    WebAppBasicModule,
    MatChipsModule,
    ReactiveFormsModule,
    FormsModule,
    EffectsModule.forFeature([SavedFilterEffects]),
    StoreModule.forFeature(FEATURE_KEY, reducer),
    OverlayModule,
    MatCardModule,
  ],
  declarations: [WebAppCommonFilterFavouritesComponent, FilterFavouritesComponent],
  exports: [WebAppCommonFilterFavouritesComponent],
})
export class WebAppCommonFilterFavouritesModule {}
