import {ComponentFixture} from '@angular/core/testing';
import {DebugElement, Type, InjectionToken, AbstractType} from '@angular/core';
import {SimpleElement} from '../generic/components';

export interface JestFixtureFacadeAware {
  jestFixtureFacade: JestFixtureFacade;
}

export class JestFixtureFacade {
  private component: any;
  private compiled: any;
  private pathFragment: string = '';

  constructor(public fixture: ComponentFixture<any>, paths?: Paths) {
    this.component = fixture.debugElement.componentInstance;
    this.compiled = fixture.debugElement.nativeElement;
    if (paths) {
      this.pathFragment = paths.originalPathFragment + paths.additionalPathFragment;
    }
  }

  getSub(pathFragment: string) {
    return new JestFixtureFacade(this.fixture, {
      originalPathFragment: this.pathFragment,
      additionalPathFragment: `${pathFragment} `,
    });
  }

  select(testId: string): Element {
    return this.compiled.querySelector(`${this.pathFragment}[test-id="${testId}"]`);
  }

  selectAsSimpleElement(testId: string): SimpleElement {
    return {
      testId,
      textContent: this.select(testId)?.textContent,
    };
  }

  selectAll(testId: string): NodeListOf<Element> {
    return this.compiled.querySelectorAll(`${this.pathFragment}[test-id="${testId}"]`);
  }

  selectFromParent(testId: string): Element {
    return this.compiled.parentNode.querySelector(`[test-id="${testId}"]`);
  }

  selectFromParentAsSimpleElement(testId: string): SimpleElement {
    return {
      testId,
      textContent: this.selectFromParent(testId).textContent,
    };
  }

  selectAllAsSimpleElements(testId: string): SimpleElement[] {
    return Array.from(this.selectAll(testId)).map((element) => ({
      testId,
      textContent: element.textContent,
    }));
  }

  selectAllFromParent(testId: string): NodeListOf<Element> {
    return this.compiled.parentNode.querySelectorAll(`${this.pathFragment}[test-id="${testId}"]`);
  }

  selectAllFromParentHavingPrefix(testIdPrefix: string): NodeListOf<Element> {
    return this.compiled.parentNode.querySelectorAll(`${this.pathFragment}[test-id^="${testIdPrefix}"]`);
  }

  selectAllFromParentAsSimpleElement(testId: string): SimpleElement[] {
    return Array.from(this.selectAllFromParent(testId)).map((element) => ({
      testId,
      textContent: element.textContent,
    }));
  }

  get debugElement(): DebugElement {
    return this.fixture.debugElement;
  }

  detectChanges() {
    this.fixture.detectChanges();
  }

  getChildComponent<T>(injectionToken: Type<T> | InjectionToken<T> | AbstractType<T>, testId: string): T | undefined {
    return this.fixture.debugElement
      .query((element) => element.nativeElement.getAttribute('test-id') === testId)
      ?.injector.get<T>(injectionToken);
  }
}

interface Paths {
  originalPathFragment: string;
  additionalPathFragment: string;
}
