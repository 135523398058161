import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export type ScreenSize = 'mobile' | 'desktop' | 'tablet';

@Injectable({
  providedIn: 'root',
})
export class BszScreenSize {
  private breakpoints = this.getBreakpoints();

  constructor(public breakpointObserver: BreakpointObserver) {}

  getScreenSize(): Observable<ScreenSize> {
    return this.breakpointObserver
      .observe([this.breakpoints.desktop, this.breakpoints.mobile, this.breakpoints.tablet])
      .pipe(
        map((state: BreakpointState) => {
          if (state.breakpoints[this.breakpoints.desktop]) {
            return 'desktop';
          }
          if (state.breakpoints[this.breakpoints.tablet]) {
            return 'tablet';
          }
          return 'mobile';
        })
      );
  }

  getBreakpoints() {
    const documentStyle = getComputedStyle(document.documentElement);

    // these breakpoints are defined in projects/ui-elements/src/styles/mixins/_media-queries.scss
    // while the values are defined in projects/ui-elements/src/styles/mixins/_variables.scss
    const desktopMinWidth = documentStyle.getPropertyValue('--bsz-theme-breakpoint-desktop') || '840px';
    const tabletMinWidth = documentStyle.getPropertyValue('--bsz-theme-breakpoint-tablet') || '600px';
    const mobileMaxWidth = `calc(${tabletMinWidth} - 1px)`;

    return {
      desktop: `(min-width: ${desktopMinWidth})`,
      tablet: `(min-width: ${tabletMinWidth})`,
      mobile: `(max-width: ${mobileMaxWidth})`,
    };
  }
}
