<div *ngIf="fields" class="mdc-layout-grid__inner bsz-padding-t4 bsz-padding-b4">
  <ng-container *ngFor="let field of fields; let numberOfFields = count">
    <div
      class="mdc-layout-grid__cell--span-12-phone bsz-padding-t1"
      [class.mdc-layout-grid__cell--span-6]="numberOfFields > 1"
      [class.mdc-layout-grid__cell--span-12]="numberOfFields === 1"
    >
      <div class="bsz-caption bsz-text-secondary">
        {{ getFieldLabel(field) }}
      </div>
      <div class="bsz-body-1 bsz-padding-t1">{{ field.value }}</div>
    </div>
  </ng-container>
</div>
