import {Injectable, SecurityContext} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable, of} from 'rxjs';
import {AfpRestOperators, AfpRestResponse, parameterizeHttpOptions} from '@basuiz/web-app-common';
import {Notification, NotificationDto, NotificationMessageStatus} from '../models/notification.definition';
import {DomSanitizer} from '@angular/platform-browser';
import {map} from 'rxjs/operators';
import {chunk} from 'lodash';

@Injectable()
export class NotificationsService {
  private readonly URLS = {
    NOTIFICATION_LIST: '/com.basuiz.afs.rest.services/rest/notification/notifications',
    STATUS_UPDATE: '/com.basuiz.afs.rest.services/rest/notification/statusUpdate',
  };

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  getNotificationList(options?: {status?: NotificationMessageStatus; category?: string}): Observable<Notification[]> {
    const params = parameterizeHttpOptions(options ?? {});

    return this.http.get<AfpRestResponse<NotificationDto[]>>(this.URLS.NOTIFICATION_LIST, {params}).pipe(
      AfpRestOperators.extractPayload(),
      map((notifications) =>
        notifications
          .filter(
            (notification) =>
              notification.status !== NotificationMessageStatus.ERROR &&
              // AFP-44351 filter out notifications without a category
              notification.category
          )
          .map((notification) => ({
            ...notification,
            content: this.sanitizer.sanitize(SecurityContext.HTML, notification.content) ?? '',
          }))
      )
    );
  }

  updateStatus(options: {
    idList: number[];
    targetStatus: NotificationMessageStatus;
  }): Observable<AfpRestResponse<{}>[]> {
    if (options.idList?.length > 0) {
      // TODO AFP-43709 remove this workaround:
      // In case we update a large list of notifications, this service fails, so we update the status in chunks of 100
      const chunksOf100 = chunk(options.idList, 100);

      return forkJoin(
        chunksOf100.map((aChunk) =>
          this.http.post<AfpRestResponse<{}>>(this.URLS.STATUS_UPDATE, {
            idList: aChunk,
            targetStatus: options.targetStatus,
          })
        )
      );
    }
    return of([]);
  }
}
