const supportedDateFormats = [
  // For matching ISO dates like '2017-09-22'
  /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/,
  // For matching ISO dates like '2019-12-17T13:07:02Z', '2020-03-04T07:57:38', '2020-03-04T06:53:45.852'
  /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])T([0-1]\d|2[0-3]):([0-5]\d):([0-5]\d)Z*(\.(\d{1,3}))*$/,
];

/**
 * Check whether a string date is formatted in ISO 8601
 */
export function isDateFormatSupported(dateString: string | null | undefined): boolean {
  if (typeof dateString !== 'string') {
    return false;
  }

  for (const formatRegExp of supportedDateFormats) {
    if (formatRegExp.test(dateString)) {
      return true;
    }
  }

  return false;
}
