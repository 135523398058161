import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AfpRestOperators, AfpRestResponse} from '../../afp-rest/index';

@Injectable({providedIn: 'root'})
export class BankDateDateService {
  constructor(private httpClient: HttpClient) {}

  private url: string = '/com.basuiz.afs.rest.services/rest/baseBankData/businessUnitBankDate';

  public fetch(): Observable<string> {
    return this.httpClient.get<AfpRestResponse<string>>(this.url, {}).pipe(AfpRestOperators.extractPayload());
  }
}
