import {ComponentType} from '@angular/cdk/portal';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  Injectable,
  Injector,
  Optional,
} from '@angular/core';
import {first} from 'rxjs/operators';

import {BszModalBottomSheet} from './bsz-modal-bottom-sheet';
import {BszModalBottomSheetAwareComponent} from './bsz-modal-bottom-sheet-aware-component';
import {
  BSZ_MODAL_BOTTOM_SHEET_DEFAULT_OPTIONS,
  BszModalBottomSheetConfig,
  BszModalBottomSheetDefaultConfig,
  BszModalBottomSheetGlobalConfig,
} from './bsz-modal-bottom-sheet-config';

const LOG_PREFIX = '[bsz-modal-bottom-sheet-service]';

@Injectable()
export class BszModalBottomSheetService {
  private openModals: BszModalBottomSheet[] = [];

  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly appRef: ApplicationRef,
    private readonly injector: Injector,
    @Optional()
    @Inject(BSZ_MODAL_BOTTOM_SHEET_DEFAULT_OPTIONS)
    private readonly globalConfig: BszModalBottomSheetGlobalConfig | null
  ) {}

  /**
   * Opens a modal containing the given component.
   *
   * @param template Type of the component which holds the template references to add into the modal.
   * @param config Extra configuration options.
   *
   * @returns Reference to the newly created modal.
   */
  open<T extends BszModalBottomSheetAwareComponent>(
    template: ComponentType<T>,
    config: BszModalBottomSheetConfig = {}
  ): BszModalBottomSheet<T> {
    const modalConfig = {...new BszModalBottomSheetDefaultConfig(), ...(this.globalConfig || {}), ...config};

    const bottomSheetRef = this.createBottomSheetInstance<T>(this.injector);
    const bottomSheetInstance = bottomSheetRef.instance;

    const templateRef = this.createBottomSheetTemplateInstance<T>(template, bottomSheetInstance, this.injector);
    const templateInstance = templateRef.instance;

    bottomSheetInstance.templateInstance = templateInstance;

    // Assign config
    bottomSheetInstance.hasBackdrop = modalConfig.hasBackdrop;
    bottomSheetInstance.offsetTop = modalConfig.offsetTop;
    bottomSheetInstance.maxWidth = modalConfig.maxWidth;
    bottomSheetInstance.ariaLabel = modalConfig.ariaLabel;
    bottomSheetInstance.ariaLabelledby = modalConfig.ariaLabelledby;
    bottomSheetInstance.ariaDescribedby = modalConfig.ariaDescribedby;
    bottomSheetInstance.disableEscape = modalConfig.disableEscape;
    bottomSheetInstance.data = modalConfig.data;
    bottomSheetInstance.panelClass = modalConfig.panelClass;

    // Assign template definitions
    if (!templateInstance.contentDef) {
      throw new Error(
        `${LOG_PREFIX} No template definition found for content. Did you define the [*bszModalBottomSheetContentDef] directive on your template?`
      );
    }
    bottomSheetInstance.title = templateInstance.titleDef;
    bottomSheetInstance.content = templateInstance.contentDef;
    bottomSheetInstance.footer = templateInstance.footerDef;

    // Attach components to the application and ignite the lifecycle hooks
    this.appRef.attachView(bottomSheetRef.hostView);
    this.appRef.attachView(templateRef.hostView);

    // Manually process the change detection and its side-effects to prevent:
    // "ERROR TypeError: Cannot read property 'createEmbeddedView' of undefined"
    this.appRef.tick();

    this.initBottomSheetInstanceSubscriptions(bottomSheetInstance);

    this.openModals.push(bottomSheetInstance);

    bottomSheetInstance.open();

    return bottomSheetInstance;
  }

  /**
   * Closes all the opened modals.
   */
  closeAll() {
    this.openModals.forEach((dialog) => dialog.close());
    this.openModals = [];
  }

  /**
   * Minimizes the modals starting from the position of the given reference and back.
   */
  private minimizeOthers(modalBottomSheetRef: BszModalBottomSheet) {
    const modalIndex = this.openModals.indexOf(modalBottomSheetRef);
    this.openModals.filter((_, index) => index < modalIndex).forEach((modalRef) => modalRef.minimize());
  }

  /**
   * Remove a modal reference from the stack and Open the one that's immediately before it.
   * If there are opened modals after it they will be closed and removed as well.
   */
  private removeReferenceAndOpenPrevious(modalBottomSheetRef: BszModalBottomSheet) {
    const indexOfModalRef = this.openModals.indexOf(modalBottomSheetRef);

    if (indexOfModalRef < 0) {
      return;
    }

    // Remove modals that have been opened later than the current one.
    const modalsToRemove = this.openModals.splice(indexOfModalRef, this.openModals.length);
    modalsToRemove.forEach((modalRef) => modalRef.close(modalRef.outputData));

    // Open the previous modal if exists.
    const previousActiveModal = this.openModals[indexOfModalRef - 1];
    if (previousActiveModal) {
      previousActiveModal.open();
    }
  }

  private initBottomSheetInstanceSubscriptions(bottomSheetInstance: BszModalBottomSheet): void {
    // Subscribe to open/close events and keep the reference of the applied subscriptions.
    const subscriptions = [
      bottomSheetInstance._onClose.subscribe(() => this.removeReferenceAndOpenPrevious(bottomSheetInstance)),
      bottomSheetInstance.afterOpened.subscribe(() => this.minimizeOthers(bottomSheetInstance)),
    ];

    // Clear subscriptions when the modal is closed.
    bottomSheetInstance.afterClosed.pipe(first()).subscribe(() => {
      subscriptions.forEach((sub) => sub.unsubscribe());
    });
  }

  private createBottomSheetInstance<T>(injector: Injector): ComponentRef<BszModalBottomSheet<T>> {
    return this.componentFactoryResolver
      .resolveComponentFactory<BszModalBottomSheet<T>>(BszModalBottomSheet)
      .create(injector);
  }

  private createBottomSheetTemplateInstance<T>(
    template: ComponentType<T>,
    modalBottomSheet: BszModalBottomSheet<T>,
    injector: Injector
  ): ComponentRef<T> {
    // Provide the instance of the ModalBottomSheet so it's accessible from
    // within the component which is used as a template for the modal.
    const componentInjector = Injector.create({
      parent: injector,
      providers: [{provide: BszModalBottomSheet, useValue: modalBottomSheet}],
    });

    const factory = this.componentFactoryResolver.resolveComponentFactory(template);
    return factory.create(componentInjector);
  }
}
