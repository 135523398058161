import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {MatMenuItem} from '@angular/material/menu';

@Component({
  // eslint-disable-next-line
  selector: '[bsz-context-menu-item]',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <ng-content></ng-content>

    <!-- This template is taken from MatMenuItem -->
    <div
      class="mat-menu-ripple"
      matRipple
      [matRippleDisabled]="disableRipple || disabled"
      [matRippleTrigger]="_getHostElement()"
    ></div>
  `,
})
export class BszContextMenuItem extends MatMenuItem {
  // Extending MatMenuItem to not expose any angular material elements outside of the ui-sdk
  // to use it inside bsz-context-menu as a content for displaying actions inside the menu
}
