import {Inject, Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe as AngularCurrencyPipe} from '@angular/common';

import {throwInvalidPipeArgument} from '../../helpers/formatting-helpers';
import Big from 'big.js';

import {VALID_ISO_CODE_REG_EXP} from '../../const/valid-iso-code-reg-exp';
import {BszI18nService} from '@basuiz/i18n';
import {postProcessLocalizedNumericValue} from './post-process-localized-numeric-value';
import {
  ɵCURRENCY_AMOUNT_FORMATTERS,
  CurrencyAmountFormatterOptions,
  CurrencyAmountFormatters,
} from '../../formatters/currency-amount.formatters';
import {isNullOrUndefined} from '../../../utils/defined.util';

@Pipe({name: 'bszCurrency'})
export class BszCurrencyPipe implements PipeTransform {
  constructor(
    private i18nService: BszI18nService,
    private angularCurrencyPipe: AngularCurrencyPipe,
    @Inject(ɵCURRENCY_AMOUNT_FORMATTERS) private customFormatters: CurrencyAmountFormatters
  ) {}

  private isIsoCodeParamValid(isoCode: string | null | undefined): isoCode is string {
    const isString = typeof isoCode === 'string';
    const isValidFormat = isString && !!isoCode?.match(VALID_ISO_CODE_REG_EXP);
    return isValidFormat;
  }

  public transform(
    input: number | Big | null | undefined,
    isoCode: string | null | undefined,
    digitsInfo?: string,
    showISOCode: boolean = true
  ): string | null {
    if (isNullOrUndefined(input) || isNullOrUndefined(isoCode)) {
      // return empty string for nil params
      return '';
    } else if (typeof input !== 'number' && !(input instanceof Big)) {
      // if value is not of an accepted type then fail
      throwInvalidPipeArgument(BszCurrencyPipe, input);
    } else if (!this.isIsoCodeParamValid(isoCode)) {
      // if ISO code is not of an accepted type then fail
      throwInvalidPipeArgument(BszCurrencyPipe, isoCode);
    } else {
      const inputAsNumber: number = Number(input);
      const formatted: string | null = this.formatValue(inputAsNumber, isoCode, showISOCode, digitsInfo);
      return formatted ? postProcessLocalizedNumericValue(input, formatted) : formatted;
    }
  }

  private formatValue(amount: number, isoCode: string, showIsoCode: boolean, digitsInfo?: string): string | null {
    const localeId = this.i18nService.localeId;
    const customFormatter = this.customFormatters[localeId];
    if (customFormatter) {
      const options: CurrencyAmountFormatterOptions = {showIsoCode, digitsInfo};
      return customFormatter(amount, isoCode, options);
    } else {
      const display: string = showIsoCode ? 'code' : '';
      return this.angularCurrencyPipe.transform(amount, isoCode, display, digitsInfo, localeId);
    }
  }
}
