import {PageObjectComponentFactory} from '../generic/page-object-component-factory';
import {
  FormFieldMatInput,
  FormFieldMatSelect,
  MatButton,
  MatButtonToggleGroup,
  MatMenu,
  SimpleElement,
  BszTable,
  SimpleElementList,
  MatTabGroup,
  MatSlideToggle,
} from '../generic/components';

export class SimplePageObjectComponentFactory implements PageObjectComponentFactory {
  matButton(testId: string): MatButton {
    return <MatButton>{testId};
  }

  matButtonToggleGroup(testId: string): MatButtonToggleGroup {
    return <MatButtonToggleGroup>{testId};
  }

  matInput(testId: string): FormFieldMatInput {
    return <FormFieldMatInput>{testId};
  }

  matMenu(testId: string): MatMenu {
    return <MatMenu>{testId};
  }

  matSelect<T = string>(testId: string): FormFieldMatSelect<T> {
    return <FormFieldMatSelect<T>>{testId};
  }

  matTabGroup(testId: string): MatTabGroup {
    return <MatTabGroup>{testId};
  }

  matSlideToggle(testId: string): MatSlideToggle {
    return <MatSlideToggle>{testId};
  }

  simpleElement(testId: string): SimpleElement {
    return <SimpleElement>{testId};
  }

  simpleElementList(testId: string): SimpleElementList {
    return <SimpleElementList>{testId};
  }

  list<T extends Object>(testId: string, callback: (factory: PageObjectComponentFactory) => T): T {
    const subElements: T = callback(this);
    return new Proxy(<T>{}, {
      get(target: T, p: PropertyKey, receiver: any): any {
        if ('testId' === p) {
          return testId;
        }
        // @ts-ignore as can't please TS to assume p is a field of Object type
        return subElements[p];
      },
    });
  }

  bszTable(testId: string): BszTable {
    return <BszTable>{testId};
  }
}
