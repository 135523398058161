import {NavigationIntent} from '../../navigation/classes/navigation-intent';

export interface ShowNotificationRelatedSectionIntentPayload {
  notificationCategoryId: number;
}

export class ShowNotificationRelatedSectionIntent extends NavigationIntent {
  constructor(public payload: ShowNotificationRelatedSectionIntentPayload) {
    super();
  }
}
