import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {AFPUserRole} from '@basuiz/web-app-applet-sdk';

export const afpRolesLocalStoreKey = 'bsz.web-app-dev-tools.afp-roles';

@Injectable()
export class UserRolesInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.shouldIntercept(req)) {
      return of(new HttpResponse({status: 200, body: this.getFormattedResponse()}));
    } else {
      return UserRolesInterceptor.noop(req, next);
    }
  }

  private shouldIntercept(req: HttpRequest<any>): boolean {
    return (
      req.url.toString().includes('/rest/user/accessAndRoles') && localStorage.getItem(afpRolesLocalStoreKey) !== null
    );
  }

  private static noop(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req);
  }

  private getFormattedResponse(): {} {
    return {
      payload: {
        userRoleList: this.getRolesFromLocalStore().map((role) => ({roleIntlId: role})),
      },
    };
  }

  private getRolesFromLocalStore(): AFPUserRole[] {
    return localStorage.getItem(afpRolesLocalStoreKey).split(',');
  }
}
