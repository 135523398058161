import {ActionWithoutResponse} from '@basuiz/mobile-bridge';

/**
 * Downloads a single document that matches the provided id from the document
 * safe REST service and displays it inside of the application on top of the
 * current content.
 */
export class ViewDocumentWithIdAction extends ActionWithoutResponse<ViewDocumentWithIdRequest> {
  readonly name = 'viewDocumentWithID';
}

export interface ViewDocumentWithIdRequest {
  /**
   * An id of a document that is stored in AFP and retrieved with document safe
   * service.
   */
  documentID: number;

  /**
   * Name that is going to be displayed in navigation bar of the displayed
   * document.
   */
  documentName: string;
}
