import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';

import {BszTableTreeRow} from '../bsz-table/bsz-table.definitions';
import {SelectionModel, SelectionState, TreeSelectionModel} from './bsz-selectable.definitions';

export type BszSelectableCheckboxType = 'select-all' | 'select-group' | 'select-row';

/**
 * An adapter to connect bszSelectableCheckbox with bszSelectable.
 */
export interface BszSelectableCheckboxAdapter {
  /** The type of the checkbox. */
  readonly type: BszSelectableCheckboxType;

  /**
   * Observable which fires when the selection state changes - checkbox should listen to this
   * and update its value accordingly (checked/unchecked/indeterminate).
   */
  readonly selectionStateChange: Observable<SelectionState>;

  /**
   * This is a method that should be called when the user changes the checked state of the checkbox.
   */
  setSelected(selected: boolean): void;
}

/** An adapter for the "select all" checkbox. */
export class AllCheckboxAdapter implements BszSelectableCheckboxAdapter {
  readonly type = 'select-all';
  readonly selectionStateChange = this.selectionModel.selectionChange.pipe(
    map((selectionChange) => selectionChange.allSelected)
  );

  constructor(private selectionModel: SelectionModel<any>) {}

  setSelected(selected: boolean) {
    this.selectionModel.setAllSelected(selected);
  }
}

/** An adapter for the "select group" checkbox. */
export class GroupCheckboxAdapter<G> implements BszSelectableCheckboxAdapter {
  readonly type = 'select-group';
  readonly selectionStateChange = this.selectionModel.selectionChange.pipe(
    map((selectionChange) => selectionChange.selectedGroupMap.get(this.group)),
    filter(isNotUndefined)
  );

  constructor(
    private selectionModel: TreeSelectionModel<any, G | BszTableTreeRow>,
    private group: G | BszTableTreeRow
  ) {}

  setSelected(selected: boolean) {
    this.selectionModel.setGroupSelected(selected, this.group);
  }
}

/**
 * An adapter for the "select row" checkbox.
 *
 * Note: We use "row" instead of "item" here, since "item" is a bit too generic here...
 */
export class RowCheckboxAdapter<T> implements BszSelectableCheckboxAdapter {
  readonly type = 'select-row';
  readonly selectionStateChange = this.selectionModel.selectionChange.pipe(
    map((selectionChange) => selectionChange.selectedItemMap.get(this.row)),
    filter(isNotUndefined)
  );

  constructor(private selectionModel: SelectionModel<T | BszTableTreeRow>, private row: T | BszTableTreeRow) {}

  setSelected(selected: boolean) {
    this.selectionModel.setItemSelected(selected, this.row);
  }
}

// We need this type guard for type inference in Observables to work properly.
// See https://medium.com/ngconf/filtering-types-with-correct-type-inference-in-rxjs-f4edf064880d#579a
function isNotUndefined<T>(input: T | undefined): input is T {
  return input !== undefined;
}
