import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AuditLogService {
  constructor(private http: HttpClient) {}

  private readonly url = '/com.basuiz.afs.rest.services/rest/auditLog/log';

  log(params: {message: string}): Observable<void> {
    return this.http.post<void>(this.url, params);
  }
}
