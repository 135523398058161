import {AlertListAppletConfig} from './alert-list-applet.config.definition';

// IMPORTANT: do not modify the signature of this function
export function getDefaultConfig(): AlertListAppletConfig {
  return {
    showAlertCounterBadges: true,

    notificationActions: {
      options: ['viewAll', 'markAllAsRead'],
      displayAs: 'contextMenu',
    },
  };
}
