import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {CallState, LoadingState} from '../../definitions/call-state.definition';
import {SavedFilter} from '../models/saved-filter.interface';
import {
  deleteSavedFilters,
  deleteSavedFilterSuccess,
  loadSavedFilterListFailed,
  loadSavedFilterListRequired,
  loadSavedFilterListSuccess,
  saveFilter,
  saveFilterSuccess,
  selectSavedFilter,
} from './saved-filter.actions';

export const FEATURE_KEY = 'web-app-common-filter-favourites/saved-filters';

export interface PartialState {
  readonly [FEATURE_KEY]: State;
}

interface SavedFilterState extends EntityState<SavedFilter> {
  selected: string;
  callState: CallState;
}

export interface State {
  [key: string]: SavedFilterState;
}

export const adapter: EntityAdapter<SavedFilter> = createEntityAdapter<SavedFilter>({
  selectId: (savedFilter: SavedFilter) => savedFilter.name,
  sortComparer: false,
});

export const initialState: State = {};

const savedFilterReducer = createReducer(
  initialState,
  on(loadSavedFilterListRequired, (state, action) => ({
    ...state,
    [action.savedFilterKey]: {
      ...adapter.getInitialState(),
      // Below line is needed in order to prevent removing the current state in case of refetching after saving new filter so the chips-blinking effect is avoided
      ...state[action.savedFilterKey],
      callState: LoadingState.LOADING,
    },
  })),
  on(loadSavedFilterListSuccess, (state, action) => {
    return {
      ...state,
      [action.savedFilterKey]: adapter.setAll(action.savedFilterList, {
        ...state[action.savedFilterKey],
        callState: LoadingState.LOADED,
      }),
    };
  }),
  on(loadSavedFilterListFailed, (state, action) => ({
    ...state,
    [action.savedFilterKey]: adapter.removeAll({
      ...state[action.savedFilterKey],
      callState: action.errorState,
    }),
  })),
  on(selectSavedFilter, (state, action) => ({
    ...state,
    [action.savedFilterKey]: {
      ...state[action.savedFilterKey],
      selected: action.name || '',
    },
  })),
  on(saveFilter, (state, action) => ({
    ...state,
    [action.filterToSave.type]: {
      ...state[action.filterToSave.type],
      callState: LoadingState.LOADING,
    },
  })),
  on(saveFilterSuccess, (state, action) => ({
    ...state,
    [action.savedFilter.type]: {
      ...state[action.savedFilter.type],
      selected: action.savedFilter.name,
      callState: LoadingState.LOADED,
    },
  })),
  on(deleteSavedFilters, (state, action) => ({
    ...state,
    [action.savedFilterKey]: {
      ...state[action.savedFilterKey],
      callState: LoadingState.LOADING,
    },
  })),
  on(deleteSavedFilterSuccess, (state, action) => ({
    ...state,
    [action.savedFilterKey]: adapter.removeMany(action.names, {
      ...state[action.savedFilterKey],
      selected: '',
      callState: LoadingState.LOADED,
    }),
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return savedFilterReducer(state, action);
}
