import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'bsz-select-search-highlight-filtered',
  template: `
    <span
      bsz-highlight-text
      [textContent]="value.filterValue"
      [pattern]="value.searchText"
      [caseSensitive]="false"
      [highlightClass]="['bsz-text-on-accent', 'bsz-background-accent']"
    ></span>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BszSelectSearchHighlightFilteredComponent {
  @Input()
  value: {filterValue: string; searchText: string};
}
