import {Component, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import {PortalLayoutService} from '../../portal-layout/portal-layout.service';

@Component({
  selector: 'bsz-page-content-centered',
  templateUrl: './page-content-centered.component.html',
  styleUrls: ['./page-content-centered.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageContentCenteredComponent implements OnDestroy {
  constructor(private portalLayoutService: PortalLayoutService) {
    this.portalLayoutService.ɵsetPageContentCentered(true);
  }
  ngOnDestroy(): void {
    this.portalLayoutService.ɵsetPageContentCentered(false);
  }
}
