import {
  ConnectedPosition,
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  Output,
  ViewContainerRef,
} from '@angular/core';
import {AsyncSubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {BszPopover, BszPopoverTriggerEvent} from './bsz-popover';
import {BszPopoverService} from './bsz-popover-service';

type BszPopoverPositionY = 'top' | 'bottom' | 'center';
type BszPopoverPositionX = 'start' | 'end' | 'center';

export type BszPopoverDirection = 'horizontal' | 'vertical';

@Directive({
  selector: '[bszPopoverTriggerFor]',
  host: {
    'class': 'bsz-popover-trigger',
    '[attr.aria-expanded]': '_triggerEvent.includes("click") ? popoverOpen : null',
    '[attr.aria-owns]': 'popoverOpen ? popover.panelId : null',
    '[attr.aria-describedby]': 'popoverOpen ? popover.panelId : null',
    '(mouseenter)': 'handleMouseenter()',
    '(mouseleave)': 'handleMouseleave()',
    '(keydown)': 'handleKeydown($event)',
    '(click)': 'handleClick()',
    '(focus)': 'handleFocus()',
  },
})
export class BszPopoverTrigger implements AfterViewInit, OnDestroy {
  @Input('bszPopoverTriggerFor') popover: BszPopover | null = null;

  @Input() set direction(direction: BszPopoverDirection) {
    if (direction) {
      this._direction = direction;
      return;
    }
    this._direction = 'vertical';
  }

  get direction(): BszPopoverDirection {
    return this._direction;
  }

  private _direction: BszPopoverDirection = 'vertical';

  @Input()
  get triggerEvent(): BszPopoverTriggerEvent | BszPopoverTriggerEvent[] {
    return this._triggerEvent;
  }

  set triggerEvent(triggerEvent: BszPopoverTriggerEvent | BszPopoverTriggerEvent[]) {
    if (!triggerEvent) {
      this._triggerEvent = ['click'];
      return;
    }
    this._triggerEvent = Array.isArray(triggerEvent) ? triggerEvent : [triggerEvent];
  }

  @Output() afterOpened = new EventEmitter<void>();
  @Output() afterClosed = new EventEmitter<void>();

  @HostListener('document:click', ['$event']) clickout(event: MouseEvent) {
    this.documentClickHandler(event);
  }

  private templatePortal: TemplatePortal<unknown> | null = null;
  private overlayRef: OverlayRef | null = null;
  isOpen = false;
  private popoverClosed = new AsyncSubject<void>();
  private destroy = new AsyncSubject<void>();
  private _triggerEvent: BszPopoverTriggerEvent[] = ['click'];
  private intersectionObserver: IntersectionObserver | null = null;
  private documentClickHandler = (event?: MouseEvent) => {};

  // This is meant to have a way to adapt the position strategy in the tests,
  // so it is possible to test things like the arrow position, etc. Because of that
  // it cannot be private nor protected
  /** @private */
  _defaultPositions: {
    vertical: ConnectedPosition[];
    horizontal: ConnectedPosition[];
  } = {
    vertical: [
      {originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom', offsetY: 0},
      {originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom', offsetY: 0},
      {originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top', offsetY: 0},
      {originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top', offsetY: 0},
      {originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom', offsetY: 0},
      {originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top', offsetY: 0},
    ],
    horizontal: [
      {originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center', offsetY: 0},
      {originX: 'end', originY: 'top', overlayX: 'start', overlayY: 'top', offsetY: 0},
      {originX: 'end', originY: 'bottom', overlayX: 'start', overlayY: 'bottom', offsetY: 0},
      {originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center', offsetY: 0},
      {originX: 'start', originY: 'top', overlayX: 'end', overlayY: 'top', offsetY: 0},
      {originX: 'start', originY: 'bottom', overlayX: 'end', overlayY: 'bottom', offsetY: 0},
    ],
  };

  constructor(
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private cd: ChangeDetectorRef,
    private readonly _elementRef: ElementRef,
    private readonly popoverService: BszPopoverService,
    private readonly zone: NgZone
  ) {}

  ngAfterViewInit() {
    this.popover?.closed.subscribe(() => this.close());
  }

  ngOnDestroy() {
    this.removePopover();
    this.destroy.next();
    this.destroy.complete();
  }

  handleClick(): void {
    if (!this.isValidEvent('click')) {
      return;
    }
    this.togglePopover('click');
  }

  /**
   * Function to handle the click to close the popover without using backdrop.
   * By this way, the rest of the contents are not blocked. The goal is to
   * close it when the user clicks in any place that is not the trigger and that
   * is outside the content of the popover.
   */
  private clickOutHandler(event?: MouseEvent) {
    if (
      event &&
      !this.overlayRef?.overlayElement.contains(event.target as HTMLElement) &&
      document.activeElement !== this._elementRef.nativeElement
    ) {
      this.close();
    }
  }

  handleFocus(): void {
    if (!this.isValidEvent('focus')) {
      return;
    }
    // timeout of 100 milliseconds because some screen readers, does not read the
    // content because it moved too fast to the container of the popover
    setTimeout(() => {
      this.open('focus');
    }, 100);
  }

  handleMouseenter(): void {
    if (!this.isValidEvent('hover') || !this.popover) {
      return;
    }
    this.popover.closeDisabled = true;
    this.open('hover');
  }

  handleMouseleave(): void {
    if (!this.isValidEvent('hover') || !this.popover) {
      return;
    }
    this.popover.handleMouseleave();
  }

  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape' || event.key === 'Tab') {
      this.close();
    }
  }

  get popoverOpen(): boolean {
    return this.isOpen;
  }

  private togglePopover(triggerEvent: BszPopoverTriggerEvent): void {
    return this.isOpen ? this.close() : this.open(triggerEvent);
  }

  private open(triggerEvent: BszPopoverTriggerEvent): void {
    if (this.isOpen || this.overlayRef || !this.popover) {
      return;
    }
    this.popoverService.closePreviousPopover();

    this.isOpen = true;
    this.popover.triggerEvent = triggerEvent;

    const {width, height} = this._elementRef.nativeElement.getBoundingClientRect();
    this.popover.triggerWidth = width;
    this.popover.triggerHeight = height;
    this.popoverService.setCurrentPopover(this.popover);

    this.createOverlay(triggerEvent).attach(this.templatePortal);
    this.subscribeToDetachments();
    this.initTriggerIntersectionObserver();
    this.afterOpened.emit();

    // setTimeout used to be sure that the function is not executed
    // before the popover is already present, so if it opens with the
    // click event, it is not closed automatically
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        this.documentClickHandler = this.clickOutHandler;
      });
    });
  }

  private close(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.setPopoverClosed();
      this.afterClosed.emit();
    }

    this.removePopover();
    this.documentClickHandler = (event?: MouseEvent) => {};
    this.intersectionObserver?.disconnect();
    this.popoverService.setCurrentPopover(null);
  }

  private removePopover(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  private subscribeToDetachments(): void {
    if (!this.overlayRef) {
      return;
    }
    this.overlayRef
      .detachments()
      .pipe(takeUntil(this.popoverClosed), takeUntil(this.destroy))
      .subscribe(() => {
        this.setPopoverClosed();
      });
  }

  private setPopoverClosed(): void {
    if (!this.isOpen) {
      return;
    }
    this.isOpen = false;
    this.popoverClosed.next();
    this.cd.markForCheck();
  }

  private createOverlay(triggerEvent: BszPopoverTriggerEvent): OverlayRef {
    if (!this.overlayRef) {
      if (this.popover?.templateRef) {
        this.templatePortal = new TemplatePortal(this.popover.templateRef, this.viewContainerRef);
      }
      const overlayConfig = this.getOverlayConfig(triggerEvent);
      this.subscribeToPositions(overlayConfig.positionStrategy as FlexibleConnectedPositionStrategy);
      this.overlayRef = this.overlay.create(overlayConfig);
    }

    return this.overlayRef;
  }

  private getOverlayConfig(triggerEvent: BszPopoverTriggerEvent): OverlayConfig {
    const overlayConfig = new OverlayConfig();
    return Object.assign(overlayConfig, {
      positionStrategy: this.getPositionStrategy(),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      hasBackdrop: false,
      disposeOnNavigation: true,
    });
  }

  /**
   * Required to update the classes for the arrow
   */
  private subscribeToPositions(position: FlexibleConnectedPositionStrategy): void {
    position.positionChanges.pipe(takeUntil(this.destroy)).subscribe((change) => {
      if (!this.popover) {
        return;
      }
      this.cd.markForCheck();
      this.popover.zone.run(() => {
        const connectionPair = change.connectionPair;
        this.setPositionClasses(connectionPair.overlayX, connectionPair.overlayY);
        this.setInlineArrowStyles(connectionPair.overlayX, connectionPair.overlayY);
      });
    });
  }

  /**
   * Defines the position strategy with a preference order in both vertical and horizontal disposition
   */
  private getPositionStrategy(): FlexibleConnectedPositionStrategy {
    return this.overlay
      .position()
      .flexibleConnectedTo(this._elementRef)
      .withLockedPosition(false) // The overlay's position is not locked in after initial position, so it repositions itself when the position is re-applied
      .withPositions(this._defaultPositions[this.direction])
      .withDefaultOffsetX(0)
      .withDefaultOffsetY(0);
  }

  /**
   * Set the classes that the arrow should have so it can be displayed in the correct position with CSS
   */
  private setPositionClasses(positionX: BszPopoverPositionX, positionY: BszPopoverPositionY): void {
    if (!this.popover) {
      return;
    }
    this.popover.arrowClassList['bsz-popover-arrow-bottom'] = positionY === 'bottom';
    this.popover.arrowClassList['bsz-popover-arrow-middle'] = positionY === 'center';
    this.popover.arrowClassList['bsz-popover-arrow-top'] = positionY === 'top';
    this.popover.arrowClassList['bsz-popover-arrow-right'] = positionX === 'end';
    this.popover.arrowClassList['bsz-popover-arrow-center'] = positionX === 'center';
    this.popover.arrowClassList['bsz-popover-arrow-left'] = positionX === 'start';
    this.popover.positionData = `${positionX} ${positionY}`;
    this.popover.directionData = this.direction;
  }

  /**
   * Set the inline styles for the arrow, so it is aligned to the center of the trigger
   */
  private setInlineArrowStyles(positionX: BszPopoverPositionX, positionY: BszPopoverPositionY): void {
    if (!this.popover) {
      return;
    }
    this.popover.arrowInlineStyle = {};
    if (this._direction === 'vertical' && positionX !== 'center') {
      const property = positionX === 'start' ? 'margin-left' : 'margin-right';
      this.popover.arrowInlineStyle = {[property]: `${this.popover.triggerWidth / 2}px`};
      return;
    }

    if (this._direction === 'horizontal' && positionY !== 'center') {
      const property = positionY === 'bottom' ? 'margin-bottom' : 'margin-top';
      this.popover.arrowInlineStyle = {[property]: `${this.popover.triggerHeight / 2}px`};
    }
  }

  /**
   * Checks whether the event used is one of the defined
   */
  private isValidEvent(triggerEvent: BszPopoverTriggerEvent): boolean {
    return this._triggerEvent.indexOf(triggerEvent) >= 0;
  }

  /**
   * It sets the intersection observer for the trigger element and
   * if it goes out the viewport, it closes the popover
   */
  private initTriggerIntersectionObserver() {
    this.intersectionObserver = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting && this.isOpen) {
            this.close();
          }
        });
      },
      {threshold: 0.5}
    ); // when half of the trigger element is not visible
    this.intersectionObserver.observe(this._elementRef.nativeElement);
  }
}
