<div class="bsz-tabs">
  <div role="tablist" class="bsz-tabs-tablist"
       [attr.aria-label]="ariaLabel || null"
       [attr.aria-labelledby]="ariaLabelledby || null">
    <button role="tab" class="bsz-tabs-tab bsz-body-2"
            *ngFor="let tab of bszTabs; index as i"
            [id]="'bsz-tabs-tab-' + tab.uniqueIndex"
            (click)="setActiveTab(i)"
            (keydown)="handleKeydown($event)"
            (focus)="updateIndexSelected(i)"
            [attr.tabindex]="i === selectedIndex ? 0 : -1"
            [attr.aria-controls]="'bsz-tabs-tabpanel-' + tab.uniqueIndex"
            [attr.aria-selected]="i === selectedIndex"
            [attr.aria-label]="tab.ariaLabel"
            [disabled]="isTabDisabled(i)"
            [class.bsz-tabs-tab-active]="i === selectedIndex"
            [class.bsz-tabs-tab-disabled]="isTabDisabled(i)">
      <mat-icon *ngIf="tab.icon" class="bsz-tabs-tab-icon">{{tab.icon}}</mat-icon>
      <span class="bsz-tabs-tab-text">{{tab.textLabel}}</span>
    </button>
  </div>
  <div class="bsz-tabs-tabpanels">
    <ng-content select="bsz-tab"></ng-content>
  </div>
</div>
