import {LoadChildren, LoadChildrenCallback, Route} from '@angular/router';
import {DisabledPageGuard} from './disabled-page.guard';
import {PortalPageClass} from './classes/portal-page';
import {UnsavedDataGuard} from './unsaved-data.guard';
import {InitialNavigationRedirect} from './initial-navigation-redirect.guard';
import {DashboardPage} from '../pages/dashboard.page';
import {DashboardId} from '../config/portal.config.definition';
import {DashboardIdService} from './dashboard-id.service';
import {firstValueFrom, isObservable, Observable} from 'rxjs';
import {AuthJwtUserLoggedInGuardService} from '@basuiz/web-app-common';

export interface GeneratePortalRouteOptions {
  canActivate?: Route['canActivate'];
}

/** Generates an Angular route that conforms to the navigation system of the web-app portal */
export function generatePortalRoute(
  /** Portal page behind the route, the value is the class itself, not an instance.
   *  To adhere to the portal standards, one route is tied to one and only one Page and vice versa.
   * */
  pageClass: PortalPageClass,
  /** Angular's route 'loadChildren'.
   * For a faster initial load of the portal, pages are lazy loaded.
   * Example: () => import('./my-pages/foo-page/index').then((m) => m.FooPageModule) */
  loadChildren: LoadChildren,
  options: GeneratePortalRouteOptions = {}
): Route {
  const onInitialNavigationRedirectTo: string | undefined = pageClass.onInitialNavigationRedirectTo?.routerConfigPath;
  return {
    path: getPathOrFail(pageClass),
    loadChildren,
    canActivate: [
      ...(options.canActivate ?? []),
      DisabledPageGuard,
      ...(!!onInitialNavigationRedirectTo ? [InitialNavigationRedirect] : []),
    ],
    canDeactivate: [UnsavedDataGuard],
    data: {
      pageClass,
      ...(onInitialNavigationRedirectTo ? {onInitialNavigationRedirectTo} : {}),
    },
  };
}

/** Generates an Angular route for the DashboardPage, which loads one of a set of pages defined in dashboardLoaders
 * based on the Id returned by the 'dashboardIdResolver' property of the PortalConfig.
 * This function can be used in the application's routing module to re-define the dashboard route with a different set
 * or loaders. */
export function generateDashboardPortalRoute(dashboardLoaders: Record<DashboardId, LoadChildrenCallback>): Route {
  return generatePortalRoute(
    DashboardPage,
    async () => {
      const activeDashboardId = await DashboardIdService.dashboardIdResolver();
      const loaderCallback: LoadChildrenCallback | undefined = dashboardLoaders[activeDashboardId];
      if (!loaderCallback) {
        throw new Error(`generateDashboardPortalRoute: No loader defined for dashboard ${activeDashboardId}`);
      }
      return promisify(loaderCallback());
    },
    {canActivate: [AuthJwtUserLoggedInGuardService]}
  );
}

function promisify<T>(input: T | Promise<T> | Observable<T>): Promise<T> {
  return isObservable(input) ? firstValueFrom(input) : Promise.resolve(input);
}

function getPathOrFail(pageClass: PortalPageClass): string {
  const path = pageClass.routerConfigPath;
  if (!path || path === '') {
    throw new Error(
      `Page ${pageClass.name} does not define the STATIC property 'routerConfigPath' or it contains an invalid value.
Value must be an non-empty string`
    );
  }
  return path;
}
