/**
 * This module is used when Phrase Editor is enabled,
 * it configures NGX Translate to use the BszI18nPhraseCompiler instead of the default
 */
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateCompiler, TranslateModule} from '@ngx-translate/core';

import {BszI18nPhraseCompiler} from './i18n-phrase-compiler';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: BszI18nPhraseCompiler,
      },
    }),
  ],
  exports: [TranslateModule],
})
export class BszI18nPhraseEditorModule {}
