/**
 * Native app's interface in the global scope:
 * - native app defines these functions in a property (see NATIVE_BRIDGE_GLOBAL_VARIABLE) in the global scope
 * - mobile bridge calls these functions
 *
 * Note the parameters of all functions are serialized. This means they are not type-safe, so for usage
 * inside services we recommend to use DeserializedNativeAppInterface in conjunction with
 * getDeserializedNativeAppInterface() instead.
 */
export interface NativeAppInterface {
  bridgeIsOperational(): void;
  instantiatedViewReady(response: Serialized<InstantiateViewResponse>): void;
  isNativeActionSupported(request: Serialized<IsNativeActionSupportedRequest>): void;
  triggerNativeAction(request: Serialized<NativeActionRequest>): void;
  getConfiguration(request: Serialized<ConfigurationRequest>): void;
}
export const NATIVE_BRIDGE_GLOBAL_VARIABLE = 'BszNativeBridge';

/**
 * The mobile bridge's interface in the global scope:
 * - mobile bridge defines these functions in a property (see MOBILE_BRIDGE_GLOBAL_VARIABLE) in the global scope
 * - native app calls these functions
 */
export interface MobileBridgeInterface {
  instantiateView(request: InstantiateViewRequest): void;
  returnIsNativeActionSupported(response: IsNativeActionSupportedResponse | ErrorResponse): void;
  returnResultOfNativeAction(response: NativeActionResponse | ErrorResponse): void;
  returnConfiguration(response: ConfigurationResponse | ErrorResponse): void;
}
export const MOBILE_BRIDGE_GLOBAL_VARIABLE = 'BszMobileBridge';

export interface Request {
  requestId: RequestIdentifier;
}

export interface Response {
  status: 'success' | 'error';
  requestId: RequestIdentifier;
}

export interface ErrorResponse extends Response {
  status: 'error';
  error: any;
}

export interface SuccessResponse extends Response {
  status: 'success';
}

export interface InstantiateViewRequest extends Request {
  view: ViewName;
  payload?: any; // depends on the view name
}

// currently we only have a success response for the instantiateView request and it's empty
export type InstantiateViewResponse = SuccessResponse;

export interface IsNativeActionSupportedRequest extends Request {
  nativeActionType: NativeActionType;
  payload: any; // depends on the action type
}

export interface IsNativeActionSupportedResponse extends SuccessResponse {
  nativeActionType: NativeActionType;
  isSupported: boolean;
}

export interface NativeActionRequest extends Request {
  nativeActionType: NativeActionType;
  payload: any; // depends on the action type
}

export type ConfigurationRequest = Request;

export interface NativeActionResponse extends SuccessResponse {
  nativeActionType: NativeActionType;
  payload: any; // depends on the action type
}

export interface NativeConfiguration {
  locale?: string;
}

export interface ConfigurationResponse extends SuccessResponse {
  payload: NativeConfiguration;
}

export type RequestIdentifier = number;

// native app defines these, see ActionWithoutResponse.name and ActionWithResponse.name
export type NativeActionType = string;

// native app defines these, see ViewWithoutPayload.name and ViewWithPayload.name
export type ViewName = string;

export function isWindowWithNativeAppInterface(window: any): window is WindowWithNativeAppInterface {
  return typeof window[NATIVE_BRIDGE_GLOBAL_VARIABLE] === 'object';
}

export function getNativeAppInterface(window: Window): NativeAppInterface {
  if (isWindowWithNativeAppInterface(window)) {
    return window[NATIVE_BRIDGE_GLOBAL_VARIABLE];
  } else {
    throw new Error(
      `Native app interface not present in window (expected variable ${NATIVE_BRIDGE_GLOBAL_VARIABLE} to exist)`
    );
  }
}

export interface WindowWithNativeAppInterface {
  [NATIVE_BRIDGE_GLOBAL_VARIABLE]: NativeAppInterface;
}

/**
 * This interface denotes a serialized version of the type T.
 */
export type Serialized<T> = string;

export function serializeNativeAppParam<T>(data: T): Serialized<T> {
  return JSON.stringify(data);
}

export function deserializeNativeAppParam<T>(serializedData: Serialized<T>): T {
  return JSON.parse(serializedData) as T;
}

/**
 * Same as NativeAppInterface, but the function parameters are not serialized in order to have full
 * type support. This is for usage inside services to get type safety.
 *
 * You can use getDeserializedNativeAppInterface() to get an instance of this interface from
 * an existing instance of NativeAppInterface.
 */
export interface DeserializedNativeAppInterface {
  bridgeIsOperational(): void;
  instantiatedViewReady(response: InstantiateViewResponse): void;
  isNativeActionSupported(request: IsNativeActionSupportedRequest): void;
  triggerNativeAction(request: NativeActionRequest): void;
  getConfiguration(request: ConfigurationRequest): void;
}

/**
 * Get the type-safe version (without the serialization) of NativeAppInterface.
 *
 * Example usage:
 * ```
 * const nativeAppInterface = getDeserializedNativeAppInterface(window[NATIVE_BRIDGE_GLOBAL_VARIABLE]);
 *
 * nativeAppInterface.isNativeActionSupported({
 *   nativeActionType: 'MyAction1',
 *   // ... other params; note this is not serialized, so we have full type safety here :)
 * });
 * ```
 */
export function getDeserializedNativeAppInterface(
  nativeAppInterface: NativeAppInterface
): DeserializedNativeAppInterface {
  return {
    bridgeIsOperational: () => nativeAppInterface.bridgeIsOperational(),
    instantiatedViewReady: (response: InstantiateViewResponse) =>
      nativeAppInterface.instantiatedViewReady(serializeNativeAppParam(response)),
    isNativeActionSupported: (request: IsNativeActionSupportedRequest) =>
      nativeAppInterface.isNativeActionSupported(serializeNativeAppParam(request)),
    triggerNativeAction: (request: NativeActionRequest) =>
      nativeAppInterface.triggerNativeAction(serializeNativeAppParam(request)),
    getConfiguration: (request: ConfigurationRequest) =>
      nativeAppInterface.getConfiguration(serializeNativeAppParam(request)),
  };
}
