import {PortalPage} from '../navigation/classes/portal-page';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslationKeyWithParams} from '../navigation/translation-key-with-params';
import {TranslationKey} from '@basuiz/web-app-applet-api';

export class PriceCalculatorPage extends PortalPage {
  get parentPortalPage(): PortalPage | null {
    return null; // TODO - Replace with the adequate PortalPage unless the page hangs direct under the 'Home' page.
  }

  get breadcrumbText(): TranslationKey | TranslationKeyWithParams {
    return asTranslationKey('web-app-portal.pages.price-calculator.breadcrumb');
  }

  get breadcrumbTestId(): string {
    return 'web-app-portal.pages.price-calculator.breadcrumb';
  }

  get titleText(): TranslationKey {
    return asTranslationKey('web-app-portal.pages.price-calculator.window-title');
  }

  static readonly routerConfigPath = 'cotizador';
  get route(): Array<string> {
    return [`/${PriceCalculatorPage.routerConfigPath}`];
  }
}
