import {Directive} from '@angular/core';

export abstract class BszPortalPageHeaderItem {}

@Directive({
  selector: '[bszPortalPageHeaderTitle]',
  providers: [{provide: BszPortalPageHeaderItem, useExisting: PortalPageHeaderTitleDirective}],
})
export class PortalPageHeaderTitleDirective {}

@Directive({
  selector: '[bszPortalPageHeaderSubtitle]',
  providers: [{provide: BszPortalPageHeaderItem, useExisting: PortalPageHeaderSubtitleDirective}],
})
export class PortalPageHeaderSubtitleDirective {}

@Directive({
  selector: '[bszPortalPageHeaderActions]',
  providers: [{provide: BszPortalPageHeaderItem, useExisting: PortalPageHeaderActionsDirective}],
})
export class PortalPageHeaderActionsDirective {}

@Directive({
  selector: '[bszPortalPageHeaderExtras]',
  providers: [{provide: BszPortalPageHeaderItem, useExisting: PortalPageHeaderExtrasDirective}],
})
export class PortalPageHeaderExtrasDirective {}
