import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {WebAppCommonFacade} from '../base-data/web-app-common.facade';
import {Country} from '../base-data/models/country.definitions';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {MatSelectChange} from '@angular/material/select';

@Component({
  selector: 'bsz-country',
  templateUrl: './bsz-country.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BszCountryComponent {
  @Input()
  control: UntypedFormControl;
  @Input()
  required: boolean = false;

  selectedCountry: Country;

  private searchSubject = new BehaviorSubject('');
  private countrySearch$: Observable<string> = this.searchSubject.asObservable().pipe(debounceTime(200));
  filteredCountryList$: Observable<Country[]> = combineLatest([
    this.webAppCommonFacade.countryListSorted$,
    this.countrySearch$,
  ]).pipe(
    map(([countryList, searchTerm]) =>
      countryList.filter((country) => country.name.toLowerCase().includes(searchTerm.toLowerCase()))
    )
  );

  constructor(private webAppCommonFacade: WebAppCommonFacade) {}

  onSearchCountry(val: string) {
    this.searchSubject.next(val);
  }

  selectionChange(country: MatSelectChange) {
    this.selectedCountry = country.value;
  }
}
