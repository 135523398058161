import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Host,
  HostListener,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {ControlValueAccessor, FormGroupDirective, NgControl, NgForm} from '@angular/forms';
import {matFormFieldAnimations} from '@angular/material/form-field';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {FileListUtils} from './utils';

const LOG_PREFIX = '[bsz-file-upload]';

@Component({
  selector: 'bsz-file-upload',
  styleUrls: ['./bsz-file-upload.scss'],
  templateUrl: './bsz-file-upload.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [matFormFieldAnimations.transitionMessages],
  host: {
    'class': 'bsz-file-upload',
    '[class.bsz-file-upload-is-invalid]': 'formSubmittedOrTouchedAndHasErrors()',
  },
})
export class BszFileUpload implements OnDestroy, ControlValueAccessor {
  static nextId = 0;

  readonly uniqueId = `bsz-file-upload-${BszFileUpload.nextId++}`;

  /**
   * If set, the file input allows the user to select more than one file
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/multiple
   */
  @Input()
  get multiple() {
    return this._multiple;
  }
  set multiple(multiple: BooleanInput) {
    this._multiple = coerceBooleanProperty(multiple);
  }
  _multiple = false;

  /** If set, will add the required attribute to the native input[type="file"] */
  @Input()
  get required() {
    return this._required;
  }
  set required(required: BooleanInput) {
    this._required = coerceBooleanProperty(required);
  }
  /** @private */
  _required = false;

  /** If set, will add the disabled attribute to the native input[type="file"] */
  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(disabled: BooleanInput) {
    this.setDisabledState(coerceBooleanProperty(disabled));
  }
  /** @private */
  _disabled = false;

  /** Disables the ripple effect on the drop zone */
  @Input()
  get disableRipple() {
    return this._disableRipple;
  }
  set disableRipple(disableRipple: BooleanInput) {
    this._disableRipple = coerceBooleanProperty(disableRipple);
  }
  /** @private */
  _disableRipple = false;

  /**
   * One or more unique file type specifiers describing file types to allow
   * this is bound to the native accept property of the input[type="file"]
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept
   */
  @Input()
  get accept() {
    return this._accept;
  }
  set accept(accept: string | string[] | null) {
    this._accept = Array.isArray(accept) ? accept.join(',') : accept;
  }
  /** @private */
  _accept: string | null = null;

  /** Selector for the input[type="file"] from the template */
  @ViewChild('fileInputElement', {static: true}) fileInputElement!: ElementRef<HTMLInputElement>;

  /**
   * Catch the event from the input[type="file"] element and pass the fileList
   * to the onChange method to update the form model
   */
  @HostListener('change', ['$event.target.files']) onFileInputChange(fileList: FileList | null) {
    if (fileList === null) {
      this.clearFileList();
    } else {
      this.updateFileList(fileList);
    }
  }

  /**
   * Forms API will register a function to this property
   *
   * When the value changes, call the registered
   * function to allow the forms API to update
   * itself
   *
   * @see `registerOnChange(fn): void`
   */
  onChange = (value: unknown) => {};

  /**
   * Forms API will register a function to this property
   *
   * @see `registerOnTouched(fn): void`
   */
  onTouched = () => {};

  /**
   * The parent form the element is hosted into
   * - NgForm for template forms
   * - FormGroupDirective for reactive forms
   */
  parentForm: NgForm | FormGroupDirective | null = null;

  /** The FormControl which the element is bound */
  ngControl: NgControl | null = null;
  /** Reference FileList to hold the state of the native input[type="file"] fileList */
  fileList: FileList | null = null;
  /** the focus state of the native input[type="file"] */
  focused = false;
  isInvalid = false;

  private destroy = new Subject<void>();

  constructor(
    private readonly cd: ChangeDetectorRef,
    @Optional() @Self() ngControl: NgControl | null,
    @Optional() @Host() ngForm: NgForm | null,
    @Optional() @Host() formGroupDirective: FormGroupDirective | null
  ) {
    this.bindNgControlIfAvailable(ngControl);
    this.initParentForm(ngForm || formGroupDirective);
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  onFocus(): void {
    this.focused = true;
  }

  onBlur(): void {
    this.focused = false;
    this.onTouched();
    this.detectChanges();
  }

  formSubmittedOrTouchedAndHasErrors(): boolean {
    const isSubmitted = this.parentForm?.submitted || false;
    const isTouched = this.ngControl?.touched || false;
    const hasErrors = !!this.ngControl?.errors || false;

    return (isSubmitted || isTouched) && hasErrors;
  }

  reset(): void {
    this.clearFileList();
    this.writeValue('');
    this.ngControl?.reset();
  }

  /**
   * This method is called by the forms API to write to the view
   * when programmatic changes from model to view are requested
   *
   * @override
   * @param value The new value for the element
   */
  writeValue(value: string | null): void {
    if (value !== null && value !== '') {
      throw new Error(
        `${LOG_PREFIX} File Input element accepts a filename, which may only be programmatically set to an empty string`
      );
    }

    this.fileInputElement.nativeElement.value = '';
    this.clearFileList();
  }

  /**
   * Registers a callback function that is called when the control's
   * value changes in the UI.
   *
   * This method is called by the forms API on initialization to
   * update the form model when values propagate from the view
   * to the model.
   *
   * When implementing the registerOnChange method in your own
   * value accessor, save the given function so your class
   * calls it at the appropriate time.
   *
   * @override
   * @param fn The callback function to register
   */
  registerOnChange(fn: (value: unknown) => void): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function is called by the forms API on
   * initialization to update the form model on blur
   *
   * When implementing registerOnTouched in your own value accessor,
   * save the given function so your class calls it when the control
   * should be considered blurred or "touched"
   *
   * @override
   * @param fn The callback function to register
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Function that is called by the forms API when the control status
   * changes to or from 'DISABLED'. Depending on the status, it
   * enables or disables the appropriate DOM element.
   *
   * @override
   * @param isDisabled The disabled status to set on the element
   */
  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
    this.detectChanges();
  }

  /** @private for internal use only */
  getFileListAsArray(): File[] {
    return FileListUtils.fileListToArray(this.fileList);
  }

  /** @private library internal */
  _deleteFileFromFileList(file: File) {
    const updatedFileList = FileListUtils.deleteFile(this.fileList, file);

    this.fileList = null;
    if (updatedFileList.length > 0) {
      this.updateFileList(updatedFileList);
    } else {
      this.clearFileList();
    }
  }

  private updateFileList(fileList: FileList): void {
    if (this.fileList !== null) {
      this.fileList = FileListUtils.mergeFileLists(this.fileList, fileList);
    } else {
      this.fileList = fileList;
    }

    if (this.fileList.length > 1 && !this._multiple) {
      throw new Error(`${LOG_PREFIX} Cannot attach multiple files when the "multiple" property is not set`);
    }

    this.updateNativeElementFiles(this.fileList);
    this.onChange(this.fileList);
    this.detectChanges();
  }

  private updateNativeElementFiles(fileList: FileList) {
    // Workaround for jest because DataTransfer object is not available in jsdom to create a valid FileList and even
    // a Mock implementing the prototype of the FileList triggers an error:
    // TypeError: Failed to set the 'files' property on 'HTMLInputElement': The provided value is not of type 'FileList'.
    // @ts-ignore
    if (process && process.env?.JEST_WORKER_ID !== undefined) {
      Object.defineProperty(this.fileInputElement.nativeElement, 'files', {
        value: fileList,
        writable: false,
      });
    } else {
      this.fileInputElement.nativeElement.files = fileList;
    }
  }

  private initParentForm(parentForm: NgForm | FormGroupDirective | null): void {
    if (!parentForm) {
      return;
    }
    this.parentForm = parentForm;
    this.parentForm.ngSubmit.pipe(takeUntil(this.destroy)).subscribe(() => this.detectChanges(true));
  }

  private clearFileList() {
    this.fileList = null;
    this.updateNativeElementFiles(FileListUtils.EmptyFileList);
    this.onChange(null);
    this.detectChanges();
  }

  /**
   * If the component is inside a ReactiveForm bind it to the valueAccessor
   * to create the bridge to the Angular forms API
   */
  private bindNgControlIfAvailable(ngControl: NgControl | null): void {
    if (!ngControl) {
      return;
    }

    ngControl.valueAccessor = this;
    this.ngControl = ngControl;
  }

  /**
   * Trigger change detection and update invalid component state
   *
   * @param immediate if false call markForCheck and if true call detectChanges from changeDetector
   */
  private detectChanges(immediate?: boolean): void {
    if (immediate) {
      this.cd.detectChanges();
    } else {
      this.cd.markForCheck();
    }
  }
}
