import {map} from 'rxjs/operators';
import {AfpRestPaging, AfpRestResponse, AfpRestResponsePaging} from './models/afp-rest-response.definitions';

export class AfpRestOperators {
  static extractPayload() {
    return map(<T>(response: AfpRestResponse<T>): T => response.payload);
  }

  static extractPayloadWithPaging() {
    return map(
      <T extends AfpRestPaging>({payload, snapshotId, hasMoreResults}: AfpRestResponsePaging<T>): T => ({
        ...payload,
        snapshotId,
        hasMoreResults,
      })
    );
  }

  static extractNotifications() {
    return map((response: AfpRestResponse<any>) => response.notifications);
  }
}
