import {BszFeatureToggle} from './models/features.definition';
import {FeatureStatus} from './models/generic.definition';
import {warnOnEnabledExperimentalFeatureToggle} from './feature-toggle-experimental.validation';

// IMPORTANT: do not modify the signature of this function
export function validateFeatureToggle(toggle: BszFeatureToggle): string | undefined {
  let result: string = '';

  const invalidValues = checkInvalidValues(toggle as unknown as TreeNode);
  if (invalidValues) {
    result += '\n' + `The following features contain invalid values:\n${invalidValues}`;
  }

  warnOnEnabledExperimentalFeatureToggle(toggle);

  const paymentCardsError = checkPaymentCardsToggle(toggle);
  if (paymentCardsError) {
    result += '\n' + paymentCardsError;
  }

  const paymentMethodError = checkPaymentMethodToggle(toggle);
  if (paymentMethodError) {
    result += '\n' + paymentMethodError;
  }
  return result === '' ? undefined : result;
}

type TreeNode = {[key in string]: {} | FeatureStatus};

function checkInvalidValues(node: TreeNode, signature?: string): string | undefined {
  const sig = !signature ? '' : `${signature}.`;
  let result: string = '';
  Object.entries(node).forEach(([key, value]) => {
    if (!isValidValue(value)) {
      result += `${sig}${key}\n`;
    }
    if (value instanceof Object) {
      result += checkInvalidValues(value, sig + key);
    }
  });
  return result;
}

function isValidValue(value: unknown): boolean {
  return value instanceof Object || (typeof value === 'string' && ['enabled', 'disabled'].includes(value));
}

function checkPaymentCardsToggle(toggle: BszFeatureToggle): string | undefined {
  if (toggle.paymentCards instanceof Object) {
    if (!(toggle.paymentCards.debitCards === 'enabled')) {
      return `'paymentCards': If set as object, at least one child must be enabled.`;
    }
  }
  return;
}

function checkPaymentMethodToggle(toggle: BszFeatureToggle): string | undefined {
  if (toggle.paymentMethod instanceof Object) {
    if (!(toggle.paymentMethod.wallet === 'enabled')) {
      return `'paymentMethod': If set as object, the child 'wallet' must be enabled to use other types of payment methods.`;
    }
  }
  return;
}
