import {InjectionToken} from '@angular/core';
import {BszFactoryProviderGenerator, LocaleId} from '@basuiz/web-app-applet-api';

/** A map of optional currency compact amount formatters assigned to specific locales
 *  When no custom formatter is provided for a given locale, web-app uses its internal formatter
 */
export type CurrencyCompactAmountFormatters = Record<LocaleId, CurrencyCompactAmountFormatter>;

/**
 * A formatter for compact currency amounts. E.g. (123456789.10, 'CHF') => ('CHF 123.4M')
 * Compact currency amounts are used in certain views where the amount is not the most relevant element
 * (typically charts), the view allocates more space to other content (e.g. the chart), and therefore it is
 * acceptable to loose precision.
 * If available, web-app libraries will invoke the provided formatter instead of the default logic.
 * @param amount: the amount to be formatted e.g 123450
 * @param currencyIsoCode: ISO code of the currency being formatted e.g. CHF
 */
export type CurrencyCompactAmountFormatter = (amount: number, currencyIsoCode: string) => string;

export const ɵCURRENCY_COMPACT_AMOUNT_FORMATTERS = new InjectionToken<CurrencyCompactAmountFormatters>(
  'bsz.web-app-common.formatting.currency-compact-amount-formatters',
  {
    factory: () => ({}),
  }
);

/**
 * Factory provider for convinient override of the compact currency amount formatting style,
 * depending on the provided Locale id.
 */
export const provideCurrencyCompactAmountFormatters: BszFactoryProviderGenerator<CurrencyCompactAmountFormatters> = (
  useFactory,
  deps
) => ({
  provide: ɵCURRENCY_COMPACT_AMOUNT_FORMATTERS,
  useFactory,
  deps,
});
