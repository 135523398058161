import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {SelfServiceLocaleSelectorComponent} from './self-service-locale-selector/self-service-locale-selector.component';

@Component({
  selector: 'bsz-web-app-self-service-locale-selector-applet',
  template: `<bsz-self-service-locale-selector
    bszWebAppApplet
    bszTestId="testIds.web-app-self-service-locale-selector-applet"
    [changeUponSelection]="changeUponSelection"
    (selectedAndActiveLocalesDiffer)="selectedAndActiveLocalesDiffer.emit($event)"
  ></bsz-self-service-locale-selector>`,
})
export class WebAppSelfServiceLocaleSelectorAppletComponent {
  /** Steers whether the applet should trigger the language change as soon the user selects a new locale
   * in the selector or not. If set to false, the change must be triggered by calling the method 'changeLocale'
   * of this component. */
  @Input()
  changeUponSelection: boolean = true;

  /** Indicates whether the session active locale and the locale selected in the applet differ or not */
  @Output()
  readonly selectedAndActiveLocalesDiffer: EventEmitter<boolean> = new EventEmitter();

  /** Indicates the applet to change the user's locale.
   * First the applet will communicate with the back-end to update the user's locale,
   * then the applet will trigger a page reload */
  changeLocale(): Observable<void> {
    return this.innerComponent.changeLocale();
  }

  @ViewChild(SelfServiceLocaleSelectorComponent)
  private innerComponent: SelfServiceLocaleSelectorComponent;
}
