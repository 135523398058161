import {PortalPage} from './portal-page';
import {TranslationKeyWithParams} from '../translation-key-with-params';
import {TranslationKey} from '@basuiz/web-app-applet-api';

/* This class is used by the navigation service on the first navigation of the session.
Since there is not a valid page instance for the page behind the URL entered in the browser,
the PortalPageComponent class will detect this scenario and try to generate an instance of the
approproiate PortalPage child-class in order to replace the AppLoadsPage instance */
export class AppLoadsPage extends PortalPage {
  get route(): Array<string> {
    return [];
  }

  get breadcrumbText(): TranslationKey | TranslationKeyWithParams {
    return '';
  }

  get breadcrumbTestId(): string {
    return '';
  }

  get titleText(): TranslationKey {
    return '';
  }

  get parentPortalPage(): PortalPage | null {
    return null;
  }
}
