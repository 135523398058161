import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {
  loadAttentionItemCounterAction,
  loadAttentionItemCountersFailed,
  loadAttentionItemCountersSuccess,
} from './attention-item-counter.actions';
import {AttentionItemCounterService} from '../../data-access/attention-item-counter.service';

@Injectable()
export class AttentionItemCounterEffects {
  loadAttentionItemCounter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAttentionItemCounterAction),
      mergeMap((action) =>
        this.attentionItemCounterService.fetch(action.counterTypes).pipe(
          map((attentionItemCounters) => loadAttentionItemCountersSuccess({attentionItemCounters})),
          catchError((error) => of(loadAttentionItemCountersFailed(error)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private attentionItemCounterService: AttentionItemCounterService) {}
}
