/**
 * Base class for an Action with no response (mobile bridge won't wait for a response).
 */
export abstract class ActionWithoutResponse<TRequestPayload> {
  abstract readonly name: string;

  constructor(public payload: TRequestPayload) {}
}

/**
 * Base class for an Action that has a response (mobile bridge will wait for a response and return it).
 */
export abstract class ActionWithResponse<TRequestPayload, TResponsePayload> {
  abstract readonly name: string;

  constructor(public payload: TRequestPayload) {}

  // We need at least one property referencing TResponse, otherwise type inference
  // down the line won't work.
  private _res: TResponsePayload;
}

/**
 * Union type for both types of actions. Useful when we don't care about the specifics of the action.
 */
export type Action = ActionWithoutResponse<any> | ActionWithResponse<any, any>;
