import {Inject, Injectable, OnDestroy} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {BszUINotification, BszUINotificationRemark, BszUINotificationSeverity} from '@basuiz/web-app-applet-api';
import {assertNever, UI_NOTIFICATION_STREAM} from '@basuiz/web-app-common';

/*
 * Service that render UI-notifications using MatSnackBar as the default way to present notifications to the user.
 * This service is instantiated by the WebAppModule.
 * Whether WebAppModule instantiates the service or not, depends on the value provided in
 * the injection token ENABLE_UI_NOTIFICATION_SNACK_BAR_SERVICE
 * which can be controlled in the options of WebAppModule.forRoot
 * Banks can opt to disable this service and provide their own UI-notification consumers
 * */
@Injectable()
export class UINotificationSnackBarService implements OnDestroy {
  private terminateSubject = new Subject();

  constructor(
    @Inject(UI_NOTIFICATION_STREAM) private uiNotificationStream: Observable<BszUINotification>,
    private snackBar: MatSnackBar
  ) {
    this.subscribeToNotificationStream();
  }

  private subscribeToNotificationStream(): void {
    this.uiNotificationStream.pipe(takeUntil(this.terminateSubject)).subscribe((uiNotification) => {
      const {message, severity} = uiNotification;
      const remarks: BszUINotificationRemark[] = uiNotification.remarks ?? [];
      const snackBarText = remarks.reduce(
        (fullText, remark) => `${fullText.trim().replace(/\.$/, '')}. ${remark.message}`,
        message
      );
      return this.snackBar.open(snackBarText, undefined, this.getSnackBarConfig(severity));
    });
  }

  ngOnDestroy(): void {
    this.terminateSubject.next(undefined);
    this.terminateSubject.complete();
  }

  private getSnackBarConfig(severity: BszUINotificationSeverity): MatSnackBarConfig {
    const baseSnackBarConfig: MatSnackBarConfig = {
      horizontalPosition: 'end',
      duration: 3000,
    };
    switch (severity) {
      case 'success':
        return {
          ...baseSnackBarConfig,
          panelClass: 'bsz-notification-snackbar-success',
        };
      case 'info':
        return {
          ...baseSnackBarConfig,
          panelClass: 'bsz-notification-snackbar-info',
        };
      case 'warning':
        return {
          ...baseSnackBarConfig,
          panelClass: 'bsz-notification-snackbar-info',
        };
      case 'error':
        return {
          ...baseSnackBarConfig,
          duration: 20000,
          panelClass: 'bsz-notification-snackbar-error',
        };
      default:
        assertNever(severity);
    }
  }
}
