import {PortalPage} from '../navigation/classes/portal-page';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslationKeyWithParams} from '../navigation/translation-key-with-params';
import {TranslationKey} from '@basuiz/web-app-applet-api';
import {NotificationsPage} from './notifications.page';
import {BszObjectId} from '@basuiz/web-app-applet-api';

export interface NotificationsSubscriptionFormPagePayload {
  subscriptionEditId?: BszObjectId;
  painFileId?: BszObjectId;
}

export class NotificationsSubscriptionFormPage extends PortalPage {
  constructor(public payload: NotificationsSubscriptionFormPagePayload = {}) {
    super();
  }
  get parentPortalPage(): PortalPage | null {
    return new NotificationsPage({view: 'subscription'});
  }

  get breadcrumbText(): TranslationKey | TranslationKeyWithParams {
    return this.payload.subscriptionEditId
      ? asTranslationKey('web-app-portal.pages.notifications-subscription-form.edit.breadcrumb')
      : asTranslationKey('web-app-portal.pages.notifications-subscription-form.breadcrumb');
  }

  get breadcrumbTestId(): string {
    return 'web-app-portal.pages.notifications-subscription-form.breadcrumb';
  }

  get titleText(): TranslationKey {
    return asTranslationKey('web-app-portal.pages.notifications-subscription-form.window-title');
  }

  static readonly routerConfigPath = 'notifications/subscription-form';
  get route(): Array<string> {
    return [`/${NotificationsSubscriptionFormPage.routerConfigPath}`];
  }
}
