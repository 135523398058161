export const AdminRoutePrefix = '/admin';

export interface IAdminViewerParams {
  /**
   * @param UID refers to the 'objectOwnerId' within the DB table where this Role.adminViewer query runs.
   * Ex. When an Role.adminViewer requests a 'Note' object from the user '123', then the UID must be '123'.
   */
  UID: number;

  /**
   * @param UEMAIL refers to the user.email within the DB table where this Role.adminViewer query runs.
   * This value is optional on some endpoint where it's more convinient to find a user via its email.
   * Ex. When an Role.adminViewer requests a 'User' object with email 'user@mail.com', then the UEMAIL
   * must be 'user@mail.com', in this case to find the user it's only required to input the UEMAIL and not the UID.
   */
  UEMAIL?: string;
}

export interface IAdminEditorParams {
  /**
   * @param ownerUID refers to the 'objectOwnerId' within the DB table where this Role.adminEditor will assign the ownership to.
   * Ex. When an Role.adminEditor creates/updates a 'Note' object on behalf of the user '123', then the ownerUID must be '123'.
   */
  ownerUID: number;

  /**
   * @param ownerUEMAIL refers to the user.email where this Role.adminEditor will assign the ownership to. This email field MUST match a user of 'objectId == ownerUID'
   * Ex. When an Role.adminEditor creates/updates a 'Note' object on behalf of the user.email 'john.smith@email' (and UID = 123), then the ownerUEMAIL must be 'john.smith@email' AND must match a user in the DB where user.objectId is '123'.
   */
  ownerUEMAIL: string;
}
