import {createAction, props} from '@ngrx/store';
import {getErrorState} from '@basuiz/web-app-common';
import {Notification} from '../../models/notification.definition';
import {Update} from '@ngrx/entity';

export const deleteNotificationList = createAction(
  '[Notifications] Delete notification list',
  props<{notificationIds: number[]}>()
);

export const deleteNotificationListError = createAction(
  '[Notifications] Delete notification list failed',
  getErrorState
);

export const deleteNotificationListSuccess = createAction(
  '[Notifications] Deleted notification list',
  props<{notificationList: Update<Notification>[]}>()
);

export const loadNotifications = createAction('[Notifications] Load notifications');

export const loadNotificationsRequired = createAction('[Notifications] Load notifications required');

export const loadNotificationListError = createAction(
  '[Notifications] Notification list failed to fetch',
  getErrorState
);

export const loadNotificationListSuccess = createAction(
  '[Notifications] Notification list fetched',
  props<{notificationList: Notification[]}>()
);

export const markAllNotificationsAsRead = createAction('[Notifications] Mark all notifications as read');

export const markNotificationListAsRead = createAction(
  '[Notifications] Mark notification list as read',
  props<{notificationIds: number[]}>()
);

export const markNotificationListAsReadError = createAction(
  '[Notifications] Mark notification list as read failed',
  getErrorState
);

export const markNotificationListAsReadSuccess = createAction(
  '[Notifications] Marked notification list as read',
  props<{notificationList: Update<Notification>[]}>()
);

export const markNotificationListAsUnread = createAction(
  '[Notifications] Mark notification list as unread',
  props<{notificationIds: number[]}>()
);

export const markNotificationListAsUnreadError = createAction(
  '[Notifications] Mark notification list as unread failed',
  getErrorState
);

export const markNotificationListAsUnreadSuccess = createAction(
  '[Notifications] Marked notification list as unread',
  props<{notificationList: Update<Notification>[]}>()
);

export const restoreNotificationList = createAction(
  '[Notifications] Restore notification list',
  props<{notificationIds: number[]}>()
);

export const restoreNotificationListError = createAction(
  '[Notifications] Restore notification list failed',
  getErrorState
);

export const restoreNotificationListSuccess = createAction(
  '[Notifications] Restored notification list',
  props<{notificationList: Update<Notification>[]}>()
);
