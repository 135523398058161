import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {BszI18nModule} from '@basuiz/i18n';

import {BszBannerAction, BszBannerActions, BszBannerTitle} from './bsz-banner-base';
import {BszContextualBanner} from './bsz-contextual-banner';
import {BszGlobalBanner} from './bsz-global-banner';
import {BszResizeObserver} from './bsz-resize-observer.directive';

@NgModule({
  imports: [CommonModule, BszI18nModule, MatButtonModule, MatIconModule],
  exports: [BszBannerActions, BszContextualBanner, BszGlobalBanner, BszBannerTitle, BszBannerAction, BszResizeObserver],
  declarations: [
    BszBannerActions,
    BszContextualBanner,
    BszGlobalBanner,
    BszBannerTitle,
    BszBannerAction,
    BszResizeObserver,
  ],
})
export class BszBannerModule {}
