import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {BszSpinner} from './bsz-spinner';

@NgModule({
  declarations: [BszSpinner],
  imports: [CommonModule],
  exports: [BszSpinner],
})
export class BszSpinnerModule {}
