<div class="bsz-notification-overlay"
     [@notificationOverlayTransition]="{
        value: _getState(),
        params: {height: _getHeightInPixels()}
      }"
     (@notificationOverlayTransition.start)="_onTransitionStart($event)"
     (@notificationOverlayTransition.done)="_onTransitionEnd($event)"
     [attr.data-type]="type"
     [attr.data-horizontal]="_horizontalPosition"
     [attr.data-vertical]="_verticalPosition">

  <!-- Initially holds the content, will be empty after announcing to screen readers. -->
  <div aria-hidden="true">
    <ng-template cdkPortalOutlet></ng-template>
  </div>

  <div [attr.aria-live]="_live" [attr.role]="_role"></div>

</div>
