import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {BszBadge} from './bsz-badge';

@NgModule({
  declarations: [BszBadge],
  imports: [CommonModule],
  exports: [BszBadge],
})
export class BszBadgeModule {}
