import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {Directive, ElementRef, Inject, Input, Optional} from '@angular/core';
import {AbstractControl, ControlValueAccessor, ValidationErrors, Validator, ValidatorFn} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats} from '@angular/material/core';
import {DateFilterFn} from '@angular/material/datepicker';
import {MAT_FORM_FIELD, MatFormField} from '@angular/material/form-field';

import {BszDateRange} from './bsz-datepicker.definitions';
import {BszDatePickerOverlay} from './bsz-datepicker-overlay';

type DateInput<D> = string | D | BszDateRange<D> | null;

@Directive()
export abstract class BszDatepickerAbstractInput<D> implements ControlValueAccessor, Validator {
  /** The datepicker that this input is associated with. */
  abstract _datepicker: BszDatePickerOverlay<D> | null;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(isDisabled: BooleanInput) {
    const newValue = coerceBooleanProperty(isDisabled);
    this.setDisabledState(newValue);
  }
  protected _disabled = false;

  /** The minimum valid date. */
  @Input()
  get min(): D | null {
    return this._min;
  }
  set min(value: D | null) {
    const validDateOrNull = this._dateAdapter.getValidDateOrNull(this._dateAdapter.deserialize(value));

    this._min = validDateOrNull;
    this.composeValidators();

    if (this._datepicker) {
      this._datepicker.minDate = validDateOrNull;
    }
  }
  protected _min: D | null = null;

  /** The maximum valid date. */
  @Input()
  get max(): D | null {
    return this._max;
  }
  set max(value: D | null) {
    const validDateOrNull = this._dateAdapter.getValidDateOrNull(this._dateAdapter.deserialize(value));
    this._max = validDateOrNull;
    this.composeValidators();

    if (this._datepicker) {
      this._datepicker.maxDate = validDateOrNull;
    }
  }
  protected _max: D | null = null;

  /** Function that can be used to filter out dates within the datepicker. */
  @Input('bszDatepickerFilter')
  get dateFilter() {
    return this._dateFilter;
  }
  set dateFilter(value: DateFilterFn<D | null> | null) {
    this._dateFilter = value;
    this.composeValidators();

    if (this._datepicker) {
      this._datepicker.dateFilter = value;
    }
  }
  protected _dateFilter: DateFilterFn<D | null> | null = null;

  /**
   * Forms API will register a function to this property
   *
   * When the value changes, call the registered
   * function to allow the forms API to update
   * itself
   *
   * @see `registerOnChange(fn: any): void`
   */
  onChange = (value: DateInput<D>) => {};

  /**
   * Forms API will register a function to this property
   *
   * @see `registerOnTouched(fn: any): void`
   */
  onTouched = () => {};

  /**
   * Forms API will register a callback function to call when the validator inputs change.
   */
  _validatorOnChange = () => {};

  /** The collection of custom validator functions for this control. */
  protected validators: ValidatorFn | null = null;

  constructor(
    readonly _dateAdapter: DateAdapter<D>,
    protected readonly elementRef: ElementRef<HTMLInputElement>,
    @Inject(MAT_DATE_FORMATS) protected readonly dateFormats: MatDateFormats,
    @Optional() @Inject(MAT_FORM_FIELD) readonly _formField: MatFormField | null
  ) {}

  protected initWith(datepicker: BszDatePickerOverlay<D>): void {
    this.connectDatepicker(datepicker);
    this.composeValidators();
  }

  protected connectDatepicker(datepicker: BszDatePickerOverlay<D>) {
    datepicker._registerInput(this);

    // Forward the disabled property to the datepicker only when it's not explicitly set to it.
    if (datepicker._disabled === undefined) {
      datepicker.disabled = this.disabled;
    }

    // forward relevant inputs
    datepicker.maxDate = this.max;
    datepicker.minDate = this.min;
    datepicker.dateFilter = this.dateFilter;
  }

  /**
   * This method is called by the angular form controls to perform
   * synchronous validation against the provided control.
   */
  validate(control: AbstractControl): ValidationErrors | null {
    return this.validators ? this.validators(control) : null;
  }

  /**
   * This method is called by the forms API.
   * Registers a callback function that is called when the control's
   * value changes in the UI.
   */
  registerOnChange(fn: (value: DateInput<D>) => void): void {
    this.onChange = fn;
  }

  /**
   * This method is called by the forms API.
   * Registers a callback function that is called by the forms API on
   * initialization to update the form model on blur.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * This method is called by the forms API.
   * Registers a callback function to call when the validator inputs change.
   */
  registerOnValidatorChange(fn: () => void): void {
    this._validatorOnChange = fn;
  }

  /**
   * Gets the element that the datepicker popup should be connected to.
   *
   * @return The element to connect the popup to.
   *
   * @private
   */
  _getConnectedOverlayOrigin(): ElementRef {
    return this._formField ? this._formField.getConnectedOverlayOrigin() : this.elementRef;
  }

  /** Attempt to convert a string into a date object. */
  protected stringToDateObject(value: string): D | null {
    return this._dateAdapter.parse(value, this.dateFormats.parse.dateInput);
  }

  /** Convert a date object to a string using the format defined in MatDateFormats */
  protected dateObjectToString(value: D | null): string {
    const date = this._dateAdapter.parse(value, this.dateFormats.parse.dateInput);
    const dateFormatted = date ? this._dateAdapter.format(date, this.dateFormats.parse.dateInput) : '';

    return dateFormatted;
  }

  /**
   * This method is called by the forms API to write to the view
   * when programmatic changes from model to view are requested
   *
   * @param value The new value for the element
   */
  abstract writeValue(value: DateInput<D>): void;

  /**
   * This method is called by the forms API when the control
   * status changes to or from 'DISABLED'.
   */
  abstract setDisabledState(isDisabled: boolean): void;

  /** Updates the text of the input element. */
  protected abstract updateView(value: DateInput<D>): void;

  /** Updates the value of the formControl or ngModel respectively. */
  protected abstract updateModel(value: DateInput<D>): void;

  /**
   * Use these method to create and assign any custom validators to the "validators"
   * property and call the "_validatorOnChange()" to revalidate the component.
   */
  protected abstract composeValidators(): void;
}
