import {ActionWithoutResponse} from '@basuiz/mobile-bridge';

/**
 * Downloads a single attachment that matches the provided id from the
 * collaboration REST service and displays it inside of the application on top
 * of the current content.
 */
export class ViewCollaborationAttachmentWithIDAction extends ActionWithoutResponse<
  ViewCollaborationAttachmentWithIDRequest
> {
  readonly name = 'viewCollaborationAttachmentWithID';
}

export interface ViewCollaborationAttachmentWithIDRequest {
  /**
   * An id of an attachment that is stored in AFP and retrieved with
   * collaboration service.
   */
  attachmentID: string;

  /**
   * Name that is going to be displayed in navigation bar of the displayed
   * attachment.
   */
  documentName: string;
}
