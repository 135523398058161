import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';

import {dataPageIndexAttrName} from './bsz-carousel.definitions';

export interface CarouselItemStatus {
  selected: boolean;
  itemIndex: number;
}

@Component({
  selector: 'bsz-carousel-item',
  template: ` <ng-content></ng-content>`,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'bsz-carousel-item',
    role: 'listitem',
    tabindex: '-1', // required for browsing with keyboard combined with the focus helpers
  },
})
export class BszCarouselItem<T = unknown> {
  @Input() itemData: T | null = null;
  @Input() itemIndex = 0;

  @Input()
  set selected(selected: BooleanInput) {
    this._selected = coerceBooleanProperty(selected);
    this.selectedChange.emit({
      selected: this._selected,
      itemIndex: this.itemIndex,
    });
  }

  get selected(): boolean {
    return this._selected;
  }

  @Input() set class(classValue: string) {
    this.classValue = classValue;
  }

  pageIndexAttr: number | null = null;
  private _selected = false;

  readonly selectedChange = new EventEmitter<CarouselItemStatus>();

  constructor(private readonly _elementRef: ElementRef<HTMLElement>, private readonly renderer: Renderer2) {}

  setItemWidth(itemWidth: string) {
    this.renderer.setStyle(this._elementRef.nativeElement, 'width', itemWidth);
  }

  setPageIndex(pageIndex: number) {
    this.pageIndexAttr = pageIndex;
    this.renderer.setAttribute(this._elementRef.nativeElement, dataPageIndexAttrName, `${pageIndex}`);
  }

  classValue = '';
}
