import {Directive, Input, TemplateRef} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslatedText} from '@basuiz/web-app-applet-api';
import {TranslateService} from '@ngx-translate/core';
import {finalize} from 'rxjs/operators';
import {AbstractControl} from '@angular/forms';

@Directive({
  selector: '[bszWebAppStep]',
})
export class WebAppStepDirective {
  /**
   * The label shown in the header when this step is active.
   */
  @Input()
  label: TranslatedText;

  /**
   * The form control used to determine whether a step is valid.
   * The next button is disabled until the form control is valid.
   */
  @Input()
  stepControl: AbstractControl;

  /**
   * Whether the step is disabled.
   */
  @Input()
  disabled: boolean = false;

  /**
   * The template for showing additional info below each step.
   */
  @Input()
  summary?: TemplateRef<unknown>;

  /**
   * A string used to uniquely identify a step.
   */
  @Input()
  stepId?: string;

  /** The web-app stepper will not show the summary of any step when the selected step is the confirmation step */
  @Input()
  confirmationStep: boolean = false;

  /**
   * A factory returning an observable invoked when the user clicks the next button.
   * The stepper only advances if the return value of the validation is truthy.
   */
  private _validationFactory: () => Observable<unknown>;

  get validationFactory(): () => Observable<unknown> {
    return this._validationFactory;
  }

  @Input()
  set validationFactory(value: () => Observable<unknown>) {
    this._validationFactory = () => {
      this.isValidatingSubject.next(true);
      return value().pipe(finalize(() => this.isValidatingSubject.next(false)));
    };
  }

  /**
   * The text used for the next button.
   */
  @Input()
  nextButtonText: TranslatedText = this.translateService.instant(
    asTranslationKey('web-app-common.web-app-stepper.next-button')
  );

  readonly isValidatingSubject = new BehaviorSubject<boolean>(false);

  constructor(public templateRef: TemplateRef<any>, private translateService: TranslateService) {
    this.validationFactory = () => of(true);
  }
}
