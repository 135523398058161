import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {loadHoliday, loadHolidayFailed, loadHolidaySuccess} from './holiday.actions';
import {HolidayDataService} from '../../data-access/holiday-data.service';
import {HolidayDto} from '../../models/holiday-dto.definitions';

@Injectable()
export class HolidayEffects {
  loadHolidays$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadHoliday),
      mergeMap(() => this.holidaysDataService.fetch()),
      map((holidayDtoList: HolidayDto[]) => loadHolidaySuccess({holidayDtoList: holidayDtoList})),
      catchError((error) => of(loadHolidayFailed(error)))
    )
  );

  constructor(private actions$: Actions, private holidaysDataService: HolidayDataService) {}
}
