import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
  Currency,
  FbApiUserData,
  UserJwt,
  UserPublic,
  UserWithoutPassword,
  getFacebookApiEnvironment,
  getFormDataAddPaymentInfo,
  getFormDataAddToCart,
  getFormDataCompleteRegistration,
  getFormDataInitiateCheckout,
  getFormDataPurchase,
  getFormDataRate,
  getFormDataSpentCredits,
} from '@basuiz/shared/data-access';
import {CookieService} from 'ngx-cookie-service';
import {Observable, firstValueFrom, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthJwtService} from '../../auth-jwt';

@Injectable({providedIn: 'root'})
export class FbExtApiService {
  private readonly eventsUrl: string = getFacebookApiEnvironment().conversionsApi.eventsEndpoint;

  readonly userJwt$: Observable<UserJwt | null> = this.authJwtService.userJwt$;

  constructor(
    private httpClient: HttpClient,
    private authJwtService: AuthJwtService,
    private cookieService: CookieService
  ) {}

  private async postEvent(form: FormData): Promise<any | null> {
    return await firstValueFrom(
      this.httpClient.post<any>(`${this.eventsUrl}`, form).pipe(
        catchError((error: HttpErrorResponse): Observable<null> => {
          console.error('ERROR FbExtApiService:', JSON.stringify(error.error));
          return of(null);
        })
      )
    );
  }

  private async getBrowserIpAddress(): Promise<string | null> {
    // TODO p3: the free version of 'freeipapi.com' only supports 60 requests per minute. Change to a paid version when load is higer than this. For NON-PROD enviroments, skips this so we don't spend non-useful requests.

    if (process.env['APP_ENV'] !== 'PROD') {
      return null;
    }

    const ipinfo = await firstValueFrom(
      this.httpClient.get<any>(`https://freeipapi.com/api/json`).pipe(
        catchError((error: HttpErrorResponse): Observable<null> => {
          console.error('ERROR ipinfo:', JSON.stringify(error.error));
          return of(null);
        })
      )
    );

    return ipinfo?.ipAddress ?? null;
  }

  private async getFbApiUserData(user?: UserPublic): Promise<FbApiUserData> {
    let userData: FbApiUserData;

    if (user) {
      // This is for events where there's no logged in user. Ex. on registration

      userData = {
        em: user.emailHashed!, // TODO p4: this field 'em' (emailHashed) could be obtained in the backend as well, to send server-side events
      };

      if (user.emailHashed) {
        userData.external_id = user.emailHashed; // TODO p4 should change to hashed UID
      }

      if (user.phoneHashed) {
        userData.ph = user.phoneHashed; // TODO p4: this field 'ph' (phoneHashed) could be obtained in the backend as well, to send server-side events
      }
    } else {
      // This is for events where there is a logged in user. Ex. on purchase as a logged user
      const userJwt = await firstValueFrom(this.userJwt$);

      userData = {
        em: userJwt?.user.emailHashed!, // TODO p4: this field 'em' (emailHashed) could be obtained in the backend as well, to send server-side events
      };

      if (userJwt?.user.emailHashed) {
        userData.external_id = userJwt?.user.emailHashed; // TODO p4 should change to hashed UID
      }

      if (userJwt?.user.phoneHashed) {
        userData.ph = userJwt?.user.phoneHashed; // TODO p4: this field 'ph' (phoneHashed) could be obtained in the backend as well, to send server-side events
      }
    }

    // TODO p4: currently this only works when running in the browser
    // in order to send events to FB Conversion API, we must store the fbp in the server DB
    const fbp = this.cookieService.get('_fbp');
    if (fbp) {
      userData.fbp = fbp;
    }

    // TODO p4: currently this only works when running in the browser
    // in order to send events to FB Conversion API, we must store the fbc in the server DB
    const fbc = this.cookieService.get('_fbc');
    if (fbc) {
      userData.fbc = fbc;
    }

    // TODO p4: currently this only works when running in the browser
    const ip = await this.getBrowserIpAddress();
    if (ip) {
      userData.client_ip_address = ip;
    }

    // TODO p4: currently this only works when running in the browser
    const userAgent = window.navigator.userAgent;
    if (userAgent) {
      userData.client_user_agent = userAgent;
    }

    return userData;
  }

  async sendFbEventAddPaymentInfo(UID: number): Promise<any | null> {
    const userData: FbApiUserData = await this.getFbApiUserData();

    const formData = getFormDataAddPaymentInfo(UID, userData, null);

    if (!formData) {
      return of(null);
    }

    return await this.postEvent(formData);
  }

  async sendFbEventAddToCart(genericOrderObjectId: number): Promise<any | null> {
    const userData: FbApiUserData = await this.getFbApiUserData();

    const formData = getFormDataAddToCart(genericOrderObjectId, userData, null);

    if (!formData) {
      return of(null);
    }

    return await this.postEvent(formData);
  }

  async sendFbEventCompleteRegistration(user: UserPublic): Promise<any | null> {
    const userData: FbApiUserData = await this.getFbApiUserData(user);

    const formData = getFormDataCompleteRegistration(user.objectId!, userData, null);

    if (!formData) {
      return of(null);
    }

    return await this.postEvent(formData);
  }

  async sendFbEventInitiateCheckout(genericOrderObjectId: number): Promise<any | null> {
    const userData: FbApiUserData = await this.getFbApiUserData();

    const formData = getFormDataInitiateCheckout(genericOrderObjectId, userData, null);

    if (!formData) {
      return of(null);
    }

    return await this.postEvent(formData);
  }

  async sendFbEventPurchase(
    user: UserWithoutPassword,
    genericOrderObjectId: number,
    purchaseCurrency: Currency,
    purchaseValue: number
  ): Promise<any | null> {
    const userData: FbApiUserData = await this.getFbApiUserData(user);

    const customData = {
      currency: purchaseCurrency,
      value: purchaseValue,
    };

    const formData = getFormDataPurchase(genericOrderObjectId, userData, customData);

    if (!formData) {
      return of(null);
    }

    return await this.postEvent(formData);
  }

  async sendFbEventRate(genericOrderObjectId: number): Promise<any | null> {
    const userData: FbApiUserData = await this.getFbApiUserData();

    const formData = getFormDataRate(genericOrderObjectId, userData, null);

    if (!formData) {
      return of(null);
    }

    return await this.postEvent(formData);
  }

  async sendFbEventSpentCredits(genericOrderObjectId: number): Promise<any | null> {
    const userData: FbApiUserData = await this.getFbApiUserData();

    const formData = getFormDataSpentCredits(genericOrderObjectId, userData, null);

    if (!formData) {
      return of(null);
    }

    return await this.postEvent(formData);
  }
}
