import {InjectionToken} from '@angular/core';

/** On the 'blocking' strategy, APP_INITIALIZERs provided by web-app will block the Angular initialization until
 * the 'web-app initializers' have completed (either successfully or with failures).
 * As consequence, no Angular content will be rendered which, as side-effect, prevents instantiating applets until
 * the web-app root initialization has completed.
 * On the 'non-blocking' strategy, web-app initializers will not block the Angular bootstrap process,
 * therefore the Angular application can start rendering content while web-app is still initializing, however,
 * the Application owner must make sure the application does not instantiate any applets until the web-app
 * initialization has completed by listening to 'WebAppInitializationService.getInitializationResult$()' */
export type WebAppInitializationStrategy = 'blocking' | 'non-blocking';

/** Determines the strategy used while web-app initializes during the Angular application bootstrap process. */
export const WEB_APP_INITIALIZATION_STRATEGY: InjectionToken<WebAppInitializationStrategy> = new InjectionToken(
  'bsz.web-app.initialization-strategy',
  {
    providedIn: 'root',
    factory: () => 'blocking',
  }
);
