import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {AuthDialogRepresentationGroupDetails} from '../../../models/auth-dialog.definitions';

@Component({
  selector: 'bsz-collective-representation',
  templateUrl: './collective-representation.component.html',
  styleUrls: ['./collective-representation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectiveRepresentationComponent {
  @Input()
  groupedDetails: AuthDialogRepresentationGroupDetails[];
}
