// IMPORTANT: do not modify this file

import {inject, InjectionToken, FactoryProvider} from '@angular/core';
import {BszConfigFactory, BszConfigFactoryWithDeps} from '@basuiz/web-app-applet-api';
import {PortalConfig} from './portal.config.definition';
import {getDefaultConfig} from './portal.config.default';
import {validatePortalConfig} from './portal.config.validation';
import {WEB_APP_EXPANDED_FEATURE_TOGGLE} from '@basuiz/web-app-feature-toggle';
import {UserRoleService} from '@basuiz/web-app-applet-sdk';

// IMPORTANT: do not modify this file

export const ɵPORTAL_CONFIG = new InjectionToken<PortalConfig>('bsz.web-app.portal-config', {
  factory: () => inject(PORTAL_CONFIG_DEFAULT),
});

// IMPORTANT: do not modify this file

export const PORTAL_CONFIG_DEFAULT = new InjectionToken<PortalConfig>('bsz.web-app.portal-config.default', {
  factory: () => {
    const config = getDefaultConfig(inject(WEB_APP_EXPANDED_FEATURE_TOGGLE), inject(UserRoleService));
    const error = validatePortalConfig(config);
    if (error) {
      console.error('Default PortalConfig validation failed:', error);
    }
    return config;
  },
});

// IMPORTANT: do not modify this file

export function portalConfigProvider({useFactory, deps}: BszConfigFactoryWithDeps<PortalConfig>): FactoryProvider {
  return {
    provide: ɵPORTAL_CONFIG,
    useFactory: factoryWithValidation(useFactory),
    deps: [PORTAL_CONFIG_DEFAULT, ...(deps || [])],
  };
}

// IMPORTANT: do not modify this file

function factoryWithValidation(factory: BszConfigFactory<PortalConfig>): BszConfigFactory<PortalConfig> {
  return (defaultConfig: PortalConfig, ...deps: unknown[]) => {
    const config = factory(defaultConfig, ...deps);
    const error = validatePortalConfig(config);
    if (error) {
      console.error('Portal validation failed:', error);
    }
    return config;
  };
}

// IMPORTANT: do not modify this file
