import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, FEATURE_KEY, State} from './attention-item-counter.reducer';
import {getErrorMessage, LoadingState} from '@basuiz/web-app-common';

const adapterSelectors = adapter.getSelectors();

export const selectState = createFeatureSelector<State>(FEATURE_KEY);

export const selectLoaded = createSelector(selectState, (state: State) => state.callState === LoadingState.LOADED);

export const selectLoading = createSelector(selectState, (state: State) => state.callState === LoadingState.LOADING);

export const selectErrorMessage = createSelector(selectState, (state) => getErrorMessage(state.callState));

export const selectAll = createSelector(selectState, adapterSelectors.selectAll);

export const selectEntities = createSelector(selectState, adapterSelectors.selectEntities);
