import {BszFeatureToggle} from './models/features.definition';
import {ExpandedFeatureToggle} from './models/features-expanded.definition';
import {getAllEnabledFeatureToggle} from './all-enabled-feature-toggle';
import {FeatureStatus} from './models/generic.definition';

type OriginalNode = {[key in string]: {} | FeatureStatus};
type ExpandedNode = {[key in string]: {} | null};

export function expandFeatureToggle(toggle: BszFeatureToggle): ExpandedFeatureToggle {
  const allEnabled: BszFeatureToggle = getAllEnabledFeatureToggle();

  const result = (expandRecursive(
    (toggle as unknown) as OriginalNode,
    (allEnabled as unknown) as OriginalNode
  ) as unknown) as ExpandedFeatureToggle;

  return result;
}

function expandRecursive(toggleNode: OriginalNode, allEnabledSubTree: OriginalNode): ExpandedNode {
  const result: ExpandedNode = {};
  Object.entries(toggleNode).forEach(([key, value]) => {
    if (value === 'disabled') {
      result[key] = null;
    } else if (value === 'enabled') {
      result[key] = allEnabledSubTree[key];
    } else {
      result[key] = expandRecursive(value, allEnabledSubTree[key] as OriginalNode);
    }
  });
  return result;
}
