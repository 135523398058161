<div class="bsz-calendar-content-wrapper"
     [@transformCalendar]="_getOpenedState()">
  <div [class.bsz-display-block]="touchUi" class="bsz-calendar-wrapper">

    <mat-selection-list
      *ngIf="touchUi === false && presets"
      [multiple]="false"
      [attr.aria-label]="'ui-elements.bsz-datepicker.date-presets.label' | translate"
      (selectionChange)="_onPresetSelectionChange($event)"
      class="bsz-calendar-preset-list">
      <mat-list-option
        *ngFor="let preset of presets | keyvalue: _keyValuePipeOriginalOrder;"
        [selected]="preset.value === _selectedPreset"
        [value]="preset.value"
        class="bsz-body-1"
      >
        {{preset.key}}
      </mat-list-option>
    </mat-selection-list>

    <div *ngIf="touchUi === true && presets" class="bsz-calendar-preset-list-mobile">
      <mat-form-field style="width: 100%;">
        <mat-label>{{'ui-elements.bsz-datepicker.date-presets.label' | translate}}</mat-label>
        <mat-select (selectionChange)="_onPresetSelectionChange($event)" [value]="_selectedPreset">
          <mat-option *ngFor="let preset of presets | keyvalue: _keyValuePipeOriginalOrder;" [value]="preset.value">
            {{preset.key}}
          </mat-option>
          <mat-divider></mat-divider>
          <mat-option [value]="_presetForCustomValue">
            {{'ui-elements.bsz-datepicker.date-presets.option.custom' | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-calendar
      (selectedChange)="_onCalendarDaySelect($event)"
      [selected]="_selected"
      [maxDate]="maxDate"
      [minDate]="minDate"
      [startAt]="startAt"
      [dateFilter]="$any(dateFilter)"
      class="bsz-inner-calendar">
      <!--
        Using $any(dateFilter) since the typing in the input `dateFilter` on the
        mat-calendar does not include null or undefined even though they are valid.
        If we try to pass undefined or null it results to compilation error and
        since we cannot bind a property conditionally in the template we do this
        workaround.
      -->
    </mat-calendar>
  </div>

  <div *ngIf="rangePicker" class="bsz-calendar-actions">
    <button class="bsz-calendar-cancel-button" (click)="_onCancel()" mat-button
            color="primary">{{ 'ui-elements.bsz-datepicker.cancel-button' | translate }}</button>
    <button class="bsz-calendar-apply-button" (click)="_onSubmit()" mat-flat-button
            color="primary">{{ 'ui-elements.bsz-datepicker.apply-button' | translate }}</button>
  </div>

  <!-- Invisible close button for keyboard and screen reader users. -->
  <button
    *ngIf="!rangePicker"
    (focus)="_closeButtonFocused = true"
    (blur)="_closeButtonFocused = false"
    (click)="_onCancel()"
    [class.cdk-visually-hidden]="!_closeButtonFocused"
    mat-flat-button
    color="primary"
    class="bsz-calendar-close-button">
    {{ 'ui-elements.bsz-datepicker.close-button' | translate }}
  </button>
</div>
