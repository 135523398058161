import {LoadChildren, Route} from '@angular/router';
import {generatePortalRoute, PortalPageClass} from '@basuiz/web-app-portal';
import {HybridPageGuard} from './hybrid-page.guard';

/**
 * Generates an Angular route that conforms to the navigation system of the web-app portal.
 * With an additional route guard that ensure the route is only available when the app is embedded
 * in a native application
 */
export function generatePortalHybridRoute(
  /**
   *  Portal page behind the route, the value is the class itself, not an instance.
   *  To adhere to the portal standards, one route is tied to one and only one Page and vice versa.
   */
  pageClass: PortalPageClass,
  /**
   * Angular's route 'loadChildren'.
   * For a faster initial load of the portal, pages are lazy loaded.
   * Example: () => import('./my-pages/foo-page/index').then((m) => m.FooPageModule) */
  loadChildren: LoadChildren
): Route {
  const {canActivate, ...route} = generatePortalRoute(pageClass, loadChildren);

  return {
    ...route,
    canActivate: [...(canActivate || []), HybridPageGuard],
  };
}
