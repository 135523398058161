import {Component, Inject, Input, OnInit} from '@angular/core';
import {CommonConfig, ɵCOMMON_CONFIG} from '@basuiz/web-app-common/config';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  ExportToFileConfigFactory,
  ExportToFileFromClientDataConfig,
} from '../../definitions/export-to-file-config.definition';
import {
  isExportToFileFromClientDataConfig,
  isExportToFileFromServerDataConfig,
} from '../../definitions/export-to-file-config.typeguard';
import {FileType} from '../../enums/file-type.enum';
import {ExportToFileService} from '../../services/export-to-file.service';
import {assertNever} from '../../../utils/assert-never';
import {HybridActionService} from '@basuiz/web-app-hybrid';

@Component({
  selector: 'bsz-export-to-file-button',
  templateUrl: './export-to-file-button.component.html',
  styleUrls: ['./export-to-file-button.component.scss'],
})
export class ExportToFileButtonComponent implements OnInit {
  @Input() set fileTypes(inputFileTypes: FileType[]) {
    this._fileTypes$.next(inputFileTypes);
  }

  @Input() exportToFileConfigFactory: ExportToFileConfigFactory;

  @Input() isDisabled = false;

  FILE_TYPE = FileType;

  exportFileTypes$: Observable<FileType[]>;

  private _fileTypes$: BehaviorSubject<FileType[]> = new BehaviorSubject([]);

  constructor(
    @Inject(ɵCOMMON_CONFIG) private commonConfig: CommonConfig,
    private exportToFileService: ExportToFileService,
    private hybridActionService: HybridActionService
  ) {}

  ngOnInit() {
    this.exportFileTypes$ = this._fileTypes$.pipe(map((fileTypes) => this.exportToFileFileTypes(fileTypes)));
  }

  public download(fileType: FileType) {
    let config = this.exportToFileConfigFactory(fileType);
    if (isExportToFileFromClientDataConfig(config)) {
      // This is a workaround. Please remove after resolving AFP-43450.
      if (fileType === FileType.CSV) {
        config = JSON.parse(JSON.stringify(config).replace(/’/g, "'")) as ExportToFileFromClientDataConfig;
      }

      return this.exportToFileService.fromClientData(config).subscribe();
    }
    if (isExportToFileFromServerDataConfig(config)) {
      return this.exportToFileService.fromServerData(config).subscribe();
    }
  }

  public exportToFileFileTypes(inputFileTypes: FileType[]): FileType[] {
    return !this.hybridActionService.isInsideNative()
      ? this.commonConfig.exportToFileFileTypes
          .map((fileType) => {
            switch (fileType) {
              case 'PDF':
                return FileType.PDF;
              case 'CSV':
                return FileType.CSV;
              case 'EXCEL':
                return FileType.EXCEL;
              default:
                assertNever(fileType);
            }
          })
          .filter((fileType) => (inputFileTypes?.length > 0 ? inputFileTypes.includes(fileType) : true))
      : [];
  }
}
