import {Injectable} from '@angular/core';

import {BszPopover} from './bsz-popover';

// The trigger for the popover uses this simple service as way to
// close other popover that could be open previously. It is not an
// array because opening one will close the previous, so there is
// no way to have more than one at the same time.
@Injectable({
  providedIn: 'root',
})
export class BszPopoverService {
  private activePopover: BszPopover | null = null;

  closePreviousPopover() {
    if (this.activePopover) {
      this.activePopover.emitCloseEvent();
      this.setCurrentPopover(null);
    }
  }

  setCurrentPopover(popover: BszPopover | null) {
    this.activePopover = popover;
  }
}
