import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {BszSkeletonLoader} from './bsz-skeleton-loader';

@NgModule({
  imports: [CommonModule],
  exports: [BszSkeletonLoader],
  declarations: [BszSkeletonLoader],
})
export class BszSkeletonLoaderModule {}
