import {InjectionToken, Provider} from '@angular/core';
import {WebAppCommonLocaleConfig} from './definition/locale-config.definition';

/* Notice that the locale config is optional since banks can opt to provide their own logic
 * to get and change the user locale */
export type OptionalWebAppCommonLocaleConfig = WebAppCommonLocaleConfig | undefined;

export const WEB_APP_COMMON_LOCALE_CONFIG = new InjectionToken<OptionalWebAppCommonLocaleConfig>(
  'bsz.web-app-common-locale.config',
  {
    factory: () => undefined,
  }
);

export function commonLocaleConfigProvider(
  factory: (...args: any[]) => WebAppCommonLocaleConfig,
  deps: any[] = []
): Provider {
  return {provide: WEB_APP_COMMON_LOCALE_CONFIG, useFactory: factory, deps};
}
