import {animate, state, style, transition, trigger} from '@angular/animations';

const transitionTiming = '300ms cubic-bezier(0.4, 0, 0.2, 1)';

export class BszPageHeaderAnimations {
  static readonly pageHeaderContentTransition = trigger('pageHeaderContentTransition', [
    state('expanded', style({opacity: 1, maxHeight: '1000px'})),
    state('collapsed', style({opacity: 0, maxHeight: '0'})),
    transition('*  => void', [style({opacity: 0, maxHeight: '1000px'})]),
    transition('expanded => collapsed', animate(transitionTiming)),
    transition('collapsed => expanded', animate(transitionTiming)),
  ]);

  static readonly pageHeaderShadowTransition = trigger('pageHeaderShadowTransition', [
    state('shadowed', style({opacity: 0.5})),
    state('*', style({opacity: 0})),
    transition('*  => void', [style({opacity: 0.5})]),
    transition('* => shadowed', animate(transitionTiming)),
    transition('shadowed => *', animate(transitionTiming)),
  ]);
}
