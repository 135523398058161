import {BszShipmentFormAction, TranslationKey} from '@basuiz/web-app-applet-api';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {PortalPage} from '../navigation/classes/portal-page';
import {TranslationKeyWithParams} from '../navigation/translation-key-with-params';
import {ShipmentOverviewPage} from './shipment-overview.page';

export interface ShipmentEntryPagePayload {
  /**
   * Determines the action to be done
   */
  shipmentFormAction?: BszShipmentFormAction;
}

const defaultPayload: ShipmentEntryPagePayload = {shipmentFormAction: {action: 'create'}};

export class ShipmentEntryPage extends PortalPage {
  constructor(public payload: ShipmentEntryPagePayload = defaultPayload) {
    super();
  }

  get parentPortalPage(): PortalPage | null {
    return new ShipmentOverviewPage();
  }

  get breadcrumbText(): TranslationKey | TranslationKeyWithParams {
    return asTranslationKey('web-app-portal.pages.shipment-entry.breadcrumb');
  }

  get breadcrumbTestId(): string {
    return 'web-app-portal.pages.shipment-entry.breadcrumb';
  }

  get titleText(): TranslationKey {
    return asTranslationKey('web-app-portal.pages.shipment-entry.window-title');
  }

  static readonly routerConfigPath = 'shipments/new';
  get route(): Array<string> {
    return [`/${ShipmentEntryPage.routerConfigPath}`];
  }
}
