<h3 mat-dialog-title class="bsz-title-5">User roles</h3>

<section>
  <mat-slide-toggle [checked]="useInterceptor" (change)="setUseInterceptor($event.checked)"> </mat-slide-toggle>
  <span class="bsz-margin-l2 bsz-body-2">Use interceptor</span>
</section>

<bsz-wb-banner type="warning" *ngIf="useInterceptor" class="bsz-margin-y2 bsz-body-1" style="max-width: 400px">
  The web application is using the role(s) defined in the selection below. However, bear in mind,
  <b>that this tool does not have any effect in the back-end</b>, which is still taking the original role of the user.
</bsz-wb-banner>

<div class="role-list bsz-margin-t2" *ngIf="useInterceptor">
  <mat-checkbox [(ngModel)]="retail">Retail</mat-checkbox>
  <mat-checkbox [(ngModel)]="corporate">Corporate</mat-checkbox>
</div>

<div mat-dialog-actions>
  <button mat-button color="accent" mat-dialog-close>Cancel</button>
  <button mat-flat-button color="accent" (click)="onReload()">Set and reload</button>
</div>
