import {PortalModule} from '@angular/cdk/portal';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {BszI18nModule} from '@basuiz/i18n';

import {BszNotificationOverlayModule} from '../bsz-notification-overlay';
import {
  BszNotificationToast,
  BszNotificationToastAction,
  BszNotificationToastActions,
  BszNotificationToastIcon,
  BszNotificationToastSummary,
  BszNotificationToastTitle,
} from './bsz-notification-toast';
import {BszNotificationToastService} from './bsz-notification-toast.service';

@NgModule({
  imports: [
    CommonModule,
    BszI18nModule,
    MatButtonModule,
    MatRippleModule,
    PortalModule,
    BszNotificationOverlayModule,
    MatIconModule,
  ],
  declarations: [
    BszNotificationToast,
    BszNotificationToastTitle,
    BszNotificationToastSummary,
    BszNotificationToastIcon,
    BszNotificationToastActions,
    BszNotificationToastAction,
  ],
  exports: [
    BszNotificationToast,
    BszNotificationToastTitle,
    BszNotificationToastSummary,
    BszNotificationToastIcon,
    BszNotificationToastActions,
    BszNotificationToastAction,
  ],
  entryComponents: [BszNotificationToast],
  providers: [BszNotificationToastService],
})
export class BszNotificationToastModule {}
