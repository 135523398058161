import {OverlayContainer} from '@angular/cdk/overlay';
import {Platform} from '@angular/cdk/platform';
import {DOCUMENT} from '@angular/common';
import {Inject, Injectable, OnDestroy, StaticProvider} from '@angular/core';
import {bszWebAppThemeScopeCssClass} from '@basuiz/web-app-applet-api';

@Injectable()
/**
  Child class of Angular Material's 'OverlayContainer' that adds to the overlay container DOM element
  the web-app CSS scoping class required to apply the web-app theme to all overlays.
  Warning! By using BszOverlayContainer, Angular Material overlays (dialog, menu, select, etc.),
  defined by the application will also use the web-app theme.
*/
export class BszOverlayContainer extends OverlayContainer implements OnDestroy {
  constructor(@Inject(DOCUMENT) _document: Document, @Inject(Platform) _platform: Platform) {
    super(_document, _platform);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  protected _createContainer(): void {
    super._createContainer();
    if (!this._containerElement) return;

    this._containerElement.classList.add(bszWebAppThemeScopeCssClass);
  }
}

/**
 * Returns a provider that ensures that all Angular Material overlays apply the web-app theme.
 * In order to work, it must be provider in the root provider scope of the Angular application.
 */
export function bszOverlayContainerProvider(): StaticProvider {
  return {provide: OverlayContainer, useClass: BszOverlayContainer};
}
