import {NavigationIntent} from '../../navigation/classes/navigation-intent';
import {BszCollaborationIssueType} from '@basuiz/web-app-applet-api';

export interface CreateCollaborationInquiryIntentPayload {
  collaborationIssueType: BszCollaborationIssueType;
}

export class CreateCollaborationInquiryIntent extends NavigationIntent {
  constructor(public payload: CreateCollaborationInquiryIntentPayload) {
    super();
  }
}
