import {Inject, Injectable, Optional} from '@angular/core';
import {filter, first} from 'rxjs/operators';

import {BszNotificationOverlayConfig, BszNotificationOverlayService} from '../bsz-notification-overlay';
import {BszScreenSize} from '../bsz-screen-size-content-switcher';
import {BszSnackbar} from './bsz-snackbar';
import {BSZ_SNACK_BAR_DEFAULT_OPTIONS, BszSnackbarConfig} from './bsz-snackbar.definitions';

const DEFAULT_CORE_CONFIG: BszNotificationOverlayConfig = {
  verticalPosition: 'bottom',
  horizontalPosition: 'center',
  type: 'snackbar',
  politeness: 'polite',
  duration: 4000,
  maxStackLength: 1,
  pauseOnHover: true,
};

const minDuration = 4000;
const maxDuration = 10000;
const LOG_PREFIX = '[bsz-snackbar]';

@Injectable({
  providedIn: 'root',
})
export class BszSnackbarService {
  private readonly config: BszNotificationOverlayConfig;

  /** @private */
  _dismissable = false;

  isActive = false;

  constructor(
    private readonly notificationOverlayService: BszNotificationOverlayService,
    private readonly screenSizeService: BszScreenSize,
    @Optional() @Inject(BSZ_SNACK_BAR_DEFAULT_OPTIONS) defaultConfig?: BszSnackbarConfig
  ) {
    this.config = Object.assign({}, DEFAULT_CORE_CONFIG, defaultConfig);
  }

  /**
   * Creates a snackbar with a message for the content and an optional action
   * @param message The message to show
   * @param action The label for the action
   * @param config Configuration options
   */
  open(message: string, action?: string, config?: BszSnackbarConfig): BszSnackbar {
    this.validateDuration(config?.duration);
    const dismissable = config?.dismissable || false;
    const isActive = this.isActive;
    const configuration = Object.assign({}, this.config, config || {}, {
      data: {message, action, dismissable, isActive},
    });

    if (configuration.horizontalPosition === 'left') {
      this.screenSizeService
        .getScreenSize()
        .pipe(
          first(),
          filter((size) => size === 'mobile')
        )
        .subscribe(() => (configuration.horizontalPosition = 'center'));
    }

    const {notificationOverlay, component} = this.notificationOverlayService.openFromComponent(
      BszSnackbar,
      configuration
    );

    notificationOverlay.afterOpened.pipe(first()).subscribe(() => {
      component.afterOpened.emit();
      component._setMultiline();
    });

    notificationOverlay.afterDismissed.pipe(first()).subscribe(() => {
      component.afterDismissed.emit();
    });

    return component;
  }

  private validateDuration(duration: number | undefined) {
    if (duration === undefined) {
      return;
    }
    if (duration < minDuration) {
      throw new Error(`${LOG_PREFIX} The duration cannot be lower than ${minDuration} milliseconds`);
    }
    if (duration > maxDuration) {
      throw new Error(`${LOG_PREFIX} The duration cannot be higher than ${maxDuration} milliseconds`);
    }
  }
}
