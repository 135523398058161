import {InjectionToken} from '@angular/core';

export interface SectionDefinition {
  id: string | undefined;
  sectionId: string;
  text: string;
  element: HTMLElement;
  intersectionObserver?: IntersectionObserver;
  active: boolean;
}

export interface BszRouterLinkConfiguration {
  routerLink: any[] | string | null | undefined;
  fragment?: string | ((param: unknown) => string);
}

export interface BszContentNavigationOptions {
  routerLinkConfiguration?: BszRouterLinkConfiguration;
  sectionSelector?: string;
  navigationPosition?: 'left' | 'right';
}

/** Injection token that can be used to specify default configuration */
export const BSZ_CONTENT_NAVIGATION_SECTION_OPTIONS = new InjectionToken<BszContentNavigationOptions>(
  'bsz_page_content_navigation_section_selector'
);
