import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BszI18nService} from '@basuiz/i18n';

const AFP_URLS_THAT_REQUIRE_LANG_PARAMETER: Array<{
  urlStartsWith: `/${string}`;
  langQueryParam: 'afp-lang' | 'afpLang';
}> = [
  {
    urlStartsWith: '/com.basuiz.afs.rest.services/',
    langQueryParam: 'afp-lang',
  },
  {
    urlStartsWith: '/com.basuiz.afpaas.master.rest.services/',
    langQueryParam: 'afp-lang',
  },
  {
    urlStartsWith: '/AWS/',
    langQueryParam: 'afpLang',
  },
];

@Injectable()
export class AfpRestRequestLangHttpInterceptor implements HttpInterceptor {
  constructor(private i18nService: BszI18nService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const langHttpParams = this.getLangHttpParamsForUrl(req.url);

    if (langHttpParams) {
      return next.handle(req.clone({params: req.params.appendAll(langHttpParams)}));
    }

    return next.handle(req);
  }

  private getLangHttpParamsForUrl(url: string): Record<string, string> | undefined {
    const localeId = this.i18nService.localeId;

    if (!localeId) {
      return undefined;
    }

    const requiredLangQueryParam = AFP_URLS_THAT_REQUIRE_LANG_PARAMETER.find((required) =>
      url.startsWith(required.urlStartsWith)
    )?.langQueryParam;

    if (!requiredLangQueryParam) {
      return undefined;
    }

    const localeIdInAfpFormat = localeId.replace(/-/g, '_');

    return {
      [requiredLangQueryParam]: localeIdInAfpFormat,
    };
  }
}
