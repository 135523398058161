export enum DateFormat {
  SHORT_DATE = 'shortDate',
  MEDIUM_DATE = 'mediumDate',
  LONG_DATE = 'longDate',
  FULL_DATE = 'fullDate',
  SHORT_DATE_TIME = 'shortDateTime',
  MEDIUM_DATE_TIME = 'mediumDateTime',
  SHORT_TIME = 'shortTime',
  MEDIUM_TIME = 'mediumTime',
}
