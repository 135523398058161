import {ChangeDetectorRef, Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {Subscription} from 'rxjs';

import {BszScreenSize, ScreenSize} from './bsz-screen-size';

@Directive({
  selector: '[bszDesktopContent]',
})
export class BszDesktopContent implements OnInit, OnDestroy {
  constructor(
    private screenSize: BszScreenSize,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cd: ChangeDetectorRef
  ) {}

  private observerSubscription: Subscription;

  ngOnInit() {
    this.observerSubscription = this.screenSize.getScreenSize().subscribe((size: ScreenSize) => {
      this.viewContainer.clear();
      if (size === 'tablet' || size === 'desktop') {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
      this.cd.detectChanges();
    });
  }

  ngOnDestroy() {
    this.observerSubscription.unsubscribe();
  }
}

@Directive({
  selector: '[bszMobileContent]',
})
export class BszMobileContent implements OnInit, OnDestroy {
  constructor(
    private screenSize: BszScreenSize,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cd: ChangeDetectorRef
  ) {}

  private observerSubscription: Subscription;

  ngOnInit() {
    this.observerSubscription = this.screenSize.getScreenSize().subscribe((size: ScreenSize) => {
      this.viewContainer.clear();
      if (size === 'mobile') {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
      this.cd.detectChanges();
    });
  }

  ngOnDestroy() {
    this.observerSubscription.unsubscribe();
  }
}
