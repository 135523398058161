import {JestFixtureFacade, JestFixtureFacadeAware} from './jest-fixture-facade';
import {SimpleElement} from '../generic/components';

export class JestSimpleElementList implements JestFixtureFacadeAware {
  constructor(public testId: string, public jestFixtureFacade: JestFixtureFacade) {}

  get elements(): SimpleElement[] {
    return Array.from(this.jestFixtureFacade.selectAll(this.testId)).map((element: Element) => ({
      testId: this.testId,
      textContent: element.textContent,
    }));
  }
}
