<nav *ngIf="breadcrumbs$ | async as breadcrumbs" class="bsz-display-flex bsz-align-items-center">
  <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last; let index = index">
    <bsz-breadcrumb
      [active]="!last"
      [displayText]="breadcrumb"
      (navigate)="breadcrumbClicked(breadcrumbs, index)"
    ></bsz-breadcrumb>
    <bsz-breadcrumb-separator *ngIf="!last"></bsz-breadcrumb-separator>
  </ng-container>
</nav>
