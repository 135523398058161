import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {PortalPageComponent} from './classes/portal-page-component';
import {PortalPage} from './classes/portal-page';
import {NavigationService} from './navigation.service';
import {map, mergeMap} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {UnsavedDataGuardDialogComponent} from '../components/unsaved-data-guard-dialog/index';

@Injectable({
  providedIn: 'root',
})
export class UnsavedDataGuard implements CanDeactivate<PortalPageComponent<PortalPage>> {
  constructor(private navigationService: NavigationService, public dialog: MatDialog) {}

  canDeactivate(
    component: PortalPageComponent<PortalPage>,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.navigationService.currentPortalPage$.pipe(
      mergeMap((portalPage) => {
        return portalPage.hasUnsavedData ? this.confirmAction() : of(true);
      })
    );
  }

  confirmAction(): Observable<boolean> {
    const dialogRef = this.dialog.open<UnsavedDataGuardDialogComponent, void, boolean>(UnsavedDataGuardDialogComponent);
    return dialogRef.afterClosed().pipe(map((confirmation) => !!confirmation));
  }
}
