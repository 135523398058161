import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {BszConfirmationModalData} from './bsz-confirmation-modal.model';
import {ThemePalette} from '@angular/material/core';

@Component({
  selector: 'bsz-confirmation-modal',
  templateUrl: './bsz-confirmation-modal.component.html',
  styleUrls: ['./bsz-confirmation-modal.component.scss'],
})
export class BszConfirmationModalComponent implements OnInit {
  public confirmationButtonColor: ThemePalette;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BszConfirmationModalData,
    public dialogRef: MatDialogRef<BszConfirmationModalComponent>
  ) {
    this.confirmationButtonColor = data.confirmationButtonColor ?? 'primary';
  }

  ngOnInit() {
    this.dialogRef.disableClose = true;
  }

  confirmAction() {
    this.dialogRef.close(true);
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
}
