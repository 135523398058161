import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {CallState, LoadingState} from '@basuiz/web-app-common';
import {
  deleteNotificationList,
  deleteNotificationListError,
  deleteNotificationListSuccess,
  loadNotificationListError,
  loadNotificationListSuccess,
  loadNotificationsRequired,
  markAllNotificationsAsRead,
  markNotificationListAsRead,
  markNotificationListAsReadError,
  markNotificationListAsReadSuccess,
  markNotificationListAsUnread,
  markNotificationListAsUnreadError,
  markNotificationListAsUnreadSuccess,
  restoreNotificationListError,
  restoreNotificationListSuccess,
} from './notifications.actions';
import {Notification} from '../../models/notification.definition';

export const FEATURE_KEY = 'web-app-self-service-notifications/notifications';

export interface PartialState {
  readonly [FEATURE_KEY]: State;
}

export interface State extends EntityState<Notification> {
  callState: CallState;
}

export const adapter: EntityAdapter<Notification> = createEntityAdapter<Notification>({
  selectId: (collaborationIssue: Notification) => collaborationIssue.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  callState: LoadingState.INIT,
});

const _reducer = createReducer(
  initialState,
  on(
    loadNotificationsRequired,
    deleteNotificationList,
    markAllNotificationsAsRead,
    markNotificationListAsRead,
    markNotificationListAsUnread,
    (state) => ({
      ...state,
      callState: LoadingState.LOADING,
    })
  ),
  on(
    deleteNotificationListSuccess,
    markNotificationListAsReadSuccess,
    markNotificationListAsUnreadSuccess,
    restoreNotificationListSuccess,
    (state, {notificationList}) =>
      adapter.updateMany(notificationList, {
        ...state,
        callState: LoadingState.LOADED,
      })
  ),
  on(loadNotificationListSuccess, (state, {notificationList}) =>
    adapter.setAll(notificationList, {
      ...state,
      callState: LoadingState.LOADED,
    })
  ),
  on(
    deleteNotificationListError,
    markNotificationListAsReadError,
    markNotificationListAsUnreadError,
    loadNotificationListError,
    restoreNotificationListError,
    (state, error) => ({
      ...state,
      callState: error,
    })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return _reducer(state, action);
}
