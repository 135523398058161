import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnDestroy, Output} from '@angular/core';
import {BreadcrumbsService} from '../breadcrumbs.service';
import {TranslatedText} from '@basuiz/web-app-applet-api';

@Component({
  selector: 'bsz-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent implements OnDestroy {
  readonly bszTestIdPrefix = 'web-app-portal.bsz-breadcrumb';
  @Input()
  active: boolean = true;

  @Input()
  appearance: 'regular' | 'back-button' = 'regular';

  @Input()
  displayText: TranslatedText;

  @Output()
  navigate: EventEmitter<void> = new EventEmitter();

  @HostBinding('class')
  breadcrumbCssClasses = 'bsz-caption';

  constructor(private readonly breadcrumbsService: BreadcrumbsService) {
    this.breadcrumbsService.addBreadcrumb();
  }

  ngOnDestroy(): void {
    this.breadcrumbsService.removeBreadcrumb();
  }
}
