import {Pipe, PipeTransform} from '@angular/core';

import {isUndefined, throwInvalidPipeArgument} from '../../helpers/formatting-helpers';

@Pipe({name: 'bszLink'})
export class BszLinkPipe implements PipeTransform {
  public transform(value: string | null | undefined): string {
    // return empty string for nil param
    if (isUndefined(value)) {
      return '';
    }

    // if value is not of an accepted type then fail
    if (typeof value !== 'string') {
      throwInvalidPipeArgument(BszLinkPipe, value);
    }

    // This pipe does not provide any significant transformation
    // It is kept for consistency with other data types
    return value;
  }
}
