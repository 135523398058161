import {ComponentFixture} from '@angular/core/testing';
import {JestFixtureFacade, JestFixtureFacadeAware} from '../testing-core/index';
import {MatPaginator} from '@angular/material/paginator';

export class BszPaginatorComponentPageObject implements JestFixtureFacadeAware {
  jestFixtureFacade: JestFixtureFacade;

  constructor(fixture: ComponentFixture<any>) {
    this.jestFixtureFacade = new JestFixtureFacade(fixture);
  }

  getMatPaginator(): MatPaginator {
    return this.jestFixtureFacade.debugElement
      .query((element) => element.nativeElement.getAttribute('test-id') === 'web-app-common-bsz-paginator')
      .injector.get<MatPaginator>(MatPaginator);
  }
}
