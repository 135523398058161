import {ComponentFixture} from '@angular/core/testing';
import {JestFixtureFacade, JestFixtureFacadeAware} from '../testing-core/index';

export class BszConfirmationModalComponentPageObject implements JestFixtureFacadeAware {
  jestFixtureFacade: JestFixtureFacade;

  constructor(fixture: ComponentFixture<any>) {
    this.jestFixtureFacade = new JestFixtureFacade(fixture);
  }

  confirmDialog() {
    this.click('bsz-confirmation-modal-confirm-action-button');
  }

  cancelDialog() {
    this.click('bsz-confirmation-modal-close-dialog-button');
  }

  private click(testId: string) {
    const button: HTMLButtonElement = this.jestFixtureFacade.selectFromParent(testId) as HTMLButtonElement;
    if (button) {
      button.click();
    } else {
      throw new Error('Button with testId: ' + testId + 'could not be found');
    }
  }
}
