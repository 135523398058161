import {isNotNullOrUndefined, isStringFalsy} from '../../utils/defined.util';

export function getExportFilterRange(
  fromValue: number | string | undefined | null,
  toValue: number | string | undefined | null
): string | undefined {
  let filterString: string | undefined;
  const hasFromValue = hasValue(fromValue);
  const hasToValue = hasValue(toValue);

  if (hasFromValue && hasToValue) {
    filterString = `${fromValue} - ${toValue}`;
  } else if (hasFromValue) {
    filterString = `>${fromValue}`;
  } else if (hasToValue) {
    filterString = `<${toValue}`;
  } else {
    filterString = undefined;
  }

  return filterString;
}

function hasValue(value: number | string | undefined | null): boolean {
  return typeof value === 'string' ? !isStringFalsy(value) : isNotNullOrUndefined(value);
}
