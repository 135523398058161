import {InjectionToken} from '@angular/core';
import {BszFactoryProviderGenerator} from '@basuiz/web-app-applet-api';

export const ɵPAGE_SIZE_FOR_FORM = new InjectionToken<number>('bsz.web-app-common.page-sizes.form', {
  factory: () => 10,
});

/**
 * Factory provider for a convenient overwrite of the page size for applets displaying a list in form input or the 2FA applet
 * E.g.: 2FA applet, Object Selection in Order report for CORPORATE user, etc.
 * Can be provided multiple times in the root provider scope and in nested provider scopes if different applets
 * should use different page sizes.
 * */
export const providePageSizeForForm: BszFactoryProviderGenerator<number> = (factory, deps) => ({
  provide: ɵPAGE_SIZE_FOR_FORM,
  useFactory: factory,
  deps,
});
