import {
  BszExportFileType,
  BszTableColumns,
  BszTableColumnsDisplayFormat,
  BszTableColumnsDisplayOptionalFormat,
  BszTableColumnsExportDisplayFormat,
  BszTableColumnsExportOnly,
  BszTableMultiValueColumn,
} from '@basuiz/web-app-applet-api';
import {flattenArray} from './array.util';

export function extractBszTableColumnsForBrowser<COLUMN>(
  tableColumns: BszTableColumns<COLUMN, 'mobile'>,
  format: 'mobile' | 'desktop'
): COLUMN[] {
  return Array.isArray(tableColumns) ? tableColumns : tableColumns[format] ?? tableColumns['desktop'];
}

export function extractBszTableColumnsForExport<COLUMN>(
  tableColumns: BszTableColumns<COLUMN, BszTableColumnsExportDisplayFormat>,
  fileType: BszExportFileType
): COLUMN[] {
  const format = exportFileTypeToDisplayFormat[fileType];
  return Array.isArray(tableColumns) ? tableColumns : tableColumns[format] ?? tableColumns['desktop'];
}

export function extractBszTableColumnsForExportOnly<COLUMN>(
  tableColumns: BszTableColumnsExportOnly<COLUMN, BszTableColumnsExportDisplayFormat>,
  fileType: BszExportFileType,
  fallbackLayout: COLUMN[]
): COLUMN[] {
  const format = exportFileTypeToDisplayFormat[fileType];
  return Array.isArray(tableColumns) ? tableColumns : tableColumns[format] ?? fallbackLayout;
}

const exportFileTypeToDisplayFormat: Record<BszExportFileType, BszTableColumnsExportDisplayFormat> = {
  PDF: 'pdf',
  CSV: 'csv',
  EXCEL: 'excel',
};

export interface TableColumnFieldForSpreadsheet<FIELD> {
  field: FIELD;
  content:
    | 'field' // the full value associated to the field
    | 'fieldCurrencyISOCode' // the ISO code of a field that contains a currency-amount
    | 'fieldCurrencyAmount'; // the numeric part of a fields that contains a currency-amount
}

export function prepareBszTableMultiValueColumnsForSpreadsheet<FIELD, CURRENCY_AMOUNT_FIELD extends FIELD>(
  tableColumns: BszTableMultiValueColumn<FIELD>[],
  currencyAmountFieldList: CURRENCY_AMOUNT_FIELD[]
): TableColumnFieldForSpreadsheet<FIELD>[] {
  return flattenArray(tableColumns).reduce<TableColumnFieldForSpreadsheet<FIELD>[]>((columns, field) => {
    const isCurrencyAmountField = (currencyAmountFieldList as unknown[]).includes(field);
    const newColumns: TableColumnFieldForSpreadsheet<FIELD>[] = isCurrencyAmountField
      ? [
          {field, content: 'fieldCurrencyAmount'},
          {field, content: 'fieldCurrencyISOCode'},
        ]
      : [{field, content: 'field'}];
    return [...columns, ...newColumns];
  }, []);
}

/* Normalizes a columns definition by converting all columns (single- or multi-value) to multi-value columns */
export function normalizeBszTableMultiValueColumns<FIELD>(tableColumns: BszTableMultiValueColumn<FIELD>[]): FIELD[][] {
  return tableColumns.map((column) => (Array.isArray(column) ? [...column] : [column]));
}

/* Returns the result of applying the function passed into the 'transformation' parameter
 * to each of the display formats present in the value passed in the 'tableColumns' parameter */
export function applyTransformationToBszTableColumns<
  COLUMN,
  OPTIONAL_FORMAT extends BszTableColumnsDisplayOptionalFormat
>(
  tableColumns: BszTableColumns<COLUMN, OPTIONAL_FORMAT>,
  transformation: (columns: COLUMN[]) => COLUMN[]
): BszTableColumns<COLUMN, OPTIONAL_FORMAT> {
  if (Array.isArray(tableColumns)) {
    return transformation(tableColumns);
  } else {
    const optionalFormats = BszTableColumnsDisplayFormat.filter(
      (format) => format in tableColumns
    ) as unknown as OPTIONAL_FORMAT[];

    return optionalFormats.reduce<BszTableColumns<COLUMN, OPTIONAL_FORMAT>>(
      (result, format) => {
        const maybeColumns = tableColumns[format];
        return maybeColumns ? {...result, [format]: transformation(maybeColumns)} : result;
      },
      {desktop: transformation(tableColumns.desktop)}
    );
  }
}

export function validateSingleValueTableColumns<COLUMN, OPTIONAL_FORMAT extends BszTableColumnsDisplayOptionalFormat>(
  tableColumns: BszTableColumns<COLUMN, OPTIONAL_FORMAT>,
  propertyName: string,
  validator?: (columns: COLUMN[]) => string | undefined
): string | undefined {
  return validateTableColumns(tableColumns, propertyName, false, true, validator);
}

export function validateMultiValueTableColumns<COLUMN, OPTIONAL_FORMAT extends BszTableColumnsDisplayOptionalFormat>(
  tableColumns: BszTableColumns<COLUMN, OPTIONAL_FORMAT>,
  propertyName: string,
  validator?: (columns: COLUMN[]) => string | undefined
): string | undefined {
  return validateTableColumns(tableColumns, propertyName, true, true, validator);
}

export function validateMultiValueTableColumnsExportOnly<
  COLUMN,
  OPTIONAL_FORMAT extends BszTableColumnsExportDisplayFormat
>(
  tableColumns: BszTableColumnsExportOnly<COLUMN, OPTIONAL_FORMAT>,
  propertyName: string,
  validator?: (columns: COLUMN[]) => string | undefined
): string | undefined {
  const proxy: BszTableColumns<COLUMN, OPTIONAL_FORMAT> = Array.isArray(tableColumns)
    ? tableColumns
    : {
        desktop: [],
        ...tableColumns,
      };
  return validateTableColumns(proxy, propertyName, true, false, validator);
}

/* Function to be used in configuration validations, to run a validation check over all defined display formats.
 *  The function include validation for standard errors:
 *  - A columns definition must contain at least one column
 *  - Multi-value columns must contain at least one field
 *  - Fields should not be repeated
 *  On top, custom errors can be checked by passing a custom function in the 'validator' parameter
 * */
function validateTableColumns<COLUMN, OPTIONAL_FORMAT extends BszTableColumnsDisplayOptionalFormat>(
  tableColumns: BszTableColumns<COLUMN, OPTIONAL_FORMAT>,
  propertyName: string,
  allowsMultiValueColumns: boolean,
  checkDesktop: boolean,
  validator?: (columns: COLUMN[]) => string | undefined
): string | undefined {
  const enhancedValidator: (columns: COLUMN[]) => string | undefined = (columns) => {
    let commonErrors: string | undefined;
    if (!columns.length) {
      commonErrors = `should have at least one element`;
    } else if (allowsMultiValueColumns && columns.some((column) => Array.isArray(column) && !column.length)) {
      commonErrors = `multi-value column should contain at least one element`;
    } else {
      const flattenedColumns = allowsMultiValueColumns ? flattenArray(columns) : columns;
      if (new Set(flattenedColumns).size !== flattenedColumns.length) {
        commonErrors = `fields should not be repeated`;
      }
    }
    const customErrors = validator && validator(columns);
    return commonErrors && customErrors
      ? `${commonErrors}\n${customErrors}`
      : commonErrors
      ? commonErrors
      : customErrors;
  };

  if (Array.isArray(tableColumns)) {
    const maybeErrors = enhancedValidator(tableColumns);
    return maybeErrors && `${propertyName}: ${maybeErrors}`;
  } else {
    const optionalFormats = BszTableColumnsDisplayFormat.filter(
      (format) => format !== 'desktop' && format in tableColumns
    ) as unknown as OPTIONAL_FORMAT[];

    const maybeDesktopError = checkDesktop ? enhancedValidator(tableColumns.desktop) : undefined;
    const desktop = maybeDesktopError && `${propertyName}.desktop: ${maybeDesktopError}`;

    return optionalFormats.reduce<string | undefined>((result, format) => {
      const maybeColumns = tableColumns[format];
      const maybeError = maybeColumns ? enhancedValidator(maybeColumns) : undefined;
      if (!maybeError) {
        return result;
      } else {
        const error = `${propertyName}.${format}: ${maybeError}`;
        return result ? `${result}\n${error}` : error;
      }
    }, desktop);
  }
}
