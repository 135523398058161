<ng-template>
  <div class="bsz-fab-menu" [class.bsz-fab-menu-is-open]="isOpen" [ngClass]="className" [attr.data-fab-menu-id]="fabMenuUid">
    <div
      class="bsz-fab-menu-container mat-elevation-z4"
      [@fabContainerTransition]="{value: isOpen ? 'open' : 'close', params: {containerOpenedHeight: menuItemsTotalHeight}}"
      (@fabContainerTransition.start)="toggleBackdrop()"
    >
      <!-- FAB Trigger -->
      <button
        (click)="open(fabTriggerFocusOrigin)"
        [color]="fabTriggerDirective?.color"
        [style.z-index]="isOpen ? 0 : 1"
        [attr.aria-expanded]="isOpen"
        [attr.aria-controls]="fabMenuUid"
        [attr.aria-label]="fabTriggerDirective?.ariaLabel || null"
        [attr.aria-labelledby]="fabTriggerDirective?.ariaLabelledby || null"
        [attr.aria-describedby]="fabTriggerDirective?.ariaDescribedby || null"
        class="bsz-fab-menu-trigger mat-elevation-z0"
        disableRipple
        mat-fab
      >
        <ng-content select="ng-container[bsz-fab-menu-trigger]"></ng-content>
      </button>
      <!-- FAB Trigger -->
      
      <!-- FAB Menu Panel -->
      <div
        (click)="close()"
        (keydown)="handleKeydown($event)"
        [id]="fabMenuUid"
        [style.z-index]="isOpen ? 1 : 0"
        [attr.aria-label]="ariaLabel || null"
        [attr.aria-labelledby]="ariaLabelledby || null"
        [attr.aria-describedby]="ariaDescribedby || null"
        role="menu"
        tabindex="-1"
        class="bsz-fab-menu-panel"
      >
        <ng-content select="button[bsz-fab-menu-item]"></ng-content>
      </div>
      <!-- FAB Menu Panel -->

    </div>
  </div>
</ng-template>