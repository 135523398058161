import {ReplaySubject} from 'rxjs';

import {SelectionChange, SelectionModel} from './bsz-selectable.definitions';

const LOG_PREFIX = '[bsz-flat-selection-model]';

/**
 * Selection model for generic flat data.
 */
export class BszFlatSelectionModel<T> implements SelectionModel<T> {
  readonly selectionChange = new ReplaySubject<SelectionChange<T>>(1);

  /** Map storing the selection state of all items. */
  protected readonly selectedItemMap = new Map<T, boolean>();

  setData(data: T[]) {
    // Remove items that are not in the new data.
    for (const existingItem of this.selectedItemMap.keys()) {
      if (!data.includes(existingItem)) {
        this.selectedItemMap.delete(existingItem);
      }
    }

    // Add new items.
    for (const item of data) {
      if (!this.selectedItemMap.has(item)) {
        this.selectedItemMap.set(item, false);
      }
    }

    this.emitSelectedChange();
  }

  setSelectedItems(selectedItems: T[]) {
    // We need to deselect all items that were previously selected and are not to be selected anymore.
    const itemsToDeselect = this.getSelectedItems().filter((item) => !selectedItems.includes(item));

    this.setItemSelectedStates([
      ...selectedItems.map((item) => ({item: item, selected: true})),
      ...itemsToDeselect.map((item) => ({item: item, selected: false})),
    ]);
  }

  setItemSelected(selected: boolean, ...items: T[]) {
    this.setItemSelectedStates([...items.map((item) => ({item, selected}))]);
  }

  setAllSelected(selected: boolean) {
    const allItems = [...this.selectedItemMap.keys()];
    this.setItemSelectedStates(allItems.map((item) => ({item, selected})));
  }

  isItemSelected(item: T) {
    const selected = this.selectedItemMap.get(item);
    if (selected === undefined) {
      console.error(item);
      throw new Error(`${LOG_PREFIX} Unknown item`);
    }
    return selected;
  }

  getSelectedItems() {
    return [...this.selectedItemMap.entries()].filter(([item, selected]) => selected).map(([item]) => item);
  }

  private setItemSelectedStates(selectedStates: {item: T; selected: boolean}[]) {
    let changed = false;

    for (const {item, selected} of selectedStates) {
      // Only change the selected state if it actually changed.
      if (this.isItemSelected(item) !== selected) {
        this.selectedItemMap.set(item, selected);
        changed = true;
      }
    }

    if (changed) {
      this.emitSelectedChange();
    }
  }

  private emitSelectedChange() {
    const selectedItems = this.getSelectedItems();
    const selectedCount = selectedItems.length;
    const allCount = this.selectedItemMap.size;
    const allSelected = selectedCount === 0 ? false : selectedCount === allCount ? true : null;

    this.selectionChange.next({
      selectedItems: selectedItems,
      selectedItemMap: this.selectedItemMap,
      allSelected: allSelected,
    });
  }
}
