import {ApplicationInitStatus, Injectable, InjectionToken, Injector, Type} from '@angular/core';
import {WebAppInitializationService} from './web-app-initialization.service';
import {take} from 'rxjs/operators';

/** Options for method injectUponSuccessfulInitialization */
export interface InjectUponSuccessfulInitializationOptions {
  /** Whether the injection should wait for the application init status (ApplicationInitStatus from @angular/core)
   * to be done or not.
   * I.e. the service waits first for the app initialization and then for the web-app own initialization to complete
   * before injecting the requested token.
   * This might be important if the injected token depends on other tokens whose calculation need to wait until
   * the application initialization has completed (for example for tokens that need to read the locale-id).
   * @default: true */
  waitForAppInitStatusDone: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class WebAppInjectUponInitializationService {
  constructor(
    private initializationService: WebAppInitializationService,
    private injector: Injector,
    private applicationInitStatus: ApplicationInitStatus
  ) {}

  /** Utility method that uses the Injector (from @angular/core) to retrieve a token only when
   * @basuiz/web-app initialization completes successfully.
   * In case the initialization fails, the promise is rejected. */
  async injectUponSuccessfulInitialization<T>(
    token: Type<T> | InjectionToken<T>,
    options: Partial<InjectUponSuccessfulInitializationOptions> = {waitForAppInitStatusDone: true}
  ): Promise<T> {
    const {waitForAppInitStatusDone}: InjectUponSuccessfulInitializationOptions = {
      ...this.getDefaultInjectUponSuccessfulInitializationOptions(),
      ...options,
    };

    const tokenName = token instanceof InjectionToken ? (token as unknown as {_desc: string})._desc : token.name;

    return new Promise((resolve, reject) => {
      const injectUponSuccess: () => void = () => {
        this.initializationService
          .getInitializationResult$()
          .pipe(take(1))
          .subscribe(
            (initializationResult) => {
              if (initializationResult === 'success') {
                resolve(this.injector.get(token));
              } else {
                reject(`Cannot inject token ${tokenName} because @basuiz/web-app initialization failed`);
              }
            },
            (error) =>
              reject(
                `Cannot inject token ${tokenName} because failed to get an initialization result from @basuiz/web-app.`
              )
          );
      };

      const injectAfterAppInitDone: () => void = () =>
        this.applicationInitStatus.donePromise.then(
          () => injectUponSuccess(),
          () => reject(`Cannot inject token ${tokenName} because the app initialization failed`)
        );

      waitForAppInitStatusDone ? injectAfterAppInitDone() : injectUponSuccess();
    });
  }

  private getDefaultInjectUponSuccessfulInitializationOptions(): InjectUponSuccessfulInitializationOptions {
    return {
      waitForAppInitStatusDone: true,
    };
  }
}
