import {isNil} from 'lodash';

export function getFilterRange(fromValue: any, toValue: any, paramName: string): string | undefined {
  let filterString: string | undefined;

  if (!isNil(fromValue) && !isNil(toValue)) {
    filterString = `${paramName}:${fromValue},${toValue}`;
  } else if (!isNil(fromValue)) {
    filterString = `${paramName}:>${fromValue}`;
  } else if (!isNil(toValue)) {
    filterString = `${paramName}:<${toValue}`;
  }

  return filterString;
}
