import {Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewContainerRef} from '@angular/core';
import {WebAppAppletService} from './web-app-applet.service';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {BszAppletContextRequest} from '@basuiz/web-app-applet-api';
import {AppletContextRequest} from '../applet-context/applet-context.definitions';
import {WebAppAppletNameComponent} from './web-app-applet-name/web-app-applet-name.component';

@Directive({
  selector: '[bszWebAppApplet]',
})
export class WebAppAppletDirective implements OnInit, OnDestroy {
  @Input() experimental: boolean = false;

  private _appletContext: BszAppletContextRequest | undefined;

  @Input() set appletContext(value: BszAppletContextRequest | null | undefined) {
    this._appletContext = value ? value : undefined;
    const defaultAppletContextRequest: AppletContextRequest = {appletContextId: 'default', onInit: 'reset'};

    if (value === null) {
      // assume async pipe and wait for value
    } else if (value === undefined) {
      // assume wrapper component input not bound and provide default
      this.appletContextSubject.next(defaultAppletContextRequest);
    } else {
      this.appletContextSubject.next({...defaultAppletContextRequest, ...value});
    }
  }

  get appletContext(): BszAppletContextRequest | undefined {
    return this._appletContext;
  }

  private readonly appletContextSubject: ReplaySubject<AppletContextRequest> = new ReplaySubject(1);
  readonly appletContext$: Observable<AppletContextRequest> = this.appletContextSubject.asObservable();

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef,
    private webAppAppletService: WebAppAppletService
  ) {}

  private readonly subscriptions = new Subscription();

  private appletShellCssSelector: string;
  getAppletComponentSelector(): string {
    if (this.appletShellCssSelector === undefined) {
      throw new Error(
        'appletShellCssSelector accessed before initialization. Postpone access to ngOnInit or a later lifecycle event hook.'
      );
    }
    return this.appletShellCssSelector;
  }

  ngOnInit() {
    const appletFrame = this.elementRef.nativeElement;
    this.renderer.addClass(appletFrame, 'bsz-web-app-applet--frame');

    const appletShell = this.renderer.parentNode(appletFrame);
    this.renderer.addClass(appletShell, 'bsz-web-app-applet');

    this.appletShellCssSelector = appletShell.tagName.toLowerCase();

    const appletNameComponentRef = this.viewContainerRef.createComponent(WebAppAppletNameComponent);
    appletNameComponentRef.instance.appletCssSelector = this.appletShellCssSelector;
    appletNameComponentRef.changeDetectorRef.detectChanges();
    this.renderer.insertBefore(appletShell, appletNameComponentRef.location.nativeElement, appletFrame);

    this.subscriptions.add(
      this.webAppAppletService.getRevealBoundaryAndName().subscribe((doReveal) => {
        const revealBoundaryAndNameCssClass = 'bsz-wb-reveal-applet-boundary-and-name';
        if (doReveal) {
          this.renderer.addClass(appletShell, revealBoundaryAndNameCssClass);
        } else {
          this.renderer.removeClass(appletShell, revealBoundaryAndNameCssClass);
        }

        if (this.experimental) {
          const experimentalAppletCssClass = 'bsz-wb-experimental-applet';
          if (!doReveal) {
            this.renderer.addClass(appletShell, experimentalAppletCssClass);
          } else {
            this.renderer.removeClass(appletShell, experimentalAppletCssClass);
          }
        }
      })
    );

    if (this.experimental) {
      console.warn(`Applet '${this.getAppletComponentSelector()}' is experimental and not suitable for
production environments. Besides it's public API is not frozen it can be changed without previous notice.`);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.appletContextSubject.complete();
  }
}
