import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import 'hammerjs';
import {ThemeStyleLoader} from './app/dev-tools/theme-style-loader';

if (environment.production) {
  enableProdMode();
}

ThemeStyleLoader.loadTheme();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
