// source: https://developers.facebook.com/docs/meta-pixel/get-started

import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {
  ENVIRONMENT_INITIALIZER,
  FactoryProvider,
  InjectionToken,
  PLATFORM_ID,
  ValueProvider,
  // makeEnvironmentProviders, // TODO p4: enable when upgrade to angular >=v15
} from '@angular/core';

function extAppScript(pixelId: string): string {
  return `!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${pixelId}');
  fbq('track', 'PageView');`;
}

const BSZ_FACEBOOK_PIXEL_CONFIG_TOKEN = new InjectionToken<BszFacebookPixelConfiguration>('bsz-facebook-pixel.config');

export type BszFacebookPixelConfiguration = Readonly<{
  /**
   * Whether or not to inject this script on application initialization.
   */
  enabled: boolean;
  /**
   * The Facebook Pixel ID.
   */
  pixelId: string;
}>;

/**
 * Builds configured providers for injecting External Application scripts during
 * application initialization.
 * @param config The runtime configuration for the External Application providers.
 * @returns the External Application providers.
 */
export function facebookPixelProvider(config: BszFacebookPixelConfiguration): (ValueProvider | FactoryProvider)[] {
  // return makeEnvironmentProviders([
  return [
    {provide: BSZ_FACEBOOK_PIXEL_CONFIG_TOKEN, useValue: config},
    {
      provide: ENVIRONMENT_INITIALIZER,
      useFactory: (platformId: Object, d: Document, {enabled, pixelId}: BszFacebookPixelConfiguration) => {
        return () => {
          if (isPlatformBrowser(platformId) && enabled) {
            // script
            const s = d.createElement('script');
            s.type = 'text/javascript';
            s.innerHTML = extAppScript(pixelId);
            d.head.appendChild(s);

            // noscript
            // <noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id={your-pixel-id-goes-here}&ev=PageView&noscript=1"/></noscript>
            const ns = d.createElement('noscript');
            const img = d.createElement('img');
            img.height = 1;
            img.width = 1;
            img.style.display = 'none';
            img.src = `https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`;
            ns.append(img);
            d.head.appendChild(ns);
          }
        };
      },
      deps: [PLATFORM_ID, DOCUMENT, BSZ_FACEBOOK_PIXEL_CONFIG_TOKEN],
      multi: true,
    },
  ];
}
