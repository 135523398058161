import {Directive, ElementRef, EventEmitter, OnDestroy, Output} from '@angular/core';

@Directive({
  selector: '[bszResizeObserver]',
})
export class BszResizeObserver implements OnDestroy {
  private readonly resizeObserver =
    window.ResizeObserver &&
    new ResizeObserver((entries: ResizeObserverEntry[]) => {
      const entry = entries.find((observerEntry) => observerEntry.target === this.elementRef.nativeElement);
      if (entry) {
        this.resize.emit(entry);
      }
    });

  @Output() resize = new EventEmitter<ResizeObserverEntry>();

  constructor(private readonly elementRef: ElementRef) {
    this.resizeObserver?.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.resizeObserver?.disconnect();
  }
}
