import {InjectionToken} from '@angular/core';

import {BszCurrencyDisplay} from './currency.pipe';

export interface BszCurrencyPipeConfig {
  emptyValue: string;
  display: BszCurrencyDisplay;
  template: {[key: string]: string};
}

/** Injection token that can be used to specify default pipe options. */
export const BSZ_CURRENCY_PIPE_DEFAULT_OPTIONS = new InjectionToken<Partial<BszCurrencyPipeConfig>>(
  'bsz_currency_pipe_default_options'
);

export const BSZ_CURRENCY_PIPE_DEFAULT_CONFIG: BszCurrencyPipeConfig = {
  emptyValue: '',
  display: 'code',
  template: {
    'en-GB': '[CURRENCY_SYMBOL]\u00A0[MINUS_SYMBOL][AMOUNT]',
    'de-CH': '[CURRENCY_SYMBOL]\u00A0[MINUS_SYMBOL][AMOUNT]',
    'de-DE': '[CURRENCY_SYMBOL]\u00A0[MINUS_SYMBOL][AMOUNT]',
    'it': '[MINUS_SYMBOL][AMOUNT]\u00A0[CURRENCY_SYMBOL]',
    'it-CH': '[MINUS_SYMBOL][AMOUNT]\u00A0[CURRENCY_SYMBOL]',
    'nl': '[CURRENCY_SYMBOL]\u00A0[MINUS_SYMBOL][AMOUNT]',
    'ja': '[CURRENCY_SYMBOL]\u00A0[MINUS_SYMBOL][AMOUNT]',
    'fr': '[MINUS_SYMBOL][AMOUNT]\u00A0[CURRENCY_SYMBOL]',
    'fr-CH': '[MINUS_SYMBOL][AMOUNT]\u00A0[CURRENCY_SYMBOL]',
    'es': '[MINUS_SYMBOL][AMOUNT]\u00A0[CURRENCY_SYMBOL]',
  },
};
