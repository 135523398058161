import {Observable} from 'rxjs';
import {filter, map, shareReplay, skip, switchMap, take, withLatestFrom} from 'rxjs/operators';
import {MemoizedSelector, Store} from '@ngrx/store';
import {Action, FunctionIsNotAllowed} from '@ngrx/store/src/models';
import {WebAppAppletDirective} from '../web-app-applet/web-app-applet.directive';
import {AppletContextRequest} from './applet-context.definitions';
import {AppletContextFeatureState, isActionWithAppletContextId} from './applet-context-feature-store.definitions';
import {AppletContextFeatureStoreAdapter} from './applet-context-feature-store.adapter';

export abstract class AppletContextFeatureStoreService<STATE> {
  private readonly contextRequest$: Observable<AppletContextRequest> = this.appletDirective.appletContext$.pipe(
    filter(({appletContextId}) => !!appletContextId),
    take(1),
    withLatestFrom(this.store.select(this.appletContextAdapter.selectFeatureState)),
    map(([appletContextRequest, featureState]) => {
      const {appletContextId, onInit} = appletContextRequest;

      const isRestoreForMissingContext = onInit === 'restore' && !(appletContextId in featureState);
      const initRequest: AppletContextRequest = isRestoreForMissingContext
        ? {appletContextId, onInit: 'reset'}
        : appletContextRequest;

      return initRequest;
    }),
    shareReplay()
  );

  readonly initialRequest$: Observable<AppletContextRequest['onInit']> = this.contextRequest$.pipe(
    map(({onInit: request}) => request)
  );

  readonly initialRequestReset$: Observable<true> = this.initialRequest$.pipe(
    filter((request) => request === 'reset'),
    map(() => true)
  );

  getInputAfterInitialRequest$<T>(input$: Observable<T>): Observable<T> {
    return this.initialRequest$.pipe(
      switchMap((initialRequest) =>
        // If the input$ emits before the context is restored then skip it
        input$.pipe(skip(initialRequest === 'restore' ? 1 : 0))
      )
    );
  }

  private appletContextId: string | undefined = undefined;

  dispatch<V extends Action = Action>(
    action: V &
      FunctionIsNotAllowed<
        V,
        'Functions are not allowed to be dispatched. Did you forget to call the action creator function?'
      >
  ): void {
    const {appletContextId} = this;

    if (appletContextId === undefined) {
      throw new Error(
        `[AppletContextStoreService] cannot dispatch action ${action.type} before the context is available.
Subscribe to initialRequest$ to react after the context is available.
Check that you are not providing WebAppAppletDirective in the wrong scope, WebAppAppletModule already provides this.`
      );
    }

    if (isActionWithAppletContextId(action)) {
      throw new Error(
        `[AppletContextStoreService] cannot dispatch action ${action.type} in context because it specifies its own appletContextId prop ${action.appletContextId}.`
      );
    }

    this.store.dispatch<V>({...action, appletContextId});
  }

  select<RESULT>(selector: MemoizedSelector<STATE, RESULT>): Observable<RESULT> {
    return this.contextRequest$.pipe(
      switchMap(({appletContextId}) =>
        this.store.select(this.appletContextAdapter.getSelectorInContext(selector, appletContextId))
      )
    );
  }

  constructor(
    private appletDirective: WebAppAppletDirective,
    private store: Store<AppletContextFeatureState<STATE>>,
    private appletContextAdapter: AppletContextFeatureStoreAdapter<STATE>
  ) {
    this.contextRequest$.subscribe((appletContextRequest) => {
      this.appletContextAdapter.initAppletContext(appletContextRequest, store);
      this.appletContextId = appletContextRequest.appletContextId;
    });
  }
}
