<ng-container *ngIf="isGroupHeaderTypeGuard(data); else leafTpl">
  <div
    class="bsz-tree-data-list-group-header bsz-tree-data-list-item-content">
    <div class="bsz-tree-data-list-collapser">
      <button
        mat-icon-button
        class="bsz-tree-data-list-collapser-button"
        [attr.aria-labelledby]="bszTreeDataListGroupHeader"
        [attr.aria-controls]="bszTreeDataListNodeId"
        [attr.aria-expanded]="!isCollapsed(data.groupId)"
        (click)="toggleCollapsed(data.groupId); $event.stopPropagation();">
        <i
          class="material-icons"
          aria-hidden="true">keyboard_arrow_right</i>
      </button>
    </div>

    <div class="bsz-tree-data-list-group-header-content bsz-display-flex bsz-align-items-center bsz-padding-l2"
         [attr.id]="bszTreeDataListGroupHeader"
         (click)="toggleCollapsed(data.groupId); $event.stopPropagation();">
      <div class="bsz-display-block bsz-width-100pct">
        <ng-container
          [ngTemplateOutlet]="groupHeaderTemplate"
          [ngTemplateOutletContext]="{$implicit: data.group}"></ng-container>
      </div>
    </div>
  </div>

  <ul *ngIf="!isCollapsed(data.groupId)" class="bsz-tree-data-list-collection"
      [attr.id]="bszTreeDataListNodeId">
        <li *ngFor="let child of data.children"
        bsz-tree-data-list-node
        [data]="child"
        [level]="level + 1"
        [groupHeaderTemplate]="groupHeaderTemplate"
        [itemTemplate]="itemTemplate"
        [expandFn]="expandFn"
        [hasUserInteraction]="hasUserInteraction"
        [componentCollapsed]="componentCollapsed"
        (collapsedChange)="collapsedChangeCallback($event)"
        [expandedGroupIds]="expandedGroupIds">
    </li>
  </ul>
</ng-container>

<ng-template #leafTpl>
  <div class="bsz-tree-data-list-item-content">
    <ng-container
      [ngTemplateOutlet]="itemTemplate"
      [ngTemplateOutletContext]="{$implicit: data}"></ng-container>
  </div>
</ng-template>
