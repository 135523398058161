import {NgModule} from '@angular/core';
import {PortalBasicModule} from '../util/portal-basic.module';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {PortalUINotificationDialogComponent} from './ui-notification-dialog/portal-ui-notification-dialog.component';
import {MatListModule} from '@angular/material/list';
import {WebAppSdkBusinessObjectModule} from '@basuiz/web-app-applet-sdk';

@NgModule({
  declarations: [PortalUINotificationDialogComponent],
  imports: [PortalBasicModule, MatSnackBarModule, MatListModule, WebAppSdkBusinessObjectModule],
  exports: [PortalUINotificationDialogComponent],
})
export class PortalUiNotificationModule {}
