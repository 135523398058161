import {Injectable} from '@angular/core';
import {
  FilterFavouritesJsonModel,
  FilterFavouritesJsonModelTypes,
  SavedFilterPayload,
} from './models/saved-filter.interface';
import moment from 'moment';

@Injectable({providedIn: 'root'})
export class FilterFavouritesHelperService {
  public static mapFormValuesToSavedFilterPayload(formValues: Record<string, unknown>): SavedFilterPayload[] {
    return Object.entries(formValues).map(([key, value]) => {
      return {
        name: key,
        jsonModel: FilterFavouritesHelperService.getJsonModel(value),
      };
    });
  }

  public static mapSavedFilterPayloadToFormValues(
    payload: SavedFilterPayload[]
  ): Record<FilterFavouritesJsonModelTypes, unknown> {
    return payload.reduce((prev, curr) => {
      return {...prev, [curr.name]: FilterFavouritesHelperService.getValueFromJsonModel(curr.jsonModel)};
    }, {} as Record<FilterFavouritesJsonModelTypes, unknown>);
  }

  private static getJsonModel(value: any): FilterFavouritesJsonModel {
    if (value === null || value === undefined) {
      return {
        value,
        type: 'null',
      };
    }

    switch (typeof value) {
      case 'boolean':
      case 'number':
      case 'string':
        return {
          value,
          type: typeof value as FilterFavouritesJsonModelTypes,
        };

      case 'object':
        if (moment.isMoment(value)) {
          return {
            value,
            type: 'moment',
          };
        }
        if (value.toIsoString) {
          return {
            value,
            type: 'date',
          };
        }
        if (Array.isArray(value)) {
          return {
            value,
            type: 'array',
          };
        }

        return {
          value: FilterFavouritesHelperService.mapFormValuesToSavedFilterPayload(value),
          type: 'object',
        };
      default:
        throw new Error(`Received unsupported value for filter: ${value} of type: ${typeof value}`);
    }
  }

  private static getValueFromJsonModel(jsonModel: FilterFavouritesJsonModel) {
    const valueMap: Record<FilterFavouritesJsonModelTypes, (value: unknown) => unknown> = {
      boolean: (value: boolean) => Boolean(jsonModel.value),
      number: (value: number) => Number(jsonModel.value),
      string: (value: string) => String(jsonModel.value),
      moment: (value: string) => moment(jsonModel.value),
      date: (value: string) => new Date(jsonModel.value),
      object: (value: Object) => FilterFavouritesHelperService.mapSavedFilterPayloadToFormValues(jsonModel.value),
      array: (value: unknown[]) => Array.from(jsonModel.value),
      null: (value: null) => null,
    };
    return valueMap[jsonModel.type](jsonModel.value);
  }
}
