import {ActionWithResponse} from '@basuiz/mobile-bridge';

/**
 * Use native scanner to scan IBANs.
 */
export class ScanIbanAction extends ActionWithResponse<void, ScanIbanResponse> {
  readonly name = 'scanIBAN';
}

export interface ScanIbanResponse {
  /**
   * Status success is returned when an IBAN has been successfully scanned.
   * Status cancelled is returned when user cancels the scanning process.
   */
  resultStatus: 'success' | 'cancelled';

  /**
   * Scanned value.
   */
  iban?: string;
}
