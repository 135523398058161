import {InjectionToken} from '@angular/core';
import {PortalPage} from './classes/portal-page';
import {NavigationIntent, NavigationIntentClass} from './classes/navigation-intent';

export type DefaultNavigationIntentHandler = (intent: NavigationIntent) => PortalPage;

export type DefaultNavigationIntentHandlerMap = Map<NavigationIntentClass, DefaultNavigationIntentHandler>;

export const DEFAULT_NAVIGATION_INTENT_HANDLERS = new InjectionToken<DefaultNavigationIntentHandlerMap>(
  'bsz.web-app-portal.default-navigation-intent-handlers',
  {
    factory: () => {
      throw new Error('Default handlers not provided in library web-app-portal');
    },
  }
);
