import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {BszCentralMessageModule} from '../central-message/bsz-central-message.module';
import {BszProgressBarModule} from '../progress-indicator/bsz-progress-bar.module';
import {BszTechnicalErrorModule} from '../technical-error/bsz-technical-error.module';
import {DataStateDirective} from './data-state.directive';
import {DataStateUnavailableComponent} from './data-state-unavailable/data-state-unavailable.component';

@NgModule({
  imports: [CommonModule, TranslateModule, BszCentralMessageModule, BszProgressBarModule, BszTechnicalErrorModule],
  declarations: [DataStateDirective, DataStateUnavailableComponent],
  exports: [DataStateDirective],
})
export class DataStateModule {}
