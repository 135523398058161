import {NgModule} from '@angular/core';

import {BszStatus} from './bsz-status';

@NgModule({
  declarations: [BszStatus],
  exports: [BszStatus],
})
export class BszStatusModule {}

/** @deprecated [v3.12.0] The BszLozengeModule is deprecated, use BszStatusModule instead*/
@NgModule({
  exports: [BszStatusModule],
})
export class BszLozengeModule {}
