<nav *ngIf="items.length > 1" [attr.aria-label]="ariaLabel" [attr.aria-labelledby]="ariaLabelledby">
  <ng-container *ngFor="let breadcrumb of items; let isFirst = first; let isLast = last; let index = index">
    <a
      *ngIf="!isLast"
      class="bsz-caption bsz-cursor-pointer bsz-text-primary bsz-text-bold bsz-breadcrumbs-item"
      role="link"
      tabindex="0"
      (click)="itemClicked(breadcrumb)"
      (keyup.enter)="itemClicked(breadcrumb)"
      [attr.id]="breadcrumb.id || null"
    >
      {{ breadcrumb.text }}
    </a>

    <mat-icon *ngIf="!isLast" class="bsz-text-secondary">chevron_right</mat-icon>

    <span
      *ngIf="isLast"
      class="bsz-caption bsz-text-secondary bsz-text-bold bsz-breadcrumbs-item"
      [attr.id]="breadcrumb.id || null"
    >
      {{ breadcrumb.text }}
    </span>
  </ng-container>
</nav>
