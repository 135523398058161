import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {CustomInitializationFailureComponent} from './custom-initialization-failure.component';
import {MatDividerModule} from '@angular/material/divider';

@NgModule({
  declarations: [CustomInitializationFailureComponent],
  exports: [CustomInitializationFailureComponent],
  imports: [CommonModule, MatIconModule, MatDividerModule],
})
export class CustomInitializationFailureModule {}
