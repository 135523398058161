import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Optional,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import {AsyncSubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {BszScreenSize} from '../bsz-screen-size-content-switcher';
import {
  BSZ_CONTENT_NAVIGATION_SECTION_OPTIONS,
  BszContentNavigationOptions,
  BszRouterLinkConfiguration,
  SectionDefinition,
} from './bsz-content-navigation.definitions';

const sectionActiveClass = 'bsz-content-navigation-section-active';
const defaultNavigationPosition = 'right';

@Directive({
  selector: '[bszSectionTitle]',
  host: {
    '[attr.bszSectionTitle]': 'bszSectionTitle',
  },
})
export class BszSectionTitle {
  @Input() bszSectionTitle: string | null = null;
}

@Component({
  selector: 'bsz-content-navigation-aside',
  template: ` <ng-content></ng-content> `,
  host: {
    class: 'bsz-content-navigation-aside',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BszSectionAside {}

@Component({
  selector: 'bsz-content-navigation',
  templateUrl: 'bsz-content-navigation.html',
  styleUrls: ['./bsz-content-navigation.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BszContentNavigation implements AfterContentInit, OnDestroy {
  @Input() set routerLinkConfiguration(routerLinkConf: BszRouterLinkConfiguration) {
    this.updateRouterLinkConfiguration(routerLinkConf);
  }

  @Input() set sectionSelector(selector: string) {
    if (!selector.trim()) {
      return;
    }
    this._sectionSelector += `, ${selector}`;
    this.updateNavigation();
  }

  @Input() set navigationPosition(position: undefined | 'left' | 'right') {
    if (!position) {
      return;
    }
    this._navigationPosition = position;
  }

  @ContentChildren(BszSectionTitle, {descendants: true}) sectionTitles!: QueryList<BszSectionTitle>;
  @ContentChild(BszSectionAside) sectionAside: BszSectionAside | null = null;

  /** @private */
  _sections: SectionDefinition[] = [];
  /** @private */
  _activeId: string | null = null;
  /** @private */
  _routerLinkConf: BszRouterLinkConfiguration | null = null;
  /** @private */
  _isMobile = false;
  /** @private */
  _navigationPosition: 'left' | 'right' = defaultNavigationPosition;
  private readonly destroy = new AsyncSubject<void>();
  // it selects automatically the elements with the attribute that matches the directive BszSectionTitle
  private _sectionSelector = '[bszSectionTitle]';
  private readonly sectionsId: string[] = [];
  private mainIntersectionObserver: IntersectionObserver | null = null;
  private preventIntersectionCallback = false;

  constructor(
    private readonly screenSizeService: BszScreenSize,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly elementRef: ElementRef,
    @Optional() @Inject(BSZ_CONTENT_NAVIGATION_SECTION_OPTIONS) defaultOptions?: BszContentNavigationOptions
  ) {
    if (!defaultOptions) {
      return;
    }
    if (defaultOptions.sectionSelector) {
      this._sectionSelector += `, ${defaultOptions.sectionSelector}`;
    }
    if (defaultOptions.routerLinkConfiguration) {
      this.updateRouterLinkConfiguration(defaultOptions.routerLinkConfiguration);
    }
    if (defaultOptions.navigationPosition) {
      this._navigationPosition = defaultOptions.navigationPosition;
    }
  }

  ngAfterContentInit() {
    this.setSections();
    this.initSubscriptions();
    this.connectMainIntersectionObserver();
    this.setActiveSection(this.sectionsId[0]);
  }

  ngOnDestroy() {
    this.disconnectIntersectionObservers();
    this.destroy.next();
    this.destroy.complete();
  }

  /**
   * Updates the list of links in the navigation. In some cases it is needed because the
   * logic to identify the sections uses querySelectorAll, so there is no change detection.
   * When using the BszSectionTitle directive, if there are changes, they are detected.
   */
  updateNavigation() {
    setTimeout(() => {
      this.setSections();
      this.changeDetector.markForCheck();
      this.disconnectIntersectionObservers();
      this.connectMainIntersectionObserver();
    });
  }

  /**
   * Set the sections finding them by smart selection or by the use of the section directive
   */
  private setSections() {
    this.sectionsId.length = 0;
    const sections: HTMLElement[] = [];
    this.elementRef.nativeElement.querySelectorAll(this._sectionSelector).forEach((element: HTMLElement) => {
      sections.push(element);
    });

    this._sections = sections.map((element: HTMLElement, index: number) => {
      return this.createSectionDefinition(element, `bsz-page-content-section-${index}`);
    });
  }

  private createSectionDefinition(element: HTMLElement, sectionId: string): SectionDefinition {
    element.setAttribute('tabindex', '-1');

    let contentTitle = element.getAttribute('bszSectionTitle');
    if (!contentTitle) {
      contentTitle = this.getText(element);
    }

    this.sectionsId.push(sectionId);

    return {
      id: element.getAttribute('id') || undefined,
      sectionId: sectionId,
      text: contentTitle,
      element: element,
      active: false,
    };
  }

  /** @private */
  _onClick(event: Event, sectionId: string) {
    event.preventDefault();

    // prevent the intersection observer from setting the active option
    // by this way, it is possible to set the active option to the clicked one
    this.preventIntersectionCallback = true;

    const sectionIndex = this._sections.findIndex((section: SectionDefinition) => section.sectionId === sectionId);
    const sectionToFocus = this._sections[sectionIndex];
    this.focus(sectionToFocus.element);

    this.setActiveSection(sectionId);

    // after some time, stop preventing the intersection observer from setting the active option
    setTimeout(() => {
      this.preventIntersectionCallback = false;
    }, 1000);
  }

  /** @private */
  _itemClass(isActive: boolean): string {
    return isActive ? 'bsz-content-navigation-active' : '';
  }

  /** @private */
  _isRouterLink(fragmentParam: string | undefined): boolean {
    if (!this._routerLinkConf) {
      return false;
    }
    return (
      !!this._routerLinkConf.fragment &&
      (typeof this._routerLinkConf.fragment === 'string' || !!this._routerLinkConf.fragment(fragmentParam))
    );
  }

  /** @private */
  _routerFragment(fragmentParam: string | undefined): string | undefined {
    const fragment = this._routerLinkConf?.fragment;
    if (!fragment) {
      return undefined;
    }
    if (typeof fragment === 'string') {
      return fragment;
    }
    return fragment(fragmentParam);
  }

  private initSubscriptions(): void {
    this.screenSizeService
      .getScreenSize()
      .pipe(takeUntil(this.destroy))
      .subscribe((size) => {
        this._isMobile = size === 'mobile';
        this.changeDetector.markForCheck();
      });

    this.sectionTitles.changes.pipe(takeUntil(this.destroy)).subscribe(() => this.updateNavigation());
  }

  private focus(element: HTMLElement) {
    // to make it behave smooth, the focus does not trigger the scroll, but scrollIntoView does it
    element.focus({preventScroll: true});
    element.scrollIntoView({block: 'start'});
  }

  /**
   * Smart function to extract the textContent without considering elements hidden by attributes
   */
  private getText(element: HTMLElement): string {
    const clone = element.cloneNode(true) as HTMLElement;
    const descendantsToHide = clone.querySelectorAll('[aria-hidden=true], [hidden]');
    descendantsToHide.forEach((descendant) => descendant.parentElement?.removeChild(descendant));
    return clone.textContent || '';
  }

  private updateRouterLinkConfiguration(routerLinkConfiguration: BszRouterLinkConfiguration) {
    this._routerLinkConf = Object.assign({}, routerLinkConfiguration, this._routerLinkConf || {});
  }

  /**
   * The first element in the template (bsz-content-navigation-intersect) connects the intersection observers
   * for the sections. When they connect, they detect the top position that will be used to define the rootMargin
   * in the threshold of their observers (see connectSectionObservers)
   */
  private connectMainIntersectionObserver() {
    this.mainIntersectionObserver = new IntersectionObserver(
      this.createIntersectCallback(this.mainIntersectionObserverCallback()),
      {rootMargin: '0px 0px 0px'}
    );
    this.mainIntersectionObserver.observe(
      this.elementRef.nativeElement.querySelector('.bsz-content-navigation-intersect')
    );
  }

  /**
   * Based in the way in which the element appears/disappears from the top, it connects or disconnects the observers
   */
  private mainIntersectionObserverCallback() {
    return (scrollDown: boolean, entering: boolean) => {
      if (!this._sections.length || this._sections[0].intersectionObserver) {
        return;
      }

      // When scrolling the page down, the element reaches the top, so it activates the observers
      if (scrollDown && !entering) {
        this.connectSectionObservers();
      }
    };
  }

  /**
   * Each section has its own intersectionObserver to notify when it is active based in the scroll,
   * and this function connects them all
   */
  private connectSectionObservers() {
    const intersect = this.elementRef.nativeElement.querySelector('.bsz-content-navigation-nav-list');
    const options = {
      // setting the space needed to consider when there is header
      rootMargin: `-${intersect.getBoundingClientRect().top}px 0px 0px`,
      threshold: 1,
    };
    this._sections.forEach((section: SectionDefinition) => {
      section.intersectionObserver = new IntersectionObserver(
        this.createIntersectCallback(this.sectionObserverCallback(section)),
        options
      );
      section.intersectionObserver.observe(section.element);
    });
  }

  private sectionObserverCallback(section: SectionDefinition) {
    return (scrollDown: boolean, entering: boolean) => {
      if (this.preventIntersectionCallback || this._isMobile) {
        return;
      }
      if (scrollDown && !entering) {
        this.sectionDisappearingFromTop(section);
        return;
      }

      if (!scrollDown && entering) {
        this.sectionAppearingFromTop(section);
      }
    };
  }

  /**
   * When scrolling the page down, the content reaches the top. When it happens, the section is considered
   * the active one and the previous section stops being active
   */
  private sectionDisappearingFromTop(section: SectionDefinition) {
    const activeId = section.sectionId;
    this.setSectionStatus(activeId, true);

    const indexOfPreviouslyActive =
      this.sectionsId.findIndex((sectionId: string) => sectionId === section.sectionId) - 1;
    const inactiveId = this.sectionsId[indexOfPreviouslyActive ? indexOfPreviouslyActive : 0];
    this.setSectionStatus(inactiveId, false);
    this.updateActive();
  }

  /**
   * When scrolling the page up, the content reaches goes down, so the active section would be the
   * one that in the array of sections is before
   */
  private sectionAppearingFromTop(section: SectionDefinition) {
    const indexOfActive = this.sectionsId.findIndex((sectionId: string) => sectionId === section.sectionId) - 1;
    const activeId = this.sectionsId[indexOfActive ? indexOfActive : 0];
    this.setSectionStatus(activeId, true);
    this.updateActive();
  }

  private setSectionStatus(sectionId: string, isActive: boolean) {
    const sectionToUpdate = this._sections.find((section: SectionDefinition) => section.sectionId === sectionId);

    if (isActive) {
      if (sectionToUpdate) {
        sectionToUpdate.active = isActive;
      }
      return;
    }

    if (sectionToUpdate) {
      sectionToUpdate.active = isActive;
    }
  }

  private setActiveSection(sectionId: string) {
    this._sections.forEach((section: SectionDefinition, index) => {
      section.active = section.sectionId === sectionId;
    });
    this.updateActive();
  }

  private updateActive() {
    const previousActiveIndex = this.sectionsId.findIndex((sectionId: string) => sectionId === this._activeId);
    this.removeSectionClass(this._sections[previousActiveIndex], sectionActiveClass);

    // the active link is the first active section in the array
    const activeIndex = this._sections.findIndex((section: SectionDefinition) => section.active);
    this._activeId = this.sectionsId[activeIndex];
    this.setSectionClass(this._sections[activeIndex], sectionActiveClass);
    this.changeDetector.markForCheck();
  }

  private setSectionClass(section: SectionDefinition | undefined, sectionClass: string) {
    if (!section) {
      return;
    }
    section.element.classList.add(sectionClass);
  }

  private removeSectionClass(section: SectionDefinition | undefined, sectionClass: string) {
    if (!section) {
      return;
    }
    section.element.classList.remove(sectionClass);
  }

  private disconnectIntersectionObservers() {
    this.disconnectSectionObservers();
    this.mainIntersectionObserver?.disconnect();
  }

  private disconnectSectionObservers() {
    this._sections.forEach((section: SectionDefinition, index: number) => {
      section.intersectionObserver?.disconnect();
      section.active = index === 0;
    });
    this.updateActive();
  }

  /**
   * Generic function to create callbacks that will be executed with intersectionObserver
   */
  private createIntersectCallback(callback: (scrollDown: boolean, entering: boolean) => void) {
    let previousTop = 0;
    let previousRatio = 0;
    return (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        const currentTop = entry.boundingClientRect.top;
        const currentRatio = entry.intersectionRatio;
        const isIntersecting = entry.isIntersecting;
        const scrollDown = currentTop < previousTop;
        let entering: boolean;

        if (scrollDown) {
          entering = currentRatio > previousRatio && isIntersecting;
        } else {
          entering = currentRatio > previousRatio;
        }
        previousTop = currentTop;
        previousRatio = currentRatio;

        callback(scrollDown, entering);
      });
    };
  }

  /** @private */
  _getClass(): string[] {
    const elementClass = [];

    if (this._isMobile) {
      elementClass.push('bsz-content-navigation-mobile');
    }

    if (this._navigationPosition === 'left') {
      elementClass.push('bsz-content-navigation--left-to-right');
    }
    return elementClass;
  }
}
