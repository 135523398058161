import {ChangeDetectionStrategy, Component, Inject, Input} from '@angular/core';
import {NavigationService} from '../../../navigation/navigation.service';
import {MainNavItemInternal} from '../model/main-nav.internal-model';
import {PortalConfig} from '../../../config/portal.config.definition';
import {ɵPORTAL_CONFIG} from '../../../config/portal.config.provider';

@Component({
  selector: 'bsz-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavItemComponent {
  @Input()
  navItem: MainNavItemInternal;

  @Input()
  active: boolean = false;

  @Input()
  childItem: boolean = false;

  readonly defaultIcon = this.portalConfig.navigation.mainNav.defaultIcon;

  constructor(
    @Inject(ɵPORTAL_CONFIG) private portalConfig: PortalConfig,
    private navigationService: NavigationService
  ) {}

  navigate(): void {
    const intentFactory = this.navItem.intentFactory;
    if (intentFactory === null || intentFactory === undefined) {
      throw new Error(`The selected main menu item does not have a value for property 'intentFactory'`);
    }
    if (typeof intentFactory !== 'function') {
      throw new Error(`The selected main menu item contains a non-function value for property 'intentFactory'`);
    }
    this.navigationService.navigate(intentFactory(), {resetHistoryNavigation: true});
  }
}
