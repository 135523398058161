import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {BszI18nModule} from '@basuiz/i18n';

import {BszSelectable} from './bsz-selectable';
import {BszSelectableCheckbox} from './bsz-selectable-checkbox';

const EXPORTED_DECLARATIONS = [BszSelectable, BszSelectableCheckbox];

@NgModule({
  imports: [CommonModule, MatCheckboxModule, BszI18nModule],
  exports: EXPORTED_DECLARATIONS,
  declarations: EXPORTED_DECLARATIONS,
})
export class BszSelectableModule {}
