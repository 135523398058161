import {Injectable} from '@angular/core';
import {ComponentType} from '@angular/cdk/overlay';
import {Observable, of} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {BszScreenSize} from '@basuiz/ui-elements';
import {AfpAuthObject, AfpAuthRequest} from '../models/auth.definitions';
import {
  AfpAuthDialogInput,
  AfpAuthDialogResult,
  AuthDialogRepresentation,
  AuthDialogVerificationRequest,
} from '../models/auth-dialog.definitions';
import {AuthDialogComponent} from '../components/auth-dialog/auth-dialog.component';
import {AuthRepresentationService} from './auth-representation.service';
import {AuthNativeDialogService} from './auth-native-dialog.service';

@Injectable()
export class AuthDialogService {
  constructor(
    private dialog: MatDialog,
    private screenSize: BszScreenSize,
    private representationService: AuthRepresentationService,
    private nativeDialogService: AuthNativeDialogService
  ) {}

  public overrideDialog: ComponentType<unknown> | undefined = undefined;

  private get dialogComponent() {
    return this.overrideDialog ?? AuthDialogService.DIALOG_COMPONENT;
  }

  public open(authRequest: AfpAuthRequest): Observable<[AfpAuthDialogResult, AfpAuthObject]> {
    return this.nativeDialogService
      .open(authRequest)
      .pipe(
        switchMap((result: [AfpAuthDialogResult, AfpAuthObject] | 'unhandled') =>
          result === 'unhandled' ? this.openDialog(authRequest) : of(result)
        )
      );
  }

  private openDialog(authRequest: AfpAuthRequest): Observable<[AfpAuthDialogResult, AfpAuthObject]> {
    const [authDialogInput, transactionSigningObject] = this.getDialogData(authRequest);

    return this.getDialogConfig(authDialogInput).pipe(
      switchMap((config: MatDialogConfig<AfpAuthDialogInput>) =>
        this.dialog.open(this.dialogComponent, config).afterClosed()
      ),
      map((result: AfpAuthDialogResult) => [result, transactionSigningObject])
    );
  }

  private getDialogData(authRequest: AfpAuthRequest): [AfpAuthDialogInput, AfpAuthObject] {
    const {clientRepresentation, id, versionId, transactionSigningObjectType, ...operation} = authRequest;
    const transactionSigningObject: AfpAuthObject = {id, versionId, transactionSigningObjectType};

    const representation: AuthDialogRepresentation = this.representationService.getDialogRepresentation(
      clientRepresentation
    );
    const verificationRequest: AuthDialogVerificationRequest = {
      transactionSigningObject,
      ...operation,
    };
    const authDialogInput: AfpAuthDialogInput = {
      representation,
      verificationRequest,
    };

    return [authDialogInput, transactionSigningObject];
  }

  private getDialogConfig(authDialogInput: AfpAuthDialogInput): Observable<MatDialogConfig<AfpAuthDialogInput>> {
    return this.screenSize.getScreenSize().pipe(
      take(1),
      map((screenSize) => {
        const baseConfig =
          screenSize === 'mobile' ? AuthDialogService.MOBILE_DIALOG_CONFIG : AuthDialogService.DESKTOP_DIALOG_CONFIG;
        return {
          ...baseConfig,
          data: authDialogInput,
        };
      })
    );
  }

  private static DIALOG_COMPONENT = AuthDialogComponent;

  private static DIALOG_CONFIG: MatDialogConfig = {
    panelClass: 'bsz-auth-dialog',
    disableClose: true,
    hasBackdrop: true,
  };

  private static MOBILE_DIALOG_CONFIG: MatDialogConfig = {
    ...AuthDialogService.DIALOG_CONFIG,
    width: '100vw',
    maxWidth: '100vw',
    height: '100vh',
    maxHeight: '100vh',
  };

  private static DESKTOP_DIALOG_CONFIG: MatDialogConfig = {
    ...AuthDialogService.DIALOG_CONFIG,
    maxHeight: '90vh',
  };
}
