import {DebugElement} from '@angular/core';

import {JestFixtureFacade, JestFixtureFacadeAware} from './jest-fixture-facade';
import {MatTab, MatTabGroup} from '@angular/material/tabs';
import {MatTabGroup as GenericMatTabGroup} from '../generic/components';

export class JestMatTabGroup implements JestFixtureFacadeAware, GenericMatTabGroup {
  constructor(public testId: string, public jestFixtureFacade: JestFixtureFacade) {}

  get exists(): boolean {
    return !!this.matTabGroupDirectiveDebugElement;
  }

  get selectedIndex(): number | null {
    return this.matTabGroup.selectedIndex;
  }

  get labels(): string[] {
    return this.tabs.map((i: MatTab) => i.textLabel);
  }

  click(tabIndex: number) {
    const tabs: NodeListOf<Element> = this.matTabGroupDirectiveDebugElement.nativeElement.querySelectorAll(
      `div[role="tab"]`
    );
    (tabs.item(tabIndex) as HTMLElement).click();
    this.jestFixtureFacade.detectChanges();
  }

  private get tabs(): MatTab[] {
    return this.matTabGroup._tabs.toArray();
  }

  private get matTabGroup(): MatTabGroup {
    return this.matTabGroupDirectiveDebugElement.injector.get<MatTabGroup>(MatTabGroup);
  }

  private get matTabGroupDirectiveDebugElement(): DebugElement {
    return this.jestFixtureFacade.debugElement.query((element) => {
      const matches = element.nativeElement.getAttribute('test-id') === this.testId;
      if (matches && element.nativeElement.tagName !== 'MAT-TAB-GROUP') {
        throw new Error('A test id for a MatTabGroup must be placed on the <mat-tab-group> element');
      }
      return matches;
    });
  }
}
