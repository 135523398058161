import {UnionTypeFromArray} from '@basuiz/web-app-applet-api';

export const BackendFeature = [
  /**
   * New option to add multiple 'businessPartnerId' and 'portfolioId' in trading orders and trading forex orders.
   * com.basuiz.afs.rest.services/rest/trading/orders?portfolioId=PORTFOLIO_ID_1&portfolioId=PORTFOLIO_ID_2&businessPartnerId=BUSINESS_PARTNER_ID_1&businessPartnerId=BUSINESS_PARTNER_ID_2
   * com.basuiz.afs.rest.services/rest/fxtrade/orders?portfolioId=PORTFOLIO_ID_1&portfolioId=PORTFOLIO_ID_2&businessPartnerId=BUSINESS_PARTNER_ID_1&businessPartnerId=BUSINESS_PARTNER_ID_2
   */
  'rest.end-point.trading.query-param.multiple-business-partner-id.multiple-portfolio-id',
] as const;

export type BackendFeature = UnionTypeFromArray<typeof BackendFeature>;
