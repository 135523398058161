import {Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';

/**
 * Directive that adds css class with proper styling for positive or negative values
 *
 * Eg.: <div bszPositiveNegative="10"> 10 </div>
 * @param bszPositiveNegative value to be checked
 *
 */
@Directive({
  selector: '[bszPositiveNegative]',
})
export class PositiveNegativeDirective implements OnChanges {
  @Input() bszPositiveNegative: number | undefined;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['bszPositiveNegative'].currentValue && this.el?.nativeElement) {
      this.renderer.removeClass(this.el.nativeElement, 'bsz-text-negative');
      this.renderer.removeClass(this.el.nativeElement, 'bsz-text-positive');
      const cssClass = this.getValueCssClass(this.bszPositiveNegative);

      if (cssClass) {
        this.renderer.addClass(this.el.nativeElement, cssClass);
      }
    }
  }

  private getValueCssClass(value: any): 'bsz-text-negative' | 'bsz-text-positive' | '' {
    if (Number(value) < 0) {
      return 'bsz-text-negative';
    }
    if (Number(value) > 0) {
      return 'bsz-text-positive';
    }
    return '';
  }
}
