import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  Renderer2,
} from '@angular/core';

// CSS class applied when swiping
const swipeActiveCSSClass = 'bsz-carousel-swipe-on';

@Directive({
  selector: '[bszCarouselSwipe]',
})
export class BszCarouselSwipe implements AfterViewInit, OnDestroy {
  @Output() swipeStart = new EventEmitter<any>();
  @Output() swipeEnd = new EventEmitter<any>();
  @Output() swipeMove = new EventEmitter<any>();
  @Input()
  set blockSwipe(active: BooleanInput) {
    this._blockSwipe = coerceBooleanProperty(active);
  }
  @Input()
  set swipeRightLimit(value: string | number | undefined | null) {
    this._swipeRightLimit = this.isNumberValue(value) ? Number(value) : undefined;
  }
  @Input()
  set swipeLeftLimit(value: string | number | undefined | null) {
    this._swipeLeftLimit = this.isNumberValue(value) ? Number(value) : undefined;
  }

  @HostListener('touchstart', ['$event']) onTouchStart(event: TouchEvent) {
    this.onPointerDeviceDown(event, 'touch');
  }

  @HostListener('mousedown', ['$event']) onMouseDown(event: MouseEvent) {
    this.onPointerDeviceDown(event, 'mouse');
  }

  @HostListener('touchend', ['$event']) onTouchEnd(event: TouchEvent) {
    this.documentPointerDeviceUp();
  }

  @HostListener('mouseup', ['$event']) onMouseUp(event: MouseEvent) {
    this.documentPointerDeviceUp();
  }

  private initialPointerClientX = 0;
  private initialScrollLeft = 0;
  private isSwipeActive = false;
  private documentPointerDeviceMove: (event: TouchEvent | MouseEvent) => void = () => {};
  private documentPointerDeviceUp = () => {};
  private disabledButtonsEventsUnListeners: (() => void)[] = [];
  private _blockSwipe = false;
  private _swipeRightLimit: number | undefined;
  private _swipeLeftLimit: number | undefined;

  constructor(private _elementRef: ElementRef<HTMLElement>, private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.setDisabledButtonsEventsListeners();
    this.documentPointerDeviceMove = this.onPointerDeviceMove();
    this.documentPointerDeviceUp = this.onPointerDeviceUp();
  }

  ngOnDestroy() {
    this.removeDocumentEventListeners();
    this.disabledButtonsEventsUnListeners.forEach((unListener: () => void) => {
      unListener();
    });
  }

  onPointerDeviceDown(event: TouchEvent | MouseEvent, eventType: 'touch' | 'mouse') {
    if (this._blockSwipe) {
      return;
    }
    this.swipeStart.emit();
    this.addDocumentEvents(eventType);
    this.initialPointerClientX = this.getClientXFromEvent(event);
    this.isSwipeActive = true;
    this.initialScrollLeft = this.getTranslationX();
    this.renderer.addClass(this._elementRef.nativeElement, swipeActiveCSSClass);
  }

  onPointerDeviceMove() {
    return (event: TouchEvent | MouseEvent) => {
      if (!this.isSwipeActive || this._blockSwipe) {
        return;
      }
      event.preventDefault();
      const currentPointerClientX = this.getClientXFromEvent(event);
      const incrementBetweenPositions = Math.round(currentPointerClientX - this.initialPointerClientX);
      const updatedPosition = this.getUpdatedPosition(this.initialScrollLeft + incrementBetweenPositions);

      this.renderer.setStyle(this._elementRef.nativeElement, 'transform', `translateX(${updatedPosition}px)`);
      this.swipeMove.emit(incrementBetweenPositions);
    };
  }

  onPointerDeviceUp() {
    return () => {
      this.removeDocumentEventListeners();
      if (!this.isSwipeActive || this._blockSwipe) {
        return;
      }
      this.isSwipeActive = false;
      this.renderer.removeClass(this._elementRef.nativeElement, swipeActiveCSSClass);
      const currentScrollLeft = this.getTranslationX();
      const positionIncrement = currentScrollLeft - this.initialScrollLeft;
      this.swipeEnd.emit(positionIncrement);
    };
  }

  private addDocumentEvents(eventType: 'mouse' | 'touch') {
    const pointerEvents: Record<string, {move: 'touchmove' | 'mousemove'; up: 'touchend' | 'mouseup'}> = {
      touch: {move: 'touchmove', up: 'touchend'},
      mouse: {move: 'mousemove', up: 'mouseup'},
    };
    // we use addEventListener to pass the additional option "passive", because Angular does not have a way to have it
    // by using the  method "listen" in the renderer service
    document.addEventListener(pointerEvents[eventType].move, this.documentPointerDeviceMove, {passive: false});
    document.addEventListener(pointerEvents[eventType].up, this.documentPointerDeviceUp);
  }

  /**
   * Returns the position based on the horizontal distance that the movement does. It also slow down
   * the movement of the swipe effect when reaching the left or right limits in a ratio 1:3 (the swipe
   * moves 1px when the mouse/touch movement does it 3px)
   */
  private getUpdatedPosition(displacement: number): number {
    if (this._swipeLeftLimit !== undefined && displacement < this._swipeLeftLimit) {
      return this._swipeLeftLimit - (this._swipeLeftLimit - displacement) / 3;
    }

    if (this._swipeRightLimit !== undefined && displacement > this._swipeRightLimit) {
      return this._swipeRightLimit + displacement / 3;
    }

    return displacement;
  }

  /**
   * Returns the numeric value of the translationX in the CSS transform property
   */
  private getTranslationX(): number {
    const transform = window.getComputedStyle(this._elementRef.nativeElement).getPropertyValue('transform');
    const matrixValues = transform.split(',');

    // If the browser returns the property translateX(value) directly
    if (transform.indexOf('translateX') === 0) {
      return parseInt(transform.replace(/(?!-)[^0-9.]/g, ''), 10);
    }

    // If the browser returns the CSS translation matrix, the fifth value is translationX:
    // matrix( scaleX(), skewY(), skewX(), scaleY(), translateX(), translateY() )
    if (matrixValues.length >= 6) {
      return parseInt(matrixValues[4], 10);
    }

    return 0;
  }

  private getClientXFromEvent(event: MouseEvent | TouchEvent): number {
    // We don't use the instanceOf here because cypress in e2e tests is triggering an "Event"
    // making the component behave wrongly. So we explicitly check the properties instead.
    if ('changedTouches' in event) {
      return event.changedTouches[0].clientX;
    }

    if ('clientX' in event) {
      return event.clientX;
    }

    return 0;
  }

  private removeDocumentEventListeners() {
    const moveEvents: ('mousemove' | 'touchmove')[] = ['mousemove', 'touchmove'];
    moveEvents.forEach((event) => {
      document.removeEventListener(event, this.documentPointerDeviceMove);
    });
    ['mouseup', 'touchend'].forEach((event) => {
      document.removeEventListener(event, this.documentPointerDeviceUp);
    });
  }

  /**
   * Disabled buttons do not trigger mouse or touch events. Because of that, some of the listeners
   * that are at document level are not triggered and the swipe can have unexpected behavior (see UISDK-625)
   * This function adds listeners to those events to the direct descendants of all buttons in the parent element
   * and they are triggered only when they are disabled.
   *
   * Checks the presence of the disabled attribute but not its value because that attribute, in HTML, is
   * boolean, so just its presence means the element is disabled
   */
  private setDisabledButtonsEventsListeners() {
    const parentElement = this._elementRef.nativeElement.parentElement as HTMLElement;
    const buttons = parentElement.querySelectorAll('button > *');
    buttons.forEach((arrowButton) => {
      const parentButton = arrowButton.parentElement as HTMLElement;
      const mouseupUnListener = this.renderer.listen(arrowButton, 'mouseup', (event) => {
        if (parentButton.attributes.getNamedItem('disabled')) {
          this.documentPointerDeviceUp();
        }
      });
      const touchendUnListener = this.renderer.listen(arrowButton, 'touchend', (event) => {
        if (parentButton.attributes.getNamedItem('disabled')) {
          this.documentPointerDeviceUp();
        }
      });
      this.disabledButtonsEventsUnListeners.push(mouseupUnListener);
      this.disabledButtonsEventsUnListeners.push(touchendUnListener);
    });
  }

  private isNumberValue(value: any): boolean {
    // parseFloat(value) handles most of the cases we're interested in (it treats null, empty string,
    // and other non-number values as NaN, where Number just uses 0) but it considers the string
    // '123hello' to be a valid number. Therefore we also check if Number(value) is NaN.
    return !isNaN(parseFloat(value)) && !isNaN(Number(value));
  }
}
