import {Inject, Injectable} from '@angular/core';
import {firstValueFrom, ReplaySubject} from 'rxjs';
import {DashboardId, PortalConfig} from '../config/portal.config.definition';
import {ɵPORTAL_CONFIG} from '../config/portal.config.provider';
import {map, take} from 'rxjs/operators';

@Injectable()
export class DashboardIdService {
  static dashboardIdResolver(): Promise<DashboardId> {
    return firstValueFrom(
      DashboardIdService._dashboardIdResolverSubject.pipe(
        take(1),
        map((resolver) => resolver())
      )
    );
  }
  private static readonly _dashboardIdResolverSubject = new ReplaySubject<
    PortalConfig['navigation']['dashboardIdResolver']
  >(1);

  // Important! do not instantiate before web-app initialization completes, otherwise the portal-config will fail
  constructor(@Inject(ɵPORTAL_CONFIG) portalConfig: PortalConfig) {
    DashboardIdService._dashboardIdResolverSubject.next(portalConfig.navigation.dashboardIdResolver);
  }
}
