import {AnimationEvent} from '@angular/animations';
import {FocusMonitor} from '@angular/cdk/a11y';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import {AsyncSubject, Subject} from 'rxjs';
import {filter, take, takeUntil} from 'rxjs/operators';

import {BszModalBottomSheetAnimations} from './bsz-modal-bottom-sheet-animations';
import {
  BSZ_MODAL_BOTTOM_SHEET_DEFAULT_OPTIONS,
  BszModalBottomSheetDefaultConfig,
  BszModalBottomSheetGlobalConfig,
} from './bsz-modal-bottom-sheet-config';
import {
  BszModalBottomSheetContentDef,
  BszModalBottomSheetFooterDef,
  BszModalBottomSheetTitleDef,
} from './bsz-modal-bottom-sheet-template-directives';

const LOG_PREFIX = '[bsz-modal-bottom-sheet]';

const defaultConfig = new BszModalBottomSheetDefaultConfig();

@Component({
  selector: 'bsz-modal-bottom-sheet',
  templateUrl: 'bsz-modal-bottom-sheet.html',
  styleUrls: ['bsz-modal-bottom-sheet.scss'],
  animations: [BszModalBottomSheetAnimations.modalBottomSheetMaximizeMinimizeTransition],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    // although it would not be a big impact, hiding the host element prevents conflicts or accessibility errors
    // because it can use aria attributes but the element by itself is not defined in the HTML standard
    hidden: 'hidden',
  },
})
export class BszModalBottomSheet<T = unknown> implements OnDestroy {
  private static nextId = 0;

  /** @private */
  readonly uniqueId = `bsz-modal-bottom-sheet-${BszModalBottomSheet.nextId++}`;

  /** Whether the modal-bottom-sheet has a backdrop. */
  @Input() hasBackdrop: boolean;

  /** The overlay top offset in pixels. */
  @Input() offsetTop: number;

  /** The max width of the modal-bottom-sheet panel. */
  @Input() maxWidth: string;

  /** Aria label to assign to the modal-bottom-sheet element. */
  @Input('aria-label') ariaLabel: string;

  /** ID of the element that labels the modal-bottom-sheet. */
  @Input('aria-labelledby') ariaLabelledby: string;

  /** ID of the element that describes the modal-bottom-sheet. */
  @Input('aria-describedby') ariaDescribedby: string;

  /** Class to add to the panel. */
  @Input() set panelClass(panelClass: string | string[]) {
    if (typeof panelClass === 'string') {
      this._panelClass = panelClass;
      return;
    }
    this._panelClass = panelClass.join(' ');
  }

  /** @private */
  _panelClass: string | string[] = '';

  /** Whether the component should close with Escape key */
  @Input()
  set disableEscape(disableEscape: BooleanInput) {
    this._disableEscape = coerceBooleanProperty(disableEscape);
  }

  get disableEscape() {
    return this._disableEscape;
  }

  _disableEscape: boolean;

  @Input() data: T;

  /** Output when Escape key is used */
  @Output() escapeKeydown = new EventEmitter<KeyboardEvent>();

  /** Event that emits when a dialog has been opened after the animation is done. */
  @Output() afterOpened = new EventEmitter<void>();

  /** Event that emits when a dialog has been closed after the animation is done. */
  @Output() afterClosed = new EventEmitter<T | undefined>();

  /** The <ng-template> reference which will be passed down to the CDK overlay */
  @ViewChild(TemplateRef) private templateRef: TemplateRef<any>;

  /** The <ng-template> references to the available slots for content */
  @ContentChild(BszModalBottomSheetTitleDef, {read: TemplateRef, static: true}) title: TemplateRef<any>;
  @ContentChild(BszModalBottomSheetContentDef, {read: TemplateRef, static: true}) content: TemplateRef<any>;
  @ContentChild(BszModalBottomSheetFooterDef, {read: TemplateRef, static: true}) footer: TemplateRef<any>;

  /** The instance of the template component. It is assigned by the BszModalBottomSheetService if the component was created from it */
  templateInstance: T;

  /** Whether the modal bottom sheet is maximized or minimized. */
  isMaximized = true;

  /** Whether the modal bottom sheet is open. */
  isOpen = false;

  /** @private passed down to the maximize/minimize animation as parameter. */
  minimizedHeight = '0px';

  /** @private it is required so the height of the content does not go off screen. */
  contentContainerHeight = 'calc(100% - 0px)';

  /**
   * Initially (when the overlay is created) the minimizeMaximizeTrigger element is not focusable.
   * This is required in order to let the focus go to the first focusable element of the modal,
   * and not always trapped to the trigger itself.
   *
   * The element becomes focusable after the focus has been set to the first focusable element
   * inside the modal.
   *
   * @private
   */
  minimizeMaximizeTriggerIsFocusable: 0 | -1 = -1;

  /**
   * It is meant to hide the contents of the modal from keyboard and also from assistive technologies.
   * It is used when it is minimized, so it is not only hidden to sight users.
   *
   * @private
   */
  hideContentFromA11YTech = false;

  /** @private for internal use only. Use `afterClosed` in case you want to subscribe to the close event. */
  readonly _onClose = new Subject<void>();

  private readonly maximizedPanelCssClass = 'bsz-modal-bottom-sheet-is-maximized';

  private readonly minimizedPanelCssClass = 'bsz-modal-bottom-sheet-is-minimized';

  /** Reference to the overlay created by the overlay service. */
  private overlayRef: OverlayRef;

  /** Store portal to not re-create it every time */
  private overlayTemplatePortal: TemplatePortal;

  private readonly destroy = new AsyncSubject<void>();

  outputData: T | undefined;

  constructor(
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private focusMonitor: FocusMonitor,
    @Optional()
    @Inject(BSZ_MODAL_BOTTOM_SHEET_DEFAULT_OPTIONS)
    private readonly globalConfig?: BszModalBottomSheetGlobalConfig
  ) {
    const config = Object.assign(defaultConfig, globalConfig || {});
    this.hasBackdrop = config.hasBackdrop;
    this.offsetTop = config.offsetTop;
    this.maxWidth = config.maxWidth;
    this.disableEscape = config.disableEscape;
    this.panelClass = config.panelClass;
  }

  ngOnDestroy(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
    this.destroy.next();
    this.destroy.complete();
    this._onClose.complete();
  }

  open(): void {
    // in case the modal is already open but minimized, and 'open' is re-triggered, we just maximise it
    if (this.overlayRef?.hasAttached()) {
      this.maximize();
      return;
    }
    this.isOpen = true;
    this.createOverlay();
    this.addMaximizedClassToOverlayPanel();
  }

  close(outputData?: T): void {
    this.outputData = outputData;
    this.overlayRef.detach();
    this.resetState();
    this._onClose.next();
  }

  maximize(): void {
    this.isMaximized = true;
    this.addMaximizedClassToOverlayPanel();
  }

  minimize(): void {
    const bottomSheetHeaderElement = this.getHeaderHtmlElement();
    const headerClientRect = bottomSheetHeaderElement.getBoundingClientRect();
    this.minimizedHeight = this.offsetTop + headerClientRect.height + 'px';
    this.isMaximized = false;
    this.addMinimizedClassToOverlayPanel();
  }

  toggleMaximize(): void {
    if (this.isMaximized) {
      this.minimize();
    } else {
      this.maximize();
    }
  }

  /** @private */
  onTransitionStart(event: AnimationEvent) {
    if (event.toState === 'maximized') {
      this.hideContentFromA11YTech = false;
    }
  }

  /** @private */
  onTransitionEnd(event: AnimationEvent) {
    this.hideContentFromA11YTech = !this.isMaximized;

    if (event.fromState === 'void') {
      this.afterOpened.emit();
    }

    if (event.toState === 'void') {
      this.afterClosed.emit(this.outputData);
    }
  }

  private resetState(): void {
    this.isMaximized = true;
    this.isOpen = false;
    this.minimizeMaximizeTriggerIsFocusable = -1;
  }

  private createOverlay(): void {
    this.overlayRef = this.overlay.create(this.getOverlayConfig());
    this.initOverlaySubscribers();

    if (!this.overlayTemplatePortal) {
      this.overlayTemplatePortal = new TemplatePortal(this.templateRef, this.viewContainerRef);
    }
    this.overlayRef.attach(this.overlayTemplatePortal);

    this.contentContainerHeight = this.calculateModalContainerHeight();

    this.focusMonitor
      .monitor(this.overlayRef.overlayElement, true)
      .pipe(take(1))
      .subscribe(() => this.enableFocusOnTrigger());
  }

  private handleEscPress(event: KeyboardEvent): void {
    this.escapeKeydown.emit(event);
    if (!this.disableEscape) {
      this.close();
      return;
    }
  }

  /**
   * We need to calculate the height of the element since we cannot simply use 100% because
   * the margin of the overlay is pushing the container offscreen. We take 100% of the height
   * and we subtract the offset from the top (including the top offset and the height of the
   * top bar element).
   */
  private calculateModalContainerHeight(): string {
    const bottomSheetHeaderElement = this.getHeaderHtmlElement();

    const totalOffset = this.offsetTop + bottomSheetHeaderElement.offsetHeight;

    return `calc(100% - ${totalOffset}px)`;
  }

  /**
   * We get the HTML element for the header via the overlayRef
   * because we cannot use @ViewChild or @ViewChildren for
   * elements inside the <ng-template>.
   */
  private getHeaderHtmlElement(): HTMLDivElement {
    const bottomSheetHeaderElement = this.overlayRef.overlayElement.querySelector(
      '.bsz-modal-bottom-sheet-header'
    ) as HTMLDivElement;

    if (!bottomSheetHeaderElement) {
      throw new Error(`${LOG_PREFIX} Header element not found`);
    }

    return bottomSheetHeaderElement;
  }

  private addMaximizedClassToOverlayPanel(): void {
    this.overlayRef.addPanelClass(this.maximizedPanelCssClass);
    this.overlayRef.removePanelClass(this.minimizedPanelCssClass);
  }

  private addMinimizedClassToOverlayPanel(): void {
    this.overlayRef.addPanelClass(this.minimizedPanelCssClass);
    this.overlayRef.removePanelClass(this.maximizedPanelCssClass);
  }

  private getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .global()
      .top(this.offsetTop + 'px')
      .centerHorizontally();

    const panelClasses = ['bsz-modal-bottom-sheet-overlay-panel'];
    if (!this.hasBackdrop) {
      panelClasses.push('bsz-modal-bottom-sheet-overlay-panel-no-backdrop');
    }

    return {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      positionStrategy: positionStrategy,
      panelClass: panelClasses,
      hasBackdrop: this.hasBackdrop,
      backdropClass: ['cdk-overlay-dark-backdrop'],
    };
  }

  private enableFocusOnTrigger() {
    this.minimizeMaximizeTriggerIsFocusable = 0;
  }

  private initOverlaySubscribers(): void {
    this.overlayRef
      .keydownEvents()
      .pipe(
        filter((event) => event.key === 'Escape'),
        takeUntil(this._onClose)
      )
      .subscribe((event: KeyboardEvent) => this.handleEscPress(event));
  }
}
