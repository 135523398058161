import {animate, AnimationTriggerMetadata, keyframes, state, style, transition, trigger} from '@angular/animations';

const transitionTiming = '150ms cubic-bezier(0, 0, 0.2, 1)';

export const transformCalendar: AnimationTriggerMetadata = trigger('transformCalendar', [
  state('dropdown-opened', style({transform: 'scale(1, 1)', opacity: 1})),
  state('dialog-opened', style({transform: 'scale(1, 1)', opacity: 1})),
  transition('* => dropdown-opened', [style({transform: 'scale(1, .8)', opacity: 0}), animate(transitionTiming)]),
  transition('dropdown-opened => *', [style({opacity: 0}), animate(transitionTiming)]),
  transition('* => dialog-opened', [
    animate(
      transitionTiming,
      keyframes([style({opacity: 0, transform: 'scale(0.7)'}), style({transform: 'scale(1)', opacity: 1})])
    ),
  ]),
  transition('dialog-opened => *', [style({opacity: 0}), animate(transitionTiming)]),
]);
