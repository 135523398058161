import {Directive, HostBinding, Input, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {BszSelectSearch} from '@basuiz/ui-elements';
import {BszSelectSearchFilterMatSelectDirective} from './bsz-select-search-filter.mat-select.directive';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'bsz-select-search',
})
export class BszSelectSearchFilterBszSelectSearchDirective implements OnDestroy {
  @Input()
  minNumberOfOptionsToSearch: number = 8;

  @HostBinding('style.display')
  private styleDisplay: 'none' | undefined;

  private subscriptions = new Subscription();

  constructor(
    selectSearchFilterMatSelectDirective: BszSelectSearchFilterMatSelectDirective,
    selectSearch: BszSelectSearch
  ) {
    this.subscriptions.add(
      selectSearchFilterMatSelectDirective.optionsCount.subscribe(
        (optionsCount) => (this.styleDisplay = optionsCount < this.minNumberOfOptionsToSearch ? 'none' : undefined)
      )
    );

    this.subscriptions.add(
      selectSearch.searchChange.subscribe((searchText: string) =>
        selectSearchFilterMatSelectDirective.searchChange.next(searchText)
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
