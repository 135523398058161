import {OperatorFunction} from 'rxjs';
import {filter} from 'rxjs/operators';

export function isDefined<T>(value: T | undefined): value is T {
  return value !== undefined;
}

export function isNotNullOrUndefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function isNullOrUndefined<T>(value: T | null | undefined): value is null | undefined {
  return value === null || value === undefined;
}

export function isStringFalsy(value: string | null | undefined): boolean {
  return isNullOrUndefined(value) || value === '';
}

export function assertHasValue<T>(value: T | null | undefined): asserts value is T {
  if (isNullOrUndefined(value)) {
    throw new Error(`Expected a value but received ${value === null ? 'null' : 'undefined'}`);
  }
}

export function getValueOrFail<T>(value: T | null | undefined): T {
  assertHasValue(value);
  return value;
}

export function getValueOrUndefined<T>(value: T | null | undefined): T | undefined {
  return isNullOrUndefined(value) ? undefined : value;
}

export function getNonEmptyStringOrUndefined(value: string | null | undefined): string | undefined {
  return isStringFalsy(value) ? undefined : (value as string);
}

export function filterDefined<T>(): OperatorFunction<T | undefined, T> {
  return filter(isDefined);
}
