<ng-container *ngIf="!_modal; then bannerComponentTpl;">
</ng-container>

<ng-template #bannerComponentTpl>
  <div *ngIf="isVisible" [attr.role]="_role" tabindex="-1" [attr.aria-label]="ariaLabel"
       class="bsz-banner bsz-body-2"
       [ngClass]="['bsz-banner-' + type, className, _bannerClass]">
  <span class="bsz-banner-icon" [ngClass]="'bsz-banner-icon-' + type">
    <mat-icon aria-hidden="false"
              [attr.aria-label]="_getIconAriaLabel(type)">{{_getIcon(type)}}</mat-icon>
  </span>

    <div class="bsz-banner-content" bszResizeObserver (resize)="_onResize()"
         [ngClass]="{'bsz-banner-content--with-actions': !!actions, 'bsz-banner-content--multiline': _isMultiLine}">
      <div class="bsz-banner-text" [ngClass]="{'bsz-banner-text--with-title': !!title}">
        <ng-content *ngIf="!!title" select="bsz-banner-title"></ng-content>
        <div class="bsz-banner-message">
          <ng-content></ng-content>
        </div>
      </div>

      <ng-content *ngIf="actions" select="bsz-banner-actions"></ng-content>
    </div>

    <button
      *ngIf="dismissable"
      mat-icon-button
      class="bsz-banner-dismiss"
      (click)="dismiss()"
      [color]="_dismissButtonColor"
      [attr.aria-label]="'ui-elements.bsz-banner.accessibility.close' | translate"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</ng-template>
