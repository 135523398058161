import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HybridActionService} from '@basuiz/web-app-hybrid';

@Injectable()
export class AfpRestIsNativeAppHttpInterceptor implements HttpInterceptor {
  constructor(private hybridActionService: HybridActionService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.shouldIntercept()) {
      return next.handle(req.clone({headers: req.headers.set('afp-isNativeApp', 'true')}));
    }

    return next.handle(req);
  }

  private shouldIntercept(): boolean {
    return this.hybridActionService.isInsideNative();
  }
}
