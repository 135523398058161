import {Component, Host, OnInit, Optional} from '@angular/core';

import {BszTableCollapsible} from './bsz-table-collapsible';
import {BszTableColumn} from './bsz-table-column';

@Component({
  selector: 'bsz-table-group-header-column',
  providers: [{provide: 'BSZ_TABLE_GROUP_HEADER_COLUMN', useExisting: BszTableGroupHeaderColumn}],
  template: `
    <ng-container [matColumnDef]="name">
      <td
        bsz-cell
        *matCellDef="let data"
        [attr.colspan]="colspan"
        [class.bsz-cursor-pointer]="isCollapsible"
        class="bsz-group-header-cell"
      >
        <button
          *ngIf="isFirstColumn() && isCollapsible"
          class="bsz-table-collapser"
          [attr.aria-expanded]="!data._collapsed"
        >
          <i class="material-icons" aria-hidden="true" matRipple [matRippleRadius]="20" [matRippleCentered]="true">
            keyboard_arrow_right
          </i>
          <span class="bsz-group-header-cell-content mat-cell">
            <ng-container *ngTemplateOutlet="cellContent"></ng-container>
          </span>
        </button>

        <span *ngIf="!isFirstColumn() || !isCollapsible" class="bsz-group-header-cell-content">
          <ng-container *ngTemplateOutlet="cellContent"></ng-container>
        </span>

        <ng-template #cellContent>
          <!-- Render custom template if set -->
          <ng-template
            *ngIf="cellDef; else rawCell"
            [ngTemplateOutlet]="cellDef"
            [ngTemplateOutletContext]="{$implicit: data}"
          ></ng-template>

          <!-- Else just print the raw value -->
          <ng-template #rawCell>
            <span>{{ getPropertyFromDefinition(data) }}</span>
          </ng-template>
        </ng-template>
      </td>
    </ng-container>
  `,
})
export class BszTableGroupHeaderColumn<T> extends BszTableColumn<T> implements OnInit {
  colspan = 1;

  /** prevent the check of the headers as they are not needed for this type of column */
  override headerCellDef!: never;

  isCollapsible = false;

  constructor(@Optional() @Host() bszCollapsible: BszTableCollapsible<T, unknown>) {
    super();
    this.isCollapsible = bszCollapsible !== null;
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  override ngOnInit() {
    // method needs to be defined to override base class implementation of ngOnInit
  }

  isFirstColumn(): boolean {
    return this.columnIndex === 0;
  }
}
