import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import {BszPortalPageTabDirective} from './bsz-portal-page-tab.directive';
import {PageTabMemory} from './page-tab-memory.definition';
import {startWith} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, Observable, ReplaySubject, Subscription} from 'rxjs';
import {TranslatedText} from '@basuiz/web-app-applet-api';
import {PortalLayoutService} from '../portal-layout/portal-layout.service';
import {ɵPORTAL_CONFIG} from '../../config/portal.config.provider';
import {PortalConfig} from '../../config/portal.config.definition';
import {
  BszPortalPageHeaderItem,
  PortalPageHeaderSubtitleDirective,
  PortalPageHeaderTitleDirective,
} from './bsz-portal-page-header-item';
import {BreadcrumbsComponent} from './breadcrumbs/breadcrumbs.component';
import {appShellOutletProvider} from '@basuiz/web-app-applet-sdk';

@Component({
  selector: 'bsz-portal-page-layout',
  templateUrl: './bsz-portal-page-layout.component.html',
  styleUrls: ['./bsz-portal-page-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [appShellOutletProvider('primaryActions')],
})
export class BszPortalPageLayoutComponent implements AfterContentInit, AfterViewInit, OnDestroy {
  readonly bszTestIdPrefix = 'web-app-portal.bsz-portal-page-layout';

  /* When true, the page will not add a header section.
   * I.e. the section typically including the title, breadcrumbs, actions ...
   * @default: false (the header section will be included in the page)
   */
  @Input()
  hideHeader: boolean = false;

  /** Object that will be updated when a tab is selected.
   * Typically used to keep track of the active tab when navigating back in the session history */
  @Input()
  set tabMemory(value: PageTabMemory<string> | null) {
    this.#tabMemory = value;

    if (this._pageTabs?.length > 0) {
      const currentTabId = this._pageTabs[this._selectedTabIndex].tabId;
      const newTabId = value?.activeTabId;
      const availableTabs = this._pageTabs.map((tab) => tab.tabId);

      if (newTabId && availableTabs.includes(newTabId) && newTabId !== currentTabId) {
        this.selectTab(newTabId);
      }
    }
  }

  #tabMemory: PageTabMemory<string> | null = null;

  private tabFilterSubject = new BehaviorSubject<string[] | undefined>(undefined);

  /** Optional array containing the id of the tabs to be displayed, on the same order as in the array.
   * Alternatively set to undefined in order to show all the tabs in their natural order.
   * @default: undefined, all tabs are shown */
  @Input()
  public set tabFilter(value: string[] | undefined) {
    this.tabFilterSubject.next(value);
  }

  @HostBinding('class.full-screen-layout')
  private _fullScreen: boolean;
  get fullScreen(): boolean {
    return this._fullScreen;
  }

  /** Toggles the screen between normal-screen and full-screen mode. In full screen mode, the page is styled to cover the
   * whole viewport, thus covering other parts of the portal's layout. */
  @Input()
  set fullScreen(value: boolean) {
    const valueHasChanged = value !== this._fullScreen;
    this._fullScreen = value;
    this.portalLayoutService.ɵsetFullScreen(value);
    if (valueHasChanged) {
      this.changeDetectorRef.markForCheck();
    }
  }

  /** Aria label to be added to the close full screen button. If not set, the button will use a default translation. */
  @Input()
  fullScreenCloseButtonAriaLabel: TranslatedText;

  /** Event that emits when the user clicks on the close button shown on full-screen mode.
   * If no binding is present on the consumer's template, then the layout triggers the default behavior which is
   * switching back the layout to normal-screen mode.
   * */
  @Output()
  public fullScreenClose: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(BreadcrumbsComponent)
  private breadcrumbsComponent: BreadcrumbsComponent;

  @ContentChildren(BszPortalPageHeaderItem)
  private headerItems: QueryList<BszPortalPageHeaderItem>;

  private readonly containsHeaderItemsSubject = new ReplaySubject<boolean>(1);
  readonly containsHeaderItems$: Observable<boolean> = this.containsHeaderItemsSubject.asObservable();

  @ContentChildren(BszPortalPageTabDirective)
  private bszPageTabs: QueryList<BszPortalPageTabDirective>;

  _selectedTabIndex: number;
  _pageTabs: BszPortalPageTabDirective[];
  _tabIds: string[];

  readonly _maxPageWidthInPx = this.portalConfig.portalLayout.maxPageWidthInPx + 'px';
  readonly _showTitle = this.portalConfig.pageLayout.showPageTitle;
  readonly _showSubtitle = this.portalConfig.pageLayout.showPageSubtitle;

  private subscriptions = new Subscription();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private portalLayoutService: PortalLayoutService,
    @Inject(ɵPORTAL_CONFIG) private portalConfig: PortalConfig
  ) {
    this.fullScreen = false;
  }

  ngAfterContentInit(): void {
    const tabChanges$ = this.bszPageTabs.changes.pipe(startWith(0));
    this.subscriptions.add(
      combineLatest([tabChanges$, this.tabFilterSubject]).subscribe(([_, tabFilter]) => {
        this._pageTabs = this.sortAndFilterTabs(this.bszPageTabs, tabFilter);
        this._tabIds = this._pageTabs.map((tab) => tab.tabId);
        this.setSelectedTabOnTabsChanges();
      })
    );
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      combineLatest([
        this.breadcrumbsComponent.breadcrumbsVisible$,
        this.headerItems.changes.pipe(startWith(0)),
      ]).subscribe(([breadcrumbsVisible, _]) => {
        const hasExactlyOneTab =
          this.headerItems.toArray().filter((item) => item instanceof BszPortalPageTabDirective).length === 1;
        const hasVisibleHeaderItems: boolean = this.headerItems
          .toArray()
          .some(
            (item) =>
              !(
                (item instanceof PortalPageHeaderTitleDirective && !this._showTitle) ||
                (item instanceof PortalPageHeaderSubtitleDirective && !this._showSubtitle) ||
                (item instanceof BszPortalPageTabDirective && hasExactlyOneTab)
              )
          );
        setTimeout(() => this.containsHeaderItemsSubject.next(breadcrumbsVisible || hasVisibleHeaderItems));
      })
    );
  }

  public _showTabHeaders(): boolean {
    return this._pageTabs?.length > 1;
  }

  private sortAndFilterTabs(
    pageTabs: QueryList<BszPortalPageTabDirective>,
    tabFilter: string[] | undefined
  ): BszPortalPageTabDirective[] {
    const templateTabs = pageTabs.toArray();
    return tabFilter
      ? tabFilter
          .map((tf) => templateTabs.find((tt) => tt.tabId === tf))
          .filter((tab): tab is BszPortalPageTabDirective => !!tab)
      : templateTabs;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private static readonly DEFAULT_TAB_INDEX = 0;
  private setSelectedTabOnTabsChanges() {
    if (this._tabIds.length > 0) {
      let initialTabIndex = BszPortalPageLayoutComponent.DEFAULT_TAB_INDEX;
      if (this.#tabMemory?.activeTabId) {
        const indexFromTabMemory = this._tabIds.indexOf(this.#tabMemory?.activeTabId);
        if (indexFromTabMemory !== -1) {
          initialTabIndex = indexFromTabMemory;
        }
      }
      this._setSelectedTabAndUpdateTabMemory(initialTabIndex);
    }
  }

  _setSelectedTabAndUpdateTabMemory(tabIndex: number): void {
    this._selectedTabIndex = tabIndex;
    if (this.#tabMemory) {
      this.#tabMemory.activeTabId = this._tabIds[tabIndex];
    }
  }

  /** Use to select a specific tab programmatically
   * @param tabId the same id-value passed to the corresponding bszPageTab directive */
  selectTab(tabId: string): void {
    const tabIndex = this._tabIds.indexOf(tabId);
    if (tabIndex === -1) {
      throw new Error(`Tab with id ${tabId} not found in the page.`);
    }
    this._setSelectedTabAndUpdateTabMemory(tabIndex);
    this.changeDetectorRef.detectChanges();
  }

  onFullScreenCloseClick() {
    if (this.fullScreenClose.observers.length > 0) {
      this.fullScreenClose.emit();
    } else {
      this.fullScreen = false;
    }
  }
}
