import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {map, take} from 'rxjs/operators';
import {AuthJwtService} from './auth-jwt.service';

/**
 * Guard to be used in pages that should ONLY be available when
 * the user has been previously autenticathed.
 * Pages like: 'dashboard', 'settings', etc.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthJwtUserLoggedInGuardService implements CanActivate {
  constructor(private authJwtService: AuthJwtService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    return this.authJwtService.userJwt$.pipe(
      take(1),
      map((userJwt) => {
        if (userJwt?.user?.objectId) {
          return true; // User found
        } else {
          return this.router.parseUrl('/auth/signin');
          // return false; // don't use this, as this doesn't give any feedback to the user
        }
      })
    );
  }
}
