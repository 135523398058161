import {AfterViewInit, Directive, ElementRef, Host, NgZone, OnDestroy, Renderer2} from '@angular/core';
import {MatStepper} from '@angular/material/stepper';
import {TranslateService} from '@ngx-translate/core';
import {AsyncSubject} from 'rxjs';
import {filter, map, switchMap, take, takeUntil} from 'rxjs/operators';

import {BszScreenSize} from '../bsz-screen-size-content-switcher/index';

@Directive({
  selector: 'mat-stepper[bsz-stepper], mat-horizontal-stepper[bsz-stepper], mat-vertical-stepper[bsz-stepper]',
  host: {class: 'bsz-stepper'},
})
export class BszStepper implements AfterViewInit, OnDestroy {
  /** The wrapper element for the step indicator */
  private readonly stepsIndicatorElement = BszStepper.createStepsIndicatorElement(this.renderer);

  /** The element which displays the active and total steps */
  private readonly stepsIndicatorStepsElement = BszStepper.createStepsIndicatorStepsElement(this.renderer);

  /** The element which displays the label of the active step */
  private readonly stepsIndicatorLabelElement = BszStepper.createStepsIndicatorLabelElement(this.renderer);

  /** The html element of mat-stepper */
  private readonly hostElement = this.elementRef.nativeElement;

  private readonly isMobile = this.screenSizeService.getScreenSize().pipe(map((screenSize) => screenSize === 'mobile'));

  private readonly destroy = new AsyncSubject<void>();

  constructor(
    @Host() private readonly matStepper: MatStepper,
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly screenSizeService: BszScreenSize,
    private readonly translate: TranslateService,
    private readonly renderer: Renderer2,
    private readonly ngZone: NgZone
  ) {
    this.subscribeToSelectionChange(matStepper);
  }

  ngAfterViewInit(): void {
    this.buildIndicatorAndAttach();
    this.updateIndicator(this.matStepper.selectedIndex + 1, this.getActiveLabelText());

    this.isMobile.pipe(takeUntil(this.destroy)).subscribe((isMobile) => this.toggleHeader(isMobile));
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  private subscribeToSelectionChange(matStepper: MatStepper): void {
    // This side effect along with the combination of filter() helps to prevent multiple
    // and redundant updates because ngZone.onStable emits many times
    let activeLabelText: string;
    const stepIndicatorNeedsUpdate = () => this.getActiveLabelText() !== activeLabelText;

    matStepper.selectionChange
      .pipe(
        switchMap(() => this.ngZone.onStable),
        filter(() => stepIndicatorNeedsUpdate()),
        takeUntil(this.destroy)
      )
      .subscribe(() => {
        activeLabelText = this.getActiveLabelText();

        this.updateIndicator(this.matStepper.selectedIndex + 1, activeLabelText);
        this.focusIndicatorWhenMobile();
      });
  }

  private buildIndicatorAndAttach(): void {
    this.renderer.appendChild(this.stepsIndicatorElement, this.stepsIndicatorStepsElement);
    this.renderer.appendChild(this.stepsIndicatorElement, this.stepsIndicatorLabelElement);

    this.hostElement.prepend(this.stepsIndicatorElement);
  }

  private updateIndicator(currentStep: number, currentStepLabel: string): void {
    const totalSteps = this.matStepper.steps.length;
    const stepsLabel = this.translate.instant('ui-elements.bsz-stepper.active-step-indicator', {
      currentStep,
      totalSteps,
    });
    this.stepsIndicatorStepsElement.textContent = `${stepsLabel}: `;
    this.stepsIndicatorLabelElement.textContent = currentStepLabel;
  }

  /** Move the focus only on mobile when the indicator is visible. */
  private focusIndicatorWhenMobile(): void {
    this.isMobile
      .pipe(
        filter((isMobile) => isMobile),
        take(1)
      )
      .subscribe(() => this.stepsIndicatorElement.focus());
  }

  private focusSelectedStep() {
    const selectedStepElement = this.hostElement.querySelector('.mat-step-header[aria-selected="true"]') as HTMLElement;

    if (selectedStepElement) {
      selectedStepElement.focus();
    }
  }

  private getActiveLabelText(): string {
    return this.hostElement.querySelector('.mat-step-label-selected .mat-step-text-label')?.textContent ?? '';
  }

  private toggleHeader(isMobile: boolean): void {
    if (isMobile) {
      this.hideHeader();
    } else {
      // If the focus is set to the mobile step indicator then move it to the original stepper
      const shouldRestoreFocus = document.activeElement === this.stepsIndicatorElement;
      this.showHeader();

      // Set the focus after the header is visible to prevent it from going to <body>
      if (shouldRestoreFocus) {
        this.focusSelectedStep();
      }
    }
  }

  private hideHeader(): void {
    const stepperHeaderContainer = this.hostElement.querySelector(
      '.mat-horizontal-stepper-header-container'
    ) as HTMLDivElement;

    if (stepperHeaderContainer) {
      stepperHeaderContainer.style.display = 'none';
    }

    this.stepsIndicatorElement.style.display = 'block';
  }

  private showHeader(): void {
    const stepperHeaderContainer = this.hostElement.querySelector(
      '.mat-horizontal-stepper-header-container'
    ) as HTMLDivElement;

    if (stepperHeaderContainer) {
      stepperHeaderContainer.style.display = '';
    }

    this.stepsIndicatorElement.style.display = 'none';
  }

  private static createStepsIndicatorElement(renderer: Renderer2): HTMLDivElement {
    const el = renderer.createElement('div') as HTMLDivElement;

    el.setAttribute('tabindex', '-1');

    el.className = 'bsz-stepper-step-indicator bsz-caption bsz-text-bold';

    return el;
  }

  private static createStepsIndicatorStepsElement(renderer: Renderer2): HTMLSpanElement {
    const el = renderer.createElement('span');

    el.className = 'bsz-stepper-step-indicator-steps';

    return el;
  }

  private static createStepsIndicatorLabelElement(renderer: Renderer2): HTMLSpanElement {
    const el = renderer.createElement('span');

    el.className = 'bsz-stepper-step-indicator-label bsz-text-normal';

    return el;
  }
}
