import {PortalPage} from '../navigation/classes/portal-page';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslationKeyWithParams} from '../navigation/translation-key-with-params';
import {TranslationKey} from '@basuiz/web-app-applet-api';
import {UserSettingsPage} from './user-settings.page';

export class PostalAddressUpdatePage extends PortalPage {
  get parentPortalPage(): PortalPage | null {
    return new UserSettingsPage();
  }

  get breadcrumbText(): TranslationKey | TranslationKeyWithParams {
    return asTranslationKey('web-app-portal.pages.postal-address-update.breadcrumb');
  }

  get breadcrumbTestId(): string {
    return 'web-app-portal.pages.postal-address-update.breadcrumb';
  }

  get titleText(): TranslationKey {
    return asTranslationKey('web-app-portal.pages.postal-address-update.window-title');
  }

  static readonly routerConfigPath = 'user-settings/postal-address/update';
  get route(): Array<string> {
    return [`/${PostalAddressUpdatePage.routerConfigPath}`];
  }
}
