import {Options, YAxisPlotLinesOptions} from 'highcharts';
import {getPrimaryColor, getChartFontFamilyFromTheme} from './chart-theme';

export function createChartBaseOptions(): Options {
  return {
    chart: {
      style: {
        fontFamily: getChartFontFamilyFromTheme(),
      },
      spacing: [0, 0, 0, 0],
      backgroundColor: undefined,
    },
    plotOptions: {
      series: {
        animation: false,
      },
    },
    title: {
      text: undefined,
    },
    xAxis: {
      crosshair: {
        color: getPrimaryColor(),
        width: 1,
      },
    },
    yAxis: {
      opposite: true,
      title: {
        text: undefined,
      },
      labels: {
        align: 'right',
        reserveSpace: true,
      },
      plotLines: getYPlotLines(),
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    exporting: {
      enabled: false,
      allowHTML: false,
    },
  };
}

/**
 * Display a plot line to highlight the 0 value on Y axis
 */
function getYPlotLines(): YAxisPlotLinesOptions[] {
  return [
    {
      color: '#a6a6a6',
      width: 1,
      value: 0,
      zIndex: 5,
    },
  ];
}
