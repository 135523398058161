import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {ChangeDetectionStrategy, Component, Input, OnInit, QueryList, ViewChild} from '@angular/core';
import {MatSelectionList, MatSelectionListChange} from '@angular/material/list';
import {MatMenuTrigger} from '@angular/material/menu';
import {first} from 'rxjs/operators';

import {BszTable} from '../bsz-table';
import {BszHeaderToggle} from './bsz-header-toggle';
import {BszHeaderToggleDefinition} from './bsz-header-toggle-definition';

const LOG_PREFIX = '[bsz-table-column-toggle]';

/**
 * Menu for toggling table columns on and off, this will put a button to the table
 * that will create a menu with all the listed elements that have been marked to
 * be toggleable (using the "bsz-toggle-header" directive)
 */
@Component({
  selector: 'bsz-table-column-toggle',
  templateUrl: 'bsz-table-column-toggle.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BszTableColumnToggle implements OnInit {
  /** The table this toggle menu will affect */
  @Input() table!: BszTable<unknown, unknown>;

  /**
   * Flag that applies dense style mode on the list if true
   * Used to delegate the setting to child components
   */
  @Input()
  set dense(value: BooleanInput) {
    this._dense = coerceBooleanProperty(value);
  }
  get dense() {
    return this._dense;
  }
  private _dense = false;

  /** Reference to the list of select boxes */
  @ViewChild(MatSelectionList) selectionList!: MatSelectionList;

  /** MatMenuTrigger is responsible for toggling the display of the provided menu instance */
  @ViewChild(MatMenuTrigger, {static: true}) menuTrigger!: MatMenuTrigger;

  /** The columns that can be toggled (defined with the "bsz-toggle-header" directive) */
  toggleableColumns: BszHeaderToggleDefinition[] = [];

  /** Selected columns updated via the event from MatMenuTrigger. Defaults to all selected */
  private columnsToShow: string[] = [];

  /** The minimum amount of selected columns before the rest turn to read only */
  private limit = 1;

  ngOnInit(): void {
    if (!this.table) {
      throw new Error(`${LOG_PREFIX} bsz-table instance is required`);
    }

    this.table.ready.subscribe(() => {
      this.initialize(this.table.tableHeaderToggleElements, this.table.columns, this.table.isTreeTable());
    });

    this.bindFocusOnFirstElement();
  }

  /**
   * When the total selected columns reach the limit disable them, so we don't allow cases where
   * someone can remove all the columns of the table
   */
  disabled(column: BszHeaderToggleDefinition): boolean {
    return this.columnsToShow.length <= this.limit && this.columnsToShow.includes(column.property);
  }

  /** On MatSelectionListChange, call the table to hide or show respective columns */
  toggleColumnsVisibility(event: MatSelectionListChange): void {
    this.columnsToShow = event.source._value || [];

    const columnsToHide = this.toggleableColumns
      .map((col: BszHeaderToggleDefinition) => col.property)
      .filter((col: string) => !this.columnsToShow.includes(col));

    this.hideColumns(columnsToHide);
  }

  private initialize(tableHeaderToggleElements: QueryList<BszHeaderToggle>, columns: string[], isTreeTable: boolean) {
    // We subscribe to the tableHeaderToggleElements because even when the table is ready, QueryList<BszHeaderToggle> is empty
    // and the elements come later.
    //
    // In case the implementation changes in the future to allow the table to be set after the component has been initialized
    // (ex. set the table programmatically) this subscription will not trigger because QueryList<BszHeaderToggle> will have
    // the elements already. That will require this function to be adapted to handle this case as well.
    tableHeaderToggleElements.changes.pipe(first()).subscribe((elements: QueryList<BszHeaderToggle>) => {
      this.createMenuFromToggleElements(elements, columns, isTreeTable);
    });
  }

  private hideColumns(columnsToHide: string[]): void {
    const table = this.table;
    const columnIndices: number[] = [];
    const columnsToShow = table.columns.filter((column: string, index: number) => {
      // Keep columns that are not present in columnsToHide array
      if (!columnsToHide.includes(column)) {
        return true;
      }
      // Track the position of the columns removed so we can remove
      // the group header columns in the exact same position
      columnIndices.push(index);
      return false;
    });

    // Remove group headers that do not have a corresponding column
    const groupHeadersToShow = table.groupHeaderColumns.filter((col, index) => !columnIndices.includes(index));

    table.updateOnlyColumnsToDisplay(columnsToShow, groupHeadersToShow);
  }

  /** Build the menu for toggling columns visibility */
  private createMenuFromToggleElements(elements: QueryList<BszHeaderToggle>, columns: string[], isTreeTable: boolean) {
    this.toggleableColumns = elements
      .map((columnHeader: BszHeaderToggle) => ({
        property: columnHeader.columnProperty,
        text: columnHeader.columnText,
      }))
      .sort((a: BszHeaderToggleDefinition, b: BszHeaderToggleDefinition) =>
        columns.indexOf(a.property) > columns.indexOf(b.property) ? 1 : -1
      );

    // first column cannot be hidden from a tree table
    if (isTreeTable && columns.indexOf(this.toggleableColumns[0].property) === 0) {
      this.toggleableColumns.shift();
    }

    // initially everything is shown
    this.columnsToShow = this.toggleableColumns.map((col: BszHeaderToggleDefinition) => col.property);
  }

  /**
   * When the menu is opened set the focus to the first element of the list
   * so the user can use the keyboard to navigate
   */
  private bindFocusOnFirstElement() {
    this.menuTrigger.menuOpened.subscribe(() => {
      this.selectionList.options.first.focus();
    });
  }
}
