export const regexDisallowAllWhiteSpaces = /^(\s+\S+\s*)*(?!\s).*$/;

export const replaceablePrefix = '<%=';
export const replaceableSuffix = '=>';
export const textSeparator = '|:|';

export const ObfuscatedText = {
  obfuscatedId: `${replaceablePrefix}OBFUSCATED_ID${replaceableSuffix}`,
  obfuscatedUser: `${replaceablePrefix}OBFUSCATED_USER${replaceableSuffix}`,
  obfuscatedPassword: `${replaceablePrefix}OBFUSCATED_PASSWORD${replaceableSuffix}`,
};
