import {Injectable} from '@angular/core';
import {BackendFeature} from './backend-feature.definition';
import {BackendVersionService} from '../backend-version/backend-version.service';
import {
  BszTableColumns,
  BszTableColumnsDisplayFormat,
  BszTableColumnsDisplayOptionalFormat,
  BszTableMultiValueColumn,
} from '@basuiz/web-app-applet-api';
import * as semver from 'semver';

@Injectable({
  providedIn: 'root',
})
export class BackendFeatureAvailabilityService {
  readonly featuresSupportMap: Record<BackendFeature, string | 'not-released'> = {
    'rest.end-point.trading.query-param.multiple-business-partner-id.multiple-portfolio-id':
      '~4.3.130 || ~4.4.96 || ~4.5.48 || ^4.6.31 || >=5.0.210 <24',
  };

  constructor(private backendVersionService: BackendVersionService) {}

  /**
   * This method checks the backend support for a feature.
   * Basuiz specific assumptions:
   * - versions are composed by 3 mandatory segments (separated by .),
   *   followed by an optional fourth segment (separated by - or +)
   * - fourth segment represents the hotfix. '4.3.0' is consider before '4.3.0-alpha'.
   *   A feature will never be delivered on a hotfix.
   */
  isFeatureSupported(backendFeature: BackendFeature): boolean {
    const supportedVersion: string | 'not-released' = this.featuresSupportMap[backendFeature];
    const backendVersion = this.backendVersionService.backendVersion;

    if (supportedVersion === 'not-released' || backendVersion === null) {
      return false;
    } else {
      return semver.satisfies(backendVersion, supportedVersion);
    }
  }

  /**
   * Filters out a value from a 1 or 2-levels array
   */
  filterOutValueFromArrayOrMatrix<T>(matrix: (T | T[])[], value: T): typeof matrix {
    return matrix
      .map((array: T | T[]) => {
        if (Array.isArray(array)) {
          return array.filter((item) => item !== value);
        }
        return array;
      })
      .filter((arrayItem) => (Array.isArray(arrayItem) && arrayItem.length) || arrayItem !== value);
  }

  /**
   * Filters out a value from an array
   */
  filterOutValueFromArray<T>(array: T[], value: T): typeof array {
    return array.filter((item) => item !== value);
  }

  /**
   * Filters out a value from Bsz Table Columns
   */
  filterOutValueFromTableColumns<FIELD, OPTIONAL_FORMAT extends BszTableColumnsDisplayOptionalFormat>(
    tableColumns: BszTableColumns<FIELD, OPTIONAL_FORMAT>,
    fieldToRemove: FIELD
  ): typeof tableColumns {
    if (Array.isArray(tableColumns)) {
      return tableColumns.filter((field) => field !== fieldToRemove);
    } else {
      const optionalFormats = BszTableColumnsDisplayFormat.filter(
        (format) => format in tableColumns
      ) as unknown as OPTIONAL_FORMAT[];

      return optionalFormats.reduce<typeof tableColumns>(
        (result, format) => {
          const formatFields: FIELD[] | undefined = format in tableColumns ? tableColumns[format] : undefined;
          return formatFields
            ? {
                ...result,
                [format]: formatFields.filter((field) => field !== fieldToRemove),
              }
            : result;
        },
        {desktop: tableColumns.desktop.filter((field) => field !== fieldToRemove)}
      );
    }
  }

  /**
   * Iterates thru a matrix and returns an error for each not-valid value.
   */
  validateMatrix<FIELD, OPTIONAL_FORMAT extends BszTableColumnsDisplayOptionalFormat>(
    matrix: BszTableColumns<BszTableMultiValueColumn<FIELD>, OPTIONAL_FORMAT>[],
    unsupportedField: FIELD
  ): string[] {
    const errors: string[] = [];
    matrix.forEach((tableColumns) => {
      if (Array.isArray(tableColumns)) {
        errors.push(...this.validateBszTableMultiValueColumnList(tableColumns, unsupportedField));
      } else {
        const availableFormats = BszTableColumnsDisplayFormat.filter((format) => format in tableColumns) as unknown as (
          | OPTIONAL_FORMAT
          | 'desktop'
        )[];

        availableFormats.forEach((format) => {
          errors.push(...this.validateBszTableMultiValueColumnList(tableColumns[format], unsupportedField));
        });
      }
    });
    return errors;
  }

  private validateBszTableMultiValueColumnList<FIELD>(
    columns: BszTableMultiValueColumn<FIELD>[] | undefined,
    unsupportedField: FIELD
  ): string[] {
    return (columns ?? []).reduce<string[]>((errors, column) => {
      if ((Array.isArray(column) && column.includes(unsupportedField)) || column === unsupportedField) {
        return [...errors, this.getErrorMessage(unsupportedField)];
      } else {
        return errors;
      }
    }, []);
  }

  /**
   * Returns an error if the value and not-valid value are equal.
   */
  validateProperty<T>(configProperty: T, notValidValue: T): string | undefined {
    if (configProperty === notValidValue) {
      return this.getErrorMessage(notValidValue);
    }
    return;
  }

  private getErrorMessage(value: any): string {
    return `Configuration value ${value} is not supported by the backend version`;
  }
}
