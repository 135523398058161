interface NodeEnv {
  CI?: boolean;
  JEST_WORKER_ID?: boolean;
  [prop: string]: unknown;
}

interface NodeProcess {
  env?: NodeEnv;
  [prop: string]: unknown;
}

interface NodeGlobal {
  ignoreEnvVariablesChecks?: boolean;
  process?: NodeProcess;
  [prop: string]: unknown;
}

declare const ngDevMode: unknown;
declare const globalThis: NodeGlobal;
declare const process: NodeProcess;

// This is required to prevent "ERROR ReferenceError: process is not defined"
// when we are on Browser environment
if (!globalThis.process) {
  globalThis.process = {};
}

/**
 * Use this function to display deprecation warnings instead of printing them directly with a console.log or console.warn
 * This will ensure that the warnings are not displayed when an application is on production.
 */
export function webcoat_deprecated(message: string): void {
  if (!!ngDevMode && !(window as any).__uisdk_suppress_deprecation_warnings && !(isJest() || isCI())) {
    console?.warn(`Warning: ${message}`);
  }
}

function isCI() {
  if (globalThis.ignoreEnvVariablesChecks) {
    return false;
  }
  return process.env?.CI !== undefined;
}

function isJest() {
  if (globalThis.ignoreEnvVariablesChecks) {
    return false;
  }
  return process.env?.JEST_WORKER_ID !== undefined;
}
