import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiHttpErrorCodes} from '@basuiz/shared/data-access';
import {BehaviorSubject, Observable, catchError, finalize, firstValueFrom, of, tap} from 'rxjs';
import {ExternalLinkService} from '../../../external-link/external-link.service';
import {AuthJwtService} from '../../services/auth-jwt.service';

@Component({
  selector: 'bsz-auth-reset-password-request',
  templateUrl: './reset-password-request.component.html',
  styleUrls: ['./reset-password-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordRequestComponent implements OnInit {
  @Output()
  goToSignin: EventEmitter<void> = new EventEmitter<void>();

  public readonly showHelp: boolean = Boolean(process.env['APP_CUSTOMER_SUPPORT_PHONE_WHATSAPP']);

  public resetPasswordRequestStatus:
    | ApiHttpErrorCodes.resetPasswordRequestUserNotFound
    | ApiHttpErrorCodes.resetPasswordRequestTimeDelayToCreateNewToken
    | 'pristine'
    | 'error'
    | 'success' = 'pristine';

  private submitLoadingSubject = new BehaviorSubject<boolean>(false);
  submitLoadingSubject$ = this.submitLoadingSubject.asObservable();

  ApiHttpErrorCodes = ApiHttpErrorCodes;

  resetPasswordRequestForm: FormGroup;

  constructor(private authJwtService: AuthJwtService, private externalLinkService: ExternalLinkService) {
    this.resetPasswordRequestForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  ngOnInit(): void {}

  getFormControl(formControlName: any): FormControl {
    return this.resetPasswordRequestForm.get(formControlName) as FormControl;
  }

  async doResetPasswordValidate() {
    if (!this.resetPasswordRequestForm.valid) {
      return;
    }

    this.submitLoadingSubject.next(true);

    const email = this.resetPasswordRequestForm.get('email')?.value;

    await firstValueFrom(
      this.authJwtService.resetPasswordRequest(email).pipe(
        tap((user) => {
          if (user.email) {
            this.resetPasswordRequestStatus = 'success';
          }
        }),
        finalize(() => {
          this.submitLoadingSubject.next(false);
        }),
        catchError((error: HttpErrorResponse): Observable<null> => {
          if (error.error.message === ApiHttpErrorCodes.resetPasswordRequestTimeDelayToCreateNewToken) {
            this.resetPasswordRequestStatus = ApiHttpErrorCodes.resetPasswordRequestTimeDelayToCreateNewToken;
          }
          if (error.error.message === ApiHttpErrorCodes.resetPasswordRequestUserNotFound) {
            this.resetPasswordRequestStatus = ApiHttpErrorCodes.resetPasswordRequestUserNotFound;
          } else {
            this.resetPasswordRequestStatus = 'error';
          }
          return of(null);
        })
      )
    );
  }

  openHelp() {
    this.externalLinkService.open(
      `https://api.whatsapp.com/send/?phone=${process.env['APP_CUSTOMER_SUPPORT_PHONE_WHATSAPP']}`
    );
  }
}
