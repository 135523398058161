import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DisplayFlexDirective} from './display-flex.directive';
import {PositiveNegativeDirective} from './positive-negative.directive';

const directives = [DisplayFlexDirective, PositiveNegativeDirective];

@NgModule({
  declarations: directives,
  exports: directives,
  imports: [CommonModule],
})
export class StylerModule {}
