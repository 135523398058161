import {Dictionary} from '@ngrx/entity';

type WithIndexableProperty<T, K extends keyof T> = T &
  {
    [P in K]: string | number;
  };

export function convertObjectListToDictionary<T, K extends keyof T>(
  list: Array<WithIndexableProperty<T, K>>,
  prop: K
): Dictionary<T> {
  const dictionary: Dictionary<T> = {};
  list.forEach((item) => {
    if (item.hasOwnProperty(prop)) {
      const indexKey = item[prop].toString();
      dictionary[indexKey] = item;
    }
  });
  return dictionary;
}
