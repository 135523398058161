import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import {transformPopover} from './bsz-popover-animations';

export type BszPopoverTriggerEvent = 'click' | 'hover' | 'focus';
export type BszPopoverTheme = 'light' | 'dark';

interface ArrowClassList {
  'bsz-popover-arrow-bottom'?: boolean;
  'bsz-popover-arrow-middle'?: boolean;
  'bsz-popover-arrow-top'?: boolean;
  'bsz-popover-arrow-right'?: boolean;
  'bsz-popover-arrow-center'?: boolean;
  'bsz-popover-arrow-left'?: boolean;
}

let panelIdCounter = 0;

@Component({
  selector: 'bsz-popover',
  templateUrl: './bsz-popover.html',
  styleUrls: ['./bsz-popover.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [transformPopover],
  host: {
    // although it would not be a big impact, hiding the host element prevents conflicts or accessibility errors
    // because it can use aria attributes but the element by itself is not defined in the HTML standard
    hidden: 'hidden',
  },
})
export class BszPopover implements OnDestroy {
  @Input() triggerEvent: BszPopoverTriggerEvent | null = null;

  @Input('aria-label') ariaLabel = '';

  @Input('aria-labelledby') ariaLabelledby = '';

  @Input() theme: BszPopoverTheme = 'dark';

  @Output() closed = new EventEmitter<void>();

  @ViewChild(TemplateRef) templateRef!: TemplateRef<unknown>;

  arrowClassList: ArrowClassList = {};
  arrowInlineStyle: {[key: string]: number | string} = {};
  positionData = '';
  directionData = '';
  closeDisabled = false;
  triggerWidth = 0;
  triggerHeight = 0;
  readonly panelId = `bsz-popover-panel-${panelIdCounter++}`;

  constructor(public zone: NgZone) {}

  ngOnDestroy() {
    this.closed.complete();
  }

  emitCloseEvent(): void {
    this.closeDisabled = false;
    this.closed.emit();
  }

  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.emitCloseEvent();
    }
  }

  handleMouseenter(): void {
    if (!this.isHoverEvent()) {
      return;
    }
    this.closeDisabled = true;
  }

  handleMouseleave(): void {
    if (!this.isHoverEvent()) {
      return;
    }
    this.closeDisabled = false;
    setTimeout(() => {
      // Although closeDisabled was set to false, during the 200ms the user could move
      // the mouse between both the popover and the trigger. This action should not close
      // it, so when the mouse arrives to any of them, it sets closeDisabled back to false
      // This timeout is meant to give some time to move the mouse from one element
      // to the other without closing it
      if (!this.closeDisabled) {
        this.emitCloseEvent();
      }
    }, 200);
  }

  private isHoverEvent(): boolean {
    return this.triggerEvent === 'hover';
  }
}
