import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {FormattingModule} from '../formatting/formatting.module';
import {TestingCoreModule} from '../testing-core/testing-core.module';
import {BszFormFieldErrorsComponent} from './bsz-form-field-errors.component';

@NgModule({
  declarations: [BszFormFieldErrorsComponent],
  imports: [CommonModule, FormsModule, FormattingModule, TranslateModule, TestingCoreModule],
  exports: [BszFormFieldErrorsComponent],
})
export class BszFormFieldErrorsModule {}
