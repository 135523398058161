import {AlertConfig} from './alert.config.definition';
import {ExpandedFeatureToggle} from '@basuiz/web-app-feature-toggle';
import {UserRoleService} from '@basuiz/web-app-common';

// IMPORTANT: do not modify the signature of this function
export function getDefaultConfig(userRoleService: UserRoleService, featureToggle: ExpandedFeatureToggle): AlertConfig {
  return {
    counterTypes: ['COLLABORATION_ISSUES_UNREAD', 'NOTIFICATIONS_UNREAD'],
    pollingInterval: 300000,
  };
}
