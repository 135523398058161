import {Injectable} from '@angular/core';
import {NavigationIntent, PortalDebugService, PortalPage} from '@basuiz/web-app-portal';
import {assertNever} from '@basuiz/web-app-common';
import {ActionWithoutResponse} from '@basuiz/mobile-bridge';

@Injectable({
  providedIn: 'root',
})
export class PortalHybridDebugService {
  constructor(private portalDebugService: PortalDebugService) {}

  public logCustomIntentHandling(
    intent: NavigationIntent,
    result: PortalPage | 'ignore' | 'execute-default',
    handlerOrigin: string
  ) {
    if (this.portalDebugService.navigationIntentHandling) {
      console.log('-->>' + '-'.repeat(56));
      console.log('Custom handler for navigation intent');
      console.log(`Intent: ${intent.constructor.name}`);
      console.log((intent as unknown as {payload: unknown}).payload || 'Intent without payload');
      console.log(`Handled with handler defined by ${handlerOrigin}`);
      if (result instanceof PortalPage) {
        console.log(`Resulting PortalPage: ${result.constructor.name}`);
        console.log((result as unknown as {payload: unknown}).payload || 'Page instance without payload');
      } else if (result === 'ignore') {
        console.log(`Instruction given by the handler to ignore the intent.`);
      } else if (result === 'execute-default') {
        console.log(`Instruction given by the handler to execute the next available handler for the intent.`);
      } else {
        assertNever(result);
      }
      console.log('--<<' + '-'.repeat(56));
    }
  }

  public logMobileActionIntentHandling(
    intent: NavigationIntent,
    action: ActionWithoutResponse<unknown> | undefined,
    isSupported: boolean
  ) {
    if (this.portalDebugService.navigationIntentHandling) {
      console.log('-->>' + '-'.repeat(56));
      console.log('Mobile action handler for navigation intent');
      console.log(`Intent: ${intent.constructor.name}`);
      console.log((intent as unknown as {payload: unknown}).payload || 'Intent without payload');
      if (!action) {
        console.log('Action factory did not return an action for the intent');
      } else {
        console.log(`Action: ${action.name}`);
        console.log((action as unknown as {payload: unknown}).payload || 'Action without payload');
        if (isSupported) {
          console.log('Mobile app supports the action, triggered');
        } else {
          console.log('Mobile app does not support the action');
        }
      }
      console.log('--<<' + '-'.repeat(56));
    }
  }
}
