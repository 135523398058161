import {DefaultNavigationIntentHandlerMap} from '../../navigation/default-intent-handlers.config';
import {DefaultIntentHandlerMapBuilder} from '../default-intent-handler-map.builder';
import * as intents from '../../intents/intents.index';
import * as pages from '../../pages/pages.index';

export function notificationsDefaultIntentHandlersFactory(): DefaultNavigationIntentHandlerMap {
  const builder = new DefaultIntentHandlerMapBuilder();
  defineHandlers(builder.addHandler);
  return builder.build();
}

function defineHandlers(addHandler: DefaultIntentHandlerMapBuilder['addHandler']): void {
  addHandler(
    intents.CreateNotificationsSubscriptionIntent,
    (intent) => new pages.NotificationsSubscriptionFormPage(intent.payload)
  );
  addHandler(
    intents.EditNotificationsSubscriptionIntent,
    (intent) => new pages.NotificationsSubscriptionFormPage({...intent.payload})
  );
  addHandler(intents.ShowNotificationsOverviewIntent, () => new pages.NotificationsPage({view: 'inbox'}));
  addHandler(
    intents.ShowNotificationsSubscriptionOverviewIntent,
    () => new pages.NotificationsPage({view: 'subscription'})
  );
}
