import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  AfterContentChecked,
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  Host,
  Input,
  Optional,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {MatRipple} from '@angular/material/core';
import {first} from 'rxjs/operators';

import {BszNotificationOverlay} from '../bsz-notification-overlay';

@Directive({
  selector: 'bsz-notification-toast-icon',
  host: {
    class: 'bsz-notification-toast-icon bsz-text-secondary',
  },
})
export class BszNotificationToastIcon {}

@Directive({
  selector: 'bsz-notification-toast-title',
  host: {
    class: 'bsz-notification-toast-title bsz-subtitle-2 bsz-text-bold',
  },
})
export class BszNotificationToastTitle {}

@Directive({
  selector: 'bsz-notification-toast-summary',
  host: {
    class: 'bsz-notification-toast-summary',
  },
})
export class BszNotificationToastSummary {}

@Directive({
  selector: 'bsz-notification-toast-actions',
  host: {
    class: 'bsz-notification-toast-actions',
  },
})
export class BszNotificationToastActions {}

@Component({
  selector: 'bsz-notification-toast',
  templateUrl: './bsz-notification-toast.html',
  styleUrls: ['./bsz-notification-toast.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'class': 'mat-elevation-z4',
    '[class.bsz-notification-toast--clickable]': '_hasNotificationClick',
  },
})
export class BszNotificationToast implements AfterContentChecked, AfterViewChecked {
  @Output() afterDismissed = new EventEmitter<unknown>();
  @Output() afterOpened = new EventEmitter();
  @Output() notificationClick = new EventEmitter();

  @ContentChild(BszNotificationToastIcon) notificationIcon: BszNotificationToastIcon;
  @ViewChild(MatRipple) ripple: MatRipple;
  /** @private */
  _hasIcon: boolean;

  /** @private */
  _hasNotificationClick: boolean;

  constructor(
    @Optional() private readonly notificationOverlayRef: BszNotificationOverlay,
    private readonly elementRef: ElementRef
  ) {
    this.notificationOverlayRef?.afterOpened.pipe(first()).subscribe(() => {
      this.afterOpened.emit();
    });
    this.notificationOverlayRef?.afterDismissed.pipe(first()).subscribe(() => {
      this.afterDismissed.emit();
    });
  }
  ngAfterContentChecked() {
    this._hasIcon = !!this.notificationIcon;
    this._hasNotificationClick = this.notificationClick.observed;
  }

  ngAfterViewChecked() {
    if (!this._hasNotificationClick) {
      return;
    }

    // make the clickable element be like an HTML button element
    const notification = this.elementRef.nativeElement as HTMLElement;

    if (notification.classList.contains('bsz-notification-toast--clickable')) {
      const clickableElement = this.elementRef.nativeElement.querySelector('.bsz-notification-toast-content');
      if (clickableElement) {
        clickableElement.setAttribute('role', 'button');
        clickableElement.setAttribute('tabindex', '0');
      }
    }
  }

  /** @private */
  dismiss() {
    this.notificationOverlayRef?.dismiss();
    this.afterDismissed.emit();
  }

  /** @private */
  _onNotificationClick() {
    if (!this._hasNotificationClick) {
      return;
    }
    this.notificationClick.emit();
    this.dismiss();
  }
}

@Component({
  selector: 'bsz-notification-toast-action',
  host: {
    class: 'bsz-notification-toast-action',
  },
  template: ` <button
    mat-button
    class="bsz-text-bold"
    color="primary"
    (click)="dismiss()"
    [disabled]="_disabled"
    [attr.aria-label]="ariaLabel || null"
    [attr.aria-labelledby]="ariaLabelledby || null"
  >
    <ng-content></ng-content>
  </button>`,
})
export class BszNotificationToastAction {
  @Input('aria-label') ariaLabel: string;
  @Input('aria-labelledby') ariaLabelledby: string;

  @Input() set disabled(disabled: BooleanInput) {
    this._disabled = coerceBooleanProperty(disabled);
  }

  /** @private */
  _disabled = false;

  constructor(@Host() private readonly notification: BszNotificationToast) {}

  dismiss() {
    this.notification.dismiss();
  }
}
