<button mat-button class="bsz-switch-button"
        *ngIf="isValidComponent"
        (click)="switchOption()"
        [class.bsz-switch-button__disabled]="disabled"
        [attr.aria-label]="ariaLabel"
        [disabled]="disabled"
        [attr.aria-disabled]="disabled"
        disableRipple>
  <span class="bsz-switch-button-options" aria-hidden="true">
    <ng-content select="bsz-switch-button-option"></ng-content>
  </span>
</button>
