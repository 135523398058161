import {ExpandedGroupStatus, ExpandedHierarchyStatus} from './bsz-tree-definition';

export function getExpandedGroupsInfo(expandedGroups: (string | ExpandedGroupStatus)[]): {
  expandedGroupsId: string[];
  groupsHierarchyStatus: {[key: string]: ExpandedHierarchyStatus};
} {
  const expandedGroupsId: string[] = [];
  const groupsHierarchyStatus: Record<string, ExpandedHierarchyStatus> = {};
  const defaultHierarchyStatus: ExpandedHierarchyStatus = {
    expandAncestors: true,
    expandDescendants: false,
  };
  expandedGroups.forEach((expandedGroup: string | ExpandedGroupStatus) => {
    if (typeof expandedGroup === 'string') {
      groupsHierarchyStatus[expandedGroup] = defaultHierarchyStatus;
    } else {
      const {expandAncestors, expandDescendants} = Object.assign(defaultHierarchyStatus, expandedGroup);
      groupsHierarchyStatus[expandedGroup.groupId] = {expandAncestors, expandDescendants};
    }
    const groupId = typeof expandedGroup === 'string' ? expandedGroup : expandedGroup.groupId;
    expandedGroupsId.push(groupId);
  });

  return {expandedGroupsId, groupsHierarchyStatus};
}
