import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {CurrencyDTO} from '../../data-access/currency-data.service';
import {CallState, LoadingState} from '../../../definitions/call-state.definition';
import {loadCurrencies, loadCurrenciesFailed, loadCurrenciesSuccess} from './currency.actions';

export const FEATURE_KEY = 'web-app-common/currency';

export interface PartialState {
  readonly [FEATURE_KEY]: State;
}

export interface CurrencyState extends EntityState<CurrencyDTO> {
  callState: CallState;
}

export interface CurrencyLoadedState extends EntityState<CurrencyDTO> {
  callState: LoadingState.LOADED;
  entities: Record<CurrencyDTO['id'], CurrencyDTO>;
}

export type State = CurrencyLoadedState | CurrencyState;

export function isCurrencyLoadedState(state: State): state is CurrencyLoadedState {
  return state.callState === LoadingState.LOADED;
}

export const adapter: EntityAdapter<CurrencyDTO> = createEntityAdapter<CurrencyDTO>({
  selectId: (currencyDTO: CurrencyDTO) => currencyDTO.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  callState: LoadingState.INIT,
});

const currencyReducer = createReducer(
  initialState,
  on(loadCurrencies, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(loadCurrenciesSuccess, (state, action) =>
    adapter.setAll(action.currencyList, {
      ...state,
      callState: LoadingState.LOADED,
    })
  ),
  on(loadCurrenciesFailed, (state, error) => ({
    ...state,
    callState: error,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return currencyReducer(state, action);
}
