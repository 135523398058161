import {Provider} from '@angular/core';
import {BszFeatureToggle} from './models/features.definition';
import {WEB_APP_EXPANDED_FEATURE_TOGGLE} from './expanded-feature-toggle.injection';
import {expandFeatureToggle} from './expand-feature-toggle';
import {ExpandedFeatureToggle} from './models/features-expanded.definition';
import {BszConfigFactory} from '@basuiz/web-app-applet-api';
import {validateFeatureToggle} from './feature-toggle.validation';
import {getDefaultFeatureToggle} from './feature-toggle.default';
import {UserRoleService} from '@basuiz/web-app-common';

export function featureToggleProviderFactory(
  callerFactory: BszConfigFactory<BszFeatureToggle>,
  deps: unknown[] = []
): Provider {
  return {
    provide: WEB_APP_EXPANDED_FEATURE_TOGGLE,
    useFactory: (userRoleService: UserRoleService, ...callerDeps: unknown[]) =>
      expandFactory(callerFactory, userRoleService, callerDeps),
    deps: [UserRoleService, ...deps],
  };
}

export function expandFactory(
  callerFactory: BszConfigFactory<BszFeatureToggle>,
  userRoleService: UserRoleService,
  callerDeps: unknown[]
): ExpandedFeatureToggle {
  const toggle: BszFeatureToggle = callerFactory(getDefaultFeatureToggle(userRoleService), ...callerDeps);
  const error = validateFeatureToggle(toggle);
  if (error) {
    console.error('BszFeatureToggle validation failed:', error);
  }
  return expandFeatureToggle(toggle);
}
