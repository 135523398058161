import {formatNumber, getLocaleNumberSymbol, NumberSymbol} from '@angular/common';
import {Inject, Optional, Pipe, PipeTransform} from '@angular/core';
import {BszI18nService} from '@basuiz/i18n';

import {InvalidPipeArgumentError} from '../common/invalid-pipe-argument-error';
import {isValue, strToNumber} from '../common/number-utils';
import {UNICODE_MINUS_SIGN} from '../common/pipes.constants';
import {BSZ_NUMBER_PIPE_DEFAULT_OPTIONS, BszNumberPipeConfig} from './number.pipe.config';

@Pipe({name: 'bszNumber'})
export class BszNumberPipe implements PipeTransform {
  private readonly config: BszNumberPipeConfig;

  constructor(
    private i18nService: BszI18nService,
    @Optional() @Inject(BSZ_NUMBER_PIPE_DEFAULT_OPTIONS) defaultConfig?: BszNumberPipeConfig
  ) {
    this.config = defaultConfig || new BszNumberPipeConfig();
  }

  transform(value: number | string | null | undefined, digitsInfo?: string): string {
    if (!isValue(value)) {
      return this.config.emptyValue;
    }

    try {
      const digitInformation = digitsInfo ?? this.config.digitsInfo;
      const num = strToNumber(value);
      const locale = this.i18nService.localeId;
      const localeMinusSing = getLocaleNumberSymbol(locale, NumberSymbol.MinusSign);

      return formatNumber(num, locale, digitInformation).replace(localeMinusSing, UNICODE_MINUS_SIGN);
    } catch (error) {
      throw new InvalidPipeArgumentError(value, BszNumberPipe);
    }
  }
}
