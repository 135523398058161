import {ExpandedFeatureToggle, isEnabled} from '@basuiz/web-app-feature-toggle';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {MainNavConfig, MainNavElements, MainNavItem} from '../config/portal.config.definition';
import {
  ModifyUserDataIntent,
  ShowCollaborationOverviewIntent,
  ShowDashboardIntent,
  ShowInvestmentProposalsIntent,
  ShowManifestOverviewIntent,
  ShowNotificationsOverviewIntent,
  ShowPaymentCardsIntent,
  ShowPaymentMethodWalletOverviewIntent,
  ShowPriceCalculatorIntent,
  ShowShipmentAddressOverviewIntent,
  ShowShipmentOverviewIntent,
} from '../intents/intents.index';

export function getDefaultMainNavConfig(featureToggle: ExpandedFeatureToggle): MainNavConfig {
  return {
    mainNavElements: getDefaultMainNavElements(featureToggle),
    defaultIcon: 'lens',
    regulatoryLinks: {
      cookiesLinkUrl: undefined,
      privacyLinkUrl: undefined,
      termsLinkUrl: undefined,
    },
    copyright: undefined,
  };
}

function getDefaultMainNavElements(featureToggle: ExpandedFeatureToggle): MainNavElements {
  return [
    {
      icon: 'home',
      label: asTranslationKey('web-app-portal.main-nav.item.dashboard'),
      baseRoute: '/dashboard',
      intentFactory: () => new ShowDashboardIntent(),
    },
    // SHIPMENT
    ...getShipmentMenuItem(featureToggle),

    // WEALTH
    ...getWealthMenuElements(featureToggle),
    // TRANSACTIONS
    ...getTransactionMenuElements(featureToggle),
    // PAYMENT METHOD
    'divider',
    ...getPaymentMethodMenuItem(featureToggle),
    // SELF-SERVICE
    'divider',
    ...getAddressChangeMenuItem(featureToggle),
    ...getCollaborationMenuItem(featureToggle),
    ...getNotificationsMenuItem(featureToggle),
    {
      icon: 'settings',
      label: asTranslationKey('web-app-portal.main-nav.item.user-settings'),
      baseRoute: '/user-settings',
      intentFactory: () => new ModifyUserDataIntent({modificationType: 'web-app-settings'}),
    },
  ];
}

function getTransactionMenuElements(featureToggle: ExpandedFeatureToggle): MainNavElements {
  const investmentProposalsItem: MainNavItem = {
    icon: 'directions',
    label: asTranslationKey('web-app-portal.main-nav.item.investment-proposals'),
    baseRoute: '/investment-proposals',
    intentFactory: () => new ShowInvestmentProposalsIntent(),
  };

  const menuItems: MainNavItem[] = [
    ...getMenuItemIf(investmentProposalsItem, isEnabled(featureToggle.investmentProposals)),
  ];

  return menuItems.length === 0 ? [] : ['divider', ...menuItems];
}

function getMenuItemIf(mainNavItem: MainNavItem, conditionResult: boolean): MainNavItem[] {
  return conditionResult ? [mainNavItem] : [];
}

function getAddressChangeMenuItem(featureToggle: ExpandedFeatureToggle): MainNavElements {
  const changeAddressItem: MainNavItem = {
    icon: 'contact_mail',
    label: asTranslationKey('web-app-portal.main-nav.item.address-change'),
    baseRoute: '/address-change',
    intentFactory: () => new ModifyUserDataIntent({modificationType: 'address'}),
  };

  return [...getMenuItemIf(changeAddressItem, isEnabled(featureToggle.userProfile?.postalAddress))];
}

function getWealthMenuElements(featureToggle: ExpandedFeatureToggle): MainNavElements {
  const paymentCardsItem: MainNavItem = {
    icon: 'credit_card',
    label: asTranslationKey('web-app-portal.main-nav.item.payment-cards-debit'),
    baseRoute: '/cards',
    intentFactory: () => new ShowPaymentCardsIntent(),
  };

  const menuItems: MainNavItem[] = [...getMenuItemIf(paymentCardsItem, isEnabled(featureToggle.paymentCards))];

  return ['divider', ...menuItems];
}

function getCollaborationMenuItem(featureToggle: ExpandedFeatureToggle): MainNavElements {
  const collaborationItem: MainNavItem = {
    icon: 'message',
    label: asTranslationKey('web-app-portal.main-nav.item.collaboration'),
    baseRoute: '/collaboration',
    intentFactory: () => new ShowCollaborationOverviewIntent(),
  };

  return [...getMenuItemIf(collaborationItem, isEnabled(featureToggle.collaboration))];
}

function getNotificationsMenuItem(featureToggle: ExpandedFeatureToggle): MainNavElements {
  const notificationsItem: MainNavItem = {
    svgIcon: 'bszNotifications',
    label: asTranslationKey('web-app-portal.main-nav.item.notifications'),
    baseRoute: '/notifications',
    intentFactory: () => new ShowNotificationsOverviewIntent(),
  };

  return [...getMenuItemIf(notificationsItem, isEnabled(featureToggle.notifications))];
}

function getShipmentMenuItem(featureToggle: ExpandedFeatureToggle): MainNavElements {
  const priceCalcultorItem: MainNavItem = {
    icon: 'calculate',
    label: asTranslationKey('web-app-portal.main-nav.item.price-calculator'),
    baseRoute: '/cotizador',
    intentFactory: () => new ShowPriceCalculatorIntent(),
  };

  const shipmentsItem: MainNavItem = {
    icon: 'products_avq', // TODO p2 re-build icon library
    label: asTranslationKey('web-app-portal.main-nav.item.shipments'),
    baseRoute: '/shipments',
    intentFactory: () => new ShowShipmentOverviewIntent(),
  };

  const manifestsItem: MainNavItem = {
    icon: 'local_shipping',
    label: asTranslationKey('web-app-portal.main-nav.item.manifests'),
    baseRoute: '/manifests',
    intentFactory: () => new ShowManifestOverviewIntent(),
  };

  const shipmentAddressesItem: MainNavItem = {
    icon: 'pin_drop',
    label: asTranslationKey('web-app-portal.main-nav.item.shipment-addresses'),
    baseRoute: '/shipment-addresses',
    intentFactory: () => new ShowShipmentAddressOverviewIntent(),
  };

  return [
    ...getMenuItemIf(priceCalcultorItem, isEnabled(featureToggle.shipment)),
    ...getMenuItemIf(shipmentsItem, isEnabled(featureToggle.shipment)),
    ...getMenuItemIf(manifestsItem, isEnabled(featureToggle.shipment)),
    ...getMenuItemIf(shipmentAddressesItem, isEnabled(featureToggle.shipment)),
  ];
}

function getPaymentMethodMenuItem(featureToggle: ExpandedFeatureToggle): MainNavElements {
  const walletItem: MainNavItem = {
    icon: 'credit_card',
    label: asTranslationKey('web-app-portal.main-nav.item.wallet'),
    baseRoute: '/wallet',
    intentFactory: () => new ShowPaymentMethodWalletOverviewIntent(),
  };

  return [...getMenuItemIf(walletItem, isEnabled(featureToggle.paymentMethod?.wallet))];
}
