import {NgModule} from '@angular/core';
import {MatDatepickerIntl} from '@angular/material/datepicker';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {MatSortHeaderIntl} from '@angular/material/sort';
import {MatStepperIntl} from '@angular/material/stepper';
import {BszI18nModule} from '@basuiz/i18n';

import {MatDatepickerLabels} from './mat-datepicker/mat-datepicker-labels';
import {MatPaginatorLabels} from './mat-paginator/mat-paginator-labels';
import {MatSortHeaderLabels} from './mat-sort-header/mat-sort-header-labels';
import {MatStepperLabels} from './mat-stepper/mat-stepper-labels';

@NgModule({
  imports: [BszI18nModule],
  providers: [
    {
      provide: MatDatepickerIntl,
      useClass: MatDatepickerLabels,
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorLabels,
    },
    {
      provide: MatSortHeaderIntl,
      useClass: MatSortHeaderLabels,
    },
    {
      provide: MatStepperIntl,
      useClass: MatStepperLabels,
    },
  ],
})
export class BszMaterialIntlModule {}
