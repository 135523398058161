import {PortalPage} from '../navigation/classes/portal-page';
import {marker as asTranslationKey} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslationKeyWithParams} from '../navigation/translation-key-with-params';
import {TranslationKey} from '@basuiz/web-app-applet-api';
import {InvestmentProposalDetailPage} from './investment-proposal-detail.page';

export interface InvestmentProposalRequestChangesPagePayload {
  investmentProposalId: number;
}

export class InvestmentProposalRequestChangesPage extends PortalPage {
  constructor(public payload: InvestmentProposalRequestChangesPagePayload) {
    super();
  }

  get parentPortalPage(): PortalPage | null {
    return new InvestmentProposalDetailPage({investmentProposalId: this.payload.investmentProposalId});
  }

  get breadcrumbText(): TranslationKey | TranslationKeyWithParams {
    return asTranslationKey('web-app-portal.pages.investment-proposal-request-changes.breadcrumb');
  }

  get breadcrumbTestId(): string {
    return 'web-app-portal.pages.investment-proposal-request-changes.breadcrumb';
  }

  get titleText(): TranslationKey {
    return asTranslationKey('web-app-portal.pages.investment-proposal-request-changes.window-title');
  }

  static readonly routerConfigPath = 'investment-proposals/request-changes';
  get route(): Array<string> {
    return [`/${InvestmentProposalRequestChangesPage.routerConfigPath}`];
  }
}
