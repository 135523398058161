import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BszI18nModule} from '@basuiz/i18n';

import {BszContentNavigation, BszSectionAside, BszSectionTitle} from './bsz-content-navigation';

const DECLARATIONS_AND_EXPORTS = [BszContentNavigation, BszSectionAside, BszSectionTitle];

@NgModule({
  imports: [CommonModule, RouterModule, BszI18nModule],
  declarations: DECLARATIONS_AND_EXPORTS,
  exports: DECLARATIONS_AND_EXPORTS,
})
export class BszContentNavigationModule {}
